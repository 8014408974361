import React, { FunctionComponent } from 'react';
import Column from 'shared/components/atoms/Column';
import { useSelector } from 'react-redux';
import { selectQuote } from 'Quotes/selectors';
import { ProductType } from 'Quotes/types/productTypes';
import { ProviderName } from 'Quotes/types/store';

export const Diversity: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const quote = useSelector(selectQuote);

  if (quote.productType === ProductType.P2CCT || quote.productType === ProductType.NNI2CCT) {
    if (quote.location.aEnd.cloudConnect.name === ProviderName.Azure) {
      return null;
    }

    if (quote.location.aEnd.cloudConnect.diversified) {
      return (
        <div className="row no-gutters mt-3">
          <Column defaultWidth={2}>
            <span className="sub-heading">Diversity</span>
          </Column>
          <Column defaultWidth={10}>
            <span className="font-weight-bold">Yes</span>
          </Column>
        </div>
      );
    }
  }

  return null;
};
