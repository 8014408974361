import React, { FunctionComponent } from 'react';

import AddUser from './AddUser';
import ViewEditUser from './ViewEditUser';
import NotFound from '../App/NotFound';
import AuthoriseForRoles from '../Auth/AuthoriseForRoles';
import Users from './UsersList';
import Pricing from './Pricing';
import { userPermissions } from 'shared/utils/permissions';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Preferences } from 'Admin/preferences/Preferences';
import { SupplierPreferences } from 'Admin/preferences/SupplierPreferences/SupplierPreferences';
import { OrderTemplates } from 'Admin/preferences/OrderTemplates/OrderTemplates';

const AdminRouter: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path="/users"
        element={
          <AuthoriseForRoles roles={userPermissions.manageUser}>
            <Users />
          </AuthoriseForRoles>
        }
      />
      <Route
        path="/adduser"
        element={
          <AuthoriseForRoles roles={userPermissions.manageUser}>
            <AddUser />
          </AuthoriseForRoles>
        }
      />
      <Route
        path="/user/:userId"
        element={
          <AuthoriseForRoles roles={userPermissions.manageUser}>
            <ViewEditUser />
          </AuthoriseForRoles>
        }
      />
      <Route
        path="/pricing/*"
        element={
          <AuthoriseForRoles roles={[...userPermissions.exportPricing, ...userPermissions.updatePricing]}>
            <Pricing />
          </AuthoriseForRoles>
        }
      />
      <Route
        element={
          <Preferences>
            <Outlet />
          </Preferences>
        }
      >
        <Route
          path="/preferences/supplier"
          element={
            <AuthoriseForRoles roles={userPermissions.customerPreferences}>
              <SupplierPreferences />
            </AuthoriseForRoles>
          }
        />
        <Route path="/preferences/order-templates" element={<OrderTemplates />} />
        <Route path="*" element={<Navigate to="preferences/supplier" />} />
      </Route>
      <Route index element={<NotFound />} />
    </Routes>
  );
};

export default AdminRouter;
