import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import Modal from 'shared/components/molecules/Modal';
import deleteQuote from 'Quotes/crud/deleteQuote';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import { quoteList } from 'Quotes/Routes';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import Column from 'shared/components/atoms/Column';
import Icon from 'shared/components/atoms/Icon';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface Props {
  className?: string;
  quoteId: string;
}

const DeleteQuote: FC<React.PropsWithChildren<Props>> = (props) => {
  const navigate = useNavigate();

  return <DeleteQuoteInternal {...props} navigate={navigate} />;
};
export const DeleteQuoteInternal: FC<React.PropsWithChildren<Props & Navigation>> = ({
  className,
  quoteId,
  navigate,
}) => {
  const [isModalVisible, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const toggleModal = useCallback(() => {
    setError(false);
    setShowModal(!isModalVisible);
  }, [isModalVisible]);

  const onDelete = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      await deleteQuote(quoteId);
      setSuccess(true);
      setTimeout(() => {
        navigate(quoteList);
      }, 3000);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [navigate, quoteId]);

  return (
    <>
      <div className={`${className} row pt-5 px-4 button-container`}>
        <Column>
          <button type="button" className="font-weight-bold" onClick={toggleModal}>
            <Icon name="delete" size="xxLarge" />
            <strong className="align-top d-inline-block pt-2 pl-2">Delete quote</strong>
          </button>
        </Column>
      </div>

      {isModalVisible && (
        <Modal
          disableActions={loading || success}
          title="Delete this quote"
          showCloseBtn={false}
          showConfirmBtn={false}
          onClose={toggleModal}
        >
          <p>
            Are you sure you want to delete this quote?
            <br />
            You will lose all information in the quote, this cannot be undone.
          </p>
          <hr />
          <div className="d-flex justify-content-between">
            <Button mainStyle={ButtonMainStyle.PrimaryOutline} disabled={loading || success} onClick={onDelete}>
              Delete
            </Button>

            <Button
              mainStyle={ButtonMainStyle.PrimaryRectangular}
              disabled={loading || success}
              onClick={toggleModal}
              className="mr-0"
            >
              Cancel
            </Button>
          </div>

          {loading && <Spinner text="Deleting quote" size="large" />}

          {success && (
            <Alert alertType={Alerts.SUCCESS} className="mt-4 mb-0">
              This quote has been successfully deleted. You will be automatically redirected to the quotes screen.
            </Alert>
          )}

          {error && (
            <Alert className="mt-4 mb-0">
              Error deleting quote. Please try again later. If the problem persists, please contact your Account
              Manager.
            </Alert>
          )}
        </Modal>
      )}
    </>
  );
};

const StyledDeleteQuote = styled(DeleteQuote)`
  color: ${(props) => props.theme.colours.secondaryA1};
`;

export { StyledDeleteQuote as DeleteQuote };
