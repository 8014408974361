import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import addressNotListedMenu, {
  ADDRESS_NOT_LISTED_PLACEHOLDER,
  DEFAULT_PLACEHOLDER,
} from 'Location/AddressNotListed/addressNotListedMenu';
import { IOpenReachAddress, IOpenReachAddressSite, IPAFAddress, IPAFAddressSite } from 'shared/types/postcodeResults';
import {
  concatenateOpenReachAddress,
  concatenatePAFAddress,
  findAddressByUdprn,
  findOpenReachAddressByItsLabel,
} from 'shared/utils/addresses/helperFunctions';
import ManualAddressFields from './ManualAddressFields';
import StyledFormHeader from '../FormHeader';
import cx from 'classnames';
import GreyPrompt from 'shared/components/atoms/GreyPrompt';
import { objectIsEmpty } from 'shared/utils/objectHelper';
import AddressTypeBadge, { AddressType as AddressTypeForBadge } from 'shared/components/atoms/AddressTypeBadge';
import { AddressType, ILocation, IQuote } from 'Quotes/types/store';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import onlyPostcodeIsPresent from 'shared/utils/addresses/onlyPostcodeIsPresent';
import Alert from 'shared/components/atoms/Alert';
import { getDefaultValueForOpenReachAddress } from 'Location/OpenreachAddressCapture';
import { LocationLookup } from 'Quotes/types/quoteRecordAttributesBase';
import { ProductType } from 'Quotes/types/productTypes';

interface IAddressSubSection {
  pafAddresses: IPAFAddressSite[];
  openReachAddresses: IOpenReachAddressSite[];
  pafAddressNotListed: boolean;
  fullAddressNotListed: boolean;
  locationFullAddress: IOpenReachAddress;
  onatAddress: ILocation['onatAddress'];
  className?: string;
  menuOpenByDefault?: boolean;
  postcode: string;
  identifierPrefix: string;
  isFTTXQuote: boolean;
  isSupplierNNAT: boolean;
  PAFLocation: IPAFAddress;
  addressType: AddressType;
  errorInFetchingOpenReach?: Error;
  errorInFetchingPAF?: Error;
  fetchingOpenReachAddressesInProgress?: boolean;
  fetchingPAFAddressesInProgress?: boolean;
  locationLookupIntent?: LocationLookup;
  quote: IQuote;
  setAddressType(endAddressType: AddressType): void;
  saveFullAddress(address: IOpenReachAddressSite | null): void;
  savePAFAddress(aEndAddress: IPAFAddressSite | null): void;
  setPAFAddressNotListed(notListed: boolean): void;
  setFullAddressNotListed(notListed: boolean): void;
  fieldOnChange(fieldName: keyof IOpenReachAddress, fieldValue: string): void;
}

const getInitialStatus = (addressNotListedStatus: boolean): string => {
  return addressNotListedStatus ? ADDRESS_NOT_LISTED_PLACEHOLDER : DEFAULT_PLACEHOLDER;
};

const errorMessage =
  'Unable to fetch addresses. Try again later or complete your address manually. Contact your account manager if the problem persists.';

const AddressSubSection: FunctionComponent<React.PropsWithChildren<IAddressSubSection>> = ({
  openReachAddresses,
  pafAddressNotListed,
  fullAddressNotListed,
  locationFullAddress,
  onatAddress,
  className,
  fieldOnChange,
  identifierPrefix,
  isFTTXQuote,
  isSupplierNNAT,
  menuOpenByDefault = false,
  postcode,
  saveFullAddress,
  setPAFAddressNotListed,
  setFullAddressNotListed,
  PAFLocation,
  pafAddresses,
  savePAFAddress,
  errorInFetchingOpenReach,
  errorInFetchingPAF,
  fetchingOpenReachAddressesInProgress,
  fetchingPAFAddressesInProgress,
  setAddressType,
  locationLookupIntent,
  quote,
}) => {
  const [placeholder, setPlaceholder] = useState<string>(getInitialStatus(pafAddressNotListed));

  const [openReachPlaceholder, setOpenReachPlaceholder] = useState<string>(getInitialStatus(fullAddressNotListed));

  const Menu = addressNotListedMenu({
    addressNotListed: pafAddressNotListed,
    onAddressNotListed: setPAFAddressNotListed,
    setPlaceholder,
  });

  const OpenReachMenu = addressNotListedMenu({
    addressNotListed: fullAddressNotListed,
    onAddressNotListed: setFullAddressNotListed,
    setPlaceholder: setOpenReachPlaceholder,
  });

  const onPAFAddressSelect = (address: IDropdownOption): void => {
    if (address) {
      const foundAddress = findAddressByUdprn(address.value, pafAddresses);

      if (foundAddress) {
        savePAFAddress(foundAddress);
        setPAFAddressNotListed(false);
        setPlaceholder(DEFAULT_PLACEHOLDER);
      }
    } else {
      savePAFAddress(null);
      saveFullAddress(null);
      setFullAddressNotListed(false);
    }
    setAddressType(AddressType.PAF);
  };

  const onOpenReachAddressSelect = (address: IDropdownOption): void => {
    if (address) {
      const foundAddress = findOpenReachAddressByItsLabel(address.label, openReachAddresses);

      if (foundAddress) {
        setAddressType(AddressType.OPENREACH);
        saveFullAddress(foundAddress);
        setFullAddressNotListed(false);
        setOpenReachPlaceholder(DEFAULT_PLACEHOLDER);
      }
    } else {
      saveFullAddress(null);
      setAddressType(AddressType.PAF);
    }
  };

  const getDefaultValueForPAFAddress = (location: IPAFAddress): IDropdownOption | null => {
    if (objectIsEmpty(location) || pafAddressNotListed || onlyPostcodeIsPresent(location)) {
      return null;
    }

    const addressLine = concatenatePAFAddress(location);
    return {
      label: addressLine,
      value: location.udprn,
    };
  };

  const openreachOptions: IDropdownOption[] = useMemo(() => {
    return openReachAddresses.map(
      (site: IOpenReachAddressSite): IDropdownOption => {
        const addressLine = concatenateOpenReachAddress(site.attributes, true);

        return {
          label: addressLine,
          value: addressLine,
        };
      }
    );
  }, [openReachAddresses]);

  const pafOptions: IDropdownOption[] = useMemo(() => {
    return pafAddresses.map(
      (site: IPAFAddressSite): IDropdownOption => {
        const addressLine = concatenatePAFAddress(site.attributes);

        return {
          label: addressLine,
          value: site.attributes.udprn,
        };
      }
    );
  }, [pafAddresses]);

  const PAFAddressIsPresent = (): boolean => {
    return !pafAddressNotListed && !onlyPostcodeIsPresent(PAFLocation) && !objectIsEmpty(PAFLocation);
  };

  useEffect(() => {
    if (openreachOptions.length === 1) {
      onOpenReachAddressSelect(openreachOptions[0]);
    }
  }, [openreachOptions]);

  const showAddressDropdown = (): boolean => {
    return (
      !isFTTXQuote &&
      !isSupplierNNAT &&
      !fetchingPAFAddressesInProgress &&
      !errorInFetchingPAF &&
      locationLookupIntent !== 'alk' &&
      (quote.productType !== ProductType.OpticalP2P ||
        !quote.location[identifierPrefix === 'aEndLocation' ? 'aEnd' : 'bEnd'].dataCentreReference)
    );
  };

  const showOpenReachDropdown = (): boolean => {
    return (
      !isFTTXQuote &&
      !isSupplierNNAT &&
      PAFAddressIsPresent() &&
      !fetchingOpenReachAddressesInProgress &&
      !errorInFetchingOpenReach &&
      (quote.productType !== ProductType.OpticalP2P ||
        !quote.location[identifierPrefix === 'aEndLocation' ? 'aEnd' : 'bEnd'].dataCentreReference)
    );
  };

  return (
    <>
      <StyledFormHeader
        title={`Address${
          !(
            isFTTXQuote ||
            locationLookupIntent === 'alk' ||
            isSupplierNNAT ||
            quote.productType === ProductType.OpticalP2P
          )
            ? ' *'
            : ''
        }`}
      />
      <div className={cx(identifierPrefix, className)}>
        <div
          className={`${identifierPrefix}-addresses__wrapper`}
          data-testid={`${identifierPrefix}-addresses__wrapper`}
        >
          {showAddressDropdown() && (
            <Dropdown
              className="PAFAddresses__dropdown"
              customComponents={{ Menu }}
              defaultValue={getDefaultValueForPAFAddress(PAFLocation)}
              defaultMenuIsOpen={menuOpenByDefault}
              onChange={onPAFAddressSelect}
              options={pafOptions}
              placeholder={placeholder}
            />
          )}

          {fetchingPAFAddressesInProgress && (
            <SpinnerWithText id={`${identifierPrefix}-paf-spinner`} text="Finding locations" />
          )}

          {(isFTTXQuote || locationLookupIntent === 'alk') && (
            <>
              <address>{concatenateOpenReachAddress(locationFullAddress)}</address>
              <AddressTypeBadge
                addressType={AddressTypeForBadge.OPENREACH}
                alk={locationFullAddress.alk}
                qualifier={locationFullAddress.qualifier}
              />
            </>
          )}

          {quote.productType === ProductType.OpticalP2P && (
            <address>
              {quote.location[identifierPrefix === 'aEndLocation' ? 'aEnd' : 'bEnd'].dataCentreReference}
            </address>
          )}

          {isSupplierNNAT && (
            <>
              <address>{onatAddress?.full_address || ''}</address>
              <AddressTypeBadge addressType={AddressTypeForBadge.ONAT} />
            </>
          )}

          {showOpenReachDropdown() && (
            <div>
              {openReachAddresses.length > 1 && <GreyPrompt prompt="Select a specific location at this address." />}
              <Dropdown
                className="OpenReachAddresses__dropdown"
                customComponents={{ Menu: OpenReachMenu }}
                value={getDefaultValueForOpenReachAddress(locationFullAddress, fullAddressNotListed) || null}
                defaultMenuIsOpen={menuOpenByDefault}
                onChange={onOpenReachAddressSelect}
                options={openreachOptions}
                placeholder={openReachPlaceholder}
              />
              {(locationFullAddress.alk || locationFullAddress.qualifier) && (
                <div className="mt-2">
                  <AddressTypeBadge
                    addressType={AddressTypeForBadge.OPENREACH}
                    alk={locationFullAddress.alk}
                    qualifier={locationFullAddress.qualifier}
                  />
                </div>
              )}
            </div>
          )}

          {fetchingOpenReachAddressesInProgress && PAFLocation.udprn !== '' && (
            <SpinnerWithText id={`${identifierPrefix}-openreach-spinner`} text="Finding locations" />
          )}

          {(errorInFetchingOpenReach || errorInFetchingPAF) && (
            <Alert id={`${identifierPrefix}-fetching-alert`}>{errorMessage}</Alert>
          )}

          {(pafAddressNotListed || fullAddressNotListed || errorInFetchingPAF || errorInFetchingOpenReach) && (
            <div className={`${identifierPrefix}ManualFields`}>
              <p className={`${identifierPrefix}_form_header`}>Enter address</p>
              <ManualAddressFields
                inputIdentifierPrefix={`${identifierPrefix}_`}
                postcode={postcode}
                fieldOnChange={fieldOnChange}
                fullAddress={locationFullAddress}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const StyledAddressSubSection = styled(AddressSubSection)`
  .OpenReachAddresses__dropdown {
    margin-top: 2em;
  }
`;

export default StyledAddressSubSection;
