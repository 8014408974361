import styled from 'styled-components';
import React from 'react';
import { Circuit, Exchange, IQuote, SecondaryCircuits as ISecondaryCircuits } from 'Quotes/types/store';
import SecondaryExchanges from './SecondaryExchanges';
import { useSecondaryCircuitsWithSelectedPrice } from './data/useSecondaryCircuits';
import { Loading } from '../Loading/Loading';
import { CheckNowInfoPanel } from './CheckNowInfoPanel';
import { ShowLoadPrices } from './ShowLoadPrices';
import { ShowPrices } from './ShowPrices';
import { NNIData } from 'shared/components/molecules/SecondaryCircuits/data/useP2NNICircuitData';
import DiverseToggleButton from 'shared/components/molecules/ToggleButton/DiverseToggleButton';
import { DiverseCollapsableCard } from 'Order/OrderBuilder/shared/components/CollapsableCard/DiverseCollapsableCard';
import { DIAConfig } from 'shared/components/molecules/SecondaryCircuits/DIAConfig';

export const Container = styled.div`
  background: ${(props) => props.theme.colours.primaryB2};
  width: 99%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
  margin: 0 4px;
`;

export const Text = styled.span`
  color: white;
  font-weight: bold;
`;

const NO_EXCHANGES_TEXT = 'There are no other exchanges available, RO2 Diversity is not available at this address.';

export const NoExchangesAvailable = () => (
  <Container>
    <Text data-testid="no-exchanges-info">{NO_EXCHANGES_TEXT}</Text>
  </Container>
);

const NO_PRICES_TEXT =
  'There are no available secondary circuits. Please contact your account manager to discuss prices.';

export const NoPricesAvailable = () => (
  <Container>
    <Text data-testid="no-prices-info">{NO_PRICES_TEXT}</Text>
  </Container>
);

export interface SecondaryCircuitsParams {
  isReadOnly: boolean;
  quote: IQuote;
}

export enum SecondaryCircuitStatus {
  SHOW_CHECK_NOW = 'SHOW_CHECK_NOW',
  SHOW_EXCHANGES = 'SHOW_EXCHANGES',
  NO_EXCHANGES_AVAILABLE = 'NO_EXCHANGES_AVAILABLE',
  SHOW_LOAD_PRICES = 'SHOW_LOAD_PRICES',
  NO_PRICES_AVAILABLE = 'NO_PRICES_AVAILABLE',
  DISPLAY_PRICES = 'DISPLAY_PRICES',
}

export const SecondaryCircuitContainer = ({
  shouldRender,
  isReadOnly,
  enabled,
  setEnabled,
  status,
  loadPrices,
  CheckNow = CheckNowInfoPanel,
  NoExchanges = NoExchangesAvailable,
  ShowExchanges = SecondaryExchanges,
  LoadPrices = ShowLoadPrices,
  NoPrices = NoPricesAvailable,
  DisplayPrices = ShowPrices,
  selectPrice,
  selectedCircuit,
  loadSecondaryCircuits,
  circuitData,
  quote,
}: {
  shouldRender: boolean;
  isReadOnly: boolean;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  status: SecondaryCircuitStatus;
  loadPrices: () => void;
  loadSecondaryCircuits: () => void;
  selectPrice: (circuitId: string, priceId: string) => void;
  selectedCircuit?: Circuit | undefined;
  circuitData: CircuitData;
  quote: IQuote;
} & {
  CheckNow?: typeof CheckNowInfoPanel;
  NoExchanges?: typeof NoExchangesAvailable;
  ShowExchanges?: typeof SecondaryExchanges;
  LoadPrices?: typeof ShowLoadPrices;
  NoPrices?: typeof NoPricesAvailable;
  DisplayPrices?: typeof ShowPrices;
}) => {
  if (!shouldRender) return null;
  const isDisabled = isReadOnly || !enabled;

  const showToggle = [
    SecondaryCircuitStatus.SHOW_LOAD_PRICES,
    SecondaryCircuitStatus.NO_PRICES_AVAILABLE,
    SecondaryCircuitStatus.DISPLAY_PRICES,
    SecondaryCircuitStatus.SHOW_EXCHANGES,
    SecondaryCircuitStatus.NO_EXCHANGES_AVAILABLE,
  ].includes(status);

  return (
    <DiverseCollapsableCard
      includeSecondaryCircuit={enabled}
      isReadOnly={isReadOnly}
      setInclude={setEnabled}
      showToggle={showToggle}
    >
      <div data-testid="secondary-circuits-container">
        {status === SecondaryCircuitStatus.SHOW_CHECK_NOW && (
          <div data-testid="check-now">
            {<CheckNow isReadOnly={isDisabled} onClick={() => loadSecondaryCircuits()} />}
          </div>
        )}
        {status === SecondaryCircuitStatus.SHOW_EXCHANGES && (
          <div data-testid="show-exchanges">
            <ShowExchanges readOnly={isDisabled} circuitData={circuitData} />
          </div>
        )}
        {status === SecondaryCircuitStatus.NO_EXCHANGES_AVAILABLE && (
          <div data-testid="no-exchanges-available">
            <NoExchanges />
          </div>
        )}
        {status === SecondaryCircuitStatus.SHOW_LOAD_PRICES && (
          <div data-testid="show-load-price">
            <ShowExchanges readOnly={isDisabled} circuitData={circuitData} />
            <LoadPrices isReadOnly={isDisabled} onClick={loadPrices} />
          </div>
        )}
        {status === SecondaryCircuitStatus.NO_PRICES_AVAILABLE && (
          <div data-testid="no-prices-available">
            <ShowExchanges readOnly={isDisabled} circuitData={circuitData} />
            <NoPrices />
          </div>
        )}
        {status === SecondaryCircuitStatus.DISPLAY_PRICES && (
          <div data-testid="display-prices">
            <ShowExchanges readOnly={isDisabled} circuitData={circuitData} />
            <DisplayPrices
              circuit={selectedCircuit}
              selectPrice={
                selectedCircuit ? (priceId: string) => selectPrice(selectedCircuit.id, priceId) : () => undefined
              }
              readOnly={isDisabled}
            />
            <DIAConfig circuitData={circuitData} quote={quote} isReadOnly={isReadOnly} setEnabled={setEnabled} />
          </div>
        )}
      </div>
    </DiverseCollapsableCard>
  );
};

export const ProceedWithSecondaryCircuitToggle = ({
  includeSecondaryCircuit,
  isReadOnly,
  setInclude,
}: {
  includeSecondaryCircuit: boolean;
  isReadOnly: boolean;
  setInclude: (include: boolean) => void;
}) => {
  return (
    <DiverseToggleButton
      checked={includeSecondaryCircuit}
      disabled={isReadOnly}
      label={'Proceed with diverse option  '}
      onToggleChange={(event) => setInclude(event.target.checked)}
    />
  );
};

export interface IExchanges {
  exchanges: Exchange[];
  selectExchange: (exchange: Exchange) => void;
  selectedExchangeId: string | undefined;
  selected: Exchange | undefined;
}

export interface InternetAccess {
  selected: undefined;
}

export interface BaseCircuitData {
  isLoading: boolean;
  status: () => SecondaryCircuitStatus;
  canLoadPrices: () => boolean;
  processors: ((secondaryCircuit: ISecondaryCircuits) => ISecondaryCircuits)[];
  getSelectedCircuitId: () => string | undefined;
}

export interface P2NNICircuitData extends BaseCircuitData {
  aEnd: NNIData;
  bEnd: IExchanges;
  getCircuitId: (aEnd: NNIData['selected'], bEnd: IExchanges['selected']) => string | undefined;
  type: 'P2NNI';
}

export interface P2PCircuitData extends BaseCircuitData {
  aEnd: IExchanges;
  bEnd: IExchanges;
  getCircuitId: (aEnd: IExchanges['selected'], bEnd: IExchanges['selected']) => string | undefined;
  type: 'P2P';
}

export interface DIACircuitData extends BaseCircuitData {
  aEnd: IExchanges;
  bEnd: InternetAccess;
  getCircuitId: (aEnd: IExchanges['selected']) => string | undefined;
  type: 'DIA';
}

export type CircuitData = P2NNICircuitData | P2PCircuitData | DIACircuitData;

// TODO: We will want to look into refactoring these & interfaces due to commonalities
export const isP2P = (circuitData: CircuitData): circuitData is P2PCircuitData => circuitData.type === 'P2P';
export const isP2NNI = (circuitData: CircuitData): circuitData is P2NNICircuitData => circuitData.type === 'P2NNI';
export const isDIA = (circuitData: CircuitData): circuitData is DIACircuitData => circuitData.type === 'DIA';
const SecondaryCircuits = (props: SecondaryCircuitsParams) => {
  const secondaryCircuitHook = useSecondaryCircuitsWithSelectedPrice();
  if (!secondaryCircuitHook.circuitData) return null; // Unsupported Product Type

  return (
    <Loading isLoading={secondaryCircuitHook.isLoading}>
      <Bubble>
        <SecondaryCircuitContainer
          enabled={secondaryCircuitHook.enabled}
          setEnabled={secondaryCircuitHook.setEnabled}
          selectedCircuit={secondaryCircuitHook.selectedCircuit}
          shouldRender={secondaryCircuitHook.isSupported()}
          isReadOnly={props.isReadOnly}
          loadSecondaryCircuits={secondaryCircuitHook.loadSecondaryCircuits}
          status={secondaryCircuitHook.getStatus()}
          circuitData={secondaryCircuitHook.circuitData}
          loadPrices={secondaryCircuitHook.loadPrices}
          selectPrice={secondaryCircuitHook.selectPrice}
          quote={props.quote}
        />
      </Bubble>
    </Loading>
  );
};

const Bubble = styled.div`
  border-radius: 5px;
  padding: 0.25em;
`;

export default SecondaryCircuits;
