import { ProductType } from '../../../../Quotes/types/productTypes';
import React from 'react';
import { OverallConnectionType } from '../../../../Quotes/types/connectionType';
import { IPriceData } from '../../../../Quotes/types/store';
import { annualCost } from '../../../../Quotes/selectors';
import { BEndSupplierCosts } from './BEndSupplierCosts';
import { BEndPortCosts } from './BEndPortCosts';

function getBEndSelectedPriceData(selectedPrice: IPriceData) {
  const bEndSetupCost = selectedPrice.b_end_setup_cost;
  const bEndAnnualCost = annualCost(selectedPrice).B;
  // const bEndAnnualCost = selectedPrice.b_end_annual_cost;
  const bEndPortCost = selectedPrice.port_b_cost;
  const bEndOpticalCosts = selectedPrice.opticalCosts?.bEnd;
  return { bEndSetupCost, bEndAnnualCost, bEndPortCost, bEndOpticalCosts };
}

export function BEndCosts(props: {
  productType: ProductType;
  selectedPrice: IPriceData;
  connectionType: OverallConnectionType;
}) {
  const { bEndSetupCost, bEndAnnualCost, bEndPortCost, bEndOpticalCosts } = getBEndSelectedPriceData(
    props.selectedPrice
  );

  return (
    <>
      <BEndSupplierCosts productType={props.productType} bEndSetupCost={bEndSetupCost} cost={bEndAnnualCost} />
      <BEndPortCosts
        connectionType={props.connectionType}
        productType={props.productType}
        bEndPortCost={bEndPortCost}
        bEndOpticalCosts={bEndOpticalCosts}
      />
    </>
  );
}
