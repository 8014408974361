import React, { FunctionComponent } from 'react';
import Column from 'shared/components/atoms/Column';
import { PoP } from '../PoP';
import { useSelector } from 'react-redux';
import {
  isNewNNI as selectIsNewNNI,
  isNewShadowNNI as selectIsNewShadowNNI,
  selectCurrentMeta,
  selectNNILabel,
  selectQuote,
  selectSelectedPrice,
} from 'Quotes/selectors';
import { concatenateShadowVlan } from 'shared/utils/addresses/helperFunctions';
import { useNewNNIRequests } from 'Order/OrderBuilder/Locations/shared/components/NNISummary';
import { selectOrder } from 'Order/selectors';
import { NewNNIJobReference } from 'Order/OrderBuilder/QuoteSummary/P2NNI';

export const NNI2CCT: FunctionComponent = () => {
  const selectedPrice = useSelector(selectSelectedPrice);
  const quote = useSelector(selectQuote);
  const order = useSelector(selectOrder);
  const quoteMeta = useSelector(selectCurrentMeta);
  const shadowRef = concatenateShadowVlan(quoteMeta, quote.location.aEnd.nni);
  const nniLabel = useSelector(selectNNILabel);
  const isNewNNI = useSelector(selectIsNewNNI);
  const isNewShadowNNI = useSelector(selectIsNewShadowNNI);

  const location = order.locationA;
  const newNNIRequests = location.siteConfig.new_nni_requests;

  const primaryNNIRequest = useNewNNIRequests(isNewNNI, selectedPrice.a_end_p_o_p_id, newNNIRequests);
  const shadowNNIRequest = useNewNNIRequests(
    isNewShadowNNI,
    quote.location.aEnd.nni.shadowVLAN.selectedDataCentreId,
    newNNIRequests
  );

  return (
    <>
      <div className="row no-gutters mt-3">
        <Column defaultWidth={2}>
          <span className="sub-heading">Location(s)</span>
        </Column>
        <Column defaultWidth={2}>
          <span className="font-weight-bold" data-testid="order-summary-nni2cc-cloud-provider">
            {quote.location.aEnd.cloudConnect.name}
          </span>
        </Column>
      </div>
      <div className="row no-gutters mt-3">
        <Column defaultWidth={2} offsetDefaultWidth={2}>
          <span className="font-weight-bold">NNI (Neos Networks on-net)</span>
        </Column>
        <Column defaultWidth={8}>
          <PoP
            end="A"
            port={quote.aEndPort}
            pop={selectedPrice.a_end_p_o_p}
            postcode={selectedPrice.a_end_p_o_p_postcode}
            exchangeType={selectedPrice.a_end_exchange_type}
            showExchange={false}
          />
          <div className="nni-label" data-testid="order-summary-nni2cc-nni-address">
            {nniLabel}
          </div>
          <NewNNIJobReference nniRequest={primaryNNIRequest} jobReference={location.siteConfig.nni_job_reference} />
        </Column>
      </div>
      {shadowRef && (
        <div className="row no-gutters mt-1">
          <Column defaultWidth={2} offsetDefaultWidth={2}>
            <span className="font-weight-bold">Shadow VLAN</span>
          </Column>
          <Column defaultWidth={8}>
            <div className="shadow-nni-label" data-testid="order-summary-nni2cc-shadowvlan-address">
              {shadowRef}
            </div>
            <NewNNIJobReference
              nniRequest={shadowNNIRequest}
              jobReference={location.siteConfig.shadow_nni_job_reference}
            />
          </Column>
        </div>
      )}
    </>
  );
};
