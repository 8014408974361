import { isValidTextField, isValidEmail, isValidPhoneField } from './validation';
import { IPContact } from 'Order/types/formFields';
import { IContact } from 'Order/types/contact';
import { ISiteContact } from 'Order/types/location';

export const contactIsComplete = (contactDetails: IContact): boolean => {
  return (
    isValidTextField(contactDetails.firstName) &&
    isValidTextField(contactDetails.surname) &&
    isValidPhoneField(contactDetails.phoneNumber) &&
    isValidEmail(contactDetails.email)
  );
};

export const siteContactIsComplete = (siteContact: ISiteContact): boolean => {
  return (
    isValidTextField(siteContact.firstName) &&
    isValidTextField(siteContact.surname) &&
    isValidPhoneField(siteContact.phone) &&
    isValidEmail(siteContact.email)
  );
};

export const ipContactIsComplete = (ipContact: IPContact): boolean => {
  return (
    isValidTextField(ipContact.address) &&
    isValidEmail(ipContact.email) &&
    isValidTextField(ipContact.firstName) &&
    isValidTextField(ipContact.surname) &&
    isValidTextField(ipContact.organisation) &&
    isValidPhoneField(ipContact.telephone)
  );
};
