import { Action } from 'redux';

export enum RequestLifecycle {
  Started = 'started',
  Error = 'error',
  Success = 'success',
  Loading = 'loading',
}

export type GenericAction = Action & {
  payload?: any;
};
