import IQuoteRecordAttributesBase from '../types/quoteRecordAttributesBase';

export const getOpenreachAddressAttrsAEnd = (attributes: IQuoteRecordAttributesBase): any => {
  return {
    id: '',
    type: 'talktalk_addresses',
    attributes: {
      alk: attributes.a_end_openreach_address?.full_site_alk || '',
      qualifier: attributes.a_end_openreach_address?.full_site_match_type || '',
      building_name: attributes.a_end_openreach_address?.full_site_building_name || '',
      building_number: attributes.a_end_openreach_address?.full_site_building_number || '',
      sub_building: attributes.a_end_openreach_address?.full_site_sub_building || '',
      street: attributes.a_end_openreach_address?.full_site_street || '',
      post_town: attributes.a_end_openreach_address?.full_site_town || '',
      county: attributes.a_end_openreach_address?.full_site_county || '',
      css_district_code: attributes.a_end_openreach_address?.full_site_district_code || '',
    },
  };
};
