import classNames from 'classnames';
import Button, { ButtonMainStyle } from '../../../../shared/components/atoms/Button';
import React from 'react';
import { IRequestState } from '../../../../shared/types/requestState';
import { PublishingInProgressSpinner } from './Spinners';
import { QuoteStatus } from '../../../types/quoteRecordAttributesBase';
import { SavingONATAddress } from '../../../types/store';
import { Tooltip } from '../../../../shared/components/atoms/Tooltip';
import styled from 'styled-components';

export const OrderPriceButton = styled(Button)`
  height: 50px;
`;

const PublishButtonBottom = styled(OrderPriceButton)`
  &:disabled {
    opacity: 1;
  }
`;

export const InternalQuotePublishButton = ({
  onClick,
  publishing: { inProgress },
  updatingQuote,
}: {
  publishing: IRequestState;
  updatingQuote: undefined | boolean;
  onClick: () => void;
}) => (
  <div className="pr-1">
    <PublishButtonBottom
      className={classNames('outline order-price-btn publish_btn_bottom', {
        'pt-0': inProgress,
      })}
      disabled={inProgress || updatingQuote}
      mainStyle={ButtonMainStyle.PrimaryOutline}
      onClick={onClick}
    >
      {inProgress && <PublishingInProgressSpinner />}
      {!inProgress && 'Publish'}
    </PublishButtonBottom>
  </div>
);
export const SaveAndExitButton = ({
  onClick,
  quoteState,
  savingONATAddress,
  sentToCerillionAt,
}: {
  sentToCerillionAt: string | null;
  quoteState: QuoteStatus | undefined;
  savingONATAddress: SavingONATAddress;
  onClick: () => void;
}) => (
  <div className="price__save_and_exit_btn pr-1">
    <OrderPriceButton
      data-testid="save and exit button"
      className="price__save_and_exit_btn_btn order-price-btn outline"
      disabled={sentToCerillionAt !== null || quoteState === QuoteStatus.ORDERED || savingONATAddress.inProgress}
      mainStyle={ButtonMainStyle.PrimaryOutline}
      onClick={onClick}
    >
      Save &amp; exit
    </OrderPriceButton>
  </div>
);
export const AddToMultiQuoteButton = ({ disabled, onClick }: { disabled: boolean; onClick: () => void }) => (
  <div className="price__proceed_to_multi_quote_btn pr-1">
    <OrderPriceButton
      className="multi-quote-price-btn order-price-btn outline"
      disabled={disabled}
      mainStyle={ButtonMainStyle.PrimaryOutline}
      onClick={onClick}
    >
      <span className="mr-1">Multi-Quote</span>
      <Tooltip text="Add this quote to a Multi-Quote for a bulk discount. Maximum of 10 quotes per Multi-Quote." />
    </OrderPriceButton>
  </div>
);
export const ReQuoteButton = ({ disabled, onClick }: { disabled: undefined | boolean; onClick: () => void }) => (
  <div>
    <OrderPriceButton
      disabled={disabled}
      className="mr-0 order-price-btn"
      mainStyle={ButtonMainStyle.DangerRectangular}
      subStyle="outline"
      onClick={onClick}
    >
      Re-Quote
    </OrderPriceButton>
  </div>
);
