import cx from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import Icon from '../../atoms/Icon';
import Column from '../../atoms/Column';
import Spinner from '../../molecules/SpinnerWithText';
import EditButton from '../../molecules/EditButton';

export enum CardState {
  Initial,
  Edit,
  Loading,
  Summary,
  LockedSummary,
}

interface ICardWithInitialTextProps {
  children?: ReactNode;
  className?: string;
  completed?: boolean;
  initialList?: string[];
  cardState: CardState;
  loadingText?: string;
  title: string;
  onEditClick(): void;
  extraButtons?: ReactNode;
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 0.5rem;
`;

const noop = () => null;

const CardWithInitialText: FunctionComponent<React.PropsWithChildren<ICardWithInitialTextProps>> = ({
  children,
  className,
  completed = false,
  initialList = [],
  loadingText = 'Loading',
  cardState,
  onEditClick,
  title,
  extraButtons,
}) => {
  const clickable =
    cardState !== CardState.Edit && cardState !== CardState.Loading && cardState !== CardState.LockedSummary;

  return (
    <section className={className}>
      <div className="row no-gutters header">
        <Column defaultWidth={10}>
          <h4 className={cx({ completed })}>
            {completed ? (
              <Icon className="completed-icon primary align-middle" name="check_circle" size="xLarge" />
            ) : (
              <Icon className="grey20 align-middle" name="brightness_1" size="xLarge" />
            )}
            {title}
          </h4>
        </Column>
        <Column defaultWidth={2}>
          <ButtonContainer>
            {clickable && <EditButton onClick={clickable ? onEditClick : noop} />}
            {clickable && extraButtons}
            {cardState === CardState.LockedSummary && (
              <Icon className="align-middle cardLock" name="lock" size="xLarge" />
            )}
          </ButtonContainer>
        </Column>
      </div>

      {cardState === CardState.Initial && (
        <div className="row no-gutters initial-list">
          <Column defaultWidth={12}>
            <ul>
              {initialList.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </Column>
        </div>
      )}

      {cardState === CardState.Loading && <Spinner text={loadingText} className="loading-spinner" size="large" />}

      {cardState === CardState.Initial || cardState === CardState.Loading || <div className="content">{children}</div>}
    </section>
  );
};

const StyledCardWithInitialText = styled(CardWithInitialText)`
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  border: solid 0.5px #ecf3fa;
  background-color: #ffffff;
  padding: 1em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  li {
    word-break: break-all;
  }

  &.clickable {
    cursor: pointer;
  }

  .header {
    border-bottom: 1px solid ${(props) => props.theme.colours.grey10};
    padding-bottom: 8px;
  }

  h4 {
    color: ${(props) => props.theme.colours.secondary};
    display: flex;
    line-height: 1.4em;

    i {
      margin-right: 15px;
    }

    &.completed {
      color: ${(props) => props.theme.colours.primaryB1};
    }
  }

  .loading-spinner {
    margin-top: 20px;
  }

  .initial-list {
    padding-top: 30px;
  }

  .content {
    padding-top: 1.5em;
  }

  .cardLock {
    margin-right: 0;
    margin-left: auto;
    color: ${(props) => props.theme.colours.primaryB1};
  }
`;

export default StyledCardWithInitialText;
