import { AvailabilityCheckRequest, AvailabilityCheckResponse } from 'Quotes/types/availabilityCheck';
import { doRequest, Methods } from 'Request';
import { objectToGetParams } from 'shared/utils/objectToGetParams';
import { mapValues } from 'lodash';

const getAvailability = (params: AvailabilityCheckRequest): Promise<AvailabilityCheckResponse> => {
  // Ensures we don't encode undefined to "undefined" with `encodeURIComponent` below.
  // We delete it as the API only looks at the existence of the parameter rather than
  // its value to determine if it is active.
  const sanitisedParams = sanitiseParams(params);
  const urlSafeValues = urlEncodeParams(sanitisedParams);

  return doRequest({
    method: Methods.GET,
    path: `/lq-availability?${objectToGetParams(urlSafeValues)}`,
  });
};

const sanitiseParams = <T extends Record<string, unknown>>(data: T) => {
  return Object.entries(data)
    .filter(([, value]) => value !== undefined)
    .reduce((obj: { [key: string]: any }, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
};

const urlEncodeParams = (sanitisedParams: { [p: string]: any }) =>
  mapValues(sanitisedParams, (item) => encodeURIComponent(item!));

export { getAvailability };
