import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';

export interface DIAOrderOptions {
  connectorType: {
    isLCAvailable: boolean;
    isSCAvailable: boolean;
    isRJ45Available: boolean;
  };
  mediaType: {
    isLXAvailable: boolean;
    isSXAvailable: boolean;
    isLRAvailable: boolean;
    isSRAvailable: boolean;
    isTXAvailable: boolean;
  };
  autoNegotiation: {
    isYesMandatory: boolean;
    isNoMandatory: boolean;
    removeSection: boolean;
  };
  vlanTagging: {
    isAvailable: boolean;
  };
}

export const RJ45_ONLY: DIAOrderOptions['connectorType'] = {
  isLCAvailable: false,
  isSCAvailable: false,
  isRJ45Available: true,
};

export const LARGE_FIBRE_ONLY: DIAOrderOptions['connectorType'] = {
  isLCAvailable: true,
  isSCAvailable: false,
  isRJ45Available: false,
};

export const LARGE_FIBRE_AND_RJ45: DIAOrderOptions['connectorType'] = {
  isLCAvailable: true,
  isSCAvailable: false,
  isRJ45Available: true,
};

export const FIBRES_ONLY: DIAOrderOptions['connectorType'] = {
  isLCAvailable: true,
  isSCAvailable: true,
  isRJ45Available: false,
};

export const ALL_CONNECTORS_AVAILABLE: DIAOrderOptions['connectorType'] = {
  isLCAvailable: true,
  isSCAvailable: true,
  isRJ45Available: true,
};

export const NO_CONNECTORS_AVAILABLE = {
  isLCAvailable: false,
  isSCAvailable: false,
  isRJ45Available: false,
};

export const TX_ONLY: DIAOrderOptions['mediaType'] = {
  isLXAvailable: false,
  isSXAvailable: false,
  isLRAvailable: false,
  isSRAvailable: false,
  isTXAvailable: true,
};

export const LX_SX_TX_ONLY: DIAOrderOptions['mediaType'] = {
  isLXAvailable: true,
  isSXAvailable: true,
  isLRAvailable: false,
  isSRAvailable: false,
  isTXAvailable: true,
};

export const REACH_FIBRE_ONLY: DIAOrderOptions['mediaType'] = {
  isLXAvailable: false,
  isSXAvailable: false,
  isLRAvailable: true,
  isSRAvailable: true,
  isTXAvailable: false,
};

export const REACH_FIBRE_AND_TX: DIAOrderOptions['mediaType'] = {
  isLXAvailable: false,
  isSXAvailable: false,
  isLRAvailable: true,
  isSRAvailable: true,
  isTXAvailable: true,
};

export const NO_MEDIA_TYPES_AVAILABLE = {
  isLXAvailable: false,
  isSXAvailable: false,
  isLRAvailable: false,
  isSRAvailable: false,
  isTXAvailable: false,
};

export const AUTO_NEGOTIATION_AVAILABLE: DIAOrderOptions['autoNegotiation'] = {
  isYesMandatory: false,
  isNoMandatory: false,
  removeSection: false,
};

export const AUTO_NEGOTIATION_UNAVAILABLE: DIAOrderOptions['autoNegotiation'] = {
  isYesMandatory: false,
  isNoMandatory: false,
  removeSection: true,
};

export const DISPLAY_MANDATORY_AUTO_NEGOTIATION: DIAOrderOptions['autoNegotiation'] = {
  isYesMandatory: true,
  isNoMandatory: false,
  removeSection: false,
};

export const DISPLAY_MANDATORY_NO_AUTO_NEGOTIATION: DIAOrderOptions['autoNegotiation'] = {
  isYesMandatory: false,
  isNoMandatory: true,
  removeSection: false,
};

export const VLAN_TAGGING_AVAILABLE: DIAOrderOptions['vlanTagging'] = {
  isAvailable: true,
};

export const VLAN_TAGGING_UNAVAILABLE: DIAOrderOptions['vlanTagging'] = {
  isAvailable: false,
};

const NO_CONFIGURATION_AVAILABLE = {
  connectorType: NO_CONNECTORS_AVAILABLE,
  mediaType: NO_MEDIA_TYPES_AVAILABLE,
  autoNegotiation: AUTO_NEGOTIATION_AVAILABLE,
  vlanTagging: VLAN_TAGGING_UNAVAILABLE,
};

/**
 * Display logic for DIA order options as described from MDIA 1.2 in the following ticket:
 * https://dev.azure.com/NeosNetworksUK/IT-Software-Development/_workitems/edit/54230
 */
export function getDIAOrderOptions(isManagedDIA: boolean, bearer: BearerType, isNNAT: boolean): DIAOrderOptions {
  if (isManagedDIA) {
    return getMDIAConfiguration(bearer, isNNAT) ?? NO_CONFIGURATION_AVAILABLE;
  } else {
    return getDIAConfiguration(bearer, isNNAT) ?? NO_CONFIGURATION_AVAILABLE;
  }
}

const getMDIAConfiguration = (bearer: BearerType, isNNAT: boolean) => {
  if ([BearerType.SMALL, BearerType.MEDIUM].includes(bearer)) {
    if (isNNAT) {
      return {
        connectorType: RJ45_ONLY,
        mediaType: TX_ONLY,
        autoNegotiation: DISPLAY_MANDATORY_AUTO_NEGOTIATION,
        vlanTagging: VLAN_TAGGING_UNAVAILABLE,
      };
    } else {
      return {
        connectorType: RJ45_ONLY,
        mediaType: TX_ONLY,
        autoNegotiation: DISPLAY_MANDATORY_AUTO_NEGOTIATION,
        vlanTagging: VLAN_TAGGING_UNAVAILABLE,
      };
    }
  } else if ([BearerType.LARGE].includes(bearer)) {
    if (isNNAT) {
      return {
        connectorType: LARGE_FIBRE_ONLY,
        mediaType: REACH_FIBRE_ONLY,
        autoNegotiation: AUTO_NEGOTIATION_UNAVAILABLE,
        vlanTagging: VLAN_TAGGING_UNAVAILABLE,
      };
    } else {
      return {
        connectorType: LARGE_FIBRE_ONLY,
        mediaType: REACH_FIBRE_ONLY,
        autoNegotiation: AUTO_NEGOTIATION_UNAVAILABLE,
        vlanTagging: VLAN_TAGGING_UNAVAILABLE,
      };
    }
  }
};

const getDIAConfiguration = (bearer: BearerType, isNNAT: boolean) => {
  if ([BearerType.SMALL].includes(bearer)) {
    if (isNNAT) {
      return {
        connectorType: RJ45_ONLY,
        mediaType: TX_ONLY,
        autoNegotiation: DISPLAY_MANDATORY_NO_AUTO_NEGOTIATION,
        vlanTagging: VLAN_TAGGING_AVAILABLE,
      };
    } else {
      return {
        connectorType: RJ45_ONLY,
        mediaType: TX_ONLY,
        autoNegotiation: AUTO_NEGOTIATION_AVAILABLE,
        vlanTagging: VLAN_TAGGING_AVAILABLE,
      };
    }
  } else if ([BearerType.MEDIUM].includes(bearer)) {
    if (isNNAT) {
      return {
        connectorType: ALL_CONNECTORS_AVAILABLE,
        mediaType: LX_SX_TX_ONLY,
        autoNegotiation: AUTO_NEGOTIATION_UNAVAILABLE,
        vlanTagging: VLAN_TAGGING_AVAILABLE,
      };
    } else {
      return {
        connectorType: ALL_CONNECTORS_AVAILABLE,
        mediaType: LX_SX_TX_ONLY,
        autoNegotiation: AUTO_NEGOTIATION_UNAVAILABLE,
        vlanTagging: VLAN_TAGGING_AVAILABLE,
      };
    }
  } else if ([BearerType.LARGE].includes(bearer)) {
    if (isNNAT) {
      return {
        connectorType: FIBRES_ONLY,
        mediaType: REACH_FIBRE_ONLY,
        autoNegotiation: DISPLAY_MANDATORY_NO_AUTO_NEGOTIATION,
        vlanTagging: VLAN_TAGGING_AVAILABLE,
      };
    } else {
      return {
        connectorType: FIBRES_ONLY,
        mediaType: REACH_FIBRE_ONLY,
        autoNegotiation: AUTO_NEGOTIATION_AVAILABLE,
        vlanTagging: VLAN_TAGGING_AVAILABLE,
      };
    }
  }
};
