import { AccessNotice, AsbestosRegister, ConnectorType, Duplexity, MediaType, PowerType } from './location';
import { BillingFrequency } from './orderRecord';
import { ResourceTypes } from 'Request';
import { migrateTemplateBody } from 'Order/crud/orderTemplateMapper';

export type APITemplateBody = IOrderTemplateState | IOrderTemplateStateV1;

export interface APIOrderTemplate {
  id: string;
  type: ResourceTypes.order_template;
  attributes: {
    title: string;
    original_order_id: string;
    /** This is supposed to store a JSON */
    template_body: APITemplateBody;
    created_at: string;
    created_by: string;
    last_updated_at: string;
    last_updated_by: string;
    customer_id: string;
    is_default: boolean;
  };
}

export interface SetDefaultBody {
  id: APIOrderTemplate['id'];
  type: APIOrderTemplate['type'];
  attributes: {
    is_default: APIOrderTemplate['attributes']['is_default'];
  };
}

export interface IOrderTemplate {
  id: string;
  type: ResourceTypes.order_template;
  attributes: {
    title: string;
    originalOrderId: string;
    templateBody: IOrderTemplateState;
    createdAt: string;
    createdBy: string;
    lastUpdatedAt: string;
    lastUpdatedBy: string;
    customerId: string;
    isDefault: boolean;
  };
}

export interface IOrderTemplateState {
  orderContact: OrderContact;
  operationalContact: OperationalContact;
  billingContactAndAddress: BillingContactAndAddress;
  ip: Ip;
  billingFrequency?: BillingFrequency | null;

  locationA?: Location;
  locationB?: Location;
}

export interface IOrderTemplateStateV1 {
  orderContact: OrderContact;
  operationalContact: OperationalContact;
  billingContactAndAddress: BillingContactAndAddress;
  ipContact: IPContact;
  billingFrequency?: BillingFrequency | null;

  locationA?: Location;
  locationB?: Location;
}

interface OrderContact {
  firstName: string;
  surname: string;
  phoneNumber: string;
  email: string;
  address?: string;
}

interface OperationalContact {
  firstName: string;
  surname: string;
  phoneNumber: string;
  email: string;
  address?: string;
}

interface BillingContactAndAddress {
  purchaseOrderRef: string;

  /** @deprecated */
  firstName?: string;
  /** @deprecated */
  surname?: string;
  /** @deprecated */
  phoneNumber?: string;
  /** @deprecated */
  email?: string;
  /** @deprecated */
  address?: string;
}

interface Ip {
  justification: string;
  contact: IPContact;
}

interface IPContact {
  address: string;
  email: string;
  firstName: string;
  surname: string;
  nic_ripe_handle: string;
  organisation: string;
  telephone: string;
}

interface Location {
  siteConfig: {
    floor: string;
    room: string | null;
    suite: string;
    rackId: string | null;
    connectorType: ConnectorType | null;
    mediaType: MediaType | null;
    powerType: PowerType | null;
    duplexity: Duplexity;
    autoNegotiation: boolean | undefined;
  };
  siteContact: {
    firstName: string;
    surname: string;
    phone: string;
    email: string;
    notes: string;
    [propName: string]: string;
  };
  siteReadiness: {
    accessNotice: AccessNotice;
    moreThanOneTenant: boolean;
    asbestosRegister: AsbestosRegister;
    hazardsOnSite: boolean;
    hazardsOnSiteDescription?: string | null;
    landOwnerPermissionRequired: boolean;
  };
}

export const mapOrderTemplateToAPI = (orderTemplate: IOrderTemplate): APIOrderTemplate => ({
  id: orderTemplate.id,
  type: orderTemplate.type,
  attributes: {
    title: orderTemplate.attributes?.title,
    original_order_id: orderTemplate.attributes?.originalOrderId,
    template_body: orderTemplate.attributes?.templateBody,
    created_at: orderTemplate.attributes?.createdAt,
    created_by: orderTemplate.attributes?.createdBy,
    last_updated_at: orderTemplate.attributes?.lastUpdatedAt,
    last_updated_by: orderTemplate.attributes?.lastUpdatedBy,
    customer_id: orderTemplate.attributes?.customerId,
    is_default: orderTemplate.attributes?.isDefault,
  },
});

export const mapOrderTemplateFromAPI = (orderTemplate: APIOrderTemplate): IOrderTemplate => ({
  id: orderTemplate.id,
  type: ResourceTypes.order_template,
  attributes: {
    title: orderTemplate.attributes?.title,
    originalOrderId: orderTemplate.attributes?.original_order_id,
    templateBody: migrateTemplateBody(orderTemplate.attributes?.template_body),
    createdAt: orderTemplate.attributes?.created_at,
    createdBy: orderTemplate.attributes?.created_by,
    lastUpdatedAt: orderTemplate.attributes?.last_updated_at,
    lastUpdatedBy: orderTemplate.attributes?.last_updated_by,
    customerId: orderTemplate.attributes?.customer_id,
    isDefault: orderTemplate.attributes?.is_default,
  },
});
