import { sharedQuoteOrderTableHeaders } from '../../../../shared/utils/shared-quote-order-table-headers';
import TableNames from '../../../../Tables/types/tableNames';
import { TableHeaderProps } from 'react-table';

const shared = sharedQuoteOrderTableHeaders((row) => row.quote);
const tableName = TableNames.ordersTable;

const headers: TableHeaderProps[] = [
  shared.orderId(tableName, 'orderId'),
  shared.quoteId('quote_id', tableName, 'quoteId'),
  shared.customer(tableName, 'customer'),
  shared.product(tableName, 'product'),
  shared.accessTypeForOrder(tableName, 'accessType'),
  shared.locations(tableName, 'location'),
  shared.accessProvider(tableName, 'accessProvider'),
  shared.updatedAt(tableName, 'updatedAt'),
  shared.modifiedBy(tableName, 'updatedBy'),
  shared.orderStatus(tableName, 'status'),
];

export default headers;
