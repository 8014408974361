import React, { FunctionComponent } from 'react';
import { IContact } from '../../../types/contact';
import Edit from './Edit';
import Summary from './Summary';
import { IOrder } from '../../../types/store';
import { ContactIdentifierType } from '../../shared/components/ExistingContactOptions';
import { OrderStatus } from '../../../types/orderRecord';

export interface IContactProps {
  contact: IContact;
  contactIdentifier: ContactIdentifierType;
  canEdit: boolean;
  description: string;
  order: IOrder;
  title: string;
  onChange(updated: IContact): void;
}

const Contact: FunctionComponent<React.PropsWithChildren<IContactProps>> = (props) => {
  if (props.contactIdentifier === ContactIdentifierType.serviceDeliveryContact) {
    if (props.canEdit) {
      return null;
    } else if (![OrderStatus.CANCELLED, OrderStatus.PLACED].includes(props.order.state)) {
      return null;
    } else if (
      props.order.billingContactAndAddress.firstName === '' &&
      props.order.billingContactAndAddress.surname === '' &&
      props.order.billingContactAndAddress.email === '' &&
      props.order.billingContactAndAddress.phoneNumber === '' &&
      props.order.billingContactAndAddress.address === ''
    ) {
      return null;
    }
  }

  return props.canEdit ? <Edit {...props} /> : <Summary {...props} />;
};

export { Contact };
