import React, { FC } from 'react';
import styled from 'styled-components';
import Checkbox from 'shared/components/atoms/Checkbox';
import { IQuote } from 'Quotes/types/store';
import { MAX_SELECTABLE_BANDWIDTHS } from 'Quotes/reducer';
import { Badge } from 'shared/components/atoms/Badge/Badge';
import { Option, OptionGroup } from 'shared/components/organisms/OptionGroup/OptionGroup';
import { canToggle } from 'Quotes/QuoteBuilder/utils/canToggle';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import {
  opticalBandwidthTypes,
  POPULAR_BANDWIDTHS,
} from 'Quotes/QuoteBuilder/components/Configure/Bandwidth/bandwidthTypes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  align-items: flex-start;
`;

type Bandwidth = IQuote['chosen_bandwidths'][0];

interface Props {
  bandwidths: IQuote['chosen_bandwidths'];
  chosenBandwidths: IQuote['chosen_bandwidths'];
  onToggleBandwidth(bandwidth: Bandwidth): void;
  popularBandwidths?: string[];
}

const bandwidthsTooFullForPopularSelection = (availablePopularBandwidths: string[], chosenBandwidths: string[]) =>
  new Set([...availablePopularBandwidths, ...chosenBandwidths]).size > MAX_SELECTABLE_BANDWIDTHS;

export const PopularBandwidths: FC<React.PropsWithChildren<Props>> = ({
  bandwidths,
  chosenBandwidths,
  onToggleBandwidth,
  popularBandwidths = POPULAR_BANDWIDTHS,
}) => {
  const toggleBandwidth = (bandwidth: Bandwidth) => onToggleBandwidth(bandwidth);

  const availablePopularBandwidths = bandwidths.filter((bandwidth) => popularBandwidths.includes(bandwidth));

  const allPopularBandwidthsSelected = availablePopularBandwidths.every((bandwidth) =>
    chosenBandwidths.includes(bandwidth)
  );

  const tooFullForPopularSelection = bandwidthsTooFullForPopularSelection(availablePopularBandwidths, chosenBandwidths);

  return (
    <Container>
      <div data-testid="popular_bandwidth_checkbox_wrapper">
        <Checkbox
          data-testId="most_popular_checkbox"
          value={allPopularBandwidthsSelected}
          label="Select all popular bandwidths"
          disabled={!allPopularBandwidthsSelected && tooFullForPopularSelection}
          onChange={() => {
            if (allPopularBandwidthsSelected) {
              availablePopularBandwidths.forEach(toggleBandwidth);
            } else {
              availablePopularBandwidths
                .filter((bandwidth) => !chosenBandwidths.includes(bandwidth))
                .forEach(toggleBandwidth);
            }
          }}
        />
      </div>

      <NumberOfBandwidthsSelectedBadge
        bandwidthCount={bandwidths.length}
        selectedBandwidthCount={chosenBandwidths.length}
      />

      <PopularBandwidthOptions
        popularBandwidths={popularBandwidths}
        availablePopularBandwidths={availablePopularBandwidths}
        chosenBandwidths={chosenBandwidths}
        onClick={(value) => {
          onToggleBandwidth(value);
        }}
      />
    </Container>
  );
};

function NumberOfBandwidthsSelectedBadge({
  bandwidthCount,
  selectedBandwidthCount,
}: {
  bandwidthCount: number;
  selectedBandwidthCount: number;
}) {
  if (bandwidthCount <= MAX_SELECTABLE_BANDWIDTHS) return null;

  return (
    <Badge
      data-testid="selected_bandwidth_count"
      variant={selectedBandwidthCount === MAX_SELECTABLE_BANDWIDTHS ? 'warning' : null}
    >
      <strong>
        {selectedBandwidthCount} of {MAX_SELECTABLE_BANDWIDTHS}
      </strong>{' '}
      max bandwidths selected
    </Badge>
  );
}

const toOptions = (
  popularBandwidths: string[],
  availablePopularBandwidths: string[],
  chosenBandwidths: string[]
): Option[] =>
  popularBandwidths.map((bandwidth) => {
    const isUnavailable = !availablePopularBandwidths.includes(bandwidth);
    const isFull = !canToggle(bandwidth, chosenBandwidths, MAX_SELECTABLE_BANDWIDTHS);
    const isOptical =
      opticalBandwidthTypes.MEDIUM.includes(bandwidth) || opticalBandwidthTypes.LARGE.includes(bandwidth);
    return {
      label: formatBandwidth(bandwidth),
      value: bandwidth,
      customId: `bandwidth--${bandwidth}`,
      tooltip: isUnavailable
        ? 'This option is unavailable based on your current selections'
        : isFull
        ? `You can only select up to ${MAX_SELECTABLE_BANDWIDTHS} bandwidths`
        : isOptical
        ? `${formatBandwidth(bandwidth)} bandwidth circuits will have a ${formatBandwidth(bandwidth)} bearer`
        : undefined,
      disabled: isUnavailable || isFull,
    };
  });

function PopularBandwidthOptions(props: {
  popularBandwidths: string[];
  availablePopularBandwidths: string[];
  chosenBandwidths: string[];
  onClick: (value: Bandwidth) => void;
}) {
  const bandwidthOptions = toOptions(props.popularBandwidths, props.availablePopularBandwidths, props.chosenBandwidths);
  return <OptionGroup multiple options={bandwidthOptions} value={props.chosenBandwidths} onClick={props.onClick} />;
}
