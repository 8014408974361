import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

interface ICheckbox {
  className?: string;
  label?: string;
  inputId?: string;
  disabled?: boolean;
  value: boolean | undefined | null;
  onChange(checked: boolean): void;
  ['data-testId']?: string;
}

const Checkbox: FunctionComponent<React.PropsWithChildren<ICheckbox>> = ({
  className,
  onChange,
  value,
  label,
  inputId,
  disabled,
  ...props
}) => {
  const checked = value || false;
  return (
    <div className={className} data-testid={props['data-testId']}>
      <label className={cx('checkbox', { disabled: disabled, checked: checked })}>
        <input
          type="checkbox"
          id={inputId}
          data-testid={inputId}
          disabled={disabled}
          checked={checked}
          onChange={() => onChange(!value)}
        />
        <span className={cx('overlay', { checked: checked })}>
          <svg
            width="35"
            height="35"
            viewBox="0 5 45 35"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
        </span>
        <span className="checkbox__label">{label}</span>
      </label>
    </div>
  );
};

const StyledCheckbox = styled(Checkbox)`
  .checkbox {
    position: relative;
    display: flex;
    align-items: center;

    & input {
      position: absolute;
      opacity: 0;
    }

    & .overlay {
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: transparent;
      border-radius: 8px;
      border: 2px solid ${(props) => props.theme.colours.primaryB1};
      transition: all ${(props) => props.theme.defaultTransition};

      & .icon {
        color: ${(props) => props.theme.colours.primaryB1};
        display: none;
      }

      &.checked .icon {
        display: block;
        width: 30px;
        margin-left: 2px;
      }
    }

    & .checkbox__label {
      margin-left: 0.8rem;
    }

    &.checked .checkbox__label {
      color: ${(props) => props.theme.colours.primaryB1};
    }

    &.disabled {
      & .overlay {
        background-color: #eee;
        border-color: #bbb;
      }

      & .checkbox__label {
        color: ${(props) => props.theme.colours.grey30};
      }
    }
  }
}
`;

export default StyledCheckbox;
