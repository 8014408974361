import { IUserRoleFromBackend } from 'User/types/role';
import canAccessUserManagement from 'shared/utils/permissionForUserManagement';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { userList } from 'Admin/routes';

export const BACK_TO_USER_MANAGEMENT = 'Back to user management';

const BackToUserManagementButton = ({ isLoading }: { isLoading: boolean }) => {
  const navigate = useNavigate();
  return (
    <Button
      mainStyle={ButtonMainStyle.Link}
      onClick={() => navigate(userList)}
      className="back-btn"
      disabled={isLoading}
    >
      {BACK_TO_USER_MANAGEMENT}
    </Button>
  );
};

export const BackToUserManagement = ({
  allRoles,
  isLoading,
}: {
  allRoles: IUserRoleFromBackend[];
  isLoading: boolean;
}) => {
  if (canAccessUserManagement(allRoles)) return <BackToUserManagementButton isLoading={isLoading} />;
  else return null;
};
