import { ISiteConfig } from 'Order/types/location';
import Column from 'shared/components/atoms/Column';
import TextInput from 'shared/components/atoms/TextInput';
import React from 'react';

interface INNIInputFieldsProps {
  identifierPrefix: string;
  siteConfig: ISiteConfig;
  onChange: (field: keyof ISiteConfig, value: any) => void;
}

export const NNIInputFields: React.FC<INNIInputFieldsProps> = ({ identifierPrefix, siteConfig, onChange }) => (
  <div className="row">
    <Column defaultWidth={6}>
      <TextInput
        inputIdentifierPrefix={`${identifierPrefix}`}
        fieldName="_nni_job_reference"
        onChange={(e) => onChange('nni_job_reference', e.target.value)}
        labelText="NNI Job Reference"
        value={siteConfig.nni_job_reference || ''}
        optional
      />
    </Column>
    <Column defaultWidth={6}>
      <TextInput
        inputIdentifierPrefix={`${identifierPrefix}`}
        fieldName="_nni_idn_number"
        onChange={(e) => onChange('nni_idn_number', e.target.value)}
        labelText="IDN"
        value={siteConfig.nni_idn_number || ''}
        optional
      />
    </Column>
  </div>
);
