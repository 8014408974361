import { ICurrentlyViewedUserAttributes, UserState } from '../User/types/user';
import produce from 'immer';
import { AdminActions, AdminActionTypes } from './types/actions';
import { RequestState } from './types';
import { IErrorResponse, parseErrorResponse } from 'Request/utils/parseErrorResponse';
import { determineBackendUserStatus } from 'User/utils/determineUserStatus';

export interface IAdminState {
  addUserRequestError: IErrorResponse;
  addUserRequestState: RequestState;
  currentlyViewedUser: ICurrentlyViewedUserAttributes;
  setCurrentlyViewedUserRequestState: RequestState;
  updateUserRequestState: RequestState;
  sendInviteRequestState: RequestState;
  blockUserRequestState: RequestState;
  unblockUserRequestState: RequestState;
  editMode?: boolean;
}

export const initialState: IAdminState = {
  currentlyViewedUser: {
    companyEmail: '',
    jobTitle: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    landlinePhone: '',
    contactEmail: '',
    roles: [],
    lastVerificationEmailSent: '',
    id: '',
    updatedAt: '',
    createdAt: '',
    type: '',
    lastLoggedIn: '',
    enabled: true,
    status: UserState.Active,
    unreadAlerts: 0,
    unreadMessages: 0,
    wildcardRoles: [],
  },
  addUserRequestError: null,
  addUserRequestState: RequestState.NOT_REQUESTED,
  updateUserRequestState: RequestState.NOT_REQUESTED,
  setCurrentlyViewedUserRequestState: RequestState.NOT_REQUESTED,
  sendInviteRequestState: RequestState.NOT_REQUESTED,
  blockUserRequestState: RequestState.NOT_REQUESTED,
  unblockUserRequestState: RequestState.NOT_REQUESTED,
  editMode: false,
};

const resetRequestState = (draft: IAdminState) => {
  draft.sendInviteRequestState = RequestState.NOT_REQUESTED;
  draft.blockUserRequestState = RequestState.NOT_REQUESTED;
  draft.unblockUserRequestState = RequestState.NOT_REQUESTED;
};

const reducer = produce((draft: IAdminState, action: AdminActions): IAdminState | void => {
  switch (action.type) {
    case AdminActionTypes.ADD_USER_START: {
      draft.addUserRequestState = RequestState.LOADING;
      break;
    }

    case AdminActionTypes.ADD_USER_SUCCESS: {
      draft.addUserRequestState = RequestState.SUCCESS;
      break;
    }

    case AdminActionTypes.ADD_USER_ERROR: {
      draft.addUserRequestState = RequestState.ERROR;
      draft.addUserRequestError = parseErrorResponse(action.payload.error);
      break;
    }

    case AdminActionTypes.ADD_USER_SET_REQUEST_STATE: {
      draft.addUserRequestState = action.payload.state;
      break;
    }

    case AdminActionTypes.SET_CURRENTLY_VIEWED_USER: {
      const {
        user: { attributes, type, id },
      } = action.payload;

      draft.currentlyViewedUser.companyEmail = attributes.username;
      draft.currentlyViewedUser.firstName = attributes.first_name;
      draft.currentlyViewedUser.jobTitle = attributes.job_title || '';
      draft.currentlyViewedUser.lastName = attributes.last_name;
      draft.currentlyViewedUser.mobilePhone = attributes.mobile_number;
      draft.currentlyViewedUser.landlinePhone = attributes.landline_number || '';
      draft.currentlyViewedUser.contactEmail = attributes.email;
      draft.currentlyViewedUser.lastVerificationEmailSent = attributes.last_verification_email_sent || '';
      draft.currentlyViewedUser.enabled = attributes.enabled;
      draft.currentlyViewedUser.roles = attributes.roles;
      draft.currentlyViewedUser.updatedAt = attributes.updated_at;
      draft.currentlyViewedUser.createdAt = attributes.created_at;
      draft.currentlyViewedUser.lastLoggedIn = attributes.last_logged_in;
      draft.currentlyViewedUser.unreadAlerts = attributes.unread_events || 0;
      draft.currentlyViewedUser.type = type;
      draft.currentlyViewedUser.id = id;
      draft.currentlyViewedUser.status = determineBackendUserStatus(attributes);
      break;
    }

    case AdminActionTypes.GET_USER_START: {
      draft.setCurrentlyViewedUserRequestState = RequestState.LOADING;
      break;
    }

    case AdminActionTypes.GET_USER_SUCCESS: {
      draft.setCurrentlyViewedUserRequestState = RequestState.SUCCESS;
      break;
    }

    case AdminActionTypes.GET_USER_ERROR: {
      draft.setCurrentlyViewedUserRequestState = RequestState.ERROR;
      break;
    }

    case AdminActionTypes.UPDATE_USER_START: {
      draft.updateUserRequestState = RequestState.LOADING;
      break;
    }

    case AdminActionTypes.UPDATE_USER_SUCCESS: {
      draft.updateUserRequestState = RequestState.SUCCESS;
      break;
    }

    case AdminActionTypes.UPDATE_USER_ERROR: {
      draft.updateUserRequestState = RequestState.ERROR;
      break;
    }

    case AdminActionTypes.EDIT_USER_STATE: {
      draft.editMode = true;
      break;
    }

    case AdminActionTypes.RESET_UPDATE_USER_REQUEST_STATE:
      draft.updateUserRequestState = RequestState.NOT_REQUESTED;
      break;

    case AdminActionTypes.RESET_CURRENTLY_VIEWED_USER:
      draft.currentlyViewedUser = initialState.currentlyViewedUser;
      draft.setCurrentlyViewedUserRequestState = RequestState.NOT_REQUESTED;
      resetRequestState(draft);
      break;

    case AdminActionTypes.RESET_REQUESTS_STATE:
      resetRequestState(draft);
      break;

    case AdminActionTypes.SEND_INVITE_START: {
      draft.sendInviteRequestState = RequestState.LOADING;
      break;
    }

    case AdminActionTypes.SEND_INVITE_SUCCESS: {
      draft.sendInviteRequestState = RequestState.SUCCESS;
      if (draft.currentlyViewedUser.status === UserState.InviteNotSent) {
        draft.currentlyViewedUser.status = UserState.InviteSent;
      }
      break;
    }

    case AdminActionTypes.SEND_INVITE_ERROR: {
      draft.sendInviteRequestState = RequestState.ERROR;
      break;
    }

    case AdminActionTypes.BLOCK_USER_START: {
      draft.blockUserRequestState = RequestState.LOADING;
      break;
    }

    case AdminActionTypes.BLOCK_USER_ERROR: {
      draft.blockUserRequestState = RequestState.ERROR;
      break;
    }

    case AdminActionTypes.BLOCK_USER_SUCCESS: {
      draft.blockUserRequestState = RequestState.SUCCESS;
      break;
    }

    case AdminActionTypes.UNBLOCK_USER_SUCCESS: {
      draft.unblockUserRequestState = RequestState.SUCCESS;
      break;
    }

    case AdminActionTypes.UNBLOCK_USER_START: {
      draft.unblockUserRequestState = RequestState.LOADING;
      break;
    }

    case AdminActionTypes.UNBLOCK_USER_ERROR: {
      draft.unblockUserRequestState = RequestState.ERROR;
      break;
    }

    default:
      return draft;
  }
}, initialState);

export default reducer;
