export enum BackendRoleName {
  Admin = 'role::customer_admin',
  Quoter = 'role::quoter',
  Orderer = 'role::orderer',
  SSEAdmin = 'role::sse_admin',
  SSEAccountManager = 'role::sse_account_manager',
  SSEPricing = 'role::sse_pricing',
}

export interface IUserRoleForCreation {
  customer_id: string;
  role: BackendRoleName;
}

export interface IUserRoleFromBackend extends IUserRoleForCreation {
  customer_name: string;
}

export enum Role {
  Admin = 'Admin',
  Quoter = 'Quoter',
  Orderer = 'Orderer',
  SSEAdmin = 'SSEAdmin',
  SSEAccountManager = 'SSEAccountManager',
  SSEPricing = 'SSEPricing',
}

export const backendRoleNameToRoleMapping: {
  [key in BackendRoleName]: Role;
} = {
  [BackendRoleName.Admin]: Role.Admin,
  [BackendRoleName.Quoter]: Role.Quoter,
  [BackendRoleName.Orderer]: Role.Orderer,
  [BackendRoleName.SSEAdmin]: Role.SSEAdmin,
  [BackendRoleName.SSEAccountManager]: Role.SSEAccountManager,
  [BackendRoleName.SSEPricing]: Role.SSEPricing,
};

export const roles: Record<Role, { title: string; examples: string[]; backendRoleName: BackendRoleName }> = {
  [Role.Admin]: {
    title: 'Administrator',
    examples: ['Manage users & roles', 'Create & view quotes', 'Edit & view orders', 'Can approve and place orders'],
    backendRoleName: BackendRoleName.Admin,
  },
  [Role.Quoter]: {
    title: 'Quoter',
    examples: ['Create & view quotes', 'Edit & view orders'],
    backendRoleName: BackendRoleName.Quoter,
  },
  [Role.Orderer]: {
    title: 'Orderer',
    examples: ['Create & view quotes', 'Create & view orders', 'Can approve and place orders'],
    backendRoleName: BackendRoleName.Orderer,
  },
  [Role.SSEAdmin]: {
    title: 'Neos Networks Admin',
    examples: ['Global Administrator Access'],
    backendRoleName: BackendRoleName.SSEAdmin,
  },
  [Role.SSEAccountManager]: {
    title: 'Neos Networks Account Manager',
    examples: ['Internal account for managing customers accounts'],
    backendRoleName: BackendRoleName.SSEAccountManager,
  },
  [Role.SSEPricing]: {
    title: 'Neos Networks Pricing',
    examples: ['Global administrator access, plus the ability to modify pricing tables.'],
    backendRoleName: BackendRoleName.SSEPricing,
  },
};
