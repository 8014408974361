import { doRequest, Methods } from '../../../Request';

export enum OrderSubmitType {
  REVIEW = 'review',
  PLACE = 'place',
  RETURN = 'return',
}

const submitOrder = (submitType: OrderSubmitType, orderId: string) => {
  return doRequest({
    method: Methods.POST,
    path: `/orders/${orderId}/${submitType}`,
  });
};

export default submitOrder;
