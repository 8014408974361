import cx from 'classnames';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import AvatarSVG from '../SVG/AvatarSVG';

interface IAvatar {
  className?: string;
  image?: string;
  size?: 'small' | 'medium' | 'large';
}

const Avatar: FunctionComponent<React.PropsWithChildren<IAvatar>> = ({ className, image, size = 'small' }) => (
  <div className={cx(className, size)}>{image ? <img alt="avatar" src={image} /> : <AvatarSVG />}</div>
);

const StyledAvatar = styled(Avatar)`
  overflow: hidden;
  svg,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.small {
    width: 28px;
    height: 28px;
    border-radius: 28px;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &.medium {
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }

  &.large {
    width: 90px;
    height: 90px;
    border-radius: 90px;
  }
`;

export default StyledAvatar;
