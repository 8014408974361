import React from 'react';
import { Charges } from './TillReceipt';
import { IAEndLocation } from 'Quotes/types/store';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { ProductType } from 'Quotes/types/productTypes';
import AnnualChargesTotal from './AnnualChargesTotal';
import AnnualChargesExtra from './AnnualChargesExtra';
import { AnnualExtras } from 'Quotes/QuoteBuilder/utils/costsUtilities';
import { IMDIAPrices } from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/UpfrontCosts';

interface AnnualChargesProps {
  charges: Charges;
  includeFTTPAggregationCharge: boolean;
  productType: ProductType | null | undefined;
  ipType?: IPType;
  isManagedDIA: boolean;
  diaIPAllocation: IAEndLocation['dia_ip_allocation'];
  engineerInstallationRequired?: boolean;
  priceAmortised: boolean;
  annualCharges: AnnualExtras;
  mdiaPrices: IMDIAPrices | undefined;
}

const AnnualCharges: React.FC<AnnualChargesProps> = ({
  includeFTTPAggregationCharge,
  productType,
  mdiaPrices,
  ipType,
  isManagedDIA,
  diaIPAllocation,
  charges,
  priceAmortised,
  annualCharges,
}) => {
  return (
    <>
      <AnnualChargesTotal priceAmortised={priceAmortised} charges={charges} />
      <AnnualChargesExtra
        includeFTTPAggregationCharge={includeFTTPAggregationCharge}
        productType={productType}
        mdiaPrices={mdiaPrices}
        ipType={ipType}
        isManagedDIA={isManagedDIA}
        diaIPAllocation={diaIPAllocation}
        priceAmortised={priceAmortised}
        annualCharges={annualCharges}
      />
    </>
  );
};

export default AnnualCharges;
