import React, { useState, FunctionComponent, useEffect } from 'react';
import Button, { ButtonMainStyle } from '../../../../shared/components/atoms/Button';
import styled from 'styled-components';
import UploadFileView from './UploadFileView';
import SelectedFileView from './SelectedFileView';
import { connect, DispatchProp } from 'react-redux';
import { submitBulkQuote, resetBulkQuoteSubmitState } from '../../../actions';
import { IBulkQuoteUploadState, UploadState } from '../../../types/store';
import { IAppState } from '../../../../reducers';
import { fileHasRequiredExtension } from '../../../../shared/utils/fileHasRequiredFormat';
import { pages, trackPage, useTrackPage } from '../../../../shared/utils/trackPage';

const REQUIRED_EXTENSION = 'csv';

interface IBulkUploadSidePanel {
  className?: string;
  theme: {
    colours: {
      grey60: string;
    };
  };
  onClose(): void;
  onSubmitBulkQuote(selectedFile: File): void;
  onResetBulkQuoteSubmitState(): void;
  bulkQuoteUploadState: IBulkQuoteUploadState;
}

const BulkUploadSidePanel: FunctionComponent<React.PropsWithChildren<IBulkUploadSidePanel>> = ({
  className,
  onClose,
  onSubmitBulkQuote,
  bulkQuoteUploadState,
  onResetBulkQuoteSubmitState,
}) => {
  useTrackPage(pages.uploadBulkQuote);
  const [bulkQuoteFile, setBulkQuoteFile] = useState<File | null>(null);
  const hasFile = bulkQuoteFile !== null;
  const validFileFormat = hasFile && fileHasRequiredExtension(bulkQuoteFile!.name, REQUIRED_EXTENSION);
  const invalidFileFormat = hasFile && !validFileFormat;

  useEffect(() => {
    if (validFileFormat) {
      trackPage(pages.uploadBulkQuoteSelected);
    }
  }, [validFileFormat]);

  useEffect(() => {
    if (bulkQuoteUploadState === UploadState.SUCCESSFUL) {
      trackPage(pages.uploadBulkQuoteSuccess);
    } else if (bulkQuoteUploadState[0] === UploadState.ERRORED) {
      trackPage(pages.uploadBulkQuoteError);
    }
  }, [bulkQuoteUploadState]);

  const showSelected = hasFile && validFileFormat;
  const canUpload = !hasFile || invalidFileFormat || bulkQuoteUploadState[0] === UploadState.ERRORED;
  const triedToUploadInvalidFile = invalidFileFormat;

  const onUploadFile = (files: FileList) => {
    onResetBulkQuoteSubmitState();
    setBulkQuoteFile(files[0]);
  };

  const onDeleteFile = () => {
    setBulkQuoteFile(null);
  };

  return (
    <div className={`bulk-upload-side-panel ${className} bg-white px-3 py-4`}>
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">Upload bulk quote</h4>
        <Button id="bulk-quote-close-button" onClick={onClose} mainStyle={ButtonMainStyle.Link}>
          Close
        </Button>
      </div>
      <hr></hr>
      {canUpload && <UploadFileView onUploadFile={onUploadFile} showWrongFormatMessage={triedToUploadInvalidFile} />}
      {showSelected && (
        <SelectedFileView
          bulkQuoteUploadState={bulkQuoteUploadState}
          fileName={bulkQuoteFile!.name}
          onDelete={onDeleteFile}
          onSubmitBulkQuote={() => onSubmitBulkQuote(bulkQuoteFile!)}
        />
      )}
    </div>
  );
};

const StyledBulkUploadSidePanel = styled(BulkUploadSidePanel)`
  box-shadow: 0 0rem 0.5rem 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
  width: 30em;
  z-index: 1030;

  #bulk-quote-close-button {
    color: ${(props) => props.theme.colours.grey60};
  }
`;

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  onSubmitBulkQuote: (selectedFile: File) => dispatch(submitBulkQuote(selectedFile)),
  onResetBulkQuoteSubmitState: () => dispatch(resetBulkQuoteSubmitState()),
});

const mapStateToProps = ({ quoteBuilder: { bulkQuoteUploadState } }: IAppState) => ({
  bulkQuoteUploadState,
});

export const ConnectedBulkUploadSidePanel = connect(mapStateToProps, mapDispatchToProps)(StyledBulkUploadSidePanel);

export default StyledBulkUploadSidePanel;
