import React from 'react';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import Icon from 'shared/components/atoms/Icon';
import Column from 'shared/components/atoms/Column';

export const IncompatibleFTTXLocation = () => {
  return (
    <Alert alertType={Alerts.INFO}>
      <div className="row no-gutters">
        <Column defaultWidth={1} classNames={['text-right', 'pt-4']}>
          <Icon name="info_outline" />
        </Column>
        <Column defaultWidth={11}>
          <p className="pl-2 pt-3">
            We don&apos;t have an address key for the selected location. Without one, we&apos;re unable to check
            EoFTTC/P availability. Speak with your account manager if you wish to get a quote for EoFTTC/P at this
            location.
          </p>
        </Column>
      </div>
    </Alert>
  );
};
