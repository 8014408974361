import React from 'react';
import { connect } from 'react-redux';
import { Role } from '../User/types/role';
import { anyRolesMatch } from '../User/utils/roles';
import { IAppState } from '../reducers';

export const AllowEditingForRolesUnconnected = ({
  roles,
  children,
  userRoles,
}: {
  roles: Role[];
  children?: React.ReactNode;
  userRoles: Role[];
}): JSX.Element => {
  if (!anyRolesMatch(roles, userRoles)) {
    return <fieldset disabled={true}>{children}</fieldset>;
  }
  return <>{children}</>;
};

const mapStateToProps = ({
  user: {
    userData: { roles },
  },
}: IAppState) => ({ userRoles: roles });

export default connect(mapStateToProps)(AllowEditingForRolesUnconnected);
