import { CerillionQuoteRecord } from 'Quotes/types/cerillionQuoteRecord';

const tableName = 'cerillion_quotes_table';

export const cerillionQuotesHeaders = [
  {
    Header: 'Basket ID',
    accessor: 'id',
    id: 'basket_id',
    disableSortBy: true,
    disableFilters: true,
    tableName,
  },
  {
    Header: 'Basket Type',
    accessor: (row: CerillionQuoteRecord) => row.attributes.basket_type,
    id: 'basket_type',
    disableSortBy: true,
    disableFilters: true,
    tableName,
  },
];
