import React from 'react';
import { Link } from 'react-router-dom';

import Button, { ButtonMainStyle, IButtonProps } from '../Button/index';

interface ILinkButtonProps extends IButtonProps {
  pathname: string;
  buttonText: string;
}

const LinkButton = ({
  buttonText,
  className,
  disabled = false,
  id = '',
  loading,
  saving,
  onClick,
  pathname,
  type = 'button',
  mainStyle = ButtonMainStyle.Primary,
  subStyle,
}: ILinkButtonProps) => (
  <Link to={pathname}>
    <Button
      className={className}
      id={id}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      mainStyle={mainStyle}
      saving={saving}
      subStyle={subStyle}
      type={type}
    >
      {buttonText}
    </Button>
  </Link>
);

export default LinkButton;
