import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import SlickSlider from 'react-slick';
import cx from 'classnames';
import styled from 'styled-components';
import { getFirstSlideOnPageForIndex } from 'Quotes/utils/sliderHelpers';
import { IPriceData } from 'Quotes/types/store';
import { IDummyPrice } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';

interface Slider {
  prices: (IPriceData | IDummyPrice)[];
  className?: string;
  readonly: boolean;
  slidesToShow: number;
  slidesToScroll: number;
  children: React.ReactNode[];
  selectedPriceId: string | undefined;
}

function selectedPriceIndex(priceDatas: (IPriceData | IDummyPrice)[], selectedPriceId: string | null): number {
  if (!selectedPriceId) {
    return 0;
  }

  return priceDatas.findIndex((priceData) => priceData.id === selectedPriceId);
}

const Slider: FunctionComponent<React.PropsWithChildren<Slider>> = ({
  prices,
  selectedPriceId,
  className,
  children,
  readonly,
  slidesToShow,
  slidesToScroll,
}) => {
  const [lastChildren, setLastChildren] = useState<React.ReactNode[]>(children);
  const viewAs = 'slider';
  const [initialSelectedPrice] = useState(selectedPriceId);

  const sliderRef = useRef<SlickSlider>(null);

  useEffect(() => {
    const slides = sliderRef.current?.props?.children;
    if (!Array.isArray(slides) || slides.length === 0) return;
    if (!initialSelectedPrice) return;

    const index = selectedPriceIndex(prices, initialSelectedPrice);

    if (index >= slidesToShow) {
      const slideIndex = getFirstSlideOnPageForIndex(index, slidesToShow);
      sliderRef.current?.slickGoTo(slideIndex, true);
    }
  }, [initialSelectedPrice, prices, slidesToShow, viewAs]);

  useEffect(() => {
    if (lastChildren.length === 0 && children.length > 0) {
      sliderRef?.current?.slickGoTo(0, true);
    }
    setLastChildren(children);
  }, [children, lastChildren.length]);

  return (
    <div className={className}>
      <SlickSlider
        className={cx({ readonly })}
        ref={sliderRef}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        infinite={false}
        speed={200}
      >
        {children}
      </SlickSlider>
    </div>
  );
};

const StyledSlider = styled(Slider)`
  .slick-slide {
    padding: 0.3em;
  }

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-slider.readonly {
    opacity: 0.7;

    div {
      outline: none;
    }
  }

  .slick-list {
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    outline: none;
  }

  .slick-prev {
    left: -29px;
    z-index: 2;
  }

  .slick-next {
    right: -29px;
    z-index: 2;
  }

  .slick-next,
  .slick-prev {
    background-size: cover;
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
  }

  .slick-next.slick-disabled {
    background-image: url('/images/utils/pagination-right-disabled.png');
  }

  .slick-prev.slick-disabled {
    background-image: url('/images/utils/pagination-left-disabled.png');
  }

  .slick-prev {
    background-image: url('/images/utils/pagination-left.png');
  }

  .slick-next {
    background-image: url('/images/utils/pagination-right.png');
  }
`;

export { StyledSlider as Slider };
