import React from 'react';

export const GenericErrorMessage = () => {
  return (
    <>
      <strong>Something went wrong.</strong>
      <br />
      Please try again later.
    </>
  );
};
