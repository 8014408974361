import { Supplier } from 'Quotes/types/supplier';
import PresentForRoles from 'Auth/PresentForRoles';
import { userPermissions } from 'shared/utils/permissions';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import React from 'react';
import { presentSupplierNameForPriceTile } from 'Quotes/utils/supplierHelpers';
import { SupplierImage } from 'Quotes/QuoteBuilder/components/Price/components/Supplier';
import { ConnectionType } from 'Quotes/types/connectionType';

export const SupplierImageComponent = ({ supplier }: { supplier: Supplier }) => {
  if (!supplier) return null;
  return (
    <li>
      <SupplierImage supplier={supplier} />
    </li>
  );
};

interface DataCentreComponentParams {
  dataCentreName: string | null | undefined;
  dataCentreReference: string | null | undefined;
  fallbackPostcode: string;
  ['data-testId']: string;
}

export const DataCentreComponent = (props: DataCentreComponentParams) => (
  <li className="mt-2 dc-ref" data-testid={props['data-testId']}>
    {props.dataCentreName || props.dataCentreReference || props.fallbackPostcode}
  </li>
);

interface SupplierNameComponentParams {
  supplier: Supplier;
  ['data-testId']: string;
}

export const SupplierNameComponent = (props: SupplierNameComponentParams) => (
  <li data-testid={props['data-testId']}>{presentSupplierNameForPriceTile(props.supplier)}</li>
);

interface AccessMethodComponentParams {
  accessMethod: ConnectionType | null;
  ['data-testId']: string;
}

export const AccessMethodComponent = (props: AccessMethodComponentParams) => {
  if (!props.accessMethod) return null;
  return (
    <li className="text-secondary" data-testid={props['data-testId']}>
      {props.accessMethod}
    </li>
  );
};

interface CostComponentParams {
  poA: boolean;
  price: number | null;
  ['data-testId']: string;
}

export const CostComponent = (props: CostComponentParams) => {
  if (props.poA)
    return (
      <li className="text-secondary small" data-testid={props['data-testId']}>
        &nbsp;
      </li>
    );
  return (
    <PresentForRoles roles={userPermissions.costs}>
      <li className="text-secondary small" data-testid={props['data-testId']}>
        Cost: {currencyFormatter.format(props.price ?? 0)}
      </li>
    </PresentForRoles>
  );
};

export const BandwidthLinkComponent = () => (
  <li className="bandwidth-link">
    <img src="/images/price-end-link-graphic.png" alt="Link between aEnd and bEnd" width={23} height={71} />
  </li>
);
