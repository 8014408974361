import { CrossConnectCost } from '../../../../../../types/pricedQuote';
import { CablingType } from '../../../../../../types/store';

export const setInstallCost = (isCopper: boolean, crossConnectCost: CrossConnectCost | null): number => {
  const adminFee = crossConnectCost?.admin_fee ?? 0;
  const installCost = isCopper ? crossConnectCost?.copper?.install ?? 0 : crossConnectCost?.fibre?.install ?? 0;
  return adminFee + installCost;
};

export const setAnnualCost = (isCopper: boolean, crossConnectCost: CrossConnectCost | null) => {
  return isCopper ? crossConnectCost?.copper?.annual ?? 0 : crossConnectCost?.fibre?.annual ?? 0;
};

export const setCablingType = (isCopper: boolean, hasCabling: boolean | null): CablingType | null => {
  if (!hasCabling) return null;
  return isCopper ? CablingType.Copper : CablingType.Fibre;
};
