import cx from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import Icon from 'shared/components/atoms/Icon';
import Column from 'shared/components/atoms/Column';

export interface ILockableCardProps {
  children?: ReactNode;
  className?: string;
  locked?: boolean;
  title: string;
}

const LockableCard: FunctionComponent<React.PropsWithChildren<ILockableCardProps>> = ({
  children,
  className,
  locked = true,
  title,
}) => {
  return (
    <section className={className}>
      <div className={cx({ locked }, 'row no-gutters header')}>
        <Column defaultWidth={10}>
          <h4 className={cx({ unlocked: !locked })}>
            {locked ? (
              <Icon className="grey20 align-middle" name="brightness_1" size="xLarge" />
            ) : (
              <Icon className="unlocked-icon primary align-middle" name="check_circle" size="xLarge" />
            )}
            {title}
          </h4>
        </Column>
        <Column defaultWidth={2} classNames={['text-right', 'justify-content-end']}>
          {locked && <Icon className="align-middle grey50" name="lock" size="xLarge" />}
        </Column>
      </div>

      <div className="content">{children}</div>
    </section>
  );
};

const StyledLockableCard = styled(LockableCard)`
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  border: solid 0.5px ${(props) => props.theme.colours.grey10};
  background-color: #ffffff;
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .locked {
    opacity: 0.6;
  }

  .header {
    border-bottom: 1px solid ${(props) => props.theme.colours.grey10};
    padding-bottom: 8px;
  }

  h4 {
    &.unlocked {
      color: ${(props) => props.theme.colours.primaryB1};
    }

    i {
      margin-right: 15px;
    }
  }

  .content {
    margin-left: 2em;
    padding-top: 1.5em;
  }
`;

export default StyledLockableCard;
