import { useEffect, useRef, useState } from 'react';

export const useDefaultValueWhenDisabled = <T>(
  enabled: boolean,
  defaultValue: T,
  value: T,
  setValue: (value: T) => void
) => {
  const [userSelectedState, setUserSelectedState] = useState(value);
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (enabled) {
      setUserSelectedState(value);
    }
  }, [value, enabled]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (enabled) {
      setValue(userSelectedState);
    } else {
      setValue(defaultValue);
    }
  }, [enabled, userSelectedState, setValue, defaultValue]);
};
