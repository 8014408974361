import { orderById } from 'Order/routes';
import { IQuoteListItemWithOrderId } from 'Quotes/types/store';
import { formatAccessProvider, formatProductType } from 'Quotes/utils/quoteTableHelpers';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { shared } from '../headers';

interface QuoteCompactSummaryWithOrderTableProps {
  quotes: IQuoteListItemWithOrderId[];
}

export const QuoteCompactSummaryWithOrderTable = ({ quotes }: QuoteCompactSummaryWithOrderTableProps) => {
  return (
    <Table className="table table-striped">
      <thead>
        <tr>
          <th>Quote ID</th>
          <th>Order ID</th>
          <th>Product</th>
          <th>Access Type</th>
          <th>Locations</th>
          <th>Access provider</th>
        </tr>
      </thead>
      <tbody>
        {quotes.map((quote) => (
          <tr key={quote.short_id}>
            <td>{quote.short_id}</td>
            <td>
              <Link to={orderById(quote.orderId)} target="_blank">
                {quote.orderShortId}
              </Link>
            </td>
            <td>{formatProductType(quote.product_type)}</td>
            <td>{shared.accessTypeForQuote().accessor(quote)}</td>
            <td>{shared.locations().accessor(quote)}</td>
            <td>{formatAccessProvider(quote)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const Table = styled.table`
  font-size: 0.875rem;

  tbody {
    display: block;
    max-height: 300px;
    overflow-y: auto;
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
`;
