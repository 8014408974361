import React from 'react';
import { ProductType } from 'Quotes/types/productTypes';
import FttpAggregationCharge from './FttpAggregationCharge';
import IPCharge from './IPCharge';
import PriceSummarySegment from './PriceSummarySegment';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { IPType } from '../../../Configure/DIAConfig/types/ip';
import { IAEndLocation } from 'Quotes/types/store';
import { AnnualExtras } from 'Quotes/QuoteBuilder/utils/costsUtilities';
import { IMDIAPrices } from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/UpfrontCosts';
import MDIAPrices from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/MDIAPrices';

interface AnnualChargesExtraProps {
  includeFTTPAggregationCharge: boolean;
  productType: ProductType | null | undefined;
  ipType?: IPType;
  isManagedDIA: boolean;
  diaIPAllocation: IAEndLocation['dia_ip_allocation'];
  priceAmortised: boolean;
  annualCharges: AnnualExtras;
  mdiaPrices: IMDIAPrices | undefined;
}

const AnnualChargesExtra: React.FC<AnnualChargesExtraProps> = ({
  includeFTTPAggregationCharge,
  productType,
  mdiaPrices,
  ipType,
  isManagedDIA,
  diaIPAllocation,
  priceAmortised,
  annualCharges,
}) => {
  return (
    <>
      {includeFTTPAggregationCharge && (
        <FttpAggregationCharge fttpAggregationCharge={annualCharges.fttpAggregationCharge} />
      )}
      {productType === ProductType.DIA && (
        <>
          <IPCharge
            ipType={ipType}
            isManagedDIA={isManagedDIA}
            diaIPAllocation={diaIPAllocation}
            ipCost={annualCharges.ipCost}
          />
          {featureFlag.isEnabled(Feature.mdiaPriceBreakdown) && isManagedDIA && annualCharges.mdiaAnnualPrice > 0 && (
            <PriceSummarySegment
              label="Includes managed DIA services at"
              value={annualCharges.mdiaAnnualPrice}
              suffix="per year"
            />
          )}

          {featureFlag.isEnabled(Feature.newMDIAUI) && !!mdiaPrices && priceAmortised && (
            <MDIAPrices
              isManagedDIA={true}
              mdiaRouterPrice={mdiaPrices.router}
              mdiaEngineerPrice={mdiaPrices.engineerInstallation}
              mdiaRackMountKitPrice={mdiaPrices.rackMountKit}
              engineerInstallationRequired={mdiaPrices.engineerInstallation > 0}
            />
          )}
        </>
      )}
      {annualCharges.annualDiscount > 0 && (
        <PriceSummarySegment label="Includes annual discount of" value={annualCharges.annualDiscount} />
      )}
    </>
  );
};

export default AnnualChargesExtra;
