import { IOrderMetaForLocation } from 'Order/types/store';
import { userPermissions } from 'shared/utils/permissions';
import { Role } from 'User/types/role';
import { anyRolesMatch } from 'User/utils/roles';

type FormatAddressLine = (userRoles: Role[], fullQuoteAddress: IOrderMetaForLocation) => string;

export const formatAddressLine: FormatAddressLine = (userRoles, fullQuoteAddress) => {
  const getAddress = fullQuoteAddress.location || fullQuoteAddress.name;

  if (anyRolesMatch(userPermissions.showOnNetAddressRef, userRoles)) {
    if (getAddress && fullQuoteAddress.reference) {
      return `${getAddress}, ${fullQuoteAddress.reference}`;
    } else {
      return getAddress || fullQuoteAddress.reference;
    }
  } else {
    return getAddress;
  }
};
