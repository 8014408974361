import React from 'react';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { Charges } from './TillReceipt';

interface AnnualChargesTotalProps {
  priceAmortised: boolean;
  charges: Charges;
}

const AnnualChargesTotal: React.FC<AnnualChargesTotalProps> = ({ priceAmortised, charges }) => {
  return (
    <div className="price-summary__segment-wrapper">
      <div>Annual Charge</div>
      <div className="d-flex flex-column align-items-end flex-fill">
        <b className="total currency" data-testid="quote-page-annual-charge">
          {currencyFormatter.format(priceAmortised ? charges.amortisedPrice : charges.annualPrice)}
        </b>
      </div>
    </div>
  );
};

export default AnnualChargesTotal;
