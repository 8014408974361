import { mapOrderToOrderTemplateState } from 'Order/crud/orderTemplateMapper';
import { IOrderTemplate } from 'Order/types/orderTemplate';
import { IOrder } from 'Order/types/store';
import React from 'react';
import Icon from 'shared/components/atoms/Icon';
import { useModal } from 'shared/hooks/useModal';
import styled from 'styled-components';
import { UpdateOrderTemplateModal } from './UpdateOrderTemplateModal';
import { setSelectedOrderTemplate } from 'Order/actions';
import { useDispatch } from 'react-redux';
import { useOrderTemplates } from 'Order/crud/orderTemplatesApi';

interface OrderTemplateUpdateButtonProps {
  customerId: string;
  order: IOrder;
  orderId: string;
  setSelectedOrderTemplateAction?: typeof setSelectedOrderTemplate;
  useOrderTemplatesHook?: typeof useOrderTemplates;
}

export const OrderTemplateUpdateButton: React.FC<OrderTemplateUpdateButtonProps> = ({
  customerId,
  order,
  setSelectedOrderTemplateAction = setSelectedOrderTemplate,
  useOrderTemplatesHook = useOrderTemplates,
}) => {
  const { isOpen, openModal, closeModal } = useModal();
  const dispatch = useDispatch();

  const updatedOrderTemplate: IOrderTemplate = {
    ...order.selectedOrderTemplate!,
    attributes: {
      ...order.selectedOrderTemplate!.attributes,
      templateBody: mapOrderToOrderTemplateState(order),
    },
  };

  const updateSelectedOrderTemplate = (createdOrderTemplate: IOrderTemplate) => {
    dispatch(setSelectedOrderTemplateAction(createdOrderTemplate));
  };

  return (
    <>
      {isOpen && (
        <UpdateOrderTemplateModal
          customerId={customerId}
          orderTemplate={updatedOrderTemplate}
          onClose={closeModal}
          onSuccess={updateSelectedOrderTemplate}
          useOrderTemplatesHook={useOrderTemplatesHook}
        />
      )}

      <SaveTemplateButton onClick={openModal}>
        <Icon name="update" size="large" />
        <strong>Update order template</strong>
      </SaveTemplateButton>
    </>
  );
};

const SaveTemplateButton = styled.button`
  color: ${(props) => props.theme.colours.primaryB1};
  display: flex;
  align-items: center;
`;
