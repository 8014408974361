import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectQuote, selectSelectedPrice } from 'Quotes/selectors';
import { Exchange, IPriceData } from 'Quotes/types/store';
import styled from 'styled-components';
import Container from '../../atoms/Container';
import { ProductType } from 'Quotes/types/productTypes';
import {
  CircuitData,
  DIACircuitData,
  IExchanges,
  isDIA,
  isP2NNI,
  isP2P,
  P2NNICircuitData,
  P2PCircuitData,
} from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import { SecondaryNNIAndExchange } from 'shared/components/molecules/SecondaryCircuits/SecondaryNNIAndExchange';
import { SecondaryDualExchanges } from 'shared/components/molecules/SecondaryCircuits/SecondaryDualExchanges';
import { DIAExchanges } from 'shared/components/molecules/SecondaryCircuits/DIAExchanges';
import { PrimaryInfo } from 'shared/components/molecules/SecondaryCircuits/PrimaryCircuitInfo';

export interface SecondaryExchange {
  popId: string;
  name: string;
  distance?: number;
}

export interface SecondaryExchangeSelector {
  productType: ProductType | string | null | undefined;
  readOnly: boolean;
  primaryExchangeA: string;
  primaryExchangeB: string;
  circuitData: CircuitData;
}

export interface ExchangeOptions {
  label: string;
  value: string;
  defaultSelected: boolean;
}

export const Circuit = styled.div`
  display: flex;
  width: 100%;
  padding: 1em;
  margin: 0.5em 0;

  border: 1px solid ${(props) => props.theme.colours.primaryB1};
  border-radius: 0.5rem;

  > * {
    flex: 1;
  }
`;

export const Grow = styled.div`
  flex: 0;
  margin: 0 1em;
`;

export const Arrow = () => (
  <Grow>
    <i className="material-icons">trending_flat</i>
  </Grow>
);

const findByPopId = (secondaryExchanges: SecondaryExchange[], popId: string): Exchange =>
  secondaryExchanges.find((it) => it.popId === popId) as Exchange;

function SecondaryCircuitSelections(props: { readOnly: boolean; circuitData: P2NNICircuitData }) {
  const bEndExchanges = useMemo(() => convertExchangesToDropDownOptions(props.circuitData.bEnd.exchanges), [
    props.circuitData.bEnd.exchanges,
  ]);

  useEffect(() => {
    if (props.circuitData.bEnd.selectedExchangeId) return;
    if (props.circuitData.bEnd.exchanges.length === 0) return;

    props.circuitData.bEnd.selectExchange(findByPopId(props.circuitData.bEnd.exchanges, bEndExchanges[0].value));
  }, [bEndExchanges, props.circuitData.bEnd]);

  return (
    <Circuit data-testid="secondary-circuit">
      <SecondaryNNIAndExchange
        circuitData={props.circuitData}
        readOnly={props.readOnly}
        options={bEndExchanges}
        predicate={(it) => it.value === props.circuitData.bEnd.selectedExchangeId}
        onChange={(option) => {
          props.circuitData.bEnd.selectExchange(findByPopId(props.circuitData.bEnd.exchanges, option?.value ?? ''));
        }}
      />
    </Circuit>
  );
}

const useSelectExchange = (endExchanges: IExchanges, exchangeOptions: ExchangeOptions[]) => {
  useEffect(() => {
    if (endExchanges.selectedExchangeId) return;
    if (endExchanges.exchanges.length === 0) return;

    endExchanges.selectExchange(findByPopId(endExchanges.exchanges, exchangeOptions[0].value));
  }, [exchangeOptions, endExchanges]);
};

function DualExchangeCircuitSelections(props: { readOnly: boolean; circuitData: P2PCircuitData }) {
  const exchangesToOptions = (exchanges: Exchange[]) =>
    useMemo(() => convertExchangesToDropDownOptions(exchanges), [exchanges]);

  const aEndExchangeOptions: ExchangeOptions[] = exchangesToOptions(props.circuitData.aEnd.exchanges);
  const bEndExchangeOptions: ExchangeOptions[] = exchangesToOptions(props.circuitData.bEnd.exchanges);

  useSelectExchange(props.circuitData.aEnd, aEndExchangeOptions);
  useSelectExchange(props.circuitData.bEnd, bEndExchangeOptions);

  return (
    <Circuit data-testid="secondary-circuit">
      <SecondaryDualExchanges
        aEndExchangeOptions={aEndExchangeOptions}
        disabled={props.readOnly}
        predicateAEnd={(it) => it.value === props.circuitData.aEnd.selectedExchangeId}
        onChangeAEnd={(option) => {
          props.circuitData.aEnd.selectExchange(findByPopId(props.circuitData.aEnd.exchanges, option?.value ?? ''));
        }}
        bEndExchangeOptions={bEndExchangeOptions}
        predicateBEnd={(it) => it.value === props.circuitData.bEnd.selectedExchangeId}
        onChangeBEnd={(option) => {
          props.circuitData.bEnd.selectExchange(findByPopId(props.circuitData.bEnd.exchanges, option?.value ?? ''));
        }}
      />
    </Circuit>
  );
}

function DIAExchangeSelection(props: { readOnly: boolean; circuitData: DIACircuitData }) {
  const exchangesToOptions = (exchanges: Exchange[]) =>
    useMemo(() => convertExchangesToDropDownOptions(exchanges), [exchanges]);

  const exchangeOptions: ExchangeOptions[] = exchangesToOptions(props.circuitData.aEnd.exchanges);

  useSelectExchange(props.circuitData.aEnd, exchangeOptions);

  return (
    <Circuit data-testid="secondary-circuit">
      <DIAExchanges
        exchangeOptions={exchangeOptions}
        isDropdownDisabled={props.readOnly}
        selectedExchange={(it) => it.value === props.circuitData.aEnd.selectedExchangeId}
        onChange={(option) => {
          props.circuitData.aEnd.selectExchange(findByPopId(props.circuitData.aEnd.exchanges, option?.value ?? ''));
        }}
      />
    </Circuit>
  );
}

const ExchangeContainer = styled(Container)`
  min-height: unset;
  padding-bottom: unset;
`;

function convertExchangesToDropDownOptions(secondaryExchanges: SecondaryExchange[]): ExchangeOptions[] {
  if (!secondaryExchanges.length) {
    return [{ label: 'None available', value: 'None available', defaultSelected: true }];
  }

  const defaultSelectedExchange = secondaryExchanges[0];
  const convertToOption = (exchange: SecondaryExchange) => ({
    label:
      exchange.distance === undefined
        ? exchange.name
        : `${exchange.name} - ${(Math.round(exchange.distance / 100) * 100) / 1000}km`,
    value: exchange.popId,
    defaultSelected: exchange === defaultSelectedExchange,
  });

  return secondaryExchanges.map(convertToOption);
}

export const SecondaryExchanges = ({
  productType,
  readOnly,
  primaryExchangeA,
  primaryExchangeB,
  circuitData,
}: SecondaryExchangeSelector) => {
  return (
    <ExchangeContainer>
      {productType === ProductType.P2NNI && isP2NNI(circuitData) && (
        <>
          <PrimaryInfo
            aEndHeader={'Primary NNI'}
            aEndType={circuitData.aEnd.primaryNNILabel}
            aEndTestId={'nni'}
            bEndHeader={'Primary Exchange'}
            bEndType={primaryExchangeB}
            bEndTestId={'exchange'}
          />
          <SecondaryCircuitSelections readOnly={readOnly} circuitData={circuitData} />
        </>
      )}
      {productType === ProductType.P2P && isP2P(circuitData) && (
        <>
          <PrimaryInfo
            aEndHeader={'Primary Exchange A'}
            aEndType={primaryExchangeA}
            aEndTestId={'exchange-a'}
            bEndHeader={'Primary Exchange B'}
            bEndType={primaryExchangeB}
            bEndTestId={'exchange-b'}
          />
          <DualExchangeCircuitSelections readOnly={readOnly} circuitData={circuitData} />
        </>
      )}
      {productType === ProductType.DIA && isDIA(circuitData) && (
        <>
          <PrimaryInfo
            aEndHeader={'Primary Exchange'}
            aEndType={primaryExchangeA}
            aEndTestId={'exchange-a'}
            bEndHeader={'Internet Access Point'}
          />
          <DIAExchangeSelection readOnly={readOnly} circuitData={circuitData} />
        </>
      )}
    </ExchangeContainer>
  );
};

const formatExchangeAInfo = (price: IPriceData) => `${price.a_end_p_o_p} ${price.a_end_p_o_p_postcode}`;
const formatExchangeBInfo = (price: IPriceData) => `${price.b_end_p_o_p} ${price.b_end_p_o_p_postcode}`;
const ConnectedSecondaryExchanges = ({ readOnly, circuitData }: { readOnly: boolean; circuitData: CircuitData }) => {
  const selectedPrice = useSelector(selectSelectedPrice);
  const exchangeAInfo = formatExchangeAInfo(selectedPrice);
  const exchangeBInfo = formatExchangeBInfo(selectedPrice);
  const quote = useSelector(selectQuote);
  const productType = quote.productType;

  return (
    <SecondaryExchanges
      productType={productType}
      readOnly={readOnly}
      primaryExchangeA={exchangeAInfo}
      primaryExchangeB={exchangeBInfo}
      circuitData={circuitData}
    />
  );
};

export default ConnectedSecondaryExchanges;
