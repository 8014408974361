export const translateErrorMessage = (message: string): string => {
  if (message.includes('An account with the given email already exists')) {
    return 'User already exists, please contact your account manager.';
  } else if (message.includes("'errors':")) {
    try {
      const jsonErrors = JSON.parse(message.replace(/'/g, '"')).errors || [];
      const errorMessages = jsonErrors.map((e: { detail: string }) => e.detail || '');
      return [...new Set(errorMessages)].join(', ');
    } catch (e) {
      return `Unknown Error: ${message}`;
    }
  }

  return message;
};
