import { IAppState } from 'reducers';
import { IMeta, QuoteStatus } from './types/quoteRecordAttributesBase';
import { concatenateNNI } from 'shared/utils/addresses/helperFunctions';
import { AddressType, ILocation, INNI, IPriceData, IQuotesState, QuoteOrigin } from './types/store';
import { Supplier } from './types/supplier';
import { ProductType } from './types/productTypes';
import { BearerType, isOpticalBearer } from './QuoteBuilder/components/Configure/Bearer/BearerType';
import { getSelectedShadowNNIReference } from './QuoteBuilder/utils/getSelectedNNIReference';
import { ConnectorType, IOrderLocation } from 'Order/types/location';
import hasFeatureEnabled, { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { presentProductNameForQuoteExtraInfo } from './utils/popHelper';
import { isPriceNNAT, isSupplierNNAT } from './utils/isPriceNNAT';
import { createSelector } from '@reduxjs/toolkit';
import { TermAndBandwidth } from 'Quotes/QuoteBuilder/components/Price/selectors';
import { isFTTXConnection } from './types/connectionType';

export const selectAEnd = (state: IAppState) => state.quoteBuilder.quote.location.aEnd;

export const selectBEnd = (state: IAppState) => state.quoteBuilder.quote.location.bEnd;

export const selectPricingProgress = (state: IAppState) => state.quoteBuilder.pricing.pricingProgress;

export const selectCurrentMeta = (state: IAppState): IMeta => state.quoteBuilder.quoteEndpointMeta;

export const selectNNI = (state: IAppState): INNI => state.quoteBuilder.quote.location.aEnd.nni;

export const isNewNNI = (state: IAppState): boolean => !state.quoteBuilder.quote.location.aEnd.nni.selectedId;

export const isNewShadowNNI = (state: IAppState): boolean =>
  !!state.quoteBuilder.quote.location.aEnd.nni.shadowVLAN.selectedDataCentreId;

export const selectQuoteUpdateError = (state: IAppState): boolean => state.quoteBuilder.updating.error || false;

export const selectIsQuoteUpdating = (state: IAppState) => {
  return !!state.quoteBuilder.updating.inProgress;
};

export const selectQuoteId = (state: IAppState) => state.quoteBuilder.currentQuoteId;

export const selectNNILabel = (state: IAppState) => {
  return selectNNILabelFromQuote(state.quoteBuilder);
};

export const selectNNILabelFromQuote = (state: IQuotesState) => {
  return concatenateNNI(state.quoteEndpointMeta, state.quote, state.pricing.selectedPrice);
};

export const selectLocationsContainOnNetAddress = (state: IAppState) => {
  const { aEnd, bEnd } = state.quoteBuilder.quote.location;

  return [aEnd.addressType, bEnd.addressType].includes(AddressType.ON_NET);
};

export const selectQuote = (state: IAppState) => {
  return state.quoteBuilder.quote;
};

export const selectSelectedPrice = (state: IAppState) => {
  return state.quoteBuilder.pricing.selectedPrice;
};

export const selectAllPricedBandwidths = (state: IQuotesState): TermAndBandwidth[] => {
  return state.pricing.allPrices
    .map((it): TermAndBandwidth | undefined => {
      return it.bandwidth && it.term_length_in_months
        ? {
            bandwidth: it.bandwidth,
            term: it.term_length_in_months,
          }
        : undefined;
    })
    .filter((it) => it !== undefined) as TermAndBandwidth[];
};

export const isFTTXQuote = (state: IAppState) => {
  const { connectionType } = state.quoteBuilder.quote;
  return isFTTXConnection(connectionType);
};

export const showSiteTypeConfigForPoint = (end: 'aEndLocation' | 'bEndLocation') => (state: IAppState) => {
  if (!isFTTXQuote(state)) {
    return false;
  }

  const selectedPrice = selectSelectedPrice(state);

  return [Supplier.TALKTALK, Supplier.BT_WHOLESALE].includes(
    end === 'aEndLocation'
      ? (selectedPrice.a_end_access_type as Supplier)
      : (selectedPrice.b_end_access_type as Supplier)
  );
};

export const selectL2SIDForPoPDisplay = (end: 'A' | 'B') => (state: IAppState) => {
  const isFTTX = isFTTXQuote(state);
  const quote = selectQuote(state);
  const selectedPrice = selectSelectedPrice(state);

  if (!isFTTX) {
    return null;
  }

  if (end === 'A') {
    return selectedPrice.a_end_access_type === Supplier.OPENREACH ? quote.aEndL2SID || 'Unavailable' : null;
  } else {
    return selectedPrice.b_end_access_type === Supplier.OPENREACH ? quote.bEndL2SID || 'Unavailable' : null;
  }
};

export const selectMDFIDForPoPDisplay = (end: 'A' | 'B') => (state: IAppState) => {
  const isFTTX = isFTTXQuote(state);
  const quote = selectQuote(state);
  const selectedPrice = selectSelectedPrice(state);

  if (!isFTTX) {
    return null;
  }

  if (end === 'A') {
    return selectedPrice.a_end_access_type === Supplier.OPENREACH ? quote.aEndMDFID || 'Unavailable' : null;
  } else {
    return selectedPrice.b_end_access_type === Supplier.OPENREACH ? quote.bEndMDFID || 'Unavailable' : null;
  }
};

export const selectSupplierNNIRefForPoPDisplay = (end: 'A' | 'B') => (state: IAppState) => {
  return selectQuote(state).location[end === 'A' ? 'aEnd' : 'bEnd'].supplierNNI?.supplier_nni_reference;
};

export const selectAddressesRetrievalPending = (state: IAppState) => {
  const quote = selectQuote(state);
  const { aEnd, bEnd } = quote.location;

  return (
    aEnd.addressesRetrieving.onNetAndPaf ||
    aEnd.addressesRetrieving.openreach ||
    bEnd.addressesRetrieving.onNetAndPaf ||
    bEnd.addressesRetrieving.openreach
  );
};

export const selectSubjectToAdditionalOpenreachSurcharges = (state: IAppState) => {
  const isFTTX = isFTTXQuote(state);
  const selectedPrice = selectSelectedPrice(state);
  const fttpAggregationEnabled = featureFlag.isEnabled(Feature.fttpAggregation);

  if (isFTTX || fttpAggregationEnabled) {
    return false;
  }

  return (
    selectedPrice.a_end_access_type === Supplier.OPENREACH || selectedPrice.b_end_access_type === Supplier.OPENREACH
  );
};

export const selectNNICapacity = (state: IAppState) => {
  return state.quoteBuilder.quote.location.aEnd.nni.capacity;
};

export const annualCost = (selectedPrice: IPriceData) => ({
  A: selectedPrice.a_end_annual_cost ?? 0,
  B: (selectedPrice.cloud_connect?.annual || 0) + (selectedPrice.b_end_annual_cost ?? 0),
});

export const selectSelectedPriceAnnualCost = createSelector(
  (state: IAppState) => selectSelectedPrice(state),
  (selectedPrice: IPriceData) => annualCost(selectedPrice)
);

export const selectSelectedPriceAccessType = (state: IAppState) => {
  const selectedPrice = selectSelectedPrice(state);
  return {
    A: selectedPrice.a_end_access_type,
    B: selectedPrice.b_end_access_type,
  };
};

function isUnsupported(productType: ProductType | null | undefined) {
  const supportedProductTypes = [ProductType.DIA, ProductType.P2CCT, ProductType.P2NNI, ProductType.P2P];

  return !productType || !supportedProductTypes.includes(productType);
}

function isNNAT(accessType: string | null, end: ILocation) {
  return isSupplierNNAT(accessType) && !!end?.onatAddress;
}

function selectAEndRJ45ConnectorTypeAvailable(state: IAppState) {
  const selectedPrice = selectSelectedPrice(state);

  if (isFTTXQuote(state) || isUnsupported(state.quoteBuilder.quote.productType)) {
    return false;
  } else if (state.quoteBuilder.quote.bearer === BearerType.SMALL) {
    return true;
  } else if (isNNAT(selectedPrice.a_end_access_type, state.quoteBuilder.quote.location.aEnd)) {
    return false;
  }

  const excludedSuppliersFor1Gbps = [Supplier.BT_WHOLESALE, Supplier.TALKTALK];
  return (
    state.quoteBuilder.quote.bearer === BearerType.MEDIUM &&
    !excludedSuppliersFor1Gbps.includes(selectedPrice.a_end_access_type as Supplier)
  );
}

function selectBEndRJ45ConnectorTypeAvailable(state: IAppState) {
  const selectedPrice = selectSelectedPrice(state);

  if (isFTTXQuote(state) || isUnsupported(state.quoteBuilder.quote.productType)) {
    return false;
  } else if (state.quoteBuilder.quote.bearer === BearerType.SMALL) {
    return true;
  } else if (isNNAT(selectedPrice.b_end_access_type, state.quoteBuilder.quote.location.bEnd)) {
    return state.quoteBuilder.quote.bearer === BearerType.MEDIUM;
  }

  const excludedSuppliersFor1Gbps = [Supplier.BT_WHOLESALE, Supplier.TALKTALK];
  return (
    state.quoteBuilder.quote.bearer === BearerType.MEDIUM &&
    !excludedSuppliersFor1Gbps.includes(selectedPrice.b_end_access_type as Supplier)
  );
}

export interface RJ45Availability {
  A: boolean;
  B: boolean;
}
export const selectRJ45ConnectorTypeAvailable = (state: IAppState): RJ45Availability => {
  return { A: selectAEndRJ45ConnectorTypeAvailable(state), B: selectBEndRJ45ConnectorTypeAvailable(state) };
};

export const selectIsNNATPrice = (state: IAppState) => {
  const selectedPrice = selectSelectedPrice(state);

  return isPriceNNAT(selectedPrice.a_end_access_type, selectedPrice.b_end_access_type);
};

export const selectMultiQuoteModalDisplayButton = (state: IAppState) => {
  const { quote, pricing, state: quoteState } = state.quoteBuilder;

  return !!(
    quote.is_multiquote ||
    quote.bulkQuoteId ||
    pricing.pricingProgress.fetchingPrice ||
    pricing.pricingProgress.error ||
    pricing.pricingProgress.loadingUpdate ||
    state.orderBuilder.creating.inProgress ||
    quoteState === QuoteStatus.LAPSED ||
    quoteState === QuoteStatus.ORDERED
  );
};

export const selectPublishing = (state: IAppState) => {
  return state.quoteBuilder.publishing;
};

export const selectSavingONATAddress = (state: IAppState) => {
  return state.quoteBuilder.savingONATAddress;
};

export const selectShowShadowVLANPrice = (state: IAppState) => {
  const { quote, quoteEndpointMeta: meta } = state.quoteBuilder;
  const { shadow_vlan_price } = selectSelectedPrice(state);

  if (quote.productType !== ProductType.P2NNI) {
    return false;
  }

  if (!shadow_vlan_price || shadow_vlan_price === 0) {
    return false;
  }

  if (quote.origin === QuoteOrigin.API) {
    return true;
  }

  return !!getSelectedShadowNNIReference(meta);
};

export const selectIsInternalQuote = (state: IAppState) => {
  return state.quoteBuilder.quote.is_internal;
};

export const selectCablingTypeSelection = (state: IAppState) => {
  const selectedPrice = selectSelectedPrice(state);
  const meta = selectCurrentMeta(state);

  const emptyReturn = {
    priceId: selectedPrice.id,
    a_end_cabling_type_selected: undefined,
    b_end_cabling_type_selected: undefined,
  };

  if (!selectedPrice.id || !meta.cabling_type_selection?.length) {
    return emptyReturn;
  }

  const existingItem = meta.cabling_type_selection.find((item) => item.priceId === selectedPrice.id);

  if (!existingItem) {
    return emptyReturn;
  }

  return existingItem;
};

export const selectIsCablingChoiceMandatory = (state: IAppState) => {
  const selectedPrice = selectSelectedPrice(state);

  return {
    A: !!selectedPrice.a_end_cross_connect_cost?.is_on_net,
    B: !!selectedPrice.b_end_cross_connect_cost?.is_on_net,
  };
};

export const selectSRMediaTypeAvailable = (state: IAppState) => {
  const selectedPrice = selectSelectedPrice(state);
  return {
    A: isSRMediaTypeAvailable(
      state,
      selectedPrice.a_end_access_type,
      state.quoteBuilder.quote.location.aEnd,
      state.orderBuilder.order.locationA
    ),
    B: isSRMediaTypeAvailable(
      state,
      selectedPrice.b_end_access_type,
      state.quoteBuilder.quote.location.bEnd,
      state.orderBuilder.order.locationB
    ),
  };
};

function isSRMediaTypeAvailable(
  state: IAppState,
  supplier: string | null,
  quoteLocation: ILocation,
  orderLocation: IOrderLocation
) {
  const bearer = state.quoteBuilder.quote.bearer;
  if (isFTTXQuote(state) || isUnsupported(state.quoteBuilder.quote.productType) || bearer !== BearerType.LARGE) {
    return false;
  }

  if (isNNAT(supplier, quoteLocation) && isOpticalBearer(bearer)) {
    return true;
  }

  return (
    supplier === Supplier.OPENREACH &&
    !!orderLocation.siteConfig.connectorType &&
    [ConnectorType.LC, ConnectorType.SC].includes(orderLocation.siteConfig.connectorType)
  );
}

export const selectCanDeleteQuote = (state: IAppState) => {
  return (
    state.quoteBuilder.state !== QuoteStatus.ORDERED &&
    !!state.quoteBuilder.currentQuoteId &&
    !state.quoteBuilder.updating.inProgress &&
    !state.quoteBuilder.pricing.pricingProgress.fetchingPrice &&
    !state.quoteBuilder.pricing.pricingProgress.loadingUpdate
  );
};

export const selectManagedDIAAvailable = (state: IAppState) => {
  const isFTTX = isFTTXQuote(state);
  const supportedBearers = [
    BearerType.SMALL,
    BearerType.MEDIUM,
    ...(hasFeatureEnabled(Feature.newMDIAUI) ? [BearerType.LARGE] : []),
  ];
  const bearer = state.quoteBuilder.quote.bearer;

  return (
    !isFTTX &&
    state.quoteBuilder.quote.productType === ProductType.DIA &&
    !!bearer &&
    supportedBearers.includes(bearer!)
  );
};

export const selectQuoteState = (state: IAppState) => {
  return state.quoteBuilder.state;
};
export const selectIsQuoteReadOnly = (state: IAppState) => {
  return selectQuoteState(state) === QuoteStatus.ORDERED || !!selectQuote(state).sent_to_cerillion_at;
};

export const selectAvailabilityCheck = (state: IAppState) => {
  return state.quoteBuilder.availabilityCheck;
};

export const selectBearer = (state: IAppState) => {
  return state.quoteBuilder.quote.bearer;
};

export const selectBandwidth = (state: IAppState) => {
  return state.quoteBuilder.quote.bandwidth;
};

export const selectConnectionType = (state: IAppState) => {
  return state.quoteBuilder.quote.connectionType;
};

export const selectCommonCircuitOptions = (state: IAppState) => {
  return state.quoteBuilder.availabilityCheck.lastestAvailabilityResponse?.data.attributes.common_circuit_options;
};

export const selectProductCircuitAvailabilities = (state: IAppState) => {
  return state.quoteBuilder.availabilityCheck.lastestAvailabilityResponse?.data.attributes
    .product_circuit_availabilities;
};

export const selectP2CCTBearers = (state: IAppState) => {
  return (
    selectProductCircuitAvailabilities(state)?.find((item) => item.product === ProductType.P2CCT)?.a_end_availability
      .ports ?? []
  );
};

export const selectProductType = (state: IAppState) => {
  return state.quoteBuilder.quote.productType;
};

export const selectFTTXCheckingAvailability = (state: IAppState) => {
  return state.quoteBuilder.checkingAvailability;
};

export const selectQuoteCreating = (state: IAppState) => {
  return state.quoteBuilder.creating;
};

export const selectPricing = (state: IAppState) => {
  return state.quoteBuilder.pricing;
};

export const selectQuoteRetrieving = (state: IAppState) => {
  return state.quoteBuilder.retrieving;
};

export const selectChosenBandwidths = (state: IAppState) => {
  return state.quoteBuilder.quote.chosen_bandwidths;
};

export const selectIsMultiQuote = (state: IAppState) => {
  const quote = selectQuote(state);
  return quote.is_multiquote;
};

export const selectIsEmptyQuoteExtraInfo = (state: IAppState) => {
  const selectedPrice = selectSelectedPrice(state);
  const productTypeValA = presentProductNameForQuoteExtraInfo(
    selectedPrice.a_end_access_type,
    selectedPrice.a_end_product_name
  );
  const productTypeValB = presentProductNameForQuoteExtraInfo(
    selectedPrice.b_end_access_type,
    selectedPrice.b_end_product_name
  );

  return (
    !productTypeValA &&
    !productTypeValB &&
    !selectedPrice.a_end_exchange_type &&
    !selectedPrice.b_end_exchange_type &&
    !selectedPrice.a_end_p_o_p &&
    !selectedPrice.b_end_p_o_p
  );
};

export const selectCustomerId = (state: IAppState) => {
  return state.quoteBuilder.quote.customerId;
};
