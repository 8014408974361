import { Role } from 'User/types/role';
import Column from 'shared/components/atoms/Column';
import { anyRolesMatch } from 'User/utils/roles';
import { userPermissions } from 'shared/utils/permissions';
import { StyledListItem } from 'Order/OrderBuilder/QuoteSummary/TermAndPrice/styles';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import React from 'react';
import { Amount } from 'Order/OrderBuilder/QuoteSummary/TermAndPrice/index';

export function AnnualPriceAndDiscount(props: { amount: number; toCheck: Role[]; value: number }) {
  return (
    <>
      <Column defaultWidth={3}>
        <span className="mr-2">Annual</span>
        <Amount amount={props.amount} />
        {anyRolesMatch(userPermissions.costs, props.toCheck) && (
          <div className="discount">
            <StyledListItem>Discount</StyledListItem>
            <StyledListItem>{currencyFormatter.format(props.value)}</StyledListItem>
          </div>
        )}
      </Column>
    </>
  );
}
