import { createSelector } from '@reduxjs/toolkit';
import { IMeta } from '../../../../Quotes/types/quoteRecordAttributesBase';
import { INNI } from '../../../../Quotes/types/store';
import { IAppState } from 'reducers';

const hasShadowNNI = (quoteMeta: IMeta, nni: INNI): boolean => {
  return (
    !!quoteMeta!.a_end_nni_shadow_reference ||
    !!quoteMeta!.a_end_nni_shadow_data_centre_reference ||
    !!nni.shadowVLAN.name ||
    !!nni.shadowVLAN.location
  );
};

export const selectHasShadowNNI = createSelector(
  (state: IAppState) => state.quoteBuilder.quoteEndpointMeta,
  (state: IAppState) => state.quoteBuilder.quote.location.aEnd.nni,
  (meta, nni) => hasShadowNNI(meta, nni)
);

export default hasShadowNNI;
