import { clearSessionTimer } from 'App/utils/sessionTimer';
import { signOut } from 'Auth/cognito';
import { onResetApp } from 'actions';
import { login } from 'Auth/routes';
import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import { Location } from 'history';

export const signUserOut = (dispatch: Dispatch, navigate: NavigateFunction, location: Location) => {
  signUserOutDirectly(dispatch, navigate, location.pathname, location.search);
};

export const signUserOutDirectly = (
  dispatch: Dispatch,
  navigate: NavigateFunction,
  pathname: string,
  search: string
) => {
  clearSessionTimer();
  signOut();
  dispatch(onResetApp());

  navigate(login, { state: { from: `${pathname}${search}` } });
};
