import { IBulkQuoteByIdAPIResponse, IBulkQuoteByIdResponse } from 'Quotes/types/bulkQuoteById';
import { doRequest } from 'Request';
import { IQuoteData, IQuoteListItem } from 'Quotes/types/quoteRecord';

const getQuotesFromBulkQuote = async (bulkQuoteId: string): Promise<IBulkQuoteByIdResponse> => {
  const response = await doRequest<IBulkQuoteByIdAPIResponse | IBulkQuoteByIdResponse>({
    path: `/bulk-quote-requests/${bulkQuoteId}`,
  });

  if ('bulk_lines' in response.data.attributes) return response as IBulkQuoteByIdResponse;

  if ('multiquote_lines' in response.data.attributes) {
    return convertApiResponse(response);
  }

  return response;
};

const convertApiResponse = (response: IBulkQuoteByIdAPIResponse): IBulkQuoteByIdResponse => {
  const convertedBulkLines: IQuoteListItem[] | undefined = response.data.attributes.multiquote_lines?.map(
    (bulk_line: IQuoteData) => ({
      ...bulk_line.attributes,
      id: bulk_line.id,
    })
  );
  return {
    data: {
      ...response.data,
      attributes: {
        ...response.data.attributes,
        bulk_lines: convertedBulkLines,
      },
    },
  };
};

export default getQuotesFromBulkQuote;
