import { IOrderTemplateState } from 'Order/types/orderTemplate';
import stringify from 'json-stable-stringify';

export const areOrderFormStatesEqual = (a?: IOrderTemplateState, b?: IOrderTemplateState) => {
  if (!a || !b) return false;

  const tempA = { ...a };
  const tempB = { ...b };

  // TODO: remove this after we have refined the location data fields
  delete tempA.locationA;
  delete tempA.locationB;
  delete tempB.locationA;
  delete tempB.locationB;

  return stringify(tempA) === stringify(tempB);
};
