import React, { FunctionComponent, useState } from 'react';
import StyledFormHeader from '../FormHeader';
import Dropdown from 'shared/components/molecules/Dropdown';
import { IOrderLocation, SiteCoordsType } from 'Order/types/location';
import Column from 'shared/components/atoms/Column';
import TextInput from 'shared/components/atoms/TextInput';
import { SetSiteCoords } from 'Order/types/actions';

type Props = {
  coords: IOrderLocation['siteCoords'];
  identifierPrefix: string;
  isManualAddress: boolean;
  onChange(field: SetSiteCoords['payload']['field'], value: SetSiteCoords['payload']['value']): void;
};

type SiteCoordsDropdownOption = {
  label: string;
  value: SiteCoordsType;
};

const options: Array<SiteCoordsDropdownOption> = [
  {
    label: 'Latitude / Longitude',
    value: SiteCoordsType.LatLon,
  },
  {
    label: 'Eastings (X) / Northings (Y)',
    value: SiteCoordsType.EastingNorthing,
  },
];

const selectDefaultCoordsType = (coords: IOrderLocation['siteCoords']) => {
  return coords.latitude !== '' || coords.longitude !== '' || (coords.easting === '' && coords.northing === '')
    ? SiteCoordsType.LatLon
    : SiteCoordsType.EastingNorthing;
};

const getDefaultValue = (coords: IOrderLocation['siteCoords']) => {
  return selectDefaultCoordsType(coords) === SiteCoordsType.LatLon ? options[0] : options[1];
};

export const SiteLatLon: FunctionComponent<React.PropsWithChildren<Props>> = ({
  coords,
  identifierPrefix,
  isManualAddress,
  onChange,
}) => {
  const [defaultValue] = useState(getDefaultValue(coords));
  const [latLon, setLatLon] = useState(selectDefaultCoordsType(coords) === SiteCoordsType.LatLon);
  const isEastingNorthingSet = coords.easting || coords.northing;
  const isLatLonSet = coords.latitude || coords.longitude;

  return (
    <div>
      <StyledFormHeader title={`Site co-ordinates ${isManualAddress ? '*' : ''}`} />
      <div className="row pb-3">
        <Column smWidth={12} lgWidth={10}>
          <Dropdown
            className="coords-dropdown"
            onChange={(selection) => {
              setLatLon(selection?.value !== SiteCoordsType.EastingNorthing);

              if (!selection?.value) {
                return;
              }

              if (selection.value === SiteCoordsType.LatLon) {
                onChange('easting', '');
                onChange('northing', '');
                onChange('latitude', coords.northing);
                onChange('longitude', coords.easting);
              } else {
                onChange('latitude', '');
                onChange('longitude', '');
                onChange('easting', coords.longitude);
                onChange('northing', coords.latitude);
              }
            }}
            options={options}
            defaultValue={defaultValue}
            isClearable={false}
          />
        </Column>
      </div>
      <div className="row">
        {latLon && (
          <>
            <Column smWidth={12} lgWidth={6}>
              <TextInput
                maxLength={50}
                value={coords.latitude}
                onChange={(e) => onChange('latitude', e.target.value)}
                labelText={`Latitude ${isLatLonSet || isManualAddress ? '*' : ''}`}
                fieldName={`${identifierPrefix}Latitude`}
                optional={!isLatLonSet}
              />
            </Column>
            <Column smWidth={12} lgWidth={6}>
              <TextInput
                maxLength={50}
                value={coords.longitude}
                onChange={(e) => onChange('longitude', e.target.value)}
                labelText={`Longitude ${isLatLonSet || isManualAddress ? '*' : ''}`}
                fieldName={`${identifierPrefix}Longitude`}
                optional={!isLatLonSet}
              />
            </Column>
          </>
        )}

        {!latLon && (
          <>
            <Column smWidth={12} lgWidth={6}>
              <TextInput
                maxLength={50}
                value={coords.easting}
                onChange={(e) => onChange('easting', e.target.value)}
                labelText={`Eastings (X) ${isEastingNorthingSet || isManualAddress ? '*' : ''}`}
                fieldName={`${identifierPrefix}Eastings`}
                optional={!isEastingNorthingSet}
              />
            </Column>
            <Column smWidth={12} lgWidth={6}>
              <TextInput
                maxLength={50}
                value={coords.northing}
                onChange={(e) => onChange('northing', e.target.value)}
                labelText={`Northings (Y) ${isEastingNorthingSet || isManualAddress ? '*' : ''}`}
                fieldName={`${identifierPrefix}Northings`}
                optional={!isEastingNorthingSet}
              />
            </Column>
          </>
        )}
      </div>

      {isManualAddress &&
        ((latLon && (!coords.latitude || !coords.longitude)) || (!latLon && (!coords.easting || !coords.northing))) && (
          <strong className="text-danger">* Add site co-ordinates</strong>
        )}
    </div>
  );
};
