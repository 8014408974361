import styled from 'styled-components';

export const DropdownMenu = styled.div`
  z-index: 3;
  position: absolute;
  top: 35px;
  left: 0;
  background: white;
  box-shadow: ${(props) => props.theme.colours.grey20} 0px 0px 8px 0px;
  border-radius: 4px;
  padding: 0.5em;
  width: 100%;

  li {
    color: #737373;
    border-top: 1px solid ${(props) => props.theme.colours.grey20};
    padding-top: 0.45em;

    .checkbox {
      cursor: pointer;
    }
  }
`;
