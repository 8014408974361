import { base as faqBase } from 'FAQ/routes';
import { base as promoOfferBase } from 'PromoOffer/routes';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

declare const ga: jest.Mock;

export type GetPageTrack = (subType: string) => IPageTrack;

export interface IPageTrack {
  title: string;
  location: string;
}

interface EventTrack {
  category: 'faq';
  action: 'faq_view' | 'faq_section_open' | 'faq_section_close';
  label: string;
}

export const pagesWithType: Record<string, GetPageTrack> = {
  orderSummary: (subType) => ({
    title: `ORDER ${subType.toUpperCase()} SUMMARY`,
    location: `/orders/${encodeURIComponent(subType.toLowerCase())}-summary`,
  }),
  orderALocation: (subType) => ({
    title: `ORDER ${subType.toUpperCase()} A LOCATION`,
    location: `/orders/${encodeURIComponent(subType.toLowerCase())}-a-location`,
  }),
  orderBLocation: (subType) => ({
    title: `ORDER ${subType.toUpperCase()} B LOCATION`,
    location: `/orders/${encodeURIComponent(subType.toLowerCase())}-b-location`,
  }),
  orderBilling: (subType) => ({
    title: `ORDER ${subType.toUpperCase()} BILLING`,
    location: `/orders/${encodeURIComponent(subType.toLowerCase())}-billing`,
  }),
  orderReady: (subType) => ({
    title: `ORDER ${subType.toUpperCase()} READY TO PLACE`,
    location: `/orders/${encodeURIComponent(subType.toLowerCase())}-ready-to-place`,
  }),
  orderComplete: (subType) => ({
    title: `ORDER ${subType.toUpperCase()} STATUS`,
    location: `/orders/${encodeURIComponent(subType.toLowerCase())}-status`,
  }),
  setQuoteLocation: (subType) => ({
    title: 'QUOTE ' + subType.toUpperCase(),
    location: `/quotes/${encodeURIComponent(subType.toLowerCase())}`,
  }),
  configureQuote: (subType) => ({
    title: `QUOTE ${subType.toUpperCase()} CONFIG`,
    location: `/quotes/${encodeURIComponent(subType.toLowerCase())}-config`,
  }),
  getQuotePrice: (subType) => ({
    title: `QUOTE ${subType.toUpperCase()} PRICE`,
    location: `/quotes/${encodeURIComponent(subType.toLowerCase())}-price`,
  }),
};

export const pages: Record<string, IPageTrack> = {
  maintenance: { title: 'MAINTENANCE MODE', location: '/maintenance' },
  welcome: { title: 'WELCOME', location: '/welcome' },
  alerts: { title: 'ALERTS', location: '/alerts' },
  apiQuotes: { title: 'API QUOTES', location: '/quotes/api' },
  bulkQuotes: { title: 'BULK QUOTE REQUESTS', location: '/quotes/bulk' },
  bulkQuotesById: { title: 'BULK QUOTE', location: '/quotes/bulk/list' },
  multiQuotes: { title: 'MULTI QUOTE REQUESTS', location: '/quotes/multi' },
  cerillionQuotes: {
    title: 'CERILLION QUOTE REQUESTS',
    location: '/quotes/cerillion',
  },
  multiQuotesById: { title: 'MULTI QUOTE', location: '/quotes/multi/list' },
  bulkQuotePrice: {
    title: 'BULK QUOTE PRICE',
    location: '/quotes/bulk/list/price',
  },
  apiQuotePrice: {
    title: 'API QUOTE PRICE',
    location: '/quotes/api/list/price',
  },
  createQuote: { title: 'NEW QUOTE', location: '/quotes/new' },
  createUser: {
    title: 'USER ADD',
    location: '/users/user/add',
  },
  login: { title: 'LOG IN', location: '/start' },
  orders: { title: 'ORDERS', location: '/orders' },
  quotes: { title: 'QUOTES', location: '/quotes' },
  resetPassword: { title: 'RESET PASSWORD', location: '/start/password' },
  uploadBulkQuote: {
    title: 'NEW BULK QUOTE RQ 1',
    location: '/quotes/bulk/new',
  },
  uploadBulkQuoteSelected: {
    title: 'NEW BULK QUOTE RQ 2',
    location: '/quotes/bulk/new-02',
  },
  uploadBulkQuoteSuccess: {
    title: 'NEW BULK QUOTE RQ 3',
    location: '/quotes/bulk/new-03',
  },
  uploadBulkQuoteError: {
    title: 'NEW BULK QUOTE ERROR',
    location: '/quotes/bulk/new-error',
  },
  users: {
    title: 'USERS',
    location: '/users',
  },
  usersReporting: {
    title: 'USERS REPORTING',
    location: '/users/reporting',
  },
  user: {
    title: 'USER VIEW & EDIT',
    location: '/users/user',
  },
  customerPreferences: {
    title: 'CUSTOMER PREFERENCES VIEW & EDIT',
    location: '/admin/preferences',
  },
  faq: {
    title: 'HELP AND FAQ',
    location: faqBase,
  },
  promoOffer: {
    title: 'PROMO OFFER',
    location: promoOfferBase,
  },
};

export const trackPage = (event: IPageTrack): void => {
  if (typeof ga !== 'undefined') {
    ga('send', 'pageview', {
      page: event.location,
      title: event.title,
    });
  }
};

export const trackEvent = (event: EventTrack): void => {
  if (typeof ga !== 'undefined') {
    ReactGA.event({
      category: event.category,
      action: event.action,
      label: event.label,
    });
  }
};

export function useTrackPage(track: IPageTrack) {
  useEffect(() => {
    trackPage(track);
  }, [track]);
}
