import React, { FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isCheckboxCell } from 'shared/utils/tableHelper';
import MultiSelectTable, {
  ISingleFilter,
  RowCell,
} from '../../../../../../shared/components/organisms/MultiSelectTable';
import headers from './headers';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { selectOrdersReportTableFilters, selectOrdersReportTablePageSize } from 'Tables/selectors';
import TableNames from '../../../../../../Tables/types/tableNames';
import { IFilterPair } from 'shared/types/filterPairType';
import { setSelectedCompany } from 'User/actions';
import { orderById } from 'Order/routes';
import { UpdateHeaderCellWidths } from 'shared/utils/updateHeaderCellWidth';
import { PersistorContext } from 'store';

interface IOrdersReportTable {
  className?: string;
  paging: IServerSidePaging;
}

const OrdersReportTable: FunctionComponent<React.PropsWithChildren<IOrdersReportTable>> = ({ paging }) => {
  const persistor = useContext(PersistorContext);
  const defaultPageSize = useSelector(selectOrdersReportTablePageSize);

  const dispatch = useDispatch();

  const rowClickHandler = async (cell: RowCell) => {
    if (!isCheckboxCell(cell)) {
      const orderId = cell.row.original.id;

      if (cell.row.original.quote) {
        dispatch(setSelectedCompany(cell.row.original.quote.customer_id));

        await persistor!.flush();

        window.open(`${window.location.origin}${orderById(orderId)}`, '_blank');
      }
    }
  };

  const initialFilters: ISingleFilter[] = [];

  const filterPairs: IFilterPair<'ordersTable'>[] = [
    { tableHeaderId: 'id', storeField: 'orderId' },
    { tableHeaderId: 'customer_name', storeField: 'customer' },
    { tableHeaderId: 'product_type', storeField: 'product' },
    { tableHeaderId: 'locations', storeField: 'location' },
    { tableHeaderId: 'access_type', storeField: 'accessProvider' },
    { tableHeaderId: 'updated_at', storeField: 'updatedAt' },
    { tableHeaderId: 'updated_by', storeField: 'updatedBy' },
    { tableHeaderId: 'order_state', storeField: 'status' },
  ];

  const ordersReportTableFilters = useSelector(selectOrdersReportTableFilters);

  filterPairs.forEach((pair) => {
    const storeValue = ordersReportTableFilters[pair.storeField];
    if (storeValue) {
      initialFilters.push({ id: pair.tableHeaderId, value: storeValue });
    }
  });

  return (
    <MultiSelectTable
      headers={UpdateHeaderCellWidths(headers, TableNames.ordersReportTable)}
      paging={paging}
      isLoading={paging.isLoading}
      rows={paging.results}
      rowClickHandler={rowClickHandler}
      initialFilters={initialFilters}
      className="orders-table"
      defaultSort={{
        id: 'updated_at',
        desc: true,
      }}
      defaultPageSize={defaultPageSize}
      storeTableName={TableNames.ordersReportTable}
    />
  );
};

export default OrdersReportTable;
