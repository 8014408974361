import styled from 'styled-components';

import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from 'classnames';

interface IFilterButtonProps
  extends PropsWithChildren<any>,
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  className?: string;
  isOpen?: boolean;
}

const UnstyledFilterButton: FunctionComponent<React.PropsWithChildren<IFilterButtonProps>> = ({
  className,
  children,
  isOpen,
  ...buttonProps
}: IFilterButtonProps) => {
  return (
    <button {...buttonProps} className={classNames(className, buttonProps.className)}>
      {children}

      {isOpen !== undefined && <span className="material-icons">{`keyboard_arrow_${isOpen ? 'up' : 'down'}`}</span>}
    </button>
  );
};

const FilterButton = styled(UnstyledFilterButton)`
  color: black;
  font-family: ${(props) => props.theme.typography.fontFamilyBold};
  cursor: pointer;
  background-color: #f1f5f7;
  display: inline-block;
  border-radius: 30px;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  column-gap: 0.3em;

  .material-icons {
    color: ${(props) => props.theme.colours.primaryB1}!important;
  }
`;

export default FilterButton;
