import { IPAFResponse } from '../../types/postcodeResults';
import { IOnNetSiteResponse, isOnNetSite } from '../../types/onNetSite';

export const isValidPAFResponse = (pafResponse: IPAFResponse): boolean => {
  const isResults = Array.isArray(pafResponse.data);
  if (!isResults) {
    return false;
  }
  return isResults && pafResponse.data.length > 0;
};

export const isValidOnNetResponse = (onNetResponse: IOnNetSiteResponse): boolean => {
  const isResults = Array.isArray(onNetResponse.data);
  if (!isResults) {
    return false;
  }
  return isResults && onNetResponse.data.length > 0 && isOnNetSite(onNetResponse.data[0]);
};
