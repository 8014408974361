import React, { FunctionComponent } from 'react';

import { formatDateTimeHuman } from '../../../shared/utils/dateHelper';
import Column from '../../../shared/components/atoms/Column';
import { useTheme } from 'styled-components';

type Props = {
  placedAt: string;
  submittedAt: string;
  submittedBy: string | undefined | null;
};

const Placed: FunctionComponent<React.PropsWithChildren<Props>> = ({ placedAt, submittedAt, submittedBy }) => {
  const theme = useTheme();

  return (
    <div className="row no-gutters">
      <Column>
        <p>
          Submitted{submittedBy && <span>&nbsp;by {submittedBy}</span>}&nbsp;on {formatDateTimeHuman(submittedAt)}
        </p>
        <p>
          Accepted by Front Office on {formatDateTimeHuman(placedAt)}. If you have any queries please email{' '}
          <a href={`mailto:${theme.content.contactInfo.provisioning.email}`}>
            {theme.content.contactInfo.provisioning.email}
          </a>
        </p>
      </Column>
    </div>
  );
};

export default Placed;
