import { NavigateFunction, NavLink, NavLinkProps } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

export type NavLinkWithActiveParams = NavLinkProps & { className?: string; activeClassName?: string };

export const NavLinkWithActive = (props: NavLinkWithActiveParams) => {
  const { className = '', activeClassName = 'active', ...rest } = props;
  return <NavLink {...rest} className={({ isActive }) => (isActive ? `${className} ${activeClassName}` : className)} />;
};

export interface Navigation {
  navigate: NavigateFunction;
}

export const Link = styled(NavLinkWithActive)`
  color: ${(props) => props.theme.colours.grey70} !important;
  display: inline-block;
  font-size: 1.75rem;
  margin: 0 1em;
  padding: 0;
  text-decoration: none;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: ${(props) => props.theme.colours.secondaryC1};
    text-decoration: none;
  }

  &.active {
    border-bottom: 3px solid ${(props) => props.theme.colours.navLinkActiveColor ?? props.theme.colours.secondaryC1};
    color: ${(props) => props.theme.colours.navLinkActiveColor ?? props.theme.colours.secondaryC1} !important;
  }
`;
