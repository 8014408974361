import { snakeCase } from 'lodash';
import { default as React, FC } from 'react';
import Icon from 'shared/components/atoms/Icon';
import { ProgressBar } from 'shared/components/atoms/ProgressBar';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import { formatDate } from 'shared/utils/dateHelper';
import styled from 'styled-components';

export interface Step {
  id: string;
  name: string;
  completed: boolean;
  completed_date: string | null;
  progressBarValue: number;
}

interface IStatusStepperProps {
  className?: string;
  steps: Step[];
  hideProgressBar?: boolean;
  currentStepId: string;
  currentStepDescription: string | null;
}
const UnstyledStatusStepper: FC<React.PropsWithChildren<IStatusStepperProps>> = ({
  className,
  hideProgressBar,
  steps,
  currentStepId,
  currentStepDescription,
}) => {
  return (
    <div className={className}>
      <div className="container status-stepper">
        <div className="steps">
          {steps.map((step, index) => {
            let status = '';

            if (step.completed) {
              status = 'completed';
            } else if (step.id === currentStepId) {
              status = 'current';
            }

            const isNotFirstPhase = index !== 0;

            const circleContent = status === 'completed' ? <Icon name="check" /> : index + 1;
            const completedDate = step.completed_date ? formatDate(step.completed_date) : null;

            return (
              <div key={index} className={`phase ${snakeCase(step.name)}`}>
                {isNotFirstPhase && !hideProgressBar && (
                  <div className="progress-bar-container">
                    <ProgressBar value={step.progressBarValue} />
                  </div>
                )}
                <span className={`circle ${status}`}>{circleContent}</span>
                <span className="name">
                  {step.name}

                  {step.completed_date && (
                    <div className="step-details">
                      <Tooltip text={`Completed: ${completedDate}`} multiline={true} place="bottom" />
                      <span className="ml-1 completed-date">{completedDate}</span>
                    </div>
                  )}
                </span>
              </div>
            );
          })}
        </div>

        <p>{currentStepDescription}</p>
      </div>
    </div>
  );
};
export const StatusStepper = styled(UnstyledStatusStepper)`
  .container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      row-gap: 16px;
      column-gap: 8px;

      @media (min-width: ${(props) => props.theme.breakpoints.L}) {
        flex-direction: row;
      }
    }
  }

  .phase {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 8px;
    flex-grow: 1;
    row-gap: 8px;

    &:first-child {
      flex-grow: 0;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.L}) {
      flex-direction: row;
    }

    .circle {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: #d8d8d8;
      font-weight: 700;
      flex-grow: 0;
      flex-shrink: 0;

      &.completed {
        background-color: ${(props) => props.theme.colours.primaryB1};
        color: white;
      }

      &.current {
        background-color: transparent;
        border: 2px solid ${(props) => props.theme.colours.primaryB1};
      }
    }

    .name {
      display: flex;
      font-size: 16px;
      flex-grow: 1;
      flex-direction: column;
      max-width: 160px;
      text-align: center;

      @media (min-width: ${(props) => props.theme.breakpoints.L}) {
        text-align: left;
      }

      .completed-date {
        font-size: 14px;
        color: ${(props) => props.theme.colours.grey70};
      }

      .step-details {
        text-align: center;

        @media (min-width: ${(props) => props.theme.breakpoints.L}) {
          text-align: left;
        }
      }
    }

    .progress-bar-container {
      min-width: 40px;
      min-height: 40px;
      rotate: 90deg;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        flex-grow: 1;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.L}) {
        rotate: 0deg;
      }
    }
  }
`;
