import { ProductType } from '../../../../Quotes/types/productTypes';
import Column from '../../../../shared/components/atoms/Column';
import React from 'react';
import Cost from './Cost';

export function AEndSupplierCosts(props: { productType: ProductType; aEndSetupCost: number | null; cost: number }) {
  return (
    <div className="row no-gutters">
      <Column defaultWidth={2}>
        <span className="sub-heading">{`${props.productType === ProductType.DIA ? 'B' : 'A'}-End`}</span>
      </Column>
      <Column defaultWidth={2}>
        <span>Supplier</span>
      </Column>
      <Cost label="Install" cost={props.aEndSetupCost ?? 0} className="a-end-setup-cost" />
      <Cost label="Annual" cost={props.cost} className="a-end-annual-cost" />
    </div>
  );
}
