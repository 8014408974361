import { IBulkQuoteRecord } from '../../../types/bulkQuoteRecord';
import { DateRangeFilter, ThrottledFilter } from '../../../../shared/components/organisms/MultiSelectTable/filters';
import TableNames from '../../../../Tables/types/tableNames';
import capitalize from 'lodash/capitalize';
import { maskSSEUserEmail } from 'shared/utils/maskEmail';

const overflowVisibleStyle = { overflow: 'visible' };
const tableName = TableNames.bulkQuotesTable;

const headers = [
  {
    Filter: ThrottledFilter,
    Header: 'Bulk Quote ID',
    accessor: (row: IBulkQuoteRecord) => capitalize(row.attributes.short_id),
    id: 'id',
    tableName,
    storeKeyName: 'quoteId',
    disableSortBy: true,
    width: 66,
  },
  {
    Filter: DateRangeFilter,
    style: overflowVisibleStyle,
    Header: 'Created',
    accessor: (row: IBulkQuoteRecord) => row.attributes.date_uploaded,
    id: 'created_at',
    tableName,
    storeKeyName: 'updatedAt',
    width: 80,
  },
  {
    Filter: ThrottledFilter,
    Header: 'Quoted by',
    accessor: (row: IBulkQuoteRecord) => maskSSEUserEmail(row.attributes.quoted_by),
    id: 'quoted_by',
    tableName,
    storeKeyName: 'quotedBy',
    disableSortBy: true,
  },
  {
    Filter: ThrottledFilter,
    Header: 'Reference',
    accessor: (row: IBulkQuoteRecord) => row.attributes.reference,
    id: 'reference',
    tableName,
    storeKeyName: 'reference',
    disableSortBy: true,
  },
  {
    Header: 'Completed entries',
    accessor: (row: IBulkQuoteRecord) => row.attributes.completed_entries,
    id: 'completed_entries',
    width: 95,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Status',
    accessor: (row: IBulkQuoteRecord) => row.attributes.state,
    id: 'status',
    width: 65,
    disableSortBy: true,
    disableFilters: true,
  },
];

export default headers;
