import React, { FunctionComponent } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import cx from 'classnames';
import { Qualifier } from 'shared/types/postcodeResults';
import CopyToClipboardBadge from 'shared/components/molecules/CopyToClipboardBadge';

export enum AddressType {
  MANUAL = 'manual',
  ON_NET_SSE = 'on-net-sse',
  ON_NET_3RD_PARTY = 'on-net-3rd-party',
  OPENREACH = 'openreach',
  ONAT = 'onat',
}

export interface IAddressTypeBadge {
  addressType: AddressType;
  className?: string;
  alk?: string;
  qualifier?: Qualifier | null;
}

export const getColour = (qualifier: Qualifier | undefined | null, theme: DefaultTheme) => {
  switch (qualifier) {
    case Qualifier.GOLD:
      return theme.colours.primaryC1;

    case Qualifier.SILVER:
      return theme.colours.grey50;

    case Qualifier.BRONZE:
      return theme.colours.primaryC2;

    default:
      return undefined;
  }
};

const AddressTypeBadge: FunctionComponent<React.PropsWithChildren<IAddressTypeBadge>> = ({
  addressType,
  className,
  alk,
  qualifier,
}) => {
  const theme = useTheme();

  if (addressType !== AddressType.OPENREACH) {
    return (
      <div className={className}>
        <div className={cx('wrapper', { onat: addressType === AddressType.ONAT })}>
          {addressType === AddressType.MANUAL && <span className="manual-address">Manual address</span>}

          {addressType === AddressType.ON_NET_SSE && <span className="on-net-address">On-net building</span>}

          {addressType === AddressType.ON_NET_3RD_PARTY && (
            <span className="third-party-building">3rd party building</span>
          )}

          {addressType === AddressType.ONAT && <span className="onat">Neos Networks Address</span>}
        </div>
      </div>
    );
  }

  if (!alk) {
    return (
      <div className={className}>
        <div className={cx('wrapper', { [`${qualifier}`]: qualifier })}>
          <span className="openreach-qualifier">{`${qualifier} address`}</span>
        </div>
      </div>
    );
  }

  return <CopyToClipboardBadge colour={getColour(qualifier, theme)} label={qualifier || undefined} value={alk} />;
};

const StyledAddressTypeBadge = styled(AddressTypeBadge)`
  display: flex;

  .wrapper {
    background: white;
    border-radius: 5px;
    color: ${(props) => props.theme.colours.secondaryB1};
    border: 0.1em solid ${(props) => props.theme.colours.secondaryB1};
    outline: none;
    padding: 0.2em 1.5em;

    &.Gold {
      border-color: ${(props) => props.theme.colours.primaryC1};
      color: ${(props) => props.theme.colours.primaryC1};
    }

    &.Silver {
      border-color: ${(props) => props.theme.colours.grey50};
      color: ${(props) => props.theme.colours.grey50};
    }

    &.Bronze {
      border-color: ${(props) => props.theme.colours.primaryC2};
      color: ${(props) => props.theme.colours.primaryC2};
    }

    &.onat {
      border-color: ${(props) => props.theme.colours.primaryB1};
      color: ${(props) => props.theme.colours.primaryB1};
    }
  }
`;

export default StyledAddressTypeBadge;
