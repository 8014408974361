import { useEffect, useState } from 'react';
import { IOnNetSite } from 'shared/types/onNetSite';
import { getOnNetAddressesForPostcode } from 'shared/utils/addresses/getOnNetAddressesForPostcode';
import { isValidOnNetResponse } from 'shared/utils/addresses/validators';
import { IOpenReachAddressSite, IPAFAddress, IPAFAddressSite } from '../../../shared/types/postcodeResults';
import getOpenReachAddressesForPostcode from '../../../shared/utils/addresses/getOpenReachAddressesForPostcode';
import getPAFAddressesForPostcode from '../../../shared/utils/addresses/getPAFAddressesForPostcode';

interface IUseAddressesFetch {
  enabled?: boolean;
  postcode: string;
  pafAddress: IPAFAddress;
  resultsFilter?: (results: IOpenReachAddressSite[]) => IOpenReachAddressSite[];
}

interface IUsePAFAddressesFetch {
  enabled?: boolean;
  postcode: string;
}

interface IUseOnNetAddressesFetch {
  postcode: string;
  companyId: string;
}

export const useOpenReachAddressesFetch = ({
  enabled = true,
  postcode,
  pafAddress,
  resultsFilter,
}: IUseAddressesFetch) => {
  const [openReachAddresses, setAddresses] = useState<IOpenReachAddressSite[]>([]);
  const [errorInFetchingOpenReach, setError] = useState<Error | undefined>();
  const [fetchingOpenReachAddressesInProgress, setFetchingOpenReachAddressesInProgress] = useState(enabled);

  useEffect(() => {
    if (!enabled || !postcode) return;

    setError(undefined);
    (async function () {
      try {
        setFetchingOpenReachAddressesInProgress(true);
        const addressesResponse = await getOpenReachAddressesForPostcode(postcode, pafAddress);
        setAddresses(
          typeof resultsFilter === 'function' ? resultsFilter(addressesResponse.data) : addressesResponse.data
        );
      } catch (e: any) {
        setAddresses([]);
        setError(e);
      } finally {
        setFetchingOpenReachAddressesInProgress(false);
      }
    })();
  }, [enabled, postcode, pafAddress, resultsFilter]);
  return {
    openReachAddresses,
    errorInFetchingOpenReach,
    fetchingOpenReachAddressesInProgress,
  };
};

export const usePAFAddressesFetch = ({ enabled = true, postcode }: IUsePAFAddressesFetch) => {
  const [pafAddresses, setAddresses] = useState<IPAFAddressSite[]>([]);
  const [errorInFetchingPAF, setError] = useState<Error>();
  const [fetchingPAFAddressesInProgress, setFetchingPAFAddressesInProgress] = useState(enabled);

  useEffect(() => {
    if (enabled && postcode) {
      (async function () {
        try {
          setFetchingPAFAddressesInProgress(true);
          const addressesResponse = await getPAFAddressesForPostcode(postcode);
          if ('statusCode' in addressesResponse) {
            setError(new Error(addressesResponse.body.message));
          } else {
            setAddresses(addressesResponse.data || []);
          }
        } catch (e: any) {
          setError(e);
        } finally {
          setFetchingPAFAddressesInProgress(false);
        }
      })();
    } else {
      setFetchingPAFAddressesInProgress(false);
    }
  }, [enabled, postcode]);
  return {
    PAFaddresses: pafAddresses,
    errorInFetchingPAF,
    fetchingPAFAddressesInProgress,
  };
};

export const useOnNetAddressesFetch = ({ postcode, companyId }: IUseOnNetAddressesFetch) => {
  const [results, setResults] = useState<IOnNetSite[]>([]);
  const [error, setError] = useState<Error>();
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    (async function () {
      try {
        const response = await getOnNetAddressesForPostcode(postcode, companyId);

        if (!isValidOnNetResponse(response)) {
          setError(new Error('Invalid response'));
          return;
        }

        setResults(response.data);
      } catch (e: any) {
        setError(e);
      } finally {
        setFetching(false);
      }
    })();
  }, [postcode]);

  return {
    fetching,
    error,
    results,
  };
};
