import { ResourceTypes } from 'Request';

export enum MessageActionTypes {
  CHECK_FOR_NEW_MESSAGES = 'CHECK_FOR_NEW_MESSAGES',
  SEND_MESSAGE = 'SEND_MESSAGE',
  SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL',
  SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
}

type CheckForNewMessages = () => {
  type: MessageActionTypes.CHECK_FOR_NEW_MESSAGES;
};

export interface MessageActions {
  type: MessageActionTypes;
  payload?: MessageActionsPayload;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MessageActionsPayload {}

export const checkForNewMessages: CheckForNewMessages = () => ({
  type: MessageActionTypes.CHECK_FOR_NEW_MESSAGES,
});

export interface SendMessage extends MessageActions {
  type: MessageActionTypes.SEND_MESSAGE;
  payload: {
    resourceId: string;
    resourceType: ResourceTypes.quote | ResourceTypes.order;
    message: string;
  };
}

export const sendMessage = (payload: SendMessage['payload']): SendMessage => ({
  type: MessageActionTypes.SEND_MESSAGE,
  payload,
});

interface SendMessageFail extends MessageActions {
  type: MessageActionTypes.SEND_MESSAGE_FAIL;
}

export const sendMessageFail = (): SendMessageFail => ({
  type: MessageActionTypes.SEND_MESSAGE_FAIL,
});

interface SendMessageSuccess extends MessageActions {
  type: MessageActionTypes.SEND_MESSAGE_SUCCESS;
}

export const sendMessageSuccess = (): SendMessageSuccess => ({
  type: MessageActionTypes.SEND_MESSAGE_SUCCESS,
});
