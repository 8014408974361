import { IPAFAddressSiteResponse } from 'shared/types/postcodeResults';
import { doRequest } from 'Request';

export type Response = IPAFAddressSiteResponse | { statusCode: number; body: { message: string } };

const getPAFAddressesForPostcode = async (postcode: string): Promise<Response> => {
  return doRequest({
    path: `/postcode-lookup/${postcode}`,
  });
};

export default getPAFAddressesForPostcode;
