import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import TableNames from 'Tables/types/tableNames';
import { setTablePageSize } from 'Tables/actions';

interface IPaginationBar {
  canPreviousPage: boolean;
  canNextPage: boolean;
  className?: string;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  totalElements: number;
  defaultPageSize?: number;
  storeTableName?: TableNames;
  gotoPage(pageIndex: number): void;
  previousPage(): void;
  nextPage(): void;
  setPageSize(size: number): void;
}

const PaginationBar: FunctionComponent<React.PropsWithChildren<IPaginationBar>> = ({
  canPreviousPage,
  canNextPage,
  className,
  pageCount,
  pageIndex,
  pageSize,
  gotoPage,
  previousPage,
  nextPage,
  totalElements,
  setPageSize,
  defaultPageSize,
  storeTableName,
}) => {
  const dispatch = useDispatch();

  const startingIndex = pageIndex * pageSize;
  const startingElement = startingIndex + 1;
  const pageEndElement = startingIndex + pageSize;
  const endElement = pageEndElement < totalElements ? pageEndElement : totalElements;

  useEffect(() => {
    if (defaultPageSize) {
      setPageSize(defaultPageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <span data-testid="paging-record-count">
        {startingElement}-{endElement} of {totalElements}
      </span>
      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} id="go-to-first-page-btn">
        {'|<'}
      </button>
      <button onClick={() => previousPage()} disabled={!canPreviousPage} id="go-to-previous-page-btn">
        {'<'}
      </button>
      <button onClick={() => nextPage()} disabled={!canNextPage} id="go-to-next-page-btn">
        {'>'}
      </button>
      <button onClick={() => gotoPage(pageCount - 1)} id="go-to-last-page-btn">
        {'>|'}
      </button>

      <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
          if (storeTableName) {
            dispatch(setTablePageSize(storeTableName, Number(e.target.value)));
          }
        }}
        id="select-page-size"
      >
        {[10, 20, 30, 40, 50].concat(defaultPageSize && defaultPageSize >= 250 ? [250] : []).map((pageSizeOption) => (
          <option key={pageSizeOption} value={pageSizeOption}>
            Show {pageSizeOption}
          </option>
        ))}
      </select>
    </div>
  );
};

const StyledPagination = styled(PaginationBar)`
  padding: 0.5rem;
  font-size: 1rem;

  button {
    padding: 0.5rem;
  }
  span {
    margin-right: 1rem;
  }
`;

export default StyledPagination;
