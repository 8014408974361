import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import TextInput from 'shared/components/atoms/TextInput';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import Column from 'shared/components/atoms/Column';
import { signIn } from 'Auth/cognito';
import Alert from 'shared/components/atoms/Alert';
import Spinner from 'shared/components/atoms/Spinner';
import ForceUpdatePassword from './ForceUpdatePassword';
import { resetPassword } from '../routes';
import { ErrorMessage, parseErrorMessage } from '../errorMessage';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import { refreshSessionTimer } from 'App/utils/sessionTimer';
import { useDispatch } from 'react-redux';
import { onResetApp } from 'actions';
import Wrapper from 'Auth/shared/Wrapper';
import { useLocationWithState } from 'shared/hooks/useLocationWithState';
import { Navigation } from 'shared/RouterComponents';

interface Props {
  className?: string;
}

export const SignInInternal: FC<React.PropsWithChildren<Props & Navigation>> = ({ className, navigate }) => {
  const theme = useTheme();
  useTrackPage(pages.login);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<any>();
  const [userAttributes, setUserAttributes] = useState<any>();

  const canSignIn = username !== '' && password !== '';

  const dispatch = useDispatch();
  const location = useLocationWithState();

  const onSuccess = () => {
    refreshSessionTimer();
    dispatch(onResetApp());
    navigate(location.state?.from || '/');
  };

  const onFailure = (errorMessage: ErrorMessage): void => {
    setError(parseErrorMessage(errorMessage));
    setLoading(false);
  };

  const handleRequireNewPassword = (user: any, attributes: any): void => {
    setNewPasswordRequired(true);
    setUserAttributes(attributes);
    setCognitoUser(user);
  };

  const onSignIn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!canSignIn) {
      return;
    }
    setError(undefined);
    setLoading(true);
    signIn(username, password, onSuccess, onFailure, handleRequireNewPassword);
  };

  if (newPasswordRequired && cognitoUser && userAttributes) {
    return <ForceUpdatePassword cognitoUser={cognitoUser} userAttributes={userAttributes} />;
  }

  return (
    <Wrapper className={className}>
      <h1>Sign in to {theme.content.productName.toUpperCase()}</h1>

      {error && <Alert>{error}</Alert>}

      <form onSubmit={onSignIn}>
        <TextInput
          id="username"
          onChange={(e) => setUsername(e.target.value.trim().toLowerCase())}
          labelText="Username"
          helperText="This is your primary email address"
          value={username}
        />
        <TextInput
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          labelText="Password"
          type="password"
          value={password}
        />

        <div className="row no-gutters pt-4">
          <Column>
            {!loading && (
              <Button type="submit" id="sign-in" mainStyle={ButtonMainStyle.PrimaryRectangular} disabled={!canSignIn}>
                Sign in
              </Button>
            )}
            {loading && <Spinner />}
          </Column>
          <Column classNames={['text-right']}>
            <Link id="forgot-password" state={{ username }} to={resetPassword}>
              Forgot password?
            </Link>
          </Column>
        </div>
      </form>
      <hr />
      <p className="text-right mb-0 mt-4">
        Want to set up an account?
        <br />
        Email{' '}
        <a
          href={`mailto:${theme.content.contactInfo.enquiries.email}?subject=Portal request: I want to set up a ${theme.content.productName} account`}
        >
          {theme.content.contactInfo.enquiries.email}
        </a>
        .
      </p>
    </Wrapper>
  );
};

const SignIn: FC<React.PropsWithChildren<Props>> = (props) => {
  const navigate = useNavigate();
  return <SignInInternal {...props} navigate={navigate} />;
};
export default styled(SignIn)`
  #sign-in {
    width: 130px;
    height: 49px;
  }

  #forgot-password {
    font-size: 0.95em;
  }
`;
