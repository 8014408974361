import { doRequest } from '../../../Request';
import { APIQuoteData } from '../../types/apiQuote';

const getAPIQuote: (quoteId: string) => Promise<APIQuoteData> = (quoteId: string) => {
  return doRequest({
    path: `/lq-quotes/${quoteId}?include=orders,prices`,
  });
};

export default getAPIQuote;
