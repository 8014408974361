import React, { FunctionComponent, useCallback, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { IAppState } from 'reducers';
import Alert from 'shared/components/atoms/Alert';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import { setCerillionSentAt } from 'Quotes/actions';
import Modal from '../../../../shared/components/molecules/Modal';
import { postQuotesSubmitOpportunity } from 'Quotes/crud/postQuotesSubmitOpportunity';
import styled from 'styled-components';

interface IPoAModal {
  closeModal: () => void;
  quoteId?: string;
  cerillionSentAt(setCerillionSentAtValue: string): void;
  saveSelectedPrice(): void;
}

export const PoAModal: FunctionComponent<React.PropsWithChildren<IPoAModal>> = ({
  closeModal,
  quoteId,
  cerillionSentAt,
  saveSelectedPrice,
}) => {
  const [postSubmitOpportunityError, setPostSubmitOpportunityError] = useState(false);
  const [submittingQuote, setSubmittingQuote] = useState(false);

  const toggleOpticalP2PModal = useCallback(() => {
    setPostSubmitOpportunityError(false);
    setSubmittingQuote(false);
    closeModal();
  }, [closeModal]);

  const opticalP2PSubmit = () => {
    if (!quoteId) return;

    saveSelectedPrice();
    setSubmittingQuote(true);

    postQuotesSubmitOpportunity(quoteId)
      .then((response) => {
        cerillionSentAt(response.data.attributes.sent_to_cerillion_at);
        toggleOpticalP2PModal();
        window.scrollTo(0, 0);
      })
      .catch(() => {
        setPostSubmitOpportunityError(true);
      });
  };

  return (
    <ModalWithDynamicButtonSizing
      closeBtnLabel="Cancel"
      confirmBtnLabel="Proceed to application"
      showCloseIcon={true}
      disableActions={false}
      showCloseBtn={true}
      showConfirmBtn={true}
      onClose={toggleOpticalP2PModal}
      onConfirm={opticalP2PSubmit}
      title="We need to carry out some pre-checks"
    >
      {postSubmitOpportunityError && (
        <Alert>
          <p>Your quote was not submitted due to a technical error. Please try again later</p>
          <p>If the problem persists, contact your account manager.</p>
        </Alert>
      )}
      <p>This quote needs additional checks to be carried out. Create a quote if you want to start this process now.</p>
      <p>Once created, we will keep you updated on its progress.</p>
      {submittingQuote && <SpinnerWithText text="Submitting quote" size="medium" />}
    </ModalWithDynamicButtonSizing>
  );
};

const ModalWithDynamicButtonSizing = styled(Modal)`
  .modal-footer button {
    width: fit-content;
  }
`;

const mapStateToProps = ({ quoteBuilder: { currentQuoteId } }: IAppState) => ({
  quoteId: currentQuoteId,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  cerillionSentAt: (setCerillionSentAtValue: string) => dispatch(setCerillionSentAt(setCerillionSentAtValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoAModal);
