import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { connect, DispatchProp } from 'react-redux';
import Alert, { Alerts } from '../../../../../shared/components/atoms/Alert';
import { ICurrentlyViewedUserAttributes, UserState } from '../../../../../User/types/user';
import { formatDateTimeHuman } from '../../../../../shared/utils/dateHelper';
import { sendInviteAction, blockUserAction, unblockUserAction } from '../../../../actions';
import { IAppState } from '../../../../../reducers';
import { RequestState } from '../../../../types';
import SpinnerWithText from '../../../../../shared/components/molecules/SpinnerWithText';

interface IUserStatus {
  className?: string;
  userData: ICurrentlyViewedUserAttributes;
  sendInviteRequestState: RequestState;
  blockUserRequestState: RequestState;
  unblockUserRequestState: RequestState;
  sendInvite(id: string): void;
  blockUser(id: string): void;
  unblockUser(id: string): void;
}

const getStatusForRequestState = (
  requestState: RequestState,
  spinnerText: string,
  successText: string,
  errorText: string
) => {
  switch (requestState) {
    case RequestState.LOADING:
      return <SpinnerWithText text={spinnerText} />;
    case RequestState.SUCCESS:
      return <Alert alertType={Alerts.SUCCESS}>{successText}</Alert>;
    case RequestState.ERROR:
      return <Alert alertType={Alerts.WARNING}>{errorText}</Alert>;
    default:
      return null;
  }
};

const getRequestStatus = (
  sendInviteRequestState: RequestState,
  blockUserRequestState: RequestState,
  unblockUserRequestState: RequestState
) => {
  if (sendInviteRequestState !== RequestState.NOT_REQUESTED) {
    return getStatusForRequestState(
      sendInviteRequestState,
      'Sending the invitation',
      'The invite was sent successfully',
      'Error: the invite may not have been sent'
    );
  } else if (blockUserRequestState !== RequestState.NOT_REQUESTED) {
    return getStatusForRequestState(
      blockUserRequestState,
      'Blocking the user',
      'The user has been blocked',
      'Error: the user may not be blocked'
    );
  } else {
    return getStatusForRequestState(
      unblockUserRequestState,
      'Activating the user',
      'The user has been activated',
      'Error: the user may not be activated'
    );
  }
};

export const UserStatus: FunctionComponent<React.PropsWithChildren<IUserStatus>> = ({
  className,
  userData,
  sendInvite,
  sendInviteRequestState,
  blockUserRequestState,
  blockUser,
  unblockUser,
  unblockUserRequestState,
}) => {
  const getSignInSection = () => {
    return userData.lastLoggedIn ? <>Last sign-in on the {formatDateTimeHuman(userData.lastLoggedIn)}</> : null;
  };

  const blockUserSection = () => {
    return userData.status !== UserState.Blocked ? (
      <button onClick={() => blockUser(userData.id)} className="block-user-btn link-button">
        Block this person from all companies
      </button>
    ) : null;
  };

  const unblockUserSection = () => {
    return userData.status === UserState.Blocked ? (
      <button onClick={() => unblockUser(userData.id)} className="unblock-user-btn link-button">
        Activate profile
      </button>
    ) : null;
  };

  const inviteUserSection = (prompt: string, buttonText: string) => {
    return (
      <div className="status-info">
        {prompt}
        <button onClick={() => sendInvite(userData.id)} className="send-invite-btn link-button">
          {buttonText}
        </button>
      </div>
    );
  };

  const getContent = () => {
    switch (userData.status) {
      case UserState.Blocked:
        return (
          <div>
            <div className="status-row">
              <p className="header"> Status </p> <p className="status-indicator blocked">Blocked</p>
            </div>
            {getSignInSection()}
            {unblockUserSection()}
          </div>
        );

      case UserState.Active:
        return (
          <div>
            <div className="status-row">
              <p className="header"> Status </p> <p className="status-indicator active">Active</p>
            </div>
            {getSignInSection()}
            {blockUserSection()}
          </div>
        );

      case UserState.InviteSent:
        return (
          <div>
            <div className="status-row">
              <p className="header"> Status </p> <p className="status-indicator invite">Invite sent</p>
            </div>
            {inviteUserSection(
              'If they haven’t received an invite via email, you can re-send it from here.',
              'Re-send invite'
            )}
            {blockUserSection()}
          </div>
        );

      case UserState.InviteNotSent:
        return (
          <div className="status-info">
            <div className="status-row">
              <h3> Status </h3> <p className="status-indicator invite">Invite not sent</p>
            </div>
            {inviteUserSection('This person has not been invited, send an invite now.', 'Send invite')}
            {blockUserSection()}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className={`${className} userStatus column`}>
      {getContent()}
      <div className="requestStatus">
        {getRequestStatus(sendInviteRequestState, blockUserRequestState, unblockUserRequestState)}
      </div>
    </div>
  );
};

const StyledUserStatus = styled(UserStatus)`
  .userStatus {
    font-size: 1em;
  }

  .requestStatus {
    margin-top: 1em;
    width: fit-content;
  }

  .header {
    border-bottom: none;
    font-size: 1.2em;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .status-row {
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  .status-row {
    display: flex;
  }

  .status-indicator {
    height: fit-content;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
    margin-left: 0.5em;

    &.invite {
      background-color: ${(props) => props.theme.colours.primaryC1};
    }

    &.active {
      background-color: ${(props) => props.theme.colours.primaryB1};
    }

    &.blocked {
      background-color: ${(props) => props.theme.colours.secondaryA1};
      color: white;
    }
  }

  .link-button {
    text-decoration: underline;
    width: 100%;
    text-align: left;
    margin-top: 0.7em;
  }

  .block-user-btn,
  .unblock-user-btn {
    color: ${(props) => props.theme.colours.primaryB1};
  }
`;

const mapStateToProps = (state: IAppState) => ({
  sendInviteRequestState: state.admin.sendInviteRequestState,
  blockUserRequestState: state.admin.blockUserRequestState,
  unblockUserRequestState: state.admin.unblockUserRequestState,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  sendInvite: (id: string) => {
    dispatch(sendInviteAction({ id }));
  },
  blockUser: (id: string) => {
    dispatch(blockUserAction({ id }));
  },
  unblockUser: (id: string) => {
    dispatch(unblockUserAction({ id }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledUserStatus);
