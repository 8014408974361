import React from 'react';
import { Charges } from '../TillReceipt/TillReceipt';
import cx from 'classnames';
import {
  Figure,
  Title,
} from 'Quotes/QuoteBuilder/components/Price/components/TillReceiptInternal/TCVMarginAndValuePanel';

interface TotalContractValueMarginPercentageProps {
  charges: Charges;
}

export const calculateTCVMarginPercentage = (totalContractValue: number, marginAmount: number) => {
  return (marginAmount / totalContractValue) * 100;
};

export const formatToTwoDecimalPlaces = (percentage: number) => {
  return parseFloat(percentage.toFixed(2));
};

export function getTCVMarginPercentage(primaryTotalContractValue: number, primaryContractMargin: number) {
  return formatToTwoDecimalPlaces(calculateTCVMarginPercentage(primaryTotalContractValue, primaryContractMargin));
}

export const TotalContractValueMarginPercentage: React.FC<TotalContractValueMarginPercentageProps> = ({ charges }) => {
  const tcvMarginPercentage = getTCVMarginPercentage(charges.totalContractValue, charges.marginAmount);

  return (
    <>
      <Title data-testid="tcv-margin-percentage-title">TCV Margin %</Title>
      <Figure data-testid="tcv-margin-percentage-figure" className={cx({ 'text-danger': tcvMarginPercentage < 12 })}>
        {tcvMarginPercentage}%
      </Figure>
    </>
  );
};
