import React, { FC, FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ALKLocation,
  ALKLocationResponse,
  IPAFAddressSite,
  IPAFAddressSiteResponse,
} from 'shared/types/postcodeResults';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import { IOnNetSite, IOnNetSiteResponse } from 'shared/types/onNetSite';
import {
  concatenateOpenReachAddress,
  findAddressByUdprn,
  findOnNetAddressByItsLabel,
  isFTTXCompatibleOpenreachLocation,
  openreachGoldFilter,
} from 'shared/utils/addresses/helperFunctions';
import { CompatibleFTTXLocation } from 'Location/CompatibleFTTXLocation';
import { IncompatibleFTTXLocation } from 'Location/IncompatibleFTTXLocation';
import { AddressType, ILocation } from 'Quotes/types/store';
import Column from 'shared/components/atoms/Column';
import Icon from 'shared/components/atoms/Icon';
import { AvailabilityCheckRequest, OnChange, Source } from 'Quotes/types/availabilityCheck';
import { useDispatch, useSelector } from 'react-redux';
import { selectAvailabilityCheck, selectCurrentMeta, selectProductType, selectQuote } from 'Quotes/selectors';
import { ProductType } from 'Quotes/types/productTypes';
import { QUOTE_ON_NET_3RD_PARTY_MESSAGE } from 'shared/constants';
import { selectSelectedCompanyId } from 'User/selectors';
import { usePreviousState } from 'shared/utils/customHooks';
import { GoogleMapsMarkerOptionsWithId } from 'shared/components/organisms/GoogleMapsLocation';
import {
  setAddressesFoundAEnd,
  setAddressesFoundBEnd,
  setAddressNotListedAEnd,
  setAddressNotListedBEnd,
  setFullAddressAEndAction,
  setFullAddressBEndAction,
  setOpenreachAddress,
  setPostcodeAEndAction,
  setPostcodeBEndAction,
} from 'Quotes/actions';
import { getOpenReachAddressForALK } from 'shared/utils/addresses/getOpenReachAddressForALK';
import getPAFAddressesForLocation from 'shared/utils/addresses/getPAFAddressesForLocation';
import { getLookupError, NO_ADDRESSES_FOR_LOCATION_MSG } from 'Location/PostcodeCapture';
import { getOnNetAddressesForPostcode } from 'shared/utils/addresses/getOnNetAddressesForPostcode';
import { mapIPAFAddressSiteResponsesToMapMarkers } from 'shared/utils/mapAddressesToMarkers';
import { isALK } from 'shared/utils/ALKHelpers';
import { DropdownOption } from 'Location/LocationByPostcode';
import { noop } from 'lodash';
import { PostcodeInput } from 'Quotes/QuoteBuilderByLocation/components/Point/PostcodeInput';
import AddressesCombined from 'Location/AddressesCombined';
import { productTypeHasFTTX } from 'Quotes/utils/productTypeHasFTTX';
import { OpenreachAddressCapture } from 'Location/OpenreachAddressCapture';
import { MapsDisplay } from 'Quotes/QuoteBuilderByLocation/components/Point/MapsDisplay';
import { NNATCapablePostcode } from 'Quotes/shared/components/NNAT/NNATAlerts';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { isNNATEnabledFor } from 'FeatureFlags/nnatUtils';

interface Point {
  className?: string;
  id: Source;
  updateNNATSupported?: (isSupported: boolean) => void;
  initialiseFromData?: boolean;
  onChange: OnChange;
  onSelectedAddressPAFChanged?: (address: IPAFAddressSite | null) => void;
  referencePAFAddress?: IPAFAddressSite | null;
}

export const UnstyledPoint: FC<React.PropsWithChildren<Point>> = (props) => {
  if (props.initialiseFromData) {
    return <PointWithInitialData {...props} />;
  }

  return <InnerPoint {...props} />;
};

const PointWithInitialData: FC<React.PropsWithChildren<Point>> = (props) => {
  const quoteState = useSelector(selectQuote);
  const productType = useSelector(selectProductType);
  const end: ILocation = isPointLeft(props.id, productType) ? quoteState.location.aEnd : quoteState.location.bEnd;

  return (
    <InnerPoint
      {...props}
      initialPostcode={end.postcode}
      initialAddressPAF={extractPAFAddress(end)}
      initialAddressOnNet={extractOnNetAddress(end)}
    />
  );
};

interface InitialData {
  initialPostcode?: string;
  initialAddressPAF?: IPAFAddressSite | null;
  initialAddressOnNet?: IOnNetSite | null;
}

export const InnerPoint: FunctionComponent<React.PropsWithChildren<Point & InitialData>> = ({
  className,
  id,
  updateNNATSupported = () => undefined,
  onChange,
  onSelectedAddressPAFChanged,
  referencePAFAddress,
  initialPostcode = '',
  initialAddressPAF = null,
  initialAddressOnNet = null,
}) => {
  const dispatch = useDispatch();
  const quoteState = useSelector(selectQuote);
  const productType = useSelector(selectProductType);
  const end: ILocation = isPointLeft(id, productType) ? quoteState.location.aEnd : quoteState.location.bEnd;

  const companyId = useSelector(selectSelectedCompanyId);
  const availabilityCheck = useSelector(selectAvailabilityCheck);
  const quoteMeta = useSelector(selectCurrentMeta);
  const [textVerified, setTextVerified] = useState('');
  const [loadingOnNet, setLoadingOnNet] = useState(false);
  const [loadingPAF, setLoadingPAF] = useState(false);
  const [loadingALK, setLoadingALK] = useState(false);
  const isLoading = loadingOnNet || loadingPAF || loadingALK;
  const [resultsOnNet, setResultsOnNet] = useState<IOnNetSiteResponse['data']>();
  const [resultsPAF, setResultsPAF] = useState<IPAFAddressSiteResponse['data']>();
  const [resultsALK, setResultsALK] = useState<ALKLocationResponse['data']>();
  const [hasAvailableNNAT, setHasNNATAvailable] = useState(false);
  const updateHasNNATAvailable = (isAvailable: boolean) => {
    setHasNNATAvailable(isAvailable);
    updateNNATSupported(isAvailable);
  };

  const [errorPostcode, setErrorPostcode] = useState<any>();
  const [errorALK, setErrorALK] = useState<any>();
  const [selectedAddressPAF, setSelectedAddressPAF] = useState<IPAFAddressSite | null>(initialAddressPAF);
  const [selectedAddressOnNet, setSelectedAddressOnNet] = useState<IOnNetSite | null>(initialAddressOnNet);
  const prevProductType = usePreviousState(productType);

  const [mapMarkers, setMapMarkers] = useState<GoogleMapsMarkerOptionsWithId[]>([]);

  const resetResults = () => {
    setResultsOnNet(undefined);
    setResultsPAF(undefined);
    setResultsALK(undefined);
    setSelectedAddressOnNet(null);
    setSelectedAddressPAF(null);
  };

  useEffect(() => {
    // FTTX isn't applicable for P2CCT, so we remove any selected Openreach address.
    // This ensures the FTTX availability check doesn't needlessly run.
    if (productType === ProductType.P2CCT) {
      dispatch(
        setOpenreachAddress(
          // Point to NNI A/B End switch
          id === 'point_left' && prevProductType !== ProductType.P2NNI ? 'A' : 'B',
          null
        )
      );
    }
  }, [productType]);

  useEffect(() => {
    if (initialPostcode) {
      onPostcodeSubmit(initialPostcode.trim(), true);
    }
  }, [initialPostcode]);

  const mapMarkersFilteredBySelectedAddressPAF = () => {
    if (selectedAddressPAF)
      return mapMarkers.filter((marker) => {
        return marker.id === selectedAddressPAF?.id;
      });

    return mapMarkers;
  };

  const changeSelectedAddressPAFOnMarkerClick = (addressId: string) => {
    setSelectedAddressPAF(resultsPAF!.find((address) => address.id === addressId)!);
  };

  async function loadALK(value: string) {
    resetResults();
    setLoadingALK(true);

    try {
      const result = (await getOpenReachAddressForALK(value)).data;
      setResultsALK(result);

      // Point to NNI A/B End switch
      if (isPointLeft(id, productType)) {
        dispatch(setPostcodeAEndAction(result.attributes.postcode!));
        dispatch(setOpenreachAddress('A', result));
      } else {
        dispatch(setPostcodeBEndAction(result.attributes.postcode!));
        dispatch(setOpenreachAddress('B', result));
      }

      const changes: AvailabilityCheckRequest =
        id === 'point_left'
          ? {
              ...availabilityCheck.sources.point_left,
              a_end_postcode: result.attributes.postcode,
            }
          : {
              ...availabilityCheck.sources.point_right,
              b_end_postcode: result.attributes.postcode,
            };
      onChange(changes, id);
    } catch (error) {
      setErrorALK(error);
    } finally {
      setLoadingALK(false);
    }
  }

  const buildAvailabilityCheckRequestPayload = (
    pafResults: IPAFAddressSiteResponse,
    freshStart: undefined | boolean
  ): AvailabilityCheckRequest => {
    const isLeft = isPointLeft(id, productType);
    const payload: AvailabilityCheckRequest = {
      ...availabilityCheck.sources[id],
    };

    if (isLeft) {
      payload.a_end_postcode = pafResults.meta.postcode;

      if (!freshStart && selectedAddressOnNet?.attributes.pop_id) {
        // Point to NNI A/B End switch
        payload.a_end_data_centre_id = selectedAddressOnNet.attributes.pop_id;
      }
      return payload;
    } else {
      payload.b_end_postcode = pafResults.meta.postcode;

      if (!freshStart && selectedAddressOnNet?.attributes.pop_id) {
        // Point to NNI A/B End switch
        payload.b_end_data_centre_id = selectedAddressOnNet.attributes.pop_id;
      }

      return payload;
    }
  };

  const loadBroadLocations = async (initialisedFromData: boolean, value: string) => {
    setLoadingOnNet(true);
    setLoadingPAF(true);
    const freshStart = !initialisedFromData;
    if (freshStart) resetResults();

    try {
      const pafResults = await getPAFAddressesForLocation(value);

      // Location is more than just postcodes, e.g. Lat/Long coordinates, so the backend will return a postcode for our given value
      if (!pafResults.meta || !pafResults.meta.postcode) {
        setErrorPostcode(getLookupError(NO_ADDRESSES_FOR_LOCATION_MSG, value));
        return;
      }

      updateHasNNATAvailable(featureFlag.isEnabled(Feature.nnat) && pafResults.meta.has_nnat_addresses);

      const onNetResults = await getOnNetAddressesForPostcode(pafResults.meta.postcode, companyId, 'all');

      setResultsOnNet(onNetResults.data);
      setResultsPAF(pafResults.data);

      if (pafResults.data.length > 0) {
        setMapMarkers(mapIPAFAddressSiteResponsesToMapMarkers(pafResults.data));
      }

      setTextVerified(pafResults.meta.postcode);

      // Point to NNI A/B End switch
      if (freshStart) {
        const updatePostcode = isPointLeft(id, productType) ? setPostcodeAEndAction : setPostcodeBEndAction;
        dispatch(updatePostcode(pafResults.meta.postcode));
      }

      const payload = buildAvailabilityCheckRequestPayload(pafResults, initialisedFromData);

      onChange(payload, id);
    } catch (error) {
      setErrorPostcode(getLookupError(error, value));
    } finally {
      setLoadingOnNet(false);
      setLoadingPAF(false);
    }
  };

  const onPostcodeSubmit = async (text: string, initialisedFromData = false) => {
    setErrorPostcode(undefined);
    setErrorALK(undefined);
    updateHasNNATAvailable(false);

    if (isALK(text)) {
      await loadALK(text);
    } else {
      await loadBroadLocations(initialisedFromData, text);
    }
  };

  const onPAFAddressSelect = (address: DropdownOption) => {
    if (!Array.isArray(resultsPAF)) return;

    const match = findAddressByUdprn(address.value, resultsPAF) || null;
    setSelectedAddressPAF(match);
    setSelectedAddressOnNet(null);

    const payload = { ...availabilityCheck.sources[id] };

    if (isPointLeft(id, productType)) {
      delete payload.a_end_data_centre_id;
      onChange(payload, id);
      dispatch(setFullAddressAEndAction(match));
    } else {
      delete payload.b_end_data_centre_id;
      onChange(payload, id);
      dispatch(setFullAddressBEndAction(match));
    }
  };

  const onOnNetAddressSelect = (address: DropdownOption) => {
    if (!Array.isArray(resultsOnNet)) return;

    const match = findOnNetAddressByItsLabel(address.label, resultsOnNet);
    setSelectedAddressOnNet(match || null);
    setSelectedAddressPAF(null);
    if (isPointLeft(id, productType)) {
      if (match?.attributes.pop_id) {
        onChange(
          {
            ...availabilityCheck.sources[id],
            a_end_data_centre_id: match.attributes.pop_id,
          },
          id
        );
      }
      dispatch(setFullAddressAEndAction(match || null, address.onNetType || null));
    } else {
      if (match?.attributes.pop_id) {
        onChange(
          {
            ...availabilityCheck.sources[id],
            b_end_data_centre_id: match.attributes.pop_id,
          },
          id
        );
      }
      dispatch(setFullAddressBEndAction(match || null, address.onNetType || null));
    }
  };

  useEffect(() => {
    if (onSelectedAddressPAFChanged) onSelectedAddressPAFChanged(selectedAddressPAF);
  }, [selectedAddressPAF]);

  const clearSelectedAddress = () => {
    setSelectedAddressOnNet(null);
    setSelectedAddressPAF(null);

    // Point to NNI A/B End switch
    if (isPointLeft(id, productType)) {
      dispatch(setFullAddressAEndAction(null));
    } else {
      dispatch(setFullAddressBEndAction(null));
    }
    onChange(
      {
        ...availabilityCheck.sources[id],
        [id === 'point_left' ? 'a_end_postcode' : 'b_end_postcode']: textVerified,
      },
      id
    );
  };

  const selectAddress = (address: DropdownOption | null) => {
    if (address === null) {
      clearSelectedAddress();
    } else if (address.addressType === AddressType.ON_NET) {
      onOnNetAddressSelect(address);
    } else {
      onPAFAddressSelect(address);
    }
  };

  return (
    <div className={className} data-testid={id}>
      <h4 className="h5">Point</h4>
      <PostcodeInput
        id={id}
        initialText={initialPostcode}
        onSubmit={onPostcodeSubmit}
        isLoading={isLoading}
        alkError={errorALK}
        postcodeError={errorPostcode}
      />

      <MapsDisplay
        markersOptions={mapMarkersFilteredBySelectedAddressPAF()}
        onMarkerClick={changeSelectedAddressPAFOnMarkerClick}
        address={referencePAFAddress}
      />

      {hasAvailableNNAT && isNNATEnabledFor(productType) && <NNATCapablePostcode />}

      <FTTXLocationInformation resultsALK={resultsALK} />

      {Array.isArray(resultsOnNet) && Array.isArray(resultsPAF) && (
        <>
          <AddressesCombined
            addresses={{ onNet: resultsOnNet, paf: resultsPAF }}
            selectedAddress={selectedAddressOnNet || selectedAddressPAF}
            addressNotListed={
              isPointLeft(id, productType) ? !!quoteMeta.a_end_address_not_listed : !!quoteMeta.b_end_address_not_listed
            }
            onAddressNotListed={(isNotListed) => {
              dispatch(
                isPointLeft(id, productType)
                  ? setAddressNotListedAEnd(isNotListed)
                  : setAddressNotListedBEnd(isNotListed)
              );
            }}
            onSelect={selectAddress}
          />
          {!selectedAddressOnNet && !selectedAddressPAF && <SelectStreetAddressInfo />}
        </>
      )}

      {selectedAddressOnNet && <SelectedOnNetInfoMessage />}

      {productType && selectedAddressPAF && productTypeHasFTTX(productType) && (
        <OpenreachAddressCapture
          addressRetrieval={noop}
          addresses={
            // Point to NNI A/B End switch
            end.addressesFound
          }
          enableAddressNotListedMenu={false}
          addressNotListed={false}
          onAddressNotListed={noop}
          onResults={(_, addresses) => {
            dispatch(
              // Point to NNI A/B End switch
              isPointLeft(id, productType) ? setAddressesFoundAEnd(addresses) : setAddressesFoundBEnd(addresses)
            );
          }}
          resultsFilter={openreachGoldFilter}
          onOpenreachAddressSubmit={(address) => {
            dispatch(
              setOpenreachAddress(
                // Point to NNI A/B End switch
                isPointLeft(id, productType) ? 'A' : 'B',
                address
              )
            );
            onChange(
              {
                ...availabilityCheck.sources[id],
                [id === 'point_left' ? 'a_end_postcode' : 'b_end_postcode']: textVerified,
              },
              id
            );
          }}
          pafAddress={selectedAddressPAF.attributes}
          postcode={textVerified}
          selectedAddress={
            // Point to NNI A/B End switch
            end.openreachAddress
          }
        />
      )}
    </div>
  );
};

function extractPAFAddress(location: ILocation) {
  if (location.addressType && location.addressType !== 'ON_NET') {
    return location.fullAddress as IPAFAddressSite | null;
  }
}

function extractOnNetAddress(location: ILocation) {
  if (location.addressType && location.addressType === 'ON_NET') {
    return location.fullAddress as IOnNetSite | null;
  }
}

function isPointLeft(id: Source, productType: ProductType | null | undefined) {
  return id === 'point_left' && productType !== ProductType.P2NNI;
}

const FTTXLocationInformation = ({ resultsALK }: { resultsALK: ALKLocation | undefined }) => {
  if (!resultsALK) return <></>;
  return (
    <>
      {isFTTXCompatibleOpenreachLocation(resultsALK) ? (
        <div className="alk-result p-3">
          <CompatibleFTTXLocation
            address={concatenateOpenReachAddress(resultsALK.attributes)}
            selectedALK={resultsALK}
          />
        </div>
      ) : (
        <div className="mt-2">
          <IncompatibleFTTXLocation />
        </div>
      )}
    </>
  );
};

function SelectedOnNetInfoMessage() {
  return (
    <Alert alertType={Alerts.INFO}>
      <div className="row no-gutters">
        <Column defaultWidth={1} classNames={['text-right', 'on-net-info-icon']}>
          <Icon name="info_outline" />
        </Column>
        <Column defaultWidth={11}>
          <p className="pl-2 pt-3">{QUOTE_ON_NET_3RD_PARTY_MESSAGE}</p>
        </Column>
      </div>
    </Alert>
  );
}

function SelectStreetAddressInfo() {
  return (
    <Alert alertType={Alerts.INFO}>
      <p className="mb-0">
        <strong>Select your street address to quote for EoFTTC/P services</strong>
        <br />
        Select the street address (and location, if required) to check availability for EoFTTC or EoFTTP. If you
        can&apos;t find the address but still wish to quote for these services, speak to your Account Manager.
      </p>
    </Alert>
  );
}

export const Point = styled(UnstyledPoint)`
  small {
    font-size: 0.95rem;
    color: ${(props) => props.theme.colours.grey60};
  }

  input {
    margin-right: 0.8em;
    max-width: 100%;
    text-transform: uppercase;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};

    &[disabled] {
      cursor: not-allowed;
    }
  }

  .point-input {
    color: ${(props) => props.theme.colours.grey90};
    padding-left: 1em;
    height: 2.5em;
  }

  .find-btn {
    margin: 0.25em 0 0.5em 0;
    height: 2.5em;
    width: 115px;
  }

  .alk-result {
    background-color: ${(props) => props.theme.colours.secondaryC5};
  }

  .on-net-info-icon {
    padding-top: 1em;
  }
`;
