import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export enum AdditionalInfoStyles {
  Info = 'info',
  Error = 'error',
}

interface IAdditionalInfoTopPane {
  mainStyle?: AdditionalInfoStyles;
  className?: string;
}

const AdditionalInfoTopPane: FunctionComponent<React.PropsWithChildren<IAdditionalInfoTopPane>> = ({
  mainStyle = AdditionalInfoStyles.Info,
  className,
  children,
}) => (
  <div className={cx(className, mainStyle, 'additionalInfoPane')}>
    <p>{children}</p>
  </div>
);

const StyledAdditionalInfoTopPane = styled(AdditionalInfoTopPane)`
  margin-bottom: 1em;
  border-radius: 5px;

  &.error {
    background-color: ${(props) => props.theme.colours.secondaryA5};
  }

  &.info {
    background-color: ${(props) => props.theme.colours.secondaryC5};
  }

  p {
    margin-bottom: 0;
    padding: 1em;
    vertical-align: middle;
    display: inline-flex;

    i {
      padding-right: 0.2em;
    }

    a {
      text-decoration: underline;
    }
  }
`;

export default StyledAdditionalInfoTopPane;
