import React, { useEffect, useRef, useState } from 'react';

export function usePreviousState(state: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  }, [state]);
  return ref.current;
}

export function useOutsideClick(ref: React.RefObject<HTMLDivElement>, callback: () => void) {
  // Due to the React 18 Upgrade? Handle Click is being called on the initial component render,
  // sees the originally clicked element as not the modal, and immediately closes it.
  // This solves that problem by ensuring the component has rendered at least once before accepting a handleClick
  const [initialised, setInitialised] = useState(false);
  useEffect(() => {
    setInitialised(true);
  }, []);

  const handleClick = (event: any) => {
    if (initialised && ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
}
