/** @jsxRuntime classic */
import 'react-app-polyfill/stable';
import ReactGA from 'react-ga';
import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';

import App from './App';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';

import './index.css';
import { setupDates } from 'utils/dateUtil';
import { createRoot } from 'react-dom/client';

registerLocale('en-GB', enGB);
setDefaultLocale('en-GB');

setupDates();

if (process.env.REACT_APP_GA) {
  ReactGA.initialize(process.env.REACT_APP_GA, {
    testMode: process.env.NODE_ENV === 'test',
    titleCase: false,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
