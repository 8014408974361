import { Exchange, ExchangeRequestStatus, SecondaryCircuits } from 'Quotes/types/store';
import { DIACircuitData, InternetAccess } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import {
  getExchangeStatus,
  HandleSelectedCircuit,
  hasAEnd,
  useExchanges,
} from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';

export function useDIACircuitData(
  secondaryCircuits: SecondaryCircuits | undefined,
  handleSelectedCircuit: HandleSelectedCircuit,
  status: ExchangeRequestStatus
): DIACircuitData {
  const aExchanges = useExchanges(
    secondaryCircuits?.aEndExchanges,
    secondaryCircuits?.selectedAEndId,
    (exchange: Exchange) => handleSelectedCircuit(getCircuitId(exchange))
  );

  const internetAccess: InternetAccess = {
    selected: undefined,
  };

  return {
    aEnd: aExchanges,
    bEnd: internetAccess,
    getSelectedCircuitId: () => getCircuitId(aExchanges.selected),
    getCircuitId: getCircuitId,
    status: () => getExchangeStatus(status, aExchanges.exchanges),
    canLoadPrices: () => !!aExchanges.selectedExchangeId,
    isLoading: false,
    processors: [hasAEnd],
    type: 'DIA',
  };
}

function getCircuitId(aEnd: Exchange | undefined) {
  if (!aEnd) return undefined;
  return `datacentre-${aEnd.popId}`;
}
