import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { useCopyToClipboard } from 'shared/hooks/useCopyToClipboard';
import { Copy } from 'shared/components/molecules/Copy';
import SpinnerWithText from '../SpinnerWithText';

type HeaderWithChildrenWrapper = FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
    header: string;
    subHeader?: string;
    displayBackground?: boolean;
    compact?: boolean;
    headerCopyToClipboardText?: string;
    isLoading?: boolean;
    isLoadingText?: string;
    extraHeaderContent?: ReactNode;
  }>
>;

const HeaderWithChildrenWrapper: HeaderWithChildrenWrapper = ({
  children,
  className,
  displayBackground = false,
  header,
  compact = false,
  subHeader,
  headerCopyToClipboardText,
  isLoading = false,
  isLoadingText = 'Loading...',
  extraHeaderContent,
}) => {
  const { copy, isRecentlyCopied } = useCopyToClipboard();

  return (
    <div
      className={cx(className, {
        'display-background': displayBackground,
        'sub-heading': subHeader,
        compact: compact,
      })}
    >
      <header>
        <div className="container">
          <h1
            className={cx({
              'display-4': displayBackground,
              'pt-4': displayBackground,
              'mb-0': subHeader,
              clickable: !!headerCopyToClipboardText,
            })}
            onClick={() => {
              if (!headerCopyToClipboardText) {
                return;
              }
              copy(headerCopyToClipboardText);
            }}
          >
            {header}
            {!!headerCopyToClipboardText && <Copy isRecentlyCopied={isRecentlyCopied} />}
          </h1>
          {subHeader && <aside>{subHeader}</aside>}
        </div>
      </header>
      <div className="container">
        {extraHeaderContent}
        <div className="content-wrapper">
          {isLoading && (
            <div className="loading-overlay">
              <SpinnerWithText text={isLoadingText} size="large" />
            </div>
          )}
          <div className={isLoading ? 'loading' : ''}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const StyledHeaderWithChildrenWrapper = styled(HeaderWithChildrenWrapper)`
  > header {
    background-color: ${(props) => props.theme.header.bgColor ?? props.theme.colours.secondaryE1};
    background-size: 650px;
    background-repeat: no-repeat;
    background-position-x: 160%;
    background-position-y: 10px;
    color: white;
    min-height: 330px;
    padding-top: 30px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.XL}) {
    > header {
      background-position-x: 140%;
    }
  }

  @media (min-width: 1050px) {
    > header {
      background-position-x: 145%;
    }
  }

  @media (min-width: 1150px) {
    > header {
      background-position-x: 135%;
    }
  }

  @media (min-width: 1300px) {
    > header {
      background-position-x: 125%;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.XXL}) {
    > header {
      background-position-x: 120%;
    }
  }

  @media (min-width: 1500px) {
    > header {
      background-position-x: 115%;
    }
  }

  @media (min-width: 1600px) {
    > header {
      background-position-x: right;
    }
  }

  @media (min-width: 1850px) {
    > header {
      background-position-x: 90%;
    }
  }

  @media (min-width: 2000px) {
    > header {
      background-position-x: 85%;
    }
  }

  @media (min-width: 2400px) {
    > header {
      background-position-x: 80%;
    }
  }

  &.display-background > header {
    background-image: ${(props) =>
      props.theme.header.style === 'image' ? `url(${props.theme.header.bgImage})` : 'none'};
  }

  > .container {
    margin-top: -235px;
    position: relative;

    .loading-overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      // min-height: 500px;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loading {
      opacity: 0.2;
    }
  }

  &.sub-heading > .container {
    margin-top: -205px;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }

  &.display-background > .container {
    margin-top: -180px;
  }

  .content-wrapper {
    background-color: white;
    box-shadow: ${(props) => props.theme.shadows.boxShadow};
    border: solid 0.5px ${(props) => props.theme.colours.secondaryC5};
    border-radius: 0.5em;
    padding: 1em 2em 2em 2em;
    min-height: 250px;
    position: relative;
  }

  &.compact .content-wrapper {
    padding: 0.75em;
  }

  .clickable {
    cursor: pointer;
  }
`;

export default StyledHeaderWithChildrenWrapper;
