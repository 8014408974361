import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface ITextArea {
  className?: string;
  placeholder?: string;
  maxLength?: number;
  inputId?: string;
  disabled?: boolean;
  onChange(text: string): void;
  labelText?: string;
  value: string | null | undefined;
  theme: {
    colours: {
      grey90: string;
    };
  };
}

const TextArea: FunctionComponent<React.PropsWithChildren<ITextArea>> = ({
  inputId,
  className,
  disabled,
  labelText,
  placeholder,
  onChange,
  maxLength,
  value,
}) => {
  const handleChange = (event: React.FormEvent<HTMLTextAreaElement>): void => {
    onChange(event.currentTarget.value);
  };

  return (
    <div className={className}>
      {labelText && (
        <label htmlFor={inputId} className="small mb-1">
          {labelText}
        </label>
      )}
      <textarea
        id={inputId}
        maxLength={maxLength}
        value={value || ''}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  );
};

const StyledTextArea = styled(TextArea)`
  padding: 1em;
  textarea {
    border: 0;
    color: ${(props) => props.theme.colours.grey90};
    max-height: 100%;
    padding: 0.5em;
    resize: vertical;
    width: 100%;
  }
`;

export default StyledTextArea;
