import { Feature } from 'FeatureFlags/types';
import hasFeatureEnabled from 'FeatureFlags/utils/hasFeatureEnabled';
import getQueryParams from 'shared/utils/getQueryParams';
import { DefaultTheme } from 'styled-components';
import { default as neosTheme } from 'theme/theme';
import { default as nrtTheme } from 'theme/themes/nrt-theme';

const themes = {
  nrt: {
    theme: nrtTheme,
    domains: ['nrt.neosnetworks.com', 'nrt.testing.digital-foundations.co.uk'],
  },
};

/**
 * Get the current theme by hostname, overridable by query param.
 * @returns the current DefaultTheme
 */
export default function getThemeByHostname(): DefaultTheme {
  if (!hasFeatureEnabled(Feature.nrtRedirecting)) return neosTheme;

  // check for query params
  const params = getQueryParams();

  if (params.theme === 'nrt') {
    return nrtTheme;
  }

  // check for hostname
  const hostname = window.location.hostname;

  for (const theme of Object.values(themes)) {
    if (theme.domains.includes(hostname)) {
      return theme.theme;
    }
  }

  // default to NEOS theme
  return neosTheme;
}
