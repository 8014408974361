import { LocationLookup } from 'Quotes/types/quoteRecordAttributesBase';
import React, { FC } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

const Paragraph = styled.p`
  font-size: 16px;
`;

const Button = styled.button`
  &.inactive {
    text-decoration: underline;
    font-weight: bold;
    color: ${(props) => props.theme.colours.primaryB1};
  }
`;

interface Props {
  activeLookupIntent?: LocationLookup;
  onSwitch(lookupType: LocationLookup): void;
}

export const LookupIntentSwitcher: FC<React.PropsWithChildren<Props>> = ({ activeLookupIntent, onSwitch }) => {
  return (
    <Paragraph>
      <Button
        type="button"
        className={cx({ inactive: activeLookupIntent === 'alk' })}
        onClick={() => {
          if (activeLookupIntent === 'alk') {
            onSwitch('postcode');
          }
        }}
      >
        Enter postcode, ALK, latitude/longitude or Eastings/Northings
      </Button>
      <span className="px-4">or</span>
      <Button
        type="button"
        className={cx({ inactive: activeLookupIntent !== 'alk' })}
        onClick={() => {
          if (activeLookupIntent !== 'alk') {
            onSwitch('alk');
          }
        }}
      >
        Enter ALK (Address Level Key)
      </Button>
    </Paragraph>
  );
};
