import { Supplier } from 'Quotes/types/supplier';

export function presentProductNameForPoPDisplay(productName: string | null, supplier: string | null | undefined) {
  if (!productName) {
    return '';
  }

  return (supplier === Supplier.OPENREACH || supplier === Supplier.CITYFIBRE) ? ` ${productName}` : '';
}

export function presentProductNameForQuoteExtraInfo(supplier: string | null, productName?: string | null) {
  return productName && (supplier === Supplier.OPENREACH || supplier === Supplier.CITYFIBRE) ? ` ${productName}` : '';
}
