import { INewNNIRequest } from 'Order/types/location';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { noop } from 'lodash';
import React from 'react';
import Column from 'shared/components/atoms/Column';
import TextInput from 'shared/components/atoms/TextInput';
import { OptionGroup } from 'shared/components/organisms/OptionGroup/OptionGroup';
import styled from 'styled-components';

interface INewNNIRequestFormProps {
  identifierPrefix: string;
  dataCentre?: string | null;
  customerName?: string | null;
  onChange: (field: keyof INewNNIRequest, value: any) => void;
  newNNIRequest: INewNNIRequest;
}

const bearerOptions = [
  { label: '1 Gbps', value: BearerType.MEDIUM },
  { label: '10 Gbps', value: BearerType.LARGE },
  { label: '100 Gbps', value: BearerType.LARGER },
];

const termOptions = [
  { label: '1 year', value: 1 },
  { label: '2 years', value: 2 },
  { label: '3 years', value: 3 },
  { label: '4 years', value: 4 },
  { label: '5 years', value: 5 },
];

export const NewNNIRequestForm = ({
  identifierPrefix,
  onChange,
  newNNIRequest,
  customerName,
  dataCentre,
}: INewNNIRequestFormProps) => {
  return (
    <Container className="row">
      <Column defaultWidth={12}>
        {dataCentre && (
          <TextInput
            readOnly={true}
            inputIdentifierPrefix={`${identifierPrefix}`}
            fieldName="_new_nni_request_data_centre"
            onChange={noop}
            labelText="Data centre"
            value={dataCentre}
          />
        )}

        {customerName && (
          <TextInput
            readOnly={true}
            inputIdentifierPrefix={`${identifierPrefix}`}
            fieldName="_new_nni_request_customer_name"
            onChange={noop}
            labelText="Customer"
            value={customerName}
          />
        )}
      </Column>

      <Column defaultWidth={12}>
        <label className="small mb-1">Capacity</label>
        <OptionGroup
          options={bearerOptions}
          value={newNNIRequest?.capacity}
          onClick={(e) => onChange('capacity', e)}
        ></OptionGroup>
      </Column>
      <Column defaultWidth={12}>
        <label className="small mb-1">Term</label>

        <OptionGroup
          options={termOptions}
          value={newNNIRequest?.termLengthInYears}
          onClick={(e) => onChange('termLengthInYears', e)}
        />
      </Column>
      <Column defaultWidth={8}>
        <TextInput
          inputIdentifierPrefix={`${identifierPrefix}`}
          fieldName="_new_nni_request_contact_email"
          onChange={(e) => onChange('contactEmail', e.target.value)}
          labelText="Customer contact email address"
          value={newNNIRequest?.contactEmail}
          type="email"
        />
      </Column>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  direction: column;
  row-gap: 2rem;
`;
