import { ResourceTypes } from 'Request';

export interface ONATAddressAttributes {
  street?: string | null;
  full_address?: string | null;
  postcode?: string | null;
  onat_capable?: boolean;
  building_name?: string | null;
  building_number?: string | null;
  county?: string | null;
  town?: string | null;
}

export interface ONATAddressResponseItem {
  attributes: ONATAddressAttributes;
  type: ResourceTypes.onat_address;
  id: string;
}

export interface ONATAddressesResponse {
  data: ONATAddressResponseItem[];
}

export interface FlatONATAddressResponseItem extends ONATAddressAttributes {
  id: ONATAddressResponseItem['id'];
  type: ONATAddressResponseItem['type'];
}

export const isValidONATAddressResponseItem = (
  item: Partial<FlatONATAddressResponseItem>
): item is ONATAddressResponseItem => {
  return item.type === ResourceTypes.onat_address && !!item.full_address;
};
