import React, { FunctionComponent, useState } from 'react';
import Button, { ButtonMainStyle } from '../../shared/components/atoms/Button';
import { forgotPassword } from '../cognito';
import { ErrorMessage, parseErrorMessage } from '../errorMessage';
import ConfirmPassword from './ConfirmPassword';
import Alert from '../../shared/components/atoms/Alert';
import TextInput from '../../shared/components/atoms/TextInput';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import Wrapper from '../shared/Wrapper';
import { useLocationWithState } from 'shared/hooks/useLocationWithState';

const ForgotPassword: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  useTrackPage(pages.resetPassword);

  const location = useLocationWithState();

  const [username, setUsername] = useState(location.state?.username || '');
  const [error, setError] = useState<string | undefined>();
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    setShowDialog(true);
    setError(undefined);
  };
  const onFailure = (e: ErrorMessage) => {
    setLoading(false);
    setError(parseErrorMessage(e));
  };
  const onResetPassword = () => {
    setLoading(true);
    forgotPassword(username, onSuccess, onFailure);
  };

  return (
    <Wrapper>
      <h1>Forgot your password?</h1>

      {error && <Alert id="forgot-password-error">{error}</Alert>}

      <TextInput
        fieldName="username"
        onChange={(e) => setUsername(e.target.value.trim().toLowerCase())}
        labelText="Email"
        value={username}
        readOnly={showDialog}
      />

      {!showDialog && (
        <div className="mt-4">
          <Button
            id="forgot-password"
            mainStyle={ButtonMainStyle.PrimaryRectangular}
            onClick={onResetPassword}
            disabled={username.trim() === ''}
            loading={loading}
          >
            Reset password
          </Button>
        </div>
      )}

      {showDialog && <ConfirmPassword username={username} />}
    </Wrapper>
  );
};

export default ForgotPassword;
