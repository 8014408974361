import { setFilteredPricedData, togglePriceBandwidthFilter } from 'Quotes/actions';
import { selectPricing } from 'Quotes/selectors';
import { IPriceData } from 'Quotes/types/store';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'shared/components/atoms/Checkbox';
import Column from 'shared/components/atoms/Column';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import { useOutsideClick } from 'shared/utils/customHooks';
import styled from 'styled-components';
import FilterButton from '../shared/FilterButton';
import { DropdownMenu } from '../shared/DropdownMenu';

interface Props {
  className?: string;
}

const isString = (bandwidth: IPriceData['bandwidth']): bandwidth is string => {
  return !!bandwidth;
};

const UnstyledBandwidthFilter: FunctionComponent<React.PropsWithChildren<Props>> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const pricing = useSelector(selectPricing);
  const [uniqueBandwidths] = useState(
    [...new Set(pricing.allPrices.map((price) => price.bandwidth).filter(isString))].sort((a, b) => {
      if (parseInt(a, 10) < parseInt(b, 10)) {
        return -1;
      }
      if (parseInt(a, 10) > parseInt(b, 10)) {
        return 1;
      }
      return 0;
    })
  );

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <div className={`${className} position-relative`} ref={ref}>
      <FilterButton onClick={() => setIsOpen(!isOpen)} data-testid="bandwidth-filter-label" isOpen={isOpen}>
        Bandwidth
      </FilterButton>
      {isOpen && (
        <DropdownMenu>
          <span>Bandwidth</span>
          <ul className="list-unstyled pt-3 mb-0">
            {uniqueBandwidths.map((bandwidth, i) => {
              if (!bandwidth) {
                return null;
              }

              return (
                <li className="row no-gutters" key={i}>
                  <Column lgWidth={7}>{formatBandwidth(bandwidth)}</Column>
                  <Column lgWidth={5}>
                    <Checkbox
                      inputId={`price_bandwidth_filter_${bandwidth}`}
                      onChange={() => {
                        dispatch(togglePriceBandwidthFilter(bandwidth));
                        dispatch(setFilteredPricedData());
                      }}
                      value={pricing.bandwidthFilterList.includes(bandwidth)}
                    />
                  </Column>
                </li>
              );
            })}
          </ul>
        </DropdownMenu>
      )}
    </div>
  );
};

export const BandwidthFilter = styled(UnstyledBandwidthFilter)`
  z-index: 1;
`;
