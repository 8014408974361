import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import Alert, { Alerts } from '../../../shared/components/atoms/Alert';
import Icon from '../../../shared/components/atoms/Icon';
import SpinnerWithText from '../../../shared/components/molecules/SpinnerWithText';
import { APIQuoteListItem, APIQuotesResponse } from '../../types/apiQuote';
import QuotesTable from './QuotesTable';
import { IAppState } from '../../../reducers';
import Column from '../../../shared/components/atoms/Column';
import { useTablePaging } from '../../../shared/hooks/useTablePaging';
import { pages, useTrackPage } from '../../../shared/utils/trackPage';
type Props = {
  selectedCompanyId: string;
};

export const ERROR_MESSAGE = 'Error in fetching quotes';

function transformAPIQuoteDataToListItems(response: APIQuotesResponse): APIQuoteListItem[] {
  return response.data.map((quote) => {
    return {
      ...quote.attributes,
      id: quote.id,
    };
  });
}

const ShowAPIQuotes: FunctionComponent<React.PropsWithChildren<Props>> = ({ selectedCompanyId }) => {
  useTrackPage(pages.apiQuotes);
  const paging = useTablePaging({
    baseUrl: `/lq-quotes`,
    dataTransform: transformAPIQuoteDataToListItems,
    extraParams: {
      origin: 'lq-native',
      customer_id: selectedCompanyId,
    },
  });

  if (paging.fetchAttempts === 1 && !paging.isLoading && paging.error) {
    return <Alert>{ERROR_MESSAGE}</Alert>;
  }

  if (paging.isLoading && paging.fetchAttempts === 0) {
    return <SpinnerWithText id="api-quotes-spinner" text="Retrieving API quotes" size="large" />;
  }

  if (paging.fetchAttempts === 1 && !paging.isLoading && paging.results.length === 0) {
    return (
      <Alert alertType={Alerts.INFO}>
        <div className="row no-gutters">
          <Column defaultWidth={1} offsetDefaultWidth={2} classNames={['text-right', 'pt-4']}>
            <Icon name="info_outline" />
          </Column>
          <Column defaultWidth={6}>
            <p className="pl-2 pt-3">
              No API quote data is available to you at the moment. Please speak to your account manager if you believe
              this to be an error.
            </p>
          </Column>
        </div>
      </Alert>
    );
  }

  return <QuotesTable paging={paging} />;
};

const mapStateToProps = (state: IAppState) => ({
  selectedCompanyId: state.user.companyData.selectedCompanyId!,
});

export default connect(mapStateToProps)(ShowAPIQuotes);
