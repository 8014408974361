import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Column from '../../shared/components/atoms/Column';
import Button from '../../shared/components/atoms/Button';

interface IDataCentreNotListedProps {
  className?: string;
  onClick(): void;
}

const DataCentreNotListed: FunctionComponent<React.PropsWithChildren<IDataCentreNotListedProps>> = ({
  onClick,
  className,
}) => {
  return (
    <div className={`${className} panel mb-3`}>
      <Column defaultWidth={12}>
        <h4>Find your address</h4>
        <p>
          We cannot serve Optical service outside of the listed locations as standard, but we can try to quote an
          Unprotected Ethernet based on EAD tail. Enter a post code for a quote on the next step, otherwise contact your
          Account Manager for a bespoke quote.
        </p>
        <div className="text-right">
          <Button onClick={onClick} type="button" className="agree-btn">
            I agree
          </Button>
        </div>
      </Column>
    </div>
  );
};

const StyledDataCentreNotListed = styled(DataCentreNotListed)`
  background-color: ${(props) => props.theme.colours.grey10};
  padding: 1rem;
  border-radius: 5px;

  h4 {
    color: ${(props) => props.theme.colours.secondary};
  }

  p {
    margin-top: 1rem;
    color: ${(props) => props.theme.colours.grey70};
  }

  .agree-btn {
    width: 180px;
    border-radius: 2px;
    height: 3em;
  }
`;

export default StyledDataCentreNotListed;
