import { noop, Omit } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import Modal, { IModalProps } from 'shared/components/molecules/Modal';
import { useOrderTemplates } from 'Order/crud/orderTemplatesApi';

export interface ISetDefaultOrderTemplateModalProps extends Omit<IModalProps, 'title' | 'children'> {
  orderTemplateId: string;
  customerId: string;
  useOrderTemplatesHook?: typeof useOrderTemplates;
  onClose?: () => void;
}

export const SetDefaultOrderTemplateModal = ({
  orderTemplateId,
  customerId,
  useOrderTemplatesHook = useOrderTemplates,
  onClose = noop,
  ...props
}: ISetDefaultOrderTemplateModalProps) => {
  const orderTemplates = useOrderTemplatesHook(customerId);

  const setAsDefaultAction = () =>
    orderTemplates
      .setAsDefault(orderTemplateId)
      .then(() => {
        toast.success('Order template set as default');
        onClose();
      })
      .catch(() => {
        toast.error('Failed to set order template as default');
      });

  return (
    <Modal
      {...props}
      title="Set default order template"
      onConfirm={setAsDefaultAction}
      disableActions={orderTemplates.isLoading}
      onClose={onClose}
    >
      Setting this order template as default will overwrite the current default order template. Are you sure you want to
      proceed?
    </Modal>
  );
};
