import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

interface IBadgeProps extends PropsWithChildren<any> {
  className?: string;
  rounded?: boolean;
  variant?: 'info' | 'warning';
  'data-testid'?: string;
}
const UnstyledBadge: FC<React.PropsWithChildren<IBadgeProps>> = (props) => {
  return (
    <div className={props.className} data-testid={props['data-testid']}>
      <div className={`container ${props.variant ?? 'info'} ${props.rounded ? 'rounded-shadow' : ''}`}>
        {props.children}
      </div>
    </div>
  );
};
export const Badge = styled(UnstyledBadge)`
  .container {
    background-color: ${(props) => props.theme.colours.mainBackgroundColor};
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-block;
    width: min-content;
    white-space: nowrap;

    &.info {
      background-color: ${(props) => props.theme.colours.mainBackgroundColor};
    }

    &.warning {
      background-color: ${(props) => props.theme.alerts.warning.backgroundColor};
      color: ${(props) => props.theme.alerts.warning.headingColor};
    }

    &.rounded-shadow {
      font-size: 11px;
      border-radius: 50px;
      color: ${(props) => props.theme.colours.secondaryE3};
      filter: drop-shadow(2px 3px 2px rgba(0, 70, 135, 0.1));
      padding: 6px 16px;
    }
  }
`;
