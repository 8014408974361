import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';

export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (copied && !timer) {
      setCopied(true);
      setTimer(
        setTimeout(() => {
          setCopied(false);
          setTimer(null);
        }, 1500)
      );
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
        setCopied(false);
        setTimer(null);
      }
    };
  }, [copied, timer]);

  return {
    copy: (text: string) => {
      copy(text);
      setCopied(true);
    },
    isRecentlyCopied: copied,
  };
};
