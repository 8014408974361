import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import React from 'react';
import RadioButton from 'shared/components/molecules/RadioButton';
import { bearerForDisplay } from 'shared/utils/connectionCapacity';

export function BearerRadioGroup({
  selectedBearer,
  bearers,
  setBearer,
}: {
  selectedBearer: BearerType | undefined;
  bearers: BearerType[];
  setBearer: (bearer?: BearerType) => void;
}) {
  return (
    <div>
      {bearers.map((bearerValue) => (
        <BearerRadioButton
          key={bearerValue}
          portSpeed={bearerValue}
          onClick={() => {
            setBearer(bearerValue);
          }}
          isSelected={selectedBearer === bearerValue}
        />
      ))}
    </div>
  );
}

function BearerRadioButton(props: { portSpeed: BearerType; onClick: () => void; isSelected: boolean }) {
  return (
    <RadioButton
      inline
      size="medium"
      description={bearerForDisplay(props.portSpeed)}
      id={`bearer--${props.portSpeed}`}
      onClick={props.onClick}
      status={props.isSelected ? 'selected' : 'notSelected'}
    />
  );
}
