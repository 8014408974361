import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import HeaderWithChildrenWrapper from '../../shared/components/molecules/HeaderWithChildrenWrapper';
import { showFullName } from '../../shared/utils/nameHelper';
import { IAppState } from '../../reducers';
import { IUserAttributesBaseFrontendRoles } from '../types/user';
import EditableCard from '../../shared/components/molecules/EditableCard';
import UserProfileReadOnly from './UserProfileReadOnly';
import EditDetails from './EditDetails';

interface IUserProfile {
  userData: IUserAttributesBaseFrontendRoles;
}

export const UserProfile: FunctionComponent<React.PropsWithChildren<IUserProfile>> = ({ userData }) => {
  const closedCardContent = <UserProfileReadOnly />;
  const openCardContent = <EditDetails />;

  return (
    <HeaderWithChildrenWrapper header={showFullName(userData.firstName, userData.lastName)}>
      <EditableCard closedCardContent={closedCardContent} openCardContent={openCardContent} />
    </HeaderWithChildrenWrapper>
  );
};

const mapStateToProps = (state: IAppState) => ({
  userData: state.user.userData,
});

export default connect(mapStateToProps, null)(UserProfile);
