import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from 'shared/components/atoms/Icon';

export type QuestionProps = {
  className?: string;
  question: string;
  answer: string[];
  isOpen: boolean;
  onToggle(): void;
};

const QuestionTitle = styled.button`
  position: relative;

  i {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Question: FC<React.PropsWithChildren<QuestionProps>> = ({ className, question, isOpen, answer, onToggle }) => {
  return (
    <div>
      <QuestionTitle className={`${className} h5 question pl-4 font-weight-bold`} onClick={onToggle}>
        <Icon name={isOpen ? 'remove' : 'add'} />
        {question}
      </QuestionTitle>
      {isOpen && (
        <div className="content pl-4">
          {answer.map((part, i) => {
            return <p key={i}>{part}</p>;
          })}
        </div>
      )}
    </div>
  );
};

export { Question };
