import React, { FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';

import Column from '../../atoms/Column';
import { InfoPane } from '../../organisms/InfoPane';
import Button, { ButtonMainStyle } from '../../atoms/Button';

interface ICardRowProps {
  children: ReactNode;
  className?: string;
  id?: string;
  infoPaneTitle?: string;
  renderPaneContent?(): ReactNode;
  title: string;
  subtitle?: string;
  theme: {
    colours: {
      grey50: string;
      secondary: string;
    };
  };
}

const CardRow: FunctionComponent<React.PropsWithChildren<ICardRowProps>> = ({
  children,
  id,
  renderPaneContent,
  infoPaneTitle,
  className,
  title,
  subtitle,
}) => {
  const [paneShow, setPaneShow] = useState(false);

  return (
    <section className={className} id={id}>
      <div className="row no-gutters">
        <Column smWidth={12} mdWidth={12} lgWidth={3}>
          <h4>{title}</h4>
          {subtitle && <h5 className="ml-2">{subtitle}</h5>}
          {renderPaneContent && (
            <Button className="info-btn" onClick={() => setPaneShow(true)} mainStyle={ButtonMainStyle.Link}>
              More information
            </Button>
          )}
          {paneShow && (
            <InfoPane onClose={() => setPaneShow(false)} title={infoPaneTitle!} className="card-row-more-info-pane">
              {typeof renderPaneContent === 'function' && renderPaneContent()}
            </InfoPane>
          )}
        </Column>
        <Column smWidth={12} mdWidth={12} lgWidth={9}>
          {children}
        </Column>
      </div>
    </section>
  );
};

const StyledCardRow = styled(CardRow)`
  h4 {
    color: ${(props) => props.theme.colours.secondary};
  }

  .info-btn {
    color: ${(props) => props.theme.colours.grey50};
  }
`;

export default StyledCardRow;
