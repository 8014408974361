import { BackendRoleName, backendRoleNameToRoleMapping, IUserRoleFromBackend, Role, roles } from '../types/role';
import { Company } from '../types/user';
import uniq from 'lodash/uniq';

export function backendRoleNameForRole(role: Role): BackendRoleName {
  return roles[role].backendRoleName;
}

export const groupCompaniesById = (rolesForUser: IUserRoleFromBackend[]): Company[] => {
  return rolesForUser
    .filter((role) => role.customer_id !== '*')
    .reduce((acc: Company[], role) => {
      if (!acc.find((item) => item.id === role.customer_id)) {
        acc.push({
          id: role.customer_id,
          name: role.customer_name,
        });
      }

      return acc;
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const getRolesForCompany = (companyId: string, rolesForUser: IUserRoleFromBackend[]): Role[] =>
  uniq(
    translateBackendRolesToFrontend(
      rolesForUser.filter((role) => role.customer_id === companyId || role.customer_id === '*')
    )
  );

export const translateBackendRolesToFrontend = (rolesForUser: IUserRoleFromBackend[]): Role[] => {
  return rolesForUser.map((role) => backendRoleNameToRoleMapping[role.role]);
};

export function roleValues(): Role[] {
  return Object.keys(Role) as Role[];
}

export function customerRoleValues(): Role[] {
  return Object.keys(Role).filter((role) => !role.includes('SSE')) as Role[];
}

export function sseRoleValues(): Role[] {
  return Object.keys(Role).filter((role) => role.includes('SSE')) as Role[];
}

export function sseRoleValuesExcludingPricing(): Role[] {
  return sseRoleValues().filter((role) => role !== Role.SSEPricing);
}

export function roleForDisplay(role: Role): string {
  if (role.includes('SSE')) {
    return role.replace('SSE', '').replace(/([a-z])([A-Z])/, (_, $1, $2) => {
      return `${$1} ${$2}`;
    });
  }
  return role;
}

export function examplesForRole(role: Role): string[] {
  return roles[role].examples;
}

export function titleForRole(role: Role): string {
  return roles[role].title;
}

export function anyRolesMatch(rolesForUser: Role[], toCheck: Role[]): boolean {
  return rolesForUser.some((r) => toCheck.includes(r));
}

export function userHasRole(role: Role, userRoles: Role[]): boolean {
  return userRoles.includes(role);
}
