import React from 'react';
import PriceSummarySegment from './PriceSummarySegment';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';

interface MDIAPricesProps {
  isManagedDIA: boolean;
  mdiaRouterPrice: number;
  mdiaEngineerPrice: number;
  mdiaRackMountKitPrice: number;
  engineerInstallationRequired: boolean;
}

const MDIAPrices: React.FC<MDIAPricesProps> = ({
  isManagedDIA,
  mdiaRouterPrice,
  mdiaEngineerPrice,
  mdiaRackMountKitPrice,
  engineerInstallationRequired,
}) => {
  if (!isManagedDIA) return null;

  return (
    <>
      {featureFlag.isEnabled(Feature.mdiaPriceBreakdown) && mdiaRouterPrice > 0 && (
        <PriceSummarySegment label="Includes managed DIA router at" value={mdiaRouterPrice} />
      )}
      {engineerInstallationRequired && mdiaEngineerPrice > 0 && (
        <PriceSummarySegment label="Includes engineer installation at" value={mdiaEngineerPrice} />
      )}
      {mdiaRackMountKitPrice > 0 && (
        <PriceSummarySegment label="Includes rack mount kit at" value={mdiaRackMountKitPrice} />
      )}
    </>
  );
};

export default MDIAPrices;
