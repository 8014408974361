import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import getQuotesFromBulkQuote from 'Quotes/crud/getQuotesFromBulkQuote';
import { IBulkQuoteByIdRecord } from 'Quotes/types/bulkQuoteById';
import Alert from 'shared/components/atoms/Alert';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import StyledHeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import { multiQuotes, quoteById } from 'Quotes/Routes';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import { usePreviousState } from 'shared/utils/customHooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectNameOfSelectedCompany, selectSelectedCompanyId } from 'User/selectors';
import BulkQuoteHeader, { SelectedQuotesPrompt } from '../BulkQuoteById/QuotesFromBulkTable/BulkQuoteHeader';
import { lowerCase, noop } from 'lodash';
import MultiSelectTable, { IRow, RowCell } from 'shared/components/organisms/MultiSelectTable';
import headers from './headers';
import { isCheckboxCell } from 'shared/utils/tableHelper';
import Button from 'shared/components/atoms/Button';
import hasFeatureEnabled from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { pluralize } from 'shared/utils/pluralize';
import { IQuoteListItem } from 'Quotes/types/quoteRecord';
import { QuoteStatus } from 'Quotes/types/quoteRecordAttributesBase';
import { CSSProperties, useTheme } from 'styled-components';
import { createMultipleOrders, createMultipleOrdersCleanup } from 'Quotes/actions';
import { MultiOrderSummaryModal } from './components/MultiOrderSummaryModal';
import { selectbulkOrderCreateState } from 'Order/selectors';
import { QuoteNotOrderableTooltip } from './components/QuoteNotOrderableTooltip';
import { QuoteIsPOATooltip } from './components/QuoteIsPOATooltip';
import { useNavigate, useParams } from 'react-router-dom';

type Params = {
  multiQuoteId?: string;
};

const selectedQuotesPrompt: SelectedQuotesPrompt = (count) => {
  return <span>{`You have ${count} ${pluralize('quote', count)} selected`}</span>;
};

interface SelectedQuotesCTA {
  selectedQuotesCount: number;
  onClick?: () => void;
  loading?: boolean;
}

export const SelectedQuotesCTA: FunctionComponent<React.PropsWithChildren<SelectedQuotesCTA>> = ({
  selectedQuotesCount,
  onClick,
  loading = false,
}) => (
  <Button onClick={onClick} loading={loading}>
    Proceed {pluralize('quote', selectedQuotesCount)} to order
  </Button>
);

const MultiQuoteById: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  useTrackPage(pages.multiQuotesById);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { multiQuoteId } = useParams<Params>();
  const companyId = useSelector(selectSelectedCompanyId);
  const companyName = useSelector(selectNameOfSelectedCompany);
  const prevCompanyId = usePreviousState(companyId);
  const [fetchError, setError] = useState<string>('');
  const [bulkQuotesData, setBulkQuotesData] = useState<IBulkQuoteByIdRecord>();
  const [selectedQuotes, setSelectedQuotes] = useState<IRow[]>([]);
  const theme = useTheme();
  const bulkOrderCreateState = useSelector(selectbulkOrderCreateState);
  const [showMultiOrderSummaryModal, setShowMultiOrderSummaryModal] = useState(false);

  const bulkOrderCreationHasFeedback =
    bulkOrderCreateState &&
    !bulkOrderCreateState?.creating.inProgress &&
    (bulkOrderCreateState.successfullyCreatedOrders.length > 0 ||
      bulkOrderCreateState.successfullyCreatedPOAQuotes.length > 0 ||
      bulkOrderCreateState.failedQuotes.length > 0);

  useEffect(() => {
    setShowMultiOrderSummaryModal(bulkOrderCreationHasFeedback);
  }, [bulkOrderCreationHasFeedback]);

  const fetchQuotesFromBulkQuote = useCallback(() => {
    if (multiQuoteId) {
      getQuotesFromBulkQuote(multiQuoteId)
        .then((result) => {
          const data = result.data;
          setBulkQuotesData(data);
        })
        .catch(() => {
          setError('Error in fetching quotes');
        });
    }
  }, [multiQuoteId]);

  useEffect(() => {
    fetchQuotesFromBulkQuote();
  }, [fetchQuotesFromBulkQuote, multiQuoteId]);

  useEffect(() => {
    if (prevCompanyId && companyId !== prevCompanyId) {
      navigate(multiQuotes);
    }
  }, [companyId, navigate, prevCompanyId]);

  const rowClickHandler = (cell: RowCell) => {
    if (isCheckboxCell(cell)) return;
    navigate(quoteById(cell.row.original.id));
  };

  let content = <SpinnerWithText text="Retrieving quotes" size="large" />;

  const disabledRowCSS = (row: IQuoteListItem): CSSProperties => {
    if (row.state === QuoteStatus.ORDERED) {
      return { backgroundColor: theme.colours.grey20 };
    }
    return {};
  };

  const getCheckboxTooltip = (row: IQuoteListItem) => {
    if (row.is_poa) return <QuoteIsPOATooltip />;
    else if (!row.is_orderable) return <QuoteNotOrderableTooltip />;
  };

  const submitMultipleOrders = () => {
    if (!bulkQuotesData) return;

    dispatch(createMultipleOrders(getSelectedQuotes(), companyName));
  };

  const getSelectedQuotes = () => {
    if (!bulkQuotesData || !bulkQuotesData.attributes.bulk_lines) return [];

    const selectedQuotesId = selectedQuotes.map((item) => lowerCase(item.id));
    return bulkQuotesData.attributes.bulk_lines.filter((quote) => {
      return selectedQuotesId.includes(lowerCase(quote.short_id!));
    });
  };

  const closeOrderCreationSummaryModal = () => {
    setShowMultiOrderSummaryModal(false);
    dispatch(createMultipleOrdersCleanup());
    fetchQuotesFromBulkQuote();
  };

  if (multiQuoteId && bulkQuotesData) {
    content = (
      <div data-testid="multi-quotes-table">
        <BulkQuoteHeader
          downloading={{}} // n/a for multi-quote
          downloadQuotes={noop} // n/a for multi-quote
          selectedQuotes={selectedQuotes}
          companyName={bulkQuotesData.attributes.company_name}
          bulkQuoteId={bulkQuotesData.attributes.short_id}
          quotesCompleted={bulkQuotesData.attributes.completed_entries}
          reference={bulkQuotesData.attributes.multiquote_label}
          isMulti
          selectedQuotesPrompt={selectedQuotesPrompt}
          selectedQuotesCTA={
            <SelectedQuotesCTA
              selectedQuotesCount={selectedQuotes.length}
              onClick={submitMultipleOrders}
              loading={bulkOrderCreateState && bulkOrderCreateState.creating.inProgress}
            />
          }
        />
        {hasFeatureEnabled(Feature.multiQuoteToOrder) ? (
          <MultiSelectTable
            headers={headers}
            rows={bulkQuotesData.attributes.bulk_lines || []}
            rowClickHandler={rowClickHandler}
            onCheckedChange={(items) => setSelectedQuotes(items)}
            className="quotes-from-multi-table"
            defaultSort={{
              id: 'created_at',
              desc: true,
            }}
            disableCheckBoxes={(row: IQuoteListItem) => !row.is_orderable && !row.is_poa}
            checkboxTooltip={getCheckboxTooltip}
            rowStyle={disabledRowCSS}
          />
        ) : (
          <MultiSelectTable
            headers={headers}
            rows={bulkQuotesData.attributes.bulk_lines || []}
            rowClickHandler={rowClickHandler}
            className="quotes-from-multi-table"
            defaultSort={{
              id: 'created_at',
              desc: true,
            }}
          />
        )}

        {showMultiOrderSummaryModal && (
          <MultiOrderSummaryModal
            successfullyCreatedOrders={bulkOrderCreateState.successfullyCreatedOrders}
            successfullyCreatedPOAQuotes={bulkOrderCreateState.successfullyCreatedPOAQuotes}
            failedQuotes={bulkOrderCreateState.failedQuotes}
            onClose={closeOrderCreationSummaryModal}
          />
        )}
      </div>
    );
  } else if (fetchError !== '') {
    content = <Alert>{fetchError}</Alert>;
  }

  return (
    <StyledHeaderWithChildrenWrapper header="Quotes" compact={true}>
      {content}
    </StyledHeaderWithChildrenWrapper>
  );
};

export { MultiQuoteById };
