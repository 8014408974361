// _____   __                      _____   __    _____                       ______
// ___  | / /__________________    ___  | / /______  /___      _________________  /_________
// __   |/ /_  _ \  __ \_  ___/    __   |/ /_  _ \  __/_ | /| / /  __ \_  ___/_  //_/_  ___/
// _  /|  / /  __/ /_/ /(__  )     _  /|  / /  __/ /_ __ |/ |/ // /_/ /  /   _  ,<  _(__  )
// /_/ |_/  \___/\____//____/      /_/ |_/  \___/\__/ ____/|__/ \____//_/    /_/|_| /____/
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { checkDomainThenRedirect } from 'shared/utils/redirectHelper';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import HeadTagsByTheme from 'theme/HeadTagsByTheme';
import getThemeByHostname from 'theme/utils/getThemeByHostname';
import setupFontsByTheme from 'theme/utils/setupFontsByTheme';
import AdminRouter from '../Admin/AdminRouter';
import AlertsList from '../Alerts/AlertsList';
import Forbidden from '../Auth/Forbidden';
import ForgotPassword from '../Auth/ForgotPassword';
import { forbidden, login, resetPassword } from 'Auth/routes';
import SignIn from '../Auth/SignIn';
import OrderRouter from '../Order/OrderRouter';
import ReportsRouter from '../Reports/ReportsRouter';
import { PersistorContext, setupStore } from 'store';
import UserProfile from '../User/UserProfile';
import Welcome from 'Welcome/Welcome';
import AuthenticatedLayout from './AuthenticatedLayout';
import { LocationChangeScrollTop } from './LocationChangeScrollTop';
import MaintenanceMode from './MaintenanceMode';
import NotFound from './NotFound';
import UnauthenticatedLayout from './UnauthenticatedLayout';
import { default as neosTheme } from '../theme/theme';
import { base as faqBase } from 'FAQ/routes';
import { FAQ } from 'FAQ';
import { base as promoOfferBase } from 'PromoOffer/routes';
import PromoOfferSettings from 'PromoOffer/components/PromoOfferSettings';

import Messages from 'Messages/Messages/Messages';
import { ToastSetup } from './ToastSetup';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import QuoteRouter from 'Quotes/QuoteRouter';

const App: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  checkDomainThenRedirect();
  const [theme] = useState<DefaultTheme>(getThemeByHostname());

  useEffect(() => {
    setupFontsByTheme(theme);
  }, [theme]);

  const { store, persistor } = setupStore();

  return (
    <ThemeProvider theme={theme ?? neosTheme}>
      <HeadTagsByTheme />
      <Provider store={store}>
        <Router>
          <LocationChangeScrollTop />
          <Routes>
            <Route path="/maintenance" element={<MaintenanceMode />} />
            <Route
              element={
                <UnauthenticatedLayout>
                  <Outlet />
                </UnauthenticatedLayout>
              }
            >
              <Route path={login} element={<SignIn />} />
              <Route path={resetPassword} element={<ForgotPassword />} />
            </Route>
            <Route
              element={
                <AuthenticatedLayout>
                  <PersistorContext.Provider value={persistor}>
                    <PersistGate persistor={persistor}>
                      <Outlet />
                    </PersistGate>
                  </PersistorContext.Provider>
                </AuthenticatedLayout>
              }
            >
              <Route path={`${forbidden}/*`} element={<Forbidden />} />
              <Route path="/quotes/*" element={<QuoteRouter />} />
              <Route path="/orders/*" element={<OrderRouter />} />
              <Route path="/admin/*" element={<AdminRouter />} />
              <Route path="/alerts/*" element={<AlertsList />} />
              <Route path="/messages/*" element={<Messages />} />
              <Route path="/user/profile/*" element={<UserProfile />} />
              <Route path="/reports/*" element={<ReportsRouter />} />
              <Route path={`${promoOfferBase}/*`} element={<PromoOfferSettings />} />
              <Route path="/welcome" element={<Welcome />} />
              <Route path={`${faqBase}/*`} element={<FAQ />} />
              <Route index element={<Navigate replace to="/welcome" />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </Provider>

      <ToastSetup />
    </ThemeProvider>
  );
};

export { App };
export default App;
