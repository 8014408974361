import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { checkForNewMessages, MessageActionTypes, SendMessage, sendMessageFail, sendMessageSuccess } from './actions';
import hasFeatureEnabled from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { selectSelectedCompanyId } from 'User/selectors';
import { saveMessage } from './crud/saveMessage';
import { newQuoteMessage } from 'Quotes/actions';
import { newOrderMessage } from 'Order/actions';
import { SendMessageResponse } from 'shared/types/Messages';
import dayjs from 'dayjs';
import { toMessageResource } from 'Messages/crud/loadMessages';
import { updateUnreadCounts } from 'User/actions';

export const triggerUpdate = () => updateUnreadCounts();

export const getMessagingEnabled = () => hasFeatureEnabled(Feature.messaging);
export function* pollForMessages(): any {
  const hasMessagingEnabled = yield call(getMessagingEnabled);
  if (hasMessagingEnabled) {
    yield put(triggerUpdate());
  }

  yield delay(dayjs.duration({ minutes: 5 }).asMilliseconds());
  yield put(checkForNewMessages());
}

const messageSenderForResource = {
  quote: {
    newMessageDispatcher: newQuoteMessage,
  },
  order: {
    newMessageDispatcher: newOrderMessage,
  },
};

export function* sendMessage({ payload }: SendMessage): any {
  const selectedCompanyId = yield select(selectSelectedCompanyId);
  const resourceConfig = messageSenderForResource[payload.resourceType];

  try {
    const response: SendMessageResponse = yield call(saveMessage, {
      resourceId: payload.resourceId,
      customerId: selectedCompanyId,
      messageText: payload.message,
      resourceType: payload.resourceType,
    });
    yield put(sendMessageSuccess());
    yield put(resourceConfig.newMessageDispatcher(toMessageResource(response.data)));
  } catch (e) {
    yield put(sendMessageFail());
  }
}

export default function* rootSaga() {
  yield takeEvery(MessageActionTypes.CHECK_FOR_NEW_MESSAGES, pollForMessages);
  yield takeEvery(MessageActionTypes.SEND_MESSAGE, sendMessage);

  if (process.env.NODE_ENV !== 'test') {
    yield delay(5000);
    yield put(checkForNewMessages());
  }
}
