import { IPAddressAllocation } from '../types/diaIPAllocation';

/**
 * Generate IP label with usable addresses.
 * If the IP allocation is public only or if the product type is MDIA, then the number of unusable addresses is 3.
 * @param is_mdia
 * @param subnet
 * @param dia_ip_allocation
 * @returns
 */
export function generateIPLabelWithUsableAddresses(
  is_mdia: boolean,
  subnet: number,
  dia_ip_allocation: IPAddressAllocation | null
) {
  const unusableAddresses = dia_ip_allocation === IPAddressAllocation.PUBLIC_ALLOCATION_ONLY || is_mdia ? 3 : 2;
  const totalAddresses = 2 ** (32 - subnet);
  const baseLabel = `/${subnet} subnet`;

  let usableAddresses;
  // exception for /31 subnet
  if (subnet === 31) {
    usableAddresses = is_mdia ? 1 : 2;
  } else {
    usableAddresses = totalAddresses - unusableAddresses;
  }

  const usableAddressesLabel = `(${usableAddresses} usable IP addresses)`;
  return [baseLabel, usableAddressesLabel].join(' ');
}
