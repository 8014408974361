import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import CloudLocation from './CloudLocation';
import CloudProviders from './CloudProviders';
import classNames from 'classnames';
import { OnChange } from 'Quotes/types/availabilityCheck';

export interface ICloudConnect {
  className?: string;
  renderCloudLocation?: boolean;
  initialisedFromData?: boolean;
  onAvailabilityCheckChange?: OnChange;
}

const CloudConnect: FunctionComponent<React.PropsWithChildren<ICloudConnect>> = ({
  className,
  renderCloudLocation = true,
  initialisedFromData = false,
  onAvailabilityCheckChange,
}) => {
  return (
    <div className={className}>
      {renderCloudLocation && <CloudLocation className="inner-wrapper cloud-location" />}
      <CloudProviders
        className={classNames('inner-wrapper cloud-providers', {
          'no-cloud-location': !renderCloudLocation,
        })}
        onAvailabilityCheckChange={onAvailabilityCheckChange}
        initialisedFromData={initialisedFromData}
      />
    </div>
  );
};

const StyledCloudConnect = styled(CloudConnect)`
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  padding-left: ${(props) => (props.renderCloudLocation ? '1em' : '0')};

  .inner-wrapper {
    width: 45%;
  }
  .nni-container {
    margin-top: 1em;
  }
  .header {
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    margin: 1em 0;
    &--small-margin {
      margin-bottom: 0;
    }
  }

  .no-cloud-location .header {
    margin-top: 0;
  }
`;

export default StyledCloudConnect;
