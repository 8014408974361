import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IUserData, ProfileFields } from 'Admin/types/actions';
import PhoneInput from 'shared/components/atoms/PhoneInput';
import TextInput from 'shared/components/atoms/TextInput';
import SectionHeader from 'shared/components/atoms/SectionHeader';
import Column from 'shared/components/atoms/Column';
import { inputIdentifierPrefix } from 'Admin/AddUser/index';

const FirstNameInput = ({
  forwardedRef,
  onChange,
  firstName,
}: {
  forwardedRef: React.RefObject<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  firstName: IUserData['firstName'];
}) => (
  <TextInput
    forwardedRef={forwardedRef}
    required
    inputIdentifierPrefix={inputIdentifierPrefix}
    fieldName="firstName"
    onChange={onChange}
    labelText="First name *"
    value={firstName}
  />
);

const CompanyEmailInput = ({
  companyEmail,
  forwardedRef,
  onChange,
}: {
  forwardedRef: React.RefObject<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  companyEmail: IUserData['companyEmail'];
}) => (
  <TextInput
    classNames={['col']}
    forwardedRef={forwardedRef}
    required
    type="email"
    inputIdentifierPrefix={inputIdentifierPrefix}
    fieldName="companyEmail"
    onChange={onChange}
    helperText="this will be the username for this person"
    labelText="Company email address *"
    value={companyEmail}
  />
);

const JobTitleInput = ({
  jobTitle,
  onChange,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  jobTitle: IUserData['jobTitle'];
}) => (
  <TextInput
    inputIdentifierPrefix={inputIdentifierPrefix}
    fieldName="jobTitle"
    onChange={onChange}
    labelText="Job title/position"
    optional={true}
    value={jobTitle}
  />
);

const LastNameInput = ({
  forwardedRef,
  lastName,
  onChange,
}: {
  forwardedRef: React.RefObject<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  lastName: IUserData['lastName'];
}) => (
  <TextInput
    forwardedRef={forwardedRef}
    required
    inputIdentifierPrefix={inputIdentifierPrefix}
    fieldName="lastName"
    onChange={onChange}
    labelText="Last name *"
    value={lastName}
  />
);

const MobilePhoneInput = ({
  forwardedRef,
  mobilePhone,
  onChange,
}: {
  forwardedRef: React.RefObject<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mobilePhone: IUserData['mobilePhone'];
}) => (
  <PhoneInput
    forwardedRef={forwardedRef}
    required
    inputIdentifierPrefix={inputIdentifierPrefix}
    fieldName="mobilePhone"
    onChange={onChange}
    labelText="Mobile phone number *"
    value={mobilePhone}
  />
);

const LandlinePhoneInput = ({
  landlinePhone,
  onChange,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  landlinePhone: IUserData['landlinePhone'];
}) => (
  <PhoneInput
    inputIdentifierPrefix={inputIdentifierPrefix}
    fieldName="landlinePhone"
    onChange={onChange}
    labelText="Landline phone number"
    optional={true}
    value={landlinePhone}
  />
);

const ContactEmailInput = ({
  contactEmail,
  forwardedRef,
  onChange,
}: {
  forwardedRef: React.RefObject<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  contactEmail: IUserData['contactEmail'];
}) => (
  <TextInput
    forwardedRef={forwardedRef}
    required
    type="email"
    inputIdentifierPrefix={inputIdentifierPrefix}
    fieldName="contactEmail"
    onChange={onChange}
    labelText="Contact email address *"
    value={contactEmail}
  />
);

export const UserProfileForm = ({
  setIsValid,
  setProfileFields,
}: {
  setIsValid: (isValid: boolean) => void;
  setProfileFields: (profileFields: ProfileFields) => void;
}) => {
  const firstNameInput: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const lastNameInput: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const mobilePhoneInput: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const companyEmailInput: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const contactEmailInput: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const inputRefs = useMemo(
    () => [firstNameInput, lastNameInput, mobilePhoneInput, companyEmailInput, contactEmailInput, mobilePhoneInput],
    []
  );

  const [user, setUser] = useState<ProfileFields>({
    companyEmail: '',
    jobTitle: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    landlinePhone: '',
    contactEmail: '',
  });

  useEffect(() => {
    const isFormValid = inputRefs.map((it) => it.current).every((inputField) => !!inputField?.checkValidity());
    setIsValid(isFormValid);
    if (isFormValid) setProfileFields(user);
  }, [inputRefs, setIsValid, setProfileFields, user]);

  return (
    <div>
      <SectionHeader text="Profile" />

      <p className="my-2">The personal information for this person.</p>
      <div className="form-row">
        <Column defaultWidth={6}>
          <CompanyEmailInput
            forwardedRef={companyEmailInput}
            companyEmail={user.companyEmail}
            onChange={(e) => {
              setUser({
                ...user,
                companyEmail: e.target.value.toLowerCase(),
              });
            }}
          />
        </Column>
        <Column defaultWidth={6}>
          <JobTitleInput
            jobTitle={user.jobTitle}
            onChange={(e) => {
              setUser({
                ...user,
                jobTitle: e.target.value,
              });
            }}
          />
        </Column>
      </div>
      <div className="form-row">
        <Column defaultWidth={6}>
          <FirstNameInput
            forwardedRef={firstNameInput}
            firstName={user.firstName}
            onChange={(e) => {
              setUser({
                ...user,
                firstName: e.target.value,
              });
            }}
          />
        </Column>
        <Column defaultWidth={6}>
          <LastNameInput
            forwardedRef={lastNameInput}
            lastName={user.lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setUser({
                ...user,
                lastName: e.target.value,
              });
            }}
          />
        </Column>
      </div>
      <div className="form-row">
        <Column defaultWidth={6}>
          <MobilePhoneInput
            forwardedRef={mobilePhoneInput}
            mobilePhone={user.mobilePhone}
            onChange={(e) => {
              setUser({
                ...user,
                mobilePhone: e.target.value,
              });
            }}
          />
        </Column>
        <Column defaultWidth={6}>
          <LandlinePhoneInput
            landlinePhone={user.landlinePhone}
            onChange={(e) => {
              setUser({
                ...user,
                landlinePhone: e.target.value,
              });
            }}
          />
        </Column>
      </div>
      <div className="form-row pb-3">
        <Column defaultWidth={6}>
          <ContactEmailInput
            forwardedRef={contactEmailInput}
            contactEmail={user.contactEmail}
            onChange={(e) => {
              setUser({
                ...user,
                contactEmail: e.target.value,
              });
            }}
          />
        </Column>
      </div>
    </div>
  );
};
