import React, { FunctionComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';

import { IAppState } from '../../../../../reducers';
import { setContractTermLengthAction, toggleChosenContractTerm } from '../../../../actions';
import contractTermLengths, { toId } from './contractTermLengths';
import { Tooltip } from '../../../../../shared/components/atoms/Tooltip';
import { Option, OptionGroup } from 'shared/components/organisms/OptionGroup/OptionGroup';
import { MAX_SELECTABLE_TERM_LENGTHS } from 'Quotes/reducer';
import { Badge } from 'shared/components/atoms/Badge/Badge';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { canToggle } from 'Quotes/QuoteBuilder/utils/canToggle';

interface IContractTermLength {
  className?: string;
  contractTerm: string;
  setContractTermLength(contractTerm: string): void;
  chosenTermLengthsInMonths: string[];
  toggleContractTerm(term: string): void;
}

const ContractTermLength: FunctionComponent<React.PropsWithChildren<IContractTermLength>> = ({
  className,
  contractTerm,
  setContractTermLength,
  chosenTermLengthsInMonths,
  toggleContractTerm,
}) => {
  const contractTermOptionsSingleSelection: Option[] = contractTermLengths.map((term) => ({
    label: term.label,
    value: term.contractTermInMonths,
    customId: toId(term.label),
  }));

  const contractTermOptionsMultipleSelection: Option[] = contractTermLengths.map((term) => ({
    label: term.label,
    value: term.contractTermInMonths,
    customId: toId(term.label),
    disabled: !canToggle(term.contractTermInMonths, chosenTermLengthsInMonths, MAX_SELECTABLE_TERM_LENGTHS),
    tooltip: !canToggle(term.contractTermInMonths, chosenTermLengthsInMonths, MAX_SELECTABLE_TERM_LENGTHS)
      ? `You can only select up to ${MAX_SELECTABLE_TERM_LENGTHS} terms`
      : '',
  }));

  const toggleContractTermWithLimitation = (term: string) => {
    if (canToggle(term, chosenTermLengthsInMonths, MAX_SELECTABLE_TERM_LENGTHS)) toggleContractTerm(term);
  };

  return (
    <div className={`${className} mb-5`}>
      <h5 className="contractTermLength__pickLengthPrompt h5">
        Contract term length <Tooltip text="Amortisation is available on terms of 3 years or more." />
      </h5>

      {featureFlag.isEnabled(Feature.multiTermQuote) && (
        <>
          <p>
            Select up to <strong>{MAX_SELECTABLE_TERM_LENGTHS}</strong> contract terms.
          </p>

          <Badge variant={chosenTermLengthsInMonths.length === MAX_SELECTABLE_TERM_LENGTHS ? 'warning' : null}>
            <strong>
              {chosenTermLengthsInMonths.length} of {MAX_SELECTABLE_TERM_LENGTHS}
            </strong>{' '}
            max terms selected
          </Badge>
          <OptionGroup
            multiple
            customClassName="contractTermLength__radiogroup mt-2"
            options={contractTermOptionsMultipleSelection}
            value={chosenTermLengthsInMonths}
            onClick={toggleContractTermWithLimitation}
          />
        </>
      )}

      {!featureFlag.isEnabled(Feature.multiTermQuote) && (
        <OptionGroup
          customClassName="contractTermLength__radiogroup"
          options={contractTermOptionsSingleSelection}
          value={contractTerm}
          onClick={setContractTermLength}
        />
      )}
    </div>
  );
};

const styledContractTermLength = styled(ContractTermLength)`
  .contractTermLength__pickLengthPrompt {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 1rem;

    > span {
      margin-left: 0.3em;
    }
  }

  .contractTermLength__radiogroup {
    display: flex;
  }
`;

const mapStateToProps = (state: IAppState) => {
  return {
    contractTerm: state.quoteBuilder.quote.contractTerm,
    chosenTermLengthsInMonths: state.quoteBuilder.quote.chosen_term_lengths_in_months,
  };
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => {
  return {
    setContractTermLength: (contractTerm: string) => dispatch(setContractTermLengthAction(contractTerm)),
    toggleContractTerm: (term: string) => dispatch(toggleChosenContractTerm(term)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(styledContractTermLength);
