import React, { ChangeEvent, FunctionComponent, ReactNode, useState } from 'react';
import SectionHeader from 'shared/components/atoms/SectionHeader';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import Column from 'shared/components/atoms/Column';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { useSelector } from 'react-redux';
import { selectSelectedCompanyId } from 'User/selectors';
import { cloneDeep } from 'lodash';
import Spinner from 'shared/components/atoms/Spinner';
import Alert from 'shared/components/atoms/Alert';
import { SupplierList } from 'Admin/preferences/SupplierPreferences/SupplierPreferences';
import { presentSupplierName } from 'Quotes/utils/supplierHelpers';
import { updateCustomerAllowedSuppliers } from 'Admin/crud/updateCustomerAllowedSuppliers';

type Props = {
  className?: string;
  introText: ReactNode;
  suppliers: SupplierList[];
  updatePageStatus(readOnly: boolean, saved: boolean): void;
  updateSuppliersState(displaySupplierListState: SupplierList[]): void;
};

export const EditPreferences: FunctionComponent<React.PropsWithChildren<Props>> = ({
  className,
  introText,
  suppliers,
  updatePageStatus,
  updateSuppliersState,
}) => {
  const [pending, setPending] = useState(false);
  const [errors, setErrors] = useState(false);

  const companyId = useSelector(selectSelectedCompanyId);
  const columns = 3;
  const localSuppliers = cloneDeep(suppliers);

  const supplierColumns = [...Array(columns).keys()].map((c) => suppliers.filter((_, i) => i % columns === c));

  const onToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // string value of "Key" in the object inside of suppliers array
    const supplierKey = event.target.getAttribute('data-key');
    // Index of this changed value in the suppliers array
    const supplierIndex = suppliers.findIndex((element) => {
      return element.key === supplierKey;
    });

    localSuppliers[supplierIndex].status = event.target.checked;
  };

  const onCancelButtonClick = () => {
    updatePageStatus(true, false);
  };

  const onSubmitButtonClick = () => {
    if (pending) {
      return;
    }

    const selectedSupplierValues = localSuppliers
      .filter((localSupplier) => localSupplier.status)
      .map((localSupplier) => localSupplier.value);

    setPending(true);

    updateCustomerAllowedSuppliers(companyId, selectedSupplierValues)
      .then(() => {
        updateSuppliersState(localSuppliers);
        updatePageStatus(true, true);
      })
      .catch(() => {
        setErrors(true);
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div className={className}>
      <div className="row no-gutters button-wrapper">
        <div className="col-4 offset-8">
          <Button
            data-testid="preferences-button-edit-cancel"
            mainStyle={ButtonMainStyle.PrimaryOutline}
            disabled={pending}
            onClick={onCancelButtonClick}
          >
            Cancel
          </Button>
          <Button
            data-testid="preferences-button-edit-submit"
            type="submit"
            mainStyle={ButtonMainStyle.PrimaryRectangular}
            disabled={pending}
            onClick={onSubmitButtonClick}
          >
            {pending && <Spinner size="small" colour="secondary" />}
            {!pending && 'Save changes'}
          </Button>
        </div>
      </div>

      <SectionHeader text="Supplier Preferences" />

      {errors && <Alert>Unable to save changes</Alert>}

      {introText}

      <Column defaultWidth={12}>
        <div data-testid="preferences-toggles" className="row no-gutters">
          {supplierColumns.map((supplier_column, i) => {
            return (
              <Column key={'col' + i} defaultWidth={4} classNames={['admin']}>
                {supplier_column.map((supplier, i_inner) => {
                  return (
                    <div key={'col' + i + '-' + i_inner} className="row no-gutters">
                      <ToggleButton
                        toggleKey={supplier.key}
                        label={presentSupplierName(supplier.value)}
                        defaultChecked={supplier.status}
                        onToggleChange={onToggleChange}
                      />
                    </div>
                  );
                })}
              </Column>
            );
          })}
        </div>
      </Column>
    </div>
  );
};
