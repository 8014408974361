import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import headers from './headers';
import MultiSelectTable, { ISingleFilter, RowCell } from '../../../../shared/components/organisms/MultiSelectTable';
import { IUserBackendProfile } from 'User/types/backendUser';
import { isCheckboxCell } from 'shared/utils/tableHelper';
import { selectUsersTableFilters, selectUsersTablePageSize } from 'Tables/selectors';
import TableNames from '../../../../Tables/types/tableNames';
import { IFilterPair } from 'shared/types/filterPairType';
import { UpdateHeaderCellWidths } from 'shared/utils/updateHeaderCellWidth';
import { useNavigate } from 'react-router-dom';

interface IUsersTable {
  className?: string;
  users: IUserBackendProfile[];
}

const UsersTable: FunctionComponent<React.PropsWithChildren<IUsersTable>> = ({ users, className }) => {
  const navigate = useNavigate();
  const defaultPageSize = useSelector(selectUsersTablePageSize);

  const rowClickHandler = (cell: RowCell) => {
    if (!isCheckboxCell(cell)) {
      const userId = cell.row.original.id;
      navigate(`/admin/user/${userId}`);
    }
  };

  const initialFilters: ISingleFilter[] = [];

  const filterPairs: IFilterPair<'usersTable'>[] = [
    { tableHeaderId: 'user_id', storeField: 'userId' },
    { tableHeaderId: 'name', storeField: 'name' },
    { tableHeaderId: 'username', storeField: 'username' },
    { tableHeaderId: 'status', storeField: 'status' },
    { tableHeaderId: 'last_logged_in', storeField: 'lastActive' },
  ];

  const usersTableFilters = useSelector(selectUsersTableFilters);

  filterPairs.forEach((pair) => {
    const storeValue = usersTableFilters[pair.storeField];
    if (storeValue) {
      initialFilters.push({ id: pair.tableHeaderId, value: storeValue });
    }
  });

  return (
    <div className={className}>
      <MultiSelectTable
        defaultPageSize={defaultPageSize}
        headers={UpdateHeaderCellWidths(headers, TableNames.usersTable)}
        rows={users}
        rowClickHandler={rowClickHandler}
        initialFilters={initialFilters}
        className="users-table"
        defaultSort={{
          id: 'last_logged_in',
          desc: true,
        }}
        storeTableName={TableNames.usersTable}
      />
    </div>
  );
};

export default UsersTable;
