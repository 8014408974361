import { QuoteStatus } from 'Quotes/types/quoteRecordAttributesBase';
import { SupplierSelection } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection';
import React from 'react';
import styled from 'styled-components';

const PriceBlocksDiv = styled.div`
  display: flex;
`;

export const PriceBlocks = ({
  sentToCerillion,
  quoteState,
}: {
  sentToCerillion: boolean;
  quoteState: QuoteStatus | undefined;
}) => (
  <PriceBlocksDiv className="price__blocks">
    <SupplierSelection readOnly={sentToCerillion || quoteState === QuoteStatus.ORDERED} />
  </PriceBlocksDiv>
);
