import cx from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

export interface IExistingContactButtonProps {
  children?: ReactNode;
  className?: string;
  onClick(): void;
}

const ExistingContactButton: FunctionComponent<React.PropsWithChildren<IExistingContactButtonProps>> = ({
  children,
  className,
  onClick,
}) => (
  <button className={cx('existing-contact-button', className)} onClick={onClick} type="button">
    {children}
  </button>
);

const StyledExistingContactButton = styled(ExistingContactButton)`
  background-color: ${(props) => props.theme.colours.primaryB1};
  border-radius: 0.3em;
  color: white;
  cursor: pointer;
  display: inline-block;
  float: none;
  font-size: 0.9em;
  margin: 0 0.75em 0.75em 0;
  min-height: 2em;
  min-width: 6.75em;
  padding: 0.5em 0.7em 0.5em 0.7em;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
`;

export default StyledExistingContactButton;
