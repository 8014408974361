import { doRequest, Methods } from '../../../Request';

export const postMultiQuote = async (selectedCompanyId: string, multiQuoteReference: string) => {
  const body = {
    data: {
      attributes: {
        multiquote_label: multiQuoteReference,
      },
      type: 'bulk_quote_request',
    },
  };

  return doRequest({
    body,
    method: Methods.POST,
    path: `/customers/${selectedCompanyId}/bulk-quote-requests`,
  });
};
