import React from 'react';
import Column from 'shared/components/atoms/Column';
import { ProductType } from 'Quotes/types/productTypes';
import NNI from 'Location/NNI/NNI';
import OpticalDataCentres from 'Location/OpticalDataCentres';
import EthernetPoint, { EndType } from 'Location/EthernetPoint';
import CloudConnect from 'Location/CloudConnect';
import Row from 'shared/components/atoms/Column/row';
import { useSelector } from 'react-redux';
import { NNATWarning } from 'Quotes/shared/components/NNAT/NNATAlerts';
import { postcodeNotChanged } from 'Quotes/QuoteBuilder/utils/accordionPanel';
import { ILocation } from 'Quotes/types/store';
import { selectAEnd, selectBEnd } from 'Quotes/selectors';
import { isNNATEnabledFor } from 'FeatureFlags/nnatUtils';

interface Props {
  productType: ProductType | null | undefined;
}

const LocationByProductType = ({ productType }: Props): JSX.Element => {
  switch (productType) {
    case ProductType.P2P:
      return <PointToPointLocation />;

    case ProductType.P2NNI:
      return <PointToNNILocation />;

    case ProductType.OpticalP2P:
      return <OpticalPointToPointLocation />;
    case ProductType.DIA:
      return <DIALocation />;

    case ProductType.P2CCT:
      return <CloudConnectLocation />;

    case ProductType.NNI2CCT:
      return <CloudConnectLocation />;

    default:
      return <div />;
  }
};

export const hasValidLocationSelected = (address: ILocation) => {
  const aEndHasNNAT = address.addressesFound.hasNNATAddresses;
  const aEndHasAddress = !!address.fullAddress;
  const aEndValidSelection = postcodeNotChanged(address.postcode, address.draftPostcode);
  const missingNNATAEnd = aEndHasNNAT && !aEndHasAddress;
  return aEndValidSelection && missingNNATAEnd;
};

const LocationForProductType = ({ productType }: Props): JSX.Element => {
  const aEnd = useSelector(selectAEnd);
  const bEnd = useSelector(selectBEnd);

  const displayAEndWarning = hasValidLocationSelected(aEnd);
  const displayBEndWarning = hasValidLocationSelected(bEnd);

  const shouldDisplayWarning = isNNATEnabledFor(productType) && (displayAEndWarning || displayBEndWarning);
  return (
    <Row>
      <LocationByProductType productType={productType} />
      {shouldDisplayWarning && <NNATWarning />}
    </Row>
  );
};

const CloudConnectLocation = () => (
  <Column defaultWidth={12} classNames={['location-point']}>
    <CloudConnect />
  </Column>
);

const PointToPointLocation = () => (
  <>
    <Column defaultWidth={6} classNames={['location-a-end position-static']}>
      <EthernetPoint end={EndType.AEnd} />
    </Column>
    <Column defaultWidth={6} classNames={['location-b-end position-static']}>
      <EthernetPoint end={EndType.BEnd} />
    </Column>
  </>
);

const PointToNNILocation = () => (
  <>
    <Column defaultWidth={6} classNames={['location-point']}>
      <EthernetPoint end={EndType.BEnd} title="B-End Location" />
    </Column>
    <Column defaultWidth={6} classNames={['location-nni']}>
      <NNI />
    </Column>
  </>
);

const OpticalPointToPointLocation = () => (
  <Column defaultWidth={12} classNames={['location-point']}>
    <OpticalDataCentres />
  </Column>
);

const DIALocation = () => (
  <Column defaultWidth={6} classNames={['location-point']}>
    <EthernetPoint end={EndType.AEnd} title="B-End Location" />
  </Column>
);

export default LocationForProductType;
