import React, { FunctionComponent } from 'react';
import { ISiteReadiness, AccessNotice, AsbestosRegister } from 'Order/types/location';
import RadioButton from 'shared/components/molecules/RadioButton';
import Checkbox from 'shared/components/atoms/Checkbox';
import Column from 'shared/components/atoms/Column';
import TextArea from 'shared/components/atoms/TextArea';
import FormRowBlock from 'shared/components/atoms/FormRowBlock';
import FormHeader from '../FormHeader';
import { MAX_LENGTH_LONG_INPUT } from 'Order/OrderBuilder/shared/utils/validation';
import { accessNoticeForDisplay } from 'Order/OrderBuilder/shared/utils/accessNoticeForDisplay';

interface ISiteReadinessProps {
  className?: string;
  identifierPrefix: string;
  siteReadiness: ISiteReadiness;
  updateSiteReadiness(updated: ISiteReadiness): void;
}

const SiteReadinessInformation: FunctionComponent<React.PropsWithChildren<ISiteReadinessProps>> = ({
  className,
  identifierPrefix,
  updateSiteReadiness,
  siteReadiness,
}) => {
  const onChange = (field: keyof ISiteReadiness, value: any): void => {
    updateSiteReadiness({
      ...siteReadiness,
      [field]: value,
    });
  };

  return (
    <div className={className}>
      <label className="font-weight-bold">What notice is required to access site?</label>
      <div className="row mb-4">
        <Column defaultWidth={6}>
          <RadioButton
            id={`${identifierPrefix}_accessNotice_upTo48hours`}
            description={accessNoticeForDisplay(AccessNotice.UpTo48Hours)}
            onClick={() => onChange('accessNotice', AccessNotice.UpTo48Hours)}
            selected={siteReadiness.accessNotice === AccessNotice.UpTo48Hours}
          />
        </Column>

        <Column defaultWidth={6}>
          <RadioButton
            id={`${identifierPrefix}_accessNotice_over48hours`}
            description={accessNoticeForDisplay(AccessNotice.Over48Hours)}
            onClick={() => onChange('accessNotice', AccessNotice.Over48Hours)}
            selected={siteReadiness.accessNotice === AccessNotice.Over48Hours}
          />
        </Column>
      </div>

      <FormHeader title="Specify any options relevant to your site.." />

      <FormRowBlock>
        <Column>
          <Checkbox
            inputId={`${identifierPrefix}_moreThanOneTenant`}
            label="You have more than one tenant on-site."
            onChange={(val) => onChange('moreThanOneTenant', val)}
            value={siteReadiness.moreThanOneTenant}
          />
        </Column>
      </FormRowBlock>
      <FormRowBlock>
        <Column>
          <Checkbox
            inputId={`${identifierPrefix}_buildingBuiltPriorTo2000`}
            label="The building was built prior to the year 2000."
            onChange={(val) =>
              onChange('asbestosRegister', val ? AsbestosRegister.Yes : AsbestosRegister.NotBuiltPriorTo2000)
            }
            value={siteReadiness.asbestosRegister !== AsbestosRegister.NotBuiltPriorTo2000}
          />

          {siteReadiness.asbestosRegister !== AsbestosRegister.NotBuiltPriorTo2000 && (
            <div>
              <label>Is there an asbestos register available on-site?</label>
              <div className="row">
                <Column defaultWidth={6}>
                  <RadioButton
                    id={`${identifierPrefix}_asbestosRegister_yes`}
                    description="Yes"
                    onClick={() => onChange('asbestosRegister', AsbestosRegister.Yes)}
                    selected={siteReadiness.asbestosRegister === AsbestosRegister.Yes}
                  />
                </Column>

                <Column defaultWidth={6}>
                  <RadioButton
                    id={`${identifierPrefix}_asbestosRegister_no`}
                    description="No"
                    onClick={() => onChange('asbestosRegister', AsbestosRegister.No)}
                    selected={siteReadiness.asbestosRegister === AsbestosRegister.No}
                  />
                </Column>
              </div>
            </div>
          )}
        </Column>
      </FormRowBlock>
      <FormRowBlock>
        <Column>
          <Checkbox
            inputId={`${identifierPrefix}_hazardsOnSite`}
            label="Are there any hazards on site which any 3rd party engineer should be aware of?"
            onChange={(val) => onChange('hazardsOnSite', val)}
            value={siteReadiness.hazardsOnSite}
          />
          {siteReadiness.hazardsOnSite && (
            <>
              <TextArea
                maxLength={MAX_LENGTH_LONG_INPUT}
                inputId={`${identifierPrefix}_hazardsOnSiteDescription`}
                value={siteReadiness.hazardsOnSiteDescription || ''}
                onChange={(text) => onChange('hazardsOnSiteDescription', text)}
                labelText="Please explain *"
              />
              <div className="float-right text-muted mt-1">
                {(siteReadiness.hazardsOnSiteDescription || '').length} / {MAX_LENGTH_LONG_INPUT}
              </div>
            </>
          )}
        </Column>
      </FormRowBlock>

      <FormRowBlock>
        <Column>
          <Checkbox
            inputId={`${identifierPrefix}_landOwnerPermissionRequired`}
            label="Land owner permission (Wayleave) required?"
            onChange={(val) => onChange('landOwnerPermissionRequired', val)}
            value={siteReadiness.landOwnerPermissionRequired}
          />
        </Column>
      </FormRowBlock>
    </div>
  );
};

export default SiteReadinessInformation;
