import React, { FC } from 'react';
import Column from 'shared/components/atoms/Column';
import { PoP } from '../PoP';
import { useSelector } from 'react-redux';
import { selectQuote, selectSelectedPrice } from 'Quotes/selectors';
import { presentProductNameForPoPDisplay } from 'Quotes/utils/popHelper';
import { presentSupplierName } from 'Quotes/utils/supplierHelpers';
import { ProductType } from 'Quotes/types/productTypes';
import { selectOrderAddress } from 'Order/selectors';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { OrderBandwidth } from 'Order/OrderBuilder/QuoteSummary/OrderBandwidth';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import {
  getSelectedSecondaryPriceFromPrice,
  useSecondaryCircuitsWithSelectedPrice,
} from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { MultiCircuitPanel } from 'Order/OrderBuilder/QuoteSummary/P2NNI/MultiNNILocations';
import { DIAConfig } from 'Order/OrderBuilder/QuoteSummary/DIAConfig';

interface IDIAProps {
  isSSEUser?: boolean;
  bearer: BearerType | undefined;
  bandwidth: string;
  productType: ProductType;
  label?: string;
}

function DiaCircuit(props: {
  selectedPrice: IPriceData;
  sseUser: boolean | undefined;
  address: any;
  quote: IQuote;
  label?: string;
}) {
  return (
    <div className="row no-gutters mt-3">
      <Column defaultWidth={2}>
        <span className="sub-heading">Location {props.label ? `(${props.label})` : `(s)`}</span>
      </Column>
      <Column defaultWidth={2}>
        <span className="font-weight-bold">
          B-End ({presentSupplierName(props.selectedPrice.a_end_access_type || '')}
          {props.sseUser &&
            presentProductNameForPoPDisplay(
              props.selectedPrice.a_end_product_name,
              props.selectedPrice.a_end_access_type
            )}
          )
        </span>
      </Column>
      <Column defaultWidth={8}>
        <address className="mb-0" data-testid="dia-summary-address">
          {props.address}
        </address>
        <PoP
          end="A"
          port={props.quote.aEndPort}
          pop={props.selectedPrice.a_end_p_o_p}
          postcode={props.selectedPrice.a_end_p_o_p_postcode}
          exchangeType={props.selectedPrice.a_end_exchange_type}
          showExchange={true}
          productType={ProductType.DIA}
        />
      </Column>
    </div>
  );
}

function SingleCircuitDIA(props: {
  selectedPrice: IPriceData;
  sseUser: boolean | undefined;
  address: any;
  quote: IQuote;
  bandwidth: string;
  bearer: BearerType | undefined;
  productType: ProductType;
  label?: string;
}) {
  return (
    <>
      <DiaCircuit
        selectedPrice={props.selectedPrice}
        sseUser={props.sseUser}
        address={props.address}
        quote={props.quote}
        label={props.label}
      />
      <OrderBandwidth
        connectionType={props.quote.connectionType}
        bandwidth={props.bandwidth}
        selectedPrice={props.selectedPrice}
        quote={props.quote}
        bearer={props.bearer}
        aEndAccessMethod={props.quote.aEndAccessMethod}
        bEndAccessMethod={props.quote.bEndAccessMethod}
        aEndBandwidth={props.quote.aEndBandwidth}
        bEndBandwidth={props.quote.bEndBandwidth}
        productType={props.productType}
      />
      <DIAConfig circuitLabel={props.label} />
    </>
  );
}

function MultiCircuitDIA(props: {
  selectedPrice: IPriceData;
  secondarySelectedPrice: IPriceData;
  sseUser: boolean | undefined;
  address: any;
  quote: IQuote;
  bandwidth: string;
  bearer: BearerType | undefined;
  productType: ProductType;
}) {
  return (
    <>
      <MultiCircuitPanel>
        <SingleCircuitDIA
          label={'Primary'}
          selectedPrice={props.selectedPrice}
          sseUser={props.sseUser}
          address={props.address}
          quote={props.quote}
          bandwidth={props.bandwidth}
          bearer={props.bearer}
          productType={props.productType}
        />
      </MultiCircuitPanel>
      <MultiCircuitPanel>
        <SingleCircuitDIA
          label={'Secondary'}
          selectedPrice={props.secondarySelectedPrice}
          sseUser={props.sseUser}
          address={props.address}
          quote={props.quote}
          bandwidth={props.bandwidth}
          bearer={props.bearer}
          productType={props.productType}
        />
      </MultiCircuitPanel>
    </>
  );
}

export const DIA: FC<React.PropsWithChildren<IDIAProps>> = ({ isSSEUser, bearer, bandwidth, productType }) => {
  const selectedPrice = useSelector(selectSelectedPrice);
  const quote = useSelector(selectQuote);

  const { A: address } = useSelector(selectOrderAddress);

  const secondarySelectedPrice = getSelectedSecondaryPriceFromPrice(selectedPrice);
  const secondaryBandwidth = secondarySelectedPrice?.bandwidth;
  const secondaryCircuits = useSecondaryCircuitsWithSelectedPrice();

  const isRO2 = featureFlag.isEnabled(Feature.SecondaryCircuits) && secondaryCircuits.isSupported();

  return (
    <>
      {isRO2 && secondarySelectedPrice && secondaryBandwidth ? (
        <MultiCircuitDIA
          selectedPrice={selectedPrice}
          sseUser={isSSEUser}
          address={address}
          quote={quote}
          bandwidth={bandwidth}
          bearer={bearer}
          productType={productType}
          secondarySelectedPrice={secondarySelectedPrice}
        />
      ) : (
        <SingleCircuitDIA
          selectedPrice={selectedPrice}
          sseUser={isSSEUser}
          address={address}
          quote={quote}
          bandwidth={bandwidth}
          bearer={bearer}
          productType={productType}
        />
      )}
    </>
  );
};
