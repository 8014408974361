import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IOnNetSite, isOnNetSite } from 'shared/types/onNetSite';
import addressNotListedMenu, {
  ADDRESS_NOT_LISTED_PLACEHOLDER,
  DEFAULT_PLACEHOLDER,
} from '../AddressNotListed/addressNotListedMenu';
import { concatenateOnNetAddress, concatenatePAFAddress } from 'shared/utils/addresses/helperFunctions';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import { DropdownOption } from '../LocationByPostcode';
import { IPAFAddressSite } from 'shared/types/postcodeResults';
import { AddressesFound, AddressType, ILocation } from 'Quotes/types/store';
import GreyPrompt from 'shared/components/atoms/GreyPrompt';

type Props = {
  className?: string;
  addresses: Pick<AddressesFound, 'onNet' | 'paf'>;
  addressNotListed: boolean;
  menuOpenByDefault?: boolean;
  selectedAddress: IPAFAddressSite | IOnNetSite | null;
  onAddressNotListed(notListed: boolean): void;
  onSelect(address: DropdownOption | null): void;
};

export const onNetSiteMapper = (onNetType: ILocation['onNetType']) => (site: IOnNetSite) => {
  const addressLine = concatenateOnNetAddress(site.attributes);

  return {
    addressType: AddressType.ON_NET,
    onNetType,
    label: addressLine,
    value: addressLine,
  };
};

const pafSiteMapper = (site: IPAFAddressSite) => {
  const { attributes } = site;
  const addressLine = concatenatePAFAddress(attributes);

  return {
    addressType: AddressType.PAF,
    label: addressLine,
    value: attributes.udprn,
  };
};

const getDefaultValue = (
  address: IOnNetSite['attributes'] | IPAFAddressSite['attributes'] | null
): IDropdownOption | null => {
  if (address === null || !address) {
    return null;
  }

  if ('reference' in address) {
    const addressLine = concatenateOnNetAddress(address as IOnNetSite['attributes']);
    return {
      label: addressLine,
      value: addressLine,
    };
  }

  return {
    label: concatenatePAFAddress(address as IPAFAddressSite['attributes']),
    value: (address as IPAFAddressSite['attributes']).udprn,
  };
};

export const isSSEOnNetSite = (site: IOnNetSite) => {
  return typeof site.attributes.pop_id === 'string' && site.attributes.pop_id !== '0';
};

export const isColtOnNetSite = (site: IOnNetSite) => {
  return typeof site.attributes.colt_building_id === 'string' && site.attributes.colt_building_id !== '0';
};

export const SSE_SITES_FOUND_PROMPT =
  'We’ve found preferential on-net addresses for this postcode, please select one from the list below.';

const AddressesCombined: FunctionComponent<React.PropsWithChildren<Props>> = ({
  className,
  addresses,
  addressNotListed,
  onAddressNotListed,
  menuOpenByDefault,
  selectedAddress,
  onSelect,
}) => {
  const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER);
  const Menu = addressNotListedMenu({
    addressNotListed,
    onAddressNotListed,
    setPlaceholder,
  });

  useEffect(() => {
    if (addressNotListed) {
      setPlaceholder(ADDRESS_NOT_LISTED_PLACEHOLDER);
    }
  }, [addressNotListed]);

  useEffect(() => {
    if (!selectedAddress || !isOnNetSite(selectedAddress)) return;

    const selected = getDefaultValue(selectedAddress.attributes);
    if (selected === null) return;

    onSelect({
      addressType: AddressType.ON_NET,
      label: selected.label,
      onNetType: selectedAddress.attributes.colt_building_id ? 'colt' : 'sse',
      value: selected.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddressSelect = (address: DropdownOption | null) => {
    if (address) {
      onSelect(address);
      onAddressNotListed(false);
      setPlaceholder(DEFAULT_PLACEHOLDER);
    } else {
      onSelect(null);
    }
  };

  const groupedOptions = [
    {
      label: 'Choose on-net address',
      options: addresses.onNet.filter(isSSEOnNetSite).map(onNetSiteMapper('sse')),
    },
    {
      label: 'Choose 3rd Party building',
      options: addresses.onNet.filter(isColtOnNetSite).map(onNetSiteMapper('colt')),
    },
    {
      label: 'Other locations',
      options: addresses.paf.map(pafSiteMapper),
    },
  ];

  const sseLocationsArePresent = () => addresses.onNet.filter(isSSEOnNetSite).length > 0;

  return (
    <div className={className}>
      {sseLocationsArePresent() && <GreyPrompt prompt={SSE_SITES_FOUND_PROMPT} />}

      <Dropdown
        customComponents={{ Menu }}
        defaultMenuIsOpen={menuOpenByDefault}
        onChange={onAddressSelect}
        options={groupedOptions}
        placeholder={placeholder}
        className="dropdown"
        value={getDefaultValue(selectedAddress && selectedAddress.attributes) || null}
      />
    </div>
  );
};

const StyledAddressesCombined = styled(AddressesCombined)`
  .dropdown {
    padding-bottom: 1em;
  }
`;

export default StyledAddressesCombined;
