import { ConnectionType, OverallConnectionType } from 'Quotes/types/connectionType';
import Column from '../../../shared/components/atoms/Column';
import React from 'react';
import { MixedFttxBandwidth } from 'Order/OrderBuilder/QuoteSummary/MixedFttxBandwidth';
import { Bandwidth } from 'Order/OrderBuilder/QuoteSummary/Bandwidth';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';

export function OrderBandwidth(props: {
  connectionType: OverallConnectionType;
  aEndAccessMethod: ConnectionType | null;
  bEndAccessMethod: ConnectionType | null;
  aEndBandwidth: string | null;
  bEndBandwidth: string | null;
  selectedPrice: IPriceData;
  bandwidth: string;
  quote: IQuote;
  bearer: BearerType | undefined;
  productType: ProductType;
}) {
  return (
    <div className="row no-gutters mt-3">
      <Column defaultWidth={2}>
        <span className="sub-heading">Capacity</span>
      </Column>
      <Bandwidth
        connectionType={props.connectionType}
        selectedPrice={props.selectedPrice}
        bandwidth={props.bandwidth}
        quote={props.quote}
        bearer={props.bearer}
        productType={props.productType}
      />
      <MixedFttxBandwidth
        aEndAccessMethod={props.aEndAccessMethod}
        bEndAccessMethod={props.bEndAccessMethod}
        connectionType={props.connectionType}
        aEndBandwidth={props.aEndBandwidth}
        bEndBandwidth={props.bEndBandwidth}
      />
    </div>
  );
}
