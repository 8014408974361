import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CopyFromQuoteModal } from './CopyFromQuoteModal';
import { useDispatch } from 'react-redux';
import getOrder from 'Order/crud/getOrder';
import IOrderRecord from 'Order/types/orderRecord';
import IQuoteRecord from 'Quotes/types/quoteRecord';
import getQuote from 'Quotes/crud/getQuote';
import { multiQuoteCopySection } from 'Order/actions';
import { toast } from 'react-toastify';
import { GenericErrorMessage } from 'Request/components/GenericErrorMessage';

const Button = styled.button`
  color: ${(props) => props.theme.colours.primaryB1};
  position: relative;

  .button-label {
    display: inline-block;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  .icon {
    position: relative;
    display: inline-block;
    font-size: 0.95em;
    margin-left: 8px;
    top: 2px;
  }
`;

interface CopyFromQuoteButtonProps {
  orderId: string;
  bulkQuoteId: string;
  sectionToCopy: OrderSectionCopyTarget;
  onCopyComplete: () => void;
}

export const CopyFromQuoteButton = ({
  orderId,
  bulkQuoteId,
  sectionToCopy,
  onCopyComplete,
}: CopyFromQuoteButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderIdToCopyFrom, setSelectedOrderIdToCopyFrom] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [isCopying, setIsCopying] = useState(false);

  const toggleModal: React.MouseEventHandler<HTMLButtonElement> | undefined = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const copyFromQuote = async () => {
    try {
      if (!selectedOrderIdToCopyFrom) return;

      setIsCopying(true);

      const order: IOrderRecord = await getOrder(selectedOrderIdToCopyFrom);
      const quote: IQuoteRecord = await getQuote(order.data.attributes.quote_id, {
        prices: true,
        bulkQuote: true,
        aEndSupplierNNI: true,
        bEndSupplierNNI: true,
        onatAddresses: true,
        pafAddresses: true,
        messages: true,
      });

      dispatch(multiQuoteCopySection(sectionToCopy, order, quote));

      toast.success('The section has been copied successfully!');
      onCopyComplete();
    } catch (e) {
      toast.error(GenericErrorMessage);
    } finally {
      setIsCopying(false);
    }
  };

  const onOrderSelect = (id: string | null) => {
    setSelectedOrderIdToCopyFrom(id);
  };

  if (!featureFlag.isEnabled(Feature.multiQuoteCopySection)) return null;

  return (
    <>
      <Button onClick={toggleModal}>
        <span className="button-label">Copy and Paste</span>
        <span className="icon material-icons">{'content_paste_go'}</span>
      </Button>

      {showModal && (
        <CopyFromQuoteModal
          orderId={orderId}
          bulkQuoteId={bulkQuoteId}
          onOrderSelect={onOrderSelect}
          onClose={() => {
            setShowModal(false);
          }}
          onConfirm={copyFromQuote}
          disableConfirmBtn={!selectedOrderIdToCopyFrom}
          confirmBtnLoading={isCopying}
        />
      )}
    </>
  );
};

export type OrderSectionCopyTarget = 'a-end' | 'b-end' | 'billing-and-contact-info';
