import { Persistor, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import appSagas from './App/sagas';
import adminSagas from './Admin/sagas';
import alertsSagas from './Alerts/sagas';
import orderSagas from './Order/sagas';
import quoteSagas from './Quotes/sagas';
import userSagas from './User/sagas';
import messagesSagas from './Messages/sagas';
import { createContext } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from 'apiSlice';

export const PersistorContext = createContext<Persistor | null>(null);

const sagaMiddleware = createSagaMiddleware();

export const doConfigureStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(baseApi.middleware).concat(sagaMiddleware);
    },
    devTools: {
      name: `Neos Networks - LIVEQUOTE App - ${process.env.NODE_ENV}`,
      trace: process.env.NODE_ENV === 'development',
    },
  });

const store = doConfigureStore();

const persistor = persistStore(store);

const sagas = [appSagas, adminSagas, alertsSagas, orderSagas, quoteSagas, userSagas, messagesSagas];

sagas.forEach(sagaMiddleware.run);

export const setupStore = () => {
  return { store, persistor };
};
