import { doRequest } from '../../../Request';
import forOwn from 'lodash/forOwn';

type RequestParams = {
  customer_id?: string | null;
  product_type?: string | null;
  start_date?: string | null | undefined;
  end_date?: string | null | undefined;
  state?: string | null;
  b_end_port?: string | null;
  max_bandwidth?: string | null;
  min_bandwidth?: string | null;
};

const atLeastOneParamIsDefined = (requestParams: RequestParams) => {
  return Object.values(requestParams).some((e) => Boolean(e));
};

const buildPath = (requestParams: RequestParams) => {
  const pathCore = '/quotes/export';

  if (atLeastOneParamIsDefined(requestParams)) {
    const keyValuePairs: string[] = [];

    forOwn(requestParams, (value, key) => {
      if (value) {
        keyValuePairs.push(`${key}=${value}`);
      }
    });

    return `${pathCore}?${keyValuePairs.join('&')}`;
  } else {
    return pathCore;
  }
};

export const getQuotesReport = (requestParams: RequestParams): Promise<any> => {
  const path = buildPath(requestParams);

  return doRequest({
    path: path,
  });
};
