import React, { FunctionComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { IAppState } from '../../../../reducers';
import { CardState } from '../../../../shared/components/organisms/CardWithInitialText';
import { IOrderLocation, LocationLetter } from '../../../types/location';
import BEndLocationContainer from './BEndLocation';
import CardWrapper from '../shared/components/CardWrapper';
import { IOrder, IOrderMeta } from '../../../types/store';
import { IQuote } from '../../../../Quotes/types/store';
import { ProductType } from '../../../../Quotes/types/productTypes';
import { editOrderAction } from '../../../actions';
import { isCompleted } from '../../shared/utils/isCompleted';
import { CopyFromQuoteButton } from 'Order/OrderBuilder/CopyFromQuote/CopyFromQuoteButton';
import { isSupplierNNAT } from 'Quotes/utils/isPriceNNAT';

interface IBEndLocation {
  order: IOrder;
  quote: IQuote;
  isNNAT: boolean;
  orderId: string;
  cardState: CardState;
  saving?: boolean;
  onSaveClick(orderId: string, locationA: IOrderLocation, locationB: IOrderLocation, orderMeta: IOrderMeta): void;
  setCardState(state: CardState): void;
}

export const BEndLocation: FunctionComponent<React.PropsWithChildren<IBEndLocation>> = ({
  quote,
  order,
  isNNAT,
  orderId,
  saving,
  cardState,
  onSaveClick,
  setCardState,
}) => {
  const { locationA, locationB } = order;

  const bEndPostcode = locationB.locationData.readonly_postcode;

  if (
    order.productType === ProductType.DIA ||
    order.productType === ProductType.P2CCT ||
    order.productType === ProductType.NNI2CCT
  ) {
    return null;
  }

  return (
    <CardWrapper
      cardState={cardState}
      locationLetter={LocationLetter.B}
      completed={isCompleted(locationB, quote, isNNAT)}
      saving={saving}
      onSaveClick={() => onSaveClick(orderId, locationA, locationB, order.orderMeta)}
      locationDescription={bEndPostcode}
      setCardState={setCardState}
      extraButtons={
        !!quote.bulkQuoteId && (
          <CopyFromQuoteButton
            orderId={orderId}
            bulkQuoteId={quote.bulkQuoteId}
            sectionToCopy="b-end"
            onCopyComplete={() => {
              setCardState(CardState.Edit);
            }}
          />
        )
      }
    >
      <BEndLocationContainer
        order={order}
        quote={quote}
        postcode={bEndPostcode}
        cardState={cardState}
        locationLetter={LocationLetter.B}
      />
    </CardWrapper>
  );
};

const mapStateToProps = ({
  quoteBuilder: {
    quote,
    pricing: {
      selectedPrice: { b_end_access_type },
    },
  },
  orderBuilder: {
    id: orderId,
    updating: { inProgress },
    order,
  },
}: IAppState) => ({
  orderId: orderId!,
  order: order!,
  quote: quote!,
  saving: inProgress,
  orderMeta: order.orderMeta,
  isNNAT: isSupplierNNAT(b_end_access_type),
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  onSaveClick: (orderId: string, locationA: IOrderLocation, locationB: IOrderLocation, orderMeta: IOrderMeta) =>
    dispatch(editOrderAction(orderId, { locationA, locationB, orderMeta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(BEndLocation);
