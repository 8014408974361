import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import SectionHeader from '../../../../shared/components/atoms/SectionHeader';
import Column from '../../../../shared/components/atoms/Column';
import CompanySelector, { ICompanySelectorOption } from '../CompanySelector';
import CompaniesRolesTable, { IRow } from '../CompaniesRolesTable';
import { WildcardBar } from '../WildcardBar';
import { CompanyData } from 'User/types/user';
import { BackendRoleName, IUserRoleFromBackend, Role } from 'User/types/role';
import { customerRoleValues, sseRoleValuesExcludingPricing } from 'User/utils/roles';
import {
  addAllCompaniesToRolesRows,
  addCompanyToRolesRow,
  assignExistingRolesToCompanyRolesRows,
  buildInitialCompanyEnabledList,
  checkIfAllEditableCompaniesHaveARole,
  disableAllCompanies,
  enableAllCompanies,
  hasNoRoles,
  removeAllCompaniesFromRolesRows,
  removeCompanyFromRolesRow,
  setStatusForARoleInEditableCompanies,
  toggleCompanyEnabled,
  toggleRoleForCompany,
  translateBackendRolesToCompanyRolesRows,
} from 'Admin/utils/companyRolesRows';
import { RolesInfo } from 'Admin/shared/components/CompaniesAndRoles/RolesInfo';

interface IProps extends CompanyAndRolesData, UserType {
  isAdmin?: boolean;
  showDescription?: boolean;
}

interface UserType {
  isSSEUser: boolean;
}

interface CompanyAndRolesData {
  companyData: CompanyData;
  initialData?: IUserRoleFromBackend[];
  readonly?: boolean;
  onChange?(companiesRolesRows: IRow[], invalidSelections: boolean, wildcardRoles: BackendRoleName[]): void;
}

interface RoleData {
  initialData: IUserRoleFromBackend[];
}

interface Companies {
  companySelectorOptions: ICompanySelectorOption[];
  roles: Role[];
}

interface ToggleableCompanies extends Companies {
  enableAllCompanies: () => void;
  disableAllCompanies: () => void;
  toggleCompanyEnabled: (companyId: string) => void;
}

export interface IRoleHeader {
  role: Role;
  isChecked: boolean;
  toggleColumnStatus(status: boolean): void;
}

const Description = ({ admin }: { admin: boolean }) => (
  <p className="is-admin-text">
    {admin
      ? 'This person has been assigned the following roles and permissions.'
      : 'Your permissions are locked, contact your administrator to make any changes.'}
  </p>
);

const CompaniesAndRoles: FC<IProps> = ({
  companyData,
  initialData,
  isAdmin = false,
  isSSEUser,
  onChange,
  readonly = false,
  showDescription = true,
}) => {
  // Without this, the 'initial company list' is constantly reset since [] !== []
  const initialRoleData = useMemo(() => initialData ?? [], [initialData]);

  return (
    <>
      <SectionHeader text="Companies &amp; roles" />

      <RolesInfo sseUser={isSSEUser} />

      {showDescription && <Description admin={isAdmin} />}

      <CompaniesAndRolesWrapper
        isSSEUser={isSSEUser}
        companyData={companyData}
        initialData={initialRoleData}
        onChange={onChange}
        readonly={readonly}
      />
    </>
  );
};

const CompaniesAndRolesWrapper: FC<CompanyAndRolesData & RoleData & UserType> = ({
  companyData,
  initialData,
  isSSEUser,
  onChange,
  readonly = false,
}) => {
  const initialCompanyList = useMemo(() => buildInitialCompanyEnabledList(companyData.companies, initialData), [
    companyData.companies,
    initialData,
  ]);
  const roles = useMemo(() => (isSSEUser ? sseRoleValuesExcludingPricing() : customerRoleValues()), [isSSEUser]);

  if (readonly)
    return (
      <ReadOnlyCompaniesAndRoles
        companyData={companyData}
        initialData={initialData}
        companySelectorOptions={initialCompanyList}
        isSSEUser={isSSEUser}
        roles={roles}
      />
    );
  else
    return (
      <WritableCompaniesAndRoles
        companyData={companyData}
        initialData={initialData}
        onChange={onChange}
        readonly={readonly}
        isSSEUser={isSSEUser}
        roles={roles}
        companySelectorOptions={initialCompanyList}
      />
    );
};

const WritableCompaniesAndRoles: FC<CompanyAndRolesData & Companies & UserType> = ({
  companyData,
  initialData = [],
  onChange,
  isSSEUser,
  roles,
  companySelectorOptions: initialState,
}) => {
  const [companyOptions, setCompanyOptions] = useState<ICompanySelectorOption[]>(initialState);

  useEffect(() => {
    // Reset selected companies when toggling user to avoid mismatched state
    setCompanyOptions(initialState);
  }, [initialState, isSSEUser]);

  const doEnableAllCompanies = useCallback(() => setCompanyOptions(enableAllCompanies(companyOptions)), [
    companyOptions,
    setCompanyOptions,
  ]);
  const doDisableAllCompanies = useCallback(() => setCompanyOptions(disableAllCompanies(companyOptions)), [
    companyOptions,
    setCompanyOptions,
  ]);
  const doToggleCompany = useCallback(
    (companyId: string) => {
      setCompanyOptions(toggleCompanyEnabled(companyId, companyOptions));
    },
    [companyOptions, setCompanyOptions]
  );

  if (isSSEUser)
    return (
      <InternalCompaniesAndRoles
        companyData={companyData}
        initialData={initialData}
        onChange={onChange}
        companySelectorOptions={companyOptions}
        enableAllCompanies={doEnableAllCompanies}
        disableAllCompanies={doDisableAllCompanies}
        toggleCompanyEnabled={doToggleCompany}
        roles={roles}
      />
    );
  else
    return (
      <ExternalCompaniesAndRoles
        companyData={companyData}
        initialData={initialData}
        onChange={onChange}
        companySelectorOptions={companyOptions}
        enableAllCompanies={doEnableAllCompanies}
        disableAllCompanies={doDisableAllCompanies}
        toggleCompanyEnabled={doToggleCompany}
        roles={roles}
      />
    );
};

const noWildcards: BackendRoleName[] = [];

const ExternalCompaniesAndRoles: FC<CompanyAndRolesData & ToggleableCompanies> = ({
  companyData,
  initialData = [],
  onChange,
  companySelectorOptions,
  enableAllCompanies,
  disableAllCompanies,
  toggleCompanyEnabled,
}) => {
  const [companiesRolesRows, setCompaniesRolesRows] = useState<IRow[]>(
    assignExistingRolesToCompanyRolesRows(
      translateBackendRolesToCompanyRolesRows(initialData, companyData.companies),
      false
    )
  );

  useEffect(() => {
    if (!onChange) return;
    onChange(companiesRolesRows, hasNoRoles(companiesRolesRows), noWildcards);
  }, [onChange, companiesRolesRows]);

  const roles = customerRoleValues();
  const tableRoles = useMemo(
    () =>
      roles.map((role) => ({
        role: role,
        isChecked: checkIfAllEditableCompaniesHaveARole(companiesRolesRows, role),
        toggleColumnStatus: (status: boolean) =>
          setCompaniesRolesRows(setStatusForARoleInEditableCompanies(role, status, companiesRolesRows)),
      })),
    [companiesRolesRows, roles]
  );

  const onSelectAll = () => {
    enableAllCompanies();
    setCompaniesRolesRows(addAllCompaniesToRolesRows(companiesRolesRows, false, companySelectorOptions));
  };

  const onSelectNone = () => {
    disableAllCompanies();
    setCompaniesRolesRows(removeAllCompaniesFromRolesRows(companiesRolesRows));
  };

  return (
    <>
      <div className="d-flex row">
        <Column defaultWidth={5} offsetDefaultWidth={7}>
          <CompanySelector
            options={companySelectorOptions}
            onSelectAll={onSelectAll}
            onSelectNone={onSelectNone}
            onToggle={(toggledOption, enabled) => {
              toggleCompanyEnabled(toggledOption.value);

              setCompaniesRolesRows(
                enabled
                  ? addCompanyToRolesRow(toggledOption, companiesRolesRows, false)
                  : removeCompanyFromRolesRow(toggledOption.value, companiesRolesRows)
              );
            }}
          />
        </Column>
      </div>

      <CompaniesRolesTable
        onRoleToggle={(companyId, role) => {
          setCompaniesRolesRows(toggleRoleForCompany(companyId, role, companiesRolesRows));
        }}
        readonly={false}
        roles={tableRoles}
        rows={companiesRolesRows}
      />
    </>
  );
};

const InternalCompaniesAndRoles: FC<CompanyAndRolesData & ToggleableCompanies> = ({
  companyData,
  initialData = [],
  onChange,
  companySelectorOptions,
  enableAllCompanies,
  disableAllCompanies,
  toggleCompanyEnabled,
  roles,
}) => {
  const [companiesRolesRows, setCompaniesRolesRows] = useState<IRow[]>(
    assignExistingRolesToCompanyRolesRows(
      translateBackendRolesToCompanyRolesRows(initialData, companyData.companies),
      true
    )
  );
  const [wildcardRoles, setWildcardRoles] = useState(
    initialData.filter((item) => item.customer_id === '*').map((item) => item.role)
  );

  useEffect(() => {
    if (!onChange) return;
    onChange(companiesRolesRows, hasNoRoles(companiesRolesRows), wildcardRoles);
  }, [onChange, companiesRolesRows, wildcardRoles]);

  const tableRoles = useMemo(
    () =>
      roles.map((role) => ({
        role: role,
        isChecked: checkIfAllEditableCompaniesHaveARole(companiesRolesRows, role),
        toggleColumnStatus: (status: boolean) =>
          setCompaniesRolesRows(setStatusForARoleInEditableCompanies(role, status, companiesRolesRows)),
      })),
    [companiesRolesRows, roles]
  );

  const onSelectAll = () => {
    enableAllCompanies();
    setCompaniesRolesRows(addAllCompaniesToRolesRows(companiesRolesRows, true, companySelectorOptions));
  };

  const onSelectNone = () => {
    disableAllCompanies();
    setCompaniesRolesRows(removeAllCompaniesFromRolesRows(companiesRolesRows));
  };

  const hasWildcards = wildcardRoles.length > 0;

  return (
    <>
      <WildcardBar
        onRoleToggle={(role) => {
          if (wildcardRoles.includes(role)) {
            setWildcardRoles(wildcardRoles.filter((item) => item !== role));
          } else {
            setWildcardRoles([...wildcardRoles, role]);
          }
        }}
        roles={wildcardRoles}
        readonly={false}
      />

      {!hasWildcards && (
        <div className="d-flex row">
          <Column defaultWidth={5} offsetDefaultWidth={7}>
            <CompanySelector
              options={companySelectorOptions}
              onSelectAll={onSelectAll}
              onSelectNone={onSelectNone}
              onToggle={(toggledOption, enabled) => {
                toggleCompanyEnabled(toggledOption.value);

                setCompaniesRolesRows(
                  enabled
                    ? addCompanyToRolesRow(toggledOption, companiesRolesRows, true)
                    : removeCompanyFromRolesRow(toggledOption.value, companiesRolesRows)
                );
              }}
            />
          </Column>
        </div>
      )}

      {!hasWildcards && (
        <CompaniesRolesTable
          onRoleToggle={(companyId, role) => {
            setCompaniesRolesRows(toggleRoleForCompany(companyId, role, companiesRolesRows));
          }}
          readonly={false}
          roles={tableRoles}
          rows={companiesRolesRows}
        />
      )}
    </>
  );
};

const ReadOnlyCompaniesAndRoles: FC<CompanyAndRolesData & Companies & UserType> = ({
  companyData,
  initialData = [],
  isSSEUser,
  roles,
}) => {
  const [companiesRolesRows] = useState<IRow[]>(
    assignExistingRolesToCompanyRolesRows(
      translateBackendRolesToCompanyRolesRows(initialData, companyData.companies),
      isSSEUser
    )
  );
  const [wildcardRoles] = useState(initialData.filter((item) => item.customer_id === '*').map((item) => item.role));

  const buildRolesColumnsWithTheirStatus = (): IRoleHeader[] => {
    return roles.map((role) => ({
      role: role,
      isChecked: checkIfAllEditableCompaniesHaveARole(companiesRolesRows, role),
      toggleColumnStatus: () => undefined,
    }));
  };

  const hasWildcards = wildcardRoles.length > 0;

  if (isSSEUser && hasWildcards)
    return <WildcardBar onRoleToggle={() => undefined} roles={wildcardRoles} readonly={true} />;
  else
    return (
      <CompaniesRolesTable
        onRoleToggle={() => undefined}
        readonly={true}
        roles={buildRolesColumnsWithTheirStatus()}
        rows={companiesRolesRows}
      />
    );
};

export default CompaniesAndRoles;
