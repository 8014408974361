import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'shared/components/molecules/Dropdown';
import { ILocation, IPriceData, IQuote } from 'Quotes/types/store';
import { Loading } from '../Loading/Loading';
import { selectNNILabel, selectSelectedPrice } from 'Quotes/selectors';
import { ProductType } from 'Quotes/types/productTypes';
import {
  ADDRESS_NOT_LISTED_PLACEHOLDER,
  DEFAULT_PLACEHOLDER,
} from 'Location/AddressNotListed/addressNotListedMenu'; //addressNotListedMenu, 
import { doRequest, Methods } from 'Request';
import { IPAFAddress } from 'shared/types/postcodeResults';
import { setFullAddressAEndAction, setFullAddressBEndAction, updatePriceOrderable } from 'Quotes/actions';
import { Supplier } from 'Quotes/types/supplier';

const NO_ADDRESSES_TEXT = 'There are no servicable addresses available at this postcode. Please select an alternative supplier or contact your account manager for more details.';
const SELECT_ADDRESS_TEXT = 'This pricing is available at selected locations for this Postcode. Please select a location:';
const ADDRESS_NOT_LISTED_SELECTED_TEXT = 'If your address is not listed please select an alternative supplier or contact your account manager for more details.';

export interface IAddressOption {
  label: string;
  value: string;
  address: any;
}

export interface SupplierAddressSelectorParams {
  isReadOnly: boolean;
  quote: IQuote;
}

const udprn = (address: IAddressOption | ILocation | null): string | null => {
  if (!address) return null;

  // Custom type guard
  const isIAddressOption = (address: any): address is IAddressOption => {
    return address && 'address' in address && 'attributes' in address.address;
  };
  const isILocation = (address: any): address is ILocation => {
    return address && 'fullAddress' in address && 'attributes' in address.fullAddress;
  };

  if (isIAddressOption(address)) {
    return (address as IAddressOption).address.attributes.udprn;
  }
  if (isILocation(address as ILocation)) {
    return ((address as ILocation).fullAddress?.attributes as IPAFAddress).udprn;
  }
  return null;
};

export const SupplierAddressSelectorContainer = ({
  shouldRender,
  isReadOnly,
  addressData,
  quote,
  price,
}: {
  shouldRender: boolean;
  isReadOnly: boolean;
  quote: IQuote;
  price: IPriceData;
  addressData: {
    endA: {
      addresses: IAddressOption[],
      show: boolean,
      title: string,
      detail: string,
    },
    endB: {
      addresses: IAddressOption[],
      show: boolean,
      title: string,
      detail: string,
    },
  }
}) => {
  if (!shouldRender) return null;

  const notListedOption = { label: ADDRESS_NOT_LISTED_PLACEHOLDER, value: "NA", address: { attributes: {} } }
  if (addressData.endA.addresses.length > 0 && !addressData.endA.addresses.some((opt) => opt.value === "NA"))
    addressData.endA.addresses.push(notListedOption)
  if (addressData.endB.addresses.length > 0 && !addressData.endB.addresses.some((opt) => opt.value === "NA"))
    addressData.endB.addresses.push(notListedOption)

  // Add local states for selected addresses
  const [selectedA, setSelectedA] = useState<IAddressOption | null>();
  const [selectedB, setSelectedB] = useState<IAddressOption | null>();

  useEffect(() => {
    setSelectedA(addressData.endA.addresses.find((it) => udprn(it) === udprn(quote.location.aEnd)) ?? null);
    setSelectedB(addressData.endB.addresses.find((it) => udprn(it) === udprn(quote.location.bEnd)) ?? null);
  }, [addressData.endA.addresses, addressData.endB.addresses]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedA || udprn(selectedA) === udprn(quote.location.aEnd)) return;
    
    const updatedAddress = {
      hasChanged: true,
      ...quote.location.aEnd.fullAddress,                   // Clone the fullAddress object
      attributes: {
        ...quote.location.aEnd.fullAddress?.attributes,     // Clone the existing attributes
        ...selectedA.address.attributes,                    // Merge the found address attributes
        post_town: selectedA.address.attributes.town,       // Explicitly set or overwrite fields that dont match names
        thoroughfare: selectedA.address.attributes.street,
      }
    };

    dispatch(setFullAddressAEndAction(updatedAddress, null))
    //dispatch(setOpenreachAddress("A", null)) // alreay set as part of setFullAddressBEndAction
  }, [selectedA]);
  
  useEffect(() => {
    if (!selectedB || udprn(selectedB) === udprn(quote.location.bEnd)) return;

    const updatedAddress = {
      hasChanged: true,
      ...quote.location.bEnd.fullAddress,                   // Clone the fullAddress object
      attributes: {
        ...quote.location.bEnd.fullAddress?.attributes,     // Clone the existing attributes
        ...selectedB.address.attributes,              // Merge the found address attributes
        post_town: selectedB.address.attributes.town, // Explicitly set or overwrite fields that dont match names
        thoroughfare: selectedB.address.attributes.street,
      }
    };

    dispatch(setFullAddressBEndAction(updatedAddress, null))
    //dispatch(setOpenreachAddress("B", null)) // alreay set as part of setFullAddressBEndAction
  }, [selectedB]);

  useEffect(() => {
    let orderableA = false, orderableB = false, orderable = false;

    if(addressData.endA.show === false) {
      if(addressData.endA.detail === NO_ADDRESSES_TEXT) {
        orderableA = false;
      } else {
        orderableA = true;
      }
    } else {
      if(!selectedA || selectedA.value === "NA") {
        orderableA = false;
      } else if(selectedA) {
        orderableA = true;
      }
    }

    if(addressData.endB.show === false) {
      if(addressData.endB.detail === NO_ADDRESSES_TEXT) {
        orderableB = false;
      } else {
        orderableB = true;
      }
    } else {
       if(!selectedB || selectedB.value === "NA") {
        orderableB = false;
      } else if(selectedB){
        orderableB = true;
      }
    }

    orderable = orderableA && orderableB

    if(price.is_price_orderable !== orderable){
      dispatch(updatePriceOrderable(price.id, orderable))
    }
  }, [selectedA, selectedB]);

  return (
    <OuterContainer className={isReadOnly ? 'readonly' : ''}>
      <div className="header">
        <h3>Price Options</h3>
        <div>{SELECT_ADDRESS_TEXT}</div>
      </div>
      <div className='content'>
        <AddressContainer>
          {!addressData.endA.show && <div style={{ maxWidth: '50%' }}>
            <h4>{addressData.endA.title}</h4>
            <div>{addressData.endA.detail}</div>
          </div>}
          {addressData.endA.show && <div style={{ maxWidth: '50%' }}>
            <h4>{addressData.endA.title}</h4>
            <Dropdown
              className='select'
              // customComponents={{ Menu }}
              placeholder={DEFAULT_PLACEHOLDER}
              options={addressData.endA.addresses}
              isDisabled={isReadOnly}
              //defaultValue={addressData.endA.addresses[0]}
              value={selectedA}
              onChange={(option) => {
                setSelectedA(option);
              }}
              isClearable={true}
            />
            {selectedA?.value === "NA" && <div>{ADDRESS_NOT_LISTED_SELECTED_TEXT}</div>}
          </div>}
          <Arrow />
          {!addressData.endB.show && <div style={{ maxWidth: '50%' }}>
            <h4>{addressData.endB.title}</h4>
            <div>{addressData.endB.detail}</div>
          </div>}
          {addressData.endB.show && <div style={{ maxWidth: '50%' }}>
            <h4>{addressData.endB.title}</h4>
            <Dropdown
              className='select'
              // customComponents={{ Menu }}
              placeholder={DEFAULT_PLACEHOLDER}
              options={addressData.endB.addresses}
              isDisabled={isReadOnly}
              // defaultValue={addressData.endB.addresses[0]}
              value={selectedB}
              onChange={(option) => {
                setSelectedB(option);
              }}
              isClearable={true}
            />
            {selectedB?.value === "NA" && <div>{ADDRESS_NOT_LISTED_SELECTED_TEXT}</div>}
          </div>}
        </AddressContainer>
      </div>
    </OuterContainer>
  );
};

const loadAddresses = async (
  postcode: string,
) : Promise<IAddressOption[]> => {
  try {
    
    // Remove all spaces from the postcode
    const cleanedPostcode = postcode.replace(/\s+/g, '');
    const response = await doRequest({
      method: Methods.GET,
      path: `/availability/cityfibre/${cleanedPostcode}`,
    });

    // Map response data to IAddressOption[]
    const addresses: IAddressOption[] = response.data.map((item: any) => ({
      address: item,
      value: item.attributes.uprn,
      label: [
        item.attributes.organisation_name,
        item.attributes.building_name,
        item.attributes.sub_building,
        item.attributes.building_number !== "0" ? item.attributes.building_number : null,
        item.attributes.street,
        item.attributes.town,
        item.attributes.county,
        item.attributes.postcode,
      ]
        .filter(Boolean) // Remove null or undefined parts
        .join(', '), // Combine into a single string
    }));

    return addresses;

  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to load addresses:", error);
   return []; // Handle error by passing an empty list
  }
};

export const useSupplierAddressSelectorWithSelectedPrice = (quote: IQuote) => {
  const selectedPrice = useSelector(selectSelectedPrice);
  const primaryNNILabel = useSelector(selectNNILabel);

  const [isLoading, setIsLoading] = useState(false);
  const [showA, setShowA] = useState(selectedPrice.a_end_access_type === Supplier.CITYFIBRE && selectedPrice.a_end_product_name === "National");
  const [showB, setShowB] = useState(selectedPrice.b_end_access_type === Supplier.CITYFIBRE && selectedPrice.b_end_product_name === "National");
  const [detailA, setDetailA] = useState((quote.productType === ProductType.P2NNI ? primaryNNILabel : quote.location.aEnd.fullAddress?.attributes.address_1) ?? "");
  const [detailB, setDetailB] = useState(quote.location.bEnd.fullAddress?.attributes.address_1 ?? "");
  const [addressListA, setAddressListA] = useState<IAddressOption[]>([]);
  const [addressListB, setAddressListB] = useState<IAddressOption[]>([]);

  const addressData = {
    endA: {
      addresses: addressListA,
      show: showA,
      title: "Address at " + quote.location.aEnd.postcode,
      detail: detailA,
    },
    endB: {
      addresses: addressListB,
      show: showB,
      title: "Address at " + quote.location.bEnd.postcode,
      detail: detailB,
    },
  };

  if (quote.productType === ProductType.P2NNI) addressData.endA.title = "NNI at " + quote.location.aEnd.postcode;
  if (quote.productType === ProductType.DIA) addressData.endB.title = "Direct Internet Access";
  if (quote.productType === ProductType.P2CCT) addressData.endB.title = "Cloud Connect to " + quote.location.aEnd.cloudConnect.name;

  useEffect(() => {
    const loadBothAddresses = async () => {
      setIsLoading(true);
  
      const promises = [];
  
      if (selectedPrice.a_end_access_type === Supplier.CITYFIBRE && selectedPrice.a_end_product_name === "National" && addressListA.length === 0) {
        setShowA(true);
        promises.push(
          loadAddresses(quote.location.aEnd.postcode).then((addresses) => {
            if (addresses.length === 0) {
              setShowA(false);
              setDetailA(NO_ADDRESSES_TEXT);
            }
            setAddressListA(addresses);
          })
        );
      }
  
      if (selectedPrice.b_end_access_type === Supplier.CITYFIBRE && selectedPrice.b_end_product_name === "National" && addressListB.length === 0) {
        setShowB(true);
        promises.push(
          loadAddresses(quote.location.bEnd.postcode).then((addresses) => {
            if (addresses.length === 0) {
              setShowB(false);
              setDetailB(NO_ADDRESSES_TEXT);
            }
            setAddressListB(addresses);
          })
        );
      }
  
      await Promise.all(promises);
      setIsLoading(false);
    };
  
    loadBothAddresses();
  }, [selectedPrice.a_end_access_type, selectedPrice.b_end_access_type, selectedPrice.a_end_product_name, selectedPrice.b_end_product_name]);
  
  return {
    isLoading: isLoading,
    isSupported: () => (selectedPrice.a_end_access_type === Supplier.CITYFIBRE && selectedPrice.a_end_product_name === "National") 
                        || (selectedPrice.b_end_access_type === Supplier.CITYFIBRE && selectedPrice.b_end_product_name === "National"),
    addressData: addressData, 
    selectedPrice: selectedPrice
  };
};

const SupplierAddressSelector = (props: SupplierAddressSelectorParams) => {
  const SupplierAddressSelectorHook = useSupplierAddressSelectorWithSelectedPrice(props.quote);

  return (
    <Loading isLoading={SupplierAddressSelectorHook.isLoading}>
      <Bubble>
        <SupplierAddressSelectorContainer
          shouldRender={SupplierAddressSelectorHook.isSupported()}
          addressData={SupplierAddressSelectorHook.addressData}
          price={SupplierAddressSelectorHook.selectedPrice}
          quote={props.quote}
          isReadOnly={props.isReadOnly}
        />
      </Bubble>
    </Loading>
  );
};

const Bubble = styled.div`
  border-radius: 5px;
  padding: 0.25em;
`;

const OuterContainer = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  border: solid 0.5px ${(props) => props.theme.colours.grey10};
  .header {
    padding: 1em 1em 0.5em 1em;
  }
  .content {
    padding: 0em 1em 0.5em 1em;
  }
  &.readonly {
    opacity: 0.7
  }
`;

const AddressContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 1em;
  margin: 0.5em 0;
  background: ${(props) => props.theme.colours.primaryB1};
  color: white;
  border-radius: 0.5rem;
  .select {
    color: black;
  }
  > * {
    flex: 1;
  }
`;

const Grow = styled.div`
  flex: 0;
  margin: 0 1em;
`;

const Arrow = () => (
  <Grow>
    <i className="material-icons">trending_flat</i>
  </Grow>
);

export default SupplierAddressSelector;
