import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import {
  getAlertsError,
  getAlertsStarted,
  getAlertsSuccess,
  getNextAlertsError,
  getNextAlertsStarted,
  getNextAlertsSuccess,
} from './actions';
import { AlertActionTypes, IGetNextAlerts, IMarkAlertAsRead } from './types/actions';
import { doRequest, Methods } from 'Request';
import getAlertsRequest from './crud/getAlerts';

export function* getAlerts(): any {
  yield put(getAlertsStarted());
  try {
    const response = yield getAlertsRequest();
    yield put(getAlertsSuccess(response));
  } catch (error: any) {
    yield put(getAlertsError(error.response));
  }
}
export function* getNextAlerts({ payload }: IGetNextAlerts): any {
  yield put(getNextAlertsStarted());
  try {
    const response = yield doRequest({
      method: Methods.GET,
      path: payload.url,
    });
    yield put(getNextAlertsSuccess(response));
  } catch (error: any) {
    yield put(getNextAlertsError(error.response));
  }
}

export function* markAlertAsRead({ payload }: IMarkAlertAsRead): any {
  const { alertId } = payload;

  try {
    yield call(doRequest, {
      method: Methods.POST,
      path: alertId === '*' ? '/events/mark-read' : `/events/${alertId}/mark-read`,
    });
  } catch (e) {
    // OK to ignore - optimistic update approach taken.
  }
}

export default function* rootSaga() {
  yield debounce(300, AlertActionTypes.GET_ALERTS, getAlerts);
  yield debounce(300, AlertActionTypes.GET_NEXT_ALERTS, getNextAlerts);
  yield takeEvery(AlertActionTypes.MARK_ALERT_AS_READ, markAlertAsRead);
}
