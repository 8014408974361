import React from 'react';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { totalGEACablelinkCostInstall } from 'Quotes/QuoteBuilder/utils/costsUtilities';
import { ProductType } from 'Quotes/types/productTypes';
import CostDisplay from './CostDisplay';
import { IAEndLocation, IPriceData } from 'Quotes/types/store';
import { IMDIACosts } from 'Quotes/QuoteBuilder/components/Price/components/TillReceiptInternal/TillReceiptInternal';

interface CostsInternalInstallProps {
  selectedPrice: IPriceData;
  isFTTXQuote: boolean;
  productType: ProductType | null | undefined;
  isManagedDIA: IAEndLocation['is_managed_dia'];
  mdiaCosts?: IMDIACosts | undefined;
}

export const CostsInternalInstall: React.FC<CostsInternalInstallProps> = ({
  selectedPrice,
  productType,
  isFTTXQuote,
  isManagedDIA,
  mdiaCosts = { engineerInstallation: 0, rackMountKit: 0, router: 0 },
}) => {
  const supplierSetupCostAEnd = selectedPrice.a_end_setup_cost ?? 0;
  const supplierSetupCostBEnd = selectedPrice.b_end_setup_cost ?? 0;
  const bandwidthCostInstall = selectedPrice.bandwidth_cost?.install ?? 0;
  const geaCableLinkInstall = totalGEACablelinkCostInstall(selectedPrice);
  const opticalPortCosts =
    (selectedPrice.opticalCosts?.aEnd.install ?? 0) + (selectedPrice.opticalCosts?.bEnd.install ?? 0);
  return (
    <>
      <CostDisplay label="A-End Supplier Costs" cost={supplierSetupCostAEnd} />
      <CostDisplay label="B-End Supplier Costs" cost={supplierSetupCostBEnd} />
      {productType === ProductType.OpticalP2P && <CostDisplay label="Port Costs" cost={opticalPortCosts} />}
      {productType === ProductType.DIA && <CostDisplay label="Bandwidth Costs" cost={bandwidthCostInstall} />}
      {productType === ProductType.DIA && isManagedDIA && (
        <>
          {mdiaCosts.router > 0 && <CostDisplay label="Managed DIA router Costs" cost={mdiaCosts.router} />}
          {featureFlag.isEnabled(Feature.newMDIAUI) && mdiaCosts.engineerInstallation > 0 && (
            <CostDisplay label="Engineer installation Costs" cost={mdiaCosts.engineerInstallation} />
          )}
          {featureFlag.isEnabled(Feature.newMDIAUI) && mdiaCosts.rackMountKit > 0 && (
            <CostDisplay label="Rack mount kit Costs" cost={mdiaCosts.rackMountKit} />
          )}
        </>
      )}
      {isFTTXQuote && geaCableLinkInstall > 0 && <CostDisplay label="GEA Cablelink Costs" cost={geaCableLinkInstall} />}
    </>
  );
};
``;
