import { NNIDataCentreFromBE } from './types';
import { bearerForDisplay, formatBandwidth } from '../../shared/utils/connectionCapacity';
import { buildNameThatMightIncludeEmptyStrings } from '../../shared/utils/buildNameThatMightIncludeEmptyStrings';

export const dataCentreReference = (dataCentre: NNIDataCentreFromBE): string => {
  const { name, max_bandwidth, location, ports, postcode } = dataCentre.attributes;

  return buildNameThatMightIncludeEmptyStrings([
    `${formatBandwidth(max_bandwidth)} / ${bearerForDisplay(ports[ports.length - 1])}`,
    name,
    location,
    postcode,
  ]);
};

export const sortDataCentresByMaxBandwidth = (dataCentres: NNIDataCentreFromBE[]): NNIDataCentreFromBE[] => {
  return dataCentres.sort((a, b) => {
    const bandwidthA = parseInt(a.attributes.max_bandwidth, 10);
    const bandwidthB = parseInt(b.attributes.max_bandwidth, 10);

    if (bandwidthA < bandwidthB) {
      return 1;
    }
    if (bandwidthA > bandwidthB) {
      return -1;
    }
    return 0;
  });
};
