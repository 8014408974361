import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';

interface ISectionHeader {
  className?: string;
  isLocked?: boolean;
  text: string;
  rightSideContent?: React.ReactNode;
  theme: {
    colours: {
      secondary: string;
      grey50: string;
    };
    typogrphy: {
      fontSizeH4: string;
    };
  };
}

const SectionHeader: FunctionComponent<React.PropsWithChildren<ISectionHeader>> = ({
  className,
  text,
  isLocked = false,
  rightSideContent,
}) => (
  <div className={className}>
    <div>
      <h4>{text}</h4>
      {isLocked && <Icon name="lock" className="lock-icon" />}
    </div>
    {rightSideContent}
  </div>
);

const StyledSectionHeader = styled(SectionHeader)`
  border-bottom: 1px solid ${(props) => props.theme.colours.grey10};
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h4 {
    color: ${(props) => props.theme.colours.secondary};
  }

  .lock-icon {
    color: ${(props) => props.theme.colours.grey50};
    font-size: 2em;
  }
`;

export default StyledSectionHeader;
