import React from 'react';
import cx from 'classnames';

interface IMaybeLabelProps {
  inputId: string;
  labelText: string;
  hidden: boolean;
}

export const MaybeLabel = ({ inputId, labelText, hidden: isHidden }: IMaybeLabelProps) =>
  labelText ? (
    <label htmlFor={inputId} className={cx('small', 'mb-1', { invisible: isHidden })}>
      {labelText}
    </label>
  ) : null;
