import React, { FunctionComponent, useMemo, useState } from 'react';
import styled from 'styled-components';
import Column from 'shared/components/atoms/Column';
import { Question } from '../Question';
import { trackEvent } from 'shared/utils/trackPage';
import Row from 'shared/components/atoms/Column/row';

interface Question {
  question: string;
  answer: string[];
}

type Props = FunctionComponent<
  React.PropsWithChildren<{
    title: string;
    questions: Question[];
  }>
>;

const TitleSection = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colours.grey20};
  margin-bottom: 15px;
`;

const Button = styled.button`
  color: ${(props) => props.theme.colours.primaryB1};

  &:hover {
    text-decoration: underline;
  }
`;

const Separator = styled.span`
  color: ${(props) => props.theme.colours.primaryB1};
`;

const Section: Props = ({ title, questions, children }) => {
  const [visibility, setVisibility] = useState<number[]>(Array(questions.length).fill(0));
  const sectionName = useMemo(() => title.toLowerCase().replace(' ', '_'), [title]);

  return (
    <div className="pt-5" data-testid={`question-section__${sectionName}`}>
      <TitleSection className="row">
        <Column defaultWidth={8}>
          <h2>{title}</h2>
        </Column>
        <Column defaultWidth={4} classNames={['text-right mt-2']}>
          <Button
            type="button"
            onClick={() => {
              setVisibility(Array(visibility.length).fill(1));
              trackEvent({
                category: 'faq',
                action: 'faq_section_open',
                label: sectionName,
              });
            }}
            data-testid="expand_all"
          >
            Expand all
          </Button>
          <Separator className="px-2">|</Separator>
          <Button
            type="button"
            onClick={() => {
              setVisibility(Array(visibility.length).fill(0));
              trackEvent({
                category: 'faq',
                action: 'faq_section_close',
                label: sectionName,
              });
            }}
            data-testid="collapse_all"
          >
            Collapse all
          </Button>
        </Column>
      </TitleSection>

      <Row>
        <Column defaultWidth={8}>
          {questions.map((q, i) => {
            return (
              <Question
                key={i}
                question={q.question}
                answer={q.answer}
                isOpen={visibility[i] === 1}
                onToggle={() => {
                  setVisibility(
                    visibility.map((current, index) => {
                      if (index !== i) {
                        return current;
                      }

                      if (current === 0) {
                        trackEvent({
                          category: 'faq',
                          action: 'faq_view',
                          label: `${sectionName} / ${q.question}`,
                        });
                      }

                      return current === 0 ? 1 : 0;
                    })
                  );
                }}
              />
            );
          })}
        </Column>
        <Column defaultWidth={4}>{children}</Column>
      </Row>
    </div>
  );
};

export { Section };
