import { useDownloadCSV } from 'Admin/Pricing/hooks';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import ButtonWithIcon from 'shared/components/molecules/ButtonWithIcon';
import { ButtonMainStyle } from 'shared/components/atoms/Button';
import React from 'react';

export const ExportCSV = ({ quoteId, shortId }: { quoteId: string | undefined; shortId: string | undefined }) => {
  const { downloading, error, getData } = useDownloadCSV(
    `/quotes/${quoteId}/prices/export`,
    `${shortId}_pricing_table.csv`
  );

  if (!quoteId || !shortId) return null;

  return (
    <div className="export-csv-row">
      {error && (
        <Alert alertType={Alerts.DANGER}>An error occurred while exporting the CSV. Please try again later.</Alert>
      )}
      <ButtonWithIcon
        mainStyle={ButtonMainStyle.LinkSecondary}
        iconName="download"
        iconPlacement="left"
        onClick={getData}
        loading={downloading}
      >
        Export CSV
      </ButtonWithIcon>
    </div>
  );
};
