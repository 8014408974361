import Alert, { Alerts } from '../../../../shared/components/atoms/Alert';
import React from 'react';
import { Link } from 'react-router-dom';
import { orderById } from 'Order/routes';
import { capitalize, truncate } from 'lodash';
import { multiQuoteById } from 'Quotes/Routes';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import styled from 'styled-components';
import Icon from 'shared/components/atoms/Icon';
import { QUOTE_TIMEFRAME_LAPSE_IN_DAYS } from 'Order/OrderBuilder/Terms/components/TermsText';

const AlertBox = styled.div`
  padding-bottom: 50px;
`;

export const SubmittedQuoteAlert = () => (
  <AlertBox>
    <Alert alertType={Alerts.SUCCESS}>
      <p className="mb-0">Your quote has been submitted and is being processed.</p>
    </Alert>
  </AlertBox>
);

export const OpticalNoticeAlert = () => (
  <AlertBox>
    <Alert alertType={Alerts.INFO}>
      <p className="mb-0">Optical wavelengths are also available and shown below</p>
    </Alert>
  </AlertBox>
);

// TODO: Pretty sure this `p` isn't used, if not we can remove it
const LapsedQuoteAlertBox = styled.div`
  display: flex;
  justify-content: flex-end;

  p {
    width: 50%;
  }
`;

// Classname left for end-to-end tests
export const LapsedQuoteAlert = () => (
  <LapsedQuoteAlertBox className="price__lapsed_quote_text pt-3">
    <Alert className="mb-0">This quote has lapsed after exceeding its {QUOTE_TIMEFRAME_LAPSE_IN_DAYS}-day limit.</Alert>
  </LapsedQuoteAlertBox>
);

const QuoteOrdered = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: 50%;
`;

const OrderLink = styled(Link)`
  color: ${(props) => props.theme.colours.primaryB1};

  &:hover {
    text-decoration: underline;
  }
`;

const EditQuoteButton = styled.button`
  color: ${(props) => props.theme.colours.primaryB1};

  &:hover {
    text-decoration: underline;
  }
`;

export const QuoteAlreadyOrdered = ({
  associatedOrderId,
  onClick,
  string,
}: {
  associatedOrderId: string | undefined;
  string: string | undefined;
  onClick: (() => void) | undefined;
}) => (
  // Classname left for end-to-end tests
  <QuoteOrdered className="quote-ordered pt-4">
    <Alert alertType={Alerts.INFO}>
      An order has been started/placed against this quote. Please click the Order ID (
      {<OrderLink to={orderById(associatedOrderId || '')}>{capitalize(string) || ''}</OrderLink>}) to view, or if you
      wish to make another similar quote click{' '}
      <EditQuoteButton type="button" className="edit-quote-btn" onClick={onClick}>
        Edit
      </EditQuoteButton>
      .
    </Alert>
  </QuoteOrdered>
);

export const BulkQuoteAlert = ({ id }: { id: string }) => (
  <Alert alertType={Alerts.INFO}>
    <Container>
      <Icon className="info_outline mr-2" name="info_outline" size="large" />
      This quote is part of multi-quote&nbsp;
      <Link to={multiQuoteById(id)} target="_blank">
        {shortenBulkQuoteId(id)}
      </Link>
    </Container>
  </Alert>
);

export const shortenBulkQuoteId = (bulkQuoteId: string): string | null =>
  `Mq-${truncate(bulkQuoteId, { length: 8, omission: '' })}`;

const Container = styled.div`
  display: flex;
  justify-content: flex-center;
  align-items: center;
`;

const MultiQuoteNotice = styled.div`
  padding-bottom: 50px;

  a {
    color: ${(props) => props.theme.alerts.success.color};
    text-decoration: underline;
  }
`;

const MultiQuoteCopyButton = styled.button`
  float: right;
  text-decoration: underline;
`;

export const SuccessfulMultiQuoteAlert = ({
  onClick,
  successfulMultiQuoteRequest: { id, label },
}: {
  successfulMultiQuoteRequest: { id: string; label: string };
  onClick: (() => void) | undefined;
}) => (
  <MultiQuoteNotice className="multi-quote-notice">
    <Alert alertType={Alerts.SUCCESS}>
      <p className="mb-0">
        Quote successfully added to the Multi-Quote{' '}
        <Link to={multiQuoteById(id)} target="_blank">
          {label}
        </Link>
        <MultiQuoteCopyButton type="button" className="multi_quote_copy_quote" onClick={onClick}>
          <span className="mr-1">Copy &amp; Quote</span>
          <Tooltip text="Start a new quote with pre-filled information from this quote as a starting point." />
        </MultiQuoteCopyButton>
      </p>
    </Alert>
  </MultiQuoteNotice>
);
