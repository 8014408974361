import classNames from 'classnames';
import { isArray } from 'lodash';
import React from 'react';
import CheckboxButton from 'shared/components/molecules/CheckboxButton';
import RadioButton from 'shared/components/molecules/RadioButton';
import styled from 'styled-components';

export interface Option<T = string> {
  label: string;
  value: T;
  tooltip?: string;
  customId?: string;
  disabled?: boolean;
  readonly?: boolean;
}

const MainContainer = styled.div`
  display: flex;
  row-gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
`;

const OptionContainer = styled.div`
  padding: 0 0.5rem;
`;

interface IOptionGroupProps<T = string> {
  className?: string;
  customClassName?: string;
  options: Option<T>[];
  multiple?: boolean;
  value?: T | T[] | null;
  onClick: (value: T) => void;
  itemsPerRow?: number;
  readOnly?: boolean;
}
export function OptionGroup<T = string>({
  customClassName,
  options,
  multiple = false,
  value,
  onClick,
  itemsPerRow,
  readOnly,
}: IOptionGroupProps<T>) {
  function isOptionSelected(option: Option<T>) {
    if (multiple) {
      return value && isArray(value) ? value.includes(option.value) : false;
    }
    return value === option.value;
  }

  return (
    <MainContainer className={classNames(customClassName)}>
      {options.map((option) => (
        <OptionContainer
          key={String(option.value)}
          style={{
            flexBasis: itemsPerRow ? `${100 / itemsPerRow}%` : 'auto',
          }}
        >
          {!multiple && (
            <RadioButton
              inline
              size={itemsPerRow ? 'fill' : 'medium'}
              description={option.label}
              id={option.customId}
              onClick={() => {
                onClick(option.value);
              }}
              status={isOptionSelected(option) ? 'selected' : 'notSelected'}
              disabled={option.disabled || readOnly}
              tooltipText={option.tooltip}
            />
          )}

          {multiple && (
            <CheckboxButton
              inline
              size={itemsPerRow ? 'fill' : 'medium'}
              description={option.label}
              id={option.customId}
              onClick={() => {
                onClick(option.value);
              }}
              status={isOptionSelected(option) ? 'selected' : 'notSelected'}
              disabled={option.disabled}
              tooltipText={option.tooltip}
            />
          )}
        </OptionContainer>
      ))}
    </MainContainer>
  );
}
