import { Wrapper } from '@googlemaps/react-wrapper';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

export interface GoogleMapsMarkerOptionsWithId extends google.maps.MarkerOptions {
  id: string;
}

interface IGoogleMapsLocationProps extends google.maps.MapOptions {
  className?: string;
  markersOptions?: GoogleMapsMarkerOptionsWithId[];
  referenceMarkerOptions: GoogleMapsMarkerOptionsWithId | null;
  onMarkerClick: (id: string) => void;
}

const UnstyledGoogleMapsLocation: FC<React.PropsWithChildren<IGoogleMapsLocationProps>> = ({
  className,
  markersOptions = [],
  referenceMarkerOptions,
  onMarkerClick,
  ...mapOptions
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [referenceMarker, setReferenceMarker] = useState<google.maps.Marker | null>(null);

  const setAllMarkers = () => {
    if (!map) return;

    setMarkers(
      markersOptions?.map((markerOptions) => {
        const marker = new window.google.maps.Marker({
          map,
          ...markerOptions,
          clickable: true,
          icon: {
            url: '/images/map_markers/red-dot.png',
          },
        });

        marker.addListener('click', () => {
          onMarkerClick(markerOptions.id);
        });

        return marker;
      }) || []
    );
  };

  const clearAllMarkers = () => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    setMarkers([]);
  };

  useEffect(() => {
    if (markersOptions.length > 0) {
      clearAllMarkers();
      setAllMarkers();
    }
  }, [markersOptions]);

  const createBounds = (boundMarkers: google.maps.Marker[]) => {
    const bounds = new window.google.maps.LatLngBounds();

    boundMarkers.forEach((marker) => {
      bounds.extend(marker.getPosition()!);
    });

    return bounds;
  };

  useEffect(() => {
    if (!map) return;

    // handle single marker
    if (markers.length === 1) {
      if (referenceMarker) {
        const bounds = createBounds([referenceMarker, ...markers]);
        map?.fitBounds(bounds);
        return;
      }

      map?.setZoom(15);
      map?.setCenter(markers[0].getPosition()!);
    }

    // handle multiple markers
    if (markers.length > 1) {
      const bounds = createBounds(markers);
      map?.fitBounds(bounds);
    }
  }, [markers, referenceMarker]);

  useEffect(() => {
    if (!map) return;

    referenceMarker?.setMap(null);

    if (!referenceMarkerOptions) {
      setReferenceMarker(null);
      return;
    }

    const marker = new window.google.maps.Marker({
      map,
      ...referenceMarkerOptions,
      clickable: false,
      icon: {
        url: '/images/map_markers/white.png',
      },
    });

    setReferenceMarker(marker);
  }, [referenceMarkerOptions]);

  const onRefChange = useCallback((node: any) => {
    if (node !== null) {
      setMap(
        new window.google.maps.Map(node, {
          zoom: 12,
          ...mapOptions,
        })
      );
    }
  }, []);

  return (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_APIKEY!}>
      <div className={className} ref={onRefChange} />
    </Wrapper>
  );
};
export const GoogleMapsLocation = styled(UnstyledGoogleMapsLocation)`
  height: 100%;
  border-radius: 5px;
`;
