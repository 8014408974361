import { doRequest, Methods } from '../../../Request';
import IPricedQuote from '../../types/pricedQuote';

export const updatePrice = async (priceId: string, updateValues: Partial<IPricedQuote>) => {
  return doRequest({
    body: {
      data: {
        attributes: {
          ...updateValues,
        },
        type: 'price',
      },
    },
    method: Methods.PATCH,
    path: `/prices/${priceId}`,
  });
};
