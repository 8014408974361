export interface DIAIPAllocationOption {
  id: IPAddressAllocation;
  label: string;
  helperText: string;
}

export enum IPAddressAllocation {
  WAN_ONLY_31 = 'WAN_ONLY_31',
  WAN_31_PUBLIC_ALLOCATION = 'WAN_31_PUBLIC_ALLOCATION',
  PUBLIC_ALLOCATION_ONLY = 'PUBLIC_ALLOCATION_ONLY',
  IP_TRANSIT = 'IP_TRANSIT',
}

export const diaIPAllocationOptions: DIAIPAllocationOption[] = [
  {
    id: IPAddressAllocation.WAN_ONLY_31,
    label: 'WAN only /31',
    helperText:
      'You will be provided a /31 IP Address subnet for use on the WAN side of the service. You do not require any other public IP Addresses on the service.',
  },
  {
    id: IPAddressAllocation.WAN_31_PUBLIC_ALLOCATION,
    label: 'WAN /31 + public allocation',
    helperText:
      'You will be provided a /31 IP Address subnet for use on the WAN side of the service. You require additional public IP Addresses for use on the LAN side of the service.',
  },
  {
    id: IPAddressAllocation.PUBLIC_ALLOCATION_ONLY,
    label: 'Public allocation only',
    helperText:
      'You do not wish to use a /31 IP Address subnet and therefore will be provided with a single public IP Address allocation. This range will be used for both the WAN and LAN side of the service',
  },
  {
    id: IPAddressAllocation.IP_TRANSIT,
    label: 'IP Transit',
    helperText:
      'You have your own Public IP Address allocation registered with RIPE and would like to use this on the service.',
  },
];

export const findDiaIPAllocationOptionById = (id: IPAddressAllocation): DIAIPAllocationOption | undefined =>
  diaIPAllocationOptions.find((item) => item.id === id);

export enum DualCircuitRouterConfigurations {
  // A single CPE to be used across both circuits.
  SINGLE = 'single',
  // One CPE on each circuit working independently of each other.
  DUAL_INDEPENDENT = 'dual_independent',
  // Active/Active and Active/Standby Failover configurations.
  DUAL_FAILOVER_ACTIVE_ACTIVE = 'dual_failover_active_active',
  DUAL_FAILOVER_ACTIVE_STANDBY = 'dual_failover_active_standby',
}
