import { IUserBackendProfile } from '../../User/types/backendUser';
import { IUserRoleFromBackend, Role } from '../../User/types/role';
import { IRow } from '../shared/components/CompaniesRolesTable';
import { RequestState } from '../types';

export enum AdminActionTypes {
  ADD_USER = 'ADD_USER',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_ERROR = 'ADD_USER_ERROR',
  ADD_USER_START = 'ADD_USER_START',
  ADD_USER_SET_REQUEST_STATE = 'ADD_USER_SET_REQUEST_STATE',
  GET_USER = 'GET_USER',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_ERROR = 'GET_USER_ERROR',
  GET_USER_START = 'GET_USER_START',
  SET_CURRENTLY_VIEWED_USER = 'SET_CURRENTLY_VIEWED_USER',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_START = 'UPDATE_USER_START',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',
  RESET_UPDATE_USER_REQUEST_STATE = 'RESET_UPDATE_USER_REQUEST_STATE',
  RESET_CURRENTLY_VIEWED_USER = 'RESET_CURRENTLY_VIEWED_USER',
  SEND_INVITE = 'SEND_INVITE',
  SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS',
  SEND_INVITE_ERROR = 'SEND_INVITE_ERROR',
  SEND_INVITE_START = 'SEND_INVITE_START',
  BLOCK_USER = 'BLOCK_USER',
  BLOCK_USER_START = 'BLOCK_USER_START',
  BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS',
  BLOCK_USER_ERROR = 'BLOCK_USER_ERROR',
  UNBLOCK_USER = 'UNBLOCK_USER',
  UNBLOCK_USER_START = 'UNBLOCK_USER_START',
  UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS',
  UNBLOCK_USER_ERROR = 'UNBLOCK_USER_ERROR',
  RESET_REQUESTS_STATE = 'RESET_REQUESTS_STATE',
  EDIT_USER_STATE = 'EDIT_USER_STATE',
}

export interface IUserData extends ProfileFields {
  shouldSendEmail: boolean;
  roles: Set<Role>;
}

export interface ProfileFields {
  companyEmail: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  landlinePhone: string;
  contactEmail: string;
}

export interface IUpdateUserPayload {
  userData: {
    companyEmail: string;
    contactEmail: string;
    jobTitle: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    landlinePhone: string;
    id: string;
    roles: IUserRoleFromBackend[];
  };
  companiesRolesRows: IRow[];
}

export interface IGetUserPayload {
  id: string;
}

export interface IAddUser {
  payload: {
    userData: IUserData;
    companiesRolesRows: IRow[];
  };
  type: AdminActionTypes.ADD_USER;
}

export interface IAddUserSuccess {
  type: AdminActionTypes.ADD_USER_SUCCESS;
}

export interface IAddUserErrorPayload {
  error: any;
}

export interface IAddUserError {
  payload: IAddUserErrorPayload;
  type: AdminActionTypes.ADD_USER_ERROR;
}

export interface IAddUserStart {
  type: AdminActionTypes.ADD_USER_START;
}

export interface IAddUserSetRequestState {
  type: AdminActionTypes.ADD_USER_SET_REQUEST_STATE;
  payload: {
    state: RequestState;
  };
}

export interface IGetUser {
  payload: IGetUserPayload;
  type: AdminActionTypes.GET_USER;
}

export interface IGetUserSuccess {
  type: AdminActionTypes.GET_USER_SUCCESS;
}

export interface IGetUserError {
  type: AdminActionTypes.GET_USER_ERROR;
}

export interface IGetUserStart {
  type: AdminActionTypes.GET_USER_START;
}

export interface ISetCurrentlyViewedUser {
  payload: {
    user: IUserBackendProfile;
  };
  type: AdminActionTypes.SET_CURRENTLY_VIEWED_USER;
}

export interface IUpdateUser {
  payload: IUpdateUserPayload;
  type: AdminActionTypes.UPDATE_USER;
}

export interface ISendInvitePayload {
  id: string;
}

export interface ISendInvite {
  payload: ISendInvitePayload;
  type: AdminActionTypes.SEND_INVITE;
}

export interface ISendInviteSuccess {
  type: AdminActionTypes.SEND_INVITE_SUCCESS;
}

export interface ISendInviteError {
  type: AdminActionTypes.SEND_INVITE_ERROR;
}

export interface ISendInviteStart {
  type: AdminActionTypes.SEND_INVITE_START;
}

export interface IUpdateUserSuccess {
  type: AdminActionTypes.UPDATE_USER_SUCCESS;
}

export interface IUpdateUserError {
  type: AdminActionTypes.UPDATE_USER_ERROR;
}

export interface IUpdateUserStart {
  type: AdminActionTypes.UPDATE_USER_START;
}

export interface IResetUpdateUserRequestState {
  type: AdminActionTypes.RESET_UPDATE_USER_REQUEST_STATE;
}

export interface IResetCurrentlyViewedUser {
  type: AdminActionTypes.RESET_CURRENTLY_VIEWED_USER;
}

export interface IBlockUserPayload {
  id: string;
}

export interface IBlockUser {
  payload: IBlockUserPayload;
  type: AdminActionTypes.BLOCK_USER;
}

export interface IBlockUserSuccess {
  type: AdminActionTypes.BLOCK_USER_SUCCESS;
}

export interface IBlockUserError {
  type: AdminActionTypes.BLOCK_USER_ERROR;
}

export interface IBlockUserStart {
  type: AdminActionTypes.BLOCK_USER_START;
}

export interface IUnblockUserPayload {
  id: string;
}

export interface IUnblockUser {
  payload: IUnblockUserPayload;
  type: AdminActionTypes.UNBLOCK_USER;
}

export interface IUnblockUserSuccess {
  type: AdminActionTypes.UNBLOCK_USER_SUCCESS;
}

export interface IUnblockUserError {
  type: AdminActionTypes.UNBLOCK_USER_ERROR;
}

export interface IUnblockUserStart {
  type: AdminActionTypes.UNBLOCK_USER_START;
}

export interface IResetRequestsState {
  type: AdminActionTypes.RESET_REQUESTS_STATE;
}

export interface IEditUserState {
  type: AdminActionTypes.EDIT_USER_STATE;
}

export type AdminActions =
  | IAddUser
  | IAddUserSuccess
  | IAddUserError
  | IAddUserStart
  | IAddUserSetRequestState
  | IGetUser
  | IGetUserSuccess
  | IGetUserError
  | IGetUserStart
  | ISetCurrentlyViewedUser
  | IUpdateUser
  | IUpdateUserStart
  | IUpdateUserSuccess
  | IUpdateUserError
  | IResetUpdateUserRequestState
  | IResetCurrentlyViewedUser
  | ISendInvite
  | ISendInviteStart
  | ISendInviteSuccess
  | ISendInviteError
  | IBlockUser
  | IBlockUserStart
  | IBlockUserSuccess
  | IBlockUserError
  | IUnblockUser
  | IUnblockUserStart
  | IUnblockUserSuccess
  | IUnblockUserError
  | IResetRequestsState
  | IEditUserState;
