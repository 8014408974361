import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface ISelectAllNoneOptions {
  onSelectToggle(): void;

  className?: string;
  title: string;
  theme: {
    colours: {
      secondary: string;
    };
  };
}

const SelectAllNoneOptions: FunctionComponent<React.PropsWithChildren<ISelectAllNoneOptions>> = ({
  onSelectToggle,
  title,
  className,
}) => {
  return (
    <div className={className}>
      <span className="text-muted flex-fill">{title}</span>
      <button type="button" onClick={onSelectToggle} className="select__btn selectAll__btn">
        Select all/none
      </button>
    </div>
  );
};

export default styled(SelectAllNoneOptions)`
  padding: 0.5em;
  color: ${(props) => props.theme.colours.secondary};
  display: flex;

  .select__btn {
    padding-left: 0.4em;
    padding-right: 0.4em;
  }

  .select__btn:hover {
    text-decoration: underline;
  }
`;
