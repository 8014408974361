import React, { FunctionComponent } from 'react';
import { Supplier } from 'Quotes/types/supplier';
import { presentSupplierNameForPriceTile } from 'Quotes/utils/supplierHelpers';
import { SupplierImage } from '../../../Supplier';
import PresentForRoles from 'Auth/PresentForRoles';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { CloudProviderImage } from '../../../CloudProvider';
import { IPriceData, ProviderName } from 'Quotes/types/store';
import { userPermissions } from 'shared/utils/permissions';
import { getNNIOrDataCentreLabel } from 'Quotes/QuoteBuilder/utils/getSelectedNNIReference';
import { PriceCard } from '../PriceCard';
import styled from 'styled-components';
import classNames from 'classnames';

const identifierPrefix = 'NNI2CCT';

interface INNI2CCTProps {
  selected?: boolean;
  nniLabel: string | null | undefined;
  nniLocation: string | null | undefined;
  provider: ProviderName;
  aEndSupplier: Supplier;
  amortisedAnnualPrice: number | null;
  aEndPrice: number | null;
  cloudConnectAnnual: number | null;
  nniReference?: string | null;
  nniPopName?: string | null;
  priceId: string | null;
  className?: string;
  readonly: boolean;
  onClick?: () => void;
  product_sub_type: IPriceData['product_sub_type'];
  bandwidth?: string;
  term_length_in_months?: IPriceData['term_length_in_months'];
  isPoA?: boolean;
}

export const NNI2CCT: FunctionComponent<React.PropsWithChildren<INNI2CCTProps>> = ({
  selected = false,
  onClick,
  amortisedAnnualPrice,
  aEndSupplier,
  provider,
  aEndPrice,
  priceId,
  nniReference,
  nniPopName,
  cloudConnectAnnual,
  readonly,
  nniLabel,
  nniLocation,
  product_sub_type,
  bandwidth,
  term_length_in_months,
  className,
  isPoA = false,
}) => (
  <PriceCard
    identifierPrefix={identifierPrefix}
    priceId={priceId!}
    readonly={readonly}
    selected={selected}
    onClick={onClick}
    amortisedAnnualPrice={amortisedAnnualPrice}
    product_sub_type={product_sub_type}
    term_length_in_months={term_length_in_months}
    bandwidth={bandwidth}
    isPoA={isPoA}
  >
    <ul className={classNames(className, 'list-unstyled')}>
      <li>
        <CloudProviderImage cloudProvider={provider} />
      </li>
      {provider !== ProviderName.NOT_DEFINED && <li className="provider-name">{provider}</li>}
      <PresentForRoles roles={userPermissions.costs}>
        <li className="text-secondary cloud-connect-annual-cost">
          Cost: {currencyFormatter.format(cloudConnectAnnual ?? 0)}
        </li>
      </PresentForRoles>
      <li className="bandwidth-link">
        <img
          src="/images/price-end-link-graphic.png"
          alt="Link between aEnd and cloud provider"
          width={23}
          height={71}
        />
      </li>
      {aEndSupplier && (
        <li>
          <SupplierImage supplier={aEndSupplier} />
        </li>
      )}
      <li>{presentSupplierNameForPriceTile(aEndSupplier)}</li>
      <li className="nni-text">
        {nniLabel || getNNIOrDataCentreLabel(nniPopName, nniReference)} {nniLocation}
      </li>
      <PresentForRoles roles={userPermissions.costs}>
        <li className="text-secondary a-end-cost small">Cost: {currencyFormatter.format(aEndPrice ?? 0)}</li>
      </PresentForRoles>
    </ul>
  </PriceCard>
);

export const StyledNNI2CCT = styled(NNI2CCT)`
  .provider-name {
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  .nni-text {
    color: ${(props) => props.theme.colours.grey70};
    display: block;
    font-size: 0.75em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
