import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Column from 'shared/components/atoms/Column';
import CardRow from 'shared/components/molecules/CardRow';
import { IAppState } from 'reducers';
import SummaryTextHelper, {
  EmailSummary,
  NameFieldSummary,
  NotesFieldSummary,
  PhoneFieldSummary,
  TextFieldSummary,
} from 'Order/OrderBuilder/shared/components/SummaryTextHelper';
import { isStaticIPWanIPCompatible, wanIpAdditionQuestion } from 'Quotes/QuoteBuilder/utils/ip';
import { isIPAddressJustificationRequiredById } from '../Edit';
import { isArray } from 'lodash';
import { IOrder } from 'Order/types/store';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { IPAddressAllocation } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { useSecondaryCircuitsWithSelectedPrice } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { IPContact } from 'Order/types/formFields';

const Summary: FC = () => {
  const order = useSelector((state: IAppState) => state.orderBuilder.order);
  const { selectedId } = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.ip);
  const { dia_ip_allocation, is_managed_dia } = useSelector(
    (state: IAppState) => state.quoteBuilder.quote.location.aEnd
  );

  const secondaryCircuits = useSecondaryCircuitsWithSelectedPrice();
  const hasSecondaryCircuit = secondaryCircuits.enabled && !!secondaryCircuits.selectedCircuit;

  return hasSecondaryCircuit ? (
    <DualCircuitSummary
      order={order}
      selectedId={selectedId}
      managedDia={is_managed_dia}
      diaIpAllocation={dia_ip_allocation}
    />
  ) : (
    <SingleCircuitSummary
      order={order}
      selectedId={selectedId}
      managedDia={is_managed_dia}
      diaIpAllocation={dia_ip_allocation}
    />
  );
};
const toListItem = (child: React.ReactElement) => <li key={child.key}>{child}</li>;

const UnorderedList: FC<{ children: React.ReactElement | React.ReactElement[]; heading: string }> = ({
  heading,
  children,
}) => {
  return (
    <UL>
      <GroupTitle className="group-title">{heading}</GroupTitle>
      {isArray(children) ? children.map(toListItem) : toListItem(children)}
    </UL>
  );
};

const UL = styled.ul`
  list-style: none;
`;

const GroupTitle = styled.div`
  color: ${(props) => props.theme.colours.grey50};
  margin-bottom: 0.5rem;
`;

const SingleCircuitSummary = ({
  diaIpAllocation,
  managedDia,
  order,
  selectedId,
}: {
  order: IOrder;
  selectedId: IPType | null | undefined;
  managedDia: boolean;
  diaIpAllocation: IPAddressAllocation | null;
}) => (
  <CardRow title="IP address deployment">
    <div className="row no-gutters">
      <Column defaultWidth={4}>
        <Contact ipContact={order.ipContact} />
      </Column>
      <Column defaultWidth={3} offsetDefaultWidth={1}>
        <Address ipContact={order.ipContact} />
      </Column>
    </div>
    <div className="row no-gutters">
      <Column defaultWidth={4}>
        <OrganisationName ipContact={order.ipContact} />
      </Column>
      <Column defaultWidth={7} offsetDefaultWidth={1}>
        <IPAddressJustification ipText={order.ipText} selectedId={selectedId} />
      </Column>
    </div>
    <div className="row no-gutters">
      <Column defaultWidth={4}>
        <RIPEHandle nicRIPEHandle={order.ipContact.nic_ripe_handle} />
      </Column>
      <Column defaultWidth={7} offsetDefaultWidth={1}>
        <WanIpInAddition
          selectedId={selectedId}
          managedDia={managedDia}
          diaIpAllocation={diaIpAllocation}
          wanIpInAddition={order.wan_ip_in_addition}
        />
      </Column>
    </div>
  </CardRow>
);

const DualCircuitSummary = ({
  diaIpAllocation,
  managedDia,
  order,
  selectedId,
}: {
  order: IOrder;
  selectedId: IPType | null | undefined;
  managedDia: boolean;
  diaIpAllocation: IPAddressAllocation | null;
}) => (
  <>
    <CardRow title="IP address deployment">
      <div className="row no-gutters">
        <Column defaultWidth={4}>
          <Contact ipContact={order.ipContact} />
        </Column>
        <Column defaultWidth={3} offsetDefaultWidth={1}>
          <Address ipContact={order.ipContact} />
        </Column>
      </div>
      <div className="row no-gutters">
        <Column defaultWidth={4}>
          <OrganisationName ipContact={order.ipContact} />
        </Column>
      </div>
    </CardRow>
    <CardRow title="" subtitle="Primary Circuit">
      <div className="row no-gutters">
        <Column defaultWidth={4}>
          <IPAddressJustification ipText={order.ipText} selectedId={selectedId} />
        </Column>
        <Column defaultWidth={7} offsetDefaultWidth={1}>
          <RIPEHandle nicRIPEHandle={order.ipContact.nic_ripe_handle} />
        </Column>
      </div>
    </CardRow>
    <CardRow title="" subtitle="Secondary Circuit">
      <div className="row no-gutters">
        <Column defaultWidth={4}>
          <IPAddressJustification ipText={order.secondaryIPDetails?.ipText} selectedId={selectedId} />
        </Column>
        <Column defaultWidth={7} offsetDefaultWidth={1}>
          <RIPEHandle nicRIPEHandle={order.secondaryIPDetails?.nicRIPEHandle} />
        </Column>
      </div>
      <div className="row no-gutters">
        <Column defaultWidth={7} offsetDefaultWidth={1}>
          <WanIpInAddition
            selectedId={selectedId}
            managedDia={managedDia}
            diaIpAllocation={diaIpAllocation}
            wanIpInAddition={order.wan_ip_in_addition}
          />
        </Column>
      </div>
    </CardRow>
  </>
);

const Contact = (props: { ipContact: IPContact }) => (
  <UnorderedList heading="Contact">
    <NameFieldSummary firstName={props.ipContact.firstName} surname={props.ipContact.surname} />
    <PhoneFieldSummary phone={props.ipContact.telephone} />
    <EmailSummary email={props.ipContact.email} />
  </UnorderedList>
);

const Address = (props: { ipContact: IPContact }) => (
  <UnorderedList heading="Address">
    <address className="text-break">
      {props.ipContact.address ? props.ipContact.address : <SummaryTextHelper label="Address" />}
    </address>
  </UnorderedList>
);

const OrganisationName = (props: { ipContact: IPContact }) => (
  <UnorderedList heading="Organisation name">
    <TextFieldSummary text={props.ipContact.organisation} prompt="Add organisation" />
  </UnorderedList>
);

const IPAddressJustification = (props: { ipText: string | undefined; selectedId: IPType | null | undefined }) => (
  <UnorderedList heading="IP address justification">
    <NotesFieldSummary
      text={props.ipText || ''}
      required={isIPAddressJustificationRequiredById(props.selectedId!)}
      requiredPrompt="Add justification"
      errorPrompt="Please limit justification to 500 characters"
    />
  </UnorderedList>
);

const RIPEHandle = (props: { nicRIPEHandle: string | undefined }) => (
  <UnorderedList heading="NIC / RIPE handle">
    <span>{props.nicRIPEHandle || ''}</span>
  </UnorderedList>
);

const WanIpInAddition = (props: {
  selectedId: IPType | null | undefined;
  managedDia: boolean;
  diaIpAllocation: IPAddressAllocation | null;
  wanIpInAddition: boolean | null;
}) => {
  if (!props.selectedId || !isStaticIPWanIPCompatible(props.selectedId)) return null;

  return (
    <UnorderedList heading={wanIpAdditionQuestion(props.selectedId, props.managedDia, props.diaIpAllocation)}>
      <span>{props.wanIpInAddition ? 'Yes' : 'No'}</span>
    </UnorderedList>
  );
};

const StyledSummary = styled(Summary)`
  ul {
    list-style: none;
  }

  .group-title {
    color: ${(props) => props.theme.colours.grey50};
    margin-bottom: 0.5rem;
  }
`;

export default StyledSummary;
