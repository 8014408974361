import React, { FunctionComponent, useEffect } from 'react';
import CardWithInitialText, { CardState } from 'shared/components/organisms/CardWithInitialText';
import OrderCardFooter from 'shared/components/molecules/OrderCardFooter';
import { usePreviousState } from 'shared/utils/customHooks';
import initialList, { initialNewNNIList, initialNNIList } from '../../initialList';
import { LocationLetter } from 'Order/types/location';

interface IProps {
  cardState: CardState;
  className?: string;
  isNNI?: boolean;
  isNewNNI?: boolean;
  completed: boolean;
  locationLetter: LocationLetter;
  locationDescription: string;
  saving?: boolean;
  onSaveClick(): void;
  setCardState(cardState: CardState): void;
  extraButtons?: React.ReactNode;
}

export const CardWrapper: FunctionComponent<React.PropsWithChildren<IProps>> = ({
  cardState,
  completed,
  locationLetter,
  className,
  onSaveClick,
  locationDescription,
  isNNI,
  isNewNNI = false,
  saving,
  children,
  setCardState,
  extraButtons,
}) => {
  const cardTopAnchor = React.useRef<HTMLDivElement | null>(null);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (CardState.Edit) {
      setCardState(CardState.Loading);
      onSaveClick();

      if (cardTopAnchor && cardTopAnchor.current) {
        cardTopAnchor.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  const prevSaving = usePreviousState(saving);
  useEffect(() => {
    if (prevSaving && !saving && cardState === CardState.Loading) {
      setCardState(CardState.Summary);
    }
  }, [cardState, prevSaving, saving, setCardState]);

  return (
    <form onSubmit={onSubmit} className={className}>
      <div ref={cardTopAnchor} />

      <CardWithInitialText
        className={`order-${locationLetter}-end-location-section`}
        completed={completed}
        loadingText="Saving"
        initialList={isNNI ? (isNewNNI ? initialNewNNIList : initialNNIList) : initialList}
        onEditClick={() => setCardState(CardState.Edit)}
        cardState={cardState}
        title={`${locationDescription} (${locationLetter.toUpperCase()}-End Location)`}
        extraButtons={extraButtons}
      >
        {children}
        {cardState === CardState.Edit && <OrderCardFooter />}
      </CardWithInitialText>
    </form>
  );
};

export default CardWrapper;
