import { IQuote } from 'Quotes/types/store';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import {
  getAdditionalIPAddressesHelperText,
  getIpAddressesOptions,
  IP_ADDRESS_SELECTION_AVAILABLE,
  IPAddressAllocationSelection,
  IPAddressSelection,
} from 'Quotes/QuoteBuilder/components/Configure/DIAConfig';
import { getIPTypeLabel } from 'Quotes/QuoteBuilder/utils/ip';
import React from 'react';
import styled from 'styled-components';
import {
  diaIPAllocationOptions,
  IPAddressAllocation,
} from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { Title } from 'shared/components/molecules/SecondaryCircuits/DIAConfig';
import { useDispatch } from 'react-redux';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { setDIAIPAllocation as setDIAIPAllocationAction, setSecondaryIPOption } from 'Quotes/actions';
import { ISetSecondIPOption } from 'Quotes/types/actions';

const Subsection = styled.div``;

function getSubnetTypeLabel(
  selectedIPType: string | undefined,
  ipAddressesOptions: Array<{ customId: string; label: string; value: string }>
): string | undefined {
  if (!selectedIPType) {
    return undefined;
  }

  const selectedOption = ipAddressesOptions.find((option) => option.value === selectedIPType);

  return selectedOption ? selectedOption.label : undefined;
}

function getLabelForDiaIPAllocation(diaIPAllocation: IPAddressAllocation | null): string | undefined {
  if (!diaIPAllocation) {
    return undefined;
  }
  const selectedOption = diaIPAllocationOptions.find((option) => option.id === diaIPAllocation);

  return selectedOption ? selectedOption.label : undefined;
}

function DualCircuitIPSelection(props: {
  primaryIPLabel?: string;
  additionalHelperText: false | string;
  options: { label: string; customId: IPType; value: IPType }[];
  setIPOption: (option: IPType) => ISetSecondIPOption;
  ipOption: IPType;
  readOnly: boolean | undefined;
  selectedPrimaryIP?: IPType;
}) {
  return (
    <Subsection>
      Primary: {props.primaryIPLabel}
      <IPAddressSelection
        additionalHelperText={props.additionalHelperText}
        options={props.options}
        setIPOption={props.setIPOption}
        ipOption={props.ipOption}
        typeText={'Secondary'}
        isReadOnly={props.readOnly}
        marginTop={'2'}
      />
    </Subsection>
  );
}

export function getPrimaryIPLabel(quote: IQuote): string | undefined {
  const newMDIAUI = featureFlag.isEnabled(Feature.newMDIAUI);
  const interimFix = (newMDIAUI && featureFlag.getAttribute<boolean>(Feature.newMDIAUI, 'interimFix')) || false;
  const isMDIA = quote.location.aEnd.is_managed_dia;
  const supportsPublicAddressSpace =
    quote.location.aEnd.dia_ip_allocation !== null &&
    IP_ADDRESS_SELECTION_AVAILABLE.includes(quote.location.aEnd.dia_ip_allocation);
  const staticIPTypeLabel = getIPTypeLabel(
    quote.location.aEnd.ip.selectedId!,
    isMDIA,
    quote.location.aEnd.dia_ip_allocation
  );
  const subnetTypeLabel = getSubnetTypeLabel(
    quote.location.aEnd.ip.selectedId!,
    getIpAddressesOptions(isMDIA, quote.location.aEnd.dia_ip_allocation, newMDIAUI, interimFix)
  );

  if (newMDIAUI) {
    if (interimFix) {
      return staticIPTypeLabel;
    } else if (isMDIA) {
      return subnetTypeLabel;
    } else if (!isMDIA && supportsPublicAddressSpace) {
      return subnetTypeLabel;
    }
  } else {
    return staticIPTypeLabel;
  }
  return undefined;
}

export function SecondaryIPAllocation(props: { quote: IQuote; isReadOnly?: boolean }) {
  const dispatch = useDispatch();

  const setSecondaryIP = (option: IPType) => dispatch(setSecondaryIPOption(option));
  const setDIAIPAllocation = (diaIPAllocation: IPAddressAllocation) =>
    dispatch(setDIAIPAllocationAction(diaIPAllocation));

  const selectedPrimaryIP = props.quote.location.aEnd.ip.selectedId;
  if (!selectedPrimaryIP) {
    return null;
  }

  const selectedSecondaryIP = props.quote.location.aEnd.secondIPChoice;
  const diaIPAllocation = props.quote.location.aEnd.dia_ip_allocation;
  const isMDIA = props.quote.location.aEnd.is_managed_dia;
  const newMDIAUI = featureFlag.isEnabled(Feature.newMDIAUI);
  const interimFix = (newMDIAUI && featureFlag.getAttribute<boolean>(Feature.newMDIAUI, 'interimFix')) || false;
  const ipAddressesOptions = getIpAddressesOptions(isMDIA, diaIPAllocation, newMDIAUI, interimFix);
  const supportsPublicAddressSpace =
    diaIPAllocation !== null && IP_ADDRESS_SELECTION_AVAILABLE.includes(diaIPAllocation); // this only shows for DIA + !interimFix + public allocation

  // displays in format eg. '/29 subnet (5 usable IP addresses)'
  const subnetTypeLabel = getSubnetTypeLabel(selectedPrimaryIP, ipAddressesOptions);
  // displays in format eg. '8 static IPs (/29 subnet)'
  const staticIPTypeLabel = getIPTypeLabel(selectedPrimaryIP, isMDIA, diaIPAllocation);
  // displays in format eg. 'Public IP Allocation Only'
  const diaIPAllocationLabel = getLabelForDiaIPAllocation(diaIPAllocation);

  const additionalHelperText = newMDIAUI && getAdditionalIPAddressesHelperText(isMDIA, diaIPAllocation);

  const ipOption =
    selectedSecondaryIP ??
    (() => {
      setSecondaryIP(selectedPrimaryIP);
      return selectedPrimaryIP;
    })();

  return (
    <>
      <Title>IP Allocation</Title>
      {newMDIAUI ? (
        <>
          {interimFix && (
            <DualCircuitIPSelection
              selectedPrimaryIP={selectedPrimaryIP}
              primaryIPLabel={staticIPTypeLabel}
              additionalHelperText={additionalHelperText}
              options={ipAddressesOptions}
              setIPOption={setSecondaryIP}
              ipOption={ipOption}
              readOnly={props.isReadOnly}
            />
          )}

          {isMDIA && !interimFix && (
            <DualCircuitIPSelection
              selectedPrimaryIP={selectedPrimaryIP}
              primaryIPLabel={subnetTypeLabel}
              additionalHelperText={additionalHelperText}
              options={ipAddressesOptions}
              setIPOption={setSecondaryIP}
              ipOption={ipOption}
              readOnly={props.isReadOnly}
            />
          )}

          {!isMDIA && !interimFix && !supportsPublicAddressSpace && (
            <Subsection>
              {diaIPAllocationLabel}
              <IPAddressAllocationSelection setDIAIPAllocation={setDIAIPAllocation} diaIPAllocation={diaIPAllocation} />
            </Subsection>
          )}
          {!isMDIA && !interimFix && supportsPublicAddressSpace && (
            <>
              <Subsection>Primary: {diaIPAllocationLabel}</Subsection>
              <Subsection className="small">
                Public Allocation: {subnetTypeLabel}
                <IPAddressAllocationSelection
                  setDIAIPAllocation={setDIAIPAllocation}
                  diaIPAllocation={diaIPAllocation}
                />
                <DualCircuitIPSelection
                  selectedPrimaryIP={selectedPrimaryIP}
                  additionalHelperText={additionalHelperText}
                  options={ipAddressesOptions}
                  setIPOption={setSecondaryIP}
                  ipOption={ipOption}
                  readOnly={props.isReadOnly}
                />
              </Subsection>
            </>
          )}
        </>
      ) : (
        <DualCircuitIPSelection
          selectedPrimaryIP={selectedPrimaryIP}
          primaryIPLabel={staticIPTypeLabel}
          additionalHelperText={additionalHelperText}
          options={ipAddressesOptions}
          setIPOption={setSecondaryIP}
          ipOption={ipOption}
          readOnly={props.isReadOnly}
        />
      )}
    </>
  );
}
