export default [
  {
    question: 'Why am I not seeing the expected tail provider?',
    answer: [
      "Not all providers cover every location. Should your chosen tail provider not appear in your search results, it's possible they don't serve the area you require.",
      'For our EoFTTx product we can only provide confirmation of service coverage based on specific address details, typically a ALK (Address Location Key) or GALK (Gold Address Location Key). This may impact the results returned and the tail providers displayed.',
      "If you believe that a particular tail provider should be showing in a quote search result but isn't, please contact your Account Director.",
    ],
  },
  {
    question: "Why can't I see my NNI?",
    answer: [
      "The list of NNIs available is specific to your customer account. If you're not seeing a recently created NNI in the list, it may be that it has not yet been uploaded into the portal. If a new NNI still isn't displayed after five working days following an order, please contact your Account Director.",
      'If an NNI was previously on the list but is no longer displayed, it may have been renamed upon request by someone at your organisation. Make sure to look at your list of NNIs for any that are similarly named or appear to be new. If you still believe the NNI is missing from your account following this, please contact to your Account Director.',
    ],
  },
  {
    question: 'Is my quoted price correct?',
    answer: [
      "Our pricing is regularly monitored and updated to ensure that we keep up with market trends and fluctuations. This forms part of LIVEQUOTE's dynamic ability to provide real-time availability, distance and cost calculations. If you believe that the price generated is incorrect, please check a similar recent quote for the same location, product, bandwidth and term to compare the pricing. In addition, the homepage of the LIVEQUOTE portal will outline any ongoing pricing promotions or activities that might be impacting your quote.",
      "If the pricing is higher than you'd expect to see and you cannot determine an obvious reason as to why, contact your Account Director who will review this for you.",
    ],
  },
  {
    question: 'Who can I talk to about my quoted price?',
    answer: [
      'To discuss the price of a quote, firstly please refer to our earlier FAQs (Is my quoted price correct?).',
      'If you would like to discuss further, please contact your Account Director in the first instance.',
    ],
  },
];
