export enum RootActionTypes {
  RESET_APP = 'RESET_APP',
}

export interface IOnResetApp {
  type: RootActionTypes.RESET_APP;
}

export const onResetApp = (): IOnResetApp => {
  return { type: RootActionTypes.RESET_APP };
};
