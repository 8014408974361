import FeatureFlag from 'FeatureFlags/types';

const allEnabled: FeatureFlag['environments'] = {
  dev: { enabled: true },
  integration: { enabled: true },
  testing: { enabled: true },
  staging: { enabled: true },
  preproduction: { enabled: true },
  production: { enabled: true },
};

// These feature flags are 'done' with, and are enabled in production.
// When we've double-checked with Neos and have some free time, we can remove them entirely!
export const oldFeatureFlags = {
  sampleFeature: {
    description: 'I am a sample feature, used for testing feature flags',
    environments: {
      dev: { enabled: true, attributes: { title: 'Dev Title' } },
      integration: { enabled: true },
      testing: { enabled: true, attributes: { title: 'Testing Title' } },
      staging: { enabled: true, attributes: { title: 'Staging Title' } },
      preproduction: { enabled: true, attributes: { title: 'Preproduction Title' } },
      production: { enabled: true, attributes: { title: 'Production Title' } },
    },
  },
  multiTermQuote: {
    description: 'Support for multi-term selection during quote creation - R001',
    environments: allEnabled,
  },
  multiQuoteToOrder: {
    description: 'Ability to select multiple quotes in a multi-quote and take them to order - R002',
    environments: allEnabled,
  },
  multiQuoteCopySection: {
    description: 'Ability to copy order sections across orders of the same multiquote - R002',
    environments: allEnabled,
  },
  messaging: {
    description: 'Messaging - R003',
    environments: allEnabled,
  },
  fttpAggregation: {
    description: 'Add FTTP Aggregation selection & costs',
    environments: allEnabled,
  },
  orderIncompleteAlert: {
    description: 'Show alert when order contains incomplete data such as `tbc` or `n/a` - R007',
    environments: allEnabled,
  },
};
