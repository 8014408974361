import { IQuoteListItem } from 'Quotes/types/quoteRecord';
import { formatProductType, formatQuoteLocation } from 'Quotes/utils/quoteTableHelpers';
import React from 'react';
import Dropdown from 'shared/components/molecules/Dropdown';
import { shortenId } from 'shared/utils/idFormatter';

export interface OrderedQuoteListItem extends IQuoteListItem {
  order_id: string;
}

interface CopyFromQuoteDropdownProps {
  otherOrderedQuotes: OrderedQuoteListItem[];
  onOrderSelect: (orderId: string | null) => void;
}

export const CopyFromQuoteDropdown = ({ otherOrderedQuotes, onOrderSelect }: CopyFromQuoteDropdownProps) => {
  const quoteOptions = [
    {
      label: 'Order ID / Product / Locations',
      options: otherOrderedQuotes
        .map((quote) => ({
          label: getQuoteOptionLabel(quote),
          value: quote.order_id!,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
  ];

  return (
    <Dropdown
      options={quoteOptions}
      placeholder="Select an order to copy from"
      onChange={(option) => {
        if (!option) onOrderSelect(null);
        else onOrderSelect(option.value);
      }}
    />
  );
};

const getQuoteOptionLabel = (quote: OrderedQuoteListItem) => {
  return `O-${shortenId(quote.order_id)} / ${formatProductType(quote.product_type)} / ${formatQuoteLocation(quote)}`;
};
