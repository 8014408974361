import { Supplier } from '../../../Quotes/types/supplier';
import { doRequest, Methods } from '../../../Request';

interface IUpdateCustomerAllowedSuppliersRequestBody {
  data: {
    attributes: {
      allowed_suppliers: Supplier[];
    };
    type: 'customer';
  };
}

export const updateCustomerAllowedSuppliers = (companyId: string, selectedSuppliers: Supplier[]) => {
  // Default suppliers (basically Neos themselves) to be added to every PATCH request.
  const defaultSuppliers = [Supplier.NNAT, Supplier.NONE];
  const body: IUpdateCustomerAllowedSuppliersRequestBody = {
    data: {
      attributes: {
        allowed_suppliers: selectedSuppliers.concat(defaultSuppliers),
      },
      type: 'customer',
    },
  };

  return doRequest({
    body,
    method: Methods.PATCH,
    path: `/customers/${companyId}`,
  });
};
