import React, { FC, useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import getQuotesFromBulkQuote from 'Quotes/crud/getQuotesFromBulkQuote';
import { IBulkQuoteByIdRecord } from 'Quotes/types/bulkQuoteById';
import Alert from 'shared/components/atoms/Alert';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import QuotesFromBulkTable from './QuotesFromBulkTable';
import StyledHeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import NoQuotesInBulkQuote from './NoQuotesInBulkQuote';
import { IAppState } from 'reducers';
import { usePreviousState } from 'shared/utils/customHooks';
import { bulkQuotes } from 'Quotes/Routes';
import { downloadQuotes as downloadQuotesAction } from 'Quotes/actions';
import IDownloadQuotesType from 'Quotes/types/downloadQuotes';
import { IRow } from 'shared/components/organisms/MultiSelectTable';
import { selectIsAuthorised } from 'User/selectors';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import { userPermissions } from 'shared/utils/permissions';
import { IRequestState } from 'shared/types/requestState';
import { Navigation } from 'shared/RouterComponents';

interface IBulkQuoteById {
  allowDownloadQuotes: boolean;
  companyId: string | null;
  downloading: IRequestState;
  downloadQuotes(payload: IDownloadQuotesType): void;
}

export const ERROR_MESSAGE = 'Error in fetching quotes';

enum FetchQuotesStatus {
  BEFORE_FETCHING = 'BEFORE_FETCHING',
  FETCHING_COMPLETE = 'FETCHING_COMPLETE',
}

type Params = {
  bulkQuoteId?: string;
};

export const BulkQuoteById: FC<React.PropsWithChildren<IBulkQuoteById>> = (props) => {
  const navigate = useNavigate();
  return <BulkQuoteByIdInternal {...props} navigate={navigate} />;
};

export const BulkQuoteByIdInternal: FC<React.PropsWithChildren<IBulkQuoteById & Navigation>> = ({
  companyId,
  downloadQuotes,
  downloading,
  allowDownloadQuotes,
  navigate,
}) => {
  useTrackPage(pages.bulkQuotesById);

  const { bulkQuoteId } = useParams<Params>();
  const [bulkQuotesData, setBulkQuotesData] = useState<IBulkQuoteByIdRecord>();
  const [fetchError, setError] = useState<string>('');
  const [fetchQuotesStatus, setFetchQuotesStatus] = useState<FetchQuotesStatus>(FetchQuotesStatus.BEFORE_FETCHING);
  const [selectedQuotes, setSelectedQuotes] = useState<IRow[]>([]);

  const onBulkCheckedChange = allowDownloadQuotes
    ? (items: IRow[]) => {
        setSelectedQuotes(items);
      }
    : undefined;

  const downloadQuotesPressed = (template: IDownloadQuotesType['template']) => {
    if (bulkQuotesData && selectedQuotes.length) {
      downloadQuotes({
        bulkQuoteId: bulkQuotesData.id,
        lineIds: selectedQuotes.map((item: IRow) => item.id.replace(/^Q/, 'q')),
        selectedAllQuotes: bulkQuotesData.attributes.bulk_lines!.length === selectedQuotes.length,
        template,
      });
    }
  };

  useEffect(() => {
    const fetchQuotesFromBulkQuote = async () => {
      try {
        const result = await getQuotesFromBulkQuote(bulkQuoteId!);
        const bulkQuoteById = result.data;
        setBulkQuotesData(bulkQuoteById);
      } catch (error) {
        setError(ERROR_MESSAGE);
      } finally {
        setFetchQuotesStatus(FetchQuotesStatus.FETCHING_COMPLETE);
      }
    };

    fetchQuotesFromBulkQuote();
  }, [bulkQuoteId]);

  const prevCompanyId = usePreviousState(companyId);

  useEffect(() => {
    if (prevCompanyId && companyId !== prevCompanyId) {
      navigate(bulkQuotes);
    }
  }, [companyId, navigate, prevCompanyId]);

  const quotesPresentInBulkQuote = (): boolean | undefined => {
    return bulkQuotesData && bulkQuotesData.attributes.bulk_lines && bulkQuotesData.attributes.bulk_lines.length > 0;
  };

  let content;
  if (quotesPresentInBulkQuote()) {
    content = (
      <QuotesFromBulkTable
        downloadQuotes={downloadQuotesPressed}
        downloading={downloading}
        selectedQuotes={selectedQuotes}
        onCheckedChange={onBulkCheckedChange}
        quotes={bulkQuotesData!.attributes.bulk_lines!}
        companyName={bulkQuotesData!.attributes.company_name}
        bulkQuoteId={bulkQuotesData!.attributes.short_id}
        quotesCompleted={bulkQuotesData!.attributes.completed_entries}
      />
    );
  } else if (fetchError !== '') {
    content = <Alert>{fetchError}</Alert>;
  } else {
    content = <NoQuotesInBulkQuote />;
  }

  return (
    <StyledHeaderWithChildrenWrapper header="Quotes" compact={true}>
      {fetchQuotesStatus === FetchQuotesStatus.BEFORE_FETCHING ? (
        <SpinnerWithText text="Retrieving quotes" size="large" />
      ) : (
        content
      )}
    </StyledHeaderWithChildrenWrapper>
  );
};

const mapStateToProps = (state: IAppState) => ({
  companyId: state.user.companyData.selectedCompanyId,
  downloading: state.quoteBuilder.downloading,
  allowDownloadQuotes: selectIsAuthorised(userPermissions.downloadBulkQuote, state),
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  downloadQuotes: (payload: IDownloadQuotesType) => dispatch(downloadQuotesAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkQuoteById);
