import React, { FC } from 'react';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import { Feature } from 'FeatureFlags/types';
import { EnabledByFlag } from 'FeatureFlags/EnabledByFlag';
import { Link } from 'shared/RouterComponents';

export function NavLinks() {
  // Only display links if there's more than one to display
  return (
    <EnabledByFlag feature={Feature.orderTemplates}>
      <Link to="/admin/preferences/supplier">Supplier Preferences</Link>
      <Link to="/admin/preferences/order-templates">Order Templates</Link>
    </EnabledByFlag>
  );
}

export const Preferences: FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <HeaderWithChildrenWrapper header="Preferences">
      <NavLinks />
      <div>{children}</div>
    </HeaderWithChildrenWrapper>
  );
};
