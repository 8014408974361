import Alert, { Alerts } from 'shared/components/atoms/Alert';
import React from 'react';
import { formatBandwidth } from 'shared/utils/connectionCapacity';

export function NoBearerWarning() {
  return (
    <Alert alertType={Alerts.WARNING}>
      Error in NNI port capacity. The available ports sizes and bandwidth speeds may be incompatible with your NNI
      selection(s). If you believe this to be incorrect, please contact your Account Manager.
    </Alert>
  );
}

export function MaximumBandwidthAlert(props: { bandwidth: number | null }) {
  return (
    <Alert alertType={Alerts.INFO}>
      The chosen NNI(s) have a maximum bandwidth constraint of {formatBandwidth(`${props.bandwidth}`)}.
    </Alert>
  );
}
