import React from 'react';
import { Arrow, Circuit } from 'shared/components/molecules/SecondaryCircuits/SecondaryExchanges';

export function PrimaryInfo(props: {
  aEndHeader: string;
  aEndType: string;
  aEndTestId: string;
  bEndHeader?: string;
  bEndType?: string;
  bEndTestId?: string;
}) {
  return (
    <Circuit data-testid="primary-circuit">
      <div>
        <h4>{props.aEndHeader}</h4>
        <div data-testid={`SecondaryCircuits-primary-${props.aEndTestId}`}>{props.aEndType}</div>
      </div>
      <Arrow />
      {props.bEndHeader && (
        <div>
          <h4>{props.bEndHeader}</h4>
          <div data-testid={`SecondaryCircuits-primary-${props.bEndTestId}`}>{props.bEndType}</div>
        </div>
      )}
    </Circuit>
  );
}
