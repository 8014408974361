import React, { FunctionComponent, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect, DispatchProp } from 'react-redux';
import { getUserAction, resetCurrentlyViewedUser } from '../actions';
import { IAppState } from 'reducers';
import { IGetUserPayload } from '../types/actions';
import UserProfile from './UserProfile';
import { RequestState } from 'User/types/requestState';
import Spinner from '../../shared/components/molecules/SpinnerWithText';
import Alert from '../../shared/components/atoms/Alert';
import { ICurrentlyViewedUserAttributes } from 'User/types/user';
import { pages, useTrackPage } from 'shared/utils/trackPage';

interface IViewEditUserPage {
  currentUserId: string;
  currentlyViewedUser: ICurrentlyViewedUserAttributes;
  setCurrentlyViewedUserRequestState: RequestState;
  getCurrentlyViewedUser: (getUserPayload: IGetUserPayload) => void;
  resetUser: () => void;
}

type Params = {
  userId?: string;
};

export const ViewEditUserPage: FunctionComponent<React.PropsWithChildren<IViewEditUserPage>> = ({
  currentUserId,
  currentlyViewedUser,
  setCurrentlyViewedUserRequestState,
  getCurrentlyViewedUser,
  resetUser,
}) => {
  const { userId } = useParams<Params>();

  useTrackPage(pages.user);

  useEffect(() => {
    if (userId !== currentUserId) {
      getCurrentlyViewedUser({ id: userId! });
    }
  }, [currentUserId, userId, getCurrentlyViewedUser]);

  useEffect(() => {
    return () => {
      resetUser();
    };
  }, [resetUser]);

  if (currentUserId === userId) {
    return <Navigate to="/user/profile" />;
  } else if (
    setCurrentlyViewedUserRequestState === RequestState.LOADING ||
    setCurrentlyViewedUserRequestState === RequestState.NOT_REQUESTED
  ) {
    return <Spinner text="Retrieving User" id="load-user-spinner" size="large" />;
  } else if (setCurrentlyViewedUserRequestState === RequestState.ERROR) {
    return (
      <Alert>
        Error retrieving user. Please try again later. If the problem persists, please contact your Account Manager.
      </Alert>
    );
  }
  return <UserProfile userData={currentlyViewedUser} />;
};

const mapStateToProps = (state: IAppState) => ({
  currentUserId: state.user.userData.id,
  currentlyViewedUser: state.admin.currentlyViewedUser,
  setCurrentlyViewedUserRequestState: state.admin.setCurrentlyViewedUserRequestState,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  getCurrentlyViewedUser: (getUserPayload: IGetUserPayload) => dispatch(getUserAction(getUserPayload)),
  resetUser: () => dispatch(resetCurrentlyViewedUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEditUserPage);
