import React from 'react';

import { OptionalIndicator } from '../OptionalIndicator';

interface ITextAreaInputProps {
  fieldName: string;
  inputIdentifierPrefix: string;
  labelText: string;
  value: string;
  placeholder?: string;
  optional?: boolean;
  maxLength?: number;
  readOnly?: boolean;
  onChange(e: React.ChangeEvent<HTMLTextAreaElement>): void;
}

const TextAreaInput = ({
  fieldName,
  inputIdentifierPrefix,
  labelText,
  value,
  maxLength,
  onChange,
  placeholder = "",
  optional = false,
  readOnly = false,
}: ITextAreaInputProps) => {
  const id = `${inputIdentifierPrefix}${fieldName}`;

  return (
    <div className="form-group mb-1">
      <label htmlFor={id} className="small mb-1">
        {labelText}
      </label>
      <textarea
        className={'form-control'}
        rows={5}
        id={id}
        maxLength={maxLength}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        name={fieldName}
        placeholder={placeholder}
      />
      {optional && <OptionalIndicator />}
    </div>
  );
};

export default TextAreaInput;
