import React, { FunctionComponent } from 'react';
import CardRow from 'shared/components/molecules/CardRow';
import { IOpenReachAddress, IOpenReachAddressSite, IPAFAddressSite } from 'shared/types/postcodeResults';
import Column from 'shared/components/atoms/Column';
import { AddressType, IPriceData, IQuote, QuoteOrigin } from 'Quotes/types/store';
import { IOrderLocation, ISecondarySiteConfig, ISiteConfig, ISiteContact, SiteType as ST } from 'Order/types/location';
import SiteContactInformation from './SiteContactInformation';
import SiteReadinessInformation from './SiteReadinessInformation';
import ShowOnNetAddress from './ShowOnNetAddress';
import AddressSubSection from './AddressSubSection';
import { SiteType } from './SiteType';
import { IOrderMetaForLocation } from 'Order/types/store';
import { useOpenReachAddressesFetch, usePAFAddressesFetch } from 'Order/OrderBuilder/shared/hooks';
import EndCompanyName from './EndCompanyName';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { AddressAndContactInfo, SiteConfigInfo } from './ContextualHelp';
import { ProductType } from 'Quotes/types/productTypes';
import { isSelectedPriceOnNet } from 'Order/OrderBuilder/shared/utils/isSelectedPriceOnNet';
import { IUpdateOnNetAddress, SetSiteCoords } from 'Order/types/actions';
import { SiteLatLon } from './SiteLatLon';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { useSecondaryCircuits } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { isP2NNI, P2NNICircuitData } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import { NNISiteConfig } from 'Order/OrderBuilder/Locations/shared/components/Edit/NNISiteConfig';
import { DualCircuitSiteConfig } from 'Order/OrderBuilder/Locations/shared/components/Edit/DualCircuitSiteConfig';
import { SingleCircuitSiteConfig } from 'Order/OrderBuilder/Locations/shared/components/Edit/SingleCircuitSiteConfig';
import { DualCircuitNNISiteConfig } from 'Order/OrderBuilder/Locations/shared/components/Edit/DualCircuitNNISiteConfig';

export interface Props {
  quote: IQuote;
  location: IOrderLocation;
  identifierPrefix: string;
  otherSiteContact?: ISiteContact;
  postcode: string;
  isNNI?: boolean;
  isNewNNI?: boolean;
  hasShadowNNI?: boolean;
  isNewShadowNNI?: boolean;
  isFTTXQuote: boolean;
  isSupplierNNAT: boolean;
  nniLabel?: string;
  orderMeta: IOrderMetaForLocation;
  quoteMeta: IMeta;
  addressType: AddressType;
  endPort: BearerType;
  showSiteTypeConfigForPoint: boolean;
  selectedPrice: IPriceData;
  saveOnNetAddressSelection(attributes: IUpdateOnNetAddress['payload']['attributes']): void;
  fieldOnChange(fieldName: keyof IOpenReachAddress, fieldValue: string): void;
  saveFullAddress(address: IOpenReachAddressSite | null): void;
  savePAFAddress(aEndAddress: IPAFAddressSite | null): void;
  setPAFAddressNotListed(notListed: boolean): void;
  setFullAddressNotListed(notListed: boolean): void;
  copyLocationASiteContactToLocationB?: () => void;
  updateLocation(updated: IOrderLocation): void;
  setEndCompanyName(endCompanyName: string): void;
  setSiteType(siteType: ST | null): void;
  setAddressType(endAddressType: AddressType): void;
  setSiteCoords(field: SetSiteCoords['payload']['field'], value: SetSiteCoords['payload']['value']): void;
}

export const siteConfigProps = (productType?: ProductType | null) => {
  switch (productType) {
    case ProductType.P2P:
    case ProductType.P2NNI:
    case ProductType.DIA:
      return {
        renderPaneContent: () => <SiteConfigInfo />,
        infoPaneTitle: 'Site Config information',
      };

    default:
      return {};
  }
};

export const addressAndContactProps = (productType?: ProductType | null) => {
  switch (productType) {
    case ProductType.P2P:
    case ProductType.P2NNI:
    case ProductType.DIA:
      return {
        renderPaneContent: () => <AddressAndContactInfo />,
        infoPaneTitle: 'Address and contact information',
      };

    default:
      return {};
  }
};

const Edit: FunctionComponent<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    identifierPrefix,
    location,
    isFTTXQuote,
    isSupplierNNAT,
    isNNI,
    isNewNNI,
    isNewShadowNNI,
    nniLabel,
    quoteMeta,
    quote,
    hasShadowNNI,
    otherSiteContact,
    selectedPrice,
    updateLocation,
    copyLocationASiteContactToLocationB,
    setAddressType,
    setSiteType,
    showSiteTypeConfigForPoint,
    saveOnNetAddressSelection,
    setSiteCoords,
  } = props;

  const postcode = location.locationData.readonly_postcode;
  const pafAddress = location.locationData.pafAddress;
  const {
    errorInFetchingOpenReach,
    openReachAddresses,
    fetchingOpenReachAddressesInProgress,
  } = useOpenReachAddressesFetch({
    enabled: !isFTTXQuote && !isSupplierNNAT,
    postcode: postcode,
    pafAddress: pafAddress,
  });

  const onNetAddressIsPresent = (): boolean => {
    return !!props.orderMeta.reference || !!props.orderMeta.location;
  };

  const { errorInFetchingPAF, PAFaddresses, fetchingPAFAddressesInProgress } = usePAFAddressesFetch({
    enabled: !isFTTXQuote && !isSupplierNNAT,
    postcode: postcode,
  });
  const locationFullAddress = location.locationData.fullAddress as IOpenReachAddress;
  const isAPIOnNet = quote.origin === QuoteOrigin.API && isSelectedPriceOnNet(selectedPrice, identifierPrefix);

  const getONATAddress = () => {
    if (identifierPrefix === 'aEndLocation') {
      return quote.location.aEnd.onatAddress ?? null;
    } else {
      return quote.location.bEnd.onatAddress ?? null;
    }
  };

  const secondaryCircuits = useSecondaryCircuits(selectedPrice);
  const circuitData = secondaryCircuits.circuitData;

  if (isNNI) {
    const p2nniCircuitData = circuitData && isP2NNI(circuitData) ? (circuitData as P2NNICircuitData) : undefined;
    const nniData = p2nniCircuitData?.aEnd;
    const dataCentre = nniData?.nniCapableDataCentres?.find((it) => it.id === nniData?.selected?.selectedDataCentreId);
    if (featureFlag.isEnabled(Feature.SecondaryCircuits) && location.secondarySiteConfig && nniData?.selected) {
      return (
        <DualCircuitNNISiteConfig
          quote={quote}
          quoteMeta={quoteMeta}
          identifierPrefix={identifierPrefix}
          newNNI={isNewNNI}
          nniLabel={nniLabel}
          selectedPrice={selectedPrice}
          siteConfig={location.siteConfig}
          secondarySiteConfig={location.secondarySiteConfig}
          updateSiteConfig={(siteConfig: ISiteConfig) =>
            updateLocation({
              ...location,
              siteConfig,
            })
          }
          nniData={nniData}
          dataCentre={dataCentre}
          updateSecondarySiteConfig={(secondarySiteConfig: ISecondarySiteConfig) =>
            updateLocation({ ...location, secondarySiteConfig })
          }
        />
      );
    }
    return (
      <>
        <NNISiteConfig
          customerName={quote.customerName}
          dataCentre={quoteMeta.nni_label}
          defaultNNIRequestContactEmail={quote.createdBy}
          hasShadowNNI={hasShadowNNI}
          identifierPrefix={identifierPrefix}
          isNewNNI={isNewNNI}
          isNewShadowNNI={isNewShadowNNI}
          nniLabel={nniLabel}
          popId={selectedPrice.a_end_p_o_p_id}
          quote={quote}
          quoteMeta={quoteMeta}
          showShadowNNI={true}
          siteConfig={location.siteConfig}
          siteConfigProps={siteConfigProps(quote.productType)}
          updateSiteConfig={(siteConfig: ISiteConfig) => updateLocation({ ...location, siteConfig })}
        />
      </>
    );
  }

  return (
    <>
      <CardRow title="Address &amp; contact" {...addressAndContactProps(quote.productType)}>
        <div className="row no-gutters mb-4">
          <Column smWidth={12} mdWidth={12} lgWidth={12} xlWidth={10}>
            <EndCompanyName
              identifierPrefix={props.identifierPrefix}
              endCompanyName={location.endCompanyName}
              setEndCompanyName={props.setEndCompanyName}
            />
            <div className="row">
              <Column smWidth={12} mdWidth={6}>
                {location.locationData.addressType === AddressType.ON_NET && (onNetAddressIsPresent() || isAPIOnNet) ? (
                  <ShowOnNetAddress
                    identifierPrefix={identifierPrefix}
                    fieldOnChange={props.fieldOnChange}
                    addressOnChange={saveOnNetAddressSelection}
                    fullQuoteAddress={props.orderMeta}
                    locationFullAddress={locationFullAddress}
                    selectedPrice={selectedPrice}
                    isAPIOnNet={isAPIOnNet}
                  />
                ) : (
                  <AddressSubSection
                    {...props}
                    PAFLocation={pafAddress}
                    pafAddresses={PAFaddresses}
                    fullAddressNotListed={location.locationData.fullAddressNotListed}
                    pafAddressNotListed={location.locationData.pafAddressNotListed}
                    fetchingOpenReachAddressesInProgress={fetchingOpenReachAddressesInProgress}
                    fetchingPAFAddressesInProgress={fetchingPAFAddressesInProgress}
                    errorInFetchingOpenReach={errorInFetchingOpenReach}
                    errorInFetchingPAF={errorInFetchingPAF}
                    openReachAddresses={openReachAddresses}
                    locationFullAddress={locationFullAddress}
                    onatAddress={getONATAddress()}
                    isFTTXQuote={isFTTXQuote}
                    isSupplierNNAT={isSupplierNNAT}
                    className="px-0 address-subsection"
                    setAddressType={setAddressType}
                    locationLookupIntent={
                      props.identifierPrefix === 'aEndLocation'
                        ? quoteMeta.a_end_location_lookup
                        : quoteMeta.b_end_location_lookup
                    }
                  />
                )}
              </Column>
              <Column smWidth={12} mdWidth={6}>
                <SiteLatLon
                  coords={location.siteCoords}
                  identifierPrefix={props.identifierPrefix}
                  onChange={setSiteCoords}
                  isManualAddress={
                    location.locationData.fullAddressNotListed || location.locationData.pafAddressNotListed
                  }
                />
              </Column>
            </div>
            {showSiteTypeConfigForPoint && <SiteType siteType={location.siteType} setSiteType={setSiteType} />}
            <SiteContactInformation
              siteContact={location.siteContact}
              productType={quote.productType}
              identifierPrefix={identifierPrefix}
              otherSiteContact={otherSiteContact}
              copyLocationASiteContactToLocationB={copyLocationASiteContactToLocationB}
              updateSiteContact={(siteContact) => updateLocation({ ...location, siteContact })}
            />
          </Column>
        </div>
      </CardRow>
      {featureFlag.isEnabled(Feature.SecondaryCircuits) && location.secondarySiteConfig ? (
        <DualCircuitSiteConfig
          quote={quote}
          endPort={props.endPort}
          siteConfig={location.siteConfig}
          secondarySiteConfig={location.secondarySiteConfig}
          identifierPrefix={identifierPrefix}
          fttxQuote={isFTTXQuote}
          supplierNNAT={isSupplierNNAT}
          updateSiteConfig={(siteConfig) => updateLocation({ ...location, siteConfig })}
          selectedPrice={selectedPrice}
          updateSecondarySiteConfig={(secondarySiteConfig: ISecondarySiteConfig) =>
            updateLocation({ ...location, secondarySiteConfig })
          }
        />
      ) : (
        <SingleCircuitSiteConfig
          quote={quote}
          endPort={props.endPort}
          siteConfig={location.siteConfig}
          identifierPrefix={identifierPrefix}
          fttxQuote={isFTTXQuote}
          supplierNNAT={isSupplierNNAT}
          updateSiteConfig={(siteConfig) => updateLocation({ ...location, siteConfig })}
          selectedPrice={selectedPrice}
        />
      )}
      {quote.productType !== ProductType.OpticalP2P && <CardRow title="Site Readiness">
        <div className="row no-gutters">
          <Column smWidth={12} mdWidth={12} lgWidth={12} xlWidth={10}>
            <SiteReadinessInformation
              siteReadiness={location.siteReadiness}
              identifierPrefix={identifierPrefix}
              updateSiteReadiness={(siteReadiness) => updateLocation({ ...location, siteReadiness })}
            />
          </Column>
        </div>
      </CardRow>}
    </>
  );
};

export default Edit;
