import { RowCell } from '../../shared/components/organisms/MultiSelectTable';

export function isCheckboxCell(cell: RowCell): boolean {
  return cell.column.id === 'selection';
}

export const formatAttributeDisplay = (attribute: string | undefined | null): string | undefined => {
  return isAttributeInvalid(attribute) ? undefined : attribute!;
};

const isAttributeInvalid = (attribute: string | undefined | null): boolean => {
  return typeof attribute !== 'string' || attribute.length < 1;
};
