import React, { FC } from 'react';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';

export const EnabledByFlag: FC<React.PropsWithChildren<{ feature: Feature }>> = ({ feature, children }) => {
  if (featureFlag.isEnabled(feature)) return <>{children}</>;
  else return null;
};

export const DisabledByFlag: FC<React.PropsWithChildren<{ feature: Feature }>> = ({ feature, children }) => {
  if (!featureFlag.isEnabled(feature)) return <>{children}</>;
  else return null;
};
