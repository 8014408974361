import Column from '../../../shared/components/atoms/Column';
import { capitalize } from 'lodash';
import { Copy } from '../../../shared/components/molecules/Copy';
import React from 'react';

export function OrderId(props: { onClick: () => void; string: string; recentlyCopied: boolean }) {
  return (
    <Column defaultWidth={12} mdWidth={9}>
      <h1 className="mb-0">
        Order{' '}
        <span className="copy-to-clipboard" onClick={props.onClick}>
          {capitalize(props.string)}
          <Copy isRecentlyCopied={props.recentlyCopied} />
        </span>
      </h1>
    </Column>
  );
}
