import { doRequest, Methods } from '../../../Request/index';
import { objectToGetParams } from '../../../shared/utils/objectToGetParams';

export const ALERTS_PAGE_SIZE = 50;

const getAlerts = () => {
  return doRequest({
    method: Methods.GET,
    path: `/events?${objectToGetParams({
      'page[size]': `${ALERTS_PAGE_SIZE}`,
    })}`,
  });
};

export default getAlerts;
