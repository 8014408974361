import { IOrderListItem, IOrdersResponse } from '../../Order/types/orderRecord';

export function transformOrderDataToListItems(response: IOrdersResponse): IOrderListItem[] {
  return response.data.map((data) => {
    const quote = response.included.find((includedItem) => includedItem.id === data?.relationships?.quotes?.data.id);
    return {
      ...data.attributes,
      id: data.id,
      quote: quote && {
        ...quote!.attributes,
        id: quote!.id,
      },
    };
  });
}
