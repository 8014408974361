import { Role } from 'User/types/role';

enum Permission {
  cancelOrderSSE = 'cancelOrderSSE',
  cancelOrderCustomer = 'cancelOrderCustomer',
  costs = 'costs',
  createEditQuote = 'createEditQuote',
  deleteOrder = 'deleteOrder',
  deleteQuote = 'deleteQuote',
  deleteUser = 'deleteUser', // Not available yet in UI but exists on backend
  downloadBulkQuote = 'downloadBulkQuote',
  editOrder = 'editOrder',
  exportPricing = 'exportPricing',
  manageUser = 'manageUser',
  placeOrder = 'placeOrder',
  reports = 'reports',
  showOnNetAddressRef = 'showOnNetAddressRef',
  submitOrderForReview = 'submitOrderForReview',
  updatePricing = 'updatePricing',
  viewDistrictCode = 'viewDistrictCode',
  viewEditAPIQuote = 'viewEditAPIQuote',
  viewEditBulkQuote = 'viewEditBulkQuote',
  viewOrder = 'viewOrder',
  viewQuote = 'viewQuote',
  viewCRMOrderResponse = 'viewCRMOrderResponse',
  resendCRMOrder = 'resendCRMOrder',
  viewEditPromoOffers = 'viewEditPromoOffers',
  customerPreferences = 'customerPreferences',
}

export const userPermissions: Record<Permission, Role[]> = {
  cancelOrderSSE: [Role.SSEAdmin],
  cancelOrderCustomer: [Role.Orderer, Role.Admin],
  createEditQuote: [Role.Admin, Role.SSEAdmin, Role.SSEAccountManager, Role.Quoter, Role.Orderer],
  costs: [Role.SSEAdmin, Role.SSEAccountManager],
  deleteOrder: [Role.SSEAdmin],
  deleteQuote: [Role.SSEAdmin, Role.SSEAccountManager],
  deleteUser: [Role.SSEAdmin],
  downloadBulkQuote: [Role.SSEAdmin, Role.SSEAccountManager],
  editOrder: [Role.Quoter, Role.Orderer, Role.Admin, Role.SSEAdmin, Role.SSEAccountManager],
  exportPricing: [Role.SSEPricing],
  manageUser: [Role.Admin, Role.SSEAdmin],
  placeOrder: [Role.SSEAdmin],
  reports: [Role.SSEAdmin, Role.SSEAccountManager],
  showOnNetAddressRef: [Role.SSEAdmin, Role.SSEAccountManager],
  submitOrderForReview: [Role.Admin, Role.Orderer],
  updatePricing: [Role.SSEPricing],
  viewDistrictCode: [Role.SSEAccountManager, Role.SSEAdmin],
  viewEditAPIQuote: [Role.SSEAdmin, Role.SSEAccountManager, Role.Admin, Role.Quoter, Role.Orderer],
  viewEditBulkQuote: [Role.SSEAdmin, Role.SSEAccountManager],
  viewEditPromoOffers: [Role.SSEAdmin, Role.SSEAccountManager],
  viewOrder: [Role.Orderer, Role.Admin, Role.SSEAdmin, Role.SSEAccountManager, Role.Quoter],
  viewQuote: [Role.Admin, Role.SSEAdmin, Role.SSEAccountManager, Role.Quoter, Role.Orderer],
  viewCRMOrderResponse: [Role.SSEAdmin, Role.SSEAccountManager],
  resendCRMOrder: [Role.SSEAdmin],
  customerPreferences: [Role.Admin, Role.SSEAdmin],
};
