import { ILocationGroup } from '../types/store';
import { IP2PAvailabilityProps } from '../crud/getAccessAvailabilityP2P';

const buildP2PAvailabilityProps = (location: ILocationGroup): IP2PAvailabilityProps => {
  return {
    a_end_galk: location.aEnd.openreachAddress?.attributes.alk || '',
    a_end_district_code: location.aEnd.openreachAddress?.attributes.css_district_code || '',
    a_end_postcode: location.aEnd.postcode || '',
    b_end_galk: location.bEnd.openreachAddress?.attributes.alk || '',
    b_end_district_code: location.bEnd.openreachAddress?.attributes.css_district_code || '',
    b_end_postcode: location.bEnd.postcode || '',
  };
};

export default buildP2PAvailabilityProps;
