import styled from 'styled-components';

export const AlignContentRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AlignContentCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlignContentLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const FlexSpacedMd = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;
