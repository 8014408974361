import React, { FC, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';

import Avatar from '../../../shared/components/atoms/Avatar';
import SignOutPanel from '../SignOutPanel';
import LinkDropdown from '../LinkDropdown';
import { NavLinkWithActive } from 'shared/RouterComponents';

interface IUserDropdownProps {
  theme: Partial<DefaultTheme>;
  name?: string;
  className?: string;
  image?: string;
}

const UserDropdown: FC<IUserDropdownProps> = (props: IUserDropdownProps) => {
  const { className, image } = props;
  const location = useLocation();
  const [signOutPanelOpen, setSignOutPanelOpen] = useState(false);

  return (
    <div className={`${className} user-dropdown`}>
      <LinkDropdown
        isActive={location.pathname === '/user/profile'}
        title={<Avatar className="user-avatar" image={image} />}
      >
        {props.name && <div className="pointer-none">{props.name}</div>}
        <NavLinkWithActive key="profile" to="/user/profile">
          Profile
        </NavLinkWithActive>
        <div className="dropdown__sign-out-option" key="signout" onClick={() => setSignOutPanelOpen(true)}>
          Sign out
        </div>
      </LinkDropdown>
      {signOutPanelOpen && <SignOutPanel onClose={() => setSignOutPanelOpen(false)} />}
    </div>
  );
};

const StyledUserDropdown = styled(UserDropdown)`
  .user-avatar {
    position: relative;
    left: -8px;
  }

  .pointer-none {
    pointer-events: none;
  }
  .user-avatar path {
    transition: all 300ms linear;
  }
  .active .user-avatar {
    path {
      fill: white;
      opacity: 1;
    }
  }
  .active.open .user-avatar {
    path {
      fill: ${(props) => props.theme.colours.primaryB1};
      opacity: 1;
    }
  }
  .dropdown__options {
    left: auto;
    right: 0;
  }

  .dropdown__control {
    position: relative;
    left: -14px;
  }
`;

export default StyledUserDropdown;
