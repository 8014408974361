import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  color: ${(props) => props.theme.colours.primaryA1};
  background: #f1f5f7;
  font-size: 12px;
  border-radius: 4px;
  text-align: center;
`;

export const DetailBlock = ({ children, dataTestId }: PropsWithChildren<any> & { dataTestId?: string }) => (
  <Block data-testid={dataTestId}>{children}</Block>
);
