import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonMainStyle } from '../../atoms/Button';
import StyledIcon from '../../atoms/Icon';

interface IPostcodeLookupProps {
  className?: string;
  initialPostcode?: string;
  isLoading: boolean;
  showValidPostcodeFeedback: boolean;
  onLookup(postcode: string): void;
  onChange?(postcode: string): void;
  clearErrorMessage?(): void;
}

const PostcodeLookup: FunctionComponent<React.PropsWithChildren<IPostcodeLookupProps>> = ({
  className,
  initialPostcode = '',
  isLoading,
  onLookup,
  onChange,
  showValidPostcodeFeedback,
  clearErrorMessage,
}) => {
  const [postcode, setPostcode] = useState<string>(initialPostcode);
  const update = (value: string) => {
    setPostcode(value);

    if (typeof onChange === 'function') {
      onChange(value);
    }
  };
  const trimValue = () => {
    const newValue = postcode.trim();
    if (postcode !== newValue) {
      update(newValue);
    }
    return newValue;
  };

  const onChangeHandler = (event: any) => {
    update(event.target.value);
    if (typeof clearErrorMessage === 'function') {
      clearErrorMessage();
    }
  };
  const onBlur = () => {
    trimValue();
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newValue = trimValue();
    onLookup(newValue);
  };

  return (
    <div className={`${className} pb-2`}>
      <div>
        <span>Postcode</span>
      </div>
      <form onSubmit={onSubmit}>
        <input onBlur={onBlur} onChange={onChangeHandler} type="text" value={postcode} className="postcode-input" />
        <Button
          type="submit"
          disabled={isLoading}
          loading={isLoading}
          className="find-btn"
          mainStyle={ButtonMainStyle.SecondaryRectangular}
        >
          Find
        </Button>
        {showValidPostcodeFeedback && <StyledIcon name="check" className="success-icon" />}
      </form>
    </div>
  );
};

const StyledPostcodeLookup = styled(PostcodeLookup)`
  span {
    font-size: 0.95rem;
    color: ${(props) => props.theme.colours.grey60};
  }

  .success-icon {
    color: ${(props) => props.theme.colours.primaryB1};
    margin-left: 0.4em;
    vertical-align: middle;
    font-size: 1.8em;
  }

  input {
    margin-right: 0.8em;
    max-width: 100%;
    text-transform: uppercase;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  button {
    margin: 0.5em 0;
    max-width: 10em;
  }

  .postcode-input {
    color: ${(props) => props.theme.colours.grey90};
    padding-left: 1em;
    height: 2.5em;
  }

  .find-btn {
    height: 2.5em;
    width: 115px;
    margin-top: 0.25em;
  }
`;

export default StyledPostcodeLookup;
