import React, { FunctionComponent, ReactNode, useState } from 'react';

import IQuoteAccordionRenderProps from './types/QuoteAccordionRenderProps';

interface IQuoteAccordionProps {
  className?: string;
  initialActiveIndex?: number;
  render(accordionRenderProps: IQuoteAccordionRenderProps): ReactNode;
}

const QuoteAccordion: FunctionComponent<React.PropsWithChildren<IQuoteAccordionProps>> = ({
  className,
  initialActiveIndex = 0,
  render,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(initialActiveIndex);

  React.useEffect(() => {
    setActiveIndex(initialActiveIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showContent = (index: number) => setActiveIndex(index);

  return <div className={className}>{render({ activeIndex, showContent })}</div>;
};

export default QuoteAccordion;
