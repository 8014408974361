import { calculateUpFront } from 'Quotes/QuoteBuilder/utils/price';
import React from 'react';
import AnnualCharges from './AnnualCharges';
import UpfrontCosts, { IMDIAPrices } from './UpfrontCosts';
import { Charges } from './TillReceipt';
import { DIAConfig, TotalsSelectedPriceProps } from './Totals';
import { ProductType } from 'Quotes/types/productTypes';
import {
  getMdiaPrices,
  getMdiaPricesSecondary,
} from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/SingleCircuitTotals';
import {
  calculatePrimaryAnnualExtraTotals,
  calculateCombinedAnnualExtraTotals,
  calculateSecondaryAnnualExtraTotals,
} from 'Quotes/QuoteBuilder/utils/costsUtilities';
import { DualCircuitRouterConfigurations } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { IQuote } from 'Quotes/types/store';

interface MultiCircuitTotalsProps {
  charges: Charges;
  includeFTTPAggregationCharge: boolean;
  productType: ProductType | null | undefined;
  secondaryCharges: Charges;
  secondarySelectedPrice: TotalsSelectedPriceProps;
  selectedPrice: TotalsSelectedPriceProps;
  showShadowVLANPrice: boolean;
  priceAmortised: boolean;
  diaConfig: DIAConfig;
  quote: IQuote;
}

// const combineMDIAPrices = (
//   primary: IMDIAPrices | undefined,
//   // secondary?: IMDIAPrices | undefined,
//   quote: IQuote
// ): IMDIAPrices => {
//   const options = quote.location.aEnd.secondRouterOptions;
//   const engineerInstallationRequired = options?.engineerInstallationRequired;
//   const rackMountKitRequired = options?.rackMountKitRequired;
//   return {
//     engineerInstallation:
//       (primary?.engineerInstallation ?? 0) + (engineerInstallationRequired ? MDIA_ENGINEER_COST : 0),
//     rackMountKit: (primary?.rackMountKit ?? 0) + (rackMountKitRequired ? MDIA_RACK_MOUNT_KIT_COST : 0),
//     // both primary as secondary router costs same as primary router cost
//     router: (primary?.router ?? 0) + (primary?.router ?? 0),
//   };
// };

export const combineCharges = (primary: Charges, secondary: Charges): Charges => {
  return {
    amortisedPrice: primary.amortisedPrice + secondary.amortisedPrice,
    annualPrice: primary.annualPrice + secondary.annualPrice,
    marginAmount: primary.marginAmount + secondary.marginAmount,
    marginPercentage: primary.marginPercentage + secondary.marginPercentage,
    totalContractValue: primary.totalContractValue + secondary.totalContractValue,
  };
};

function getSecondaryMDIAPrices(diaConfig: DIAConfig, secondarySelectedPrice: TotalsSelectedPriceProps, quote: IQuote) {
  return diaConfig.routerChoice === DualCircuitRouterConfigurations.SINGLE
    ? undefined
    : getMdiaPricesSecondary(diaConfig, secondarySelectedPrice, quote);
}

export const MultiCircuitTotals: React.FC<MultiCircuitTotalsProps> = ({
  selectedPrice,
  showShadowVLANPrice,
  charges,
  includeFTTPAggregationCharge,
  productType,
  secondarySelectedPrice,
  secondaryCharges,
  priceAmortised,
  diaConfig,
  quote,
}) => {
  const primaryMDIAPrices = getMdiaPrices(diaConfig, selectedPrice);
  const secondaryMDIAPrices = getSecondaryMDIAPrices(diaConfig, selectedPrice, quote);
  const primaryAnnualCharges = calculatePrimaryAnnualExtraTotals(selectedPrice);
  const secondaryAnnualCharges = calculateSecondaryAnnualExtraTotals(secondarySelectedPrice, selectedPrice, quote);
  const totalAnnualCharges = calculateCombinedAnnualExtraTotals(selectedPrice, quote, secondarySelectedPrice);
  const secondaryIPChoice = quote.location.aEnd.secondIPChoice;
  const secondaryIPType = secondaryIPChoice ? secondaryIPChoice : diaConfig.ipType;

  const getCombinedMDIAPrices = (
    primaryMDIAPrice: IMDIAPrices | undefined,
    secondaryMDIAPrice: IMDIAPrices | undefined
  ) => {
    return {
      engineerInstallation:
        (Number(primaryMDIAPrice?.engineerInstallation) ?? 0) + (Number(secondaryMDIAPrice?.engineerInstallation) ?? 0),
      rackMountKit: (Number(primaryMDIAPrice?.rackMountKit) ?? 0) + (Number(secondaryMDIAPrice?.rackMountKit) ?? 0),
      router: (Number(primaryMDIAPrice?.router) ?? 0) + (Number(secondaryMDIAPrice?.router) ?? 0),
    };
  };

  const combinedMDIAPrices = getCombinedMDIAPrices(primaryMDIAPrices, secondaryMDIAPrices);

  return (
    <>
      <div data-testid="primary-prices">
        <div className="annual-suppliers-prompt mb-2">Primary</div>
        <UpfrontCosts
          selectedPrice={selectedPrice}
          showShadowVLANPrice={showShadowVLANPrice}
          mdiaPrices={primaryMDIAPrices}
          calculateUpFront={calculateUpFront}
          priceAmortised={priceAmortised}
          quote={quote}
          label={'Primary'}
        />
        <AnnualCharges
          charges={charges}
          includeFTTPAggregationCharge={includeFTTPAggregationCharge}
          productType={productType}
          mdiaPrices={primaryMDIAPrices}
          ipType={diaConfig.ipType}
          isManagedDIA={diaConfig.isManagedDIA}
          diaIPAllocation={diaConfig.diaIPAllocation}
          engineerInstallationRequired={diaConfig.engineerInstallationRequired}
          priceAmortised={priceAmortised}
          annualCharges={primaryAnnualCharges}
        />
      </div>
      <br />
      <div data-testid="secondary-prices">
        <div className="annual-suppliers-prompt mb-2">Secondary</div>
        <UpfrontCosts
          selectedPrice={secondarySelectedPrice}
          showShadowVLANPrice={showShadowVLANPrice}
          mdiaPrices={secondaryMDIAPrices}
          calculateUpFront={calculateUpFront}
          priceAmortised={priceAmortised}
          quote={quote}
          label={'Secondary'}
        />
        <AnnualCharges
          charges={secondaryCharges}
          includeFTTPAggregationCharge={includeFTTPAggregationCharge}
          productType={productType}
          mdiaPrices={secondaryMDIAPrices}
          ipType={secondaryIPType}
          isManagedDIA={diaConfig.isManagedDIA}
          diaIPAllocation={diaConfig.diaIPAllocation}
          engineerInstallationRequired={diaConfig.engineerInstallationRequired}
          priceAmortised={priceAmortised}
          annualCharges={secondaryAnnualCharges}
        />
      </div>
      <hr />
      <div data-testid="total-prices">
        <div className="annual-suppliers-prompt mb-2">Total</div>
        <UpfrontCosts
          selectedPrice={selectedPrice}
          secondarySelectedPrice={secondarySelectedPrice}
          showShadowVLANPrice={showShadowVLANPrice}
          mdiaPrices={combinedMDIAPrices}
          calculateUpFront={calculateUpFront}
          priceAmortised={priceAmortised}
          quote={quote}
          label={'Total'}
        />
        <AnnualCharges
          charges={combineCharges(charges, secondaryCharges)}
          includeFTTPAggregationCharge={includeFTTPAggregationCharge}
          productType={productType}
          mdiaPrices={combinedMDIAPrices}
          isManagedDIA={diaConfig.isManagedDIA}
          diaIPAllocation={diaConfig.diaIPAllocation}
          engineerInstallationRequired={diaConfig.engineerInstallationRequired}
          priceAmortised={priceAmortised}
          annualCharges={totalAnnualCharges}
        />
      </div>
    </>
  );
};

export default MultiCircuitTotals;
