import React, { FunctionComponent, useState } from 'react';
import TextInput from '../../shared/components/atoms/TextInput';
import Button, { ButtonMainStyle } from '../../shared/components/atoms/Button';
import Alert, { Alerts } from '../../shared/components/atoms/Alert';
import styled from 'styled-components';

const PASSWORDS_MISMATCH = 'Passwords do not match.';

type UpdatePassword = FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
    loading: boolean;
    onUpdate(password: string): void;
  }>
>;

const UpdatePassword: UpdatePassword = (props) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const isPasswordValid = password.length > 0 && passwordConfirm.length > 0;
  const passwordMismatch = passwordConfirm !== password;

  return (
    <>
      <TextInput
        id="password"
        onChange={(e) => setPassword(e.target.value)}
        labelText="New Password"
        type="password"
        value={password}
      />
      <TextInput
        id="confirm-password"
        onChange={(e) => setPasswordConfirm(e.target.value)}
        labelText="Confirm New Password"
        type="password"
        value={passwordConfirm}
      />
      <div className={`${props.className} mt-4`}>
        <Button
          id="update-password"
          loading={props.loading}
          mainStyle={ButtonMainStyle.PrimaryRectangular}
          onClick={() => props.onUpdate(password)}
          disabled={passwordMismatch || !isPasswordValid || props.loading}
        >
          Update password
        </Button>
      </div>

      {passwordMismatch && isPasswordValid && (
        <div className="mt-4">
          <Alert alertType={Alerts.WARNING} id="password-mismatch">
            {PASSWORDS_MISMATCH}
          </Alert>
        </div>
      )}
    </>
  );
};

export default styled(UpdatePassword)`
  #update-password {
    width: 200px;
  }
`;
