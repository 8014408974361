import React, { FunctionComponent } from 'react';
import cx from 'classnames';

interface IColumn {
  className?: string;
  classNames?: string[];
  defaultWidth?: number;
  smWidth?: number;
  mdWidth?: number;
  lgWidth?: number;
  xlWidth?: number;
  offsetDefaultWidth?: number;
  offsetSmWidth?: number;
  offsetMdWidth?: number;
  offsetLgWidth?: number;
  offsetXlWidth?: number;
}

const Column: FunctionComponent<React.PropsWithChildren<IColumn>> = ({
  className,
  classNames,
  children,
  defaultWidth = 12,
  smWidth,
  mdWidth,
  lgWidth,
  xlWidth,
  offsetDefaultWidth,
  offsetSmWidth,
  offsetMdWidth,
  offsetLgWidth,
  offsetXlWidth,
}) => {
  const columnSizes = [
    {
      name: 'sm',
      value: smWidth,
    },
    {
      name: 'md',
      value: mdWidth,
    },
    {
      name: 'lg',
      value: lgWidth,
    },
    {
      name: 'xl',
      value: xlWidth,
    },
  ];

  const offsetSizes = [
    {
      name: 'sm',
      value: offsetSmWidth,
    },
    {
      name: 'md',
      value: offsetMdWidth,
    },
    {
      name: 'lg',
      value: offsetLgWidth,
    },
    {
      name: 'xl',
      value: offsetXlWidth,
    },
  ];

  const setColumnSizesAndWidths = (): string => {
    let columnSizeAndWidth = `col-${defaultWidth} `;

    columnSizes.forEach(function (size) {
      if (size.value) {
        columnSizeAndWidth += `col-${size.name}-${size.value} `;
      }
    });
    return columnSizeAndWidth.trim();
  };

  const setOffsetSizesAndWidths = (): string => {
    let offsetSizeAndWidth = '';

    if (offsetDefaultWidth) {
      offsetSizeAndWidth += `offset-${offsetDefaultWidth} `;
    }

    offsetSizes.forEach(function (size) {
      if (Number.isInteger(size.value)) {
        offsetSizeAndWidth += `offset-${size.name}-${size.value} `;
      }
    });
    return offsetSizeAndWidth.trim();
  };

  const formatColumnAndOffset = (): string => {
    return `${setColumnSizesAndWidths()} ${setOffsetSizesAndWidths()}`.trim();
  };

  return <div className={cx(formatColumnAndOffset(), className, classNames)}>{children}</div>;
};

export default Column;
