import React, { FunctionComponent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../atoms/Icon';
import Column from '../../atoms/Column';
import { userList } from 'Admin/routes';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { IAppState } from 'reducers';
import { connect, useSelector } from 'react-redux';
import { BackendRoleName } from 'User/types/role';
import { hasCustAdminRole, hasSSEAdminRole, hasSSEPricingRole } from 'User/selectors';
import { useMatch, useNavigate } from 'react-router-dom';

export enum EditableCardState {
  Closed,
  Open,
}

interface IEditableCard {
  closedCardContent: ReactNode;
  openCardContent: ReactNode;
  onChange?: (state: EditableCardState) => void;
  className?: string;
  roles?: BackendRoleName[];
}

const EditableCard: FunctionComponent<React.PropsWithChildren<IEditableCard>> = ({
  className,
  closedCardContent,
  openCardContent,
  onChange,
}) => {
  const [cardState, setCardState] = useState<EditableCardState>(EditableCardState.Closed);

  const hasCustAdmin = useSelector(hasCustAdminRole);
  const hasSSEAdmin = useSelector(hasSSEAdminRole);
  const hasSSEPricing = useSelector(hasSSEPricingRole);
  const isOnProfilePage = !!useMatch('/user/profile');
  const navigate = useNavigate();

  const toggleCardState = () => {
    const newState = cardState === EditableCardState.Closed ? EditableCardState.Open : EditableCardState.Closed;
    setCardState(newState);
    if (onChange) {
      onChange(newState);
    }
  };

  const toggleBackBtnClass = () => {
    if (hasCustAdmin || hasSSEAdmin || hasSSEPricing) {
      if (!isOnProfilePage) {
        return 'back-btn';
      } else {
        return 'back-btn hide';
      }
    } else {
      return 'back-btn hide';
    }
  };

  return (
    <div className={className}>
      <div className="row no-gutters edit-button-wrapper">
        <Column offsetDefaultWidth={8} defaultWidth={3}>
          <Button mainStyle={ButtonMainStyle.Link} onClick={() => navigate(userList)} className={toggleBackBtnClass()}>
            Back to user management
          </Button>
        </Column>
        <Column defaultWidth={1}>
          {cardState === EditableCardState.Closed && (
            <button onClick={toggleCardState}>
              <span className="edit-text edit-btn">Edit</span>
              <Icon className="primary align-middle" name="edit" size="normal" />
            </button>
          )}

          {cardState === EditableCardState.Open && (
            <button onClick={toggleCardState}>
              <span className="edit-text close-btn">Close</span>
              <Icon className="primary align-middle" name="close" size="normal" />
            </button>
          )}
        </Column>
      </div>

      {cardState === EditableCardState.Closed && <div className="close-card-content">{closedCardContent}</div>}

      {cardState === EditableCardState.Open && <div className="open-card-content">{openCardContent}</div>}
    </div>
  );
};

const StyledEditableCard = styled(EditableCard)`
  .edit-button-wrapper {
    text-align: right;
  }

  .edit-text {
    color: ${(props) => props.theme.colours.primaryB1};
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    margin-right: 8px;
  }

  .back-btn {
    color: ${(props) => props.theme.colours.grey60};
    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }
  }
`;

const mapStateToProps = ({ user: { allRoles } }: IAppState) => ({
  allRoles,
});

export default connect(mapStateToProps)(StyledEditableCard);
