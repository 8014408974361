import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import Icon from '../../shared/components/atoms/Icon';

interface IFooterProps {
  addressNotListed: boolean;
  className?: string;
  placeholder?: string;
  theme: {
    colours: {
      grey20: string;
      grey60: string;
    };
  };
  onAddressNotListed(): void;
}

const AddressNotListed: FunctionComponent<React.PropsWithChildren<IFooterProps>> = ({
  addressNotListed,
  className,
  onAddressNotListed,
  placeholder,
}) => {
  return (
    <div className={`${className} row no-gutters`}>
      <button onClick={onAddressNotListed} className="not-listed">
        {addressNotListed ? (
          <Icon name="check_box" className="primary icon" size="xLarge" />
        ) : (
          <Icon name="check_box_outline_blank" className="grey20 icon" size="xLarge" />
        )}
        <span>{placeholder || "My address isn't listed."}</span>
      </button>
    </div>
  );
};

const StyledAddressNotListed = styled(AddressNotListed)`
  margin-top: 1em;
  padding-top: 1em;
  border-top: solid 1px ${(props) => props.theme.colours.grey20};
  color: ${(props) => props.theme.colours.grey60};

  .not-listed {
    display: inline-flex;
  }
  .icon {
    margin-bottom: 0.5em;
    margin-left: 0.2em;
    margin-right: 1em;
    text-align: center;
  }
`;

export default StyledAddressNotListed;
