import { IPriceData, IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ISecondarySiteConfig, ISiteConfig } from 'Order/types/location';
import CardRow from 'shared/components/molecules/CardRow';
import Column from 'shared/components/atoms/Column';
import SiteConfigInformation from 'Order/OrderBuilder/Locations/shared/components/Edit/SiteConfigInformation/SiteConfig';
import React from 'react';
import { siteConfigProps } from 'Order/OrderBuilder/Locations/shared/components/Edit/index';

export function DualCircuitSiteConfig(props: {
  quote: IQuote;
  endPort: BearerType;
  siteConfig: ISiteConfig;
  secondarySiteConfig: ISecondarySiteConfig;
  identifierPrefix: string;
  fttxQuote: boolean;
  supplierNNAT: boolean;
  updateSiteConfig: (siteConfig: any) => void;
  selectedPrice: IPriceData;
  updateSecondarySiteConfig: (secondarySiteConfig: ISecondarySiteConfig) => void;
}) {
  return (
    <>
      <CardRow title="Site Config" subtitle="Primary Circuit" {...siteConfigProps(props.quote.productType)}>
        <div className="row no-gutters mb-4">
          <Column smWidth={12} mdWidth={12} lgWidth={12} xlWidth={10}>
            <h4>Primary Circuit Site Configuration</h4>
            <SiteConfigInformation
              quote={props.quote}
              endPort={props.endPort}
              siteConfig={props.siteConfig}
              identifierPrefix={props.identifierPrefix}
              isFTTXQuote={props.fttxQuote}
              isSupplierNNAT={props.supplierNNAT}
              updateSiteConfig={props.updateSiteConfig}
              selectedPrice={props.selectedPrice}
            />
          </Column>
        </div>
      </CardRow>
      {props.secondarySiteConfig && (
        <CardRow title="Site Config" subtitle="Secondary Circuit" {...siteConfigProps(props.quote.productType)}>
          <div className="row no-gutters mb-4">
            <Column smWidth={12} mdWidth={12} lgWidth={12} xlWidth={10}>
              <h4>Secondary Circuit Site Configuration</h4>
              <SiteConfigInformation
                quote={props.quote}
                endPort={props.endPort}
                siteConfig={props.secondarySiteConfig}
                identifierPrefix={props.identifierPrefix}
                isFTTXQuote={props.fttxQuote}
                isSupplierNNAT={props.supplierNNAT}
                updateSiteConfig={props.updateSecondarySiteConfig}
                selectedPrice={props.selectedPrice}
              />
            </Column>
          </div>
        </CardRow>
      )}
    </>
  );
}
