import { doRequest, Methods } from '../../../Request';

const getUser = (userId: string) => {
  return doRequest({
    method: Methods.GET,
    path: `/users/${userId}`,
  });
};

export default getUser;
