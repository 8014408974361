export const UpdateHeaderCellWidths = (headers: any, tableName: string) => {
  const newHeaderCols: any[] = [];
  headers.forEach((header: any, i: number) => {
    const getItem = localStorage.getItem(`${tableName}-width-${header.id}`);

    newHeaderCols[i] = header;
    if (getItem !== null) {
      newHeaderCols[i].width = getItem;
    }
  });

  return headers;
};

export default UpdateHeaderCellWidths;
