import { CerillionQuoteRecord } from 'Quotes/types/cerillionQuoteRecord';
import { useEffect, useState } from 'react';
import { doRequest } from 'Request';

export const useCerillionQuote = (id: string) => {
  const [fetching, setFetching] = useState(true);
  const [fetchingError, setFetchingError] = useState(false);
  const [quote, setQuote] = useState<CerillionQuoteRecord | null>(null);

  useEffect(() => {
    setFetchingError(false);
    setFetching(true);

    (async function () {
      try {
        const data: CerillionQuoteRecord = await doRequest({
          path: `/cerillion_baskets/${id}`,
        });

        setQuote(data);
      } catch (e) {
        setFetchingError(true);
      } finally {
        setFetching(false);
      }
    })();
  }, [id]);

  return {
    fetching,
    fetchingError,
    quote,
  };
};
