import { FunctionComponent } from 'react';

interface IQuoteAccordionContentProps {
  activeIndex: number;
  children: any;
  index: number;
}

const QuoteAccordionContent: FunctionComponent<React.PropsWithChildren<IQuoteAccordionContentProps>> = ({
  activeIndex,
  children,
  index,
}) => {
  if (activeIndex !== index) {
    return null;
  }
  return children;
};

export default QuoteAccordionContent;
