import React, { FunctionComponent } from 'react';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import { UserReportStatus } from 'User/types/user';

interface IUserStatusFilter {
  className?: string;
  setUserStatus(userStatus: string | null): void;
}

const UserStatusFilter: FunctionComponent<React.PropsWithChildren<IUserStatusFilter>> = ({ setUserStatus }) => {
  const getUserStatusOptions = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [];

    Object.values(UserReportStatus).forEach((status) => {
      options.push({
        label: status,
        value: status,
      });
    });

    return options;
  };

  return (
    <div className="control">
      <label>Status</label>
      <Dropdown
        options={getUserStatusOptions()}
        onChange={(option: IDropdownOption) => setUserStatus(option ? option.value : null)}
        className="select-input userstatus-dropdown"
      />
    </div>
  );
};

export default UserStatusFilter;
