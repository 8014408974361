import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';
import { IAppState } from 'reducers';
import RadioButton from 'shared/components/molecules/RadioButton';
import { setBearerAction, setQuoteConnectionType } from 'Quotes/actions';
import { BearerType, bearerTypes, bearerTypesCloudConnect, bearerTypesOptical } from './BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import AvailabilityCheck from 'Quotes/AvailabilityCheck';
import { AvailabilityResultMessage, NoAvailabilityMessage } from '../AvailabilityMessage';
import { isFTTXConnection, OverallConnectionType } from 'Quotes/types/connectionType';
import { productTypeHasFTTX } from 'Quotes/utils/productTypeHasFTTX';
import { selectLocationsContainOnNetAddress, selectNNICapacity } from 'Quotes/selectors';
import Column from 'shared/components/atoms/Column';
import { INNI } from 'Quotes/types/store';
import { BearerRadioGroup } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerRadioGroup';
import { MaximumBandwidthAlert, NoBearerWarning } from 'Quotes/QuoteBuilder/components/Configure/Bearer/Alerts';
import { OptionGroup } from 'shared/components/organisms/OptionGroup/OptionGroup';
import { bearerForDisplay } from 'shared/utils/connectionCapacity';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import { Link } from 'react-router-dom';
import { newQuoteByLocation } from 'Quotes/Routes';
import { BearerHeader } from 'Quotes/QuoteBuilderByLocation/components/Bearer/Bearer';

export interface IBearer {
  className?: string;
  bearer: BearerType | undefined;
  connectionType: OverallConnectionType;
  setBearer(bearer?: BearerType): void;
  setConnectionType(connectionType: OverallConnectionType): void;
  availability: OverallConnectionType | undefined;
  availabilityLoading: boolean;
  availabilityError: boolean;
  productType: ProductType;
  requiresAvailabilityCheck: boolean;
  hasOnNetLocation: boolean;
  nniCapacity: INNI['capacity'];
}

function EthernetOverFTTX(props: {
  availability: OverallConnectionType | undefined;
  onClick: () => void;
  connectionType: OverallConnectionType;
}) {
  return (
    <>
      <h6>Ethernet over FTTC/P</h6>
      <RadioButton
        inline
        size="medium"
        description={props.availability!}
        id={`bearer--${props.availability}`}
        onClick={props.onClick}
        status={props.connectionType === 'Ethernet' ? 'notSelected' : 'selected'}
      />
    </>
  );
}

const getBearersForProductType = (productType: ProductType, nniCapacity: INNI['capacity']) => {
  switch (productType) {
    case ProductType.OpticalP2P:
      return bearerTypesOptical;
    case ProductType.P2CCT:
      return bearerTypesCloudConnect;

    case ProductType.P2NNI: {
      return bearerTypes.filter((bearerType) => nniCapacity.ports.includes(bearerType));
    }

    case ProductType.P2P:
    case ProductType.NNI2CCT:
    case ProductType.DIA:
      return bearerTypes;
    default:
      return bearerTypes;
  }
};

export const Bearer: FunctionComponent<IBearer> = ({
  className,
  bearer,
  setBearer,
  connectionType,
  availability,
  setConnectionType,
  availabilityError,
  availabilityLoading,
  productType,
  requiresAvailabilityCheck,
  hasOnNetLocation,
  nniCapacity,
}) => {
  const bearers = getBearersForProductType(productType, nniCapacity);
  useEffect(() => {
    const shouldRemoveSelectedBearer = productType === ProductType.P2NNI && bearer && !bearers.includes(bearer);
    if (productType === ProductType.OpticalP2P && !bearer) {
      setBearer(bearers[0]);
    } else if (shouldRemoveSelectedBearer) {
      setBearer(undefined);
    }
  }, [productType, bearer, bearers, setBearer]);

  const isFTTXProduct = productTypeHasFTTX(productType);

  if (requiresAvailabilityCheck) {
    return (
      <div className={`${className} mb-5`}>
        <div className="bearer__pickBearerPrompt">
          <h5>Access method</h5>
          {isFTTXProduct && !hasOnNetLocation && (
            <AvailabilityResultMessage
              error={availabilityError}
              isLoading={availabilityLoading}
              availability={availability}
              journey={'by_product_type'}
            />
          )}
        </div>
        {isFTTXProduct && !availability ? (
          <AvailabilityCheck />
        ) : (
          <div className="bearer__radiogroup">
            <div className="row no-gutters">
              {bearers.length === 0 && <NoBearerWarning />}
              {availability && isFTTXConnection(availability) && (
                <Column defaultWidth={12} lgWidth={3}>
                  <EthernetOverFTTX
                    availability={availability}
                    onClick={() => setConnectionType(availability!)}
                    connectionType={connectionType}
                  />
                </Column>
              )}
              <Column
                defaultWidth={12}
                lgWidth={availability && isFTTXConnection(availability) ? 9 : 12}
                classNames={['ethernet-fibre-btn-wrapper']}
              >
                <h6>Ethernet fibre / Optical wavelengths</h6>

                {productType === ProductType.P2NNI && <MaximumBandwidthAlert bandwidth={nniCapacity.bandwidth} />}

                <BearerRadioGroup
                  selectedBearer={bearer}
                  bearers={bearers.length > 0 ? bearers : bearerTypes}
                  setBearer={setBearer}
                />
              </Column>
            </div>
          </div>
        )}
      </div>
    );
  }

  const is100G400GDisabled =
    productType === ProductType.OpticalP2P &&
    featureFlag.isEnabled(Feature.Optical100G400G) &&
    !featureFlag.isEnabled(Feature.Optical100G400GInProductJourney);

  return (
    <div className={`${className} mb-5`}>
      <div className="bearer__pickBearerPrompt pb-3">
        <BearerHeader />
        {isFTTXProduct && !hasOnNetLocation && (
          <NoAvailabilityMessage error={availabilityError} isLoading={availabilityLoading} />
        )}
        {is100G400GDisabled && (
          <Alert alertType={Alerts.INFO}>
            100Gbps & 400Gbps are currently only available on <Link to={newQuoteByLocation}>Quote by Location</Link>,
            subject to availability
          </Alert>
        )}
      </div>
      <div className="row no-gutters">
        {bearers.length === 0 && <NoBearerWarning />}
        <Column defaultWidth={12} lgWidth={12} classNames={['ethernet-fibre-btn-wrapper']}>
          <h6>Ethernet fibre / Optical wavelengths</h6>

          {productType === ProductType.P2NNI && <MaximumBandwidthAlert bandwidth={nniCapacity.bandwidth} />}

          {is100G400GDisabled ? (
            <OpticalP2PBearerGroup selectedBearer={bearer} allBearers={bearers} setBearer={setBearer} />
          ) : (
            <BearerRadioGroup
              selectedBearer={bearer}
              bearers={bearers.length > 0 ? bearers : bearerTypes}
              setBearer={setBearer}
            />
          )}
        </Column>
      </div>
    </div>
  );
};

const OpticalP2PBearerGroup = ({
  selectedBearer,
  allBearers,
  setBearer,
}: {
  selectedBearer: BearerType | undefined;
  allBearers: BearerType[];
  setBearer: (bearer: BearerType) => void;
}) => {
  const availableBearers = allBearers.filter((it) => it !== BearerType.LARGER && it !== BearerType.LARGEST);
  return (
    <div data-testid="optical-bearer">
      <OptionGroup<BearerType>
        options={getBearerOptions(allBearers, availableBearers)}
        value={selectedBearer}
        onClick={setBearer}
      />
    </div>
  );
};

function getBearerOptions(allBearers: BearerType[], availableBearers: BearerType[]) {
  return allBearers.map((bearerValue) => ({
    label: bearerForDisplay(bearerValue),
    value: bearerValue,
    disabled: !availableBearers.includes(bearerValue),
    customId: `bearer--${bearerValue}`,
    tooltip: !availableBearers.includes(bearerValue)
      ? `${bearerForDisplay(
          bearerValue
        )} is currently only available at selected locations when creating a Quote by Location`
      : undefined,
  }));
}

const styledBearer = styled(Bearer)`
  .bearer__pickBearerPrompt {
    text-align: left;
  }

  .bearer__radiogroup {
    h6 {
      margin-top: 3em;
      margin-bottom: 1.5em;
    }

    .alert-warning {
      margin-top: 1em;
      margin-bottom: 0;
    }
  }

  .ethernet-fibre-btn-wrapper {
    > div {
      margin-bottom: 15px;
    }
  }
`;

const mapStateToProps = (state: IAppState) => ({
  bearer: state.quoteBuilder.quote.bearer,
  productType: state.quoteBuilder.quote.productType,
  availability: state.quoteBuilder.quote.availability?.overall,
  connectionType: state.quoteBuilder.quote.connectionType,
  availabilityLoading: state.quoteBuilder.checkingAvailability.inProgress,
  availabilityError: state.quoteBuilder.checkingAvailability.error,
  requiresAvailabilityCheck: state.quoteBuilder.quote.requiresAvailabilityCheck,
  hasOnNetLocation: selectLocationsContainOnNetAddress(state),
  nniCapacity: selectNNICapacity(state),
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => {
  return {
    setBearer: (bearer?: BearerType) => dispatch(setBearerAction(bearer)),
    setConnectionType: (connectionType: OverallConnectionType) => dispatch(setQuoteConnectionType(connectionType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(styledBearer);
