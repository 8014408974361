import React, { forwardRef, FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { formatDateRangePickerDateForDisplay } from 'shared/utils/dateHelper';

interface IDateFilter {
  className?: string;
  startDate: Date | null;
  endDate: Date | null;
  theme: {
    colours: {
      inputBorder: string;
      inputText: string;
    };
  };
  setStartDate(date: Date | null): void;
  setEndDate(date: Date | null): void;
}

type CustomDatePickerInputProps = {
  onClick?(): void;
};

const DateRangeFilter: FunctionComponent<React.PropsWithChildren<IDateFilter>> = ({
  className,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const CustomInput = forwardRef<HTMLInputElement, CustomDatePickerInputProps>(({ onClick }, ref) => {
    return (
      <input
        className="select-input"
        placeholder="Select..."
        onClick={onClick}
        ref={ref}
        value={startDate ? formatDateRangePickerDateForDisplay([startDate, endDate || startDate]) : ''}
        readOnly
      />
    );
  });

  const onClose = () => {
    if (!startDate) {
      return;
    }

    if (!endDate) {
      setEndDate(startDate);
    }
  };

  const onChange = (date: Date | [Date, Date] | null) => {
    if (!date) {
      setStartDate(null);
      setEndDate(null);
    }

    if (Array.isArray(date)) {
      const [start, end] = date;
      setStartDate(start);
      setEndDate(end);
    }
  };

  return (
    <div className={`${className} control`}>
      <label>Date range</label>
      <DatePicker
        popperPlacement="bottom"
        isClearable
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        customInput={<CustomInput />}
        shouldCloseOnSelect={!!startDate && !endDate}
        onCalendarClose={onClose}
        onChange={onChange}
      />
    </div>
  );
};

const StyledDateRangeFilter = styled(DateRangeFilter)`
  .react-datepicker-wrapper {
    display: block;

    .select-input {
      display: block;
      border-color: ${(props) => props.theme.colours.inputBorder};
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      height: 44px;
      color: ${(props) => props.theme.colours.inputText};
      padding: 0.3em 0.5em;
    }
  }
`;

export default StyledDateRangeFilter;
