import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IEmptyOrdersList {
  className?: string;
}

const EmptyAlertsList: FunctionComponent<React.PropsWithChildren<IEmptyOrdersList>> = ({ className }) => (
  <p className={`${className} no-alerts-info`}>It looks like you don&apos;t have any alerts yet.</p>
);

const StyledEmptyAlertsList = styled(EmptyAlertsList)<IEmptyOrdersList>`
  color: ${(props) => props.theme.colours.secondary};
  font-family: ${(props) => props.theme.typography.fontFamilyBold};
  font-size: ${(props) => props.theme.typography.fontSizeH4};
`;

export default StyledEmptyAlertsList;
