import { doRequest, Methods } from 'Request';

const getCustomer = (companyId: string) => {
  return doRequest({
    method: Methods.GET,
    path: `/customers/${companyId}`,
  });
};

export default getCustomer;
