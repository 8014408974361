import React, { forwardRef, FunctionComponent, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import DatePicker from 'react-datepicker';
import { utcToZonedTime } from 'date-fns-tz';
import { useDispatch } from 'react-redux';
import TextInput from '../../atoms/TextInput';
import Select from '../../atoms/Select';
import { emptyFilterString } from '../../../utils/equalsOrEmptyFilter';
import {
  formatDatePickerDate,
  formatDateRangePickerDate,
  formatDateRangePickerDateForDisplay,
} from '../../../utils/dateHelper';
import { setTableFilter } from '../../../../Tables/actions';
import TableNames from '../../../../Tables/types/tableNames';
import { IStoreKeyNameFilters } from '../../../../Tables/types/store';
import dayjs from 'dayjs';

type Option = {
  label: string;
  value: string;
};

export interface IFilterComponent {
  column: ColumnProps;
}

type ColumnProps = {
  filterValue: string;
  setFilter(value: string | undefined): void;
  preFilteredRows: any[];
  id: string;
  options?: Option[];
  tableName?: TableNames;
  storeKeyName?: IStoreKeyNameFilters;
};

type CustomDatePickerInputProps = {
  onClick?(): void;
};

export const PresetSelectedColumnFilter: FunctionComponent<React.PropsWithChildren<IFilterComponent>> = (props: {
  column: ColumnProps;
}) => {
  const { filterValue, setFilter, options, tableName, storeKeyName } = props.column;

  const dispatch = useDispatch();

  useEffect(() => {
    if (filterValue) {
      setFilter(filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        if (tableName && storeKeyName) {
          dispatch(setTableFilter(tableName, storeKeyName, e.target.value?.trim()));
        }
      }}
    >
      <option key={0} value="">
        All
      </option>
      {(options || []).map((option: Option, i: number) => (
        <option key={i + 1} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export const SelectColumnFilter: FunctionComponent<React.PropsWithChildren<IFilterComponent>> = (props: {
  column: ColumnProps;
}) => {
  const { filterValue, setFilter, preFilteredRows, id, tableName, storeKeyName } = props.column;

  const dispatch = useDispatch();

  useEffect(() => {
    if (filterValue) {
      setFilter(filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = React.useMemo(() => {
    const optionsSet = preFilteredRows.map((row: any) => row.values[id]);
    return [...new Set(optionsSet)];
  }, [id, preFilteredRows]);

  return (
    <Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        if (tableName && storeKeyName) {
          dispatch(setTableFilter(tableName, storeKeyName, e.target.value || undefined));
        }
      }}
    >
      <option value="">All</option>
      {options.map((option: any, i: number) => (
        <option key={i} value={option || emptyFilterString}>
          {option || '(No Value)'}
        </option>
      ))}
    </Select>
  );
};

export function SelectSupplierColumnFilter(props: { column: ColumnProps }) {
  const { filterValue, setFilter, preFilteredRows, id } = props.column;

  const options = React.useMemo(() => {
    const bEndOptionsSet = new Set();

    const aEndOptionsSet = preFilteredRows.map((row: any) => {
      const value = row.values[id];
      if (value) {
        if (value.includes('/')) {
          bEndOptionsSet.add(value.split('/').pop().trim());
          return value.substring(0, value.indexOf('/')).trim();
        }
        return value.trim();
      }
      return '';
    });

    const mergedSet = new Set(
      (function* () {
        yield* aEndOptionsSet;
        yield* bEndOptionsSet;
      })()
    );

    return [...mergedSet];
  }, [id, preFilteredRows]);
  return (
    <Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.sort().map((option: any, i: number) => (
        <option key={i} value={option || emptyFilterString}>
          {option || '(No Value)'}
        </option>
      ))}
    </Select>
  );
}

const convertUtcToZonedTime = (filterValue: string) => {
  const utcString = dayjs.tz(filterValue, 'YYYY-MM-DD', 'Europe/London').toISOString();

  return utcToZonedTime(new Date(utcString), 'Europe/London');
};

const onCalendarOpenOrClose = (onOpen: boolean) => {
  const wrapper = document.querySelector('.table-wrapper');

  if (!wrapper) {
    return;
  }

  if (onOpen) {
    wrapper.classList.add('date-picker-open');
  } else {
    wrapper.classList.remove('date-picker-open');
  }
};

export const DateFilter: FunctionComponent<React.PropsWithChildren<IFilterComponent>> = (props: {
  column: ColumnProps;
}) => {
  const dispatch = useDispatch();

  const { filterValue, setFilter, tableName, storeKeyName } = props.column;
  let localisedDate;

  useEffect(() => {
    if (filterValue) {
      setFilter(filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (filterValue) {
    localisedDate = convertUtcToZonedTime(filterValue);
  }

  return (
    <div className="date-filter">
      <DatePicker
        popperPlacement="bottom"
        dateFormat="dd-MM-yyyy"
        isClearable
        selected={localisedDate ? localisedDate : null}
        onCalendarClose={() => onCalendarOpenOrClose(false)}
        onCalendarOpen={() => onCalendarOpenOrClose(true)}
        onChange={(date) => {
          if (!Array.isArray(date)) {
            setFilter(date ? formatDatePickerDate(date) : '');

            if (tableName && storeKeyName) {
              dispatch(setTableFilter(tableName, storeKeyName, date ? formatDatePickerDate(date) : ''));
            }
          }
        }}
      />
    </div>
  );
};

export const DateRangeFilter: FunctionComponent<React.PropsWithChildren<IFilterComponent>> = ({
  column,
}: {
  column: ColumnProps;
}) => {
  const dispatch = useDispatch();

  const { filterValue, setFilter, tableName, storeKeyName } = column;
  let localisedStartDate = null;
  let localisedEndDate = null;

  if (filterValue) {
    const parts = filterValue.split(' - ');
    localisedStartDate = convertUtcToZonedTime(parts[0]);
    localisedEndDate = convertUtcToZonedTime(parts[1]);
  }

  const [startDate, setStartDate] = useState<Date | null>(localisedStartDate);
  const [endDate, setEndDate] = useState<Date | null>(localisedEndDate);

  useEffect(() => {
    if (filterValue) {
      setFilter(filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTableFilter = (value: string) => {
    if (tableName && storeKeyName) {
      dispatch(setTableFilter(tableName, storeKeyName, value));
    }
  };

  const CustomInput = forwardRef<HTMLInputElement, CustomDatePickerInputProps>(({ onClick }, ref) => {
    return (
      <input
        onClick={onClick}
        ref={ref}
        value={startDate ? formatDateRangePickerDateForDisplay([startDate, endDate || startDate]) : ''}
        readOnly
      />
    );
  });

  const onClose = () => {
    onCalendarOpenOrClose(false);

    if (!startDate) {
      return;
    }

    if (!endDate) {
      setEndDate(startDate);
    }

    setFilter(formatDateRangePickerDate([startDate, endDate || startDate]));
    updateTableFilter(formatDateRangePickerDate([startDate, endDate || startDate]));
  };

  const onChange = (date: Date | [Date, Date] | null) => {
    if (!date) {
      setStartDate(null);
      setEndDate(null);
      setFilter('');
      updateTableFilter('');
    }

    if (Array.isArray(date)) {
      const [start, end] = date;
      if (start === null && end === null) {
        setFilter('');
        updateTableFilter('');
      }
      setStartDate(start);
      setEndDate(end);
    }
  };

  return (
    <div className="date-filter">
      <DatePicker
        popperPlacement="bottom"
        isClearable
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        customInput={<CustomInput />}
        shouldCloseOnSelect={!!startDate && !endDate}
        onCalendarClose={onClose}
        onCalendarOpen={() => onCalendarOpenOrClose(true)}
        onChange={onChange}
      />
    </div>
  );
};

export const DefaultColumnFilter: FunctionComponent<React.PropsWithChildren<IFilterComponent>> = (props: {
  column: ColumnProps;
}) => {
  const dispatch = useDispatch();

  const { filterValue, setFilter, id, tableName, storeKeyName } = props.column;

  React.useEffect(() => {
    if (filterValue && filterValue !== '') {
      const trimTimer = setTimeout(() => {
        setFilter(filterValue.trim());
      }, 1000);
      return () => clearTimeout(trimTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  return (
    <TextInput
      classNames={['multi-select-table__filter']}
      fieldName={id}
      inputIdentifierPrefix={`column-filter__`}
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        if (tableName && storeKeyName) {
          dispatch(setTableFilter(tableName, storeKeyName, e.target.value || ''));
        }
      }} // Set undefined to remove the filter entirely
    />
  );
};

const debouncedSetFilter = debounce((func: (value: string | undefined) => void, value: string | undefined) => {
  func(value);
}, 200);

export const ThrottledFilter: FunctionComponent<React.PropsWithChildren<IFilterComponent>> = (props: {
  column: ColumnProps;
}) => {
  const dispatch = useDispatch();

  const { filterValue, setFilter, id, tableName, storeKeyName } = props.column;

  const [localFilterValue, setLocalFilterValue] = useState<string | undefined>(filterValue || undefined);

  useEffect(() => {
    if (filterValue) {
      setFilter(filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || undefined;
    setLocalFilterValue(value);
    debouncedSetFilter(setFilter, value?.trim());
    if (tableName && storeKeyName) {
      dispatch(setTableFilter(tableName, storeKeyName, value?.trim()));
    }
  };

  return (
    <TextInput
      classNames={['multi-select-table__filter']}
      fieldName={id}
      inputIdentifierPrefix={`column-filter__`}
      value={localFilterValue || ''}
      onChange={onChange} // Set undefined to remove the filter entirely
    />
  );
};
