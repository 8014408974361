import cx from 'classnames';
import React, { FC, ReactNode, useState } from 'react';
import Column from 'shared/components/atoms/Column';
import Icon from 'shared/components/atoms/Icon';
import styled from 'styled-components';

interface ICollapsableCardProps {
  className?: string;
  children?: ReactNode;
  header: ReactNode;
  subHeader?: ReactNode;
  collapseButtonLabel?: string;
  showDivider?: boolean;
  initialState?: boolean;
}

const UnstyledCollapsableCard: FC<React.PropsWithChildren<ICollapsableCardProps>> = ({
  className,
  children,
  header,
  collapseButtonLabel,
  showDivider,
  subHeader,
  initialState = false,
}) => {
  const [open, setOpen] = useState(initialState);

  return (
    <div className={className}>
      <div className="row no-gutters header">
        <Column defaultWidth={9}>{header}</Column>

        {children && (
          <Column defaultWidth={3} classNames={['text-right pr-2']}>
            <span className="sub-heading dropdown-click-area" onClick={() => setOpen(!open)}>
              {collapseButtonLabel}
              <Icon className={cx('dropdown-arrow', { open })} name="keyboard_arrow_down" />
            </span>
          </Column>
        )}
      </div>

      <div className="row no-gutters">
        <Column defaultWidth={12}>{subHeader}</Column>
      </div>

      {open && (
        <>
          {showDivider && (
            <div className="divider">
              <hr />
            </div>
          )}
          <div className={'row no-gutters mb-2 content'}>
            <Column defaultWidth={12}>{children}</Column>
          </div>
        </>
      )}
    </div>
  );
};

export const CollapsableCard = styled(UnstyledCollapsableCard)`
  background: white;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  border: solid 0.5px ${(props) => props.theme.colours.grey10};

  .header {
    padding: 0.5em 1em;
  }

  .content {
    padding: 1em;
  }

  .divider {
    padding: 0 1em;

    hr {
      margin: 0 !important;
      padding: 0 1rem;
    }
  }

  .sub-heading {
    color: ${(props) => props.theme.colours.primaryB1};
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  .dropdown-arrow {
    vertical-align: middle;
    transition: transform 200ms ease-in-out;

    &.open {
      transform: rotate(180deg);
    }
  }

  .dropdown-click-area {
    cursor: pointer;
  }

  hr {
    margin-top: 0.5em;
  }
`;
