import { IPriceData, IQuotesState, ProductSubType, ProviderName } from 'Quotes/types/store';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { ProductType } from 'Quotes/types/productTypes';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { RowCell } from 'shared/components/organisms/MultiSelectTable';
import { List } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/List';
import React from 'react';
import {
  DataRowDIA,
  DataRowNNI2CCT,
  DataRowOpticalP2P,
  DataRowP2CCT,
  DataRowP2NNI,
  DataRowP2P,
} from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/List/types';
import { IAppState } from 'reducers';
import { useSelector } from 'react-redux';
import { selectNNILabelFromQuote } from 'Quotes/selectors';
import { selectHasCostsPermissions } from 'User/selectors';
import { ContractTerm } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import { createSelector } from '@reduxjs/toolkit';
import {
  IDummyPrice,
  isDummyPrice,
} from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import { useSecondaryCircuits } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';

interface SharedRowData {
  termLengthInMonths: ContractTerm | undefined;
  product_sub_type: ProductSubType | null;
  supportsSecondaryCircuits: boolean;
}

function convertCommonPriceData(priceData: IPriceData): SharedRowData {
  const secondaryCircuits = useSecondaryCircuits(priceData);
  const supportsSecondaryCircuits = secondaryCircuits.isSupported();
  return {
    termLengthInMonths: priceData.term_length_in_months ? priceData.term_length_in_months : undefined,
    product_sub_type: priceData.product_sub_type,
    supportsSecondaryCircuits: supportsSecondaryCircuits,
  };
}

function convertP2PPriceData(
  priceData: IPriceData,
  aEndBandwidth: string | null,
  bandwidth: string,
  aEndPostcode: string,
  bEndPostcode: string,
  isPriceSelected: (priceId: string) => boolean,
  hasCostsPermissions: boolean
): DataRowP2P & SharedRowData {
  return {
    priceId: priceData.id,
    bandwidth: priceData.bandwidth || aEndBandwidth || bandwidth, // Multi-bandwidth || FTTX || Ethernet
    aEndSupplier: priceData.a_end_access_type,
    aEndPostcode,
    bEndSupplier: priceData.b_end_access_type,
    bEndPostcode,
    aEndAccessMethod: priceData.product_sub_type === 'optical' ? 'Optical' : 'Ethernet',
    bEndAccessMethod: priceData.product_sub_type === 'optical' ? 'Optical' : 'Ethernet',
    amortisedAnnualPrice: priceData.amortised_annual_price,
    isPriceListRowSelected: isPriceSelected(priceData.id),
    tooltip: hasCostsPermissions
      ? `A-End cost: ${currencyFormatter.format(
          priceData.a_end_annual_cost ?? 0
        )} <br /> B-End cost: ${currencyFormatter.format(priceData.b_end_annual_cost ?? 0)}`
      : undefined,
    ...convertCommonPriceData(priceData),
  };
}

function convertP2NNIPriceData(
  priceData: IPriceData,
  bEndBandwidth: string | null,
  bandwidth: string,
  bEndPostcode: string,
  nniReference: string | undefined,
  dataCentreReferenceAEnd: string | undefined,
  quoteEndpointMeta: IMeta | undefined,
  shadowVlanLocation: string | undefined,
  nniPopName: string | undefined,
  nniLabel: string,
  aEndFullAddressLocation: string | null | undefined,
  isPriceSelected: (priceId: string) => boolean,
  hasCostsPermissions: boolean
): DataRowP2NNI & SharedRowData {
  return {
    priceId: priceData.id,
    bandwidth: priceData.bandwidth || bEndBandwidth || bandwidth, // Multi-bandwidth || FTTX || Ethernet
    amortisedAnnualPrice: priceData.amortised_annual_price,
    bEndAccessMethod: priceData.product_sub_type === 'optical' ? 'Optical' : 'Ethernet',
    bEndPostcode,
    bEndSupplier: priceData.b_end_access_type,
    nniReference: nniReference || dataCentreReferenceAEnd,
    nniShadowReference:
      quoteEndpointMeta?.a_end_nni_shadow_reference ||
      quoteEndpointMeta?.a_end_nni_shadow_data_centre_reference ||
      shadowVlanLocation,
    nniPopName,
    nniLabel,
    nniLocation: aEndFullAddressLocation,
    isPriceListRowSelected: isPriceSelected(priceData.id),
    tooltip: hasCostsPermissions
      ? `Cost: ${currencyFormatter.format(priceData.b_end_annual_cost ? priceData.b_end_annual_cost : 0)}`
      : undefined,
    ...convertCommonPriceData(priceData),
  };
}

function convertDIAPriceData(
  priceData: IPriceData,
  aEndBandwidth: string | null,
  bandwidth: string,
  aEndPostcode: string,
  isPriceSelected: (priceId: string) => boolean,
  hasCostsPermissions: boolean
): DataRowDIA & SharedRowData {
  return {
    priceId: priceData.id,
    bandwidth: priceData.bandwidth || aEndBandwidth || bandwidth, // Multi-bandwidth || FTTX || Ethernet
    aEndAccessMethod: priceData.product_sub_type === 'optical' ? 'Optical' : 'Ethernet',
    aEndPostcode,
    amortisedAnnualPrice: priceData.amortised_annual_price,
    aEndSupplier: priceData.a_end_access_type,
    isPriceListRowSelected: isPriceSelected(priceData.id),
    tooltip: hasCostsPermissions
      ? `Cost: ${currencyFormatter.format(
          priceData.a_end_annual_cost
            ? priceData.a_end_annual_cost + (priceData.annual_ip_charge ? priceData.annual_ip_charge : 0)
            : 0
        )}`
      : undefined,
    ...convertCommonPriceData(priceData),
  };
}

function convertNNI2CCTPriceData(
  priceData: IPriceData,
  bandwidth: string,
  nniReference: string | undefined,
  dataCentreReferenceAEnd: string | undefined,
  quoteEndpointMeta: IMeta | undefined,
  shadowVlanLocation: string | undefined,
  nniPopName: string | undefined,
  nniLabel: string,
  aEndFullAddressLocation: string | null | undefined,
  isPriceSelected: (priceId: string) => boolean,
  hasCostsPermissions: boolean
): DataRowNNI2CCT & SharedRowData {
  return {
    priceId: priceData.id,
    bandwidth: priceData.bandwidth || bandwidth,
    provider: priceData.cloud_connect?.provider || ProviderName.NOT_DEFINED,
    aEndSupplier: priceData.a_end_access_type,
    nniReference: nniReference || dataCentreReferenceAEnd,
    nniShadowReference:
      quoteEndpointMeta?.a_end_nni_shadow_reference ||
      quoteEndpointMeta?.a_end_nni_shadow_data_centre_reference ||
      shadowVlanLocation,
    nniPopName,
    nniLabel,
    nniLocation: aEndFullAddressLocation,
    amortisedAnnualPrice: priceData.amortised_annual_price,
    isPriceListRowSelected: isPriceSelected(priceData.id),
    tooltip: hasCostsPermissions
      ? `Cloud connect cost: ${currencyFormatter.format(
          priceData.cloud_connect?.annual ?? 0
        )}<br />A-End cost: ${currencyFormatter.format(priceData.a_end_annual_cost ?? 0)}`
      : undefined,
    ...convertCommonPriceData(priceData),
  };
}

function convertP2CCTPriceData(
  priceData: IPriceData,
  bandwidth: string,
  aEndPostcode: string,
  isPriceSelected: (priceId: string) => boolean,
  hasCostsPermissions: boolean
): DataRowP2CCT & SharedRowData {
  return {
    bandwidth: priceData.bandwidth || bandwidth,
    priceId: priceData.id,
    provider: priceData.cloud_connect?.provider || ProviderName.NOT_DEFINED,
    aEndSupplier: priceData.a_end_access_type,
    aEndPostcode,
    amortisedAnnualPrice: priceData.amortised_annual_price,
    isPriceListRowSelected: isPriceSelected(priceData.id),
    tooltip: hasCostsPermissions
      ? `Cloud connect cost: ${currencyFormatter.format(
          priceData.cloud_connect?.annual ?? 0
        )}<br />A-End cost: ${currencyFormatter.format(priceData.a_end_annual_cost ?? 0)}`
      : undefined,
    ...convertCommonPriceData(priceData),
  };
}

function convertOpticalP2PPriceData(
  priceData: IPriceData,
  bandwidth: string,
  dataCentreReferenceAEnd: string | undefined,
  aEndDataCentreName: string | null | undefined,
  aEndPostcode: string,
  dataCentreReferenceBEnd: string | undefined,
  bEndDataCentreName: string | null | undefined,
  bEndPostcode: string,
  isPriceSelected: (priceId: string) => boolean,
  hasCostsPermissions: boolean
): DataRowOpticalP2P & SharedRowData {
  return {
    bandwidth: priceData.bandwidth || bandwidth,
    priceId: priceData.id,
    aEndSupplier: priceData.a_end_access_type,
    bEndSupplier: priceData.b_end_access_type,
    a_end_data_centre_reference: dataCentreReferenceAEnd || aEndDataCentreName || aEndPostcode,
    b_end_data_centre_reference: dataCentreReferenceBEnd || bEndDataCentreName || bEndPostcode,
    amortisedAnnualPrice: priceData.amortised_annual_price,
    isPriceListRowSelected: isPriceSelected(priceData.id),
    tooltip: hasCostsPermissions
      ? `A-End cost: ${currencyFormatter.format(
          priceData.a_end_annual_cost ?? 0
        )} <br /> B-End cost: ${currencyFormatter.format(priceData.b_end_annual_cost ?? 0)}`
      : undefined,
    ...convertCommonPriceData(priceData),
  };
}

function convertDummyOpticalP2PPriceData(
  priceData: IDummyPrice,
  isPriceSelected: (priceId: string) => boolean
): DataRowOpticalP2P & SharedRowData {
  return {
    bandwidth: priceData.bandwidth ?? null,
    priceId: priceData.id,
    aEndSupplier: priceData.aEndSupplier,
    bEndSupplier: priceData.bEndSupplier,
    a_end_data_centre_reference:
      priceData.aEndDataCentreReference || priceData.aEndDataCentreName || priceData.aEndPostcode || '',
    b_end_data_centre_reference:
      priceData.bEndDataCentreReference || priceData.bEndDataCentreName || priceData.bEndPostcode || '',
    amortisedAnnualPrice: null,
    isPoA: true,
    isPriceListRowSelected: isPriceSelected(priceData.id),
    termLengthInMonths: priceData.term_length_in_months ?? undefined,
    product_sub_type: 'optical',
    supportsSecondaryCircuits: false,
  };
}

export type PriceRowData = SharedRowData &
  (DataRowDIA | DataRowNNI2CCT | DataRowOpticalP2P | DataRowP2CCT | DataRowP2NNI | DataRowP2P);

interface ConvertPriceDataParams {
  productType: ProductType;
  aEndBandwidth: string | null;
  bandwidth: string;
  aEndPostcode: string;
  bEndPostcode: string;
  isPriceSelected: (priceId: string) => boolean;
  hasCostsPermissions: boolean;
  nniReference: string | undefined;
  dataCentreReferenceAEnd: string | undefined;
  quoteEndpointMeta: IMeta | undefined;
  shadowVlanLocation: string | undefined;
  nniPopName: string | undefined;
  nniLabel: string;
  aEndFullAddressLocation: string | null | undefined;
  aEndDataCentreName: string | null | undefined;
  dataCentreReferenceBEnd: string | undefined;
  bEndDataCentreName: string | null | undefined;
  bEndBandwidth: string | null;
}

export function convertPriceData({
  productType,
  priceData,
  aEndBandwidth,
  bandwidth,
  aEndPostcode,
  bEndPostcode,
  isPriceSelected,
  hasCostsPermissions,
  nniReference,
  dataCentreReferenceAEnd,
  quoteEndpointMeta,
  shadowVlanLocation,
  nniPopName,
  nniLabel,
  aEndFullAddressLocation,
  aEndDataCentreName,
  dataCentreReferenceBEnd,
  bEndDataCentreName,
  bEndBandwidth,
}: ConvertPriceDataParams & {
  priceData: IPriceData | IDummyPrice;
}): PriceRowData {
  if (isDummyPrice(priceData)) {
    return convertDummyOpticalP2PPriceData(priceData, isPriceSelected);
  }
  const productTypeToDataMapping: {
    [key in ProductType]: () => PriceRowData;
  } = {
    [ProductType.DIA]: () => ({
      ...convertDIAPriceData(priceData, aEndBandwidth, bandwidth, aEndPostcode, isPriceSelected, hasCostsPermissions),
    }),
    [ProductType.NNI2CCT]: () => ({
      ...convertNNI2CCTPriceData(
        priceData,
        bandwidth,
        nniReference,
        dataCentreReferenceAEnd,
        quoteEndpointMeta,
        shadowVlanLocation,
        nniPopName,
        nniLabel,
        aEndFullAddressLocation,
        isPriceSelected,
        hasCostsPermissions
      ),
    }),
    [ProductType.OpticalP2P]: () => ({
      ...convertOpticalP2PPriceData(
        priceData,
        bandwidth,
        dataCentreReferenceAEnd,
        aEndDataCentreName,
        aEndPostcode,
        dataCentreReferenceBEnd,
        bEndDataCentreName,
        bEndPostcode,
        isPriceSelected,
        hasCostsPermissions
      ),
    }),
    [ProductType.P2CCT]: () => ({
      ...convertP2CCTPriceData(priceData, bandwidth, aEndPostcode, isPriceSelected, hasCostsPermissions),
    }),
    [ProductType.P2NNI]: () => ({
      ...convertP2NNIPriceData(
        priceData,
        bEndBandwidth,
        bandwidth,
        bEndPostcode,
        nniReference,
        dataCentreReferenceAEnd,
        quoteEndpointMeta,
        shadowVlanLocation,
        nniPopName,
        nniLabel,
        aEndFullAddressLocation,
        isPriceSelected,
        hasCostsPermissions
      ),
    }),
    [ProductType.P2P]: () => ({
      ...convertP2PPriceData(
        priceData,
        aEndBandwidth,
        bandwidth,
        aEndPostcode,
        bEndPostcode,
        isPriceSelected,
        hasCostsPermissions
      ),
    }),
  };

  return productTypeToDataMapping[productType]();
}

interface PriceListProps {
  data: (IPriceData | IDummyPrice)[];
  onPriceItemClick: (priceId: string) => void;
  isPriceSelected: (priceId: string) => boolean;
}

interface ReduxParams {
  productType: ProductType | null | undefined;
  aEndBandwidth: string | null;
  bandwidth: string;
  aEndPostcode: string;
  bEndPostcode: string;
  nniReference: string | undefined;
  dataCentreReferenceAEnd: string | undefined;
  quoteEndpointMeta: IMeta | undefined;
  shadowVlanLocation: string | undefined;
  nniPopName: string | undefined;
  nniLabel: string;
  aEndFullAddressLocation: string | null | undefined;
  aEndDataCentreName: string | null | undefined;
  dataCentreReferenceBEnd: string | undefined;
  bEndDataCentreName: string | null | undefined;
  bEndBandwidth: string | null;
}

export function PricesList({ data, onPriceItemClick, isPriceSelected }: PriceListProps) {
  const reduxParams: ReduxParams = useSelector(selectProps);
  const hasCostsPermissions = useSelector(selectHasCostsPermissions);
  const productType = reduxParams.productType;
  // TODO: Sort the type system so this is unnecessary
  if (!productType) return null;
  return (
    <List
      productType={productType}
      className="prices-list"
      data={data.map((priceData) =>
        convertPriceData({
          ...reduxParams,
          isPriceSelected,
          hasCostsPermissions,
          priceData,
          productType,
        })
      )}
      onRowClick={(cell: RowCell) => {
        if (cell.row.original.priceId) {
          onPriceItemClick(cell.row.original.priceId);
        }
      }}
    />
  );
}

const mapStateToProps = (state: IQuotesState): ReduxParams => {
  return {
    nniLabel: selectNNILabelFromQuote(state),
    aEndBandwidth: state.quote.aEndBandwidth,
    bEndBandwidth: state.quote.bEndBandwidth,
    aEndPostcode: state.quote.location.aEnd.postcode,
    aEndFullAddressLocation: state.quote.location.aEnd.fullAddress?.attributes.location,
    bEndPostcode: state.quote.location.bEnd.postcode,
    dataCentreReferenceBEnd: state.quote.location.bEnd.dataCentreReference,
    dataCentreReferenceAEnd: state.quote.location.aEnd.dataCentreReference,
    aEndDataCentreName: state.quote.location.aEnd.fullAddress?.attributes.name,
    bEndDataCentreName: state.quote.location.bEnd.fullAddress?.attributes.name,
    nniReference: state.quote.location.aEnd.nni.reference,
    nniPopName: state.quote.location.aEnd.nni.popName,
    shadowVlanLocation: state.quote.location.aEnd.nni.shadowVLAN.location,
    quoteEndpointMeta: state.quoteEndpointMeta,
    productType: state.quote.productType,
    bandwidth: state.quote.bandwidth,
  };
};

const selectProps = createSelector(
  (state: IAppState) => state.quoteBuilder,
  (quoteBuilder) => mapStateToProps(quoteBuilder)
);
