import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { IAppState } from 'reducers';
import { resetPricingProgressStateAction, setSelectedPrice as setSelectedPriceAction } from 'Quotes/actions';
import { ProductType } from 'Quotes/types/productTypes';
import getAmortisedValueForContractTerm from 'Quotes/utils/getAmortisedValueForContractTerm';
import { monthsToYears } from '../../../Configure/ContractTermLength/contractTermLengths';
import { selectIsEmptyQuoteExtraInfo, selectIsNNATPrice, selectPricingProgress } from 'Quotes/selectors';
import QuoteExtraInfo from '../QuoteExtraInfo';
import { isSSEUserFromBackendRoles } from 'Admin/utils/companyRolesRows';
import { IUserRoleFromBackend } from 'User/types/role';
import { NNATAlert } from 'Quotes/shared/components/NNAT/NNATAlerts';
import { PricesDisplay } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/Prices';
import {
  IDummyPrice,
  isRealPrice,
} from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import { createSelector } from '@reduxjs/toolkit';
import SecondaryCircuits from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import { EnabledByFlag } from 'FeatureFlags/EnabledByFlag';
import { Feature } from 'FeatureFlags/types';
import SupplierAddressSelector from 'shared/components/molecules/SecondaryCircuits/SupplierAddressSelector';

interface ISupplierSelectionProps {
  contractTerm: string;
  className?: string;
  allPrices: IPriceData[];
  priceDatas: IPriceData[];
  productType: ProductType;
  readOnly: boolean;
  selectedPrice: IPriceData | IDummyPrice;
  setSelectedPrice: (preferredPriceId: string, amortised: boolean, missingPrices?: IDummyPrice[]) => void;
  allRoles?: IUserRoleFromBackend[] | undefined;
  quoteId?: string;
  shortId?: string;
  quote: IQuote;
}

function isAmortised(selectedPrice: IPriceData, contractTerm: string) {
  if (selectedPrice.id && selectedPrice.amortised === false) return false;
  return getAmortisedValueForContractTerm(monthsToYears(contractTerm));
}

export const UnstyledSupplierSelection = ({
  contractTerm,
  productType,
  selectedPrice,
  setSelectedPrice,
  priceDatas,
  allPrices,
  className,
  readOnly,
  allRoles,
  quoteId,
  shortId,
  quote,
}: ISupplierSelectionProps) => {
  const dispatch = useDispatch();
  const isSSEUser = isSSEUserFromBackendRoles(allRoles as []);
  const isEmptyQuoteExtraInfo = useSelector(selectIsEmptyQuoteExtraInfo);
  const isNNATSelectedPrice = useSelector(selectIsNNATPrice);

  const showExtraQuoteInfo = isSSEUser && !isEmptyQuoteExtraInfo;
  const showExternalUserNNATMessage = isNNATSelectedPrice && !showExtraQuoteInfo;

  const pricingProgress = useSelector(selectPricingProgress);

  const resetPriceError = () => {
    if (pricingProgress.error) {
      dispatch(resetPricingProgressStateAction());
    }
  };

  const onPriceItemClick = (priceId: string, missingPrices?: IDummyPrice[]) => {
    if (readOnly) return;
    const amortised = isRealPrice(selectedPrice) && isAmortised(selectedPrice, contractTerm);
    setSelectedPrice(priceId, amortised, missingPrices);
  };

  return (
    <div className={cx(className, 'w-100')}>
      <PricesDisplay
        readonly={readOnly}
        resetPriceError={resetPriceError}
        selectedPriceId={selectedPrice.id}
        allPrices={allPrices}
        filteredPrices={priceDatas}
        setSelectedPrice={onPriceItemClick}
        productType={productType}
        quoteId={quoteId}
        shortId={shortId}
      />

      <div className="mt-1">
        <SupplierAddressSelector isReadOnly={readOnly} quote={quote} />
      </div>

      <EnabledByFlag feature={Feature.SecondaryCircuits}>
        <div className="mt-1">
          <SecondaryCircuits isReadOnly={readOnly} quote={quote} />
        </div>
      </EnabledByFlag>

      {showExtraQuoteInfo && (
        <div className="mt-1">
          <QuoteExtraInfo />
        </div>
      )}

      {showExternalUserNNATMessage && (
        <div className="mt-1">
          <NNATAlert />
        </div>
      )}

      {priceDatas.length > 0 && (
        <div className="mt-3">
          <p className="mb-0">Amounts shown are representative of Total Annual Rental and no up-front charges</p>
        </div>
      )}
    </div>
  );
};

export const UnconnectedSupplierSelection = styled(UnstyledSupplierSelection)`
  .view_as_icon {
    color: ${(props) => props.theme.colours.grey30};

    &.active {
      color: ${(props) => props.theme.colours.primaryB1};
    }
  }

  .external_user_tooltip .multi-line {
    text-align: left;
  }

  .export-csv-row {
    display: flex;
    justify-content: flex-end;
    margin: 1.2rem 0;
  }
`;

const mapStateToProps = createSelector(
  (state: IAppState) => state.quoteBuilder,
  (state: IAppState) => state.user,
  (quoteBuilder, user) => ({
    contractTerm: quoteBuilder.quote.contractTerm,
    allPrices: quoteBuilder.pricing.allPrices,
    priceDatas: quoteBuilder.pricing.filteredPrices,
    selectedPrice: quoteBuilder.pricing.selectedDummyPrice || quoteBuilder.pricing.selectedPrice,
    productType: quoteBuilder.quote.productType,
    allRoles: user.allRoles,
    quoteId: quoteBuilder.currentQuoteId,
    shortId: quoteBuilder.quote.shortId,
    quote: quoteBuilder.quote,
  })
);

export const SupplierSelection = ({ readOnly }: { readOnly: boolean }) => {
  const state = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const setSelectedPrice = (preferredPriceId: string, amortised: boolean, missingPrices?: IDummyPrice[]) =>
    dispatch(setSelectedPriceAction(preferredPriceId, amortised, missingPrices));

  const productType = state.productType;
  // TODO: Sort the type system so this is unnecessary
  if (!productType) return null;

  return (
    <UnconnectedSupplierSelection
      {...state}
      productType={productType}
      setSelectedPrice={setSelectedPrice}
      readOnly={readOnly}
    />
  );
};
