import React, { FunctionComponent } from 'react';
import Column from 'shared/components/atoms/Column';
import { useSelector } from 'react-redux';
import { selectQuote } from 'Quotes/selectors';
import { getIPTypeLabel } from 'Quotes/QuoteBuilder/utils/ip';
import { ProductType } from 'Quotes/types/productTypes';
import {
  DualCircuitRouterConfigurations,
  findDiaIPAllocationOptionById,
} from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { IQuote } from 'Quotes/types/store';
import { mdiaRouterOptions } from 'shared/components/molecules/SecondaryCircuits/MDIARouters';

function PrimaryIPType(props: { quote: IQuote }) {
  if (!props.quote.location.aEnd.ip.selectedId) return null;
  return (
    <span className="font-weight-bold" data-testid="order-summary-dia-ip-type">
      {getIPTypeLabel(
        props.quote.location.aEnd.ip.selectedId,
        props.quote.location.aEnd.is_managed_dia,
        props.quote.location.aEnd.dia_ip_allocation
      )}
    </span>
  );
}

function SecondaryIPType(props: { quote: IQuote }) {
  return (
    <span className="font-weight-bold" data-testid="order-summary-dia-ip-type">
      {getIPTypeLabel(
        props.quote.location.aEnd.secondIPChoice,
        props.quote.location.aEnd.is_managed_dia,
        props.quote.location.aEnd.dia_ip_allocation
      )}
    </span>
  );
}

function DualIPType(props: { quote: IQuote; label?: string }) {
  if (!props.quote.location.aEnd.ip.selectedId) return null;
  if (!props.quote.location.aEnd.secondIPChoice) return null;
  return (
    <>
      {props.label === 'Primary' && <PrimaryIPType quote={props.quote} />}
      {props.label === 'Secondary' && <SecondaryIPType quote={props.quote} />}
    </>
  );
}

interface DIAConfigProps {
  circuitLabel?: string; // Primary or Secondary, used to display the IP type
}

export const DIAConfig: FunctionComponent<DIAConfigProps> = ({ circuitLabel }) => {
  const quote = useSelector(selectQuote);
  if (quote.productType !== ProductType.DIA) {
    return null;
  }

  const { routerChoice } = quote.location.aEnd;
  const dualRouters = [
    DualCircuitRouterConfigurations.DUAL_INDEPENDENT,
    DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_ACTIVE,
    DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_STANDBY,
  ];
  const isDualRouter = routerChoice ? dualRouters.includes(routerChoice) : false;
  const dualRouterConfig = mdiaRouterOptions.find((option) => option.value === quote.location.aEnd.routerChoice)?.label;

  return (
    <>
      <div className="row no-gutters mt-3">
        <Column defaultWidth={2}>
          <span className="sub-heading">DIA Configuration</span>
        </Column>
        {quote.location.aEnd.dia_ip_allocation && (
          <Column defaultWidth={2}>
            <span className="sub-heading" data-testid="order-summary-dia-ip-allocation">
              {findDiaIPAllocationOptionById(quote.location.aEnd.dia_ip_allocation!)?.label}
            </span>
          </Column>
        )}
        {quote.location.aEnd.ip.selectedId && (
          <Column defaultWidth={8}>
            {circuitLabel ? <DualIPType quote={quote} label={circuitLabel} /> : <PrimaryIPType quote={quote} />}
          </Column>
        )}
      </div>
      {quote.location.aEnd.is_managed_dia && (
        <div className="row no-gutters mt-1">
          <Column defaultWidth={10} offsetDefaultWidth={2}>
            <span className="font-weight-bold" data-testid="order-summary-dia-managed-dia">
              Managed DIA services
            </span>
          </Column>
        </div>
      )}
      {quote.location.aEnd.is_managed_dia && routerChoice && isDualRouter && circuitLabel === 'Secondary' && (
        <div className="row no-gutters mt-1">
          <Column defaultWidth={10} offsetDefaultWidth={2}>
            <span className="font-weight-bold" data-testid="order-summary-dia-managed-dia">
              Dual Router Configuration: {dualRouterConfig}
            </span>
          </Column>
        </div>
      )}
    </>
  );
};
