import React, { FunctionComponent } from 'react';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import { UserReportType } from 'User/types/user';

interface IUserTypeFilter {
  className?: string;
  setUserType(userType: string | null): void;
}

const UserTypeFilter: FunctionComponent<React.PropsWithChildren<IUserTypeFilter>> = ({ setUserType }) => {
  const getUserTypeOptions = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [];

    Object.values(UserReportType).forEach((type) => {
      options.push({
        label: type,
        value: type,
      });
    });

    return options;
  };

  return (
    <div className="control">
      <label>User type</label>
      <Dropdown
        options={getUserTypeOptions()}
        onChange={(option: IDropdownOption) => setUserType(option ? option.value : null)}
        className="select-input usertype-dropdown"
      />
    </div>
  );
};

export default UserTypeFilter;
