import {
  IGetAlerts,
  IGetAlertsError,
  IGetAlertsStarted,
  IGetAlertsSuccess,
  IGetNextAlertsError,
  IGetNextAlertsStarted,
  IGetNextAlertsSuccess,
  AlertActionTypes,
  IGetNextAlerts,
  IMarkAlertAsRead,
} from './types/actions';
import { ErrorResponse } from 'Request';
import { IAlertsRecord } from './types/alertsRecord';

export const getAlerts = (): IGetAlerts => ({
  type: AlertActionTypes.GET_ALERTS,
});

export const getAlertsStarted = (): IGetAlertsStarted => ({
  type: AlertActionTypes.GET_ALERTS_STARTED,
});

export const getAlertsSuccess = (alertsRecord: IAlertsRecord): IGetAlertsSuccess => ({
  type: AlertActionTypes.GET_ALERTS_SUCCESS,
  payload: {
    alertsRecord,
  },
});

export const getAlertsError = (response: ErrorResponse): IGetAlertsError => ({
  payload: { response },
  type: AlertActionTypes.GET_ALERTS_ERROR,
});

export const getNextAlerts = (url: string): IGetNextAlerts => ({
  type: AlertActionTypes.GET_NEXT_ALERTS,
  payload: { url },
});

export const getNextAlertsStarted = (): IGetNextAlertsStarted => ({
  type: AlertActionTypes.GET_NEXT_ALERTS_STARTED,
});

export const getNextAlertsSuccess = (alertsRecord: IAlertsRecord): IGetNextAlertsSuccess => ({
  type: AlertActionTypes.GET_NEXT_ALERTS_SUCCESS,
  payload: {
    alertsRecord,
  },
});

export const getNextAlertsError = (response: ErrorResponse): IGetNextAlertsError => ({
  payload: { response },
  type: AlertActionTypes.GET_NEXT_ALERTS_ERROR,
});

export const markAlertAsRead = (alertId: IMarkAlertAsRead['payload']['alertId']): IMarkAlertAsRead => ({
  payload: { alertId },
  type: AlertActionTypes.MARK_ALERT_AS_READ,
});
