import React, { FunctionComponent } from 'react';
import { ICloudProvider } from '../types';
import StyledImageButton from '../../../../shared/components/molecules/ImageButton';
import { ProviderName } from '../../../../Quotes/types/store';

interface ICloudProviderCell {
  className?: string;
  provider: ICloudProvider;
  selectedProviderName: ProviderName;
  onClick(cloudProvider: ICloudProvider): void;
}

const logoForProvider = (name: string): string => {
  return `/cloud_logos/${name}.png`;
};

const CloudProviderCell: FunctionComponent<React.PropsWithChildren<ICloudProviderCell>> = ({
  provider,
  onClick,
  selectedProviderName,
}) => {
  const name = provider.attributes.name;

  const isSelected = () => {
    return provider.attributes.name === selectedProviderName;
  };

  return (
    <StyledImageButton
      selected={isSelected()}
      description={name}
      src={logoForProvider(name)}
      onClick={() => onClick(provider)}
    />
  );
};

export default CloudProviderCell;
