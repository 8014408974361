import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

// Reference: https://fkhadra.github.io/react-toastify/how-to-style#override-existing-css-classes
const StylingContainer = styled.div`
  font-size: 0.9rem;

  .Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: ${(props) => props.theme.alerts.info.backgroundColor};
    color: ${(props) => props.theme.alerts.info.color};

    .Toastify__close-button {
      color: ${(props) => props.theme.alerts.info.color};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: ${(props) => props.theme.alerts.success.borderColor};
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: ${(props) => props.theme.colours.primaryC1};
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: ${(props) => props.theme.alerts.danger.backgroundColor};
  }
`;

export const ToastSetup = () => {
  return (
    <StylingContainer>
      <ToastContainer position="bottom-center" transition={Slide} theme="colored" />
    </StylingContainer>
  );
};
