import getQuotesFromBulkQuote from 'Quotes/crud/getQuotesFromBulkQuote';
import { IQuoteListItem } from 'Quotes/types/quoteRecord';
import React, { useCallback, useEffect, useState } from 'react';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import Modal, { IModalProps } from 'shared/components/molecules/Modal';
import styled from 'styled-components';
import { CopyFromQuoteDropdown, OrderedQuoteListItem } from './CopyFromQuoteDropdown';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import { useImmediateRequest } from 'shared/hooks/useImmediateRequest';

export const modalTitle = 'Copy & Paste';
interface CopyFromQuoteModalProps extends Omit<IModalProps, 'children' | 'title'> {
  pasteDestination?: string;
  multiQuoteId?: string;
  orderId: string;
  bulkQuoteId: string;
  onOrderSelect: (orderId: string | null) => void;
}

const getOtherOrderedQuotes = (currentOrderId: string, multiQuoteOrders: IQuoteListItem[]) =>
  multiQuoteOrders.filter((it) => it.order_id && it.order_id !== currentOrderId) as OrderedQuoteListItem[];

export const CopyFromQuoteModal = ({ orderId, onOrderSelect, bulkQuoteId, ...modalProps }: CopyFromQuoteModalProps) => {
  const [otherOrderedQuotes, setOtherOrderedQuotes] = useState<OrderedQuoteListItem[]>([]);

  const getQuotesRequest = useCallback(() => getQuotesFromBulkQuote(bulkQuoteId), [bulkQuoteId]);
  const { response: bulkQuotesResponse, inProgress } = useImmediateRequest(getQuotesRequest);

  useEffect(() => {
    if (!bulkQuotesResponse) return;

    const otherOrders = getOtherOrderedQuotes(orderId, bulkQuotesResponse.data.attributes.bulk_lines || []);

    setOtherOrderedQuotes(otherOrders);
  }, [bulkQuotesResponse]);

  const showDropdown = otherOrderedQuotes.length > 0 && !inProgress;

  const noOtherOrdersAvailable = bulkQuotesResponse && otherOrderedQuotes.length === 0;

  return (
    <Modal {...modalProps} confirmBtnLabel="Copy & Paste" title={modalTitle} size="lg" disableOutsideClick={true}>
      <ModalContent>
        <Alert alertType={Alerts.WARNING}>
          <AlertContainer>
            <h5>Please note</h5>
            <ul>
              <li>You can only copy and paste from orders that were part of the same Multi-Quote.</li>
              <li>If product types differ, some information may not be copied over.</li>
              <li>Any completed fields in the order section you are copying to may be overwritten.</li>
              <li>Please check all information is correct and complete before submitting for review.</li>
            </ul>
          </AlertContainer>
        </Alert>
      </ModalContent>

      {showDropdown && (
        <>
          <strong>Copy from order</strong>
          <CopyFromQuoteDropdown otherOrderedQuotes={otherOrderedQuotes} onOrderSelect={onOrderSelect} />
        </>
      )}

      {inProgress && <SpinnerWithText text="Loading..." />}

      {noOtherOrdersAvailable && (
        <Alert alertType={Alerts.WARNING}>There are no other orders available from the same multi quote.</Alert>
      )}
    </Modal>
  );
};

const AlertContainer = styled.div`
  text-align: left;

  strong {
    color: ${(props) => props.theme.alerts.warning.headingColor};
    font-size: 1.2rem;
  }

  ul {
    padding-left: 1.5rem;
  }
`;

const ModalContent = styled.div`
  text-align: left;
`;
