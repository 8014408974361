import { IQuote } from 'Quotes/types/store';
import { isFTTXQuote } from 'Quotes/utils/isFTTXQuote';

const MAX_TERMS_FOR_QUICK_PRICE = 12;

/**
 * This is the mirror of the logic used by the Pricing Engine to determine whether to use the async pricing with progress.
 */
export const requiresAsyncPrices = (quote: IQuote) => {
  const requestedTerms = quote.chosen_bandwidths.length * quote.chosen_term_lengths_in_months.length;

  return requestedTerms > MAX_TERMS_FOR_QUICK_PRICE && !isFTTXQuote(quote);
};
