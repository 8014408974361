import { IOrder } from 'Order/types/store';
import React, { FC } from 'react';
import styled from 'styled-components';
import { CollapsableCard } from '../shared/components/CollapsableCard';
import { StatusStepper } from '../shared/components/StatusStepper';
import {
  emptyCerillionOrderStageHistorySteps,
  mapCerillionOrderStageHistoryToSteps,
} from '../shared/components/StatusStepper/utils/mappers';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';

interface IDeliveryDetailsProps {
  className?: string;
  order: IOrder;
}

const UnstyledDeliveryDetails: FC<React.PropsWithChildren<IDeliveryDetailsProps>> = ({ className, order }) => {
  // const content = hasFeatureEnabled(Feature.orderCerillionDeliveryInfo) ? (
  //   <>
  //     <hr />
  //     <CardRow title="Delivery information">
  //       <div className="row no-gutters mb-4 card-container">
  //         <Column smWidth={12} mdWidth={6} lgWidth={6}>
  //           <ul>
  //             <li>
  //               <TextWithLabelSummary
  //                 label="Next customer update"
  //                 text={'DD/MM/YYYY'}
  //               />
  //             </li>
  //             <li>
  //               <br />
  //             </li>
  //             <li>
  //               <TextWithLabelSummary
  //                 label="Site survey date(s)"
  //                 text={'DD/MM/YYYY (A-End)'}
  //               />
  //             </li>
  //             <li>
  //               <TextWithLabelSummary label="" text={'DD/MM/YYYY (B-End)'} />
  //             </li>
  //             <li>
  //               <br />
  //             </li>
  //             <li>
  //               <TextWithLabelSummary
  //                 label="Order owner for the provide P2NNI"
  //                 text={'API, MEOS, Mx'}
  //               />
  //             </li>
  //             <li>
  //               <TextWithLabelSummary
  //                 label="Forecast Neos delivery date"
  //                 text={'DD/MM/YYYY'}
  //               />
  //             </li>
  //             <li>
  //               <TextWithLabelSummary
  //                 label="Service activation"
  //                 text={'Lorem ipsum'}
  //               />
  //             </li>
  //           </ul>
  //         </Column>
  //         <Column smWidth={12} mdWidth={6} lgWidth={6}>
  //           <ul>
  //             <li>
  //               <TextWithLabelSummary
  //                 label="Site survey date"
  //                 text={'API, MEOS, Mx'}
  //               />
  //             </li>
  //             <li>
  //               <TextWithLabelSummary
  //                 label="Forecast supplier CDD"
  //                 text={'DD/MM/YYYY'}
  //               />
  //             </li>
  //             <li>
  //               <TextWithLabelSummary
  //                 label="Supplier fit and test date"
  //                 text={'DD/MM/YYYY'}
  //               />
  //             </li>
  //             <li>
  //               <TextWithLabelSummary
  //                 label="Supplier service category"
  //                 text={'Lorem ipsum'}
  //               />
  //             </li>
  //           </ul>
  //         </Column>
  //       </div>
  //     </CardRow>
  //   </>
  // ) : null;

  const isEnabled = featureFlag.isEnabled(Feature.orderCerillionDeliveryInfo);
  if (!isEnabled) return null;
  
  if (!order.cerillion_stage) return null;

  return (
    <div className={`${className} delivery-details`}>
      <CollapsableCard
        header={<h4 className="title">Delivery details</h4>}
        subHeader={
          <StatusStepper
            steps={
              order.cerillion_stage_history
                ? mapCerillionOrderStageHistoryToSteps(order.cerillion_stage_history)
                : emptyCerillionOrderStageHistorySteps
            }
            currentStepId={order.cerillion_stage}
            currentStepDescription={order.cerillion_order_status_message}
          />
        }
      />
    </div>
  );
};

export const DeliveryDetails = styled(UnstyledDeliveryDetails)`
  hr {
    margin-bottom: 2rem;
  }

  h4.title {
    color: ${(props) => props.theme.colours.primaryB1};
  }

  .card-container {
    ul {
      list-style: none;
    }

    li {
      white-space: nowrap;
    }
  }
`;
