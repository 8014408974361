import { useEffect, useRef, useState } from 'react';

import { onFetchSuccess } from './types';
import { doRequest } from 'Request';

type UseNNIListFetch = {
  customerId: string | null;
  onSuccess: onFetchSuccess;
};

async function fetchNNIs(customerId: string) {
  const [existingNNIAgreements, nniCapableDataCentres] = await Promise.all([
    doRequest({
      path: `/customers/${customerId}/nnis/`,
    }),
    doRequest({
      path: '/nni-data-centres/',
    }),
  ]);
  return { existingNNIAgreements, nniCapableDataCentres };
}

export const useNNIListFetch = ({ customerId, onSuccess }: UseNNIListFetch) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!loading && !error && customerId && !hasFetched.current) {
      setLoading(true);

      (async () => {
        try {
          const { existingNNIAgreements, nniCapableDataCentres } = await fetchNNIs(customerId);
          onSuccess(existingNNIAgreements.data, nniCapableDataCentres.data);
        } catch (e) {
          setError(true);
        } finally {
          setLoading(false);
          hasFetched.current = true;
        }
      })();
    }
  }, [customerId, error, loading, onSuccess]);

  return { error, loading };
};
