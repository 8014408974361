export const joinWordsWithComma = (words: string[]) => {
  // Check if the array is empty
  if (words.length === 0) {
    return '';
  }

  // If there's only one word, return it
  if (words.length === 1) {
    return words[0];
  }

  // Join all words except the last with ', '
  const allButLast = words.slice(0, -1).join(', ');

  // Return the joined words with ' or ' before the last word
  return `${allButLast} or ${words[words.length - 1]}`;
};
