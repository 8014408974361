import { ButtonMainStyle } from 'shared/components/atoms/Button';
import React, { FunctionComponent } from 'react';
import { OrderPriceButton } from './Buttons';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectShouldEnableProceedToOrder, shouldDisplayProceedToOrderButton } from './selectors';
import { selectIsNNATPrice, selectQuote } from 'Quotes/selectors';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { useSecondaryCircuitsWithSelectedPrice } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { SecondaryCircuitStatus } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';

const useRequiresRO2Price = (): boolean => {
  const secondaryCircuits = useSecondaryCircuitsWithSelectedPrice();

  const isRO2 = featureFlag.isEnabled(Feature.SecondaryCircuits) && secondaryCircuits.isSupported();

  if (!isRO2 || !secondaryCircuits.enabled) return false;

  // By not including 'no prices' or 'no exchanges' we force a user to deselect Secondary Circuits
  return secondaryCircuits.exchangeStatus !== SecondaryCircuitStatus.SHOW_CHECK_NOW;
};

const useRO2HasSecondaryPrice = (): boolean => {
  const secondaryCircuits = useSecondaryCircuitsWithSelectedPrice();

  return !!secondaryCircuits.selectedCircuit?.selectedPriceId;
};

const useDualRouterEnabledConditions = (requiresRO2Price: boolean): boolean => {
  const quote = useSelector(selectQuote);

  const { routerChoice } = quote.location.aEnd;
  const isMDIA = quote.location.aEnd.is_managed_dia;

  if (!isMDIA) return true;
  if (!requiresRO2Price) return true;

  return !!routerChoice;
};

const useQuoteOrderability = () => {
  const visible = useSelector(shouldDisplayProceedToOrderButton);
  const enabled = useSelector(selectShouldEnableProceedToOrder);
  const requiresRO2Price = useRequiresRO2Price();
  const hasSecondaryPrice = useRO2HasSecondaryPrice();
  const dualRouterAcceptable = useDualRouterEnabledConditions(requiresRO2Price);

  const ro2Acceptable = !requiresRO2Price || (requiresRO2Price && hasSecondaryPrice);

  return { visible: visible, enabled: enabled && ro2Acceptable && dualRouterAcceptable };
};

export const CreateAQuoteOrProceedToOrder = ({ onClick, isPoAQuote }: { onClick: () => void; isPoAQuote: boolean }) => {
  const isNNATPrice = useSelector(selectIsNNATPrice);
  const { visible, enabled } = useQuoteOrderability();
  if (!visible) return null;

  const buttonText = orderPriceButtonText(isPoAQuote, isNNATPrice);

  return (
    <ProceedToOrderButton>
      <OrderPriceButton
        data-testid="proceed to order button"
        className="order-price-btn mr-0"
        disabled={!enabled}
        mainStyle={ButtonMainStyle.PrimaryRectangular}
        onClick={onClick}
      >
        {buttonText}
      </OrderPriceButton>
    </ProceedToOrderButton>
  );
};

const ProceedToOrderButtonDiv: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <div className="price__proceed_to_order_btn">{children}</div>;
};

const ProceedToOrderButton = styled(ProceedToOrderButtonDiv)`
  margin-bottom: 1em;
  position: relative;

  button {
    min-width: 200px;
    width: fit-content;
  }
`;

function orderPriceButtonText(shouldCreateQuote: boolean, isNNATPrice: boolean) {
  if (isNNATPrice && !featureFlag.isEnabled(Feature.nnatOrderable)) return 'Proceed to Data Capture';

  return shouldCreateQuote ? 'Proceed to application' : 'Proceed to order';
}
