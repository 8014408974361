import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

interface IGetInTouchProps {
  quoteShortId: string;
  message: string;
  className?: string;
}

export const GetInTouch: FunctionComponent<React.PropsWithChildren<IGetInTouchProps>> = ({
  quoteShortId,
  className,
  message,
}) => (
  <div className={cx(className, 'get-in-touch')}>
    <h3>Get in touch</h3>
    <p>{message}</p>
    <div>
      Your Quote ID is <strong>{capitalize(quoteShortId)}</strong>
    </div>
  </div>
);

export const StyledGetInTouch = styled(GetInTouch)<IGetInTouchProps>`
  padding: 1rem;
  border-radius: 5px;

  h3 {
    color: ${(props) => props.theme.colours.secondary};
  }

  p {
    color: ${(props) => props.theme.colours.grey70};
    margin-top: 1rem;
  }
`;
