import { ConnectionType, OverallConnectionType } from 'Quotes/types/connectionType';
import { fttxMixedConnectionTypesBreakdown, isConnectionTypeMixed } from 'shared/utils/connectionCapacity';
import Column from 'shared/components/atoms/Column';
import React from 'react';

export function MixedFttxBandwidth(props: {
  aEndAccessMethod: ConnectionType | null;
  bEndAccessMethod: ConnectionType | null;
  connectionType: OverallConnectionType;
  aEndBandwidth: string | null;
  bEndBandwidth: string | null;
}) {
  return (
    <>
      {isConnectionTypeMixed({
        aEndAccessMethod: props.aEndAccessMethod,
        bEndAccessMethod: props.bEndAccessMethod,
        connectionType: props.connectionType,
      }) && (
        <Column defaultWidth={6}>
          {fttxMixedConnectionTypesBreakdown({
            aEndBandwidth: props.aEndBandwidth,
            bEndBandwidth: props.bEndBandwidth,
            aEndAccessMethod: props.aEndAccessMethod,
            bEndAccessMethod: props.bEndAccessMethod,
          })}
        </Column>
      )}
    </>
  );
}
