import React, { FunctionComponent } from 'react';

import Edit from './Edit';
import Summary from './Summary';
import { IOrder } from '../../../types/store';

export interface IBillingContactAndAddressProps {
  order: IOrder;
  canEdit: boolean;
}

const BillingContactAndAddress: FunctionComponent<React.PropsWithChildren<IBillingContactAndAddressProps>> = ({
  order,
  canEdit,
}) =>
  canEdit ? (
    <Edit order={order} />
  ) : (
    <Summary
      billingContactAndAddress={order.billingContactAndAddress}
      billingFrequency={order.billingFrequency}
      orderStatus={order.state}
    />
  );

export { BillingContactAndAddress };
