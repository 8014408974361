import React, { FC, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { toggleChosenBandwidth } from 'Quotes/actions';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBearer,
  selectChosenBandwidths,
  selectCommonCircuitOptions,
  selectConnectionType,
} from 'Quotes/selectors';
import bandwidthTypes, {
  getPopularOpticalBandwidths,
  POPULAR_BANDWIDTHS,
} from 'Quotes/QuoteBuilder/components/Configure/Bandwidth/bandwidthTypes';
import Column from 'shared/components/atoms/Column';
import { BandwidthsDropdown } from 'Quotes/shared/components/BandwidthsDropmenu';
import { PopularBandwidths } from 'Quotes/shared/components/PopularBandwidths/PopularBandwidths';
import { MAX_SELECTABLE_BANDWIDTHS } from 'Quotes/reducer';
import { Badge } from 'shared/components/atoms/Badge/Badge';
import styled from 'styled-components';
import { canToggle } from 'Quotes/QuoteBuilder/utils/canToggle';
import Row from 'shared/components/atoms/Column/row';
import {
  BearerType,
  isEthernetBearer,
  isOpticalBearer,
} from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import bearerToBandwidth from 'Quotes/QuoteBuilder/components/Configure/bearerBandwidthMap';
import cx from 'classnames';

export const Bandwidth: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const selectedBearer = useSelector(selectBearer);
  return (
    <div className="mb-4">
      <BandwidthHeader />
      {selectedBearer ? <BandwidthSelect selectedBearer={selectedBearer} /> : <NoBearerSelected />}
    </div>
  );
};

const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  h5 {
    margin: 0;
  }
`;

function BandwidthHeader() {
  return (
    <Header>
      <h5>Bandwidth sizes</h5>
      <Badge rounded={true}>Most popular</Badge>
    </Header>
  );
}

function NoBearerSelected() {
  return <small className="text-muted d-block pt-1">Bandwidths not yet available - select a bearer first.</small>;
}

function usePopularBandwidths(selectedBearer: BearerType) {
  const [popularBandwidths, setPopularBandwidths] = useState(POPULAR_BANDWIDTHS);
  useEffect(() => {
    const bandwidths: string[] = [
      ...(isEthernetBearer(selectedBearer) ? POPULAR_BANDWIDTHS : []),
      ...(isOpticalBearer(selectedBearer) ? getPopularOpticalBandwidths() : []),
    ];
    bandwidths.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
    setPopularBandwidths([...new Set(bandwidths)]);
  }, [selectedBearer]);

  return popularBandwidths;
}

const BandwidthSelect: FC<React.PropsWithChildren<{ selectedBearer: BearerType }>> = ({ selectedBearer }) => {
  const dispatch = useDispatch();
  const chosenBandwidths = useSelector(selectChosenBandwidths);
  const commonBandwidths = useSelector(selectCommonCircuitOptions)?.bandwidths?.map((value) => value.toString()) ?? [];
  const connectionType = useSelector(selectConnectionType);
  const popularBandwidths = usePopularBandwidths(selectedBearer);

  const bandwidths = useMemo(
    () => [
      ...new Set([
        ...bandwidthTypes.SMALL,
        ...bandwidthTypes.MEDIUM,
        ...bandwidthTypes.LARGE,
        ...bandwidthTypes.OPTICAL_SMALL,
        ...bandwidthTypes.OPTICAL_MEDIUM,
        ...bandwidthTypes.OPTICAL_LARGE,
      ]),
    ],
    []
  );

  const isBandwidthDisabled = (bandwidth: string) => {
    if (commonBandwidths.length === 0) return false;

    const availableBandwidths = bearerToBandwidth[selectedBearer];

    return !commonBandwidths.includes(bandwidth) || !availableBandwidths.includes(bandwidth);
  };

  const isBandwidthEnabled = (bandwidth: string) => !isBandwidthDisabled(bandwidth);

  const options = bandwidths.map((item) => ({
    label: formatBandwidth(item),
    value: item,
    isDisabled: isBandwidthDisabled(item),
  }));

  const toggleBandwidthWithLimitation = (bandwidth: string) => {
    if (canToggle(bandwidth, chosenBandwidths, MAX_SELECTABLE_BANDWIDTHS)) dispatch(toggleChosenBandwidth(bandwidth));
  };

  const availableBandwidths = bandwidths.filter(isBandwidthEnabled);
  const allAvailableBandwidthsAreInPopular = availableBandwidths.every((bandwidth) =>
    popularBandwidths.includes(bandwidth)
  );

  return (
    <>
      <Row>
        <Column>
          {/* <p>Select all bandwidths that apply.</p> */}
          <p>
            Select up to <strong>{MAX_SELECTABLE_BANDWIDTHS}</strong> bandwidths.
          </p>

          <PopularBandwidths
            bandwidths={availableBandwidths}
            chosenBandwidths={chosenBandwidths}
            onToggleBandwidth={toggleBandwidthWithLimitation}
            popularBandwidths={popularBandwidths}
          />
        </Column>
      </Row>
      <Row className={cx({ 'd-none': allAvailableBandwidthsAreInPopular })}>
        <Column defaultWidth={12} mdWidth={8} lgWidth={6} xlWidth={4}>
          <BandwidthsDropdown
            options={options}
            onToggleBandwidth={toggleBandwidthWithLimitation}
            chosenBandwidths={chosenBandwidths}
            isDisabled={
              connectionType !== 'Ethernet' || !Array.isArray(commonBandwidths) || commonBandwidths.length === 0
            }
            connectionType={connectionType}
          />
        </Column>
      </Row>
    </>
  );
};
