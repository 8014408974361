import React, { FC, useEffect, useState } from 'react';
import Modal from '../../molecules/Modal';
import { useDispatch } from 'react-redux';
import { setSelectedCompany } from 'User/actions';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface IProps {
  selectedCompanyId: string | null;
  path: string;
  editMode?: boolean;
}

const SwitchCustomerModal: FC<React.PropsWithChildren<IProps>> = (props) => {
  const navigate = useNavigate();
  return <SwitchCustomerModalInternal {...props} navigate={navigate} />;
};

export const SwitchCustomerModalInternal: FC<React.PropsWithChildren<IProps & Navigation>> = ({
  selectedCompanyId,
  path,
  editMode,
  navigate,
}) => {
  const dispatch = useDispatch();
  const [showSwitchCustomerModal, setSwitchCustomerModal] = useState<boolean>(false);

  const [currentCompanyId, setCurrentCompanyId] = useState<string | null>(selectedCompanyId);

  useEffect(() => {
    if (currentCompanyId !== selectedCompanyId) {
      if (editMode) {
        setCurrentCompanyId(currentCompanyId);
        setSwitchCustomerModal(true);
      } else {
        navigate(path);
      }
    }
  }, [selectedCompanyId, navigate, editMode, currentCompanyId, path]);

  const cancel = () => {
    setSwitchCustomerModal(false);
    setCurrentCompanyId(currentCompanyId);
    dispatch(setSelectedCompany(currentCompanyId!));
  };

  const proceed = () => {
    setSwitchCustomerModal(false);
    dispatch(setSelectedCompany(selectedCompanyId!));
    navigate(path);
  };

  if (showSwitchCustomerModal) {
    return (
      <div>
        {showSwitchCustomerModal && (
          <Modal
            closeBtnLabel="Cancel"
            confirmBtnLabel="Proceed anyway"
            disableActions={false}
            title="Switch customers"
            onClose={cancel}
            onConfirm={proceed}
          >
            By changing customers, you may lose work in progress. Are you sure you wish to proceed?
          </Modal>
        )}
      </div>
    );
  }

  return null;
};

export default SwitchCustomerModal;
