import React, { MutableRefObject } from 'react';

import cx from 'classnames';
import { OptionalIndicator, HelperText } from '../OptionalIndicator';
import { PHONE_REGEX_STRING } from '../../../utils/phoneRegex';

export interface IPhoneInput {
  forwardedRef?: MutableRefObject<any>;
  classNames?: string[];
  fieldName: string;
  inputIdentifierPrefix: string;
  optional?: boolean;
  required?: boolean;
  labelText?: string;
  helperText?: string;
  readOnly?: boolean;
  value?: string | string[] | number;
  pattern?: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

const PhoneInput = ({
  forwardedRef,
  classNames,
  fieldName,
  inputIdentifierPrefix,
  labelText = '',
  helperText,
  optional = false,
  required = false,
  readOnly = false,
  value,
  onChange,
  pattern = PHONE_REGEX_STRING,
}: IPhoneInput) => {
  const id = `${inputIdentifierPrefix}${fieldName}`;

  return (
    <div className="form-group mb-1">
      <label htmlFor={id} className="small mb-1">
        {labelText}
      </label>
      <input
        ref={forwardedRef}
        required={required}
        type="tel"
        className={cx('form-control', classNames)}
        id={id}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        pattern={pattern}
      />
      {optional && <OptionalIndicator />}
      {helperText && <HelperText text={helperText} />}
    </div>
  );
};

export default PhoneInput;
