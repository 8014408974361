import React, { FunctionComponent, useRef, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';
import { CompanyData, IUserAttributesBaseFrontendRoles } from '../../types/user';
import { IUserRoleFromBackend } from '../../types/role';
import Button, { ButtonMainStyle } from '../../../shared/components/atoms/Button';
import Column from '../../../shared/components/atoms/Column';
import TextInput from '../../../shared/components/atoms/TextInput';
import SectionHeader from '../../../shared/components/atoms/SectionHeader';
import { IAppState } from '../../../reducers';
import { updateUserPersonalDetails } from '../../actions';
import { RequestState } from '../../../Admin/types';
import Alert, { Alerts } from '../../../shared/components/atoms/Alert';
import { IUpdateUserPersonalDetailsPayload } from '../../types/actions';
import PhoneInput from '../../../shared/components/atoms/PhoneInput';
import CompaniesAndRoles from 'Admin/shared/components/CompaniesAndRoles/CompaniesAndRoles';
import { isSSEUserFromBackendRoles } from '../../../Admin/utils/companyRolesRows';

interface IEditDetails {
  allRoles: IUserRoleFromBackend[];
  className?: string;
  companyData: CompanyData;
  userData: IUserAttributesBaseFrontendRoles;
  updateUserRequestState: RequestState;
  updateUser: (updateUserPayload: IUpdateUserPersonalDetailsPayload) => void;
}

const inputIdentifierPrefix = 'updateSelf__';

export const EditDetails: FunctionComponent<React.PropsWithChildren<IEditDetails>> = ({
  allRoles,
  className,
  companyData,
  userData,
  updateUserRequestState,
  updateUser,
}) => {
  const [triedToSave, setTriedToSave] = useState<boolean>(false);
  const [user, setUser] = useState<IUserAttributesBaseFrontendRoles>({
    ...userData,
  });

  const firstNameInput = useRef<HTMLInputElement>(null);
  const lastNameInput = useRef<HTMLInputElement>(null);
  const mobilePhoneInput = useRef<HTMLInputElement>(null);
  const companyEmailInput = useRef<HTMLInputElement>(null);
  const contactEmailInput = useRef<HTMLInputElement>(null);
  const landlinePhoneInput = useRef<HTMLInputElement>(null);

  const inputRefs = [
    firstNameInput,
    lastNameInput,
    mobilePhoneInput,
    companyEmailInput,
    contactEmailInput,
    landlinePhoneInput,
  ];

  const userWith = (field: keyof IUserAttributesBaseFrontendRoles, value: string) => ({
    ...user,
    [field]: value,
  });

  const noop = () => null;

  const validInputs = (): boolean => {
    if (inputRefs.every((ref) => ref.current)) {
      return inputRefs.every((ref) => ref.current!.checkValidity());
    }
    return true;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTriedToSave(true);
    if (validInputs()) {
      updateUser(user);
    } else {
      companyEmailInput.current!.scrollIntoView();
    }
  };

  const isSSEUser = isSSEUserFromBackendRoles(allRoles);

  return (
    <div className={className}>
      <SectionHeader text={`${isSSEUser ? 'Neos Networks team profile' : 'Profile'}`} />
      <form onSubmit={onSubmit} id="user-save-form">
        <section className={`${triedToSave ? 'show-errors' : ''} pb-3`}>
          <div className="form-row">
            <Column defaultWidth={6}>
              <TextInput
                classNames={['col']}
                forwardedRef={companyEmailInput}
                required
                type="email"
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="companyEmail"
                onChange={noop}
                labelText="Username &amp; Email *"
                value={user.companyEmail}
                readOnly
              />
            </Column>
            <Column defaultWidth={6}>
              <TextInput
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="jobTitle"
                onChange={(e) => setUser(userWith('jobTitle', e.target.value))}
                labelText="Job title/position"
                optional={true}
                value={user.jobTitle}
              />
            </Column>
          </div>
          <div className="form-row">
            <Column defaultWidth={6}>
              <TextInput
                forwardedRef={firstNameInput}
                required
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="firstName"
                onChange={(e) => setUser(userWith('firstName', e.target.value))}
                labelText="First name *"
                value={user.firstName}
              />
            </Column>
            <Column defaultWidth={6}>
              <TextInput
                forwardedRef={lastNameInput}
                required
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="lastName"
                onChange={(e) => setUser(userWith('lastName', e.target.value))}
                labelText="Last name *"
                value={user.lastName}
              />
            </Column>
          </div>
          <div className="form-row">
            <Column defaultWidth={6}>
              <PhoneInput
                forwardedRef={mobilePhoneInput}
                required
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="mobilePhone"
                onChange={(e) => setUser(userWith('mobilePhone', e.target.value))}
                labelText="Mobile phone number *"
                value={user.mobilePhone}
              />
            </Column>
            <Column defaultWidth={6}>
              <PhoneInput
                forwardedRef={landlinePhoneInput}
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="landlinePhone"
                onChange={(e) => setUser(userWith('landlinePhone', e.target.value))}
                labelText="Landline phone number"
                optional={true}
                value={user.landlinePhone}
              />
            </Column>
          </div>
          <div className="form-row">
            <Column defaultWidth={6}>
              <TextInput
                forwardedRef={contactEmailInput}
                required
                type="email"
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="contactEmail"
                onChange={(e) => setUser(userWith('contactEmail', e.target.value))}
                labelText="Contact email address *"
                value={user.contactEmail}
              />
            </Column>
          </div>
        </section>

        <CompaniesAndRoles companyData={companyData} initialData={allRoles} isSSEUser={isSSEUser} readonly={true} />

        <Button
          id="user-save-button"
          type="submit"
          mainStyle={ButtonMainStyle.PrimaryRectangular}
          disabled={updateUserRequestState === RequestState.LOADING}
        >
          Save profile
        </Button>
        <div className="pt-3 update-user-btn">
          {updateUserRequestState === RequestState.SUCCESS ? (
            <Alert alertType={Alerts.SUCCESS}>Your profile was updated successfully</Alert>
          ) : updateUserRequestState === RequestState.ERROR ? (
            <Alert alertType={Alerts.WARNING}>Error: your profile may not have been updated</Alert>
          ) : (
            ''
          )}
        </div>
      </form>
    </div>
  );
};

const StyledEditDetails = styled(EditDetails)`
  .show-errors input:invalid {
    border-color: ${(props) => props.theme.colours.invalidFieldBorderColor ?? 'red'};
  }

  .column {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }

  #user-save-button {
    display: block;
    margin-right: 0;
    margin-left: auto;
  }
`;

const mapStateToProps = ({ user: { allRoles, companyData, updateUserRequestState, userData } }: IAppState) => ({
  allRoles,
  companyData,
  updateUserRequestState,
  userData,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  updateUser: (updateUserPayload: IUpdateUserPersonalDetailsPayload) =>
    dispatch(updateUserPersonalDetails(updateUserPayload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledEditDetails);
