import React, { FunctionComponent } from 'react';

import ReportsDownload from './ReportsDownload';
import AuthoriseForRoles from '../Auth/AuthoriseForRoles';
import { ordersReport } from './routes';
import OrdersReport from './ReportsDownload/OrdersReport';
import UserDataReport from './ReportsDownload/UserDataReport';
import QuotesReport from './ReportsDownload/QuotesReport';
import { userPermissions } from 'shared/utils/permissions';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

const ReportsRouter: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  return (
    <Routes>
      <Route
        element={
          <AuthoriseForRoles roles={userPermissions.reports}>
            <ReportsDownload>
              <Outlet />
            </ReportsDownload>
          </AuthoriseForRoles>
        }
      >
        <Route path="/orders" element={<OrdersReport />} />
        <Route path="/quotes" element={<QuotesReport />} />
        <Route path="/users" element={<UserDataReport />} />
        <Route path="*" element={<Navigate to={ordersReport} />} />
      </Route>
    </Routes>
  );
};

export default ReportsRouter;
