import {
  IGetWhoAmI,
  IGetWhoAmIError,
  IGetWhoAmIStarted,
  IGetWhoAmISuccess,
  IResetUpdateUserRequestState,
  ISetSelectedCompany,
  ISetUnreadCounts,
  ISetUserData,
  IUpdateUnreadCounts,
  IUpdateUserError,
  IUpdateUserPersonalDetails,
  IUpdateUserPersonalDetailsPayload,
  IUpdateUserStarted,
  IUpdateUserSuccess,
  UserActionTypes,
} from './types/actions';
import { IUserBackendProfile } from './types/backendUser';

export const getWhoAmI = (): IGetWhoAmI => ({
  type: UserActionTypes.GET_WHOAMI,
});

export const getWhoAmIStarted = (): IGetWhoAmIStarted => ({
  type: UserActionTypes.GET_WHOAMI_STARTED,
});

export const getWhoAmIError = (): IGetWhoAmIError => ({
  type: UserActionTypes.GET_WHOAMI_ERROR,
});

export const setSelectedCompany = (id: string): ISetSelectedCompany => ({
  payload: { id },
  type: UserActionTypes.SET_SELECTED_COMPANY,
});

export const setUserData = (payload: IUserBackendProfile): ISetUserData => ({
  payload,
  type: UserActionTypes.SET_USER_DATA,
});

export const setUnreadCounts = (payload: IUserBackendProfile): ISetUnreadCounts => ({
  payload,
  type: UserActionTypes.SET_UNREAD_COUNTS,
});

export const updateUnreadCounts = (): IUpdateUnreadCounts => ({
  type: UserActionTypes.UPDATE_UNREAD_COUNTS,
});

export const getWhoAmISuccess = (): IGetWhoAmISuccess => ({
  type: UserActionTypes.GET_WHOAMI_SUCCESS,
});

export const updateUserStarted = (): IUpdateUserStarted => ({
  type: UserActionTypes.UPDATE_USER_STARTED,
});

export const updateUserError = (): IUpdateUserError => ({
  type: UserActionTypes.UPDATE_USER_ERROR,
});

export const updateUserSuccess = (): IUpdateUserSuccess => ({
  type: UserActionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserPersonalDetails = (payload: IUpdateUserPersonalDetailsPayload): IUpdateUserPersonalDetails => ({
  payload,
  type: UserActionTypes.UPDATE_USER_PERSONAL_DETAILS,
});

export const resetUpdateUserRequestState = (): IResetUpdateUserRequestState => ({
  type: UserActionTypes.RESET_UPDATE_USER_REQUEST_STATE,
});
