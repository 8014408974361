import { useDispatch, useSelector } from 'react-redux';
import { setIsManagedDIA as setIsManagedDIAAction } from 'Quotes/actions';
import { IAppState } from 'reducers';
import { selectManagedDIAAvailable } from 'Quotes/selectors';
import React, { useEffect } from 'react';
import { BooleanOptionGroup } from 'shared/components/organisms/OptionGroup/utils/BooleanOptionGroup';

export const MDIASelection = () => {
  const dispatch = useDispatch();
  const setIsManagedDIA = (isManagedDia: boolean) => dispatch(setIsManagedDIAAction(isManagedDia));

  const isManagedDIA = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.is_managed_dia);
  const isManagedDIAAvailable = useSelector(selectManagedDIAAvailable);

  return (
    <MDIASelectionInternal
      isManagedDIAAvailable={isManagedDIAAvailable}
      isManagedDIA={isManagedDIA}
      setIsManagedDIA={setIsManagedDIA}
    />
  );
};

const MDIASelectionInternal = ({
  isManagedDIA,
  isManagedDIAAvailable,
  setIsManagedDIA,
}: {
  isManagedDIAAvailable: boolean;
  setIsManagedDIA: (value: boolean) => void;
  isManagedDIA: boolean;
}) => {
  useEffect(() => {
    if (isManagedDIA && !isManagedDIAAvailable) {
      setIsManagedDIA(false);
    }
  }, [isManagedDIAAvailable, isManagedDIA, setIsManagedDIA]);

  if (isManagedDIAAvailable)
    return (
      <>
        <div data-testid="mdia-confirm-section">
          <p className="mt-4 mb-2">Do you want managed DIA services?</p>
          <p className="text-muted mb-4">
            By selecting that you want managed DIA services, you will be provided with a managed router which would be
            provisioned, configured and maintained by Neos Networks. If you do not select this option, you will need to
            provide your own router to use this service.
          </p>

          <BooleanOptionGroup customIdBase="managed_dia" onClick={setIsManagedDIA} value={isManagedDIA} />
        </div>
      </>
    );
  else return null;
};
