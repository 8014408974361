import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Column from '../../../../../../shared/components/atoms/Column';
import Checkbox from '../../../../../../shared/components/atoms/Checkbox';
import { ProductType } from '../../../../../types/productTypes';

interface ISupplierSelectionCheckboxes {
  className?: string;
  label?: string;
  inputId?: string;
  disabled?: boolean;
  productType: ProductType;
  aEndValue?: boolean | null;
  bEndValue?: boolean | null;
  aEndDisabled?: boolean | null;
  bEndDisabled?: boolean | null;
  aEndOnChange(checked: boolean): void;
  bEndOnChange(checked: boolean): void;
}

const getLabel = (label: string | undefined): string => {
  return label ? label : '';
};

const SupplierSelectionCheckboxes: FunctionComponent<React.PropsWithChildren<ISupplierSelectionCheckboxes>> = ({
  className,
  label,
  productType,
  aEndValue,
  bEndValue,
  aEndDisabled,
  bEndDisabled,
  aEndOnChange,
  bEndOnChange,
}) => {
  const showAEnd = () =>
    productType === ProductType.P2P ||
    productType === ProductType.DIA ||
    productType === ProductType.P2CCT ||
    productType === ProductType.OpticalP2P;

  const showBEnd = () =>
    productType === ProductType.P2P || productType === ProductType.P2NNI || productType === ProductType.OpticalP2P;

  return (
    <div className={`${className} supplier-selection-checkboxes`}>
      <hr className={'supplier-selection-checkboxes__hr'} />
      <div className={`${className} supplier-selection-checkboxes__wrapper`}>
        <Column defaultWidth={5} classNames={['supplier-selection-checkboxes__label']}>
          {label}
        </Column>
        <Column defaultWidth={1} />

        {showAEnd() && (
          <Column defaultWidth={3} classNames={['supplier-selection-checkboxes__aEnd-checkbox']}>
            <Checkbox
              inputId={`aEnd-supplier-checkbox__${getLabel(label)}`}
              className="aEnd-supplier-checkbox"
              onChange={() => aEndOnChange(!aEndValue)}
              value={aEndValue || false}
              disabled={aEndDisabled || false}
            />
          </Column>
        )}

        {showBEnd() && (
          <Column defaultWidth={2} classNames={['supplier-selection-checkboxes__bEnd-checkbox']}>
            <Checkbox
              inputId={`bEnd-supplier-checkbox__${getLabel(label)}`}
              className="bEnd-supplier-checkbox"
              onChange={() => bEndOnChange(!bEndValue)}
              value={bEndValue || false}
              disabled={bEndDisabled || false}
            />
          </Column>
        )}
      </div>
    </div>
  );
};

const StyledSupplierSelectionCheckboxes = styled(SupplierSelectionCheckboxes)`
  .supplier-selection-checkboxes__wrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  .supplier-selection-checkboxes__label {
    align-content: center;
    font-size: 0.8em;
  }

  .supplier-selection-checkboxes__aEnd-checkbox {
    min-width: 0;
    align-content: center;
  }

  .supplier-selection-checkboxes__bEnd-checkbox {
    min-width: 0;
    align-content: center;
  }

  .supplier-selection-checkboxes__hr {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .checkbox input {
    position: absolute;
    opacity: 0;
  }

  .checkbox .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid;
    border-color: ${(props) => props.theme.colours.primaryB1};
    transition: all ${(props) => props.theme.defaultTransition};
    cursor: pointer;
  }

  .checkbox .icon {
    color: ${(props) => props.theme.colours.primaryB1};
    display: none;
  }

  .checkbox input:checked ~ .overlay {
    border-radius: 4px;
    opacity: 1;
  }

  .checkbox input:disabled ~ .overlay {
    background-color: #eee;
    border-color: #bbb;
  }

  .checkbox input:checked ~ .overlay .icon {
    display: block;
  }

  .checkbox input:checked ~ .checkbox__label {
    color: ${(props) => props.theme.colours.primaryB1};
  }
`;

export default StyledSupplierSelectionCheckboxes;
