import { compact } from 'lodash';
import { doRequest, Methods } from 'Request';
import IOrderRecord, { IOrderDataAttributes } from 'Order/types/orderRecord';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { AddressType, IAEndLocation, ILocation, IPriceData, IQuote, QuoteOrigin } from 'Quotes/types/store';
import { isOnNetSite } from 'shared/types/onNetSite';
import { IPAFAddress } from 'shared/types/postcodeResults';
import { ProductType } from 'Quotes/types/productTypes';
import { isSupplierNNAT } from 'Quotes/utils/isPriceNNAT';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';

const getAEndAttributes = (aEnd: IAEndLocation, productType: ProductType, origin: QuoteOrigin, isNNAT: boolean) => {
  const aEndAttributes: Partial<IOrderDataAttributes> = {};

  if (productType === ProductType.P2NNI || productType === ProductType.NNI2CCT) {
    aEndAttributes.a_end_vlan_tagging = 'YES';
    aEndAttributes.a_end_access_required_prior_notice = 'NA';
    aEndAttributes.a_end_any_hazard = 'NA';
    aEndAttributes.a_end_more_than_one_tenant = 'NA';
    aEndAttributes.a_end_connector_type = 'NA';
    aEndAttributes.a_end_power = 'NA';
    aEndAttributes.a_end_auto_negotiation = 'NA';
  }
  getAEndFullSiteAddressType(aEndAttributes, productType, isNNAT, aEnd);

  if (aEnd.openreachAddress !== null && origin === QuoteOrigin.BULK) {
    const openreachAddress = aEnd.openreachAddress.attributes;

    aEndAttributes.a_end_full_site_address_type = AddressType.OPENREACH;
    aEndAttributes.a_end_full_site_postal_code = aEnd.draftPostcode?.toUpperCase();
    aEndAttributes.a_end_full_site_alk = openreachAddress.alk;
    aEndAttributes.a_end_full_site_match_type = openreachAddress.qualifier;
    aEndAttributes.a_end_full_site_building_name = openreachAddress.building_name;
    aEndAttributes.a_end_full_site_building_number = openreachAddress.building_number;
    aEndAttributes.a_end_full_site_county = openreachAddress.county;
    aEndAttributes.a_end_full_site_town = openreachAddress.post_town;
    aEndAttributes.a_end_full_site_street = openreachAddress.street;
    aEndAttributes.a_end_full_site_sub_building = openreachAddress.sub_building;
  } else if (aEnd.fullAddress !== null) {
    if (!isOnNetSite(aEnd.fullAddress)) {
      const fullAddress = aEnd.fullAddress.attributes;
      aEndAttributes.a_end_full_site_postal_code = fullAddress.postcode?.toUpperCase() || '';

      if (aEnd.addressType === AddressType.OPENREACH && aEnd.openreachAddress !== null) {
        const openreachAddress = aEnd.openreachAddress.attributes;

        aEndAttributes.a_end_full_site_alk = openreachAddress.alk;
        aEndAttributes.a_end_full_site_match_type = openreachAddress.qualifier;
        aEndAttributes.a_end_full_site_building_name = openreachAddress.building_name;
        aEndAttributes.a_end_full_site_building_number = openreachAddress.building_number;
        aEndAttributes.a_end_full_site_county = openreachAddress.county;
        aEndAttributes.a_end_full_site_town = openreachAddress.post_town;
        aEndAttributes.a_end_full_site_street = openreachAddress.street;
        aEndAttributes.a_end_full_site_sub_building = openreachAddress.sub_building;
      }
    } else {
      aEndAttributes.a_end_full_site_postal_code = aEnd.postcode?.toUpperCase() || '';
      aEndAttributes.a_end_full_site_building_name = aEnd.fullAddress.attributes.address_1 || undefined;
      aEndAttributes.a_end_full_site_street = aEnd.fullAddress.attributes.address_2 || undefined;
      aEndAttributes.a_end_full_site_town = aEnd.fullAddress.attributes.town || undefined;
      aEndAttributes.a_end_full_site_county = aEnd.fullAddress.attributes.county || undefined;
      aEndAttributes.a_end_full_site_address =
        aEnd.fullAddress.attributes.location ||
        compact([
          aEnd.fullAddress.attributes.address_1,
          aEnd.fullAddress.attributes.address_2,
          aEnd.fullAddress.attributes.town,
          aEnd.fullAddress.attributes.county,
        ]).join(', ');
    }
  }

  return aEndAttributes;
};

function getAEndFullSiteAddressType(
  aEndAttributes: Partial<IOrderDataAttributes>,
  productType: ProductType,
  isNNAT: boolean,
  aEnd: IAEndLocation
) {
  if (productType === ProductType.P2NNI || !isNNAT) {
    aEndAttributes.a_end_full_site_address_type = aEnd.addressType || AddressType.PAF;
  } else {
    aEndAttributes.a_end_full_site_address_type = getNNATAddressType();
  }
}

function getNNATAddressType() {
  return featureFlag.isEnabled(Feature.nnatOrderable) ? AddressType.PAF : AddressType.ONAT;
}

function getBEndFullSiteAddressType(isNNAT: boolean, productType: ProductType, bEnd: ILocation) {
  if (isNNAT && productType === ProductType.DIA) {
    return null;
  } else if (isNNAT) {
    return getNNATAddressType();
  } else {
    return bEnd.addressType || AddressType.PAF;
  }
}

const getBEndAttributes = (bEnd: ILocation, productType: ProductType, origin: QuoteOrigin, isNNAT: boolean) => {
  const bEndAttributes: Partial<IOrderDataAttributes> = {};

  bEndAttributes.b_end_full_site_address_type = getBEndFullSiteAddressType(isNNAT, productType, bEnd);

  if (bEnd.openreachAddress !== null && origin === QuoteOrigin.BULK) {
    const openreachAddress = bEnd.openreachAddress.attributes;

    bEndAttributes.b_end_full_site_address_type = AddressType.OPENREACH;
    bEndAttributes.b_end_full_site_postal_code = bEnd.draftPostcode?.toUpperCase();
    bEndAttributes.b_end_full_site_alk = openreachAddress.alk;
    bEndAttributes.b_end_full_site_match_type = openreachAddress.qualifier;
    bEndAttributes.b_end_full_site_building_name = openreachAddress.building_name;
    bEndAttributes.b_end_full_site_building_number = openreachAddress.building_number;
    bEndAttributes.b_end_full_site_county = openreachAddress.county;
    bEndAttributes.b_end_full_site_town = openreachAddress.post_town;
    bEndAttributes.b_end_full_site_street = openreachAddress.street;
    bEndAttributes.b_end_full_site_sub_building = openreachAddress.sub_building;
  } else if (bEnd.fullAddress !== null) {
    if (!isOnNetSite(bEnd.fullAddress)) {
      const fullAddress = bEnd.fullAddress.attributes;
      bEndAttributes.b_end_full_site_postal_code = fullAddress.postcode?.toUpperCase() || '';

      if (bEnd.addressType === AddressType.OPENREACH && bEnd.openreachAddress !== null) {
        const openreachAddress = bEnd.openreachAddress.attributes;

        bEndAttributes.b_end_full_site_alk = openreachAddress.alk;
        bEndAttributes.b_end_full_site_match_type = openreachAddress.qualifier;
        bEndAttributes.b_end_full_site_building_name = openreachAddress.building_name;
        bEndAttributes.b_end_full_site_building_number = openreachAddress.building_number;
        bEndAttributes.b_end_full_site_county = openreachAddress.county;
        bEndAttributes.b_end_full_site_town = openreachAddress.post_town;
        bEndAttributes.b_end_full_site_street = openreachAddress.street;
        bEndAttributes.b_end_full_site_sub_building = openreachAddress.sub_building;
      }
    } else {
      bEndAttributes.b_end_full_site_postal_code = bEnd.postcode?.toUpperCase() || '';
      bEndAttributes.b_end_full_site_building_name = bEnd.fullAddress.attributes.address_1 || undefined;
      bEndAttributes.b_end_full_site_street = bEnd.fullAddress.attributes.address_2 || undefined;
      bEndAttributes.b_end_full_site_town = bEnd.fullAddress.attributes.town || undefined;
      bEndAttributes.b_end_full_site_county = bEnd.fullAddress.attributes.county || undefined;
      bEndAttributes.b_end_full_site_address =
        bEnd.fullAddress.attributes.location ||
        compact([
          bEnd.fullAddress.attributes.address_1,
          bEnd.fullAddress.attributes.address_2,
          bEnd.fullAddress.attributes.town,
          bEnd.fullAddress.attributes.county,
        ]).join(', ');
    }
  }

  return bEndAttributes;
};

const getMeta = (quote: IQuote) => {
  const { aEnd, bEnd } = quote.location;

  const metaFields: Partial<IOrderDataAttributes['meta']> = {};

  if (aEnd.fullAddress !== null && aEnd.addressType === AddressType.ON_NET) {
    metaFields.a_end_on_net_location = aEnd.fullAddress.attributes.location;
    metaFields.a_end_on_net_reference = aEnd.fullAddress.attributes.reference;
    metaFields.a_end_on_net_name = aEnd.fullAddress.attributes.name;
  }
  if (bEnd.fullAddress !== null && bEnd.addressType === AddressType.ON_NET) {
    metaFields.b_end_on_net_location = bEnd.fullAddress.attributes.location;
    metaFields.b_end_on_net_reference = bEnd.fullAddress.attributes.reference;
    metaFields.b_end_on_net_name = bEnd.fullAddress.attributes.name;
  }
  if (aEnd.fullAddress !== null && aEnd.addressType !== AddressType.ON_NET) {
    const fullAddress = aEnd.fullAddress.attributes as IPAFAddress;
    metaFields.a_end_PAF_full_site_udprn = fullAddress.udprn || '';
    metaFields.a_end_PAF_organisation_name = fullAddress.organisation_name;
    metaFields.a_end_PAF_building_name = fullAddress.building_name;
    metaFields.a_end_PAF_building_number = fullAddress.building_number;
    metaFields.a_end_PAF_county = fullAddress.county;
    metaFields.a_end_PAF_post_town = fullAddress.post_town;
    metaFields.a_end_PAF_postcode = fullAddress.postcode;
    metaFields.a_end_PAF_thoroughfare = fullAddress.thoroughfare;
    metaFields.a_end_PAF_sub_building = fullAddress.sub_building;
  }

  if (bEnd.fullAddress !== null && bEnd.addressType !== AddressType.ON_NET) {
    const fullAddress = bEnd.fullAddress.attributes as IPAFAddress;
    metaFields.b_end_PAF_full_site_udprn = fullAddress.udprn || '';
    metaFields.b_end_PAF_organisation_name = fullAddress.organisation_name;
    metaFields.b_end_PAF_building_name = fullAddress.building_name;
    metaFields.b_end_PAF_building_number = fullAddress.building_number;
    metaFields.b_end_PAF_county = fullAddress.county;
    metaFields.b_end_PAF_post_town = fullAddress.post_town;
    metaFields.b_end_PAF_postcode = fullAddress.postcode;
    metaFields.b_end_PAF_thoroughfare = fullAddress.thoroughfare;
    metaFields.b_end_PAF_sub_building = fullAddress.sub_building;
  }

  return metaFields;
};

const createOrder = (
  quoteId: string,
  quote: IQuote,
  customerName: string,
  selectedPrice: IPriceData,
  meta?: IMeta,
  lqId?: string
) => {
  const aEnd = quote.location.aEnd;
  const bEnd = quote.location.bEnd;

  const body = {
    data: {
      attributes: {
        ...getAEndAttributes(aEnd, quote.productType!, quote.origin, isSupplierNNAT(selectedPrice.a_end_access_type)),
        ...getBEndAttributes(bEnd, quote.productType!, quote.origin, isSupplierNNAT(selectedPrice.b_end_access_type)),
        quote_lq_id: lqId,
        customer_name: customerName,
        meta: {
          ...getMeta(quote),
          a_end_PAF_address_not_listed:
            aEnd.addressType === AddressType.PAF || aEnd.addressType === null ? meta?.a_end_address_not_listed : false,
          b_end_PAF_address_not_listed:
            bEnd.addressType === AddressType.PAF || bEnd.addressType === null ? meta?.b_end_address_not_listed : false,
        },
      },
      type: 'order',
    },
  };

  return doRequest<IOrderRecord>({
    body,
    method: Methods.POST,
    path: `/quotes/${quoteId}/orders`,
  });
};

export const createOrderFromQuoteId = (quoteId: string) => {
  const body = {
    data: {
      attributes: {},
      type: 'order',
    },
  };

  return doRequest<IOrderRecord>({
    body,
    method: Methods.POST,
    path: `/quotes/${quoteId}/orders`,
  });
};

export default createOrder;
