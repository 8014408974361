import { ILocationGroup } from 'Quotes/types/store';
import { ProductType } from 'Quotes/types/productTypes';
import { getEndTypesForProduct } from 'shared/utils/getEndTypesForProduct';
import { PointType } from 'shared/types/pointType';

const buildAvailabilityProps = (location: ILocationGroup, productType: ProductType) => {
  const ends = getEndTypesForProduct(productType);
  const end = ends.aEnd === PointType.POINT ? location.aEnd : location.bEnd;

  return {
    galk: end.openreachAddress?.attributes.alk || '',
    district_code: end.openreachAddress?.attributes.css_district_code || '',
    postcode: end.postcode || '',
  };
};

export default buildAvailabilityProps;
