import { IOrder } from 'Order/types/store';
import { IPriceData, IQuote } from 'Quotes/types/store';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'reducers';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import { CardState } from 'shared/components/organisms/CardWithInitialText';
import LockableCard from 'shared/components/organisms/LockableCard';
import { useTheme } from 'styled-components';
import quoteHasShadowNNI from '../shared/utils/hasShadowNNI';
import { isOrderReady } from '../shared/utils/isOrderReady';

export interface ICardState {
  aEnd: CardState;
  bEnd: CardState;
  billingAndContact: CardState;
  cloudConnect: CardState;
}

type OrderReadyCard = {
  order?: IOrder;
  cardState: ICardState;
  className?: string;
  orderId: string;
  hasShadowNNI: boolean;
  quote: IQuote;
  selectedPrice: IPriceData;
};

const getURLForEnv = (orderId: string) => {
  return `${window.location.origin}/orders/${orderId}`;
};

export const orderNotCompleteText = 'Please complete the site and billing and contact information to place an order.';

interface MDIAText {
  className?: string;
}

export const MDIAText: FunctionComponent<React.PropsWithChildren<MDIAText>> = ({ className = '' }) => {
  return (
    <p className={className}>
      For further information about what to expect and how to prepare for the installation of your managed DIA services
      please{' '}
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://264183.fs1.hubspotusercontent-na1.net/hubfs/264183/Neos%20Networks%20Provisioning%20guide%20-%20Managed%20DIA.pdf"
      >
        click here
      </a>
      .
    </p>
  );
};

export const OrderReadyCard: FunctionComponent<React.PropsWithChildren<OrderReadyCard>> = ({
  className,
  order,
  cardState,
  orderId,
  quote,
  hasShadowNNI,
  selectedPrice,
}) => {
  const theme = useTheme();
  const isReady = isOrderReady(order!, hasShadowNNI, cardState, quote, selectedPrice);

  return (
    <div className={className}>
      <LockableCard title="Submit order for review" locked={!isReady}>
        {isReady ? (
          <>
            {quote.location.aEnd.is_managed_dia && <MDIAText />}
            <Alert alertType={Alerts.INFO}>
              Currently, you don&apos;t have permission to submit the order, copy the below link and send it to your
              approved purchaser and they can easily submit the order through {theme.content.productName.toUpperCase()}.
            </Alert>
            <p className="mb-1">
              <strong>Link to share:</strong>
            </p>
            <a className="share-link" href={getURLForEnv(orderId)}>
              {getURLForEnv(orderId)}
            </a>
          </>
        ) : (
          <>
            <p className="text-muted">{orderNotCompleteText}</p>
            {quote.location.aEnd.is_managed_dia && <MDIAText className="text-muted" />}
          </>
        )}
      </LockableCard>
    </div>
  );
};

const mapStateToProps = ({
  quoteBuilder: {
    quoteEndpointMeta,
    quote,
    pricing: { selectedPrice },
  },
}: IAppState) => ({
  hasShadowNNI: quoteHasShadowNNI(quoteEndpointMeta, quote.location.aEnd.nni),
  selectedPrice,
});

export default connect(mapStateToProps)(OrderReadyCard);
