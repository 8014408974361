import { IUserBackendProfile } from './backendUser';

export enum UserActionTypes {
  GET_WHOAMI = 'GET_WHOAMI',
  GET_WHOAMI_STARTED = 'GET_WHOAMI_STARTED',
  GET_WHOAMI_ERROR = 'GET_WHOAMI_ERROR',
  GET_WHOAMI_SUCCESS = 'GET_WHOAMI_SUCCESS',
  SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY',
  SET_USER_DATA = 'SET_USER_DATA',
  UPDATE_USER_STARTED = 'UPDATE_USER_STARTED',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',
  UPDATE_USER_PERSONAL_DETAILS = 'UPDATE_USER_PERSONAL_DETAILS',
  RESET_UPDATE_USER_REQUEST_STATE = 'RESET_UPDATE_USER_REQUEST_STATE',
  UPDATE_UNREAD_COUNTS = 'UPDATE_UNREAD_COUNTS',
  SET_UNREAD_COUNTS = 'SET_UNREAD_COUNTS',
}

export interface IUpdateUserPersonalDetailsPayload {
  contactEmail: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  landlinePhone: string;
  id: string;
}

export interface IGetWhoAmI {
  type: UserActionTypes.GET_WHOAMI;
}

export interface IGetWhoAmIError {
  type: UserActionTypes.GET_WHOAMI_ERROR;
}

export interface IGetWhoAmIStarted {
  type: UserActionTypes.GET_WHOAMI_STARTED;
}

export interface IGetWhoAmISuccess {
  type: UserActionTypes.GET_WHOAMI_SUCCESS;
}

export interface ISetSelectedCompany {
  payload: { id: string };
  type: UserActionTypes.SET_SELECTED_COMPANY;
}

export interface ISetUserData {
  type: UserActionTypes.SET_USER_DATA;
  payload: IUserBackendProfile;
}

export interface ISetUnreadCounts {
  type: UserActionTypes.SET_UNREAD_COUNTS;
  payload: IUserBackendProfile;
}

export interface IUpdateUnreadCounts {
  type: UserActionTypes.UPDATE_UNREAD_COUNTS;
}

export interface IUpdateUserStarted {
  type: UserActionTypes.UPDATE_USER_STARTED;
}

export interface IUpdateUserSuccess {
  type: UserActionTypes.UPDATE_USER_SUCCESS;
}

export interface IUpdateUserError {
  type: UserActionTypes.UPDATE_USER_ERROR;
}

export interface IUpdateUserPersonalDetails {
  type: UserActionTypes.UPDATE_USER_PERSONAL_DETAILS;
  payload: IUpdateUserPersonalDetailsPayload;
}

export interface IResetUpdateUserRequestState {
  type: UserActionTypes.RESET_UPDATE_USER_REQUEST_STATE;
}

export type UserActions =
  | IGetWhoAmI
  | IGetWhoAmIError
  | IGetWhoAmIStarted
  | IGetWhoAmISuccess
  | ISetSelectedCompany
  | ISetUserData
  | IUpdateUserStarted
  | IUpdateUserError
  | IUpdateUserSuccess
  | IUpdateUserPersonalDetails
  | IResetUpdateUserRequestState
  | ISetUnreadCounts;
