import { IPriceData, IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ISiteConfig } from 'Order/types/location';
import CardRow from 'shared/components/molecules/CardRow';
import Column from 'shared/components/atoms/Column';
import SiteConfigInformation from 'Order/OrderBuilder/Locations/shared/components/Edit/SiteConfigInformation/SiteConfig';
import React from 'react';
import { siteConfigProps } from 'Order/OrderBuilder/Locations/shared/components/Edit/index';

export function SingleCircuitSiteConfig(props: {
  quote: IQuote;
  endPort: BearerType;
  siteConfig: ISiteConfig;
  identifierPrefix: string;
  fttxQuote: boolean;
  supplierNNAT: boolean;
  updateSiteConfig: (siteConfig: any) => void;
  selectedPrice: IPriceData;
}) {
  return (
    <CardRow title="Site Config" {...siteConfigProps(props.quote.productType)}>
      <div className="row no-gutters mb-4">
        <Column smWidth={12} mdWidth={12} lgWidth={12} xlWidth={10}>
          <SiteConfigInformation
            quote={props.quote}
            endPort={props.endPort}
            siteConfig={props.siteConfig}
            identifierPrefix={props.identifierPrefix}
            isFTTXQuote={props.fttxQuote}
            isSupplierNNAT={props.supplierNNAT}
            updateSiteConfig={props.updateSiteConfig}
            selectedPrice={props.selectedPrice}
          />
        </Column>
      </div>
    </CardRow>
  );
}
