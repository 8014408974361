import React, { FunctionComponent } from 'react';
import EditableCard from '../../../shared/components/molecules/EditableCard';
import ReadOnlyUserDetails from './ReadOnlyUserDetails';
import styled from 'styled-components';
import { showFullName } from '../../../shared/utils/nameHelper';
import EditUserDetails from './EditUserDetails';
import HeaderWithChildrenWrapper from '../../../shared/components/molecules/HeaderWithChildrenWrapper';
import { ICurrentlyViewedUserAttributes } from '../../../User/types/user';

interface IUserProfile {
  className?: string;
  userData: ICurrentlyViewedUserAttributes;
}

export const UserProfile: FunctionComponent<React.PropsWithChildren<IUserProfile>> = ({ userData, className }) => {
  const closedCardContent = <ReadOnlyUserDetails userData={userData} />;
  const openCardContent = <EditUserDetails userData={userData} />;

  return (
    <HeaderWithChildrenWrapper className={className} header={showFullName(userData.firstName, userData.lastName)}>
      <EditableCard closedCardContent={closedCardContent} openCardContent={openCardContent} />
    </HeaderWithChildrenWrapper>
  );
};

const StyledUserProfile = styled(UserProfile)`
  .info-prompt {
    padding-bottom: 0.5em;
  }
`;

export default StyledUserProfile;
