import cx from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import Icon from '../../atoms/Icon';
import Column from '../../atoms/Column';

interface IProps {
  children?: ReactNode;
  className?: string;
  completed?: boolean;
  title: string;
}

const ReadOnlyCard: FunctionComponent<React.PropsWithChildren<IProps>> = ({
  children,
  className,
  completed = true,
  title,
}) => {
  return (
    <section className={className}>
      <div className="row no-gutters header">
        <Column defaultWidth={10}>
          <h4 className={cx({ completed })}>
            {completed ? (
              <Icon className="completed-icon primary align-middle" name="check_circle" size="xLarge" />
            ) : (
              <Icon className="grey20 align-middle" name="brightness_1" size="xLarge" />
            )}
            {title}
          </h4>
        </Column>
      </div>

      <div className="content">{children}</div>
    </section>
  );
};

const StyledReadOnlyCard = styled(ReadOnlyCard)`
  border-radius: 0.3em;
  box-shadow: 0.4em 0.4em 0.15em 0 rgba(0, 70, 135, 0.1);
  border: solid 0.05em ${(props) => props.theme.colours.grey10};
  background-color: #ffffff;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .header {
    border-bottom: 1px solid ${(props) => props.theme.colours.grey10};
    padding-bottom: 0.4em;
  }

  h4 {
    i {
      margin-right: 0.5em;
    }

    &.completed {
      color: ${(props) => props.theme.colours.primaryB1};
    }
  }

  .content {
    padding-top: 1.5em;
  }
`;

export default StyledReadOnlyCard;
