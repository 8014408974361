import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import Summary from '../../shared/components/Summary';
import { CardState } from 'shared/components/organisms/CardWithInitialText';
import {
  editOrderState,
  setBEndAddressAction,
  setBEndAddressNotListedAction,
  setBEndAddressTypeAction,
  setBEndCompanyNameAction,
  setBEndFullAddressNotListedAction,
  setBEndPAFAddressAction,
  setSiteCoords as setSiteCoordsAction,
  setSiteType as setSiteTypeAction,
  updateAddressFieldLocationB,
  updateOnNetAddress,
} from 'Order/actions';
import { IOpenReachAddress, IOpenReachAddressSite, IPAFAddressSite } from 'shared/types/postcodeResults';
import { IOrderLocation } from 'Order/types/location';
import Edit from '../../shared/components/Edit';
import { AddressType, IPriceData, IQuote } from 'Quotes/types/store';
import { IOrder, IOrderMetaForLocation } from 'Order/types/store';
import { IAppState } from 'reducers';
import { pagesWithType, trackPage } from 'shared/utils/trackPage';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import {
  isFTTXQuote as isFTTXQuoteSelector,
  selectSelectedPrice,
  showSiteTypeConfigForPoint as showSiteTypeConfigForPointSelector,
} from 'Quotes/selectors';
import { ISetSiteType, IUpdateOnNetAddress, SetSiteCoords } from 'Order/types/actions';
import { isSupplierNNAT } from 'Quotes/utils/isPriceNNAT';

interface IBEndLocationContainerProps {
  order: IOrder;
  quote: IQuote;
  quoteMeta: IMeta;
  cardState: CardState;
  isFTTXQuote: boolean;
  postcode: string;
  metaForBEnd: IOrderMetaForLocation;
  locationLetter: string;
  selectedPrice: IPriceData;
  showSiteTypeConfigForPoint: boolean;
  updateOrder(order: IOrder): void;
  saveBEndFullAddress(bEndAddress: IOpenReachAddressSite | null): void;
  saveBEndPAFAddress(aEndAddress: IPAFAddressSite | null): void;
  setBEndPAFAddressNotListed(notListed: boolean): void;
  setBEndFullAddressNotListed(notListed: boolean): void;
  saveOnNetAddressSelection(attributes: IUpdateOnNetAddress['payload']['attributes']): void;
  fieldOnChange(fieldName: keyof IOpenReachAddress, fieldValue: string): void;
  setBEndCompanyName(endCompanyName: string): void;
  setBEndAddressType(bEndAddressType: AddressType): void;
  setSiteType(siteType: ISetSiteType['payload']['siteType']): void;
  setSiteCoords(field: SetSiteCoords['payload']['field'], value: SetSiteCoords['payload']['value']): void;
}

export const BEndLocationContainer: FunctionComponent<React.PropsWithChildren<IBEndLocationContainerProps>> = ({
  order,
  quote,
  quoteMeta,
  order: {
    locationA: { siteContact },
    locationB,
    locationB: { locationData },
    productType,
  },
  cardState,
  fieldOnChange,
  postcode,
  updateOrder,
  saveOnNetAddressSelection,
  locationLetter,
  saveBEndFullAddress,
  saveBEndPAFAddress,
  setBEndPAFAddressNotListed,
  setBEndFullAddressNotListed,
  metaForBEnd,
  setBEndCompanyName,
  setBEndAddressType,
  setSiteType,
  isFTTXQuote,
  selectedPrice,
  showSiteTypeConfigForPoint,
  setSiteCoords,
}) => {
  const identifierPrefix = `${locationLetter}EndLocation`;

  useEffect(() => {
    if (productType && cardState === CardState.Edit) {
      trackPage(pagesWithType.orderBLocation(productType));
    }
  }, [productType, cardState]);

  if (cardState === CardState.Edit || cardState === CardState.Initial) {
    return (
      <Edit
        quote={quote}
        endPort={quote.bEndPort!}
        quoteMeta={quoteMeta}
        location={locationB}
        addressType={locationData.addressType}
        identifierPrefix={identifierPrefix}
        fieldOnChange={fieldOnChange}
        saveOnNetAddressSelection={saveOnNetAddressSelection}
        otherSiteContact={siteContact}
        postcode={postcode}
        saveFullAddress={saveBEndFullAddress}
        orderMeta={metaForBEnd}
        savePAFAddress={saveBEndPAFAddress}
        copyLocationASiteContactToLocationB={() => updateOrder({ ...order, locationB: { ...locationB, siteContact } })}
        setPAFAddressNotListed={setBEndPAFAddressNotListed}
        setFullAddressNotListed={setBEndFullAddressNotListed}
        updateLocation={(updated: IOrderLocation) => updateOrder({ ...order, locationB: updated })}
        setEndCompanyName={setBEndCompanyName}
        setSiteType={setSiteType}
        setAddressType={setBEndAddressType}
        isFTTXQuote={isFTTXQuote}
        isSupplierNNAT={isSupplierNNAT(selectedPrice.b_end_access_type)}
        showSiteTypeConfigForPoint={showSiteTypeConfigForPoint}
        selectedPrice={selectedPrice}
        setSiteCoords={setSiteCoords}
      />
    );
  }

  return (
    <Summary
      productType={quote.productType!}
      identifierPrefix={identifierPrefix}
      isFTTXQuote={isFTTXQuote}
      isNNAT={isSupplierNNAT(selectedPrice.b_end_access_type)}
      onatAddress={quote.location.bEnd.onatAddress}
      location={locationB}
      orderMeta={metaForBEnd}
      showSiteTypeConfigForPoint={showSiteTypeConfigForPoint}
      selectedPrice={selectedPrice}
      quote={quote}
    />
  );
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  updateOrder: (order: IOrder) => dispatch(editOrderState(order)),
  fieldOnChange: (fieldName: keyof IOpenReachAddress, fieldValue: string) =>
    dispatch(updateAddressFieldLocationB(fieldName, fieldValue)),
  saveOnNetAddressSelection: (attributes: IUpdateOnNetAddress['payload']['attributes']) =>
    dispatch(updateOnNetAddress('B', attributes)),
  saveBEndFullAddress: (bEndAddress: IOpenReachAddressSite | null) => dispatch(setBEndAddressAction(bEndAddress)),
  setBEndPAFAddressNotListed: (notListed: boolean) => dispatch(setBEndAddressNotListedAction(notListed)),
  setBEndFullAddressNotListed: (notListed: boolean) => dispatch(setBEndFullAddressNotListedAction(notListed)),
  saveBEndPAFAddress: (bEndAddress: IPAFAddressSite | null) => dispatch(setBEndPAFAddressAction(bEndAddress)),
  setBEndCompanyName: (endCompanyName: string) => dispatch(setBEndCompanyNameAction(endCompanyName)),
  setBEndAddressType: (bEndAddressType: AddressType) => dispatch(setBEndAddressTypeAction(bEndAddressType)),
  setSiteType: (siteType: ISetSiteType['payload']['siteType']) => dispatch(setSiteTypeAction('B', siteType)),
  setSiteCoords: (field: SetSiteCoords['payload']['field'], value: SetSiteCoords['payload']['value']) =>
    dispatch(setSiteCoordsAction('B', field, value)),
});

const mapStateToProps = (state: IAppState) => ({
  isFTTXQuote: isFTTXQuoteSelector(state),
  metaForBEnd: state.orderBuilder.order.orderMeta.bEnd,
  quoteMeta: state.quoteBuilder.quoteEndpointMeta,
  showSiteTypeConfigForPoint: showSiteTypeConfigForPointSelector('bEndLocation')(state),
  selectedPrice: selectSelectedPrice(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(BEndLocationContainer);
