import { IQuote } from 'Quotes/types/store';
import Column from 'shared/components/atoms/Column';
import { monthsToYears } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import React from 'react';

export function Term(props: { quote: IQuote }) {
  return (
    <>
      <Column defaultWidth={2}>
        <span className="font-weight-bold" data-testid="order-summary-term">
          {monthsToYears(props.quote!.contractTerm)}
        </span>
      </Column>
    </>
  );
}
