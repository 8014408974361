import { ProductType } from 'Quotes/types/productTypes';
import { doRequest, Methods } from '../../../Request';
import IQuoteRecordAttributesBase, { IMeta } from '../../types/quoteRecordAttributesBase';

type DeepPartial<T> = {
  [K in keyof T]?: DeepPartial<T[K]>;
};

const updateQuote = (quoteId: string, updateValues: DeepPartial<IQuoteRecordAttributesBase>, currentMeta: IMeta) => {
  const body: any = {
    data: {
      attributes: {
        ...updateValues,
        meta: {
          ...currentMeta,
          ...updateValues.meta,
        },
      },
      type: 'quote',
    },
  };

  if (body.data.attributes.product_type === ProductType.DIA) {
    delete body.data.attributes.b_end_port;
  }

  return doRequest({
    body,
    method: Methods.PATCH,
    path: `/quotes/${quoteId}`,
  });
};

export default updateQuote;
