import React, { FunctionComponent } from 'react';
import { components, MenuProps } from 'react-select';

import AddressNotListed from '.';
import { IDropdownOption } from '../../shared/components/molecules/Dropdown';

export const DEFAULT_PLACEHOLDER = 'Choose location';
export const ADDRESS_NOT_LISTED_PLACEHOLDER = "My address isn't listed";

interface IAddressNotListedMenu {
  addressNotListed: boolean;
  selectedPlaceholder?: string;
  deselectedPlaceholder?: string;
  checkboxPlaceholder?: string;
  onAddressNotListed(notListed: boolean): void;
  setPlaceholder(placeholder: string): void;
}

const addressNotListedMenu = ({
  addressNotListed,
  onAddressNotListed,
  setPlaceholder,
  checkboxPlaceholder,
  selectedPlaceholder,
  deselectedPlaceholder,
}: IAddressNotListedMenu) => {
  const Menu: FunctionComponent<React.PropsWithChildren<MenuProps<IDropdownOption>>> = (props) => {
    const onChange = () => {
      onAddressNotListed(!addressNotListed);
      setPlaceholder(
        addressNotListed
          ? deselectedPlaceholder || DEFAULT_PLACEHOLDER
          : selectedPlaceholder || ADDRESS_NOT_LISTED_PLACEHOLDER
      );
      props.clearValue();

      if (!addressNotListed && props.selectProps.inputId) {
        const input: HTMLElement | null = document.getElementById(props.selectProps.inputId);

        if (input) {
          input.blur();
        }
      }
    };

    return (
      <components.Menu {...props}>
        <>
          {props.children}

          <AddressNotListed
            placeholder={checkboxPlaceholder}
            addressNotListed={addressNotListed}
            onAddressNotListed={onChange}
          />
        </>
      </components.Menu>
    );
  };

  return Menu;
};

export default addressNotListedMenu;
