import { AuthActionTypes, ILogOutUser, ISetLogOutUser, ISetShowLogOutPrompt } from './types/actions';
import { History } from 'history';

import { CustomLocationState } from 'shared/hooks/useLocationWithState';

export const setShowLogOutPrompt = (showLogOutPrompt: boolean): ISetShowLogOutPrompt => ({
  type: AuthActionTypes.SET_SHOW_LOG_OUT_PROMPT,
  payload: { showLogOutPrompt },
});

export const setLogOutUser = (logOutUser: boolean): ISetLogOutUser => ({
  type: AuthActionTypes.SET_LOG_OUT_USER,
  payload: { logOutUser },
});

export const logOutUser = (history: History<CustomLocationState>, userActionedSignOut?: boolean): ILogOutUser => ({
  type: AuthActionTypes.LOG_OUT_USER,
  payload: { history, userActionedSignOut },
});
