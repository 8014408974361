import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
};

export const UnStyledContainer: FunctionComponent<React.PropsWithChildren<Props>> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export const Container = styled(UnStyledContainer)`
  background-color: ${(props) => props.theme.colours.mainBackgroundColor};
  padding: 2em;

  h2 {
    color: ${(props) => props.theme.colours.secondary};
  }

  ol {
    font-size: 1.1em;
  }

  ul.errors {
    padding-top: 1.5em;
  }

  .success {
    color: ${(props) => props.theme.colours.primaryB1};
    position: relative;
    padding-left: 50px;
    padding-top: 10px;
    margin-top: 2em;

    i {
      border: 2px solid ${(props) => props.theme.colours.primaryB1};
      border-radius: 50%;
      left: 0;
      top: 0;
      padding: 0.2em;
      position: absolute;
    }
  }
`;
