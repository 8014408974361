import { doRequest, Methods } from 'Request';
import { OrderUpdateFields } from 'Order/types/formFields';
import { INewNNIRequest, IOrderLocation, ISecondarySiteConfig } from 'Order/types/location';
import { IOrderMeta } from 'Order/types/store';
import { AddressType } from 'Quotes/types/store';
import { ProductType } from 'Quotes/types/productTypes';
import { transform } from 'lodash';
import { mapNewNNIRequestToAPI } from 'Order/types/mappers';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { toast } from 'react-toastify';
import { SecondaryIPInformation } from 'Order/types/orderRecord';

function toYesNo(val: boolean) {
  return val ? 'YES' : 'NO';
}

function hasNNI(productType: ProductType) {
  return productType === ProductType.NNI2CCT || productType === ProductType.P2NNI;
}

export const blacklist: Record<ProductType, string[]> = {
  [ProductType.DIA]: [
    'b_end_any_hazard',
    'b_end_asbestos_register',
    'b_end_full_site_address_type',
    'b_end_more_than_one_tenant',
    'b_end_power',
    'b_end_auto_negotiation',
    'b_end_vlan_tagging',
    'b_end_access_required_prior_notice',
  ],
  [ProductType.P2CCT]: [
    'b_end_more_than_one_tenant',
    'b_end_asbestos_register',
    'b_end_any_hazard',
    'b_end_full_site_address_type',
    'b_end_auto_negotiation',
    'b_end_vlan_tagging',
    'b_end_access_required_prior_notice',
    'b_end_power',
    'b_end_connector_type',
  ],
  [ProductType.NNI2CCT]: [
    'a_end_full_site_address_type',
    'a_end_auto_negotiation',
    'a_end_more_than_one_tenant',
    'a_end_asbestos_register',
    'a_end_power',
    'a_end_access_required_prior_notice',
    'a_end_connector_type',
    'b_end_more_than_one_tenant',
    'b_end_asbestos_register',
    'b_end_any_hazard',
    'b_end_full_site_address_type',
    'b_end_auto_negotiation',
    'b_end_vlan_tagging',
    'b_end_access_required_prior_notice',
    'b_end_power',
    'b_end_connector_type',
  ],
  [ProductType.P2NNI]: [
    'a_end_full_site_address_type',
    'a_end_auto_negotiation',
    'a_end_more_than_one_tenant',
    'a_end_asbestos_register',
    'a_end_power',
    'a_end_access_required_prior_notice',
    'a_end_connector_type',
  ],
  [ProductType.P2P]: [],
  [ProductType.OpticalP2P]: [],
};

export function withBlacklistNullify<T>(value: T, attribute: string, productType: ProductType) {
  if (blacklist[productType].includes(attribute)) {
    return null;
  }
  return value;
}

function aEndLocationFields(
  productType: ProductType,
  location: IOrderLocation,
  bEndLocation?: IOrderLocation,
  orderMeta?: IOrderMeta
) {
  const {
    endCompanyName,
    siteConfig,
    siteContact,
    siteReadiness,
    siteType,
    siteCoords,
    locationData: {
      fullAddress,
      pafAddress,
      pafAddressNotListed,
      fullAddressNotListed,
      readonly_postcode,
      addressType,
    },
  } = location;

  let bEndMeta = {};
  if (bEndLocation) {
    bEndMeta = {
      b_end_PAF_address_not_listed: bEndLocation.locationData.pafAddressNotListed,
      b_end_full_address_not_listed: bEndLocation.locationData.fullAddressNotListed,
      b_end_land_owner_permission_required: bEndLocation.siteReadiness.landOwnerPermissionRequired,
      b_end_PAF_full_site_udprn: bEndLocation.locationData.pafAddress.udprn,
      b_end_PAF_organisation_name: bEndLocation.locationData.pafAddress.organisation_name,
      b_end_PAF_building_name: bEndLocation.locationData.pafAddress.building_name,
      b_end_PAF_building_number: bEndLocation.locationData.pafAddress.building_number,
      b_end_PAF_county: bEndLocation.locationData.pafAddress.county,
      b_end_PAF_post_town: bEndLocation.locationData.pafAddress.post_town,
      b_end_PAF_postcode: bEndLocation.locationData.pafAddress.postcode,
      b_end_PAF_thoroughfare: bEndLocation.locationData.pafAddress.thoroughfare,
      b_end_PAF_sub_building: bEndLocation.locationData.pafAddress.sub_building,
      b_end_on_net_location: orderMeta?.bEnd.location || '',
      b_end_on_net_reference: orderMeta?.bEnd.reference || '',
      b_end_on_net_name: orderMeta?.bEnd.name || '',
    };
  }

  return transform(
    {
      a_end_full_site_address_type: fullAddressNotListed || pafAddressNotListed ? AddressType.MANUAL : addressType,
      a_end_full_site_building_name: fullAddress.building_name || '',
      a_end_full_site_building_number: fullAddress.building_number || '',
      a_end_full_site_sub_building: fullAddress.sub_building || '',
      a_end_full_site_alk: fullAddress.alk || '',
      a_end_full_site_match_type: fullAddress.qualifier || '',
      a_end_full_site_street: fullAddress.street || '',
      a_end_full_site_town: fullAddress.post_town || '',
      a_end_full_site_county: fullAddress.county || '',
      a_end_full_site_postal_code: readonly_postcode,
      a_end_site_notes: siteContact.notes,
      a_end_site_contact_first_name: siteContact.firstName,
      a_end_site_contact_surname: siteContact.surname,
      a_end_site_contact_telephone: siteContact.phone,
      a_end_site_contact_email: siteContact.email,
      a_end_access_required_prior_notice: siteReadiness.accessNotice,
      a_end_any_hazard: hasNNI(productType) ? 'NA' : toYesNo(siteReadiness.hazardsOnSite),
      a_end_hazard_description: siteReadiness.hazardsOnSiteDescription,
      a_end_asbestos_register: siteReadiness.asbestosRegister,
      a_end_more_than_one_tenant: toYesNo(siteReadiness.moreThanOneTenant),
      a_end_floor: siteConfig.floor,
      a_end_room: siteConfig.room,
      a_end_suite: siteConfig.suite,
      a_end_rack: siteConfig.rackId,
      a_end_media_type: siteConfig.mediaType,
      a_end_connector_type: siteConfig.connectorType,
      a_end_power: siteConfig.powerType,
      a_end_vlan_tagging: hasNNI(productType) ? 'YES' : toYesNo(siteConfig.vlanTagging),
      a_end_vlan_id: siteConfig.vlanId || null,
      a_end_auto_negotiation: toYesNo(siteConfig.autoNegotiation || false),
      shadow_vlan: toYesNo(siteConfig.shadowVLANTagging || false),
      shadow_vlan_n_n_i: siteConfig.shadowVLANId,
      a_end_company_name: endCompanyName,
      a_end_site_type: siteType || null,
      a_end_latitude: siteCoords.latitude || null,
      a_end_longitude: siteCoords.longitude || null,
      a_end_easting: siteCoords.easting || null,
      a_end_northing: siteCoords.northing || null,
      nni_job_reference: siteConfig.nni_job_reference || null,
      nni_idn_number: siteConfig.nni_idn_number || null,
      shadow_nni_job_reference: siteConfig.shadow_nni_job_reference || null,
      shadow_nni_idn_number: siteConfig.shadow_nni_idn_number || null,
      new_nni_requests: featureFlag.isEnabled(Feature.newNNIRequests)
        ? siteConfig.new_nni_requests.filter((it) => !it.forDeletion).map(mapNewNNIRequestToAPI)
        : [],
      meta: {
        ...bEndMeta,
        a_end_PAF_address_not_listed: pafAddressNotListed,
        a_end_full_address_not_listed: fullAddressNotListed,
        a_end_land_owner_permission_required: siteReadiness.landOwnerPermissionRequired,
        a_end_PAF_full_site_udprn: pafAddress.udprn,
        a_end_PAF_organisation_name: pafAddress.organisation_name,
        a_end_PAF_building_name: pafAddress.building_name,
        a_end_PAF_building_number: pafAddress.building_number,
        a_end_PAF_county: pafAddress.county,
        a_end_PAF_post_town: pafAddress.post_town,
        a_end_PAF_postcode: pafAddress.postcode,
        a_end_PAF_thoroughfare: pafAddress.thoroughfare,
        a_end_PAF_sub_building: pafAddress.sub_building,
        a_end_on_net_location: orderMeta?.aEnd.location || '',
        a_end_on_net_reference: orderMeta?.aEnd.reference || '',
        a_end_on_net_name: orderMeta?.aEnd.name || '',
      },
    },
    (result, value, key) => {
      result[key] = withBlacklistNullify(value, key, productType);
      return result;
    },
    {} as Record<string, any>
  );
}

function bEndLocationFields(
  productType: ProductType,
  location: IOrderLocation,
  aEndLocation?: IOrderLocation,
  orderMeta?: IOrderMeta
) {
  const {
    endCompanyName,
    siteConfig,
    siteContact,
    siteReadiness,
    siteType,
    siteCoords,
    locationData: {
      fullAddress,
      pafAddress,
      pafAddressNotListed,
      fullAddressNotListed,
      readonly_postcode,
      addressType,
    },
  } = location;

  let aEndMeta = {};

  if (aEndLocation) {
    aEndMeta = {
      a_end_PAF_address_not_listed: aEndLocation.locationData.pafAddressNotListed,
      a_end_full_address_not_listed: aEndLocation.locationData.fullAddressNotListed,
      a_end_land_owner_permission_required: aEndLocation.siteReadiness.landOwnerPermissionRequired,
      a_end_PAF_full_site_udprn: aEndLocation.locationData.pafAddress.udprn,
      a_end_PAF_organisation_name: aEndLocation.locationData.pafAddress.organisation_name,
      a_end_PAF_building_name: aEndLocation.locationData.pafAddress.building_name,
      a_end_PAF_building_number: aEndLocation.locationData.pafAddress.building_number,
      a_end_PAF_county: aEndLocation.locationData.pafAddress.county,
      a_end_PAF_post_town: aEndLocation.locationData.pafAddress.post_town,
      a_end_PAF_postcode: aEndLocation.locationData.pafAddress.postcode,
      a_end_PAF_thoroughfare: aEndLocation.locationData.pafAddress.thoroughfare,
      a_end_PAF_sub_building: aEndLocation.locationData.pafAddress.sub_building,
      a_end_on_net_location: orderMeta?.aEnd.location || '',
      a_end_on_net_reference: orderMeta?.aEnd.reference || '',
      a_end_on_net_name: orderMeta?.aEnd.name || '',
    };
  }

  return transform(
    {
      b_end_full_site_address_type: fullAddressNotListed || pafAddressNotListed ? AddressType.MANUAL : addressType,
      b_end_full_site_building_name: fullAddress.building_name || '',
      b_end_full_site_building_number: fullAddress.building_number || '',
      b_end_full_site_sub_building: fullAddress.sub_building || '',
      b_end_full_site_alk: fullAddress.alk || '',
      b_end_full_site_match_type: fullAddress.qualifier || '',
      b_end_full_site_street: fullAddress.street || '',
      b_end_full_site_town: fullAddress.post_town || '',
      b_end_full_site_county: fullAddress.county || '',
      b_end_full_site_postal_code: readonly_postcode,
      b_end_site_notes: siteContact.notes,
      b_end_site_contact_first_name: siteContact.firstName,
      b_end_site_contact_surname: siteContact.surname,
      b_end_site_contact_telephone: siteContact.phone,
      b_end_site_contact_email: siteContact.email,
      b_end_access_required_prior_notice: siteReadiness.accessNotice,
      b_end_any_hazard: toYesNo(siteReadiness.hazardsOnSite),
      b_end_hazard_description: siteReadiness.hazardsOnSiteDescription,
      b_end_asbestos_register: siteReadiness.asbestosRegister,
      b_end_more_than_one_tenant: toYesNo(siteReadiness.moreThanOneTenant),
      b_end_floor: siteConfig.floor,
      b_end_room: siteConfig.room,
      b_end_suite: siteConfig.suite,
      b_end_rack: siteConfig.rackId,
      b_end_media_type: siteConfig.mediaType,
      b_end_connector_type: siteConfig.connectorType,
      b_end_power: siteConfig.powerType,
      b_end_vlan_tagging: toYesNo(siteConfig.vlanTagging),
      b_end_vlan_id: siteConfig.vlanId || null,
      b_end_auto_negotiation: addressType === AddressType.ONAT ? null : toYesNo(siteConfig.autoNegotiation || false),
      b_end_company_name: endCompanyName,
      b_end_site_type: siteType || null,
      b_end_latitude: siteCoords.latitude || null,
      b_end_longitude: siteCoords.longitude || null,
      b_end_easting: siteCoords.easting || null,
      b_end_northing: siteCoords.northing || null,
      meta: {
        ...aEndMeta,
        b_end_PAF_address_not_listed: pafAddressNotListed,
        b_end_full_address_not_listed: fullAddressNotListed,
        b_end_land_owner_permission_required: siteReadiness.landOwnerPermissionRequired,
        b_end_PAF_full_site_udprn: pafAddress.udprn,
        b_end_PAF_organisation_name: pafAddress.organisation_name,
        b_end_PAF_building_name: pafAddress.building_name,
        b_end_PAF_building_number: pafAddress.building_number,
        b_end_PAF_county: pafAddress.county,
        b_end_PAF_post_town: pafAddress.post_town,
        b_end_PAF_postcode: pafAddress.postcode,
        b_end_PAF_thoroughfare: pafAddress.thoroughfare,
        b_end_on_net_location: orderMeta?.bEnd.location || '',
        b_end_on_net_reference: orderMeta?.bEnd.reference || '',
        b_end_on_net_name: orderMeta?.bEnd.name || '',
        b_end_PAF_sub_building: pafAddress.sub_building,
      },
    },
    (result, value, key) => {
      result[key] = withBlacklistNullify(value, key, productType);
      return result;
    },
    {} as Record<string, any>
  );
}

export interface APISecondarySiteConfig {
  pop_id: string;
  floor: string;
  room: string;
  suite: string;
  rack: string;
  connection_type: string;
  media_type: string;
  power: string;
  auto_negotiation: string | undefined;
  vlan_tagging: string;
  vlan_id: string;
  duplexity: string;
  shadow_vlan_tagging: string;
  shadow_vlan_id: string;
  nni_job_reference: string;
  nni_idn_number: string;
  shadow_nni_job_reference: string;
  shadow_nni_idn_number: string;
  secondary_circuit_id: string;
  end: 'a' | 'b';
}

export function convertSecondarySiteConfigToAPI(secondarySiteConfig: ISecondarySiteConfig): APISecondarySiteConfig {
  return {
    secondary_circuit_id: secondarySiteConfig.circuitId,
    end: secondarySiteConfig.end,
    pop_id: '',
    floor: secondarySiteConfig.floor ?? '',
    room: secondarySiteConfig.room ?? '',
    suite: secondarySiteConfig.suite ?? '',
    rack: secondarySiteConfig.rackId ?? '',
    connection_type: secondarySiteConfig.connectorType ?? '',
    media_type: secondarySiteConfig.mediaType ?? '',
    power: secondarySiteConfig.powerType ?? '',
    auto_negotiation: secondarySiteConfig.autoNegotiation ? 'YES' : 'NO',
    vlan_tagging: secondarySiteConfig.vlanTagging ? 'YES' : 'NO',
    vlan_id: secondarySiteConfig.vlanId ?? '',
    duplexity: secondarySiteConfig.duplexity ?? '',
    shadow_vlan_tagging: secondarySiteConfig.shadowVLANTagging ? 'YES' : 'NO',
    shadow_vlan_id: secondarySiteConfig.shadowVLANId ?? '',
    nni_job_reference: secondarySiteConfig.nni_job_reference ?? '',
    nni_idn_number: secondarySiteConfig.nni_idn_number ?? '',
    shadow_nni_job_reference: secondarySiteConfig.shadow_nni_job_reference ?? '',
    shadow_nni_idn_number: secondarySiteConfig.shadow_nni_idn_number ?? '',
  };
}

function toOrderBody(formFields: OrderUpdateFields, productType: ProductType): Record<string, unknown> {
  let data = {};

  if (formFields.billingContact) {
    const billingContact = formFields.billingContact;

    data = {
      ...data,
      customer_reference: billingContact.purchaseOrderRef,
    };
  }

  if (formFields.orderContact) {
    data = {
      ...data,
      contact_first_name: formFields.orderContact.firstName,
      contact_surname: formFields.orderContact.surname,
      contact_telephone: formFields.orderContact.phoneNumber,
      contact_email: formFields.orderContact.email,
    };
  }

  if (formFields.operationalContact) {
    data = {
      ...data,
      service_ops_first_name: formFields.operationalContact.firstName,
      service_ops_surname: formFields.operationalContact.surname,
      service_ops_telephone: formFields.operationalContact.phoneNumber,
      service_ops_email: formFields.operationalContact.email,
    };
  }

  if (formFields.ipContact) {
    data = {
      ...data,
      ip_contact_organisation: formFields.ipContact.organisation,
      ip_contact_first_name: formFields.ipContact.firstName,
      ip_contact_surname: formFields.ipContact.surname,
      ip_contact_address: formFields.ipContact.address,
      ip_contact_email: formFields.ipContact.email,
      ip_contact_nic_ripe_handle: formFields.ipContact.nic_ripe_handle,
      ip_contact_telephone: formFields.ipContact.telephone,
    };
  }

  if (formFields.secondaryIPDetails) {
    const mappedInformation: SecondaryIPInformation = {
      end: 'a',
      ip_text: formFields.secondaryIPDetails.ipText,
      ip_contact_nic_ripe_handle: formFields.secondaryIPDetails.nicRIPEHandle,
    };
    data = { ...data, secondary_circuits_ip_information: [mappedInformation] };
  }

  if (formFields.cloudConnect) {
    data = {
      ...data,
      cloud_connect_configuration: {
        keys: {
          primary: formFields.cloudConnect.serviceKey1,
          secondary: formFields.cloudConnect.serviceKey2,
        },
        microsoft_peering: formFields.cloudConnect.microsoftPeering,
        private_peering: formFields.cloudConnect.privatePeering,
      },
    };
  }

  data = {
    ...data,
    ip_text: formFields.ipText || '',
    wan_ip_in_addition: formFields.wan_ip_in_addition ?? null,
  };

  if (formFields.locationA) {
    data = {
      ...data,
      ...aEndLocationFields(productType, formFields.locationA, formFields.locationB, formFields.orderMeta),
    };
  }

  if (formFields.locationB) {
    data = {
      ...data,
      ...bEndLocationFields(productType, formFields.locationB, formFields.locationA, formFields.orderMeta),
    };
  }

  if (formFields.locationA?.secondarySiteConfig || formFields.locationB?.secondarySiteConfig) {
    const configs: APISecondarySiteConfig[] = [
      formFields.locationA?.secondarySiteConfig,
      formFields.locationB?.secondarySiteConfig,
    ]
      .filter((it) => !!it)
      .map((it) => it as ISecondarySiteConfig)
      .map(convertSecondarySiteConfigToAPI);
    data = { ...data, secondary_circuits_site_configs: configs };
  }

  data = {
    ...data,
    billing_frequency: formFields.billingFrequency,
  };

  return data;
}

const toDelete = (request: INewNNIRequest) => request.forDeletion;
const alreadyExists = (request: INewNNIRequest) => !!request.id;

const deleteRemovedNewNNIRequests = async (orderId: string, newNNIRequests: INewNNIRequest[] | undefined) => {
  const requests = newNNIRequests?.filter(toDelete).filter(alreadyExists) ?? [];
  // This needs to happen in sequence otherwise the backend will not delete all requests due to writing stale data
  for (const newNNIRequest of requests) {
    await deleteNewNNIRequest(orderId, newNNIRequest);
  }
};

const deleteNewNNIRequest = (orderId: string, newNNiRequest: INewNNIRequest): Promise<void> => {
  return doRequest({
    method: Methods.DELETE,
    path: `/orders/${orderId}/new_nni_requests/${newNNiRequest.id}`,
    onError: () => {
      toast.error('We ran into trouble clearing that new NNI request, please double-check the order.');
    },
  });
};

const updateOrder = async (orderId: string, data: OrderUpdateFields, productType: ProductType) => {
  await deleteRemovedNewNNIRequests(orderId, data.locationA?.siteConfig.new_nni_requests);

  return doRequest({
    body: {
      data: {
        attributes: toOrderBody(data, productType),
        type: 'order',
      },
    },
    method: Methods.PATCH,
    path: `/orders/${orderId}`,
  });
};

export default updateOrder;
