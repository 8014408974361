import React, { FunctionComponent, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import RadioButton from 'shared/components/molecules/RadioButton';
import { useDispatch } from 'react-redux';
import { setFTTPAggregationAction } from 'Quotes/actions';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';

interface IFTTPAggregationWarning {
  fttpAggregationSupported: boolean;
  fttpAggregation: boolean | null | undefined;
  readonly: boolean;
  secondaryCircuitsEnabled?: boolean;
}

export const OPENREACH_FTTP_AGGREGATION_TOOLTIP_TEXT =
  'On 1st January 2021 Openreach issued a new change notification entitled “price changes for leased line circuits being used to aggregate FTTP to multiple premises” (briefing GEN102/20)';
export const FTTP_AGGREGATION_UNSUPPORTED = 'A selected supplier does not support FTTP Aggregation.';
export const SECONDARY_CIRCUITS_ENABLED =
  'Coming soon! Please contact your account manager to proceed with diverse circuits with FTTP aggregation enabled.';

const FTTPAggregationWarning: FunctionComponent<IFTTPAggregationWarning> = ({
  fttpAggregationSupported,
  fttpAggregation,
  readonly,
  secondaryCircuitsEnabled = false,
}) => {
  const isEnabled = featureFlag.isEnabled(Feature.fttpAggregation);
  const hasFTTPValue = fttpAggregation === true || fttpAggregation === false;

  const dispatch = useDispatch();
  const unsetFTTPAggregation = useCallback(() => dispatch(setFTTPAggregationAction(null)), [dispatch]);

  useEffect(() => {
    const shouldResetFTTPAggregation = !fttpAggregationSupported && fttpAggregation;
    if (shouldResetFTTPAggregation) unsetFTTPAggregation();
  }, [fttpAggregationSupported, fttpAggregation, unsetFTTPAggregation]);

  if (!isEnabled && !hasFTTPValue) {
    return <></>;
  }

  return (
    <div className="pb-3">
      <p>
        This connection will be used for FTTP Aggregation
        <span className="pl-2">
          <Tooltip text={OPENREACH_FTTP_AGGREGATION_TOOLTIP_TEXT} />
        </span>
      </p>
      <YesRadioButton
        disabled={readonly || !isEnabled}
        fttpAggregationSupported={fttpAggregationSupported}
        onClick={() => dispatch(setFTTPAggregationAction(true))}
        fttpAggregation={fttpAggregation}
        secondaryCircuitsEnabled={secondaryCircuitsEnabled}
      />
      <NoRadioButton
        disabled={readonly || !isEnabled}
        onClick={() => dispatch(setFTTPAggregationAction(false))}
        fttpAggregation={fttpAggregation}
      />
    </div>
  );
};

const YesRadioButton = ({
  disabled,
  fttpAggregationSupported,
  fttpAggregation,
  onClick,
  secondaryCircuitsEnabled,
}: {
  disabled: boolean;
  fttpAggregationSupported: boolean;
  onClick: () => void;
  fttpAggregation: boolean | null | undefined;
  secondaryCircuitsEnabled: boolean;
}) => {
  const tooltipText = secondaryCircuitsEnabled ? SECONDARY_CIRCUITS_ENABLED : FTTP_AGGREGATION_UNSUPPORTED;
  return (
    <PriceSummaryRadioButton
      inline
      description="Yes"
      disabled={disabled || !fttpAggregationSupported}
      id="fttp-aggregation-yes"
      key="fttp-aggregation-yes"
      testId="fttp-aggregation-yes"
      onClick={onClick}
      tooltipText={(!fttpAggregationSupported && tooltipText) || undefined}
      status={fttpAggregation === true ? 'selected' : 'notSelected'}
    />
  );
};

const NoRadioButton = ({
  disabled,
  fttpAggregation,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
  fttpAggregation: boolean | null | undefined;
}) => (
  <PriceSummaryRadioButton
    inline
    description="No"
    disabled={disabled}
    id="fttp-aggregation-no"
    key="fttp-aggregation-no"
    testId="fttp-aggregation-no"
    onClick={onClick}
    status={fttpAggregation === false ? 'selected' : 'notSelected'}
  />
);

const PriceSummaryRadioButton = styled(RadioButton)`
  width: 100%;
  height: 53px;
  margin-bottom: 1em;
`;

export default FTTPAggregationWarning;
