import { generateIPLabel } from '../utils/generateIPLabel';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { DualCircuitRouterConfigurations } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';

export interface IPOption {
  id: IPType;
  label: () => string;
  subnet: number;
  visible: boolean;
}

export enum IPType {
  /** @deprecated */
  WAN = 'IP_WAN',

  /** @deprecated */
  STATIC_4 = 'IP_STATIC_4',

  /** @deprecated */
  STATIC_8 = 'IP_STATIC_8',

  /** @deprecated */
  STATIC_16 = 'IP_STATIC_16',

  /** This is the IP configuration for when the user selects `WAN only /31` as IP address allocation. */
  SUBNET_31 = 'SUBNET_31',
  SUBNET_30 = 'SUBNET_30',
  SUBNET_29 = 'SUBNET_29',
  SUBNET_28 = 'SUBNET_28',
  SUBNET_27 = 'SUBNET_27',
  SUBNET_26 = 'SUBNET_26',
}

/**
 * List of IP options.
 * Deprecated options from MDIA 1.2 are hidden.
 * New options require access to quote's `is_managed_dia` and `dia_ip_allocation`, so the label generation is done in the component.
 */
export const ipOptions: IPOption[] = [
  {
    id: IPType.WAN,
    label: generateIPLabel('WAN IP'),
    subnet: 31,
    visible: false,
  },
  {
    id: IPType.STATIC_4,
    label: generateIPLabel('4 static IPs'),
    subnet: 30,
    visible: false,
  },
  {
    id: IPType.STATIC_8,
    label: generateIPLabel('8 static IPs'),
    subnet: 29,
    visible: false,
  },
  {
    id: IPType.STATIC_16,
    label: generateIPLabel('16 static IPs'),
    subnet: 28,
    visible: false,
  },
  {
    id: IPType.SUBNET_31,
    label: generateIPLabel('WAN IP'),
    subnet: 31,
    visible: false,
  },
  {
    id: IPType.SUBNET_30,
    label: generateIPLabel('4 static IPs'),
    subnet: 30,
    visible: true,
  },
  {
    id: IPType.SUBNET_29,
    label: generateIPLabel('8 static IPs'),
    subnet: 29,
    visible: true,
  },
  {
    id: IPType.SUBNET_28,
    label: generateIPLabel('16 static IPs'),
    subnet: 28,
    visible: true,
  },
  {
    id: IPType.SUBNET_27,
    label: generateIPLabel('32 static IPs'),
    subnet: 27,
    visible: true,
  },
  {
    id: IPType.SUBNET_26,
    label: generateIPLabel('64 static IPs'),
    subnet: 26,
    visible: true,
  },
];

const staticIPOptions = [IPType.WAN, IPType.STATIC_4, IPType.STATIC_8, IPType.STATIC_16];
export const staticIPList = ipOptions.filter((it) => staticIPOptions.includes(it.id));
export const subnetList = ipOptions.filter((it) => !staticIPOptions.includes(it.id));

// This is for RO2 MDIA min IP Address Requirements
type mdiaMinIPAddressesProps = {
  bearer: BearerType;
  dualRouterConfig: DualCircuitRouterConfigurations;
  minIPAddress: number | undefined;
};

export const mdiaMinIPAddresses: mdiaMinIPAddressesProps[] = [
  {
    bearer: BearerType.SMALL,
    dualRouterConfig: DualCircuitRouterConfigurations.DUAL_INDEPENDENT,
    minIPAddress: getSubnetNumberByIPType(IPType.SUBNET_31),
  },
  {
    bearer: BearerType.SMALL,
    dualRouterConfig: DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_STANDBY,
    minIPAddress: getSubnetNumberByIPType(IPType.SUBNET_29),
  },
  {
    bearer: BearerType.SMALL,
    dualRouterConfig: DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_ACTIVE,
    minIPAddress: getSubnetNumberByIPType(IPType.SUBNET_28),
  },
  {
    bearer: BearerType.MEDIUM,
    dualRouterConfig: DualCircuitRouterConfigurations.DUAL_INDEPENDENT,
    minIPAddress: getSubnetNumberByIPType(IPType.SUBNET_31),
  },
  {
    bearer: BearerType.MEDIUM,
    dualRouterConfig: DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_STANDBY,
    minIPAddress: getSubnetNumberByIPType(IPType.SUBNET_29),
  },
  {
    bearer: BearerType.MEDIUM,
    dualRouterConfig: DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_ACTIVE,
    minIPAddress: getSubnetNumberByIPType(IPType.SUBNET_28),
  },
  {
    bearer: BearerType.LARGE,
    dualRouterConfig: DualCircuitRouterConfigurations.DUAL_INDEPENDENT,
    minIPAddress: getSubnetNumberByIPType(IPType.SUBNET_31),
  },
  {
    bearer: BearerType.LARGE,
    dualRouterConfig: DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_STANDBY,
    minIPAddress: getSubnetNumberByIPType(IPType.SUBNET_29),
  },
  {
    bearer: BearerType.LARGE,
    dualRouterConfig: DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_ACTIVE,
    minIPAddress: getSubnetNumberByIPType(IPType.SUBNET_28),
  },
];

export function getSubnetNumberByIPType(ipType: IPType): number | undefined {
  const option = ipOptions.find((option) => option.id === ipType);
  return option ? option.subnet : undefined;
}
export function getIPTypeBySubnetNumber(subnetNumber: number): IPType | undefined {
  const option = ipOptions.find((option) => option.subnet === subnetNumber && option.visible === true);
  return option ? option.id : undefined;
}

export function getLabelBySubnetNumber(subnetNumber: number): string | undefined {
  const option = ipOptions.find((option) => option.subnet === subnetNumber);
  return option ? option.label() : undefined;
}

export function getMinIPAddress(
  bearer?: BearerType,
  dualRouterConfig?: DualCircuitRouterConfigurations
): number | undefined {
  const searchResult = mdiaMinIPAddresses.find(
    (row) => row.bearer === bearer && row.dualRouterConfig === dualRouterConfig
  );

  return searchResult?.minIPAddress;
}
