import { doRequest } from '../../../Request';

interface IGetOrdersRequestParams {
  customer_id?: string | null;
  product_type?: string | null;
  start_date?: string | null | undefined;
  end_date?: string | null | undefined;
  state?: string | null;
  [propName: string]: string | null | undefined;
}

const atLeastOneParamIsDefined = (requestParams: IGetOrdersRequestParams) => {
  return Object.values(requestParams).some((e) => Boolean(e));
};

const buildPath = (requestParams: IGetOrdersRequestParams) => {
  const pathCore = '/orders/export';

  if (atLeastOneParamIsDefined(requestParams)) {
    const keyValuePairs = [];

    for (const key in requestParams) {
      if (Object.prototype.hasOwnProperty.call(requestParams, key) && !!requestParams[key]) {
        const pair = key + '=' + requestParams[key];
        keyValuePairs.push(pair);
      }
    }

    return pathCore + '?' + keyValuePairs.join('&');
  } else {
    return pathCore;
  }
};

const getOrdersReport = (requestParams: IGetOrdersRequestParams) => {
  const path = buildPath(requestParams);
  return doRequest({
    path: path,
  });
};

export default getOrdersReport;
