import { ContractTerm } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import { IPriceData } from 'Quotes/types/store';
import { Supplier } from 'Quotes/types/supplier';

export interface IDummyPrice {
  type: 'dummy';
  id: string;
  term_length_in_months?: ContractTerm | null;
  is_orderable?: boolean;
  bandwidth?: string;
  aEndSupplier: Supplier.NONE;
  bEndSupplier: Supplier.NONE;
  aEndPostcode: string | undefined;
  bEndPostcode: string | undefined;
  aEndAccessMethod: 'Optical';
  bEndAccessMethod: 'Optical';
  aEndDataCentreReference?: string | null;
  bEndDataCentreReference?: string | null;
  aEndDataCentreName?: string | null;
  bEndDataCentreName?: string | null;
}

export const isRealPrice = (price: IPriceData | IDummyPrice): price is IPriceData => !isDummyPrice(price);
export const realPrice = (price: IPriceData | IDummyPrice): IPriceData | undefined =>
  isDummyPrice(price) ? undefined : price;

export const isDummyPrice = (price: IPriceData | IDummyPrice): price is IDummyPrice =>
  'type' in price && price.type === 'dummy';
