import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from 'shared/components/atoms/Alert';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import { SortDirection, useTablePaging } from 'shared/hooks/useTablePaging';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import { selectSelectedCompanyId } from 'User/selectors';
import { CerillionQuotesTable } from './CerillionQuotesTable';

const ShowCerillionQuotes: FC<React.PropsWithChildren<unknown>> = () => {
  useTrackPage(pages.cerillionQuotes);
  const customer_id = useSelector(selectSelectedCompanyId);
  const paging = useTablePaging({
    baseUrl: '/cerillion_baskets',
    extraParams: {
      customer_id,
    },
    defaultSort: {
      key: 'created_at',
      direction: SortDirection.DESCENDING,
    },
  });

  useEffect(() => {
    if (paging.fetchAttempts >= 1 && !paging.isLoading) {
      paging.refresh();
    }
  }, [customer_id]);

  if (paging.isLoading && paging.fetchAttempts === 0) {
    return <SpinnerWithText text="Retrieving quotes" size="large" />;
  }

  if (!paging.isLoading && paging.error) {
    return <Alert>Error in fetching quotes</Alert>;
  }

  return <CerillionQuotesTable paging={paging} />;
};

export { ShowCerillionQuotes };
