import React, { createRef, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import Column from 'shared/components/atoms/Column';
import { signUserOut } from 'App/logout';
import { useDispatch } from 'react-redux';
import { useOutsideClick } from 'shared/utils/customHooks';

interface ISignOutPanelProps {
  className?: string;
  onClose: () => void;
}

const SignOutPanel: FC<ISignOutPanelProps> = ({ className, onClose }) => {
  const ref = createRef<HTMLDivElement>();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useOutsideClick(ref, onClose);

  return (
    <div ref={ref} className={`${className} sign-out-panel`}>
      <p className="mb-1">Before you go!</p>
      <p>Just a reminder that any unsaved work will be lost if you sign out before saving.</p>
      <div className="row">
        <Column defaultWidth={6}>
          <Button
            className="sign-out-panel__sign-out-btn"
            mainStyle={ButtonMainStyle.PrimaryRectangular}
            onClick={() => {
              onClose();
              signUserOut(dispatch, navigate, location);
            }}
          >
            Sign out
          </Button>
        </Column>
        <Column defaultWidth={6}>
          <Button onClick={onClose} className="sign-out-panel__close-btn" mainStyle={ButtonMainStyle.PrimaryOutline}>
            Close
          </Button>
        </Column>
      </div>
    </div>
  );
};

const StyledSignOutPanel = styled(SignOutPanel)`
  background: white;
  box-shadow: ${(props) => props.theme.colours.grey20} 0px 0px 8px 0px;
  border-radius: 4px;
  padding: 1em;
  position: absolute;
  width: 21em;
  right: 10px;
  top: 60px;
`;

export default StyledSignOutPanel;
