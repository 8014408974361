import { VLAN_ID_INTERNALLY_ASSIGNED } from 'Order/types/orderRecord';

export const vlanForDisplay = (vlanId: string | null): string | null => {
  if (vlanId === VLAN_ID_INTERNALLY_ASSIGNED) {
    return 'Neos Networks to assign VLAN reference';
  }
  return vlanId;
};

export const shadowVLANForDisplay = (shadowVLANId: string | null): string | null => {
  if (shadowVLANId === VLAN_ID_INTERNALLY_ASSIGNED) {
    return 'Neos Networks to assign shadow VLAN reference';
  }
  return shadowVLANId;
};
