import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectOrderAddress } from 'Order/selectors';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import {
  getSelectedSecondaryPriceFromPrice,
  useSecondaryCircuitsWithSelectedPrice,
} from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { ProductType } from 'Quotes/types/productTypes';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { MultiCircuitP2P } from 'Order/OrderBuilder/QuoteSummary/P2P/MultiCircuitP2P';
import { SingleCircuitP2P } from 'Order/OrderBuilder/QuoteSummary/P2P/SingleCircuitP2P';
import { IPriceData, IQuote } from 'Quotes/types/store';

interface IP2PProps {
  quote: IQuote;
  selectedPrice: IPriceData;
  isSSEUser?: boolean;
  bearer: BearerType | undefined;
  bandwidth: string;
  productType: ProductType;
}

export const P2P: FC<React.PropsWithChildren<IP2PProps>> = ({
  isSSEUser,
  bearer,
  bandwidth,
  productType,
  quote,
  selectedPrice,
}) => {
  const secondarySelectedPrice = getSelectedSecondaryPriceFromPrice(selectedPrice);
  const secondaryBandwidth = secondarySelectedPrice?.bandwidth;
  const secondaryCircuits = useSecondaryCircuitsWithSelectedPrice();

  const primaryAddresses = useSelector(selectOrderAddress);
  const primaryAddressAEnd = primaryAddresses.A;
  const primaryAddressBEnd = primaryAddresses.B;

  const isRO2 = featureFlag.isEnabled(Feature.SecondaryCircuits) && secondaryCircuits.isSupported();

  return (
    <>
      {isRO2 && secondarySelectedPrice && secondaryBandwidth ? (
        <MultiCircuitP2P
          selectedPrice={selectedPrice}
          sseUser={isSSEUser}
          primaryAddressAEnd={primaryAddressAEnd}
          quote={quote}
          primaryAddressBEnd={primaryAddressBEnd}
          bandwidth={bandwidth}
          bearer={bearer}
          productType={productType}
          secondarySelectedPrice={secondarySelectedPrice}
          secondaryBandwidth={secondaryBandwidth}
        />
      ) : (
        <SingleCircuitP2P
          selectedPrice={selectedPrice}
          sseUser={isSSEUser}
          primaryAddressAEnd={primaryAddressAEnd}
          quote={quote}
          primaryAddressBEnd={primaryAddressBEnd}
          bandwidth={bandwidth}
          bearer={bearer}
          productType={productType}
        />
      )}
    </>
  );
};
