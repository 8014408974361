import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { connect, DispatchProp } from 'react-redux';

import { CompanyData, IUserAttributesBaseFrontendRoles } from '../../types/user';
import { IUserRoleFromBackend } from '../../types/role';
import ViewPersonalInformation from '../../components/ViewPersonalInformation';
import UserStatus from './UserStatus';
import SectionHeader from '../../../shared/components/atoms/SectionHeader';
import { resetUpdateUserRequestState } from '../../actions';
import { IAppState } from '../../../reducers';
import CompaniesAndRoles from 'Admin/shared/components/CompaniesAndRoles/CompaniesAndRoles';
import { isSSEUserFromBackendRoles } from '../../../Admin/utils/companyRolesRows';

interface IUserProfileReadOnly {
  allRoles: IUserRoleFromBackend[];
  className?: string;
  companyData: CompanyData;
  userData: IUserAttributesBaseFrontendRoles;
  resetUpdateUser: () => void;
}

export const UserProfileReadOnly: FunctionComponent<React.PropsWithChildren<IUserProfileReadOnly>> = ({
  allRoles,
  className,
  companyData,
  userData,
  resetUpdateUser,
}) => {
  useEffect(() => {
    resetUpdateUser();
  }, [resetUpdateUser]);

  const isSSEUser = isSSEUserFromBackendRoles(allRoles);

  return (
    <div className={className}>
      <SectionHeader text={`${isSSEUser ? 'Neos Networks team profile' : 'Profile'}`} />
      <ViewPersonalInformation userData={userData} />
      <UserStatus lastSignIn={userData.lastLoggedIn} />
      <CompaniesAndRoles companyData={companyData} initialData={allRoles} isSSEUser={isSSEUser} readonly={true} />
    </div>
  );
};

const StyledUserProfileReadOnly = styled(UserProfileReadOnly)`
  .column {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
`;

const mapStateToProps = ({ user: { allRoles, companyData, userData } }: IAppState) => ({
  allRoles,
  companyData,
  userData,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  resetUpdateUser: () => dispatch(resetUpdateUserRequestState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledUserProfileReadOnly);
