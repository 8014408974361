import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import calendar from 'dayjs/plugin/calendar';

export const setupDates = () => {
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);
  dayjs.extend(duration);
  dayjs.extend(calendar);
};

export const toRelativeString = (date: dayjs.ConfigType) => dayjs(date).calendar(null, { sameElse: 'DD/MM/YYYY' });
