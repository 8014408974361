import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect, DispatchProp, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ProductType } from 'Quotes/types/productTypes';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { IAppState } from 'reducers';
import AllowEditingForRoles from 'Auth/AllowEditingForRoles';
import { Role } from 'User/types/role';
import Alert from 'shared/components/atoms/Alert';
import QuoteSummary from './QuoteSummary';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import { CardState } from 'shared/components/organisms/CardWithInitialText';
import ReadOnlyCard from 'shared/components/organisms/ReadOnlyCard';
import { getOrderQuoteAndPriceAction, resetOrderState as resetOrderStateAction } from 'Order/actions';
import { resetQuoteStateAction } from 'Quotes/actions';
import { IOrder, IOrderState } from 'Order/types/store';
import { RequestLifecycle } from 'shared/actions';
import AEnd from './Locations/AEnd';
import BillingAndContact from './BillingAndContact';
import SubmitOrder from './SubmitOrder';
import Submitted from './Submitted';
import SubmittedSSE from './SubmittedSSE';
import Placed from './Placed';
import BEnd from './Locations/BEnd';
import { isEmptyBillingAndContact } from './shared/utils/isEmptyBillingAndContact';
import { isEmptyLocation } from './shared/utils/isEmptyLocation';
import { isEmptyCloudConnect } from './shared/utils/isEmptyCloudConnect';
import { usePreviousState } from 'shared/utils/customHooks';
import { location, locationA, locationB } from 'Order/types/location';
import OrderReadyCard, { MDIAText } from './OrderReadyCard';
import { anyRolesMatch } from 'User/utils/roles';
import { ordersList } from 'Order/routes';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { pagesWithType, trackPage } from 'shared/utils/trackPage';
import CloudConnectConfig from './CloudConnectConfig';
import quoteHasShadowNNI from './shared/utils/hasShadowNNI';
import { OrderStatus } from 'Order/types/orderRecord';
import DraftOrder from './DraftOrder';
import TermsText from './Terms/components/TermsText';
import { userPermissions } from 'shared/utils/permissions';
import {
  isOrderCardLockedSelector,
  selectCanDeleteOrder,
  selectCanResendOrder,
  selectIsOrderONAT,
} from 'Order/selectors';
import Cancelled from './Cancelled';
import { OrderNotes } from './OrderNotes';
import { setSelectedCompany as setSelectedCompanyAction } from 'User/actions';
import SwitchCustomerModal from 'shared/components/organisms/SwitchCustomerModal';
import { showGeaCablelinkNotice } from './shared/utils/geaCablelink';
import { isFTTXQuote } from 'Quotes/utils/isFTTXQuote';
import { MarginAndCosts } from 'Order/OrderBuilder/QuoteSummary/MarginAndCosts/MarginAndCosts';
import PresentForRoles from 'Auth/PresentForRoles';
import cx from 'classnames';
import { selectIsAuthorised } from 'User/selectors';
import showOpenreachCPENotice from 'Quotes/utils/showOpenreachCPENotice';
import { selectSelectedPriceAnnualCost, selectSubjectToAdditionalOpenreachSurcharges } from 'Quotes/selectors';
import { DeleteOrder } from './DeleteOrder';
import { CRMResponse } from './CRMResponse';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { ResendOrder } from './ResendOrder';
import { DeliveryDetails } from './DeliveryDetails';
import { OrderMessages } from 'shared/components/organisms/Messages/Messages';
import { capitalize } from 'lodash';
import { AlignContentRight, FlexSpacedMd } from 'shared/cssUtils';
import { OrderTemplateDropdown } from './OrderTemplate/OrderTemplateDropdown';
import { IDropdownOption } from 'shared/components/molecules/Dropdown';
import getQueryParams from 'shared/utils/getQueryParams';
import OrderTemplateActionButtons from './OrderTemplate/OrderTemplateActionButtons/OrderTemplateActionButtons';
import isNNICompleted from 'Order/OrderBuilder/shared/utils/isNNICompleted';

interface IOrderBuilder {
  className?: string;
  selectedPrice: IPriceData;
  selectedPriceAnnualCost: ReturnType<typeof selectSelectedPriceAnnualCost>;
  order?: IOrder;
  quote?: IQuote;
  quoteMeta?: IMeta;
  hasShadowNNI: boolean;
  productType: ProductType;
  orderPageLoadState: RequestLifecycle;
  orderPageLoadError: IOrderState['orderPageLoadError'];
  quoteId?: string;
  bulkQuoteId?: string;
  selectedCompanyId: string;
  userRoles: Role[];
  ipType?: IPType;
  editMode?: boolean;
  allowMarginCostsView: boolean;
  subjectToAdditionalOpenreachSurcharges: boolean;
  canDeleteOrder: boolean;
  canResendOrder: ReturnType<typeof selectCanResendOrder>;
  getOrderQuoteAndPrice(orderId: string): void;
  resetQuoteState(): void;
  resetOrderState(): void;
  setSelectedCompany(id: string): void;
}

const getBillingAndContactCardState = (order: IOrder, cardsLocked: boolean) => {
  if (cardsLocked) {
    return CardState.LockedSummary;
  }
  return isEmptyBillingAndContact(order) ? CardState.Initial : CardState.Summary;
};

const getLocationCardState = (order: IOrder, loc: location, cardsLocked: boolean) => {
  if (cardsLocked) {
    return CardState.LockedSummary;
  }
  return isEmptyLocation(order!, loc) ? CardState.Initial : CardState.Summary;
};

const getCloudConnectCardState = (order: IOrder, cardsLocked: boolean) => {
  if (cardsLocked) {
    return CardState.LockedSummary;
  }

  return isEmptyCloudConnect(order!) ? CardState.Initial : CardState.Summary;
};

const getNNICardState = (order: IOrder, hasShadowNNI: boolean, cardsLocked: boolean) => {
  if (cardsLocked) {
    return CardState.LockedSummary;
  }

  return isNNICompleted(order, hasShadowNNI) ? CardState.Summary : CardState.Initial;
};

type Params = {
  orderId?: string;
};

const ONATOrderId = styled.span`
  color: ${(props) => props.theme.colours.primaryB1};
`;

const defaultErrorMessage = `Error retrieving order. Please try again later.
If the problem persists, please contact your Account Manager.`;

export const OrderBuilder: FunctionComponent<React.PropsWithChildren<IOrderBuilder>> = ({
  allowMarginCostsView,
  className,
  selectedPrice,
  order,
  quote,
  orderPageLoadState,
  orderPageLoadError,
  productType,
  quoteId,
  getOrderQuoteAndPrice,
  resetQuoteState,
  resetOrderState,
  selectedCompanyId,
  bulkQuoteId,
  userRoles,
  ipType,
  quoteMeta,
  editMode,
  subjectToAdditionalOpenreachSurcharges,
  canDeleteOrder,
  canResendOrder,
}) => {
  const { orderId } = useParams<Params>();
  const { redirectedAfterCreation } = getQueryParams();
  const cardsLocked = useSelector(isOrderCardLockedSelector);
  const showDataCapture = useSelector(selectIsOrderONAT) && !featureFlag.isEnabled(Feature.nnatOrderable);

  useEffect(() => {
    if (productType) {
      trackPage(pagesWithType.orderSummary(productType));
    }
  }, [productType]);

  const userCanPlaceOrder = (): boolean => {
    return anyRolesMatch(userPermissions.placeOrder, userRoles);
  };

  const userCanSubmitOrderForReview = () => {
    return anyRolesMatch(userPermissions.submitOrderForReview, userRoles);
  };

  const prevProductType = usePreviousState(productType);
  const [billingAndContactCardState, setBillingAndContactCardState] = useState<CardState>(CardState.Loading);
  const [aEndCardState, setAEndCardState] = useState<CardState>(CardState.Loading);
  const [bEndCardState, setBEndCardState] = useState<CardState>(CardState.Loading);
  const [cloudConnectCardState, setCloudConnectCardState] = useState<CardState>(CardState.Loading);

  const setAllCardsState = (cardState: CardState) => {
    setBillingAndContactCardState(cardState);
    // setAEndCardState(cardState);
    // setBEndCardState(cardState);
    // setCloudConnectCardState(cardState);
  };

  const handleOrderTemplateSelection = (selection: IDropdownOption | undefined) => {
    if (!selection) setAllCardsState(CardState.Initial);
    else setAllCardsState(CardState.Edit);
  };
  useEffect(() => {
    if (orderId) {
      getOrderQuoteAndPrice(orderId);
    }
  }, [orderId, getOrderQuoteAndPrice]);

  useEffect(() => {
    return () => {
      resetQuoteState();
      resetOrderState();
    };
  }, [resetOrderState, resetQuoteState]);

  useEffect(() => {
    if (!prevProductType && productType) {
      setBillingAndContactCardState(getBillingAndContactCardState(order!, cardsLocked));

      if (productType === ProductType.P2CCT || productType === ProductType.NNI2CCT) {
        setCloudConnectCardState(getCloudConnectCardState(order!, cardsLocked));
      }

      if (productType === ProductType.P2NNI || productType === ProductType.NNI2CCT) {
        setAEndCardState(getNNICardState(order!, quoteHasShadowNNI(quoteMeta!, quote!.location.aEnd.nni), cardsLocked));
      } else {
        setAEndCardState(getLocationCardState(order!, locationA, cardsLocked));
      }
      setBEndCardState(getLocationCardState(order!, locationB, cardsLocked));
    }
  }, [order, productType, prevProductType, quoteMeta, quote, cardsLocked]);

  if (orderPageLoadState === RequestLifecycle.Error || !orderId) {
    return (
      <div className="pt-3 px-3">
        <Alert>{orderPageLoadError || defaultErrorMessage}</Alert>
      </div>
    );
  } else if (orderPageLoadState === RequestLifecycle.Loading) {
    return <SpinnerWithText id="order-spinner" text="Loading order" size="large" />;
  }

  const cardState = {
    aEnd: aEndCardState,
    bEnd: bEndCardState,
    billingAndContact: billingAndContactCardState,
    cloudConnect: cloudConnectCardState,
  };

  return (
    <div
      className={cx(className, 'order-builder__wrapper', {
        'has-margin-costs-bar': allowMarginCostsView,
      })}
    >
      <header>
        <div className="container">
          <QuoteSummary
            quoteId={quoteId!}
            order={order!}
            bulkQuoteId={bulkQuoteId}
            bearer={quote!.bearer}
            bandwidth={quote!.bandwidth}
            productType={productType}
            quote={quote!}
            selectedPrice={selectedPrice}
          />
        </div>
      </header>

      <div className="container spaced-list">
        {featureFlag.isEnabled(Feature.orderStatusStepperUI) && <DeliveryDetails order={order!} />}

        <PresentForRoles roles={userPermissions.costs}>
          <MarginAndCosts
            connectionType={quote!.connectionType}
            ipType={ipType}
            productType={quote!.productType!}
            selectedPrice={selectedPrice}
            isManagedDIA={quote!.location.aEnd.is_managed_dia}
            diaIPAllocation={quote!.location.aEnd.dia_ip_allocation}
            displayFTTPAggregationCharge={!!quote!.fttpAggregation}
            quote={quote!}
          />
        </PresentForRoles>

        {featureFlag.isEnabled(Feature.orderTemplates) && quote && (
          <AlignContentRight>
            <OrderTemplateDropdown
              selectedOrderTemplateId={order?.selectedOrderTemplate?.id}
              customerId={quote.customerId}
              onChange={handleOrderTemplateSelection}
              autoFillFromDefaultTemplate={!!redirectedAfterCreation}
            />
          </AlignContentRight>
        )}

        <AllowEditingForRoles roles={userPermissions.editOrder}>
          {productType === ProductType.P2NNI ? (
            <>
              <BEnd cardState={bEndCardState} setCardState={setBEndCardState} />
              <AEnd cardState={aEndCardState} setCardState={setAEndCardState} />
            </>
          ) : (
            <>
              <AEnd cardState={aEndCardState} setCardState={setAEndCardState} />
              <BEnd cardState={bEndCardState} setCardState={setBEndCardState} />
            </>
          )}
          {(productType === ProductType.P2CCT || productType === ProductType.NNI2CCT) && (
            <CloudConnectConfig cardState={cloudConnectCardState} setCardState={setCloudConnectCardState} />
          )}
          <BillingAndContact cardState={billingAndContactCardState} setCardState={setBillingAndContactCardState} />
          {order!.state === OrderStatus.PLACED && (
            <ReadOnlyCard className="read-only-card" title="Order is placed">
              <Placed
                placedAt={order!.placedAt!}
                submittedAt={order!.submittedForReviewAt!}
                submittedBy={order!.submittedForReviewBy}
              />
              {quote?.location.aEnd.is_managed_dia && <MDIAText />}
              <TermsText
                productType={order!.productType!}
                showGeaCablelinkNotice={showGeaCablelinkNotice(selectedPrice, order!, quote!)}
                showOpenreachCPENotice={showOpenreachCPENotice(
                  quote?.connectionType,
                  isFTTXQuote(quote!),
                  quote!.aEndAccessMethod,
                  quote!.bEndAccessMethod,
                  selectedPrice.a_end_access_type,
                  selectedPrice.b_end_access_type
                )}
                showAdditionalOpenreachSurchargeNotice={subjectToAdditionalOpenreachSurcharges}
                orderState={order!.state}
              />
            </ReadOnlyCard>
          )}
          {order!.state === OrderStatus.CANCELLED && (
            <ReadOnlyCard className="read-only-card" title="Order is cancelled">
              <Cancelled order={order!} />
            </ReadOnlyCard>
          )}
          {order!.state === OrderStatus.IN_REVIEW && !userCanPlaceOrder() && (
            <ReadOnlyCard className="read-only-card" title="Order is being reviewed">
              <Submitted submittedAt={order!.submittedForReviewAt!} submittedBy={order!.submittedForReviewBy} />
              {quote?.location.aEnd.is_managed_dia && <MDIAText />}
              <TermsText
                productType={order!.productType!}
                showGeaCablelinkNotice={showGeaCablelinkNotice(selectedPrice, order!, quote!)}
                showOpenreachCPENotice={isFTTXQuote(quote!)}
                showAdditionalOpenreachSurchargeNotice={subjectToAdditionalOpenreachSurcharges}
                orderState={order!.state}
              />
            </ReadOnlyCard>
          )}
          {order!.state === OrderStatus.IN_REVIEW && userCanPlaceOrder() && (
            <ReadOnlyCard className="read-only-card" title="Review &amp; place order">
              <SubmittedSSE
                productType={order!.productType!}
                cardState={cardState}
                showGeaCablelinkNotice={showGeaCablelinkNotice(selectedPrice, order!, quote!)}
                showOpenreachCPENotice={isFTTXQuote(quote!)}
                showAdditionalOpenreachSurchargeNotice={subjectToAdditionalOpenreachSurcharges}
                isManagedDIA={quote?.location.aEnd.is_managed_dia}
              />
            </ReadOnlyCard>
          )}
          {order!.state === OrderStatus.DRAFT && <DraftOrder isManagedDIA={quote?.location.aEnd.is_managed_dia} />}
          {(order!.state === OrderStatus.READY || order!.state === OrderStatus.RETURNED) &&
            userCanSubmitOrderForReview() &&
            !showDataCapture && <SubmitOrder cardState={cardState} order={order!} />}
          {(order!.state === OrderStatus.READY || order!.state === OrderStatus.RETURNED) &&
            !userCanSubmitOrderForReview() &&
            !showDataCapture && (
              <OrderReadyCard
                className="order-ready-card"
                orderId={orderId}
                cardState={cardState}
                order={order}
                quote={quote!}
              />
            )}

          {showDataCapture && order!.state === OrderStatus.READY && (
            <ReadOnlyCard className="read-only-card" title="Next steps">
              <p>
                Please contact your Account Manager to progress this order quoting the id:
                <ONATOrderId className="font-weight-bold"> {capitalize(order!.shortId)}</ONATOrderId>.
              </p>
              <TermsText
                productType={productType}
                showGeaCablelinkNotice={false}
                showOpenreachCPENotice={false}
                showAdditionalOpenreachSurchargeNotice={false}
                isONAT={true}
              />
            </ReadOnlyCard>
          )}

          <SwitchCustomerModal selectedCompanyId={selectedCompanyId} path={ordersList} editMode={editMode} />
        </AllowEditingForRoles>

        {featureFlag.isEnabled(Feature.orderTemplates) && quote && order && (
          <AlignContentRight>
            <FlexSpacedMd>
              <OrderTemplateActionButtons customerId={quote.customerId} order={order} orderId={orderId} />
            </FlexSpacedMd>
          </AlignContentRight>
        )}

        <PresentForRoles roles={userPermissions.deleteOrder}>
          {canDeleteOrder && <DeleteOrder orderId={orderId} />}
        </PresentForRoles>

        {order && order.state === OrderStatus.PLACED && (
          <PresentForRoles roles={userPermissions.viewCRMOrderResponse}>
            <CRMResponse
              downstream_responses={order.downstream_responses}
              cerillion_basket_id={order.cerillion_basket_id}
            />
          </PresentForRoles>
        )}

        {canResendOrder && (
          <PresentForRoles roles={userPermissions.resendCRMOrder}>
            <ResendOrder orderId={orderId} />
          </PresentForRoles>
        )}

        {featureFlag.isEnabled(Feature.messaging) ? <OrderMessages /> : <OrderNotes />}
      </div>
    </div>
  );
};

const styledOrderBuilder = styled(OrderBuilder)<IOrderBuilder>`
  font-size: 1.1rem;

  .spaced-list {
    display: flex;
    flex-direction: column;
    row-gap: 1.7em;
    margin-top: -27px;
  }

  > header {
    background-color: ${(props) => props.theme.header.bgColor ?? props.theme.colours.secondaryE1};
    color: white;
    padding-top: 15px;
    padding-bottom: 1.6em;
  }

  &.has-margin-costs-bar > header {
    padding-bottom: 2.5em;
  }
`;

const mapStateToProps = (state: IAppState) => ({
  selectedPrice: state.quoteBuilder.pricing.selectedPrice,
  selectedPriceAnnualCost: selectSelectedPriceAnnualCost(state),
  bulkQuoteId: state.quoteBuilder.quote.bulkQuoteId,
  order: state.orderBuilder.order,
  orderId: state.orderBuilder.id,
  quote: state.quoteBuilder.quote,
  orderPageLoadState: state.orderBuilder.orderPageLoadState,
  orderPageLoadError: state.orderBuilder.orderPageLoadError,
  productType: state.quoteBuilder.quote.productType,
  quoteId: state.quoteBuilder.currentQuoteId,
  selectedCompanyId: state.user.companyData.selectedCompanyId!,
  userRoles: state.user.userData.roles,
  ipType: state.quoteBuilder.quote.location.aEnd.ip.selectedId,
  quoteMeta: state.quoteBuilder.quoteEndpointMeta,
  hasShadowNNI: quoteHasShadowNNI(state.quoteBuilder.quoteEndpointMeta, state.quoteBuilder.quote.location.aEnd.nni),
  editMode: state.orderBuilder.editMode,
  allowMarginCostsView: selectIsAuthorised(userPermissions.costs, state),
  subjectToAdditionalOpenreachSurcharges: selectSubjectToAdditionalOpenreachSurcharges(state),
  canDeleteOrder: selectCanDeleteOrder(state),
  canResendOrder: selectCanResendOrder(state),
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  getOrderQuoteAndPrice: (orderId: string) => dispatch(getOrderQuoteAndPriceAction(orderId)),
  resetQuoteState: () => dispatch(resetQuoteStateAction()),
  resetOrderState: () => dispatch(resetOrderStateAction()),
  setSelectedCompany: (id: string) => dispatch(setSelectedCompanyAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(styledOrderBuilder);
