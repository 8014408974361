import { ProductType } from '../../../../Quotes/types/productTypes';
import { IPType } from '../../../../Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import Column from '../../../../shared/components/atoms/Column';
import { Amount, Cost } from './Cost';
import { getIPTypeLabel } from '../../../../Quotes/QuoteBuilder/utils/ip';
import React from 'react';
import { IPriceData, IQuote } from '../../../../Quotes/types/store';
import {
  MDIA_ENGINEER_COST,
  MDIA_RACK_MOUNT_KIT_COST,
} from 'Quotes/QuoteBuilder/components/Price/components/TillReceiptInternal/TillReceiptInternal';

function getSelectedPriceData(selectedPrice: IPriceData) {
  const bandwidthCostInstall = selectedPrice.bandwidth_cost?.install ?? 0;
  const bandwidthCostAnnual = selectedPrice.bandwidth_cost?.annual ?? 0;
  const annualIpCharge = selectedPrice.annual_ip_charge ?? 0;
  const mdiaEngineerCost = selectedPrice.mdia_engineer_cost;
  const mdiaRackMountKitCost = selectedPrice.mdia_rack_mount_kit_cost;
  return {
    bandwidthCostInstall,
    bandwidthCostAnnual,
    annualIpCharge,
    mdiaEngineerCost,
    mdiaRackMountKitCost,
  };
}

function DIAConfiguration(props: {
  ipType: IPType | undefined;
  managedDIA: boolean;
  ipAllocation: any;
  amount: number;
}) {
  return (
    <div className="row no-gutters mt-3">
      <Column defaultWidth={2}>
        <span className="sub-heading">DIA Configuration</span>
      </Column>
      <Column defaultWidth={2}>
        <span>{getIPTypeLabel(props.ipType, props.managedDIA, props.ipAllocation)}</span>
      </Column>
      <Column defaultWidth={2} offsetDefaultWidth={2}>
        <span className="mr-2">Annual</span>
        <Amount amount={props.amount} className="annual-ip-cost" />
      </Column>
    </div>
  );
}

export function DiaCosts(props: {
  productType: ProductType;
  ipType: IPType | undefined;
  managedDia: boolean;
  diaIpAllocation: any;
  selectedPrice: IPriceData;
  quote?: IQuote;
  label?: string;
  mdiaAnnualCost: number;
  mdiaRouterCost: number;
  annualIPCharge: number;
}) {
  const options = props.quote?.location.aEnd.secondRouterOptions;
  const engineerInstallationRequired = options?.engineerInstallationRequired;
  const rackMountKitRequired = options?.rackMountKitRequired;
  const engineerCost = engineerInstallationRequired ? MDIA_ENGINEER_COST : 0;
  const rackMountKitCost = rackMountKitRequired ? MDIA_RACK_MOUNT_KIT_COST : 0;

  const { bandwidthCostInstall, bandwidthCostAnnual, mdiaEngineerCost, mdiaRackMountKitCost } = getSelectedPriceData(
    props.selectedPrice
  );

  return (
    <>
      {props.productType === ProductType.DIA && (
        <div className="row no-gutters mt-3">
          <Column defaultWidth={2}>
            <span className="sub-heading">Bandwidth</span>
          </Column>
          <Column defaultWidth={2} offsetDefaultWidth={2}>
            <span className="mr-2">Install</span>
            <Amount amount={bandwidthCostInstall} className="bandwidth-install" />
          </Column>
          <Column defaultWidth={2}>
            <span className="mr-2">Annual</span>
            <Amount amount={bandwidthCostAnnual} className="bandwidth-annual" />
          </Column>
        </div>
      )}

      {props.productType === ProductType.DIA && (
        <>
          <DIAConfiguration
            ipType={props.ipType}
            managedDIA={props.managedDia}
            ipAllocation={props.diaIpAllocation}
            amount={props.annualIPCharge}
          />
          {props.managedDia && (
            <>
              <div className="row no-gutters mt-1">
                <Column defaultWidth={2} offsetDefaultWidth={2}>
                  <span>Managed DIA services</span>
                </Column>
                <Column defaultWidth={2} offsetDefaultWidth={2}>
                  <span className="mr-2">Annual</span>
                  <Amount amount={props.mdiaAnnualCost} className="mdia-annual" />
                </Column>
              </div>
              <div className="row no-gutters mt-1">
                <Column defaultWidth={2} offsetDefaultWidth={2}>
                  <span>Managed DIA router</span>
                </Column>
                <Cost label="Install" cost={props.mdiaRouterCost} className="mdia-install" />
              </div>

              {mdiaEngineerCost && (
                <div className="row no-gutters mt-1">
                  <Column defaultWidth={2} offsetDefaultWidth={2}>
                    <span>Engineer installation</span>
                  </Column>
                  <Cost
                    label="Install"
                    cost={props.label === 'Secondary' && props.managedDia ? engineerCost : mdiaEngineerCost ?? 0}
                    className="mdia-engineer-install"
                  />
                </div>
              )}

              {mdiaRackMountKitCost && (
                <div className="row no-gutters mt-1">
                  <Column defaultWidth={2} offsetDefaultWidth={2}>
                    <span>Rack mount kit</span>
                  </Column>
                  <Cost
                    label="Install"
                    cost={
                      props.label === 'Secondary' && props.managedDia ? rackMountKitCost : mdiaRackMountKitCost ?? 0
                    }
                    className="mdia-rack-mount-install"
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
