import { CollapsableCard } from 'Order/OrderBuilder/shared/components/CollapsableCard';
import {
  getChargesFromSecondarySelectedPrice,
  getChargesFromSelectedPrice,
  getPrimaryIPCharge,
  getSecondaryIPCharge,
} from 'Quotes/QuoteBuilder/utils/costsUtilities';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Props } from './AEndCosts';
import { TcvHeader } from './TcvHeader';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { getSelectedSecondaryPriceFromPrice } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { CircuitCosts } from 'Order/OrderBuilder/QuoteSummary/MarginAndCosts/CircuitCosts';
import { combineCharges } from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/MultiCircuitTotals';

const MultiCircuitContainer = styled.div`
  background: ${(props) => props.theme.colours.secondaryC5};
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colours.secondaryD5};
  .internal-costs-header {
    font-size: 1.3rem;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    color: ${(props) => props.theme.colours.secondaryD1};
    margin-bottom: 0.5rem;
  }
`;

export const UnstyledMarginAndCosts: FunctionComponent<Props> = ({
  className,
  connectionType,
  ipType,
  productType,
  selectedPrice,
  isManagedDIA,
  diaIPAllocation = null,
  displayFTTPAggregationCharge,
  quote,
}) => {
  const charges = getChargesFromSelectedPrice(selectedPrice, displayFTTPAggregationCharge);
  const secondarySelectedPrice = getSelectedSecondaryPriceFromPrice(selectedPrice);
  const secondaryCharges = secondarySelectedPrice
    ? getChargesFromSecondarySelectedPrice(secondarySelectedPrice, selectedPrice, quote, displayFTTPAggregationCharge)
    : undefined;

  const combinedCharges = secondaryCharges ? combineCharges(charges, secondaryCharges) : charges;

  const mdiaAnnualCost = selectedPrice.mdia_annual_cost ?? 0;
  const mdiaRouterCost = selectedPrice.mdia_router_cost;
  const mdiaInstallCost = selectedPrice.mdia_install_cost;
  const mdiaRouter = mdiaRouterCost ?? mdiaInstallCost ?? 0;

  const primaryIPCharge = getPrimaryIPCharge(quote);
  const secondaryIPCharge = getSecondaryIPCharge(quote);

  const primaryIPType = ipType;
  const secondaryIPType = quote.location.aEnd.secondIPChoice;

  return (
    <CollapsableCard
      className={`${className}`}
      collapseButtonLabel={'Costs'}
      showDivider={true}
      header={<TcvHeader charges={combinedCharges} />}
    >
      {secondarySelectedPrice && secondaryCharges && featureFlag.isEnabled(Feature.SecondaryCircuits) ? (
        <>
          <MultiCircuitContainer>
            <CircuitCosts
              label={'Primary'}
              productType={productType}
              connectionType={connectionType}
              selectedPrice={selectedPrice}
              displayFTTPAggregationCharge={displayFTTPAggregationCharge}
              ipType={primaryIPType}
              managedDia={isManagedDIA}
              diaIpAllocation={diaIPAllocation}
              mdiaAnnualCost={mdiaAnnualCost}
              mdiaRouterCost={mdiaRouter}
              annualIPCharge={primaryIPCharge}
            />
          </MultiCircuitContainer>
          <br />
          <MultiCircuitContainer>
            <CircuitCosts
              label={'Secondary'}
              productType={productType}
              connectionType={connectionType}
              selectedPrice={secondarySelectedPrice}
              displayFTTPAggregationCharge={displayFTTPAggregationCharge}
              ipType={secondaryIPType}
              managedDia={isManagedDIA}
              diaIpAllocation={diaIPAllocation}
              quote={quote}
              mdiaAnnualCost={mdiaAnnualCost}
              mdiaRouterCost={mdiaRouter}
              annualIPCharge={secondaryIPCharge}
            />
          </MultiCircuitContainer>
        </>
      ) : (
        <CircuitCosts
          productType={productType}
          connectionType={connectionType}
          selectedPrice={selectedPrice}
          displayFTTPAggregationCharge={displayFTTPAggregationCharge}
          ipType={primaryIPType}
          managedDia={isManagedDIA}
          diaIpAllocation={diaIPAllocation}
          mdiaAnnualCost={mdiaAnnualCost}
          mdiaRouterCost={mdiaRouter}
          annualIPCharge={primaryIPCharge}
        />
      )}
    </CollapsableCard>
  );
};

export const MarginAndCosts = styled(UnstyledMarginAndCosts)`
  .sub-heading {
    color: ${(props) => props.theme.colours.primaryB1};
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }
`;
