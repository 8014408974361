import { doRequest } from '../../../Request';
import { objectToGetParams } from '../../../shared/utils/objectToGetParams';

export interface IP2PAvailabilityProps {
  a_end_galk: string;
  a_end_district_code: string;
  a_end_postcode: string;
  b_end_galk: string;
  b_end_district_code: string;
  b_end_postcode: string;
}

const getAccessAvailabilityP2P = (body: IP2PAvailabilityProps) => {
  const { a_end_galk, a_end_district_code, a_end_postcode, b_end_galk, b_end_district_code, b_end_postcode } = body;
  return doRequest({
    path: `/availability/fttx/p2p?${objectToGetParams({
      a_end_galk,
      a_end_district_code,
      a_end_postcode,
      b_end_galk,
      b_end_district_code,
      b_end_postcode,
    })}`,
  });
};

export default getAccessAvailabilityP2P;
