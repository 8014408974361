export class PromiseBasedFileReader {
  reader: FileReader;

  constructor() {
    this.reader = new FileReader();
  }

  read(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      this.reader.onerror = () => {
        this.reader.abort();
        reject(new Error('Could not parse file'));
      };

      this.reader.onload = () => {
        resolve(this.reader.result as string);
      };

      this.reader.readAsText(file);
    });
  }

  readAsBinaryString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      this.reader.onerror = () => {
        this.reader.abort();
        reject(new Error('Could not parse file'));
      };

      this.reader.onload = () => {
        resolve(this.reader.result as string);
      };

      this.reader.readAsBinaryString(file);
    });
  }
}
