import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Alert from '../../shared/components/atoms/Alert';
import EmptyOrdersList from '../../shared/components/EmptyOrdersList';
import OrdersTable from './components/OrdersTable';
import HeaderWithChildrenWrapper from '../../shared/components/molecules/HeaderWithChildrenWrapper';
import { IAppState } from 'reducers';
import SpinnerWithText from '../../shared/components/molecules/SpinnerWithText';
import { Role } from 'User/types/role';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import { SortDirection, useTablePaging } from 'shared/hooks/useTablePaging';
import { anyRolesMatch } from 'User/utils/roles';
import { userPermissions } from 'shared/utils/permissions';
import { orderFilterTransform } from 'shared/utils/shared-quote-order-table-headers';
import { transformOrderDataToListItems } from 'shared/utils/transformOrderDataToListItems';
import { useNavigate } from 'react-router-dom';

export const ERROR_MESSAGE = 'Error in retrieving orders';

interface IOrdersList {
  className?: string;
  roles: Role[];
  selectedCompanyId: string;
}

const OrdersList: FunctionComponent<React.PropsWithChildren<IOrdersList>> = ({
  className,
  roles,
  selectedCompanyId,
}) => {
  useTrackPage(pages.orders);
  const paging = useTablePaging({
    baseUrl: `/customers/${selectedCompanyId}/orders`,
    dataTransform: transformOrderDataToListItems,
    filterTransform: orderFilterTransform,
    defaultSort: {
      key: 'updated_at',
      direction: SortDirection.DESCENDING,
    },
    extraParams: {
      include: 'quotes',
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!anyRolesMatch(userPermissions.viewOrder, roles)) {
      return navigate('/');
    }
  });

  let content;
  if (paging.fetchAttempts === 1 && !paging.isLoading && paging.error) {
    content = <Alert>{ERROR_MESSAGE}</Alert>;
  } else if (paging.isLoading && paging.fetchAttempts === 0) {
    content = <SpinnerWithText text="Retrieving orders" size="large" />;
  } else if (paging.fetchAttempts === 1 && !paging.isLoading && paging.results.length === 0) {
    content = <EmptyOrdersList />;
  } else {
    content = <OrdersTable paging={paging} />;
  }

  return (
    <HeaderWithChildrenWrapper header="Orders" compact={true}>
      <div className={className}>{content}</div>
    </HeaderWithChildrenWrapper>
  );
};

const styledComponent = styled(OrdersList)<IOrdersList>`
  height: 100%;
`;

const mapStateToProps = ({
  user: {
    companyData: { selectedCompanyId },
    userData: { roles },
  },
}: IAppState) => ({
  roles,
  selectedCompanyId: selectedCompanyId!,
});

export default connect(mapStateToProps)(styledComponent);
