import React, { createRef, FunctionComponent } from 'react';
import styled from 'styled-components';

import Button, { ButtonMainStyle } from '../../../../shared/components/atoms/Button';
import Column from '../../../../shared/components/atoms/Column';
import { useOutsideClick } from '../../../utils/customHooks';

type InfoPane = FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
    onClose(): void;
    theme: {
      colours: {
        grey10: string;
      };
      shadows: {
        boxShadow: string;
      };
    };
    title: string;
  }>
>;

const InfoPane: InfoPane = ({ children, className, onClose, title }) => {
  const ref = createRef<HTMLDivElement>();
  useOutsideClick(ref, onClose);

  return (
    <div className={`${className} d-flex`} ref={ref}>
      <div className="d-flex pane-wrapper">
        <div className="d-flex pane-wrapper__header">
          <Column defaultWidth={9}>
            <span className="pane-wrapper__title">{title}</span>
          </Column>
          <Column defaultWidth={3}>
            <Button onClick={onClose} mainStyle={ButtonMainStyle.Link}>
              Close
            </Button>
          </Column>
        </div>

        <div className="pane-wrapper__content">{children}</div>
      </div>
    </div>
  );
};

const Styled = styled(InfoPane)`
  background: white;
  border: solid 0.5px ${(props) => props.theme.colours.grey10};
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  width: 450px;
  max-height: 50vh;
  position: absolute;
  z-index: 1;

  .pane-wrapper {
    box-sizing: border-box;
    flex-flow: column;
    max-height: 100%;
    width: 100%;
  }

  .pane-wrapper__header {
    padding: 0.8em 0;
    border-bottom: solid 0.5px ${(props) => props.theme.colours.grey10};
  }

  .pane-wrapper__title {
    font-size: 1em;
  }

  .pane-wrapper__content {
    flex: 1;
    font-size: 0.95em;
    margin-bottom: 0;
    min-height: 25vh;
    overflow-y: auto;
    padding: 1.2em;
  }
`;

export { Styled as InfoPane };
