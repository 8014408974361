import { IOrderTemplate } from 'Order/types/orderTemplate';
import React, { useState } from 'react';
import { useModal } from 'shared/hooks/useModal';
import { OrderTemplateDeleteModal } from './OrderTemplateDeleteModal';
import styled from 'styled-components';
import classNames from 'classnames';
import Icon from 'shared/components/atoms/Icon';
import { ClearDefaultOrderTemplateButton } from 'Order/OrderBuilder/OrderTemplate/ClearDefaultOrderTemplateButton';
import { ShortSetDefaultOrderTemplateButton } from 'Order/OrderBuilder/OrderTemplate/SetDefaultOrderTemplateButton';
import _ from 'lodash';

interface OrderTemplateCardProps {
  orderTemplate: IOrderTemplate;
  customerId: string;
}

interface ShowMoreComponentProps {
  orderTemplate: IOrderTemplate;
  isVisible: boolean;
}

const templateDataCheck = (data: string | null | undefined) =>
  data ? <strong>{data}</strong> : <span>No Data Saved</span>;

const ShowMoreComponent: React.FC<ShowMoreComponentProps> = ({ orderTemplate, isVisible }) => {
  if (!isVisible) return null;
  const {
    orderContact,
    operationalContact,
    billingContactAndAddress,
    billingFrequency,
    ip,
  } = orderTemplate.attributes.templateBody;

  return (
    <ShowMoreContent>
      <h4>Billing & contact information</h4>

      <h5>Order Delivery contact</h5>
      <p>First name: {templateDataCheck(orderContact.firstName)}</p>
      <p>Surname: {templateDataCheck(orderContact.surname)}</p>
      <p>Mobile or landline phone number: {templateDataCheck(orderContact.phoneNumber)}</p>
      <p>Email address: {templateDataCheck(orderContact.email)}</p>

      <h5>Operational contact</h5>
      <p>First name: {templateDataCheck(operationalContact.firstName)}</p>
      <p>Surname: {templateDataCheck(operationalContact.surname)}</p>
      <p>Mobile or landline phone number: {templateDataCheck(operationalContact.phoneNumber)}</p>
      <p>Email address: {templateDataCheck(operationalContact.email)}</p>

      <h5>Billing details</h5>
      <p>PO number / Ref: {templateDataCheck(billingContactAndAddress.purchaseOrderRef)}</p>
      <p>
        Billing Frequency: {templateDataCheck(billingFrequency ? _.capitalize(billingFrequency) : billingFrequency)}
      </p>

      <h5>IP address deployment</h5>
      <p>First name: {templateDataCheck(ip.contact.firstName)}</p>
      <p>Surname: {templateDataCheck(ip.contact.surname)}</p>
      <p>Mobile or landline phone number: {templateDataCheck(ip.contact.telephone)}</p>
      <p>Organisation: {templateDataCheck(ip.contact.email)}</p>
      <p>Street address including postcode: {templateDataCheck(ip.contact.address)}</p>
      <p>NIC RIPE Handle: {templateDataCheck(ip.contact.nic_ripe_handle)}</p>
      <p>IP address justification: {templateDataCheck(ip.justification)}</p>
    </ShowMoreContent>
  );
};

export const OrderTemplateCard = ({ orderTemplate, customerId }: OrderTemplateCardProps) => {
  const { isOpen: isDeleteModalOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <Card
      className={classNames({
        default: orderTemplate.attributes.isDefault,
      })}
      data-testid="order-template-card"
      data-customerid={customerId}
      data-templateid={orderTemplate.id}
    >
      {orderTemplate.attributes.isDefault && (
        <BookmarkIcon>
          <Icon name="bookmark" size="large" />
        </BookmarkIcon>
      )}
      <strong>{orderTemplate.attributes.title}</strong>
      {orderTemplate.attributes.isDefault && <div style={{ fontSize: '1.1rem' }}>Default template</div>}

      <ShowMoreComponent isVisible={showMore} orderTemplate={orderTemplate} />

      <Spacer />
      <ButtonsContainer>
        <div>
          {orderTemplate.attributes.isDefault && <ClearDefaultOrderTemplateButton customerId={customerId} />}
          {!orderTemplate.attributes.isDefault && (
            <ShortSetDefaultOrderTemplateButton customerId={customerId} selectedOrderTemplateId={orderTemplate.id} />
          )}
        </div>
        <div>
          <DeleteButton onClick={openDeleteModal}>
            <Icon name="delete" size="large" />
          </DeleteButton>
        </div>
      </ButtonsContainer>

      <ShowMoreContainer onClick={toggleShowMore}>
        <ShowMoreIcon>
          <Icon name={showMore ? 'expand_less' : 'expand_more'} size="large" />
        </ShowMoreIcon>
      </ShowMoreContainer>

      {isDeleteModalOpen && (
        <OrderTemplateDeleteModal
          customerId={customerId}
          orderTemplateId={orderTemplate.id}
          onClose={closeDeleteModal}
        />
      )}
    </Card>
  );
};

const BookmarkIcon = styled.div`
  position: absolute;
  top: -10px;
  left: 10px;
  color: ${(props) => props.theme.colours.primaryB2};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Card = styled.div`
  position: relative; /* This is important to position the bookmark icon absolutely within the card */
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colours.grey10};
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  min-height: 12.5rem;
  width: 100%; /* default to full width for mobile */
  border: 1px solid ${(props) => props.theme.colours.grey10};

  &.default {
    border: 3px solid ${(props) => props.theme.colours.primaryB2};
  }

  /* Tablet (and above) screens */
  @media (min-width: 768px) {
    width: calc(50% - 2rem); /* 2 columns per row */
  }

  /* Desktop (and larger) screens */
  @media (min-width: 1024px) {
    width: calc(33% - 2rem); /* revert to original 3 columns per row */
  }
`;

export const ClearDefaultButton = styled.button`
  color: ${(props) => props.theme.colours.grey50};
  display: flex;
  align-items: center;
  font-size: 1.1rem;

  &:hover {
    color: ${(props) => props.theme.colours.grey80};
  }
`;

const DeleteButton = styled.button`
  color: ${(props) => props.theme.colours.grey50};
  display: flex;
  align-items: center;

  &:hover {
    color: ${(props) => props.theme.colours.secondaryA1};
  }
`;

export const SaveAsDefaultButton = styled.button`
  color: ${(props) => props.theme.colours.grey50};
  display: flex;
  align-items: center;
  font-size: 1.1rem;

  &:hover {
    color: ${(props) => props.theme.colours.primaryB2};
  }
`;

const ShowMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-top: 0.5rem 0;
  border-top: 1px solid ${(props) => props.theme.colours.grey20};
`;

const ShowMoreContent = styled.div`
  position: absolute;
  background-color: rgb(247, 247, 247);
  padding: 20px; /* Increased padding */
  border-radius: 0.5rem;
  border: 2px dotted;
  border-color: ${(props) => props.theme.colours.primaryB2};
  text-align: left;
  top: 105%;
  transform: translateX(-50%); /* Center it horizontally */
  left: 50%;
  width: 102%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 2;
  font-family: 'Arial', sans-serif; /* Improved font styling */
  line-height: 1.5; /* Improved line spacing for better readability */

  h4 {
    color: ${(props) => props.theme.colours.grey90};
    margin-bottom: 10px;
  }

  h5 {
    color: ${(props) => props.theme.colours.primaryB1};
    margin-bottom: 10px;
  }

  p {
    margin-left: 20px;
    color: ${(props) => props.theme.colours.grey80};
    font-size: 0.9rem;
  }
`;

export const ShowMoreIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colours.primaryB2};
`;

const Spacer = styled.div`
  flex-grow: 1;
  margin-top: 2rem;
`;
