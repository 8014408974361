import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export type SpinnerProps = {
  backgroundColour?: string;
  className?: string;
  colour?: 'primary' | 'secondary';
  id?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
};

const Spinner: FunctionComponent<React.PropsWithChildren<SpinnerProps>> = ({
  colour = 'primary',
  className,
  id,
  size = 'medium',
}) => {
  return (
    <div
      className={cx(className, 'spinner-border', {
        primary: colour === 'primary',
        secondary: colour === 'secondary',
        'spinner-border-sm': size === 'small',
        'spinner-border-md': size === 'medium',
        'spinner-border-lg': size === 'large',
        'spinner-border-xl': size === 'xlarge',
      })}
      role="status"
      id={id}
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const StyledSpinner = styled(Spinner)`
  &.primary {
    color: ${(props) => props.theme.colours.primaryB1};
  }

  &.secondary {
    color: ${(props) => props.theme.colours.secondaryD5};
  }

  &.spinner-border-lg {
    width: 3em;
    height: 3em;
  }

  &.spinner-border-xl {
    width: 5em;
    height: 5em;
  }
`;

export default StyledSpinner;
