import React, { FC } from 'react';
import { ProductType } from 'Quotes/types/productTypes';

export const GENERAL_TERMS = 'https://neosnetworks.com/wp-content/uploads/2024/08/NeosNetworks_General_Terms.pdf';
export const ETHERNET_SERVICE_TERMS =
  'https://neosnetworks.com/wp-content/uploads/2024/08/NeosNetworks_Ethernet_Terms.pdf';
export const CLOUD_SERVER_TERMS =
  'https://neosnetworks.com/wp-content/uploads/2024/08/NeosNetworks_BusinessCloudInternet_Terms.pdf';
export const OPTICAL_INTERNET_TERMS =
  'https://neosnetworks.com/wp-content/uploads/2024/08/NeosNetworks_Optical_Terms.pdf';

export const GeneralTerms: FC<React.PropsWithChildren> = ({ children }) => (
  <>
    <GeneralTermsLink />
    {children}, which shall incorporate the Supplementary Terms and Conditions as detailed above for all orders
    submitted from and including 27th September 2022. Orders submitted prior to that date will be under the Terms and
    Conditions applicable at the point the order was submitted.
  </>
);
export const GeneralTermsLink = () => (
  <a href={GENERAL_TERMS} target="blank" rel="noopener noreferrer">
    General terms
  </a>
);
export const EthernetTerms = () => (
  <>
    {' '}
    and{' '}
    <a href={ETHERNET_SERVICE_TERMS} target="blank" rel="noopener noreferrer">
      Ethernet terms
    </a>
  </>
);
export const InternetAndCloudTerms = () => (
  <>
    {' '}
    and{' '}
    <a href={CLOUD_SERVER_TERMS} target="blank" rel="noopener noreferrer">
      Business Cloud &amp; Internet terms
    </a>
  </>
);
export const OpticalTerms = () => (
  <>
    {' '}
    and{' '}
    <a href={OPTICAL_INTERNET_TERMS} target="blank" rel="noopener noreferrer">
      Optical Wavelength terms
    </a>
  </>
);

export const Terms: FC<{ productType: ProductType }> = ({ productType }) => {
  switch (productType) {
    case ProductType.OpticalP2P:
      return <OpticalTerms />;
    case ProductType.P2P:
      return <EthernetTerms />;
    case ProductType.P2NNI:
      return <EthernetTerms />;
    case ProductType.P2CCT:
      return <InternetAndCloudTerms />;
    case ProductType.NNI2CCT:
      return <InternetAndCloudTerms />;
    case ProductType.DIA:
      return <InternetAndCloudTerms />;
  }
};
