import { OverallConnectionType } from '../../../../Quotes/types/connectionType';
import { IPType } from '../../../../Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { ProductType } from '../../../../Quotes/types/productTypes';
import { IAEndLocation, IPriceData, IQuote } from '../../../../Quotes/types/store';
import { annualCost } from '../../../../Quotes/selectors';
import React from 'react';
import { AEndPortCosts } from './AEndPortCosts';
import { AEndFttxCosts } from './AEndFttxCosts';
import { AEndSupplierCosts } from './AEndSupplierCosts';

export type Props = {
  className?: string;
  connectionType: OverallConnectionType;
  ipType?: IPType;
  productType: ProductType;
  selectedPrice: IPriceData;
  isManagedDIA: IAEndLocation['is_managed_dia'];
  diaIPAllocation: IAEndLocation['dia_ip_allocation'];
  displayFTTPAggregationCharge: boolean;
  quote: IQuote;
};

function getAEndSelectedPriceData(selectedPrice: IPriceData) {
  const aEndSetupCost = selectedPrice.a_end_setup_cost;
  const aEndAnnualCost = annualCost(selectedPrice).A;
  const aEndPortCost = selectedPrice.port_a_cost;
  const aEndOpticalCosts = selectedPrice.opticalCosts?.aEnd;
  const aEndGeaCablelinkInstallCost = selectedPrice.a_end_gea_cablelink_install_cost;
  const aEndGeaCablelinkAnnualCost = selectedPrice.a_end_gea_cablelink_annual_cost;
  return {
    aEndSetupCost,
    aEndAnnualCost,
    aEndPortCost,
    aEndOpticalCosts,
    aEndGeaCablelinkInstallCost,
    aEndGeaCablelinkAnnualCost,
  };
}

export function AEndCosts(props: {
  selectedPrice: IPriceData;
  productType: ProductType;
  connectionType: OverallConnectionType;
}) {
  const {
    aEndSetupCost,
    aEndAnnualCost,
    aEndPortCost,
    aEndOpticalCosts,
    aEndGeaCablelinkInstallCost,
    aEndGeaCablelinkAnnualCost,
  } = getAEndSelectedPriceData(props.selectedPrice);

  return (
    <>
      <AEndSupplierCosts productType={props.productType} aEndSetupCost={aEndSetupCost} cost={aEndAnnualCost} />
      <AEndPortCosts
        connectionType={props.connectionType}
        productType={props.productType}
        aEndPortCost={aEndPortCost}
        aEndOpticalCosts={aEndOpticalCosts}
      />
      <AEndFttxCosts
        connection={props.connectionType}
        aEndGeaCablelinkInstallCost={aEndGeaCablelinkInstallCost}
        aEndGeaCablelinkAnnualCost={aEndGeaCablelinkAnnualCost}
      />
    </>
  );
}
