import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { IAppState } from '../../../../reducers';
import Button, { ButtonMainStyle } from '../../../../shared/components/atoms/Button';
import { updateQuoteAndGetPrice as updateQuoteAndGetPriceAction } from '../../../actions';
import IQuoteRecordAttributesBase from '../../../types/quoteRecordAttributesBase';
import { IAccordionSectionRenderProps } from '../../types';
import Bandwidth from 'Quotes/QuoteBuilder/components/Configure/Bandwidth/Bandwidth';
import Bearer from 'Quotes/QuoteBuilder/components/Configure/Bearer/Bearer';
import DIAConfig from './DIAConfig';
import ContractTermLength from './ContractTermLength';
import { IQuoteRecordAttributes } from '../../../types/quoteRecord';
import { ProductType } from '../../../types/productTypes';
import { isComplete } from '../../utils/configureSection';
import { IQuote, IQuotesState } from '../../../types/store';
import { pagesWithType, trackPage } from '../../../../shared/utils/trackPage';
import Diversity from './Diversity';
import { isFTTXQuote } from 'Quotes/utils/isFTTXQuote';
import { requiresAsyncPrices } from 'Quotes/QuoteBuilder/utils/requiresAsyncPrices';
import { QuoteAsyncPricingAlert } from 'Quotes/shared/components/QuotePricingComplexAlert/QuoteAsyncPricingAlert';

interface IConfigure {
  accordion: IAccordionSectionRenderProps;
  checkingAvailability: IQuotesState['checkingAvailability'];
  currentQuoteId: string | undefined;
  updatingQuote?: boolean;
  quote: IQuote;
  updateQuoteAndGetPrice(currentQuoteId: string, updateValues: IQuoteRecordAttributesBase, isAsync: boolean): void;
}

export const getUpdateValues = (quote: IQuote, attributes?: IQuoteRecordAttributesBase): IQuoteRecordAttributesBase => {
  const updateValues: IQuoteRecordAttributesBase = attributes
    ? { ...attributes }
    : {
        a_end_port: quote.bearer,
        b_end_port: quote.bearer,
        path_speed: quote.chosen_bandwidths[0] || quote.bandwidth, // BE expects path_speed to be set, even though we populate chosen_bandwidths instead.
        chosen_bandwidths: quote.chosen_bandwidths,
        chosen_term_lengths_in_months: quote.chosen_term_lengths_in_months,
        term_length_in_months: quote.contractTerm,
        a_end_exclude_sse_onnet: quote.aEndExcludeSSEOnNet,
        b_end_exclude_sse_onnet: quote.bEndExcludeSSEOnNet,
      };

  if (isFTTXQuote(quote) && !!quote.availability) {
    updateValues.availability_id = quote.availability.id;
    updateValues.a_end_access_method = quote.availability.aEnd;
    updateValues.b_end_access_method = quote.availability.bEnd;
    updateValues.a_end_bandwidth = quote.availability.fixedAEndBandwidth?.value || quote.bandwidth;
    updateValues.b_end_bandwidth = quote.availability.fixedBEndBandwidth?.value || quote.bandwidth;

    if (quote.productType === ProductType.P2NNI) {
      delete updateValues.a_end_access_method;
      delete updateValues.a_end_bandwidth;
    }
  } else if (quote.connectionType === 'Ethernet') {
    if (quote.productType === ProductType.P2P) {
      updateValues.a_end_access_method = 'Ethernet';
      updateValues.b_end_access_method = 'Ethernet';
    } else if (quote.productType === ProductType.P2NNI) {
      updateValues.b_end_access_method = 'Ethernet';
    } else if (quote.productType === ProductType.DIA) {
      updateValues.a_end_access_method = 'Ethernet';
    } else if (quote.productType === ProductType.OpticalP2P) {
      updateValues.a_end_access_method = 'Optical';
      updateValues.b_end_access_method = 'Optical';
    } else if (quote.productType === ProductType.P2CCT) {
      updateValues.a_end_access_method = 'Ethernet';
    }
  }

  if (quote.productType === ProductType.DIA) {
    if (quote.location.aEnd.ip.selectedId) updateValues.ip_count = quote.location.aEnd.ip.selectedId;

    updateValues.is_managed_dia = quote.location.aEnd.is_managed_dia;
    if (!updateValues.is_managed_dia && quote.location.aEnd.dia_ip_allocation)
      updateValues.dia_ip_allocation = quote.location.aEnd.dia_ip_allocation;
    else {
      delete updateValues.dia_ip_allocation;
    }

    updateValues.is_engineer_installation_required = quote.location.aEnd.is_engineer_installation_required;
    updateValues.is_rack_mount_required = quote.location.aEnd.is_rack_mount_required;
    updateValues.secondary_circuit_options = { mdia_cpe_selection: quote.location.aEnd.routerChoice };
  }

  if (quote.productType === ProductType.P2CCT || quote.productType === ProductType.NNI2CCT) {
    updateValues.bandwidth = quote.chosen_bandwidths[0] || quote.bandwidth;
    updateValues.cloud_connect_option = {};
    updateValues.cloud_connect_option.diversified = quote.location.aEnd.cloudConnect.diversified;
    updateValues.cloud_connect_option.provider = quote.location.aEnd.cloudConnect.name;
  }

  if (
    quote.productType === ProductType.DIA ||
    quote.productType === ProductType.P2CCT ||
    quote.productType === ProductType.NNI2CCT
  ) {
    delete updateValues.b_end_port;
    delete updateValues.b_end_access_method;
    delete updateValues.b_end_bandwidth;
  }

  if (quote.productType === ProductType.P2NNI || quote.productType === ProductType.NNI2CCT) {
    const selectedNniId = quote.location.aEnd.nni.selectedId;

    updateValues.a_end_n_n_i_id = selectedNniId ? selectedNniId : '0';
    updateValues.a_end_data_centre_id = quote.location.aEnd.nni.selectedDataCentreId;
  }

  return updateValues;
};

export const Configure: FunctionComponent<React.PropsWithChildren<IConfigure>> = ({
  accordion,
  checkingAvailability,
  currentQuoteId,
  updatingQuote,
  updateQuoteAndGetPrice,
  quote,
}) => {
  const updateValues = getUpdateValues(quote);
  const { productType } = quote;

  useEffect(() => {
    if (productType) {
      trackPage(pagesWithType.configureQuote(productType));
    }
  }, [productType]);

  useEffect(() => {
    if (updatingQuote) {
      accordion.showContent(accordion.index + 1);
    }
  }, [accordion, updatingQuote]);

  return (
    <div>
      <ContractTermLength />
      {quote.productType !== ProductType.NNI2CCT && <Bearer />}
      <Bandwidth />
      {quote.productType === ProductType.DIA && <DIAConfig />}
      {(quote.productType === ProductType.P2CCT || quote.productType === ProductType.NNI2CCT) && <Diversity />}

      {requiresAsyncPrices(quote) && <QuoteAsyncPricingAlert />}

      {currentQuoteId && (
        <div className="text-right pb-3">
          <Button
            disabled={!isComplete(quote) || checkingAvailability.inProgress || checkingAvailability.error}
            id="getPriceButton"
            mainStyle={ButtonMainStyle.PrimaryRectangular}
            onClick={() => {
              updateQuoteAndGetPrice(currentQuoteId, updateValues, requiresAsyncPrices(quote));
            }}
          >
            Get price
          </Button>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  updateQuoteAndGetPrice: (quoteId: string, updateValues: IQuoteRecordAttributes, isAsync: boolean) =>
    dispatch(updateQuoteAndGetPriceAction(quoteId, updateValues, isAsync)),
});

const mapStateToProps = ({ quoteBuilder: { checkingAvailability, currentQuoteId, updating, quote } }: IAppState) => ({
  checkingAvailability,
  currentQuoteId,
  updatingQuote: updating.inProgress,
  quote,
});

export default connect(mapStateToProps, mapDispatchToProps)(Configure);
