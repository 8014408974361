import produce from 'immer';
import { TableActions, TableActionTypes } from './actions';
import { ITablesState } from './types/store';

export const initialState: ITablesState = {
  singleQuotesTable: {
    filters: {
      quoteId: '',
      quotedBy: '',
      product: '',
      diverse: '',
      location: '',
      accessProvider: '',
      accessType: '',
      price: '',
      createdAt: '',
      updatedAt: '',
      status: '',
    },
    pageSize: 10,
  },
  bulkQuotesTable: {
    filters: {
      quoteId: '',
      updatedAt: '',
      quotedBy: '',
      reference: '',
    },
    pageSize: 10,
  },
  ordersTable: {
    filters: {
      orderId: '',
      quoteId: '',
      customer: '',
      product: '',
      location: '',
      accessProvider: '',
      updatedAt: '',
      updatedBy: '',
      status: '',
    },
    pageSize: 10,
  },
  usersTable: {
    filters: {
      userId: '',
      name: '',
      username: '',
      status: '',
      lastActive: '',
    },
    pageSize: 10,
  },
  ordersReportTable: {
    filters: {
      orderId: '',
      customer: '',
      product: '',
      location: '',
      accessProvider: '',
      updatedAt: '',
      updatedBy: '',
      status: '',
    },
    pageSize: 10,
  },
  historicalAndAPITable: {
    filters: {},
    pageSize: 10,
  },
  messages: {
    filters: {},
    pageSize: 10,
  },
};

const reducer = produce((draft: ITablesState, action: TableActions): ITablesState | void => {
  switch (action.type) {
    case TableActionTypes.SET_TABLE_FILTER: {
      const { tableName, key, value } = action.payload;
      draft[tableName].filters[key] = value || '';
      break;
    }
    case TableActionTypes.SET_TABLE_PAGE_SIZE: {
      const { tableName, pageSize } = action.payload;
      draft[tableName].pageSize = pageSize;
      break;
    }
    default:
      return draft;
  }
}, initialState);

export default reducer;
