import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ICloudProvider } from '../types';
import { useCloudProvidersFetch } from '../hooks';
import SpinnerWithText from '../../../../shared/components/molecules/SpinnerWithText';
import Alert from '../../../../shared/components/atoms/Alert';
import CloudProviderCell from '../CloudProviderCell';
import { ProviderName } from '../../../../Quotes/types/store';

export interface ICloudProvidersCapture {
  className?: string;
  cloudProvidersList: ICloudProvider[];
  selectedProviderName: ProviderName;
  theme: {
    colours: {
      fadedGrey: string;
    };
    typography: {
      fontSizeSm: string;
    };
  };
  onCloudProvidersFetchSuccess(cloudProviders: ICloudProvider[]): void;
  onCloudProviderSelected(cloudProvider: ICloudProvider): void;
}

const getMaxBandwidthForSelectedProvider = (
  providerName: string,
  cloudProvidersList: ICloudProvider[]
): number | undefined => {
  const match = cloudProvidersList.find((provider) => provider.attributes.name === providerName);
  if (match && match.attributes.supported_bandwidths.length > 0) {
    return match.attributes.supported_bandwidths.slice(-1)[0];
  } else {
    return undefined;
  }
};

const CloudProvidersCapture: FunctionComponent<React.PropsWithChildren<ICloudProvidersCapture>> = ({
  className,
  cloudProvidersList,
  onCloudProvidersFetchSuccess,
  onCloudProviderSelected,
  selectedProviderName,
}) => {
  const { error, loading } = useCloudProvidersFetch({
    onSuccess: onCloudProvidersFetchSuccess,
  });

  if (loading) {
    return (
      <div className={className}>
        <SpinnerWithText text="Retrieving Cloud Providers" className="loading-spinner" size="large" />
      </div>
    );
  }
  if (error) {
    return (
      <Alert>
        Error retrieving Cloud Providers. Please try again later. If the problem persists, please contact your Account
        Manager.
      </Alert>
    );
  }

  const maxBandwidth = getMaxBandwidthForSelectedProvider(selectedProviderName, cloudProvidersList);

  return (
    <div className={className}>
      <div className="providers">
        <div className="header">Cloud connect service</div>
        <div className="providers-logos">
          {cloudProvidersList.map((provider) => (
            <CloudProviderCell
              provider={provider}
              selectedProviderName={selectedProviderName}
              key={provider.attributes.name}
              onClick={onCloudProviderSelected}
            />
          ))}
        </div>
        {maxBandwidth && (
          <p className="maximum-bandwidth">
            Maximum bandwidth for {selectedProviderName} is {maxBandwidth} Mbps
          </p>
        )}
      </div>
    </div>
  );
};

const StyledCloudProvidersCapture = styled(CloudProvidersCapture)`
  .maximum-bandwidth {
    margin-top: 1em;
    font-size: ${(props) => props.theme.typography.fontSizeSm};
    color: ${(props) => props.theme.colours.grey70};
  }
  .providers-logos {
    display: flex;
    flex-flow: wrap;
  }
`;

export default StyledCloudProvidersCapture;
