const initialList = [
  'Add address & contact information',
  'Create your site configuration',
  'Let us know how ready your site is to start work',
];

export const initialNNIList = ['Provide VLAN Reference'];
export const initialNewNNIList = [
  ...initialNNIList,
  'To order against new NNI agreements in progress, provide job reference(s) or IDN',
];

export default initialList;
