import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { ProductType } from 'Quotes/types/productTypes';

export const isNNATEnabledFor = (productType: ProductType | null | undefined): boolean => {
  if (!featureFlag.isEnabled(Feature.nnat)) return false;

  const includeP2P = !featureFlag.getAttribute<boolean>(Feature.nnat, 'excludeP2P');
  const includeDIA = !featureFlag.getAttribute<boolean>(Feature.nnat, 'excludeDIA');
  switch (productType) {
    case ProductType.P2NNI:
      return true;
    case ProductType.DIA:
      return includeDIA;
    default:
      return includeP2P;
  }
};
