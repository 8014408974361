import { isColtOnNetSite, isSSEOnNetSite } from 'Location/AddressesCombined';
import { useOnNetAddressesFetch } from 'Order/OrderBuilder/shared/hooks';
import { selectReadOnlyPostcode } from 'Order/selectors';
import { selectSelectedPriceAccessType } from 'Quotes/selectors';
import { Supplier } from 'Quotes/types/supplier';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from 'shared/components/atoms/Alert';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import Column from 'shared/components/atoms/Column';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import styled from 'styled-components';
import { selectSelectedCompanyId, selectUserRoles } from 'User/selectors';
import { IOnNetSite } from 'shared/types/onNetSite';
import { concatenateOnNetAddress } from 'shared/utils/addresses/helperFunctions';
import { IUpdateOnNetAddress } from 'Order/types/actions';
import { IOrderMetaForLocation } from 'Order/types/store';
import { formatAddressLine } from 'Order/OrderBuilder/shared/utils/formatAddressLine';

export interface IOnNetSelectOption extends IDropdownOption {
  orderMeta: IOrderMetaForLocation;
}

export const onNetSiteMapperForOrder = (site: IOnNetSite): IOnNetSelectOption => {
  const address = concatenateOnNetAddress(site.attributes);

  return {
    label: address,
    value: address,
    orderMeta: {
      reference: site.attributes.reference || '',
      name: site.attributes.name || '',
      location: site.attributes.location || '',
    },
  };
};

const getDefaultValue = (address: IOrderMetaForLocation): IOnNetSelectOption | null => {
  const addressLine = concatenateOnNetAddress(address);

  if (!addressLine) {
    return null;
  }

  return {
    label: addressLine,
    value: addressLine,
    orderMeta: address,
  };
};

type Props = {
  className?: string;
  identifierPrefix: string;
  orderMeta: IOrderMetaForLocation;
  saveOnNetAddressSelection(attributes: IUpdateOnNetAddress['payload']['attributes']): void;
};

export const OnNetAddressSelection: FC<React.PropsWithChildren<Props>> = ({
  className,
  identifierPrefix,
  saveOnNetAddressSelection,
  orderMeta,
}) => {
  const selectedCompanyId = useSelector(selectSelectedCompanyId);
  const postcode = useSelector(selectReadOnlyPostcode);
  const accessType = useSelector(selectSelectedPriceAccessType);
  const userRoles = useSelector(selectUserRoles);
  const isColt = identifierPrefix === 'aEndLocation' ? accessType.A === Supplier.COLT : accessType.B === Supplier.COLT;

  const { fetching, error, results } = useOnNetAddressesFetch({
    postcode: identifierPrefix === 'aEndLocation' ? postcode.A : postcode.B,
    companyId: selectedCompanyId,
  });

  const options = results.filter(isColt ? isColtOnNetSite : isSSEOnNetSite).map(onNetSiteMapperForOrder);

  const saveToOrderMeta = () => {
    if (options.length === 1) {
      saveOnNetAddressSelection(options[0].orderMeta);
    }
  };

  useEffect(() => {
    if (options.length === 1) {
      saveToOrderMeta();
    }
  }, [options.length]);

  if (fetching) {
    return (
      <div className={`${className} d-flex`}>
        <Spinner text="Finding addresses" className="loading-spinner" size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-75">
        <Alert>
          Error retrieving on-net addresses. Please try again later. If the problem persists, please contact your
          Account Manager.
        </Alert>
      </div>
    );
  }

  return (
    <div className="row">
      {options.length > 1 && (
        <Column mdWidth={6}>
          <Dropdown
            onChange={(address) => {
              saveOnNetAddressSelection(address?.orderMeta || null);
            }}
            options={results.filter(isColt ? isColtOnNetSite : isSSEOnNetSite).map(onNetSiteMapperForOrder)}
            placeholder="Choose location"
            value={getDefaultValue(orderMeta)}
          />
        </Column>
      )}
      {options.length === 1 && (
        <Column>
          <address className={`${identifierPrefix} on_net_address_field`}>
            {formatAddressLine(userRoles, orderMeta)}
          </address>
        </Column>
      )}
    </div>
  );
};

export default styled(OnNetAddressSelection)`
  .loading-spinner {
    padding-top: 0;
  }
`;
