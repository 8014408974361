import { ProductType } from 'Quotes/types/productTypes';
import React, { FunctionComponent, useState } from 'react';
import { OrderStatus } from 'Order/types/orderRecord';
import styled from 'styled-components';
import { GeneralTerms, Terms } from 'Order/OrderBuilder/Terms/components/TermsText/GeneralTerms';

export const GEA_CABLELINK_NOTICE =
  'Your order may be subject to an additional 30 day lead-time following capacity checks against GEA Cablelinks.';
export const GEA_CABLELINK_NOTICE_ORDER_IN_REVIEW =
  'Please note, in order to complete your order, Neos Networks will need to order a GEA Cablelink which carries an additional lead time of approx. 30 days.';
export const OPENREACH_SURCHARGE_TEXT = `If I intend to use this service for the purpose of FTTP aggregation,
I accept that there may be a surcharge passed on by Openreach to me`;

export const QUOTE_TIMEFRAME_LAPSE_IN_DAYS = 14;

interface ProductSpecificTerms {
  productType: ProductType;
  isCheckbox: boolean;
}

export const ProductSpecificTerms: FunctionComponent<React.PropsWithChildren<ProductSpecificTerms>> = ({
  productType,
  isCheckbox,
}) => {
  return (
    <>
      {isCheckbox && 'Agree to '}
      <GeneralTerms>
        <Terms productType={productType} />
      </GeneralTerms>
    </>
  );
};

type TermsTextProps = {
  className?: string;
  productType: ProductType;
  showGeaCablelinkNotice: boolean;
  showOpenreachCPENotice: boolean;
  showAdditionalOpenreachSurchargeNotice: boolean;
  orderState?: OrderStatus;
  isONAT?: boolean;
  onToggleTerms?: () => void;
};

const TermsText: FunctionComponent<React.PropsWithChildren<TermsTextProps>> = ({
  showGeaCablelinkNotice,
  showOpenreachCPENotice,
  showAdditionalOpenreachSurchargeNotice,
  productType,
  className,
  orderState,
  isONAT = false,
  onToggleTerms,
}) => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const onChangeTerms = () => {
    setTermsAccepted(!termsAccepted);

    if (typeof onToggleTerms === 'function') {
      onToggleTerms();
    }
  };

  return (
    <div className={className}>
      <p>
        <strong>Supplementary Terms &amp; Conditions</strong>
      </p>
      <ul>
        <li>Pricing is quoted in GBP and excludes VAT and any other applicable taxes.</li>
        <li>
          Quotations are not binding and are only valid for {QUOTE_TIMEFRAME_LAPSE_IN_DAYS} days from issue. Provision
          of quoted services is subject to contract.
        </li>
        <li>Quotations are subject to capacity check, third party supplier confirmation and site survey.</li>
        <li>Pricing does not include third party internal cabling.</li>
        <li>
          Lead-times are indicative only and delivery is subject to the following: site survey, wayleaves, site access,
          and condition/existence of Network termination equipment at a customer location.
        </li>
        <li>
          Third party tail provision is subject to additional fees where there is no existing fibre, or there is
          inappropriate equipment at either end of the fibre.
        </li>
        <li>
          Please note customers with an existing Master Services Agreement with us shall be billed in accordance with
          the terms of the Agreement.
        </li>
        <li>
          Please note for customers with an existing Master Services Agreement (“MSA”) in Term, with Neos Networks
          Limited the terms of the MSA shall take precedence over the full terms and conditions referred below over the
          standard terms and conditions.
        </li>
        <li>
          Neos Networks acting reasonably reserves the right to cancel any order(s) where it is no longer of commercial
          benefit to it and/or where it becomes liable to excess construction charges in respect of an order.
        </li>
        {showGeaCablelinkNotice && (
          <li>{orderState === OrderStatus.IN_REVIEW ? GEA_CABLELINK_NOTICE_ORDER_IN_REVIEW : GEA_CABLELINK_NOTICE}</li>
        )}
        {showOpenreachCPENotice && (
          <li>
            I understand that it is prohibited to use a device with this service which has not passed{' '}
            <a
              className="openreach-cre-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://neosnetworks.com/products-services/ethernet/eofttx-mct-list/"
              aria-current="page"
            >
              Openreach&apos;s MCT (Modem Conformance Testing)
            </a>
            . We reserve the right to not process an order if an unapproved modem is intended for use.
          </li>
        )}
        {showAdditionalOpenreachSurchargeNotice && <li>{OPENREACH_SURCHARGE_TEXT}</li>}
      </ul>
      {onToggleTerms !== undefined && (
        <div className="form-check pb-4">
          <input
            type="checkbox"
            id="terms-checkbox"
            checked={termsAccepted}
            className="form-check-input"
            onChange={onChangeTerms}
          />
          <ProductSpecificTerms productType={productType} isCheckbox />
        </div>
      )}
      {(isONAT ||
        orderState === OrderStatus.PLACED ||
        orderState === OrderStatus.IN_REVIEW ||
        orderState === OrderStatus.RETURNED ||
        orderState === OrderStatus.READY) && <ProductSpecificTerms productType={productType} isCheckbox={false} />}
    </div>
  );
};

export default styled(TermsText)`
  .terms-link,
  .openreach-cre-link {
    color: ${(props) => props.theme.colours.primaryB1};
  }

  .openreach-cre-link {
    text-decoration: underline;
  }
`;
