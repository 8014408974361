export interface IOnNetAttributes {
  location?: string | null;
  max_bandwidth?: string | null;
  name?: string | null;
  reference: string | null;
  ports?: string[] | null;
  postcode?: string | null;
  colt_building_id?: string | null;
  min_bandwidth?: string | null;
  pop_id?: string | null;
  floor?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  town?: string | null;
  country?: string | null;
  county?: string | null;
}

export interface IOnNetSite {
  attributes: IOnNetAttributes;
  type: 'on_net_building';
  id: string;
}

export interface IOnNetSiteResponse {
  data: IOnNetSite[] | [];
}

export const isOnNetSite = (object: any): object is IOnNetSite => {
  try {
    return 'attributes' in object && 'reference' in object.attributes;
  } catch {
    return false;
  }
};
