import { DisabledByFlag, EnabledByFlag } from 'FeatureFlags/EnabledByFlag';
import { Feature } from 'FeatureFlags/types';
import React from 'react';

export const NEW_NNI_TITLE = 'You are quoting against a New NNI';
export const NEW_NNI_NO_PRICE = 'Prices shown will not include the provisioning of this NNI.';

export const NEW_NNI_CONTACT_ACCOUNT_MANAGER =
  'Should you wish to progress you will need to order this separately by contacting your Account Manager.';
export const NEW_NNI_FILL_IN_ORDER =
  'You will be asked to provide the necessary information at the order stage. Following this, your account manager will contact you to progress the NNI order.';

export const NewNNIInfo = () => (
  <div className="mt-5">
    <div className="text-danger font-weight-bold" data-testid="new-nni-title">
      {NEW_NNI_TITLE}
    </div>
    <EnabledByFlag feature={Feature.newNNIRequests}>
      <p data-testid="new-nni-body">
        {NEW_NNI_NO_PRICE} {NEW_NNI_FILL_IN_ORDER}
      </p>
    </EnabledByFlag>
    <DisabledByFlag feature={Feature.newNNIRequests}>
      <p data-testid="new-nni-body">
        {NEW_NNI_NO_PRICE} {NEW_NNI_CONTACT_ACCOUNT_MANAGER}
      </p>
    </DisabledByFlag>
  </div>
);
