import { call, cancel, delay, fork, race, take } from 'redux-saga/effects';

const defaultTimeoutInMs = 60000;
const defaultIntervalInMs = 1000;

interface PollingConfig {
  intervalInMs?: number;
  stopAction?: string;
  onSagaError?: (e: any) => void;
  timoutInMs?: number;
}

/**
 * Utility saga to implement polling with a given interval.
 * @param saga worker saga to be called in each polling cycle
 * @param intervalInMs interval in milliseconds
 * @param stopAction optional action that will stop the polling, if not provided polling will continue forever
 * @param onSagaError optional callback to be called when the worker saga throws an error
 * @param params worker saga parameters
 */
export function* pollWithInterval<Saga extends (...args: any) => any>(
  config: PollingConfig,
  saga: Saga,
  ...params: Parameters<Saga>
): any {
  const { intervalInMs, stopAction, onSagaError, timoutInMs } = config;

  function* pollWorkerSaga() {
    while (true) {
      try {
        yield delay(intervalInMs ?? defaultIntervalInMs);
        yield call(saga, ...params);
      } catch (e) {
        if (onSagaError) onSagaError(e);
        break;
      }
    }
  }

  // starts task asynchronously using `fork` to allow setup of stop effects
  const task = yield fork(pollWorkerSaga);

  // if stop action is provided, stop the polling when the action is dispatched or timeout is reached
  if (stopAction) {
    const { timeout } = yield race({
      timeout: delay(timoutInMs ?? defaultTimeoutInMs),
      stop: take(stopAction),
    });

    if (timeout && onSagaError) onSagaError(new Error(`Polling timed out after ${timoutInMs}ms`));

    yield cancel(task);
  }
}
