import { IOrder } from 'Order/types/store';
import { isValidVLANIdField } from './validation';
import { ISiteConfig } from 'Order/types/location';

export default function isNNICompleted(order: IOrder, hasShadowNNI: boolean): boolean {
  const location = order.locationA;
  const primaryCircuitCompleted = isNNIComplete(location.siteConfig, hasShadowNNI);
  const secondaryCircuitCompleted = location.secondarySiteConfig
    ? isNNIComplete(location.secondarySiteConfig, false)
    : true;

  return primaryCircuitCompleted && secondaryCircuitCompleted;
}

export function isNNIComplete(siteConfig: ISiteConfig, hasShadowNNI: boolean): boolean {
  if (!siteConfig.vlanId) return false;

  const vlanValid = isValidVLANIdField(siteConfig.vlanId);
  if (!hasShadowNNI) return vlanValid;

  const shadowVlanValid = isValidVLANIdField(siteConfig.shadowVLANId || null);

  return vlanValid && shadowVlanValid;
}
