import dayjs from 'dayjs';
import { MessageResource, MessagesOnResourceResponse } from 'shared/types/Messages';
import { toMessageResource } from 'Messages/crud/loadMessages';

export const transformMessagesForReducer = (messages: MessagesOnResourceResponse[]): MessageResource[] => {
  return messages
    .map((message) => {
      const { id, type, ...rest } = message;
      return {
        type: type,
        id: id,
        attributes: rest,
      };
    })
    .map(toMessageResource)
    .sort((a, b) => {
      return dayjs(a.attributes.createdAt).diff(b.attributes.createdAt);
    });
};
