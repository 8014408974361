import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect, DispatchProp, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IOrder } from 'Order/types/store';
import { IPriceData, IQuote } from 'Quotes/types/store';
import LockableCard from 'shared/components/organisms/LockableCard';
import { CardState } from 'shared/components/organisms/CardWithInitialText';
import Modal from 'shared/components/molecules/Modal';
import Alert from 'shared/components/atoms/Alert';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import { IAppState } from 'reducers';
import { isOrderReady } from 'Order/OrderBuilder/shared/utils/isOrderReady';
import Terms from 'Order/OrderBuilder/Terms';
import { submitOrder as submitOrderAction } from 'Order/actions';
import { OrderSubmitType } from 'Order/crud/submitOrder';
import { usePreviousState } from 'shared/utils/customHooks';
import { pagesWithType, trackPage } from 'shared/utils/trackPage';
import { MDIAText, orderNotCompleteText } from 'Order/OrderBuilder/OrderReadyCard';
import quoteHasShadowNNI from 'Order/OrderBuilder/shared/utils/hasShadowNNI';
import PresentForRoles from 'Auth/PresentForRoles';
import { userPermissions } from 'shared/utils/permissions';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { OrderStatus } from 'Order/types/orderRecord';
import CancelOrder from 'Order/OrderBuilder/CancelOrder';
import { IRequestState } from 'shared/types/requestState';
import { showGeaCablelinkNotice } from 'Order/OrderBuilder/shared/utils/geaCablelink';
import { GEA_CABLELINK_NOTICE } from 'Order/OrderBuilder/Terms/components/TermsText';
import { isFTTXQuote } from 'Quotes/utils/isFTTXQuote';
import showOpenreachCPENotice from 'Quotes/utils/showOpenreachCPENotice';
import { selectSubjectToAdditionalOpenreachSurcharges } from 'Quotes/selectors';
import { useNavigate } from 'react-router-dom';

export interface ICardState {
  aEnd: CardState;
  bEnd: CardState;
  billingAndContact: CardState;
  cloudConnect: CardState;
}

type Props = {
  cardState: ICardState;
  className?: string;
  order: IOrder;
  hasShadowNNI: boolean;
  orderId?: string;
  quote: IQuote;
  selectedPrice: IPriceData;
  submitting: IRequestState;
  submitOrder(orderId: string, submitType: OrderSubmitType): void;
};

const SubmitOrder: FunctionComponent<React.PropsWithChildren<Props>> = ({
  cardState,
  className,
  order,
  orderId,
  hasShadowNNI,
  selectedPrice,
  submitOrder,
  quote,
  submitting,
}) => {
  const isReady = isOrderReady(order!, hasShadowNNI, cardState, quote, selectedPrice);
  const prevInProgress = usePreviousState(submitting.inProgress);
  const navigate = useNavigate();
  const goToOrders = () => navigate('/orders');
  const isComplete = prevInProgress && !submitting.inProgress && !submitting.error;
  const [showCancelModal, setShowCancelModal] = useState(false);
  const subjectToAdditionalOpenreachSurcharges = useSelector(selectSubjectToAdditionalOpenreachSurcharges);

  useEffect(() => {
    if (isComplete && order.productType) {
      trackPage(pagesWithType.orderComplete(order.productType));
    }
  }, [isComplete, order.productType]);

  useEffect(() => {
    if (isReady && order.productType) {
      trackPage(pagesWithType.orderReady(order.productType));
    }
  }, [isReady, order.productType]);

  return (
    <div className={`${className} place-order__wrapper`}>
      <LockableCard locked={!isReady} title="Submit order for review">
        {!isReady ? (
          <>
            <p className="text-muted">{orderNotCompleteText}</p>
            {quote.location.aEnd.is_managed_dia && <MDIAText className="text-muted" />}
          </>
        ) : (
          <>
            {quote.location.aEnd.is_managed_dia && <MDIAText />}
            <Terms
              onSubmit={() => orderId && submitOrder(orderId, OrderSubmitType.REVIEW)}
              showGeaCablelinkNotice={showGeaCablelinkNotice(selectedPrice, order, quote)}
              showOpenreachCPENotice={showOpenreachCPENotice(
                quote?.connectionType,
                isFTTXQuote(quote!),
                quote!.aEndAccessMethod,
                quote!.bEndAccessMethod,
                selectedPrice.a_end_access_type,
                selectedPrice.b_end_access_type
              )}
              showAdditionalOpenreachSurchargeNotice={subjectToAdditionalOpenreachSurcharges}
              submitting={submitting}
              productType={quote.productType!}
            />
          </>
        )}

        {(order.state === OrderStatus.RETURNED || order.state === OrderStatus.READY) && (
          <PresentForRoles roles={userPermissions.cancelOrderCustomer}>
            <hr />
            <Button
              disabled={submitting.inProgress}
              className="cancel-order-btn"
              onClick={() => setShowCancelModal(true)}
              mainStyle={ButtonMainStyle.PrimaryRectangular}
              subStyle="outline"
            >
              Cancel order
            </Button>
          </PresentForRoles>
        )}

        {showCancelModal && <CancelOrder onClose={() => setShowCancelModal(false)} />}

        {submitting.error && (
          <Alert>
            Error submitting order for review. Please try again later. If the problem persists, please contact your
            Account Manager.
          </Alert>
        )}

        {submitting.inProgress && <Spinner className="submitting-spinner" text="Submitting order" />}

        {isComplete && (
          <Modal
            confirmBtnLabel="OK"
            title="Your order has been submitted for review"
            onConfirm={goToOrders}
            onClose={goToOrders}
            showCloseBtn={false}
          >
            {showGeaCablelinkNotice(selectedPrice, order, quote) && <p>{GEA_CABLELINK_NOTICE}</p>}
          </Modal>
        )}
      </LockableCard>
    </div>
  );
};

const StyledSubmitOrder = styled(SubmitOrder)`
  .alert,
  .submitting-spinner {
    margin-top: 1.5em;
  }
`;

const mapStateToProps = ({
  orderBuilder: { id: orderId, submitting },
  quoteBuilder: {
    quote,
    quoteEndpointMeta,
    pricing: { selectedPrice },
  },
}: IAppState) => ({
  orderId,
  quote,
  selectedPrice,
  submitting,
  hasShadowNNI: quoteHasShadowNNI(quoteEndpointMeta, quote.location.aEnd.nni),
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  submitOrder: (orderId: string, submitType: OrderSubmitType) => dispatch(submitOrderAction(orderId, submitType)),
});

export { SubmitOrder };
export default connect(mapStateToProps, mapDispatchToProps)(StyledSubmitOrder);
