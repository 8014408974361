import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { formatDateTimeHuman } from '../../../../shared/utils/dateHelper';

interface IUserStatus {
  className?: string;
  lastSignIn: string | null;
}

export const UserStatus: FunctionComponent<React.PropsWithChildren<IUserStatus>> = ({ className, lastSignIn }) => {
  return (
    <div className={`${className} column`}>
      <div className="header">Status</div>
      {lastSignIn && <p>Last sign-in on the {formatDateTimeHuman(lastSignIn)}</p>}
    </div>
  );
};

const StyledUserStatus = styled(UserStatus)`
  .header {
    border-bottom: none;
    font-size: 1.2em;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
`;

export default StyledUserStatus;
