import { IOpenReachAddress, IPAFAddress } from '../../types/postcodeResults';

const onlyPostcodeIsPresent = (location: IOpenReachAddress | IPAFAddress): boolean => {
  for (const field in location) {
    if (field !== 'postcode' && field !== null && location[field] !== '' && location[field] !== null) {
      return false;
    }
  }
  return location.postcode !== '' ? true : false;
};

export default onlyPostcodeIsPresent;
