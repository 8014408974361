import { FTTXConnectionType, fttxConnectionTypes, OverallConnectionType } from 'Quotes/types/connectionType';
import { IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import { monthsToYears } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import { joinWordsWithComma } from './joinItemsWithComma';

const FTTP_C_BANDWIDTH_REGEX = /(\d+)\/(\d+)/;

export const getPortSpeedFromPortString = (portSpeed: string): string => {
  return portSpeed.replace(/Port_(\d+).*/, (_, $1) => {
    return $1;
  });
};

export const getPortSpeedFromPort = (portSpeed: string | undefined): number => {
  if (!portSpeed) return 0;

  return parseInt(getPortSpeedFromPortString(portSpeed), 10);
};

export const maxPortSpeedFilter = (maxPortSpeed: number) => (bearer: BearerType) => {
  return getPortSpeedFromPort(bearer) <= maxPortSpeed;
};

export const doesBearerSupportSpeed = (speed: number) => (bearer: string | undefined) => {
  const maximumPortSpeed = getPortSpeedFromPort(bearer);
  return maximumPortSpeed >= speed;
};

export const bearerForDisplay = (portSpeed = ''): string => {
  const extractedNumber = getPortSpeedFromPortString(portSpeed);

  if (extractedNumber) {
    return formatBandwidth(extractedNumber);
  }

  return '';
};

export const formatBandwidth = (bandwidth: string): string => {
  if (bandwidth.match(FTTP_C_BANDWIDTH_REGEX)) return `${bandwidth} Mbps`;

  const bw = parseInt(bandwidth, 10);
  return bw >= 1000 ? `${bw / 1000} Gbps` : `${bw} Mbps`;
};

export const multipleBandwidthForDisplay = (bandwidths: string[]) => {
  const words = bandwidths.map(formatBandwidth);

  return joinWordsWithComma(words);
};

export const multipleContractTermsForDisplay = (contractTerms: string[]) => {
  const words = contractTerms.map(monthsToYears);

  return joinWordsWithComma(words);
};

export const fttxBandwidthForDisplay = ({
  aEndBandwidth,
  bEndBandwidth,
  connectionType,
  chosenBandwidths = [],
}: {
  aEndBandwidth: IQuote['aEndBandwidth'];
  bEndBandwidth: IQuote['bEndBandwidth'];
  connectionType: FTTXConnectionType;
  chosenBandwidths?: IQuote['chosen_bandwidths'];
}) => {
  if (fttxConnectionTypes.includes(connectionType) && chosenBandwidths.length > 0) {
    return chosenBandwidths.map(formatBandwidth).join(', ');
  }

  const lowestBandwidth = pickLowestBandwidth(aEndBandwidth, bEndBandwidth);

  if (!lowestBandwidth) {
    return 'Unavailable';
  }

  if (connectionType === 'EoFTTP') {
    return formatBandwidth(lowestBandwidth);
  }
  ``;
  return `Up to ${formatBandwidth(lowestBandwidth)}`;
};

export const fttxMixedConnectionTypesBreakdown = ({
  aEndBandwidth,
  bEndBandwidth,
  aEndAccessMethod,
  bEndAccessMethod,
}: {
  aEndBandwidth: IQuote['aEndBandwidth'];
  bEndBandwidth: IQuote['bEndBandwidth'];
  aEndAccessMethod: IQuote['aEndAccessMethod'];
  bEndAccessMethod: IQuote['bEndAccessMethod'];
}) => {
  if (aEndAccessMethod === 'EoFTTC') {
    return `(Up to ${formatBandwidth(aEndBandwidth || '')} at A-End, ${formatBandwidth(bEndBandwidth || '')} at B-End)`;
  }

  if (bEndAccessMethod === 'EoFTTC') {
    return `(${formatBandwidth(aEndBandwidth || '')} at A-End, Up to ${formatBandwidth(bEndBandwidth || '')} at B-End)`;
  }

  return '';
};

export const isConnectionTypeMixed = ({
  aEndAccessMethod,
  bEndAccessMethod,
  connectionType,
}: {
  aEndAccessMethod: IQuote['aEndAccessMethod'];
  bEndAccessMethod: IQuote['bEndAccessMethod'];
  connectionType: OverallConnectionType;
}) => {
  return connectionType === 'EoFTTC' && (aEndAccessMethod === 'EoFTTP' || bEndAccessMethod === 'EoFTTP');
};

export const pickLowestBandwidth = (aEndBandwidth: string | null, bEndBandwidth: string | null) => {
  if (aEndBandwidth === null) {
    return bEndBandwidth;
  }

  if (bEndBandwidth === null) {
    return aEndBandwidth;
  }

  if (parseInt(aEndBandwidth, 10) < parseInt(bEndBandwidth, 10)) {
    return aEndBandwidth;
  }

  return bEndBandwidth;
};

export const pickLowestBearerType = (
  aPortEnd?: BearerType | null,
  bPortEnd?: BearerType | null
): BearerType | undefined => {
  if (aPortEnd && bPortEnd) {
    const aPortEndNumber = aPortEnd.replace(/[^\d]/g, '');
    const bPortEndNumber = bPortEnd.replace(/[^\d]/g, '');

    return parseInt(aPortEndNumber, 10) < parseInt(bPortEndNumber, 10) ? aPortEnd : bPortEnd;
  }

  return aPortEnd || bPortEnd || undefined;
};

export const reducePortEnds = (
  productType: ProductType,
  aPortEnd?: BearerType | null,
  bPortEnd?: BearerType | null
): BearerType | undefined => {
  switch (productType) {
    case ProductType.DIA:
    case ProductType.P2CCT:
      return aPortEnd || undefined;

    case ProductType.P2NNI:
      return bPortEnd || undefined;

    case ProductType.P2P:
    case ProductType.OpticalP2P:
      return pickLowestBearerType(aPortEnd, bPortEnd);

    default:
      return undefined;
  }
};
