import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAppState } from '../../../../reducers';
import Column from '../../../../shared/components/atoms/Column';
import CardRow from '../../../../shared/components/molecules/CardRow';
import TextInput from '../../../../shared/components/atoms/TextInput';
import { ProviderName } from '../../../../Quotes/types/store';
import { CloudConnect } from '../../../types/formFields';
import { updateCloudConnect } from '../../../actions';
import { cloudProviderForDisplay } from '../../shared/utils/cloudProviderForDisplay';
import { GroupedFields } from '../../../../shared/components/molecules/GroupedFields';

const inputIdentifierPrefix = 'cloud-connect_';

export const Edit: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const cloudConnect = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.cloudConnect);
  const order = useSelector((state: IAppState) => state.orderBuilder.order);

  const updated = (field: keyof CloudConnect, value: string): CloudConnect => ({
    ...order.cloudConnect,
    [field]: value,
  });

  const onChangeWithUpdate = (field: keyof CloudConnect, value: string) =>
    dispatch(updateCloudConnect(updated(field, value)));

  return (
    <CardRow title={`${cloudProviderForDisplay(cloudConnect.name)} configuration`}>
      <p>Cloud Connect key(s)</p>
      <div className="row no-gutters">
        <Column defaultWidth={5}>
          <TextInput
            inputIdentifierPrefix={inputIdentifierPrefix}
            fieldName="serviceKey1"
            onChange={(e) => onChangeWithUpdate('serviceKey1', e.target.value)}
            labelText="Service key 1 *"
            value={order.cloudConnect.serviceKey1}
          />
        </Column>

        {cloudConnect.diversified && (
          <Column defaultWidth={5} offsetDefaultWidth={1}>
            <TextInput
              inputIdentifierPrefix={inputIdentifierPrefix}
              fieldName="serviceKey2"
              onChange={(e) => onChangeWithUpdate('serviceKey2', e.target.value)}
              labelText="Service key 2 *"
              value={order.cloudConnect.serviceKey2}
              helperText="A second key is required, as this order includes cloud connect diversity"
            />
          </Column>
        )}
      </div>

      {cloudConnect.name === ProviderName.Azure && (
        <div className="mt-5">
          <p className="mb-0">Microsoft Azure ExpressRoute Inner VLAN id (c-tag)</p>
          <small>Please ensure your Layer3 devices support QinQ interface to enable this service.</small>

          <GroupedFields footerText="* A value is required for at least one of these fields." className="mt-3">
            <div className="row no-gutters">
              <Column defaultWidth={5}>
                <TextInput
                  inputIdentifierPrefix={inputIdentifierPrefix}
                  fieldName="privatePeering"
                  onChange={(e) => onChangeWithUpdate('privatePeering', e.target.value)}
                  labelText="Private Peering"
                  value={order.cloudConnect.privatePeering}
                />
              </Column>
              <Column defaultWidth={5} offsetDefaultWidth={1}>
                <TextInput
                  inputIdentifierPrefix={inputIdentifierPrefix}
                  fieldName="microsoftPeering"
                  onChange={(e) => onChangeWithUpdate('microsoftPeering', e.target.value)}
                  labelText="Microsoft Peering"
                  value={order.cloudConnect.microsoftPeering}
                />
              </Column>
            </div>
          </GroupedFields>
        </div>
      )}
    </CardRow>
  );
};
