import { useCallback, useEffect, useState } from 'react';
import { IOpticalDataCentre } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { setOpticalDataCentreList } from 'Quotes/actions';
import { selectAEnd } from 'Quotes/selectors';
import { doRequest } from 'Request';

interface IUseOpticalDataCentreFetch {
  hasLoaded: boolean;
  onSuccess(data: IOpticalDataCentre[]): void;
}

export const loadDataCentres = async () =>
  await doRequest<{ data: IOpticalDataCentre[] }>({
    path: '/optical-data-centres/',
  });

export const useOpticalDataCentreFetch = ({ hasLoaded, onSuccess }: IUseOpticalDataCentreFetch) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!hasLoaded && !loading && !error) {
      setLoading(true);
      (async () => {
        try {
          const response = await loadDataCentres();
          onSuccess(response.data);
        } catch (e) {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [hasLoaded, onSuccess, loading, error]);

  return { error, loading };
};

export const useReduxWrappedOpticalDataCentreFetch = () => {
  const dispatch = useDispatch();
  const setDataCentres2 = useCallback(
    (opticalDataCentreList: IOpticalDataCentre[]) => dispatch(setOpticalDataCentreList(opticalDataCentreList)),
    [dispatch]
  );
  const aEnd = useSelector(selectAEnd);

  useOpticalDataCentreFetch({
    hasLoaded: !!aEnd.optical.list,
    onSuccess: (data) => {
      setDataCentres2(data);
    },
  });

  return { list: aEnd.optical.list };
};
