import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import flow from 'lodash/flow';

import Alert from '../../../shared/components/atoms/Alert';
import EmptyBulkQuotesList from './EmptyBulkQuotesList';
import SpinnerWithText from '../../../shared/components/molecules/SpinnerWithText';
import BulkQuotesTable from './BulkQuotesTable';
import { IAppState } from '../../../reducers';
import { IBulkQuoteUploadState, UploadState } from '../../types/store';
import { pages, useTrackPage } from '../../../shared/utils/trackPage';
import { useTablePaging, SortDirection } from '../../../shared/hooks/useTablePaging';
import {
  transformCreatedByFilter,
  transformDateAtFilter,
  transformIdFilter,
} from 'shared/utils/shared-quote-order-table-headers';
import { usePreviousState } from 'shared/utils/customHooks';

interface IShowBulkQuotes {
  companyId: string | null;
  bulkQuoteUploadState: IBulkQuoteUploadState;
}

export const ERROR_MESSAGE = 'Error in fetching bulk quotes';

export const ShowBulkQuotes: FunctionComponent<React.PropsWithChildren<IShowBulkQuotes>> = ({
  companyId,
  bulkQuoteUploadState,
}) => {
  useTrackPage(pages.bulkQuotes);

  const paging = useTablePaging({
    baseUrl: `/customers/${companyId}/bulk-quote-requests`,
    filterTransform: flow([transformDateAtFilter, transformIdFilter, transformCreatedByFilter]),
    defaultSort: {
      key: 'created_at',
      direction: SortDirection.DESCENDING,
    },
  });

  const prevUploadState = usePreviousState(bulkQuoteUploadState);
  const needsRefetch =
    prevUploadState === UploadState.IN_PROGRESS &&
    (bulkQuoteUploadState === UploadState.SUCCESSFUL || bulkQuoteUploadState[0] === UploadState.ERRORED);

  useEffect(() => {
    if (needsRefetch) {
      paging.refresh();
    }
  }, [needsRefetch, paging]);

  if (paging.fetchAttempts === 1 && !paging.isLoading && paging.error) {
    return <Alert>{ERROR_MESSAGE}</Alert>;
  }

  if (paging.isLoading && paging.fetchAttempts === 0) {
    return <SpinnerWithText text="Retrieving bulk quotes" size="large" />;
  }

  if (paging.fetchAttempts === 1 && !paging.isLoading && paging.results.length === 0) {
    return <EmptyBulkQuotesList />;
  }

  return <BulkQuotesTable paging={paging} />;
};

const mapStateToProps = (state: IAppState) => ({
  companyId: state.user.companyData.selectedCompanyId,
  bulkQuoteUploadState: state.quoteBuilder.bulkQuoteUploadState,
});

export default connect(mapStateToProps)(ShowBulkQuotes);
