import React, { MutableRefObject, ReactNode } from 'react';

import cx from 'classnames';
import { HelperText, OptionalIndicator } from '../OptionalIndicator';
import { MaybeLabel } from '../shared/MaybeLabel';

export interface ISelectInputProps {
  forwardedRef?: MutableRefObject<any>;
  classNames?: string[];
  fieldName?: string;
  inputIdentifierPrefix?: string;
  optional?: boolean;
  labelText?: string;
  helperText?: string;
  readOnly?: boolean;
  id?: string;
  name?: string;
  children?: ReactNode;
  value?: string | string[] | number;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

const Select = ({
  forwardedRef,
  classNames,
  fieldName,
  inputIdentifierPrefix = '',
  labelText = '',
  name,
  readOnly,
  id,
  helperText,
  optional = false,
  value,
  children,
  onChange,
}: ISelectInputProps) => {
  const inputId = id ? id : `${inputIdentifierPrefix}${fieldName}`;

  return (
    <div className="form-group mb-1">
      <MaybeLabel inputId={inputId} labelText={labelText} hidden={value === ''} />
      <div className="select">
        <select
          ref={forwardedRef}
          className={cx('form-control', classNames)}
          id={inputId}
          onChange={onChange}
          name={name}
          value={value}
          disabled={readOnly}
        >
          {children}
        </select>
      </div>
      {optional && <OptionalIndicator />}
      {helperText && <HelperText text={helperText} />}
    </div>
  );
};

export default Select;
