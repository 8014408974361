import React from 'react';
import { ProductType } from 'Quotes/types/productTypes';
import { IAEndLocation, IQuote } from 'Quotes/types/store';
import { Charges } from './TillReceipt';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import MultiCircuitTotals from './MultiCircuitTotals';
import { SingleCircuitTotals } from './SingleCircuitTotals';
import DIAConfig from 'Quotes/QuoteBuilder/components/Configure/DIAConfig';
import { DualCircuitRouterConfigurations } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';

export interface TotalsSelectedPriceProps {
  amortised?: boolean | null;
  annual_discount: number | null;
  annual_ip_charge: number | null;
  fttp_aggregation_charge?: number | null;
  install_discount: number | null;
  mdia_annual_price: number | null;
  mdia_engineer_price: number | null;
  mdia_install_price: number | null;
  mdia_rack_mount_kit_price: number | null;
  mdia_router_price: number | null;
  net_amortised_install_price: number | null;
  net_install_price: number | null;
  shadow_vlan_price: number | null;
}

interface TotalsProps {
  charges: Charges;
  includeFTTPAggregationCharge: boolean;
  productType: ProductType | null | undefined;
  secondaryCharges?: Charges;
  secondarySelectedPrice?: TotalsSelectedPriceProps;
  selectedPrice: TotalsSelectedPriceProps;
  showShadowVLANPrice: boolean;
  diaConfig: DIAConfig;
  quote: IQuote;
}

export interface DIAConfig {
  ipType?: IPType;
  isManagedDIA: boolean;
  diaIPAllocation: IAEndLocation['dia_ip_allocation'];
  engineerInstallationRequired: boolean;
  routerChoice: DualCircuitRouterConfigurations | null | undefined;
}

const Totals: React.FC<TotalsProps> = ({
  selectedPrice,
  secondarySelectedPrice,
  showShadowVLANPrice,
  charges,
  secondaryCharges,
  includeFTTPAggregationCharge,
  productType,
  diaConfig,
  quote,
}) => {
  const priceAmortised = selectedPrice.amortised ?? true;

  return (
    <>
      {secondarySelectedPrice && secondaryCharges && featureFlag.isEnabled(Feature.SecondaryCircuits) ? (
        <>
          <MultiCircuitTotals
            selectedPrice={selectedPrice}
            secondarySelectedPrice={secondarySelectedPrice}
            showShadowVLANPrice={showShadowVLANPrice}
            diaConfig={diaConfig}
            charges={charges}
            includeFTTPAggregationCharge={includeFTTPAggregationCharge}
            productType={productType}
            secondaryCharges={secondaryCharges}
            priceAmortised={priceAmortised}
            quote={quote}
          />
        </>
      ) : (
        <>
          <SingleCircuitTotals
            charges={charges}
            includeFTTPAggregationCharge={includeFTTPAggregationCharge}
            productType={productType}
            selectedPrice={selectedPrice}
            showShadowVLANPrice={showShadowVLANPrice}
            priceAmortised={priceAmortised}
            diaConfig={diaConfig}
            quote={quote}
          />
        </>
      )}
    </>
  );
};
export default Totals;
