import { call, debounce, put } from 'redux-saga/effects';
import {
  AdminActionTypes,
  IAddUser,
  IBlockUser,
  IGetUser,
  ISendInvite,
  IUnblockUser,
  IUpdateUser,
} from './types/actions';
import { addUser } from './crud/saveUser';
import getUser from './crud/getUser';
import blockUser from './crud/blockUser';
import unblockUser from './crud/unblockUser';
import sendEmailInvite from './crud/sendEmailInvite';
import {
  addUserError,
  addUserStart,
  addUserSuccess,
  blockUserError,
  blockUserStart,
  blockUserSuccess,
  getUserError,
  getUserStart,
  getUserSuccess,
  resetRequestState,
  sendInviteError,
  sendInviteStart,
  sendInviteSuccess,
  setCurrentlyViewedUser,
  unblockUserError,
  unblockUserStart,
  unblockUserSuccess,
  updateUserError,
  updateUserStart,
  updateUserSuccess,
} from './actions';
import updateUser from './crud/updateUser';

export function* addUserSaga({ payload }: IAddUser): any {
  try {
    yield put(addUserStart());

    const {
      data: { id: userId },
    } = yield call(addUser, {
      userData: payload.userData,
      companiesRolesRows: payload.companiesRolesRows,
    });

    if (payload.userData.shouldSendEmail) {
      yield call(sendEmailInvite, userId);
    }

    yield put(addUserSuccess());
  } catch (error: any) {
    yield put(addUserError(error.response?.data));
  }
}

export function* getUserSaga({ payload: { id } }: IGetUser): any {
  yield put(getUserStart());
  try {
    const user = yield call(getUser, id);
    yield put(setCurrentlyViewedUser(user.data));

    yield put(getUserSuccess());
  } catch (error) {
    yield put(getUserError());
  }
}

export function* updateUserSaga({ payload: { companiesRolesRows, userData } }: IUpdateUser): any {
  yield put(updateUserStart());
  try {
    const updatedUser = yield call(updateUser, {
      companiesRolesRows,
      userData,
    });
    yield put(setCurrentlyViewedUser(updatedUser.data));

    yield put(updateUserSuccess());
  } catch (error) {
    yield put(updateUserError());
  }
}

export function* sendInviteSaga({ payload: { id } }: ISendInvite): any {
  yield put(resetRequestState());
  yield put(sendInviteStart());
  try {
    yield call(sendEmailInvite, id);
    yield put(sendInviteSuccess());
  } catch (error) {
    yield put(sendInviteError());
  }
}

export function* blockUserSaga({ payload: { id } }: IBlockUser): any {
  yield put(resetRequestState());
  yield put(blockUserStart());
  try {
    const user = yield call(blockUser, id);
    yield put(setCurrentlyViewedUser(user.data));

    yield put(blockUserSuccess());
  } catch (error) {
    yield put(blockUserError());
  }
}

export function* unblockUserSaga({ payload: { id } }: IUnblockUser): any {
  yield put(resetRequestState());
  yield put(unblockUserStart());
  try {
    const user = yield call(unblockUser, id);
    yield put(setCurrentlyViewedUser(user.data));

    yield put(unblockUserSuccess());
  } catch (error) {
    yield put(unblockUserError());
  }
}

export default function* rootSaga() {
  yield debounce(300, AdminActionTypes.ADD_USER, addUserSaga);
  yield debounce(300, AdminActionTypes.GET_USER, getUserSaga);
  yield debounce(300, AdminActionTypes.UPDATE_USER, updateUserSaga);
  yield debounce(300, AdminActionTypes.SEND_INVITE, sendInviteSaga);
  yield debounce(300, AdminActionTypes.BLOCK_USER, blockUserSaga);
  yield debounce(300, AdminActionTypes.UNBLOCK_USER, unblockUserSaga);
}
