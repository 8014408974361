import produce from 'immer';
import { UserActions, UserActionTypes } from './types/actions';
import { RequestState } from './types/requestState';
import { CompanyData, IUserAttributesBaseFrontendRoles } from './types/user';
import { IUserRoleFromBackend } from './types/role';
import { getRolesForCompany, groupCompaniesById } from './utils/roles';
import { AlertActionTypes, IMarkAlertAsRead } from 'Alerts/types/actions';
import { IBackendUserAttributes } from 'User/types/backendUser';

export interface IUserState {
  allRoles: IUserRoleFromBackend[];
  companyData: CompanyData;
  userData: IUserAttributesBaseFrontendRoles;
  whoAmIRequestState: RequestState;
  updateUserRequestState: RequestState;
}

export const initialState: IUserState = {
  allRoles: [],
  companyData: {
    companies: [],
    selectedCompanyId: null,
  },
  userData: {
    companyEmail: '',
    contactEmail: '',
    jobTitle: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    landlinePhone: '',
    lastVerificationEmailSent: '',
    enabled: true,
    updatedAt: '',
    createdAt: '',
    type: '',
    unreadAlerts: 0,
    unreadMessages: 0,
    roles: [],
    id: '',
    lastLoggedIn: '',
    wildcardRoles: [],
  },
  whoAmIRequestState: RequestState.NOT_REQUESTED,
  updateUserRequestState: RequestState.NOT_REQUESTED,
};

const setUnreadCounts = (draft: IUserState, attributes: IBackendUserAttributes) => {
  draft.userData.unreadMessages = attributes.unread_messages || 0;
  draft.userData.unreadAlerts = attributes.unread_events || 0;
};

const reducer = produce((draft: IUserState, action: UserActions | IMarkAlertAsRead): IUserState | void => {
  switch (action.type) {
    case UserActionTypes.GET_WHOAMI_STARTED:
      draft.whoAmIRequestState = RequestState.LOADING;
      break;

    case UserActionTypes.GET_WHOAMI_ERROR:
      draft.whoAmIRequestState = RequestState.ERROR;
      break;

    case UserActionTypes.GET_WHOAMI_SUCCESS:
      draft.whoAmIRequestState = RequestState.SUCCESS;
      break;

    case UserActionTypes.SET_UNREAD_COUNTS:
      setUnreadCounts(draft, action.payload.attributes);
      break;

    case UserActionTypes.SET_USER_DATA: {
      const { companyData, userData } = draft;
      const { attributes, type, id } = action.payload;

      companyData.companies = groupCompaniesById(attributes.roles);
      const selectedCompanyId =
        companyData.selectedCompanyId !== null &&
        companyData.companies.find((company) => company.id === companyData.selectedCompanyId)
          ? companyData.selectedCompanyId
          : companyData.companies[0].id;

      companyData.selectedCompanyId = selectedCompanyId;
      userData.companyEmail = attributes.username;
      userData.contactEmail = attributes.email;
      userData.jobTitle = attributes.job_title || '';
      userData.firstName = attributes.first_name;
      userData.lastName = attributes.last_name;
      userData.mobilePhone = attributes.mobile_number;
      setUnreadCounts(draft, action.payload.attributes);
      userData.landlinePhone = attributes.landline_number || '';
      userData.lastVerificationEmailSent = attributes.last_verification_email_sent || '';
      userData.enabled = attributes.enabled;
      userData.createdAt = attributes.created_at;
      userData.updatedAt = attributes.updated_at;
      userData.lastLoggedIn = attributes.last_logged_in;
      userData.type = type;
      userData.id = id;
      draft.allRoles = attributes.roles;
      userData.roles = getRolesForCompany(selectedCompanyId, attributes.roles);
      userData.wildcardRoles = attributes.roles.filter((item) => item.customer_id === '*').map((item) => item.role);
      break;
    }

    case UserActionTypes.UPDATE_USER_STARTED:
      draft.updateUserRequestState = RequestState.LOADING;
      break;

    case UserActionTypes.UPDATE_USER_ERROR:
      draft.updateUserRequestState = RequestState.ERROR;
      break;

    case UserActionTypes.UPDATE_USER_SUCCESS:
      draft.updateUserRequestState = RequestState.SUCCESS;
      break;

    case UserActionTypes.RESET_UPDATE_USER_REQUEST_STATE:
      draft.updateUserRequestState = RequestState.NOT_REQUESTED;
      break;

    case AlertActionTypes.MARK_ALERT_AS_READ: {
      draft.userData.unreadAlerts = action.payload.alertId === '*' ? 0 : Math.max(draft.userData.unreadAlerts - 1, 0);
      break;
    }

    case UserActionTypes.SET_SELECTED_COMPANY:
      draft.companyData.selectedCompanyId = action.payload.id;
      draft.userData.roles = getRolesForCompany(action.payload.id, draft.allRoles);
      break;

    default:
      return draft;
  }
}, initialState);

export default reducer;
