import React, { FunctionComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';
import StyledRadioButton from '../../../../../shared/components/molecules/RadioButton';
import { setDivercityAction } from '../../../../actions';
import { IAppState } from '../../../../../reducers';
import { Tooltip } from '../../../../../shared/components/atoms/Tooltip';
import { ProviderName } from '../../../../types/store';

interface IDiversity {
  className?: string;
  diversity: boolean;
  provider: string;
  theme: {
    colours: {
      grey70: string;
    };
  };
  setDiversity(diversity: boolean): void;
}

const Diversity: FunctionComponent<React.PropsWithChildren<IDiversity>> = ({
  className,
  diversity,
  setDiversity,
  provider,
}) => (
  <div className={`${className} mb-5`}>
    {provider === ProviderName.AWS && (
      <>
        <p className="divercity__pickDivercityPrompt">
          Cloud connect diversity
          <Tooltip text="Diversity is over the Neos Networks network and there will be an additional cost for AWS." />
        </p>
        <div className="divercity__radiogroup">
          <StyledRadioButton
            inline
            description="Yes"
            id="diversity-yes"
            onClick={() => {
              setDiversity(true);
            }}
            status={diversity ? 'selected' : 'notSelected'}
          />
          <StyledRadioButton
            inline
            description="No"
            id="diversity-no"
            onClick={() => {
              setDiversity(false);
            }}
            status={diversity ? 'notSelected' : 'selected'}
          />
        </div>
      </>
    )}
    {provider === ProviderName.Azure && <p className="azure-info">Microsoft Azure includes diversity in the price.</p>}
  </div>
);

const styledDiversity = styled(Diversity)`
  .divercity__pickDivercityPrompt {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 1rem;

    > span {
      margin-left: 0.3em;
    }
  }
  .divercity__radiogroup {
    display: flex;
  }

  .azure-info {
    color: ${(props) => props.theme.colours.grey70};
  }
`;

const mapStateToProps = (state: IAppState) => ({
  provider: state.quoteBuilder.quote.location.aEnd.cloudConnect.name,
  diversity: state.quoteBuilder.quote.location.aEnd.cloudConnect.diversified,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  setDiversity: (diversity: boolean) => dispatch(setDivercityAction(diversity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(styledDiversity);
