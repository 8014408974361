import { IMeta } from '../../types/quoteRecordAttributesBase';

const getNNILabel = (nniReference: string | null | undefined, nniPopName: string | null | undefined): string | null => {
  if (nniPopName && nniReference) {
    return `${nniPopName} - ${nniReference}`;
  }
  if (nniReference) {
    return nniReference;
  }
  return null;
};

export const getSelectedShadowNNIReference = (quoteMeta: IMeta): string | null => {
  if (!quoteMeta) {
    return null;
  }

  if (quoteMeta.a_end_nni_shadow_reference) {
    return quoteMeta.a_end_nni_shadow_reference;
  }

  if (quoteMeta.a_end_nni_shadow_data_centre_reference) {
    return quoteMeta.a_end_nni_shadow_data_centre_reference;
  }

  return null;
};

export const getNNIOrDataCentreLabel = (
  nniPopName: string | null | undefined,
  nniReference: string | null | undefined
) => {
  const nniLabel = getNNILabel(nniReference, nniPopName);
  if (nniLabel !== null) {
    return nniLabel;
  } else {
    return 'Unavailable';
  }
};
