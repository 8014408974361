import { doRequest, Methods } from '../../../Request/index';

const unblockUser = (userId: string) => {
  return doRequest({
    method: Methods.POST,
    path: `/users/${userId}/enable`,
  });
};

export default unblockUser;
