import React, { FunctionComponent, useCallback, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { pick } from 'lodash';
import PostcodeCapture from '../PostcodeCapture';
import { IOpenReachAddressSite, IPAFAddressSite, isPAFSite } from 'shared/types/postcodeResults';
import {
  concatenateOpenReachAddress,
  findAddressByUdprn,
  findOnNetAddressByItsLabel,
  isFTTXCompatibleOpenreachLocation,
  isPAFAddressEmpty,
  openreachGoldFilter,
} from 'shared/utils/addresses/helperFunctions';
import { IOnNetSite, isOnNetSite } from 'shared/types/onNetSite';
import AddressesCombined from '../AddressesCombined';
import { emptyAddressesFound } from 'Quotes/reducer';
import { AddressesFound, AddressType, ILocation } from 'Quotes/types/store';
import { IDropdownOption } from 'shared/components/molecules/Dropdown';
import { OpenreachAddressCapture } from '../OpenreachAddressCapture';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import Column from 'shared/components/atoms/Column';
import Icon from 'shared/components/atoms/Icon';
import { ProductType } from 'Quotes/types/productTypes';
import { productTypeHasFTTX } from 'Quotes/utils/productTypeHasFTTX';
import { toNormalised } from 'postcode';
import { ISetOpenreachAddress } from 'Quotes/types/actions';
import { CompatibleFTTXLocation } from 'Location/CompatibleFTTXLocation';
import { GoogleMapsLocation } from 'shared/components/organisms/GoogleMapsLocation';
import {
  mapIPAFAddressSiteResponsesToMapMarker,
  mapIPAFAddressSiteResponsesToMapMarkers,
} from 'shared/utils/mapAddressesToMarkers';
import { QUOTE_ON_NET_3RD_PARTY_MESSAGE } from 'shared/constants';
import { NNATCapablePostcode } from 'Quotes/shared/components/NNAT/NNATAlerts';

export type DropdownOption = IDropdownOption & {
  addressType: AddressType;
  onNetType?: ILocation['onNetType'];
};

interface ILocationByPostcodeProps {
  className?: string;
  addressesFound: AddressesFound;
  addressNotListed: boolean;
  draftPostcode: string;
  postcode: string;
  productType: ProductType;
  selectedAddress: IPAFAddressSite | IOnNetSite | null;
  referenceAddress?: IPAFAddressSite | IOnNetSite | null;
  selectedOpenreachAddress: IOpenReachAddressSite | null;
  onAddressesFound(addresses: AddressesFound): void;
  onAddressNotListed(notListed: boolean): void;
  onOpenreachAddressSubmit(address: ISetOpenreachAddress['payload']['address'] | null): void;
  onFullAddressSubmit(address: IPAFAddressSite | IOnNetSite | null, onNetType?: ILocation['onNetType']): void;
  onDraftPostcodeChange(postcode: string): void;
  onPostcodeSubmit(postcode: string): void;
  addressRetrieval(retrieve: boolean, onNetAndPaf: boolean): void;
}

const LocationByPostcode: FunctionComponent<React.PropsWithChildren<ILocationByPostcodeProps>> = ({
  addressesFound,
  addressNotListed,
  className,
  draftPostcode,
  postcode,
  productType,
  selectedAddress,
  referenceAddress = null,
  selectedOpenreachAddress,
  onAddressesFound,
  onAddressNotListed,
  onDraftPostcodeChange,
  onPostcodeSubmit,
  onFullAddressSubmit,
  onOpenreachAddressSubmit,
  addressRetrieval,
}) => {
  const theme = useTheme();
  const [validPostcodeFound, setValidPostcodeFound] = useState(false);
  const [hasAvailableNNAT, setHasNNATAvailable] = useState<boolean>(false);

  const addressRetrievalOnNetAndPaf = useCallback(
    (retrieve: boolean) => {
      addressRetrieval(retrieve, true);
    },
    [addressRetrieval]
  );

  const addressRetrievalOpenreach = useCallback(
    (retrieve: boolean) => {
      addressRetrieval(retrieve, false);
    },
    [addressRetrieval]
  );

  const onResults = (postCode: string, addresses: AddressesFound) => {
    const pafAddress = selectedAddress as IPAFAddressSite;
    const onNetAddress = selectedAddress as IOnNetSite;

    setHasNNATAvailable(!!addresses.hasNNATAddresses);

    if (
      !pafAddress?.attributes?.udprn &&
      !onNetAddress?.attributes?.colt_building_id &&
      !onNetAddress?.attributes?.pop_id
    ) {
      onPostcodeSubmit(postCode);
    }

    setValidPostcodeFound(true);
    onAddressesFound(addresses);
  };

  const onPostcodeError = () => {
    onAddressesFound(emptyAddressesFound);
    onFullAddressSubmit(null);
    onPostcodeSubmit('');
    setValidPostcodeFound(false);
  };

  const onAddressSelect = (address: DropdownOption | null) => {
    if (address === null) {
      onFullAddressSubmit(null);
      return;
    }

    let addressFound: IPAFAddressSite | IOnNetSite | undefined;
    let onNetType: ILocation['onNetType'] = null;

    if (address.addressType === AddressType.ON_NET) {
      addressFound = findOnNetAddressByItsLabel(address.label, addressesFound.onNet);
      onNetType = address.onNetType || null;
    } else {
      addressFound = findAddressByUdprn(address.value, addressesFound.paf);
    }

    if (addressFound !== undefined) {
      onFullAddressSubmit(addressFound, onNetType);
    }
  };

  const onPostcodeChange = (postalCode: string): void => {
    if (!postcodesMatch(draftPostcode, postalCode)) {
      onDraftPostcodeChange(postalCode);
      setValidPostcodeFound(false);
    }
  };

  const postcodesMatch = (draftPostalCode: string, postalCode: string): boolean =>
    postalCode !== '' && draftPostalCode.trim().replace(/\s/g, '') === postalCode.trim().replace(/\s/g, '');

  const mapMarkersFilteredBySelectedAddressPAF = () => {
    const markers = mapIPAFAddressSiteResponsesToMapMarkers(addressesFound.paf!);

    if (selectedAddress?.id)
      return markers.filter((marker) => {
        return marker.id === selectedAddress?.id;
      });

    return markers;
  };

  const changeSelectedAddressPAFOnMarkerClick = (addressId: string) => {
    const address = addressesFound.paf.find((a) => a.id === addressId)!;
    onFullAddressSubmit(address, null);
  };

  const onPostcodeCaptureChange = (value: string) => {
    onDraftPostcodeChange(value);
  };

  const postcodesAreMatching = postcodesMatch(draftPostcode, postcode);
  return (
    <div className={className}>
      <PostcodeCapture
        productType={productType}
        addressRetrieval={addressRetrievalOnNetAndPaf}
        initialPostcode={draftPostcode}
        addressesFound={pick(addressesFound, 'onNet', 'paf')}
        selectedAddress={selectedAddress}
        onResults={onResults}
        onPostcodeChange={onPostcodeChange}
        onPostcodeError={onPostcodeError}
        validPostcodeFound={validPostcodeFound}
        onChange={onPostcodeCaptureChange}
      />

      <div style={{ height: '500px' }} className={'mt-2 mb-3'}>
        <GoogleMapsLocation
          center={theme.content.defaultMapsPosition}
          markersOptions={mapMarkersFilteredBySelectedAddressPAF()}
          onMarkerClick={changeSelectedAddressPAFOnMarkerClick}
          referenceMarkerOptions={mapIPAFAddressSiteResponsesToMapMarker(referenceAddress)}
        />
      </div>

      {postcodesAreMatching && hasAvailableNNAT && <NNATCapablePostcode />}

      {postcodesAreMatching && Object.values(pick(addressesFound, 'onNet', 'paf')).flat().length > 0 && (
        <AddressesCombined
          addresses={addressesFound}
          addressNotListed={addressNotListed}
          onAddressNotListed={onAddressNotListed}
          onSelect={onAddressSelect}
          selectedAddress={selectedAddress}
        />
      )}

      {isPAFSite(selectedAddress) &&
        isPAFAddressEmpty(selectedAddress) &&
        isFTTXCompatibleOpenreachLocation(selectedOpenreachAddress) && (
          <Alert alertType={Alerts.INFO}>
            <p className="mb-0">
              This quote was created using limited location/address information. If you wish to change the address,
              please re-submit the postcode and choose your location as required.
            </p>
          </Alert>
        )}

      {productTypeHasFTTX(productType) && postcodesAreMatching && !selectedAddress && (
        <Alert alertType={Alerts.INFO}>
          <p className="mb-0 postcode-only-notice">
            <strong>Select your street address to quote for EoFTTC/P services</strong>
            <br />
            Select the street address (and location, if required) to check availability for EoFTTC or EoFTTP. If you
            can&apos;t find the address but still wish to quote for these services, speak to your Account Manager.
          </p>
        </Alert>
      )}

      {productTypeHasFTTX(productType) && isOnNetSite(selectedAddress) && postcodesAreMatching && (
        <Alert alertType={Alerts.INFO}>
          <div className="row no-gutters on-net-notice">
            <Column defaultWidth={1} classNames={['text-right', 'on-net-info-icon']}>
              <Icon name="info_outline" />
            </Column>
            <Column defaultWidth={11}>
              <p className="pl-2 pt-3">{QUOTE_ON_NET_3RD_PARTY_MESSAGE}</p>
            </Column>
          </div>
        </Alert>
      )}

      {isPAFSite(selectedAddress) &&
        isPAFAddressEmpty(selectedAddress) &&
        isFTTXCompatibleOpenreachLocation(selectedOpenreachAddress) && (
          <div className="openreach-readonly-wrapper">
            <CompatibleFTTXLocation
              address={`${concatenateOpenReachAddress(selectedOpenreachAddress.attributes)}, ${toNormalised(
                draftPostcode
              )}`}
              selectedOpenreachAddress={selectedOpenreachAddress}
            />
          </div>
        )}

      {productTypeHasFTTX(productType) &&
        isPAFSite(selectedAddress) &&
        !isPAFAddressEmpty(selectedAddress) &&
        postcodesAreMatching && (
          <OpenreachAddressCapture
            addressRetrieval={addressRetrievalOpenreach}
            addresses={addressesFound}
            addressNotListed={addressNotListed}
            onAddressNotListed={onAddressNotListed}
            onResults={onResults}
            resultsFilter={openreachGoldFilter}
            onOpenreachAddressSubmit={onOpenreachAddressSubmit}
            pafAddress={selectedAddress.attributes}
            postcode={postcode}
            selectedAddress={selectedOpenreachAddress}
          />
        )}
    </div>
  );
};

const StyledLocationByPostcode = styled(LocationByPostcode)`
  top: -4px;
  z-index: 0;

  .on-net-info-icon {
    padding-top: 1em;
  }

  .openreach-readonly-wrapper {
    background: ${(props) => props.theme.colours.mainBackgroundColor};
    padding: 1.6em 0.8em 0.1em 0.8em;
  }
`;

export default StyledLocationByPostcode;
