import { calculateUpFront } from 'Quotes/QuoteBuilder/utils/price';
import React from 'react';
import AnnualCharges from './AnnualCharges';
import UpfrontCosts, { IMDIAPrices } from './UpfrontCosts';
import { ProductType } from 'Quotes/types/productTypes';
import { Charges } from './TillReceipt';
import { DIAConfig, TotalsSelectedPriceProps } from './Totals';
import { calculatePrimaryAnnualExtraTotals } from 'Quotes/QuoteBuilder/utils/costsUtilities';
import {
  MDIA_ENGINEER_CHARGE,
  MDIA_RACK_MOUNT_KIT_CHARGE,
} from 'Quotes/QuoteBuilder/components/Price/components/TillReceiptInternal/TillReceiptInternal';
import { IQuote } from 'Quotes/types/store';

interface SingleCircuitTotalsProps {
  charges: Charges;
  includeFTTPAggregationCharge: boolean;
  productType: ProductType | null | undefined;
  selectedPrice: TotalsSelectedPriceProps;
  showShadowVLANPrice: boolean;
  priceAmortised: boolean;
  diaConfig: DIAConfig;
  quote: IQuote;
}

export function getMdiaPrices(diaConfig: DIAConfig, selectedPrice: TotalsSelectedPriceProps): IMDIAPrices | undefined {
  return diaConfig.isManagedDIA
    ? {
        engineerInstallation: diaConfig.engineerInstallationRequired ? selectedPrice.mdia_engineer_price ?? 0 : 0,
        rackMountKit: selectedPrice.mdia_rack_mount_kit_price ?? 0,
        router: selectedPrice.mdia_router_price ?? selectedPrice.mdia_install_price ?? 0,
      }
    : undefined;
}

export function getMdiaPricesSecondary(
  diaConfig: DIAConfig,
  selectedPrice: TotalsSelectedPriceProps,
  quote: IQuote
): IMDIAPrices | undefined {
  const options = quote.location.aEnd.secondRouterOptions;
  const engineerInstallationRequired = options?.engineerInstallationRequired;
  const rackMountKitRequired = options?.rackMountKitRequired;
  return diaConfig.isManagedDIA
    ? {
        engineerInstallation: engineerInstallationRequired ? MDIA_ENGINEER_CHARGE : 0,
        rackMountKit: rackMountKitRequired ? MDIA_RACK_MOUNT_KIT_CHARGE : 0,
        // The secondary router cost is the same as the primary router cost
        router: Number(selectedPrice.mdia_router_price ?? selectedPrice.mdia_install_price ?? 0),
      }
    : undefined;
}

export const SingleCircuitTotals: React.FC<SingleCircuitTotalsProps> = ({
  selectedPrice,
  showShadowVLANPrice,
  charges,
  includeFTTPAggregationCharge,
  productType,
  priceAmortised,
  diaConfig,
  quote,
}) => {
  const mdiaPrices: IMDIAPrices | undefined = getMdiaPrices(diaConfig, selectedPrice);
  const annualCharges = calculatePrimaryAnnualExtraTotals(selectedPrice);
  return (
    <div data-testid="total-prices">
      <div className="annual-suppliers-prompt mb-2">Total</div>
      <UpfrontCosts
        selectedPrice={selectedPrice}
        showShadowVLANPrice={showShadowVLANPrice}
        calculateUpFront={calculateUpFront}
        priceAmortised={priceAmortised}
        mdiaPrices={mdiaPrices}
        quote={quote}
        label={'Total'}
      />

      <AnnualCharges
        charges={charges}
        includeFTTPAggregationCharge={includeFTTPAggregationCharge}
        productType={productType}
        mdiaPrices={mdiaPrices}
        ipType={diaConfig.ipType}
        isManagedDIA={diaConfig.isManagedDIA}
        diaIPAllocation={diaConfig.diaIPAllocation}
        engineerInstallationRequired={diaConfig.engineerInstallationRequired}
        priceAmortised={priceAmortised}
        annualCharges={annualCharges}
      />
    </div>
  );
};
