import throttle from 'lodash/throttle';

import { LocalStorageKeys } from '../../User/types/localStorageTypes';

const SESSION_TIMER_KEY = LocalStorageKeys.SESSION_TIMER_KEY;
const REFRESH_SESSION_DURATION = 1000;
export const SESSION_TIMEOUT = process.env.NODE_ENV === 'development' ? 9e9 : 1000 * 60 * 55;

export const sessionTimeValid = (): boolean | null => {
  const sessionTimer = localStorage.getItem(SESSION_TIMER_KEY);
  return sessionTimer ? Date.now().valueOf() - new Date(sessionTimer).valueOf() < SESSION_TIMEOUT : null;
};

export const refreshSessionTimer: () => void = () => {
  localStorage.setItem(SESSION_TIMER_KEY, new Date().toISOString());
};

export const onSessionActive = throttle(
  () => {
    if (sessionTimeValid()) return refreshSessionTimer();
  },
  REFRESH_SESSION_DURATION,
  { trailing: true }
);

export const clearSessionTimer: () => void = () => {
  localStorage.removeItem(SESSION_TIMER_KEY);
};
