import { sessionTimeValid } from 'App/utils/sessionTimer';
import { useEffect, useState } from 'react';

export const useSessionTimeCheck = () => {
  const [activeSession, setActiveSession] = useState<null | boolean>(null);

  useEffect(() => {
    setActiveSession(!!sessionTimeValid());
  }, []);

  return {
    activeSession,
  };
};
