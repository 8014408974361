import { doRequest, Methods } from '../../../Request/index';

const sendEmailInvite = (userId: string) => {
  return doRequest({
    method: Methods.POST,
    path: `/users/${userId}/confirmation-email`,
  });
};

export default sendEmailInvite;
