import {
  DateFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
} from '../../../../shared/components/organisms/MultiSelectTable/filters';
import { showFullName } from '../../../../shared/utils/nameHelper';
import { IUserBackendProfile } from '../../../../User/types/backendUser';
import { determineBackendUserStatus } from '../../../../User/utils/determineUserStatus';
import { shortenId } from '../../../../shared/utils/idFormatter';
import TableNames from '../../../../Tables/types/tableNames';

const overflowVisibleStyle = { overflow: 'visible' };
const tableName = TableNames.usersTable;

const headers = [
  {
    Filter: DefaultColumnFilter,
    Header: 'User ID',
    accessor: (row: IUserBackendProfile) => shortenId(row.id),
    id: 'user_id',
    width: 90,
    tableName,
    storeKeyName: 'userId',
  },
  {
    Filter: DefaultColumnFilter,
    Header: 'Name',
    accessor: (row: IUserBackendProfile) => showFullName(row.attributes.first_name, row.attributes.last_name),
    id: 'name',
    minWidth: 280,
    tableName,
    storeKeyName: 'name',
  },
  {
    Filter: DefaultColumnFilter,
    Header: 'Username',
    accessor: 'attributes.username',
    id: 'username',
    minWidth: 300,
    tableName,
    storeKeyName: 'username',
  },
  {
    Filter: SelectColumnFilter,
    Header: 'Status',
    accessor: (row: IUserBackendProfile) => determineBackendUserStatus(row.attributes),
    filter: 'equalsOrEmptyFilter',
    id: 'status',
    width: 110,
    tableName,
    storeKeyName: 'status',
  },
  {
    Header: 'Last logged in',
    accessor: (row: IUserBackendProfile) => (row.attributes.last_logged_in ? row.attributes.last_logged_in : ''),
    id: 'last_logged_in',
    Filter: DateFilter,
    style: overflowVisibleStyle,
    minWidth: 90,
    tableName,
    storeKeyName: 'lastActive',
  },
];

export default headers;
