import React from 'react';
import HeaderWithChildrenWrapper from '../shared/components/molecules/HeaderWithChildrenWrapper';

const NotFound = () => {
  return (
    <HeaderWithChildrenWrapper header="Not Found">
      <p>Page was not found</p>
    </HeaderWithChildrenWrapper>
  );
};

export default NotFound;
