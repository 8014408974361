import { publishQuote } from 'Quotes/actions';
import {
  selectIsInternalQuote,
  selectPublishing,
  selectIsQuoteUpdating,
  selectPricingProgress,
} from 'Quotes/selectors';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import Column from 'shared/components/atoms/Column';
import styled from 'styled-components';
import Spinner from 'shared/components/atoms/Spinner';
import classNames from 'classnames';
import Alert from 'shared/components/atoms/Alert';

export const PUBLISH_FAILURE_TEXT =
  'Failed to publish quote. Try again later. If the problem persists, please contact your Account Manager.';

const InternalQuotePanelContainer: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const { inProgress, error } = useSelector(selectPublishing);
  const isInternalQuote = useSelector(selectIsInternalQuote);
  const quoteUpdating = useSelector(selectIsQuoteUpdating);
  const { fetchingPrice } = useSelector(selectPricingProgress);

  if (!isInternalQuote) {
    return null;
  }

  return (
    <InternalQuotePanel
      disabled={quoteUpdating || !!fetchingPrice}
      error={!!error}
      loading={!!inProgress}
      onPublish={() => {
        dispatch(publishQuote());
      }}
    />
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.colours.primaryC5};
  padding: 20px;
  border-radius: 5px;
  color: #333;
  font-size: 18px;

  .loading.publish_btn_top[disabled] {
    opacity: 1;
  }
`;

interface Props {
  disabled: boolean;
  loading: boolean;
  error: boolean;
  onPublish(): void;
}

const InternalQuotePanel: FC<React.PropsWithChildren<Props>> = ({ onPublish, loading, error, disabled }) => {
  return (
    <Container>
      <div className="row">
        <Column mdWidth={8} lgWidth={9} xlWidth={10}>
          <p className="mb-0">
            This quote is visible only to Neos Networks users. Customer users will not see this quote (and you
            won&apos;t be able to proceed to order) until you publish it. It remains editable until lapsed beyond 30
            days.
          </p>
        </Column>
        <Column
          mdWidth={4}
          lgWidth={3}
          xlWidth={2}
          classNames={['text-md-right', 'pr-0', 'pt-3', 'pt-md-0', 'pl-xl-0']}
        >
          <Button
            className={classNames('outline publish_btn_top', {
              'pt-0': loading,
            })}
            disabled={loading || disabled}
            mainStyle={ButtonMainStyle.PrimaryOutline}
            onClick={onPublish}
          >
            {loading && <Spinner size="small" colour="secondary" />}
            {!loading && 'Publish'}
          </Button>
        </Column>
      </div>
      {error && <Alert className="mb-0 mt-3">{PUBLISH_FAILURE_TEXT}</Alert>}
    </Container>
  );
};

export default InternalQuotePanelContainer;
export { InternalQuotePanel };
