import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import Alert from '../../../shared/components/atoms/Alert';
import Spinner from '../../../shared/components/molecules/SpinnerWithText';
import { IOpticalDataCentre } from '../types';
import { useOpticalDataCentreFetch } from '../hooks';
import Column from '../../../shared/components/atoms/Column';
import DataCentreSelect, { DEFAULT_OPTICAL_DATA_CENTRE_PLACEHOLDER } from '../OpticalDataCentreSelect';
import DataCentreNotListed from '../../DataCentreNotListed';
import EthernetPoint, { EndType } from '../../EthernetPoint';
import addressNotListedMenu from '../../AddressNotListed/addressNotListedMenu';

interface IOpticalDataCentresCaptureProps {
  className?: string;
  selectedBEndId?: string;
  selectedAEndId?: string;
  dataCentreNotListed: boolean;
  selectedPostcode?: string | null;
  opticalDataCentreListAEnd?: IOpticalDataCentre[];
  opticalDataCentreListBEnd?: IOpticalDataCentre[];
  onOpticalDataCentreFetchSuccess(opticalDataCentreList: IOpticalDataCentre[]): void;
  onDataCentreNotListed(notListed: boolean): void;
  onAEndSelected(id: string, reference: string): void;
  onBEndSelected(id: string, reference: string): void;
}

const dataCentreNotListedPlaceholder = 'Data centre not listed';

export const OpticalDataCentresCapture: FC<IOpticalDataCentresCaptureProps> = ({
  className,
  opticalDataCentreListAEnd,
  opticalDataCentreListBEnd,
  onOpticalDataCentreFetchSuccess,
  onAEndSelected,
  onBEndSelected,
  selectedAEndId,
  selectedBEndId,
  onDataCentreNotListed,
  dataCentreNotListed,
  selectedPostcode,
}) => {
  const [placeholder, setPlaceholder] = useState<string>(DEFAULT_OPTICAL_DATA_CENTRE_PLACEHOLDER);

  const { error, loading } = useOpticalDataCentreFetch({
    hasLoaded: !!opticalDataCentreListAEnd,
    onSuccess: onOpticalDataCentreFetchSuccess,
  });

  const [agreed, setAgreed] = useState<boolean>(false);

  useEffect(() => {
    setAgreed(false);
  }, [dataCentreNotListed]);

  const Menu = addressNotListedMenu({
    addressNotListed: dataCentreNotListed,
    onAddressNotListed: onDataCentreNotListed,
    selectedPlaceholder: dataCentreNotListedPlaceholder,
    deselectedPlaceholder: DEFAULT_OPTICAL_DATA_CENTRE_PLACEHOLDER,
    checkboxPlaceholder: dataCentreNotListedPlaceholder,
    setPlaceholder,
  });

  if (loading) {
    return (
      <div className={className}>
        <Spinner text="Retrieving Data Centres" className="loading-spinner" size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert>
        Error retrieving Data Centres. Please try again later. If the problem persists, please contact your Account
        Manager.
      </Alert>
    );
  }

  if (opticalDataCentreListAEnd && opticalDataCentreListBEnd) {
    return (
      <div className={className}>
        <div className="row">
          <Column defaultWidth={6} classNames={['position-static location-a-end']}>
            <DataCentreSelect
              selectedId={selectedAEndId}
              labelText="A-End Data Centre"
              onChange={(opticalDataCentre) => {
                opticalDataCentre
                  ? onAEndSelected(opticalDataCentre.value, opticalDataCentre.label)
                  : onAEndSelected('', '');
              }}
              opticalDataCentreList={opticalDataCentreListAEnd}
            />
          </Column>
          <Column defaultWidth={6} classNames={['position-static location-b-end']}>
            <DataCentreSelect
              customComponents={{ Menu }}
              selectedId={selectedBEndId}
              labelText="B-End Data Centre"
              placeholder={placeholder}
              onChange={(opticalDataCentre) => {
                opticalDataCentre
                  ? onBEndSelected(opticalDataCentre.value, opticalDataCentre.label)
                  : onBEndSelected('', '');
              }}
              opticalDataCentreList={opticalDataCentreListBEnd}
            />
            <div className="address-not-listed">
              {dataCentreNotListed && !agreed && !selectedPostcode && (
                <DataCentreNotListed
                  onClick={() => {
                    setAgreed(true);
                  }}
                />
              )}
              {((dataCentreNotListed && agreed) || selectedPostcode) && (
                <EthernetPoint end={EndType.BEnd} title="B-End Location" />
              )}
            </div>
          </Column>
        </div>
      </div>
    );
  }

  return null;
};

const StyledOpticalDataCentresCapture = styled(OpticalDataCentresCapture)`
  .loading-spinner {
    margin-top: 0;
  }
  .address-not-listed {
    margin-top: 2rem;
  }
`;

export default StyledOpticalDataCentresCapture;
