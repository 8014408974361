import React, { FunctionComponent, ReactNode } from 'react';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import SectionHeader from 'shared/components/atoms/SectionHeader';
import Column from 'shared/components/atoms/Column';
import Icon from 'shared/components/atoms/Icon';
import styled from 'styled-components';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import { SupplierList } from 'Admin/preferences/SupplierPreferences/SupplierPreferences';
import { presentSupplierName } from 'Quotes/utils/supplierHelpers';

type Props = {
  className?: string;
  introText: ReactNode;
  suppliers: SupplierList[];
  updatePageStatus(readOnly: boolean, saved: boolean): void;
  saved: boolean;
};

const UnstyledReadOnlyPreferences: FunctionComponent<React.PropsWithChildren<Props>> = ({
  className,
  introText,
  suppliers,
  updatePageStatus,
  saved,
}) => {
  const columns = 2;

  const supplierColumns = [...Array(columns).keys()].map((c) => suppliers.filter((_, i) => i % columns === c));

  const onEditButtonClick = () => {
    updatePageStatus(false, false);
  };

  return (
    <div className={className}>
      <div className="row no-gutters button-wrapper">
        <div className="col-1 offset-10">
          <Button
            data-testid="preferences-button-edit"
            mainStyle={ButtonMainStyle.PrimaryOutline}
            disabled={false}
            onClick={onEditButtonClick}
          >
            Edit
          </Button>
        </div>
      </div>

      <SectionHeader text="Supplier Preferences" />

      {saved && <Alert alertType={Alerts.SUCCESS}>Your preferences have been saved</Alert>}

      {introText}

      <Column defaultWidth={12}>
        <div data-testid="preferences-readonly" className="row no-gutters">
          {supplierColumns.map((supplier_column, i) => {
            return (
              <Column key={'col' + i} defaultWidth={6} classNames={['admin']}>
                {supplier_column.map((supplier, i_inner) => {
                  return (
                    <div key={'col' + i + '-' + i_inner} className="row no-gutters">
                      <span className="option">
                        {<Icon name={supplier.status ? 'done' : 'close'} className="grey10" />}
                        {presentSupplierName(supplier.value)}
                      </span>
                    </div>
                  );
                })}
              </Column>
            );
          })}
        </div>
      </Column>
    </div>
  );
};

const ReadOnlyPreferences = styled(UnstyledReadOnlyPreferences)`
  .option {
    i {
      margin-right: 0.2em;
      vertical-align: middle;
    }
  }
`;

export { ReadOnlyPreferences };
