import React, { FunctionComponent } from 'react';
import Column from 'shared/components/atoms/Column';
import CardRow from 'shared/components/molecules/CardRow';
import Form from '../Form';
import { IOrder } from 'Order/types/store';
import { BillingFrequency } from '../BillingFrequency';
import { SUMMARY_TEXT } from '../Summary';

interface IEditProps {
  order: IOrder;
}

const Edit: FunctionComponent<React.PropsWithChildren<IEditProps>> = ({ order }) => {
  const { billingContactAndAddress } = order;
  return (
    <CardRow title="Billing details">
      <div className="row no-gutters">
        <Column defaultWidth={4} offsetDefaultWidth={1}>
          {SUMMARY_TEXT}
        </Column>
        <Column defaultWidth={6} offsetDefaultWidth={1}>
          <Form billingContactAndAddress={billingContactAndAddress} />
        </Column>
      </div>
      <BillingFrequency />
    </CardRow>
  );
};

export default Edit;
