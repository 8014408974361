import { Feature, FeatureFlagEnvironment } from '../types';
import { featureFlags } from '../featureFlags';
import getAttributeForFeature from 'FeatureFlags/utils/getAttributeForFeature';

export const supportsOverridingFeatureFlags = (environment: FeatureFlagEnvironment) => {
  return [
    FeatureFlagEnvironment.dev,
    FeatureFlagEnvironment.integration,
    FeatureFlagEnvironment.testing,
    FeatureFlagEnvironment.staging,
  ].includes(environment);
};

/**
 * @deprecated use `featureFlag.isEnabled` instead
 * @param feature
 * @returns
 */
const hasFeatureEnabled = (feature: Feature): boolean => isEnabled(feature);

const isEnabled = (feature: Feature): boolean => {
  const environment = getEnvironment();

  const defaultValue = featureFlags[feature].environments[environment].enabled;

  if (supportsOverridingFeatureFlags(environment)) {
    const storedValue = loadStateFromLocalStorage(feature);
    return storedValue === undefined ? defaultValue : storedValue;
  }

  return defaultValue;
};

export const loadStateFromLocalStorage = (feature: Feature): boolean | undefined => {
  const state = localStorage.getItem(feature);
  switch (state) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
};

export default hasFeatureEnabled;

export const featureFlag = {
  isEnabled: (feature: Feature): boolean => isEnabled(feature),
  getAttribute: <T>(feature: Feature, attribute: string): T | undefined =>
    getAttributeForFeature(feature, attribute, undefined),
  isAttributeEnabled: (feature: Feature, attribute: string): boolean =>
    isEnabled(feature) && !!getAttributeForFeature(feature, attribute),
};

export function getEnvironment() {
  return process.env.REACT_APP_FEATURE_FLAGS as FeatureFlagEnvironment;
}

export const useFeatureEnabled = (feature: Feature) => {
  return featureFlag.isEnabled(feature) ? {} : null;
};
