import { IPriceData } from 'Quotes/types/store';
import React, { FC, useEffect, useState } from 'react';
import { Supplier } from 'Quotes/types/supplier';
import { ProductType } from 'Quotes/types/productTypes';
import { ListAndSliderViewToggle } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Toggle';
import SupplierSelectionDropdown from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelectionDropdown';
import { ProductSubTypeFilter } from 'Quotes/QuoteBuilder/components/Price/components/ProductSubTypeFilter';
import { BandwidthFilter } from 'Quotes/QuoteBuilder/components/Price/components/BandwidthFilter';
import { ContractTermFilter } from 'Quotes/QuoteBuilder/components/Price/components/ContractTermFilter';
import styled from 'styled-components';
import { presentSupplierName } from 'Quotes/utils/supplierHelpers';

const FilterWrapper: FC<React.PropsWithChildren<unknown>> = (props) => (
  <FilterRow>
    <FilterLabel>Filter</FilterLabel>
    {props.children}
  </FilterRow>
);

interface FiltersParams {
  readOnly: undefined | boolean;
  suppliersSortedByPresentationName: Supplier[];
  productType: ProductType;
  pricesHaveProductSubType: boolean;
  priceHasBandwidth: boolean;
  priceHasMultipleContractTerms: boolean;
}

const Filters = ({
  priceHasBandwidth,
  priceHasMultipleContractTerms,
  pricesHaveProductSubType,
  productType,
  readOnly,
  suppliersSortedByPresentationName,
}: FiltersParams) => {
  if (readOnly) return <FilterWrapper />;
  return (
    <FilterWrapper>
      <SupplierSelectionDropdown suppliers={suppliersSortedByPresentationName} productType={productType} />
      {pricesHaveProductSubType && <ProductSubTypeFilter />}
      {priceHasBandwidth && <BandwidthFilter />}
      {priceHasMultipleContractTerms && <ContractTermFilter />}
    </FilterWrapper>
  );
};

function preventDuplicateEntries(supplier: Supplier) {
  return supplier !== Supplier.ONAT && supplier !== Supplier.NNAT;
}
const getSuppliersSortedByPresentationName = (): Supplier[] => {
  return Object.values(Supplier)
    .filter(preventDuplicateEntries)
    .sort((a, b) => {
      const aPresentName = presentSupplierName(a);
      const bPresentName = presentSupplierName(b);
      if (aPresentName < bPresentName) return -1;
      if (bPresentName < aPresentName) return 1;
      return 0;
    });
};

export function PricesToolbar({
  activeView,
  allPrices,
  onClick,
  filteredPrices,
  productType,
  readOnly,
}: {
  allPrices: IPriceData[];
  activeView: 'list' | 'slider';
  onClick: (value: 'list' | 'slider') => void;
  readOnly: undefined | boolean;
  productType: ProductType;
  filteredPrices: IPriceData[];
}) {
  const [priceHasBandwidth, setPriceHasBandwidth] = useState(false);
  const [multipleContractTerms, setMultipleContractTerms] = useState(false);

  useEffect(() => {
    setPriceHasBandwidth(allPrices.some((price) => !!price.bandwidth));
  }, [allPrices]);

  useEffect(() => {
    const allTermLengths = allPrices.map((price) => price.term_length_in_months);
    setMultipleContractTerms(new Set(allTermLengths).size > 1);
  }, [allPrices]);

  return (
    <ViewConfigRow>
      <ListAndSliderViewToggle activeView={activeView} onClick={onClick} />

      <Filters
        readOnly={readOnly}
        suppliersSortedByPresentationName={getSuppliersSortedByPresentationName()}
        productType={productType}
        pricesHaveProductSubType={filteredPrices.every((price) => price.product_sub_type !== null)}
        priceHasBandwidth={priceHasBandwidth}
        priceHasMultipleContractTerms={multipleContractTerms}
      />
    </ViewConfigRow>
  );
}

const ViewConfigRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterRow = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 2rem;
`;

const FilterLabel = styled.span`
  color: ${(props) => props.theme.colours.primaryA1};
  font-weight: bold;
  display: flex;
  align-items: center;
`;
