import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IEditButton {
  onClick?: () => void;
  className?: string;
}

const EditButton: FunctionComponent<React.PropsWithChildren<IEditButton>> = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick} type="button">
      <span className="button-label">Edit</span>
      <span className="icon material-icons">{'edit'}</span>
    </button>
  );
};

const StyledEditButton = styled(EditButton)`
  color: ${(props) => props.theme.colours.primaryB1};
  position: relative;

  .button-label {
    display: inline-block;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  .icon {
    position: relative;
    display: inline-block;
    font-size: 0.95em;
    margin-left: 8px;
    top: 2px;
  }
`;

export default StyledEditButton;
