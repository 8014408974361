import { AxiosError } from 'axios';
import Icon from 'shared/components/atoms/Icon';
import styled from 'styled-components';
import { useCopyToClipboard } from 'usehooks-ts';
import React from 'react';

export const VerboseErrorMessage = ({ error }: { error: AxiosError }) => {
  const [value, copy] = useCopyToClipboard();
  const isClipboardAvailable = !!navigator?.clipboard;

  const copyErrorToClipboard = (e: any) => {
    e.stopPropagation();
    copy(`${error.message}\n\n${error.stack}`);
  };

  return (
    <Container>
      <Header>
        <strong>{error.message}</strong>

        {isClipboardAvailable && !value && (
          <button onClick={copyErrorToClipboard}>
            <Icon name="content_copy" size="small" />
          </button>
        )}

        {value && (
          <CopyToClipboardSuccessMessage>
            <Icon name="check" size="small" />
            Copied!
          </CopyToClipboardSuccessMessage>
        )}
      </Header>
      <StackTrace>{error.stack}</StackTrace>
    </Container>
  );
};

const StackTrace = styled.pre`
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 8px;
  margin-top: 10px;
  margin-bottom: 0;
  color: inherit;
`;

const Header = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
`;

const CopyToClipboardSuccessMessage = styled.span`
  display: flex;
  align-items: center;
  column-gap: 2px;
  font-size: 10px;
`;
