import { format } from 'date-fns';
import dayjs from 'dayjs';

export function formatDateTime(dateTime: string): string {
  return dayjs.utc(dateTime).local().format('DD-MM-YYYY HH:mm');
}

export function formatDateTime24h(dateTime: string) {
  return dayjs.utc(dateTime).local().format('DD-MM-YYYY HHmm');
}

export function formatDate(dateTime: string): string {
  return dayjs.utc(dateTime).local().format('DD-MM-YYYY');
}

export function formatTime(dateTime: string): string {
  return dayjs.utc(dateTime).local().format('HH:mm');
}

export function formatDateTimeHuman(dateTime: string): string {
  return dayjs.utc(dateTime).local().format('Do MMMM YYYY HH:mm');
}

export function formatDatePickerDate(date: Date): string {
  return dayjs(date).format('YYYY-MM-DD');
}

export function formatDateRangePickerDate(date: [Date, Date]) {
  const startDate = format(date[0], 'yyyy-MM-dd');
  const endDate = format(date[1], 'yyyy-MM-dd');

  return `${startDate} - ${endDate}`;
}

export function formatDateRangePickerDateForDisplay(date: [Date, Date]) {
  const startDate = format(date[0], 'd/M');
  const endDate = format(date[1], 'd/M');

  return `${startDate} - ${endDate}`;
}

export function formatDateForPaginatedQuery(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
}

export function formatDateHuman(dateTime: string): string {
  return dayjs.utc(dateTime).local().format('Do MMMM YYYY');
}

export function sortDates(a: string, b: string): number {
  const aTime = dayjs(a).valueOf();
  const bTime = dayjs(b).valueOf();

  return aTime > bTime ? 1 : -1;
}
