import React, { createRef, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';

export interface IModalProps {
  children: ReactNode;
  className?: string;
  closeBtnLabel?: string;
  confirmBtnLabel?: string;
  confirmBtnLoading?: boolean;
  showCloseIcon?: boolean;
  disableActions?: boolean;
  disableConfirmBtn?: boolean;
  showCloseBtn?: boolean;
  showConfirmBtn?: boolean;
  title: string;
  onClose?(): void;
  onConfirm?(): void;
  size?: 'sm' | 'md' | 'lg' | 'xl';

  /** This is needed because `useOutsideClick` doesn't handle correctly the click on a Dropdown option and
  wrongly triggers the outside click handler */
  disableOutsideClick?: boolean;
}

const Modal: FunctionComponent<React.PropsWithChildren<IModalProps>> = ({
  children,
  className,
  closeBtnLabel = 'Close',
  confirmBtnLabel = 'Confirm',
  disableActions,
  showCloseBtn = true,
  showCloseIcon = true,
  showConfirmBtn = true,
  title,
  onClose,
  onConfirm,
  size = 'md',
  disableOutsideClick = false,
  disableConfirmBtn = false,
  confirmBtnLoading = false,
}) => {
  const modalRef = createRef<HTMLDivElement>();

  return (
    <div
      className={`${className} modal`}
      tabIndex={-1}
      role="dialog"
      aria-modal="true"
      onClick={() => {
        if (!(disableOutsideClick || disableActions || !onClose)) onClose();
      }}
    >
      <div
        className={`modal-dialog modal-${size}`}
        role="document"
        ref={modalRef}
        onClick={(e) => e.stopPropagation()}
        data-testid="modal-box"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" data-testid="modal-title">
              {title}
            </h3>
            {showCloseIcon && (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
                disabled={!!disableActions}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
          <div className="modal-body">{children}</div>
          {(showCloseBtn || showConfirmBtn) && (
            <div className="modal-footer">
              {showCloseBtn && (
                <Button onClick={onClose} disabled={!!disableActions} mainStyle={ButtonMainStyle.PrimaryOutline}>
                  {closeBtnLabel}
                </Button>
              )}
              {showConfirmBtn && (
                <Button
                  onClick={onConfirm}
                  disabled={!!disableActions || disableConfirmBtn}
                  mainStyle={ButtonMainStyle.PrimaryRectangular}
                  loading={confirmBtnLoading}
                >
                  {confirmBtnLabel}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const StyledModal = styled(Modal)`
  .modal-header {
    border: none;

    .modal-title {
      flex: 1;
      text-align: center;
      color: ${(props) => props.theme.colours.grey70};
    }
  }

  .modal-content {
    border-color: transparent;
    border-radius: 5px;
    box-shadow: 6px 7px 2px 0 rgba(0, 70, 135, 0.1);
    background-color: #ffffff;
  }

  .close {
    padding: 1rem 1rem;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    opacity: 1;
    color: ${(props) => props.theme.colours.secondary};
  }

  &.modal {
    display: block;
  }

  background: rgba(33, 37, 41, 0.6);

  .modal-footer button {
    min-width: ${(props) => props.theme.button.width};
  }

  button[disabled] {
    cursor: not-allowed;
  }
`;

export default StyledModal;
