import { useCallback, useEffect, useState } from 'react';
import { IPAFAddressSite } from 'shared/types/postcodeResults';
import { AvailabilityCheckRequest, Source } from 'Quotes/types/availabilityCheck';

export const useNNAT = (
  leftPAFAddress: IPAFAddressSite | null,
  rightPAFAddress: IPAFAddressSite | null,
  activeSources: readonly [Source, Source],
  sources: Record<Source, AvailabilityCheckRequest>
) => {
  const [nnatAvailable, setNNATAvailable] = useState({
    aEnd: false,
    bEnd: false,
  });

  const [displayNNATWarningBox, setDisplayNNATWarningBox] = useState(false);

  useEffect(() => {
    const aEndWarning = nnatAvailable.aEnd && !leftPAFAddress;

    const bEndWarning =
      activeSources[1] === 'point_right' && !sources.point_left.is_dia && nnatAvailable.bEnd && !rightPAFAddress;

    setDisplayNNATWarningBox(aEndWarning || bEndWarning);
  }, [nnatAvailable, leftPAFAddress, rightPAFAddress, activeSources, sources.point_left.is_dia]);

  const setLeftAvailable = useCallback(
    (isSupported: boolean) => {
      setNNATAvailable({
        ...nnatAvailable,
        aEnd: isSupported,
      });
    },
    [nnatAvailable]
  );

  const setRightAvailable = useCallback(
    (isSupported: boolean) => {
      setNNATAvailable({
        ...nnatAvailable,
        bEnd: isSupported,
      });
    },
    [nnatAvailable]
  );

  return { displayNNATWarningBox, setLeftAvailable, setRightAvailable };
};
