import React, { useState, FC } from 'react';
import Column from 'shared/components/atoms/Column';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { doRequest, Methods, ErrorFromBE } from 'Request';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import Spinner from 'shared/components/atoms/Spinner';

interface Props {
  orderId: string;
}

export const ResendOrder: FC<React.PropsWithChildren<Props>> = ({ orderId }) => {
  const [pending, setPending] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);

  const onClick = () => {
    if (pending) {
      return;
    }

    setPending(true);
    setSuccess(false);
    setErrors([]);

    doRequest({
      path: `/orders/${orderId}/retry`,
      method: Methods.POST,
    })
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        if (Array.isArray(error?.response?.data?.errors)) {
          setErrors(error.response.data.errors.map((e: ErrorFromBE) => e.title || 'Unknown error occurred'));
        } else {
          setErrors([error?.message || 'Unknown error occurred']);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div className="row pt-5">
      <Column classNames={['text-right']}>
        <Button
          subStyle="outline"
          mainStyle={ButtonMainStyle.SecondaryRectangular}
          className="mr-0"
          onClick={onClick}
          disabled={pending}
        >
          {pending && <Spinner size="small" colour="secondary" />}
          {!pending && 'Resend order'}
        </Button>

        {errors.length > 0 && (
          <Alert className="mt-4 mb-0 text-left">
            <p>Error resending order:</p>
            <ul>
              {errors.map((e, i) => (
                <li key={i}>{e}</li>
              ))}
            </ul>
            <p>
              Please try again later. If the problem persists, please raise a ticket via the Helpdesk quoting the
              Customer name and Digital order id.
            </p>
          </Alert>
        )}

        {success && (
          <Alert alertType={Alerts.SUCCESS} className="mt-4 mb-0 text-left">
            This order has been successfully marked for resending.
          </Alert>
        )}
      </Column>
    </div>
  );
};
