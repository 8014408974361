import {
  AvailabilityCheckChange,
  AvailabilityCheckRequestFailure,
  AvailabilityCheckRequestStart,
  AvailabilityCheckRequestSuccess,
  AvailabilityCheckTabChange,
  IBulkQuoteSubmitEnded,
  IBulkQuoteSubmitError,
  IBulkQuoteSubmitStarted,
  IClearONATAddressIdFailure,
  IClearRouterChoice,
  ICloneQuote,
  ICloneQuoteEnded,
  ICloneQuoteError,
  ICloneQuoteStarted,
  ICreateMultipleOrders,
  ICreateMultipleOrdersCleanup,
  ICreateMultipleOrdersEnded,
  ICreateMultipleOrdersError,
  ICreateMultipleOrdersPOASuccess,
  ICreateMultipleOrdersStarted,
  ICreateMultipleOrdersSuccess,
  ICreateQuote,
  ICreateQuoteEnded,
  ICreateQuoteError,
  ICreateQuoteStarted,
  IDownloadQuotes,
  IDownloadQuotesEnded,
  IDownloadQuotesError,
  IDownloadQuotesStarted,
  IGetAvailableAccessMethods,
  IGetAvailableAccessMethodsEnded,
  IGetAvailableAccessMethodsError,
  IGetAvailableAccessMethodsStarted,
  IGetExistingQuote,
  IGetExistingQuoteEnded,
  IGetExistingQuoteError,
  IGetExistingQuoteStarted,
  IPriceComplexQuoteCompleted,
  IPriceComplexQuoteUpdateProgress,
  IPriceQuote,
  IPriceQuoteEnded,
  IPriceQuoteError,
  IPriceQuoteStarted,
  IPriceQuoteSuccess,
  IPricingQuoteWillRetry,
  IPublishQuote,
  IPublishQuoteFailure,
  IPublishQuoteSuccess,
  IRefreshFilteredPrices,
  IReplaceQuoteState,
  IResetBulkQuoteSubmitState,
  IResetPricingProgressStateAction,
  IResetQuoteState,
  ISaveONATAddressId,
  ISaveONATAddressIdFailure,
  ISaveONATAddressIdSuccess,
  ISetAddressesFoundAEnd,
  ISetAddressesFoundBEnd,
  ISetAddressNotListedAEnd,
  ISetAddressNotListedBEnd,
  ISetAddressRetrieval,
  ISetAmortisation,
  ISetBandwidth,
  ISetBearer,
  ISetBulkQuoteId,
  ISetCablingSuccessState,
  ISetCerillionSentAt,
  ISetCloudProvider,
  ISetCloudProvidersList,
  ISetContractTermLength,
  ISetCurrentQuoteData,
  ISetDataCentreAEnd,
  ISetDataCentreBEnd,
  ISetDataCentreBEndNotListed,
  ISetDIAIPAllocation,
  ISetDivercity,
  ISetDraftPostcodeAEnd,
  ISetDraftPostcodeBEnd,
  ISetFilteredPriceData,
  ISetFTTPAggregation,
  ISetFullAddressAEnd,
  ISetFullAddressBEnd,
  ISetIPOption,
  ISetIsEngineerInstallationRequired,
  ISetIsManagedDIA,
  ISetIsRackMountRequired,
  ISetMultiQuote,
  ISetNNIIdAndReference,
  ISetOpenreachAddress,
  ISetOpticalDataCentreList,
  ISetPostcodeAEnd,
  ISetPostcodeBEnd,
  ISetPriceData,
  ISetPricesOnCircuit,
  ISetProductType,
  ISetQuoteConnectionType,
  ISetQuotePOA,
  ISetRouterChoice,
  ISetSecondaryCircuitOptions,
  ISetSecondaryCircuitsEnabled,
  ISetSecondaryExchanges,
  ISetSecondIPOption,
  ISetSelectedPrice,
  ISetSelectedPriceOnCircuit,
  ISetShortBulkQuoteId,
  ISetSupplierEndFilter,
  ISetSupportedBandwidths,
  ISubmitBulkQuote,
  ISwitchLocationLookup,
  IToggleChosenBandwidth,
  IToggleChosenContractTerm,
  IToggleContractTermFilter,
  IToggleNNIShadowVLAN,
  ITogglePriceBandwidthFilter,
  IToggleProductSubTypeFilter,
  IUpdatePricedQuote,
  IUpdatePricedQuoteAndCreateOrder,
  IUpdatePriceQuote,
  IUpdatePriceQuotePayload,
  IUpdateQuote,
  IUpdateQuoteAndGetPrice,
  IUpdateQuoteEnded,
  IUpdateQuoteError,
  IUpdateQuoteStarted,
  IUpdateSinglePrice,
  Journey,
  NewQuoteMessage,
  QuoteAction,
  QuoteActionTypes,
  UpdatePricedQuotePayloadPrice,
  UpdatePricedQuotePayloadQuote,
} from './types/actions';
import IQuoteRecord, {
  IComplexQuotePricingProgress,
  IQuoteData,
  IQuoteListItem,
  IQuotePricedOrOrdered,
} from './types/quoteRecord';
import IQuoteRecordAttributesBase from './types/quoteRecordAttributesBase';
import {
  AddressesFound,
  ILocation,
  IPriceData,
  IQuoteListItemWithOrderId,
  IQuotesState,
  MDIAFields,
} from './types/store';
import { ALKLocation, IOpenReachAddressSite, IPAFAddressSite } from 'shared/types/postcodeResults';
import { NNISelectionType } from 'Location/NNI/types';
import { BearerType } from './QuoteBuilder/components/Configure/Bearer/BearerType';
import { IBulkQuoteSubmitErrorData } from './types/bulkQuoteSubmitResponse';
import { Supplier, SupplierEndType } from './types/supplier';
import { IOnNetSite } from 'shared/types/onNetSite';
import { ErrorResponse } from 'Request';
import IDownloadQuotesType from './types/downloadQuotes';
import { IPType } from './QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { IOpticalDataCentre } from 'Location/OpticalDataCentres/types';
import { ErrorResponse as JsonApiErrorResponse } from 'Request/json-api/types';
import { ICloudProvider } from 'Location/CloudConnect/CloudProviders/types';
import IPricedQuote from './types/pricedQuote';
import { OverallConnectionType } from './types/connectionType';
import { IAccessAvailability } from './types/accessAvailability';
import { FlatONATAddressResponseItem } from 'shared/types/onatAddress';
import { AvailabilityCheckRequest, AvailabilityCheckResponse, Source } from './types/availabilityCheck';
import {
  DualCircuitRouterConfigurations,
  IPAddressAllocation,
} from './QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { MessageResource } from 'shared/types/Messages';
import { IDummyPrice } from './QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import { ICreateOrder } from 'Order/types/actions';

export const setFullAddressAEndAction = (
  fullAddress: IPAFAddressSite | IOnNetSite | null,
  onNetType?: ILocation['onNetType']
): ISetFullAddressAEnd => ({
  payload: { fullAddress, onNetType },
  type: QuoteActionTypes.SET_FULL_ADDRESS_A_END,
});

export const setFullAddressBEndAction = (
  fullAddress: IPAFAddressSite | IOnNetSite | null,
  onNetType?: ILocation['onNetType']
): ISetFullAddressBEnd => ({
  payload: { fullAddress, onNetType },
  type: QuoteActionTypes.SET_FULL_ADDRESS_B_END,
});

export const setOpenreachAddress = (
  end: 'A' | 'B',
  address: IOpenReachAddressSite | ALKLocation | null
): ISetOpenreachAddress => ({
  payload: {
    address,
    end,
  },
  type: QuoteActionTypes.SET_OPENREACH_ADDRESS,
});

export const setAddressNotListedAEnd = (notListed: boolean): ISetAddressNotListedAEnd => ({
  payload: { notListed },
  type: QuoteActionTypes.SET_ADDRESS_NOT_LISTED_A_END,
});

export const setAddressNotListedBEnd = (notListed: boolean): ISetAddressNotListedBEnd => ({
  payload: { notListed },
  type: QuoteActionTypes.SET_ADDRESS_NOT_LISTED_B_END,
});

export const setAddressesFoundAEnd = (addresses: AddressesFound): ISetAddressesFoundAEnd => ({
  payload: { addresses },
  type: QuoteActionTypes.SET_ADDRESSES_FOUND_A_END,
});

export const setAddressesFoundBEnd = (addresses: AddressesFound): ISetAddressesFoundBEnd => ({
  payload: { addresses },
  type: QuoteActionTypes.SET_ADDRESSES_FOUND_B_END,
});

export const setPostcodeAEndAction = (postcode: string): ISetPostcodeAEnd => ({
  payload: { postcode },
  type: QuoteActionTypes.SET_POSTCODE_A_END,
});

export const setPostcodeBEndAction = (postcode: string): ISetPostcodeBEnd => ({
  payload: { postcode },
  type: QuoteActionTypes.SET_POSTCODE_B_END,
});

export const setDraftPostcodeAEndAction = (postcode: string): ISetDraftPostcodeAEnd => ({
  payload: { postcode },
  type: QuoteActionTypes.SET_DRAFT_POSTCODE_A_END,
});

export const setDraftPostcodeBEndAction = (postcode: string): ISetDraftPostcodeBEnd => ({
  payload: { postcode },
  type: QuoteActionTypes.SET_DRAFT_POSTCODE_B_END,
});

export const setProductTypeAction = (productType: IQuoteRecordAttributesBase['product_type']): ISetProductType => ({
  payload: { productType },
  type: QuoteActionTypes.SET_PRODUCT_TYPE,
});

export const setBearerAction = (bearer?: BearerType): ISetBearer => ({
  payload: { bearer },
  type: QuoteActionTypes.SET_BEARER,
});

export const setDivercityAction = (diversity: boolean): ISetDivercity => ({
  payload: { diversity },
  type: QuoteActionTypes.SET_DIVERSITY,
});

export const setBandwidthAction = (bandwidth: string): ISetBandwidth => ({
  payload: { bandwidth },
  type: QuoteActionTypes.SET_BANDWIDTH,
});

export const toggleChosenBandwidth = (
  bandwidth: IToggleChosenBandwidth['payload']['bandwidth']
): IToggleChosenBandwidth => ({
  payload: { bandwidth },
  type: QuoteActionTypes.TOGGLE_CHOSEN_BANDWIDTH,
});

export const toggleChosenContractTerm = (
  term: IToggleChosenContractTerm['payload']['term']
): IToggleChosenContractTerm => ({
  payload: { term },
  type: QuoteActionTypes.TOGGLE_CHOSEN_CONTRACT_TERM,
});

export const setSupportedBandwidthsAction = (bandwidths: number[]): ISetSupportedBandwidths => ({
  payload: { bandwidths },
  type: QuoteActionTypes.SET_SUPPORTED_BANDWIDTHS,
});

/** @deprecated multi term is now supported by `toggleChosenContractTerm` */
export const setContractTermLengthAction = (contractTerm: string): ISetContractTermLength => ({
  payload: { contractTerm },
  type: QuoteActionTypes.SET_CONTRACT_TERM_LENGTH,
});

export const setCurrentQuoteDataAction = (currentQuote: IQuoteData): ISetCurrentQuoteData => ({
  payload: { currentQuote },
  type: QuoteActionTypes.SET_CURRENT_QUOTE_DATA,
});

export const createQuoteAction = (
  quote: IQuotesState['quote'],
  journey = 'by_product_type' as Journey
): ICreateQuote => ({
  payload: { quote, journey },
  type: QuoteActionTypes.CREATE_QUOTE,
});

export const cloneQuote = (quote: IQuotesState['quote'], journey = 'by_product_type' as Journey): ICloneQuote => ({
  payload: { quote, journey },
  type: QuoteActionTypes.CLONE_QUOTE,
});

// todo Wire up when required (missing test coverage for this atm)
export const updateQuoteAction = (quoteId: string, updateValues: IQuoteRecordAttributesBase): IUpdateQuote => ({
  payload: { quoteId, updateValues },
  type: QuoteActionTypes.UPDATE_QUOTE,
});

export const updatePriceQuoteAction = (
  priceId: string,
  updateValues: IPricedQuote,
  updatingCabling?: boolean,
  quoteId?: string,
  updatingPrice?: boolean,
  updatingCablingOrigin?: IUpdatePriceQuotePayload['updatingCablingOrigin']
): IUpdatePriceQuote => ({
  payload: {
    quoteId,
    priceId,
    updateValues,
    updatingPrice,
    updatingCabling,
    updatingCablingOrigin,
  },
  type: QuoteActionTypes.UPDATE_QUOTE_PRICE,
});

export const updateQuoteAndGetPrice = (
  quoteId: string,
  updateValues: IQuoteRecordAttributesBase,
  requiresAsyncPrices: boolean
): IUpdateQuoteAndGetPrice => ({
  payload: { quoteId, updateValues, requiresAsyncPrices },
  type: QuoteActionTypes.UPDATE_QUOTE_AND_GET_PRICE,
});

export const priceQuote = (quoteId: string, requiresAsyncPrices: boolean): IPriceQuote => ({
  payload: { quoteId, requiresAsyncPrices: requiresAsyncPrices },
  type: QuoteActionTypes.PRICE_QUOTE,
});

export const priceComplexQuoteUpdateProgress = (
  progress: IComplexQuotePricingProgress
): IPriceComplexQuoteUpdateProgress => ({
  payload: { progress },
  type: QuoteActionTypes.PRICE_COMPLEX_QUOTE_UPDATE_PROGRESS,
});

export const priceComplexQuoteCompleted = (): IPriceComplexQuoteCompleted => ({
  type: QuoteActionTypes.PRICE_COMPLEX_QUOTE_COMPLETED,
});

export const priceQuoteSuccess = (): IPriceQuoteSuccess => ({
  type: QuoteActionTypes.PRICE_QUOTE_SUCCESS,
});

export const updatePricedQuote = (
  price: UpdatePricedQuotePayloadPrice,
  quote: UpdatePricedQuotePayloadQuote
): IUpdatePricedQuote => ({
  payload: { price, quote },
  type: QuoteActionTypes.UPDATE_PRICED_QUOTE,
});

export const updatePricedQuoteAndCreateOrder = (
  updatePricedQuoteAction: IUpdatePricedQuote,
  createOrder: ICreateOrder
): IUpdatePricedQuoteAndCreateOrder => {
  return {
    type: QuoteActionTypes.UPDATE_PRICED_QUOTE_AND_CREATE_ORDER,
    payload: {
      updatePricedQuoteAction,
      createOrder,
    },
  };
};

export const setPricedQuoteData = (prices: IPriceData[]): ISetPriceData => ({
  payload: { prices },
  type: QuoteActionTypes.SET_PRICE_DATA,
});

export const setFilteredPricedData = (): ISetFilteredPriceData => ({
  type: QuoteActionTypes.SET_FILTERED_PRICE_DATA,
});

export const createQuoteErrorAction = (): ICreateQuoteError => ({
  type: QuoteActionTypes.CREATE_QUOTE_ERROR,
});

export const createQuoteStarted = (): ICreateQuoteStarted => ({
  type: QuoteActionTypes.CREATE_QUOTE_STARTED,
});

export const createQuoteEnded = (): ICreateQuoteEnded => ({
  type: QuoteActionTypes.CREATE_QUOTE_ENDED,
});

export const cloneQuoteStarted = (): ICloneQuoteStarted => ({
  type: QuoteActionTypes.CLONE_QUOTE_STARTED,
});

export const cloneQuoteEnded = (): ICloneQuoteEnded => ({
  type: QuoteActionTypes.CLONE_QUOTE_ENDED,
});

export const cloneQuoteError = (): ICloneQuoteError => ({
  type: QuoteActionTypes.CLONE_QUOTE_ERROR,
});

export const priceQuoteErrorAction = (error?: JsonApiErrorResponse): IPriceQuoteError => ({
  payload: { error },
  type: QuoteActionTypes.PRICE_QUOTE_ERROR,
});

export const setQuotePOA = (): ISetQuotePOA => ({
  type: QuoteActionTypes.SET_QUOTE_POA,
});

export const setPricingQuoteWillRetryAction = (): IPricingQuoteWillRetry => ({
  type: QuoteActionTypes.PRICING_QUOTE_WILL_RETRY,
});

export const getExistingQuote = (quoteId: string): IGetExistingQuote => ({
  payload: { quoteId },
  type: QuoteActionTypes.GET_EXISTING_QUOTE,
});

export const getAPIQuote = (quoteId: string): QuoteAction => ({
  payload: { quoteId },
  type: QuoteActionTypes.GET_API_QUOTE,
});

export const getExistingQuoteEnded = (): IGetExistingQuoteEnded => ({
  type: QuoteActionTypes.GET_EXISTING_QUOTE_ENDED,
});

export const getExistingQuoteError = (response: ErrorResponse): IGetExistingQuoteError => ({
  payload: { response },
  type: QuoteActionTypes.GET_EXISTING_QUOTE_ERROR,
});

export const getExistingQuoteStarted = (): IGetExistingQuoteStarted => ({
  type: QuoteActionTypes.GET_EXISTING_QUOTE_STARTED,
});

export const getAPIQuoteEnded = (): QuoteAction => ({
  type: QuoteActionTypes.GET_API_QUOTE_ENDED,
});

export const getAPIQuoteError = (): QuoteAction => ({
  type: QuoteActionTypes.GET_API_QUOTE_ERROR,
});

export const getAPIQuoteStarted = (): QuoteAction => ({
  type: QuoteActionTypes.GET_API_QUOTE_STARTED,
});

export const priceQuoteEndedAction = (): IPriceQuoteEnded => ({
  type: QuoteActionTypes.PRICE_QUOTE_ENDED,
});

export const priceQuoteStartedAction = (
  updatingPrice?: boolean,
  updatingCabling?: boolean,
  requiresAsyncPrices?: boolean
): IPriceQuoteStarted => ({
  payload: {
    updatingPrice: updatingPrice ?? false,
    updatingCabling: updatingCabling ?? false,
    requiresAsyncPrices: requiresAsyncPrices ?? false,
  },
  type: QuoteActionTypes.PRICE_QUOTE_STARTED,
});

export const asyncPriceQuoteStartedAction = (updatingPrice?: boolean, updatingCabling?: boolean): IPriceQuoteStarted =>
  priceQuoteStartedAction(updatingPrice, updatingCabling, true);

export const replaceQuoteState = (quoteRecord: IQuoteRecord): IReplaceQuoteState => ({
  payload: { quote: quoteRecord },
  type: QuoteActionTypes.REPLACE_QUOTE_STATE,
});

export const setCerillionSentAt = (cerillionSentAt: string): ISetCerillionSentAt => ({
  payload: { cerillionSentAt: cerillionSentAt },
  type: QuoteActionTypes.SET_CERILLION_SENT_AT,
});

export const quoteUpdatingStartedAction = (): IUpdateQuoteStarted => ({
  type: QuoteActionTypes.UPDATE_QUOTE_STARTED,
});

export const quoteUpdatingEndedAction = (): IUpdateQuoteEnded => ({
  type: QuoteActionTypes.UPDATE_QUOTE_ENDED,
});

export const quoteUpdatingErrorAction = (): IUpdateQuoteError => ({
  type: QuoteActionTypes.UPDATE_QUOTE_ERROR,
});

export const resetQuoteStateAction = (): IResetQuoteState => ({
  type: QuoteActionTypes.RESET_QUOTE_STATE,
});

export const resetPricingProgressStateAction = (): IResetPricingProgressStateAction => ({
  payload: {},
  type: QuoteActionTypes.RESET_PRICING_PROGRESS_STATE,
});

export const setDataCentreAEnd = (id: string, reference: string): ISetDataCentreAEnd => ({
  payload: { id, reference },
  type: QuoteActionTypes.SET_DATA_CENTRE_A_END,
});

export const setDataCentreBEnd = (id: string, reference: string): ISetDataCentreBEnd => ({
  payload: { id, reference },
  type: QuoteActionTypes.SET_DATA_CENTRE_B_END,
});

export const setDataCentreBEndNotListed = (notListed: boolean): ISetDataCentreBEndNotListed => ({
  type: QuoteActionTypes.SET_DATA_CENTRE_B_END_NOT_LISTED,
  notListed,
});

export const setOpticalDataCentreList = (opticalDataCentreList: IOpticalDataCentre[]): ISetOpticalDataCentreList => ({
  payload: { opticalDataCentreList },
  type: QuoteActionTypes.SET_OPTICAL_DATA_CENTRE_LIST,
});

export const setCloudProvidersList = (cloudProvidersList: ICloudProvider[]): ISetCloudProvidersList => ({
  payload: { cloudProvidersList },
  type: QuoteActionTypes.SET_CLOUD_PROVIDERS_LIST,
});

export const setCloudProvider = (cloudProvider: ICloudProvider): ISetCloudProvider => ({
  payload: { cloudProvider },
  type: QuoteActionTypes.SET_CLOUD_PROVIDER,
});

export const setNNIIdAndReference = (
  id: string,
  nniReference: string,
  dataCentreReference: string,
  postcode: string,
  shadowVLAN: boolean,
  nniSelectionType: NNISelectionType,
  label: string
): ISetNNIIdAndReference => ({
  payload: {
    id,
    nniReference,
    dataCentreReference,
    postcode,
    shadowVLAN,
    type: nniSelectionType,
    label,
  },
  type: QuoteActionTypes.SET_NNI_ID_AND_REFERENCE,
});

export const toggleNNIShadowVLAN = (): IToggleNNIShadowVLAN => ({
  type: QuoteActionTypes.TOGGLE_NNI_SHADOW_VLAN,
});

export const setSelectedPrice = (
  preferredPriceId?: string,
  amortised?: boolean,
  missingPrices?: IDummyPrice[]
): ISetSelectedPrice => ({
  payload: { preferredPriceId, amortised, missingPrices },
  type: QuoteActionTypes.SET_SELECTED_PRICE,
});

export const setSecondaryCircuitsEnabled = (priceId: string, enabled: boolean): ISetSecondaryCircuitsEnabled => ({
  payload: { priceId, enabled },
  type: QuoteActionTypes.SET_SECONDARY_CIRCUITS_ENABLED,
});

export const setSecondaryCircuits = (
  priceId: string,
  secondaryCircuits: IPriceData['secondary_circuits']
): ISetSecondaryExchanges => ({
  payload: { priceId, secondaryCircuits },
  type: QuoteActionTypes.SET_SECONDARY_CIRCUITS_ON_SELECTED_PRICE,
});

export const addPricesToCircuit = (
  primaryPriceId: string,
  circuitId: string,
  prices: IPriceData[]
): ISetPricesOnCircuit => ({
  payload: { primaryPriceId, circuitId, prices },
  type: QuoteActionTypes.ADD_PRICES_TO_CIRCUIT,
});

export const selectPriceOnCircuit = (
  primaryPriceId: string,
  circuitId: string,
  priceId: string
): ISetSelectedPriceOnCircuit => ({
  payload: { primaryPriceId, circuitId: circuitId, priceId },
  type: QuoteActionTypes.SELECT_PRICE_ON_CIRCUIT,
});

export const setSupplierEndFilter = (supplier: Supplier, endType: SupplierEndType): ISetSupplierEndFilter => ({
  payload: { supplier, endType },
  type: QuoteActionTypes.SET_SUPPLIER_END_FILTER,
});

export const toggleProductSubTypeFilter = (
  productSubType: IToggleProductSubTypeFilter['payload']['productSubType']
): IToggleProductSubTypeFilter => ({
  payload: { productSubType },
  type: QuoteActionTypes.TOGGLE_PRODUCT_SUB_TYPE_FILTER,
});

export const togglePriceBandwidthFilter = (
  bandwidth: ITogglePriceBandwidthFilter['payload']['bandwidth']
): ITogglePriceBandwidthFilter => ({
  payload: { bandwidth },
  type: QuoteActionTypes.TOGGLE_PRICE_BANDWIDTH_FILTER,
});

export const toggleContractTermFilter = (
  contractTerm: IToggleContractTermFilter['payload']['contractTerm']
): IToggleContractTermFilter => ({
  payload: { contractTerm },
  type: QuoteActionTypes.TOGGLE_CONTRACT_TERM_FILTER,
});

export const setAmortisationAction = (amortised: boolean): ISetAmortisation => ({
  payload: { amortised },
  type: QuoteActionTypes.SET_AMORTISATION,
});

export const setFTTPAggregationAction = (fttpAggregation: boolean | null): ISetFTTPAggregation => ({
  payload: { fttpAggregation },
  type: QuoteActionTypes.SET_FTTP_AGGREGATION,
});

export const setRouterChoice = (routerChoice: DualCircuitRouterConfigurations): ISetRouterChoice => ({
  payload: { routerChoice },
  type: QuoteActionTypes.SET_ROUTER_CHOICE,
});

export const clearRouterChoice = (routerChoice: undefined): IClearRouterChoice => ({
  payload: { routerChoice },
  type: QuoteActionTypes.CLEAR_ROUTER_CHOICE,
});

export const setSecondaryCircuitOptions = (
  options: MDIAFields['secondRouterOptions']
): ISetSecondaryCircuitOptions => ({
  payload: {
    engineerInstallationRequired: options?.engineerInstallationRequired,
    rackMountKitRequired: options?.rackMountKitRequired,
  },
  type: QuoteActionTypes.SET_SECONDARY_CIRCUIT_OPTIONS,
});

export const submitBulkQuote = (bulkQuoteFile: File): ISubmitBulkQuote => ({
  payload: { bulkQuoteFile },
  type: QuoteActionTypes.SUBMIT_BULK_QUOTE,
});

export const bulkQuoteSubmitStarted = (): IBulkQuoteSubmitStarted => ({
  type: QuoteActionTypes.BULK_QUOTE_SUBMIT_STARTED,
});

export const bulkQuoteSubmitEnded = (): IBulkQuoteSubmitEnded => ({
  type: QuoteActionTypes.BULK_QUOTE_SUBMIT_ENDED,
});

export const bulkQuoteSubmitError = (response: IBulkQuoteSubmitErrorData): IBulkQuoteSubmitError => ({
  payload: response,
  type: QuoteActionTypes.BULK_QUOTE_SUBMIT_ERROR,
});

export const resetBulkQuoteSubmitState = (): IResetBulkQuoteSubmitState => ({
  type: QuoteActionTypes.RESET_BULK_QUOTE_SUBMIT_STATE,
});

export const downloadQuotes = (payload: IDownloadQuotesType): IDownloadQuotes => ({
  type: QuoteActionTypes.DOWNLOAD_QUOTES,
  payload,
});

export const downloadQuotesStarted = (): IDownloadQuotesStarted => ({
  type: QuoteActionTypes.DOWNLOAD_QUOTES_STARTED,
});

export const downloadQuotesEnded = (): IDownloadQuotesEnded => ({
  type: QuoteActionTypes.DOWNLOAD_QUOTES_ENDED,
});

export const downloadQuotesError = (): IDownloadQuotesError => ({
  type: QuoteActionTypes.DOWNLOAD_QUOTES_ERROR,
});

export const setIPOption = (option: IPType): ISetIPOption => ({
  payload: { option },
  type: QuoteActionTypes.SET_IP_OPTION,
});

export const setSecondaryIPOption = (option: IPType): ISetSecondIPOption => ({
  payload: { option },
  type: QuoteActionTypes.SET_SECOND_IP_OPTION,
});

export const setDIAIPAllocation = (dia_ip_allocation: IPAddressAllocation): ISetDIAIPAllocation => ({
  payload: { dia_ip_allocation },
  type: QuoteActionTypes.SET_DIA_IP_ALLOCATION,
});

export const getAvailableAccessMethods = (): IGetAvailableAccessMethods => ({
  type: QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS,
});

export const getAvailableAccessMethodsStarted = (): IGetAvailableAccessMethodsStarted => ({
  type: QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_STARTED,
});

export const getAvailableAccessMethodsEnded = (availability: IAccessAvailability): IGetAvailableAccessMethodsEnded => ({
  type: QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_ENDED,
  payload: { availability },
});

export const getAvailableAccessMethodsError = (response: ErrorResponse): IGetAvailableAccessMethodsError => ({
  type: QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_ERROR,
  payload: { response },
});

export const setCablingSuccessStateAction = (state: boolean): ISetCablingSuccessState => ({
  payload: { state },
  type: QuoteActionTypes.SET_CABLING_SUCCESS_STATE,
});

export const updateSinglePrice = (price: IQuotePricedOrOrdered): IUpdateSinglePrice => ({
  payload: { price },
  type: QuoteActionTypes.UPDATE_SINGLE_PRICE,
});

export const updatePriceOrderable = (priceId: string, is_price_orderable: boolean) => ({
  payload: { priceId, is_price_orderable },
  type: QuoteActionTypes.UPDATE_PRICE_ORDERABLE,
});

export const refreshFilteredPrices = (): IRefreshFilteredPrices => ({
  type: QuoteActionTypes.REFRESH_FILTERED_PRICES,
});

export const setQuoteConnectionType = (connectionType: OverallConnectionType): ISetQuoteConnectionType => ({
  type: QuoteActionTypes.SET_QUOTE_CONNECTION_TYPE,
  payload: {
    connectionType,
  },
});

export const setShortBulkQuoteId = (shortBulkQuoteId: string): ISetShortBulkQuoteId => ({
  payload: { shortBulkQuoteId },
  type: QuoteActionTypes.SET_SHORT_BULK_QUOTE_ID,
});

export const setBulkQuoteId = (bulkQuoteId?: string): ISetBulkQuoteId => ({
  payload: { bulkQuoteId },
  type: QuoteActionTypes.SET_BULK_QUOTE_ID,
});

export const publishQuote = (): IPublishQuote => ({
  type: QuoteActionTypes.PUBLISH_QUOTE,
});

export const publishQuoteFailure = (): IPublishQuoteFailure => ({
  type: QuoteActionTypes.PUBLISH_QUOTE_FAILURE,
});

export const publishQuoteSuccess = (): IPublishQuoteSuccess => ({
  type: QuoteActionTypes.PUBLISH_QUOTE_SUCCESS,
});

export const setAddressRetrieval = (aEnd: boolean, onNetAndPaf: boolean, retrieve: boolean): ISetAddressRetrieval => ({
  payload: { aEnd, onNetAndPaf, retrieve },
  type: QuoteActionTypes.ADDRESS_RETRIEVAL,
});

export const switchLocationLookup = (
  end: ISwitchLocationLookup['payload']['end'],
  lookupType: ISwitchLocationLookup['payload']['lookupType']
): ISwitchLocationLookup => ({
  payload: { end, lookupType },
  type: QuoteActionTypes.SWITCH_LOCATION_LOOKUP,
});

export const setIsManagedDIA = (is_managed_dia: ISetIsManagedDIA['payload']['is_managed_dia']): ISetIsManagedDIA => ({
  payload: { is_managed_dia },
  type: QuoteActionTypes.SET_IS_MANAGED_DIA,
});

export const setIsEngineerInstallationRequired = (
  is_engineer_installation_required: ISetIsEngineerInstallationRequired['payload']['is_engineer_installation_required']
): ISetIsEngineerInstallationRequired => ({
  payload: { is_engineer_installation_required },
  type: QuoteActionTypes.SET_IS_ENGINEER_INSTALLATION_REQUIRED,
});

export const setIsRackMountRequired = (
  is_rack_mount_required: ISetIsRackMountRequired['payload']['is_rack_mount_required']
): ISetIsRackMountRequired => ({
  payload: { is_rack_mount_required },
  type: QuoteActionTypes.SET_IS_RACK_MOUNT_REQUIRED,
});

export const saveONATAddressId = (
  id: ISaveONATAddressId['payload']['id'],
  end: ISaveONATAddressId['payload']['end']
): ISaveONATAddressId => ({
  payload: { id, end },
  type: QuoteActionTypes.SAVE_ONAT_ADDRESS_ID,
});

export const saveONATAddressIdFailure = (): ISaveONATAddressIdFailure => ({
  type: QuoteActionTypes.SAVE_ONAT_ADDRESS_ID_FAILURE,
});

export const saveONATAddressIdSuccess = (address: FlatONATAddressResponseItem): ISaveONATAddressIdSuccess => ({
  payload: { address },
  type: QuoteActionTypes.SAVE_ONAT_ADDRESS_ID_SUCCESS,
});

export const clearONATAddressIdFailure = (
  end: IClearONATAddressIdFailure['payload']['end']
): IClearONATAddressIdFailure => ({
  payload: { end },
  type: QuoteActionTypes.CLEAR_ONAT_ADDRESS_ID_FAILURE,
});

export const triggerAvailabilityCheck = (
  changes: AvailabilityCheckRequest,
  source: Source
): AvailabilityCheckChange => ({
  type: QuoteActionTypes.AVAILABILITY_CHECK_CHANGE,
  payload: { changes, source },
});

export const availabilityCheckRequestStart = (): AvailabilityCheckRequestStart => ({
  type: QuoteActionTypes.AVAILABILITY_CHECK_REQUEST_START,
});

export const availabilityCheckRequestSuccess = (
  response: AvailabilityCheckResponse
): AvailabilityCheckRequestSuccess => ({
  payload: { response },
  type: QuoteActionTypes.AVAILABILITY_CHECK_REQUEST_SUCCESS,
});

export const availabilityCheckRequestFailure = (): AvailabilityCheckRequestFailure => ({
  type: QuoteActionTypes.AVAILABILITY_CHECK_REQUEST_FAILURE,
});

export const availabilityCheckTabChange = (source: Source): AvailabilityCheckTabChange => ({
  payload: { source },
  type: QuoteActionTypes.AVAILABILITY_CHECK_TAB_CHANGE,
});

export const setIsMultiQuote = (isMultiQuote: boolean, bulkRequestId: string): ISetMultiQuote => ({
  payload: {
    isMultiQuote: isMultiQuote,
    bulkRequestId: bulkRequestId,
  },
  type: QuoteActionTypes.SET_IS_MULTIQUOTE,
});

export const newQuoteMessage = (message: MessageResource): NewQuoteMessage => ({
  payload: { message },
  type: QuoteActionTypes.NEW_QUOTE_MESSAGE,
});

export const createMultipleOrders = (quotes: IQuoteListItem[], companyName: string): ICreateMultipleOrders => ({
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS,
  payload: { quotes, companyName },
});

export const createMultipleOrdersStarted = (): ICreateMultipleOrdersStarted => ({
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_STARTED,
});

export const createMultipleOrdersEnded = (): ICreateMultipleOrdersEnded => ({
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_ENDED,
});

export const createMultipleOrdersError = (relatedQuote: IQuoteListItem): ICreateMultipleOrdersError => ({
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_ERROR,
  payload: { relatedQuote },
});

export const createMultipleOrdersSuccess = (relatedQuote: IQuoteListItemWithOrderId): ICreateMultipleOrdersSuccess => ({
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_SUCCESS,
  payload: { relatedQuote },
});

export const createMultipleOrdersPOASuccess = (relatedQuote: IQuoteListItem): ICreateMultipleOrdersPOASuccess => ({
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_POA_SUCCESS,
  payload: { relatedQuote },
});

export const createMultipleOrdersCleanup = (): ICreateMultipleOrdersCleanup => ({
  type: QuoteActionTypes.CREATE_MULTIPLE_ORDERS_CLEANUP,
});
