import { isFTTXConnection, OverallConnectionType } from 'Quotes/types/connectionType';
import Column from 'shared/components/atoms/Column';
import React from 'react';
import { ProductType } from 'Quotes/types/productTypes';
import { bearerForDisplay, formatBandwidth, fttxBandwidthForDisplay } from 'shared/utils/connectionCapacity';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';

export function Bandwidth(props: {
  connectionType: OverallConnectionType;
  selectedPrice: IPriceData;
  bandwidth: string;
  quote: IQuote;
  bearer: BearerType | undefined;
  productType: ProductType;
}) {
  const displayCapacity = () => {
    const speed = `${formatBandwidth(props.selectedPrice.bandwidth || props.bandwidth)}`;

    if (props.productType === ProductType.NNI2CCT) {
      return `Bandwidth: ${speed}`;
    }

    return `Bearer: ${bearerForDisplay(props.bearer)}, Bandwidth: ${speed}`;
  };

  const displayFttxCapacity = () => {
    if (!isFTTXConnection(props.quote.connectionType)) {
      return '';
    }

    if (props.quote.connectionType === 'EoFTTP' && !!props.selectedPrice.bandwidth) {
      return formatBandwidth(props.selectedPrice.bandwidth);
    }

    return fttxBandwidthForDisplay({
      aEndBandwidth: props.quote.aEndBandwidth,
      bEndBandwidth: props.quote.bEndBandwidth,
      connectionType: props.quote.connectionType,
    });
  };

  return (
    <Column defaultWidth={props.connectionType === 'Ethernet' ? 4 : props.connectionType === 'EoFTTP' ? 4 : 2}>
      <span className="bandwidth font-weight-bold" data-testid="order-summary-bandwidth">
        {props.connectionType === 'Ethernet' && displayCapacity()}
        {displayFttxCapacity()}
      </span>
    </Column>
  );
}
