import React, { FC } from 'react';
import Column from 'shared/components/atoms/Column';
import Icon from 'shared/components/atoms/Icon';
import styled from 'styled-components';

const ButtonWithIconAndBadge: FC<
  React.PropsWithChildren<{
    className?: string;
    'data-testid': string;
    icon: string;
    count?: number;
  }>
> = (props) => {
  const testId = props['data-testid'];
  const { className, children, count, icon } = props;
  return (
    <div data-testid={testId} className={`${className || ''} row align-items-center text-center`}>
      <Column defaultWidth={12} classNames={['px-0']}>
        <span data-testid={`${testId}-icon`}>
          <StyledIcon name={icon} size="large" className="position-relative" />
        </span>
        <Hideable className="ml-1 pr-1">{children}</Hideable>
        {count !== undefined && count > 0 && (
          <Badge className="badge d-inline" data-testid={`${testId}-badge`}>
            {count > 9 ? '9+' : count}
          </Badge>
        )}
      </Column>
    </div>
  );
};

const Hideable = styled.span`
  font-size: 18px;
  display: none;
  &.active {
    display: inline;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.XL}) {
    font-size: 20px;
    display: inline;
  }
`;

const Badge = styled.span`
  background-color: ${(props) => props.theme.alerts.danger.backgroundColor};
  color: white;
  padding: 0.05em 0.2em;
`;

const StyledIcon = styled(Icon)`
  top: 5px;
  color: ${(props) => props.theme.colours.grey70};
`;

export default styled(ButtonWithIconAndBadge)`
  height: ${(props) => props.theme.navbarHeight};
  font-family: ${(props) => props.theme.typography.fontFamilyBold};
  color: ${(props) => props.theme.colours.secondaryE1};
  .nav-text {
    padding-left: 0;
  }
`;
