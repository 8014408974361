import { Feature } from 'FeatureFlags/types';
import hasFeatureEnabled from 'FeatureFlags/utils/hasFeatureEnabled';
import { orderIdSelector, shortIdSelector } from 'Order/selectors';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { saveAs } from 'file-saver';
import { doRequest } from 'Request';
import { decode } from 'base64-arraybuffer';
import Spinner from 'shared/components/atoms/Spinner';
import styled from 'styled-components';
import cx from 'classnames';
import buildFileNameWithDate from 'shared/utils/buildFileNameWithDate';
import { capitalize } from 'lodash';

type Props = {
  className?: string;
};

export const DownloadOrder: FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const orderId = useSelector(orderIdSelector);
  const shortOrderId = useSelector(shortIdSelector);
  const [pending, setPending] = useState(false);

  if (!hasFeatureEnabled(Feature.orderDownload)) {
    return null;
  }

  return (
    <Button
      className={cx(className, { loading: pending })}
      disabled={pending}
      mainStyle={ButtonMainStyle.Secondary}
      onClick={() => {
        (async () => {
          setPending(true);

          try {
            const base64 = await doRequest({
              path: `/orders/${orderId}/pdf`,
            });
            const blob = new Blob([decode(base64)], {
              type: 'application/pdf',
            });
            saveAs(blob, buildFileNameWithDate(`Digital Portal Order ${capitalize(shortOrderId)}`, 'pdf'));
          } catch (e) {
            // Silently fail until we have requirements specified for an error scenario.
          } finally {
            setPending(false);
          }
        })();
      }}
    >
      {pending && <Spinner size="small" colour="secondary" />}
      {!pending && 'Download'}
    </Button>
  );
};

export default styled(DownloadOrder)`
  min-width: 115px;

  &.loading {
    padding-top: 0;
  }
`;
