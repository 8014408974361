import { useEffect, useState } from 'react';
import { doRequest } from '../../../../../Request';
import { ProductType } from '../../../../types/productTypes';

interface IUseBandwidthFetch {
  provider: string;
  productType: ProductType;
  onSuccess(data: number[]): void;
}

export const useBandwidthFetch = ({ provider, onSuccess, productType }: IUseBandwidthFetch) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (productType === ProductType.P2CCT || productType === ProductType.NNI2CCT) {
      setLoading(true);
      (async () => {
        try {
          const { data } = await doRequest({
            path: `/cloud-connect-providers/${provider}`,
          });
          onSuccess(data.attributes.supported_bandwidths);
        } catch (e) {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { error, loading };
};
