import React, { FC, PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IAppState } from 'reducers';
import Container from 'shared/components/atoms/Container';
import { Company } from 'User/types/user';
import { getWhoAmI as getWhoAmIAction, setSelectedCompany as setSelectedCompanyAction } from 'User/actions';
import Footer from './navigation/Footer';
import { RequestState } from 'User/types/requestState';
import { login } from 'Auth/routes';
import Spinner from 'shared/components/atoms/Spinner';
import SignOutModal from 'shared/components/atoms/SignOutModal';
import { setLogOutUser as setLogOutUserAction, setShowLogOutPrompt as setShowLogOutPromptAction } from 'Auth/actions';
import { onSessionActive, refreshSessionTimer, sessionTimeValid } from './utils/sessionTimer';
import { useSessionTimeCheck } from 'Auth/hooks';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { signUserOut, signUserOutDirectly } from 'App/logout';
import TopNavigation from './navigation/TopNavigation';
import { createSelector } from '@reduxjs/toolkit';

export const SESSION_TIMER = 1000;
export const SIGNOUT_CONFIRMATION_TIMEOUT = 1000 * 60 * 5;

interface IAuthenticatedLayout {
  showLogOutPrompt: boolean;
  logOutUser: boolean;
  className?: string;
  companies: Company[];
  userName: string;
  selectedCompanyId: string | null;
  whoAmIRequestState: RequestState;

  setSelectedCompany(id: string): void;
  getWhoAmI(): void;
  setShowLogOutPrompt(showLogOutPrompt: boolean): void;
  setLogOutUser(setLogOutUser: boolean): void;
}

const AuthenticatedLayoutInternal: FC<React.PropsWithChildren<PropsWithChildren<IAuthenticatedLayout>>> = ({
  children,
  companies,
  userName,
  selectedCompanyId,
  setSelectedCompany,
  whoAmIRequestState,
  getWhoAmI,
  showLogOutPrompt,
  setShowLogOutPrompt,
  setLogOutUser,
  logOutUser,
  className,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const logOutRef = useRef(logOutUser);
  const showLogOutPromptRef = useRef(showLogOutPrompt);
  let promptTimeout: null | ReturnType<typeof setTimeout> = null;
  const sessionIntervalRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  showLogOutPromptRef.current = showLogOutPrompt;
  logOutRef.current = logOutUser;

  const { activeSession } = useSessionTimeCheck();

  useEffect(() => {
    if (activeSession) {
      getWhoAmI();
    }
  }, [activeSession, getWhoAmI]);

  useEffect(() => {
    if (activeSession === false) {
      signUserOutDirectly(dispatch, navigate, location.pathname, location.search);
    }
  }, [activeSession, dispatch, location.pathname, location.search, navigate]);

  const onValidSession = (skipRefreshTimer: boolean) => {
    setShowLogOutPrompt(false);
    setLogOutUser(false);
    if (!skipRefreshTimer) {
      refreshSessionTimer();
    }
  };

  const initialSessionCheck = (): boolean | null => {
    const initialSessionValid = sessionTimeValid();

    if (!initialSessionValid) {
      signUserOut(dispatch, navigate, location);
    }

    return initialSessionValid;
  };

  const onNoSession = (): void => {
    signUserOut(dispatch, navigate, location);
    if (sessionIntervalRef.current) {
      clearInterval(sessionIntervalRef.current);
    }
  };

  const onConfirmationTimeout = (): void => {
    const sessionValidAfterConfirmationTimeout = sessionTimeValid();
    if (sessionValidAfterConfirmationTimeout) {
      onValidSession(true);
    } else if (logOutRef.current) {
      onNoSession();
    }
  };

  const onShouldShowLogout = (): void => {
    setShowLogOutPrompt(true);
    setLogOutUser(true);
    promptTimeout = setTimeout(onConfirmationTimeout, SIGNOUT_CONFIRMATION_TIMEOUT);
  };

  useEffect(() => {
    if (whoAmIRequestState === RequestState.SUCCESS) {
      if (initialSessionCheck()) {
        window.addEventListener('mousemove', onSessionActive);
        window.addEventListener('keypress', onSessionActive);
        sessionIntervalRef.current = setInterval(async () => {
          const sessionValid = sessionTimeValid();
          if (sessionValid === null) {
            onNoSession();
          } else if (!sessionValid && !showLogOutPromptRef.current) {
            onShouldShowLogout();
          } else if (sessionValid && showLogOutPromptRef.current) {
            onValidSession(true);
          }
        }, SESSION_TIMER);
      }
    }

    return () => {
      window.removeEventListener('mousemove', onSessionActive);
      window.removeEventListener('keypress', onSessionActive);
      if (promptTimeout) {
        clearTimeout(promptTimeout);
      }
      if (sessionIntervalRef.current) {
        clearInterval(sessionIntervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whoAmIRequestState]);

  if (whoAmIRequestState === RequestState.SUCCESS) {
    return (
      <div className={className}>
        <TopNavigation
          companies={companies}
          selectedCompanyId={selectedCompanyId}
          setSelectedCompany={setSelectedCompany}
          userName={userName}
        />
        {showLogOutPrompt && <SignOutModal onClick={() => onValidSession(false)} />}

        <Container>{children}</Container>
        <Footer />
      </div>
    );
  } else if (whoAmIRequestState === RequestState.ERROR) {
    return <Navigate to={login} />;
  }

  return (
    <div className="text-center pt-5">
      <Spinner size="xlarge" />
    </div>
  );
};

const selectState = createSelector(
  (state: IAppState) => state.auth,
  (state: IAppState) => state.user,
  (auth, user) => ({
    companies: user.companyData.companies,
    userName: user.userData.companyEmail,
    selectedCompanyId: user.companyData.selectedCompanyId,
    whoAmIRequestState: user.whoAmIRequestState,
    showLogOutPrompt: auth.showLogOutPrompt,
    logOutUser: auth.logOutUser,
  })
);

const ConnectedAuthenticatedLayout: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const dispatch = useDispatch();
  const setSelectedCompany = useCallback((id: string) => dispatch(setSelectedCompanyAction(id)), [dispatch]);
  const getWhoAmI = useCallback(() => dispatch(getWhoAmIAction()), [dispatch]);
  const setShowLogOutPrompt = useCallback(
    (showLogOutPrompt: boolean) => dispatch(setShowLogOutPromptAction(showLogOutPrompt)),
    [dispatch]
  );
  const setLogOutUser = useCallback((setLogOutUser: boolean) => dispatch(setLogOutUserAction(setLogOutUser)), [
    dispatch,
  ]);

  const state = useSelector(selectState);

  return (
    <AuthenticatedLayoutInternal
      setSelectedCompany={setSelectedCompany}
      getWhoAmI={getWhoAmI}
      setShowLogOutPrompt={setShowLogOutPrompt}
      setLogOutUser={setLogOutUser}
      companies={state.companies}
      logOutUser={state.logOutUser}
      selectedCompanyId={state.selectedCompanyId}
      showLogOutPrompt={state.showLogOutPrompt}
      userName={state.userName}
      whoAmIRequestState={state.whoAmIRequestState}
    >
      {children}
    </AuthenticatedLayoutInternal>
  );
};

const StyledAuthenticatedLayout = styled(ConnectedAuthenticatedLayout)`
  background-color: ${(props) => props.theme.colours.secondaryE1};
  height: 100%;
`;

export default StyledAuthenticatedLayout;
