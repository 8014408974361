import React, { FunctionComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';

import { IAppState } from '../../reducers';
import {
  setOpticalDataCentreList,
  setDataCentreAEnd,
  setDataCentreBEnd,
  setDataCentreBEndNotListed,
} from '../../Quotes/actions';
import { IOpticalDataCentre } from './types';
import OpticalDataCentresCapture from './OpticalDataCentresCapture';

export interface IOpticalDataCentreProps {
  opticalDataCentreListAEnd?: IOpticalDataCentre[];
  opticalDataCentreListBEnd?: IOpticalDataCentre[];
  selectedAEndId?: string | null;
  selectedBEndId?: string | null;
  dataCentreNotListed: boolean;
  selectedPostcode?: string | null;
  onOpticalDataCentreFetchSuccess(opticalDataCentreList: IOpticalDataCentre[]): void;
  onDataCentreNotListed(notListed: boolean): void;
  onAEndSelected(id: string, reference: string): void;
  onBEndSelected(id: string, reference: string): void;
}

const OpticalDataCentre: FunctionComponent<React.PropsWithChildren<IOpticalDataCentreProps>> = ({
  opticalDataCentreListAEnd,
  opticalDataCentreListBEnd,
  onOpticalDataCentreFetchSuccess,
  onAEndSelected,
  onBEndSelected,
  selectedAEndId,
  selectedBEndId,
  onDataCentreNotListed,
  dataCentreNotListed,
  selectedPostcode,
}) => {
  return (
    <OpticalDataCentresCapture
      dataCentreNotListed={dataCentreNotListed}
      onDataCentreNotListed={onDataCentreNotListed}
      selectedAEndId={selectedAEndId || ''}
      selectedBEndId={selectedBEndId || ''}
      onAEndSelected={onAEndSelected}
      onBEndSelected={onBEndSelected}
      selectedPostcode={selectedPostcode}
      opticalDataCentreListAEnd={opticalDataCentreListAEnd}
      opticalDataCentreListBEnd={opticalDataCentreListBEnd}
      onOpticalDataCentreFetchSuccess={onOpticalDataCentreFetchSuccess}
    />
  );
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  onOpticalDataCentreFetchSuccess: (opticalDataCentreList: IOpticalDataCentre[]) =>
    dispatch(setOpticalDataCentreList(opticalDataCentreList)),
  onAEndSelected: (id: string, reference: string) => dispatch(setDataCentreAEnd(id, reference)),
  onBEndSelected: (id: string, reference: string) => dispatch(setDataCentreBEnd(id, reference)),
  onDataCentreNotListed: (notListed: boolean) => dispatch(setDataCentreBEndNotListed(notListed)),
});

const mapStateToProps = ({
  quoteBuilder: {
    quote: {
      location: {
        aEnd: {
          optical: { list: opticalDataCentreListAEnd, selectedId: selectedAEndId },
        },
        bEnd: {
          postcode: selectedPostcode,
          optical: { list: opticalDataCentreListBEnd, selectedId: selectedBEndId, dataCentreNotListed },
        },
      },
    },
  },
  user: {
    companyData: { selectedCompanyId },
  },
}: IAppState) => {
  return {
    opticalDataCentreListAEnd,
    opticalDataCentreListBEnd,
    selectedCompanyId,
    selectedAEndId,
    selectedBEndId,
    dataCentreNotListed,
    selectedPostcode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpticalDataCentre);
