import React, { FC } from 'react';
import styled from 'styled-components';
interface IProgressBarProps {
  className?: string;
  value: number;
}
const UnstyledProgressBar: FC<React.PropsWithChildren<IProgressBarProps>> = ({ className, value }) => {
  return (
    <div className={className}>
      <div className="progress-container">
        <div className="progress" style={{ width: `${value * 100}%` }}></div>
      </div>
    </div>
  );
};
export const ProgressBar = styled(UnstyledProgressBar)`
  .progress-container {
    width: 100%;
    height: 5px;
    background: #d8d8d8;
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 0;
      background-color: ${(props) => props.theme.colours.primaryB1};
    }
  }
`;
