import styled, { useTheme } from 'styled-components';
import React from 'react';

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 40px;
`;

const Logo = () => {
  const theme = useTheme();

  return (
    <StyledImage src={theme.logos.main} alt={`${theme.content.companyName} Logo`} title={theme.content.companyName} />
  );
};

export default Logo;
