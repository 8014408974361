import React from 'react';
import OrderBuilder from './OrderBuilder';
import AuthoriseForRoles from '../Auth/AuthoriseForRoles';
import OrdersList from './OrdersList';
import { Route, Routes } from 'react-router-dom';
import { userPermissions } from 'shared/utils/permissions';

const OrderRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route
        index
        element={
          <AuthoriseForRoles roles={userPermissions.viewOrder}>
            <OrdersList />
          </AuthoriseForRoles>
        }
      />
      <Route
        path=":orderId"
        element={
          <AuthoriseForRoles roles={userPermissions.viewOrder}>
            <OrderBuilder />
          </AuthoriseForRoles>
        }
      />
    </Routes>
  );
};

export default OrderRouter;
