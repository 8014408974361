import React from 'react';
import { useSelector } from 'react-redux';
import ButtonWithIconAndBadge from 'shared/components/molecules/ButtonWithIconAndBadge/ButtonWithIconAndBadge';
import { selectUnreadMessageCount } from 'User/selectors';

const MessagesNotification = () => {
  const unreadMessages = useSelector(selectUnreadMessageCount);

  return (
    <ButtonWithIconAndBadge
      className="messages-notification"
      icon="chat_bubble"
      data-testid="messages-header-button"
      count={unreadMessages}
    >
      Messages
    </ButtonWithIconAndBadge>
  );
};

export default MessagesNotification;
