import { doRequest, Methods } from 'Request';
import { saveAs } from 'file-saver';
import IDownloadQuotesType from 'Quotes/types/downloadQuotes';
import buildFileNameWithDate from 'shared/utils/buildFileNameWithDate';

export const downloadQuotes = async (payload: IDownloadQuotesType, companyName: string) => {
  const getParams = () => {
    if (payload.selectedAllQuotes) {
      return payload.template ? `?template_name=${payload.template}` : '';
    } else {
      return `?line_ids=${payload.lineIds.join(',')}${payload.template ? `&template_name=${payload.template}` : ''}`;
    }
  };

  return doRequest({
    method: Methods.GET,
    path: `/bulk-quote-requests/${payload.bulkQuoteId}/export${getParams()}`,
  }).then((res: string) => {
    const blob = new Blob([res], { type: 'data:text/csv;charset=utf-8,' });
    saveAs(blob, buildFileNameWithDate(`${companyName}_BulkQuote`, 'csv', true));
  });
};
