import { P2NNICircuitData } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import { NNIInternal } from 'Location/NNI/NNI';
import Dropdown from 'shared/components/molecules/Dropdown';
import React from 'react';
import { Arrow, ExchangeOptions } from 'shared/components/molecules/SecondaryCircuits/SecondaryExchanges';

export function SecondaryNNIAndExchange(props: {
  circuitData: P2NNICircuitData;
  readOnly: boolean;
  options: ExchangeOptions[];
  predicate: (it: any) => boolean;
  onChange: (option: any) => void;
}) {
  return (
    <>
      <div data-testid="SecondaryCircuits-secondary-nni" style={{ maxWidth: '50%' }}>
        <NNIInternal
          existingNNIAgreements={props.circuitData.aEnd.existingNNIAgreements}
          nniCapableDataCentres={props.circuitData.aEnd.nniCapableDataCentres}
          isReadOnly={props.readOnly}
          label="Select Secondary NNI"
          enableShadowVLAN={false}
          nni={props.circuitData.aEnd.selected}
          onSelect={props.circuitData.aEnd.selectNNI}
        />
      </div>
      <Arrow />
      <div data-testid="SecondaryCircuits-secondary-exchange" style={{ maxWidth: '50%' }}>
        <h4>Select Secondary Exchange</h4>
        <Dropdown
          options={props.options}
          isDisabled={props.readOnly}
          defaultValue={props.options[0]}
          value={props.options.find(props.predicate)}
          onChange={props.onChange}
          isClearable={false}
        />
      </div>
    </>
  );
}
