import { OverallConnectionType } from '../../../../Quotes/types/connectionType';
import { ProductType } from '../../../../Quotes/types/productTypes';
import Column from '../../../../shared/components/atoms/Column';
import Cost from './Cost';
import React from 'react';

export function BEndPortCosts(props: {
  connectionType: OverallConnectionType;
  productType: ProductType;
  bEndPortCost: {
    annual: number | undefined;
    install: number | undefined;
    description: string | null | undefined;
  } | null;
  bEndOpticalCosts?: { install: number | undefined; annual: number | undefined };
}) {
  return (
    <>
      {props.connectionType === 'Ethernet' &&
        props.productType !== ProductType.DIA &&
        props.productType !== ProductType.OpticalP2P && (
          <div className="row no-gutters mt-1">
            <Column defaultWidth={2} offsetDefaultWidth={2}>
              <span>Port</span>
            </Column>
            <Cost label="Install" cost={props.bEndPortCost?.install ?? 0} className="port-b-install" />
            <Cost label="Annual" cost={props.bEndPortCost?.annual ?? 0} className="port-b-annual" />
          </div>
        )}

      {props.productType === ProductType.OpticalP2P && (
        <div className="row no-gutters mt-1">
          <Column defaultWidth={2} offsetDefaultWidth={2}>
            <span>Port</span>
          </Column>
          <Cost label="Install" cost={props.bEndOpticalCosts?.install} className="port-b-install" />
          <Cost label="Annual" cost={props.bEndOpticalCosts?.annual} className="port-b-annual" />
        </div>
      )}
    </>
  );
}
