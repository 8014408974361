import { IPriceData, IQuote } from 'Quotes/types/store';
import { IOrder } from 'Order/types/store';
import { Supplier } from 'Quotes/types/supplier';
import { isFTTXQuote } from 'Quotes/utils/isFTTXQuote';

export const showGeaCablelinkNotice = (selectedPrice: IPriceData, order: IOrder, quote: IQuote) => {
  return (
    isFTTXQuote(quote) &&
    ((selectedPrice.a_end_access_type === Supplier.OPENREACH && !order.aEndGeaCablelinkId) ||
      (selectedPrice.b_end_access_type === Supplier.OPENREACH && !order.bEndGeaCablelinkId))
  );
};
