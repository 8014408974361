import { IPriceData, IQuote } from 'Quotes/types/store';
import Column from 'shared/components/atoms/Column';
import Cabling from 'Order/OrderBuilder/QuoteSummary/Cabling';
import React from 'react';

export function CablingRequested(props: { quote: IQuote; cablingRequested: boolean; selectedPrice: IPriceData }) {
  return (
    <>
      {props.quote.connectionType === 'Ethernet' && props.cablingRequested && (
        <Column defaultWidth={2}>
          <Cabling selectedPrice={props.selectedPrice} />
        </Column>
      )}
    </>
  );
}
