import { CerillionStage } from 'Order/types/orderRecord';
import { CerillionOrderStageHistory } from 'Order/types/store';
import { CerillionQuoteStage } from 'Quotes/types/quoteRecord';
import { CerillionQuoteStageHistory } from 'Quotes/types/store';
import { Step } from '..';

export const cerillionOrderStageLabels = {
  [CerillionStage.PLACED]: 'Order placed',
  [CerillionStage.ACCEPTANCE]: 'Order accepted',
  [CerillionStage.INITIAL_PLANNING]: 'Initial planning',
  [CerillionStage.COMMITTING_DELIVERY_DATE]: 'Committed Delivery Date',
  [CerillionStage.PHYSICAL_WORKS]: 'Physical works',
  [CerillionStage.CONFIRMING_HANDOVER_DATE]: 'Handover Date',
};

export const cerillionQuoteStageLabels = {
  [CerillionQuoteStage.QUALIFICATION]: 'Qualification',
  [CerillionQuoteStage.BUDGETARY_DESIGN]: 'Budgetary Design',
  [CerillionQuoteStage.QUOTE_CUSTOMER]: 'Quote Customer',
  [CerillionQuoteStage.DETAILED_DESIGN]: 'Detailed Design',
  [CerillionQuoteStage.TECHNICAL_REVIEW]: 'Technical Review',
  [CerillionQuoteStage.NEGOTIATION_AND_APPROVAL]: 'Negotiation and Approval',
  [CerillionQuoteStage.ORDER_CONVERSION]: 'Order Conversion',
};

export const mapCerillionOrderStageHistoryToSteps = (cerillionStageHistory: CerillionOrderStageHistory): Step[] => {
  const keys = Object.keys(cerillionStageHistory) as Array<keyof CerillionOrderStageHistory>;
  return keys.map((key) => ({
    id: key,
    completed: cerillionStageHistory[key].percentage_completion === 100,
    completed_date: cerillionStageHistory[key].end_date,
    progressBarValue: cerillionStageHistory[key].percentage_completion / 100,
    name: cerillionOrderStageLabels[key],
  }));
};

export const emptyCerillionOrderStageHistorySteps: Step[] = [
  {
    id: CerillionStage.PLACED,
    completed: false,
    completed_date: null,
    progressBarValue: 0,
    name: cerillionOrderStageLabels[CerillionStage.PLACED],
  },
  {
    id: CerillionStage.ACCEPTANCE,
    completed: false,
    completed_date: null,
    progressBarValue: 0,
    name: cerillionOrderStageLabels[CerillionStage.ACCEPTANCE],
  },
  {
    id: CerillionStage.INITIAL_PLANNING,
    completed: false,
    completed_date: null,
    progressBarValue: 0,
    name: cerillionOrderStageLabels[CerillionStage.INITIAL_PLANNING],
  },
  {
    id: CerillionStage.COMMITTING_DELIVERY_DATE,
    completed: false,
    completed_date: null,
    progressBarValue: 0,
    name: cerillionOrderStageLabels[CerillionStage.COMMITTING_DELIVERY_DATE],
  },
  {
    id: CerillionStage.PHYSICAL_WORKS,
    completed: false,
    completed_date: null,
    progressBarValue: 0,
    name: cerillionOrderStageLabels[CerillionStage.PHYSICAL_WORKS],
  },
  {
    id: CerillionStage.CONFIRMING_HANDOVER_DATE,
    completed: false,
    completed_date: null,
    progressBarValue: 0,
    name: cerillionOrderStageLabels[CerillionStage.CONFIRMING_HANDOVER_DATE],
  },
];

export const mapCerillionQuoteStageHistoryToSteps = (cerillionStageHistory: CerillionQuoteStageHistory): Step[] => {
  const keys = Object.keys(cerillionStageHistory) as Array<keyof CerillionQuoteStageHistory>;
  return keys.map((key) => ({
    id: key,
    completed: cerillionStageHistory[key].status === 'COMPLETED',
    completed_date: cerillionStageHistory[key].end_date,
    progressBarValue: cerillionStageHistory[key].status === 'COMPLETED' ? 100 : 0,
    name: cerillionStageHistory[key].stage,
  }));
};
