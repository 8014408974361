import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Column from 'shared/components/atoms/Column';
import RadioButton from 'shared/components/molecules/RadioButton';
import { selectBillingFrequency } from 'Order/selectors';
import { BillingFrequency as BillingFrequencyType } from 'Order/types/orderRecord';
import { updateBillingFrequency } from 'Order/actions';
import { selectSelectedCompanyId } from 'User/selectors';

export const BillingFrequency: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const billingFrequency = useSelector(selectBillingFrequency);
  const selectedCompanyId = useSelector(selectSelectedCompanyId);

  return (
    <>
      <div className="row no-gutters pt-3 pb-3">
        <Column offsetDefaultWidth={1}>Billing frequency *</Column>
      </div>
      {
        /* Special case for MLL Telecom Ltd who are the only customer billed quarterly in arrears */
        selectedCompanyId === '1900' ? (
          <div className="row no-gutters">
            <Column defaultWidth={4} offsetDefaultWidth={1}>
              <RadioButton
                id="billing_frequency_quarterly_arrears"
                description="Quarterly in arrears"
                selected
                size="medium"
              />
            </Column>
          </div>
        ) : (
          <div className="row no-gutters">
            <Column defaultWidth={4} offsetDefaultWidth={1}>
              <RadioButton
                id="billing_frequency_quarterly"
                description="Quarterly in advance"
                onClick={() => dispatch(updateBillingFrequency(BillingFrequencyType.Quarterly))}
                selected={billingFrequency === BillingFrequencyType.Quarterly}
              />
            </Column>
            <Column defaultWidth={4} offsetDefaultWidth={1}>
              <RadioButton
                id="billing_frequency_monthly"
                description="Monthly in advance"
                onClick={() => dispatch(updateBillingFrequency(BillingFrequencyType.Monthly))}
                selected={billingFrequency === BillingFrequencyType.Monthly}
              />
            </Column>
          </div>
        )
      }
    </>
  );
};
