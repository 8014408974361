import { IPriceData } from 'Quotes/types/store';
import classNames from 'classnames';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Postcodes } from '../shared/Postcodes';
import { PriceLabel } from '../shared/Price';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import { ProductSubType } from '../../../ProductSubType';
import { DetailBlock } from '../shared/DetailBlock';
import { monthsToYears } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import Icon from 'shared/components/atoms/Icon';

interface IPriceCardProps {
  selected?: boolean;
  onClick?: () => void;
  readonly: boolean;
  identifierPrefix: string;
  priceId: IPriceData['id'];
  product_sub_type: IPriceData['product_sub_type'];
  bandwidth?: string;
  term_length_in_months: IPriceData['term_length_in_months'];
  aEndPostcode?: string;
  bEndPostcode?: string;
  amortisedAnnualPrice: number | null;
  isPoA: boolean;
  supportsSecondaryCircuits?: boolean;
}

const Container = styled.div`
  position: relative;
  padding-top: 2rem;

  [aria-label='icon arrow_drop_down'] {
    color: ${(props) => props.theme.colours.primaryB1};
    position: absolute;
    bottom: -23px;
  }
`;

const Card = styled.button`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colours.grey90};
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  text-align: left;
  position: relative;

  .price-data {
    padding: 15px 20px 15px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    max-width: 100%;
  }

  &:not(.readonly):hover {
    border-color: ${(props) => props.theme.colours.primaryB1};
  }

  &.selected {
    position: relative;
    border: 6px solid ${(props) => props.theme.colours.primaryB1};
    border-radius: 5px;
    outline: none;
  }

  .bandwidth-link {
    position: relative;
  }

  .bandwidth-display {
    position: absolute;
    top: 50%;
    margin-top: -0.8em;
    background-color: white;
    font-size: 0.8em;
    font-weight: bold;
  }

  .detail-blocks {
    display: flex;
    column-gap: 0.5rem;
  }
`;

interface SupportsSecondaryCircuitProps {
  selected?: boolean;
}

const SupportsSecondaryCircuit = styled.div<SupportsSecondaryCircuitProps>`
  position: absolute;
  color: white;
  background: ${(props) => props.theme.colours.primaryB1};
  font-size: 0.8em;
  font-weight: bold;
  top: 0;
  left: 5px;
  transform: ${(props) => (props.selected ? 'translateY(100%)' : 'translateY(-10%)')};
  transition: transform 0.5s ease;
  padding: 0.5em;
  border-radius: 5px;
  z-index: -1;
  height: 3rem;
`;

export const PriceCard: FC<React.PropsWithChildren<IPriceCardProps>> = ({
  children,
  readonly,
  onClick,
  selected,
  identifierPrefix,
  priceId,
  aEndPostcode,
  bEndPostcode,
  amortisedAnnualPrice,
  isPoA,
  bandwidth,
  term_length_in_months,
  product_sub_type,
  supportsSecondaryCircuits,
}) => {
  return (
    <Container>
      {supportsSecondaryCircuits && (
        <SupportsSecondaryCircuit selected={selected}>Diverse Options Available</SupportsSecondaryCircuit>
      )}

      <Card
        role="button"
        onClick={onClick}
        data-testid={selected ? 'selected-price-tile' : 'price-tile'}
        className={classNames(
          `${identifierPrefix}__${priceId}`,
          'bg-white',
          { selected },
          'shadow-sm',
          'd-flex',
          'position-relative',
          { readonly }
        )}
      >
        <div className="price-data" data-testid="price-tile">
          {(aEndPostcode || bEndPostcode) && <Postcodes aEndPostcode={aEndPostcode} bEndPostcode={bEndPostcode} />}

          {children}

          <div className="detail-blocks">
            {bandwidth && <DetailBlock dataTestId="quote-tile-bandwidth">{formatBandwidth(bandwidth)}</DetailBlock>}
            {term_length_in_months && (
              <DetailBlock dataTestId="quote-tile-term">{monthsToYears(term_length_in_months)}</DetailBlock>
            )}
            {product_sub_type && (
              <DetailBlock dataTestId="quote-tile-product-subtype">
                <ProductSubType subType={product_sub_type} />
              </DetailBlock>
            )}
          </div>
        </div>

        <PriceLabel
          amortisedAnnualPrice={amortisedAnnualPrice}
          selected={selected}
          identifierPrefix={identifierPrefix}
          poA={isPoA}
          dataTestId="quote-tile-price"
        />
      </Card>

      {selected && <Icon name="arrow_drop_down" size="xxLarge" />}
    </Container>
  );
};
