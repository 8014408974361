import React, { FC } from 'react';
import { showFullName } from 'shared/utils/nameHelper';
import styled from 'styled-components';
import { isValidEmail, isValidTextField, isValidPhoneField, isValidVLANIdField } from '../../utils/validation';
import { shadowVLANForDisplay, vlanForDisplay } from '../../utils/vlanIdForDisplay';

export enum HelperType {
  Info = 'info',
  Invalid = 'invalid',
}

interface IInfoRequiredProps {
  className?: string;
  helperType?: HelperType;
  label?: string;
  infoPromptText?: string;
  errorPromptText?: string;
  theme: {
    colours: {
      grey50: string;
    };
  };
}

const InfoRequired: FC<React.PropsWithChildren<IInfoRequiredProps>> = ({
  children,
  className,
  helperType = HelperType.Info,
  infoPromptText = 'Add info',
  errorPromptText = 'Invalid',
  label,
}) => (
  <span className={className}>
    {label && <span className="label">{`${label}: `}</span>}
    {children}
    <span className={helperType}>* {helperType === HelperType.Info ? infoPromptText : errorPromptText}</span>
  </span>
);

export const EmailSummary = ({ email }: { email: string }) =>
  !isValidTextField(email) ? (
    <StyledInfoRequired infoPromptText="Add email" />
  ) : !isValidEmail(email) ? (
    <StyledInfoRequired helperType={HelperType.Invalid} errorPromptText={`Invalid email: "${email}"`} />
  ) : (
    <>{email}</>
  );

export const TextFieldSummary = ({ text, prompt, className }: { text: string; prompt: string; className?: string }) => (
  <>{isValidTextField(text) ? <p className={className}> {text}</p> : <StyledInfoRequired infoPromptText={prompt} />}</>
);

type NameFieldSummary = FC<
  React.PropsWithChildren<{
    firstName?: string;
    surname?: string;
  }>
>;

export const NameFieldSummary: NameFieldSummary = ({ firstName, surname }) => {
  const isValidFirstName = isValidTextField(firstName);
  const isValidSurname = isValidTextField(surname);

  return (
    <>
      {isValidFirstName && isValidSurname && <p> {showFullName(firstName!, surname!)}</p>}

      {isValidFirstName && !isValidSurname && <p> {firstName}</p>}

      {!isValidFirstName && isValidSurname && <p> {surname}</p>}

      {(!isValidFirstName || !isValidSurname) && (
        <ul className="pl-0">
          {!isValidFirstName && (
            <li>
              <StyledInfoRequired infoPromptText="Add first name" />
            </li>
          )}

          {!isValidSurname && (
            <li>
              <StyledInfoRequired infoPromptText="Add surname" />
            </li>
          )}
        </ul>
      )}
    </>
  );
};

export const VLANIdFieldSummary = ({ text, prompt }: { text: string; prompt: string; className?: string }) =>
  isValidVLANIdField(text) ? (
    <TextWithLabelSummary
        id="vlan_id"
        label="VLAN ID"
        text={vlanForDisplay(text) ?? ""}
      />
  ) : (
    <StyledInfoRequired infoPromptText={prompt} />
  );

export const ShadowVLANIdFieldSummary = ({ text }: { text: string; className?: string }) =>
  isValidVLANIdField(text) ? (
    <TextWithLabelSummary
        id="svlan_id"
        label="Shadown VLAN ID"
        text={shadowVLANForDisplay(text) ?? ""}
      />
  ) : (
    <StyledInfoRequired infoPromptText={isValidVLANIdField.shadowErrorMessage} />
  );

export const PhoneFieldSummary = ({ phone }: { phone: string }) =>
  !isValidTextField(phone) ? (
    <StyledInfoRequired infoPromptText="Add phone" />
  ) : !isValidPhoneField(phone) ? (
    <StyledInfoRequired helperType={HelperType.Invalid} errorPromptText={`Invalid phone: "${phone}"`} />
  ) : (
    <>{phone}</>
  );

const RawBooleanFieldSummary = ({
  className,
  label,
  bool,
  id,
}: {
  id?: string;
  className?: string;
  maxLength?: number;
  label: string;
  bool: boolean | undefined;
  theme: {
    colours: {
      grey50: string;
    };
  };
}) => {
  return (
    <div className={className} id={id}>
      <div className="label">{label} </div>
      <span className="value">{bool !== undefined ? (bool ? 'Yes' : 'No') : 'N/A'}</span>
    </div>
  );
};

export const BooleanFieldSummary = styled(RawBooleanFieldSummary)`
  display: flex;

  .label {
    color: ${(props) => props.theme.colours.grey50};
    flex: 3;
  }

  .value {
    flex: 1;
    text-align: right;
  }
`;

const RawTextWithLabelSummary = ({
  className,
  id,
  label,
  text,
  prompt,
}: {
  className?: string;
  id?: string;
  label: string;
  maxLength?: number;
  text: string;
  prompt?: string;
  theme: {
    colours: {
      grey50: string;
    };
  };
}) => {
  if (prompt) {
    return (
      <div className={className} id={id}>
        {isValidTextField(text) ? (
          <>
            <div className="label">{label} </div>
            <span className="value">{text}</span>
          </>
        ) : (
          <StyledInfoRequired infoPromptText={prompt} />
        )}
      </div>
    );
  }

  return (
    <div className={className} id={id}>
      <div className="label">{label} </div>
      <span className="value">{isValidTextField(text) ? text : 'N/A'}</span>
    </div>
  );
};

export const TextWithLabelSummary = styled(RawTextWithLabelSummary)`
  display: flex;

  .label {
    color: ${(props) => props.theme.colours.grey50};
    flex: 2;
  }

  .value {
    flex: 1;
    text-align: right;
  }
`;

const RawNotesFieldSummary = ({
  text,
  maxLength = 500,
  errorPrompt,
  requiredPrompt,
  required,
  className,
  label,
}: {
  text: string;
  maxLength?: number;
  errorPrompt?: string;
  requiredPrompt?: string;
  required?: boolean;
  className?: string;
  label?: string;
  theme: {
    colours: {
      grey50: string;
    };
  };
}) => {
  if (required && text === '') {
    return <StyledInfoRequired helperType={HelperType.Info} infoPromptText={requiredPrompt || 'Please add notes'} />;
  }
  if (text.length <= maxLength) {
    return (
      <div className={className}>
        {label && !!text && <p className="notesLabel">{label}</p>}
        {text}
      </div>
    );
  }
  return (
    <StyledInfoRequired
      helperType={HelperType.Invalid}
      errorPromptText={errorPrompt || `Please limit notes to ${maxLength} characters`}
    />
  );
};

export const NotesFieldSummary = styled(RawNotesFieldSummary)`
  white-space: pre-wrap;
  word-wrap: break-word;

  .notesLabel {
    color: ${(props) => props.theme.colours.grey50};
    margin-bottom: 0.2rem;
  }
`;

const StyledInfoRequired = styled(InfoRequired)`
  .label {
    color: ${(props) => props.theme.colours.grey50};
  }

  .info {
    color: #f18805;
  }

  .invalid {
    color: #d51123;
  }
`;

export default StyledInfoRequired;
