import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Alert from 'shared/components/atoms/Alert';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import { CerillionQuote } from './CerillionQuote';
import { useCerillionQuote } from './useCerillionQuote';

export const CerillionQuoteById: FC<React.PropsWithChildren<unknown>> = () => {
  const { quoteId } = useParams();
  const { fetching, fetchingError, quote } = useCerillionQuote(quoteId!);

  return (
    <HeaderWithChildrenWrapper header={`Cerillion Quote ${quoteId}`}>
      {fetching && <SpinnerWithText text="Fetching quote..." size="large" />}

      {fetchingError && (
        <Alert>
          Error retrieving quote. Please try again later. If the problem persists, please contact your Account Manager.
        </Alert>
      )}

      {quote && <CerillionQuote quote={quote} />}
    </HeaderWithChildrenWrapper>
  );
};
