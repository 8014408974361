import { doRequest, Methods } from '../../../Request';
import { IUpdateUserPersonalDetailsPayload } from '../../types/actions';

interface IUpdateUserPersonalDetailsBody {
  data: {
    attributes: {
      email: string;
      job_title: string | null;
      first_name: string;
      last_name: string;
      mobile_number: string;
      landline_number: string | null;
    };
    type: 'user';
  };
}

const updateUser = ({
  contactEmail,
  jobTitle,
  firstName,
  lastName,
  mobilePhone,
  landlinePhone,
  id,
}: IUpdateUserPersonalDetailsPayload) => {
  const body: IUpdateUserPersonalDetailsBody = {
    data: {
      attributes: {
        email: contactEmail,
        job_title: jobTitle,
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobilePhone,
        landline_number: landlinePhone,
      },
      type: 'user',
    },
  };

  return doRequest({
    body,
    method: Methods.PATCH,
    path: `/users/${id}`,
  });
};

export default updateUser;
