import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  theme: {
    colours: {
      grey50: string;
    };
  };
}

const FormRowBlock: FunctionComponent<React.PropsWithChildren<Props>> = ({ className, children }) => {
  return <div className={`form-row ${className}`}>{children}</div>;
};

const StyledFormRowBlock = styled(FormRowBlock)`
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 70, 135, 0.1);
  margin: 1em 0;
  padding: 1em;
`;
export default StyledFormRowBlock;
