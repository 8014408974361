import React, { FunctionComponent } from 'react';

interface IEmptyBulkQuotesList {
  className?: string;
}

const EmptyBulkQuotesList: FunctionComponent<React.PropsWithChildren<IEmptyBulkQuotesList>> = () => (
  <div className="empty-bulk-quotes">
    <p>It looks like you don&apos;t have any bulk quotes yet.</p>
  </div>
);

export default EmptyBulkQuotesList;
