import { doRequest, Methods } from '../../../Request';
import { PromiseBasedFileReader } from '../../utils/PromiseBasedFileReader';

export const postBulkQuote = async (
  fileReader: PromiseBasedFileReader,
  bulkQuoteFile: File,
  selectedCompanyId: string
) => {
  const fileContents = await fileReader.read(bulkQuoteFile);
  const body = {
    data: {
      attributes: {
        filename: bulkQuoteFile.name,
        base_64_csv_data: btoa(fileContents),
      },
      type: 'bulk_quote_request',
    },
  };

  return doRequest({
    body,
    method: Methods.POST,
    path: `/customers/${selectedCompanyId}/bulk-quote-requests`,
  });
};
