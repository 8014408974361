import { TextWithLabelSummary } from 'Order/OrderBuilder/shared/components/SummaryTextHelper';
import { ISiteConfig } from 'Order/types/location';
import React from 'react';

export const ShadowNNISummaryFields = ({ siteConfig }: { siteConfig: ISiteConfig }) => (
  <>
    <TextWithLabelSummary
      id="summary_shadow_nni_job_reference"
      label="Shadow NNI Job Reference"
      text={siteConfig.shadow_nni_job_reference || ''}
    />
    <TextWithLabelSummary
      id="summary_shadow_nni_idn_number"
      label="Shadow IDN"
      text={siteConfig.shadow_nni_idn_number || ''}
    />
  </>
);
