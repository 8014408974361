import { ProductType } from '../../../../Quotes/types/productTypes';
import Column from '../../../../shared/components/atoms/Column';
import Cost from './Cost';
import React from 'react';

export function BEndSupplierCosts(props: { productType: ProductType; bEndSetupCost: number | null; cost: number }) {
  return (
    <>
      {props.productType !== ProductType.DIA && (
        <div className="row no-gutters mt-3">
          <Column defaultWidth={2}>
            <span className="sub-heading">B-End</span>
          </Column>
          <Column defaultWidth={2}>
            <span>Supplier</span>
          </Column>
          <Cost label="Install" cost={props.bEndSetupCost ?? 0} className="b-end-setup-cost" />
          <Cost label="Annual" cost={props.cost} className="b-end-annual-cost" />
        </div>
      )}
    </>
  );
}
