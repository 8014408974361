import { ProductSubType as SubType } from 'Quotes/types/store';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  subType: SubType;
}

const UnstyledProductSubType: FunctionComponent<React.PropsWithChildren<Props>> = ({ className, subType }) => {
  return (
    <div className={className}>
      <img
        src={`/images/product_sub_type/${subType}.svg`}
        alt={`Product sub type is ${subType}`}
        title={`Product sub type is ${subType}`}
        width="45"
        height="45"
      />
    </div>
  );
};

export const ProductSubType = styled(UnstyledProductSubType)`
  border-bottom-left-radius: 5px;
`;
