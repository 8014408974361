import { AnyAction, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';

import adminReducer, { IAdminState } from './Admin/reducer';
import alertsReducer from './Alerts/reducer';
import authReducer, { IAuthState } from './Auth/reducer';
import ordersReducer from './Order/reducer';
import quotesReducer from './Quotes/reducer';
import storage from 'redux-persist/lib/storage';
import userReducer, { IUserState } from './User/reducer';
import tablesReducer from './Tables/reducer';
import messagesReducer, { MessagesState } from 'Messages/reducer';
import { IAlertsState } from 'Alerts/types/store';
import { IOrderState } from 'Order/types/store';
import { IQuotesState } from 'Quotes/types/store';
import { RootActionTypes } from 'actions';
import { ITablesState } from 'Tables/types/store';
import { baseApi } from 'apiSlice';

const appReducer = combineReducers({
  admin: adminReducer,
  alerts: alertsReducer,
  auth: authReducer,
  orderBuilder: ordersReducer,
  quoteBuilder: quotesReducer,
  user: userReducer,
  tables: tablesReducer,
  messages: messagesReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

// The rootReducer below ensures that the store gets flushed when the RESET_APP action is triggered.
// It shouldn't be used to make any other actions - for this purpose use one of the reducers in ^combineReducers instead.

export const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === RootActionTypes.RESET_APP) {
    state = undefined;
  }

  return appReducer(state, action);
};

const saveSubsetFilter = createFilter('user', ['userData', 'companyData']);
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
  transforms: [saveSubsetFilter],
};

export interface IAppState {
  admin: IAdminState;
  alerts: IAlertsState;
  auth: IAuthState;
  orderBuilder: IOrderState;
  quoteBuilder: IQuotesState;
  user: IUserState;
  tables: ITablesState;
  messages: MessagesState;
}

export default persistReducer(persistConfig, rootReducer);
