import {
  AdminActionTypes,
  IAddUser,
  IUserData,
  IAddUserSuccess,
  IAddUserError,
  IAddUserStart,
  IAddUserSetRequestState,
  IGetUser,
  IGetUserPayload,
  ISetCurrentlyViewedUser,
  IGetUserSuccess,
  IGetUserError,
  IGetUserStart,
  IUpdateUser,
  IUpdateUserSuccess,
  IUpdateUserError,
  IUpdateUserStart,
  IUpdateUserPayload,
  IResetUpdateUserRequestState,
  IResetCurrentlyViewedUser,
  ISendInvite,
  ISendInviteSuccess,
  ISendInviteStart,
  ISendInviteError,
  ISendInvitePayload,
  IBlockUserPayload,
  IBlockUser,
  IBlockUserError,
  IBlockUserStart,
  IBlockUserSuccess,
  IUnblockUserPayload,
  IUnblockUser,
  IUnblockUserSuccess,
  IUnblockUserError,
  IUnblockUserStart,
  IResetRequestsState,
  IEditUserState,
} from './types/actions';
import { IUserBackendProfile } from '../User/types/backendUser';
import { IRow } from './shared/components/CompaniesRolesTable';
import { RequestState } from './types';

export const addUserAction = (userData: IUserData, companiesRolesRows: IRow[]): IAddUser => ({
  payload: {
    userData,
    companiesRolesRows,
  },
  type: AdminActionTypes.ADD_USER,
});

export const addUserSuccess = (): IAddUserSuccess => ({
  type: AdminActionTypes.ADD_USER_SUCCESS,
});

export const addUserError = (error: any): IAddUserError => ({
  payload: { error },
  type: AdminActionTypes.ADD_USER_ERROR,
});

export const addUserStart = (): IAddUserStart => ({
  type: AdminActionTypes.ADD_USER_START,
});

export const setAddUserRequestState = (requestState: RequestState): IAddUserSetRequestState => ({
  payload: {
    state: requestState,
  },
  type: AdminActionTypes.ADD_USER_SET_REQUEST_STATE,
});

export const getUserAction = (getUserPayload: IGetUserPayload): IGetUser => ({
  payload: getUserPayload,
  type: AdminActionTypes.GET_USER,
});

export const getUserSuccess = (): IGetUserSuccess => ({
  type: AdminActionTypes.GET_USER_SUCCESS,
});

export const getUserError = (): IGetUserError => ({
  type: AdminActionTypes.GET_USER_ERROR,
});

export const getUserStart = (): IGetUserStart => ({
  type: AdminActionTypes.GET_USER_START,
});

export const setCurrentlyViewedUser = (user: IUserBackendProfile): ISetCurrentlyViewedUser => ({
  payload: { user },
  type: AdminActionTypes.SET_CURRENTLY_VIEWED_USER,
});

export const updateUserAction = (data: IUpdateUserPayload): IUpdateUser => ({
  payload: data,
  type: AdminActionTypes.UPDATE_USER,
});

export const updateUserSuccess = (): IUpdateUserSuccess => ({
  type: AdminActionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserError = (): IUpdateUserError => ({
  type: AdminActionTypes.UPDATE_USER_ERROR,
});

export const updateUserStart = (): IUpdateUserStart => ({
  type: AdminActionTypes.UPDATE_USER_START,
});

export const resetUpdateUserRequestState = (): IResetUpdateUserRequestState => ({
  type: AdminActionTypes.RESET_UPDATE_USER_REQUEST_STATE,
});

export const resetCurrentlyViewedUser = (): IResetCurrentlyViewedUser => ({
  type: AdminActionTypes.RESET_CURRENTLY_VIEWED_USER,
});

export const sendInviteAction = (sendInvitePayload: ISendInvitePayload): ISendInvite => ({
  payload: sendInvitePayload,
  type: AdminActionTypes.SEND_INVITE,
});

export const sendInviteSuccess = (): ISendInviteSuccess => ({
  type: AdminActionTypes.SEND_INVITE_SUCCESS,
});

export const sendInviteError = (): ISendInviteError => ({
  type: AdminActionTypes.SEND_INVITE_ERROR,
});

export const sendInviteStart = (): ISendInviteStart => ({
  type: AdminActionTypes.SEND_INVITE_START,
});

export const blockUserAction = (blockUserPayload: IBlockUserPayload): IBlockUser => ({
  payload: blockUserPayload,
  type: AdminActionTypes.BLOCK_USER,
});

export const blockUserSuccess = (): IBlockUserSuccess => ({
  type: AdminActionTypes.BLOCK_USER_SUCCESS,
});

export const blockUserError = (): IBlockUserError => ({
  type: AdminActionTypes.BLOCK_USER_ERROR,
});

export const blockUserStart = (): IBlockUserStart => ({
  type: AdminActionTypes.BLOCK_USER_START,
});

export const unblockUserAction = (unblockUserPayload: IUnblockUserPayload): IUnblockUser => ({
  payload: unblockUserPayload,
  type: AdminActionTypes.UNBLOCK_USER,
});

export const unblockUserSuccess = (): IUnblockUserSuccess => ({
  type: AdminActionTypes.UNBLOCK_USER_SUCCESS,
});

export const unblockUserError = (): IUnblockUserError => ({
  type: AdminActionTypes.UNBLOCK_USER_ERROR,
});

export const unblockUserStart = (): IUnblockUserStart => ({
  type: AdminActionTypes.UNBLOCK_USER_START,
});

export const resetRequestState = (): IResetRequestsState => ({
  type: AdminActionTypes.RESET_REQUESTS_STATE,
});

export const editUserState = (): IEditUserState => ({
  type: AdminActionTypes.EDIT_USER_STATE,
});
