import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { getQuotesReport } from '../../crud/getQuotesReport';
import Alert from '../../../shared/components/atoms/Alert';
import SpinnerWithText from '../../../shared/components/molecules/SpinnerWithText';
import StyledIcon from '../../../shared/components/atoms/Icon';
import buildFileNameWithDate from '../../../shared/utils/buildFileNameWithDate';
import CustomersFilter from '../filters/CustomersFilter';
import ProductsFilter from '../filters/ProductsFilter';
import DateRangeFilter from '../filters/DateRangeFilter';
import { BEndPortFilter } from '../filters/BEndPortFilter';
import { BandwidthFilter } from '../filters/BandwidthFilter';
import { formatDatePickerDate } from '../../../shared/utils/dateHelper';
import QuoteStatusFilter from '../filters/QuoteStatusFilter';
import Button, { ButtonMainStyle } from '../../../shared/components/atoms/Button';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import { selectCompanyData } from 'User/selectors';

type Props = {
  className?: string;
};

export const QuotesReport: FunctionComponent<React.PropsWithChildren<Props>> = ({ className }) => {
  const companyData = useSelector(selectCompanyData);

  const [customerId, setCustomerId] = useState<string | null>(null);
  const [productType, setProductType] = useState<string | null | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [orderStatus, setQuoteStatus] = useState<string | null>(null);
  const [bEndPort, setBEndPort] = useState<string | null>(null);
  const [bandwidth, setBandwidth] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [successfullDownload, setSuccessfullDownload] = useState<boolean>(false);

  const exportCSV = async () => {
    setIsDownloading(true);
    setSuccessfullDownload(false);
    setHasErrored(false);

    try {
      const result = await getQuotesReport({
        customer_id: customerId,
        product_type: productType,
        start_date: startDate ? formatDatePickerDate(startDate) : null,
        end_date: endDate ? formatDatePickerDate(endDate) : null,
        state: orderStatus,
        b_end_port: bEndPort,
        max_bandwidth: bandwidth ? bandwidth.split('-')[1] : null,
        min_bandwidth: bandwidth ? bandwidth.split('-')[0] : null,
      });

      const blob = new Blob([result], { type: 'data:text/csv;charset=utf-8,' });

      saveAs(blob, buildFileNameWithDate('Digital Portal Quotes', 'csv'));
      setSuccessfullDownload(true);
    } catch (error) {
      setHasErrored(true);
      setSuccessfullDownload(false);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className={className}>
      <div className="reports-filters__wrapper row">
        <CustomersFilter userCompanies={companyData.companies} setCustomerId={setCustomerId} />
        <ProductsFilter setProductType={setProductType} />
        <DateRangeFilter startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        <QuoteStatusFilter setQuoteStatus={setQuoteStatus} />
        <BEndPortFilter setFilter={setBEndPort} />
        <BandwidthFilter setFilter={setBandwidth} />
        <div className="downloading">
          <Button
            onClick={exportCSV}
            mainStyle={ButtonMainStyle.PrimaryRectangular}
            className="download-btn"
            disabled={isDownloading}
          >
            Download report to CSV
          </Button>
        </div>
      </div>

      {successfullDownload && (
        <div className="text-center mt-3">
          <StyledIcon name="check" className="success-icon" size="xxLarge" />
          <p>Downloaded</p>
        </div>
      )}

      {isDownloading && <SpinnerWithText text="Downloading" />}

      {hasErrored && (
        <div className="mt-3">
          <Alert>
            Error whilst downloading your report. Please try again later. If the problem persists, please contact your
            Account Manager.
          </Alert>
        </div>
      )}
    </div>
  );
};

export default styled(QuotesReport)`
  .reports-filters__wrapper {
    padding-top: 0;

    .control .customers-dropdown {
      width: 15em;
    }

    .control .bandwidth-dropdown {
      width: 13em;
    }

    .control .products-dropdown,
    .control .quote-status-dropdown {
      width: 12em;
    }
  }

  .downloading {
    display: block;
    text-align: right;
    flex: 1;
  }

  .control {
    margin-top: 1em;
  }

  .download-btn {
    margin-top: 1.2em;
    width: fit-content;
  }

  .success-icon {
    color: ${(props) => props.theme.colours.primaryB1};
    vertical-align: bottom;
  }
`;
