import { useSessionTimeCheck } from 'Auth/hooks';
import React, { FC, useEffect } from 'react';
import { SplashLayout } from './SplashLayout';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

const UnauthenticatedLayout: FC<React.PropsWithChildren<unknown>> = (props) => {
  const navigate = useNavigate();
  return <UnauthenticatedLayoutInternal {...props} navigate={navigate} />;
};

export const UnauthenticatedLayoutInternal: FC<React.PropsWithChildren<Navigation>> = ({ children, navigate }) => {
  const { activeSession } = useSessionTimeCheck();

  useEffect(() => {
    if (activeSession) {
      navigate('/');
    }
  }, [activeSession, navigate]);

  if (activeSession === false) {
    return <SplashLayout>{children}</SplashLayout>;
  }

  return null;
};

export default UnauthenticatedLayout;
