import React from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from 'styled-components';

const HeadTagsByTheme = () => {
  const theme = useTheme();

  return (
    <Helmet>
      <title>{theme.content.title}</title>
      <link rel="shortcut icon" href={theme.favicons.shortcutIcon} />
      <link rel="apple-touch-icon" sizes="144x144" href={theme.favicons.appleTouchIcon} />
      <link rel="icon" type="image/png" sizes="16x16" href={theme.favicons.favicon16} />
      <link rel="icon" type="image/png" sizes="32x32" href={theme.favicons.favicon32} />
      {theme.customCSS && <link rel="stylesheet" href={theme.customCSS} />}
    </Helmet>
  );
};

export default HeadTagsByTheme;
