import React from 'react';
import { currencyFormatter } from 'shared/utils/currencyFormatter';

interface FttpAggregationChargeProps {
  fttpAggregationCharge: number;
}

const FttpAggregationCharge: React.FC<FttpAggregationChargeProps> = ({ fttpAggregationCharge }) => {
  return (
    <div className="price-summary__segment-wrapper font-italic pl-2">
      <div>Includes: FTTP Aggregation Surcharge</div>
      <div className="d-flex flex-column align-items-end flex-fill">
        <b className="total currency">{currencyFormatter.format(fttpAggregationCharge)}</b>
      </div>
    </div>
  );
};

export default FttpAggregationCharge;
