import React, { FunctionComponent } from 'react';

import { IOpticalDataCentre } from '../types';
import Dropdown, { ICustomComponents, IDropdownOption } from '../../../shared/components/molecules/Dropdown';

interface IDataCentreSelectProps {
  className?: string;
  opticalDataCentreList: IOpticalDataCentre[];
  labelText: string;
  selectedId?: string;
  placeholder?: string;
  onChange(selection: IDropdownOption): void;
  customComponents?: ICustomComponents;
}

export const DEFAULT_OPTICAL_DATA_CENTRE_PLACEHOLDER = 'Search for a Data Centre';

const DataCentreSelect: FunctionComponent<React.PropsWithChildren<IDataCentreSelectProps>> = ({
  className,
  opticalDataCentreList,
  onChange,
  placeholder,
  labelText,
  selectedId,
  customComponents,
}) => {
  let defaultValue;
  const match = opticalDataCentreList.find((opticalDataCentre) => opticalDataCentre.id === selectedId);

  if (match) {
    defaultValue = {
      label: `${match.attributes.name}, ${match.attributes.postcode}`,
      value: match.id,
    };
  }

  const options = opticalDataCentreList
    .map((opticalDataCentre) => ({
      label: `${opticalDataCentre.attributes.name}, ${opticalDataCentre.attributes.postcode}`,
      value: opticalDataCentre.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className={className}>
      <p className="label">{labelText}</p>
      <Dropdown
        defaultValue={defaultValue}
        placeholder={placeholder || DEFAULT_OPTICAL_DATA_CENTRE_PLACEHOLDER}
        customComponents={customComponents}
        onChange={onChange}
        options={options}
      />
    </div>
  );
};

export default DataCentreSelect;
