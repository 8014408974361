import produce from 'immer';
import { IAlertsState } from './types/store';
import { AlertActions, AlertActionTypes } from './types/actions';

export const initialState: IAlertsState = {
  retrieving: {
    inProgress: true,
  },
  updating: {
    inProgress: false,
  },
};

const reducer = produce((draft: IAlertsState, action: AlertActions): void | IAlertsState => {
  switch (action.type) {
    case AlertActionTypes.GET_ALERTS_STARTED:
    case AlertActionTypes.GET_NEXT_ALERTS_STARTED:
      draft.retrieving.inProgress = true;
      break;
    case AlertActionTypes.GET_NEXT_ALERTS_ERROR:
    case AlertActionTypes.GET_ALERTS_ERROR:
      draft.retrieving.error = true;
      draft.retrieving.inProgress = false;
      draft.retrieving.errorResponse = action.payload.response;
      break;
    case AlertActionTypes.GET_ALERTS_SUCCESS:
      draft.retrieving.inProgress = false;
      draft.retrieving.error = false;
      draft.retrieving.errorResponse = null;
      draft.data = action.payload.alertsRecord;
      break;
    case AlertActionTypes.GET_NEXT_ALERTS_SUCCESS:
      draft.retrieving.inProgress = false;
      draft.retrieving.error = false;
      draft.retrieving.errorResponse = null;
      draft.data = {
        ...action.payload.alertsRecord,
        data: (draft.data?.data || []).concat(action.payload.alertsRecord.data),
      };
      break;

    case AlertActionTypes.MARK_ALERT_AS_READ: {
      const alertId = action.payload.alertId;

      if (draft.data && Array.isArray(draft.data.data)) {
        draft.data.data = draft.data.data.map((item) => {
          if (alertId === '*' || alertId === item.id) {
            return {
              ...item,
              attributes: {
                ...item.attributes,
                unread: false,
              },
            };
          } else {
            return item;
          }
        });
      }

      break;
    }
    default:
      return draft;
  }
}, initialState);

export default reducer;
