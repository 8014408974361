import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import TextInput from 'shared/components/atoms/TextInput';
import { GroupedFields } from 'shared/components/molecules/GroupedFields';
import { IOpenReachAddress } from 'shared/types/postcodeResults';

interface IManualAddressFields {
  className?: string;
  inputIdentifierPrefix: string;
  fullAddress: IOpenReachAddress;
  postcode: string;
  fieldOnChange(fieldName: keyof IOpenReachAddress, fieldValue: string): void;
}

const ManualAddressFields: FunctionComponent<React.PropsWithChildren<IManualAddressFields>> = ({
  className,
  inputIdentifierPrefix,
  fullAddress,
  postcode,
  fieldOnChange,
}) => {
  useEffect(() => {
    fieldOnChange('postcode', postcode);
  }, [fieldOnChange, postcode]);

  return (
    <div className={className}>
      <div className={`${inputIdentifierPrefix}form`}>
        <div className="manualForm_buildingData">
          <GroupedFields footerText="* A value is required for at least one of these fields.">
            <div className="mr-1 w-100">
              <TextInput
                fieldName="buildingNumber"
                inputIdentifierPrefix={inputIdentifierPrefix}
                labelText="Building number"
                maxLength={8}
                onChange={(e) => fieldOnChange('building_number', e.target.value)}
                value={fullAddress.building_number}
              />
            </div>
            <TextInput
              fieldName="buildingName"
              inputIdentifierPrefix={inputIdentifierPrefix}
              labelText="Building name"
              maxLength={50}
              onChange={(e) => fieldOnChange('building_name', e.target.value)}
              value={fullAddress.building_name}
            />
          </GroupedFields>
        </div>
        <TextInput
          fieldName="subBuilding"
          inputIdentifierPrefix={inputIdentifierPrefix}
          optional={true}
          labelText="Sub building"
          maxLength={50}
          onChange={(e) => fieldOnChange('sub_building', e.target.value)}
          value={fullAddress.sub_building}
        />
        <TextInput
          fieldName="street"
          inputIdentifierPrefix={inputIdentifierPrefix}
          labelText="Street *"
          maxLength={50}
          onChange={(e) => fieldOnChange('street', e.target.value)}
          value={fullAddress.street}
        />
        <TextInput
          fieldName="town"
          inputIdentifierPrefix={inputIdentifierPrefix}
          labelText="Town/city *"
          maxLength={50}
          onChange={(e) => fieldOnChange('post_town', e.target.value)}
          value={fullAddress.post_town}
        />
        <TextInput
          fieldName="county"
          inputIdentifierPrefix={inputIdentifierPrefix}
          labelText="County *"
          maxLength={50}
          onChange={(e) => fieldOnChange('county', e.target.value)}
          value={fullAddress.county}
        />
        <TextInput
          fieldName="postcode"
          inputIdentifierPrefix={inputIdentifierPrefix}
          onChange={(e) => fieldOnChange('county', e.target.value)}
          labelText="Postcode *"
          readOnly={true}
          value={postcode}
        />
      </div>
    </div>
  );
};

const StyledManualAddressFields = styled(ManualAddressFields)`
  .manualForm_buildingData {
    display: flex;
  }

  .manualForm_separator {
    font-size: 1.2em;
  }

  .form-group {
    width: 100%;
  }
`;

export default StyledManualAddressFields;
