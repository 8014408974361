export const emptyFilterString = '__EMPTY__';

interface IRow {
  values: any;
}

export const equalsOrEmptyFilter = (rows: Array<IRow>, ids: Array<string>, filterValue: string) => {
  if (filterValue !== emptyFilterString) {
    const filterString = String(filterValue).toLowerCase();
    return (rows || []).filter((row: IRow) => {
      return ids.some((id: string) => {
        const rowValue = row.values[id];
        return String(rowValue).toLowerCase().includes(filterString);
      });
    });
  }
  return (rows || []).filter((row: IRow) => {
    return ids.some((id: string) => !row.values[id]);
  });
};
