import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';
import { IAppState } from 'reducers';
import Alert from '../../../../shared/components/atoms/Alert';
import Button, { ButtonMainStyle } from '../../../../shared/components/atoms/Button';
import { usePreviousState } from 'shared/utils/customHooks';
import { createQuoteAction, updateQuoteAction } from 'Quotes/actions';
import { IAccordionSectionRenderProps } from '../../types';
import { IQuotesState } from 'Quotes/types/store';
import { ProductType } from 'Quotes/types/productTypes';
import LocationForProductType from './LocationForProductType';
import { isUnsavedLocationDetailCompleted } from '../../utils/accordionPanel';
import { quoteById } from 'Quotes/Routes';
import { pagesWithType, trackPage } from 'shared/utils/trackPage';
import IQuoteRecordAttributesBase from '../../../types/quoteRecordAttributesBase';
import { getBaseAttributes } from 'Quotes/crud/createQuote';
import { CompanyData } from 'User/types/user';
import { selectAddressesRetrievalPending } from 'Quotes/selectors';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface ILocation {
  accordion: IAccordionSectionRenderProps;
  addressesRetrievalPending: boolean;
  className?: string;
  productType: ProductType;
  saving?: boolean;
  savingError?: boolean;
  quoteBuilder: IQuotesState;
  currentQuoteId?: string;
  companyData: CompanyData;
  updatingQuote: boolean;
  createQuote(quote: IQuotesState['quote']): void;
  updateQuote(quoteId: string, updateValues: IQuoteRecordAttributesBase): void;
}

export const Location: FunctionComponent<React.PropsWithChildren<ILocation>> = (props) => {
  const navigate = useNavigate();

  return <LocationInternal {...props} navigate={navigate} />;
};

export const LocationInternal: FunctionComponent<React.PropsWithChildren<ILocation & Navigation>> = ({
  accordion,
  addressesRetrievalPending,
  className,
  createQuote,
  productType,
  saving,
  savingError,
  quoteBuilder,
  currentQuoteId,
  updateQuote,
  companyData,
  updatingQuote,
  navigate,
}) => {
  const prevSaving = usePreviousState(saving);
  const prevUpdating = usePreviousState(updatingQuote);

  useEffect(() => {
    if (productType) {
      trackPage(pagesWithType.setQuoteLocation(productType));
    }
  }, [productType]);
  useEffect(() => {
    if (prevSaving && !saving && !savingError && currentQuoteId) {
      navigate(quoteById(currentQuoteId));
    }
  }, [accordion, prevSaving, currentQuoteId, saving, savingError, navigate]);

  useEffect(() => {
    if (prevUpdating && !updatingQuote) {
      accordion.showContent(accordion.index + 1);
    }
  }, [accordion, updatingQuote, prevUpdating]);

  return (
    <div className={className}>
      <LocationForProductType productType={quoteBuilder.quote.productType} />
      <div className="location_saveAndContinueButton_wrapper pb-3">
        <Button
          disabled={
            !isUnsavedLocationDetailCompleted({
              aEndLocation: quoteBuilder.quote.location.aEnd,
              bEndLocation: quoteBuilder.quote.location.bEnd,
              productType,
              meta: quoteBuilder.quoteEndpointMeta,
            }) ||
            updatingQuote ||
            saving ||
            addressesRetrievalPending
          }
          saving={saving}
          loading={updatingQuote}
          id="location_saveAndContinueButton"
          mainStyle={ButtonMainStyle.PrimaryRectangular}
          onClick={() =>
            currentQuoteId
              ? updateQuote(
                  currentQuoteId,
                  getBaseAttributes(quoteBuilder.quote, quoteBuilder.quoteEndpointMeta, companyData)
                )
              : createQuote(quoteBuilder.quote)
          }
        >
          Next
        </Button>
      </div>
      {savingError && (
        <Alert>
          Saving quote failed. Please try again later. If the problem persists, please contact your Account Manager.
        </Alert>
      )}
    </div>
  );
};

const StyledLocation = styled(Location)`
  .location_saveAndContinueButton_wrapper {
    text-align: right;
  }

  .location-nni {
    padding-top: 1em;
  }

  .alert-danger {
    margin-top: 2em;
  }
`;

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  createQuote: (quote: IQuotesState['quote']) => dispatch(createQuoteAction(quote)),
  updateQuote: (quoteId: string, updateValues: IQuoteRecordAttributesBase) =>
    dispatch(updateQuoteAction(quoteId, updateValues)),
});

const mapStateToProps = (state: IAppState) => ({
  productType: state.quoteBuilder.quote.productType,
  quoteBuilder: state.quoteBuilder,
  currentQuoteId: state.quoteBuilder.currentQuoteId,
  saving: state.quoteBuilder.creating.inProgress,
  savingError: state.quoteBuilder.creating.error,
  companyData: state.user.companyData,
  updatingQuote: state.quoteBuilder.updating.inProgress,
  addressesRetrievalPending: selectAddressesRetrievalPending(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledLocation);
