import { IOrder } from 'Order/types/store';
import { contactIsComplete } from './contactComplete';
import {
  isValidEmail,
  isValidTextField,
  isValidPhoneField,
  isValidNoteField,
  isValidBillingFrequency,
} from './validation';
import { ProductType } from 'Quotes/types/productTypes';
import { IQuote } from 'Quotes/types/store';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';

const isIPContactComplete = (order: IOrder): boolean =>
  isValidTextField(order.ipContact.firstName) &&
  isValidTextField(order.ipContact.surname) &&
  isValidPhoneField(order.ipContact.telephone) &&
  isValidEmail(order.ipContact.email) &&
  isValidTextField(order.ipContact.address) &&
  isValidTextField(order.ipContact.organisation) &&
  isValidNoteField(order.ipText);

const isIPAddressJustified = (quote: IQuote, order: IOrder) => {
  return quote.location.aEnd.ip.selectedId !== IPType.STATIC_16 ? true : order.ipText.length > 0;
};

export const isCompleted = (order: IOrder, quote: IQuote): boolean =>
  contactIsComplete(order.orderContact) &&
  contactIsComplete(order.operationalContact) &&
  isValidTextField(order.billingContactAndAddress.purchaseOrderRef) &&
  isValidBillingFrequency(order.billingFrequency) &&
  (order.productType === ProductType.DIA ? isIPContactComplete(order) && isIPAddressJustified(quote, order) : true);
