import React, { ChangeEvent, FunctionComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

interface CheckedWithDefault {
  defaultChecked: boolean;
  checked: boolean;
}

interface CheckedDefaultOnly {
  defaultChecked: boolean;
}

interface CheckedNoDefault {
  checked: boolean;
}

interface IToggleButton {
  toggleKey?: string;
  className?: string;
  label: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onToggleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const UnstyledDiverseToggleButton: FunctionComponent<
  React.PropsWithChildren<IToggleButton & (CheckedNoDefault | CheckedWithDefault | CheckedDefaultOnly)>
> = ({ toggleKey, className, label, defaultChecked, checked, disabled = false, onToggleChange }) => {
  return (
    <label className={cx(className, 'toggle')}>
      <span className="toggle-label">{label}</span>
      <input
        key={toggleKey}
        className="toggle-checkbox"
        type="checkbox"
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onToggleChange}
        data-key={toggleKey}
        disabled={disabled}
      />
      <div className="toggle-switch"></div>
    </label>
  );
};

const DiverseToggleButton = styled(UnstyledDiverseToggleButton)`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .toggle {
    cursor: pointer;
    display: inline-block;
  }

  .toggle-switch {
    display: inline-block;
    background: #dadada;
    border-radius: 16px;
    width: 58px;
    height: 32px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
  }
  .toggle-switch:before,
  .toggle-switch:after {
    content: '';
  }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggle-checkbox:checked + .toggle-switch {
    background: #3ab1a5;
  }
  .toggle-checkbox:checked + .toggle-switch:before {
    left: 30px;
  }

  .toggle-checkbox:disabled + .toggle-switch {
    background-color: ${(props) => props.theme.colours.grey40};
  }

  .toggle-checkbox {
    position: absolute;
    visibility: hidden;
  }

  .toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
  }

  .toggle-checkbox:disabled ~ .toggle-label {
    color: ${(props) => props.theme.colours.grey40};
    // background-color: ${(props) => props.theme.colours.grey40};
  }
`;

export default DiverseToggleButton;
