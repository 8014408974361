import React, { FunctionComponent } from 'react';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';

interface LoadingProps {
  message?: string;
  isLoading: boolean;
  children: React.ReactNode;
}

export const Loading: FunctionComponent<React.PropsWithChildren<LoadingProps>> = ({
  children,
  message = 'Loading',
  isLoading,
}) => {
  if (isLoading) {
    return <SpinnerWithText text={message} />;
  } else {
    return <>{children}</>;
  }
};
