import { useEffect } from 'react';
import { useRequest, Config } from './useRequest';

export const useImmediateRequest = <T>(requestFunction: () => Promise<T>, config: Config<T> = {}) => {
  const { executeRequest, ...rest } = useRequest(requestFunction, config);

  useEffect(() => {
    executeRequest();
  }, [executeRequest]);

  return { retryExecuteRequest: executeRequest, ...rest };
};
