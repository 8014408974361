import { doRequest, Methods } from '../../../Request/index';

const blockUser = (userId: string) => {
  return doRequest({
    method: Methods.POST,
    path: `/users/${userId}/disable`,
  });
};

export default blockUser;
