export enum AlertEventType {
  CANCELLED = 'order_cancelled',
  CUSTOMER_NOTE = 'customer_note_created',
  IN_REVIEW = 'order_in_review',
  PLACED = 'order_placed',
  RETURNED = 'order_returned',
  SSE_NOTE = 'sse_note_created',
  NNI_ORDERED = 'nni_ordered',
  CERILLION_QUOTE_STAGE_UPDATE = 'quote_cerillion_stage_update',
  CERILLION_ORDER_STAGE_UPDATE = 'order_cerillion_stage_update',
}
