import { ITablesState, IStoreKeyNameFilters } from './types/store';

export enum TableActionTypes {
  SET_TABLE_FILTER = 'SET_TABLE_FILTER',
  SET_TABLE_PAGE_SIZE = 'SET_TABLE_PAGE_SIZE',
}

interface ISetTableFilter {
  type: TableActionTypes.SET_TABLE_FILTER;
  payload: {
    tableName: keyof ITablesState;
    key: IStoreKeyNameFilters;
    value: string | undefined;
  };
}

interface ISetTablePageSize {
  type: TableActionTypes.SET_TABLE_PAGE_SIZE;
  payload: {
    tableName: keyof ITablesState;
    pageSize: number;
  };
}

export const setTableFilter = (
  tableName: keyof ITablesState,
  key: IStoreKeyNameFilters,
  value: string | undefined
): ISetTableFilter => ({
  payload: { tableName, key, value },
  type: TableActionTypes.SET_TABLE_FILTER,
});

export const setTablePageSize = (tableName: keyof ITablesState, pageSize: number): ISetTablePageSize => ({
  payload: { tableName, pageSize },
  type: TableActionTypes.SET_TABLE_PAGE_SIZE,
});

export type TableActions = ISetTableFilter | ISetTablePageSize;
