import React, { FC } from 'react';
import { ConnectorType, ISiteConfig } from 'Order/types/location';
import RadioButton, { RadioButtonDisabledOverride } from 'shared/components/molecules/RadioButton';
import { BearerType, isOpticalBearer } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { useSelector } from 'react-redux';
import { RJ45Availability, selectProductType, selectQuote, selectRJ45ConnectorTypeAvailable } from 'Quotes/selectors';
import { ProductType } from 'Quotes/types/productTypes';
import { getDIAOrderOptions } from './DIAOrderOptions';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { StackedOptions } from '../utils/StackedOptions';
import { Supplier } from 'Quotes/types/supplier';

interface Props {
  className?: string;
  identifierPrefix: string;
  siteConfig: ISiteConfig;
  accessType?: string | null;
  endPort: BearerType;
  isSupplierNNAT: boolean;
  onChange(field: string, value: any): void;
}

const ConnectorTypeOptions: FC<Props> = ({
  endPort,
  identifierPrefix,
  isSupplierNNAT,
  onChange,
  siteConfig,
  accessType
}) => {
  const RJ45Availability = useSelector(selectRJ45ConnectorTypeAvailable);
  const isManagedDIA = useSelector(selectQuote).location.aEnd.is_managed_dia;
  const productType = useSelector(selectProductType);
  const { showLC, showSC, showRJ45 } = getConnectorAvailability(
    productType,
    isManagedDIA,
    endPort,
    isSupplierNNAT,
    identifierPrefix,
    RJ45Availability,
    accessType
  );

  return (
    <StackedOptions>
      {showLC && (
        <RadioButton
          size="fill"
          id={`${identifierPrefix}_connectorType_LC`}
          description="LC"
          onClick={() => onChange('connectorType', ConnectorType.LC)}
          selected={siteConfig.connectorType === ConnectorType.LC}
        />
      )}
      {showSC && (
        <RadioButton
          size="fill"
          id={`${identifierPrefix}_connectorType_SC`}
          description="SC"
          onClick={() => onChange('connectorType', ConnectorType.SC)}
          selected={siteConfig.connectorType === ConnectorType.SC}
        />
      )}
      {showRJ45 && (
        <RadioButtonDisabledOverride>
          <RadioButton
            size="fill"
            id={`${identifierPrefix}_connectorType_RJ45`}
            description="RJ45"
            onClick={() => onChange('connectorType', ConnectorType.RJ45)}
            selected={siteConfig.connectorType === ConnectorType.RJ45}
          />
        </RadioButtonDisabledOverride>
      )}
    </StackedOptions>
  );
};

const getNewMDIAConnectors = (
  isManagedDIA: boolean,
  endPort: BearerType,
  isSupplierNNAT: boolean
): ConnectorAvailability => {
  const {
    connectorType: { isLCAvailable, isSCAvailable, isRJ45Available },
  } = getDIAOrderOptions(isManagedDIA, endPort, isSupplierNNAT);

  return { showLC: isLCAvailable, showSC: isSCAvailable, showRJ45: isRJ45Available };
};

const supportsRJ45 = (identifierPrefix: string, RJ45Availability: RJ45Availability) =>
  identifierPrefix === 'aEndLocation' ? RJ45Availability.A : RJ45Availability.B;

const supportsSC = (endPort: BearerType) => endPort === BearerType.MEDIUM || isOpticalBearer(endPort);

const supportsLC = (endPort: BearerType, isSupplierNNAT: boolean) => supportsSC(endPort) || isSupplierNNAT;

const getMDIAConnectors = (showRJ45: boolean): ConnectorAvailability => ({
  showLC: false,
  showSC: false,
  showRJ45: showRJ45,
});

interface ConnectorAvailability {
  showLC: boolean;
  showSC: boolean;
  showRJ45: boolean;
}

const getConnectorAvailability = (
  productType: ProductType | null | undefined,
  isManagedDIA: boolean,
  endPort: BearerType,
  isSupplierNNAT: boolean,
  identifierPrefix: string,
  RJ45Availability: RJ45Availability,
  accessType?: string | null
): ConnectorAvailability => {

  const showRJ45 = supportsRJ45(identifierPrefix, RJ45Availability);

  if (accessType === Supplier.CITYFIBRE) {
    return { showLC: false, showSC: false, showRJ45};
  }

  // MDIA 1.2 logic
  if (featureFlag.isEnabled(Feature.newMDIAUI) && productType === ProductType.DIA) {
    return getNewMDIAConnectors(isManagedDIA, endPort, isSupplierNNAT);
  }

  if (isManagedDIA) return getMDIAConnectors(showRJ45);

  return { showLC: supportsLC(endPort, isSupplierNNAT), showSC: supportsSC(endPort), showRJ45 };
};

export default ConnectorTypeOptions;
