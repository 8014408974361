import { ConnectionType } from 'Quotes/types/connectionType';
import { IPriceData, ProductSubType } from 'Quotes/types/store';
import { ProductType } from 'Quotes/types/productTypes';
import { isSupplier, Supplier } from 'Quotes/types/supplier';
import { QuoteStatus } from 'Quotes/types/quoteRecordAttributesBase';
import { useSelector } from 'react-redux';
import { isFTTXQuote, selectQuote } from 'Quotes/selectors';
import { selectHasShadowNNI } from 'Order/OrderBuilder/shared/utils/hasShadowNNI';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';

export const useSecondaryCircuitsSupported = (price: IPriceData) => {
  const quote = useSelector(selectQuote);
  const hasShadowVLAN = useSelector(selectHasShadowNNI);
  const isFttxQuote = useSelector(isFTTXQuote);
  const isManagedDIA = quote.location.aEnd.is_managed_dia;

  if (!shouldRenderForProductType(quote.productType!)) return false;
  if (!shouldRenderForFttxQuote(isFttxQuote)) return false;
  if (!shouldRenderForConnectionType(price.product_sub_type)) return false;
  if (!shouldRenderForShadowVLAN(hasShadowVLAN)) return false;

  if (quote.productType === ProductType.P2NNI) {
    if (!shouldRenderForRawSupplier(price.b_end_access_type)) return false;
  } else if (quote.productType === ProductType.P2P) {
    if (!shouldRenderForRawSupplier(price.a_end_access_type)) return false;
    if (!shouldRenderForRawSupplier(price.b_end_access_type)) return false;
  } else if (quote.productType === ProductType.DIA) {
    if (!shouldRenderForRawSupplier(price.a_end_access_type)) return false;
    if (isManagedDIA) {
      return isMdiaEnabled();
    }
  }

  return true;
};

export function shouldRenderForProductType(productType: ProductType) {
  return featureFlag.getAttribute<boolean>(Feature.SecondaryCircuits, productType);
}

function shouldRenderForRawSupplier(supplier: string | null | undefined) {
  if (!isSupplier(supplier)) return false;
  return shouldRenderForSupplier(supplier);
}

export function shouldRenderForSupplier(supplier: Supplier | undefined) {
  return supplier === Supplier.OPENREACH;
}

export function shouldRenderForShadowVLAN(hasShadowVLAN: boolean) {
  return !hasShadowVLAN;
}

export function shouldRenderForQuoteStatus(quoteStatus: QuoteStatus) {
  return quoteStatus === QuoteStatus.PRICED;
}

export function shouldRenderForConnectionType(connectionType: ConnectionType | ProductSubType | null) {
  return connectionType === 'Ethernet' || connectionType === 'ethernet';
}

export function shouldRenderForFttxQuote(isFttxQuote: boolean) {
  return !isFttxQuote;
}

export function isMdiaEnabled(): boolean {
  return !!featureFlag.getAttribute<boolean>(Feature.SecondaryCircuits, 'isMDIAEnabled');
}
