import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export enum Alerts {
  DANGER = 'danger',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

type Alert = FunctionComponent<
  React.PropsWithChildren<{
    alertType?: Alerts;
    className?: string;
    children: any;
    id?: string;
    'data-testid'?: string;
  }>
>;

const Alert: Alert = (props) => {
  const alertClass = `alert-${props.alertType ?? Alerts.DANGER}`;
  return (
    <div
      role="alert"
      data-testid={props['data-testid']}
      className={cx('alert', alertClass, props.className)}
      id={props.id}
    >
      {props.children}
    </div>
  );
};

export default styled(Alert)`
  &.alert-danger {
    background-color: ${(props) => props.theme.alerts.danger.backgroundColor};
    border-color: ${(props) => props.theme.alerts.danger.borderColor};
    color: ${(props) => props.theme.alerts.danger.color};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${(props) => props.theme.alerts.danger.headingColor};
    }
  }

  &.alert-success {
    background-color: ${(props) => props.theme.alerts.success.backgroundColor};
    border-color: ${(props) => props.theme.alerts.success.borderColor};
    color: ${(props) => props.theme.alerts.success.color};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${(props) => props.theme.alerts.success.headingColor};
    }
  }

  &.alert-warning {
    background-color: ${(props) => props.theme.alerts.warning.backgroundColor};
    border-color: ${(props) => props.theme.alerts.warning.borderColor};
    color: ${(props) => props.theme.alerts.warning.color};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${(props) => props.theme.alerts.warning.headingColor};
    }
  }

  &.alert-info {
    background-color: ${(props) => props.theme.alerts.info.backgroundColor};
    border-color: ${(props) => props.theme.alerts.info.borderColor};
    color: ${(props) => props.theme.alerts.info.color};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${(props) => props.theme.alerts.info.headingColor};
    }
  }
`;
