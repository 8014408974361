import React, { FunctionComponent } from 'react';
import { UploadError } from '../../hooks';

type Props = {
  errors: UploadError[];
};

export const UploadErrors: FunctionComponent<React.PropsWithChildren<Props>> = ({ errors }) => {
  return (
    <ul className="errors">
      {errors.map((err, index) => (
        <li key={index}>
          {`Row ${err.source.row}: ${err.detail}`}
          {err.source.parameter && ` (${err.source.parameter})`}
        </li>
      ))}
    </ul>
  );
};
