import { BillingFrequency } from 'Order/types/orderRecord';
import { PHONE_REGEX } from 'shared/utils/phoneRegex';
import { VLAN_ID_INTERNALLY_ASSIGNED } from 'Order/types/orderRecord';
import { VLAN_REGEX } from './vlanRegex';

export const MAX_LENGTH_LONG_INPUT = 500;

export const isValidTextField = (value: string | undefined | null): boolean =>
  typeof value === 'string' && value.trim().length >= 1;

export const isValidEmail = (value: string | undefined): boolean =>
  typeof value === 'string' && /\S+@\S+\.\S+/.test(value);

export const isValidNoteField = (value: string): boolean => value.length <= MAX_LENGTH_LONG_INPUT;

export const isValidPhoneField = (value: string): boolean => {
  return PHONE_REGEX.test(value);
};

export const isValidCoords = ({
  lat,
  lon,
  easting,
  northing,
}: {
  lat: string;
  lon: string;
  easting: string;
  northing: string;
}): boolean => {
  return (isValidTextField(lat) && isValidTextField(lon)) || (isValidTextField(easting) && isValidTextField(northing));
};

export const isValidVLANIdField = (value: string | null): boolean => {
  if (!value) {
    return false;
  }

  if (value === VLAN_ID_INTERNALLY_ASSIGNED) {
    return true;
  }

  if (!VLAN_REGEX.test(value)) {
    return false;
  }

  const vlanId = parseInt(value, 10);

  return vlanId >= 1 && vlanId <= 4094;
};
isValidVLANIdField.errorMessage = 'VLAN ID should have a value range of 1-4094';
isValidVLANIdField.shadowErrorMessage = `Shadow ${isValidVLANIdField.errorMessage}`;

export const isValidBillingFrequency = (value?: BillingFrequency | null) => {
  return (
    value === BillingFrequency.Monthly ||
    value === BillingFrequency.Quarterly ||
    value === BillingFrequency.QuarterlyArrears
  );
};
