import React, { FunctionComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import Summary from '../../shared/components/Summary';
import { CardState } from 'shared/components/organisms/CardWithInitialText';
import {
  editOrderState,
  setAEndAddressAction,
  setAEndAddressNotListedAction,
  setAEndAddressTypeAction,
  setAEndCompanyNameAction,
  setAEndFullAddressNotListedAction,
  setAEndPAFAddressAction,
  setSiteCoords as setSiteCoordsAction,
  setSiteType as setSiteTypeAction,
  updateAddressFieldLocationA,
  updateOnNetAddress,
} from 'Order/actions';
import { IOpenReachAddress, IOpenReachAddressSite, IPAFAddressSite } from 'shared/types/postcodeResults';
import { IOrderLocation } from 'Order/types/location';
import Edit from '../../shared/components/Edit';
import { IOrder, IOrderMetaForLocation } from 'Order/types/store';
import { AddressType, IPriceData, IQuote } from 'Quotes/types/store';
import { IAppState } from 'reducers';
import NNISummary from '../../shared/components/NNISummary';
import quoteHasShadowNNI from '../../../shared/utils/hasShadowNNI';
import {
  isFTTXQuote as isFTTXQuoteSelector,
  isNewNNI as selectIsNewNNI,
  isNewShadowNNI as selectIsNewShadowNNI,
  selectNNILabel,
  selectSelectedPrice,
  showSiteTypeConfigForPoint as showSiteTypeConfigForPointSelector,
} from 'Quotes/selectors';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { ISetSiteType, IUpdateOnNetAddress, SetSiteCoords } from 'Order/types/actions';
import { isSupplierNNAT } from 'Quotes/utils/isPriceNNAT';

interface IAEndLocationContainerProps {
  order: IOrder;
  quote: IQuote;
  cardState: CardState;
  isFTTXQuote: boolean;
  isNNI: boolean;
  isNewNNI: boolean;
  hasShadowNNI: boolean;
  isNewShadowNNI: boolean;
  postcode: string;
  metaForAEnd: IOrderMetaForLocation;
  nniLabel: string;
  quoteMeta: IMeta;
  selectedPrice: IPriceData;
  showSiteTypeConfigForPoint: boolean;
  updateOrder(order: IOrder): void;
  saveAEndFullAddress(aEndAddress: IOpenReachAddressSite | null): void;
  saveAEndPAFAddress(aEndAddress: IPAFAddressSite | null): void;
  setAEndPAFAddressNotListed(notListed: boolean): void;
  setAEndFullAddressNotListed(notListed: boolean): void;
  saveOnNetAddressSelection(attributes: IUpdateOnNetAddress['payload']['attributes']): void;
  fieldOnChange(fieldName: keyof IOpenReachAddress, fieldValue: string): void;
  setAEndCompanyName(endCompanyName: string): void;
  setAEndAddressType(aEndAddressType: AddressType): void;
  setSiteType(siteType: ISetSiteType['payload']['siteType']): void;
  setSiteCoords(field: SetSiteCoords['payload']['field'], value: SetSiteCoords['payload']['value']): void;
}

export const AEndLocationContainer: FunctionComponent<React.PropsWithChildren<IAEndLocationContainerProps>> = ({
  quote,
  order,
  order: {
    locationA,
    locationA: { locationData },
  },
  cardState,
  fieldOnChange,
  isFTTXQuote,
  isNNI,
  isNewNNI,
  hasShadowNNI,
  isNewShadowNNI,
  nniLabel,
  postcode,
  quoteMeta,
  updateOrder,
  saveOnNetAddressSelection,
  saveAEndFullAddress,
  saveAEndPAFAddress,
  setAEndPAFAddressNotListed,
  setAEndFullAddressNotListed,
  metaForAEnd,
  setAEndCompanyName,
  setAEndAddressType,
  setSiteType,
  selectedPrice,
  showSiteTypeConfigForPoint,
  setSiteCoords,
}) => {
  const identifierPrefix = 'aEndLocation';

  if (cardState === CardState.Edit || cardState === CardState.Initial) {
    return (
      <Edit
        quote={quote}
        endPort={quote.aEndPort!}
        isFTTXQuote={isFTTXQuote}
        isSupplierNNAT={isSupplierNNAT(selectedPrice.a_end_access_type)}
        isNNI={isNNI}
        isNewNNI={isNewNNI}
        isNewShadowNNI={isNewShadowNNI}
        hasShadowNNI={hasShadowNNI}
        location={locationA}
        nniLabel={nniLabel}
        addressType={locationData.addressType}
        identifierPrefix={identifierPrefix}
        fieldOnChange={fieldOnChange}
        saveOnNetAddressSelection={saveOnNetAddressSelection}
        postcode={postcode}
        saveFullAddress={saveAEndFullAddress}
        setPAFAddressNotListed={setAEndPAFAddressNotListed}
        setFullAddressNotListed={setAEndFullAddressNotListed}
        orderMeta={metaForAEnd}
        quoteMeta={quoteMeta}
        savePAFAddress={saveAEndPAFAddress}
        setEndCompanyName={setAEndCompanyName}
        setSiteType={setSiteType}
        updateLocation={(updated: IOrderLocation) => updateOrder({ ...order, locationA: updated })}
        setAddressType={setAEndAddressType}
        selectedPrice={selectedPrice}
        showSiteTypeConfigForPoint={showSiteTypeConfigForPoint}
        setSiteCoords={setSiteCoords}
      />
    );
  }

  if (isNNI) {
    return (
      <NNISummary
        vlanPopId={selectedPrice.a_end_p_o_p_id}
        shadowVlanPopId={quote.location.aEnd.nni.shadowVLAN.selectedDataCentreId}
        location={locationA}
        customerName={quote.customerName}
      />
    );
  }

  return (
    <Summary
      identifierPrefix={identifierPrefix}
      isFTTXQuote={isFTTXQuote}
      isNNAT={isSupplierNNAT(selectedPrice.a_end_access_type)}
      productType={quote.productType!}
      location={locationA}
      onatAddress={quote.location.aEnd.onatAddress}
      orderMeta={metaForAEnd}
      showSiteTypeConfigForPoint={showSiteTypeConfigForPoint}
      selectedPrice={selectedPrice}
      quote={quote}
    />
  );
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  updateOrder: (order: IOrder) => dispatch(editOrderState(order)),
  fieldOnChange: (fieldName: keyof IOpenReachAddress, fieldValue: string) =>
    dispatch(updateAddressFieldLocationA(fieldName, fieldValue)),
  saveOnNetAddressSelection: (attributes: IUpdateOnNetAddress['payload']['attributes']) =>
    dispatch(updateOnNetAddress('A', attributes)),
  saveAEndFullAddress: (aEndAddress: IOpenReachAddressSite | null) => dispatch(setAEndAddressAction(aEndAddress)),
  setAEndPAFAddressNotListed: (notListed: boolean) => dispatch(setAEndAddressNotListedAction(notListed)),
  setAEndFullAddressNotListed: (notListed: boolean) => dispatch(setAEndFullAddressNotListedAction(notListed)),
  saveAEndPAFAddress: (aEndAddress: IPAFAddressSite | null) => dispatch(setAEndPAFAddressAction(aEndAddress)),
  setAEndCompanyName: (endCompanyName: string) => dispatch(setAEndCompanyNameAction(endCompanyName)),
  setAEndAddressType: (aEndAddressType: AddressType) => dispatch(setAEndAddressTypeAction(aEndAddressType)),
  setSiteType: (siteType: ISetSiteType['payload']['siteType']) => dispatch(setSiteTypeAction('A', siteType)),
  setSiteCoords: (field: SetSiteCoords['payload']['field'], value: SetSiteCoords['payload']['value']) =>
    dispatch(setSiteCoordsAction('A', field, value)),
});

const mapStateToProps = (state: IAppState) => ({
  metaForAEnd: state.orderBuilder.order.orderMeta.aEnd,
  quoteMeta: state.quoteBuilder.quoteEndpointMeta,
  isFTTXQuote: isFTTXQuoteSelector(state),
  isNewNNI: selectIsNewNNI(state),
  isNewShadowNNI: selectIsNewShadowNNI(state),
  hasShadowNNI: quoteHasShadowNNI(state.quoteBuilder.quoteEndpointMeta, state.quoteBuilder.quote.location.aEnd.nni),
  nniLabel: selectNNILabel(state),
  showSiteTypeConfigForPoint: showSiteTypeConfigForPointSelector('aEndLocation')(state),
  selectedPrice: selectSelectedPrice(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AEndLocationContainer);
