import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import Logo from 'shared/components/atoms/Logo';
import UserDropdown from '../UserDropdown';
import PresentForRoles from 'Auth/PresentForRoles';
import { base as adminBase, costs, preferences, pricing, userList } from 'Admin/routes';
import { base as reportsBase, reports } from 'Reports/routes';
import { base as quotesBase, quoteList } from 'Quotes/Routes';
import { base as ordersBase, ordersList } from 'Order/routes';
import { base as faqBase } from 'FAQ/routes';
import { base as messagesBase } from 'Messages/routes';
import CompanyDropdown from '../CompanyDropdown';
import { Company } from 'User/types/user';
import { userPermissions } from 'shared/utils/permissions';
import AlertsNotification from 'Alerts/AlertsNotification';
import LinkDropdown from '../LinkDropdown';
import Column from 'shared/components/atoms/Column';
import { base as promoOfferBase } from 'PromoOffer/routes';
import hasFeatureEnabled from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import MessagesNotification from 'Messages/MessagesNotification';
import { NavLinkWithActive } from 'shared/RouterComponents';

interface ITopNavigation {
  className?: string;
  userName: string;
  userImage?: string;
  companies: Company[];
  selectedCompanyId: string | null;
  setSelectedCompany(id: string): void;
}

export enum RouteTitle {
  'QUOTES' = 'Quotes',
  'ORDERS' = 'Orders',
  'USER_MANAGEMENT' = 'User management',
  'REPORTS' = 'Reporting',
  'ALERTS' = 'Alerts',
  'PRICING' = 'Pricing',
  'HOME' = 'Home',
  'PROMO_OFFER' = 'Content settings',
  'PREFERENCES' = 'Preferences',
  'FAQ' = 'Help & FAQs',
}

export function getMatchingTitle(path?: string): RouteTitle | null {
  if (!path) {
    return null;
  }

  if (path === '/welcome') {
    return RouteTitle.HOME;
  }

  if (path.startsWith(quotesBase)) {
    return RouteTitle.QUOTES;
  }
  if (path.startsWith(ordersBase)) {
    return RouteTitle.ORDERS;
  }
  if (path.startsWith(adminBase)) {
    if (path.endsWith(userList)) {
      return RouteTitle.USER_MANAGEMENT;
    }

    if (path.endsWith(pricing) || path.endsWith(costs)) {
      return RouteTitle.PRICING;
    }
  }
  if (path.startsWith(reportsBase)) {
    return RouteTitle.REPORTS;
  }
  if (path.startsWith(promoOfferBase)) {
    return RouteTitle.PROMO_OFFER;
  }
  if (path.startsWith(preferences)) {
    return RouteTitle.PREFERENCES;
  }
  if (path.startsWith(faqBase)) {
    return RouteTitle.FAQ;
  }
  return null;
}

const TopNavigation: FunctionComponent<React.PropsWithChildren<ITopNavigation>> = ({
  className,
  userImage,
  userName,
  companies,
  selectedCompanyId,
  setSelectedCompany,
}) => {
  const location = useLocation();
  const [navTitle, setNavTitle] = useState<string>(getMatchingTitle(location.pathname) || RouteTitle.QUOTES);
  const [navActive, setNavActive] = useState<boolean>(false);

  useEffect(() => {
    const matchingTitle = getMatchingTitle(location.pathname);
    if (matchingTitle) {
      setNavTitle(matchingTitle);
      setNavActive(true);
    } else {
      setNavActive(false);
    }
  }, [location]);

  const messagingEnabled = hasFeatureEnabled(Feature.messaging);

  return (
    <header className={className}>
      <div className="container">
        <div className="row">
          <Column defaultWidth={2}>
            <Link to="/welcome" className="logo-link">
              <Logo />
            </Link>
          </Column>
          <Column defaultWidth={3} xlWidth={messagingEnabled ? 3 : 4}>
            <CompanyDropdown
              className="company-selection"
              companies={companies}
              selectedCompanyId={selectedCompanyId}
              onChange={setSelectedCompany}
            />
          </Column>
          <Column defaultWidth={2} xlWidth={messagingEnabled ? 2 : 3}>
            <LinkDropdown classNames={['page-navigation ml-2']} isActive={navActive} title={navTitle}>
              <NavLinkWithActive to="/welcome">{RouteTitle.HOME}</NavLinkWithActive>
              <PresentForRoles roles={userPermissions.viewQuote}>
                <NavLinkWithActive to={quoteList}>{RouteTitle.QUOTES}</NavLinkWithActive>
              </PresentForRoles>
              <PresentForRoles roles={userPermissions.viewOrder}>
                <NavLinkWithActive to={ordersList}>{RouteTitle.ORDERS}</NavLinkWithActive>
              </PresentForRoles>
              <PresentForRoles roles={userPermissions.reports}>
                <NavLinkWithActive to={reports}>{RouteTitle.REPORTS}</NavLinkWithActive>
              </PresentForRoles>
              <PresentForRoles roles={userPermissions.manageUser}>
                <NavLinkWithActive to={userList}>{RouteTitle.USER_MANAGEMENT}</NavLinkWithActive>
              </PresentForRoles>
              <PresentForRoles roles={[...userPermissions.exportPricing, ...userPermissions.updatePricing]}>
                <NavLinkWithActive to={pricing}>{RouteTitle.PRICING}</NavLinkWithActive>
              </PresentForRoles>

              <PresentForRoles roles={userPermissions.viewEditPromoOffers}>
                <NavLinkWithActive to={promoOfferBase}>{RouteTitle.PROMO_OFFER}</NavLinkWithActive>
              </PresentForRoles>
              <PresentForRoles roles={userPermissions.customerPreferences}>
                <NavLinkWithActive to={preferences}>{RouteTitle.PREFERENCES}</NavLinkWithActive>
              </PresentForRoles>
              <NavLinkWithActive to={faqBase}>{RouteTitle.FAQ}</NavLinkWithActive>
            </LinkDropdown>
          </Column>
          <Column
            defaultWidth={messagingEnabled ? 1 : 2}
            offsetDefaultWidth={messagingEnabled ? 0 : 1}
            offsetXlWidth={0}
            xlWidth={2}
          >
            <NavLinkWithActive to="/alerts" activeClassName="active alerts-link" className="alerts-link" title="Alerts">
              <AlertsNotification />
            </NavLinkWithActive>
          </Column>
          {messagingEnabled && (
            <Column defaultWidth={2}>
              <NavLinkWithActive
                to={messagesBase}
                activeClassName="active messages-link"
                className="messages-link"
                title="Messages"
              >
                <MessagesNotification />
              </NavLinkWithActive>
            </Column>
          )}
          <Column defaultWidth={2} xlWidth={1} classNames={['pl-2']}>
            <UserDropdown name={userName} image={userImage} />
          </Column>
        </div>
      </div>
    </header>
  );
};

const StyledTopNavigation = styled(TopNavigation)`
  background-color: white;

  > div {
    height: ${(props) => props.theme.navbarHeight};
    position: relative;
    z-index: 100;
  }

  .logo-link {
    line-height: ${(props) => props.theme.navbarHeight};
  }

  .company-selection,
  .page-navigation {
    .dropdown__dropdown {
      height: 70px;
      font-family: ${(props) => props.theme.typography.fontFamilyBold};
    }

    a {
      text-decoration: none;
    }
  }

  .user-dropdown {
    max-width: 100px;
    margin-left: auto;

    .dropdown__dropdown {
      height: ${(props) => props.theme.navbarHeight};
      padding: 0;
      justify-content: flex-end;
    }

    .dropdown__options {
      right: 0;
      left: inherit;
    }

    a {
      text-decoration: none;
    }
  }

  .alerts-link,
  .messages-link {
    text-decoration: none;
  }

  .alerts-link.active {
    .alerts-notification {
      background-color: ${(props) => props.theme.colours.primaryB1};
      color: white;

      .alert-icon {
        color: white;
      }
    }
  }

  .messages-link.active {
    .messages-notification {
      background-color: ${(props) => props.theme.colours.primaryB1};
      color: white;

      .messages-icon {
        color: white;
      }
    }
  }
`;

export default StyledTopNavigation;
