import MultiSelectTable, { RowCell } from 'shared/components/organisms/MultiSelectTable/index';
import React, { FC, ReactNode } from 'react';
import { IFilterComponent } from 'shared/components/organisms/MultiSelectTable/filters';
import TableNames from 'Tables/types/tableNames';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { toRelativeString } from 'utils/dateUtil';

export interface WrapperProps<T> {
  tableName: TableNames;
  headers: Header<T>[];
  records: T[];
  onRowClick?: (record: T) => void;
}

export interface PagedProps<T> extends WrapperProps<T> {
  paging: IServerSidePaging;
}

export enum ColumnTypes {
  Custom,
  Text,
  Date,
}

export interface Header<T> {
  title: string;
  filter?: FC<React.PropsWithChildren<IFilterComponent>>;
  field: (record: T) => any;
  columnConfig: ColumnConfig;
  width?: number;
  sortable?: boolean;
}

type ColumnConfig = CustomType | BuiltInType;

export interface CustomType {
  type: ColumnTypes.Custom;
  customRenderer: (cell: RowCell) => ReactNode;
}

export interface BuiltInType {
  type: ColumnTypes.Text | ColumnTypes.Date;
  customRenderer?: never;
}

export function toColumnInfo(config: ColumnConfig) {
  const columnConfigMapping: { [key in ColumnTypes]: any } = {
    [ColumnTypes.Custom]: { customRenderer: config.customRenderer },
    [ColumnTypes.Text]: {},
    [ColumnTypes.Date]: {
      style: { overflow: 'visible' },
      customRenderer: (cell: RowCell) => {
        const date = cell.value;
        return date ? <span>{toRelativeString(date)}</span> : null;
      },
    },
  };
  return columnConfigMapping[config.type];
}

export function toFilterConfig(filter: React.FC<React.PropsWithChildren<IFilterComponent>> | undefined) {
  return filter ? { Filter: filter } : { disableFilters: true };
}

export function toHeaderInfo(title: string) {
  return title;
}

export function toHeader<T>(header: Header<T>): any {
  return {
    Header: toHeaderInfo(header.title),
    ...toFilterConfig(header.filter),
    accessor: header.field,
    ...toColumnInfo(header.columnConfig),
    width: header.width,
    disableSortBy: !header.sortable || false,
  };
}

export function getRowClickHandler<T>(onRowClick: ((record: T) => void) | undefined, toRecord: (cell: RowCell) => T) {
  return onRowClick ? (cell: RowCell) => onRowClick?.(toRecord(cell)) : undefined;
}

export function Table<T>(props: WrapperProps<T>) {
  const toRecord = (cell: RowCell): T => (cell.row.original as unknown) as T;
  const rowClickHandler = getRowClickHandler(props.onRowClick, toRecord);

  return (
    <MultiSelectTable
      storeTableName={TableNames.messages}
      headers={props.headers.map(toHeader)}
      rows={props.records}
      rowClickHandler={rowClickHandler}
    />
  );
}

export function PagedTable<T>(props: PagedProps<T>) {
  const toRecord = (cell: RowCell): T => (cell.row.original as unknown) as T;
  const rowClickHandler = getRowClickHandler(props.onRowClick, toRecord);

  return (
    <MultiSelectTable
      storeTableName={TableNames.messages}
      headers={props.headers.map(toHeader)}
      rows={props.records}
      rowClickHandler={rowClickHandler}
      paging={props.paging}
    />
  );
}
