import { doRequest } from 'Request';
import { IPAFAddress } from 'shared/types/postcodeResults';

const BUILDING_NAME_MAX_CHARS = 36;

const buildPathParameters = (pafAddress: IPAFAddress): string => {
  const pathParameter = '';
  const parametersPairs = [];

  if (pafAddress.thoroughfare !== '' && pafAddress.thoroughfare !== undefined) {
    parametersPairs.push(`street=${encodeURIComponent(pafAddress.thoroughfare)}`);
  }
  if (pafAddress.building_number !== '' && pafAddress.building_number !== undefined) {
    parametersPairs.push(`building_number=${encodeURIComponent(pafAddress.building_number)}`);
  }
  if (pafAddress.building_name !== '' && pafAddress.building_name !== undefined) {
    let buildingName = pafAddress.building_name;

    const characterLimitExceededBy = Math.min(
      0,
      BUILDING_NAME_MAX_CHARS - encodeURIComponent(pafAddress.building_name).length
    );

    if (characterLimitExceededBy < 0) {
      buildingName = buildingName.slice(0, characterLimitExceededBy);
    }

    parametersPairs.push(`building_name=${encodeURIComponent(buildingName)}`);
  }

  const encodedPathParameter = encodeURIComponent(pathParameter);
  if (parametersPairs.length > 0) {
    const joinedParameters = parametersPairs.join('&');
    return encodedPathParameter + '?' + joinedParameters;
  }
  return encodedPathParameter;
};

const getOpenReachAddressesForPostcode = (postcode: string, pafAddress: IPAFAddress) => {
  return doRequest({
    path: `/postcodes/${postcode}/addresses/${buildPathParameters(pafAddress)}`,
  });
};

export default getOpenReachAddressesForPostcode;