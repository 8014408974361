import { APIQuoteListItem } from '../../../types/apiQuote';
import {
  DefaultColumnFilter,
  SelectColumnFilter,
  SelectSupplierColumnFilter,
} from '../../../../shared/components/organisms/MultiSelectTable/filters';
import {
  formatProductType,
  formatAccessProviderForProductType,
  formatQuoteLocation,
} from '../../../utils/quoteTableHelpers';

const headers = [
  {
    Filter: DefaultColumnFilter,
    Header: 'Quote ID',
    accessor: 'id',
    id: 'id',
    width: 80,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Filter: DefaultColumnFilter,
    Header: 'Company',
    accessor: 'company_name',
    id: 'company_name',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Filter: SelectColumnFilter,
    Header: 'Product',
    accessor: (row: APIQuoteListItem) => formatProductType(row.product_type),
    filter: 'equalsOrEmptyFilter',
    id: 'product_type',
    width: 80,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Filter: DefaultColumnFilter,
    Header: 'Location',
    accessor: formatQuoteLocation,
    id: 'location',
    minWidth: 300,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Filter: SelectSupplierColumnFilter,
    Header: 'Access Provider',
    accessor: (row: APIQuoteListItem) => {
      return formatAccessProviderForProductType(
        row.product_type,
        row.start_offnet_type,
        row.end_offnet_type,
        row.cloud_connect_provider_name
      );
    },
    id: 'access_provider',
    disableSortBy: true,
    disableFilters: true,
    width: 120,
  },
  {
    Filter: DefaultColumnFilter,
    Header: 'Completed',
    accessor: 'date_completed',
    id: 'created_at',
    disableSortBy: true,
    disableFilters: true,
    width: 125,
  },
  {
    Filter: DefaultColumnFilter,
    Header: 'Price',
    accessor: 'annual_price',
    id: 'annual_price',
    width: 80,
    disableSortBy: true,
    disableFilters: true,
  },
];

export default headers;
