import { doRequest, Methods } from 'Request';

const deleteQuote = (quoteId: string) => {
  return doRequest({
    method: Methods.POST,
    path: `/quotes/${quoteId}/archive`,
  });
};

export default deleteQuote;
