import { doRequest, Methods } from 'Request';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import { ButtonMainStyle } from 'shared/components/atoms/Button';
import Icon from 'shared/components/atoms/Icon';
import { ButtonWithTimeout } from 'shared/components/molecules/ButtonWithTimer/ButtonWithTimeout';
import styled from 'styled-components';

interface INotifyCustomerButtonProps {
  orderId: string;
  className?: string;
}

const UnstyledNotifyCustomerButton: FC<React.PropsWithChildren<INotifyCustomerButtonProps>> = ({
  className,
  orderId,
}: INotifyCustomerButtonProps) => {
  const [notificationStatus, setNotificationStatus] = useState<'success' | 'error' | undefined>();

  const notifyCustomer = async () => {
    await doRequest({
      method: Methods.POST,
      path: `/orders/${orderId}/send-reminder`,
      onError: () => {
        setNotificationStatus('error');
      },
    });

    setNotificationStatus('success');
  };

  const renderMessage = (countdown: number) => {
    if (notificationStatus === 'success') return <NotifyCustomerSuccessMessage countdown={countdown} />;

    if (notificationStatus === 'error') return <NotifyCustomerErrorMessage countdown={countdown} />;
  };

  return (
    <ButtonWithTimeout
      timeout={dayjs.duration({ seconds: 60 })}
      onClick={notifyCustomer}
      renderWhileCounting={renderMessage}
      className={className}
      mainStyle={ButtonMainStyle.LinkSecondary}
    >
      Notify customer
    </ButtonWithTimeout>
  );
};

export const NotifyCustomerButton = styled(UnstyledNotifyCustomerButton)`
  &.linkSecondary {
    text-decoration: none;
    font-size: 1.2rem;
    padding: 0;

    &:hover:not(:disabled) {
      text-decoration: underline;
    }
  }
`;

const NotifyCustomerSuccessMessage = ({ countdown }: { countdown: number }) => (
  <Message className="success">
    <Icon name="check" />
    Customer notified, wait {countdown}s to re-notify.
  </Message>
);

const NotifyCustomerErrorMessage = ({ countdown }: { countdown: number }) => (
  <Message className="error">
    <Icon name="error" />
    Something went wrong, please retry in {countdown}s.
  </Message>
);

const Message = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.2em;
  font-size: 1rem;

  &.success {
    color: ${(props) => props.theme.colours.primaryB1};
  }

  &.error {
    color: ${(props) => props.theme.colours.secondaryA1};
  }
`;
