import React, { FC, useState, useEffect, ChangeEvent, FormEvent } from 'react';
import styled from 'styled-components';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import Alert from 'shared/components/atoms/Alert';
import { IOpenReachAddressSite } from 'shared/types/postcodeResults';
import { ISetOpenreachAddress } from 'Quotes/types/actions';
import { concatenateOpenReachAddress, isFTTXCompatibleOpenreachLocation } from 'shared/utils/addresses/helperFunctions';
import { IncompatibleFTTXLocation } from 'Location/IncompatibleFTTXLocation';
import { CompatibleFTTXLocation } from 'Location/CompatibleFTTXLocation';
import Column from 'shared/components/atoms/Column';
import { getOpenReachAddressForALK } from 'shared/utils/addresses/getOpenReachAddressForALK';

interface Props {
  className?: string;
  selectedOpenreachAddress: IOpenReachAddressSite | null;
  addressRetrieval(retrieve: boolean, onNetAndPaf: boolean): void;
  onALKAddressSubmit(address: ISetOpenreachAddress['payload']['address'] | null): void;
  onPostcodeSubmit(postcode: string): void;
  onDraftPostcodeSubmit(postcode: string): void;
}

const LocationByALK: FC<React.PropsWithChildren<Props>> = ({
  className,
  selectedOpenreachAddress,
  addressRetrieval,
  onALKAddressSubmit,
  onPostcodeSubmit,
  onDraftPostcodeSubmit,
}) => {
  const [alk, setALK] = useState(selectedOpenreachAddress?.attributes?.alk || '');
  const [isLoading, setLoading] = useState(false);
  const [lookupError, setError] = useState('');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    setError('');
    setALK(newValue);

    if (selectedOpenreachAddress && newValue !== alk) {
      onDraftPostcodeSubmit('');
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (alk.length === 0 || isLoading) {
      return;
    }

    setError('');
    setLoading(true);

    getOpenReachAddressForALK(alk)
      .then((result) => {
        onDraftPostcodeSubmit(result.data.attributes.postcode!);
        onPostcodeSubmit(result.data.attributes.postcode!);
        onALKAddressSubmit(result.data);
      })
      .catch((error) => {
        if (Array.isArray(error?.response?.data?.errors) && typeof error.response.data.errors[0]?.title === 'string') {
          setError(error.response.data.errors[0].title);
        } else {
          setError(error?.message || 'Unknown error occurred');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      addressRetrieval(false, false);
    };
  }, [addressRetrieval]);

  useEffect(() => {
    if (isLoading) {
      addressRetrieval(true, false);
    } else {
      addressRetrieval(false, false);
    }
  }, [addressRetrieval, isLoading]);

  return (
    <div className={`${className} pb-2`}>
      <div className="row alk-form-container">
        <Column defaultWidth={3}>
          <span className="alk-title d-inline-block pt-3">Enter ALK:</span>
        </Column>
        <Column defaultWidth={9}>
          <form onSubmit={onSubmit}>
            <input onChange={onChange} type="text" value={alk} disabled={isLoading} />
            <Button
              type="submit"
              disabled={isLoading}
              loading={isLoading}
              className="find-btn"
              mainStyle={ButtonMainStyle.SecondaryRectangular}
            >
              Find
            </Button>
          </form>
        </Column>
      </div>

      {lookupError && (
        <Alert>
          <p>
            Error during ALK lookup: <br />
            <strong>
              <em>{lookupError}</em>
            </strong>
          </p>
          <p className="mb-0">Please try again later. If the problem persists, please contact your Account Manager.</p>
        </Alert>
      )}

      {isFTTXCompatibleOpenreachLocation(selectedOpenreachAddress) && (
        <div className="openreach-readonly-wrapper">
          <CompatibleFTTXLocation
            selectedOpenreachAddress={selectedOpenreachAddress}
            address={concatenateOpenReachAddress(selectedOpenreachAddress.attributes)}
          />
        </div>
      )}

      {selectedOpenreachAddress && !isFTTXCompatibleOpenreachLocation(selectedOpenreachAddress) && (
        <div className="mt-2">
          <IncompatibleFTTXLocation />
        </div>
      )}
    </div>
  );
};

const StyledLocationByALK = styled(LocationByALK)`
  .alk-title {
    font-size: 0.95rem;
    color: ${(props) => props.theme.colours.grey60};
  }

  .alk-form-container input {
    margin-right: 0.8em;
    max-width: 175px;
    text-transform: uppercase;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    color: ${(props) => props.theme.colours.grey90};
    padding-left: 1em;
    height: 2.5em;
  }

  .find-btn {
    margin: 0.5em 0;
    max-width: 10em;
    height: 2.5em;
    width: 115px;
    margin-top: 0.25em;
  }

  .openreach-readonly-wrapper {
    background: ${(props) => props.theme.colours.mainBackgroundColor};
    padding: 0.75em 0.8em 0.1em 0.8em;
  }
`;

export { StyledLocationByALK as LocationByALK };
