import { doRequest, Methods } from '../../../Request';
import { IUpdateUserPayload } from '../../types/actions';
import { IUserRoleForCreation } from '../../../User/types/role';
import { translateRolesSelectionForUserCreation } from '../../utils/companyRolesRows';

interface IUpdateUserRequestBody {
  data: {
    attributes: {
      email: string;
      job_title: string | null;
      first_name: string;
      last_name: string;
      mobile_number: string;
      landline_number: string | null;
      roles: IUserRoleForCreation[];
    };
    type: 'user';
  };
}

const updateUser = ({
  companiesRolesRows,
  userData: { contactEmail, jobTitle, firstName, lastName, mobilePhone, landlinePhone, id },
}: IUpdateUserPayload) => {
  const body: IUpdateUserRequestBody = {
    data: {
      attributes: {
        email: contactEmail,
        job_title: jobTitle,
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobilePhone,
        landline_number: landlinePhone,
        roles: translateRolesSelectionForUserCreation(companiesRolesRows),
      },
      type: 'user',
    },
  };

  return doRequest({
    body,
    method: Methods.PATCH,
    path: `/users/${id}`,
  });
};

export default updateUser;
