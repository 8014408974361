import React from 'react';
import { AlertWithAction } from 'shared/components/molecules/SecondaryCircuits/AlertWithAction';

const CHECK_NOW_INFO_TEXT = `RO2 Diversity may be available for this circuit, click 'Check now' to check availability and pricing.`;

export const CheckNowInfoPanel = ({ isReadOnly = false, onClick }: { isReadOnly?: boolean; onClick?: () => void }) => (
  <AlertWithAction
    labelText={CHECK_NOW_INFO_TEXT}
    buttonText="Check Now"
    id="check-now"
    onClick={onClick}
    isReadOnly={isReadOnly}
  />
);
