import React, { FunctionComponent } from 'react';
import styled, { useTheme } from 'styled-components';
import Footer from './navigation/Footer';

type SplashLayout = FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
  }>
>;

const SplashLayout: SplashLayout = ({ children, className }) => {
  const theme = useTheme();

  return (
    <div className={className}>
      <div>
        <div className="container unauth-wrapper">
          <img src={theme.logos.splash} alt={`${theme.content.companyName} logo`} title={theme.content.companyName} />
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

const StyledSplashLayout = styled(SplashLayout)`
  height: 100%;
  background-color: ${(props) => props.theme.colours.secondaryE1};

  > div {
    background-image: url(${(props) => props.theme.backgroundImages.splashBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: -200px;

    @media (min-width: ${(props) => props.theme.breakpoints.XXL}) {
      background-position-x: 0;
    }

    .unauth-wrapper {
      min-height: 900px;
      position: relative;

      > img {
        position: absolute;
        top: 48px;
        left: 48px;
        max-width: 390px;
      }
    }

    button[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .text-muted {
    color: ${(props) => props.theme.colours.grey70} !important;
  }

  a {
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }
`;

export { StyledSplashLayout as SplashLayout };
