import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import RadioButton from 'shared/components/molecules/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { setAmortisationAction } from 'Quotes/actions';
import { selectPriceAmortised } from 'Quotes/QuoteBuilder/components/Price/selectors';
import { useDefaultValueWhenDisabled } from 'shared/hooks/useDefaultValueWhenDisabled';

interface IAmortisation {
  className?: string;
  readonly: boolean;
  hasShadowVLAN: boolean;
  hidden: boolean;
}

interface IConnectedAmortisation {
  amortised: boolean;
  setAmortisation(amortised: boolean): void;
}

export const Amortisation: FC<IAmortisation & IConnectedAmortisation> = ({
  className,
  amortised,
  readonly,
  hasShadowVLAN,
  setAmortisation,
  hidden,
}) => {
  if (hidden) return null;
  return (
    <div className={`${className} pb-4`}>
      <RadioButton
        inline
        className="price_summary__radiogroup"
        disabled={readonly}
        description="Pay no up-front charge"
        id="amortise-spread-costs"
        key="amortise-spread-costs"
        onClick={() => setAmortisation(true)}
        status={amortised ? 'selected' : 'notSelected'}
        tooltipText={hasShadowVLAN ? 'Excludes Shadow VLAN charge' : undefined}
      />

      <RadioButton
        inline
        className="price_summary__radiogroup"
        disabled={readonly}
        description="Pay up-front charge"
        id="amortise-up-front"
        key="amortise-up-front"
        onClick={() => setAmortisation(false)}
        status={amortised ? 'notSelected' : 'selected'}
      />
    </div>
  );
};

const StyledAmortisation = styled(Amortisation)`
  .price_summary__radiogroup {
    width: 100%;
    height: 53px;
    margin-bottom: 1em;
  }
`;

const ConnectedAmortisation = (props: IAmortisation) => {
  const dispatch = useDispatch();
  const setAmortisation = useCallback((amortised: boolean) => dispatch(setAmortisationAction(amortised)), [dispatch]);
  const amortised = useSelector(selectPriceAmortised) ?? true;

  const amortisationDisabled = !props.hidden;
  useDefaultValueWhenDisabled(amortisationDisabled, false, amortised, setAmortisation);

  return <StyledAmortisation {...props} amortised={amortised} setAmortisation={setAmortisation} />;
};

export default ConnectedAmortisation;
