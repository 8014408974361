import React, { FunctionComponent, useState } from 'react';
import { connect } from 'react-redux';

import { IAppState } from '../../../reducers';
import { Company } from '../../../User/types/user';
import getOrdersReport from '../../crud/getOrdersReports';
import Alert from '../../../shared/components/atoms/Alert';
import SpinnerWithText from '../../../shared/components/molecules/SpinnerWithText';
import StyledIcon from '../../../shared/components/atoms/Icon';
import buildFileNameWithDate from '../../../shared/utils/buildFileNameWithDate';
import CustomersFilter from '../filters/CustomersFilter';
import ProductsFilter from '../filters/ProductsFilter';
import DateRangeFilter from '../filters/DateRangeFilter';
import { formatDatePickerDate } from '../../../shared/utils/dateHelper';
import OrderStatusFilter from '../filters/OrderStatusFilter';
import Button, { ButtonMainStyle } from '../../../shared/components/atoms/Button';
import styled from 'styled-components';
import { saveAs } from 'file-saver';

import { SortDirection, useTablePaging } from '../../../shared/hooks/useTablePaging';
import { transformOrderDataToListItems } from '../../../shared/utils/transformOrderDataToListItems';
import { orderFilterTransform } from '../../../shared/utils/shared-quote-order-table-headers';
import OrdersReportTable from './components/ReportOrdersList/OrdersReportTable';

interface IOrdersReport {
  className?: string;
  userCompanies: Company[];
}

export const OrdersReport: FunctionComponent<React.PropsWithChildren<IOrdersReport>> = ({
  className,
  userCompanies,
}) => {
  const paging = useTablePaging({
    baseUrl: `/orders/all`,
    dataTransform: transformOrderDataToListItems,
    filterTransform: orderFilterTransform,
    defaultSort: {
      key: 'updated_at',
      direction: SortDirection.DESCENDING,
    },
    extraParams: {
      include: 'quotes',
    },
  });
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [productType, setProductType] = useState<string | null | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [orderStatus, setOrderStatus] = useState<string | null>(null);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [successfullDownload, setSuccessfullDownload] = useState<boolean>(false);

  const exportCSV = async () => {
    setIsDownloading(true);
    setSuccessfullDownload(false);
    setHasErrored(false);
    try {
      const result = await getOrdersReport({
        customer_id: customerId,
        product_type: productType,
        start_date: startDate ? formatDatePickerDate(startDate) : null,
        end_date: endDate ? formatDatePickerDate(endDate) : null,
        state: orderStatus,
      });
      const blob = new Blob([result], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(blob, buildFileNameWithDate('Digital Portal Orders', 'csv'));
      setSuccessfullDownload(true);
    } catch (error) {
      setHasErrored(true);
      setSuccessfullDownload(false);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className={className}>
      <div className="reports-filters__wrapper row">
        <CustomersFilter userCompanies={userCompanies} setCustomerId={setCustomerId} />
        <ProductsFilter setProductType={setProductType} />
        <DateRangeFilter startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        <OrderStatusFilter setOrderStatus={setOrderStatus} />
        <div className="downloading">
          {successfullDownload && <StyledIcon name="check" className="success-icon" />}
          <Button onClick={exportCSV} mainStyle={ButtonMainStyle.PrimaryRectangular} className="download-btn">
            Download report to CSV
          </Button>
        </div>
      </div>
      {isDownloading && <SpinnerWithText text="Downloading" />}
      {hasErrored && (
        <Alert>
          Error whilst downloading your report. Your changes may not have been saved. Please try again later. If the
          problem persists, please contact your Account Manager.
        </Alert>
      )}
      <h3 className="my-4">Orders (all customers I have access to)</h3>
      <OrdersReportTable paging={paging} />
    </div>
  );
};

const mapStateToProps = ({ user: { companyData } }: IAppState) => ({
  userCompanies: companyData.companies,
});

const styledOrdersReport = styled(OrdersReport)`
  .downloading {
    display: block;
    text-align: right;
    flex: 1;
  }

  .download-btn {
    margin-top: 1.2em;
    width: fit-content;
  }

  .success-icon {
    color: ${(props) => props.theme.colours.primaryB1};
    vertical-align: bottom;
    margin-bottom: 1em;
  }
`;

export default connect(mapStateToProps, null)(styledOrdersReport);
