/** TODO: reuse this interface across whole repo. */
export interface JSONAPIResponse<D, I = any, M = any, L = any> {
  data: D extends (infer Item)[] ? Item | Item[] : D;
  included?: I | I[];
  meta?: M;
  links?: L;
}

export function mapSingleOrArray<T, U>(input: T | T[], mapper: (item: T) => U): U | U[] {
  if (Array.isArray(input)) {
    return input.map(mapper);
  } else {
    return mapper(input);
  }
}

export function findSingleOrArray<T>(input: T | T[], predicate: (item: T) => boolean): T | undefined {
  if (Array.isArray(input)) {
    return input.find(predicate);
  } else {
    return predicate(input) ? input : undefined;
  }
}

export const isDataEmpty = (data: any) => {
  return !data || (Array.isArray(data) && data.length === 0);
};

/**
 * Utility to simplify handling of response data that depending on the number of resources can be
 * either a single resource or an array of resources.
 * @param data
 * @returns
 */
export const asArray = <T>(data: T | T[]): T[] => (Array.isArray(data) ? data : [data]);
