import React, { FunctionComponent, useState } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import styled, { useTheme } from 'styled-components';
import Icon from '../Icon';
import { Property } from 'csstype';
import { uniqueId } from 'lodash';

interface Props extends TooltipProps {
  delay?: number;
  text: string;
  config?: Config;
}

interface Config {
  maxWidth?: Property.MaxWidth;
  verticalAlign?: Property.VerticalAlign;
}

const StyledTooltip = styled.span<Config>`
  .react-tooltip {
    max-width: ${(props) => props.maxWidth || '280px'};
    pointer-events: auto !important;
    opacity: 1 !important;
    font-size: 1rem !important;
    white-space: normal;

    &:hover {
      visibility: visible !important;
    }
  }

  i {
    vertical-align: ${(props) => props.verticalAlign || 'bottom'};
  }

  i:hover {
    cursor: pointer;
  }
`;

export const Tooltip: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const theme = useTheme();
  const [active, setActive] = useState<boolean>(false);
  const id = uniqueId('tooltip_');
  const config = props.config === undefined ? {} : props.config;

  return (
    <StyledTooltip {...config}>
      <span
        data-for={id}
        data-tip={props.text}
        data-background-color={theme.colours.primaryB1}
        data-effect="solid"
        {...(props.event ? { 'data-event': props.event } : {})}
      >
        <Icon className={active ? 'primary' : 'grey50'} name="info_outline" />
      </span>
      <ReactTooltip
        // Props can override these
        delayHide={props.delay === undefined ? 500 : props.delay}
        className="react-tooltip"
        wrapper="span"
        {...props}
        // These will override props
        id={id}
        afterShow={() => {
          setActive(true);
          props.afterShow && props.afterShow();
        }}
        afterHide={() => {
          setActive(false);
          props.afterHide && props.afterHide();
        }}
      />
    </StyledTooltip>
  );
};
