import { useTheme } from 'styled-components';
import React from 'react';

interface IRadioButtonIcon {
  checked?: boolean;
  disabled?: boolean;
}

export const RadioButtonIcon = ({ checked, disabled }: IRadioButtonIcon) => {
  const theme = useTheme();

  if (checked)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g fill="none" fillRule="evenodd">
          <circle cx="15" cy="15" r="14.5" fill="#FFF" stroke={theme.colours.primaryB1} />
          <circle cx="15" cy="15" r="7" fill={theme.colours.primaryB1} />
        </g>
      </svg>
    );
  else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <circle
          cx="14.516"
          cy="14.516"
          r="14.016"
          fill="#FFF"
          fillRule="evenodd"
          stroke={disabled ? theme.colours.grey30 : '#767676'}
        />
      </svg>
    );
  }
};
