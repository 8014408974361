import { ProductType } from 'Quotes/types/productTypes';
import { DataRowDIA, DataRowNNI2CCT, DataRowOpticalP2P, DataRowP2CCT, DataRowP2NNI, DataRowP2P } from './types';

export const defaultHeaderProps = {
  disableSortBy: false,
  disableFilters: true,
};

type PriceData = DataRowDIA | DataRowNNI2CCT | DataRowOpticalP2P | DataRowP2CCT | DataRowP2NNI | DataRowP2P;

export const getPostcodesFromPriceData = (priceData: PriceData[]) => {
  const firstPriceData = priceData[0];

  return {
    aEndPostcode: 'aEndPostcode' in firstPriceData ? firstPriceData.aEndPostcode : undefined,
    bEndPostcode: 'bEndPostcode' in firstPriceData ? firstPriceData.bEndPostcode : undefined,
  };
};

/**
 * Utility type to enable intellisense for `displayCondition` for headers that would otherwise be typed as `any`
 */
type Header = {
  displayCondition?: boolean;
} & {
  [key: string]: any;
};

/**
 * Utility to create headers with default props, shared props and display conditions
 * @param headers
 * @param sharedProps
 * @returns
 */
export const buildHeaders = (headers: Header[], sharedProps: any = {}): Header[] => {
  return headers
    .map((header) => {
      if (header.displayCondition === false) return null;

      return {
        ...defaultHeaderProps,
        ...header,
        ...sharedProps,
      };
    })
    .filter((item) => item !== null);
};

type TableNames = Partial<
  {
    [key in ProductType]: string;
  }
>;

export const tableNamesByProductType: TableNames = {
  [ProductType.DIA]: 'price_list_dia',
  [ProductType.NNI2CCT]: 'price_list_nni2cct',
  [ProductType.OpticalP2P]: 'price_list_opticalp2p',
  [ProductType.P2CCT]: 'price_list_p2cct',
  [ProductType.P2NNI]: 'price_list_p2nni',
  [ProductType.P2P]: 'price_list_p2p',
};
