import React, { FunctionComponent } from 'react';
import Column from 'shared/components/atoms/Column';
import { currencyFormatter } from '../../../../shared/utils/currencyFormatter';

interface ICostColumnProps {
  label: string;
  cost?: number;
  className?: string;
}

type Amount = {
  amount: number;
  className?: string;
};

export const Amount: FunctionComponent<React.PropsWithChildren<Amount>> = ({ amount, className = '' }) => {
  const formatted = currencyFormatter.format(amount);

  return amount > 0 ? (
    <strong className={className}>{formatted}</strong>
  ) : (
    <span className={className}>{formatted}</span>
  );
};
export const Cost: React.FC<ICostColumnProps> = ({ label, cost = 0, className }) => {
  return (
    <Column defaultWidth={2}>
      <span className="mr-2">{label}</span>
      <Amount amount={cost} className={className} />
    </Column>
  );
};

export default Cost;
