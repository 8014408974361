import { BackendRoleName, IUserRoleFromBackend, Role } from './role';

export enum UserState {
  Active = 'Active',
  Blocked = 'Blocked',
  InviteNotSent = 'Invite not sent',
  InviteSent = 'Invite sent',
}

export enum UserReportStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum UserReportType {
  Internal = 'internal',
  External = 'external',
}

export interface IUserAttributesBase {
  companyEmail: string;
  contactEmail: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  unreadAlerts: number;
  unreadMessages: number;
  mobilePhone: string;
  landlinePhone: string;
  type: string | null;
  updatedAt: string | null;
  createdAt: string;
  lastVerificationEmailSent: string | null;
  id: string;
  lastLoggedIn: string | null;
  enabled: boolean;
  wildcardRoles: BackendRoleName[];
}

export interface IUserAttributesBaseFrontendRoles extends IUserAttributesBase {
  roles: Role[];
}

export interface IUserAttributesBaseBackendRoles extends IUserAttributesBase {
  roles: IUserRoleFromBackend[];
}

export interface ICurrentlyViewedUserAttributes extends IUserAttributesBaseBackendRoles {
  status: UserState;
}

export interface CompanyData {
  companies: Company[];
  selectedCompanyId: string | null;
}

export interface Company {
  id: string;
  name: string;
}
