import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { userPermissions } from 'shared/utils/permissions';
import { anyRolesMatch } from 'User/utils/roles';
import { selectUserRoles } from 'User/selectors';
import { selectGEACablelinkIdForPoPDisplay } from 'Order/selectors';
import {
  isFTTXQuote as isFTTXQuoteSelector,
  selectL2SIDForPoPDisplay,
  selectMDFIDForPoPDisplay,
  selectSupplierNNIRefForPoPDisplay,
} from 'Quotes/selectors';
import { StyledListItem } from './styles';
import { ProductType } from 'Quotes/types/productTypes';
import { getPortSpeedFromPortString, formatBandwidth } from 'shared/utils/connectionCapacity';

type PoP = {
  end: 'A' | 'B';
  port: string | undefined;
  pop: string | null;
  postcode: string | null;
  exchangeType?: string | null;
  showExchange?: boolean;
  showExtraInfo?: boolean;
  showSupplierNNIRef?: boolean;
  productType?: string | null;
};

export const PoP: FC<React.PropsWithChildren<PoP>> = ({
  end,
  port,
  pop,
  postcode,
  exchangeType,
  showExchange,
  showExtraInfo,
  showSupplierNNIRef = true,
  productType,
}) => {
  const userRoles = useSelector(selectUserRoles);
  const geaCablelinkId = useSelector(selectGEACablelinkIdForPoPDisplay(end));
  const layer2switchId = useSelector(selectL2SIDForPoPDisplay(end));
  const mdfId = useSelector(selectMDFIDForPoPDisplay(end));
  const supplierNNIRef = useSelector(selectSupplierNNIRefForPoPDisplay(end));
  const isFTTXQuote = useSelector(isFTTXQuoteSelector);

  if (!anyRolesMatch(userPermissions.costs, userRoles)) {
    return null;
  }

  const portForDisplay = typeof port === 'string' ? formatBandwidth(getPortSpeedFromPortString(port)) : null;
  const displayPoP = () => pop !== '' && pop !== null;

  return (
    <ul className="pop mb-0 list-inline">
      {displayPoP() && (
        <StyledListItem className="font-weight-bold mr-3">
          {`${productType === ProductType.DIA ? 'B' : end}POP`}:
        </StyledListItem>
      )}
      {portForDisplay && displayPoP() && (
        <StyledListItem>
          {portForDisplay}
          <span className="pr-1"> |</span>
        </StyledListItem>
      )}
      {showSupplierNNIRef && supplierNNIRef && (
        <StyledListItem>
          {supplierNNIRef}
          {displayPoP() && <span className="pr-1"> |</span>}
        </StyledListItem>
      )}
      {displayPoP() && (
        <>
          <StyledListItem>{pop}</StyledListItem>
          <StyledListItem>{postcode}</StyledListItem>
        </>
      )}
      {!isFTTXQuote && showExtraInfo && portForDisplay && displayPoP() && (
        <>
          <StyledListItem className="font-weight-bold mr-3 ml-3">Port:</StyledListItem>
          <StyledListItem>{portForDisplay}</StyledListItem>
        </>
      )}
      {mdfId && !showExtraInfo && (
        <StyledListItem>
          <span className="pr-1">|</span> MDFID: {mdfId}
        </StyledListItem>
      )}
      {layer2switchId && !showExtraInfo && (
        <StyledListItem>
          <span className="pr-1">|</span> L2SID: {layer2switchId}
        </StyledListItem>
      )}
      {geaCablelinkId && !showExtraInfo && (
        <StyledListItem>
          <span className="pr-1">|</span> Cablelink ID: {geaCablelinkId}
        </StyledListItem>
      )}
      {exchangeType && showExchange && !showExtraInfo && (
        <StyledListItem>
          <span className="pr-1">|</span> {exchangeType}
        </StyledListItem>
      )}
    </ul>
  );
};
