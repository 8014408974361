import { doRequest } from '../../../Request';

interface IGetUserDataRequestParams {
  status?: string | null;
  user_type?: string | null;
  [propName: string]: string | null | undefined;
}

const atLeastOneParamIsDefined = (requestParams: IGetUserDataRequestParams) => {
  return Object.values(requestParams).some((e) => Boolean(e));
};

const buildPath = (requestParams: IGetUserDataRequestParams) => {
  const pathCore = '/users/export';

  if (atLeastOneParamIsDefined(requestParams)) {
    const keyValuePairs = [];

    for (const key in requestParams) {
      if (Object.prototype.hasOwnProperty.call(requestParams, key) && !!requestParams[key]) {
        const pair = `${key}=${requestParams[key]}`;
        keyValuePairs.push(pair);
      }
    }

    return `${pathCore}?${keyValuePairs.join('&')}`;
  } else {
    return pathCore;
  }
};

const getUserDataReport = (requestParams: IGetUserDataRequestParams) => {
  const path = buildPath(requestParams);
  return doRequest({
    path: path,
  });
};

export default getUserDataReport;
