import { IPAddressAllocation } from '../components/Configure/DIAConfig/types/diaIPAllocation';
import { IPOption, ipOptions, IPType } from '../components/Configure/DIAConfig/types/ip';
import { generateIPLabelWithUsableAddresses } from '../components/Configure/DIAConfig/utils/generateIPLabelWithUsableAddresses';

export const findOption = (option: IPType | undefined): IPOption | undefined =>
  ipOptions.find((item) => item.id === option);

export const getIPTypeLabel = (
  ipType: IPType | undefined,
  isManagedDIA: boolean,
  ipAllocation: IPAddressAllocation | null
): string => {
  const option = findOption(ipType);

  if (!option) {
    return 'IPs';
  }

  if (option.label) return option.label();
  else return generateIPLabelWithUsableAddresses(isManagedDIA, option.subnet, ipAllocation);
};

export const isStaticIPWanIPCompatible = (ip?: IPType): ip is IPType => {
  if (!ip) {
    return false;
  }

  return [IPType.STATIC_4, IPType.STATIC_8, IPType.STATIC_16].includes(ip);
};

export const wanIpAdditionQuestion = (ip: IPType, isManagedDIA: boolean, ipAllocation: IPAddressAllocation | null) => {
  return `Do you require a WAN IP (/31 subnet) interface in addition to the ${getIPTypeLabel(
    ip,
    isManagedDIA,
    ipAllocation
  )} public addresses requested?`;
};
