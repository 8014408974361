import React, { FunctionComponent, useRef, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import Column from '../../../../shared/components/atoms/Column';
import Button, { ButtonMainStyle } from '../../../../shared/components/atoms/Button';
import Alert from '../../../../shared/components/atoms/Alert';
import getAPIQuote from '../../../crud/getAPIQuote';
import { apiQuoteById } from '../../../Routes';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

type Props = {
  className?: string;
};

const APIQuoteSearch: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const navigate = useNavigate();

  return <APIQuoteSearchInternal {...props} navigate={navigate} />;
};

export const APIQuoteSearchInternal: FunctionComponent<React.PropsWithChildren<Props & Navigation>> = ({
  className,
  navigate,
}) => {
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const id = searchInput.trim();

    if (id === '') {
      return;
    }

    setLoading(true);
    setNoMatch(false);
    setError(false);

    (async () => {
      try {
        await getAPIQuote(id);
        navigate(apiQuoteById(id));
      } catch (error: any) {
        if (inputElem && inputElem.current) {
          const status = error.response.status;

          if (status === 404 || status === 502) {
            setNoMatch(true);
          } else {
            setError(true);
          }
        }
      } finally {
        const isRef = !!inputElem && !!inputElem.current;
        isRef && setLoading(false);
        isRef && inputElem!.current!.focus();
      }
    })();
  };

  const inputElem = useRef<HTMLInputElement>(null);
  const [searchInput, setSearchInput] = useState('');
  const [noMatch, setNoMatch] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className={cx(className, { 'no-match': noMatch || error })}>
      <div className="content">
        <form className="row no-gutters" onSubmit={onSubmit}>
          <Column defaultWidth={8}>
            <input
              disabled={loading}
              placeholder="Enter a Quote ID"
              value={searchInput}
              ref={inputElem}
              onChange={(e) => setSearchInput(e.target.value)}
              onBlur={() => {
                setNoMatch(false);
                setError(false);
              }}
              id="search-quote-input"
            />
          </Column>
          <Column defaultWidth={4} classNames={['text-right']}>
            <Button
              type="submit"
              loading={loading}
              disabled={loading}
              className="search-quote-btn"
              mainStyle={ButtonMainStyle.PrimaryOutline}
            >
              Go
            </Button>
          </Column>
        </form>
        {noMatch && <p>A matching quote was not found</p>}
        {error && (
          <div className="server-error pl-2 pr-2 pb-2">
            <Alert>
              An error occurred whilst trying to find quote. Please try again later. If the problem persists, please
              contact your Account Manager.
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
};

export { APIQuoteSearch };
export default styled(APIQuoteSearch)`
  position: relative;
  z-index: 2;

  .content {
    position: absolute;
    right: 0;
    min-width: 260px;
  }

  &.no-match .content {
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 70, 135, 0.1);
  }

  form {
    border-bottom: 1px solid ${(props) => props.theme.colours.grey30};
    margin-bottom: 0.95rem;
  }

  &.no-match form {
    border-bottom-color: ${(props) => props.theme.colours.primaryB1};
  }

  input {
    background: transparent;
    border: none;
    padding: 0.5rem;
    margin-left: 0.5rem;
    max-width: 190px;
  }

  .search-quote-btn {
    width: 50px;
    height: 26px;
    line-height: inherit;
    padding: inherit;
    font-size: ${(props) => props.theme.typography.fontSizeMd};
    margin-top: 0.4rem;
    margin-right: 0.5rem;
  }

  .search-quote-btn[disabled] {
    width: 80px;
  }

  p {
    color: ${(props) => props.theme.colours.grey70};
    padding: 0 0.5rem 0 0.5rem;
  }

  .server-error {
    font-size: 14px;
  }

  .alert {
    margin-bottom: 0;
  }
`;
