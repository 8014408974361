import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import Column from '../../atoms/Column';

interface IOrderCardFooterProps {
  className?: string;
  theme: {
    colours: {
      grey10: string;
      secondary: string;
    };
  };
}

const OrderCardFooter: FunctionComponent<React.PropsWithChildren<IOrderCardFooterProps>> = ({ className }) => {
  return (
    <section className={className}>
      <div className="row no-gutters">
        <Column defaultWidth={12}>
          <Button type="submit" className="float-right">
            Save details
          </Button>
        </Column>
      </div>
    </section>
  );
};

const StyledOrderCardFooter = styled(OrderCardFooter)`
  border-top: 1px solid ${(props) => props.theme.colours.grey10};
  margin-top: 20px;
  padding-top: 20px;
`;

export default StyledOrderCardFooter;
