import { GoogleMapsLocation, GoogleMapsMarkerOptionsWithId } from 'shared/components/organisms/GoogleMapsLocation';
import { IPAFAddressSite } from 'shared/types/postcodeResults';
import { useTheme } from 'styled-components';
import { mapIPAFAddressSiteResponsesToMapMarker } from 'shared/utils/mapAddressesToMarkers';
import React from 'react';

export const MapsDisplay = ({
  address,
  markersOptions,
  onMarkerClick,
}: {
  markersOptions: GoogleMapsMarkerOptionsWithId[];
  onMarkerClick: (addressId: string) => void;
  address: IPAFAddressSite | null | undefined;
}) => {
  const theme = useTheme();
  return (
    <div style={{ height: '500px' }} className={'mt-2 mb-3'}>
      <GoogleMapsLocation
        center={theme.content.defaultMapsPosition}
        markersOptions={markersOptions}
        onMarkerClick={onMarkerClick}
        referenceMarkerOptions={mapIPAFAddressSiteResponsesToMapMarker(address)}
      />
    </div>
  );
};
