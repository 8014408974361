import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

export interface IBrowseInput {
  className?: string;
  disabled?: boolean;
  inputText?: string;
  onUploadFile: (files: FileList) => void;
  accept?: string;
}

const BrowseInput: FunctionComponent<React.PropsWithChildren<IBrowseInput>> = ({
  className,
  disabled = false,
  inputText = 'Browse',
  onUploadFile,
  accept,
}) => {
  const [value, setValue] = useState('');

  return (
    <div className={cx(className, { disabled })}>
      <label className="browse-label">
        {inputText}
        <input
          disabled={disabled}
          type="file"
          accept={accept ?? '.csv'}
          hidden
          name="file"
          onClick={() => setValue('')}
          onChange={(e) => onUploadFile(e.target.files!)}
          className="file-input"
          value={value}
        />
      </label>
    </div>
  );
};

const StyledBrowseInput = styled(BrowseInput)`
  background-color: ${(props) => props.theme.colours.primaryC1};
  font-family: ${(props) => props.theme.typography.fontFamilyBold};
  width: fit-content;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  .browse-label {
    margin-bottom: 0;
    cursor: pointer;
    padding: 0.5em 1em;
  }

  &.disabled {
    opacity: 0.4;

    .browse-label {
      cursor: not-allowed;
    }
  }
`;

export default StyledBrowseInput;
