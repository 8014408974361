import { IOrder } from 'Order/types/store';

const defaultProhibitedValues = ['tbc', 'n/a'];

const containsProhibitedValues = (obj: any, prohibitedValues = defaultProhibitedValues): boolean => {
  // If obj is an array, loop through its values
  if (Array.isArray(obj)) {
    for (const value of obj) {
      if (containsProhibitedValues(value)) {
        return true;
      }
    }
  }
  // If obj is an object (but not array or null), loop through its properties
  else if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (containsProhibitedValues(obj[key])) {
        return true;
      }
    }
  }
  // If obj is a primitive value, check if it's in the prohibitedValues array
  else {
    if (prohibitedValues.includes(String(obj).toLowerCase())) {
      return true;
    }
  }

  // If none of the recursive checks returned true, then obj doesn't contain prohibited values
  return false;
};

/**
 * Checks if order location data has any field set to `tbc` or `n/a`
 * @param order
 * @returns
 */
export const doesOrderContainProhibitedValues = (order: IOrder) => {
  if (order.locationA) {
    if (containsProhibitedValues(order.locationA)) return true;
  }

  if (order.locationB) {
    if (containsProhibitedValues(order.locationB)) return true;
  }

  return false;
};
