import React from 'react';
import { toast } from 'react-toastify';
import Modal, { IModalProps } from 'shared/components/molecules/Modal';
import { useOrderTemplates } from 'Order/crud/orderTemplatesApi';

interface IOrderTemplateDeleteModalProps extends Omit<IModalProps, 'title' | 'children'> {
  orderTemplateId: string;
  customerId: string;
  onClose: () => void;
  useOrderTemplatesHook?: typeof useOrderTemplates;
}

export const OrderTemplateDeleteModal = ({
  customerId,
  orderTemplateId,
  useOrderTemplatesHook = useOrderTemplates,
  onClose,
  ...props
}: IOrderTemplateDeleteModalProps) => {
  const orderTemplates = useOrderTemplatesHook(customerId);

  const deleteAction = () =>
    orderTemplates
      .deleteOrderTemplate(orderTemplateId)
      .then(() => {
        toast.success('Order template deleted');
        onClose();
      })
      .catch(() => {
        toast.error('Failed to delete order template');
      });

  return (
    <Modal
      {...props}
      title="Delete order template"
      onConfirm={deleteAction}
      disableActions={orderTemplates.isLoading}
      onClose={onClose}
    >
      Are you sure you want to delete this order template?
    </Modal>
  );
};
