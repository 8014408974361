import React, { FC } from 'react';
import { cerillionQuotesHeaders } from './cerillionQuotesHeaders';
import MultiSelectTable, { RowCell } from 'shared/components/organisms/MultiSelectTable';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { cerillionQuoteById } from 'Quotes/Routes';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface Props {
  paging: IServerSidePaging;
}

export const CerillionQuotesTable: FC<Props> = (props) => {
  const navigate = useNavigate();

  return <CerillionQuotesTableInternal {...props} navigate={navigate} />;
};

export const CerillionQuotesTableInternal: FC<Props & Navigation> = ({ paging, navigate }) => {
  const rowClickHandler = (cell: RowCell) => {
    navigate(cerillionQuoteById(cell.row.original.id));
  };

  return (
    <MultiSelectTable
      headers={cerillionQuotesHeaders}
      paging={paging}
      isLoading={paging.isLoading}
      rows={paging.results}
      rowClickHandler={rowClickHandler}
      defaultSort={{
        id: 'created_at',
        desc: true,
      }}
    />
  );
};
