import React, { FC, useState } from 'react';
import TextInput from '../../shared/components/atoms/TextInput';
import { confirmNewPassword, signIn } from '../cognito';
import Alert from '../../shared/components/atoms/Alert';
import { login } from '../routes';
import { ErrorMessage, parseErrorMessage } from '../errorMessage';
import UpdatePassword from '../shared/UpdatePassword';
import { refreshSessionTimer } from 'App/utils/sessionTimer';
import { useDispatch } from 'react-redux';
import { onResetApp } from 'actions';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

const ConfirmPassword: FC<{ username: string }> = (props) => {
  const navigate = useNavigate();
  return <ConfirmPasswordInternal {...props} navigate={navigate} />;
};

export const ConfirmPasswordInternal: FC<{ username: string } & Navigation> = ({ username, navigate }) => {
  const dispatch = useDispatch();

  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const onSuccess = (password: string) => () => {
    signIn(
      username,
      password,
      function onSignInSuccess() {
        dispatch(onResetApp());
        refreshSessionTimer();
        navigate('/');
      },
      function onSignInFailure() {
        navigate(login);
      },
      function onHandleRequireNewPassword() {
        navigate(login);
      }
    );
  };

  const onFailure = (e: ErrorMessage): void => {
    setError(parseErrorMessage(e));
    setLoading(false);
  };

  const confirm = (password: string): void => {
    setLoading(true);
    confirmNewPassword(username, confirmationCode, password, onSuccess(password), onFailure);
  };

  return (
    <div>
      {error && <Alert id="confirm-password-error">{error}</Alert>}

      <TextInput
        id="confirmation-code"
        onChange={(e) => setConfirmationCode(e.target.value)}
        labelText="Confirmation code"
        value={confirmationCode}
      />

      <UpdatePassword loading={loading} onUpdate={confirm} />
    </div>
  );
};

export default ConfirmPassword;
