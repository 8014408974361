import { ContactIdentifierType } from 'Order/OrderBuilder/shared/components/ExistingContactOptions';
import { IOrder } from './types/store';
import {
  ICopyFromOrderTemplate,
  ICreateNote,
  ICreateNoteError,
  ICreateNoteResetState,
  ICreateNoteSuccess,
  ICreateOrder,
  ICreateOrderEnded,
  ICreateOrderError,
  ICreateOrderStarted,
  IEditOrderState,
  IGetOrderQuoteAndPrice,
  IGetOrderQuoteAndPriceError,
  IGetOrderQuoteAndPriceStarted,
  IGetOrderQuoteAndPriceSucceeded,
  IMultiQuoteCopySection,
  IReplaceOrderAEndLocationAction,
  IReplaceOrderBEndLocationAction,
  IReplaceOrderBillingAndContactAction,
  IReplaceOrderNotes,
  IReplaceOrderState,
  IReplaceOrderStateHistory,
  IReplaceOrderStatus,
  IResetOrderState,
  ISetAEndAddress,
  ISetAEndAddressNotListed,
  ISetAEndAddressType,
  ISetAEndCompanyName,
  ISetAEndFullAddressNotListed,
  ISetAEndPAFAddress,
  ISetBEndAddress,
  ISetBEndAddressNotListed,
  ISetBEndAddressType,
  ISetBEndCompanyName,
  ISetBEndFullAddressNotListed,
  ISetBEndPAFAddress,
  ISetOrderId,
  ISetSelectedOrderTemplate,
  ISetSiteType,
  ISubmitOrder,
  ISubmitOrderError,
  ISubmitOrderStarted,
  ISubmitOrderSuccess,
  IUpdateAddressFieldLocationA,
  IUpdateAddressFieldLocationB,
  IUpdateBillingAndContact,
  IUpdateBillingFrequency,
  IUpdateContact,
  IUpdateIPContact,
  IUpdateIPText,
  IUpdateOnNetAddress,
  IUpdateOrder,
  IUpdateOrderEnded,
  IUpdateOrderError,
  IUpdateOrderStarted,
  IUpdateSecondaryIPDetails,
  IUpdateWanIpInAddition,
  NewOrderMessage,
  OrderActionTypes,
  SetSiteCoords,
  UpdateCloudConnect,
} from './types/actions';
import { CloudConnect, IBillingContactAndAddress, IPContact, OrderUpdateFields } from './types/formFields';
import IOrderRecord, { BillingFrequency, OrderStatus } from './types/orderRecord';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { IOpenReachAddress, IOpenReachAddressSite, IPAFAddressSite } from 'shared/types/postcodeResults';
import { OrderSubmitType } from './crud/submitOrder';
import { AddressType, IPriceData, IQuote } from 'Quotes/types/store';
import IQuoteRecord from '../Quotes/types/quoteRecord';
import { MessageResource } from 'shared/types/Messages';
import { OrderSectionCopyTarget } from './OrderBuilder/CopyFromQuote/CopyFromQuoteButton';
import { IOrderTemplate, IOrderTemplateState } from './types/orderTemplate';

export const setAEndAddressAction = (aEndAddress: IOpenReachAddressSite | null): ISetAEndAddress => ({
  payload: { aEndAddress },
  type: OrderActionTypes.SET_A_END_ADDRESS,
});

export const setAEndPAFAddressAction = (aEndAddress: IPAFAddressSite | null): ISetAEndPAFAddress => ({
  payload: { aEndAddress },
  type: OrderActionTypes.SET_A_END_PAF_ADDRESS,
});

export const setBEndAddressAction = (bEndAddress: IOpenReachAddressSite | null): ISetBEndAddress => ({
  payload: { bEndAddress },
  type: OrderActionTypes.SET_B_END_ADDRESS,
});

export const setBEndPAFAddressAction = (bEndAddress: IPAFAddressSite | null): ISetBEndPAFAddress => ({
  payload: { bEndAddress },
  type: OrderActionTypes.SET_B_END_PAF_ADDRESS,
});

export const setAEndAddressNotListedAction = (notListed: boolean): ISetAEndAddressNotListed => ({
  payload: { notListed },
  type: OrderActionTypes.SET_A_END_ADDRESS_NOT_LISTED,
});

export const setAEndFullAddressNotListedAction = (notListed: boolean): ISetAEndFullAddressNotListed => ({
  payload: { notListed },
  type: OrderActionTypes.SET_A_END_FULL_ADDRESS_NOT_LISTED,
});

export const setBEndAddressNotListedAction = (notListed: boolean): ISetBEndAddressNotListed => ({
  payload: { notListed },
  type: OrderActionTypes.SET_B_END_ADDRESS_NOT_LISTED,
});

export const setBEndFullAddressNotListedAction = (notListed: boolean): ISetBEndFullAddressNotListed => ({
  payload: { notListed },
  type: OrderActionTypes.SET_B_END_FULL_ADDRESS_NOT_LISTED,
});

export const createOrderStartedAction = (): ICreateOrderStarted => ({
  type: OrderActionTypes.CREATE_ORDER_STARTED,
});

export const createOrderEndedAction = (): ICreateOrderEnded => ({
  type: OrderActionTypes.CREATE_ORDER_ENDED,
});

export const createOrderErrorAction = (): ICreateOrderError => ({
  type: OrderActionTypes.CREATE_ORDER_ERROR,
});

export const updateOrderStartedAction = (): IUpdateOrderStarted => ({
  type: OrderActionTypes.UPDATE_ORDER_STARTED,
});

export const updateOrderEndedAction = (): IUpdateOrderEnded => ({
  type: OrderActionTypes.UPDATE_ORDER_ENDED,
});

export const updateOrderErrorAction = (): IUpdateOrderError => ({
  type: OrderActionTypes.UPDATE_ORDER_ERROR,
});

export const createOrderAction = (
  quoteId: string,
  quote: IQuote,
  customerName: string,
  selectedPrice: IPriceData,
  meta?: IMeta,
  lqId?: string
): ICreateOrder => ({
  payload: { quoteId, quote, customerName, selectedPrice, meta, lqId },
  type: OrderActionTypes.CREATE_ORDER,
});

export const editOrderAction = (orderId: string, data: OrderUpdateFields): IUpdateOrder => ({
  payload: { orderId, data },
  type: OrderActionTypes.UPDATE_ORDER,
});

export const createNote = (quoteId: string, orderId: string, message: string): ICreateNote => ({
  payload: { quoteId, orderId, message },
  type: OrderActionTypes.CREATE_NOTE,
});

export const createNoteError = (): ICreateNoteError => ({
  type: OrderActionTypes.CREATE_NOTE_ERROR,
});

export const createNoteSuccess = (): ICreateNoteSuccess => ({
  type: OrderActionTypes.CREATE_NOTE_SUCCESS,
});

export const createNoteResetState = (): ICreateNoteResetState => ({
  type: OrderActionTypes.CREATE_NOTE_RESET_STATE,
});

export const setOrderIdAction = (id: string): ISetOrderId => ({
  payload: { id },
  type: OrderActionTypes.SET_ORDER_ID,
});

export const getOrderQuoteAndPriceAction = (orderId: string): IGetOrderQuoteAndPrice => ({
  payload: { orderId },
  type: OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE,
});

export const getOrderQuoteAndPriceStartedAction = (): IGetOrderQuoteAndPriceStarted => ({
  type: OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE_STARTED,
});

export const getOrderQuoteAndPriceSucceededAction = (): IGetOrderQuoteAndPriceSucceeded => ({
  type: OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE_SUCCEEDED,
});

export const getOrderQuoteAndPriceErrorAction = (error?: string): IGetOrderQuoteAndPriceError => ({
  payload: { error: error || '' },
  type: OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE_ERROR,
});

export const updateBillingAndContact = (
  billingContactAndAddress: IBillingContactAndAddress
): IUpdateBillingAndContact => ({
  payload: { billingContactAndAddress },
  type: OrderActionTypes.UPDATE_BILLING_AND_CONTACT,
});

export const updateIPContact = (ipContact: IPContact): IUpdateIPContact => ({
  payload: { ipContact },
  type: OrderActionTypes.UPDATE_IP_CONTACT,
});

export const updateIPText = (text: string): IUpdateIPText => ({
  payload: { text },
  type: OrderActionTypes.UPDATE_IP_TEXT,
});

export const updateSecondaryIPDetails = (ipText: string, nicRipeHandle: string): IUpdateSecondaryIPDetails => ({
  payload: { ipText, nicRipeHandle },
  type: OrderActionTypes.UPDATE_SECONDARY_IP_DETAILS,
});

export const updateWanIpInAddition = (wan_ip_in_addition: IOrder['wan_ip_in_addition']): IUpdateWanIpInAddition => ({
  payload: { wan_ip_in_addition },
  type: OrderActionTypes.UPDATE_WAN_IP_IN_ADDITION,
});

export const updateCloudConnect = (cloudConnect: CloudConnect): UpdateCloudConnect => ({
  payload: cloudConnect,
  type: OrderActionTypes.UPDATE_CLOUD_CONNECT,
});

export const updateAddressFieldLocationA = (
  fieldName: keyof IOpenReachAddress,
  fieldValue: string
): IUpdateAddressFieldLocationA => ({
  payload: { fieldName, fieldValue },
  type: OrderActionTypes.UPDATE_ADDRESS_FIELD_LOCATION_A,
});

export const updateAddressFieldLocationB = (
  fieldName: keyof IOpenReachAddress,
  fieldValue: string
): IUpdateAddressFieldLocationB => ({
  payload: { fieldName, fieldValue },
  type: OrderActionTypes.UPDATE_ADDRESS_FIELD_LOCATION_B,
});

export const updateOnNetAddress = (
  end: IUpdateOnNetAddress['payload']['end'],
  attributes: IUpdateOnNetAddress['payload']['attributes']
): IUpdateOnNetAddress => ({
  payload: {
    end,
    attributes,
  },
  type: OrderActionTypes.UPDATE_ON_NET_ADDRESS_FIELD,
});

export const updateContact = (
  contactIdentifier: ContactIdentifierType,
  contactType: string,
  order: IOrder
): IUpdateContact => ({
  payload: { contactIdentifier, contactType, order },
  type: OrderActionTypes.UPDATE_CONTACT,
});

export const replaceOrderStateAction = (order: IOrderRecord, quote: IQuoteRecord): IReplaceOrderState => ({
  payload: { order, quote },
  type: OrderActionTypes.REPLACE_ORDER_STATE,
});

export const editOrderState = (order: IOrder): IEditOrderState => ({
  payload: order,
  type: OrderActionTypes.EDIT_ORDER_STATE,
});

export const replaceOrderAEndLocationAction = (order: IOrderRecord): IReplaceOrderAEndLocationAction => ({
  payload: { order },
  type: OrderActionTypes.REPLACE_ORDER_A_END_LOCATION,
});

export const replaceOrderBEndLocationAction = (order: IOrderRecord): IReplaceOrderBEndLocationAction => ({
  payload: { order },
  type: OrderActionTypes.REPLACE_ORDER_B_END_LOCATION,
});

export const replaceOrderBillingAndContactAction = (order: IOrderRecord): IReplaceOrderBillingAndContactAction => ({
  payload: { order },
  type: OrderActionTypes.REPLACE_ORDER_BILLING_AND_CONTACT,
});

export const replaceOrderStatus = (status: OrderStatus): IReplaceOrderStatus => ({
  payload: { status },
  type: OrderActionTypes.REPLACE_ORDER_STATUS,
});

export const replaceOrderStateHistory = (history: IOrder['stateHistory']): IReplaceOrderStateHistory => ({
  payload: { history },
  type: OrderActionTypes.REPLACE_ORDER_HISTORY,
});

export const replaceOrderNotes = (order: IOrderRecord): IReplaceOrderNotes => ({
  payload: { order },
  type: OrderActionTypes.REPLACE_ORDER_NOTES,
});

export const submitOrder = (orderId: string, submitType: OrderSubmitType): ISubmitOrder => ({
  payload: { orderId, submitType },
  type: OrderActionTypes.SUBMIT_ORDER,
});

export const submitOrderStarted = (): ISubmitOrderStarted => ({
  type: OrderActionTypes.SUBMIT_ORDER_STARTED,
});

export const submitOrderSuccess = (): ISubmitOrderSuccess => ({
  type: OrderActionTypes.SUBMIT_ORDER_SUCCESS,
});

export const submitOrderError = (): ISubmitOrderError => ({
  type: OrderActionTypes.SUBMIT_ORDER_ERROR,
});

export const resetOrderState = (): IResetOrderState => ({
  type: OrderActionTypes.RESET_ORDER_STATE,
});

export const setAEndCompanyNameAction = (endCompanyName: string): ISetAEndCompanyName => ({
  payload: { endCompanyName },
  type: OrderActionTypes.SET_A_END_COMPANY_NAME,
});

export const setBEndCompanyNameAction = (endCompanyName: string): ISetBEndCompanyName => ({
  payload: { endCompanyName },
  type: OrderActionTypes.SET_B_END_COMPANY_NAME,
});

export const setAEndAddressTypeAction = (endAddressType: AddressType): ISetAEndAddressType => ({
  payload: { endAddressType },
  type: OrderActionTypes.SET_A_END_ADDRESS_TYPE,
});

export const setBEndAddressTypeAction = (endAddressType: AddressType): ISetBEndAddressType => ({
  payload: { endAddressType },
  type: OrderActionTypes.SET_B_END_ADDRESS_TYPE,
});

export const setSiteType = (
  end: ISetSiteType['payload']['end'],
  siteType: ISetSiteType['payload']['siteType']
): ISetSiteType => ({
  payload: {
    end,
    siteType,
  },
  type: OrderActionTypes.SET_SITE_TYPE,
});

export const updateBillingFrequency = (billingFrequency: BillingFrequency): IUpdateBillingFrequency => ({
  payload: {
    billingFrequency,
  },
  type: OrderActionTypes.UPDATE_BILLING_FREQUENCY,
});

export const setSiteCoords = (
  end: SetSiteCoords['payload']['end'],
  field: SetSiteCoords['payload']['field'],
  value: SetSiteCoords['payload']['value']
): SetSiteCoords => ({
  payload: {
    end,
    field,
    value,
  },
  type: OrderActionTypes.SET_SITE_COORDS,
});

export const newOrderMessage = (message: MessageResource): NewOrderMessage => ({
  payload: { message },
  type: OrderActionTypes.NEW_ORDER_MESSAGE,
});

export const multiQuoteCopySection = (
  targetSection: OrderSectionCopyTarget,
  orderToCopyFrom: IOrderRecord,
  quoteToCopyFrom: IQuoteRecord
): IMultiQuoteCopySection => ({
  type: OrderActionTypes.MULTI_QUOTE_COPY_SECTION,
  payload: { targetSection, orderToCopyFrom, quoteToCopyFrom },
});

export const copyFromOrderTemplate = (sourceOrderTemplate: IOrderTemplateState): ICopyFromOrderTemplate => ({
  type: OrderActionTypes.COPY_FROM_ORDER_TEMPLATE,
  payload: { sourceOrderTemplate },
});

export const setSelectedOrderTemplate = (selectedOrderTemplate?: IOrderTemplate): ISetSelectedOrderTemplate => ({
  type: OrderActionTypes.SET_SELECTED_ORDER_TEMPLATE,
  payload: { selectedOrderTemplate },
});
