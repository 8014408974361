import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useOpenReachAddressesFetch } from 'Order/OrderBuilder/shared/hooks';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import { IOpenReachAddress, IOpenReachAddressSite, IPAFAddress } from 'shared/types/postcodeResults';
import Alert from 'shared/components/atoms/Alert';
import { POSTCODE_OTHER_ERROR_MSG } from 'Location/PostcodeCapture';
import { AddressesFound } from 'Quotes/types/store';
import Dropdown, { ICustomComponents, IDropdownOption } from 'shared/components/molecules/Dropdown';
import addressNotListedMenu, {
  ADDRESS_NOT_LISTED_PLACEHOLDER,
  DEFAULT_PLACEHOLDER,
} from 'Location/AddressNotListed/addressNotListedMenu';
import {
  concatenateOpenReachAddress,
  findOpenReachAddressByItsLabel,
  isFTTXCompatibleOpenreachLocation,
} from 'shared/utils/addresses/helperFunctions';
import { objectIsEmpty } from 'shared/utils/objectHelper';
import onlyPostcodeIsPresent from 'shared/utils/addresses/onlyPostcodeIsPresent';
import { IncompatibleFTTXLocation } from 'Location/IncompatibleFTTXLocation';
import { CompatibleFTTXLocation } from 'Location/CompatibleFTTXLocation';

type OpenreachAddressCapture = FunctionComponent<
  React.PropsWithChildren<{
    addresses: AddressesFound;
    addressNotListed: boolean;
    className?: string;
    onAddressNotListed(notListed: boolean): void;
    onResults(postcode: string, addressesFound: AddressesFound): void;
    onOpenreachAddressSubmit(address: IOpenReachAddressSite | null): void;
    addressRetrieval(retrieve: boolean): void;
    pafAddress: IPAFAddress;
    postcode: string;
    selectedAddress: IOpenReachAddressSite | null;
    enableAddressNotListedMenu?: boolean;
    resultsFilter: (results: IOpenReachAddressSite[]) => IOpenReachAddressSite[];
  }>
>;

export const getDefaultValueForOpenReachAddress = (
  location: IOpenReachAddress,
  fullAddressNotListed: boolean
): IDropdownOption | null => {
  if (objectIsEmpty(location) || fullAddressNotListed || onlyPostcodeIsPresent(location)) {
    return null;
  }

  const addressLine = concatenateOpenReachAddress(location, true);
  return {
    label: addressLine,
    value: addressLine,
  };
};

const buildCustomComponents = (
  enableAddressNotListedMenu: boolean,
  addressNotListed: boolean,
  onAddressNotListed: (notListed: boolean) => void,
  setMenuPlaceholder: (value: ((prevState: string) => string) | string) => void
) => {
  const customComponents: ICustomComponents = {};

  if (enableAddressNotListedMenu) {
    customComponents.Menu = addressNotListedMenu({
      addressNotListed,
      onAddressNotListed,
      setPlaceholder: setMenuPlaceholder,
    });
  }
  return customComponents;
};

const OpenreachAddressCapture: OpenreachAddressCapture = ({
  addresses,
  addressNotListed,
  addressRetrieval,
  className,
  onAddressNotListed,
  onResults,
  resultsFilter,
  onOpenreachAddressSubmit,
  pafAddress,
  postcode,
  selectedAddress,
  enableAddressNotListedMenu = true,
}) => {
  const theme = useTheme();

  const {
    errorInFetchingOpenReach,
    openReachAddresses,
    fetchingOpenReachAddressesInProgress,
  } = useOpenReachAddressesFetch({
    postcode: postcode,
    pafAddress: pafAddress,
    resultsFilter,
  });

  useEffect(() => {
    if (fetchingOpenReachAddressesInProgress) {
      addressRetrieval(true);
    } else {
      addressRetrieval(false);
    }
  }, [addressRetrieval, fetchingOpenReachAddressesInProgress]);

  useEffect(() => {
    return () => {
      addressRetrieval(false);
    };
  }, [addressRetrieval]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [menuPlaceholder, setMenuPlaceholder] = useState(
    addressNotListed ? ADDRESS_NOT_LISTED_PLACEHOLDER : DEFAULT_PLACEHOLDER
  );

  useEffect(() => {
    onResults(postcode, {
      ...addresses,
      openreach: openReachAddresses,
    });

    if (openReachAddresses.length === 1) {
      onOpenreachAddressSubmit(openReachAddresses[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReachAddresses]);

  const customComponents = buildCustomComponents(
    enableAddressNotListedMenu,
    addressNotListed,
    onAddressNotListed,
    setMenuPlaceholder
  );

  const menuOptions: IDropdownOption[] = openReachAddresses.map(
    (site: IOpenReachAddressSite): IDropdownOption => {
      const addressLine = concatenateOpenReachAddress(site.attributes, true);

      return {
        label: addressLine,
        value: addressLine,
      };
    }
  );

  const onSelect = (address: IDropdownOption | null) => {
    if (address !== null) {
      const foundAddress = findOpenReachAddressByItsLabel(address.label, openReachAddresses);

      if (foundAddress) {
        onOpenreachAddressSubmit(foundAddress);
        wrapperRef && setMenuPlaceholder(DEFAULT_PLACEHOLDER);
      }
    } else {
      onOpenreachAddressSubmit(null);
    }
  };

  return (
    <div className={className} ref={wrapperRef}>
      {fetchingOpenReachAddressesInProgress && (
        <Spinner text="Checking selected location" backgroundColour={theme.colours.mainBackgroundColor} size="large" />
      )}

      {errorInFetchingOpenReach && <Alert>{POSTCODE_OTHER_ERROR_MSG}</Alert>}

      {addresses.openreach.length > 1 && (
        <>
          <p>We&apos;ve found multiple options at this address, select the exact location below</p>
          <Dropdown
            className="openreach-dropdown"
            customComponents={customComponents}
            defaultValue={
              selectedAddress ? getDefaultValueForOpenReachAddress(selectedAddress.attributes, addressNotListed) : null
            }
            onChange={onSelect}
            options={menuOptions}
            placeholder={menuPlaceholder}
          />
          <hr />
        </>
      )}

      {isFTTXCompatibleOpenreachLocation(selectedAddress) && (
        <CompatibleFTTXLocation selectedOpenreachAddress={selectedAddress} />
      )}

      {!fetchingOpenReachAddressesInProgress &&
        !errorInFetchingOpenReach &&
        (openReachAddresses.length === 0 ||
          (selectedAddress && !isFTTXCompatibleOpenreachLocation(selectedAddress))) && <IncompatibleFTTXLocation />}
    </div>
  );
};

const Styled = styled(OpenreachAddressCapture)`
  background: ${(props) => props.theme.colours.mainBackgroundColor};
  padding: 1.6em 0.8em 0.1em 0.8em;

  .price_spinner_wrapper {
    padding-top: 0;
  }

  .alert.alert-danger {
    margin-top: 0;
  }

  .info-icon {
    margin-right: 0.3em;
    vertical-align: text-bottom;
  }

  .alk__wrapper {
    margin-top: 0;
    margin-bottom: 1.5em;
  }
`;

export { Styled as OpenreachAddressCapture };
