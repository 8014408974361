import React, { FunctionComponent } from 'react';
import Column from 'shared/components/atoms/Column';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { VERSION_NUMBER } from 'footerVersion';

type Footer = FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
    theme: Partial<DefaultTheme>;
  }>
>;

const date = new Date().getFullYear();
const Footer: Footer = (props) => {
  const theme = useTheme();
  return (
    <footer className={props.className}>
      <div className="container pt-5">
        <div className="row">
          <Column>{theme.content.footer.content}</Column>
        </div>
        <div className="row">
          <Column lgWidth={10}>
            <ul className="list-inline mt-3 links">
              <li className="list-inline-item mr-0">{VERSION_NUMBER}</li>
              <li className="list-inline-item mr-0">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={theme.content.footer.legalLinks.company.url}
                  aria-current="page"
                >
                  {theme.content.footer.legalLinks.company.name} {date}
                </a>
              </li>
              <li className="list-inline-item mr-0">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={theme.content.footer.legalLinks.complaintsProcedure}
                  aria-current="page"
                >
                  Complaints procedure
                </a>
              </li>
              <li className="list-inline-item mr-0">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={theme.content.footer.legalLinks.codesOfPractice}
                  aria-current="page"
                >
                  Codes of practice
                </a>
              </li>
              <li className="list-inline-item mr-0">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={theme.content.footer.legalLinks.privacyPolicy}
                  aria-current="page"
                >
                  Privacy policy
                </a>
              </li>
              <li className="list-inline-item mr-0">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={theme.content.footer.legalLinks.acceptableUsePolicy}
                  aria-current="page"
                >
                  Acceptable use policy
                </a>
              </li>
              <li className="list-inline-item mr-0">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={theme.content.footer.legalLinks.modernSlaveryStatement}
                  aria-current="page"
                >
                  Modern slavery statement
                </a>
              </li>
            </ul>
          </Column>
          <Column lgWidth={2} classNames={['text-right']}>
            <ul className="list-inline mt-3">
              {theme.content.footer.socialLinks.twitter && (
                <li className="list-inline-item mr-3">
                  <a rel="noopener noreferrer" target="_blank" href={theme.content.footer.socialLinks.twitter}>
                    <img src="/images/twitter.png" width="28" />
                  </a>
                </li>
              )}
              {theme.content.footer.socialLinks.linkedin && (
                <li className="list-inline-item">
                  <a rel="noopener noreferrer" target="_blank" href={theme.content.footer.socialLinks.linkedin}>
                    <img src="/images/linked-in.png" width="30" />
                  </a>
                </li>
              )}
            </ul>
          </Column>
        </div>
      </div>
    </footer>
  );
};

const StyledFooter = styled(Footer)`
  background-color: ${(props) => props.theme.colours.secondaryE1};
  color: white;
  font-size: 16px;
  padding-bottom: 2em;

  a {
    color: white;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  ul.links {
    margin-top: 4em;
    padding: 0;

    li {
      :after {
        content: '|';
        margin: 0 1em;
      }

      &:last-child:after {
        display: none;
      }
    }
  }
`;

export default StyledFooter;
