import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IAppState } from 'reducers';
import { setDIAIPAllocation as setDIAIPAllocationAction, setIPOption as setIPOptionAction } from 'Quotes/actions';
import { IPOption, IPType, staticIPList, subnetList } from './types/ip';
import { IAEndLocation, IPAddress } from 'Quotes/types/store';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import { ISetDIAIPAllocation } from 'Quotes/types/actions';
import { diaIPAllocationOptions, IPAddressAllocation } from './types/diaIPAllocation';
import { Option, OptionGroup } from 'shared/components/organisms/OptionGroup/OptionGroup';
import hasFeatureEnabled, { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { RackMountKit } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/RackMount';
import { EngineerInstallationRequired } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/EngineerInstallation';
import { MDIASelection } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/MDIASelection';
import { generateIPLabelWithUsableAddresses } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/utils/generateIPLabelWithUsableAddresses';

const tooltipText = hasFeatureEnabled(Feature.newMDIAUI)
  ? `Mandatory justification is required by RIPE for orders of 16 or more static IP addresses (/28,/27 and /26 subnets).`
  : `If you require a WAN IP (/31 subnet) interface in addition to the public addresses selected,
please select this option when completing the order form.
Mandatory justification is required by RIPE for orders of 16 static IP addresses.`;

interface DIAConfig {
  className?: string;
  ip: IPAddress;
  isManagedDIA: IAEndLocation['is_managed_dia'];
  diaIPAllocation: IAEndLocation['dia_ip_allocation'];
  setIPOption(option: IPType): void;
  setDIAIPAllocation(dia_ip_allocation: ISetDIAIPAllocation['payload']['dia_ip_allocation']): void;
}

export const IP_ADDRESS_SELECTION_AVAILABLE = [
  IPAddressAllocation.WAN_31_PUBLIC_ALLOCATION,
  IPAddressAllocation.PUBLIC_ALLOCATION_ONLY,
];

export const getAdditionalIPAddressesHelperText = (
  is_managed_dia: boolean,
  dia_ip_allocation: IPAddressAllocation | null
) => {
  if (is_managed_dia) return 'Note: 1 IP will be required on the MDIA router LAN interface.';
  else if (dia_ip_allocation === IPAddressAllocation.PUBLIC_ALLOCATION_ONLY)
    return 'Note: 1 IP will be used on upstream Neos router.';

  return '';
};

export const DIAConfig = () => {
  const dispatch = useDispatch();
  const setIPOption = (option: IPType) => dispatch(setIPOptionAction(option));
  const setDIAIPAllocation = (diaIPAllocation: IPAddressAllocation) =>
    dispatch(setDIAIPAllocationAction(diaIPAllocation));

  const ip = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.ip);
  const isManagedDIA = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.is_managed_dia);
  const diaIPAllocation = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.dia_ip_allocation);

  return (
    <DIAConfigInternal
      ip={ip}
      setIPOption={setIPOption}
      isManagedDIA={isManagedDIA}
      diaIPAllocation={diaIPAllocation}
      setDIAIPAllocation={setDIAIPAllocation}
    />
  );
};

function Header(props: { typeText?: string; additionalHelperText: false | string; marginTop: string }) {
  return (
    <div className={`mt-${props.marginTop} mb-4 ip-addresses-label`}>
      {props.typeText} IP Addresses
      <Tooltip text={tooltipText} />
      <p className="text-muted mb-4">
        Please select your {props.typeText} public address space. {props.additionalHelperText}
      </p>
    </div>
  );
}

function IPSelection(props: {
  options: Option<IPType>[];
  onClick: (value: IPType) => void;
  value: IPType | null | undefined;
  readOnly?: boolean;
}) {
  return (
    <div className="d-flex flex-wrap">
      <OptionGroup<IPType>
        options={props.options}
        onClick={props.onClick}
        value={props.value}
        itemsPerRow={3}
        readOnly={props.readOnly}
      />
    </div>
  );
}

export const IPAddressSelection = ({
  additionalHelperText,
  ipOption,
  options,
  setIPOption,
  typeText,
  isReadOnly,
  showHeader = true,
  marginTop = '5',
}: {
  additionalHelperText: false | string;
  options: Option<IPType>[];
  setIPOption: (value: IPType) => void;
  ipOption: IPType | null | undefined;
  typeText?: string;
  isReadOnly?: boolean;
  showHeader?: boolean;
  marginTop?: string;
}) => (
  <>
    <div data-testid="ip-addresses-section">
      {showHeader && <Header typeText={typeText} additionalHelperText={additionalHelperText} marginTop={marginTop} />}
      <IPSelection options={options} onClick={setIPOption} value={ipOption} readOnly={isReadOnly} />
    </div>
  </>
);

export const IPAddressAllocationSelection = ({
  diaIPAllocation,
  setDIAIPAllocation,
}: {
  diaIPAllocation: IPAddressAllocation | null;
  setDIAIPAllocation: (value: IPAddressAllocation) => void;
}) => {
  const ipAllocationOptions: Option<IPAddressAllocation>[] = diaIPAllocationOptions.map((item) => ({
    customId: item.id,
    label: item.label,
    value: item.id,
    tooltip: item.helperText,
  }));

  return (
    <>
      <p className="mt-4 mb-2">IP address allocation</p>
      <OptionGroup<IPAddressAllocation>
        options={ipAllocationOptions}
        onClick={setDIAIPAllocation}
        value={diaIPAllocation}
        itemsPerRow={3}
      />
    </>
  );
};

const getIpAddressOptions = (includeWANOption: boolean, newMDIAFeatureEnabled: boolean) => {
  const wanFilter = (ipOption: IPOption) => (includeWANOption && ipOption.subnet === 31) || ipOption.subnet !== 31;
  if (newMDIAFeatureEnabled) {
    return subnetList.filter(wanFilter);
  }

  return staticIPList.filter(wanFilter);
};

const getLabelFormatter = (
  isManagedDIA: boolean,
  diaIPAllocation: IPAddressAllocation | null,
  newMDIAFeatureEnabled: boolean,
  interimFix: boolean
) => {
  const staticIpLabel = (ipOption: IPOption): string => ipOption.label();
  const usableAddressesIpLabel = (ipOption: IPOption): string =>
    generateIPLabelWithUsableAddresses(isManagedDIA, ipOption.subnet, diaIPAllocation!);

  return newMDIAFeatureEnabled && !interimFix ? usableAddressesIpLabel : staticIpLabel;
};

export const shouldIncludeWANOption = (
  isManagedDIA: boolean,
  diaIPAllocation: IPAddressAllocation | null,
  newMDIAFeatureEnabled: boolean,
  interimFix: boolean
): boolean => {
  if (newMDIAFeatureEnabled && !interimFix) return diaIPAllocation === IPAddressAllocation.PUBLIC_ALLOCATION_ONLY;
  else return !isManagedDIA;
};

export const getIpAddressesOptions = (
  isManagedDIA: boolean,
  diaIPAllocation: IPAddressAllocation | null,
  newMDIAFeatureEnabled: boolean,
  interimFix: boolean
) => {
  const labelFormatter = getLabelFormatter(isManagedDIA, diaIPAllocation, newMDIAFeatureEnabled, interimFix);

  const includeWAN = shouldIncludeWANOption(isManagedDIA, diaIPAllocation, newMDIAFeatureEnabled, interimFix);

  return getIpAddressOptions(includeWAN, newMDIAFeatureEnabled).map((ipOption) => ({
    customId: ipOption.id,
    label: labelFormatter(ipOption),
    value: ipOption.id,
  }));
};

export const DIAConfigInternal: FC<React.PropsWithChildren<DIAConfig>> = ({
  className,
  ip,
  setIPOption,
  isManagedDIA,
  diaIPAllocation,
  setDIAIPAllocation,
}) => {
  const newMDIAFeatureEnabled = featureFlag.isEnabled(Feature.newMDIAUI);
  const interimFix =
    (newMDIAFeatureEnabled && featureFlag.getAttribute<boolean>(Feature.newMDIAUI, 'interimFix')) || false;

  const ipAddressesOptions = getIpAddressesOptions(isManagedDIA, diaIPAllocation, newMDIAFeatureEnabled, interimFix);

  const additionalHelperText =
    newMDIAFeatureEnabled && getAdditionalIPAddressesHelperText(isManagedDIA, diaIPAllocation);

  const supportsIPAddressSelection =
    diaIPAllocation !== null && IP_ADDRESS_SELECTION_AVAILABLE.includes(diaIPAllocation);

  if (newMDIAFeatureEnabled) {
    return (
      <div className={`${className} mb-5`} data-testid="mdia-section">
        <MDIASelection />

        {!isManagedDIA && !interimFix && (
          <IPAddressAllocationSelection setDIAIPAllocation={setDIAIPAllocation} diaIPAllocation={diaIPAllocation} />
        )}

        {(isManagedDIA || interimFix || supportsIPAddressSelection) && (
          <IPAddressSelection
            additionalHelperText={additionalHelperText}
            options={ipAddressesOptions}
            setIPOption={setIPOption}
            ipOption={ip.selectedId}
          />
        )}

        {isManagedDIA && (
          <>
            <EngineerInstallationRequired />

            <RackMountKit />
          </>
        )}
      </div>
    );
  } else
    return (
      <div className={`${className} mb-5`}>
        <MDIASelection />

        <IPAddressSelection
          additionalHelperText={additionalHelperText}
          options={ipAddressesOptions}
          setIPOption={setIPOption}
          ipOption={ip.selectedId}
        />
      </div>
    );
};

const styledDIAConfig = styled(DIAConfig)`
  .radio-btn {
    width: 33.333%;
    padding-right: 0.625em;
    &:nth-child(2) {
      padding-left: 0.625em;
    }
  }
`;

export default styledDIAConfig;
