import React, { CSSProperties, FunctionComponent } from 'react';
import styled, { useTheme } from 'styled-components';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import Column from 'shared/components/atoms/Column';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import Icon from 'shared/components/atoms/Icon';
import { Section } from './components/Section';
import orderContent from './content/order';
import quoteContent from './content/quote';
import otherContent from './content/other';
import userManagementContent from './content/user-management';
import preferencesContent from './content/preferences';

type Props = FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
  }>
>;

export const UnstyledFAQ: Props = ({ className }) => {
  useTrackPage(pages.faq);
  const theme = useTheme();

  return (
    <HeaderWithChildrenWrapper className="faq-page" header="Help and FAQs">
      <div className={className}>
        <div className="row">
          <Column defaultWidth={8}>
            <p>
              Our intuitive and customer-friendly portal has been designed to be simple to use, but should you need a
              helping hand our step-by-step userguide walks through how to use the portal and how to complete some
              common tasks.
            </p>
            <p>If you&apos;re still having issues with the portal, please contact your Account Director.</p>
          </Column>
          <Column defaultWidth={4}>
            <div className="actions-box">
              <h2 className="h3">Useful links</h2>
              <ul className="list-unstyled useful-links pt-1">
                <li>
                  <Icon name="chevron_right" />
                  <a rel="noopener noreferrer" target="_blank" href={theme.content.usefulLinks.userGuide.url}>
                    {theme.content.usefulLinks.userGuide.title}
                  </a>
                </li>
              </ul>
            </div>
          </Column>
        </div>
        <div className="row">
          <Column>
            <Section title="Quoting" questions={quoteContent}>
              <QuoteVideo />
            </Section>
            <Section title="Order" questions={orderContent}>
              <OrderVideo />
            </Section>
            <Section title="User Management" questions={userManagementContent} />
            <Section title="Preferences" questions={preferencesContent}>
              <PreferencesVideo />
            </Section>
            <Section title="Other" questions={otherContent} />
          </Column>
        </div>
      </div>
    </HeaderWithChildrenWrapper>
  );
};

const QuoteVideo = () => (
  <VimeoEmbed
    title="LIVEQUOTE - Quote"
    url="https://player.vimeo.com/video/848677400?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  />
);

const OrderVideo = () => (
  <VimeoEmbed
    title="LIVEQUOTE - View Orders"
    url="https://player.vimeo.com/video/848678754?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  />
);

const PreferencesVideo = () => (
  <VimeoEmbed
    title="LIVEQUOTE - Prferences"
    url="https://player.vimeo.com/video/848676956?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479#t=0m25s"
  />
);

export const VimeoEmbed = ({ title, url, style }: { title: string; url: string; style?: CSSProperties }) => (
  <div style={{height: '100%', width: '100%'}}>
    <iframe
      title={title}
      src={url}
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      style={{
        border: 0,
        width: '100%',
        height: 250,
        ...style,
      }}
    />
  </div>
);

const StyledFAQ = styled(UnstyledFAQ)`
  .actions-box {
    background-color: ${(props) => props.theme.colours.secondaryC5};
    box-shadow: ${(props) => props.theme.shadows.boxShadow};
    border-radius: 0.25em;
    padding: 2em;

    .useful-links {
      li {
        position: relative;
        padding-left: 25px;
        padding-bottom: 5px;
      }

      i {
        position: absolute;
        left: 0;
        top: 3px;
        color: ${(props) => props.theme.colours.primaryB1};
      }
    }
  }
`;

export { StyledFAQ as FAQ };
