import { IPriceData, IQuote, ISecondaryPrice } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import { MultiCircuitPanel } from 'Order/OrderBuilder/QuoteSummary/P2NNI/MultiNNILocations';
import React from 'react';
import { SingleCircuitP2P } from 'Order/OrderBuilder/QuoteSummary/P2P/SingleCircuitP2P';

export function MultiCircuitP2P(props: {
  selectedPrice: IPriceData;
  sseUser: boolean | undefined;
  primaryAddressAEnd: string | undefined;
  quote: IQuote;
  primaryAddressBEnd: string | undefined;
  bandwidth: string;
  bearer: BearerType | undefined;
  productType: ProductType;
  secondarySelectedPrice: ISecondaryPrice;
  secondaryBandwidth: string;
}) {
  return (
    <>
      <MultiCircuitPanel>
        <SingleCircuitP2P
          circuit={'Primary'}
          selectedPrice={props.selectedPrice}
          sseUser={props.sseUser}
          primaryAddressAEnd={props.primaryAddressAEnd}
          quote={props.quote}
          primaryAddressBEnd={props.primaryAddressBEnd}
          bandwidth={props.bandwidth}
          bearer={props.bearer}
          productType={props.productType}
        />
      </MultiCircuitPanel>
      <MultiCircuitPanel>
        <SingleCircuitP2P
          circuit={'Secondary'}
          selectedPrice={props.secondarySelectedPrice}
          sseUser={props.sseUser}
          primaryAddressAEnd={props.primaryAddressAEnd}
          quote={props.quote}
          primaryAddressBEnd={props.primaryAddressBEnd}
          bandwidth={props.secondaryBandwidth}
          bearer={props.bearer}
          productType={props.productType}
        />
      </MultiCircuitPanel>
    </>
  );
}
