import React, { FunctionComponent } from 'react';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import { Company } from 'User/types/user';

interface ICustomersFilter {
  className?: string;
  userCompanies: Company[];
  setCustomerId(id: string | null): void;
}

const CustomersFilter: FunctionComponent<React.PropsWithChildren<ICustomersFilter>> = ({
  userCompanies,
  setCustomerId,
}) => (
  <div className="control">
    <label>Customer</label>
    <Dropdown
      options={userCompanies.map((userCompany) => {
        return {
          label: userCompany.name,
          value: userCompany.id,
        };
      })}
      onChange={(customerOption: IDropdownOption) => setCustomerId(customerOption ? customerOption.value : null)}
      className="customers-dropdown select-input"
    />
  </div>
);

export default CustomersFilter;
