import { IPriceData, IQuote } from '../../../../Quotes/types/store';
import Column from '../../../../shared/components/atoms/Column';
import { presentSupplierName } from '../../../../Quotes/utils/supplierHelpers';
import { presentProductNameForPoPDisplay } from '../../../../Quotes/utils/popHelper';
import { PoP } from '../PoP';
import React from 'react';

export function BEndLocation(props: { selectedPrice: IPriceData; sseUser: boolean; quote: IQuote; pointAddress: any }) {
  return (
    <>
      <Column defaultWidth={2} offsetDefaultWidth={2}>
        <span className="font-weight-bold">
          B-End ({presentSupplierName(`${props.selectedPrice.b_end_access_type}` || '')}
          {props.sseUser &&
            presentProductNameForPoPDisplay(
              props.selectedPrice.b_end_product_name,
              props.selectedPrice.b_end_access_type
            )}
          )
        </span>
      </Column>
      <Column defaultWidth={8}>
        <PoP
          end="B"
          port={props.quote.bEndPort}
          pop={props.selectedPrice.b_end_p_o_p}
          postcode={props.selectedPrice.b_end_p_o_p_postcode}
          exchangeType={props.selectedPrice.b_end_exchange_type}
          showExchange={true}
        />
        <address className="mb-0" data-testid="order-summary-nni-b-end-address">
          {props.pointAddress}
        </address>
      </Column>
    </>
  );
}
