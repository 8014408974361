import { IBulkQuoteRecord } from 'Quotes/types/bulkQuoteRecord';
import capitalize from 'lodash/capitalize';
import { maskSSEUserEmail } from 'shared/utils/maskEmail';

const overflowVisibleStyle = { overflow: 'visible' };
const tableName = 'multi_quote_table';

const headers = [
  {
    Header: 'Multi Quote ID',
    accessor: (row: IBulkQuoteRecord) => capitalize(row.attributes.short_id),
    id: 'id',
    disableSortBy: true,
    disableFilters: true,
    tableName,
  },
  {
    style: overflowVisibleStyle,
    Header: 'Created',
    accessor: (row: IBulkQuoteRecord) => row.attributes.date_uploaded,
    id: 'created_at',
    disableSortBy: true,
    disableFilters: true,
    tableName,
  },
  {
    Header: 'Quoted by',
    accessor: (row: IBulkQuoteRecord) => maskSSEUserEmail(row.attributes.quoted_by),
    id: 'quoted_by',
    disableSortBy: true,
    disableFilters: true,
    tableName,
  },
  {
    Header: 'Reference',
    accessor: (row: IBulkQuoteRecord) => row.attributes.multiquote_label,
    id: 'multiquote_label',
    disableSortBy: true,
    disableFilters: true,
    tableName,
  },
  {
    Header: 'Completed entries',
    accessor: (row: IBulkQuoteRecord) => row.attributes.completed_entries,
    id: 'completed_entries',
    disableSortBy: true,
    disableFilters: true,
    tableName,
  },
];

export default headers;
