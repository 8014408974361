import React, { FC } from 'react';
import { useModal } from 'shared/hooks/useModal';
import { SaveOrderTemplateModal } from './SaveOrderTemplateModal';
import { IOrder } from 'Order/types/store';
import Icon from 'shared/components/atoms/Icon';
import styled from 'styled-components';
import { IOrderTemplate } from 'Order/types/orderTemplate';
import { setSelectedOrderTemplate } from 'Order/actions';
import { useDispatch } from 'react-redux';
import { useOrderTemplates } from 'Order/crud/orderTemplatesApi';
import { Tooltip } from 'shared/components/atoms/Tooltip';
export interface SaveOrderTemplateButtonProps {
  customerId: string;
  order: IOrder;
  orderId: string;
  setSelectedOrderTemplateAction?: typeof setSelectedOrderTemplate;
  useOrderTemplatesHook?: typeof useOrderTemplates;
}

export const SaveOrderTemplateButton: FC<SaveOrderTemplateButtonProps> = ({
  customerId,
  order,
  orderId,
  setSelectedOrderTemplateAction = setSelectedOrderTemplate,
  useOrderTemplatesHook = useOrderTemplates,
}) => {
  const { isOpen, openModal, closeModal } = useModal();
  const dispatch = useDispatch();

  const updateSelectedOrderTemplate = (createdOrderTemplate: IOrderTemplate) => {
    dispatch(setSelectedOrderTemplateAction(createdOrderTemplate));
  };

  return (
    <>
      {isOpen && (
        <SaveOrderTemplateModal
          customerId={customerId}
          order={order}
          onClose={closeModal}
          orderId={orderId}
          onSuccess={updateSelectedOrderTemplate}
          useOrderTemplatesHook={useOrderTemplatesHook}
        />
      )}

      <SaveTemplateButton onClick={openModal}>
        <Icon name="bookmark_add" size="large" />
        <SaveTemplateText>Save new order template</SaveTemplateText>
        <Tooltip text="Only the Billing & Contact details will be saved to the Order Template" />
      </SaveTemplateButton>
    </>
  );
};

const SaveTemplateButton = styled.button`
  color: ${(props) => props.theme.colours.primaryB1};
  display: flex;
  align-items: center;
  column-gap: 0.1rem;
`;

const SaveTemplateText = styled.strong`
  padding-right: 10px;
`;
