import React, { FunctionComponent } from 'react';
import StyledFormHeader from '../FormHeader';
import { IOrderMetaForLocation } from '../../../../../../types/store';
import { IPostcodeSiteBase } from '../../../../../../../shared/types/postcodeResults';
import TextInput from '../../../../../../../shared/components/atoms/TextInput';
import { Role } from '../../../../../../../User/types/role';
import { IAppState } from '../../../../../../../reducers';
import { connect } from 'react-redux';
import { IPriceData } from 'Quotes/types/store';
import AddressTypeBadge, { AddressType } from 'shared/components/atoms/AddressTypeBadge';
import { isSelectedPriceSSEOnNet } from 'Order/OrderBuilder/shared/utils/isSelectedPriceSSEOnNet';
import OnNetAddressSelection from '../OnNetAddressSelection';
import { IUpdateOnNetAddress } from 'Order/types/actions';
import { formatAddressLine } from 'Order/OrderBuilder/shared/utils/formatAddressLine';

interface IShowOnNetAddress {
  className?: string;
  identifierPrefix: string;
  fullQuoteAddress: IOrderMetaForLocation;
  locationFullAddress: IPostcodeSiteBase;
  userRoles: Role[];
  selectedPrice: IPriceData;
  isAPIOnNet: boolean;
  addressOnChange(attributes: IUpdateOnNetAddress['payload']['attributes']): void;
  fieldOnChange(fieldName: keyof IPostcodeSiteBase, fieldValue: string): void;
}

export const ShowOnNetAddress: FunctionComponent<React.PropsWithChildren<IShowOnNetAddress>> = ({
  identifierPrefix,
  fullQuoteAddress,
  fieldOnChange,
  locationFullAddress,
  userRoles,
  selectedPrice,
  isAPIOnNet,
  addressOnChange,
}) => {
  return (
    <>
      <StyledFormHeader title="Address *" />

      {isAPIOnNet && (
        <OnNetAddressSelection
          identifierPrefix={identifierPrefix}
          saveOnNetAddressSelection={addressOnChange}
          orderMeta={fullQuoteAddress}
        />
      )}

      {!isAPIOnNet && (
        <div className={`${identifierPrefix} on_net_address_field`}>
          {formatAddressLine(userRoles, fullQuoteAddress)}
        </div>
      )}

      <div className="mt-2 mb-2">
        <AddressTypeBadge
          addressType={
            isSelectedPriceSSEOnNet(selectedPrice, identifierPrefix)
              ? AddressType.ON_NET_SSE
              : AddressType.ON_NET_3RD_PARTY
          }
        />
      </div>

      <div className="row">
        <div className="col">
          <TextInput
            fieldName="buildingNumber"
            inputIdentifierPrefix={identifierPrefix}
            labelText="Building number"
            maxLength={50}
            onChange={(e) => fieldOnChange('building_number', e.target.value)}
            optional={true}
            value={locationFullAddress.building_number}
          />
        </div>
        <div className="col">
          <TextInput
            fieldName="buildingName"
            inputIdentifierPrefix={identifierPrefix}
            labelText="Building name"
            maxLength={50}
            onChange={(e) => fieldOnChange('building_name', e.target.value)}
            optional={true}
            value={locationFullAddress.building_name}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  user: {
    userData: { roles },
  },
}: IAppState) => ({ userRoles: roles });

export default connect(mapStateToProps)(ShowOnNetAddress);
