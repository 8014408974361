import React, { FC, useEffect, useMemo, useState } from 'react';
import { EditPreferences } from 'Admin/preferences/SupplierPreferences/EditPreferences';
import { ReadOnlyPreferences } from 'Admin/preferences/SupplierPreferences/ReadOnlyPreferences';
import { useSelector } from 'react-redux';
import { selectNameOfSelectedCompany, selectSelectedCompanyId } from 'User/selectors';
import getCustomer from 'Admin/crud/getCustomer';
import Alert from 'shared/components/atoms/Alert';
import { Supplier } from 'Quotes/types/supplier';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import { pages, useTrackPage } from 'shared/utils/trackPage';

enum FetchCustomerStatus {
  BEFORE_FETCHING = 'BEFORE_FETCHING',
  FETCHING_COMPLETE = 'FETCHING_COMPLETE',
}

// These are the suppliers from Supplier object that shouldn't be editable so users can never exclude Neos.
const excludedSuppliers = ['NONE', 'ONAT', 'NNAT'];

export interface SupplierList {
  key: string;
  value: Supplier;
  status: boolean;
}

const INTRO_TEXT = (companyName: string) => {
  return (
    <>
      <p>These preferences control which suppliers are shown when quoting for {companyName}.</p>
      <p>
        This change will not affect any current quotes that include the deactivated supplier and includes any edits of
        existing quotes. Only new quotes will be affected. The deactivated supplier can still be ordered on any valid
        quotes.
      </p>
      <p>
        Note that results from Neos Networks are always shown, but the more suppliers chosen will provide a broader
        range of quotes.
      </p>
    </>
  );
};

const ERROR_MESSAGE = 'Error in retrieving customer preference';

export const SupplierPreferences: FC = () => {
  useTrackPage(pages.customerPreferences);

  const companyId = useSelector(selectSelectedCompanyId);
  const companyName = useSelector(selectNameOfSelectedCompany);
  const introText = useMemo(() => INTRO_TEXT(companyName), [companyName]);

  const [isReadOnly, setReadOnly] = useState(true);
  const [isSaved, setSaved] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [fetchCustomerStatus, setFetchCustomerStatus] = useState(FetchCustomerStatus.BEFORE_FETCHING);
  const [displaySupplierList, setDisplaySupplierList] = useState<SupplierList[]>();

  const updatePageStatus = (readOnly: boolean, saved: boolean) => {
    setReadOnly(readOnly);
    setSaved(saved);
  };

  useEffect(() => {
    (async () => {
      setFetchCustomerStatus(FetchCustomerStatus.BEFORE_FETCHING);
      setReadOnly(true);
      setFetchError(false);
      setSaved(false);
      setDisplaySupplierList(undefined);

      try {
        const response = await getCustomer(companyId);
        const responseAllowedSuppliers = response.data.attributes.allowed_suppliers;

        // Loop through local Supplier object and check if each supplier is present in the API response to set the
        // status to true or false.
        const filteredAndCombinedSupplierList = Object.keys(Supplier)
          .map((key) => {
            return {
              key: key,
              value: Supplier[key as keyof typeof Supplier],
              status:
                // true = supplier in API response | false = supplier not in API response.
                typeof responseAllowedSuppliers.find(
                  (supplier: Supplier) => supplier === Supplier[key as keyof typeof Supplier]
                ) !== 'undefined',
            };
          })
          .filter((supplier) => !excludedSuppliers.includes(supplier.key));

        setDisplaySupplierList(filteredAndCombinedSupplierList);
      } catch (error) {
        setFetchError(true);
      } finally {
        setFetchCustomerStatus(FetchCustomerStatus.FETCHING_COMPLETE);
      }
    })();
  }, [companyId]);

  return (
    <div>
      {fetchCustomerStatus === FetchCustomerStatus.BEFORE_FETCHING && (
        <SpinnerWithText text="Retrieving preferences" size="large" />
      )}

      {fetchError && <Alert>{ERROR_MESSAGE}</Alert>}
      {fetchCustomerStatus === FetchCustomerStatus.FETCHING_COMPLETE && !fetchError
        ? isReadOnly
          ? displaySupplierList && (
              <ReadOnlyPreferences
                introText={introText}
                suppliers={displaySupplierList}
                updatePageStatus={updatePageStatus}
                saved={isSaved}
              />
            )
          : displaySupplierList && (
              <EditPreferences
                introText={introText}
                suppliers={displaySupplierList}
                updatePageStatus={updatePageStatus}
                updateSuppliersState={setDisplaySupplierList}
              />
            )
        : ''}
    </div>
  );
};
