import Alert from '../../../../shared/components/atoms/Alert';
import React from 'react';
import { PUBLISH_FAILURE_TEXT } from '../InternalQuotePanel';
import styled from 'styled-components';

const ErrorInUpdatesAlert = styled.div`
  margin-top: 20px;

  p:last-child {
    margin-bottom: 0;
  }
`;

export const ErrorFetchingPricingAlert = ({
  fetchingPriceErrorMessage,
}: {
  fetchingPriceErrorMessage: string[] | undefined;
}) => (
  <ErrorInUpdatesAlert>
    {Array.isArray(fetchingPriceErrorMessage) ? (
      <Alert>{fetchingPriceErrorMessage[0]}</Alert>
    ) : (
      <Alert>
        An error occurred whilst trying to get price. Your changes may not have been saved. Please try again later. If
        the problem persists, please contact your Account Manager.
      </Alert>
    )}
  </ErrorInUpdatesAlert>
);

export const ErrorUpdatingQuoteAlert = () => (
  <ErrorInUpdatesAlert>
    <Alert>
      An error occurred whilst trying to save your quote. Your changes may not have been saved. Please try again later.
      If the problem persists, please contact your Account Manager.
    </Alert>
  </ErrorInUpdatesAlert>
);
export const PublishingErrorAlert = () => <Alert className="mb-0 mt-2">{PUBLISH_FAILURE_TEXT}</Alert>;
export const CreateOrderErrorAlert = () => (
  <div>
    <Alert>
      An error occurred whilst trying to create an order. Please try again later. If the problem persists, please
      contact your Account Manager.
    </Alert>
  </div>
);
export const AdjustQuoteErrorAlert = ({ errors }: { errors: string[] | undefined }) => (
  <ErrorInUpdatesAlert className="mt-0">
    <Alert>
      {errors?.map((error, i) => (
        <p key={i}>{error}</p>
      ))}
    </Alert>
  </ErrorInUpdatesAlert>
);
