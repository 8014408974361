import { IQuote, ProviderName } from '../../types/store';
import { ProductType } from '../../types/productTypes';
import { IPAddressAllocation } from '../components/Configure/DIAConfig/types/diaIPAllocation';
import hasFeatureEnabled, { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { isFTTXConnection } from 'Quotes/types/connectionType';

export const isComplete = (quote: IQuote): boolean => {
  const { bandwidth, bearer, productType, location, connectionType, chosen_bandwidths } = quote;
  const baseCompletion =
    (!!bandwidth || chosen_bandwidths.length > 0) &&
    (!!bearer || isFTTXConnection(connectionType)) &&
    isTermSelectionComplete(quote);

  switch (productType) {
    case ProductType.DIA: {
      if (!featureFlag.isEnabled(Feature.newMDIAUI) || !!featureFlag.getAttribute(Feature.newMDIAUI, 'interimFix'))
        return baseCompletion && !!quote.location.aEnd.ip.selectedId;

      return (
        baseCompletion &&
        isDIAConfigurationComplete(
          quote.location.aEnd.is_managed_dia,
          quote.location.aEnd.dia_ip_allocation!,
          quote.location.aEnd.ip.selectedId
        )
      );
    }
    case ProductType.NNI2CCT: {
      if (location.aEnd.cloudConnect.name === ProviderName.AWS) {
        return (
          (!!bandwidth || chosen_bandwidths.length > 0) &&
          isTermSelectionComplete(quote) &&
          location.aEnd.cloudConnect.diversified !== null
        );
      }
      return (!!bandwidth || chosen_bandwidths.length > 0) && isTermSelectionComplete(quote);
    }
    case ProductType.P2CCT: {
      if (location.aEnd.cloudConnect.name === ProviderName.AWS) {
        return baseCompletion && location.aEnd.cloudConnect.diversified !== null;
      }
      return baseCompletion;
    }

    default:
      return baseCompletion;
  }
};

/**
 * DIA configuration is completed if:
 * - has a IP allocation
 * - has IP addresses selected (if allocation is public)
 *
 * MDIA configuration is completed if:
 * - has IP addresses selected
 * @param is_managed_dia
 * @param dia_ip_allocation
 * @param selected_ip
 * @returns
 */
function isDIAConfigurationComplete(
  is_managed_dia: boolean,
  dia_ip_allocation: IPAddressAllocation,
  selected_ip?: string | null
) {
  if (!is_managed_dia) {
    return (
      dia_ip_allocation !== null &&
      ([IPAddressAllocation.PUBLIC_ALLOCATION_ONLY, IPAddressAllocation.WAN_31_PUBLIC_ALLOCATION].includes(
        dia_ip_allocation!
      )
        ? !!selected_ip
        : true)
    );
  } else {
    return !!selected_ip;
  }
}

const isTermSelectionComplete = (quote: IQuote): boolean => {
  if (hasFeatureEnabled(Feature.multiTermQuote)) {
    return !!quote.chosen_term_lengths_in_months && quote.chosen_term_lengths_in_months.length > 0;
  }
  return !!quote.contractTerm;
};
