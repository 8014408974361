import { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { doRequest, Methods } from 'Request';
import { PromiseBasedFileReader } from 'Quotes/utils/PromiseBasedFileReader';
import { has } from 'lodash';

type UploadBody = {
  data: {
    attributes: {
      filename: string;
      base_64_csv_data: string;
    };
    type: 'pricing_request';
  };
};

export type UploadError = {
  detail: string;
  source: {
    row: number;
    parameter?: string;
  };
};

export const isUploadErrorWithRows = (errors: unknown[]): errors is UploadError[] => {
  if (!Array.isArray(errors)) {
    return false;
  }

  const item = errors[0];

  return has(item, 'detail') && has(item, 'source.row');
};

export const useDownloadCSV = (url: string, filename: string) => {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (downloading) {
      setError(false);

      (async () => {
        try {
          const result = await doRequest({
            path: url,
          });
          const blob = new Blob([result], {
            type: 'data:text/csv;charset=utf-8,',
          });
          saveAs(blob, filename);
        } catch (e) {
          setError(true);
        } finally {
          setDownloading(false);
        }
      })();
    }
  }, [downloading, url, filename]);

  return {
    downloading,
    error,
    getData: () => setDownloading(true),
  };
};

export const useUploadCSV = (url: string) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState<UploadError[] | unknown[]>([]);
  const [file, setFile] = useState<null | File>(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!file) return;

    setError(false);

    (async () => {
      try {
        const fileReader = new PromiseBasedFileReader();
        const fileContents = await fileReader.read(file);
        const body: UploadBody = {
          data: {
            attributes: {
              filename: file.name,
              base_64_csv_data: btoa(fileContents),
            },
            type: 'pricing_request',
          },
        };
        await doRequest({
          body,
          method: Methods.POST,
          path: url,
        });

        setSuccess(true);
      } catch (e: any) {
        setError(true);
        setErrors(e.response?.data?.errors);
      } finally {
        setUploading(false);
        setFile(null);
      }
    })();
  }, [file, url]);

  return {
    error,
    errors,
    success,
    uploading,
    uploadData: (files: FileList) => {
      if (files && files[0]) {
        setUploading(true);
        setFile(files[0]);
      }
    },
  };
};
