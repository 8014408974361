import { doRequest, Methods } from '../../../Request';

const cancelOrder = (orderId: string) => {
  return doRequest({
    method: Methods.POST,
    path: `/orders/${orderId}/cancel`,
  });
};

export default cancelOrder;
