import { noop, Omit } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import Modal, { IModalProps } from 'shared/components/molecules/Modal';
import { useOrderTemplates } from 'Order/crud/orderTemplatesApi';

export interface IClearDefaultOrderTemplateModalProps extends Omit<IModalProps, 'title' | 'children'> {
  customerId: string;
  useOrderTemplatesHook?: typeof useOrderTemplates;
  onClose?: () => void;
  onSuccess?: () => void;
}

export const ClearDefaultOrderTemplateModal = ({
  customerId,
  useOrderTemplatesHook = useOrderTemplates,
  onClose = noop,
  onSuccess = noop,
  ...props
}: IClearDefaultOrderTemplateModalProps) => {
  const orderTemplates = useOrderTemplatesHook(customerId);

  const deleteAction = () =>
    orderTemplates
      .unsetDefault()
      .then(() => {
        toast.success('Orders will no longer be automatically populated');
        onSuccess();
        onClose();
      })
      .catch(() => {
        toast.error('Failed to unset the default order template');
      });

  return (
    <Modal
      {...props}
      title="Clear default order template"
      onConfirm={deleteAction}
      disableActions={orderTemplates.isLoading}
      onClose={onClose}
    >
      <p>Are you sure you want to clear your default order template?</p>
      <p>After clearing, this template will no longer auto-fill your orders.</p>
      <p>
        <em>Please note, the template will not be deleted and can still be manually selected at the order stage.</em>
      </p>
    </Modal>
  );
};
