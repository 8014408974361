import { Feature } from '../types';
import { featureFlags } from '../featureFlags';
import { getEnvironment, supportsOverridingFeatureFlags } from 'FeatureFlags/utils/hasFeatureEnabled';

const getAttributeForFeature = (feature: Feature, attribute: string, defaultValue?: string) => {
  const environment = getEnvironment();
  const attributes = featureFlags[feature].environments[environment].attributes || {};

  if (supportsOverridingFeatureFlags(environment)) {
    const storedValue = loadAttributesFromLocalStorage(feature);
    return storedValue[attribute] ?? attributes[attribute] ?? defaultValue;
  }

  return attributes[attribute] ?? defaultValue;
};

export function attributeStorageKey(feature: Feature) {
  return `${feature}-attributes`;
}

export const loadAttributesFromLocalStorage = (feature: Feature): Record<string, any> => {
  const state = localStorage.getItem(attributeStorageKey(feature));
  return (state && JSON.parse(state)) || {};
};

export default getAttributeForFeature;
