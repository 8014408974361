import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import TableNames from '../../../../Tables/types/tableNames';
import { setTablePageSize } from 'Tables/actions';

interface IServerPaginationBar {
  paging: IServerSidePaging;
  className?: string;
  defaultPageSize?: number;
  storeTableName?: TableNames;
}

const ServerSidePaginationBar: FunctionComponent<React.PropsWithChildren<IServerPaginationBar>> = ({
  paging: {
    nextPage,
    isLoading,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    pageSize,
    firstResult,
    lastResult,
    totalResults,
  },
  storeTableName,
  className,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={className} data-testid="paging-record-count">
      {firstResult ? (
        <>
          <span>{`${firstResult}-${lastResult}`}</span>
          {!!totalResults && <span>{` of ${totalResults}`}</span>}
        </>
      ) : (
        <span>No results</span>
      )}

      <button
        className="ml-5"
        onClick={() => canPreviousPage && previousPage()}
        disabled={!canPreviousPage || isLoading}
        id="go-to-previous-page-btn"
      >
        <span className="material-icons">keyboard_arrow_left</span>
      </button>
      <button onClick={() => canNextPage && nextPage()} disabled={!canNextPage || isLoading} id="go-to-next-page-btn">
        <span className="material-icons">keyboard_arrow_right</span>
      </button>
      <select
        disabled={isLoading}
        value={pageSize}
        onChange={(e) => {
          setPageSize && setPageSize(Number(e.target.value));
          if (storeTableName) {
            dispatch(setTablePageSize(storeTableName, Number(e.target.value)));
          }
        }}
        id="select-page-size"
      >
        {[10, 20, 30, 40, 50].map((pageSizeOption) => (
          <option key={pageSizeOption} value={pageSizeOption}>
            Show {pageSizeOption}
          </option>
        ))}
      </select>
    </div>
  );
};

const StyledPagination = styled(ServerSidePaginationBar)`
  padding: 0.5rem;
  font-size: 1rem;

  button {
    padding: 0.5rem;
  }

  button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .material-icons {
    height: 31px;
    vertical-align: middle;
    position: relative;
    top: 2px;
  }

  select {
    margin-left: 1em;
  }
`;

export default StyledPagination;
