import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { flow } from 'lodash';
import Alert from 'shared/components/atoms/Alert';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import StyledEmptyQuotesList from './EmptyQuotesList';
import { IAppState } from 'reducers';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import { SortDirection, useTablePaging } from 'shared/hooks/useTablePaging';
import { IQuoteListItem, IQuotesResponse } from 'Quotes/types/quoteRecord';
import {
  transformAccessTypeFilter,
  transformCreatedByFilter,
  transformDateAtFilter,
  transformIdFilter,
  transformNeosAccessTypeFilter,
} from 'shared/utils/shared-quote-order-table-headers';
import QuotesTable from 'Quotes/QuotesList/ShowSingleQuotes/QuotesTable';

interface IShowSingleQuotes {
  selectedCompanyId: string;
}

export const ERROR_MESSAGE = 'Error in fetching quotes';

function transformQuoteDataToListItems(response: IQuotesResponse): IQuoteListItem[] {
  return response.data.map((quote) => {
    return {
      ...quote.attributes,
      id: quote.id,
    };
  });
}

const ShowSingleQuotes: FunctionComponent<React.PropsWithChildren<IShowSingleQuotes>> = ({ selectedCompanyId }) => {
  useTrackPage(pages.quotes);
  const paging = useTablePaging({
    baseUrl: `/customers/${selectedCompanyId}/quotes`,
    dataTransform: transformQuoteDataToListItems,
    filterTransform: flow([
      transformAccessTypeFilter,
      transformNeosAccessTypeFilter,
      transformDateAtFilter,
      transformIdFilter,
      transformCreatedByFilter,
    ]),
    defaultSort: {
      key: 'updated_at',
      direction: SortDirection.DESCENDING,
    },
  });

  if (paging.fetchAttempts === 1 && !paging.isLoading && paging.error) {
    return <Alert>{ERROR_MESSAGE}</Alert>;
  }

  if (paging.isLoading && paging.fetchAttempts === 0) {
    return <SpinnerWithText text="Retrieving quotes" size="large" />;
  }

  if (paging.fetchAttempts === 1 && !paging.isLoading && paging.results.length === 0) {
    return <StyledEmptyQuotesList />;
  }

  return <QuotesTable paging={paging} />;
};

const mapStateToProps = (state: IAppState) => ({
  selectedCompanyId: state.user.companyData.selectedCompanyId!,
});

export default connect(mapStateToProps)(ShowSingleQuotes);
