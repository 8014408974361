import { ISummaryRow } from 'shared/components/molecules/QuoteAccordionHeader';
import {
  concatenateOnNetAddress,
  concatenateOpenReachAddress,
  concatenatePAFAddress,
  concatenateShadowVlan,
} from 'shared/utils/addresses/helperFunctions';
import { IAEndLocation, ILocation, ProviderName, QuoteOrigin } from 'Quotes/types/store';
import { ProductType } from 'Quotes/types/productTypes';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { isOnNetSite } from 'shared/types/onNetSite';
import { isValidNNISelection } from './accordionPanel';

interface AEndLocation {
  location: IAEndLocation;
  isNNAT: boolean;
}
interface BEndLocation {
  location: ILocation;
  isNNAT: boolean;
}

export const locationSummary = (
  aEnd: AEndLocation,
  bEnd: BEndLocation,
  productType: ProductType | null | undefined,
  meta: IMeta,
  nniLabel: string,
  quoteOrigin: QuoteOrigin
): ISummaryRow[] | null => {
  const aEndAddress = getAddressInfo(aEnd.location, meta.a_end_address_not_listed || false);
  const bEndAddress = getAddressInfo(bEnd.location, meta.b_end_address_not_listed || false);

  function buildALKQualifierAndDistrictCode(end: AEndLocation | BEndLocation) {
    const { onatAddress, openreachAddress } = end.location;
    const hasNNAT = onatAddress && end.isNNAT;
    return {
      alk: hasNNAT ? null : openreachAddress?.attributes?.alk,
      qualifier: hasNNAT ? null : openreachAddress?.attributes?.qualifier,
      districtCode: hasNNAT ? null : openreachAddress?.attributes?.css_district_code,
    };
  }

  switch (productType) {
    case ProductType.P2P:
    case ProductType.OpticalP2P: {
      if (!!aEnd.location.dataCentreReference && (!!bEnd.location.dataCentreReference || !!bEnd.location.postcode)) {
        return [
          {
            topLine: aEndAddress || aEnd.location.dataCentreReference,
            bottomLine: null,
          },
          {
            topLine: bEnd.location.postcode.toUpperCase(),
            bottomLine: bEndAddress || bEnd.location.dataCentreReference,
          },
        ];
      } else if (aEnd.location.postcode !== '' && bEnd.location.postcode !== '') {
        return [
          {
            bottomLine:
              quoteOrigin === QuoteOrigin.API
                ? aEnd.location.postcode.toUpperCase()
                : aEnd.location.onatAddress && aEnd.isNNAT
                ? aEnd.location.onatAddress.full_address
                : aEndAddress,
            topLine: aEnd.location.postcode.toUpperCase(),
            ...buildALKQualifierAndDistrictCode(aEnd),
          },
          {
            bottomLine:
              quoteOrigin === QuoteOrigin.API
                ? bEnd.location.postcode.toUpperCase()
                : bEnd.location.onatAddress && bEnd.isNNAT
                ? bEnd.location.onatAddress.full_address
                : bEndAddress,
            topLine: bEnd.location.postcode.toUpperCase(),
            ...buildALKQualifierAndDistrictCode(bEnd),
          },
        ];
      }
      break;
    }

    case ProductType.P2NNI: {
      const shadowRef = concatenateShadowVlan(meta, aEnd.location.nni);

      if (bEnd.location.postcode !== '' && isValidNNISelection(aEnd.location.nni)) {
        return [
          {
            bottomLine:
              quoteOrigin === QuoteOrigin.API
                ? bEnd.location.postcode.toUpperCase()
                : bEnd.location.onatAddress && bEnd.isNNAT
                ? bEnd.location.onatAddress.full_address
                : bEndAddress,
            topLine: bEnd.location.postcode.toUpperCase(),
            ...buildALKQualifierAndDistrictCode(bEnd),
          },
          {
            bottomLine: shadowRef ? `Shadow VLAN: ${shadowRef}` : '',
            topLine: `NNI: ${nniLabel}`,
          },
        ];
      }
      break;
    }

    case ProductType.DIA: {
      if (aEnd.location.postcode !== '') {
        return [
          {
            bottomLine:
              quoteOrigin === QuoteOrigin.API
                ? aEnd.location.postcode.toUpperCase()
                : aEnd.location.onatAddress && aEnd.isNNAT
                ? aEnd.location.onatAddress.full_address
                : aEndAddress,
            topLine: aEnd.location.postcode.toUpperCase(),
            ...buildALKQualifierAndDistrictCode(aEnd),
          },
        ];
      }

      break;
    }

    case ProductType.P2CCT: {
      if (aEnd.location.postcode !== '' && aEnd.location.cloudConnect.name !== ProviderName.NOT_DEFINED) {
        return [
          {
            bottomLine: `Cloud Service: ${aEnd.location.cloudConnect.name}${
              aEnd.location.cloudConnect.supportedBandwidths.length > 0
                ? `, maximum bandwidth: ${aEnd.location.cloudConnect.supportedBandwidths.slice(-1)[0]} Mbps`
                : ''
            }`,
            topLine:
              quoteOrigin === QuoteOrigin.API
                ? aEnd.location.postcode.toUpperCase()
                : aEndAddress !== null && aEndAddress !== undefined && aEndAddress !== ''
                ? aEndAddress
                : aEnd.location.postcode,
          },
        ];
      }
      break;
    }

    case ProductType.NNI2CCT: {
      const shadowRef = concatenateShadowVlan(meta, aEnd.location.nni);

      if (isValidNNISelection(aEnd.location.nni) && aEnd.location.cloudConnect.name !== ProviderName.NOT_DEFINED) {
        return [
          {
            bottomLine: `Cloud Service: ${aEnd.location.cloudConnect.name}${
              aEnd.location.cloudConnect.supportedBandwidths.length > 0
                ? `, maximum bandwidth: ${aEnd.location.cloudConnect.supportedBandwidths.slice(-1)[0]} Mbps`
                : ''
            }`,
            topLine: `NNI: ${nniLabel}${shadowRef ? ` (Shadow VLAN: ${shadowRef})` : ''}`,
          },
        ];
      }
      break;
    }

    default:
      return null;
  }

  return null;
};

export const getAddressInfo = (
  location: IAEndLocation | ILocation,
  addressNotListed: boolean,
  includePostcode = true
): string | null => {
  if (addressNotListed) {
    return 'Address not listed';
  } else if (location.openreachAddress !== null) {
    return concatenateOpenReachAddress(location.openreachAddress.attributes);
  } else if (location.fullAddress !== null) {
    if (isOnNetSite(location.fullAddress)) {
      return concatenateOnNetAddress(location.fullAddress!.attributes);
    } else {
      return concatenatePAFAddress(location.fullAddress!.attributes, includePostcode);
    }
  } else {
    return null;
  }
};
