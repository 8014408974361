import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';
import { IAppState } from 'reducers';
import {
  setBandwidthAction,
  setBearerAction,
  setSupportedBandwidthsAction,
  toggleChosenBandwidth,
} from 'Quotes/actions';
import bearerToBandwidth, { bearerToBandwidthOptical } from '../bearerBandwidthMap';
import { BearerType, OpticalBearer } from '../Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import Alert from 'shared/components/atoms/Alert';
import getBandwidthValuesForCloudConnect from '../Bearer/utils/getBandwidthValuesForCloudConnect';
import { isFTTXConnection, OverallConnectionType } from 'Quotes/types/connectionType';
import { IBandwidthRecord } from 'Quotes/types/accessAvailabilityRecord';
import RadioButton from 'shared/components/molecules/RadioButton';
import { IAccessAvailability } from 'Quotes/types/accessAvailability';
import { INNI, IQuote, IQuotesState } from 'Quotes/types/store';
import { selectNNICapacity } from 'Quotes/selectors';
import getFttxBandwidthTooltipText from './utils/getFttxBandwidthTooltipText';
import { useBandwidthFetch } from './hook';
import { BandwidthsDropdown } from 'Quotes/shared/components/BandwidthsDropmenu';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import { PopularBandwidths } from 'Quotes/shared/components/PopularBandwidths/PopularBandwidths';
import CheckboxButton from 'shared/components/molecules/CheckboxButton';
import { MAX_SELECTABLE_BANDWIDTHS } from 'Quotes/reducer';
import { canToggle } from 'Quotes/QuoteBuilder/utils/canToggle';
import {
  getPopularOpticalBandwidths,
  POPULAR_BANDWIDTHS,
} from 'Quotes/QuoteBuilder/components/Configure/Bandwidth/bandwidthTypes';

interface IBandwidth {
  checkingAvailability: IQuotesState['checkingAvailability'];
  className?: string;
  bandwidth: string;
  bearer: BearerType;
  fttxBandwidths: IBandwidthRecord[] | undefined;
  productType: ProductType;
  connectionType: OverallConnectionType;
  supportedBandwidths: number[];
  provider: string;
  requiresAvailabilityCheck: boolean;
  quoteAvailability?: IAccessAvailability;
  nniCapacity: INNI['capacity'];
  chosenBandwidths: IQuote['chosen_bandwidths'];
  setBandwidth(bandwidth: string): void;
  toggleBandwidth(bandwidth: IQuote['chosen_bandwidths'][0]): void;
  setBearer(bearer?: BearerType): void;
  onSupportedBandwidthsFetchSuccess(bandwidths: number[]): void;
}

const isCloudConnect = (productType: ProductType): boolean =>
  productType === ProductType.P2CCT || productType === ProductType.NNI2CCT;

function getBandwidthForProductType(
  productType: ProductType,
  bearer: BearerType,
  supportedBandwidths: number[],
  nniCapacity: INNI['capacity']
) {
  switch (productType) {
    case ProductType.OpticalP2P:
      return bearerToBandwidthOptical[bearer as OpticalBearer];
    case ProductType.P2CCT:
      return getBandwidthValuesForCloudConnect(bearer, supportedBandwidths);
    case ProductType.NNI2CCT:
      return supportedBandwidths.map(String);
    case ProductType.P2NNI:
      return !bearer || nniCapacity.bandwidth === null
        ? undefined
        : bearerToBandwidth[bearer].filter((bw) => parseInt(bw, 10) <= nniCapacity.bandwidth!);
    default:
      return bearer ? bearerToBandwidth[bearer] : undefined;
  }
}

function usePopularBandwidths(productType: ProductType) {
  const [popularBandwidths, setPopularBandwidths] = useState(POPULAR_BANDWIDTHS);
  useEffect(() => {
    if (productType === ProductType.OpticalP2P) {
      setPopularBandwidths(getPopularOpticalBandwidths());
    } else {
      setPopularBandwidths(POPULAR_BANDWIDTHS);
    }
  }, [productType]);
  return popularBandwidths;
}

const getBandwidthValuesForProductType = (
  connectionType: OverallConnectionType,
  productType: ProductType,
  bearer: BearerType,
  supportedBandwidths: number[],
  nniCapacity: INNI['capacity']
): string[] | undefined => {
  if (isFTTXConnection(connectionType)) return [];
  return getBandwidthForProductType(productType, bearer, supportedBandwidths, nniCapacity);
};

export const Bandwidth: FunctionComponent<IBandwidth> = ({
  checkingAvailability,
  className,
  bandwidth,
  bearer,
  setBandwidth,
  setBearer,
  productType,
  fttxBandwidths,
  connectionType,
  onSupportedBandwidthsFetchSuccess,
  supportedBandwidths,
  provider,
  requiresAvailabilityCheck,
  quoteAvailability,
  nniCapacity,
  chosenBandwidths,
  toggleBandwidth,
}) => {
  const [initialisedBearer, setInitialisedBearer] = useState<BearerType | undefined>(undefined);

  const availableBandwidths = getBandwidthValuesForProductType(
    connectionType,
    productType,
    bearer,
    supportedBandwidths,
    nniCapacity
  );

  const popularBandwidths = usePopularBandwidths(productType);

  useEffect(() => {
    if (productType !== ProductType.P2NNI || isFTTXConnection(connectionType)) return;

    if (!!availableBandwidths && parseInt(bandwidth, 10) > nniCapacity.bandwidth!) {
      setBearer(undefined);
    }
  }, [availableBandwidths, productType, connectionType, bandwidth, nniCapacity.bandwidth, setBearer]);

  const selectBandwidth = (bw: string) => {
    if (!chosenBandwidths.includes(bw)) toggleBandwidth(bw);
  };

  useEffect(() => {
    if (initialisedBearer === bearer || productType !== ProductType.OpticalP2P) return;

    if (availableBandwidths) {
      selectBandwidth(availableBandwidths[0]);
      setInitialisedBearer(bearer);
    }
  }, [bearer, initialisedBearer, selectBandwidth, setInitialisedBearer]);

  const showBandwidthSize = !requiresAvailabilityCheck || (requiresAvailabilityCheck && quoteAvailability);

  const { error, loading } = useBandwidthFetch({
    productType: productType,
    provider: provider,
    onSuccess: onSupportedBandwidthsFetchSuccess,
  });

  if (availableBandwidths === undefined) return null;

  if (isCloudConnect(productType)) {
    if (loading) {
      return (
        <div className={`${className} mb-5`}>
          <SpinnerWithText text="loading available bandwidths" className="loading-spinner" size="large" />
        </div>
      );
    }
    if (error) {
      return (
        <Alert>
          Error while loading bandwidths. Please try again later. If the problem persists, please contact your Account
          Manager.
        </Alert>
      );
    }
  }

  const toggleBandwidthWithLimitation = (bw: string) => {
    if (canToggle(bw, chosenBandwidths, MAX_SELECTABLE_BANDWIDTHS)) toggleBandwidth(bw);
  };

  return (
    <div className={`${className} mb-5`}>
      <h5 className="bandwidth__pickBandwidthPrompt">Bandwidth size</h5>
      {checkingAvailability.inProgress && <small className="text-muted">Waiting on available access methods...</small>}
      {showBandwidthSize && (
        <>
          {['Ethernet', 'EoFTTP'].includes(connectionType) && <p>Select all bandwidths that apply.</p>}

          {connectionType === 'Ethernet' && (
            <PopularBandwidths
              popularBandwidths={popularBandwidths}
              bandwidths={availableBandwidths}
              chosenBandwidths={chosenBandwidths}
              onToggleBandwidth={toggleBandwidthWithLimitation}
            />
          )}

          <div className="bandwidth__radiogroup">
            {isFTTXConnection(connectionType) &&
              fttxBandwidths?.map((fttxBandwidth) => {
                if (connectionType === 'EoFTTC') {
                  return (
                    <RadioButton
                      inline
                      size="large"
                      className="bandwidth--radioButton"
                      description={fttxBandwidth.display_name}
                      id={`bandwidth--${fttxBandwidth.value}`}
                      key={`bandwidth--${fttxBandwidth.value}`}
                      onClick={() => {
                        setBandwidth(fttxBandwidth.value);
                      }}
                      status={bandwidth === fttxBandwidth.value ? 'selected' : 'notSelected'}
                    />
                  );
                } else {
                  return (
                    <CheckboxButton
                      inline
                      size="large"
                      className="mb-3"
                      description={fttxBandwidth.display_name}
                      id={`bandwidth--${fttxBandwidth.value}`}
                      key={`bandwidth--${fttxBandwidth.value}`}
                      onClick={() => {
                        toggleBandwidthWithLimitation(fttxBandwidth.value);
                      }}
                      status={chosenBandwidths.includes(fttxBandwidth.value) ? 'selected' : 'notSelected'}
                      tooltipText={
                        chosenBandwidths.includes(fttxBandwidth.value)
                          ? getFttxBandwidthTooltipText(fttxBandwidth.value, fttxBandwidths)
                          : undefined
                      }
                    />
                  );
                }
              })}
          </div>

          {connectionType === 'Ethernet' && productType !== ProductType.OpticalP2P && (
            <BandwidthsDropdown
              options={availableBandwidths.map((bandwidthValue) => ({
                label: formatBandwidth(bandwidthValue),
                value: bandwidthValue,
              }))}
              chosenBandwidths={chosenBandwidths}
              onToggleBandwidth={toggleBandwidthWithLimitation}
              connectionType={connectionType}
            />
          )}
        </>
      )}
    </div>
  );
};

const styledBandwidth = styled(Bandwidth)`
  .bandwidth__pickBandwidthPrompt {
    margin-top: 1rem;
    text-align: left;
    margin-bottom: 10px;
  }
  .bandwidth__radiogroup {
    display: flex;
    flex-wrap: wrap;
  }
  .bandwidth--radioButton {
    margin-top: 10px;
  }
  .bandwidth__placeholderForEmptyBearer {
    height: 100px;
  }

  .bandwidths-dropdown {
    max-width: 510px;
  }
`;

const mapStateToProps = (state: IAppState) => {
  return {
    bandwidth: state.quoteBuilder.quote.bandwidth,
    bearer: state.quoteBuilder.quote.bearer,
    productType: state.quoteBuilder.quote.productType,
    fttxBandwidths: state.quoteBuilder.quote.availability?.bandwidths,
    connectionType: state.quoteBuilder.quote.connectionType,
    supportedBandwidths: state.quoteBuilder.quote.location.aEnd.cloudConnect.supportedBandwidths,
    provider: state.quoteBuilder.quote.location.aEnd.cloudConnect.name,
    requiresAvailabilityCheck: state.quoteBuilder.quote.requiresAvailabilityCheck,
    quoteAvailability: state.quoteBuilder.quote.availability,
    checkingAvailability: state.quoteBuilder.checkingAvailability,
    nniCapacity: selectNNICapacity(state),
    chosenBandwidths: state.quoteBuilder.quote.chosen_bandwidths,
  };
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => {
  return {
    setBandwidth: (bandwidth: string) => dispatch(setBandwidthAction(bandwidth)),
    setBearer: (bearer?: BearerType) => dispatch(setBearerAction(bearer)),
    onSupportedBandwidthsFetchSuccess: (bandwidths: number[]) => dispatch(setSupportedBandwidthsAction(bandwidths)),
    toggleBandwidth: (bandwidth: IQuote['chosen_bandwidths'][0]) => dispatch(toggleChosenBandwidth(bandwidth)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(styledBandwidth);
