import React, { FC } from 'react';
import { selectSelectedCompanyId } from '../User/selectors';
import { useSelector } from 'react-redux';

const TEST_CUSTOMER = '4403';

// TODO: Find a way to let this work outside of a redux context in tests
export const AdminTool: FC<React.PropsWithChildren> = ({ children }) => {
  const companyId = useSelector(selectSelectedCompanyId);
  if (companyId !== TEST_CUSTOMER) return null;

  return <>{children}</>;
};
