import { Exchange, ExchangeRequestStatus, SecondaryCircuits } from 'Quotes/types/store';
import {
  P2PCircuitData,
  SecondaryCircuitStatus,
} from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import {
  getExchangeStatus,
  HandleSelectedCircuit,
  hasAEnd,
  hasBEnd,
  useExchanges,
} from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';

export function useP2PCircuitData(
  secondaryCircuits: SecondaryCircuits | undefined,
  handleSelectedCircuit: HandleSelectedCircuit,
  status: ExchangeRequestStatus
): P2PCircuitData {
  const aExchanges = useExchanges(
    secondaryCircuits?.aEndExchanges,
    secondaryCircuits?.selectedAEndId,
    (exchange: Exchange) => handleSelectedCircuit(getCircuitId(exchange, bExchanges.selected))
  );
  const bExchanges = useExchanges(
    secondaryCircuits?.bEndExchanges,
    secondaryCircuits?.selectedBEndId,
    (exchange: Exchange) => handleSelectedCircuit(getCircuitId(aExchanges.selected, exchange))
  );

  return {
    aEnd: aExchanges,
    bEnd: bExchanges,
    getSelectedCircuitId: () => getCircuitId(aExchanges.selected, bExchanges.selected),
    getCircuitId: getCircuitId,
    status: () => getExchangeStatus(status, bExchanges.exchanges, aExchanges.exchanges),
    canLoadPrices: () => !!aExchanges.selectedExchangeId && !!bExchanges.selectedExchangeId,
    isLoading: false,
    processors: [(secondaryCircuits) => hasAEnd(hasBEnd(secondaryCircuits))],
    type: 'P2P',
  };
}

function getCircuitId(aEnd: Exchange | undefined, bEnd: Exchange | undefined) {
  if (!aEnd || !bEnd) return undefined;
  return `datacentre-${aEnd.popId}_datacentre-${bEnd.popId}`;
}

// OpticalP2P doesn't support RO2
// However, since we can change product type from P2P to Optical P2P and back
// React throws an exception because the number of hooks changes between renders
// The correct way of fixing this is to use different components for different product types
// But in the meantime, we can fix it by just ensuring we call the same number of hooks for Optical P2P as P2P
export function useDummyOpticalP2PCircuitData(
  secondaryCircuits: SecondaryCircuits | undefined,
  handleSelectedCircuit: HandleSelectedCircuit
): P2PCircuitData {
  const aExchanges = useExchanges(
    secondaryCircuits?.aEndExchanges,
    secondaryCircuits?.selectedAEndId,
    (exchange: Exchange) => handleSelectedCircuit(getCircuitId(exchange, bExchanges.selected))
  );
  const bExchanges = useExchanges(
    secondaryCircuits?.bEndExchanges,
    secondaryCircuits?.selectedBEndId,
    (exchange: Exchange) => handleSelectedCircuit(getCircuitId(aExchanges.selected, exchange))
  );
  return {
    aEnd: aExchanges,
    bEnd: bExchanges,
    canLoadPrices: (): boolean => false,
    getCircuitId: (): string | undefined => undefined,
    getSelectedCircuitId: (): string | undefined => undefined,
    isLoading: false,
    processors: [],
    status: () => SecondaryCircuitStatus.SHOW_CHECK_NOW,
    type: 'P2P',
  };
}
