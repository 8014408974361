import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getJwt } from 'Auth/cognito';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${localStorage.API_URL_OVERRIDE ?? process.env.REACT_APP_SSE_API_URL}/`,
    credentials: 'same-origin',
    mode: 'cors',
    prepareHeaders: async (headers) => {
      const token = await getJwt();
      headers.set('Authorization', `Bearer ${token}`);
      headers.set('Pragma', 'no-cache');
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['Promotions', 'OrderTemplates', 'NNIRecords', 'DataCentres'],
});
