import { APINewNNIRequest, INewNNIRequest } from './location';

export const mapNewNNIRequestFromAPI = (item: APINewNNIRequest): INewNNIRequest => ({
  id: item.id,
  capacity: item.capacity,
  termLengthInYears: item.term_length_in_years,
  contactEmail: item.contact_email,
  popId: item.pop_id,
});

export const mapNewNNIRequestToAPI = (item: INewNNIRequest): APINewNNIRequest => ({
  id: item.id,
  capacity: item.capacity,
  term_length_in_years: item.termLengthInYears,
  contact_email: item.contactEmail,
  pop_id: item.popId,
});
