import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IEmptyOrdersList {
  className?: string;
}

const EmptyOrdersList: FunctionComponent<React.PropsWithChildren<IEmptyOrdersList>> = ({ className }) => (
  <p className={`${className} no-orders-info`}>It looks like you don&apos;t have any orders yet.</p>
);

const StyledEmptyOrdersList = styled(EmptyOrdersList)<IEmptyOrdersList>`
  color: ${(props) => props.theme.colours.secondary};
  font-family: ${(props) => props.theme.typography.fontFamilyBold};
  font-size: ${(props) => props.theme.typography.fontSizeH4};
`;

export default StyledEmptyOrdersList;
