import { Charges } from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/TillReceipt';
import Column from '../../../../shared/components/atoms/Column';
import React from 'react';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { getTCVMarginPercentage } from 'Quotes/QuoteBuilder/components/Price/components/TillReceiptInternal/TotalContractValueMarginPercentage';

export function TcvHeader({ charges }: { charges: Charges }) {
  return (
    <div className="row no-gutters">
      <Column defaultWidth={3}>
        <TcvMarginPercentage charges={charges} />
      </Column>
      <Column defaultWidth={4}>
        <TcvMarginAmount charges={charges} />
      </Column>
      <Column defaultWidth={5}>
        <TcvContractValue charges={charges} />
      </Column>
    </div>
  );
}

function TcvContractValue({ charges }: { charges: Charges }) {
  return (
    <>
      <span className="sub-heading pl-1 mr-2">Total contract value (TCV)</span>
      <span className="tcv-contract-value">{currencyFormatter.format(charges.totalContractValue ?? 0)}</span>
    </>
  );
}

function TcvMarginAmount(props: { charges: Charges }) {
  return (
    <>
      <span className="sub-heading mr-2">TCV margin amount</span>
      <span className="tcv-margin-amount">{currencyFormatter.format(props.charges.marginAmount ?? 0)}</span>
    </>
  );
}

function TcvMarginPercentage({ charges }: { charges: Charges }) {
  const tcvMarginPercentage = getTCVMarginPercentage(charges.totalContractValue, charges.marginAmount);

  return (
    <>
      <span className="sub-heading mr-2">TCV margin</span>
      <span className="tcv-margin-percentage">{tcvMarginPercentage ? `${tcvMarginPercentage}%` : '0%'}</span>
    </>
  );
}
