import React from 'react';
import QuoteBuilder from './QuoteBuilder';
import AuthoriseForRoles from 'Auth/AuthoriseForRoles';
import QuotesList from './QuotesList';
import ShowBulkQuotes from './QuotesList/ShowBulkQuotes';
import { ShowMultiQuotes } from './QuotesList/ShowMultiQuotes';
import ShowSingleQuotes from './QuotesList/ShowSingleQuotes';
import ShowAPIQuotes from './QuotesList/ShowAPIQuotes';
import QuoteById from './QuoteById';
import APIQuoteById from './APIQuoteById';
import BulkQuoteById from './QuotesList/components/BulkQuoteById';
import { MultiQuoteById } from './QuotesList/components/MultiQuoteById';
import * as QuoteRoutes from './Routes';
import { userPermissions } from 'shared/utils/permissions';
import { QuoteBuilderByLocation } from './QuoteBuilderByLocation';
import { ShowCerillionQuotes } from './QuotesList/ShowCerillionQuotes/ShowCerillionQuotes';
import { CerillionQuoteById } from './CerillionQuoteById/CerillionQuoteById';
import { Outlet, Route, Routes } from 'react-router-dom';

const QuoteRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route
        element={
          <QuotesList>
            <Outlet />
          </QuotesList>
        }
      >
        <Route index element={<ShowSingleQuotes />} />
        <Route
          path={QuoteRoutes.relativeBulkQuotes}
          element={
            <AuthoriseForRoles roles={userPermissions.viewEditBulkQuote}>
              <ShowBulkQuotes />
            </AuthoriseForRoles>
          }
        />
        <Route path={QuoteRoutes.relativeMultiQuotes} element={<ShowMultiQuotes />} />
        <Route path={QuoteRoutes.relativeCerillionQuotes} element={<ShowCerillionQuotes />} />
        <Route
          path={QuoteRoutes.relativeApiQuotes}
          element={
            <AuthoriseForRoles roles={userPermissions.viewEditAPIQuote}>
              <ShowAPIQuotes />
            </AuthoriseForRoles>
          }
        />
      </Route>
      <Route
        path={QuoteRoutes.relativeNewQuote}
        element={
          <AuthoriseForRoles roles={userPermissions.createEditQuote}>
            <QuoteBuilder />
          </AuthoriseForRoles>
        }
      />
      <Route
        path={QuoteRoutes.relativeNewQuoteByLocation}
        element={
          <AuthoriseForRoles roles={userPermissions.createEditQuote}>
            <QuoteBuilderByLocation />
          </AuthoriseForRoles>
        }
      />
      <Route path={`${QuoteRoutes.relativeQuoteList}/:quoteId`} element={<QuoteById />} />
      <Route
        path={`${QuoteRoutes.relativeQuoteList}/by_location/:quoteId`}
        element={
          <AuthoriseForRoles roles={userPermissions.createEditQuote}>
            <QuoteBuilderByLocation cloneExistingQuote={true} />
          </AuthoriseForRoles>
        }
      />
      <Route
        path={`${QuoteRoutes.relativeQuoteList}/api/:quoteId`}
        element={
          <AuthoriseForRoles roles={userPermissions.viewEditAPIQuote}>
            <APIQuoteById />
          </AuthoriseForRoles>
        }
      />
      <Route
        path={`${QuoteRoutes.relativeBulkQuotes}/:bulkQuoteId`}
        element={
          <AuthoriseForRoles roles={userPermissions.viewEditBulkQuote}>
            <BulkQuoteById />
          </AuthoriseForRoles>
        }
      />
      <Route path={`${QuoteRoutes.relativeMultiQuotes}/:multiQuoteId`} element={<MultiQuoteById />} />
      <Route path={`${QuoteRoutes.relativeCerillionQuotes}/:quoteId`} element={<CerillionQuoteById />} />
    </Routes>
  );
};

export default QuoteRouter;
