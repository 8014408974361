import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import Spinner from '../Spinner';

export enum ButtonMainStyle {
  Primary = 'primary',
  PrimaryOutline = 'primaryOutline',
  Secondary = 'secondary',
  SecondaryRectangular = 'secondaryRectangular',
  PrimaryRectangular = 'primaryRectangular',
  Link = 'link',
  LinkPrimary = 'linkPrimary',
  LinkSecondary = 'linkSecondary',
  DangerRectangular = 'dangerRectangular',
}

export interface IButtonProps {
  children?: ReactNode;
  id?: string;
  loading?: boolean;
  saving?: boolean;
  disabled?: boolean;
  className?: string;
  mainStyle?: ButtonMainStyle;
  subStyle?: 'outline';
  type?: 'button' | 'submit' | 'reset';
  onClick?(): void;
  'data-testid'?: string;
}

const LoadingOverlay = styled.div`
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  opacity: 0.5;
`;

const Button: FunctionComponent<React.PropsWithChildren<IButtonProps>> = ({
  children,
  className,
  disabled = false,
  id,
  loading,
  saving,
  onClick,
  type = 'button',
  mainStyle = 'primary',
  subStyle,
  ...rest
}) => {
  let content = children;

  if (loading) {
    return (
      <LoadingOverlay>
        <Spinner size="small" />
        loading...
      </LoadingOverlay>
    );
  } else if (saving) {
    content = 'Saving...';
  }
  return (
    <button
      data-testid={rest['data-testid']}
      className={cx(mainStyle, subStyle, className)}
      type={type}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      {content}
    </button>
  );
};

const StyledButton = styled(Button)`
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  float: none;
  font-family: ${(props) => props.theme.typography.fontFamilyBold};
  height: 35px;
  margin-bottom: 10px;
  min-height: 0;
  min-width: 35px;
  padding: 4px 20px 4px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    width: auto;
    margin-bottom: 0;
    margin-right: 10px;
  }

  &.primary {
    background-color: ${(props) => props.theme.colours.primaryC1};
    border-color: ${(props) => props.theme.colours.primaryC1};
    color: white;
    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) => props.theme.colours.primaryC1};
      border-color: ${(props) => props.theme.colours.primaryC1};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &.secondary {
    background-color: ${(props) => props.theme.colours.primaryB1};
    border-color: ${(props) => props.theme.colours.primaryB1};
    color: white;
    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) => props.theme.colours.primaryB1};
      border-color: ${(props) => props.theme.colours.primaryB1};
    }
  }

  &.secondary[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &.primaryRectangular {
    background-color: ${(props) => props.theme.colours.primaryC1};
    border-color: ${(props) => props.theme.colours.primaryC1};
    color: white;
    height: 44px;
    width: ${(props) => props.theme.button.width};
    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) => props.theme.colours.primaryC1};
      border-color: ${(props) => props.theme.colours.primaryC1};
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &.primaryOutline {
    background-color: white;
    border-color: ${(props) => props.theme.colours.primaryC1};
    color: ${(props) => props.theme.colours.primaryC1};
    height: 44px;
    width: ${(props) => props.theme.button.width};
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &.secondaryRectangular {
    background-color: ${(props) => props.theme.colours.primaryB1};
    border-color: ${(props) => props.theme.colours.primaryB1};
    color: white;
    height: 44px;
    width: ${(props) => props.theme.button.width};
    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) => props.theme.colours.primaryB1};
      border-color: ${(props) => props.theme.colours.primaryB1};
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &.dangerRectangular {
    background-color: ${(props) => props.theme.colours.secondaryA1};
    border-color: ${(props) => props.theme.colours.secondaryA1};
    color: white;
    height: 44px;
    width: ${(props) => props.theme.button.width};
    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) => props.theme.colours.secondaryA1};
      border-color: ${(props) => props.theme.colours.secondaryA1};
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &.outline {
    background-color: white;
    &:hover,
    &:focus,
    &:active {
      background-color: white;
    }
    &.primary {
      color: ${(props) => props.theme.colours.primaryC1};
      border-color: ${(props) => props.theme.colours.primaryC1};
    }
    &.primaryRectangular {
      color: ${(props) => props.theme.colours.primaryC1};
      border-color: ${(props) => props.theme.colours.primaryC1};
    }
    &.secondary {
      color: ${(props) => props.theme.colours.primaryB1};
      border-color: ${(props) => props.theme.colours.primaryB1};
    }
    &.secondaryRectangular {
      color: ${(props) => props.theme.colours.primaryB1};
      border-color: ${(props) => props.theme.colours.primaryB1};
    }
    &.dangerRectangular {
      color: ${(props) => props.theme.colours.secondaryA1};
      border-color: ${(props) => props.theme.colours.secondaryA1};
    }
  }

  &.link {
    padding: 0;
    margin-bottom: 0;
    min-width: 0;
    background-color: transparent;
    text-decoration: underline;
    color: $black;
    text-align: left;
    display: inline-block;
    text-align: unset;
    border: none;
    height: auto;
    vertical-align: unset;
  }

  &.linkPrimary {
    padding: 0;
    margin-bottom: 0;
    min-width: 0;
    background-color: transparent;
    text-decoration: underline;
    color: ${(props) => props.theme.colours.primaryC1};
    text-align: left;
    display: inline-block;
    text-align: unset;
    border: none;
    height: auto;
    vertical-align: unset;
  }

  &.linkSecondary {
    min-width: 0;
    font-size: 1.6rem;
    text-decoration: none;
    border: none;
    color: ${(props) => props.theme.colours.primaryB1};
    &:focus {
      outline: 0;
    }
  }

  &.linkSecondary[disabled] {
    color: ${(props) => props.theme.colours.primaryB1};
    cursor: default;
  }
`;

export default StyledButton;
