import React, { FC } from 'react';
import Column from 'shared/components/atoms/Column';
import { Charges } from '../TillReceipt/TillReceipt';
import { TotalContractValueMarginPercentage } from './TotalContractValueMarginPercentage';
import { combineCharges } from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/MultiCircuitTotals';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import styled from 'styled-components';

interface TCVMarginAndValuePanelProps {
  charges: Charges;
  secondaryCharges?: Charges;
}

export const TCVMarginAndValuePanel: FC<TCVMarginAndValuePanelProps> = ({ charges, secondaryCharges }) => {
  const combinedCharges = secondaryCharges ? combineCharges(charges, secondaryCharges) : charges;
  return (
    <div className="row no-gutters border-bottom mb-4">
      <Column defaultWidth={6} xlWidth={3} className="pr-3">
        <TotalContractValueMarginPercentage charges={combinedCharges} />
      </Column>
      <Column defaultWidth={6} xlWidth={4} className="pr-3">
        <TotalContractMargin charges={combinedCharges} />
      </Column>
      <Column defaultWidth={6} xlWidth={5}>
        <TotalContractValue charges={combinedCharges} />
      </Column>
    </div>
  );
};

const TotalContractMargin = ({ charges }: { charges: Charges }) => {
  return (
    <>
      <Title data-testid="total-contract-margin-title">Total Contract Margin</Title>
      <Figure data-testid="total-contract-margin-figure">{currencyFormatter.format(charges.marginAmount ?? 0)}</Figure>
    </>
  );
};

const TotalContractValue = ({ charges }: { charges: Charges }) => {
  return (
    <>
      <Title data-testid="total-contract-value-title">Total Contract Value (TCV)</Title>
      <Figure data-testid="total-contract-value-figure">
        {currencyFormatter.format(charges.totalContractValue ?? 0)}
      </Figure>
    </>
  );
};

export const Title = styled.div`
  color: ${(props) => props.theme.colours.grey70};
  margin-bottom: 0.25rem;
`;

export const Figure = styled.h4`
  font-family: ${(props) => props.theme.typography.fontFamilyBold};
  color: ${(props) => props.theme.colours.primaryA1};
`;
