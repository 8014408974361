import { IPriceData } from 'Quotes/types/store';
import { useTheme } from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { PricesSlider } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/PricesSlider';
import { PricesList } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/PricesList';
import { PricesToolbar } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/PricesToolbar';
import { ExportCSV } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/ExportCSV';
import { ProductType } from 'Quotes/types/productTypes';
import { useSelector } from 'react-redux';
import { generateMissingPrices } from 'Quotes/QuoteBuilder/components/Price/selectors';
import { IDummyPrice } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';

export function PricesDisplay({
  allPrices,
  filteredPrices,
  setSelectedPrice,
  selectedPriceId,
  readonly,
  resetPriceError,
  productType,
  quoteId,
  shortId,
}: {
  readonly: boolean;
  selectedPriceId: string;
  allPrices: IPriceData[];
  filteredPrices: IPriceData[];
  setSelectedPrice: (priceId: string, missingPrices?: IDummyPrice[]) => void;
  resetPriceError: () => void;
  productType: ProductType;
  quoteId: string | undefined;
  shortId: string | undefined;
}) {
  const [viewAs, setViewAs] = useState<'list' | 'slider'>('slider');

  const theme = useTheme();
  const slidesToShow = window.innerWidth <= parseInt(theme.breakpoints.M, 10) ? 3 : 4;

  const missingPrices = useSelector(generateMissingPrices);
  const prices = [...filteredPrices, ...missingPrices];

  const onPriceItemClick = useCallback((priceId: string) => setSelectedPrice(priceId, missingPrices), [
    missingPrices,
    setSelectedPrice,
  ]);

  useEffect(() => {
    if (!selectedPriceId && missingPrices.length > 0) {
      onPriceItemClick(missingPrices[0].id);
    }
  }, [missingPrices, onPriceItemClick, selectedPriceId]);

  return (
    <div data-testid="primary-prices-display">
      <PricesToolbar
        activeView={viewAs}
        onClick={setViewAs}
        readOnly={readonly}
        productType={productType}
        filteredPrices={filteredPrices}
        allPrices={allPrices}
      />

      <ExportCSV quoteId={quoteId} shortId={shortId} />

      {viewAs === 'slider' && (
        <PricesSlider
          prices={prices}
          selectedPriceId={selectedPriceId}
          readonly={readonly}
          slidesToShow={slidesToShow}
          resetPriceError={resetPriceError}
          onPriceItemClick={onPriceItemClick}
        />
      )}

      {viewAs === 'list' && (
        <PricesList
          isPriceSelected={(priceId: string) => selectedPriceId === priceId}
          data={prices}
          onPriceItemClick={(priceId: string) => setSelectedPrice(priceId, missingPrices)}
        />
      )}
    </div>
  );
}
