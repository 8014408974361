import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import FilterButton from '../shared/FilterButton';
import { IPriceData } from 'Quotes/types/store';
import { setFilteredPricedData, toggleContractTermFilter } from 'Quotes/actions';
import { selectPricing } from 'Quotes/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'shared/components/atoms/Checkbox';
import Column from 'shared/components/atoms/Column';
import { useOutsideClick } from 'shared/utils/customHooks';
import { DropdownMenu } from '../shared/DropdownMenu';
import { monthsToYears } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
interface IContractTermFilterProps {
  className?: string;
}
const UnstyledContractTermFilter: FC<React.PropsWithChildren<IContractTermFilterProps>> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const pricing = useSelector(selectPricing);
  const [uniqueContractTerms] = useState(
    [...new Set(pricing.allPrices.map((price: IPriceData) => price.term_length_in_months!))].sort(
      (a, b) => parseInt(a, 10) - parseInt(b, 10)
    )
  );

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <div className={`${className} position-relative`} ref={ref}>
      <FilterButton onClick={() => setIsOpen(!isOpen)} data-testid="contract-term-filter-label" isOpen={isOpen}>
        Contract term
      </FilterButton>
      {isOpen && (
        <DropdownMenu>
          <span>Contract term</span>
          <ul className="list-unstyled pt-3 mb-0">
            {uniqueContractTerms.map((contractTerm, i) => {
              if (!contractTerm) {
                return null;
              }

              return (
                <li className="row no-gutters" key={i}>
                  <Column lgWidth={7}>{monthsToYears(contractTerm)}</Column>
                  <Column lgWidth={5}>
                    <Checkbox
                      inputId={`price_contract_term_filter_${contractTerm}`}
                      onChange={() => {
                        dispatch(toggleContractTermFilter(contractTerm));
                        dispatch(setFilteredPricedData());
                      }}
                      value={pricing.contractTermFilterList.includes(contractTerm)}
                    />
                  </Column>
                </li>
              );
            })}
          </ul>
        </DropdownMenu>
      )}
    </div>
  );
};
export const ContractTermFilter = styled(UnstyledContractTermFilter)``;
