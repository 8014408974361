import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { checkMaintenanceMode } from './crud/checkMaintenanceMode';
import { AppActionTypes, maintenanceModeCheck as maintenanceModeCheckAction } from './actions';

export const MAINTENANCE_MODE_CHECK_INTERVAL = (() => {
  const EVERY_30_MINUTES = 18e5;
  const IMMEDIATELY = 1;
  const EVERY_15_SECONDS = 15000;

  switch (process.env.NODE_ENV) {
    case 'development':
      return EVERY_30_MINUTES;
    case 'test':
      return IMMEDIATELY;
    default:
      return EVERY_15_SECONDS;
  }
})();

export function* maintenanceModeCheck(): any {
  try {
    const isMaintenanceModeEnabled = yield call(checkMaintenanceMode);

    if (isMaintenanceModeEnabled && window.location.pathname !== '/maintenance') {
      window.location.assign('/maintenance');
    } else if (!isMaintenanceModeEnabled && window.location.pathname === '/maintenance') {
      window.location.assign('/');
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  yield delay(MAINTENANCE_MODE_CHECK_INTERVAL);
  yield put(maintenanceModeCheckAction());
}

export default function* rootSaga() {
  yield takeLatest(AppActionTypes.MAINTENANCE_MODE_CHECK, maintenanceModeCheck);

  if (process.env.NODE_ENV !== 'test') {
    yield put(maintenanceModeCheckAction());
  }
}
