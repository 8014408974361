import React, { FunctionComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import TextInput from 'shared/components/atoms/TextInput';
import { updateBillingAndContact } from 'Order/actions';
import { IBillingContactAndAddress } from 'Order/types/formFields';

interface IFormProps {
  billingContactAndAddress: IBillingContactAndAddress;
  onChange(updated: IBillingContactAndAddress): void;
}

const inputIdentifierPrefix = 'billingContactAddress_';

const Form: FunctionComponent<React.PropsWithChildren<IFormProps>> = ({ billingContactAndAddress, onChange }) => {
  const updated = (field: keyof IBillingContactAndAddress, value: string): IBillingContactAndAddress => ({
    ...billingContactAndAddress,
    [field]: value,
  });

  const onChangeWithUpdate = (field: keyof IBillingContactAndAddress, value: string) => onChange(updated(field, value));

  return (
    <div>
      <TextInput
        inputIdentifierPrefix={inputIdentifierPrefix}
        fieldName="po_number"
        maxLength={50}
        onChange={(e) => onChangeWithUpdate('purchaseOrderRef', e.target.value)}
        labelText="PO number / Ref *"
        value={billingContactAndAddress.purchaseOrderRef}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  onChange: (updated: IBillingContactAndAddress) => {
    dispatch(updateBillingAndContact(updated));
  },
});

export default connect(null, mapDispatchToProps)(Form);
