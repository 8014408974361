import React, { ReactNode, FunctionComponent, createRef, useEffect } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import cx from 'classnames';
import { useOutsideClick } from '../../../shared/utils/customHooks';

interface ILinkDropdownProps {
  theme: Partial<DefaultTheme>;
  classNames?: string[];
  className?: string;
  title: ReactNode;
  children: ReactNode[] | ReactNode;
  isActive?: boolean;
  filterItem?(search: string, index: number): boolean;
}

const LinkDropdown: FunctionComponent<React.PropsWithChildren<ILinkDropdownProps>> = ({
  className,
  title,
  children,
  isActive,
  classNames,
  filterItem,
}: ILinkDropdownProps) => {
  const inputRef = createRef<HTMLInputElement>();
  const dropdownRef = createRef<HTMLDivElement>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>('');
  const shouldToggle = Array.isArray(children) && children.length > 1;
  const shouldFilter = open && !!filterItem;
  const toggleDropdown = (): void => {
    setFilter('');
    if (shouldToggle) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const childrenAsArray: ReactNode[] = Array.isArray(children) ? children : [children];

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value.toLowerCase());
  };

  const filteredChildren: ReactNode[] = shouldFilter
    ? childrenAsArray.filter((_: ReactNode, index) => {
        return filterItem!(filter, index);
      })
    : childrenAsArray;

  const closeDropdown = (): void => setOpen(false);
  useOutsideClick(dropdownRef, closeDropdown);

  return (
    <div
      ref={dropdownRef}
      className={cx(className, classNames, 'dropdown', {
        active: isActive,
        open,
      })}
      tabIndex={0}
    >
      {shouldFilter && (
        <input id="select-search" ref={inputRef} onChange={onFilterChange} value={filter} placeholder={'Search...'} />
      )}
      <div className={`dropdown__dropdown`} onClick={toggleDropdown}>
        {title}
        {shouldToggle && (
          <>
            <div className="dropdown__control">
              <span className="material-icons">{'keyboard_arrow_down'}</span>
            </div>
          </>
        )}
      </div>
      {open && (
        <div className="dropdown__options">
          {filteredChildren.map(
            (child: ReactNode, i: number) =>
              child && (
                <div
                  key={i}
                  onClick={() => {
                    closeDropdown();
                  }}
                  className="dropdown__option"
                >
                  {child}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

const StyledLinkDropdown = styled(LinkDropdown)`
  cursor: pointer;
  background-color: white;
  transition: background-color 100ms linear;
  .dropdown__dropdown {
    display: flex;
    height: 50px;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
  }

  input {
    cursor: default;
    pointer-events: none;
    opacity: 0;
    &:focus {
      opacity: 1;
    }
    text-indent: 20px;
    outline: none;
    border: none;
    position: absolute;
    background-color: ${(props) => props.theme.colours.listItemHover};
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    bottom: 0px;
    top: 0px;
  }

  &.active {
    background-color: ${(props) => props.theme.colours.primaryB1};
    color: white;
    .dropdown__control {
      color: white;
    }
  }

  &.open {
    .dropdown__dropdown {
      background-color: ${(props) => props.theme.colours.listItemHover};
      color: ${(props) => props.theme.colours.navbarColour};
    }
    .dropdown__control {
      transform: rotate(180deg);
      color: ${(props) => props.theme.colours.navbarColour};
    }
  }

  :focus {
    outline: none;
    outline-offset: none;
  }

  .dropdown__control {
    user-select: none;
    transition: all ${(props) => props.theme.defaultTransition};
    border: 0;
    color: ${(props) => props.theme.colours.navbarColour};
    margin-left: 10px;
    height: 24px;
    &:hover {
      border: 0;
    }
  }

  .dropdown__options {
    white-space: nowrap;
    min-width: 100%;
    box-shadow: 6px 7px 2px 0 rgba(1, 39, 79, 0.1);
    background: white;
    color: ${(props) => props.theme.colours.navbarColour};
    background-color: ${(props) => props.theme.colours.navbarSelect};
    position: absolute;
    left: 0;
  }

  .dropdown__option {
    height: 44px;
    line-height: 44px;
    &:empty {
      display: none;
    }
    > * {
      transition: all 300ms linear;
      display: block;
      width: 100%;
      height: 100%;
      padding: 0 20px;
      color: ${(props) => props.theme.colours.navbarColour};
      &.active,
      &.active:hover {
        background-color: ${(props) => props.theme.colours.primaryB1};
        color: white;
      }
      &:hover {
        background-color: ${(props) => props.theme.colours.primaryB4};
      }
    }
  }
`;

export default StyledLinkDropdown;
