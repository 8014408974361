import { ErrorResponse } from '../json-api/types';
import { currencyFormatter } from '../../shared/utils/currencyFormatter';

interface IErrorResponseItem {
  status: number;
  title: string;
}

export type IErrorResponse = null | IErrorResponseItem[];

export const parseErrorResponse = (response: any): IErrorResponse => {
  if (response && Array.isArray(response.errors)) {
    return response.errors;
  }

  return null;
};

export const parseJsonApiResponse = (response?: ErrorResponse): string[] => {
  if (!response) {
    return [parseJsonApiResponse.DEFAULT_ERROR_MESSAGE];
  }

  if (!response.errors) {
    return [parseJsonApiResponse.DEFAULT_ERROR_MESSAGE];
  }

  return response.errors
    .map((error) => error.title || error.detail)
    .map((error) => {
      return error
        .replace(/^(the total discount cannot be greater than)(.+)/i, (_, __, rest) => {
          return `Total contract value discount (over term) cannot be >${rest}`;
        })
        .replace(/(.*)£(\d+\.\d+)(.*)/, (_, $1, currency, $3) => {
          return `${$1}${currencyFormatter.format(currency)}${$3}`;
        });
    });
};

parseJsonApiResponse.DEFAULT_ERROR_MESSAGE = 'An unknown error occurred';
