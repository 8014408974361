import { useState, useCallback } from 'react';

export interface Config<T> {
  onSuccess?: (response: T) => void;
  onError?: (error: any) => void;
}

export const useRequest = <T>(requestFunction: () => Promise<T>, { onSuccess, onError }: Config<T> = {}) => {
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState<any>(null);
  const [response, setResponse] = useState<T | null>(null);

  const executeRequest = useCallback(async () => {
    setInProgress(true);
    setError(null);

    try {
      const result = await requestFunction();
      setResponse(result);
      if (onSuccess) onSuccess(result);
    } catch (err) {
      setError(err);
      onError && onError(err);
    } finally {
      setInProgress(false);
    }
  }, [requestFunction]);

  return { executeRequest, response, inProgress, error };
};
