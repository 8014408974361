export type ErrorMessage = {
  message: string;
};

export const INCORRECT_USERNAME_PASSWORD = 'Incorrect username or password';
export const USER_DISABLED = 'User is disabled';
export const USER_NOT_EXIST = 'User does not exist';
export const PASSWORD_ATTEMPTS_EXCEEDED = 'Password attempts exceeded';
export const TOO_MANY_UNSUCCESSFUL_ATTEMPTS = 'Too many unsuccessful attempts, please wait and try again';
export const PASSWORD_POLICY_VALIDATION_ERROR = 'Password does not conform to policy: Password not long enough';
export const PASSWORD_LENGTH_VALIDATION = 'Password must be a minimum of 8 characters';

export const parseErrorMessage = ({ message }: { message: string }): string => {
  if (new RegExp(USER_DISABLED, 'i').test(message)) {
    return INCORRECT_USERNAME_PASSWORD;
  }

  if (new RegExp(USER_NOT_EXIST, 'i').test(message)) {
    return INCORRECT_USERNAME_PASSWORD;
  }

  if (new RegExp(PASSWORD_ATTEMPTS_EXCEEDED, 'i').test(message)) {
    return TOO_MANY_UNSUCCESSFUL_ATTEMPTS;
  }

  if (new RegExp(PASSWORD_POLICY_VALIDATION_ERROR, 'i').test(message)) {
    return PASSWORD_LENGTH_VALIDATION;
  }

  return message;
};
