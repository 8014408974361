import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  footerText?: string;
  theme: {
    colours: {
      grey10: string;
    };
  };
};

const GroupedFields: FunctionComponent<React.PropsWithChildren<Props>> = ({ children, className, footerText }) => {
  return (
    <fieldset className={className}>
      {children}
      <footer>
        <small className="form-text text-muted">{footerText}</small>
      </footer>
    </fieldset>
  );
};

const StyledGroupedFields = styled(GroupedFields)`
  background: ${(props) => props.theme.colours.grey10};
  padding: 0.8em;

  footer {
    padding: 0.8em 0 0 0;
  }
`;

export { StyledGroupedFields as GroupedFields };
