import { IDummyPrice } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import React from 'react';
import { StyledOpticalP2P as OpticalP2P } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider/OpticalP2P';

interface DummyPriceTileParams {
  onClick: () => void;
  price: IDummyPrice;
  priceSelected: boolean;
  onPriceItemClick: (priceId: string) => void;
  readOnly: boolean;
}
const DummyPriceTile = ({ onClick, onPriceItemClick, price, priceSelected, readOnly }: DummyPriceTileParams) => {
  return (
    <div onClick={onClick}>
      <OpticalP2P
        isPoA={true}
        a_end_data_centre_reference={price.aEndDataCentreReference}
        b_end_data_centre_reference={price.bEndDataCentreReference}
        a_end_data_centre_name={price.aEndDataCentreName}
        b_end_data_centre_name={price.bEndDataCentreName}
        amortisedAnnualPrice={null}
        selected={priceSelected}
        priceId={price.id}
        aEndAccessMethod={'Optical'}
        bEndAccessMethod={'Optical'}
        aEndPostcode={price.aEndPostcode!}
        bEndPostcode={price.bEndPostcode!}
        aEndSupplier={price.aEndSupplier}
        bEndSupplier={price.bEndSupplier}
        aEndPrice={null}
        bEndPrice={null}
        product_sub_type={'optical'}
        onClick={() => onPriceItemClick(price.id)}
        readonly={readOnly}
        bandwidth={price.bandwidth}
        term_length_in_months={price.term_length_in_months}
      />
    </div>
  );
};

export default DummyPriceTile;
