import React, { FunctionComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';
import { IAppState } from '../../../../reducers';
import Button, { ButtonMainStyle } from '../../../../shared/components/atoms/Button';
import RadioButton from '../../../../shared/components/molecules/RadioButton';
import { setProductTypeAction } from '../../../actions';
import { IAccordionSectionRenderProps } from '../../types';
import IQuoteRecordAttributesBase from '../../../types/quoteRecordAttributesBase';
import productTypes from '../../../types/productTypes';
import { pages, useTrackPage } from '../../../../shared/utils/trackPage';
interface IProductType {
  accordion: IAccordionSectionRenderProps;
  className?: string;
  productType: IQuoteRecordAttributesBase['product_type'];
  theme: {
    breakpoints: {
      M: string;
    };
    typography: {
      fontSizeH2: string;
    };
    colours: {
      secondary: string;
    };
  };
  setProductType(productType: IQuoteRecordAttributesBase['product_type']): void;
}
interface IProduct {
  id: string;
  type: IQuoteRecordAttributesBase['product_type'];
  label: string;
}
const ProductType: FunctionComponent<React.PropsWithChildren<IProductType>> = ({
  accordion,
  className,
  productType,
  setProductType,
}) => {
  useTrackPage(pages.createQuote);
  const getElementStyle = (productButtonType: IQuoteRecordAttributesBase['product_type']) => {
    return productButtonType === productType ? 'selected' : 'notSelected';
  };
  const onNext = () => accordion.showContent(accordion.index + 1);
  const products: IProduct[] = productTypes.map((product) => {
    return {
      id: `product-${product.value}`,
      label: product.label,
      type: product.value,
    };
  });
  return (
    <div className={className}>
      <div className="types-of-product">
        {products.map((product) => (
          <div className="radioButton__container" key={product.id}>
            <div className="radioButton__wrapper mb-3">
              <RadioButton
                size="fill"
                description={product.label}
                id={product.id}
                key={product.id}
                onClick={() => {
                  setProductType(product.type);
                }}
                status={getElementStyle(product.type)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="row no-gutter mb-4 mt-3">
        <div className="col-sm-6 offset-sm-6 text-right">
          <Button disabled={!productType} mainStyle={ButtonMainStyle.PrimaryRectangular} onClick={onNext}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
const styledProductType = styled(ProductType)`
  text-align: left;
  font-size: 1rem;

  .types-of-product {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .radioButton__container {
    flex: 0 0 33.3333%;
    &:nth-child(1),
    &:nth-child(4) {
      .radioButton__wrapper {
        margin-right: 6px;
      }
    }
    &:nth-child(2),
    &:nth-child(5) {
      .radioButton__wrapper {
        margin-left: 6px;
        margin-right: 6px;
      }
    }
    &:nth-child(3),
    &:nth-child(6) {
      .radioButton__wrapper {
        margin-left: 6px;
      }
    }
  }
`;
const mapStateToProps = (state: IAppState) => ({
  productType: state.quoteBuilder.quote.productType,
});
const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  setProductType: (productType: IQuoteRecordAttributesBase['product_type']) =>
    dispatch(setProductTypeAction(productType)),
});
export default connect(mapStateToProps, mapDispatchToProps)(styledProductType);
