import { MessageResource } from 'shared/types/Messages';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import React, { useState } from 'react';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { convertMessageToUrl, MessagesList } from 'Messages/Messages/MessagesList';
import { MessagePaging, useMessagePaging } from 'Messages/crud/loadMessages';
import { Loading } from 'shared/components/molecules/Loading/Loading';
import { doRequest, Methods } from 'Request';
import { useDispatch } from 'react-redux';
import { checkForNewMessages } from 'Messages/actions';
import { useNavigate } from 'react-router-dom';

export const markAllAsRead = async () =>
  await doRequest({
    method: Methods.POST,
    path: `/messages/mark-all-as/read`,
  });

const Messages = () => {
  const navigate = useNavigate();
  const messageLoader: MessagePaging = useMessagePaging();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const clickAction = async () => {
    setLoading(true);
    await markAllAsRead();

    messageLoader.refresh();
    dispatch(checkForNewMessages());
    setLoading(false);
  };

  const openQuote = (message: MessageResource) => {
    navigate(convertMessageToUrl(message).url);
  };

  return (
    <HeaderWithChildrenWrapper header="Messages">
      <Loading
        isLoading={messageLoader.isInitialising || messageLoader.isLoading || loading}
        message="Loading messages"
      >
        <MarkAllAsReadButton onClick={clickAction} />
        <MessagesList messagePaging={messageLoader} onRowClick={(message: MessageResource) => openQuote(message)} />
      </Loading>
    </HeaderWithChildrenWrapper>
  );
};

export function MarkAllAsReadButton({ onClick }: { onClick: () => void }) {
  return (
    <div className="text-right mb-2">
      <Button mainStyle={ButtonMainStyle.LinkSecondary} onClick={onClick} data-testid="mark-all-as-read">
        Mark all as read
      </Button>
    </div>
  );
}

export default Messages;
