import { IOrder, IOrderMetaForLocation, IOrderState } from './store';
import { IOpenReachAddress, IOpenReachAddressSite, IPAFAddressSite } from 'shared/types/postcodeResults';
import { CloudConnect, IBillingContactAndAddress, IPContact, OrderUpdateFields } from './formFields';
import IOrderRecord, { BillingFrequency, OrderStatus } from './orderRecord';
import IQuoteRecord from 'Quotes/types/quoteRecord';
import { ContactIdentifierType } from 'Order/OrderBuilder/shared/components/ExistingContactOptions';
import { OrderSubmitType } from '../crud/submitOrder';
import { AddressType, IPriceData, IQuote } from 'Quotes/types/store';
import { GenericAction } from 'shared/actions';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { SiteCoords, SiteType } from './location';
import { MessageResource } from 'shared/types/Messages';
import { OrderSectionCopyTarget } from 'Order/OrderBuilder/CopyFromQuote/CopyFromQuoteButton';
import { IOrderTemplate, IOrderTemplateState } from './orderTemplate';

export enum OrderActionTypes {
  SET_ORDER_ID = 'SET_ORDER_ID',
  CREATE_ORDER = 'CREATE_ORDER',
  CREATE_ORDER_STARTED = 'CREATE_ORDER_STARTED',
  CREATE_ORDER_ENDED = 'CREATE_ORDER_ENDED',
  CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR',
  UPDATE_ORDER = 'UPDATE_ORDER',
  UPDATE_ORDER_STARTED = 'UPDATE_ORDER_STARTED',
  UPDATE_ORDER_ENDED = 'UPDATE_ORDER_ENDED',
  UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR',
  GET_ORDER_QUOTE_AND_PRICE = 'GET_ORDER_QUOTE_AND_PRICE ',
  GET_ORDER_QUOTE_AND_PRICE_STARTED = 'GET_ORDER_QUOTE_AND_PRICE_STARTED',
  GET_ORDER_QUOTE_AND_PRICE_SUCCEEDED = 'GET_ORDER_QUOTE_AND_PRICE_SUCCEEDED',
  GET_ORDER_QUOTE_AND_PRICE_ERROR = 'GET_ORDER_QUOTE_AND_PRICE_ERROR',
  REPLACE_ORDER_STATE = 'REPLACE_ORDER_STATE',
  SET_A_END_ADDRESS = 'SET_A_END_ADDRESS',
  SET_B_END_ADDRESS = 'SET_B_END_ADDRESS',
  SET_A_END_ADDRESS_NOT_LISTED = 'SET_A_END_ADDRESS_NOT_LISTED',
  SET_B_END_ADDRESS_NOT_LISTED = 'SET_B_END_ADDRESS_NOT_LISTED',
  UPDATE_ADDRESS_FIELD_LOCATION_A = 'UPDATE_ADDRESS_FIELD_LOCATION_A',
  UPDATE_ADDRESS_FIELD_LOCATION_B = 'UPDATE_ADDRESS_FIELD_LOCATION_B',
  UPDATE_FIELD_BILLING_AND_CONTACT = 'UPDATE_FIELD_BILLING_AND_CONTACT',
  UPDATE_CONTACT = 'UPDATE_CONTACT',
  UPDATE_BILLING_AND_CONTACT = 'UPDATE_BILLING_AND_CONTACT',
  UPDATE_IP_CONTACT = 'UPDATE_IP_CONTACT',
  UPDATE_IP_TEXT = 'UPDATE_IP_TEXT',
  UPDATE_SECONDARY_IP_DETAILS = 'UPDATE_SECONDARY_IP_DETAILS',
  UPDATE_WAN_IP_IN_ADDITION = 'UPDATE_WAN_IP_IN_ADDITION',
  UPDATE_CLOUD_CONNECT = 'UPDATE_CLOUD_CONNECT',
  UPDATE_BILLING_FREQUENCY = 'UPDATE_BILLING_FREQUENCY',
  REPLACE_ORDER_A_END_LOCATION = 'REPLACE_ORDER_A_END_LOCATION',
  REPLACE_ORDER_B_END_LOCATION = 'REPLACE_ORDER_B_END_LOCATION',
  REPLACE_ORDER_BILLING_AND_CONTACT = 'REPLACE_ORDER_BILLING_AND_CONTACT',
  REPLACE_ORDER_NOTES = 'REPLACE_ORDER_NOTES',
  REPLACE_ORDER_STATUS = 'REPLACE_ORDER_STATUS',
  REPLACE_ORDER_HISTORY = 'REPLACE_ORDER_HISTORY',
  EDIT_ORDER_STATE = 'EDIT_ORDER_STATE',
  SUBMIT_ORDER = 'SUBMIT_ORDER',
  SUBMIT_ORDER_STARTED = 'SUBMIT_ORDER_STARTED',
  SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS',
  SUBMIT_ORDER_ERROR = 'SUBMIT_ORDER_ERROR',
  CREATE_NOTE = 'CREATE_NOTE',
  CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR',
  CREATE_NOTE_RESET_STATE = 'CREATE_NOTE_RESET_STATE',
  CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS',
  SET_A_END_PAF_ADDRESS = 'SET_A_END_PAF_ADDRESS',
  SET_B_END_PAF_ADDRESS = 'SET_B_END_PAF_ADDRESS',
  SET_A_END_FULL_ADDRESS_NOT_LISTED = 'SET_A_END_FULL_ADDRESS_NOT_LISTED',
  SET_B_END_FULL_ADDRESS_NOT_LISTED = 'SET_B_END_FULL_ADDRESS_NOT_LISTED',
  RESET_ORDER_STATE = 'RESET_ORDER_STATE',
  SET_A_END_COMPANY_NAME = 'SET_A_END_COMPANY_NAME',
  SET_B_END_COMPANY_NAME = 'SET_B_END_COMPANY_NAME',
  SET_B_END_ADDRESS_TYPE = 'SET_B_END_ADDRESS_TYPE',
  SET_A_END_ADDRESS_TYPE = 'SET_A_END_ADDRESS_TYPE',
  SET_SITE_TYPE = 'SET_SITE_TYPE',
  UPDATE_ON_NET_ADDRESS_FIELD = 'UPDATE_ON_NET_ADDRESS_FIELD',
  SET_SITE_COORDS = 'SET_SITE_COORDS',
  NEW_ORDER_MESSAGE = 'NEW_ORDER_MESSAGE',
  MULTI_QUOTE_COPY_SECTION = 'MULTI_QUOTE_COPY_SECTION',
  COPY_FROM_ORDER_TEMPLATE = 'COPY_FROM_ORDER_TEMPLATE',
  SET_SELECTED_ORDER_TEMPLATE_ID = 'SET_SELECTED_ORDER_TEMPLATE_ID',
  SET_SELECTED_ORDER_TEMPLATE = 'SET_SELECTED_ORDER_TEMPLATE',
}

export type OrderAction = GenericAction & {
  key?: keyof IOrderState;
};

export interface ICopyAddressFromQuoteToOrderPayload {
  quote: IQuoteRecord;
}

export interface ISetAEndAddressNotListedPayload {
  notListed: boolean;
}

export interface ISetAEndAddressNotListed {
  payload: ISetAEndAddressNotListedPayload;
  type: OrderActionTypes.SET_A_END_ADDRESS_NOT_LISTED;
}

export interface ISetAEndFullAddressNotListed {
  payload: ISetAEndAddressNotListedPayload;
  type: OrderActionTypes.SET_A_END_FULL_ADDRESS_NOT_LISTED;
}

export interface ISetBEndAddressNotListedPayload {
  notListed: boolean;
}

export interface ISetBEndAddressNotListed {
  payload: ISetBEndAddressNotListedPayload;
  type: OrderActionTypes.SET_B_END_ADDRESS_NOT_LISTED;
}

export interface ISetBEndFullAddressNotListed {
  payload: ISetBEndAddressNotListedPayload;
  type: OrderActionTypes.SET_B_END_FULL_ADDRESS_NOT_LISTED;
}

export interface ISetAEndAddressPayload {
  aEndAddress: IOpenReachAddressSite | null;
}

export interface ISetAEndPAFAddressPayload {
  aEndAddress: IPAFAddressSite | null;
}

export interface ISetAEndAddress {
  payload: ISetAEndAddressPayload;
  type: OrderActionTypes.SET_A_END_ADDRESS;
}

export interface ISetAEndPAFAddress {
  payload: ISetAEndPAFAddressPayload;
  type: OrderActionTypes.SET_A_END_PAF_ADDRESS;
}

export interface ISetBEndAddressPayload {
  bEndAddress: IOpenReachAddressSite | null;
}

export interface ISetBEndPAFAddressPayload {
  bEndAddress: IPAFAddressSite | null;
}

export interface ISetBEndAddress {
  payload: ISetBEndAddressPayload;
  type: OrderActionTypes.SET_B_END_ADDRESS;
}

export interface ISetBEndPAFAddress {
  payload: ISetBEndPAFAddressPayload;
  type: OrderActionTypes.SET_B_END_PAF_ADDRESS;
}

export interface ISetOrderPayload {
  id: string;
}

export interface ICreateOrderStarted {
  type: OrderActionTypes.CREATE_ORDER_STARTED;
}

export interface ICreateOrderEnded {
  type: OrderActionTypes.CREATE_ORDER_ENDED;
}

export interface ICreateOrderError {
  type: OrderActionTypes.CREATE_ORDER_ERROR;
}

export interface IUpdateOrderStarted {
  type: OrderActionTypes.UPDATE_ORDER_STARTED;
}

export interface IUpdateOrderEnded {
  type: OrderActionTypes.UPDATE_ORDER_ENDED;
}

export interface IUpdateOrderError {
  type: OrderActionTypes.UPDATE_ORDER_ERROR;
}

interface ICreateOrderPayload {
  quoteId: string;
  quote: IQuote;
  customerName: string;
  selectedPrice: IPriceData;
  meta?: IMeta;
  lqId?: string;
}

export interface ICreateOrder {
  payload: ICreateOrderPayload;
  type: OrderActionTypes.CREATE_ORDER;
}

export interface ISetOrderId {
  payload: ISetOrderPayload;
  type: OrderActionTypes.SET_ORDER_ID;
}

export interface IUpdateOrderPayload {
  orderId: string;
  data: OrderUpdateFields;
}

export interface IUpdateOrder {
  payload: IUpdateOrderPayload;
  type: OrderActionTypes.UPDATE_ORDER;
}

export interface IGetExistingOrderPayload {
  orderId: string;
}

export interface IGetOrderQuoteAndPrice {
  payload: IGetExistingOrderPayload;
  type: OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE;
}

export interface IGetOrderQuoteAndPriceStarted {
  type: OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE_STARTED;
}

export interface IGetOrderQuoteAndPriceSucceeded {
  type: OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE_SUCCEEDED;
}

export interface IGetOrderQuoteAndPriceError {
  payload: { error: string };
  type: OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE_ERROR;
}

export interface IReplaceOrderState {
  payload: { order: IOrderRecord; quote: IQuoteRecord; customerId?: string };
  type: OrderActionTypes.REPLACE_ORDER_STATE;
}

export interface IEditOrderState {
  payload: IOrder;
  type: OrderActionTypes.EDIT_ORDER_STATE;
}

export interface IReplaceOrderAEndLocationAction {
  payload: { order: IOrderRecord };
  type: OrderActionTypes.REPLACE_ORDER_A_END_LOCATION;
}

export interface IReplaceOrderBEndLocationAction {
  payload: { order: IOrderRecord };
  type: OrderActionTypes.REPLACE_ORDER_B_END_LOCATION;
}

export interface IReplaceOrderBillingAndContactAction {
  payload: { order: IOrderRecord };
  type: OrderActionTypes.REPLACE_ORDER_BILLING_AND_CONTACT;
}

export interface ICreateNote {
  payload: { quoteId: string; orderId: string; message: string };
  type: OrderActionTypes.CREATE_NOTE;
}

export interface ICreateNoteError {
  type: OrderActionTypes.CREATE_NOTE_ERROR;
}

export interface ICreateNoteSuccess {
  type: OrderActionTypes.CREATE_NOTE_SUCCESS;
}

export interface ICreateNoteResetState {
  type: OrderActionTypes.CREATE_NOTE_RESET_STATE;
}

export interface IReplaceOrderNotes {
  payload: { order: IOrderRecord };
  type: OrderActionTypes.REPLACE_ORDER_NOTES;
}

export interface IReplaceOrderStatus {
  payload: { status: OrderStatus };
  type: OrderActionTypes.REPLACE_ORDER_STATUS;
}

export interface IReplaceOrderStateHistory {
  payload: { history: IOrder['stateHistory'] };
  type: OrderActionTypes.REPLACE_ORDER_HISTORY;
}

export interface IUpdateAddressFieldLocationAPayload {
  fieldName: keyof IOpenReachAddress;
  fieldValue: string;
}

export interface IUpdateAddressFieldLocationA {
  payload: IUpdateAddressFieldLocationAPayload;
  type: OrderActionTypes.UPDATE_ADDRESS_FIELD_LOCATION_A;
}

export interface IUpdateAddressFieldLocationBPayload {
  fieldName: keyof IOpenReachAddress;
  fieldValue: string;
}

export interface IUpdateAddressFieldLocationB {
  payload: IUpdateAddressFieldLocationBPayload;
  type: OrderActionTypes.UPDATE_ADDRESS_FIELD_LOCATION_B;
}

export interface IUpdateOnNetAddress {
  payload: {
    end: 'A' | 'B';
    attributes: IOrderMetaForLocation | null;
  };
  type: OrderActionTypes.UPDATE_ON_NET_ADDRESS_FIELD;
}

interface IUpdateBillingContactAndAddressPayload {
  billingContactAndAddress: IBillingContactAndAddress;
}

export interface IUpdateBillingAndContact {
  payload: IUpdateBillingContactAndAddressPayload;
  type: OrderActionTypes.UPDATE_BILLING_AND_CONTACT;
}

export interface IUpdateIPContact {
  payload: {
    ipContact: IPContact;
  };
  type: OrderActionTypes.UPDATE_IP_CONTACT;
}

export interface IUpdateIPText {
  payload: {
    text: string;
  };
  type: OrderActionTypes.UPDATE_IP_TEXT;
}

export interface IUpdateSecondaryIPDetails {
  payload: {
    ipText: string;
    nicRipeHandle: string;
  };
  type: OrderActionTypes.UPDATE_SECONDARY_IP_DETAILS;
}

export interface IUpdateWanIpInAddition {
  payload: {
    wan_ip_in_addition: IOrder['wan_ip_in_addition'];
  };
  type: OrderActionTypes.UPDATE_WAN_IP_IN_ADDITION;
}

export interface IUpdateBillingFrequency {
  payload: {
    billingFrequency: BillingFrequency;
  };
  type: OrderActionTypes.UPDATE_BILLING_FREQUENCY;
}

export interface UpdateCloudConnect {
  payload: CloudConnect;
  type: OrderActionTypes.UPDATE_CLOUD_CONNECT;
}

interface IUpdateContactPayload {
  contactIdentifier: ContactIdentifierType;
  contactType: string;
  order: IOrder;
}

export interface IUpdateContact {
  payload: IUpdateContactPayload;
  type: OrderActionTypes.UPDATE_CONTACT;
}

export interface ISubmitOrderPayload {
  orderId: string;
  submitType: OrderSubmitType;
}

export interface ISubmitOrder {
  payload: ISubmitOrderPayload;
  type: OrderActionTypes.SUBMIT_ORDER;
}

export interface ISubmitOrderStarted {
  type: OrderActionTypes.SUBMIT_ORDER_STARTED;
}

export interface ISubmitOrderSuccess {
  type: OrderActionTypes.SUBMIT_ORDER_SUCCESS;
}

export interface ISubmitOrderError {
  type: OrderActionTypes.SUBMIT_ORDER_ERROR;
}

export interface IResetOrderState {
  type: OrderActionTypes.RESET_ORDER_STATE;
}

interface ISetEndCompanyNamePayload {
  endCompanyName: string;
}

export interface ISetAEndCompanyName {
  payload: ISetEndCompanyNamePayload;
  type: OrderActionTypes.SET_A_END_COMPANY_NAME;
}

export interface ISetBEndCompanyName {
  payload: ISetEndCompanyNamePayload;
  type: OrderActionTypes.SET_B_END_COMPANY_NAME;
}

interface ISetEndAddressTypePayload {
  endAddressType: AddressType;
}

export interface ISetBEndAddressType {
  payload: ISetEndAddressTypePayload;
  type: OrderActionTypes.SET_B_END_ADDRESS_TYPE;
}

export interface ISetAEndAddressType {
  payload: ISetEndAddressTypePayload;
  type: OrderActionTypes.SET_A_END_ADDRESS_TYPE;
}

export interface ISetSiteType {
  payload: {
    end: 'A' | 'B';
    siteType: SiteType | null;
  };
  type: OrderActionTypes.SET_SITE_TYPE;
}

export interface SetSiteCoords {
  payload: {
    end: 'A' | 'B';
    field: keyof SiteCoords;
    value: string;
  };
  type: OrderActionTypes.SET_SITE_COORDS;
}

export interface NewOrderMessage {
  type: OrderActionTypes.NEW_ORDER_MESSAGE;
  payload: {
    message: MessageResource;
  };
}

export interface IMultiQuoteCopySectionPayload {
  targetSection: OrderSectionCopyTarget;
  orderToCopyFrom: IOrderRecord;
  quoteToCopyFrom: IQuoteRecord;
}

export interface IMultiQuoteCopySection {
  type: OrderActionTypes.MULTI_QUOTE_COPY_SECTION;
  payload: IMultiQuoteCopySectionPayload;
}

export interface ICopyFromOrderTemplate {
  type: OrderActionTypes.COPY_FROM_ORDER_TEMPLATE;
  payload: { sourceOrderTemplate: IOrderTemplateState };
}

export interface ISetSelectedOrderTemplateId {
  type: OrderActionTypes.SET_SELECTED_ORDER_TEMPLATE_ID;
  payload: { selectedOrderTemplateId?: string };
}

export interface ISetSelectedOrderTemplate {
  type: OrderActionTypes.SET_SELECTED_ORDER_TEMPLATE;
  payload: { selectedOrderTemplate?: IOrderTemplate };
}
