import { IUserRoleForCreation, IUserRoleFromBackend, Role } from 'User/types/role';
import { CompanyData } from 'User/types/user';
import {
  backendRoleNameForRole,
  customerRoleValues,
  getRolesForCompany,
  groupCompaniesById,
  sseRoleValuesExcludingPricing,
} from 'User/utils/roles';
import { IRow } from '../shared/components/CompaniesRolesTable';
import { ICompanySelectorOption } from '../shared/components/CompanySelector';

export const isSSEUserFromBackendRoles = (backendRoles: IUserRoleFromBackend[] = []) =>
  !!backendRoles[0]?.role?.includes('sse');

export const toggleRoleForCompany = (companyId: string, role: Role, allRows: IRow[]): IRow[] =>
  allRows.map((row) => ({
    ...row,
    roles: row.roles.map((item) => {
      if (row.companyId === companyId && item.role === role) {
        item.enabled = !item.enabled;
      }

      return item;
    }),
  }));

export const checkIfAllEditableCompaniesHaveARole = (allRows: IRow[], lookupRole: Role): boolean => {
  if (allRows.filter((row) => row.readonly === false).length === 0) {
    return false;
  }
  return allRows.every((roleRow) => {
    if (roleRow.readonly) {
      return true;
    }
    return roleRow.roles.some((role) => {
      return role.role === lookupRole && role.enabled;
    });
  });
};

export const setStatusForARoleInEditableCompanies = (
  lookupRole: Role,
  status: boolean,
  companiesRolesRows: IRow[]
): IRow[] => {
  return companiesRolesRows.map((companyRoleRow) => {
    if (!companyRoleRow.readonly) {
      companyRoleRow.roles.map((singleRole) => {
        if (singleRole.role === lookupRole) {
          singleRole.enabled = status;
        }
        return singleRole;
      });
    }
    return companyRoleRow;
  });
};

export const toggleCompanyEnabled = (
  companyId: string,
  allOptions: ICompanySelectorOption[]
): ICompanySelectorOption[] =>
  allOptions.map((option) => ({
    enabled: option.value === companyId ? !option.enabled : option.enabled,
    label: option.label,
    value: option.value,
  }));

export const enableAllCompanies = (allOptions: ICompanySelectorOption[]): ICompanySelectorOption[] =>
  allOptions.map((option) => ({
    enabled: true,
    label: option.label,
    value: option.value,
  }));

export const disableAllCompanies = (allOptions: ICompanySelectorOption[]): ICompanySelectorOption[] =>
  allOptions.map((option) => ({
    enabled: false,
    label: option.label,
    value: option.value,
  }));

export const addAllCompaniesToRolesRows = (
  allRows: IRow[],
  isSSEUser: boolean,
  companySelectorOptions: ICompanySelectorOption[]
): IRow[] => {
  const newRoles = allRows;
  companySelectorOptions.forEach((company) => {
    if (!company.enabled) {
      newRoles.push(buildCompanyRow(company, isSSEUser));
    }
  });
  return newRoles;
};

export const removeAllCompaniesFromRolesRows = (allRows: IRow[]): IRow[] => {
  return allRows.filter((row) => row.readonly);
};

export const addCompanyToRolesRow = (option: ICompanySelectorOption, allRows: IRow[], isSSEUser: boolean): IRow[] =>
  allRows.concat([buildCompanyRow(option, isSSEUser)]);

const buildCompanyRow = (company: ICompanySelectorOption, isSSEUser: boolean): IRow => {
  return {
    companyName: company.label,
    companyId: company.value,
    readonly: false,
    roles: (isSSEUser ? sseRoleValuesExcludingPricing() : customerRoleValues()).map((role) => ({
      enabled: false,
      role,
    })),
  };
};

export const removeCompanyFromRolesRow = (companyId: string, allRows: IRow[]): IRow[] =>
  allRows.filter((row) => row.companyId !== companyId);

export const buildInitialCompanyEnabledList = (
  companies: CompanyData['companies'],
  backendRoles: IUserRoleFromBackend[]
): ICompanySelectorOption[] => {
  const enabledCompanies = groupCompaniesById(backendRoles);

  return companies.map((company) => ({
    enabled: enabledCompanies.find((enabledCompany) => company.id === enabledCompany.id) !== undefined,
    label: company.name,
    value: company.id,
  }));
};

export const translateBackendRolesToCompanyRolesRows = (
  backendRoles: IUserRoleFromBackend[],
  companies: CompanyData['companies']
): IRow[] => {
  const enabledCompanies = groupCompaniesById(backendRoles);

  return enabledCompanies.map((company) => ({
    companyName: company.name,
    companyId: company.id,
    readonly: companies.find((c) => c.id === company.id) === undefined,
    roles: getRolesForCompany(company.id, backendRoles).map((role) => ({
      enabled: true,
      role,
    })),
  }));
};

export const assignExistingRolesToCompanyRolesRows = (rows: IRow[], isSSEUser: boolean): IRow[] =>
  rows.map((row) => {
    const rolesForCompany = (isSSEUser ? sseRoleValuesExcludingPricing() : customerRoleValues()).map((role) => {
      const matchingRoleIndex = row.roles.findIndex((item) => item.role === role);

      if (matchingRoleIndex !== -1) {
        return {
          ...row.roles[matchingRoleIndex],
        };
      }

      return {
        enabled: false,
        role,
      };
    });

    return {
      ...row,
      roles: rolesForCompany,
    };
  });

export const hasNoRoles = (rows: IRow[]): boolean =>
  rows.length === 0 ? true : rows.map((row) => row.roles).some((roles) => roles.every((role) => !role.enabled));

export const translateRolesSelectionForUserCreation = (rows: IRow[]): IUserRoleForCreation[] =>
  rows
    .map((row) =>
      row.roles
        .filter((item) => item.enabled)
        .map((item) => ({
          customer_id: row.companyId,
          role: backendRoleNameForRole(item.role),
        }))
    )
    .flat();
