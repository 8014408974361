import React, { FC } from 'react';
import styled from 'styled-components';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';

interface IComplexQuoteLoadingOverlayProps {
  className?: string;
  progressPercentage: number;
}

const progressStages = [
  {
    progress: 0,
    text: 'Finding suppliers...',
  },
  {
    progress: 30,
    text: 'Finding prices...',
  },
  {
    progress: 70,
    text: `Building quotes...`,
  },
];

const UnstyledComplexQuoteLoadingOverlay: FC<IComplexQuoteLoadingOverlayProps> = ({
  className,
  progressPercentage = 100,
}) => {
  const currentStage = [...progressStages].reverse().find((stage) => progressPercentage >= stage.progress)!;

  return (
    <div className={className}>
      <SpinnerWithText text={currentStage.text} size="large" />
    </div>
  );
};

export const AsyncPricesLoadingOverlay = styled(UnstyledComplexQuoteLoadingOverlay)`
  z-index: 1;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffffe3;
  backdrop-filter: blur(3px);

  animation: fadeIn 0.5s ease-in-out forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .loading-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;

    .text {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 0;
      left: 0;
      font-weight: bold;
      white-space: nowrap;
    }

    .cable-loading {
      flex-basis: 50%;

      &.right {
        transform: rotate(180deg);
      }
    }

    .thick-progress-bar-wrapper {
      flex-basis: 50%;
    }
  }
`;
