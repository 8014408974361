import React, { FunctionComponent } from 'react';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';

interface Props {
  setFilter(id: string | null): void;
}

const options = [
  {
    label: '100',
    value: '100',
  },
  {
    label: '1000',
    value: '1000',
  },
  {
    label: '10000',
    value: '10000',
  },
];

export const BEndPortFilter: FunctionComponent<React.PropsWithChildren<Props>> = ({ setFilter }) => (
  <div className="control">
    <label>B-End Port</label>
    <Dropdown
      className="select-input b-end-port-dropdown"
      options={options}
      onChange={(item: IDropdownOption) => setFilter(item ? item.value : null)}
    />
  </div>
);
