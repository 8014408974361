import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { IPriceData, CablingType } from '../../../../Quotes/types/store';
import { currencyFormatter } from '../../../../shared/utils/currencyFormatter';
import {
  setInstallCost,
  setAnnualCost,
} from '../../../../Quotes/QuoteBuilder/components/Price/components/Cabling/utils/cabling';
import Column from '../../../../shared/components/atoms/Column';

interface Props {
  className?: string;
  selectedPrice: IPriceData;
}

const Cabling: React.FunctionComponent<React.PropsWithChildren<Props>> = (props: Props) => {
  const isCopper =
    props.selectedPrice.a_end_cabling_type === CablingType.Copper ||
    props.selectedPrice.b_end_cabling_type === CablingType.Copper;

  const installCostAEnd = setInstallCost(isCopper, props.selectedPrice.a_end_cross_connect_cost);

  const annualCostAEnd = setAnnualCost(isCopper, props.selectedPrice.a_end_cross_connect_cost);

  const installCostBEnd = setInstallCost(isCopper, props.selectedPrice.b_end_cross_connect_cost);

  const annualCostBEnd = setAnnualCost(isCopper, props.selectedPrice.b_end_cross_connect_cost);

  const renderCablingEnd = (
    cablingType: CablingType | null,
    postCode: string | null,
    installCost: number,
    annualCost: number
  ) => {
    return (
      <>
        {cablingType !== null && (
          <div className="section">
            <div className="row no-gutters">
              <Column classNames={['text-left', 'cabling_label']}>Cabling request for {postCode}</Column>
            </div>

            {installCost > 0 && (
              <div className="row no-gutters">
                <Column defaultWidth={8} classNames={['column-heading', 'text-left']}>
                  Supplier installation charge
                </Column>
                <Column defaultWidth={4} classNames={['text-right']}>
                  {currencyFormatter.format(installCost)}
                </Column>
              </div>
            )}

            {annualCost > 0 && (
              <div className="row no-gutters">
                <Column defaultWidth={8} classNames={['column-heading', 'text-left']}>
                  Supplier annual cost
                </Column>
                <Column defaultWidth={4} classNames={['text-right']}>
                  {currencyFormatter.format(annualCost)}
                </Column>
              </div>
            )}
            <div className="row no-gutters">
              <Column defaultWidth={8} classNames={['column-heading', 'text-left']}>
                Cabling type
              </Column>
              <Column defaultWidth={4} classNames={['text-right cablingType']}>
                {cablingType}
              </Column>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={props.className}>
      <span data-tip="cabling" data-event="click" data-event-off="dblclick">
        Cabling requested
      </span>
      <div className="tooltip-print">
        <ReactTooltip className="cabling-contents" place="bottom" type="light" effect="solid" globalEventOff="click">
          {renderCablingEnd(
            props.selectedPrice.a_end_cabling_type,
            props.selectedPrice.a_end_p_o_p,
            installCostAEnd,
            annualCostAEnd
          )}

          {renderCablingEnd(
            props.selectedPrice.b_end_cabling_type,
            props.selectedPrice.b_end_p_o_p,
            installCostBEnd,
            annualCostBEnd
          )}

          <hr />

          <div className="cabling_description text-left">
            Cross connect cabling charges may apply to your order. Neos Networks will pass through the costs quoted by
            the Data Centre operator with an admin charge added per end as required.
          </div>
        </ReactTooltip>
      </div>
    </div>
  );
};

const StyledCabling = styled(Cabling)`
  span {
    color: ${(props) => props.theme.colours.primaryB1};
    text-decoration: underline;
    cursor: pointer;
  }

  .cabling_label {
    color: ${(props) => props.theme.colours.secondary};
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  .cabling_description {
    width: 327px;
  }

  .cablingType {
    text-transform: capitalize;
  }

  .cabling-contents {
    font-size: 1em;

    .section + .section {
      padding-top: 1em;
    }
  }

  @media print {
    span[data-tip='cabling'] {
      display: none;
    }
    .tooltip-print {
      position: relative;
    }
    .tooltip-print > div.__react_component_tooltip {
      opacity: 1 !important;
      visibility: visible !important;
      left: auto;
      top: -5px;
      right: -25px;
      position: absolute;
      display: block;
      background: #fff;
    }
  }
`;

export default StyledCabling;
