import { IOrder } from 'Order/types/store';
import {
  APIOrderTemplate,
  APITemplateBody,
  IOrderTemplate,
  IOrderTemplateState,
  IOrderTemplateStateV1,
  mapOrderTemplateFromAPI,
} from 'Order/types/orderTemplate';
import { JSONAPIResponse, mapSingleOrArray } from 'shared/types/JSONApiResponse';

export interface Payload {
  name: string;
  order: IOrder;
  customerId: string;
  orderId: string;
  isDefault?: boolean;
}

export const mapOrderToOrderTemplateState = (order: IOrder): IOrderTemplateState => ({
  orderContact: {
    firstName: order.orderContact.firstName,
    surname: order.orderContact.surname,
    phoneNumber: order.orderContact.phoneNumber,
    email: order.orderContact.email,
    address: order.orderContact.address,
  },
  operationalContact: {
    firstName: order.operationalContact.firstName,
    surname: order.operationalContact.surname,
    phoneNumber: order.operationalContact.phoneNumber,
    email: order.operationalContact.email,
    address: order.operationalContact.address,
  },
  billingContactAndAddress: {
    purchaseOrderRef: order.billingContactAndAddress.purchaseOrderRef,
    firstName: order.billingContactAndAddress.firstName,
    surname: order.billingContactAndAddress.surname,
    phoneNumber: order.billingContactAndAddress.phoneNumber,
    email: order.billingContactAndAddress.email,
    address: order.billingContactAndAddress.address,
  },
  ip: {
    justification: order.ipText,
    contact: {
      address: order.ipContact.address,
      email: order.ipContact.email,
      firstName: order.ipContact.firstName,
      surname: order.ipContact.surname,
      nic_ripe_handle: order.ipContact.nic_ripe_handle,
      organisation: order.ipContact.organisation,
      telephone: order.ipContact.telephone,
    },
  },
  billingFrequency: order.billingFrequency,
  locationA: {
    siteConfig: {
      floor: order.locationA.siteConfig.floor,
      room: order.locationA.siteConfig.room,
      suite: order.locationA.siteConfig.suite,
      rackId: order.locationA.siteConfig.rackId,
      connectorType: order.locationA.siteConfig.connectorType,
      mediaType: order.locationA.siteConfig.mediaType,
      powerType: order.locationA.siteConfig.powerType,
      duplexity: order.locationA.siteConfig.duplexity,
      autoNegotiation: order.locationA.siteConfig.autoNegotiation,
    },
    siteContact: {
      firstName: order.locationA.siteContact.firstName,
      surname: order.locationA.siteContact.surname,
      phone: order.locationA.siteContact.phone,
      email: order.locationA.siteContact.email,
      notes: order.locationA.siteContact.notes,
    },
    siteReadiness: {
      accessNotice: order.locationA.siteReadiness.accessNotice,
      moreThanOneTenant: order.locationA.siteReadiness.moreThanOneTenant,
      asbestosRegister: order.locationA.siteReadiness.asbestosRegister,
      hazardsOnSite: order.locationA.siteReadiness.hazardsOnSite,
      hazardsOnSiteDescription: order.locationA.siteReadiness.hazardsOnSiteDescription,
      landOwnerPermissionRequired: order.locationA.siteReadiness.landOwnerPermissionRequired,
    },
  },
  locationB: {
    siteConfig: {
      floor: order.locationB.siteConfig.floor,
      room: order.locationB.siteConfig.room,
      suite: order.locationB.siteConfig.suite,
      rackId: order.locationB.siteConfig.rackId,
      connectorType: order.locationB.siteConfig.connectorType,
      mediaType: order.locationB.siteConfig.mediaType,
      powerType: order.locationB.siteConfig.powerType,
      duplexity: order.locationB.siteConfig.duplexity,
      autoNegotiation: order.locationB.siteConfig.autoNegotiation,
    },
    siteContact: {
      firstName: order.locationB.siteContact.firstName,
      surname: order.locationB.siteContact.surname,
      phone: order.locationB.siteContact.phone,
      email: order.locationB.siteContact.email,
      notes: order.locationB.siteContact.notes,
    },
    siteReadiness: {
      accessNotice: order.locationB.siteReadiness.accessNotice,
      moreThanOneTenant: order.locationB.siteReadiness.moreThanOneTenant,
      asbestosRegister: order.locationB.siteReadiness.asbestosRegister,
      hazardsOnSite: order.locationB.siteReadiness.hazardsOnSite,
      hazardsOnSiteDescription: order.locationB.siteReadiness.hazardsOnSiteDescription,
      landOwnerPermissionRequired: order.locationB.siteReadiness.landOwnerPermissionRequired,
    },
  },
});
export const mapOrderTemplateJSONAPIResponse = (
  response: JSONAPIResponse<APIOrderTemplate[]>
): JSONAPIResponse<IOrderTemplate[]> => {
  return {
    data: mapSingleOrArray(response.data, mapOrderTemplateFromAPI),
  };
};
const isV1TemplateBody = (templateBody: APITemplateBody): templateBody is IOrderTemplateStateV1 => {
  return (templateBody as IOrderTemplateStateV1).ipContact !== undefined;
};
export const migrateTemplateBody = (templateBody: APITemplateBody): IOrderTemplateState => {
  if (!isV1TemplateBody(templateBody)) return templateBody;
  else return mapV1TemplateBody(templateBody);
};
const mapV1TemplateBody = (templateBody: IOrderTemplateStateV1): IOrderTemplateState => {
  const { ipContact, ...body } = templateBody;
  return {
    ...body,
    ip: { contact: ipContact, justification: '' },
  };
};
