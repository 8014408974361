import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ICloudProvider } from './types';
import { setCloudProvidersList, setCloudProvider } from 'Quotes/actions';
import { IAppState } from 'reducers';
import CloudProvidersCapture from './CloudProvidersCapture';
import { ProviderName } from 'Quotes/types/store';
import { OnChange } from 'Quotes/types/availabilityCheck';

export interface ICloudProviders {
  className?: string;
  cloudProvidersList: ICloudProvider[];
  selectedProviderName: ProviderName;
  initialisedFromData?: boolean;
  onCloudProvidersFetchSuccess(cloudProviders: ICloudProvider[]): void;
  onCloudProviderSelected(cloudProvider: ICloudProvider): void;
  onAvailabilityCheckChange?: OnChange;
}

const CloudProviders: FunctionComponent<React.PropsWithChildren<ICloudProviders>> = ({
  className,
  cloudProvidersList,
  onCloudProvidersFetchSuccess,
  onCloudProviderSelected,
  selectedProviderName,
  onAvailabilityCheckChange,
  initialisedFromData = false,
}) => {
  const onSelected = (cloudProvider: ICloudProvider) => {
    onCloudProviderSelected(cloudProvider);

    if (typeof onAvailabilityCheckChange === 'function') {
      onAvailabilityCheckChange(
        {
          cloud_connect_provider: cloudProvider.attributes.name,
        },
        'cloud'
      );
    }
  };

  useEffect(() => {
    if (typeof onAvailabilityCheckChange === 'function' && initialisedFromData) {
      onAvailabilityCheckChange(
        {
          cloud_connect_provider: selectedProviderName,
        },
        'cloud'
      );
    }
  }, []);

  return (
    <div className={className}>
      <CloudProvidersCapture
        cloudProvidersList={cloudProvidersList}
        selectedProviderName={selectedProviderName}
        onCloudProvidersFetchSuccess={onCloudProvidersFetchSuccess}
        onCloudProviderSelected={onSelected}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  onCloudProvidersFetchSuccess: (cloudProvidersList: ICloudProvider[]) =>
    dispatch(setCloudProvidersList(cloudProvidersList)),
  onCloudProviderSelected: (cloudProvider: ICloudProvider) => dispatch(setCloudProvider(cloudProvider)),
});

const mapStateToProps = (state: IAppState) => ({
  cloudProvidersList: state.quoteBuilder.quote.location.aEnd.cloudConnect.providersList,
  selectedProviderName: state.quoteBuilder.quote.location.aEnd.cloudConnect.name,
});

export default connect(mapStateToProps, mapDispatchToProps)(CloudProviders);
