import { sharedQuoteOrderTableHeaders } from '../../../../../../shared/utils/shared-quote-order-table-headers';
import TableNames from '../../../../../../Tables/types/tableNames';

const shared = sharedQuoteOrderTableHeaders((row) => row.quote);
const tableName = TableNames.ordersReportTable;

const headers = [
  shared.orderId(tableName, 'orderId'),
  shared.customer(tableName, 'customer'),
  shared.product(tableName, 'product'),
  shared.locations(tableName, 'location'),
  shared.accessProvider(tableName, 'accessProvider'),
  shared.updatedAt(tableName, 'updatedAt'),
  shared.orderStatus(tableName, 'status'),
];

export default headers;
