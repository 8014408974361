import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Modal from '../../../shared/components/molecules/Modal';
import {
  orderIdSelector,
  shortIdSelector,
  submittedForReviewAtSelector,
  submittedForReviewBySelector,
} from '../../selectors';
import { formatDateTimeHuman } from 'shared/utils/dateHelper';
import cancelOrder from '../../crud/cancelOrder';
import Alert, { Alerts } from '../../../shared/components/atoms/Alert';
import Spinner from '../../../shared/components/molecules/SpinnerWithText';
import { ordersList } from '../../routes';
import Button, { ButtonMainStyle } from '../../../shared/components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

type Props = {
  className?: string;
  onClose(): void;
};

const CancelOrder: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const navigate = useNavigate();
  return <InternalCancelOrder {...props} navigate={navigate} />;
};

export const InternalCancelOrder: FunctionComponent<React.PropsWithChildren<Props & Navigation>> = ({
  className,
  onClose,
  navigate,
}) => {
  const shortId = useSelector(shortIdSelector);
  const submittedForReviewAt = useSelector(submittedForReviewAtSelector);
  const submittedForReviewBy = useSelector(submittedForReviewBySelector);
  const orderId = useSelector(orderIdSelector);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onConfirm = async () => {
    setLoading(true);
    setError(false);

    try {
      await cancelOrder(orderId!);
      setSuccess(true);
      setTimeout(() => {
        navigate(ordersList);
      }, 3000);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      disableActions={loading || success}
      title="Cancel this order"
      onConfirm={onConfirm}
      onClose={onClose}
      showCloseBtn={false}
      showConfirmBtn={false}
      className={className}
    >
      <p>Once cancelled, you will not be able to make any further edits, nor add any notes to it.</p>
      <p>Order ID: {shortId}</p>
      {submittedForReviewAt && (
        <p>
          Submitted
          {submittedForReviewBy && <span> by {submittedForReviewBy}</span>} on:{' '}
          {formatDateTimeHuman(submittedForReviewAt)}
        </p>
      )}

      <hr />

      <div className="d-flex justify-content-between">
        <Button mainStyle={ButtonMainStyle.PrimaryOutline} disabled={loading || success} onClick={onConfirm}>
          Yes, cancel it
        </Button>

        <Button
          mainStyle={ButtonMainStyle.PrimaryRectangular}
          disabled={loading || success}
          onClick={onClose}
          className="keep-btn"
        >
          No, I want to keep it
        </Button>
      </div>

      {loading && <Spinner text="Cancelling order" size="large" />}

      {success && (
        <Alert alertType={Alerts.SUCCESS}>
          This order has been successfully cancelled. You will be automatically redirected to the orders screen.
        </Alert>
      )}

      {error && (
        <Alert>
          Error cancelling order. Please try again later. If the problem persists, please contact your Account Manager.
        </Alert>
      )}
    </Modal>
  );
};

export default styled(CancelOrder)`
  .keep-btn {
    width: 190px;
  }
`;
