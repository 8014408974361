import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { showFullName } from '../../../shared/utils/nameHelper';
import { IUserAttributesBaseBackendRoles, IUserAttributesBaseFrontendRoles } from '../../types/user';

interface IViewPersonalInformation {
  userData: IUserAttributesBaseBackendRoles | IUserAttributesBaseFrontendRoles;
  className?: string;
}

export const ViewPersonalInformation: FunctionComponent<React.PropsWithChildren<IViewPersonalInformation>> = ({
  className,
  userData,
}) => {
  return (
    <div className={`${className} personalInformation column pb-3`}>
      <p className="header">Personal information</p>
      <div>
        <p> {userData.jobTitle} </p>
        <p> {userData.companyEmail} </p>
        <p>{showFullName(userData.firstName, userData.lastName)}</p>
        <p> {userData.mobilePhone} </p>
        <p> {userData.landlinePhone} </p>
        <div id="contact-email-row">
          {' '}
          {userData.contactEmail} <p id="contact-mark">Contact</p>{' '}
        </div>
      </div>
    </div>
  );
};

const StyledViewPersonalInformation = styled(ViewPersonalInformation)`
  .personalInformation {
    font-size: 1em;
  }

  .header {
    border-bottom: none;
    font-size: ${(props) => props.theme.typography.fontSizeH4};
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  #contact-email-row {
    display: flex;
  }

  #contact-mark {
    font-size: 0.7em;
    margin-left: 0.5em;
    padding: 0.3em;
    background-color: ${(props) => props.theme.colours.grey10};
  }
`;

export default StyledViewPersonalInformation;
