export default [
  {
    question: 'How do I set up a new user?',
    answer: [
      'To add a new user, you will need to be set up as an administrator.',
      'To add simply:',
      '1. Go to the "User management" tab.',
      '2. Click on a user to view and edit the profile.',
      '3. Click "Add person" to add a new user and set up an account.',
    ],
  },
  {
    question: 'How do I resend the password of a new user?',
    answer: [
      'If a user has been created but has not received their temporary password, you can resend the invite easily via the LIVEQUOTE portal. You will need to be an administrator to do this.',
      'You can resend the email by:',
      '1. Going to the "User management" tab.',
      '2. Clicking on a user to view their profile.',
      '1. If the status is "Invite sent" but seven days or more has elapsed and the user hasn\'t acted on the invite, you can resend the invite and temporary password.',
      '2. If the status is "Invite not sent" you can send an initial invite with a temporary password.',
    ],
  },
  {
    question: 'How do I block a user from accessing the account or reactivate a blocked user?',
    answer: [
      'If you need to block a user from accessing the account or reactivate a blocked user, follow the steps below. You will need to be an administrator to do this.',
      '1. Go to the "User management" tab.',
      '2. Click on a user to view their profile.',
      '3. To block the user from accessing your account select "Block this person from all companies".',
      '4. To reactivate a user click "Activate profile"',
    ],
  },
];
