import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import Button, { IButtonProps } from 'shared/components/atoms/Button';
import { useCountdown } from 'usehooks-ts';
import plugin from 'dayjs/plugin/duration';

export interface IButtonWithTimeoutProps extends IButtonProps, PropsWithChildren<any> {
  timeout: plugin.Duration;
  interval?: plugin.Duration;
  renderWhileCounting?: (countdown: number) => React.ReactNode;
  className?: string;
}
export const ButtonWithTimeout: FC<React.PropsWithChildren<IButtonWithTimeoutProps>> = ({
  className,
  timeout,
  interval = dayjs.duration({ seconds: 1 }),
  children,
  renderWhileCounting,
  ...buttonProps
}) => {
  const countStart = Math.ceil(timeout.asSeconds() / interval.asSeconds());
  const [countdown, { startCountdown, resetCountdown }] = useCountdown({
    countStart,
    intervalMs: interval.asMilliseconds(),
  });
  const [isCountingDown, setIsCountingDown] = useState(false);

  useEffect(() => {
    if (countdown === 0) {
      resetCountdown();
      setIsCountingDown(false);
    }
  }, [countdown]);

  if (isCountingDown && !!renderWhileCounting) return <>{renderWhileCounting(countdown)}</>;

  return (
    <Button
      {...buttonProps}
      className={classNames(className)}
      disabled={isCountingDown}
      onClick={() => {
        if (buttonProps.onClick) buttonProps.onClick();

        startCountdown();
        setIsCountingDown(true);
      }}
    >
      {children}
    </Button>
  );
};
