import React, { FC, useState } from 'react';
import { completeNewPasswordChallenge } from '../cognito';
import Alert from '../../shared/components/atoms/Alert';
import { ErrorMessage, parseErrorMessage } from '../errorMessage';
import UpdatePassword from '../shared/UpdatePassword';
import { useDispatch } from 'react-redux';
import { onResetApp } from 'actions';
import { refreshSessionTimer } from 'App/utils/sessionTimer';
import Wrapper from '../shared/Wrapper';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

export interface ForceUpdatePassword {
  cognitoUser: any;
  userAttributes: any;
}

const ForceUpdatePassword: FC<React.PropsWithChildren<ForceUpdatePassword>> = (props) => {
  const navigate = useNavigate();
  return <ForceUpdatePasswordInternal {...props} navigate={navigate} />;
};

export const ForceUpdatePasswordInternal: FC<React.PropsWithChildren<ForceUpdatePassword & Navigation>> = ({
  navigate,
  cognitoUser,
  userAttributes,
}) => {
  const dispatch = useDispatch();

  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    dispatch(onResetApp());
    refreshSessionTimer();
    navigate('/');
  };

  const onFailure = (errorMessage: ErrorMessage) => {
    setLoading(false);
    setError(parseErrorMessage(errorMessage));
  };

  const completeChange = async (password: string) => {
    setLoading(true);
    completeNewPasswordChallenge(password, cognitoUser, userAttributes, onSuccess, onFailure);
  };

  return (
    <Wrapper>
      <h1>Update Your Password</h1>

      {error && <Alert>{error}</Alert>}

      <UpdatePassword loading={loading} onUpdate={completeChange} />
    </Wrapper>
  );
};

export default ForceUpdatePassword;
