import React, { FunctionComponent } from 'react';
import headers from './headers';
import { IQuoteListItem } from 'Quotes/types/quoteRecord';
import MultiSelectTable, { IRow, RowCell } from 'shared/components/organisms/MultiSelectTable';
import BulkQuoteHeader, { SelectedQuotesPrompt } from './BulkQuoteHeader';
import { isCheckboxCell } from 'shared/utils/tableHelper';
import { quoteById } from 'Quotes/Routes';
import { IRequestState } from 'shared/types/requestState';
import UpdateHeaderCellWidths from 'shared/utils/updateHeaderCellWidth';
import IDownloadQuotesType from 'Quotes/types/downloadQuotes';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface IQuotesFromBulkTable {
  className?: string;
  quotes: IQuoteListItem[];
  companyName: string;
  bulkQuoteId: string;
  quotesCompleted: string;
  selectedQuotes: IRow[];
  downloading: IRequestState;
  onCheckedChange?(items: IRow[]): void;
  downloadQuotes(template: IDownloadQuotesType['template']): void;
}

const selectedQuotesPrompt: SelectedQuotesPrompt = (count) => {
  return <span>{`You have ${count} quote${count > 1 ? 's' : ''} selected, ready to download order templates`}</span>;
};

const QuotesFromBulkTable: FunctionComponent<React.PropsWithChildren<IQuotesFromBulkTable>> = (props) => {
  const navigate = useNavigate();
  return <QuotesFromBulkTableInternal {...props} navigate={navigate} />;
};
export const QuotesFromBulkTableInternal: FunctionComponent<
  React.PropsWithChildren<IQuotesFromBulkTable & Navigation>
> = ({
  quotes,
  companyName,
  bulkQuoteId,
  quotesCompleted,
  onCheckedChange,
  downloadQuotes,
  selectedQuotes,
  downloading,
  navigate,
}) => {
  const rowClickHandler = (cell: RowCell) => {
    if (!isCheckboxCell(cell)) {
      const quotationLineId = cell.row.original.id;
      navigate(quoteById(quotationLineId));
    }
  };

  return (
    <div>
      <BulkQuoteHeader
        downloading={downloading}
        downloadQuotes={downloadQuotes}
        selectedQuotes={selectedQuotes}
        companyName={companyName}
        bulkQuoteId={bulkQuoteId}
        quotesCompleted={quotesCompleted}
        selectedQuotesPrompt={selectedQuotesPrompt}
      />
      <MultiSelectTable
        headers={UpdateHeaderCellWidths(headers, 'bulkQuotesChildTable')}
        rows={quotes}
        onCheckedChange={onCheckedChange}
        rowClickHandler={rowClickHandler}
        className="quotes-from-bulk-table"
        defaultSort={{
          id: 'created_at',
          desc: true,
        }}
      />
    </div>
  );
};

export default QuotesFromBulkTable;
