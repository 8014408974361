import { sharedQuoteOrderTableHeaders } from '../../../../shared/utils/shared-quote-order-table-headers';
import TableNames from '../../../../Tables/types/tableNames';
import { TableHeaderProps } from 'react-table';

const shared = sharedQuoteOrderTableHeaders((row) => row);
const tableName = TableNames.singleQuotesTable;

const headers: TableHeaderProps[] = [
  shared.quoteId('id', tableName, 'quoteId'),
  shared.createdBy(tableName, 'quotedBy'),
  shared.product(tableName, 'product'),
  shared.diverse(tableName, 'diverse'),
  shared.accessTypeForQuote(tableName, 'accessType'),
  shared.locations(tableName, 'location'),
  shared.accessProvider(tableName, 'accessProvider'),
  shared.price(tableName),
  shared.createdAt(tableName, 'createdAt'),
  shared.updatedAt(tableName, 'updatedAt'),
  shared.quoteStatus(tableName, 'status'),
];

export default headers;
