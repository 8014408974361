import { INewNNIRequest, ISiteConfig } from 'Order/types/location';

export const findNNIRequestIndexByPopId = (popId: string, newNNIRequests: INewNNIRequest[]): number =>
  newNNIRequests.findIndex((request) => request.popId === popId);

export const onNewNNIRequestChange = (
  field: keyof INewNNIRequest,
  value: any,
  popId: string,
  siteConfig: ISiteConfig,
  updateSiteConfig: (updated: ISiteConfig) => void
): void => {
  if (!popId) return;

  const existingNNIRequestIndex = findNNIRequestIndexByPopId(popId, siteConfig.new_nni_requests);
  const requestDoesExist = existingNNIRequestIndex !== -1;

  if (requestDoesExist) {
    updateSiteConfig({
      ...siteConfig,
      new_nni_requests: [
        ...siteConfig.new_nni_requests.slice(0, existingNNIRequestIndex),
        {
          ...siteConfig.new_nni_requests[existingNNIRequestIndex],
          [field]: value,
        },
        ...siteConfig.new_nni_requests.slice(existingNNIRequestIndex + 1),
      ],
    });
  }
};

export const findNewNNIRequestByPopId = (
  popId: string | null | undefined,
  newNNIRequests: INewNNIRequest[]
): INewNNIRequest => {
  if (!popId) return {};

  const existingNNIRequestIndex = findNNIRequestIndexByPopId(popId, newNNIRequests);
  const requestDoesExist = existingNNIRequestIndex !== -1;

  if (requestDoesExist) return newNNIRequests[existingNNIRequestIndex];
  return {};
};

export const handleNNIAlreadyOrderedToggle = (
  alreadyOrderedNNI: boolean,
  popId: string,
  siteConfig: ISiteConfig,
  updateSiteConfig: (updated: ISiteConfig) => void,
  defaultNNIRequestContactEmail?: string
) => {
  const nniRequest = siteConfig.new_nni_requests.find((request) => request.popId === popId);
  const allOtherRequests = siteConfig.new_nni_requests.filter((request) => request.popId !== popId);

  if (alreadyOrderedNNI && nniRequest) {
    updateSiteConfig({
      ...siteConfig,
      new_nni_requests: [
        ...allOtherRequests,
        {
          ...nniRequest,
          forDeletion: true,
        },
      ],
    });
  }

  if (!alreadyOrderedNNI && nniRequest) {
    updateSiteConfig({
      ...siteConfig,
      new_nni_requests: [
        ...allOtherRequests,
        {
          ...nniRequest,
          forDeletion: false,
        },
      ],
    });
  }

  if (!alreadyOrderedNNI && !nniRequest) {
    updateSiteConfig({
      ...siteConfig,
      new_nni_requests: [
        ...allOtherRequests,
        {
          popId: popId,
          capacity: undefined,
          termLengthInYears: undefined,
          contactEmail: defaultNNIRequestContactEmail,
        },
      ],
    });
  }
};
