export const MINIMAL_TERM_FOR_AMORTISATION = 3;

const getAmortisedValueForContractTerm = (termInYears: string | undefined): boolean => {
  if (termInYears === undefined) return true;
  const term = parseInt(termInYears, 10);
  if (isNaN(term)) return true;
  return term >= MINIMAL_TERM_FOR_AMORTISATION;
};

export default getAmortisedValueForContractTerm;
