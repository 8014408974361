import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from 'shared/components/atoms/Alert';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import { MultiQuotesTable } from './Table';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import { useTablePaging, SortDirection } from 'shared/hooks/useTablePaging';
import { selectSelectedCompanyId } from 'User/selectors';

const ShowMultiQuotes: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  useTrackPage(pages.multiQuotes);
  const companyId = useSelector(selectSelectedCompanyId);

  const paging = useTablePaging({
    baseUrl: `/customers/${companyId}/bulk-quote-requests`,
    extraParams: {
      [`filter${encodeURIComponent('[multiquote]')}`]: 'true',
    },
    defaultSort: {
      key: 'created_at',
      direction: SortDirection.DESCENDING,
    },
  });

  useEffect(() => {
    if (paging.fetchAttempts >= 1 && !paging.isLoading) {
      paging.refresh();
    }
  }, [companyId]);

  if (paging.fetchAttempts === 1 && !paging.isLoading && paging.error) {
    return <Alert>Error in fetching multi quotes</Alert>;
  }

  if (paging.isLoading && paging.fetchAttempts === 0) {
    return <SpinnerWithText text="Retrieving multi quotes" size="large" />;
  }

  if (paging.fetchAttempts === 1 && !paging.isLoading && paging.results.length === 0) {
    return <p>It looks like you don&apos;t have any multi quotes yet.</p>;
  }

  return <MultiQuotesTable paging={paging} />;
};

export { ShowMultiQuotes };
