import React, { FunctionComponent } from 'react';

import { formatDateTimeHuman } from '../../../shared/utils/dateHelper';
import Column from '../../../shared/components/atoms/Column';

type Props = {
  submittedAt: string;
  submittedBy: string | undefined | null;
};

const Submitted: FunctionComponent<React.PropsWithChildren<Props>> = ({ submittedAt, submittedBy }) => (
  <div className="row no-gutters">
    <Column>
      <p>
        Thank you for submitting this order. Neos Networks will now review this order to check pricing, location and
        configuration / demarcation, and will be in touch shortly to proceed.
      </p>
      <p>
        Submitted{submittedBy && <span>&nbsp;by {submittedBy}</span>}&nbsp;on {formatDateTimeHuman(submittedAt)}
      </p>
    </Column>
  </div>
);

export default Submitted;
