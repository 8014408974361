import React, { FunctionComponent } from 'react';
import Column from 'shared/components/atoms/Column';
import styled from 'styled-components';

type Wrapper = FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
  }>
>;

const Wrapper: Wrapper = ({ children, className }) => {
  return (
    <div className={`${className} row`}>
      <Column defaultWidth={8} lgWidth={6} classNames={['ml-auto']}>
        <div className="wrapper ml-auto">{children}</div>
      </Column>
    </div>
  );
};

export default styled(Wrapper)`
  padding-top: 15vh;

  > div > .wrapper {
    background-color: white;
    border-radius: 6px;
    padding: 1.5em 2em;
    max-width: 500px;

    h1 {
      color: ${(props) => props.theme.colours.primaryA1};
    }

    a {
      color: ${(props) => props.theme.colours.primaryB1};
    }
  }
`;
