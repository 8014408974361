import { Supplier } from 'Quotes/types/supplier';

const showOpenreachCPENotice = (
  connectionType: string | undefined,
  isFTTXQuote: boolean,
  aEndAccessMethod: string | null,
  bEndAccessMethod: string | null,
  selPriceAEndAccessType: string | null,
  selPriceBEndAccessType: string | null
) => {
  if (connectionType === 'Ethernet') {
    return false;
  } else {
    return (
      (isFTTXQuote && aEndAccessMethod === 'EoFTTC' && selPriceAEndAccessType === Supplier.OPENREACH) ||
      (bEndAccessMethod === 'EoFTTC' && selPriceBEndAccessType === Supplier.OPENREACH)
    );
  }
};

export default showOpenreachCPENotice;
