import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import PresentForRoles from '../../Auth/PresentForRoles';
import LinkButton from '../../shared/components/atoms/LinkButton';
import Button, { ButtonMainStyle } from '../../shared/components/atoms/Button';
import { ConnectedBulkUploadSidePanel } from './components/BulkUploadSidePanel';
import HeaderWithChildrenWrapper from '../../shared/components/molecules/HeaderWithChildrenWrapper';
import { userPermissions } from 'shared/utils/permissions';
import APIQuoteSearch from './components/APIQuoteSearch';
import Column from 'shared/components/atoms/Column';
import { resetBulkQuoteSubmitState } from 'Quotes/actions';
import { apiQuotes, newQuote, newQuoteByLocation, quoteList } from 'Quotes/Routes';
import { useDispatch } from 'react-redux';
import { Feature } from 'FeatureFlags/types';
import { useMatch } from 'react-router-dom';
import { Link } from 'shared/RouterComponents';
import { EnabledByFlag } from 'FeatureFlags/EnabledByFlag';

interface IQuotesList {
  className?: string;
}

function CreateQuoteButtons(props: { onClick: () => void }) {
  return (
    <PresentForRoles roles={userPermissions.createEditQuote}>
      <PresentForRoles roles={userPermissions.viewEditBulkQuote}>
        <Button
          onClick={props.onClick}
          className="px-3 mb-md-0 mb-2 upload-link text-center"
          mainStyle={ButtonMainStyle.LinkPrimary}
        >
          Upload Bulk Quote
        </Button>
      </PresentForRoles>
      <LinkButton
        buttonText="New quote by Location"
        pathname={newQuoteByLocation}
        id="new-quote-by-location"
        mainStyle={ButtonMainStyle.PrimaryRectangular}
      />
      <LinkButton
        buttonText="New quote by Product"
        pathname={newQuote}
        id="new-quote-by-product"
        mainStyle={ButtonMainStyle.PrimaryRectangular}
      />
    </PresentForRoles>
  );
}

const QuotesList: FunctionComponent<React.PropsWithChildren<IQuotesList>> = ({ className, children }) => {
  const [hasSidePanel, setHasSidePanel] = useState(false);
  const isApiQuoteList = useMatch(apiQuotes);
  const dispatch = useDispatch();

  return (
    <HeaderWithChildrenWrapper header="Quotes" compact={true}>
      <div className={className}>
        <div className="row mb-2 justify-content-between">
          <Column classNames={['col-md-auto', 'flex-wrap', 'pb-2', 'pb-xl-0']}>
            <Link end to={quoteList}>
              Single
            </Link>
            <PresentForRoles roles={userPermissions.viewEditBulkQuote}>
              <Link end to="bulks">
                Bulk
              </Link>
            </PresentForRoles>
            <PresentForRoles roles={userPermissions.viewEditAPIQuote}>
              <Link end to="api">
                Historical &amp; API
              </Link>
            </PresentForRoles>
            <Link end to="multi">
              Multi
            </Link>
            <EnabledByFlag feature={Feature.cerillionQuote}>
              <Link end to="cerillion">
                Cerillion
              </Link>
            </EnabledByFlag>
          </Column>
          <Column classNames={['col-md-auto', 'flex-wrap', 'd-flex', 'ml-auto', 'justify-content-around']}>
            {isApiQuoteList ? <APIQuoteSearch /> : <CreateQuoteButtons onClick={() => setHasSidePanel(true)} />}
          </Column>
        </div>
        {children}
      </div>
      {hasSidePanel && (
        <ConnectedBulkUploadSidePanel
          onClose={() => {
            setHasSidePanel(false);
            dispatch(resetBulkQuoteSubmitState());
          }}
        />
      )}
    </HeaderWithChildrenWrapper>
  );
};

export default styled(QuotesList)`
  #new-quote-by-location,
  #new-quote-by-product {
    width: auto;
  }

  .upload-link {
    color: ${(props) => props.theme.colours.primaryC1};
  }
`;
