import { doRequest, Methods } from '../../../Request';

const createNote = (quoteId: string, orderId: string, message: string) => {
  return doRequest({
    body: {
      data: {
        attributes: {
          message,
        },
        type: 'order_note',
      },
    },
    method: Methods.POST,
    path: `/quotes/${quoteId}/orders/${orderId}/notes`,
  });
};

export default createNote;
