export enum ProductType {
  P2P = 'P2P',
  P2NNI = 'NNI',
  OpticalP2P = 'OpticalP2P',
  P2CCT = 'P2CCT',
  NNI2CCT = 'NNI2CCT',
  DIA = 'IA',
}

export interface IProductType {
  label: string;
  value: ProductType;
}

export const P2P: IProductType = {
  label: 'Ethernet Point to Point',
  value: ProductType.P2P,
};

export const P2NNI: IProductType = {
  label: 'Ethernet Point to NNI',
  value: ProductType.P2NNI,
};

export const DIA: IProductType = {
  label: 'Dedicated Internet Access (DIA)',
  value: ProductType.DIA,
};

export const OpticalP2P: IProductType = {
  label: 'Optical Point to Point',
  value: ProductType.OpticalP2P,
};

export const P2CCT: IProductType = {
  label: 'Point to Cloud Connect',
  value: ProductType.P2CCT,
};

export const NNI2CCT: IProductType = {
  label: 'NNI to Cloud Connect',
  value: ProductType.NNI2CCT,
};

export const productTypes = [P2NNI, P2P, DIA, OpticalP2P, P2CCT, NNI2CCT];

export default productTypes;
