import React, { FunctionComponent } from 'react';
import HeaderWithChildrenWrapper from '../../shared/components/molecules/HeaderWithChildrenWrapper';
import styled from 'styled-components';
import { ordersReport, quotesReport, userDataReport } from '../routes';
import { NavLinkWithActive } from 'shared/RouterComponents';

interface IReportDownload {
  className?: string;
}

const ReportDownload: FunctionComponent<React.PropsWithChildren<IReportDownload>> = ({ className, children }) => {
  return (
    <HeaderWithChildrenWrapper
      className={className}
      header="Reporting"
      subHeader="Extract data from the last six months for additional analysis outside of the portal."
      compact={true}
    >
      <div className="nav-links">
        <div className="report-buttons">
          <NavLinkWithActive to={ordersReport} end className="nav-link orders-link">
            Orders
          </NavLinkWithActive>
          <NavLinkWithActive to={quotesReport} end className="nav-link orders-link">
            Quotes
          </NavLinkWithActive>
          <NavLinkWithActive to={userDataReport} end className="nav-link users-link">
            User Data
          </NavLinkWithActive>
        </div>

        {children}
      </div>
    </HeaderWithChildrenWrapper>
  );
};

const StyledReportDownload = styled(ReportDownload)`
  height: 100%;

  .nav-link {
    color: ${(props) => props.theme.colours.grey70};
    display: inline-block;
    font-size: 1.75rem;
    margin: 0 1em;
    padding: 0;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: ${(props) => props.theme.colours.primaryB1};
      text-decoration: none;
    }

    &.active {
      border-bottom: 3px solid ${(props) => props.theme.colours.primaryB1};
      color: ${(props) => props.theme.colours.primaryB1};
    }
  }

  .report-buttons {
    padding: 0 1em;
  }

  .reports-filters__wrapper {
    margin-top: 1em;
    background-color: ${(props) => props.theme.colours.secondaryC5};
    padding: 1em;

    .control {
      margin-right: 1em;

      label {
        color: ${(props) => props.theme.colours.grey70};
        margin-bottom: 0.1em;
      }

      .select-input {
        width: 10em;
      }
    }
  }
`;

export default StyledReportDownload;
