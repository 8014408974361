import React, { FunctionComponent } from 'react';
import TextInput from '../../../../../../../shared/components/atoms/TextInput';

interface IEndCompanyName {
  className?: string;
  identifierPrefix: string;
  endCompanyName: string;
  setEndCompanyName(companyName: string): void;
}

const EndCompanyName: FunctionComponent<React.PropsWithChildren<IEndCompanyName>> = ({
  identifierPrefix,
  endCompanyName,
  setEndCompanyName,
}) => (
  <div className="col-10 pl-0">
    <div className={`${identifierPrefix} endCustomerName`}>
      <TextInput
        maxLength={50}
        fieldName="end company name"
        inputIdentifierPrefix={identifierPrefix}
        value={endCompanyName}
        onChange={(e) => {
          setEndCompanyName(e.target.value);
        }}
        labelText="End Company Name *"
      />
    </div>
  </div>
);

export default EndCompanyName;
