import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import TableNames from 'Tables/types/tableNames';
import ServerSidePaginationBar from 'shared/components/molecules/ServerSidePaginationBar';
import PaginationBar from 'shared/components/molecules/PaginationBar';
import React from 'react';

export const Pagination = ({
  defaultPageSize,
  instance,
  serverSidePaged,
  storeTableName,
}: {
  serverSidePaged: IServerSidePaging | undefined;
  storeTableName: TableNames | undefined;
  instance: any;
  defaultPageSize: number | undefined;
}) => {
  if (serverSidePaged) {
    return <ServerSidePaginationBar paging={serverSidePaged} storeTableName={storeTableName} />;
  } else {
    return (
      <PaginationBar
        canNextPage={instance.canNextPage}
        canPreviousPage={instance.canPreviousPage}
        gotoPage={instance.gotoPage}
        nextPage={instance.nextPage}
        pageCount={instance.pageOptions.length}
        pageIndex={instance.state.pageIndex}
        pageSize={instance.state.pageSize}
        previousPage={instance.previousPage}
        totalElements={instance.rows.length}
        setPageSize={instance.setPageSize}
        defaultPageSize={defaultPageSize}
        storeTableName={storeTableName}
      />
    );
  }
};
