import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import Column from '../../../../../shared/components/atoms/Column';
import CardRow from '../../../../../shared/components/molecules/CardRow';
import { IContact } from '../../../../types/contact';
import Form from '../Form';
import { IOrder } from '../../../../types/store';
import ExistingContactOptions, { ContactIdentifierType } from '../../../shared/components/ExistingContactOptions';

interface IEditProps {
  className?: string;
  contact: IContact;
  contactIdentifier: ContactIdentifierType;
  description: string;
  order: IOrder;
  title: string;
  onChange(updated: IContact): void;
}

const Edit: FunctionComponent<React.PropsWithChildren<IEditProps>> = ({
  className,
  contact,
  contactIdentifier,
  description,
  order,
  title,
  onChange,
}) => {
  return (
    <CardRow className={className} title={title}>
      <div className="row no-gutters">
        <Column defaultWidth={4} offsetDefaultWidth={1}>
          {description}
          <ExistingContactOptions contact={contact} contactIdentifier={contactIdentifier} order={order} />
        </Column>
        <Column defaultWidth={6} offsetDefaultWidth={1}>
          <Form title={title} contact={contact} onChange={onChange} />
        </Column>
      </div>
    </CardRow>
  );
};

const StyledEdit = styled(Edit)`
  margin: 1.5em 0;
`;

export default StyledEdit;
