import { ErrorResponse } from 'Request';
import { IAlertsRecord } from './alertsRecord';

export enum AlertActionTypes {
  GET_ALERTS = 'GET_ALERTS',
  GET_ALERTS_STARTED = 'GET_ALERTS_STARTED',
  GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS',
  GET_ALERTS_ERROR = 'GET_ALERTS_ERROR',
  GET_NEXT_ALERTS = 'GET_NEXT_ALERTS',
  GET_NEXT_ALERTS_STARTED = 'GET_NEXT_ALERTS_STARTED',
  GET_NEXT_ALERTS_SUCCESS = 'GET_NEXT_ALERTS_SUCCESS',
  GET_NEXT_ALERTS_ERROR = 'GET_NEXT_ALERTS_ERROR',
  MARK_ALERT_AS_READ = 'MARK_ALERT_AS_READ',
}

export interface IGetAlerts {
  type: AlertActionTypes.GET_ALERTS;
}

export interface IGetAlertsStarted {
  type: AlertActionTypes.GET_ALERTS_STARTED;
}

export interface IGetAlertsSuccess {
  type: AlertActionTypes.GET_ALERTS_SUCCESS;
  payload: {
    alertsRecord: IAlertsRecord;
  };
}

export interface IGetAlertsError {
  payload: { response: ErrorResponse | null };
  type: AlertActionTypes.GET_ALERTS_ERROR;
}

export interface IGetNextAlerts {
  type: AlertActionTypes.GET_NEXT_ALERTS;
  payload: {
    url: string;
  };
}

export interface IGetNextAlertsStarted {
  type: AlertActionTypes.GET_NEXT_ALERTS_STARTED;
}

export interface IGetNextAlertsSuccess {
  type: AlertActionTypes.GET_NEXT_ALERTS_SUCCESS;
  payload: {
    alertsRecord: IAlertsRecord;
  };
}

export interface IGetNextAlertsError {
  payload: { response: ErrorResponse | null };
  type: AlertActionTypes.GET_NEXT_ALERTS_ERROR;
}

export interface IMarkAlertAsRead {
  payload: { alertId: string };
  type: AlertActionTypes.MARK_ALERT_AS_READ;
}

export type AlertActions =
  | IGetAlerts
  | IGetAlertsSuccess
  | IGetAlertsError
  | IGetAlertsStarted
  | IGetNextAlerts
  | IGetNextAlertsSuccess
  | IGetNextAlertsError
  | IGetNextAlertsStarted
  | IMarkAlertAsRead;
