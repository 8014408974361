import {
  APIPromoOffersRecord,
  APIUpdatePromoOffersResponse,
  IPromoOffer,
  toPromoOffer,
  toPromoRecord,
} from 'PromoOffer/promoTypes';
import { UpdatePromotionBody } from 'PromoOffer/crud/updatePromoOffer';
import { baseApi } from 'apiSlice';

type UpdatePromotionParameters = { promoId: string; data: UpdatePromotionBody };

const promoApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPromotions: build.query<IPromoOffer[], void>({
      query: () => 'promotions',
      providesTags: ['Promotions'],
      transformResponse: (response: APIPromoOffersRecord): IPromoOffer[] => toPromoRecord(response),
    }),
    updatePromotion: build.mutation<IPromoOffer, UpdatePromotionParameters>({
      invalidatesTags: ['Promotions'],
      query: (updatedPromotion) => {
        return {
          url: `promotions/${updatedPromotion.promoId}`,
          method: 'PATCH',
          body: updatedPromotion.data,
        };
      },
      transformResponse: (response: APIUpdatePromoOffersResponse): IPromoOffer => toPromoOffer(response.data),
    }),
  }),
});

const { useGetPromotionsQuery, useUpdatePromotionMutation } = promoApi;

interface UsePromotions {
  isLoading: boolean;
  promotions: IPromoOffer[] | undefined;
  currentPromotion: IPromoOffer | undefined;
  updatePromotion: ({ promoId, data }: UpdatePromotionParameters) => void;
}

export const usePromotions = (): UsePromotions => {
  const promotions = useGetPromotionsQuery();
  const [updatePromotion, { isLoading }] = useUpdatePromotionMutation();

  const currentPromotion = promotions.currentData?.[0];
  return {
    isLoading: promotions.isLoading || isLoading,
    promotions: promotions.currentData,
    currentPromotion: currentPromotion,
    updatePromotion: updatePromotion,
  };
};
