import { IPriceData, IQuotesState, ProductSubType, ProviderName } from 'Quotes/types/store';
import { ConnectionType } from 'Quotes/types/connectionType';
import { Supplier } from 'Quotes/types/supplier';
import { ProductType } from 'Quotes/types/productTypes';
import { StyledP2P as P2P } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider/P2P';
import { StyledP2NNI as P2NNI } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider/P2NNI';
import { StyledOpticalP2P as OpticalP2P } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider/OpticalP2P';
import { StyledDIA as DIA } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider/DIA';
import { StyledP2CCT as P2CCT } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider/P2CCT';
import { StyledNNI2CCT as NNI2CCT } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider/NNI2CCT';
import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'reducers';
import { selectNNILabelFromQuote } from 'Quotes/selectors';
import { ContractTerm } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import { createSelector } from '@reduxjs/toolkit';
import { getSupplier } from 'Quotes/utils/supplierHelpers';

export interface CommonProps {
  isPoA: boolean;
  provider: ProviderName;
  amortisedAnnualPrice: number | null;
  selected: boolean;
  priceId: string;
  aEndAccessMethod: ConnectionType;
  bEndAccessMethod: ConnectionType;
  aEndBandwidth: string | null;
  bEndBandwidth: string | null;
  aEndPrice: number | null;
  bEndPrice: number | null;
  aEndPostcode: string;
  bEndPostcode: string;
  aEndSupplier: Supplier;
  bEndSupplier: Supplier;
  annualIPCharge: number | null;
  product_sub_type: ProductSubType | null;
  onClick: () => void;
  readonly: boolean;
  bandwidth: string | undefined;
  term_length_in_months: ContractTerm | null | undefined;
  supportsSecondaryCircuits: boolean;
}

export function getProps(
  priceData: IPriceData,
  isPriceSelected: boolean,
  aEndBandwidth: string | null,
  bEndBandwidth: string | null,
  aEndPostcode: string,
  bEndPostcode: string,
  onPriceItemClick: (priceId: string) => void,
  readOnly: boolean,
  supportsSecondaryCircuits: boolean
): CommonProps {
  return {
    isPoA: priceData.is_poa || false,
    provider: priceData.cloud_connect?.provider || ProviderName.NOT_DEFINED,
    amortisedAnnualPrice: priceData.net_amortised_annual_price,
    selected: isPriceSelected,
    priceId: priceData.id,
    aEndAccessMethod: priceData.product_sub_type === 'optical' ? 'Optical' : 'Ethernet',
    bEndAccessMethod: priceData.product_sub_type === 'optical' ? 'Optical' : 'Ethernet',
    aEndBandwidth: priceData.bandwidth || aEndBandwidth,
    bEndBandwidth: priceData.bandwidth || bEndBandwidth,
    aEndPrice: priceData.a_end_annual_cost,
    bEndPrice: priceData.b_end_annual_cost,
    aEndPostcode,
    bEndPostcode,
    aEndSupplier: getSupplier(priceData.a_end_access_type as Supplier, priceData.is_poa),
    bEndSupplier: getSupplier(priceData.b_end_access_type as Supplier, priceData.is_poa),
    annualIPCharge: priceData.annual_ip_charge,
    product_sub_type: priceData.product_sub_type,
    onClick: () => {
      onPriceItemClick(priceData.id);
    },
    readonly: readOnly,
    bandwidth: priceData.bandwidth,
    term_length_in_months: priceData.term_length_in_months,
    supportsSecondaryCircuits: supportsSecondaryCircuits,
  };
}

interface PriceTileParams {
  readOnly: boolean;
  priceData: IPriceData;
  priceSelected: boolean;
  onClick: () => void;
  onPriceItemClick: (priceId: string) => void;
  supportsSecondaryCircuits: boolean;
}

export interface PriceTileReduxParams {
  aEndBandwidth: string | null;
  aEndDataCentreName: string | null | undefined;
  aEndNniShadowDataCentreReference: string | undefined;
  aEndNniShadowReference: string | undefined;
  aEndPostcode: string;
  bEndBandwidth: string | null;
  bEndDataCentreName: string | null | undefined;
  bEndDataCentreReference: string | undefined;
  bEndPostcode: string;
  dataCentreReferenceAEnd: string | undefined;
  nniLabel: string | null | undefined;
  nniLocation: string | null | undefined;
  nniPopName: string | undefined;
  nniReference: string | undefined;
  productType: ProductType | null | undefined;
  shadowVlanLocation: string | undefined;
}

export const PriceTile = (props: PriceTileParams & PriceTileReduxParams) => {
  const commonProps = getProps(
    props.priceData,
    props.priceSelected,
    props.aEndBandwidth,
    props.bEndBandwidth,
    props.aEndPostcode,
    props.bEndPostcode,
    props.onPriceItemClick,
    props.readOnly,
    props.supportsSecondaryCircuits
  );

  // TODO: Work out how to make the compiler throw an error if we don't handle every product type
  return (
    <div>
      {props.productType === ProductType.P2P && (
        <div onClick={props.onClick}>
          <P2P {...commonProps} />
        </div>
      )}
      {props.productType === ProductType.P2NNI && (
        <div onClick={props.onClick}>
          <P2NNI
            nniReference={props.nniReference || props.dataCentreReferenceAEnd}
            nniPopName={props.nniPopName}
            nniLabel={props.nniLabel}
            nniLocation={props.nniLocation}
            nniShadowReference={
              props.aEndNniShadowReference || props.aEndNniShadowDataCentreReference || props.shadowVlanLocation
            }
            {...commonProps}
          />
        </div>
      )}
      {props.productType === ProductType.OpticalP2P && (
        <div onClick={props.onClick}>
          <OpticalP2P
            a_end_data_centre_reference={props.dataCentreReferenceAEnd}
            b_end_data_centre_reference={props.bEndDataCentreReference}
            a_end_data_centre_name={props.aEndDataCentreName}
            b_end_data_centre_name={props.bEndDataCentreName}
            {...commonProps}
          />
        </div>
      )}
      {props.productType === ProductType.DIA && (
        <div onClick={props.onClick}>
          <DIA {...commonProps} />
        </div>
      )}
      {props.productType === ProductType.P2CCT && (
        <div onClick={props.onClick}>
          <P2CCT cloudConnectAnnual={props.priceData.cloud_connect?.annual ?? null} {...commonProps} />
        </div>
      )}
      {props.productType === ProductType.NNI2CCT && (
        <NNI2CCT
          nniReference={props.nniReference || props.dataCentreReferenceAEnd}
          nniPopName={props.nniPopName}
          nniLabel={props.nniLabel}
          nniLocation={props.nniLocation}
          cloudConnectAnnual={props.priceData.cloud_connect?.annual ?? null}
          {...commonProps}
        />
      )}
    </div>
  );
};

export const selectPriceTileReduxParams = createSelector(
  (state: IAppState) => state.quoteBuilder,
  (quoteBuilder: IQuotesState): PriceTileReduxParams => {
    return {
      aEndBandwidth: quoteBuilder.quote.aEndBandwidth,
      aEndDataCentreName: quoteBuilder.quote.location.aEnd.fullAddress?.attributes.name,
      aEndNniShadowDataCentreReference: quoteBuilder.quoteEndpointMeta?.a_end_nni_shadow_data_centre_reference,
      aEndNniShadowReference: quoteBuilder.quoteEndpointMeta?.a_end_nni_shadow_reference,
      aEndPostcode: quoteBuilder.quote.location.aEnd.postcode,
      bEndBandwidth: quoteBuilder.quote.bEndBandwidth,
      bEndDataCentreName: quoteBuilder.quote.location.bEnd.fullAddress?.attributes.name,
      bEndDataCentreReference: quoteBuilder.quote.location.bEnd.dataCentreReference,
      bEndPostcode: quoteBuilder.quote.location.bEnd.postcode,
      dataCentreReferenceAEnd: quoteBuilder.quote.location.aEnd.dataCentreReference,
      nniLabel: selectNNILabelFromQuote(quoteBuilder),
      nniLocation: quoteBuilder.quote.location.aEnd.fullAddress?.attributes.location,
      nniPopName: quoteBuilder.quote.location.aEnd.nni.popName,
      nniReference: quoteBuilder.quote.location.aEnd.nni.reference,
      productType: quoteBuilder.quote.productType,
      shadowVlanLocation: quoteBuilder.quote.location.aEnd.nni.shadowVLAN.location,
    };
  }
);

const OutputPriceTile = (props: PriceTileParams) => {
  const reduxProps: PriceTileReduxParams = useSelector(selectPriceTileReduxParams);
  return <PriceTile {...props} {...reduxProps} />;
};

export default OutputPriceTile;
