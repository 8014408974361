import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import CardWithInitialText, { CardState } from 'shared/components/organisms/CardWithInitialText';
import OrderCardFooter from 'shared/components/molecules/OrderCardFooter';
import { IAppState } from 'reducers';
import { editOrderAction, editOrderState } from 'Order/actions';
import { isCompleted } from '../shared/utils/billingAndContactComplete';
import { OrderUpdateFields } from 'Order/types/formFields';
import { IOrder } from 'Order/types/store';
import { BillingContactAndAddress } from './BillingContactAndAddress';
import { IPContact } from './IPContact';
import { Contact } from './Contact';
import { usePreviousState } from 'shared/utils/customHooks';
import { ContactIdentifierType } from '../shared/components/ExistingContactOptions';
import { ProductType } from 'Quotes/types/productTypes';
import { pagesWithType, trackPage } from 'shared/utils/trackPage';
import { IQuote } from 'Quotes/types/store';
import { noop } from 'lodash';
import { CopyFromQuoteButton } from '../CopyFromQuote/CopyFromQuoteButton';

interface IBillingAndContactProps {
  cardState: CardState;
  className?: string;
  orderId: string;
  order: IOrder;
  quote: IQuote;
  saving?: boolean;
  editOrder(order: IOrder): void;
  onSaveClick(orderId: string, data: OrderUpdateFields): void;
  setCardState(cardState: CardState): void;
}

const initialList = (productType: ProductType | null) => {
  const list = ['Add contact details for operations and order management', 'Confirm billing information'];

  if (productType === ProductType.DIA) {
    list.push('Enter IP address information');
  }

  return list;
};

const BillingAndContact: FunctionComponent<React.PropsWithChildren<IBillingAndContactProps>> = ({
  cardState,
  className,
  editOrder,
  onSaveClick,
  orderId,
  order,
  quote,
  saving,
  setCardState,
}) => {
  const prevSaving = usePreviousState(saving);
  const canEdit = cardState === CardState.Edit;
  const data: OrderUpdateFields = {
    billingContact: order.billingContactAndAddress,
    billingFrequency: order.billingFrequency,
    orderContact: order.orderContact,
    operationalContact: order.operationalContact,
    ipContact: order.ipContact,
    ipText: order.ipText,
    secondaryIPDetails: order.secondaryIPDetails,
    wan_ip_in_addition: order.wan_ip_in_addition,
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCardState(CardState.Loading);
    onSaveClick(orderId, data);
  };

  useEffect(() => {
    if (order.productType && cardState === CardState.Edit) {
      trackPage(pagesWithType.orderBilling(order.productType));
    }
  }, [order.productType, cardState]);

  useEffect(() => {
    if (prevSaving && !saving && cardState === CardState.Loading) {
      setCardState(CardState.Summary);
    }
  }, [cardState, prevSaving, saving, setCardState]);

  return (
    <form onSubmit={onSubmit} className={className}>
      <CardWithInitialText
        className="order-billing-and-contact-section"
        completed={isCompleted(order, quote)}
        initialList={initialList(order.productType)}
        loadingText="Saving"
        onEditClick={() => setCardState(CardState.Edit)}
        cardState={cardState}
        title="Billing &amp; contact information"
        extraButtons={
          !!quote.bulkQuoteId && (
            <CopyFromQuoteButton
              orderId={orderId}
              bulkQuoteId={quote.bulkQuoteId}
              sectionToCopy="billing-and-contact-info"
              onCopyComplete={() => {
                setCardState(CardState.Edit);
              }}
            />
          )
        }
      >
        <Contact
          title="Order Delivery contact"
          description="Order Delivery contact is the main contact for this order and receives all updates and queries relating to this order."
          contact={order.orderContact}
          contactIdentifier={ContactIdentifierType.orderContact}
          canEdit={canEdit}
          order={order}
          onChange={(c) => editOrder({ ...order, orderContact: c })}
        />
        <Contact
          title="Operational contact"
          description="The operational contact is responsible for the day to day management of this service, and any BAU matters arising."
          contact={order.operationalContact}
          contactIdentifier={ContactIdentifierType.operationalContact}
          canEdit={canEdit}
          order={order}
          onChange={(c) => editOrder({ ...order, operationalContact: c })}
        />
        <Contact
          title="Service Delivery contact"
          description="Service delivery are in charge of delivering the service on your side."
          contact={order.serviceDeliveryContact}
          contactIdentifier={ContactIdentifierType.serviceDeliveryContact}
          canEdit={canEdit}
          order={order}
          onChange={noop}
        />
        <BillingContactAndAddress order={order} canEdit={canEdit} />

        {order.productType === ProductType.DIA && <IPContact canEdit={canEdit} />}

        {cardState === CardState.Edit && <OrderCardFooter />}
      </CardWithInitialText>
    </form>
  );
};

const mapStateToProps = ({
  orderBuilder: {
    id,
    order,
    updating: { inProgress },
  },
  quoteBuilder: { quote },
}: IAppState) => ({
  orderId: id!,
  order,
  quote,
  saving: inProgress,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  onSaveClick: (orderId: string, data: OrderUpdateFields) => {
    dispatch(editOrderAction(orderId, data));
  },
  editOrder: (order: IOrder) => {
    dispatch(editOrderState(order));
  },
});

export { BillingAndContact };
export default connect(mapStateToProps, mapDispatchToProps)(BillingAndContact);
