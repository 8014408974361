import { ProductType } from '../types/productTypes';
import { IQuoteListItem } from '../types/quoteRecord';
import { APIQuoteListItem } from '../types/apiQuote';
import { Supplier } from '../types/supplier';
import { getSupplier, presentSupplierName } from './supplierHelpers';
import { findProductTypeLabelForValue } from './findProductTypeLabelForValue';
import { ProviderName } from '../types/store';
import { QuoteStatus } from '../types/quoteRecordAttributesBase';
import { ConnectionType } from 'Quotes/types/connectionType';

export const formatProductType = (productType: ProductType | undefined | null): string => {
  switch (productType) {
    case ProductType.P2P:
      return 'P2P';
    case ProductType.P2NNI:
      return 'P2NNI';
    case ProductType.DIA:
      return 'DIA';
    case ProductType.OpticalP2P:
      return 'optP2P';
    case ProductType.P2CCT:
      return 'P2CC';
    case ProductType.NNI2CCT:
      return 'NNI2CC';
    default:
      return findProductTypeLabelForValue(productType) || '';
  }
};

const isAPIQuoteListItem = (row: Record<string, any>): row is APIQuoteListItem =>
  Object.prototype.hasOwnProperty.call(row, 'start_postcode');

export const formatQuoteLocation = (row: IQuoteListItem | APIQuoteListItem): string => {
  const aEndPostcode = isAPIQuoteListItem(row) ? row.start_postcode : row.a_end_postcode;
  const bEndPostcode = isAPIQuoteListItem(row) ? row.end_postcode : row.b_end_postcode;
  const nniLabel = isAPIQuoteListItem(row) ? '' : row.meta?.nni_label;

  return getLocationStringForProduct(
    row.product_type,
    aEndPostcode,
    bEndPostcode,
    row.a_end_n_n_i_reference,
    row.a_end_data_centre_reference,
    row.b_end_data_centre_reference,
    row.a_end_data_centre_name,
    row.b_end_data_centre_name,
    nniLabel
  );
};

export const formatAccessProvider = (row: IQuoteListItem): string | undefined => {
  const { selected_price, cloud_connect_option, is_poa } = row;

  if (!selected_price && is_poa) {
    return presentSupplierName(Supplier.NONE); // has price: PoA
  }

  if (selected_price) {
    const { a_end_access_type, b_end_access_type } = selected_price;

    if (row.product_type === ProductType.P2P || row.product_type === ProductType.OpticalP2P) {
      return formatAccessProviderForProductType(
        row.product_type,
        getSupplier(a_end_access_type as Supplier, is_poa ?? false),
        getSupplier(b_end_access_type as Supplier, is_poa ?? false)
      );
    } else {
      return formatAccessProviderForProductType(
        row.product_type,
        a_end_access_type || '',
        b_end_access_type || '',
        cloud_connect_option?.provider || ''
      );
    }
  }

  return undefined;
};

export const formatAccessType = (
  productType: ProductType | undefined,
  aEndAccessMethod: ConnectionType | string,
  bEndAccessMethod: ConnectionType | string
): string => {
  if (!aEndAccessMethod && !bEndAccessMethod) return '';

  switch (productType) {
    case ProductType.P2NNI:
      return bEndAccessMethod;
    case ProductType.DIA:
      return aEndAccessMethod;
    case ProductType.P2CCT:
      return aEndAccessMethod;
    case ProductType.NNI2CCT:
      return '';
    default:
      return `${aEndAccessMethod} / ${bEndAccessMethod}`;
  }
};

export const formatAccessProviderForProductType = (
  productType: ProductType | undefined,
  aEndAccessType: Supplier | string,
  bEndAccessType: Supplier | string,
  provider?: ProviderName | string | null
): string => {
  const aEnd = presentSupplierName(aEndAccessType);
  const bEnd = presentSupplierName(bEndAccessType);

  switch (productType) {
    case ProductType.P2NNI:
      // 'Not relevant for NNI' check to support compatibility for legacy access type value
      return aEnd && aEnd !== 'Not relevant for NNI' ? `${bEnd} / ${aEnd}` : bEnd;
    case ProductType.DIA:
      return aEnd;
    case ProductType.P2CCT:
      return `${aEnd} / ${provider ? provider : 'Cloud Provider'}`;
    case ProductType.NNI2CCT:
      return `${aEnd} / ${provider ? provider : 'Cloud Provider'}`;
    case ProductType.OpticalP2P:
      return `${aEnd} / ${bEnd}`;
    case ProductType.P2P:
      return `${aEnd} / ${bEnd}`;
    default:
      return `${aEnd} / ${bEnd}`;
  }
};

const getLocationStringForProduct = (
  product_type: string | undefined,
  aEndPostcode: string | undefined,
  bEndPostcode: string | undefined,
  a_end_n_n_i_reference: string | null | undefined,
  a_end_data_centre_reference: string | null | undefined,
  b_end_data_centre_reference: string | null | undefined,
  a_end_data_centre_name: string | null | undefined,
  b_end_data_centre_name: string | null | undefined,
  nniLabel?: string | null | undefined
): string => {
  const isExistingNNILocation = (
    dataCentreReference: string | null | undefined,
    nniReference: string | null | undefined
  ): boolean => {
    return !!dataCentreReference && !!nniReference;
  };

  switch (product_type) {
    case ProductType.P2NNI:
      if (nniLabel) {
        return `${bEndPostcode} / ${nniLabel}`;
      } else {
        if (isExistingNNILocation(a_end_data_centre_reference, a_end_n_n_i_reference)) {
          return `${bEndPostcode} / ${a_end_data_centre_reference} - ${a_end_n_n_i_reference}`;
        }
        return `${bEndPostcode} / ${a_end_n_n_i_reference || a_end_data_centre_reference || 'NNI'}`;
      }
    case ProductType.DIA:
      return aEndPostcode || '';
    case ProductType.OpticalP2P:
      return `${a_end_data_centre_name || a_end_data_centre_reference || aEndPostcode || ''} / ${
        b_end_data_centre_name || b_end_data_centre_reference || bEndPostcode || ''
      }`;
    case ProductType.P2CCT:
      return aEndPostcode || '';
    case ProductType.NNI2CCT:
      if (nniLabel) {
        return nniLabel;
      } else {
        if (isExistingNNILocation(a_end_data_centre_reference, a_end_n_n_i_reference)) {
          return `${a_end_data_centre_reference} - ${a_end_n_n_i_reference}`;
        }
        return `${a_end_n_n_i_reference || a_end_data_centre_reference || 'NNI'}`;
      }
    default:
      // Point 2 Point quotes can also be optical and just have data centre values set.
      if (a_end_data_centre_reference && b_end_data_centre_reference) {
        return `${a_end_data_centre_reference} / ${b_end_data_centre_reference}`;
      }

      return `${aEndPostcode} / ${bEndPostcode}`;
  }
};

export const formatQuoteState = (item: IQuoteListItem | string) =>
  (typeof item === 'string' ? item : item.state || '').replace(QuoteStatus.ORDERED, 'quote to order');
