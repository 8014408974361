import React, { FunctionComponent } from 'react';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';

interface Props {
  setFilter(id: string | null): void;
}

const options = [
  {
    label: '0-100',
    value: '0-100',
  },
  {
    label: '100-1000',
    value: '100-1000',
  },
  {
    label: '1000-10000',
    value: '1000-10000',
  },
];

export const BandwidthFilter: FunctionComponent<React.PropsWithChildren<Props>> = ({ setFilter }) => (
  <div className="control">
    <label>Bandwidth</label>
    <Dropdown
      className="select-input bandwidth-dropdown"
      options={options}
      onChange={(item: IDropdownOption) => setFilter(item ? item.value : null)}
    />
  </div>
);
