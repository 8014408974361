import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'reducers';
import { ISetIsRackMountRequired } from 'Quotes/types/actions';
import { setIsRackMountRequired as setIsRackMountRequiredAction } from 'Quotes/actions';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import { BooleanOptionGroup } from 'shared/components/organisms/OptionGroup/utils/BooleanOptionGroup';
import { getPortSpeedFromPortString } from 'shared/utils/connectionCapacity';

const RackMountSelection = () => {
  const isRackMountRequired = useSelector(
    (state: IAppState) => state.quoteBuilder.quote.location.aEnd.is_rack_mount_required
  );

  const dispatch = useDispatch();
  const setIsRackMountRequired = (
    is_rack_mount_required: ISetIsRackMountRequired['payload']['is_rack_mount_required']
  ) => dispatch(setIsRackMountRequiredAction(is_rack_mount_required));

  return <Primary onClick={setIsRackMountRequired} value={isRackMountRequired} />;
};

function Primary(props: { onClick: (value: boolean) => void; value: boolean }) {
  return (
    <>
      <div data-testid="mdia-rack-section">
        <p className="mt-4 mb-2">Do you need a rack mount kit?</p>
        <p className="text-muted mb-4">Free only with a 10Gbps bearer selection.</p>
        <BooleanOptionGroup onClick={props.onClick} value={props.value} customIdBase="is_rack_mount_required" />
      </div>
    </>
  );
}

function Secondary(props: { onClick: (value: boolean) => void; value: boolean; isReadOnly?: boolean }) {
  return (
    <>
      <div data-testid="mdia-rack-section">
        <br />
        <p className="text-muted mb-4">Do you need a second rack mount kit?</p>
        <BooleanOptionGroup
          onClick={props.onClick}
          value={props.value}
          customIdBase="is_rack_mount_required"
          readOnly={props.isReadOnly}
        />
      </div>
    </>
  );
}

const FreeRackMountKit = () => (
  <Alert alertType={Alerts.INFO} className="mt-4 mb-2">
    Your order qualifies for a free rack mount kit
  </Alert>
);

const RackMountKitFreeCheck: FC<PropsWithChildren> = ({ children }) => {
  const bearer = useSelector((state: IAppState) => state.quoteBuilder.quote.bearer);
  const isBearerLarge = typeof bearer !== 'undefined' && parseInt(getPortSpeedFromPortString(bearer), 10) > 1000;
  if (isBearerLarge) return <FreeRackMountKit />;
  else return <>{children}</>;
};

export const RackMountKit = () => {
  return (
    <RackMountKitFreeCheck>
      <RackMountSelection />
    </RackMountKitFreeCheck>
  );
};

export const SecondaryRackMountKit = ({
  rackMountKit,
  setRackMountKit,
  isReadOnly,
}: {
  rackMountKit: boolean;
  setRackMountKit: (required: boolean) => void;
  isReadOnly?: boolean;
}) => {
  const isRackMountRequired = useSelector(
    (state: IAppState) => state.quoteBuilder.quote.location.aEnd.is_rack_mount_required
  );
  const options = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.secondRouterOptions);

  useEffect(() => {
    setRackMountKit(options?.rackMountKitRequired ?? isRackMountRequired);
  }, [isRackMountRequired, options?.rackMountKitRequired]);

  return (
    <RackMountKitFreeCheck>
      <Secondary onClick={setRackMountKit} value={rackMountKit} isReadOnly={isReadOnly} />
    </RackMountKitFreeCheck>
  );
};
