import { IAppState } from 'reducers';
import { CerillionStatus, OrderStatus } from 'Order/types/orderRecord';
import { selectUserCanPlaceOrder } from 'User/selectors';
import { isFTTXQuote, selectCurrentMeta, selectQuote, selectSelectedPrice } from 'Quotes/selectors';
import { Supplier } from 'Quotes/types/supplier';
import { AddressType, ILocation, IQuote } from 'Quotes/types/store';
import {
  concatenateOnNetAddress,
  concatenateOpenReachAddress,
  concatenatePAFAddress,
} from 'shared/utils/addresses/helperFunctions';
import { isValid } from 'postcode';
import { ProductType } from 'Quotes/types/productTypes';
import { createSelector } from '@reduxjs/toolkit';
import { IOrderMetaForLocation, IOrderState } from 'Order/types/store';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { IOrderLocation } from 'Order/types/location';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';

export const canAddOrderNoteSelector = (state: IAppState) => {
  const orderState = state.orderBuilder.order.state;
  return orderState !== OrderStatus.PLACED && orderState !== OrderStatus.CANCELLED;
};

export const createNoteStateSelector = (state: IAppState) => {
  return state.orderBuilder.createNoteState;
};

export const orderNotesSelector = (state: IAppState) => {
  return state.orderBuilder.order.notes;
};

export const shortIdSelector = (state: IAppState) => {
  return state.orderBuilder.order.shortId;
};

export const orderIdSelector = (state: IAppState) => {
  return state.orderBuilder.id;
};

export const submittedForReviewBySelector = (state: IAppState) => {
  return state.orderBuilder.order.submittedForReviewBy;
};

export const submittedForReviewAtSelector = (state: IAppState) => {
  return state.orderBuilder.order.submittedForReviewAt;
};

export const isOrderCardLockedSelector = (state: IAppState) => {
  const orderState = state.orderBuilder.order.state;
  return (
    [OrderStatus.CANCELLED, OrderStatus.PLACED].includes(orderState) ||
    (orderState === OrderStatus.IN_REVIEW && !selectUserCanPlaceOrder(state))
  );
};

export const selectGEACablelinkIdForPoPDisplay = (end: 'A' | 'B') => (state: IAppState) => {
  const selectedPrice = selectSelectedPrice(state);
  const isFTTX = isFTTXQuote(state);
  const orderState = state.orderBuilder.order.state;

  if (!isFTTX || orderState === OrderStatus.DRAFT || orderState === OrderStatus.READY) {
    return null;
  }

  if (end === 'A') {
    return selectedPrice.a_end_access_type === Supplier.OPENREACH
      ? state.orderBuilder.order.aEndGeaCablelinkId || 'Unavailable'
      : null;
  } else {
    return selectedPrice.b_end_access_type === Supplier.OPENREACH
      ? state.orderBuilder.order.bEndGeaCablelinkId || 'Unavailable'
      : null;
  }
};

export const selectProductType = (state: IAppState) => {
  return state.orderBuilder.order.productType;
};

export const selectBillingFrequency = (state: IAppState) => {
  return state.orderBuilder.order.billingFrequency;
};

export const selectReadOnlyPostcode = (state: IAppState) => {
  return {
    A: state.orderBuilder.order.locationA.locationData.readonly_postcode,
    B: state.orderBuilder.order.locationB.locationData.readonly_postcode,
  };
};

export const selectCanDeleteOrder = (state: IAppState) => {
  return ![OrderStatus.PLACED, OrderStatus.IN_REVIEW].includes(state.orderBuilder.order.state);
};

export const selectCanResendOrder = (state: IAppState) => {
  // if the order is not placed, do not allow resend
  if (state.orderBuilder.order.state !== OrderStatus.PLACED) return false;

  // if cerillion status is `failed`, allow resend
  if (state.orderBuilder.order.cerillion_status === CerillionStatus.FAILED) return true;

  // if cerillon status is `partial`, there is no cerillon basket id and there are some downstream responses, allow resend
  return (
    state.orderBuilder.order.cerillion_status === CerillionStatus.PARTIAL &&
    !state.orderBuilder.order.cerillion_basket_id &&
    Array.isArray(state.orderBuilder.order.downstream_responses) &&
    state.orderBuilder.order.downstream_responses.length > 0
  );
};

export const selectIsOrderONAT = (state: IAppState) => {
  return (
    state.orderBuilder.order.locationA.locationData.addressType === AddressType.ONAT ||
    state.orderBuilder.order.locationB.locationData.addressType === AddressType.ONAT
  );
};

export const selectOrderCreating = (state: IAppState) => {
  return state.orderBuilder.creating;
};

function findDataCentreFor(end: ILocation) {
  return end.optical.list?.find((centre) => centre.id === end.optical.selectedId);
}

const buildAddressLabel = (
  orderLocation: IOrderLocation,
  orderMetaForLocation: IOrderMetaForLocation,
  location: ILocation
) => {
  const { addressType, fullAddress } = orderLocation.locationData;
  if (addressType === AddressType.OPENREACH || addressType === AddressType.MANUAL) {
    return concatenateOpenReachAddress(fullAddress);
  } else if (addressType === AddressType.PAF) {
    return concatenatePAFAddress(orderLocation.locationData.pafAddress);
  } else if (addressType === AddressType.ON_NET) {
    return concatenateOnNetAddress(orderMetaForLocation, fullAddress);
  } else if (addressType === AddressType.ONAT && featureFlag.isEnabled(Feature.nnatOrderable)) {
    return concatenatePAFAddress(orderLocation.locationData.pafAddress) || location.onatAddress?.full_address || '';
  } else if (addressType === AddressType.ONAT && !featureFlag.isEnabled(Feature.nnatOrderable)) {
    return location.onatAddress?.full_address || '';
  } else {
    return '';
  }
};

const sanitiseAddressLabel = (aEndLabel: string, isMetaNotListed: boolean | undefined, location: ILocation) => {
  if (isValid(aEndLabel) && isMetaNotListed) {
    aEndLabel = 'Address not listed.';
  } else if (aEndLabel === '') {
    aEndLabel = location.postcode;
  }
  return aEndLabel;
};

const getAddress = (
  orderLocation: IOrderLocation,
  location: ILocation,
  orderMetaForLocation: IOrderMetaForLocation,
  isMetaNotListed: boolean | undefined
) => {
  const addressLabel = buildAddressLabel(orderLocation, orderMetaForLocation, location);
  return sanitiseAddressLabel(addressLabel, isMetaNotListed, location);
};

export const selectOrderAddress = createSelector(
  (state: IAppState) => state.orderBuilder,
  selectQuote,
  selectCurrentMeta,
  (state: IAppState) => state.quoteBuilder.quoteEndpointMeta,
  (orderBuilder: IOrderState, quote: IQuote, quoteMeta: IMeta) => {
    const aEnd = quote.location.aEnd;
    const bEnd = quote.location.bEnd;

    if (
      quote.productType === ProductType.OpticalP2P ||
      // Handle Product Type P2P as Optical P2P
      (aEnd.dataCentreReference && bEnd.dataCentreReference)
    ) {
      return {
        A: aEnd.dataCentreReference ? aEnd.dataCentreReference : findDataCentreFor(aEnd)?.attributes.name,
        B: bEnd.dataCentreReference ? bEnd.dataCentreReference : findDataCentreFor(bEnd)?.attributes.name,
      };
    }

    return {
      A: getAddress(
        orderBuilder.order.locationA,
        aEnd,
        orderBuilder.order.orderMeta?.aEnd,
        quoteMeta?.a_end_address_not_listed
      ),
      B: getAddress(
        orderBuilder.order.locationB,
        bEnd,
        orderBuilder.order.orderMeta?.bEnd,
        quoteMeta?.b_end_address_not_listed
      ),
    };
  }
);

export const selectOrder = (state: IAppState) => {
  return state.orderBuilder.order;
};

export const selectbulkOrderCreateState = (state: IAppState) => {
  return state.quoteBuilder.bulkOrderCreateState;
};
