import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IIconProps {
  theme: {
    colours: {
      primary: string;
      secondary: string;
      grey20: string;
    };
  };
  className?: string;
  name: string;
  size?: 'xs' | 'small' | 'normal' | 'large' | 'xLarge' | 'xxLarge' | 'xxxLarge';
  round?: boolean;
}

const Icon: FunctionComponent<React.PropsWithChildren<IIconProps>> = ({ className, name, size = 'large' }) => (
  <i className={`${className} material-icons ${size}`} role="img" aria-label={`icon ${name}`}>
    {name}
  </i>
);

const StyledIcon = styled(Icon)`
  font-family: 'Material Icons';
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.primary {
    color: ${(props) => props.theme.colours.primaryB1};
  }

  &.secondary {
    color: ${(props) => props.theme.colours.secondary};
  }

  &.grey20 {
    color: ${(props) => props.theme.colours.grey20};
  }

  &.grey50 {
    color: ${(props) => props.theme.colours.grey50};
  }

  &.xs {
    font-size: 8px;
  }

  &.small {
    font-size: 12px;
  }

  &.normal {
    font-size: 16px;
  }

  &.large {
    font-size: 24px;
  }

  &.xLarge {
    font-size: 32px;
  }

  &.xxLarge {
    font-size: 40px;
  }

  &.xxxLarge {
    font-size: 48px;
  }
`;

export default StyledIcon;
