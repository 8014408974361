import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setSelectedCompany as setSelectedCompanyAction } from 'User/actions';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import { IAppState } from 'reducers';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import { getAlerts as getAlertsAction, getNextAlerts as getNextAlertsAction, markAlertAsRead } from '../actions';
import { IRetrieving } from 'shared/types/retrieving';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import Alert from 'shared/components/atoms/Alert';
import EmptyAlertsList from '../EmptyAlertsList/EmptyAlertsList';
import { IAlertsRecord } from '../types/alertsRecord';
import AlertItem from '../AlertItem';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import styled from 'styled-components';
import { IMarkAlertAsRead } from 'Alerts/types/actions';
import { IAlert } from 'Alerts/types/alert';
import { AlertEventType } from 'Alerts/types/alertEventType';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';

const ButtonContainer = styled.div`
  button {
    font-size: 1em !important;

    :hover {
      text-decoration: underline;
    }
  }
`;

interface IAlertsList {
  className?: string;
  alerts?: IAlertsRecord;
  alertsRetrievingState: IRetrieving;
  getAlerts: () => void;
  getNextAlerts: (url: string) => void;
  setSelectedCompany(id: string): void;
  markAsRead: (alertId: IMarkAlertAsRead['payload']['alertId']) => void;
}

const alertsEnabledFor = (feature: Feature): boolean => featureFlag.isAttributeEnabled(feature, 'alertsEnabled');

const alertByFeature: Partial<Record<AlertEventType, () => boolean>> = {
  [AlertEventType.CERILLION_ORDER_STAGE_UPDATE]: () => alertsEnabledFor(Feature.orderStatusStepperUI),
  [AlertEventType.CERILLION_QUOTE_STAGE_UPDATE]: () => alertsEnabledFor(Feature.quoteCerillionStageUI),
};

const enabledAlertsOnly = (alert: IAlert): boolean => {
  const attributeEnabledFn = alertByFeature[alert.attributes.event_type];
  return !attributeEnabledFn || attributeEnabledFn();
};

export const AlertsList: FunctionComponent<React.PropsWithChildren<IAlertsList>> = ({
  alerts,
  alertsRetrievingState,
  getAlerts,
  getNextAlerts,
  setSelectedCompany,
  markAsRead,
}) => {
  useTrackPage(pages.alerts);
  useEffect(() => {
    getAlerts();
  }, [getAlerts]);

  const onAlertClick = (companyId: string, alertId: IAlert['id']) => {
    setSelectedCompany(companyId);
    markAsRead(alertId);
  };

  let content = null;
  if (alertsRetrievingState.error) {
    content = (
      <Alert>
        Error retrieving alerts. Please try again later. If the problem persists, please contact your Account Manager.
      </Alert>
    );
  } else if (alertsRetrievingState.inProgress && !alerts?.data.length) {
    content = <Spinner text="Retrieving alerts" size="large" />;
  } else if (!alertsRetrievingState.inProgress && !alerts?.data.length) {
    content = <EmptyAlertsList />;
  } else if (alerts?.data.length) {
    content = (
      <InfiniteScroll
        className="alert-list"
        loader={<Spinner text="Retrieving alerts" size="large" />}
        dataLength={alerts!.data.length}
        hasMore={!!alerts!.links.next}
        next={() => getNextAlerts(alerts!.links.next)}
      >
        {alerts?.data.filter(enabledAlertsOnly).map((item) => (
          <AlertItem alert={item} key={item.id} onClick={onAlertClick} />
        ))}
      </InfiniteScroll>
    );
  }
  return (
    <HeaderWithChildrenWrapper header="Alerts">
      <ButtonContainer className="text-right">
        <Button mainStyle={ButtonMainStyle.LinkSecondary} onClick={() => markAsRead('*')}>
          Mark all as read
        </Button>
      </ButtonContainer>
      {content}
    </HeaderWithChildrenWrapper>
  );
};

const mapStateToProps = (state: IAppState) => ({
  alertsRetrievingState: state.alerts.retrieving,
  alerts: state.alerts.data,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => {
  return {
    setSelectedCompany: (id: string) => dispatch(setSelectedCompanyAction(id)),
    getAlerts: () => dispatch(getAlertsAction()),
    getNextAlerts: (url: string) => {
      dispatch(getNextAlertsAction(url));
    },
    markAsRead: (alertId: IMarkAlertAsRead['payload']['alertId']) => dispatch(markAlertAsRead(alertId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsList);
