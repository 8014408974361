import { doRequest, Methods, ResourceTypes } from 'Request';
import { MessageResource, MessageResourceType, MessageResponseData } from 'shared/types/Messages';

interface SaveMessageParams {
  resourceId: string;
  customerId: string;
  resourceType: MessageResourceType;
  messageText: string;
}

export type SaveMessageRequest = (params: SaveMessageParams) => Promise<MessageResource>;

interface Payload {
  data: {
    type: MessageResponseData['type'];
    attributes: Pick<
      MessageResponseData['attributes'],
      'resource_id' | 'customer_id' | 'resource_type' | 'message_text'
    >;
  };
}

export const saveMessage: SaveMessageRequest = ({ resourceId, customerId, resourceType, messageText }) => {
  const body: Payload = {
    data: {
      attributes: {
        resource_id: resourceId,
        customer_id: customerId,
        resource_type: resourceType,
        message_text: messageText,
      },
      type: ResourceTypes.message,
    },
  };

  return doRequest({
    method: Methods.POST,
    path: '/messages',
    body,
  });
};
