import { IOrder } from '../../../types/store';
import { isValidTextField } from './validation';
import { IQuote, ProviderName } from '../../../../Quotes/types/store';

export const isComplete = (order: IOrder, quote: IQuote): boolean => {
  const { cloudConnect } = order;
  const { cloudConnect: cloudConnectQuote } = quote.location.aEnd;

  if (!isValidTextField(cloudConnect.serviceKey1)) {
    return false;
  }

  if (cloudConnectQuote.diversified && !isValidTextField(cloudConnect.serviceKey2)) {
    return false;
  }

  if (
    cloudConnectQuote.name === ProviderName.Azure &&
    !isValidTextField(cloudConnect.microsoftPeering) &&
    !isValidTextField(cloudConnect.privatePeering)
  ) {
    return false;
  }

  return true;
};
