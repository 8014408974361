import { IOrder } from 'Order/types/store';
import { isCompleted as billingAndContactComplete } from './billingAndContactComplete';
import { isCompleted as locationComplete } from './isCompleted';
import { isComplete as cloudConnectComplete } from './cloudConnectComplete';
import { CardState } from 'shared/components/organisms/CardWithInitialText';
import { ICardState } from 'Order/OrderBuilder/OrderReadyCard';
import { ProductType } from 'Quotes/types/productTypes';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { OrderStatus } from 'Order/types/orderRecord';
import isNNICompleted from './isNNICompleted';
import { isSupplierNNAT } from 'Quotes/utils/isPriceNNAT';

export const isBaselineOrderReady = (order: IOrder, quote: IQuote): boolean =>
  (order.state === OrderStatus.READY || order.state === OrderStatus.RETURNED) &&
  billingAndContactComplete(order, quote);

export const isOrderReady = (
  order: IOrder,
  hasShadowNNI: boolean,
  cardState: ICardState,
  quote: IQuote,
  selectedPrice: IPriceData
): boolean => {
  const productType = order.productType;
  if (!productType || !isCardStateReady(cardState, productType)) return false;

  const isBaseOrderReady = isBaselineOrderReady(order, quote);

  if (!isBaseOrderReady) return false;

  const isALocationComplete = () =>
    locationComplete(order.locationA, quote, isSupplierNNAT(selectedPrice.a_end_access_type));
  const isBLocationComplete = () =>
    locationComplete(order.locationB, quote, isSupplierNNAT(selectedPrice.b_end_access_type));

  if (productType === ProductType.P2NNI) {
    return isBLocationComplete() && isNNICompleted(order, hasShadowNNI);
  } else if (productType === ProductType.P2P || productType === ProductType.OpticalP2P) {
    return isALocationComplete() && isBLocationComplete();
  } else if (productType === ProductType.DIA) {
    return isALocationComplete();
  } else if (productType === ProductType.P2CCT) {
    return isALocationComplete() && cloudConnectComplete(order, quote!);
  } else if (productType === ProductType.NNI2CCT) {
    return cloudConnectComplete(order, quote!) && isNNICompleted(order, hasShadowNNI);
  } else {
    return false;
  }
};

export const isCardStateReady = (cardState: ICardState, productType: ProductType) => {
  switch (productType) {
    case ProductType.P2NNI:
      return (
        cardState.billingAndContact === CardState.Summary &&
        cardState.aEnd === CardState.Summary &&
        cardState.bEnd === CardState.Summary
      );

    case ProductType.P2P:
    case ProductType.OpticalP2P:
      return (
        cardState.billingAndContact === CardState.Summary &&
        cardState.aEnd === CardState.Summary &&
        cardState.bEnd === CardState.Summary
      );

    case ProductType.DIA:
      return cardState.billingAndContact === CardState.Summary && cardState.aEnd === CardState.Summary;

    case ProductType.P2CCT: {
      return (
        cardState.billingAndContact === CardState.Summary &&
        cardState.aEnd === CardState.Summary &&
        cardState.cloudConnect === CardState.Summary
      );
    }

    case ProductType.NNI2CCT: {
      return (
        cardState.aEnd === CardState.Summary &&
        cardState.billingAndContact === CardState.Summary &&
        cardState.cloudConnect === CardState.Summary
      );
    }

    default:
      return false;
  }
};
