import { TextWithLabelSummary } from 'Order/OrderBuilder/shared/components/SummaryTextHelper';
import { INewNNIRequest } from 'Order/types/location';
import { formatYears } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import React from 'react';
import { bearerForDisplay } from 'shared/utils/connectionCapacity';

interface INewNNIRequestSummaryProps {
  newNNIRequest: INewNNIRequest;
  customerName?: string | null;
}

export const NewNNIRequestSummary = ({ newNNIRequest, customerName }: INewNNIRequestSummaryProps) => (
  <>
    {customerName && <TextWithLabelSummary id="new_nni_request_customer_name" label="Customer" text={customerName} />}
    <TextWithLabelSummary
      id="new_nni_request_capacity"
      label="Capacity"
      text={bearerForDisplay(newNNIRequest.capacity)}
    />
    <TextWithLabelSummary id="new_nni_request_term" label="Term" text={formatYears(newNNIRequest.termLengthInYears)} />
    <TextWithLabelSummary
      id="new_nni_request_contact_email"
      label="Customer contact email address"
      text={newNNIRequest.contactEmail || ''}
    />
  </>
);
