import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Company } from '../../../User/types/user';
import LinkDropdown from '../LinkDropdown';

interface ICompanyDropdown {
  className?: string;
  companies: Company[];
  selectedCompanyId: string | null;
  onChange(id: string): void;
}

const CompanyDropdown: FunctionComponent<React.PropsWithChildren<ICompanyDropdown>> = ({
  className,
  companies,
  onChange,
  selectedCompanyId,
}) => {
  const selectedCompany = companies.find((company) => company.id === selectedCompanyId) || companies[0];

  const filterItem = (search: string, index: number) => {
    return companies[index].name.toLowerCase().includes(search);
  };

  return (
    <div className={className}>
      <LinkDropdown filterItem={filterItem} title={selectedCompany?.name}>
        {companies.map((company) => (
          <div
            className={company === selectedCompany ? 'active' : ''}
            key={company.id}
            onClick={() => onChange(company.id)}
          >
            {company.name}
          </div>
        ))}
      </LinkDropdown>
    </div>
  );
};

const StyledCompanyDropdown = styled(CompanyDropdown)`
  .dropdown {
    background-color: #f5f9fc;
    min-width: 200px;
    .dropdown__options {
      min-width: 200px;
      max-height: 300px;
      overflow-y: auto;
    }
  }
`;

export default StyledCompanyDropdown;
