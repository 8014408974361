import { ProductType } from './productTypes';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import IPricedQuote from './pricedQuote';
import { AddressType, ProviderName } from './store';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { ConnectionType } from './connectionType';
import { Journey } from './actions';
import {
  DualCircuitRouterConfigurations,
  IPAddressAllocation,
} from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { ContractTerm } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';

export type LocationLookup = 'postcode' | 'alk';

export interface CablingTypeSelection {
  priceId: string;
  a_end_cabling_type_selected?: boolean;
  b_end_cabling_type_selected?: boolean;
}

export interface IMeta {
  preferred_p2p_price_id?: string;
  a_end_address_not_listed?: boolean;
  a_end_nni_shadow_reference?: string;
  a_end_nni_shadow_data_centre_reference?: string;
  b_end_address_not_listed?: boolean;
  a_end_on_net_location?: string | null;
  b_end_on_net_location?: string | null;
  a_end_on_net_reference?: string | null;
  b_end_on_net_reference?: string | null;
  a_end_on_net_name?: string | null;
  b_end_on_net_name?: string | null;
  a_end_address_type?: AddressType | null;
  b_end_address_type?: AddressType | null;
  nni_label?: string | null;
  a_end_location_lookup?: LocationLookup;
  b_end_location_lookup?: LocationLookup;
  cabling_type_selection?: CablingTypeSelection[];
  journey_origin?: Journey;
}

export interface IQuoteRecordAttributesBase {
  diverse_option_for?: string | null;
  diverse_option?: string | null;
  a_end_address?: string;
  availability_id?: string | null;
  a_end_paf_address_id?: string;
  b_end_paf_address_id?: string;
  a_end_access_method?: ConnectionType;
  b_end_access_method?: ConnectionType;
  a_end_bandwidth?: string;
  b_end_bandwidth?: string;
  a_end_building_name?: string;
  a_end_building_number?: string;
  a_end_colt_building_id?: string | null;
  a_end_data_centre_id?: string | null;
  a_end_data_centre_reference?: string | null;
  a_end_data_centre_name?: string | null;
  a_end_data_centre_address?: string | null;
  a_end_full_site_county?: string;
  a_end_full_site_thoroughfare?: string;
  a_end_full_site_town?: string;
  a_end_n_n_i_id?: string;
  a_end_n_n_i_reference?: string | null;
  a_end_n_n_i_shadow_vlan_data_centre_id?: string;
  a_end_n_n_i_shadow_vlan_id?: string;
  shadow_vlan_pop_name?: string;
  shadow_vlan_pop_address?: string;
  a_end_paf_organisation_name?: string | null;
  a_end_port?: BearerType;
  a_end_postcode?: string;
  a_end_sub_building?: string;
  a_end_udprn?: string;
  b_end_address?: string;
  b_end_building_name?: string;
  b_end_building_number?: string;
  b_end_colt_building_id?: string | null;
  b_end_data_centre_id?: string | null;
  b_end_data_centre_reference?: string | null;
  b_end_data_centre_name?: string | null;
  b_end_data_centre_address?: string | null;
  b_end_full_site_county?: string;
  b_end_full_site_thoroughfare?: string;
  b_end_full_site_town?: string;
  b_end_paf_organisation_name?: string | null;
  b_end_port?: BearerType;
  b_end_postcode?: string;
  b_end_sub_building?: string;
  b_end_udprn?: string;
  bandwidth?: string;
  bulk_request_id?: string | null;
  cloud_connect_option?: {
    diversified?: boolean | null;
    provider?: ProviderName;
  };
  connection_type?: ConnectionType;
  a_end_openreach_address?: IOpenReachAddressRecord | null;
  b_end_openreach_address?: IOpenReachAddressRecord | null;
  customer_id?: string;
  ip_count?: IPType;
  is_bulk?: boolean;
  lq_id?: string;
  meta?: IMeta | null;
  path_speed?: string;
  product_type?: ProductType;
  selected_price?: IPricedQuote;
  short_id?: string;
  state?: QuoteStatus;
  /** @deprecated multiple terms are now supported by `chosen_term_lengths_in_months` */
  term_length_in_months?: string;
  a_end_exclude_sse_onnet?: boolean;
  b_end_exclude_sse_onnet?: boolean;
  lq_pricing_error?: string | null;
  a_end_l2sid?: string | null;
  b_end_l2sid?: string | null;
  a_end_mdfid?: string | null;
  b_end_mdfid?: string | null;
  is_internal?: boolean;
  is_managed_dia?: boolean;
  is_engineer_installation_required?: boolean;
  is_rack_mount_required?: boolean;
  secondary_circuit_options?: {
    mdia_cpe_selection?: DualCircuitRouterConfigurations | null;
    is_engineer_installation_required?: boolean | null;
    is_rack_mount_required?: boolean | null;
    ip_count?: IPType | null;
  };
  dia_ip_allocation?: IPAddressAllocation | null;
  b_end_onat_address_id?: string | null;
  sent_to_cerillion_at?: string | null;
  chosen_bandwidths?: string[];
  chosen_term_lengths_in_months?: ContractTerm[];
  fttp_aggregation?: boolean | null;
}

export interface IOpenReachAddressRecord {
  full_site_alk?: string | null;
  full_site_match_type?: string | null;
  full_site_building_name?: string | null;
  full_site_building_number?: string | null;
  full_site_sub_building?: string | null;
  full_site_street?: string | null;
  full_site_town?: string | null;
  full_site_county?: string | null;
  full_site_district_code?: string | null;
}

export enum QuoteStatus {
  DRAFT = 'draft',
  PRICED = 'priced',
  ORDERED = 'ordered',
  LAPSED = 'lapsed',
}

export default IQuoteRecordAttributesBase;
