import React, { FunctionComponent, useState } from 'react';
import getUserDataReport from '../../crud/getUserDataReports';
import Alert from '../../../shared/components/atoms/Alert';
import SpinnerWithText from '../../../shared/components/molecules/SpinnerWithText';
import StyledIcon from '../../../shared/components/atoms/Icon';
import buildFileNameWithDate from '../../../shared/utils/buildFileNameWithDate';
import Button, { ButtonMainStyle } from '../../../shared/components/atoms/Button';
import styled from 'styled-components';
import { saveAs } from 'file-saver';

import UserStatusFilter from '../filters/UserStatusFilter';
import UserTypeFilter from '../filters/UserTypeFilter';

interface IUserDataReport {
  className?: string;
}

export const UserDataReport: FunctionComponent<React.PropsWithChildren<IUserDataReport>> = ({ className }) => {
  const [userStatus, setUserStatus] = useState<string | null>(null);
  const [userType, setUserType] = useState<string | null>(null);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [successfullDownload, setSuccessfullDownload] = useState<boolean>(false);

  const exportCSV = async () => {
    setIsDownloading(true);
    setSuccessfullDownload(false);
    setHasErrored(false);
    try {
      const result = await getUserDataReport({
        status: userStatus,
        user_type: userType,
      });
      const blob = new Blob([result], { type: 'data:text/csv;charset=utf-8,' });
      saveAs(blob, buildFileNameWithDate('Digital Portal Users', 'csv'));
      setSuccessfullDownload(true);
    } catch (error) {
      setHasErrored(true);
      setSuccessfullDownload(false);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className={className}>
      <div className="reports-filters__wrapper row">
        <UserStatusFilter setUserStatus={setUserStatus} />
        <UserTypeFilter setUserType={setUserType} />
        <div className="downloading">
          {successfullDownload && <StyledIcon name="check" className="success-icon" />}
          <Button onClick={exportCSV} mainStyle={ButtonMainStyle.PrimaryRectangular} className="download-btn">
            Download report to CSV
          </Button>
        </div>
      </div>
      {isDownloading && <SpinnerWithText text="Downloading" />}
      {hasErrored && (
        <Alert>
          Error whilst downloading your report. Please try again later. If the problem persists, please contact your
          Account Manager.
        </Alert>
      )}
    </div>
  );
};

const styledUserDataReport = styled(UserDataReport)`
  .downloading {
    display: block;
    text-align: right;
    flex: 1;
  }
  .download-btn {
    margin-top: 1.2em;
    width: fit-content;
  }

  .success-icon {
    color: ${(props) => props.theme.colours.primaryB1};
    vertical-align: bottom;
    margin-bottom: 1em;
  }
`;

export default styledUserDataReport;
