import React, { FC } from 'react';
import { Supplier } from 'Quotes/types/supplier';
import { presentSupplierNameForPriceTile } from 'Quotes/utils/supplierHelpers';
import { SupplierImage } from '../../../Supplier';
import PresentForRoles from 'Auth/PresentForRoles';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import { userPermissions } from 'shared/utils/permissions';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { PriceCard } from '../PriceCard';

const identifierPrefix = 'P2P';

interface IP2PProps {
  selected?: boolean;
  onClick?: () => void;
  aEndAccessMethod: IQuote['aEndAccessMethod'];
  bEndAccessMethod: IQuote['bEndAccessMethod'];
  aEndBandwidth: IQuote['aEndBandwidth'];
  bEndBandwidth: IQuote['bEndBandwidth'];
  aEndPostcode: string;
  aEndSupplier: Supplier;
  bEndPostcode: string;
  amortisedAnnualPrice: number | null;
  bEndSupplier: Supplier;
  aEndPrice: number | null;
  bEndPrice: number | null;
  priceId: string | null;
  className?: string;
  readonly: boolean;
  product_sub_type: IPriceData['product_sub_type'];
  bandwidth?: string;
  term_length_in_months?: IPriceData['term_length_in_months'];
  isPoA?: boolean;
  supportsSecondaryCircuits: boolean;
}

export const P2P: FC<React.PropsWithChildren<IP2PProps>> = ({
  selected = false,
  onClick,
  amortisedAnnualPrice,
  aEndAccessMethod,
  bEndAccessMethod,
  aEndBandwidth,
  bEndBandwidth,
  aEndPostcode,
  aEndSupplier,
  bEndPostcode,
  bEndSupplier,
  aEndPrice,
  bEndPrice,
  priceId,
  readonly,
  product_sub_type,
  bandwidth,
  term_length_in_months,
  isPoA = false,
  supportsSecondaryCircuits,
}) => {
  return (
    <PriceCard
      identifierPrefix={identifierPrefix}
      priceId={priceId!}
      readonly={readonly}
      selected={selected}
      onClick={onClick}
      aEndPostcode={aEndPostcode}
      bEndPostcode={bEndPostcode}
      amortisedAnnualPrice={amortisedAnnualPrice}
      product_sub_type={product_sub_type}
      term_length_in_months={term_length_in_months}
      bandwidth={bandwidth}
      isPoA={isPoA}
      supportsSecondaryCircuits={supportsSecondaryCircuits}
    >
      <ul className="list-unstyled">
        {aEndSupplier && (
          <li>
            <SupplierImage supplier={aEndSupplier} />
          </li>
        )}
        <li data-testid="a-end-supplier">{presentSupplierNameForPriceTile(aEndSupplier)}</li>
        {aEndBandwidth && <li className="text-secondary a-end-bandwidth">Port: {formatBandwidth(aEndBandwidth)}</li>}
        {aEndAccessMethod && <li className="text-secondary a-end-access-method">{aEndAccessMethod}</li>}
        <PresentForRoles roles={userPermissions.costs}>
          <li className="text-secondary a-end-cost">Cost: {currencyFormatter.format(aEndPrice ?? 0)}</li>
        </PresentForRoles>
        <li className="bandwidth-link">
          <img src="/images/price-end-link-graphic.png" alt="Link between aEnd and bEnd" width={23} height={71} />
        </li>
        {bEndSupplier && (
          <li>
            <SupplierImage supplier={bEndSupplier} />
          </li>
        )}
        <li data-testid="b-end-supplier">{presentSupplierNameForPriceTile(bEndSupplier)}</li>
        {bEndBandwidth && <li className="text-secondary b-end-bandwidth">Port: {formatBandwidth(bEndBandwidth)}</li>}
        {bEndAccessMethod && <li className="text-secondary b-end-access-method">{bEndAccessMethod}</li>}
        <PresentForRoles roles={userPermissions.costs}>
          <li className="text-secondary mb-0 b-end-cost">Cost: {currencyFormatter.format(bEndPrice ?? 0)}</li>
        </PresentForRoles>
      </ul>
    </PriceCard>
  );
};

export const StyledP2P = P2P;
