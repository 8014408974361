import React from 'react';
import Alert, { Alerts } from 'shared/components/atoms/Alert';

export const QuoteAsyncPricingAlert = () => {
  return (
    <Alert alertType={Alerts.WARNING}>
      <h5>Multiple bandwidths and contract lengths selected</h5>
      <p className="m-0">It may take longer to load prices.</p>
    </Alert>
  );
};
