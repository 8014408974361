import { call, debounce, put } from 'redux-saga/effects';

import { IUpdateUserPersonalDetails, UserActionTypes } from './types/actions';
import getWhoAmIRequest from './crud/getWhoami';
import {
  getWhoAmIError,
  getWhoAmIStarted,
  getWhoAmISuccess,
  setUnreadCounts,
  setUserData,
  updateUserError,
  updateUserStarted,
  updateUserSuccess,
} from './actions';
import updateUserPersonalDetails from './crud/updateUser';
import { sessionTimeValid } from 'App/utils/sessionTimer';

export function* getWhoAmISaga(): any {
  if (!sessionTimeValid()) {
    yield put(getWhoAmIError());
    return;
  }

  try {
    yield put(getWhoAmIStarted());

    const user = yield call(getWhoAmIRequest);

    yield put(setUserData(user.data));
    yield put(getWhoAmISuccess());
  } catch (error) {
    yield put(getWhoAmIError());
  }
}

export function* updateUnreadCountsSaga(): any {
  if (!sessionTimeValid()) {
    yield put(getWhoAmIError());
    return;
  }

  const user = yield call(getWhoAmIRequest);

  yield put(setUnreadCounts(user.data));
}

export function* updateUserSaga({ payload }: IUpdateUserPersonalDetails): any {
  yield put(updateUserStarted());

  try {
    const updatedUser = yield call(updateUserPersonalDetails, payload);
    yield put(setUserData(updatedUser.data));

    yield put(updateUserSuccess());
  } catch (error) {
    yield put(updateUserError());
  }
}

export default function* rootSaga() {
  yield debounce(300, UserActionTypes.GET_WHOAMI, getWhoAmISaga);
  yield debounce(300, UserActionTypes.UPDATE_UNREAD_COUNTS, updateUnreadCountsSaga);
  yield debounce(300, UserActionTypes.UPDATE_USER_PERSONAL_DETAILS, updateUserSaga);
}
