import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Note } from '../../types/note';
import { formatDateHuman, formatTime } from '../../../shared/utils/dateHelper';
import { OrderStatus } from '../../types/orderRecord';
import Column from '../../../shared/components/atoms/Column';

interface Props {
  notes: Note[];
  className?: string;
}

const displayMessage = (note: Note): string => {
  switch (note.state) {
    case OrderStatus.IN_REVIEW:
      return `Submitted by ${note.createdBy}`;

    case OrderStatus.RETURNED:
      return `Returned by ${note.createdBy}`;

    case OrderStatus.PLACED:
      return `Order accepted by ${note.createdBy}`;

    case OrderStatus.CANCELLED:
      return `Order cancelled by ${note.createdBy}`;

    default:
      return note.message || '';
  }
};

const NotesList: FunctionComponent<React.PropsWithChildren<Props>> = (props: Props) => {
  return (
    <ul className={props.className}>
      {props.notes.map((note, i) => {
        return (
          <li className="notes-list__note" key={i}>
            <div className="notes-list__note-message text-left text-break">{displayMessage(note)}</div>
            <div className="row no-gutters notes-list__note-meta text-left text-break">
              <Column defaultWidth={9}>
                {note.createdBy}
                {' - '}
                {formatDateHuman(note.createdAt)}
              </Column>
              <Column defaultWidth={3} classNames={['text-right']}>
                {formatTime(note.createdAt)}
              </Column>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

const StyledNotesList = styled(NotesList)`
  list-style: none;
  margin: 0;
  padding: 0 1.2em;

  .notes-list__note {
    border-bottom: 1px solid ${(props) => props.theme.colours.grey20};
    padding: 1.2em 0.25em;

    &:last-child {
      border-bottom: none;
    }
  }

  .notes-list__note-meta {
    color: ${(props) => props.theme.colours.grey50};
    font-size: 12px;
    padding-top: 1em;
  }
`;
export default StyledNotesList;
export { StyledNotesList as OrderNotesList };
