import styled from 'styled-components';

export const QuoteExtraInfoWrapper = styled.div`
  background: ${(props) => props.theme.colours.primaryC5};
  padding: 20px;
  border-radius: 5px;
  margin: 0px 4px;
  color: #333;
  position: relative;
`;
