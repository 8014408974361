import React, { useMemo } from 'react';
import { IOrder } from 'Order/types/store';
import { SaveOrderTemplateButton } from '../SaveOrderTemplateButton';
import { SetDefaultOrderTemplateButton } from '../SetDefaultOrderTemplateButton';
import { mapOrderToOrderTemplateState } from 'Order/crud/orderTemplateMapper';
import { OrderTemplateUpdateButton } from './OrderTemplateUpdateButton';
import { areOrderFormStatesEqual } from './utils/areOrderFormStatesEqual';

interface OrderTemplateActionButtonsProps {
  customerId: string;
  order: IOrder;
  orderId: string;
}

const OrderTemplateActionButtons: React.FC<OrderTemplateActionButtonsProps> = ({ customerId, order, orderId }) => {
  const currentForm = useMemo(() => mapOrderToOrderTemplateState(order), [order]);
  const template = order.selectedOrderTemplate?.attributes.templateBody;

  const formEqualsTemplate = areOrderFormStatesEqual(currentForm, template);

  const showSetDefaultButton = order.selectedOrderTemplate?.id && !order.selectedOrderTemplate?.attributes.isDefault;
  const showSaveButton = !formEqualsTemplate && !order.selectedOrderTemplate;
  const showUpdateButton = !formEqualsTemplate && order.selectedOrderTemplate;

  return (
    <>
      {showSetDefaultButton && (
        <SetDefaultOrderTemplateButton
          customerId={customerId}
          selectedOrderTemplateId={order.selectedOrderTemplate!.id}
        />
      )}

      {showSaveButton && <SaveOrderTemplateButton customerId={customerId} order={order} orderId={orderId} />}
      {showUpdateButton && <OrderTemplateUpdateButton customerId={customerId} order={order} orderId={orderId} />}
    </>
  );
};

export default OrderTemplateActionButtons;
