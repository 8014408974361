import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import LinkButton from '../../../../../shared/components/atoms/LinkButton';
import { ButtonMainStyle } from '../../../../../shared/components/atoms/Button';
import { newQuote } from '../../../../Routes';
import PresentForRoles from '../../../../../Auth/PresentForRoles';
import { userPermissions } from '../../../../../shared/utils/permissions';

interface INoQuotesInBulkQuote {
  className?: string;
}

const NoQuotesInBulkQuote: FunctionComponent<React.PropsWithChildren<INoQuotesInBulkQuote>> = ({ className }) => (
  <div className={className}>
    <p>It looks like you don&apos;t have any quotes in this bulk quote.</p>
    <PresentForRoles roles={userPermissions.createEditQuote}>
      <p>
        <LinkButton
          buttonText="Start a quote"
          pathname={newQuote}
          id="start-a-quote-inline-link"
          mainStyle={ButtonMainStyle.Link}
        />
        &nbsp;to see how much you could save with Neos Networks.
      </p>
    </PresentForRoles>
  </div>
);

const StyledNoQuotesInBulkQuote = styled(NoQuotesInBulkQuote)`
  #start-a-quote-inline-link {
    color: ${(props) => props.theme.colours.primaryB1};
  }
`;

export default StyledNoQuotesInBulkQuote;
