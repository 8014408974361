import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { addUserAction, editUserState, setAddUserRequestState } from '../actions';
import { IAppState } from 'reducers';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { IUserData, ProfileFields } from '../types/actions';
import { RequestState } from '../types';
import { IErrorResponse } from 'Request/utils/parseErrorResponse';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import { BackendRoleName, backendRoleNameToRoleMapping, IUserRoleFromBackend, Role } from 'User/types/role';
import { CompanyData } from 'User/types/user';
import CompaniesAndRoles from 'Admin/shared/components/CompaniesAndRoles/CompaniesAndRoles';
import { IRow } from '../shared/components/CompaniesRolesTable';
import { isSSEUserFromBackendRoles } from '../utils/companyRolesRows';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import { userList } from 'Admin/routes';
import SwitchCustomerModal from 'shared/components/organisms/SwitchCustomerModal';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorCreatingUserAlert, UserCreatedSuccessfullyAlert } from 'Admin/AddUser/Alerts';
import { UserProfileForm } from 'Admin/AddUser/UserProfileForm';
import { NavigationButtons } from 'Admin/AddUser/NavigationButtons';

interface IAddUserPage {
  companyData: CompanyData;
  addUser: (userData: IUserData, companiesRolesRows: IRow[]) => void;
  addUserRequestError: IErrorResponse;
  addUserRequestState: RequestState;
  allRoles: IUserRoleFromBackend[];
  className?: string;
  editMode?: boolean;
  resetRequestState(): void;
  editUser(): void;
}

export const inputIdentifierPrefix = 'addUser__';

export type UserType = 'Customer' | 'Neos User';
const initialView: UserType = 'Customer';

const DisplayCreateUserResponse = (props: { addUserRequestState: RequestState; errors: IErrorResponse }) => (
  <div className="pt-3">
    {props.addUserRequestState === RequestState.SUCCESS ? (
      <UserCreatedSuccessfullyAlert />
    ) : props.addUserRequestState === RequestState.ERROR ? (
      <ErrorCreatingUserAlert errors={props.errors} />
    ) : (
      ''
    )}
  </div>
);

const SendEmailInviteToggle = ({ checked, onChange }: { checked: boolean; onChange: () => void }) => (
  <div className="form-check">
    <input
      type="checkbox"
      id={`${inputIdentifierPrefix}shouldSendEmail`}
      className="form-check-input"
      onChange={onChange}
      checked={checked}
    />
    <label htmlFor={`${inputIdentifierPrefix}shouldSendEmail`} className="form-check-label pr-2">
      Send email invitation
    </label>
  </div>
);

const convertWildcardRoleToFullCompanyInfo = (role: BackendRoleName) => ({
  companyName: '',
  companyId: '*',
  readonly: false,
  roles: [
    {
      enabled: true,
      role: backendRoleNameToRoleMapping[role],
    },
  ],
});

export const AddUserPage: FunctionComponent<React.PropsWithChildren<IAddUserPage>> = ({
  addUser,
  companyData,
  addUserRequestError,
  addUserRequestState,
  allRoles,
  className,
  editMode,
  resetRequestState,
  editUser,
}) => {
  useTrackPage(pages.createUser);
  const [activeView, setActiveView] = useState<UserType>(initialView);
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [profileFields, setProfileFields] = useState<ProfileFields>({
    companyEmail: '',
    jobTitle: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    landlinePhone: '',
    contactEmail: '',
  });

  const [shouldSendEmail, setShouldSendEmail] = useState<boolean>(false);

  const [companiesRolesRows, setCompaniesRolesRows] = useState<IRow[]>([]);
  const [wildcardRoles, setWildcardRoles] = useState<BackendRoleName[]>([]);
  const [validRoleSelections, setValidRoleSelections] = useState<boolean>(false);

  const isSSEUser = isSSEUserFromBackendRoles(allRoles);

  useEffect(() => {
    resetRequestState();
    editUser();
  }, [resetRequestState, editUser, activeView]);

  const scrollAnchorRef = useRef<HTMLDivElement>(null);

  const isLoading = addUserRequestState === RequestState.LOADING;

  const createProfile = () => {
    setShowValidationErrors(true);

    if (!isFormValid || !validRoleSelections) {
      scrollAnchorRef.current?.scrollIntoView();
      return;
    }

    const user = { ...profileFields, shouldSendEmail, roles: new Set<Role>() };
    if (wildcardRoles.length === 0) {
      addUser(user, companiesRolesRows);
    } else {
      addUser(user, wildcardRoles.map(convertWildcardRoleToFullCompanyInfo));
    }
  };

  return (
    <HeaderWithChildrenWrapper header="Add new person">
      <div className={className}>
        <NavigationButtons sseUser={isSSEUser} loading={isLoading} activeView={setActiveView} allRoles={allRoles} />

        <div className="d-flex flex-column align-items-center">
          <section className={showValidationErrors ? 'show-errors' : ''}>
            <div ref={scrollAnchorRef} className="d-flex flex-column pt-3">
              <UserProfileForm setIsValid={setIsFormValid} setProfileFields={setProfileFields} />

              <CompaniesAndRoles
                companyData={companyData}
                onChange={(updatedRows, invalid, wildcards) => {
                  setCompaniesRolesRows(updatedRows);
                  setValidRoleSelections(!invalid || !!wildcards?.length);
                  setWildcardRoles(wildcards);
                }}
                isSSEUser={isSSEUser && activeView === 'Neos User'}
                readonly={isLoading}
                showDescription={false}
              />

              <div className="d-flex align-self-end align-items-center mt-5">
                <SendEmailInviteToggle
                  checked={shouldSendEmail}
                  onChange={() => setShouldSendEmail(!shouldSendEmail)}
                />
                <Button
                  id="admin-save-button"
                  mainStyle={ButtonMainStyle.PrimaryRectangular}
                  disabled={!validRoleSelections || addUserRequestState !== RequestState.NOT_REQUESTED}
                  onClick={createProfile}
                >
                  Create profile
                </Button>
              </div>
              <DisplayCreateUserResponse addUserRequestState={addUserRequestState} errors={addUserRequestError} />
            </div>
          </section>
        </div>
        <SwitchCustomerModal selectedCompanyId={companyData.selectedCompanyId} path={userList} editMode={editMode} />
      </div>
    </HeaderWithChildrenWrapper>
  );
};

export const StyledAddUserPage = styled(AddUserPage)<IAddUserPage>`
  .show-errors input:invalid {
    border-color: ${(props) => props.theme.colours.invalidFieldBorderColor ?? 'red'};
  }

  &.clickable {
    cursor: pointer;
  }

  section {
    width: 100%;
  }

  .tab-buttons {
    button:first-child {
      margin-right: 1.8em;
    }

    button {
      padding-bottom: 1.5em;
      border-bottom: 3px solid transparent;
    }

    .active {
      color: ${(props) => props.theme.colours.primaryB1};
      border-bottom: 3px solid ${(props) => props.theme.colours.primaryB1};
      border-radius: 0;
    }
  }

  .back-btn {
    color: ${(props) => props.theme.colours.grey60};
  }
`;

const mapStateToProps = createSelector(
  (state: IAppState) => state.admin,
  (state: IAppState) => state.user,
  (admin, user) => ({
    companyData: user.companyData,
    addUserRequestError: admin.addUserRequestError,
    addUserRequestState: admin.addUserRequestState,
    allRoles: user.allRoles,
    editMode: admin.editMode,
  })
);

const ConnectedAddUserPage = () => {
  const dispatch = useDispatch();

  const addUser = (userData: IUserData, companiesRolesRows: IRow[]) =>
    dispatch(addUserAction(userData, companiesRolesRows));

  const resetRequestState = useCallback(() => dispatch(setAddUserRequestState(RequestState.NOT_REQUESTED)), [dispatch]);
  const editUser = useCallback(() => dispatch(editUserState()), [dispatch]);

  const state = useSelector(mapStateToProps);
  return (
    <StyledAddUserPage
      addUserRequestState={state.addUserRequestState}
      resetRequestState={resetRequestState}
      addUser={addUser}
      editUser={editUser}
      allRoles={state.allRoles}
      companyData={state.companyData}
      addUserRequestError={state.addUserRequestError}
    />
  );
};

export default ConnectedAddUserPage;
