import React, { FunctionComponent } from 'react';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import { OrderStatus } from 'Order/types/orderRecord';

interface IOrdersStatusFilter {
  className?: string;
  setOrderStatus(orderStatus: string | null): void;
}

const OrderStatusFilter: FunctionComponent<React.PropsWithChildren<IOrdersStatusFilter>> = ({ setOrderStatus }) => {
  const getOrderStatusOptions = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [];

    Object.values(OrderStatus).forEach((status) => {
      options.push({
        label: status.toLowerCase().replace('_', ' '),
        value: status.toLowerCase(),
      });
    });

    return options;
  };

  return (
    <div className="control">
      <label>Status</label>
      <Dropdown
        options={getOrderStatusOptions()}
        onChange={(orderStatus: IDropdownOption) => setOrderStatus(orderStatus ? orderStatus.value : null)}
        className="select-input products-dropdown"
      />
    </div>
  );
};

export default OrderStatusFilter;
