import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectMultiQuoteModalDisplayButton } from 'Quotes/selectors';

function PoAMessageBody(props: { canAddToMultiQuote: boolean }) {
  return (
    <>
      <p>
        If you Proceed to application your account manager will contact you shortly to continue further with this quote.
      </p>
      {props.canAddToMultiQuote && <p data-testid="poa-multiquote">This quote can still be added to a Multi-Quote.</p>}
    </>
  );
}

function PriceOnApplicationTitle() {
  return <h3>Price on application</h3>;
}

function ProceedToApplicationTitle() {
  return <h3>Proceed to application</h3>;
}

export const SubmitPoAMessage = ({ canAddToMultiQuote }: { canAddToMultiQuote: boolean }) => {
  return (
    <PoAMessage data-testid="poa-message">
      <PriceOnApplicationTitle />
      <PoAMessageBody canAddToMultiQuote={canAddToMultiQuote} />
    </PoAMessage>
  );
};

export const SubmitPtAMessage = ({ canAddToMultiQuote }: { canAddToMultiQuote: boolean }) => {
  return (
    <PoAMessage data-testid="poa-message">
      <ProceedToApplicationTitle />
      <PoAMessageBody canAddToMultiQuote={canAddToMultiQuote} />
    </PoAMessage>
  );
};

const PoAMessage = styled.div`
  h3 {
    color: ${(props) => props.theme.colours.secondary};
  }

  p {
    color: ${(props) => props.theme.colours.grey70};
    margin-top: 1rem;
  }
`;

const SubmitPoAWithRedux = () => {
  const canAddToMultiQuote = !useSelector(selectMultiQuoteModalDisplayButton);
  return <SubmitPoAMessage canAddToMultiQuote={canAddToMultiQuote} />;
};

export const SubmitProceedToApplicationMessage = () => {
  const canAddToMultiQuote = !useSelector(selectMultiQuoteModalDisplayButton);
  return <SubmitPtAMessage canAddToMultiQuote={canAddToMultiQuote} />;
};

export default SubmitPoAWithRedux;
