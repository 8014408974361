import React, { FC } from 'react';
import headers from './headers';
import MultiSelectTable, { RowCell } from 'shared/components/organisms/MultiSelectTable';
import styled from 'styled-components';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { multiQuoteById } from 'Quotes/Routes';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface Props {
  className?: string;
  paging: IServerSidePaging;
}

const MultiQuotesTable: FC<React.PropsWithChildren<Props>> = (props) => {
  const navigate = useNavigate();

  return <MultiQuotesTableInternal {...props} navigate={navigate} />;
};

export const MultiQuotesTableInternal: FC<React.PropsWithChildren<Props & Navigation>> = ({
  paging,
  className,
  navigate,
}) => {
  const rowClickHandler = (cell: RowCell) => {
    navigate(multiQuoteById(cell.row.original.id));
  };

  return (
    <div className={className} data-testid="multi-quotes-table">
      <MultiSelectTable
        headers={headers}
        paging={paging}
        isLoading={paging.isLoading}
        rows={paging.results}
        rowClickHandler={rowClickHandler}
        className="multi-quotes-table"
        defaultSort={{
          id: 'created_at',
          desc: true,
        }}
      />
    </div>
  );
};

const StyledMultiQuotesTable = styled(MultiQuotesTable)`
  .table-wrapper {
    overflow-x: auto;
  }
`;

export { StyledMultiQuotesTable as MultiQuotesTable };
