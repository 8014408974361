import { doAPIRequest, Methods } from 'Request';
import { objectToGetParams } from 'shared/utils/objectToGetParams';
import { MessageResource, MessageResponseData } from 'shared/types/Messages';
import { useTablePaging } from 'shared/hooks/useTablePaging';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { useEffect, useState } from 'react';

export const MESSAGES_PAGE_SIZE = 50;
export const MESSAGES_BASE_URL = '/messages';

export const toMessageResource = (data: MessageResponseData): MessageResource => {
  return {
    id: data.id,
    type: data.type,
    attributes: {
      messageText: data.attributes.message_text,
      createdBy: data.attributes.created_by,
      resourceId: data.attributes.resource_id,
      createdAt: data.attributes.created_at,
      unread: data.attributes.unread,
      resourceType: data.attributes.resource_type,
      customerId: data.attributes.customer_id,
      customerName: data.attributes.customer_name,
      senderEmail: data.attributes.sender_email,
      internal: data.attributes.internal,
    },
  };
};

export interface MessagePaging extends IServerSidePaging {
  results: MessageResource[];
  isInitialising: boolean;
}

export const useMessagePaging: () => MessagePaging = () => {
  const [isInitialising, setIsInitialising] = useState(true);

  const serverSidePaging = useTablePaging({
    baseUrl: MESSAGES_BASE_URL,
    defaultPageSize: MESSAGES_PAGE_SIZE,
    dataTransform: ({ data }) => data.map(toMessageResource),
  });

  useEffect(() => {
    if (isInitialising && !serverSidePaging.isLoading) {
      setIsInitialising(false);
    }
  }, [serverSidePaging.isLoading, isInitialising]);

  return {
    ...serverSidePaging,
    isInitialising: isInitialising,
  };
};

const loadMessages = async () => {
  const request = {
    method: Methods.GET,
    path: `${MESSAGES_BASE_URL}?${objectToGetParams({
      'page[size]': `${MESSAGES_PAGE_SIZE}`,
    })}`,
  };

  const response = await doAPIRequest<MessageResponseData, any, any>(request);
  return response?.data.map(toMessageResource);
};

export default loadMessages;
