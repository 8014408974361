// _  _     ___    _____
// | \| |   | _ \  |_   _|
// | .` |   |   /    | |
// |_|\_|   |_|_\   _|_|_
// _|"""""|_|"""""|_|"""""|
// "`-0-0-'"`-0-0-'"`-0-0-'

import { DefaultTheme } from 'styled-components';
import React from 'react';

const userguide_url = '/documents/nrt_livequote_userguide.pdf';

const nrtTheme: DefaultTheme = {
  customCSS: `/nrt/nrt-index.css`,

  button: {
    width: '180px',
  },

  navbarHeight: '70px',

  header: {
    style: 'solid',
    bgColor: '#e35101',
  },
  tabs: {
    selectedColor: '#ec6608',
  },

  alerts: {
    danger: {
      backgroundColor: '#e01129',
      borderColor: '#e01129',
      color: '#fff',
      headingColor: '#fff',
    },
    success: {
      backgroundColor: '#e7f2f3',
      borderColor: '#007982',
      color: '#000',
      headingColor: '#000',
    },
    warning: {
      backgroundColor: '#f7c29c',
      borderColor: '#f08539',
      color: '#000',
      headingColor: '#000',
    },
    info: {
      backgroundColor: '#d4dde0',
      borderColor: '#d4dde0',
      color: '#265766',
      headingColor: '#265766',
    },
  },

  breakpoints: {
    S: '576px',
    M: '768px',
    L: '992px',
    XL: '1200px',
    XXL: '1400px',
  },

  defaultTransition: '0.3s ease',

  colours: {
    // NRT Primary (Black) #000000
    primaryA1: '#000000',
    primaryA2: '#333333',
    primaryA3: '#666666',
    primaryA4: '#999999',
    primaryA5: '#cccccc',

    // NRT Primary (blue) #106aa3
    primaryB1: '#106aa3',
    primaryB2: '#4088b5',
    primaryB3: '#70a6c8',
    primaryB4: '#cfe1ed', // lighter variation, better for dropdown :hover state
    primaryB5: '#cfe1ed',

    // NRT Primary (orange) #ec6608
    primaryC1: '#ec6608',
    primaryC2: '#f08539',
    primaryC3: '#f4a36b',
    primaryC4: '#f7c29c',
    primaryC5: '#fbe0ce',

    // NRT Secondary (red) #e01129
    secondaryA1: '#e01129',
    secondaryA2: '#e64154',
    secondaryA3: '#ec707f',
    secondaryA4: '#f3a0a9',
    secondaryA5: '#f9cfd4',

    // Secondary (Purple) / rgb(104,73,131)
    secondaryB1: '#684983',
    secondaryB2: '#866d9c',
    secondaryB3: '#a493b5',
    secondaryB4: '#c3b7cc',
    secondaryB5: '#e1dae6',

    // NRT: Secondary (Gray) // used for page background and DIA background
    secondaryC1: '#222222',
    secondaryC2: '#4e4e4e',
    secondaryC3: '#7a7a7a',
    secondaryC4: '#a7a7a7',
    secondaryC5: '#f5f5f5', // custom lighter variation

    // NRT Secondary (Black) (used in info alerts)
    secondaryD1: '#000000',
    secondaryD2: '#333333',
    secondaryD3: '#666666',
    secondaryD4: '#999999',
    secondaryD5: '#f5f5f5', // custom lighter variation for alert panel

    // NRT Secondary (Dark gray) #222222
    secondaryE1: '#222222',
    secondaryE2: '#4e4e4e',
    secondaryE3: '#7a7a7a',
    secondaryE4: '#a7a7a7',
    secondaryE5: '#d3d3d3',

    // Secondary (Dark Green) / rgb(99,127,108)
    secondaryF1: '#637f6c',
    secondaryF2: '#819989',
    secondaryF3: '#a0b3a7',
    secondaryF4: '#c1ccc4',
    secondaryF5: '#e0e6e1',

    // Other
    grey10: '#ebeaeb',
    grey20: '#dadada',
    grey30: '#c6c6c6',
    grey40: '#b2b2b2',
    grey50: '#9d9d9c',
    grey60: '#878787',
    grey70: '#706f6f',
    grey80: '#575756',
    grey90: '#3c3c3b',
    mainBackgroundColor: `#f5f8fc`,
    secondary: '#004687',
    listItemHover: '#f1f5f7',
    navbarSelect: '#f1f5f7',
    navbarColour: '#666',
    inputBorder: '#cccccc',
    inputText: '#808080',
    navLinkActiveColor: '#ec6608',
    invalidFieldBorderColor: '#e01129',
    copiedToClipboardColor: '#fff',
  },

  shadows: {
    boxShadow: `6px 7px 2px 0 rgba(19,45,72, 0.1)`,
  },

  typography: {
    fontFamilyRegular: '"network_rail_sans_regular", "Calibri", "Helvetica", sans-serif',
    fontFamilyBold: '"network_rail_sans_bold", "Calibri", "Helvetica", sans-serif',
    fontSizeH1: '67.3px',
    fontSizeH2: '50.5px',
    fontSizeH3: '37.9px',
    fontSizeH4: '1.2em',
    fontSizeMd: '14px',
    fontSizeSm: '12px',
  },
  favicons: {
    favicon16: `/nrt/favicons/favicon-16x16.png`,
    favicon32: `/nrt/favicons/favicon-32x32.png`,
    appleTouchIcon: `/nrt/favicons/apple-touch-icon.png`,
    shortcutIcon: `/nrt/favicons/favicon.ico`,
  },
  logos: {
    main: '/nrt/images/logo-transparent.png',
    splash: '/nrt/images/logo-on-white.png',
  },
  backgroundImages: {
    splashBackground: '/nrt/images/splash-bg.jpg',
    homepageNewsBackground: '/nrt/images/welcome-page-promo-bg.png',
  },
  htmlEditorTheme: 'snow',
  content: {
    companyName: 'Network Rail Telecom',
    productName: 'Digital Portal',
    title: 'Network Rail Telecom',
    defaultMapsPosition: { lat: 51.454167, lng: -0.973056 },

    homeIntro: (
      <p>
        Network Rail Telecom (NRT) is the company&rsquo;s national, telecoms asset management function. NRT owns all of
        the company&rsquo;s telecoms assets. Close to 20,000km of fibre-optic cable (the UK&rsquo;s third largest
        network), backed up by 18,000km of hard cable and 2,500 masts, provide state-of-the-art communication services
        for the whole railway network.
        <br /> <br />
        Our intuitive and customer-friendly portal is simple to use, but should you need a helping hand, our{' '}
        <a href={userguide_url} rel="noopener noreferrer" target="_blank">
          step-by-step userguide
        </a>{' '}
        has been created providing assistance on how to best navigate the site.
      </p>
    ),
    homeNews: (
      <>
        <h2 className="h3">Innovation on our Railways</h2>
        <p>
          Innovation is a vital part of our efforts to give safe, reliable services without the need to increase
          spending. We&rsquo;re investing &pound;245m to improve technology and explore new ideas, products and services
          to benefit you and freight users, aiming to return around &pound;3 for every &pound;1 spent.
        </p>
      </>
    ),
    homeMapButtonTitle: 'Neos Networks Map',
    usefulLinks: {
      userGuide: {
        title: 'Configurable link',
        url: 'https://www.networkrail.co.uk',
      },
      generalTermsAndConditions: {
        title: 'Configurable link',
        url: 'https://www.networkrail.co.uk',
      },
      ethernetTermsAndConditions: {
        title: 'Configurable link',
        url: 'https://www.networkrail.co.uk',
      },
      opticalTermsAndConditions: {
        title: 'Configurable link',
        url: 'https://www.networkrail.co.uk',
      },
      cloudTermsAndConditions: {
        title: 'Configurable link',
        url: 'https://www.networkrail.co.uk',
      },
    },
    contactInfo: {
      introText: 'In the first instance, please contact your Network Rail Representative. Otherwise:',
      support: {
        phone: '',
        email: 'enquiries@networkrail.co.uk',
      },
      pricing: {
        phone: '+44 (0) 118 953 4758',
        email: 'pricing@networkrail.co.uk',
      },
      orders: {
        phone: '+44 (0) 345 070 4301',
        email: 'orders@networkrail.co.uk',
      },
      enquiries: {
        email: 'enquiries@networkrail.co.uk',
      },
      provisioning: {
        email: 'provisioning@networkrail.co.uk',
      },
    },
    footer: {
      content: (
        <p>
          Network Rail owns, operates and develops Britain&rsquo;s Railway Infrastructure, comprising of 20,000 miles of
          track, 30,000 bridges, tunnels and viaducts and thousands of signals, level crossings and railway stations.
          <br />
          The registered office of Network Rail Limited is 1 Eversholt Street, London, NW1 2DN.
          <br />
          <br />
          The website can be found at{' '}
          <a href="https://www.networkrail.co.uk" rel="noopener noreferrer" target="_blank">
            www.networkrail.co.uk
          </a>
          .
        </p>
      ),
      legalLinks: {
        company: {
          name: <>&copy; Network Rail</>,
          url: 'https://www.networkrail.co.uk',
        },
        complaintsProcedure: 'https://neosnetworks.com/complaints-procedure/',
        codesOfPractice: 'https://neosnetworks.com/codes-of-practice/',
        privacyPolicy: 'https://neosnetworks.com/privacy-policy/',
        acceptableUsePolicy: 'https://neosnetworks.com/acceptable-use-policy/',
        modernSlaveryStatement:
          'https://neosnetworks.com/wp-content/uploads/2022/09/NN_Modern-Slavery-statement-FT22_FINAL.pdf', // TODO: update link
      },
      socialLinks: {
        linkedin: 'https://www.linkedin.com/company/network-rail',
        twitter: 'https://twitter.com/networkrail',
      },
    },
    promotionsSlot: {
      badgeText: 'LIVEQUOTE Updates',
    },
  },
};

export default nrtTheme;
