import React, { FC } from 'react';
import Icon from 'shared/components/atoms/Icon';
import styled from 'styled-components';

interface Copy {
  isRecentlyCopied: boolean;
}

const Copied = styled.span`
  font-size: 0.9rem;
  color: ${(props) => props.theme.colours.copiedToClipboardColor ?? props.theme.colours.primaryB1};
`;

export const Copy: FC<React.PropsWithChildren<Copy>> = ({ isRecentlyCopied }) => {
  return (
    <>
      <span className="pl-2">
        <Icon name="file_copy" size="normal" />
      </span>
      {isRecentlyCopied && <Copied className="d-inline-block pl-1">Copied!</Copied>}
    </>
  );
};
