export default [
  {
    question: 'How do I change supplier preferences?',
    answer: [
      'Not everyone will want to see every supplier. If you are the administrator, in order to adapt your supplier viewing options simply:',
      '1. Select "Preferences" from the primary navigation menu.',
      '2. This will load the "Preferences" page which shows the suppliers that will be shown in the quote results. The page also shows which suppliers will not be shown in results.',
      '3. To change these settings, click the "Edit preferences" button.',
      '4. You can choose which suppliers are shown in your quote results by turning the relevant toggle on (green) or off (grey).',
      '5. To save your changes, click "Save changes".',
      '6. To discard your changes, click "Cancel".',
    ],
  },
];
