import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Column from 'shared/components/atoms/Column';
import Icon from 'shared/components/atoms/Icon';
import cx from 'classnames';
import { DownstreamResponse } from 'Order/types/downstreamResponse';
import { IOrder } from 'Order/types/store';

interface Props {
  className?: string;
  downstream_responses: DownstreamResponse['attributes'][];
  cerillion_basket_id: IOrder['cerillion_basket_id'];
}

const Button = styled.button`
  width: 100%;
  color: ${(props) => props.theme.colours.primaryB1};
`;

const CRMResponse: FC<React.PropsWithChildren<Props>> = ({ className, downstream_responses, cerillion_basket_id }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <section className={className}>
      <div className="row no-gutters position-relative">
        <Column>
          <Button type="button" className="font-weight-bold text-left" onClick={() => setOpen(!isOpen)}>
            CRM Response
            <Icon className={cx('position-absolute arrow-icon', { open: isOpen })} name="keyboard_arrow_down" />
          </Button>
        </Column>
      </div>
      {isOpen && (
        <div className="row pt-3 no-gutters">
          <Column>
            <p>Sales basket id: {cerillion_basket_id ? <strong>{cerillion_basket_id}</strong> : <samp>null</samp>}</p>
            <p>API response(s):</p>
            <pre>
              <samp>{JSON.stringify(downstream_responses, null, '  ')}</samp>
            </pre>
          </Column>
        </div>
      )}
    </section>
  );
};

const StyledCRMResponse = styled(CRMResponse)`
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  border: solid 0.5px ${(props) => props.theme.colours.grey10};
  background-color: #fff;
  padding: 0.5em 1em;
  width: 100%;

  .arrow-icon {
    transition: transform 200ms ease-in-out;
    top: 3px;
    right: 0;

    &.open {
      transform: rotate(180deg);
    }
  }
`;

export { StyledCRMResponse as CRMResponse };
