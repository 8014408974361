import bandwidthTypes, { opticalBandwidthTypes } from './Bandwidth/bandwidthTypes';
import { BearerType, OpticalBearer } from './Bearer/BearerType';

const bearerToBandwidth: { [key in BearerType]: string[] } = {
  [BearerType.SMALL]: bandwidthTypes.SMALL,
  [BearerType.MEDIUM]: bandwidthTypes.MEDIUM,
  [BearerType.LARGE]: bandwidthTypes.LARGE,
  [BearerType.LARGER]: bandwidthTypes.OPTICAL_MEDIUM,
  [BearerType.LARGEST]: bandwidthTypes.OPTICAL_LARGE,
};

export const bearerToBandwidthOptical: { [key in OpticalBearer]: string[] } = {
  [BearerType.LARGE]: opticalBandwidthTypes.SMALL,
  [BearerType.LARGER]: opticalBandwidthTypes.MEDIUM,
  [BearerType.LARGEST]: opticalBandwidthTypes.LARGE,
};

export default bearerToBandwidth;
