import React, { FC } from 'react';
import styled from 'styled-components';
import { IAlert } from 'Alerts/types/alert';
import Column from 'shared/components/atoms/Column';
import { formatDateTime } from 'shared/utils/dateHelper';
import { orderById } from 'Order/routes';
import { quoteById } from 'Quotes/Routes';
import { AlertType } from 'Alerts/types/alertType';
import { AlertEventType } from 'Alerts/types/alertEventType';
import { enumToDisplayString } from 'shared/utils/enumToDisplayString';
import { shortenId } from 'shared/utils/idFormatter';
import classnames from 'classnames';
import { isBefore, subDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';
import {
  Cancelled,
  CerillionStateUpdate,
  InReview,
  NewNNIOrdered,
  NewNote,
  Placed,
  Returned,
  UnknownAlertType,
} from 'Alerts/AlertItem/AlertMessages';

interface IAlertItem {
  className?: string;
  alert: IAlert;
  onClick(companyId: string, alertId: IAlert['id']): void;
}

const getAlertLink = (alert: IAlert) => {
  switch (alert.attributes.resource_type) {
    case AlertType.ORDER:
      return orderById(alert.attributes.resource_id);
    case AlertType.QUOTE:
    default:
      return quoteById(alert.attributes.resource_id);
  }
};

const eventComponents: Record<AlertEventType, React.FC<{ eventType: AlertEventType }>> = {
  [AlertEventType.CANCELLED]: Cancelled,
  [AlertEventType.IN_REVIEW]: InReview,
  [AlertEventType.PLACED]: Placed,
  [AlertEventType.RETURNED]: Returned,
  [AlertEventType.SSE_NOTE]: NewNote,
  [AlertEventType.CUSTOMER_NOTE]: NewNote,
  [AlertEventType.NNI_ORDERED]: NewNNIOrdered,
  [AlertEventType.CERILLION_QUOTE_STAGE_UPDATE]: CerillionStateUpdate,
  [AlertEventType.CERILLION_ORDER_STAGE_UPDATE]: CerillionStateUpdate,
};

function getEventDisplayComponent(event_type: AlertEventType) {
  const Display = eventComponents[event_type] || UnknownAlertType;
  return <Display eventType={event_type} />;
}

export const renderAlertMessage = (alert: IAlert) => {
  const eventType = alert.attributes.event_type;
  return getEventDisplayComponent(eventType) ?? <span>{` ${enumToDisplayString(eventType)}`}</span>;
};

const AlertItem: FC<React.PropsWithChildren<IAlertItem>> = (props) => {
  const navigate = useNavigate();
  return <AlertItemInternal {...props} navigate={navigate} />;
};

export const AlertItemInternal: FC<React.PropsWithChildren<IAlertItem & Navigation>> = ({
  className,
  alert,
  onClick,
  navigate,
}) => {
  const {
    attributes: { customer_id, customer_name, resource_id, resource_type, created_at, event_type, unread },
    id: alertId,
  } = alert;

  const fiveDaysAgo = isBefore(new Date(created_at), subDays(new Date(), 5));

  return (
    <div
      data-testid="alert-item"
      className={classnames('alert-item', className, {
        highlight: fiveDaysAgo && event_type === AlertEventType.RETURNED && unread,
        unread,
      })}
      onClick={() => {
        onClick(customer_id, alertId);
        navigate(getAlertLink(alert));
      }}
    >
      <div className="container">
        <div className="row">
          <Column defaultWidth={8}>
            <span className="font-weight-bold">{`${customer_name} `}</span>
            {`${resource_type.toLowerCase()} `}
            <span className="resource-id font-weight-bold">{`${resource_type[0]}-${shortenId(resource_id)}`}</span>
            {renderAlertMessage(alert)}
          </Column>
          <Column defaultWidth={4}>
            <div className="text-right">
              <strong>{formatDateTime(created_at)}</strong>
            </div>
          </Column>
        </div>
      </div>
    </div>
  );
};

const StyledAlertItem = styled(AlertItem)`
  line-height: 44px;
  display: block;
  border-bottom: 1px solid ${(props) => props.theme.colours.grey10};
  font-size: 1rem;

  &:first-of-type {
    border-top: 1px solid ${(props) => props.theme.colours.grey10};
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background: ${(props) => props.theme.colours.listItemHover};
  }

  &.highlight {
    background-color: ${(props) => props.theme.colours.primaryC1}!important;
    color: white;
  }

  &.unread {
    background-color: ${(props) => props.theme.colours.primaryB1};
    color: white;
  }
`;

export default StyledAlertItem;
