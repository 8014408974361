import React from 'react';
import { ActionMeta, GroupBase, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from 'styled-components';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';

export interface ICustomComponents {
  Menu?: any;
  Option?: any;
}

export interface IDropdownOption {
  label: string;
  value: string;
}

interface IDropdownProps<TOption> {
  className?: string;
  classNamePrefix?: string;
  closeMenuOnSelect?: boolean;
  controlShouldRenderValue?: boolean;
  customComponents?: ICustomComponents;
  defaultMenuIsOpen?: boolean;
  defaultValue?: TOption | null;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  options: TOption[] | GroupBase<TOption>[];
  placeholder?: string;
  styles?: StylesConfig<TOption>;
  value?: TOption | TOption[] | null;
  onChange?(selection: TOption | null, action: ActionMeta<TOption>): void;
  isLoading?: boolean;
  onCreateOption?(inputValue: string): void;
  allowCreateNewOption?: boolean
}

const Dropdown = <TOption extends IDropdownOption>(props: IDropdownProps<TOption>): JSX.Element => {
  const theme = useTheme();

  const customStyles: StylesConfig<TOption> = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? theme.colours.primaryB1
        : state.isFocused
        ? theme.colours.primaryB4
        : 'inherit',
      color: state.isSelected ? 'white' : state.isDisabled ? theme.colours.grey40 : 'inherit',
      cursor: state.isDisabled ? 'not-allowed' : 'inherit',
    }),
  };

  const isClearable = props.isClearable === undefined ? true : props.isClearable;
  const controlShouldRenderValue = props.controlShouldRenderValue === undefined ? true : props.controlShouldRenderValue;
  const closeMenuOnSelect = props.closeMenuOnSelect === undefined ? true : props.closeMenuOnSelect;

  return (
    <CreatableSelect<TOption, boolean>
      aria-label="dropdown"
      styles={customStyles}
      classNames={{
        singleValue: () => 'single-value',
      }}
      {...props}
      inputId={`react-select-input-${Date.now()}`}
      onChange={(value: OnChangeValue<TOption, boolean>, action) => {
        // TODO - Support Multi (that is MultiValue<TOption>)
        props.onChange?.(value as TOption, action as ActionMeta<TOption>);
      }}
      defaultMenuIsOpen={props.defaultMenuIsOpen || false}
      controlShouldRenderValue={controlShouldRenderValue}
      closeMenuOnSelect={closeMenuOnSelect}
      components={props.customComponents || {}}
      isClearable={isClearable}
      isDisabled={props.isDisabled || false}
      isValidNewOption={props.allowCreateNewOption ? (v: string) => v !== '' : () => false}
      placeholder={<div data-testid={props.placeholder || 'Select...'}>{props.placeholder || 'Select...'}</div>}
      isMulti={props.isMulti || false}
    />
  );
};

export default Dropdown;
