import { IBackendUserAttributes } from '../types/backendUser';
import { UserState } from '../types/user';

const userHasLastLoginDate = (user: IBackendUserAttributes): boolean => {
  return user.last_logged_in !== '' && user.last_logged_in !== null;
};

const userHasEmailSentDate = (user: IBackendUserAttributes): boolean => {
  return user.last_verification_email_sent !== '' && user.last_verification_email_sent !== null;
};

export const determineBackendUserStatus = (user: IBackendUserAttributes): UserState => {
  if (user.enabled === false) {
    return UserState.Blocked;
  } else {
    if (userHasLastLoginDate(user)) {
      return UserState.Active;
    }
    if (!userHasLastLoginDate(user) && userHasEmailSentDate(user)) {
      return UserState.InviteSent;
    }
    return UserState.InviteNotSent;
  }
};
