import React, { FunctionComponent } from 'react';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import { QuoteStatus } from 'Quotes/types/quoteRecordAttributesBase';

type Props = {
  className?: string;
  setQuoteStatus(QuoteStatus: string | null): void;
};

const options = Object.values(QuoteStatus).map((status) => ({
  label: status.toLowerCase(),
  value: status.toLowerCase(),
}));

const QuoteStatusFilter: FunctionComponent<React.PropsWithChildren<Props>> = ({ setQuoteStatus }) => {
  return (
    <div className="control">
      <label>Status</label>
      <Dropdown
        options={options}
        onChange={(quoteStatus: IDropdownOption) => setQuoteStatus(quoteStatus ? quoteStatus.value : null)}
        className="select-input quote-status-dropdown"
      />
    </div>
  );
};

export default QuoteStatusFilter;
