import Alert, { Alerts } from 'shared/components/atoms/Alert';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import React from 'react';
import styled from 'styled-components';
import Icon from 'shared/components/atoms/Icon';

export const TOOLTIP_TEXT = `Neos Networks Access Tail`;

const Header = styled.h4`
  font-size: 1.5em;
  //font-weight: bold;
  color: #fff !important;
`;

const List = styled.ul`
  list-style-position: inside;
  padding-left: 0;
`;

const AlignedIcon = styled(Icon)`
  vertical-align: text-bottom;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`;

export const NNATTooltip = () => (
  <Tooltip
    text={TOOLTIP_TEXT}
    multiline
    config={{
      maxWidth: '600px',
      verticalAlign: 'text-bottom',
    }}
  >
    <div>
      <Header>Neos Networks Access Tail</Header>
      <List>
        <li>Neos Networks owned and managed Full Fibre Access Tail</li>
        <li>Symmetrical, uncontended, unlimited usage</li>
        <li>5 hour fix time, 24/7/365</li>
        <li>60 business day delivery lead time (subject to survey)</li>
      </List>
    </div>
  </Tooltip>
);

export const NNATCapablePostcode = () => {
  return (
    <Alert alertType={Alerts.INFO}>
      <span className="pr-1" data-testid="nnat-capable-postcode">
        Neos Networks Access Tails are available at this postcode
      </span>
      <NNATTooltip />
    </Alert>
  );
};
export const NNATAlert = () => {
  return (
    <Alert alertType={Alerts.INFO}>
      <span className="pr-1" data-testid="nnat-price">
        Neos Networks Access Tail
      </span>
      <NNATTooltip />
    </Alert>
  );
};

export const NNATWithTick = () => {
  return (
    <span className="pr-1" data-testid="nnat-price">
      Neos Networks Access Tail
      <AlignedIcon name="done" className="primary" />
    </span>
  );
};

export const NNATWarning = () => (
  <Alert alertType={Alerts.WARNING}>
    <h3>You have not selected a location</h3>
    <p>
      Please choose a location for your point(s). Without this we cannot quote for Neos Network Access Tail if the
      service is available.
    </p>
  </Alert>
);
