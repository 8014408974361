export function objectToGetParams(obj: Record<string, string | number | boolean | undefined>) {
  return Object.keys(obj)
    .filter((key) => {
      return typeof obj[key] === 'string' || typeof obj[key] === 'number' || typeof obj[key] === 'boolean';
    })
    .map(function (key) {
      return `${key}=${obj[key]}`;
    })
    .join('&');
}
