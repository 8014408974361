import { BearerType } from '../BearerType';
import { getIntegerFromString } from '../../../../../../shared/utils/getIntegerFromString';

const getBandwidthValuesForCloudConnect = (bearer: BearerType, supportedBandwidths: number[]) => {
  if (bearer) {
    const bearerValue: number = getIntegerFromString(bearer);
    const availableBandwidths = supportedBandwidths.filter((bandwidth) => bandwidth <= bearerValue);
    return availableBandwidths.map(String);
  } else {
    return undefined;
  }
};

export default getBandwidthValuesForCloudConnect;
