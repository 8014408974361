import React, { FC } from 'react';
import { ALKLocation, IOpenReachAddressSite, Qualifier } from 'shared/types/postcodeResults';
import Column from 'shared/components/atoms/Column';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import AddressTypeBadge, { AddressType as AddressTypeForBadge } from 'shared/components/atoms/AddressTypeBadge';
import PresentForRoles from 'Auth/PresentForRoles';
import CopyToClipboardBadge from 'shared/components/molecules/CopyToClipboardBadge';
import { userPermissions } from 'shared/utils/permissions';

const tooltipText = `A Gold ALK (Address Level Key) is an installation address reference, 
displayed when an address search matches an address known by Openreach (a Gold match). 
This address matching activity provides an upfront validation of the address, 
preventing provisioning errors. Where a Gold match is not available, 
EoFTTC/P services cannot be quoted.`;

interface Props {
  address?: string;
  selectedALK?: ALKLocation;
  selectedOpenreachAddress?: IOpenReachAddressSite;
}

export const CompatibleFTTXLocation: FC<React.PropsWithChildren<Props>> = ({
  address,
  selectedALK,
  selectedOpenreachAddress,
}) => {
  const selected = selectedALK ? selectedALK : selectedOpenreachAddress;
  if (!selected?.attributes.alk) return null;

  return (
    <FTTXLocation
      address={address}
      alk={selected?.attributes.alk}
      qualifier={selected.attributes.qualifier}
      districtCode={selected.attributes.css_district_code}
    />
  );
};

interface FTTXLocation {
  address?: string;
  alk: string;
  qualifier: Qualifier | null | undefined;
  districtCode: string;
}

const FTTXLocation: FC<React.PropsWithChildren<FTTXLocation>> = ({ address, alk, qualifier, districtCode }) => {
  return (
    <div>
      {address && <address>{address}</address>}

      <div className="row">
        <Column defaultWidth={12}>
          <AddressTypeBadge addressType={AddressTypeForBadge.OPENREACH} alk={alk} qualifier={qualifier} />
          <div className="d-inline-block pl-2">
            <Tooltip text={tooltipText} />
          </div>
        </Column>
      </div>
      <PresentForRoles roles={userPermissions.viewDistrictCode}>
        <div className="row">
          <Column defaultWidth={12}>
            <CopyToClipboardBadge colour="#7dc242" label="District code" value={districtCode} />
          </Column>
        </div>
      </PresentForRoles>
    </div>
  );
};
