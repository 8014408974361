import React, { FunctionComponent } from 'react';
import { CerillionStage, OrderStatus } from 'Order/types/orderRecord';
import styled from 'styled-components';

interface IOrderState {
  className?: string;
  state: CerillionStage | OrderStatus;
}

const OrderState: FunctionComponent<React.PropsWithChildren<IOrderState>> = ({ className, state }) => (
  <span data-testid="order-state" className={`state ${state} ${className}`}>
    {state.toUpperCase().replaceAll('_', ' ')}
  </span>
);

const styledOrderState = styled(OrderState)`
  display: inline-block;
  color: white;
  font-family: ${(props) => props.theme.typography.fontFamilyBold};
  text-align: center;
  border-radius: 0.7em;
  font-size: 0.8em;
  padding: 0 1em;

  &.draft,
  &.ready {
    background-color: white;
    color: ${(props) => props.theme.colours.secondaryE1};
  }

  &.in_review,
  &.returned {
    background-color: ${(props) => props.theme.colours.primaryC1};
  }

  &.placed {
    background-color: ${(props) => props.theme.colours.primaryB1};
  }

  &.pending {
    background-color: ${(props) => props.theme.colours.secondaryD1};
  }

  &.cancelled {
    background-color: ${(props) => props.theme.colours.secondaryA1};
  }

  &.acceptance,
  &.initial_planning,
  &.committing_delivery_date,
  &.physical_works {
    background-color: ${(props) => props.theme.colours.primaryC1};
  }

  &.confirming_handover_date {
    background-color: ${(props) => props.theme.colours.primaryB1};
  }
`;

export default styledOrderState;
