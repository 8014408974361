import React, { FunctionComponent } from 'react';
import { IPType } from '../../../Configure/DIAConfig/types/ip';
import { getIPTypeLabel } from 'Quotes/QuoteBuilder/utils/ip';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { IAEndLocation } from 'Quotes/types/store';

interface IPChargeProps {
  ipType?: IPType;
  isManagedDIA: boolean;
  diaIPAllocation: IAEndLocation['dia_ip_allocation'];
  ipCost: number;
}

const IPCharge: FunctionComponent<IPChargeProps> = ({ ipType, isManagedDIA, diaIPAllocation, ipCost }) => {
  return (
    <div className="price-summary__segment-wrapper">
      <div className="segment_label pl-2">
        <small>
          Includes {getIPTypeLabel(ipType, isManagedDIA, diaIPAllocation)} at {currencyFormatter.format(ipCost)} per
          year
        </small>
      </div>
    </div>
  );
};

export default IPCharge;
