import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface IEmptyUsersList {
  className?: string;
}

const EmptyUsersList: FunctionComponent<React.PropsWithChildren<IEmptyUsersList>> = ({ className }) => (
  <p className={`${className} no-users-info`}>
    It looks like you don&apos;t have any users associated with your account yet.
  </p>
);

const StyledEmptyUsersList = styled(EmptyUsersList)<IEmptyUsersList>`
  color: ${(props) => props.theme.colours.secondary};
  font-size: ${(props) => props.theme.typography.fontSizeH4};
`;

export default StyledEmptyUsersList;
