import { IOrderTemplate } from 'Order/types/orderTemplate';
import { noop } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import Modal, { IModalProps } from 'shared/components/molecules/Modal';
import { useOrderTemplates } from 'Order/crud/orderTemplatesApi';
import Alert, { Alerts } from 'shared/components/atoms/Alert';

export interface UpdateOrderTemplateModaProps extends Omit<IModalProps, 'title' | 'children'> {
  customerId: string;
  orderTemplate: IOrderTemplate;
  useOrderTemplatesHook: typeof useOrderTemplates;
  onClose?: () => void;
  onSuccess?: (updatedOrderTemplate: IOrderTemplate) => void;
}

export const UpdateOrderTemplateModal = ({
  orderTemplate,
  customerId,
  useOrderTemplatesHook,
  onClose = noop,
  onSuccess = noop,
  ...props
}: UpdateOrderTemplateModaProps) => {
  const orderTemplates = useOrderTemplatesHook(customerId);

  const updateOrderTemplateAction = () =>
    orderTemplates
      .updateOrderTemplate(orderTemplate)
      .then((response) => {
        toast.success('Order template updated successfully');
        onSuccess(response);
        onClose();
      })
      .catch(() => {
        toast.error('Failed to update order template');
      });

  return (
    <Modal
      {...props}
      title="Update order template data"
      onConfirm={updateOrderTemplateAction}
      onClose={onClose}
      confirmBtnLoading={orderTemplates.isLoading}
    >
      <Alert alertType={Alerts.INFO}>
        Only the <strong>Billing & Contact Information</strong> details will be saved to the order template.
      </Alert>
      <p>Are you sure you want to update the order template?</p>
    </Modal>
  );
};
