export default [
  {
    question: "Why can't I convert my quote to an order?",
    answer: [
      "If you're having trouble converting a quote to an order, first check that the quote you're looking to order against is still within its valid term. If it has expired, you'll need to re-run the quote search and progress the new quote to order.",
      "If you're still having issues and the quote has just one day to expiration, we advise re-running the quote. Should the new quote pricing be the same as the original, progress the new quote to order and let the original expire. If the pricing of the new quote has increased, and you believe the original quote is still valid, please contact your Account Director.",
    ],
  },
  {
    question: 'Why am I getting errors when I try to complete the order fulfilment information?',
    answer: [
      'Several of the data fields on the order fulfilment form require validated responses, including telephone number and email address format. The error message should make it clear which data field has the error and will display the data that has been entered.  After completing (or amending) each stage of the order fulfilment information, click on the "Save details" button to see if the error message has disappeared.',
      'Only once all sections have been completed without errors, can the "Order" button be clicked.',
      'If all information has been correctly entered without errors, but the "Order" button is not able to be clicked, please contact digital@neosnetworks.com or ',
      'call +44 (0) 345 305 3332',
    ],
  },
  {
    question: 'How can I find out what is happening with my order?',
    answer: [
      "If you've placed an order and want an update on the status, you can easily access this by viewing the order in LIVEQUOTE. Within the order you will be able to see the latest status information at the top of the page.",
      'If the order has been placed as expected the status in this area should state "Placed" and the date that this was received. If a different status is being shown, please refer to the userguide for more information. There are several stages that your order may be at including cancelled, in review or returned to obtain more information before proceeding.',
      "Don't forget to check the order notes to see if any additional information has been shared, by clicking on the orange message icon at the bottom right-hand side of the page.",
      'Should the delivery details section be displayed, you can also see the detailed order stage information in this location.',
    ],
  },
  {
    question: "How do I change the details of an order that's been placed?",
    answer: [
      'Once an order has been validated and confirmed as placed, the order cannot be modified within LIVEQUOTE. Each section of the order fulfilment page will have a green closed padlock next to it to symbolise that this can no longer be edited.',
      'It may be possible to modify some aspects of the order via the Orders Team, however this cannot be guaranteed, and additional charges may apply. You can reach out to the Orders team using the following contact details:',
      '- T: +44 (0) 345 070 4301',
      '- E: orders@neosnetworks.com',
    ],
  },
  {
    question: 'I am having trouble with the LIVEQUOTE portal. Who can help?',
    answer: [
      'Should you encounter any issues when using LIVEQUOTE, please reach out to our digital support team on +44 (0) 345 305 3332 or digital@neosnetworks.com who will be able to assist you.',
    ],
  },
];
