import { useEffect, useState } from 'react';
import { ICloudProvider } from './types';
import { doRequest } from 'Request';

interface IUseCloudProvidersFetch {
  onSuccess(data: ICloudProvider[]): void;
}

export const useCloudProvidersFetch = ({ onSuccess }: IUseCloudProvidersFetch) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && !error) {
      setLoading(true);
      (async () => {
        try {
          const { data } = await doRequest({
            path: `/cloud-connect-providers/`,
          });
          onSuccess(data);
        } catch (e) {
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { error, loading };
};
