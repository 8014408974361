import React, { FunctionComponent } from 'react';

import AEndLocation from './AEnd';
import BEndLocation from './BEnd';

export enum EndType {
  AEnd = 'aEnd',
  BEnd = 'bEnd',
}

interface IEthernetPointProps {
  end: EndType;
  title?: string;
}

// todo A/B End can probably be combined into a single dynamic component
const EthernetPoint: FunctionComponent<React.PropsWithChildren<IEthernetPointProps>> = ({ end, title }) =>
  end === EndType.AEnd ? <AEndLocation title={title} /> : <BEndLocation title={title} />;

export default EthernetPoint;
