import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';

const bandwidthTypes = {
  SMALL: ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
  MEDIUM: ['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000'],

  LARGE: ['1000', '2000', '3000', '4000', '5000', '6000', '7000', '8000', '9000', '10000'],

  OPTICAL_SMALL: ['10000'],

  OPTICAL_MEDIUM: featureFlag.isEnabled(Feature.Optical100G400G) ? ['10000', '100000'] : ['10000'],
  OPTICAL_LARGE: featureFlag.isEnabled(Feature.Optical100G400G) ? ['10000', '100000', '400000'] : ['10000'],
};

export const opticalBandwidthTypes = {
  SMALL: ['10000'],
  MEDIUM: ['10000', '100000'],
  LARGE: ['10000', '100000', '400000'],
};

export const POPULAR_BANDWIDTHS = ['100', '200', '500', '1000', '10000'];

export const getPopularOpticalBandwidths = () =>
  featureFlag.isEnabled(Feature.Optical100G400G) ? ['10000', '100000', '400000'] : POPULAR_BANDWIDTHS;

export default bandwidthTypes;
