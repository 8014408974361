import { IPAFAddressSiteResponse } from 'shared/types/postcodeResults';
import { doRequest } from '../../../../Request';

const getPAFAddressesForLocation = (location: string): Promise<IPAFAddressSiteResponse> => {
  return doRequest({
    path: `/paf-addresses`,
    params: {
      location: location.replace(/\s/g, ''),
    },
  });
};

export default getPAFAddressesForLocation;
