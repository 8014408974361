import { INewNNIRequest, ISiteConfig } from '../../../types/location';
import Column from '../../../../shared/components/atoms/Column';
import React from 'react';
import { NewNNIJobReference } from './index';

export function ShadowNNILocation(props: {
  shadowRef: string;
  nniRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  siteConfig: ISiteConfig;
}) {
  return (
    <div className="row no-gutters mt-1">
      <Column defaultWidth={2} offsetDefaultWidth={2}>
        <span className="font-weight-bold">Shadow VLAN</span>
      </Column>
      <Column defaultWidth={8}>
        <div className="shadow-nni-label">{props.shadowRef}</div>
        <NewNNIJobReference nniRequest={props.nniRequest} jobReference={props.siteConfig.shadow_nni_job_reference} />
      </Column>
    </div>
  );
}
