import { ordersList } from 'Order/routes';
import { IQuoteListItem } from 'Quotes/types/quoteRecord';
import { IQuoteListItemWithOrderId } from 'Quotes/types/store';
import React, { FC } from 'react';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import Modal, { IModalProps } from 'shared/components/molecules/Modal';
import { pluralize } from 'shared/utils/pluralize';
import { QuoteCompactSummaryTable } from './QuoteCompactSummaryTable';
import { QuoteCompactSummaryWithOrderTable } from './QuoteCompactSummaryWithOrderTable';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface MultiOrderSummaryModalProps extends Omit<IModalProps, 'children' | 'title'> {
  successfullyCreatedOrders: IQuoteListItemWithOrderId[];
  successfullyCreatedPOAQuotes: IQuoteListItem[];
  failedQuotes: IQuoteListItem[];
}

const ScrollableContent = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

export const MultiOrderSummaryModal: FC<React.PropsWithChildren<MultiOrderSummaryModalProps>> = (props) => {
  const navigate = useNavigate();
  return <MultiOrderSummaryModalInternal {...props} navigate={navigate} />;
};

export const MultiOrderSummaryModalInternal: FC<React.PropsWithChildren<MultiOrderSummaryModalProps & Navigation>> = ({
  successfullyCreatedOrders,
  successfullyCreatedPOAQuotes,
  failedQuotes,
  navigate,
  ...modalProps
}) => {
  return (
    <Modal
      {...modalProps}
      confirmBtnLabel="Go to Orders"
      title="Proceed to order"
      size="lg"
      onConfirm={() => {
        navigate(ordersList);
      }}
    >
      <ScrollableContent>
        {successfullyCreatedOrders.length > 0 && <SuccessfullyCreatedOrders quotes={successfullyCreatedOrders} />}

        {successfullyCreatedPOAQuotes.length > 0 && <POACreatedOrders quotes={successfullyCreatedPOAQuotes} />}

        {failedQuotes.length > 0 && <FailedQuotes quotes={failedQuotes} />}
      </ScrollableContent>
    </Modal>
  );
};

interface SuccessfullyCreatedOrdersProps {
  quotes: IQuoteListItemWithOrderId[];
}
const SuccessfullyCreatedOrders = ({ quotes }: SuccessfullyCreatedOrdersProps) => {
  return (
    <div>
      <Alert alertType={Alerts.SUCCESS}>
        {quotes.length} {pluralize('order', quotes.length)} successfully created.
      </Alert>

      <p>The following quotes have been proceeded to order.</p>

      <QuoteCompactSummaryWithOrderTable quotes={quotes} />
    </div>
  );
};

interface POACreatedOrdersProps {
  quotes: IQuoteListItem[];
}
const POACreatedOrders = ({ quotes }: POACreatedOrdersProps) => {
  return (
    <div>
      <Alert alertType={Alerts.INFO}>
        {quotes.length} {pluralize('quote', quotes.length)} need some additional checks.
      </Alert>

      <p>
        The following quotes need some additional checks before they can proceed to order. You account manager will
        contact you shortly.
      </p>

      <QuoteCompactSummaryTable quotes={quotes} />
    </div>
  );
};

interface FailedQuotesProps {
  quotes: IQuoteListItem[];
}
const FailedQuotes = ({ quotes }: FailedQuotesProps) => {
  return (
    <div>
      <Alert alertType={Alerts.DANGER}>
        {quotes.length} {pluralize('quote', quotes.length)} encountered a technical error.
      </Alert>

      <QuoteCompactSummaryTable quotes={quotes} />
    </div>
  );
};
