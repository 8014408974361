import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import {
  Cell,
  TableToggleCommonProps,
  useBlockLayout,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { usePreviousState } from 'shared/utils/customHooks';
import { equalsOrEmptyFilter } from 'shared/utils/equalsOrEmptyFilter';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { SortDirection } from 'shared/hooks/useTablePaging';
import TableNames from 'Tables/types/tableNames';
import { useSetHeaderCellWidth } from 'shared/hooks/useHeaderCellWidth';
import { Pagination } from 'shared/components/organisms/MultiSelectTable/Pagination';
import { renderCell } from 'shared/components/organisms/MultiSelectTable/Cells';

export type RowCell = Cell & {
  column: {
    id: string;
  };
  row: {
    original: {
      id: string;
      quotation_line_id: string;
      quote?: {
        customer_id: string;
      };
      priceId?: string;
      tooltip?: string;
    };
  };
};

export interface ISingleFilter {
  id: string;
  value: string;
}

export type IRow = {
  id: string;
  values: any;
};

export interface IMultiSelectTable {
  className?: string;
  defaultPageSize?: number;
  storeTableName?: TableNames | undefined;
  initialFilters?: ISingleFilter[];
  headers: any[];
  rows: any[];
  rowStyle?: (row: any) => CSSProperties;
  rowClickHandler?(cell: RowCell): void;
  onCheckedChange?(items: IRow[]): void;
  disableCheckBoxes?: boolean | ((row: any) => boolean);
  checkboxTooltip?: (row: any) => ReactNode | undefined;
  paging?: IServerSidePaging;
  isLoading?: boolean;
  defaultSort?: {
    id: string;
    desc: boolean;
  };
}

interface TableToggleCommonPropsExtended extends TableToggleCommonProps {
  disabled: boolean;
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: TableToggleCommonPropsExtended, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  }
);

function HeaderCell({ column, headProps, style }: { headProps: any; style: CSSProperties; column: any }) {
  return (
    <th {...headProps} style={style}>
      <div {...column.getSortByToggleProps()}>
        <span
          className={cx('multi-select-table__header-label', {
            'multi-select-table__header-label--active': column.isSorted,
          })}
        >
          {column.render('Header')}
          {column.canSort && (
            <>
              {column.isSorted ? (
                column.isSortedDesc ? (
                  <i className="material-icons material-icons--active">keyboard_arrow_down</i>
                ) : (
                  <i className="material-icons material-icons--active">keyboard_arrow_up</i>
                )
              ) : column.id === 'selection' ? (
                ''
              ) : (
                <i className="material-icons">keyboard_arrow_down</i>
              )}
            </>
          )}
        </span>
      </div>
      {!!column.getResizerProps && (
        <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? 'isResizing' : ''}`} />
      )}
      <div>{column.canFilter && column.render('Filter')}</div>
    </th>
  );
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  column-gap: 0.3rem;
  height: 100%;
`;

const TableHeader = ({
  element,
  instance,
}: {
  instance: any;
  element: (headerGroup: any, i: number) => JSX.Element;
}) => <thead>{instance.headerGroups.map(element)}</thead>;

const MultiSelectTable = (props: IMultiSelectTable) => {
  const {
    className,
    defaultSort,
    headers,
    isLoading,
    rows: inputRows,
    rowClickHandler,
    onCheckedChange,
    initialFilters,
    paging,
    defaultPageSize,
    storeTableName,
    disableCheckBoxes = false,
    checkboxTooltip,
    rowStyle,
  } = props;

  const columns = React.useMemo(() => {
    const selectionColumn = {
      Cell: ({ row }: { row: any }) => (
        <CheckboxContainer>
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            disabled={typeof disableCheckBoxes === 'function' ? disableCheckBoxes(row.original) : disableCheckBoxes}
          />
          {checkboxTooltip?.(row.original)}
        </CheckboxContainer>
      ),
      width: '60',
      disableResizing: true,
      Header: ({ getToggleAllRowsSelectedProps }: { getToggleAllRowsSelectedProps: any }) => {
        if (typeof disableCheckBoxes === 'function') return null;
        return (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} disabled={disableCheckBoxes} />
          </div>
        );
      },
      id: 'selection',
    };

    const providerColumnCustomStyle = headers.map((header) => {
      if (header.id === 'provider') {
        return {
          ...header,
          width: '270',
          disableResizing: true,
        };
      }
      return header;
    });

    return onCheckedChange ? [selectionColumn, ...providerColumnCustomStyle] : [...providerColumnCustomStyle];
  }, [headers]);

  const data = React.useMemo(() => inputRows, [inputRows]);

  const filterTypes = React.useMemo(() => {
    return {
      equalsOrEmptyFilter,
    };
  }, []);

  const instance = useTable(
    {
      columns,
      data,
      filterTypes,
      manualSortBy: !!paging,
      manualFilters: !!paging,
      disableSortRemove: false,
      disableMultiSort: true,
      initialState: {
        sortBy: paging
          ? paging.sort?.key
            ? [
                {
                  id: paging.sort.key,
                  desc: paging.sort.direction === SortDirection.DESCENDING,
                },
              ]
            : []
          : defaultSort
          ? [defaultSort]
          : [],
        pageSize: paging ? Number.MAX_SAFE_INTEGER : 10,
        filters: initialFilters ? initialFilters : [],
      },
    } as any,
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useResizeColumns,
    useFlexLayout,
    usePagination,
    useRowSelect
  ) as any;
  const {
    selectedFlatRows,
    state: { sortBy, filters },
  } = instance;
  const isFirstRun = useRef(true);

  React.useEffect(() => {
    if (!paging) return;

    const sortByElement = sortBy[0];
    let direction;
    if (sortByElement) {
      direction = sortByElement?.desc ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    if (sortByElement?.id !== paging.sort?.key || direction !== paging.sort?.direction) {
      paging.setSort(sortByElement?.id);
    }
  }, [sortBy]);

  const [localPageSize, setLocalPageSize] = useState<number>(defaultPageSize || 10);

  React.useEffect(() => {
    if (paging) {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        if (defaultPageSize) {
          setLocalPageSize(defaultPageSize);
        }
        return;
      }
      paging.setFilter(filters);
      if (defaultPageSize) {
        paging.setPageSize(defaultPageSize);
      }
    }
  }, [filters, localPageSize]);

  const prevSelectedFlatRows = usePreviousState(selectedFlatRows) || [];

  const onClick = (cell: RowCell) => {
    const selection = `${window.getSelection()}`;

    // TODO - can !selection ever be true? getSelection returns an object or null, and `${null}` === "null"
    if (!selection && rowClickHandler) {
      rowClickHandler(cell);
    }
  };

  useEffect(() => {
    if (prevSelectedFlatRows !== selectedFlatRows && !!onCheckedChange) {
      onCheckedChange(selectedFlatRows && selectedFlatRows.map((row: IRow) => row.values));
    }
  }, [onCheckedChange, prevSelectedFlatRows, selectedFlatRows]);

  useSetHeaderCellWidth(instance.state.columnResizing, storeTableName);

  return (
    <div>
      <div
        className={cx(`${className} table-wrapper`, {
          loading: isLoading || paging?.isLoading,
        })}
      >
        <table {...instance.getTableProps()}>
          <TableHeader
            instance={instance}
            element={(headerGroup: any, i: number) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`${i}-headerGroup`}>
                {headerGroup.headers.map((column: any, k: number) => {
                  const headProps = { ...column.getHeaderProps() };
                  const style = { ...headProps.style, ...(column.style || {}) };
                  return <HeaderCell key={`${k}-column`} headProps={headProps} style={style} column={column} />;
                })}
              </tr>
            )}
          />
          <tbody>
            {instance.page.map(
              (row: any) =>
                instance.prepareRow(row) || (
                  // eslint-disable-next-line react/jsx-key
                  <tr
                    {...row.getRowProps()}
                    className={cx({
                      price_list_row_selected: row.original.isPriceListRowSelected,
                    })}
                    style={{
                      ...row.getRowProps().style,
                      ...(rowStyle ? rowStyle(row.original) : {}),
                    }}
                  >
                    {row.cells.map(renderCell(row, onClick))}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        serverSidePaged={paging}
        storeTableName={storeTableName}
        instance={instance}
        defaultPageSize={defaultPageSize}
      />
    </div>
  );
};

const StyledMultiSelectTable = styled(MultiSelectTable)<IMultiSelectTable>`
  overflow-x: scroll;

  -webkit-transition: opacity 1s linear;
  -moz-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;

  &.date-picker-open {
    min-height: 350px;
  }

  &.loading {
    opacity: 0.5;
  }

  table {
    border-spacing: 0;
    width: 100%;
    background-color: #ffffff;
    border: none;
    font-size: 1rem;

    tr {
      width: auto !important;
    }

    thead {
      background-color: ${(props) => props.theme.colours.grey10};
      color: ${(props) => props.theme.colours.grey70};
      th {
        font-family: ${(props) => props.theme.typography.fontFamilyBold};
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .material-icons {
        height: 31px;
        vertical-align: middle;
        position: relative;
        top: 2px;
        color: ${(props) => props.theme.colours.grey30};
        &--active {
          color: ${(props) => props.theme.colours.secondaryC1};
        }
      }
      input,
      .select {
        background-color: white;
        height: 30px;
        border-style: solid;
        border-width: 0px 0px 1px 0px;
        border-radius: 0px;
        color: ${(props) => props.theme.colours.grey70};
        border-color: ${(props) => props.theme.colours.grey10};
      }
      .select {
        select {
          color: ${(props) => props.theme.colours.grey70};
          background-color: transparent;
          height: 100%;
          width: 100%;
          -webkit-appearance: button;
          text-indent: 5px;
          outline: none;
          border: none;
          padding: 0.175em 0.15em;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    tbody {
      font-size: 0.9rem;

      tr:hover {
        background-color: ${(props) => props.theme.colours.listItemHover};
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.3em 0.6em;
      border-bottom: 1px solid ${(props) => props.theme.colours.grey10};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .resizer {
        display: inline-block;
        background: ${(props) => props.theme.colours.grey40};
        width: 2px;
        height: 24px;
        position: absolute;
        right: 0;
        top: 10px;
        padding-bottom: 10px;
        transform: translateX(50%);
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          background: ${(props) => props.theme.colours.secondary};
        }
      }
    }

    th:last-child .resizer {
      visibility: hidden;
    }

    td:hover {
      cursor: pointer;
    }

    .multi-select-table__header-label {
      white-space: nowrap;
      height: 34px;
      display: inline-block;
      line-height: 34px;
      &--active {
        color: ${(props) => props.theme.colours.secondaryC1};
      }
    }
    .date-filter {
      input {
        width: 100%;
      }
    }

    .currency {
      letter-spacing: 0.02rem;
    }
  }
`;

export default StyledMultiSelectTable;
