import { doRequest, Methods } from '../../../Request';
import { IAddUser } from '../../types/actions';
import { IUserRoleForCreation } from '../../../User/types/role';
import { translateRolesSelectionForUserCreation } from '../../utils/companyRolesRows';

interface IAddUserRequestBody {
  data: {
    attributes: {
      email: string;
      username: string;
      job_title: string;
      first_name: string;
      last_name: string;
      mobile_number: string;
      landline_number?: string;
      roles: IUserRoleForCreation[];
    };
    type: 'user';
  };
}

export const addUser = ({
  userData: { contactEmail, companyEmail, jobTitle, firstName, lastName, mobilePhone, landlinePhone },
  companiesRolesRows,
}: IAddUser['payload']) => {
  const body: IAddUserRequestBody = {
    data: {
      attributes: {
        email: contactEmail,
        username: companyEmail,
        job_title: jobTitle,
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobilePhone,
        landline_number: landlinePhone,
        roles: translateRolesSelectionForUserCreation(companiesRolesRows),
      },
      type: 'user',
    },
  };

  return doRequest({
    body,
    method: Methods.POST,
    path: '/users',
  });
};
