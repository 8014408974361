import { sharedQuoteOrderTableHeaders } from 'shared/utils/shared-quote-order-table-headers';
import { formatProductType, formatAccessProvider, formatQuoteState } from 'Quotes/utils/quoteTableHelpers';
import { SelectColumnFilter, SelectSupplierColumnFilter } from 'shared/components/organisms/MultiSelectTable/filters';

export const shared = sharedQuoteOrderTableHeaders((row) => row);

const headers = [
  shared.quoteId('id'),
  {
    Filter: SelectColumnFilter,
    Header: 'Product',
    accessor: (row: any) => {
      return formatProductType(row.product_type);
    },
    filter: 'equalsOrEmptyFilter',
    id: 'product',
    disableSortBy: true,
    disableFilters: true,
  },
  shared.accessTypeForQuote(),
  shared.locations(),
  {
    Filter: SelectSupplierColumnFilter,
    Header: 'Access Provider',
    accessor: (row: any) => formatAccessProvider(row),
    filter: 'equalsOrEmptyFilter',
    id: 'access_provider',
    disableSortBy: true,
    disableFilters: true,
  },
  shared.price(),
  shared.createdAt(),
  shared.updatedAt(),
  {
    Filter: SelectColumnFilter,
    Header: 'Status',
    accessor: (row: any) => {
      return formatQuoteState(row);
    },
    filter: 'equalsOrEmptyFilter',
    id: 'quote_state',
    disableSortBy: true,
    disableFilters: true,
  },
];

export default headers;
