import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CardWithInitialText, { CardState } from '../../../shared/components/organisms/CardWithInitialText';
import OrderCardFooter from '../../../shared/components/molecules/OrderCardFooter';
import { Edit } from './Edit';
import Summary from './Summary';
import { editOrderAction } from '../../actions';
import { IAppState } from '../../../reducers';
import { usePreviousState } from '../../../shared/utils/customHooks';
import { isComplete } from '../shared/utils/cloudConnectComplete';

type Props = {
  cardState: CardState;
  className?: string;
  setCardState(cardState: CardState): void;
};

const initialList = ['Create your cloud connect configuration'];

export const CloudConnectConfig: FunctionComponent<React.PropsWithChildren<Props>> = ({
  cardState,
  className,
  setCardState,
}) => {
  const dispatch = useDispatch();
  const { id: orderId, order, updating } = useSelector((state: IAppState) => state.orderBuilder);
  const quote = useSelector((state: IAppState) => state.quoteBuilder.quote);
  const prevSaving = usePreviousState(updating.inProgress);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCardState(CardState.Loading);
    dispatch(editOrderAction(orderId!, { cloudConnect: order.cloudConnect }));
  };

  useEffect(() => {
    if (prevSaving && !updating.inProgress && cardState === CardState.Loading) {
      setCardState(CardState.Summary);
    }
  }, [cardState, prevSaving, updating.inProgress, setCardState]);

  return (
    <form className={className} onSubmit={onSubmit}>
      <CardWithInitialText
        className="order-cloud-connect-config"
        completed={isComplete(order, quote)}
        initialList={initialList}
        loadingText="Saving"
        onEditClick={() => setCardState(CardState.Edit)}
        cardState={cardState}
        title="Cloud Connect Provider"
      >
        {cardState === CardState.Edit && (
          <>
            <Edit />
            <OrderCardFooter />
          </>
        )}

        {(cardState === CardState.Summary || cardState === CardState.LockedSummary) && <Summary />}
      </CardWithInitialText>
    </form>
  );
};

export default CloudConnectConfig;
