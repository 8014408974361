import React, { FC } from 'react';
import { enumToDisplayString } from 'shared/utils/enumToDisplayString';

export const Cancelled = () => (
  <span>
    {' '}
    has been <span className="font-weight-bold">cancelled</span>
  </span>
);
export const NewNote = () => (
  <span>
    {' '}
    has a <span className="font-weight-bold">new note</span>, please check
  </span>
);
export const InReview = () => (
  <span>
    {' '}
    is being <span className="font-weight-bold">reviewed</span> by Neos Networks
  </span>
);
export const Placed = () => (
  <span>
    {' '}
    has been <span className="font-weight-bold">accepted</span> by Neos Networks
  </span>
);
export const Returned = () => (
  <span>
    {' '}
    has been <span className="font-weight-bold">returned</span>, please check notes
  </span>
);

export const NewNNIOrdered = () => (
  <span>
    {' '}
    includes a <span className="font-weight-bold">new NNI request</span>
  </span>
);

export const CerillionStateUpdate = () => <span> status updated</span>;

export const UnknownAlertType: FC<{ eventType: string }> = ({ eventType }) => (
  <span>{` ${enumToDisplayString(eventType)}`}</span>
);
