import { doRequest, Methods, ResourceQueryParam } from 'Request';
import IQuoteRecord from 'Quotes/types/quoteRecord';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';

const getQuote: (quoteId: string, include?: OptionalQuoteParams) => Promise<IQuoteRecord> = (quoteId, include = {}) => {
  const params = toQueryParams(include);

  return doRequest<IQuoteRecord>({
    method: Methods.GET,
    path: `/quotes/${quoteId}${params.length > 0 ? `?include=${params}` : ''}`,
  });
};

const includeToQueryParam: Record<QuoteIncludes, ResourceQueryParam> = {
  prices: ResourceQueryParam.prices,
  orders: ResourceQueryParam.orders,
  bulkQuote: ResourceQueryParam.bulk_quote,
  nni: ResourceQueryParam.nni,
  shadowNni: ResourceQueryParam.shadow_nni,
  aEndSupplierNNI: ResourceQueryParam.a_end_supplier_nni,
  bEndSupplierNNI: ResourceQueryParam.b_end_supplier_nni,
  nniPop: ResourceQueryParam.nni_pop,
  shadowNniPop: ResourceQueryParam.shadow_nni_pop,
  onatAddresses: ResourceQueryParam.onat_addresses,
  pafAddresses: ResourceQueryParam.paf_addresses,
  messages: ResourceQueryParam.messages,
  secondaryPops: ResourceQueryParam.secondary_pops,
};

export type OptionalQuoteParams = Partial<Record<QuoteIncludes, boolean>>;

function toQueryParams(include: OptionalQuoteParams) {
  const includes = Object.keys(include) as Array<keyof typeof QuoteIncludes>;
  return includes
    .filter((key) => !!include[key])
    .map((key) => includeToQueryParam[key])
    .filter(onlyEnabledFeatures)
    .join(',');
}

function onlyEnabledFeatures(queryParam: ResourceQueryParam) {
  switch (queryParam) {
    case ResourceQueryParam.paf_addresses:
      return featureFlag.isEnabled(Feature.nnat);
    case ResourceQueryParam.secondary_pops:
      return featureFlag.isEnabled(Feature.SecondaryCircuits);
    default:
      return true;
  }
}

enum QuoteIncludes {
  prices = 'prices',
  orders = 'orders',
  bulkQuote = 'bulkQuote',
  nni = 'nni',
  shadowNni = 'shadowNni',
  aEndSupplierNNI = 'aEndSupplierNNI',
  bEndSupplierNNI = 'bEndSupplierNNI',
  nniPop = 'nniPop',
  shadowNniPop = 'shadowNniPop',
  onatAddresses = 'onatAddresses',
  pafAddresses = 'pafAddresses',
  messages = 'messages',
  secondaryPops = 'secondaryPops',
}

export default getQuote;
