import { IQuotesState } from 'Quotes/types/store';
import React, { FunctionComponent, ReactNode } from 'react';

import QuoteAccordionContent from '../../../../shared/components/molecules/QuoteAccordionContent';
import QuoteAccordionHeader, { ISummaryRow } from '../../../../shared/components/molecules/QuoteAccordionHeader';
import IQuoteAccordionRenderProps from '../../../../shared/components/organisms/QuoteAccordion/types/QuoteAccordionRenderProps';
import { IAccordionSectionRenderProps } from '../../types';

interface IAccordionSectionProps {
  accordion: IQuoteAccordionRenderProps;
  changeDisabled: boolean;
  summaries?: ISummaryRow[] | null;
  className?: string;
  hasChangeButton: boolean;
  index: number;
  title: string;
  quote?: IQuotesState['quote'];
  onChangeButtonClicked?(): void;
  render(accordionRenderProps: IAccordionSectionRenderProps): ReactNode;
}

const AccordionSection: FunctionComponent<React.PropsWithChildren<IAccordionSectionProps>> = ({
  accordion,
  changeDisabled,
  className,
  hasChangeButton,
  index,
  onChangeButtonClicked,
  title,
  render,
  summaries,
  quote,
}) => {
  const showContent = (i: number) => accordion.showContent(i);
  const { activeIndex } = accordion;

  if (activeIndex !== index) {
    return (
      <div className="accordionSection_withSummary">
        <QuoteAccordionHeader
          isActive={false}
          isComplete={activeIndex > index}
          changeDisabled={changeDisabled}
          className={className}
          hasChangeButton={hasChangeButton}
          index={index}
          onChangeButtonClicked={onChangeButtonClicked}
          showContent={showContent}
          title={title}
          summaries={summaries}
          quote={quote}
        />
        <QuoteAccordionContent index={index} activeIndex={activeIndex}>
          {render({ activeIndex, index, showContent })}
        </QuoteAccordionContent>
      </div>
    );
  } else {
    return (
      <div className="accordionSection_noSummary">
        <QuoteAccordionHeader
          isActive
          changeDisabled={changeDisabled}
          className={className}
          hasChangeButton={hasChangeButton}
          index={index}
          onChangeButtonClicked={onChangeButtonClicked}
          showContent={showContent}
          title={title}
          summaries={summaries}
        />
        <QuoteAccordionContent index={index} activeIndex={activeIndex}>
          {render({ activeIndex, index, showContent })}
        </QuoteAccordionContent>
      </div>
    );
  }
};

export default AccordionSection;
