import React from 'react';
import { currencyFormatter } from 'shared/utils/currencyFormatter';

interface PriceSummarySegmentProps {
  label: string;
  value: number;
  suffix?: string;
}

const PriceSummarySegment: React.FC<React.PropsWithChildren<PriceSummarySegmentProps>> = ({
  label,
  value,
  suffix = '',
  children,
}) => {
  return (
    <div className="price-summary__segment-wrapper">
      <div className="segment_label pl-2">
        <small>
          {label} {currencyFormatter.format(value)} {suffix} {children}
        </small>
      </div>
    </div>
  );
};

export default PriceSummarySegment;
