import React, { FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import Alert from 'shared/components/atoms/Alert';
import Button from 'shared/components/atoms/Button';
import StyledIcon from 'shared/components/atoms/Icon';
import { IRequestState } from 'shared/types/requestState';
import { IRow } from 'shared/components/organisms/MultiSelectTable';
import { bulkQuotes, multiQuotes } from 'Quotes/Routes';
import { capitalize } from 'lodash';
import IDownloadQuotesType from 'Quotes/types/downloadQuotes';
import cx from 'classnames';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

export type SelectedQuotesPrompt = (count: number) => ReactNode;

interface IBulkQuoteHeader {
  className?: string;
  companyName: string;
  bulkQuoteId: string;
  selectedQuotes: IRow[];
  downloading: IRequestState;
  downloadQuotes: (template: IDownloadQuotesType['template']) => void;
  quotesCompleted: string;
  reference?: string;
  isMulti?: boolean;
  selectedQuotesPrompt: SelectedQuotesPrompt;
  selectedQuotesCTA?: ReactNode;
}

const BulkQuoteHeader: FC<React.PropsWithChildren<IBulkQuoteHeader>> = (props) => {
  const navigate = useNavigate();
  return <BulkQuoteHeaderInternal {...props} navigate={navigate} />;
};

export const BulkQuoteHeaderInternal: FC<React.PropsWithChildren<IBulkQuoteHeader & Navigation>> = ({
  className,
  companyName,
  bulkQuoteId,
  downloadQuotes,
  quotesCompleted,
  selectedQuotes,
  downloading,
  reference = '',
  isMulti = false,
  selectedQuotesCTA = null,
  selectedQuotesPrompt,
  navigate,
}) => {
  const selectedQuotesPromptInternal = useMemo(() => {
    return selectedQuotesPrompt(selectedQuotes.length);
  }, [selectedQuotes.length]);

  return (
    <div className={cx(className, { 'is-multi': isMulti })}>
      <button onClick={() => navigate(isMulti ? multiQuotes : bulkQuotes)}>
        <StyledIcon name="keyboard_backspace" className="back-btn" />
      </button>

      {selectedQuotes && selectedQuotes.length ? (
        <div
          className={classNames({
            download: !isMulti,
            ['multi-cta-container']: isMulti,
          })}
        >
          {downloading.error ? (
            <Alert>
              An error occurred whilst trying to download the selected quotes. Please try again later. If the problem
              persists, please contact your Account Manager.
            </Alert>
          ) : (
            selectedQuotesPromptInternal
          )}
          {selectedQuotes && selectedQuotesCTA && selectedQuotesCTA}
          {selectedQuotes && !selectedQuotesCTA && (
            <>
              <Button disabled={downloading.inProgress} onClick={() => downloadQuotes(undefined)}>
                Download
              </Button>
              <Button disabled={downloading.inProgress} onClick={() => downloadQuotes('bss')}>
                Download BSS
              </Button>
            </>
          )}
        </div>
      ) : (
        <div className="bulkQuoteInfoTable">
          <div className="tableColumn">
            <p className="header">Customer</p>
            <p className="content">{companyName} </p>
          </div>
          {!isMulti && (
            <div className="tableColumn">
              <p className="header">Bulk Quote ID</p>
              <p className="content">{capitalize(bulkQuoteId)}</p>
            </div>
          )}
          {isMulti && (
            <div className="tableColumn">
              <p className="header">Reference</p>
              <p className="content">{reference}</p>
            </div>
          )}
          <div className="tableColumn">
            <p className="header"># of Completed Quotes</p>
            <p className="content" data-testid="completed-bulk-quotes-count">
              {quotesCompleted}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const StyledBulkQuoteHeader = styled(BulkQuoteHeader)`
  display: flex;
  height: 80px;
  align-items: center;
  .back-btn {
    color: ${(props) => props.theme.colours.primaryB1};
    font-size: 2em;
    margin-right: 0.5em;
  }

  .download,
  .multi-cta-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    span {
      color: ${(props) => props.theme.colours.primaryB1};
      font-size: 1.25em;
    }
  }

  .download {
    .alert {
      margin-right: 0.5em;
    }
    button {
      width: ${(props) => props.theme.button.width};
    }
  }

  .multi-cta-container {
    width: 100%;
  }

  .bulkQuoteInfoTable {
    display: flex;

    .tableColumn {
      min-width: 8em;
      width: fit-content;
      margin-right: 2em;

      .header {
        color: ${(props) => props.theme.colours.grey60};
        margin-bottom: 0;
      }

      .content {
        font-size: 1.5em;
      }
    }
  }

  &.is-multi .bulkQuoteInfoTable .content {
    font-size: 0.9em;
  }
`;
export default StyledBulkQuoteHeader;
