import React, { FunctionComponent } from 'react';
import Dropdown, { IDropdownOption } from '../../../../shared/components/molecules/Dropdown';
import productTypes from '../../../../Quotes/types/productTypes';
import { formatProductType } from '../../../../Quotes/utils/quoteTableHelpers';

interface IProductsFilter {
  className?: string;
  setProductType(id: string | null): void;
}

const ProductsFilter: FunctionComponent<React.PropsWithChildren<IProductsFilter>> = ({ setProductType }) => (
  <div className="control">
    <label>Product</label>
    <Dropdown
      options={productTypes.map((product) => {
        return {
          label: formatProductType(product.value),
          value: product.value.toString(),
        };
      })}
      onChange={(productOption: IDropdownOption) => setProductType(productOption ? productOption.value : null)}
      className="select-input products-dropdown"
    />
  </div>
);

export default ProductsFilter;
