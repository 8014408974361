import React, { FunctionComponent, useState } from 'react';
import { ISiteConfig } from 'Order/types/location';
import Checkbox from 'shared/components/atoms/Checkbox';
import TextInput from 'shared/components/atoms/TextInput';
import { VLAN_ID_INTERNALLY_ASSIGNED } from 'Order/types/orderRecord';
import { VLAN_REGEX_STRING } from 'Order/OrderBuilder/shared/utils/vlanRegex';
import { isValidVLANIdField } from 'Order/OrderBuilder/shared/utils/validation';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { NewNNIRequestForm } from '../shared/NewNNIRequestForm';
import { NNIInputFields } from '../shared/NNIInputFields';
import { AlreadyOrderedNNIChoice } from '../shared/AlreadyOrderedNNIChoice';
import { findNewNNIRequestByPopId, handleNNIAlreadyOrderedToggle, onNewNNIRequestChange } from '../utils/nniRequest';
import { objectIsEmpty } from 'shared/utils/objectHelper';
import Column from 'shared/components/atoms/Column';
import RadioButton from 'shared/components/molecules/RadioButton';
import FormHeader from '../FormHeader';

interface ISiteConfigProps {
  identifierPrefix: string;
  siteConfig: ISiteConfig;
  required?: boolean;
  showLabel?: boolean;
  nniLabel?: string | null;
  isNewNNI?: boolean;
  defaultNNIRequestContactEmail?: string;
  popId: string | null;
  customerName?: string | null;
  dataCentre?: string | null;
  updateSiteConfig(updated: ISiteConfig): void;
}

const VLanConfiguration: FunctionComponent<React.PropsWithChildren<ISiteConfigProps>> = ({
  identifierPrefix,
  updateSiteConfig,
  required,
  showLabel,
  nniLabel,
  siteConfig,
  isNewNNI,
  popId,
  customerName,
  dataCentre,
  defaultNNIRequestContactEmail,
}) => {
  // If a record already has 'Neos to assign VLAN Id' set, display it until they uncheck the box and hit save.
  // This successfully removes the ability to set it to true for new orders, but maintains functionality on existing orders
  const [showNeosToAssignVLANCheckbox] = useState(siteConfig.vlanId === VLAN_ID_INTERNALLY_ASSIGNED);
  const onChange = (field: keyof ISiteConfig, value: any): void => {
    updateSiteConfig({
      ...siteConfig,
      [field]: value,
    });
  };

  const showVLAN = siteConfig.vlanTagging || required;
  const newNNIRequest = findNewNNIRequestByPopId(popId, siteConfig.new_nni_requests);

  return (
    <div className="row mt-2">
      {!!nniLabel && (
        <div className="mb-1">
          <h4>{nniLabel}</h4>
        </div>
      )}
      {!required && (
        <Column defaultWidth={6}>
          <FormHeader title="VLAN Tagging *" />
          <div className="row">
            <Column defaultWidth={6}>
              <RadioButton
                id={`${identifierPrefix}_vlanTagging_yes`}
                description="Yes"
                onClick={() => onChange('vlanTagging', true)}
                selected={siteConfig.vlanTagging}
              />
            </Column>
            <Column defaultWidth={6}>
              <RadioButton
                id={`${identifierPrefix}_vlanTagging_no`}
                description="No"
                onClick={() => onChange('vlanTagging', false)}
                selected={!siteConfig.vlanTagging}
              />
            </Column>
          </div>
        </Column>
      )}
      {showVLAN && (
        <Column defaultWidth={required ? 12 : 6} className={required ? 'pl-0' : ''}>
          {showLabel && <FormHeader title="VLAN Reference" />}          
          {!required && <label> </label>}
          <div className="row">
            <Column defaultWidth={12}>
              <TextInput
                readOnly={siteConfig.vlanId === VLAN_ID_INTERNALLY_ASSIGNED}
                inputIdentifierPrefix={identifierPrefix}
                pattern={VLAN_REGEX_STRING}
                fieldName="_vlanId"
                onChange={(e) => onChange('vlanId', e.target.value)}
                maxLength={4}
                labelText='VLAN ID *'
                classNames={!required ? ['p-4'] : []}
                customError={isValidVLANIdField.errorMessage}
                value={siteConfig.vlanId === VLAN_ID_INTERNALLY_ASSIGNED ? '' : siteConfig.vlanId || ''}
                helperText="Please ensure you enter the correct VLAN ID, failure to do so will result in delay to your order."
              />
              {showNeosToAssignVLANCheckbox && (
                <Checkbox
                  inputId={`${identifierPrefix}_vlanSSE`}
                  label="Neos Networks to assign VLAN"
                  onChange={(val) => {
                    onChange('vlanId', val ? VLAN_ID_INTERNALLY_ASSIGNED : '');
                  }}
                  value={siteConfig.vlanId === VLAN_ID_INTERNALLY_ASSIGNED}
                />
              )}
            </Column>
          </div>
        </Column>
      )}
      {isNewNNI &&
        popId &&
        (featureFlag.isEnabled(Feature.newNNIRequests) ? (
          <AlreadyOrderedNNIChoice
            initialSelectionYes={objectIsEmpty(newNNIRequest)}
            onChange={(val) =>
              handleNNIAlreadyOrderedToggle(val, popId, siteConfig, updateSiteConfig, defaultNNIRequestContactEmail)
            }
            renderIfYes={
              <NNIInputFields identifierPrefix={identifierPrefix} siteConfig={siteConfig} onChange={onChange} />
            }
            renderIfNo={
              <NewNNIRequestForm
                identifierPrefix={`${identifierPrefix}`}
                onChange={(field, value) => onNewNNIRequestChange(field, value, popId, siteConfig, updateSiteConfig)}
                newNNIRequest={newNNIRequest}
                customerName={customerName}
                dataCentre={dataCentre}
              />
            }
          />
        ) : (
          <NNIInputFields identifierPrefix={identifierPrefix} siteConfig={siteConfig} onChange={onChange} />
        ))}
    </div>
  );
};

export default VLanConfiguration;