import IQuoteRecordAttributesBase from '../types/quoteRecordAttributesBase';

export const getAddressAttrsBEnd = (attributes: IQuoteRecordAttributesBase): any => {
  if (
    (attributes.b_end_data_centre_id && attributes.b_end_data_centre_id !== '-1') ||
    attributes.b_end_colt_building_id
  ) {
    return {
      colt_building_id: attributes.b_end_colt_building_id || '',
      pop_id: attributes.b_end_data_centre_id || '',
      reference: attributes.meta!.b_end_on_net_reference || attributes.b_end_data_centre_reference || '',
      location: attributes.meta!.b_end_on_net_location || attributes.b_end_data_centre_address || '',
      name: attributes.meta!.b_end_on_net_name || attributes.b_end_data_centre_name || '',
      address_1: attributes.b_end_building_name || '',
      address_2: attributes.b_end_full_site_thoroughfare || '',
      town: attributes.b_end_full_site_town || '',
      county: attributes.b_end_full_site_county || '',
    };
  } else {
    return {
      udprn: attributes.b_end_udprn || '',
      organisation_name: attributes.b_end_paf_organisation_name || '',
      building_name: attributes.b_end_building_name || '',
      building_number: attributes.b_end_building_number || '',
      county: attributes.b_end_full_site_county || '',
      postcode: attributes.b_end_postcode?.toUpperCase() || '',
      thoroughfare: attributes.b_end_full_site_thoroughfare || '',
      post_town: attributes.b_end_full_site_town || '',
      sub_building: attributes.b_end_sub_building || '',
    };
  }
};
