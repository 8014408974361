import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';

export enum BearerType {
  LARGEST = 'Port_400000Mbits',
  LARGER = 'Port_100000Mbits',
  LARGE = 'Port_10000Mbits',
  MEDIUM = 'Port_1000Mbits',
  SMALL = 'Port_100Mbits',
}

export type OpticalBearer = BearerType.LARGE | BearerType.LARGER | BearerType.LARGEST;

export type EthernetBearer = BearerType.SMALL | BearerType.MEDIUM | BearerType.LARGE;

export const bearerTypes: BearerType[] = [BearerType.SMALL, BearerType.MEDIUM, BearerType.LARGE];

export const bearerTypesOptical: BearerType[] = featureFlag.isEnabled(Feature.Optical100G400G)
  ? [BearerType.LARGE, BearerType.LARGER, BearerType.LARGEST]
  : [BearerType.LARGE];

export const bearerTypesCloudConnect: BearerType[] = [BearerType.SMALL, BearerType.MEDIUM];

export const isOpticalBearer = (bearer: BearerType): bearer is OpticalBearer => bearerTypesOptical.includes(bearer);

export const isEthernetBearer = (bearer: BearerType): bearer is EthernetBearer => bearerTypes.includes(bearer);
