import { BackendRoleName, IUserRoleFromBackend } from 'User/types/role';

const rolesThatCanAccessUserManagement = [BackendRoleName.Admin, BackendRoleName.SSEAdmin];

const hasUserManagementRole = (role: BackendRoleName) => rolesThatCanAccessUserManagement.includes(role);

const canAccessUserManagement = (allRoles: IUserRoleFromBackend[]) => {
  return allRoles.map((it) => it.role).filter(hasUserManagementRole).length > 0;
};

export default canAccessUserManagement;
