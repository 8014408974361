import { MAX_SELECTABLE_BANDWIDTHS } from 'Quotes/reducer';
import { OverallConnectionType } from 'Quotes/types/connectionType';
import { IQuote } from 'Quotes/types/store';
import React, { FC } from 'react';
import { Badge } from 'shared/components/atoms/Badge/Badge';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import { formatBandwidth } from 'shared/utils/connectionCapacity';

interface BandwidthsOption extends IDropdownOption {
  isDisabled?: boolean;
}

interface Props {
  options: BandwidthsOption[];
  chosenBandwidths: IQuote['chosen_bandwidths'];
  connectionType: OverallConnectionType;
  isDisabled?: boolean;
  onToggleBandwidth(bandwidth: IQuote['chosen_bandwidths'][0]): void;
}

const BandwidthsDropdown: FC<React.PropsWithChildren<Props>> = ({
  options,
  chosenBandwidths,
  onToggleBandwidth,
  connectionType,
  isDisabled = false,
}) => {
  const transformedOptions = options.some((option) => option.isDisabled)
    ? [
        {
          label: 'Available bandwidths',
          options: options.filter((option) => !option.isDisabled),
        },
        {
          label: 'Unavailable bandwidths',
          options: options.filter((option) => option.isDisabled),
        },
      ]
    : options;

  return (
    <>
      <p className="font-weight-bold mb-0 mt-3">Other bandwidth sizes (optional)</p>
      <p>You can select more bandwidth sizes below.</p>
      <Badge variant={chosenBandwidths.length === MAX_SELECTABLE_BANDWIDTHS ? 'warning' : null}>
        <strong>
          {chosenBandwidths.length} of {MAX_SELECTABLE_BANDWIDTHS}
        </strong>{' '}
        max bandwidths selected
      </Badge>
      <Dropdown
        className="bandwidths-dropdown mt-2"
        classNamePrefix="qa"
        options={transformedOptions}
        onChange={(_, action) => {
          if (['remove-value', 'pop-value'].includes(action.action) && action.removedValue?.value) {
            onToggleBandwidth(action.removedValue.value);
          }

          if (action.action === 'select-option' && action.option?.value) {
            onToggleBandwidth(action.option.value);
          }
        }}
        value={
          chosenBandwidths.length > 0 && connectionType === 'Ethernet'
            ? chosenBandwidths.map((bandwidth) => ({
                value: bandwidth,
                label: formatBandwidth(bandwidth),
                isDisabled: false,
              }))
            : null
        }
        isDisabled={isDisabled}
        isClearable={false}
        placeholder="Choose another bandwidth size..."
        isMulti
      />
    </>
  );
};

export { BandwidthsDropdown };
