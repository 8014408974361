import React, { FC } from 'react';
import Column from 'shared/components/atoms/Column';
import { useSelector } from 'react-redux';
import { selectOrderAddress } from 'Order/selectors';

export const OpticalP2P: FC<React.PropsWithChildren<unknown>> = () => {
  const { A, B } = useSelector(selectOrderAddress);

  return (
    <>
      <div className="row no-gutters mt-3">
        <Column defaultWidth={2}>
          <span className="sub-heading">Location(s)</span>
        </Column>
        <Column defaultWidth={2}>
          <span className="font-weight-bold">A-End</span>
        </Column>
        <Column defaultWidth={8}>
          <address className="mb-0" data-testid="order-summary-op2p-a-end-address">
            {A}
          </address>
        </Column>
      </div>
      <div className="row no-gutters mt-3">
        <Column defaultWidth={2} offsetDefaultWidth={2}>
          <span className="font-weight-bold">B-End</span>
        </Column>
        <Column defaultWidth={8}>
          <address className="mb-0" data-testid="order-summary-op2p-b-end-address">
            {B}
          </address>
        </Column>
      </div>
    </>
  );
};
