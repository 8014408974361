import React from 'react';

import Location from '.';

import { IAccordionSectionRenderProps } from '../../types';

const renderLocation = ({ activeIndex, index, showContent }: IAccordionSectionRenderProps) => (
  <Location
    accordion={{
      activeIndex,
      index,
      showContent,
    }}
  />
);

export default renderLocation;
