enum TableNames {
  singleQuotesTable = 'singleQuotesTable',
  bulkQuotesTable = 'bulkQuotesTable',
  ordersTable = 'ordersTable',
  usersTable = 'usersTable',
  ordersReportTable = 'ordersReportTable',
  historicalAndAPITable = 'historicalAndAPITable',
  messages = 'messages',
}

export default TableNames;
