import React, { FunctionComponent } from 'react';
import { IContact } from '../../../../types/contact';
import TextInput from '../../../../../shared/components/atoms/TextInput';

interface IFormProps {
  title: string;
  contact: IContact;
  onChange(updated: IContact): void;
}

const Form: FunctionComponent<React.PropsWithChildren<IFormProps>> = ({ title, contact, onChange }) => {
  const inputIdentifierPrefix = `${title.toLowerCase().replace(/\s/g, '')}_`;

  const updated = (field: keyof IContact, value: string): IContact => ({
    ...contact,
    [field]: value,
  });

  const onChangeWithUpdate = (field: keyof IContact, value: string): void => onChange(updated(field, value));

  const { email, firstName, surname, phoneNumber } = contact;

  return (
    <div>
      <TextInput
        inputIdentifierPrefix={inputIdentifierPrefix}
        fieldName="firstName"
        onChange={(e) => onChangeWithUpdate('firstName', e.target.value)}
        labelText="First name *"
        maxLength={50}
        value={firstName}
      />
      <TextInput
        inputIdentifierPrefix={inputIdentifierPrefix}
        fieldName="surname"
        onChange={(e) => onChangeWithUpdate('surname', e.target.value)}
        labelText="Surname *"
        maxLength={50}
        value={surname}
      />
      <TextInput
        inputIdentifierPrefix={inputIdentifierPrefix}
        fieldName="phoneNumber"
        onChange={(e) => onChangeWithUpdate('phoneNumber', e.target.value)}
        labelText="Mobile or landline phone number *"
        maxLength={50}
        value={phoneNumber}
      />
      <TextInput
        type="email"
        inputIdentifierPrefix={inputIdentifierPrefix}
        fieldName="email"
        maxLength={70}
        onChange={(e) => onChangeWithUpdate('email', e.target.value)}
        labelText="Email address *"
        value={email}
      />
    </div>
  );
};

export default Form;
