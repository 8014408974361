import React, { FC } from 'react';
import styled from 'styled-components';

interface IPostcodesProps {
  className?: string;
  aEndPostcode?: string;
  bEndPostcode?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colours.grey70};
  column-gap: 0.5rem;
`;

export const Postcodes: FC<React.PropsWithChildren<IPostcodesProps>> = ({ className, aEndPostcode, bEndPostcode }) => {
  return (
    <Container className={className}>
      {aEndPostcode && <span data-testid="a-end-postcode">{aEndPostcode}</span>}
      {aEndPostcode && bEndPostcode && <img src={'/images/postcode-arrows.png'} alt={'postcode arrows'} />}
      {bEndPostcode && <span data-testid="b-end-postcode">{bEndPostcode}</span>}
    </Container>
  );
};
