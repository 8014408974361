import { IComplexQuotePricingProgress } from 'Quotes/types/quoteRecord';
import { doRequest, Methods } from 'Request';

export const getPricingProgress = (quoteId: string) => {
  return doRequest<IComplexQuotePricingProgress>({
    method: Methods.GET,
    path: `/quotes/${quoteId}/pricing-progress`,
  });
};

export default getPricingProgress;
