import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Column from '../../../../../shared/components/atoms/Column';
import CardRow from '../../../../../shared/components/molecules/CardRow';
import { IContact } from '../../../../types/contact';
import { EmailSummary, PhoneFieldSummary, NameFieldSummary } from '../../../shared/components/SummaryTextHelper';

interface ISummaryProps {
  contact: IContact;
  title: string;
  className?: string;
  theme: {
    colours: {
      grey50: string;
    };
  };
}

const Summary: FunctionComponent<React.PropsWithChildren<ISummaryProps>> = ({
  title,
  contact: { email, firstName, surname, phoneNumber },
  className,
}) => (
  <CardRow className={className} title={title}>
    <div className="row no-gutters">
      <Column defaultWidth={4}>
        <ul>
          <li className="group-title">Contact</li>
          <li>
            <NameFieldSummary firstName={firstName} surname={surname} />
          </li>
          <li>
            <PhoneFieldSummary phone={phoneNumber} />
          </li>
          <li>
            <EmailSummary email={email} />
          </li>
        </ul>
      </Column>
    </div>
  </CardRow>
);

const StyledSummary = styled(Summary)`
  ul {
    list-style: none;
  }

  .group-title {
    color: ${(props) => props.theme.colours.grey50};
    margin-bottom: 0.5rem;
  }
`;

export default StyledSummary;
