import { ResourceQueryParam, Response } from 'Request';
import { deserialise as kitsuDeserialise } from 'kitsu-core';
import { cloneDeep, get, omit } from 'lodash';
import { INNIRecord, PopResource, SupplierNNIResource } from 'Location/NNI/types';
import { DownstreamResponse } from 'Order/types/downstreamResponse';
import { FlatONATAddressResponseItem } from 'shared/types/onatAddress';
import { IBulkQuoteRecord } from 'Quotes/types/bulkQuoteRecord';
import { MessagesOnResourceResponse } from 'shared/types/Messages';

type PartialSupplierNNIResourceAttributes = Partial<SupplierNNIResource['attributes']>;
type PartialNNIResourceAttributes = Partial<INNIRecord['attributes']>;
type PartialPopResourceAttributes = Partial<PopResource['attributes']>;
type PartialBulkQuoteResourceAttributes = Partial<IBulkQuoteRecord['attributes']>;

const withoutJsonApiFields = (content: Record<string, any>) => omit(content, ['id', 'links', 'type']);

export const deserialiseJsonApiResponse = (response: Response) => {
  const deserialised = kitsuDeserialise(cloneDeep(response));
  return {
    deserialised,
    selectSupplierNNI(
      resourceQueryParam: ResourceQueryParam.a_end_supplier_nni | ResourceQueryParam.b_end_supplier_nni
    ): PartialSupplierNNIResourceAttributes {
      const result = get<PartialSupplierNNIResourceAttributes>(deserialised, `data[${resourceQueryParam}].data`, {});

      return withoutJsonApiFields(result);
    },
    selectNNI(): PartialNNIResourceAttributes {
      const result = get<PartialNNIResourceAttributes>(deserialised, `data[${ResourceQueryParam.nni}].data`, {});

      return withoutJsonApiFields(result);
    },
    selectShadowNNI(): PartialNNIResourceAttributes {
      const result = get<PartialNNIResourceAttributes>(deserialised, `data[${ResourceQueryParam.shadow_nni}].data`, {});

      return withoutJsonApiFields(result);
    },
    selectNniPop() {
      const result = get<PartialPopResourceAttributes>(deserialised, `data[${ResourceQueryParam.nni_pop}].data`, {});

      return withoutJsonApiFields(result);
    },
    selectNniShadowPop() {
      const result = get<PartialPopResourceAttributes>(
        deserialised,
        `data[${ResourceQueryParam.shadow_nni_pop}].data`,
        {}
      );

      return withoutJsonApiFields(result);
    },
    selectDownstreamResponses() {
      return get<DownstreamResponse['attributes'][]>(
        deserialised,
        `data[${ResourceQueryParam.downstream_responses}].data`,
        []
      );
    },
    selectONATAddresses() {
      return get<Partial<FlatONATAddressResponseItem>[]>(
        deserialised,
        `data[${ResourceQueryParam.onat_addresses}].data`,
        []
      );
    },
    selectBulkQuote() {
      const result = get<PartialBulkQuoteResourceAttributes>(
        deserialised,
        `data[${ResourceQueryParam.bulk_quote}].data`,
        {}
      );

      return withoutJsonApiFields(result);
    },
    selectMessages() {
      return get<MessagesOnResourceResponse[]>(deserialised, `data[${ResourceQueryParam.messages}].data`, []);
    },
  };
};
