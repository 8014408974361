import { useEffect } from 'react';

export type HeaderCellWidthProps = {
  isResizingColumn: undefined | null | string;
  columnWidths: any;
};

export const useSetHeaderCellWidth = (columnHeader: HeaderCellWidthProps, tableName: string | undefined) => {
  useEffect(() => {
    if (tableName === undefined) {
      tableName = 'bulkQuotesChildTable';
    }

    if (columnHeader?.isResizingColumn !== undefined && columnHeader?.isResizingColumn !== null) {
      localStorage.setItem(
        `${tableName}-width-${columnHeader.isResizingColumn}`,
        columnHeader.columnWidths[columnHeader.isResizingColumn]
      );
    }
  });
};
