import { IQuote } from 'Quotes/types/store';
import Column from 'shared/components/atoms/Column';
import React from 'react';
import { Amount } from 'Order/OrderBuilder/QuoteSummary/TermAndPrice/index';

export function FttpAggPrice(props: { quote: IQuote; amount: number }) {
  return (
    <>
      {!!props.quote.fttpAggregation && (
        <Column defaultWidth={3}>
          <span className="mr-2">FTTP Aggregation</span>
          <Amount amount={props.amount} />
        </Column>
      )}
    </>
  );
}
