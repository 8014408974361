import { PoP } from 'Order/OrderBuilder/QuoteSummary/PoP';
import React, { FC } from 'react';
import Column from 'shared/components/atoms/Column';
import { QuoteExtraInfoWrapper } from './styles';
import { isFTTXQuote as isFTTXQuoteSelector, selectQuote, selectSelectedPrice } from 'Quotes/selectors';
import { useSelector } from 'react-redux';
import { StyledListItem } from 'Order/OrderBuilder/QuoteSummary/PoP/styles';
import { presentProductNameForQuoteExtraInfo as padProductName } from 'Quotes/utils/popHelper';
import { ProductType } from 'Quotes/types/productTypes';
import classNames from 'classnames';
import { NNATWithTick } from 'Quotes/shared/components/NNAT/NNATAlerts';
import { NNATSuppliers, Supplier } from 'Quotes/types/supplier';
import Row from 'shared/components/atoms/Column/row';
import styled from 'styled-components';

const QuoteExtraInfo: FC<React.PropsWithChildren<unknown>> = () => {
  const selectedPrice = useSelector(selectSelectedPrice);
  const quote = useSelector(selectQuote);
  const isFTTXQuote = useSelector(isFTTXQuoteSelector);

  return (
    <div className="mt-3">
      <QuoteExtraInfoWrapper className="mb-0" data-testid="quote-extra-info">
        <div className="row no-gutters mt-0">
          <Column classNames={['Flip']} defaultWidth={6}>
            <DivWithRightSeparator>
              <Row noGutters>
                <PoP
                  end="A"
                  port={quote.aEndPort}
                  pop={selectedPrice.a_end_p_o_p}
                  postcode={selectedPrice.a_end_p_o_p_postcode}
                  showExchange={false}
                  showExtraInfo={true}
                  showSupplierNNIRef={false}
                />
              </Row>
              <Row noGutters>
                <DisplayProductTypeAndExchange
                  isFTTXQuote={isFTTXQuote}
                  accessType={selectedPrice.a_end_access_type}
                  productName={selectedPrice.a_end_product_name}
                  exchange={selectedPrice.a_end_exchange_type}
                />
              </Row>
              <Row noGutters>
                {NNATSuppliers.includes(selectedPrice.a_end_access_type) && (
                  <StyledListItem className="font-weight-bold">
                    <NNATWithTick />
                  </StyledListItem>
                )}
              </Row>
            </DivWithRightSeparator>
          </Column>

          <Column defaultWidth={6}>
            <Row noGutters>
              {quote.productType !== ProductType.DIA && (
                <PoP
                  end="B"
                  port={quote.bEndPort}
                  pop={selectedPrice.b_end_p_o_p}
                  postcode={selectedPrice.b_end_p_o_p_postcode}
                  showExchange={false}
                  showExtraInfo={true}
                  showSupplierNNIRef={false}
                />
              )}
            </Row>
            <Row noGutters>
              <DisplayProductTypeAndExchange
                isFTTXQuote={isFTTXQuote}
                accessType={selectedPrice.b_end_access_type}
                productName={selectedPrice.b_end_product_name}
                exchange={selectedPrice.b_end_exchange_type}
              />
            </Row>
            <Row noGutters>
              {NNAT_SUPPLIERS.includes(selectedPrice.b_end_access_type) && (
                <StyledListItem className="font-weight-bold">
                  <NNATWithTick />
                </StyledListItem>
              )}
            </Row>
          </Column>
        </div>
      </QuoteExtraInfoWrapper>
    </div>
  );
};

const DisplayProductType: FC<
  React.PropsWithChildren<{
    productType: string;
  }>
> = ({ productType }) => {
  if (!productType) return <></>;
  return (
    <>
      <StyledListItem className="font-weight-bold mr-3">Product:</StyledListItem>
      <StyledListItem>{productType}</StyledListItem>
    </>
  );
};
const DisplayExchange: FC<
  React.PropsWithChildren<{
    hasProductType: boolean;
    exchange: string | null;
  }>
> = ({ hasProductType, exchange }) => {
  if (!exchange) return null;
  return (
    <>
      <StyledListItem
        className={classNames('font-weight-bold mr-3', {
          'ml-3': hasProductType,
        })}
      >
        Exchange Type:
      </StyledListItem>
      <StyledListItem>{exchange}</StyledListItem>
    </>
  );
};

const DisplayProductTypeAndExchange: FC<
  React.PropsWithChildren<{
    isFTTXQuote: boolean;
    accessType: string | null;
    productName: string | null;
    exchange: string | null;
  }>
> = ({ isFTTXQuote, accessType, productName, exchange }) => {
  const productType = padProductName(accessType, productName);
  return (
    <>
      {!isFTTXQuote && productType && <DisplayProductType productType={productType} />}
      {exchange && <DisplayExchange exchange={exchange} hasProductType={!!productType && !isFTTXQuote} />}
    </>
  );
};

const NNAT_SUPPLIERS: (string | null)[] = [Supplier.NNAT, Supplier.ONAT];

const DivWithRightSeparator = styled.div`
  &:after {
    content: '';
    position: absolute;
    bottom: -5%;
    left: 98%;
    height: 110%;
    border-radius: 5px;
    border-right: 2px solid white;
  }
`;

export default QuoteExtraInfo;
