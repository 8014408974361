import React from 'react';
import Alert, { Alerts } from 'shared/components/atoms/Alert';

export const genericInformationAlertText = `Please avoid the use of 'tbc' or generic information when completing this form, as these may impact the time it takes for us to process your order.`;

export const OrderContainsProhibitedValuesAlert = () => (
  <Alert alertType={Alerts.WARNING} data-testid="order-contains-tbc-alert">
    {genericInformationAlertText}
  </Alert>
);
