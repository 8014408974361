import { ProductType } from 'Quotes/types/productTypes';
import { IQuote } from 'Quotes/types/store';
import { findProductTypeLabelForValue } from './findProductTypeLabelForValue';
import { isFTTXConnection } from 'Quotes/types/connectionType';

const getProductTypeValue = (quote: IQuote, productType: ProductType) => {
  if (isFTTXConnection(quote.connectionType)) {
    return `${quote.connectionType} ${findProductTypeLabelForValue(productType, false)}`;
  }
  return findProductTypeLabelForValue(productType);
};

export default getProductTypeValue;
