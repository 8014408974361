import { IQuoteListItem } from 'Quotes/types/quoteRecord';
import { formatAccessProvider, formatProductType } from 'Quotes/utils/quoteTableHelpers';
import React from 'react';
import { shared } from '../headers';
import { Table } from './QuoteCompactSummaryWithOrderTable';

interface QuoteCompactSummaryProps {
  quotes: IQuoteListItem[];
}

export const QuoteCompactSummaryTable = ({ quotes }: QuoteCompactSummaryProps) => {
  return (
    <Table className="table table-striped">
      <thead>
        <tr>
          <th>Quote ID</th>
          <th>Product</th>
          <th>Access Type</th>
          <th>Locations</th>
          <th>Access provider</th>
        </tr>
      </thead>
      <tbody>
        {quotes.map((quote) => (
          <tr key={quote.short_id}>
            <td>{quote.short_id}</td>
            <td>{formatProductType(quote.product_type)}</td>
            <td>{shared.accessTypeForQuote().accessor(quote)}</td>
            <td>{shared.locations().accessor(quote)}</td>
            <td>{formatAccessProvider(quote)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
