import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'reducers';
import IQuoteRecordAttributesBase from '../../../Quotes/types/quoteRecordAttributesBase';
import { ProductType } from 'Quotes/types/productTypes';
import EthernetPoint, { EndType } from '../../EthernetPoint';
import NNI from 'Location/NNI/NNI';

export interface ICloudLocation {
  className?: string;
  productType: IQuoteRecordAttributesBase['product_type'] | null;
}

export const CloudLocation: FunctionComponent<React.PropsWithChildren<ICloudLocation>> = ({
  className,
  productType,
}) => {
  return (
    <div className={className}>
      {productType === ProductType.P2CCT && <div className="header header--small-margin">Find your address</div>}
      {productType === ProductType.P2CCT && (
        <div className="row">
          <div className="point-container w-100">
            <EthernetPoint end={EndType.AEnd} />
          </div>
        </div>
      )}
      {productType === ProductType.NNI2CCT && (
        <div className="nni-container">
          <NNI enableShadowVLAN={false} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  productType: state.quoteBuilder.quote.productType,
});

export default connect(mapStateToProps)(CloudLocation);
