import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { ErrorMessage } from './errorMessage';

const userPool = new AmazonCognitoIdentity.CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID!,
  ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID!,
});

export const signOut = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

export const signIn = (
  username: string,
  password: string,
  onSuccess: () => void,
  onFailure: (error: ErrorMessage) => void,
  handleRequireNewPassword: (user: any, userAttributes: any) => void
) => {
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
    Username: username,
    Password: password,
  });

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: username,
    Pool: userPool,
  });

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess,
    onFailure,
    newPasswordRequired: (userAttributes) => {
      delete userAttributes.email_verified;
      delete userAttributes.email; // See https://stackoverflow.com/questions/71667989
      handleRequireNewPassword(cognitoUser, userAttributes);
    },
  });
};

export const getJwt = (): Promise<string | null> => {
  return new Promise((resolve) => {
    const currentUser = userPool.getCurrentUser();

    if (!currentUser) {
      return resolve(null);
    }

    currentUser.getSession(function (_: Error | null, result: AmazonCognitoIdentity.CognitoUserSession | null) {
      if (result) {
        return resolve(result.getIdToken().getJwtToken());
      }

      return resolve(null);
    });
  });
};

export const completeNewPasswordChallenge = (
  password: string,
  cognitoUser: any,
  userAttributes: any,
  onSuccess: () => void,
  onFailure: (error: ErrorMessage) => void
) => {
  cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
    onSuccess,
    onFailure,
  });
};

export const forgotPassword = (username: string, onSuccess: () => void, onFailure: (e: ErrorMessage) => void) => {
  const currentUser = new AmazonCognitoIdentity.CognitoUser({
    Username: username,
    Pool: userPool,
  });

  currentUser.forgotPassword({
    onSuccess,
    onFailure,
  });
};

export const confirmNewPassword = (
  username: string,
  code: string,
  newPassword: string,
  onSuccess: () => void,
  onFailure: (error: ErrorMessage) => void
) => {
  const currentUser = new AmazonCognitoIdentity.CognitoUser({
    Username: username,
    Pool: userPool,
  });
  currentUser.confirmPassword(code, newPassword, {
    onSuccess,
    onFailure,
  });
};
