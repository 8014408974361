import cx from 'classnames';
import React from 'react';
import { ProviderName } from '../../../../../types/store';

interface ICloudProvider {
  className?: string;
  width?: number;
  cloudProvider: ProviderName;
}

const CLOUD_LOGOS = {
  [ProviderName.AWS]: 'AWS.png',
  [ProviderName.Azure]: 'Azure.png',
  [ProviderName.NOT_DEFINED]: 'cloud.png',
};

function logoForCloudProvider(cloudProvider: ProviderName) {
  return `/cloud_logos/${CLOUD_LOGOS[cloudProvider]}`;
}

export const CloudProviderImage = ({ cloudProvider, className, width }: ICloudProvider) => (
  <img
    width={width || 90}
    src={logoForCloudProvider(cloudProvider)}
    className={cx(className, 'cloud_logos')}
    alt={cloudProvider}
  />
);
