import React, { FC, useEffect } from 'react';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAvailabilityCheck,
  selectCurrentMeta,
  selectIsQuoteUpdating,
  selectPricingProgress,
  selectQuote,
  selectQuoteCreating,
  selectQuoteId,
} from 'Quotes/selectors';
import { isComplete } from 'Quotes/QuoteBuilder/utils/configureSection';
import { createQuoteAction, updateQuoteAndGetPrice } from 'Quotes/actions';
import Alert from 'shared/components/atoms/Alert';
import { usePreviousState } from 'shared/utils/customHooks';
import { quoteById } from 'Quotes/Routes';
import { getUpdateValues } from 'Quotes/QuoteBuilder/components/Configure';
import { getBaseAttributes } from 'Quotes/crud/createQuote';
import { selectCompanyData, selectIsInternalUser } from 'User/selectors';
import { AsyncPricesLoadingOverlay } from 'Quotes/QuoteBuilder/components/Configure/ComplexQuoteLoadingOverlay/AsyncPricesLoadingOverlay';
import { requiresAsyncPrices } from 'Quotes/QuoteBuilder/utils/requiresAsyncPrices';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

export const GetPrice: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate: NavigateFunction = useNavigate();
  return <GetPriceInternal navigate={navigate} />;
};

export const GetPriceInternal: FC<React.PropsWithChildren<Navigation>> = ({ navigate }) => {
  const quote = useSelector(selectQuote);
  const quoteCreating = useSelector(selectQuoteCreating);
  const pricingProgress = useSelector(selectPricingProgress);
  const quoteUpdating = useSelector(selectIsQuoteUpdating);
  const availabilityCheck = useSelector(selectAvailabilityCheck);
  const quoteId = useSelector(selectQuoteId);
  const dispatch = useDispatch();
  const prevFetchingPrice = usePreviousState(pricingProgress.fetchingPrice);
  const meta = useSelector(selectCurrentMeta);
  const companyData = useSelector(selectCompanyData);
  const isInternalUser = useSelector(selectIsInternalUser);
  const showComplexPricingOverlay = pricingProgress.requiresAsyncPrices && pricingProgress?.fetchingPrice;

  useEffect(() => {
    if (quoteId && prevFetchingPrice && !pricingProgress.fetchingPrice && !pricingProgress.error) {
      navigate(quoteById(quoteId));
    }
  }, [pricingProgress.fetchingPrice, quoteId, prevFetchingPrice, pricingProgress.error, navigate]);

  return (
    <div>
      {showComplexPricingOverlay && (
        <AsyncPricesLoadingOverlay progressPercentage={Number(pricingProgress?.complexQuoteProgress?.progress) || 0} />
      )}

      <Button
        disabled={
          !isComplete(quote) ||
          quoteCreating.inProgress ||
          pricingProgress.fetchingPrice ||
          availabilityCheck.failed ||
          quoteUpdating
        }
        mainStyle={ButtonMainStyle.PrimaryRectangular}
        onClick={() => {
          if (quoteId) {
            const attributes = getBaseAttributes(quote, meta, companyData, false, isInternalUser);
            dispatch(updateQuoteAndGetPrice(quoteId, getUpdateValues(quote, attributes), requiresAsyncPrices(quote)));
          } else {
            dispatch(createQuoteAction(quote, 'by_location'));
          }
        }}
      >
        Get price
      </Button>

      {quoteCreating.error && (
        <Alert className="mt-3">
          <p className="mb-0">
            Failed to create quote. Please try again later. If the problem persists, please contact your Account
            Manager.
          </p>
        </Alert>
      )}

      {pricingProgress.error && (
        <Alert className="mt-3">
          <p className="mb-0">
            {Array.isArray(pricingProgress.errorMessage)
              ? pricingProgress.errorMessage[0]
              : `An error occurred whilst trying to get price. Please try again later. If the problem persists, please contact your Account Manager.`}
          </p>
        </Alert>
      )}
    </div>
  );
};
