import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type Container = FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
  }>
>;

const Container: Container = ({ className, children }) => <div className={`main ${className}`}>{children}</div>;

const StyledContainer = styled(Container)`
  min-height: 630px;
  background-color: ${(props) => props.theme.colours.secondaryC5};
  padding-bottom: 50px;

  a {
    color: ${(props) => props.theme.colours.primaryB1};
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  .text-muted {
    color: ${(props) => props.theme.colours.grey70} !important;
  }
`;

export default StyledContainer;
