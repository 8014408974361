import { IPriceData, IQuote } from 'Quotes/types/store';
import Column from 'shared/components/atoms/Column';
import { presentSupplierName } from 'Quotes/utils/supplierHelpers';
import { presentProductNameForPoPDisplay } from 'Quotes/utils/popHelper';
import { PoP } from 'Order/OrderBuilder/QuoteSummary/PoP';
import React from 'react';

export function P2PCircuit(props: {
  circuit?: string;
  selectedPrice: IPriceData;
  sseUser: boolean | undefined;
  primaryAddressAEnd: any;
  quote: IQuote;
  primaryAddressBEnd: any;
}) {
  return (
    <>
      <div className="row no-gutters mt-3">
        <Column defaultWidth={2}>
          <span className="sub-heading">{props.circuit} Location(s)</span>
        </Column>
        <Column defaultWidth={2}>
          <span className="font-weight-bold">
            A-End ({presentSupplierName(props.selectedPrice.a_end_access_type || '')}
            {props.sseUser &&
              presentProductNameForPoPDisplay(
                props.selectedPrice.a_end_product_name,
                props.selectedPrice.a_end_access_type
              )}
            )
          </span>
        </Column>
        <Column defaultWidth={8}>
          <address className="mb-0" data-testid="order-summary-p2p-a-end">
            {props.primaryAddressAEnd}
          </address>
          <PoP
            end="A"
            port={props.quote.aEndPort}
            pop={props.selectedPrice.a_end_p_o_p}
            postcode={props.selectedPrice.a_end_p_o_p_postcode}
            exchangeType={props.selectedPrice.a_end_exchange_type}
            showExchange={true}
          />
        </Column>
      </div>
      <div className="row no-gutters mt-3">
        <Column defaultWidth={2} offsetDefaultWidth={2}>
          <span className="font-weight-bold">
            B-End ({presentSupplierName(props.selectedPrice.b_end_access_type || '')}
            {props.sseUser &&
              presentProductNameForPoPDisplay(
                props.selectedPrice.b_end_product_name,
                props.selectedPrice.b_end_access_type
              )}
            )
          </span>
        </Column>
        <Column defaultWidth={8}>
          <PoP
            end="B"
            port={props.quote.bEndPort}
            pop={props.selectedPrice.b_end_p_o_p}
            postcode={props.selectedPrice.b_end_p_o_p_postcode}
            exchangeType={props.selectedPrice.b_end_exchange_type}
            showExchange={true}
          />
          <address className="mb-0" data-testid="order-summary-p2p-b-end">
            {props.primaryAddressBEnd}
          </address>
        </Column>
      </div>
    </>
  );
}
