import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IAppState } from 'reducers';
import { setNNIIdAndReference, toggleNNIShadowVLAN } from 'Quotes/actions';
import { INNIRecord, NNIDataCentreFromBE } from './types';
import NNISelect, { NNIOption } from './NNISelect';
import { useNNIListFetch } from './hooks';
import Alert from 'shared/components/atoms/Alert';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import Checkbox from 'shared/components/atoms/Checkbox';
import { OnChange } from 'Quotes/types/availabilityCheck';
import { ResourceTypes } from 'Request';
import { NewNNIInfo } from 'Location/NNI/NewNNIInfo';
import { INNI } from 'Quotes/types/store';
import { Dispatch } from 'redux';
import { getNNILabel } from 'shared/utils/addresses/helperFunctions';
import { selectSelectedCompanyId } from 'User/selectors';

const StyledNNISelection = styled.div`
  margin-bottom: 2em;
`;

type NNIProps = {
  enableShadowVLAN?: boolean;
  isCloningQuote?: boolean;
  onTriggerAvailabilityCheck?: OnChange;
};

type InternalNNIProps = {
  existingNNIAgreements: INNIRecord[];
  nniCapableDataCentres: NNIDataCentreFromBE[];
  nni: INNI | undefined;
  onSelect: (nniOption: NNIOption, shadowVLAN: boolean) => void;
  label?: string;
  isReadOnly?: boolean;
} & (ShadowVLAN | NoShadowVLAN);

type ShadowVLAN = { enableShadowVLAN: true; onSetShadowVLAN: (enabled: boolean) => void };
type NoShadowVLAN = { enableShadowVLAN: false; onSetShadowVLAN?: never };

const useCloningQuoteNNI = (isCloningQuote: boolean, onTriggerAvailabilityCheck: OnChange | undefined, nni: INNI) => {
  const hasDoneInitialCloneAction = useRef(false);
  useEffect(() => {
    // Ensure we only run this code cloning code once
    if (hasDoneInitialCloneAction.current) return;

    hasDoneInitialCloneAction.current = true;

    if (onTriggerAvailabilityCheck && isCloningQuote) {
      const changes = nni.selectedId
        ? { existing_nni_id: nni.selectedId }
        : { new_nni_data_centre_id: nni.selectedDataCentreId };

      onTriggerAvailabilityCheck(changes, 'nni');
    }
  }, [isCloningQuote, nni.selectedDataCentreId, nni.selectedId, onTriggerAvailabilityCheck]);
};

export function useNNIFetch(
  selectedCompanyId: string,
  initialSelectNNI: (nniAgreements: INNIRecord[], dataCentres: NNIDataCentreFromBE[]) => void
) {
  const [existingNNIAgreements, setExistingNNIAgreements] = useState<INNIRecord[]>([]);
  const [nniCapableDataCentres, setNNICapableDataCentres] = useState<NNIDataCentreFromBE[]>([]);

  const { error, loading } = useNNIListFetch({
    customerId: selectedCompanyId,
    onSuccess: (nniAgreements, dataCentres) => {
      initialSelectNNI(nniAgreements, dataCentres);

      setExistingNNIAgreements(nniAgreements);
      setNNICapableDataCentres(dataCentres);
    },
  });
  return { existingNNIAgreements, nniCapableDataCentres, error, loading };
}

function getNNIChange(nniOption: NNIOption) {
  switch (nniOption?.type) {
    case ResourceTypes.nni:
      return { existing_nni_id: nniOption.value };
    case ResourceTypes.data_centre:
      return { new_nni_data_centre_id: nniOption.value };
    default:
      return {};
  }
}

const updateNNIIdAndReferenceBuilder = (dispatch: Dispatch, onTriggerAvailabilityCheck: OnChange | undefined) => (
  nniOption: NNIOption,
  shadowVLAN: boolean
) => {
  const value = nniOption ? nniOption.value : '';
  const label = nniOption ? nniOption.label : '';
  const postcode = nniOption ? nniOption.postcode : '';
  const type = nniOption ? nniOption.type : ResourceTypes.nni;
  const nniReference = nniOption ? nniOption.nniReference : '';
  const dataCentreReference = nniOption ? nniOption.dataCentreReference : '';

  dispatch(setNNIIdAndReference(value, nniReference, dataCentreReference, postcode, shadowVLAN, type, label));

  if (onTriggerAvailabilityCheck) {
    onTriggerAvailabilityCheck(getNNIChange(nniOption), 'nni');
  }
};

const selectInitialNNI = (dispatch: Dispatch, nni: INNI, onTriggerAvailabilityCheck: OnChange | undefined) => (
  nniAgreements: INNIRecord[]
) => {
  if (nniAgreements.length === 1 && !nni.selectedId && !nni.selectedDataCentreId) {
    const {
      id,
      attributes: { reference, pop_postcode, pop_name },
    } = nniAgreements[0];
    dispatch(
      setNNIIdAndReference(
        id,
        reference,
        pop_name,
        pop_postcode,
        false,
        ResourceTypes.nni,
        getNNILabel(nniAgreements[0])
      )
    );
    onTriggerAvailabilityCheck?.({ existing_nni_id: nniAgreements[0].id }, 'nni');
  }
};

const NNI: FC<NNIProps> = ({ enableShadowVLAN = true, isCloningQuote = false, onTriggerAvailabilityCheck }) => {
  const dispatch = useDispatch();
  const selectedCompanyId = useSelector(selectSelectedCompanyId);

  const nni = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.nni);
  const { existingNNIAgreements, nniCapableDataCentres, error, loading } = useNNIFetch(
    selectedCompanyId,
    selectInitialNNI(dispatch, nni, onTriggerAvailabilityCheck)
  );

  useCloningQuoteNNI(isCloningQuote, onTriggerAvailabilityCheck, nni);
  const onSelect = updateNNIIdAndReferenceBuilder(dispatch, onTriggerAvailabilityCheck);

  if (loading) {
    return <Spinner text="Retrieving NNIs" className="loading-spinner" size="large" />;
  }

  if (error) {
    return (
      <Alert>
        Error retrieving NNIs. Please try again later. If the problem persists, please contact your Account Manager.
      </Alert>
    );
  }

  return enableShadowVLAN ? (
    <NNIInternal
      existingNNIAgreements={existingNNIAgreements}
      nniCapableDataCentres={nniCapableDataCentres}
      enableShadowVLAN={true}
      nni={nni}
      onSelect={onSelect}
      onSetShadowVLAN={() => dispatch(toggleNNIShadowVLAN())}
    />
  ) : (
    <NNIInternal
      existingNNIAgreements={existingNNIAgreements}
      nniCapableDataCentres={nniCapableDataCentres}
      enableShadowVLAN={false}
      nni={nni}
      onSelect={onSelect}
    />
  );
};

export const NNIInternal: FC<InternalNNIProps> = ({
  existingNNIAgreements,
  nniCapableDataCentres,
  enableShadowVLAN,
  nni,
  onSelect,
  onSetShadowVLAN,
  label = undefined,
  isReadOnly = false,
}) => {
  const isNewNNI = !nni?.selectedId;
  const isNewShadowNNI = !!nni?.shadowVLAN.selectedDataCentreId;

  const hasDataCentres = nniCapableDataCentres.length > 0;
  if (!hasDataCentres) {
    return null;
  }

  return (
    <StyledNNISelection>
      <NNISelect
        label={label}
        isReadOnly={isReadOnly}
        nniExistingAgreements={existingNNIAgreements.filter((item) => item.id !== nni?.shadowVLAN.selectedId)}
        nniCapableDataCentres={nniCapableDataCentres.filter((item) => item.id !== nni?.shadowVLAN.selectedDataCentreId)}
        onChange={(item) => onSelect(item, false)}
        selectedId={nni?.selectedId || nni?.selectedDataCentreId}
        selectedType={nni?.selectedId ? ResourceTypes.nni : ResourceTypes.data_centre}
      />

      {enableShadowVLAN && (
        <Checkbox
          className="mt-3 mb-3"
          label="Shadow VLAN required?"
          value={nni?.shadowVLAN.enabled}
          onChange={(checked) => onSetShadowVLAN(checked)}
        />
      )}

      {nni?.shadowVLAN.enabled && (
        <NNISelect
          className="shadow-vlan"
          label="Shadow VLAN NNI"
          nniExistingAgreements={existingNNIAgreements.filter((item) => item.id !== nni?.selectedId)}
          nniCapableDataCentres={nniCapableDataCentres.filter((item) => item.id !== nni?.selectedDataCentreId)}
          onChange={(item) => onSelect(item, true)}
          selectedId={nni?.shadowVLAN.selectedId || nni?.shadowVLAN.selectedDataCentreId}
          selectedType={nni?.shadowVLAN.selectedId ? ResourceTypes.nni : ResourceTypes.data_centre}
        />
      )}
      {((isNewNNI && nni?.selectedDataCentreId) || (isNewShadowNNI && nni?.shadowVLAN.selectedDataCentreId)) && (
        <NewNNIInfo />
      )}
    </StyledNNISelection>
  );
};

export default NNI;
