import { all, call, debounce, delay, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  asyncPriceQuoteStartedAction,
  availabilityCheckRequestFailure,
  availabilityCheckRequestStart,
  availabilityCheckRequestSuccess,
  bulkQuoteSubmitEnded,
  bulkQuoteSubmitError,
  bulkQuoteSubmitStarted,
  cloneQuoteEnded,
  cloneQuoteError,
  cloneQuoteStarted,
  createMultipleOrdersEnded,
  createMultipleOrdersError,
  createMultipleOrdersPOASuccess,
  createMultipleOrdersStarted,
  createMultipleOrdersSuccess,
  createQuoteEnded,
  createQuoteErrorAction,
  createQuoteStarted,
  downloadQuotesEnded,
  downloadQuotesError,
  downloadQuotesStarted,
  getAPIQuoteEnded,
  getAPIQuoteError,
  getAPIQuoteStarted,
  getAvailableAccessMethods as getAvailableAccessMethodsAction,
  getAvailableAccessMethodsEnded,
  getAvailableAccessMethodsError,
  getAvailableAccessMethodsStarted,
  getExistingQuoteEnded,
  getExistingQuoteError,
  getExistingQuoteStarted,
  priceComplexQuoteCompleted,
  priceComplexQuoteUpdateProgress,
  priceQuote as priceQuoteAction,
  priceQuoteEndedAction,
  priceQuoteErrorAction,
  priceQuoteStartedAction,
  priceQuoteSuccess,
  publishQuoteFailure,
  publishQuoteSuccess,
  quoteUpdatingEndedAction,
  quoteUpdatingErrorAction,
  quoteUpdatingStartedAction,
  refreshFilteredPrices,
  replaceQuoteState,
  saveONATAddressIdFailure,
  saveONATAddressIdSuccess,
  setCablingSuccessStateAction,
  setCurrentQuoteDataAction,
  setPricingQuoteWillRetryAction,
  setQuotePOA,
  setSelectedPrice,
  updateQuoteAction,
  updateSinglePrice,
} from './actions';
import createQuote from './crud/createQuote';
import priceQuote from './crud/priceQuote';
import updateQuote from './crud/updateQuote';
import getAPIQuote from './crud/getAPIQuote';
import { getAvailability } from './crud/getAvailability';
import { downloadQuotes as downloadQuotesRequest } from './crud/downloadQuotes';
import {
  ICloneQuote,
  ICreateMultipleOrders,
  ICreateQuote,
  IDownloadQuotes,
  IGetExistingQuote,
  IPriceQuote,
  ISaveONATAddressId,
  ISubmitBulkQuote,
  IUpdatePricedQuote,
  IUpdatePricedQuoteAndCreateOrder,
  IUpdatePriceQuote,
  IUpdateQuote,
  IUpdateQuoteAndGetPrice,
  QuoteActionTypes,
} from './types/actions';
import IQuoteRecord, { IComplexQuotePricingProgress, IQuoteListItem, IQuotePricedOrOrdered } from './types/quoteRecord';
import { IQuote, QuoteOrigin } from './types/store';
import { getQuoteWithPriceData, getQuoteWithPriceDataOrderDataAndBulkQuoteData, updatePriceData } from 'shared/sagas';
import {
  selectAvailabilityCheck,
  selectCurrentMeta,
  selectProductType,
  selectQuote,
  selectQuoteId,
  selectQuoteUpdateError,
  selectSelectedPrice,
} from './selectors';
import {
  selectCompanyData,
  selectIsInternalUser,
  selectNameOfSelectedCompany,
  selectSelectedCompanyId,
} from 'User/selectors';
import { postBulkQuote } from './crud/postBulkQuote';
import { PromiseBasedFileReader } from './utils/PromiseBasedFileReader';
import { updatePrice } from './crud/updatePrice';
import getAccessAvailability from './crud/getAccessAvailability';
import getAccessAvailabilityP2P from './crud/getAccessAvailabilityP2P';
import IPricedQuote from './types/pricedQuote';
import { GenericAction } from 'shared/actions';
import getQuote from './crud/getQuote';
import { AxiosError } from 'axios';
import getAmortisedValueForContractTerm from './utils/getAmortisedValueForContractTerm';
import { filterIncludedBy } from 'Request/utils/filterIncludedBy';
import { setSelectedCompany } from 'User/actions';
import { ProductType } from './types/productTypes';
import { transformAccessAvailability } from './utils/transformAccessAvailability';
import buildP2PAvailabilityProps from './utils/buildP2PAvailabilityProps';
import buildAvailabilityProps from './utils/buildAvailabilityProps';
import { isValidONATAddressResponseItem } from 'shared/types/onatAddress';
import { AvailabilityCheckRequest, AvailabilityCheckResponse } from './types/availabilityCheck';
import { convertQuoteRecord, quoteRequiresAvailabilityCheck } from './reducer';
import { deserialiseJsonApiResponse } from 'Request/utils/deserialiseJsonApiResponse';
import { getPricingProgress } from './crud/getPricingProgress';
import { requiresAsyncPrices as requireAsyncPrices } from 'Quotes/QuoteBuilder/utils/requiresAsyncPrices';
import { pollWithInterval } from 'shared/sagas/pollWithInterval';
import { postQuotesSubmitOpportunity } from './crud/postQuotesSubmitOpportunity';
import createOrder from 'Order/crud/createOrder';
import { createOrderEndedAction, createOrderStartedAction } from 'Order/actions';

export function* createNewQuote({ payload: { quote, journey } }: ICreateQuote): any {
  yield put(createQuoteStarted());

  let quoteId = '';

  try {
    let currentMeta = yield select(selectCurrentMeta);
    const companyData = yield select(selectCompanyData);
    const isInternalUser = yield select(selectIsInternalUser);
    const clone = false;

    if (journey === 'by_location') {
      currentMeta = {
        ...(currentMeta || {}),
        journey_origin: 'by_location',
      };
    }

    const newQuote: IQuoteRecord = yield call(
      createQuote,
      quote,
      currentMeta,
      companyData,
      clone,
      isInternalUser,
      journey
    );
    quoteId = newQuote.data.id;

    yield put(setCurrentQuoteDataAction(newQuote.data));
  } catch (error) {
    yield put(createQuoteErrorAction());
  } finally {
    yield put(createQuoteEnded());
  }

  if (journey === 'by_location' && quoteId) {
    yield call(priceQuoteSaga, priceQuoteAction(quoteId, requireAsyncPrices(quote)));
  }
}

export function* cloneQuote({ payload: { quote } }: ICloneQuote): any {
  yield put(cloneQuoteStarted());

  try {
    const currentMeta = yield select(selectCurrentMeta);
    const companyData = yield select(selectCompanyData);
    const newQuote: IQuoteRecord = yield call(createQuote, quote, currentMeta, companyData, true);

    yield put(setCurrentQuoteDataAction(newQuote.data));
  } catch (error) {
    yield put(cloneQuoteError());
  } finally {
    yield put(cloneQuoteEnded());
  }
}

export function* retrieveQuote({ payload: { quoteId } }: IGetExistingQuote): any {
  yield put(getExistingQuoteStarted());

  try {
    yield getQuoteWithPriceDataOrderDataAndBulkQuoteData(quoteId);
  } catch (error: any) {
    yield put(getExistingQuoteError(error.response));
  } finally {
    yield put(getExistingQuoteEnded());
  }
}

export function* retrieveAPIQuote({ payload: { quoteId } }: GenericAction): any {
  yield put(getAPIQuoteStarted());
  try {
    const apiQuoteData = yield call(getAPIQuote, quoteId);
    yield put(setCurrentQuoteDataAction(apiQuoteData.data));

    const customerId = apiQuoteData.data.attributes.customer_id;
    if (customerId) {
      yield put(setSelectedCompany(customerId));
    }

    yield put(
      replaceQuoteState({
        ...apiQuoteData,
        data: {
          ...apiQuoteData.data,
          attributes: {
            ...apiQuoteData.data.attributes,
            origin: QuoteOrigin.API,
          },
        },
      })
    );

    const quotePrices = filterIncludedBy(apiQuoteData.included as IQuotePricedOrOrdered[], 'price');

    yield updatePriceData(
      quotePrices,
      apiQuoteData.data.attributes.selected_price?.id,
      apiQuoteData.data.attributes.selected_price?.amortised
    );
  } catch (error) {
    yield put(getAPIQuoteError());
  } finally {
    yield put(getAPIQuoteEnded());
  }
}

export function* updateExistingQuote({ payload: { quoteId, updateValues } }: IUpdateQuote): any {
  yield put(quoteUpdatingStartedAction());
  const currentMeta = yield select(selectCurrentMeta);

  try {
    const quoteRecord = yield call(updateQuote, quoteId, updateValues, currentMeta);
    const productType = quoteRecord?.data?.attributes?.product_type;
    const nniApplicable = productType === ProductType.NNI2CCT || productType === ProductType.P2NNI;
    const quoteRecordWithIncludes = yield call(getQuote, quoteId, {
      nni: nniApplicable,
      nniPop: nniApplicable,
      shadowNni: nniApplicable,
      shadowNniPop: nniApplicable,
      onatAddresses: true,
      pafAddresses: true,
      messages: true,
      bulkQuote: true,
    });

    yield put(replaceQuoteState(quoteRecordWithIncludes));
  } catch (error) {
    yield put(quoteUpdatingErrorAction());
  } finally {
    yield put(quoteUpdatingEndedAction());
  }
}

export function* updatePricedQuote({ payload: { quote, price } }: IUpdatePricedQuote): any {
  yield put(quoteUpdatingStartedAction());
  const currentMeta = yield select(selectCurrentMeta);
  try {
    yield call(
      updateQuote,
      quote.id,
      {
        selected_price: { id: quote.selectedPriceId },
        fttp_aggregation: quote.fttpAggregation ?? null,

        // fields for updating address when supplier specific address selected
        ...(quote.a_end_address_changed && {
          a_end_udprn: quote.a_end_udprn,
          a_end_paf_organisation_name: quote.a_end_paf_organisation_name,
          a_end_building_name: quote.a_end_building_name,
          a_end_building_number: quote.a_end_building_number,
          a_end_sub_building: quote.a_end_sub_building,
          a_end_full_site_county: quote.a_end_full_site_county,
          a_end_full_site_town: quote.a_end_full_site_town,
          a_end_full_site_thoroughfare: quote.a_end_full_site_thoroughfare,
          a_end_openreach_address: null,
        }),

        ...(quote.b_end_address_changed && {
          b_end_udprn: quote.b_end_udprn,
          b_end_paf_organisation_name: quote.b_end_paf_organisation_name,
          b_end_building_name: quote.b_end_building_name,
          b_end_building_number: quote.b_end_building_number,
          b_end_sub_building: quote.b_end_sub_building,
          b_end_full_site_county: quote.b_end_full_site_county,
          b_end_full_site_town: quote.b_end_full_site_town,
          b_end_full_site_thoroughfare: quote.b_end_full_site_thoroughfare,
          b_end_openreach_address: null,
        }),

        secondary_circuit_options: {
          mdia_cpe_selection: quote.routerChoice ?? null,
          is_engineer_installation_required: quote.secondRouterOptions?.engineerInstallationRequired ?? null,
          is_rack_mount_required: quote.secondRouterOptions?.rackMountKitRequired ?? null,
          ip_count: quote.secondIPChoice ?? null,
        },
      },
      currentMeta
    );

    yield call(updatePrice, price.id, {
      amortised: price.amortised ?? false,
    });
  } catch (error) {
    yield put(quoteUpdatingErrorAction());
  } finally {
    yield put(quoteUpdatingEndedAction());
  }
}

export function* updatePricedQuoteAndCreateOrder({ payload }: IUpdatePricedQuoteAndCreateOrder): any {
  // Trigger the updatePricedQuote action
  yield put(payload.updatePricedQuoteAction);
  // Wait for the above action to complete
  yield take(QuoteActionTypes.UPDATE_QUOTE_ENDED);
  // Set the Order as loading to avoid re-rendering the Price page between finishing saving and starting creating an order
  yield put(createOrderStartedAction());
  // Check it was successful
  const hasError = yield select(selectQuoteUpdateError);
  if (hasError) {
    yield put(createOrderEndedAction());
    return;
  }

  // Create the order!
  yield put(payload.createOrder);
}

export function* getAvailableAccessMethods(): any {
  try {
    yield put(getAvailableAccessMethodsStarted());

    const { location, productType }: IQuote = yield select(selectQuote);

    const apiData =
      productType === ProductType.P2P
        ? yield call(getAccessAvailabilityP2P, buildP2PAvailabilityProps(location))
        : yield call(getAccessAvailability, buildAvailabilityProps(location, productType!));
    const availability = transformAccessAvailability(apiData, productType!);
    yield put(getAvailableAccessMethodsEnded(availability));
  } catch (error: any) {
    yield put(getAvailableAccessMethodsError(error.response));
  }
}

export function* complexQuotePricingPolling(quoteId: string): any {
  yield pollWithInterval(
    {
      intervalInMs: 1000,
      onSagaError: (e: any) => {
        throw e;
      },
      stopAction: QuoteActionTypes.PRICE_COMPLEX_QUOTE_COMPLETED,
    },
    updateComplexQuotePricingProgress,
    quoteId
  );
}

export function* priceQuoteSaga({ payload: { quoteId, requiresAsyncPrices } }: IPriceQuote): any {
  let timeoutCounter = 0;
  const maxNumOfAttempts = 2;

  const requestHasTimedOut = (error: AxiosError): boolean => {
    return error.response?.status === 504;
  };

  const requestGotPOAError = (error: AxiosError<{ errors: any[] }>): boolean => {
    return error.response?.data?.errors?.[0]?.title.includes('POA');
  };

  if (requiresAsyncPrices) yield put(asyncPriceQuoteStartedAction());
  else yield put(priceQuoteStartedAction());

  while (true) {
    try {
      const quotePrices = yield call(priceQuote, quoteId);

      if (requiresAsyncPrices) yield call(complexQuotePricingPolling, quoteId);

      if (quotePrices?.data?.length > 0 || requiresAsyncPrices) {
        const quoteRecord: IQuoteRecord = yield call(getQuote, quoteId, {
          prices: true,
        }) as any;
        quotePrices.data = quoteRecord.included;

        const selectedPriceId = quoteRecord.data?.attributes?.selected_price?.id;

        const defaultAmortised = getAmortisedValueForContractTerm(quoteRecord.data?.attributes?.term_length_in_years);

        if (selectedPriceId) {
          yield call(updatePrice, selectedPriceId, {
            amortised: defaultAmortised,
          } as IPricedQuote);
        }

        yield updatePriceData(quotePrices.data, selectedPriceId, defaultAmortised);

        yield put(priceQuoteSuccess());

        break;
      }

      yield put(priceQuoteErrorAction({ errors: [{ detail: 'No prices returned.' }] }));
      break;
    } catch (error: any) {
      if (!requestHasTimedOut(error) || timeoutCounter === maxNumOfAttempts) {
        if (requestGotPOAError(error)) {
          yield put(setQuotePOA());
        } else {
          yield put(priceQuoteErrorAction(error.response?.data));
        }
        break;
      } else {
        timeoutCounter += 1;
        yield put(setPricingQuoteWillRetryAction());
        yield delay(2000);
      }
    }
  }
  yield put(priceQuoteEndedAction());
}

export function* updateQuoteAndGetPrice(action: IUpdateQuoteAndGetPrice): any {
  const {
    payload: { quoteId, updateValues, requiresAsyncPrices },
  } = action;

  yield updateExistingQuote(updateQuoteAction(quoteId, updateValues));

  const quoteErrored = yield select(selectQuoteUpdateError);
  if (quoteErrored) {
    return;
  }

  yield priceQuoteSaga(priceQuoteAction(quoteId, requiresAsyncPrices));
}

export function* updatePriceQuote({
  payload: { quoteId, priceId, updateValues, updatingPrice, updatingCabling },
}: IUpdatePriceQuote): any {
  yield put(priceQuoteStartedAction(updatingPrice, updatingCabling));
  try {
    const updatedPrice = yield call(updatePrice, priceId, updateValues);
    if (quoteId && updatingPrice) {
      yield getQuoteWithPriceData(quoteId, priceId);
    }

    if (quoteId && updatingCabling) {
      const currentMeta = yield select(selectCurrentMeta);
      const selectedPrice = yield select(selectSelectedPrice);
      yield call(updateQuote, quoteId, {}, currentMeta);
      yield put(updateSinglePrice(updatedPrice.data));
      yield put(refreshFilteredPrices());
      yield put(setSelectedPrice(priceId, selectedPrice.amortised));
      yield put(setCablingSuccessStateAction(true));
    }
  } catch (error: any) {
    yield put(priceQuoteErrorAction(error.response?.data));
  } finally {
    yield put(priceQuoteEndedAction());
  }
}

export function* submitBulkQuote({ payload: { bulkQuoteFile } }: ISubmitBulkQuote): any {
  yield put(bulkQuoteSubmitStarted());
  try {
    const selectedCompanyId = yield select(selectSelectedCompanyId);

    const fileReader = new PromiseBasedFileReader();

    yield call(postBulkQuote, fileReader, bulkQuoteFile, selectedCompanyId);
    yield put(bulkQuoteSubmitEnded());
  } catch (error: any) {
    yield put(bulkQuoteSubmitError(error.response.data));
  }
}

export function* downloadQuotes({ payload }: IDownloadQuotes): any {
  try {
    const companyName = yield select(selectNameOfSelectedCompany);
    yield put(downloadQuotesStarted());
    yield call(downloadQuotesRequest, payload, companyName);
    yield put(downloadQuotesEnded());
  } catch (error) {
    yield put(downloadQuotesError());
  }
}

export function* publishQuote(): any {
  const quoteId = yield select(selectQuoteId);
  const currentMeta = yield select(selectCurrentMeta);

  try {
    yield call(updateQuote, quoteId, { is_internal: false }, currentMeta);
    yield put(publishQuoteSuccess());
  } catch (e) {
    yield put(publishQuoteFailure());
  }
}

export function* saveONATAddress({ payload }: ISaveONATAddressId): any {
  const quoteId = yield select(selectQuoteId);
  const currentMeta = yield select(selectCurrentMeta);

  const updateField = {
    [`${payload.end === 'A' ? 'a' : 'b'}_end_onat_address_id`]: payload.id,
  };

  try {
    yield call(updateQuote, quoteId, updateField, currentMeta);

    const quoteRecordWithIncludes = yield call(getQuote, quoteId, {
      onatAddresses: true,
    });
    const deserialised = deserialiseJsonApiResponse(quoteRecordWithIncludes);
    const onatAddress = deserialised.selectONATAddresses().find((address) => address.id === payload.id);

    if (onatAddress && isValidONATAddressResponseItem(onatAddress)) {
      yield put(saveONATAddressIdSuccess(onatAddress));
    } else {
      yield put(saveONATAddressIdFailure());
    }
  } catch (e) {
    yield put(saveONATAddressIdFailure());
  }
}

export function* availabilityCheck(): any {
  const options: ReturnType<typeof selectAvailabilityCheck> = yield select(selectAvailabilityCheck);
  const customer_id: ReturnType<typeof selectSelectedCompanyId> = yield select(selectSelectedCompanyId);
  const productType: ReturnType<typeof selectProductType> = yield select(selectProductType);

  let params: AvailabilityCheckRequest = {
    customer_id,
    ...options.sources[options.active[0]],
  };

  if (productType === ProductType.P2NNI && params.a_end_postcode) {
    params.b_end_postcode = params.a_end_postcode;
    delete params.a_end_postcode;
  }

  if (!params.is_dia) {
    params = {
      ...params,
      ...options.sources[options.active[1]],
    };
  }

  yield put(availabilityCheckRequestStart());

  try {
    const [response]: Array<AvailabilityCheckResponse> = yield all([
      call(getAvailability, params),
      call(fttxAvailabilityCheck),
    ]);
    yield put(availabilityCheckRequestSuccess(response));
  } catch (e) {
    yield put(availabilityCheckRequestFailure());
  }
}

export function* fttxAvailabilityCheck(): any {
  const options: ReturnType<typeof selectAvailabilityCheck> = yield select(selectAvailabilityCheck);
  const productType: ReturnType<typeof selectProductType> = yield select(selectProductType);
  const quote: ReturnType<typeof selectQuote> = yield select(selectQuote);

  // Do the active tabs contain FTTX applicable config? If not, bail early.
  if (
    !productType ||
    !quoteRequiresAvailabilityCheck(quote.location, productType) ||
    (productType === ProductType.P2NNI &&
      !(options.sources.nni.existing_nni_id || options.sources.nni.new_nni_data_centre_id))
  ) {
    return;
  }

  yield put(getAvailableAccessMethodsAction());
  yield take([
    QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_ERROR,
    QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS_ENDED,
  ]);
}

export function* updateComplexQuotePricingProgress(quoteId: string): any {
  const progress: IComplexQuotePricingProgress = yield call(getPricingProgress, quoteId);

  yield put(priceComplexQuoteUpdateProgress(progress));

  if (progress.status === 'Completed') {
    yield put(priceComplexQuoteCompleted());
  }
}

export function* createMultipleOrders({ payload: { quotes, companyName } }: ICreateMultipleOrders): any {
  yield put(createMultipleOrdersStarted());

  function* createOrderFromQuote(quote: IQuoteListItem): any {
    try {
      if (quote.is_poa) {
        yield call(postQuotesSubmitOpportunity, quote.id);
        yield put(createMultipleOrdersPOASuccess(quote));
      } else {
        const quoteRecord = yield call(getQuote, quote.id, {
          prices: true,
          orders: true,
          bulkQuote: true,
          nni: true,
          shadowNni: true,
          nniPop: true,
          shadowNniPop: true,
          aEndSupplierNNI: true,
          bEndSupplierNNI: true,
          onatAddresses: true,
          pafAddresses: true,
          messages: true,
        });
        const convertedRecord = convertQuoteRecord(replaceQuoteState(quoteRecord), false);
        const selectedPrice = convertedRecord.pricing.selectedPrice;

        const newOrder = yield call(
          createOrder,
          quote.id,
          convertedRecord.quote,
          companyName,
          selectedPrice,
          convertedRecord.quoteEndpointMeta,
          convertedRecord.lqId
        );

        yield put(
          createMultipleOrdersSuccess({
            ...quote,
            orderId: newOrder.data.id,
            orderShortId: newOrder.data.attributes.short_id,
          })
        );
      }
    } catch (error) {
      yield put(createMultipleOrdersError(quote));
    }
  }

  yield all(quotes.map(createOrderFromQuote));
  // for (const quote of quotes) {
  //   yield* createOrderFromQuote(quote);
  // }
  yield put(createMultipleOrdersEnded());
}

export default function* rootSaga() {
  yield debounce(300, QuoteActionTypes.CREATE_QUOTE, createNewQuote);
  yield debounce(300, QuoteActionTypes.DOWNLOAD_QUOTES, downloadQuotes);
  yield debounce(300, QuoteActionTypes.CLONE_QUOTE, cloneQuote);
  yield debounce(300, QuoteActionTypes.UPDATE_QUOTE_AND_GET_PRICE, updateQuoteAndGetPrice);
  yield debounce(300, QuoteActionTypes.UPDATE_QUOTE, updateExistingQuote);
  yield debounce(300, QuoteActionTypes.UPDATE_QUOTE_PRICE, updatePriceQuote);
  yield debounce(300, QuoteActionTypes.GET_EXISTING_QUOTE, retrieveQuote);
  yield debounce(300, QuoteActionTypes.GET_AVAILABLE_ACCESS_METHODS, getAvailableAccessMethods);
  yield debounce(300, QuoteActionTypes.GET_API_QUOTE, retrieveAPIQuote);
  yield debounce(300, QuoteActionTypes.SUBMIT_BULK_QUOTE, submitBulkQuote);
  yield debounce(300, QuoteActionTypes.UPDATE_PRICED_QUOTE, updatePricedQuote);
  yield debounce(300, QuoteActionTypes.UPDATE_PRICED_QUOTE_AND_CREATE_ORDER, updatePricedQuoteAndCreateOrder);
  yield takeEvery(QuoteActionTypes.PRICE_QUOTE, priceQuoteSaga);
  yield takeEvery(QuoteActionTypes.PUBLISH_QUOTE, publishQuote);
  yield takeEvery(QuoteActionTypes.SAVE_ONAT_ADDRESS_ID, saveONATAddress);
  yield takeLatest(QuoteActionTypes.AVAILABILITY_CHECK_CHANGE, availabilityCheck);
  yield debounce(300, QuoteActionTypes.CREATE_MULTIPLE_ORDERS, createMultipleOrders);
}
