import React, { FunctionComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';
import { IContact } from 'Order/types/contact';
import { IOrder } from 'Order/types/store';
import { IPContact } from 'Order/types/formFields';
import { ISiteContact } from 'Order/types/location';
import StyledExistingContactButton from 'shared/components/atoms/ExistingContactButton';
import { siteContactIsComplete, contactIsComplete, ipContactIsComplete } from '../../utils/contactComplete';
import { updateContact } from 'Order/actions';
import { ProductType } from 'Quotes/types/productTypes';
import { isP2NNI } from 'shared/utils/productTypeHelper';
import { IAppState } from 'reducers';

export enum ContactIdentifierType {
  orderContact = 'orderContact',
  operationalContact = 'operationalContact',
  ipContact = 'ipContact',

  /** @deprecated */
  serviceDeliveryContact = 'serviceDeliveryContact',
}

const contactTypes: { [index: string]: string } = {
  aEndContact: 'A-End contact',
  bEndContact: 'B-End contact',
  orderContact: 'Order Delivery contact',
  operationalContact: 'Operational contact',
  ipContact: 'IP address deployment contact',
};

interface IExistingContactOptionsProps {
  className?: string;
  contact: IContact | IPContact;
  contactIdentifier: ContactIdentifierType;
  order: IOrder;
  productType: ProductType;
  onClick(contactIdentifier: ContactIdentifierType, key: string, order: IOrder): void;
}

export const ExistingContactOptions: FunctionComponent<React.PropsWithChildren<IExistingContactOptionsProps>> = ({
  className,
  contact,
  contactIdentifier,
  order,
  productType,
  onClick,
}) => {
  const contactButtons: JSX.Element[] = [];
  const allContacts = {
    aEndContact: order.locationA.siteContact,
    bEndContact: order.locationB.siteContact,
    orderContact: order.orderContact,
    operationalContact: order.operationalContact,
    ipContact: order.ipContact,
  };

  const addExistingContactButton = (key: string) => {
    const buttonKey = formatKey(key, productType);
    contactButtons.push(
      <StyledExistingContactButton
        key={key}
        className={`${buttonKey}-button`}
        onClick={() => onClick(contactIdentifier, key, order)}
      >
        {contactTypes[buttonKey]}
      </StyledExistingContactButton>
    );
  };

  for (const [key, contactDetails] of Object.entries(allContacts)) {
    if (isValidAndCompleteContact(contactDetails) && contactDetails !== contact) {
      addExistingContactButton(key);
    }
  }

  if (contactButtons.length) {
    return (
      <div>
        <p className={className}>Use existing contact</p>
        {contactButtons}
      </div>
    );
  }
  return null;
};

const formatKey = (key: string, productType: ProductType) => {
  return key === 'bEndContact' && isP2NNI(productType) ? 'aEndContact' : key;
};

type contact = IContact | ISiteContact | IPContact;

function isValidAndCompleteContact(contact: contact): boolean {
  return (
    (isSiteContact(contact) && siteContactIsComplete(contact)) ||
    (isContact(contact) && contactIsComplete(contact)) ||
    (isIPContact(contact) && ipContactIsComplete(contact))
  );
}

export function isSiteContact(siteContact: contact): siteContact is ISiteContact {
  return (siteContact as ISiteContact).phone !== undefined;
}

export function isContact(contact: contact): contact is IContact {
  return (contact as IContact).phoneNumber !== undefined;
}

export function isIPContact(ipContact: contact): ipContact is IPContact {
  return (ipContact as IPContact).nic_ripe_handle !== undefined;
}

const StyledExistingContactOptions = styled(ExistingContactOptions)`
  color: ${(props) => props.theme.colours.grey70};
  font-size: 0.9em;
  margin-top: 1.5em;
`;

const mapStateToProps = (state: IAppState) => ({
  productType: state.quoteBuilder.quote.productType,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  onClick: (contactIdentifier: ContactIdentifierType, existingContactType: string, order: IOrder) => {
    dispatch(updateContact(contactIdentifier, existingContactType, order));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledExistingContactOptions);
