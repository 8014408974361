import { IOpenReachAddress, IPAFAddress } from 'shared/types/postcodeResults';
import { AddressType } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';

export const locationA = 'locationA';
export const locationB = 'locationB';
export type location = 'locationA' | 'locationB';

export interface ISiteContact {
  firstName: string;
  surname: string;
  phone: string;
  email: string;
  notes: string;
  [propName: string]: string;
}

export enum AccessNotice {
  UpTo48Hours = '48 Hours',
  Over48Hours = '72 Hours',
}

export enum ConnectorType {
  LC = 'LC',
  SC = 'SC',
  RJ45 = 'RJ45',
}

export enum Duplexity {
  Full = 'Full Duplex',
  Half = 'Half Duplex',
}

export enum MediaType {
  LX = 'Single Mode(LX)',
  TX = 'TX',
  SX = 'Multi Mode(SX)',
  LR = 'LR',
  SR = 'SR',
}

export enum PowerType {
  AC = 'AC',
  DC = 'DC',
}

export enum LocationLetter {
  A = 'a',
  B = 'b',
}

export enum SiteType {
  HOT = 'hot',
  NORMAL = 'normal',
}

export enum AsbestosRegister {
  Yes = 'YES',
  No = 'NO',
  NA = 'NA',
  NotBuiltPriorTo2000 = 'Not built prior to 2000',
}

export enum SiteCoordsType {
  LatLon = 'latlon',
  EastingNorthing = 'eastingnorthing',
}

export interface SiteCoords {
  latitude: string;
  longitude: string;
  easting: string;
  northing: string;
}

export interface ISiteReadiness {
  accessNotice: AccessNotice;
  moreThanOneTenant: boolean;
  asbestosRegister: AsbestosRegister;
  hazardsOnSite: boolean;
  hazardsOnSiteDescription?: string | null;
  landOwnerPermissionRequired: boolean;
}

export interface INewNNIRequest {
  id?: string;
  capacity?: BearerType;
  termLengthInYears?: number;
  contactEmail?: string;
  popId?: string;
  forDeletion?: boolean;
}

export interface APINewNNIRequest {
  id?: string;
  capacity?: BearerType;
  term_length_in_years?: number;
  contact_email?: string;
  pop_id?: string;
}

export interface ISiteConfig {
  floor: string;
  room: string | null;
  suite: string;
  rackId: string | null;
  connectorType: ConnectorType | null;
  mediaType: MediaType | null;
  powerType: PowerType | null;
  duplexity: Duplexity;
  autoNegotiation: boolean | undefined;
  vlanTagging: boolean;
  vlanId: string | null;
  shadowVLANTagging?: boolean;
  shadowVLANId?: string | null;
  new_nni_requests: INewNNIRequest[];
  nni_job_reference?: null | string;
  nni_idn_number?: null | string;
  shadow_nni_job_reference?: null | string;
  shadow_nni_idn_number?: null | string;
}

export interface ISecondarySiteConfig extends ISiteConfig {
  circuitId: string;
  end: 'a' | 'b';
}

export interface IOrderLocation {
  locationData: {
    pafAddress: IPAFAddress;
    addressType: AddressType;
    pafAddressNotListed: boolean;
    fullAddress: IOpenReachAddress;
    readonly_postcode: string;
    fullAddressNotListed: boolean;
  };
  endCompanyName: string;
  siteType: SiteType | null;
  siteReadiness: ISiteReadiness;
  siteConfig: ISiteConfig;
  siteContact: ISiteContact;
  siteCoords: SiteCoords;
  secondarySiteConfig?: ISecondarySiteConfig;
}
