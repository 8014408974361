import { doRequest } from '../../../Request';
import { objectToGetParams } from '../../../shared/utils/objectToGetParams';

export interface IAvailabilityProps {
  galk: string;
  district_code: string;
  postcode: string;
}

const getAccessAvailability = (body: IAvailabilityProps) => {
  const { galk, district_code, postcode } = body; // cast to unknown, then to Record.

  return doRequest({
    path: `/availability/fttx?${objectToGetParams({
      galk,
      district_code,
      postcode,
    })}`,
  });
};

export default getAccessAvailability;
