import React from 'react';
import { useModal } from 'shared/hooks/useModal';
import { SetDefaultOrderTemplateModal } from './SetDefaultOrderTemplateModal';
import { SaveAsDefaultButton } from 'Admin/preferences/OrderTemplates/OrderTemplateCard';
import Icon from 'shared/components/atoms/Icon';

interface ISetDefaultOrderTemplateButtonProps {
  customerId: string;
  selectedOrderTemplateId: string;
  buttonLabel?: string;
}

export const SetDefaultOrderTemplateButton = ({
  customerId,
  selectedOrderTemplateId,
  buttonLabel = 'Set order template as default',
}: ISetDefaultOrderTemplateButtonProps) => {
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      {isOpen && (
        <SetDefaultOrderTemplateModal
          onClose={closeModal}
          customerId={customerId}
          orderTemplateId={selectedOrderTemplateId}
        />
      )}

      <SaveAsDefaultButton onClick={openModal}>
        <Icon name="bookmark_add" size="large" />
        <strong>{buttonLabel}</strong>
      </SaveAsDefaultButton>
    </>
  );
};

export const ShortSetDefaultOrderTemplateButton = (props: ISetDefaultOrderTemplateButtonProps) => {
  return <SetDefaultOrderTemplateButton {...props} buttonLabel="Set as default" />;
};
