import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { selectAvailabilityCheck } from 'Quotes/selectors';
import Checkbox from 'shared/components/atoms/Checkbox';
import styled from 'styled-components';
import { OnChange } from 'Quotes/types/availabilityCheck';

const Wrapper = styled.div`
  padding: 0.5em;
  background-color: ${(props) => props.theme.colours.secondaryC5};

  label {
    margin-bottom: 0;
  }

  .checkbox .overlay {
    top: 3px;
  }
`;

interface Props {
  onChange: OnChange;
}

export const DIAToggle: FunctionComponent<React.PropsWithChildren<Props>> = ({ onChange }) => {
  const availabilityCheck = useSelector(selectAvailabilityCheck);

  return (
    <Wrapper>
      <Checkbox
        label="Dedicated Internet Access (DIA)"
        value={!!availabilityCheck.sources.point_left.is_dia}
        inputId="dia-toggle"
        onChange={() => {
          onChange(
            {
              ...availabilityCheck.sources.point_left,
              is_dia: availabilityCheck.sources.point_left.is_dia ? undefined : true,
            },
            'point_left'
          );
        }}
      />
    </Wrapper>
  );
};
