import { CerillionQuoteRecord } from 'Quotes/types/cerillionQuoteRecord';
import React, { FC } from 'react';

interface Props {
  quote: CerillionQuoteRecord;
}

export const CerillionQuote: FC<Props> = ({ quote }) => {
  // todo Once step info is available from BE, maybe use the StatusStepper
  // <StatusStepper />

  // todo Render some basic detail on screen once requirements are clear
  return <pre>{JSON.stringify(quote, null, '  ')}</pre>;
};
