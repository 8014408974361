import { IBandwidthRecord } from '../../../../../types/accessAvailabilityRecord';

const getFttxBandwidthTooltipText = (bandwidth: string, fttxBandwidths: IBandwidthRecord[] | undefined) => {
  const fttxBandwidth = fttxBandwidths?.find(({ value }) => value === bandwidth);

  if (fttxBandwidth?.downstream_prioritized_rate && fttxBandwidth.upstream_prioritized_rate) {
    return [
      `${fttxBandwidth.downstream_prioritized_rate} prioritised downstream.`,
      `${fttxBandwidth.upstream_prioritized_rate} prioritised upstream`,
    ].join(' ');
  }

  return bandwidth;
};

export default getFttxBandwidthTooltipText;
