import React from 'react';
import {
  PriceTile as InternalPriceTile,
  PriceTileReduxParams,
} from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/PriceTile';
import styled from 'styled-components';
import { Circuit } from 'Quotes/types/store';
import { Slider } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider';
import { useSelector } from 'react-redux';
import { selectBearer, selectQuote } from 'Quotes/selectors';
import { useSecondaryCircuitsWithSelectedPrice } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { bearerForDisplay } from 'shared/utils/connectionCapacity';
import { buildNameThatMightIncludeEmptyStrings } from 'shared/utils/buildNameThatMightIncludeEmptyStrings';
import { isP2NNI } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';

const DisplayPricesTile = styled.div`
  width: 323px;
  background: ${(props) => props.theme.colours.secondaryC5};
`;

const DisplayPricesContainer = styled.div`
  background: ${(props) => props.theme.colours.secondaryC5};
  border: 1px solid ${(props) => props.theme.colours.primaryB2};
  border-radius: 0.5rem;
`;

export const ShowPrices = ({
  circuit,
  selectPrice,
  readOnly = false,
  Tile = InternalPriceTile,
}: {
  circuit?: Circuit | undefined;
  selectPrice: (priceId: string) => void;
  readOnly?: boolean;
  Tile?: typeof InternalPriceTile;
}) => {
  if (!circuit) return null;

  // Secondary Circuit Data
  const secondaryPrices = circuit.prices ?? [];
  const secondaryCircuits = useSecondaryCircuitsWithSelectedPrice();
  const secondaryCircuit = secondaryCircuits.selectedCircuit;

  // Quote and Bearer Data
  const quote = useSelector(selectQuote);
  const bEndPostcode = quote.location.bEnd.postcode;
  const productType = quote.productType;
  const primaryAEndPortSize = useSelector(selectBearer);

  // Secondary NNI Data
  const secondaryNniId = secondaryCircuit?.nniId;
  const circuitData = secondaryCircuits.circuitData;
  const selectedSecondaryNNI =
    secondaryNniId && circuitData && isP2NNI(circuitData) ? circuitData?.aEnd.findNNI(secondaryNniId) : undefined;
  const secondaryAEndNNIReference = selectedSecondaryNNI?.reference;
  const secondaryAEndNNIPopAddress = selectedSecondaryNNI?.popAddress;
  const secondaryAEndPopAddress = secondaryPrices[0]?.a_end_p_o_p_address;

  const getSecondaryNNILabel = () => {
    if (secondaryAEndNNIReference && secondaryAEndNNIPopAddress) {
      return `${bearerForDisplay(primaryAEndPortSize)}, ${secondaryAEndNNIReference}, ${secondaryAEndNNIPopAddress}`;
    }
    return (
      buildNameThatMightIncludeEmptyStrings([
        bearerForDisplay(primaryAEndPortSize),
        secondaryAEndNNIReference || '',
        secondaryAEndPopAddress || '',
      ]) || 'Unavailable'
    );
  };
  const secondaryNNILabel = getSecondaryNNILabel();
  const secondaryPriceTileParams = (): PriceTileReduxParams => {
    return {
      aEndBandwidth: null,
      aEndDataCentreName: null,
      aEndNniShadowDataCentreReference: undefined,
      aEndNniShadowReference: undefined,
      aEndPostcode: '',
      bEndBandwidth: null,
      bEndDataCentreName: null,
      bEndDataCentreReference: undefined,
      bEndPostcode: bEndPostcode,
      dataCentreReferenceAEnd: undefined,
      nniLabel: secondaryNNILabel,
      nniLocation: undefined,
      nniPopName: undefined,
      nniReference: undefined,
      productType: productType,
      shadowVlanLocation: undefined,
    };
  };

  return (
    <DisplayPricesContainer data-testid="show-prices-element">
      <Slider
        prices={secondaryPrices}
        selectedPriceId={circuit.selectedPriceId}
        readonly={readOnly}
        slidesToShow={4}
        slidesToScroll={3}
      >
        {secondaryPrices.map((priceData, index) => {
          const secondaryPriceData = secondaryPriceTileParams();
          return (
            <DisplayPricesTile key={index}>
              <Tile
                readOnly={readOnly}
                priceData={priceData}
                priceSelected={priceData.id === circuit.selectedPriceId}
                onClick={() => undefined}
                onPriceItemClick={(priceId: string) => !readOnly && selectPrice(priceId)}
                supportsSecondaryCircuits={false}
                {...secondaryPriceData}
              />
            </DisplayPricesTile>
          );
        })}
      </Slider>
    </DisplayPricesContainer>
  );
};
