import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface IGreyPrompt {
  className?: string;
  prompt: string;
  theme: {
    colours: {
      grey10: string;
    };
  };
}

const GreyPrompt: FunctionComponent<React.PropsWithChildren<IGreyPrompt>> = ({ className, prompt }) => {
  return <div className={className}>{prompt}</div>;
};

const StyledGreyPrompt = styled(GreyPrompt)`
  max-width: 30em;
  margin-top: 0.5em;
  margin-bottom: 1em;
  background-color: ${(props) => props.theme.colours.grey10};
  padding: 0.5em;
`;

export default StyledGreyPrompt;
