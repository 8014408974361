import Alert, { Alerts } from 'shared/components/atoms/Alert';
import React from 'react';
import { IErrorResponse } from 'Request/utils/parseErrorResponse';
import { translateErrorMessage } from 'Admin/utils/translateErrorMessage';

export const UserCreatedSuccessfullyAlert = () => (
  <Alert alertType={Alerts.SUCCESS}>The user profile was created successfully</Alert>
);
export const ErrorCreatingUserAlert = ({ errors }: { errors: IErrorResponse }) => (
  <Alert alertType={Alerts.WARNING}>
    Error:{' '}
    {Array.isArray(errors) ? translateErrorMessage(errors[0].title) : 'the user profile may not have been created'}
  </Alert>
);
