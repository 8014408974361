import { IOnNetSiteResponse } from 'shared/types/onNetSite';
import { doRequest } from 'Request';

type DataCentreFilter = 'all' | 'ethernet';

export const getOnNetAddressesForPostcode = (
  postcode: string,
  companyId: string,
  filter?: DataCentreFilter
): Promise<IOnNetSiteResponse> => {
  return doRequest({
    path: `/postcodes/on-net?customer_id=${companyId}&postcode=${postcode}${filter ? `&filter=${filter}` : ''}`,
  });
};
