import { IQuote } from '../../../Quotes/types/store';
import { ProductType } from '../../../Quotes/types/productTypes';
import Column from '../../../shared/components/atoms/Column';
import getProductTypeValue from '../../../Quotes/utils/getProductTypeValue';
import React from 'react';

export function OrderProduct(props: { quote: IQuote; productType: ProductType }) {
  return (
    <>
      <Column defaultWidth={2}>
        <span className="sub-heading">Product</span>
      </Column>
      <Column defaultWidth={10}>
        <span className="font-weight-bold" data-testid="order-summary-product">
          {getProductTypeValue(props.quote, props.productType)}
        </span>
      </Column>
    </>
  );
}
