import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Column from '../../atoms/Column';
import Button, { ButtonMainStyle } from '../../atoms/Button';
import TextArea from '../../atoms/TextArea';
import Alert from '../../atoms/Alert';
import Icon from '../../atoms/Icon';
import { base as faqBase } from 'FAQ/routes';
import { MessageResource } from 'shared/types/Messages';
import { formatDateHuman, formatTime } from 'shared/utils/dateHelper';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuote, selectQuoteId } from 'Quotes/selectors';
import { orderIdSelector, selectOrder } from 'Order/selectors';
import { capitalize } from 'lodash';
import { SendMessage, sendMessage } from 'Messages/actions';
import { selectSending } from 'Messages/selectors';
import { usePreviousState } from 'shared/utils/customHooks';
import { doRequest, Methods, ResourceTypes } from 'Request';
import { selectActiveUserId, selectIsInternalUser } from 'User/selectors';
import { NotifyCustomerButton } from './components/NotifyCustomerButton';

interface Props {
  openOnMount?: boolean;
}

type OnSend = (message: SendMessage['payload']['message']) => void;
type OnMarkAllAsRead = () => void;

const infoMessageFor = (source: 'quote' | 'order') => {
  return `
  Please only send messages concerning this ${source}. You can access
            this conversation at any time from Messages in the top navigation.`;
};

export const QuoteMessages: FC<React.PropsWithChildren<Props>> = ({ openOnMount = false }) => {
  const quote = useSelector(selectQuote);
  const quoteId = useSelector(selectQuoteId);
  const dispatch = useDispatch();
  const onSend: OnSend = (message) => {
    if (!quoteId) return;
    dispatch(
      sendMessage({
        resourceId: quoteId,
        resourceType: ResourceTypes.quote,
        message,
      })
    );
  };
  const onMarkAllAsRead: OnMarkAllAsRead = async () => {
    if (!quoteId) return;
    try {
      await doRequest({
        method: Methods.POST,
        path: `/quotes/${quoteId}/messages/read`,
      });
      // eslint-disable-next-line no-empty
    } catch (e) {
      // TODO: maybe handle this; it's a low biz risk background task though
    }
  };

  return (
    <Messages
      id={capitalize(quote.shortId)}
      messages={quote.messages}
      openOnMount={openOnMount}
      infoMessage={infoMessageFor('quote')}
      onSend={onSend}
      onMarkAllAsRead={onMarkAllAsRead}
    />
  );
};

export const OrderMessages: FC<React.PropsWithChildren<Props>> = ({ openOnMount = false }) => {
  const order = useSelector(selectOrder);
  const dispatch = useDispatch();
  const orderId = useSelector(orderIdSelector);

  const onSend: OnSend = (message) => {
    if (!orderId) return;
    dispatch(
      sendMessage({
        resourceId: orderId,
        resourceType: ResourceTypes.order,
        message,
      })
    );
  };
  const onMarkAllAsRead: OnMarkAllAsRead = async () => {
    if (!orderId) return;
    try {
      await doRequest({
        method: Methods.POST,
        path: `/orders/${orderId}/messages/read`,
      });
      // eslint-disable-next-line no-empty
    } catch (e) {
      // TODO: maybe handle this; it's a low biz risk background task though
    }
  };

  return (
    <Messages
      id={capitalize(order.shortId)}
      messages={order.messages}
      openOnMount={openOnMount}
      infoMessage={infoMessageFor('order')}
      onSend={onSend}
      onMarkAllAsRead={onMarkAllAsRead}
    />
  );
};

const Messages: FC<
  React.PropsWithChildren<{
    openOnMount: boolean;
    id: string;
    messages: MessageResource[];
    infoMessage: string;
    onSend: OnSend;
    onMarkAllAsRead: OnMarkAllAsRead;
  }>
> = ({ messages, id, openOnMount, infoMessage, onSend, onMarkAllAsRead }) => {
  const [showMessages, setShowMessages] = useState(openOnMount);
  const [draftMessage, setDraftMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const sending = useSelector(selectSending);
  const prevInProgress = usePreviousState(sending.inProgress);
  const isInternalUser = useSelector(selectIsInternalUser);
  const orderId = useSelector(orderIdSelector);
  const isNotifyCustomerButtonVisible = orderId && isInternalUser;

  useEffect(function showMessagesByDefault() {
    if (messages.length > 0) {
      setShowMessages(true);
      onMarkAllAsRead();
    }
  }, []);

  useEffect(
    function showErrorMessage() {
      if (sending.error) {
        setShowError(true);
      }
    },
    [sending.error]
  );

  useEffect(
    function clearMessage() {
      if (prevInProgress && !sending.inProgress && !sending.error) {
        setDraftMessage('');
      }
    },
    [sending.inProgress]
  );

  return (
    <MessagesContainer>
      {showMessages && (
        <MessagesInner>
          <MessagesContent quoteOrOrderId={id} onClose={() => setShowMessages(false)} messages={messages} />
          <InfoSection>{infoMessage}</InfoSection>
          <MessagesPaneFooter>
            <SendMessageForm className="row no-gutters">
              <Column defaultWidth={12} classNames={['px-1']}>
                <SendMessageInput
                  disabled={sending.inProgress}
                  value={draftMessage}
                  placeholder="Write your message here."
                  onChange={(text) => setDraftMessage(text)}
                />
              </Column>
              <MessagesActionBar>
                {isNotifyCustomerButtonVisible && <NotifyCustomerButton orderId={orderId!} />}

                <SendMessageBtn
                  disabled={draftMessage.trim().length === 0 || sending.inProgress}
                  onClick={() => {
                    onSend(draftMessage);
                  }}
                  mainStyle={ButtonMainStyle.Primary}
                >
                  {sending.inProgress ? 'Sending...' : 'Send'}
                </SendMessageBtn>
              </MessagesActionBar>
            </SendMessageForm>
            {showError && (
              <MessageSendError data-testid="note-error">
                Error whilst sending your message. Your message may not have been sent. Please try again later. If the
                problem persists, please contact your Account Manager.
                <MessageSendErrorCloseBtn onClick={() => setShowError(false)}>
                  <Icon name="close" />
                </MessageSendErrorCloseBtn>
              </MessageSendError>
            )}
          </MessagesPaneFooter>
        </MessagesInner>
      )}

      <MessagesToggle
        disabled={sending.inProgress}
        data-testid="messages-toggle-btn"
        onClick={() => {
          setShowMessages(!showMessages);
        }}
      >
        <Icon name="message_outline" />
      </MessagesToggle>
    </MessagesContainer>
  );
};

const MessagesContainer = styled.div`
  position: fixed;
  bottom: 2em;
  right: 1.5em;
  z-index: 100;
`;

const MessagesToggle = styled.button`
  background-color: ${(props) => props.theme.alerts.danger.backgroundColor};
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  width: 60px;
  height: 60px;
  border-radius: 60px;
  padding-left: 0.95em;
  padding-top: 0.5em;
  color: white;
  float: right;
`;

interface MessageBubbleProps {
  fromSelf: boolean;
}

const MessageBubbleContainer = styled.div<MessageBubbleProps>`
  display: flex;
  justify-content: ${(props) => (props.fromSelf ? 'flex-end' : 'flex-start')};
`;

const MessageBubble = styled.div<MessageBubbleProps>`
  border-radius: 20px;
  padding: 1em;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 0.8em;
  width: fit-content;
  max-width: 90%;
  background-color: ${(props) => (props.fromSelf ? props.theme.colours.grey10 : props.theme.colours.primaryB5)};
`;

const MessageCompanyName = styled.div`
  font-weight: bold;
  padding-top: 0.5em;
`;

const MessageExternal = styled(MessageCompanyName)`
  color: ${(props) => props.theme.colours.grey80};
`;

const MessageBody: FC<React.PropsWithChildren<{ message: MessageResource }>> = ({ message }) => {
  const userId = useSelector(selectActiveUserId);
  const fromSelf = userId === message.attributes.createdBy;

  return (
    <MessageBubbleContainer fromSelf={fromSelf}>
      <MessageBubble fromSelf={fromSelf}>
        {message.attributes.messageText}

        {message.attributes.internal ? (
          <MessageCompanyName>Neos Networks</MessageCompanyName>
        ) : (
          <MessageExternal>{message.attributes.customerName}</MessageExternal>
        )}
        <MessageCompanyName />
      </MessageBubble>
    </MessageBubbleContainer>
  );
};

interface MessageContentProps {
  quoteOrOrderId: string;
  onClose(): void;
  messages: MessageResource[];
}

const MessagesContent: FC<React.PropsWithChildren<MessageContentProps>> = ({ quoteOrOrderId, onClose, messages }) => {
  const notesBottomAnchor = useRef<HTMLDivElement | null>(null);
  const sending = useSelector(selectSending);

  useEffect(() => {
    if (notesBottomAnchor && notesBottomAnchor.current) {
      notesBottomAnchor.current.scrollIntoView(false);
    }
  }, [messages.length]);

  return (
    <>
      <MessagesPaneHeader>
        <Column defaultWidth={9}>
          <MessagesTitle>
            Messages
            <MessagesTitleId>{quoteOrOrderId}</MessagesTitleId>
          </MessagesTitle>
        </Column>
        <Column defaultWidth={3} classNames={['text-right']}>
          <CloseButton disabled={sending.inProgress} onClick={onClose} mainStyle={ButtonMainStyle.LinkSecondary}>
            Close
          </CloseButton>
        </Column>
      </MessagesPaneHeader>
      <InfoSection>
        <p className="mb-0">
          Answers to common queries can be found in our{' '}
          <Link to={faqBase} target="_blank">
            Help and FAQ
          </Link>{' '}
          section. If you still have questions, send us a message or speak to your Account Manager.
        </p>
      </InfoSection>
      <MessagesPaneBody>
        {messages.length === 0 && <NoMessages>No messages found.</NoMessages>}
        <MessagesList>
          {messages.map((message, i) => {
            return (
              <MessageItem key={i}>
                <MessageBody message={message} />
                <MessageMeta className="row no-gutters text-left text-break">
                  <Column defaultWidth={9}>
                    {message.attributes.senderEmail}
                    {' - '}
                    {formatDateHuman(message.attributes.createdAt)}
                  </Column>
                  <Column defaultWidth={3} classNames={['text-right']}>
                    {formatTime(message.attributes.createdAt)}
                  </Column>
                </MessageMeta>
              </MessageItem>
            );
          })}
        </MessagesList>
        <div className="notes-bottom-anchor" ref={notesBottomAnchor} />
      </MessagesPaneBody>
    </>
  );
};

const MessagesInner = styled.div`
  display: flex;
  background: white;
  border: solid 3px ${(props) => props.theme.alerts.danger.backgroundColor};
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  height: 80vh;
  width: 475px;
  margin-bottom: -10px;
  margin-right: 30px;
  box-sizing: border-box;
  flex-flow: column;
`;

const MessagesPaneHeader = styled.div`
  display: flex;
  padding: 0.8em 0;
  border-bottom: solid 0.5px ${(props) => props.theme.colours.grey10};
`;

const MessagesTitle = styled.span`
  font-size: ${(props) => props.theme.typography.fontSizeH4};
  font-weight: bold;
`;

const MessagesTitleId = styled.span`
  color: ${(props) => props.theme.colours.grey70};
  font-weight: normal;
  margin-left: 20px;
`;

const CloseButton = styled(Button)`
  &.linkSecondary {
    font-size: 1.2rem;
    text-decoration: underline;

    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
      box-shadow: none;
    }
  }
`;

const InfoSection = styled.div`
  display: flex;
  padding: 0.8em 1.2em;
  background: ${(props) => props.theme.colours.secondaryC5};
  font-size: 1rem;
  border-bottom: solid 0.5px ${(props) => props.theme.colours.grey10};
`;

const MessagesPaneBody = styled.div`
  flex: 1;
  font-size: 0.95em;
  margin-bottom: 0;
  overflow-y: auto;
`;

const MessagesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 1.2em;
`;

const MessageItem = styled.li`
  padding-top: 1em;

  &:last-child {
    padding-bottom: 1em;
  }
`;

const MessageMeta = styled.div`
  color: ${(props) => props.theme.colours.grey50};
  font-size: 12px;
  padding-top: 1em;
`;

const NoMessages = styled.small`
  font-style: italic;
  font-size: 90%;
  padding: 1em 0 0 1em;
  display: block;
`;

const SendMessageForm = styled.form`
  width: 100%;
`;

const SendMessageInput = styled(TextArea)`
  height: 5em;
  resize: none;
  margin-bottom: 1em;
`;

const SendMessageBtn = styled(Button)`
  &.primary {
    text-decoration: none;
    margin: 0;
  }
`;

const MessagesActionBar = styled.div`
  width: 100%;
  padding: 0 1.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :only-child {
    margin-left: auto;
  }
`;

const MessageSendError = styled(Alert)`
  margin: 0 1em;
  position: absolute;
  top: -160px;
`;

const MessageSendErrorCloseBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`;

const MessagesPaneFooter = styled.div`
  padding: 0.3em 0 1em 0;
  border-top: solid 0.5px ${(props) => props.theme.colours.grey10};
  position: relative;
`;
