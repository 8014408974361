import { IOrder } from 'Order/types/store';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Column from 'shared/components/atoms/Column';
import CardRow from 'shared/components/molecules/CardRow';
import { IBillingContactAndAddress } from 'Order/types/formFields';
import {
  EmailSummary,
  TextFieldSummary,
  PhoneFieldSummary,
  NameFieldSummary,
} from 'Order/OrderBuilder/shared/components/SummaryTextHelper';
import { capitalize } from 'lodash';
import { OrderStatus } from 'Order/types/orderRecord';

export const SUMMARY_TEXT = `
Invoices will be sent to your Accounts Payable team as per the details held on our billing system.
If the invoice needs to be sent to a different address please contact your account manager.
`;

interface ISummaryProps {
  billingContactAndAddress: IBillingContactAndAddress;
  billingFrequency: IOrder['billingFrequency'];
  className?: string;
  orderStatus: OrderStatus;
}

const Summary: FunctionComponent<React.PropsWithChildren<ISummaryProps>> = ({
  billingContactAndAddress: { address, email, firstName, surname, phoneNumber, purchaseOrderRef },
  billingFrequency,
  className,
  orderStatus,
}) => {
  /**
   * Check if the billing frequency should be hidden for the older orders
   * that were created before the billing frequency feature was released.
   */
  function isBillingFrequencyHidden() {
    return (
      (orderStatus === OrderStatus.PLACED ||
        orderStatus === OrderStatus.IN_REVIEW ||
        orderStatus === OrderStatus.CANCELLED) &&
      !billingFrequency
    );
  }

  return (
    <CardRow className={className} title="Billing details">
      <div className="row no-gutters">
        {(firstName || surname || phoneNumber || email) && (
          <Column defaultWidth={4}>
            <ul>
              <li className="group-title">Contact</li>
              <li>
                <NameFieldSummary firstName={firstName} surname={surname} />
              </li>
              <li>
                <PhoneFieldSummary phone={phoneNumber || ''} />
              </li>
              <li>
                <EmailSummary email={email || ''} />
              </li>
            </ul>
          </Column>
        )}
        {!firstName && !surname && !phoneNumber && !email && (
          <Column defaultWidth={6} classNames={['gutter-left']}>
            <p>{SUMMARY_TEXT}</p>
          </Column>
        )}
        <Column defaultWidth={4} offsetDefaultWidth={1} classNames={['gutter-left']}>
          {address && (
            <>
              <p className="group-title">Address</p>
              <address>
                {address} <br />
              </address>
            </>
          )}
        </Column>
      </div>
      <div className="row no-gutters">
        <Column defaultWidth={4}>
          <ul>
            <li className="group-title">PO number/Ref</li>
            <li>
              <TextFieldSummary text={purchaseOrderRef} prompt="Add PO number/Ref" />
            </li>
          </ul>
        </Column>
        {!isBillingFrequencyHidden() && (
          <Column defaultWidth={3} offsetDefaultWidth={1}>
            <ul>
              <li className="group-title">Billing frequency</li>
              <li>
                <TextFieldSummary text={capitalize(billingFrequency!)} prompt="Add billing frequency" />
              </li>
            </ul>
          </Column>
        )}
      </div>
    </CardRow>
  );
};

const StyledSummary = styled(Summary)`
  ul {
    list-style: none;
  }

  li,
  address {
    word-break: break-word;
  }

  .group-title {
    color: ${(props) => props.theme.colours.grey50};
    margin-bottom: 0.5rem;
  }

  .gutter-left {
    padding-left: 40px;
  }
`;

export default StyledSummary;
