import React, { MutableRefObject } from 'react';

import cx from 'classnames';
import { HelperText, OptionalIndicator } from '../OptionalIndicator';

export interface ITextInputProps {
  forwardedRef?: MutableRefObject<any>;
  classNames?: string[];
  fieldName?: string;
  inputIdentifierPrefix?: string;
  optional?: boolean;
  required?: boolean;
  labelText?: string;
  helperText?: string;
  maxLength?: number;
  readOnly?: boolean;
  pattern?: string;
  placeholder? : string
  id?: string;
  name?: string;
  value?: string | string[] | number;
  type?: 'text' | 'email' | 'password';
  customError?: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

const TextInput = ({
  forwardedRef,
  classNames,
  fieldName,
  inputIdentifierPrefix = '',
  labelText,
  name,
  id,
  helperText,
  maxLength,
  optional = false,
  required = false,
  readOnly = false,
  pattern,
  placeholder,
  type = 'text',
  value,
  onChange,
  customError,
}: ITextInputProps) => {
  const inputId = id ? id : `${inputIdentifierPrefix}${fieldName}`;

  return (
    <div className="form-group mb-1">
      {labelText && (
        <label htmlFor={inputId} className="small mb-1">
          {labelText}
        </label>
      )}
      <input
        ref={forwardedRef}
        required={required}
        type={type}
        className={cx('form-control', classNames)}
        id={inputId}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        pattern={pattern}
        title={customError}
        placeholder={placeholder}
      />
      {optional && <OptionalIndicator />}
      {helperText && <HelperText text={helperText} />}
    </div>
  );
};

export default TextInput;
