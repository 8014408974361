import { IOrderTemplate } from 'Order/types/orderTemplate';
import { IOrder } from 'Order/types/store';
import { noop } from 'lodash';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Checkbox from 'shared/components/atoms/Checkbox';
import TextInput from 'shared/components/atoms/TextInput';
import Modal, { IModalProps } from 'shared/components/molecules/Modal';
import styled from 'styled-components';
import { useOrderTemplates } from 'Order/crud/orderTemplatesApi';
import cx from 'classnames';
import Alert, { Alerts } from 'shared/components/atoms/Alert';

export interface SaveOrderTemplateModalProps extends Omit<IModalProps, 'title' | 'children'> {
  customerId: string;
  order: IOrder;
  orderId: string;
  useOrderTemplatesHook: typeof useOrderTemplates;
  onClose?: () => void;
  onSuccess?: (createdOrderTemplate: IOrderTemplate) => void;
}

export const SaveOrderTemplateModal = ({
  customerId,
  orderId,
  order,
  useOrderTemplatesHook,
  onClose = noop,
  onSuccess = noop,
  ...props
}: SaveOrderTemplateModalProps) => {
  const orderTemplates = useOrderTemplatesHook(customerId);
  // const existingNames = orderTemplates.orderTemplates?.map((template) => template.attributes.title) ?? [];
  const [name, setName] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isDefault, setIsDefault] = useState(false);
  const inputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const createOrderTemplateAction = () =>
    orderTemplates
      .createOrderTemplate({ name, order, customerId, orderId, isDefault })
      .then((response) => {
        toast.success('Order template created successfully');
        onSuccess(response);
        onClose();
      })
      .catch(() => {
        toast.error('Failed to create order template');
      });

  return (
    <Modal
      {...props}
      title="Save order template"
      onConfirm={createOrderTemplateAction}
      disableConfirmBtn={!name || !isValid}
      onClose={onClose}
    >
      <Container>
        <TextInput
          forwardedRef={inputRef}
          fieldName="new order template name"
          labelText="Order template name"
          classNames={[cx({ invalid: !isValid })]}
          helperText={isValid ? '' : 'An order template with that name already exists'}
          onChange={(e) => {
            const newName = e.target.value;
            setName(newName);
            setIsValid(!newName || orderTemplates.isValidName(newName));
          }}
        />

        <div>
          <Alert alertType={Alerts.INFO}>
            Only the <strong>Billing & Contact Information</strong> details will be saved to the order template.
          </Alert>
          <p>A default order template will auto-fill new orders with the saved template information.</p>
          <Checkbox label="Set as default" value={isDefault} onChange={(e) => setIsDefault(e)} />
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  .invalid {
    border-color: ${(props) => props.theme.colours.invalidFieldBorderColor ?? 'red'};
  }
`;
