import React, { FunctionComponent } from 'react';
import styled, { useTheme } from 'styled-components';
import Column from 'shared/components/atoms/Column';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import { BackendRoleName } from 'User/types/role';
import Icon from 'shared/components/atoms/Icon';
import Checkbox from 'shared/components/atoms/Checkbox';
import { hasSSEAdminWildcardRole, hasSSEPricingWildcardRole } from 'User/selectors';
import { useSelector } from 'react-redux';

type Props = {
  className?: string;
  onRoleToggle(role: BackendRoleName): void;
  readonly: boolean;
  roles: BackendRoleName[];
  theme?: {
    colours: {
      grey10: string;
    };
  };
};

const UnstyledWildcardBar: FunctionComponent<React.PropsWithChildren<Props>> = ({
  className,
  onRoleToggle,
  readonly,
  roles,
}) => {
  const theme = useTheme();
  const isEditable = useSelector(hasSSEAdminWildcardRole);
  const isPricingRoleEditable = useSelector(hasSSEPricingWildcardRole);

  return (
    <div className={`${className} wildcard-bar`}>
      <div className="row no-gutters">
        <Column defaultWidth={3}>
          <span className="mr-1">All active companies</span>
          <Tooltip
            text={`Selecting this will give this user these roles for all current and future companies added into ${theme.content.productName}`}
          />
        </Column>
        <Column defaultWidth={9}>
          <div className="row no-gutters">
            <Column defaultWidth={3} classNames={['admin']}>
              {(readonly || !isEditable) && !roles.includes(BackendRoleName.SSEAdmin) && (
                <span className="option">
                  <Icon name="close" className="grey20" />
                  Admin
                </span>
              )}
              {(readonly || !isEditable) && roles.includes(BackendRoleName.SSEAdmin) && (
                <span className="option">
                  <Icon name="done" className="primary" />
                  Admin
                </span>
              )}

              {!readonly && isEditable && (
                <Checkbox
                  inputId="wildcard-option-admin"
                  label="Admin"
                  onChange={() => onRoleToggle(BackendRoleName.SSEAdmin)}
                  value={roles.includes(BackendRoleName.SSEAdmin)}
                />
              )}
            </Column>
            <Column defaultWidth={4} classNames={['account-manager']}>
              {(readonly || !isEditable) && !roles.includes(BackendRoleName.SSEAccountManager) && (
                <span className="option">
                  <Icon name="close" className="grey20" />
                  Account Manager
                </span>
              )}
              {(readonly || !isEditable) && roles.includes(BackendRoleName.SSEAccountManager) && (
                <span className="option">
                  <Icon name="done" className="primary" />
                  Account Manager
                </span>
              )}

              {!readonly && isEditable && (
                <Checkbox
                  inputId="wildcard-option-account-manager"
                  label="Account Manager"
                  onChange={() => onRoleToggle(BackendRoleName.SSEAccountManager)}
                  value={roles.includes(BackendRoleName.SSEAccountManager)}
                />
              )}
            </Column>
            <Column defaultWidth={2} classNames={['pricing']}>
              {(readonly || !isPricingRoleEditable) && !roles.includes(BackendRoleName.SSEPricing) && (
                <span className="option">
                  <Icon name="close" className="grey20" />
                  Pricing
                </span>
              )}

              {(readonly || !isPricingRoleEditable) && roles.includes(BackendRoleName.SSEPricing) && (
                <span className="option">
                  <Icon name="done" className="primary" />
                  Pricing
                </span>
              )}

              {!readonly && isPricingRoleEditable && (
                <Checkbox
                  inputId="wildcard-option-pricing"
                  label="Pricing"
                  onChange={() => onRoleToggle(BackendRoleName.SSEPricing)}
                  value={roles.includes(BackendRoleName.SSEPricing)}
                />
              )}
            </Column>
          </div>
        </Column>
      </div>
    </div>
  );
};

const WildcardBar = styled(UnstyledWildcardBar)`
  > div {
    border: 1px solid ${(props) => props.theme.colours.grey10};
    padding: 0.8em;
    margin-bottom: 0.8em;
  }

  .option {
    i {
      margin-right: 0.2em;
      vertical-align: middle;
    }
  }
`;

export { WildcardBar };
