export const ConnectionTypes = [
  'Ethernet',
  'EoFTTP',
  'EoFTTC',
  'Optical',
  /** Valid for an end != Point */
  'None',
] as const;

export type ConnectionType = typeof ConnectionTypes[number];

export type OverallConnectionType = Exclude<ConnectionType, 'None'>;
export type FTTXConnectionType = Extract<ConnectionType, 'EoFTTP' | 'EoFTTC'>;

export const fttxConnectionTypes: ConnectionType[] = ['EoFTTC', 'EoFTTP'];

export const connectionTypeLabels: Array<{ key: ConnectionType; label: string }> = [
  { key: 'Ethernet', label: 'Ethernet' },
  { key: 'EoFTTP', label: 'EoFTTP' },
  { key: 'EoFTTC', label: 'EoFTTC' },
  { key: 'Optical', label: 'Optical' },
  { key: 'None', label: 'None' },
];

export const isFTTXConnection = (connection: ConnectionType): connection is FTTXConnectionType => {
  return fttxConnectionTypes.includes(connection);
};
