import React, { FunctionComponent } from 'react';
import LockableCard from 'shared/components/organisms/LockableCard';
import { MDIAText, orderNotCompleteText } from '../OrderReadyCard';
import { doesOrderContainProhibitedValues } from '../shared/utils/doesOrderContainProhibitedValues';
import { selectOrder } from 'Order/selectors';
import { useSelector } from 'react-redux';
import { OrderContainsProhibitedValuesAlert } from '../shared/components/OrderContainsProhibitedValuesAlert/OrderContainsProhibitedValuesAlert';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';

type Props = {
  className?: string;
  isManagedDIA?: boolean;
};

const DraftOrder: FunctionComponent<React.PropsWithChildren<Props>> = ({ className, isManagedDIA }) => {
  const order = useSelector(selectOrder);

  return (
    <div className={`${className} draft-order__wrapper`}>
      <LockableCard locked={true} title="Submit order for review">
        {featureFlag.isEnabled(Feature.orderIncompleteAlert) && doesOrderContainProhibitedValues(order) && (
          <OrderContainsProhibitedValuesAlert />
        )}

        <p className="text-muted">{orderNotCompleteText}</p>
        {isManagedDIA && <MDIAText className="text-muted" />}
      </LockableCard>
    </div>
  );
};

export default DraftOrder;
