import React, { useState } from 'react';
import { Divider } from 'shared/components/atoms/Divider/Divider';
import { BooleanOptionGroup } from 'shared/components/organisms/OptionGroup/utils/BooleanOptionGroup';
import styled from 'styled-components';

interface IAlreadyOrderedNNIChoiceProps {
  renderIfYes: JSX.Element;
  renderIfNo: JSX.Element;
  initialSelectionYes?: boolean;
  onChange?: (value: boolean) => void;
}

export const AlreadyOrderedNNIChoice = ({
  renderIfYes,
  renderIfNo,
  initialSelectionYes = true,
  onChange,
}: IAlreadyOrderedNNIChoiceProps) => {
  const [alreadyOrderedNNI, setAlreadyOrderedNNI] = useState(initialSelectionYes);

  return (
    <Container>
      <div>
        <p>Have you already ordered this NNI?</p>
        <BooleanOptionGroup
          onClick={(value) => {
            if (value === alreadyOrderedNNI) return;
            setAlreadyOrderedNNI(value);
            if (onChange) onChange(value);
          }}
          customIdBase="already-ordered-nni"
          value={alreadyOrderedNNI}
        ></BooleanOptionGroup>
      </div>

      <Divider />

      {alreadyOrderedNNI ? renderIfYes : renderIfNo}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
`;
