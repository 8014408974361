import Column from '../../../../shared/components/atoms/Column';
import { Amount } from './Cost';
import React from 'react';
import { IPriceData } from '../../../../Quotes/types/store';

export function FttpAggCharge(props: { displayFTTPAggregationCharge: boolean; selectedPrice: IPriceData }) {
  const fttpAggregationCharge = props.selectedPrice.fttp_aggregation_charge ?? 0;

  return (
    <>
      {props.displayFTTPAggregationCharge && (
        <div className="row no-gutters mt-1">
          <Column defaultWidth={2}>
            <span className="sub-heading">FTTP Aggregation</span>
          </Column>
          <Column defaultWidth={2}>
            <span></span>
          </Column>
          <Column defaultWidth={2} offsetDefaultWidth={2}>
            <span className="mr-2">Annual</span>
            <Amount amount={fttpAggregationCharge} className="fttp-aggregation-charge" />
          </Column>
        </div>
      )}
    </>
  );
}
