import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import Button, { ButtonMainStyle } from '../../../../shared/components/atoms/Button';
import Column from '../../../../shared/components/atoms/Column';
import { Role } from '../../../../User/types/role';
import { examplesForRole, titleForRole } from '../../../../User/utils/roles';

interface IProps {
  className?: string;
  roles: Role[];
  theme: {
    colours: {
      grey10: string;
    };
    shadows: {
      boxShadow: string;
    };
  };
  onClose(): void;
}

const RolesInfoPane: FunctionComponent<React.PropsWithChildren<IProps>> = ({ className, roles, onClose }) => {
  return (
    <div className={`${className} d-flex`}>
      <div className="d-flex pane-wrapper">
        <div className="d-flex pane-wrapper__header">
          <Column defaultWidth={9}>
            <span className="pane-wrapper__title">Learn about roles &amp; permissions</span>
          </Column>
          <Column defaultWidth={3}>
            <Button onClick={onClose} mainStyle={ButtonMainStyle.Link}>
              Close
            </Button>
          </Column>
        </div>

        <ul className="pane-wrapper__content">
          {roles.map((role) => (
            <li key={role}>
              <strong>{titleForRole(role)}</strong>

              <ul>
                {examplesForRole(role).map((example, i) => (
                  <li key={i}>{example}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default styled(RolesInfoPane)`
  background: white;
  border: solid 0.5px ${(props) => props.theme.colours.grey10};
  border-radius: 5px;
  bottom: -11em;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  min-height: 25vh;
  position: absolute;
  right: 0;
  z-index: 1;
  width: 40%;

  .pane-wrapper {
    box-sizing: border-box;
    flex-flow: column;
    max-height: 100%;
    width: 100%;
  }

  .pane-wrapper__header {
    padding: 0.8em 0;
    border-bottom: solid 0.5px ${(props) => props.theme.colours.grey10};

    button {
      color: ${(props) => props.theme.colours.primaryB1};
    }
  }

  .pane-wrapper__title {
    font-size: 0.9em;
  }

  .pane-wrapper__content {
    flex: 1;
    font-size: 0.95em;
    list-style: none;
    margin-bottom: 0;
    overflow-y: auto;
    padding: 0 1.2em 0 1.2em;
    min-height: 20vh;
    max-height: 40vh;

    > li {
      padding: 1.1em 0;
    }

    > li:not(:last-child) {
      border-bottom: solid 0.5px ${(props) => props.theme.colours.grey10};
    }
  }
`;
