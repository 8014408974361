import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Column from '../../../../shared/components/atoms/Column';
import CardRow from '../../../../shared/components/molecules/CardRow';
import { IAppState } from '../../../../reducers';
import { cloudProviderForDisplay } from '../../shared/utils/cloudProviderForDisplay';
import { TextFieldSummary } from '../../shared/components/SummaryTextHelper';
import { ProviderName } from '../../../../Quotes/types/store';
import { isValidTextField } from '../../shared/utils/validation';

type Props = {
  className?: string;
  theme: {
    colours: {
      grey50: string;
    };
  };
};

export const Summary: FunctionComponent<React.PropsWithChildren<Props>> = ({ className }) => {
  const cloudConnect = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.cloudConnect);
  const order = useSelector((state: IAppState) => state.orderBuilder.order);

  return (
    <CardRow className={className} title={`${cloudProviderForDisplay(cloudConnect.name)} configuration`}>
      <div className="row no-gutters">
        <Column defaultWidth={4}>
          <p>Cloud Connect keys</p>
        </Column>
        <Column defaultWidth={4}>
          <p className="lighter-text">Service Key 1</p>
        </Column>
        <Column defaultWidth={4}>
          <TextFieldSummary text={order.cloudConnect.serviceKey1} prompt="Add service key 1" className="text-summary" />
        </Column>
      </div>

      {cloudConnect.diversified && (
        <div className="row no-gutters">
          <Column defaultWidth={4} offsetDefaultWidth={4}>
            <p className="lighter-text">Service Key 2</p>
          </Column>
          <Column defaultWidth={4}>
            <TextFieldSummary
              text={order.cloudConnect.serviceKey2}
              prompt="Add service key 2"
              className="text-summary"
            />
          </Column>
        </div>
      )}

      {cloudConnect.name === ProviderName.Azure && (
        <div className="row no-gutters mt-3">
          <Column defaultWidth={4}>
            <p className="pb-0">Microsoft Azure ExpressRoute Inner VLAN id (c-tag)</p>
            <small className="lighter-text">
              Please ensure your Layer3 devices support QinQ interface to enable this service.
            </small>
          </Column>
          <Column defaultWidth={8}>
            <div className="row no-gutters">
              <Column defaultWidth={6}>
                <p className="lighter-text">Private Peering</p>
              </Column>
              <Column defaultWidth={6}>
                {!isValidTextField(order.cloudConnect.microsoftPeering) ? (
                  <TextFieldSummary
                    text={order.cloudConnect.privatePeering}
                    prompt="Add Private Peering"
                    className="text-summary"
                  />
                ) : (
                  <p className="text-summary">{order.cloudConnect.privatePeering}</p>
                )}
              </Column>
            </div>
            <div className="row no-gutters">
              <Column defaultWidth={6}>
                <p className="lighter-text">Microsoft Peering</p>
              </Column>
              <Column defaultWidth={6}>
                {!isValidTextField(order.cloudConnect.privatePeering) ? (
                  <TextFieldSummary
                    text={order.cloudConnect.microsoftPeering}
                    prompt="Add Mircosoft Peering"
                    className="text-summary"
                  />
                ) : (
                  <p className="text-summary">{order.cloudConnect.microsoftPeering}</p>
                )}
              </Column>
            </div>
          </Column>
        </div>
      )}
    </CardRow>
  );
};

export default styled(Summary)`
  .lighter-text {
    color: ${(props) => props.theme.colours.grey50};
  }

  .text-summary {
    word-break: break-all;
  }
`;
