import React from 'react';
import styled from 'styled-components';
import { CircuitData, isDIA } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import { IQuote } from 'Quotes/types/store';
import { SecondaryIPAllocation } from 'shared/components/molecules/SecondaryCircuits/SecondaryIPAllocation';
import { MDIARouters } from 'shared/components/molecules/SecondaryCircuits/MDIARouters';

export const Title = styled.h4`
  font-weight: bold;
`;
export const DIAConfigContainer = styled.div`
  margin-top: 0.55rem;
  padding: 1rem;
  background: ${(props) => props.theme.colours.secondaryC5};
  border: 1px solid ${(props) => props.theme.colours.primaryB2};
  border-radius: 0.5rem;
`;

interface DIAConfigProps {
  circuitData: CircuitData;
  quote: IQuote;
  isReadOnly: boolean;
  setEnabled: (enabled: boolean) => void;
}

export function DIAConfig({ circuitData, quote, isReadOnly, setEnabled }: DIAConfigProps) {
  const isMDIA = quote.location.aEnd.is_managed_dia;

  if (!isDIA(circuitData)) return null;
  const selectedPrimaryIP = quote.location.aEnd.ip.selectedId;
  if (!selectedPrimaryIP) {
    return null;
  }

  return (
    <DIAConfigContainer>
      <SecondaryIPAllocation quote={quote} isReadOnly={isReadOnly} />
      {isMDIA && <MDIARouters quote={quote} isReadOnly={isReadOnly} setEnabled={setEnabled} />}
    </DIAConfigContainer>
  );
}
