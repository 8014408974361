export const maskSSEUserEmail = (email?: string) => {
  if (!email) {
    return '';
  }

  if (email.endsWith('@sse.com')) {
    return email.replace('@sse.com', '').replace(/\./g, ' ');
  }

  if (email.endsWith('@neosnetworks.com')) {
    return email.replace('@neosnetworks.com', '').replace(/\./g, ' ');
  }

  return email;
};
