import IOrderRecord from 'Order/types/orderRecord';
import { doRequest, Methods, ResourceQueryParam } from 'Request';

const getOrder = (orderId: string) => {
  return doRequest<IOrderRecord>({
    method: Methods.GET,
    path: `/orders/${orderId}?include=${ResourceQueryParam.downstream_responses},${ResourceQueryParam.messages}`,
  });
};

export default getOrder;
