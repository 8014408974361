import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'shared/components/atoms/Icon';
import { Notes } from 'shared/components/organisms/Notes';
import { OrderNotesList } from '../OrderNotesList';
import { canAddOrderNoteSelector, createNoteStateSelector, orderIdSelector, orderNotesSelector } from 'Order/selectors';
import { selectQuoteId } from 'Quotes/selectors';
import { createNote, createNoteResetState } from 'Order/actions';
import { RequestLifecycle } from 'shared/actions';

type Props = {
  className?: string;
};

const OrderNotes: FunctionComponent<React.PropsWithChildren<Props>> = ({ className }) => {
  const [showNotes, setShowNotes] = useState(false);
  const canAddNote = useSelector(canAddOrderNoteSelector);
  const orderId = useSelector(orderIdSelector);
  const quoteId = useSelector(selectQuoteId);
  const notes = useSelector(orderNotesSelector);
  const createNoteState = useSelector(createNoteStateSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showNotes) {
      dispatch(createNoteResetState());
    }
  }, [dispatch, showNotes]);

  return (
    <div className={className}>
      <div
        className="notes-icon-container"
        onClick={() => {
          if (createNoteState !== RequestLifecycle.Loading) {
            setShowNotes(!showNotes);
          }
        }}
      >
        <Icon name="message_outline" />
      </div>
      {showNotes && (
        <div className="notes-container">
          <Notes
            composeMode={canAddNote}
            error={createNoteState === RequestLifecycle.Error}
            loading={createNoteState === RequestLifecycle.Loading}
            numberOfNotes={notes.length}
            onClose={() => setShowNotes(false)}
            onSubmit={(message) => {
              if (!quoteId || !orderId) {
                return;
              }

              dispatch(createNote(quoteId, orderId, message));
            }}
            title="Order notes (customer conversation)"
            showFAQIntro={true}
          >
            <OrderNotesList notes={notes} />
          </Notes>
        </div>
      )}
    </div>
  );
};

const StyledOrderNotes = styled(OrderNotes)`
  .notes-icon-container {
    background-color: ${(props) => props.theme.colours.primaryC1};
    box-shadow: ${(props) => props.theme.shadows.boxShadow};
    position: fixed;
    bottom: 2em;
    right: 4em;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    padding-left: 1.1em;
    padding-top: 1.1em;
    color: white;
    cursor: pointer;
    z-index: 1;
  }

  .notes-container {
    position: fixed;
    right: 5em;
    bottom: 6.5em;
    top: 1.8em;
    display: flex;
    z-index: 100;
  }
`;

export { StyledOrderNotes as OrderNotes };
