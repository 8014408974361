import React, { FunctionComponent } from 'react';
import HeaderWithChildrenWrapper from '../shared/components/molecules/HeaderWithChildrenWrapper';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import styled from 'styled-components';

const Container = styled.div`
  .alert {
    margin-bottom: 0;
  }
`;

const Forbidden: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  return (
    <HeaderWithChildrenWrapper header="Forbidden">
      <Container>
        <Alert alertType={Alerts.WARNING}>
          <p className="mt-3">
            You don&apos;t have permission to view this content, please contact your administrator.
          </p>
        </Alert>
      </Container>
    </HeaderWithChildrenWrapper>
  );
};

export default Forbidden;
