import React, { FunctionComponent } from 'react';

import { IOrder } from '../../types/store';
import { OrderStatus } from '../../types/orderRecord';
import { formatDateHuman } from '../../../shared/utils/dateHelper';
import { maskSSEUserEmail } from 'shared/utils/maskEmail';

type Props = {
  order: IOrder;
};

const Cancelled: FunctionComponent<React.PropsWithChildren<Props>> = ({ order: { stateHistory } }) => {
  const cancelledItem = stateHistory.find((item) => item.state === OrderStatus.CANCELLED);

  if (!cancelledItem) {
    return null;
  }

  return (
    <p>
      Your order was cancelled on {formatDateHuman(cancelledItem.created_at)} by{' '}
      {maskSSEUserEmail(cancelledItem.updated_by)}
    </p>
  );
};

export default Cancelled;
