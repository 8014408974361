import React from 'react';

function AvatarSVG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" {...props}>
      <path
        fill="#004687"
        opacity={0.6}
        d="M14 0C6.272 0 0 6.272 0 14s6.272 14 14 14 14-6.272 14-14S21.728 0 14 0zm0 4.2c2.324 0 4.2 1.876 4.2 4.2 0 2.324-1.876 4.2-4.2 4.2a4.194 4.194 0 01-4.2-4.2c0-2.324 1.876-4.2 4.2-4.2zm0 19.88a10.08 10.08 0 01-8.4-4.508c.042-2.786 5.6-4.312 8.4-4.312 2.786 0 8.358 1.526 8.4 4.312A10.08 10.08 0 0114 24.08z"
      />
    </svg>
  );
}

export default AvatarSVG;
