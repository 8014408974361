import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

import { Role } from '../../../../User/types/role';
import Checkbox from '../../../../shared/components/atoms/Checkbox';
import Icon from '../../../../shared/components/atoms/Icon';
import { roleForDisplay } from '../../../../User/utils/roles';
import { IRoleHeader } from 'Admin/shared/components/CompaniesAndRoles/CompaniesAndRoles';

interface IRoles {
  enabled: boolean;
  role: Role;
}

export interface IRow {
  companyName: string;
  companyId: string;
  readonly: boolean;
  roles: IRoles[];
}

interface IProps {
  className?: string;
  onRoleToggle(companyId: string, role: Role, newState: boolean): void;
  readonly: boolean;
  roles: IRoleHeader[];
  rows: IRow[];
}

export const CompaniesRolesTable: FunctionComponent<React.PropsWithChildren<IProps>> = ({
  className,
  onRoleToggle,
  readonly,
  roles,
  rows,
}) => {
  return (
    <div className={`${className} table-responsive mt-2`}>
      <table className={cx('table table-hover', { empty: rows.length === 0 })}>
        <thead>
          <tr>
            <th scope="col" className="border-top-0">
              Companies
            </th>
            {roles.map((roleHeader) => (
              <th key={roleForDisplay(roleHeader.role)} scope="col" className="border-top-0">
                <div className="companyHeader">
                  {!readonly && (
                    <Checkbox
                      className={`${roleHeader.role}__checkbox`}
                      inputId={`headSelection__${roleForDisplay(roleHeader.role)}`}
                      onChange={(event) => {
                        roleHeader.toggleColumnStatus(event);
                      }}
                      value={roleHeader.isChecked}
                      label={roleForDisplay(roleHeader.role)}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.companyId}>
              <th scope="row">{row.companyName}</th>
              {row.roles.map((item) => (
                <td key={item.role}>
                  {!readonly && !row.readonly && (
                    <Checkbox
                      inputId={`${item.role}_${row.companyId}`}
                      onChange={() => onRoleToggle(row.companyId, item.role, !item.enabled)}
                      value={item.enabled}
                    />
                  )}
                  {(readonly || row.readonly) && item.enabled && (
                    <Icon name="done" className={`primary ${item.role}_${row.companyId}_done`} />
                  )}

                  {(readonly || row.readonly) && !item.enabled && (
                    <Icon name="close" className={`grey20 ${item.role}_${row.companyId}_close`} />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const StyledCompaniesRolesTable = styled(CompaniesRolesTable)`
  .empty {
    opacity: 0.3;
  }

  .table-hover tbody tr:hover {
    background-color: ${(props) => props.theme.colours.primaryB5};
  }

  .companyHeader {
    display: flex;
  }
`;

export default StyledCompaniesRolesTable;
