import { VLAN_ID_INTERNALLY_ASSIGNED, LEGACY_VLAN_ID_INTERNALLY_ASSIGNED } from 'Order/types/orderRecord';

const INTERNALLY_ASSIGNED_VALUES = [LEGACY_VLAN_ID_INTERNALLY_ASSIGNED, VLAN_ID_INTERNALLY_ASSIGNED];

export const isVlanIdInternallyAssigned = (vlanId?: string | null) => {
  if (typeof vlanId !== 'string') {
    return false;
  }

  return INTERNALLY_ASSIGNED_VALUES.includes(vlanId);
};
