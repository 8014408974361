import { QuoteOrigin } from 'Quotes/types/store';
import { CerillionStage, OrderStatus } from '../../types/orderRecord';
import Column from '../../../shared/components/atoms/Column';
import { Link } from 'react-router-dom';
import { bulkQuoteById, quoteById } from 'Quotes/Routes';
import { capitalize } from 'lodash';
import PresentForRoles from '../../../Auth/PresentForRoles';
import { userPermissions } from 'shared/utils/permissions';
import Icon from '../../../shared/components/atoms/Icon';
import { formatDateHuman } from 'shared/utils/dateHelper';
import OrderState from './OrderState/OrderState';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import React from 'react';

export function OrderIdDetails(props: {
  origin: QuoteOrigin;
  id: string;
  string: string;
  bulkQuoteId: string | undefined;
  shortBulkQuoteId: string | undefined;
  placedAt: string | null | undefined;
  createdAt: string;
  cerillionStage: CerillionStage | null | undefined;
  state: OrderStatus;
  cerillionBasketId?: string | null;
}) {
  return (
    <Column>
      <p className="headline w-100" style={{ display: 'flex' }}>
        {`From ${props.origin === QuoteOrigin.API ? 'API ' : ''}quote: `}

        <span className="ml-2 mr-2">
          <Link id="quote-link" to={quoteById(props.id)} target="_blank">
            {capitalize(props.string)}
          </Link>
        </span>

        {props.bulkQuoteId && (
          <PresentForRoles roles={userPermissions.viewEditBulkQuote}>
            <Icon className="bulk-quote-arrow" name="keyboard_backspace" size="normal" />
            <Link id="bulk-quote-link" to={bulkQuoteById(props.bulkQuoteId)} target="_blank">
              Bulk {props.shortBulkQuoteId ? props.shortBulkQuoteId : props.bulkQuoteId}
            </Link>
          </PresentForRoles>
        )}

        <span className="ml-2 mr-2">
          {props.placedAt ? 'Placed: ' : 'Created: '}
          <time dateTime={props.placedAt ? props.placedAt : props.createdAt}>
            {props.placedAt ? formatDateHuman(props.placedAt) : formatDateHuman(props.createdAt)}
          </time>
        </span>

        <span>
          <OrderState
            state={
              featureFlag.isEnabled(Feature.orderStatusStepperUI) ? props.cerillionStage ?? props.state : props.state
            }
          />
        </span>

        {props.cerillionBasketId && (
          <PresentForRoles roles={userPermissions.viewCRMOrderResponse}>
            <span style={{ flexGrow: 1 }} />
            <span className="ml-2 mr-2" style={{ alignSelf: 'self-end' }}>
              Cerillion Basket Id: {props.cerillionBasketId}
            </span>
          </PresentForRoles>
        )}
      </p>
    </Column>
  );
}
