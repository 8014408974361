import React from 'react';
import { useSelector } from 'react-redux';
import { RowCell } from 'shared/components/organisms/MultiSelectTable';
import { MessageResource, MessageResourceType } from 'shared/types/Messages';
import { Link } from 'react-router-dom';
import Icon from 'shared/components/atoms/Icon';
import styled from 'styled-components';
import { ResourceTypes } from 'Request';
import TableNames from 'Tables/types/tableNames';
import { ColumnTypes, Header, PagedTable } from 'shared/components/organisms/MultiSelectTable/Table';
import cx from 'classnames';
import { quoteById } from 'Quotes/Routes';
import { orderById } from 'Order/routes';
import { shortenId } from 'shared/utils/idFormatter';
import { selectActiveUserId } from 'User/selectors';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';

export const MessagesList = ({
  messagePaging,
  onRowClick,
}: {
  messagePaging: IServerSidePaging;
  onRowClick: (message: MessageResource) => void;
}) => {
  const userId = useSelector(selectActiveUserId);

  return (
    <div>
      <PagedTable<MessageResource>
        headers={buildHeaders(userId)}
        records={messagePaging.results}
        tableName={TableNames.messages}
        onRowClick={(message: MessageResource) => onRowClick(message)}
        paging={messagePaging}
      />
    </div>
  );
};

const buildHeaders = (userId: string): Array<Header<MessageResource>> => [
  {
    title: 'Unread',
    field: (message) => (message.attributes.unread ? 'Unread' : 'Read'),
    width: 40,
    columnConfig: {
      type: ColumnTypes.Custom,
      customRenderer: (cell: RowCell) => <UnreadIconRenderer value={cell.value} />,
    },
  },
  {
    title: 'Order/Quote Id',
    field: (message) => convertToLabel(message),
    width: 60,
    // filter: DefaultColumnFilter,
    columnConfig: {
      type: ColumnTypes.Custom,
      customRenderer: (cell) => <ResourceIdWithLink message={toMessage(cell)} />,
    },
  },
  {
    title: 'Preview',
    field: (message) => message.attributes.messageText,
    columnConfig: {
      type: ColumnTypes.Custom,
      customRenderer: (cell: RowCell) => {
        return (
          <span
            className={cx({
              'font-weight-bold': toMessage(cell).attributes.unread,
            })}
          >
            {cell.value}
          </span>
        );
      },
    },
    width: 250,
  },
  {
    title: 'Type',
    field: (message) => typeToLabel[message.attributes.resourceType] || message.attributes.resourceType,
    // filter: SelectColumnFilter,
    width: 50,
    columnConfig: {
      type: ColumnTypes.Text,
    },
  },
  {
    title: 'Date',
    field: (message) => message.attributes.createdAt,
    // filter: DateFilter,
    width: 70,
    columnConfig: {
      type: ColumnTypes.Date,
    },
  },
  {
    title: 'Sent / Received',
    width: 60,
    field: (message) => (message.attributes.createdBy === userId ? 'Sent' : 'Received'),
    columnConfig: {
      type: ColumnTypes.Text,
    },
    // filter: SelectColumnFilter,
  },
];

const UnreadIcon = styled(Icon)`
  color: ${(props) => props.theme.colours.secondaryA1};
  width: 100%;
  text-align: center;
`;

const toMessage = (cell: RowCell): MessageResource => {
  return (cell.row.original as unknown) as MessageResource;
};

const UnreadIconRenderer = ({ value }: { value: 'Unread' | 'Read' }) => {
  return value === 'Unread' ? <UnreadIcon name="circle" size="small" /> : <></>;
};

const typeToLabel: { [key in MessageResourceType]: string } = {
  [ResourceTypes.order]: 'Order',
  [ResourceTypes.quote]: 'Quote',
};

const messageToUrl = {
  [ResourceTypes.quote]: (message: MessageResource) => ({
    label: `Q-${shortenId(message.attributes.resourceId)}`,
    url: quoteById(message.attributes.resourceId),
  }),
  [ResourceTypes.order]: (message: MessageResource) => ({
    label: `O-${shortenId(message.attributes.resourceId)}`,
    url: orderById(message.attributes.resourceId),
  }),
};

export const convertMessageToUrl = (message: MessageResource): { label: string; url: string } => {
  return messageToUrl[message.attributes.resourceType](message);
};

const convertToLabel = (message: MessageResource) => convertMessageToUrl(message).label;

const ResourceIdWithLink = ({ message }: { message: MessageResource }) => {
  const { label, url } = convertMessageToUrl(message);
  return <Link to={url}>{label}</Link>;
};
