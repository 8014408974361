import { connect } from 'react-redux';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toNormalised } from 'postcode';
import { doRequest } from 'Request';
import Alert from '../../shared/components/atoms/Alert';
import PostcodeLookup from '../../shared/components/molecules/PostcodeLookup';
import { IPAFAddressSite } from 'shared/types/postcodeResults';
import { IAppState } from 'reducers';
import { IOnNetSite } from 'shared/types/onNetSite';
import { AddressesFound } from 'Quotes/types/store';
import Spinner from '../../shared/components/molecules/SpinnerWithText';
import { isValidOnNetResponse, isValidPAFResponse } from 'shared/utils/addresses/validators';
import getPAFAddressesForLocation from 'shared/utils/addresses/getPAFAddressesForLocation';
import { isNNATEnabledFor } from 'FeatureFlags/nnatUtils';
import { ProductType } from 'Quotes/types/productTypes';

interface IPostcodeCaptureProps {
  productType: ProductType;
  initialPostcode: string;
  validPostcodeFound: boolean;
  selectedCompanyId: string | null;
  addressesFound: Pick<AddressesFound, 'onNet' | 'paf'>;
  selectedAddress: IPAFAddressSite | IOnNetSite | null;
  onPostcodeChange(postcode: string): void;
  onPostcodeError(): void;
  onResults(postcode: string, addressesFound: AddressesFound): void;
  addressRetrieval(retrieve: boolean): void;
  onChange?(value: string): void;
}

export const INVALID_POSTCODE_ERROR_MSG = 'Enter a valid postcode.';
export const NO_MATCH_ERROR_MSG = 'Postcode not found. Please contact your Account Manager.';
export const LOCATION_FORMAT_ERROR = 'Location "{location}" format is not recognised.';
export const POSTCODE_OTHER_ERROR_MSG =
  'Location lookup failed. Try again later. If the problem persists, please contact your Account Director.';
export const NO_ADDRESSES_FOR_LOCATION_MSG = 'No addresses found for the selected location.';

const StyledAlertContainer = styled.div`
  margin-top: 20px;
`;

export const getLookupError = (error: any, input: string) => {
  // return specifix error if thrown
  if (typeof error === 'string') return error;

  if (error && error.response && error.response.status) {
    switch (error.response.status) {
      case 400:
        return LOCATION_FORMAT_ERROR.replace('{location}', input);
      case 404:
        return NO_MATCH_ERROR_MSG;
      default:
        return POSTCODE_OTHER_ERROR_MSG;
    }
  }
  return POSTCODE_OTHER_ERROR_MSG;
};

export const PostcodeCapture: FunctionComponent<React.PropsWithChildren<IPostcodeCaptureProps>> = ({
  productType,
  initialPostcode,
  addressesFound = [],
  addressRetrieval,
  selectedAddress,
  onPostcodeChange,
  onPostcodeError,
  onResults,
  validPostcodeFound,
  selectedCompanyId,
  onChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [postcodeLookupError, setPostcodeLookupError] = useState('');

  useEffect(() => {
    const pafAddress = selectedAddress as IPAFAddressSite;
    const onNetAddress = selectedAddress as IOnNetSite;

    if (
      initialPostcode &&
      !isLoading &&
      Object.values(addressesFound).flat().length === 0 &&
      (pafAddress?.attributes?.udprn || onNetAddress?.attributes?.colt_building_id || onNetAddress?.attributes?.pop_id)
    ) {
      onLookup(initialPostcode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) {
      addressRetrieval(true);
    } else {
      addressRetrieval(false);
    }
  }, [addressRetrieval, isLoading]);

  useEffect(() => {
    return () => {
      addressRetrieval(false);
    };
  }, [addressRetrieval]);

  const onLookup = async (input: string) => {
    if (input.length === 0) return;

    setIsLoading(true);
    setPostcodeLookupError('');

    try {
      const pafResponse = await getPAFAddressesForLocation(input);

      if (!pafResponse.meta || !pafResponse.meta.postcode) {
        setPostcodeLookupError(NO_ADDRESSES_FOR_LOCATION_MSG);
        onPostcodeError();
        setIsLoading(false);
        return;
      }

      const onNetResponse = await doRequest({
        path: `/postcodes/on-net?customer_id=${selectedCompanyId}&postcode=${toNormalised(pafResponse.meta.postcode)}`,
      });

      const normalisedPostcode = toNormalised(pafResponse.meta.postcode);
      onPostcodeChange(normalisedPostcode!);

      if (isValidPAFResponse(pafResponse) || isValidOnNetResponse(onNetResponse)) {
        onResults(normalisedPostcode!, {
          onNet: onNetResponse.data,
          openreach: [],
          paf: pafResponse.data,
          hasNNATAddresses: isNNATEnabledFor(productType) && pafResponse.meta.has_nnat_addresses,
        });
      } else {
        setPostcodeLookupError(POSTCODE_OTHER_ERROR_MSG);
        onPostcodeError();
      }
    } catch (error) {
      setPostcodeLookupError(getLookupError(error, input));
      onPostcodeError();
    }

    setIsLoading(false);
  };

  return (
    <>
      <PostcodeLookup
        initialPostcode={initialPostcode}
        isLoading={isLoading}
        onLookup={onLookup}
        showValidPostcodeFeedback={validPostcodeFound}
        clearErrorMessage={() => setPostcodeLookupError('')}
        onChange={onChange}
      />

      {isLoading && <Spinner text="Finding addresses" className="loading-spinner" size="large" />}

      {postcodeLookupError !== '' && (
        <StyledAlertContainer>
          <Alert>{postcodeLookupError}</Alert>
        </StyledAlertContainer>
      )}
    </>
  );
};

const mapStateToProps = ({ user: { companyData } }: IAppState) => ({
  selectedCompanyId: companyData.selectedCompanyId,
});

export default connect(mapStateToProps)(PostcodeCapture);
