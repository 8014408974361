import { ConnectionType, OverallConnectionType } from '../types/connectionType';
import {
  IAccessAvailabilityItem,
  IAccessAvailabilityRecord,
  IBandwidthRecord,
} from '../types/accessAvailabilityRecord';
import { IAccessAvailability } from '../types/accessAvailability';
import { ProductType } from '../types/productTypes';
import intersectionBy from 'lodash/intersectionBy';
import uniqBy from 'lodash/uniqBy';
import { productTypeHasFTTX } from './productTypeHasFTTX';

export interface IAvailability {
  fttcAvailable: boolean;
  fttcBandwidths: IBandwidthRecord[];
  fttpAvailable: boolean;
  fttpBandwidths: IBandwidthRecord[];
}

export const getEndAvailability = (availability: IAccessAvailabilityItem[] | null): IAvailability => {
  const fttp = availability?.find((item) => item.name === 'EoFTTP');
  const fttc = availability?.find((item) => item.name === 'EoFTTC');
  return {
    fttpAvailable: !!fttp?.bandwidths?.length,
    fttcAvailable: !!fttc?.bandwidths?.length,
    fttpBandwidths: fttp?.bandwidths || [],
    fttcBandwidths: fttc?.bandwidths || [],
  };
};

export const getPreferredConnectionType = (availability: IAvailability): ConnectionType => {
  if (availability.fttpAvailable) {
    return 'EoFTTP';
  } else if (availability.fttcAvailable) {
    return 'EoFTTC';
  }

  return 'Ethernet';
};

export const getBandwidthsForConnectionType = function (
  availabilityItems: IAccessAvailabilityItem[] | null,
  connectionType: ConnectionType | OverallConnectionType
): IBandwidthRecord[] {
  const availabilityItem = availabilityItems?.find((item) => item.name === connectionType);
  return availabilityItem?.bandwidths || [];
};

export const getCommonBandwidths = (
  record: IAccessAvailabilityRecord,
  preferredAvailability: OverallConnectionType | ConnectionType,
  productType: ProductType
): IBandwidthRecord[] => {
  const bandwidthsA = getBandwidthsForConnectionType(record.data.attributes.a_end_availability, preferredAvailability);
  const bandwidthsB =
    productType === ProductType.P2P
      ? getBandwidthsForConnectionType(record.data.attributes.b_end_availability, preferredAvailability)
      : bandwidthsA;

  // If FTTC, return the unique FTTC bandwidths only.
  if (preferredAvailability === 'EoFTTC') {
    return uniqBy([...bandwidthsA, ...bandwidthsB], 'value');
  }

  return intersectionBy(bandwidthsA, bandwidthsB, 'value');
};

export const getOverallAvailability = (
  connectionA: ConnectionType,
  connectionB: ConnectionType,
  productType: ProductType
) => {
  if (!productTypeHasFTTX(productType)) {
    return 'Ethernet';
  }

  if (productType === ProductType.P2NNI && connectionB === 'EoFTTP') {
    return 'EoFTTP';
  }

  if (productType === ProductType.P2NNI && connectionB === 'EoFTTC') {
    return 'EoFTTC';
  }

  if (productType === ProductType.DIA && connectionA === 'EoFTTP') {
    return 'EoFTTP';
  }

  if (productType === ProductType.DIA && connectionA === 'EoFTTC') {
    return 'EoFTTC';
  }

  if (productType === ProductType.P2P) {
    if (connectionA === 'EoFTTP' && connectionB === 'EoFTTP') {
      return 'EoFTTP';
    } else if (
      connectionA !== 'Ethernet' &&
      connectionB !== 'Ethernet' &&
      (connectionA === 'EoFTTC' || connectionB === 'EoFTTC')
    ) {
      return 'EoFTTC';
    }
  }

  return 'Ethernet';
};

export const transformAccessAvailability = (
  record: IAccessAvailabilityRecord,
  productType: ProductType
): IAccessAvailability => {
  const aEndAvailability = getEndAvailability(record.data.attributes.a_end_availability);
  const bEndAvailability =
    productType === ProductType.P2P ? getEndAvailability(record.data.attributes.b_end_availability) : aEndAvailability;

  const aEnd = getPreferredConnectionType(aEndAvailability);
  const bEnd = getPreferredConnectionType(bEndAvailability);
  const overall = getOverallAvailability(aEnd, bEnd, productType);
  const bandwidths = getCommonBandwidths(record, overall, productType);
  let fixedAEndBandwidth = null;
  let fixedBEndBandwidth = null;
  if (overall === 'EoFTTC' && aEnd === 'EoFTTP') {
    fixedAEndBandwidth = aEndAvailability.fttpBandwidths[0];
  } else if (overall === 'EoFTTC' && bEnd === 'EoFTTP') {
    fixedBEndBandwidth = bEndAvailability.fttpBandwidths[0];
  }
  return {
    id: record.data.id,
    aEnd,
    bEnd,
    overall,
    bandwidths,
    fixedAEndBandwidth,
    fixedBEndBandwidth,
  };
};
