import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import Icon from 'shared/components/atoms/Icon';

interface SupplierViewToggle {
  onClick(viewAs: 'slider' | 'list'): void;
  activeView: 'slider' | 'list';
}

const ListAndSliderViewToggle: FunctionComponent<React.PropsWithChildren<SupplierViewToggle>> = ({
  activeView,
  onClick,
}) => {
  return (
    <p className="mb-0">
      <span className="pr-1">View as</span>
      <button onClick={() => onClick('list')}>
        <Icon
          name="list"
          size="xLarge"
          className={cx('view_as_icon', 'align-top', {
            active: activeView === 'list',
          })}
        />
      </button>
      <button onClick={() => onClick('slider')}>
        <Icon
          name="view_column"
          size="xLarge"
          className={cx('view_as_icon', 'align-top', {
            active: activeView === 'slider',
          })}
        />
      </button>
    </p>
  );
};

export { ListAndSliderViewToggle };
