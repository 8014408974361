import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import Column from '../../../../../../shared/components/atoms/Column';
import Button, { ButtonMainStyle } from '../../../../../../shared/components/atoms/Button';
import { connect, DispatchProp } from 'react-redux';
import { IAppState } from '../../../../../../reducers';
import { updatePriceQuoteAction } from '../../../../../actions';
import TextInput from '../../../../../../shared/components/atoms/TextInput';
import IPricedQuote from '../../../../../types/pricedQuote';
import { QuoteStatus } from '../../../../../types/quoteRecordAttributesBase';

interface ICostAdjustment {
  className?: string;
  currentQuoteId: string;
  annualDiscount: number;
  installDiscount: number;
  selectedPriceId: string;
  amortised: boolean;
  quoteState: QuoteStatus;
  updatePrice(
    quoteId: string,
    priceId: string,
    installDiscount: number,
    annualDiscount: number,
    amortised: boolean
  ): void;
}

export const CostAdjustment: FunctionComponent<React.PropsWithChildren<ICostAdjustment>> = (props) => {
  const [annualDiscount, setAnnualDiscount] = React.useState<string | number>(props.annualDiscount);
  const [installDiscount, setInstallDiscount] = React.useState<string | number>(props.installDiscount);

  const onChangeInstallDiscount = (input: HTMLInputElement) => {
    if (input.validity.valid) {
      setInstallDiscount(input.value);
    }
  };

  const onChangeAnnualDiscount = (input: HTMLInputElement) => {
    if (input.validity.valid) {
      setAnnualDiscount(input.value);
    }
  };

  useEffect(() => {
    setInstallDiscount(props.installDiscount);
    setAnnualDiscount(props.annualDiscount);
  }, [props.annualDiscount, props.installDiscount]);

  const onClick = () => {
    if (annualDiscount !== props.annualDiscount || installDiscount !== props.installDiscount) {
      props.updatePrice(
        props.currentQuoteId,
        props.selectedPriceId,
        Number(installDiscount),
        Number(annualDiscount),
        props.amortised
      );
    }
  };

  const content = (
    <>
      <div className="form-row discount__wrapper pb-3">
        <Column defaultWidth={6}>
          <p className="mb-0 text-secondary">Install Discount</p>
          <span className="text-secondary">£</span>
          <TextInput
            inputIdentifierPrefix="add__installDiscount"
            fieldName="installDiscount"
            pattern="\d+(\.\d*)?"
            onChange={(input) => onChangeInstallDiscount(input.target)}
            value={installDiscount}
            classNames={['bottom-border__input']}
          />
        </Column>
        <Column defaultWidth={6}>
          <p className="mb-0 text-secondary">Annual Discount</p>
          <span className="text-secondary">£</span>
          <TextInput
            inputIdentifierPrefix="add__annualDiscount"
            fieldName="annualDiscount"
            pattern="\d+(\.\d*)?"
            onChange={(input) => onChangeAnnualDiscount(input.target)}
            value={annualDiscount}
            classNames={['bottom-border__input']}
          />
        </Column>
      </div>
      <Button
        onClick={onClick}
        className="find-btn mb-3"
        disabled={props.quoteState === QuoteStatus.ORDERED}
        mainStyle={ButtonMainStyle.SecondaryRectangular}
      >
        Recalculate
      </Button>
    </>
  );

  return (
    <div className={props.className}>
      <h4 className="h5">Adjust quote</h4>
      {props.quoteState === QuoteStatus.ORDERED ? <fieldset disabled={true}>{content}</fieldset> : content}
    </div>
  );
};

const styledCostAdjustment = styled(CostAdjustment)`
  h4 {
    color: ${(props) => props.theme.colours.primaryA1};
  }

  .price_summary__radiogroup {
    width: 100%;
    height: 5em;
    margin-bottom: 1em;
  }

  .bottom-border__input {
    border: 1px solid transparent;
    border-bottom-color: #ced4da;
  }

  .term-selector__dropdown > div {
    border: 1px solid transparent;
    border-bottom-color: #cccccc;
  }

  .modify-quote__column {
    max-width: 48%;
    flex: 0 0 48%;
  }

  .discount__wrapper span {
    position: absolute;
    top: 37px;
    left: 10px;
    width: auto;
    font-size: 1rem;
  }

  .discount__wrapper {
    .form-control {
      padding-left: 14px;
    }
  }
`;

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  updatePrice: (
    quoteId: string,
    priceId: string,
    installDiscount: number,
    annualDiscount: number,
    amortised: boolean
  ) =>
    dispatch(
      updatePriceQuoteAction(
        priceId,
        {
          install_discount: installDiscount,
          annual_discount: annualDiscount,
          amortised: amortised,
        } as IPricedQuote,
        false,
        quoteId,
        true
      )
    ),
});

const mapStateToProps = (state: IAppState) => ({
  currentQuoteId: state.quoteBuilder.currentQuoteId,
  quoteState: state.quoteBuilder.state,
});

export default connect(mapStateToProps, mapDispatchToProps)(styledCostAdjustment);
