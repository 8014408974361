import React, { FC } from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'reducers';
import ButtonWithIconAndBadge from 'shared/components/molecules/ButtonWithIconAndBadge/ButtonWithIconAndBadge';

interface IAlertsNotification {
  unreadAlerts: number;
}

const AlertsNotification: FC<React.PropsWithChildren<IAlertsNotification>> = ({ unreadAlerts }) => {
  return (
    <ButtonWithIconAndBadge
      className="alerts-notification"
      icon="notifications_active"
      data-testid="alerts-header-button"
      count={unreadAlerts}
    >
      Alerts
    </ButtonWithIconAndBadge>
  );
};

const mapStateToProps = (state: IAppState) => ({
  unreadAlerts: state.user.userData.unreadAlerts,
});

export default connect(mapStateToProps)(AlertsNotification);
