import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Spinner, { SpinnerProps } from '../../atoms/Spinner';
import cx from 'classnames';

type Props = SpinnerProps & {
  text: string;
  inline?: boolean;
};

const SpinnerWithText: FunctionComponent<React.PropsWithChildren<Props>> = ({
  backgroundColour,
  className,
  id,
  text,
  inline = false,
  size,
}) => {
  return (
    <div className={cx(className, 'price_spinner_wrapper', { inline })}>
      <Spinner size={size} id={id} backgroundColour={backgroundColour} />
      <p className="price_spinner_wrapper__prompt">{text}</p>
    </div>
  );
};

const styledSpinnerWithText = styled(SpinnerWithText)`
  padding-top: 50px;
  text-align: center;

  .price_spinner_wrapper__prompt {
    margin-top: 20px;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  &.inline {
    margin-top: 0;
    display: flex;

    p {
      margin-left: 20px;
    }
  }
`;

export default styledSpinnerWithText;
