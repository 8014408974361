import { IPriceData, IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import { P2PCircuit } from 'Order/OrderBuilder/QuoteSummary/P2P/P2PCircuit';
import { OrderBandwidth } from 'Order/OrderBuilder/QuoteSummary/OrderBandwidth';
import React from 'react';

export function SingleCircuitP2P(props: {
  circuit?: string;
  selectedPrice: IPriceData;
  sseUser: boolean | undefined;
  primaryAddressAEnd: string | undefined;
  quote: IQuote;
  primaryAddressBEnd: string | undefined;
  bandwidth: string;
  bearer: BearerType | undefined;
  productType: ProductType;
}) {
  return (
    <>
      <P2PCircuit
        circuit={props.circuit}
        selectedPrice={props.selectedPrice}
        sseUser={props.sseUser}
        primaryAddressAEnd={props.primaryAddressAEnd}
        quote={props.quote}
        primaryAddressBEnd={props.primaryAddressBEnd}
      />
      <OrderBandwidth
        connectionType={props.quote.connectionType}
        bandwidth={props.bandwidth}
        selectedPrice={props.selectedPrice}
        quote={props.quote}
        bearer={props.bearer}
        aEndAccessMethod={props.quote.aEndAccessMethod}
        bEndAccessMethod={props.quote.bEndAccessMethod}
        aEndBandwidth={props.quote.aEndBandwidth}
        bEndBandwidth={props.quote.bEndBandwidth}
        productType={props.productType}
      />
    </>
  );
}
