import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import MultiSelectTable, { RowCell } from '../../../../shared/components/organisms/MultiSelectTable';
import headers from './headers';
import { apiQuoteById } from 'Quotes/Routes';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { selectHistoricalAndAPITablePageSize } from 'Tables/selectors';
import TableNames from '../../../../Tables/types/tableNames';
import { UpdateHeaderCellWidths } from 'shared/utils/updateHeaderCellWidth';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface IQuotesTable {
  paging: IServerSidePaging;
}

const QuotesTable: FC<React.PropsWithChildren<IQuotesTable>> = (props) => {
  const navigate = useNavigate();

  return <QuotesTableInternal {...props} navigate={navigate} />;
};

export const QuotesTableInternal: FC<React.PropsWithChildren<IQuotesTable & Navigation>> = ({ paging, navigate }) => {
  const defaultPageSize = useSelector(selectHistoricalAndAPITablePageSize);

  const rowClickHandler = (cell: RowCell) => {
    const quoteId = cell.row.original.id;
    navigate(apiQuoteById(quoteId));
  };

  return (
    <MultiSelectTable
      headers={UpdateHeaderCellWidths(headers, TableNames.singleQuotesTable)}
      paging={paging}
      isLoading={paging.isLoading}
      rows={paging.results}
      className="api-quotes-table"
      rowClickHandler={rowClickHandler}
      defaultSort={{
        id: 'date_completed',
        desc: true,
      }}
      defaultPageSize={defaultPageSize}
      storeTableName={TableNames.historicalAndAPITable}
    />
  );
};

export default QuotesTable;
