import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';

import Column from '../../../../shared/components/atoms/Column';
import TextInput from '../../../../shared/components/atoms/TextInput';
import Button, { ButtonMainStyle } from '../../../../shared/components/atoms/Button';
import { editUserState, updateUserAction } from 'Admin/actions';
import { RequestState } from 'Admin/types';
import Alert, { Alerts } from '../../../../shared/components/atoms/Alert';
import { IAppState } from 'reducers';
import { IUpdateUserPayload } from 'Admin/types/actions';
import { CompanyData, IUserAttributesBaseBackendRoles } from 'User/types/user';
import SectionHeader from '../../../../shared/components/atoms/SectionHeader';
import PhoneInput from '../../../../shared/components/atoms/PhoneInput';
import CompaniesAndRoles from 'Admin/shared/components/CompaniesAndRoles/CompaniesAndRoles';
import { IRow } from 'Admin/shared/components/CompaniesRolesTable';
import { isSSEUserFromBackendRoles } from 'Admin/utils/companyRolesRows';
import { userList } from 'Admin/routes';
import SwitchCustomerModal from 'shared/components/organisms/SwitchCustomerModal';
import { BackendRoleName, backendRoleNameToRoleMapping } from 'User/types/role';

interface IEditUserDetails {
  companyData: CompanyData;
  userData: IUserAttributesBaseBackendRoles;
  className?: string;
  updateUserRequestState: RequestState;
  editMode?: boolean;
  updateUser: (data: IUpdateUserPayload) => void;
  editUser(): void;
}

const inputIdentifierPrefix = 'updateUser__';

export const EditUserDetails: FunctionComponent<React.PropsWithChildren<IEditUserDetails>> = ({
  className,
  companyData,
  userData,
  updateUser,
  updateUserRequestState,
  editUser,
  editMode,
}) => {
  const [triedToSave, setTriedToSave] = useState<boolean>(false);
  const [user, setUser] = useState<IUserAttributesBaseBackendRoles>({
    ...userData,
  });
  const [companiesRolesRows, setCompaniesRolesRows] = useState<IRow[]>([]);
  const [wildcardRoles, setWildcardRoles] = useState<BackendRoleName[]>([]);
  const [validRoleSelections, setValidRoleSelections] = useState<boolean>(false);

  const firstNameInput = useRef<HTMLInputElement>(null);
  const lastNameInput = useRef<HTMLInputElement>(null);
  const mobilePhoneInput = useRef<HTMLInputElement>(null);
  const companyEmailInput = useRef<HTMLInputElement>(null);
  const contactEmailInput = useRef<HTMLInputElement>(null);
  const landlinePhoneInput = useRef<HTMLInputElement>(null);

  const inputRefs = [
    firstNameInput,
    lastNameInput,
    mobilePhoneInput,
    companyEmailInput,
    contactEmailInput,
    landlinePhoneInput,
  ];

  const userWith = (field: keyof IUserAttributesBaseBackendRoles, value: string) => ({
    ...user,
    [field]: value,
  });

  const noop = () => null;

  const validInputs = (): boolean => {
    if (inputRefs.every((ref) => ref.current)) {
      return inputRefs.every((ref) => ref.current!.checkValidity());
    }
    return true;
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTriedToSave(true);

    if (validInputs() && validRoleSelections) {
      if (wildcardRoles.length === 0) {
        updateUser({ userData: user, companiesRolesRows });
      } else {
        updateUser({
          userData: user,
          companiesRolesRows: wildcardRoles.map((role) => ({
            companyName: '',
            companyId: '*',
            readonly: false,
            roles: [
              {
                enabled: true,
                role: backendRoleNameToRoleMapping[role],
              },
            ],
          })),
        });
      }
    } else {
      companyEmailInput.current!.scrollIntoView();
    }
  };

  useEffect(() => {
    editUser();
  }, [editUser]);

  return (
    <div className={className}>
      <SectionHeader text="Profile" />
      <form id="admin-save-form" onSubmit={submitForm}>
        <section className={`${triedToSave ? 'show-errors' : ''} pb-3`}>
          <div className="info-prompt">The personal information for this person.</div>
          <div className="form-row">
            <Column defaultWidth={6}>
              <TextInput
                classNames={['col']}
                forwardedRef={companyEmailInput}
                required
                type="email"
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="companyEmail"
                onChange={noop}
                labelText="Username &amp; Email *"
                value={user.companyEmail}
                readOnly
              />
            </Column>
            <Column defaultWidth={6}>
              <TextInput
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="jobTitle"
                onChange={(e) => {
                  setUser(userWith('jobTitle', e.target.value));
                }}
                labelText="Job title/position"
                optional={true}
                value={user.jobTitle}
              />
            </Column>
          </div>
          <div className="form-row">
            <Column defaultWidth={6}>
              <TextInput
                forwardedRef={firstNameInput}
                required
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="firstName"
                onChange={(e) => {
                  setUser(userWith('firstName', e.target.value));
                }}
                labelText="First name *"
                value={user.firstName}
              />
            </Column>
            <Column defaultWidth={6}>
              <TextInput
                forwardedRef={lastNameInput}
                required
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="lastName"
                onChange={(e) => {
                  setUser(userWith('lastName', e.target.value));
                }}
                labelText="Last name *"
                value={user.lastName}
              />
            </Column>
          </div>
          <div className="form-row">
            <Column defaultWidth={6}>
              <PhoneInput
                forwardedRef={mobilePhoneInput}
                required
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="mobilePhone"
                onChange={(e) => {
                  setUser(userWith('mobilePhone', e.target.value));
                }}
                labelText="Mobile phone number *"
                value={user.mobilePhone}
              />
            </Column>
            <Column defaultWidth={6}>
              <PhoneInput
                forwardedRef={landlinePhoneInput}
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="landlinePhone"
                onChange={(e) => {
                  setUser(userWith('landlinePhone', e.target.value));
                }}
                labelText="Landline phone number"
                optional={true}
                value={user.landlinePhone}
              />
            </Column>
          </div>
          <div className="form-row">
            <Column defaultWidth={6}>
              <TextInput
                forwardedRef={contactEmailInput}
                required
                type="email"
                inputIdentifierPrefix={inputIdentifierPrefix}
                fieldName="contactEmail"
                onChange={(e) => {
                  setUser(userWith('contactEmail', e.target.value));
                }}
                labelText="Contact email address *"
                value={user.contactEmail}
              />
            </Column>
          </div>
        </section>

        <CompaniesAndRoles
          companyData={companyData}
          initialData={userData.roles}
          isAdmin={true}
          isSSEUser={isSSEUserFromBackendRoles(userData.roles)}
          onChange={(updatedRows, invalid, wildcards) => {
            setCompaniesRolesRows(updatedRows);
            setValidRoleSelections(!invalid || !!wildcards?.length);
            if (wildcards) setWildcardRoles(wildcards);
          }}
          readonly={updateUserRequestState === RequestState.LOADING}
        />

        <Button
          type="submit"
          id="admin-save-button"
          mainStyle={ButtonMainStyle.PrimaryRectangular}
          disabled={!validRoleSelections || updateUserRequestState === RequestState.LOADING}
        >
          Save profile
        </Button>
        <div className="pt-3 update-user-btn">
          {updateUserRequestState === RequestState.SUCCESS ? (
            <Alert alertType={Alerts.SUCCESS}>The user profile was updated successfully</Alert>
          ) : updateUserRequestState === RequestState.ERROR ? (
            <Alert alertType={Alerts.WARNING}>Error: the user profile may not have been updated</Alert>
          ) : (
            ''
          )}
        </div>
      </form>
      <SwitchCustomerModal selectedCompanyId={companyData.selectedCompanyId} path={userList} editMode={editMode} />
    </div>
  );
};

export const StyledEditUserDetails = styled(EditUserDetails)<IEditUserDetails>`
  .show-errors input:invalid {
    border-color: ${(props) => props.theme.colours.invalidFieldBorderColor ?? 'red'};
  }

  #admin-save-button {
    margin-right: 0;
    margin-left: auto;
    display: block;
  }
`;

const mapStateToProps = (state: IAppState) => ({
  companyData: state.user.companyData,
  updateUserRequestState: state.admin.updateUserRequestState,
  editMode: state.admin.editMode,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  updateUser: (data: IUpdateUserPayload) => dispatch(updateUserAction(data)),
  editUser: () => dispatch(editUserState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledEditUserDetails);
