import { useLocation } from 'react-router-dom';
import { Location } from 'history';

export interface LocationWithState<T> extends Location {
  state: T;
}

export interface CustomLocationState {
  from?: string;
  username?: string;
}

export const useLocationWithState = () => {
  return useLocation() as LocationWithState<CustomLocationState>;
};
