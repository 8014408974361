import styled from 'styled-components';
import { Tabs as ReactTabs, TabList as ReactTabList, Tab as ReactTab, TabPanel as ReactTabPanel } from 'react-tabs';

export const TabList = styled(ReactTabList)`
  list-style-type: none;
  padding: 4px;
  display: flex;
  margin: 0;
`;

export const Tabs = styled(ReactTabs)`
  width: 100%;
`;

export const Tab = styled(ReactTab)`
  padding: 4px;
  margin-right: 10px;
  color: ${(props) => props.theme.colours.grey60};
  font-family: var(--theme-font-family-bold);
  font-size: 1.3em;
  user-select: none;
  cursor: pointer;

  &.is-selected {
    color: ${(props) => props.theme.tabs?.selectedColor ?? props.theme.colours.secondaryC1};
    border-bottom: 4px solid ${(props) => props.theme.tabs?.selectedColor ?? props.theme.colours.secondaryC1};
  }

  &:focus {
    color: ${(props) => props.theme.colours.grey90};
    outline: none;
  }
`;

export const TabPanel = styled(ReactTabPanel)`
  display: none;
  padding-top: 10px;

  &.is-selected {
    display: block;
  }
`;
