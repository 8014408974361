import { presentSupplierName } from 'Quotes/utils/supplierHelpers';
import React, { FunctionComponent } from 'react';
import Column from 'shared/components/atoms/Column';
import { PoP } from '../PoP';
import { useSelector } from 'react-redux';
import { selectQuote, selectSelectedPrice } from 'Quotes/selectors';
import { selectOrderAddress } from 'Order/selectors';

export const P2CCT: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const selectedPrice = useSelector(selectSelectedPrice);
  const quote = useSelector(selectQuote);
  const { A: address } = useSelector(selectOrderAddress);

  return (
    <>
      <div className="row no-gutters mt-3">
        <Column defaultWidth={2}>
          <span className="sub-heading">Location(s)</span>
        </Column>
        <Column defaultWidth={2}>
          <span className="font-weight-bold" data-testid="order-summary-p2cc-cloud-provider">
            {quote.location.aEnd.cloudConnect.name}
          </span>
        </Column>
      </div>
      <div className="row no-gutters mt-3">
        <Column defaultWidth={2} offsetDefaultWidth={2}>
          <span className="font-weight-bold">A-End ({presentSupplierName(selectedPrice.a_end_access_type || '')})</span>
        </Column>
        <Column defaultWidth={8}>
          <PoP
            end="A"
            port={quote.aEndPort}
            pop={selectedPrice.a_end_p_o_p}
            postcode={selectedPrice.a_end_p_o_p_postcode}
            exchangeType={selectedPrice.a_end_exchange_type}
            showExchange={false}
          />
          <address className="mb-0" data-testid="order-summary-p2cc-a-end-address">
            {address}
          </address>
        </Column>
      </div>
    </>
  );
};
