import { INewNNIRequest, ISiteConfig } from 'Order/types/location';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import { OrderLocation } from 'Order/OrderBuilder/QuoteSummary/P2NNI/OrderLocation';
import { OrderBandwidth } from 'Order/OrderBuilder/QuoteSummary/OrderBandwidth';
import React from 'react';

export function SingleNNILocation(props: {
  nniLabel: string;
  nniRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  siteConfig: ISiteConfig;
  quote: IQuote;
  selectedPrice: IPriceData;
  shadowRef: string;
  shadowNNIRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  sseUser: boolean;
  pointAddress: any;
  bandwidth: string;
  bearer: BearerType | undefined;
  productType: ProductType;
  circuit?: string;
}) {
  return (
    <>
      <OrderLocation
        circuit={props.circuit}
        nniLabel={props.nniLabel}
        nniRequest={props.nniRequest}
        siteConfig={props.siteConfig}
        quote={props.quote}
        selectedPrice={props.selectedPrice}
        shadowRef={props.shadowRef}
        shadowNNIRequest={props.shadowNNIRequest}
        sseUser={props.sseUser}
        pointAddress={props.pointAddress}
      />
      <OrderBandwidth
        connectionType={props.quote.connectionType}
        bandwidth={props.bandwidth}
        selectedPrice={props.selectedPrice}
        quote={props.quote}
        bearer={props.bearer}
        aEndAccessMethod={props.quote.aEndAccessMethod}
        bEndAccessMethod={props.quote.bEndAccessMethod}
        aEndBandwidth={props.quote.aEndBandwidth}
        bEndBandwidth={props.quote.bEndBandwidth}
        productType={props.productType}
      />
    </>
  );
}
