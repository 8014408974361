import { GoogleMapsMarkerOptionsWithId } from 'shared/components/organisms/GoogleMapsLocation';
import { IOnNetSite } from 'shared/types/onNetSite';
import { IPAFAddressSite } from 'shared/types/postcodeResults';

export const mapIPAFAddressSiteResponsesToMapMarkers = (
  addresses: IPAFAddressSite[]
): GoogleMapsMarkerOptionsWithId[] => {
  const markers: GoogleMapsMarkerOptionsWithId[] = [];

  addresses.forEach((address) => {
    const marker = mapIPAFAddressSiteResponsesToMapMarker(address);

    if (marker) {
      markers.push(marker);
    }
  });

  return markers;
};

export const mapIPAFAddressSiteResponsesToMapMarker = (address: IPAFAddressSite | IOnNetSite | null | undefined) => {
  if (
    !address ||
    !('latitude' in address.attributes) ||
    !address.attributes?.latitude ||
    !('longitude' in address.attributes) ||
    !address.attributes?.longitude ||
    !('id' in address) ||
    !address?.id
  ) {
    return null;
  }

  return {
    position: {
      lat: Number(address.attributes.latitude),
      lng: Number(address.attributes.longitude),
    },
    id: address.id,
  };
};
