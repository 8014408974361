import { productTypes, ProductType } from '../types/productTypes';

export function findProductTypeLabelForValue(
  type: ProductType | undefined | null,
  ethernet = true
): string | undefined {
  if (type) {
    const foundProductType = productTypes.find((product) => product.value === type);
    if (foundProductType) {
      const label = foundProductType.label;
      return !ethernet ? label.replace('Ethernet ', '') : label;
    }
  }

  return undefined;
}
