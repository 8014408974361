import produce from 'immer';
import { MessageActions, MessageActionsPayload, MessageActionTypes } from './actions';
import { Reducer } from 'redux';
import { MessageResource } from 'shared/types/Messages';

export interface MessagesState {
  messages: MessageResource[];
  sending: {
    inProgress: boolean;
    error: boolean;
  };
}

export const initialState: MessagesState = {
  messages: [],
  sending: {
    inProgress: false,
    error: false,
  },
};

const sendMessage = (draft: MessagesState) => {
  draft.sending.inProgress = true;
  draft.sending.error = false;
};

const sendMessageFail = (draft: MessagesState) => {
  draft.sending.inProgress = false;
  draft.sending.error = true;
};

const sendMessageSuccess = (draft: MessagesState) => {
  draft.sending.inProgress = false;
  draft.sending.error = false;
};

type Reducers = {
  [ActionType in MessageActionTypes]: (draft: MessagesState, payload?: MessageActionsPayload) => void;
};

const reducers: Reducers = {
  [MessageActionTypes.SEND_MESSAGE]: sendMessage,
  [MessageActionTypes.SEND_MESSAGE_FAIL]: sendMessageFail,
  [MessageActionTypes.SEND_MESSAGE_SUCCESS]: sendMessageSuccess,
  [MessageActionTypes.CHECK_FOR_NEW_MESSAGES]: () => undefined,
};

export default produce((draftState: MessagesState = initialState, action: MessageActions) => {
  const { type, payload } = action;
  if (type in MessageActionTypes) {
    const reducerFn = reducers[type];
    reducerFn(draftState, payload);
  }

  return draftState;
}) as Reducer<MessagesState>;
