import { pluralize } from 'shared/utils/pluralize';

export type ContractTerm = '12' | '24' | '36' | '48' | '60';

const contractTermLengths: {
  label: string;
  contractTermInMonths: ContractTerm;
}[] = [
  { label: '1 year', contractTermInMonths: '12' },
  { label: '2 years', contractTermInMonths: '24' },
  { label: '3 years', contractTermInMonths: '36' },
  { label: '4 years', contractTermInMonths: '48' },
  { label: '5 years', contractTermInMonths: '60' },
];

export const monthsToYears = (numberOfMonths: string) => {
  const contractTerm = contractTermLengths.find((element) => {
    return element.contractTermInMonths === numberOfMonths;
  });
  return contractTerm !== undefined ? contractTerm.label : `${numberOfMonths} months`;
};

export const formatYears = (years?: number) => {
  if (!years) return '';

  return `${years} ${pluralize('year', years)}`;
};

export const toId = (label: string) => `contractTermLength--${label.replace(/\s/g, '')}`;

export default contractTermLengths;
