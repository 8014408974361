import { History } from 'history';

import { CustomLocationState } from 'shared/hooks/useLocationWithState';

export enum AuthActionTypes {
  SET_SHOW_LOG_OUT_PROMPT = 'SET_SHOW_LOG_OUT_PROMPT',
  SET_LOG_OUT_USER = 'SET_LOG_OUT_USER',
  LOG_OUT_USER = 'LOG_OUT_USER',
}

export interface ISetShowLogOutPayload {
  showLogOutPrompt: boolean;
}

export interface ISetLogOutUserPayload {
  logOutUser: boolean;
}

export interface ILogOutUserPayload {
  history: History<CustomLocationState>;
  userActionedSignOut?: boolean;
}

export interface ISetShowLogOutPrompt {
  type: AuthActionTypes.SET_SHOW_LOG_OUT_PROMPT;
  payload: ISetShowLogOutPayload;
}

export interface ISetLogOutUser {
  type: AuthActionTypes.SET_LOG_OUT_USER;
  payload: ISetLogOutUserPayload;
}

export interface ILogOutUser {
  type: AuthActionTypes.LOG_OUT_USER;
  payload: ILogOutUserPayload;
}

export type AuthActions = ISetShowLogOutPrompt | ISetLogOutUser | ILogOutUser;
