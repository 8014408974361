import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import { costs, pricing } from '../routes';
import { PricingTab } from './components/PricingTab';
import { CostsTab } from './components/CostsTab';
import { Route, Routes } from 'react-router-dom';
import { NavLinkWithActive } from 'shared/RouterComponents';

type Props = {
  className?: string;
};

export const Pricing: FunctionComponent<React.PropsWithChildren<Props>> = ({ className }) => {
  return (
    <HeaderWithChildrenWrapper header="Pricing &amp; Costs">
      <div className={className}>
        <div className="quotes-list__nav-links">
          <NavLinkWithActive to={pricing} end className="quotes-list__nav-link">
            Pricing
          </NavLinkWithActive>
          <NavLinkWithActive to={costs} className="quotes-list__nav-link">
            Costs
          </NavLinkWithActive>
        </div>
        <Routes>
          <Route path="/costs" element={<CostsTab />} />
          <Route index element={<PricingTab />} />
        </Routes>
      </div>
    </HeaderWithChildrenWrapper>
  );
};

export const StyledPricing = styled(Pricing)<Props>`
  .quotes-list__nav-links {
    display: flex;
    flex-direction: row;
    padding-bottom: 1em;
  }

  .quotes-list__nav-link {
    color: ${(props) => props.theme.colours.grey70};
    display: inline-block;
    font-size: 1.75rem;
    margin: 0 1em;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: ${(props) => props.theme.colours.primaryB1};
      text-decoration: none;
    }

    &.active {
      border-bottom: 3px solid ${(props) => props.theme.colours.primaryB1};
      color: ${(props) => props.theme.colours.primaryB1};
    }
  }
`;

export default StyledPricing;
