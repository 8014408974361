import React, { FunctionComponent, useState } from 'react';
import StyledFormHeader from '../FormHeader';
import Dropdown from 'shared/components/molecules/Dropdown';
import { SiteType as ST } from 'Order/types/location';

type Props = {
  siteType: ST | null;
  setSiteType(siteType: ST | null): void;
};

type SiteTypeDropdownOption = {
  label: string;
  value: ST;
};

const options: Array<SiteTypeDropdownOption> = [
  {
    label: 'Normal site',
    value: ST.NORMAL,
  },
  {
    label: 'Hot site',
    value: ST.HOT,
  },
];

const getDefaultValue = (siteType: ST | null) => {
  if (!siteType) return null;

  if (siteType === ST.HOT) {
    return options[1];
  } else {
    return options[0];
  }
};

export const SiteType: FunctionComponent<React.PropsWithChildren<Props>> = ({ siteType, setSiteType }) => {
  const onChange = (selected: SiteTypeDropdownOption | null) => {
    setSiteType(selected ? selected.value : null);
  };
  const [defaultValue] = useState(getDefaultValue(siteType));

  return (
    <div>
      <StyledFormHeader
        title="Site type"
        tooltip="A hot site is where a lone worker is not permitted to work or where there are stringent risk assessments in place. E.g. Power Station or Electricity Generating Site"
      />
      <div className="w-50">
        <Dropdown onChange={onChange} options={options} placeholder="Select site type" defaultValue={defaultValue} />
        <div className="text-right">
          <small className="text-muted">Optional</small>
        </div>
      </div>
    </div>
  );
};
