import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

const Row: FC<React.PropsWithChildren<HTMLAttributes<HTMLDivElement> & { noGutters?: boolean }>> = (props) => {
  const { noGutters, ...rest } = props;
  return (
    <div
      {...rest}
      className={classNames(`row`, {
        [props.className ?? '']: props.className,
        'no-gutters': noGutters,
      })}
    />
  );
};

export default Row;
