import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import StyledButton from '../Button';

interface ISignOutModal {
  className?: string;
  onClick(): void;
  theme: {
    colours: {
      secondary: string;
    };
  };
}

const SignOutModal: FunctionComponent<React.PropsWithChildren<ISignOutModal>> = ({ onClick, className }) => {
  return (
    <div className={className}>
      Your session has been open for nearly an hour. For security reasons we will log you out unless you select
      continue.
      <StyledButton onClick={onClick}> Continue </StyledButton>
    </div>
  );
};

const StyledSignOutModal = styled(SignOutModal)`
  position: fixed;
  z-index: 2;
  margin-top: 20%;
  background-color: white;
  padding: 2em;
  width: 50%;
  display: grid;
  margin-left: 25%;
  margin-right: 25%;
  text-align: center;
  border: 5px solid ${(props) => props.theme.colours.secondary};
  color: ${(props) => props.theme.colours.secondary};
  border-radius: 0.2em;
  font-size: 2em;

  button {
    height: auto;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
  }
`;

export default StyledSignOutModal;
