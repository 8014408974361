import SpinnerWithText from '../../../../shared/components/molecules/SpinnerWithText';
import React from 'react';
import Spinner from '../../../../shared/components/atoms/Spinner';

export const SavingSpinner = () => <SpinnerWithText text="Saving" size="large" />;
export const CreatingOrderSpinner = () => <SpinnerWithText text="Creating Order" size="large" />;

export const PricingSpinner = ({ pricingWillRetry }: { pricingWillRetry: undefined | boolean }) => (
  <SpinnerWithText
    text={
      pricingWillRetry
        ? "It is taking a bit longer than usual but we're still working on getting your prices"
        : 'Pricing'
    }
    size="large"
  />
);
export const PublishingInProgressSpinner = () => <Spinner size="small" colour="secondary" />;
