import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { IPriceData, CablingType } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { connect, DispatchProp } from 'react-redux';
import { updatePriceQuoteAction, setCablingSuccessStateAction } from 'Quotes/actions';
import IPricedQuote from 'Quotes/types/pricedQuote';
import { IAppState } from 'reducers';
import { setInstallCost, setAnnualCost, setCablingType } from './utils/cabling';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import { CablingTypeSelection, QuoteStatus } from 'Quotes/types/quoteRecordAttributesBase';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import RadioButton from 'shared/components/molecules/RadioButton';
import { IUpdatePriceQuotePayload } from 'Quotes/types/actions';
import { selectCablingTypeSelection, selectIsCablingChoiceMandatory } from 'Quotes/selectors';

interface ICabling {
  className?: string;
  selectedPrice: IPriceData;
  bearer: BearerType | undefined;
  errorUpdating?: boolean;
  successUpdating?: boolean;
  loadingUpdate?: boolean;
  cablingUpdateSuccess?: boolean;
  quoteState?: QuoteStatus;
  quoteId?: string;
  cablingTypeSelection: CablingTypeSelection;
  isCablingChoiceMandatory: ReturnType<typeof selectIsCablingChoiceMandatory>;
  updatePrice(
    quoteId: string,
    priceId: string,
    aEndCablingType: CablingType | null,
    bEndCablingType: CablingType | null,
    updatingCablingOrigin: IUpdatePriceQuotePayload['updatingCablingOrigin']
  ): void;
  clearCablingSuccessState(): void;
}

export const Cabling: FunctionComponent<React.PropsWithChildren<ICabling>> = ({
  className,
  selectedPrice,
  bearer,
  errorUpdating,
  successUpdating,
  loadingUpdate,
  cablingUpdateSuccess,
  clearCablingSuccessState,
  updatePrice,
  quoteState,
  quoteId,
  cablingTypeSelection,
  isCablingChoiceMandatory,
}) => {
  const isCopper = bearer === BearerType.SMALL;
  const isSuccess = successUpdating && !loadingUpdate && cablingUpdateSuccess;
  const hideCablingType = !selectedPrice.a_end_cross_connect_cost && !selectedPrice.b_end_cross_connect_cost;

  useEffect(() => {
    clearCablingSuccessState();
  }, [selectedPrice.id, clearCablingSuccessState]);

  return (
    <div className={className}>
      <div
        className={cx('cabling_wrapper mt-3 pb-4', {
          readonly: quoteState === QuoteStatus.ORDERED,
        })}
      >
        <p className="font-weight-bold text-right cabling-title">
          Cabling choice required for Neos Networks on-net buildings
        </p>
        <div className="text-right mb-4">
          Cross connect cabling charges may apply to your order. Neos Networks will pass through the costs quoted by the
          Data Centre operator with an admin charge added per end as required
        </div>
        {!hideCablingType && (
          <div className="text-right font-weight-bold">
            Cabling type: {isCopper ? 'Copper' : 'Fibre'} is subject to distance limitations
          </div>
        )}

        {isCablingChoiceMandatory.A && (
          <>
            <div className="text-right mt-3">
              <p className="cabling_option_label font-weight-bold">Request cabling for {selectedPrice.a_end_p_o_p}?</p>
            </div>
            <div
              className={cx('h3 mt-3', {
                'text-muted': selectedPrice.a_end_cabling_type === null,
              })}
            >
              {setInstallCost(isCopper, selectedPrice.a_end_cross_connect_cost) > 0 && (
                <div className="cabling__segment-wrapper">
                  <p className="segment_label">Supplier installation charge</p>
                  <p>
                    <b>{currencyFormatter.format(setInstallCost(isCopper, selectedPrice.a_end_cross_connect_cost))}</b>
                  </p>
                </div>
              )}
              {setAnnualCost(isCopper, selectedPrice.a_end_cross_connect_cost) > 0 && (
                <div className="cabling__segment-wrapper">
                  <p className="segment_label">Supplier annual cost</p>
                  <p>
                    <b>{currencyFormatter.format(setAnnualCost(isCopper, selectedPrice.a_end_cross_connect_cost))}</b>
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex flex-row-reverse">
              <RadioButton
                className="cabling_option_radio ml-4 mr-0"
                disabled={quoteState === QuoteStatus.ORDERED || loadingUpdate}
                id="a_end_cabling_no"
                description="No"
                onClick={() => {
                  updatePrice(
                    quoteId!,
                    selectedPrice.id,
                    setCablingType(isCopper, false),
                    setCablingType(isCopper, !!selectedPrice.b_end_cabling_type),
                    'A'
                  );
                }}
                selected={selectedPrice.a_end_cabling_type === null && cablingTypeSelection.a_end_cabling_type_selected}
              />
              <RadioButton
                className="cabling_option_radio"
                disabled={quoteState === QuoteStatus.ORDERED || loadingUpdate}
                id="a_end_cabling_yes"
                description="Yes"
                onClick={() => {
                  updatePrice(
                    quoteId!,
                    selectedPrice.id,
                    setCablingType(isCopper, true),
                    setCablingType(isCopper, !!selectedPrice.b_end_cabling_type),
                    'A'
                  );
                }}
                selected={selectedPrice.a_end_cabling_type !== null}
              />
            </div>
            {(selectedPrice.a_end_cabling_type === null && cablingTypeSelection.a_end_cabling_type_selected) && 
                <div className='text-right mb-4'>
                  Neos Networks will provide the rack information where the customer is providing the cross connect.
                </div>
              }
          </>
        )}

        {isCablingChoiceMandatory.B && (
          <>
            <div className="text-right mt-3 d-flex justify-content-end">
              <p className="cabling_option_label font-weight-bold">Request cabling for {selectedPrice.b_end_p_o_p}?</p>
            </div>
            <div
              className={cx('h3 mt-3', {
                'text-muted': selectedPrice.b_end_cabling_type === null,
              })}
            >
              {setInstallCost(isCopper, selectedPrice.b_end_cross_connect_cost) > 0 && (
                <div className="cabling__segment-wrapper">
                  <p className="segment_label">Supplier installation charge</p>
                  <p>
                    <b>{currencyFormatter.format(setInstallCost(isCopper, selectedPrice.b_end_cross_connect_cost))}</b>
                  </p>
                </div>
              )}
              {setAnnualCost(isCopper, selectedPrice.b_end_cross_connect_cost) > 0 && (
                <div className="cabling__segment-wrapper">
                  <p className="segment_label">Supplier annual cost</p>
                  <p>
                    <b>{currencyFormatter.format(setAnnualCost(isCopper, selectedPrice.b_end_cross_connect_cost))}</b>
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex flex-row-reverse">
              <RadioButton
                className="cabling_option_radio ml-4 mr-0"
                disabled={quoteState === QuoteStatus.ORDERED || loadingUpdate}
                id="b_end_cabling_no"
                description="No"
                onClick={() => {
                  updatePrice(
                    quoteId!,
                    selectedPrice.id,
                    setCablingType(isCopper, !!selectedPrice.a_end_cabling_type),
                    setCablingType(isCopper, false),
                    'B'
                  );
                }}
                selected={selectedPrice.b_end_cabling_type === null && cablingTypeSelection.b_end_cabling_type_selected}
              />
              <RadioButton
                className="cabling_option_radio"
                disabled={quoteState === QuoteStatus.ORDERED || loadingUpdate}
                id="b_end_cabling_yes"
                description="Yes"
                onClick={() => {
                  updatePrice(
                    quoteId!,
                    selectedPrice.id,
                    setCablingType(isCopper, !!selectedPrice.a_end_cabling_type),
                    setCablingType(isCopper, true),
                    'B'
                  );
                }}
                selected={selectedPrice.b_end_cabling_type !== null}
              />
            </div>
            {(selectedPrice.b_end_cabling_type === null && cablingTypeSelection.b_end_cabling_type_selected) && 
                <div className='text-right mb-4'>
                  Neos Networks will provide the rack information where the customer is providing the cross connect.
                </div>
              }
          </>
        )}

        {loadingUpdate && (
          <div className="spinner-wrapper">
            <Spinner text="Updating..." size="xlarge" />
          </div>
        )}

        {quoteState !== QuoteStatus.ORDERED && (
          <div className="pt-3 update-user-btn">
            {isSuccess ? (
              <Alert alertType={Alerts.SUCCESS}>Cabling configuration updated successfully</Alert>
            ) : errorUpdating ? (
              <Alert alertType={Alerts.WARNING}>Error: cabling configuration may not have been updated</Alert>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styledCabling = styled(Cabling)`
  .readonly {
    opacity: 0.7;
  }

  .cabling-title {
    color: ${(props) => props.theme.colours.primaryB1};
  }

  .cabling_option_label {
    color: ${(props) => props.theme.colours.secondary};
  }

  .cabling_option_radio {
    width: 210px;
  }

  .cabling_wrapper {
    width: 48%;
    margin-left: auto;
    position: relative;
  }

  .spinner-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
  }

  .cabling__segment-wrapper {
    display: flex;
    .segment_label {
      width: 100%;
    }

    p {
      font-size: 20px;
    }
  }
`;

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  updatePrice: (
    quoteId: string,
    priceId: string,
    aEndCablingType: CablingType | null,
    bEndCablingType: CablingType | null,
    updatingCablingOrigin: IUpdatePriceQuotePayload['updatingCablingOrigin']
  ) =>
    dispatch(
      updatePriceQuoteAction(
        priceId,
        {
          a_end_cabling_type: aEndCablingType,
          b_end_cabling_type: bEndCablingType,
        } as IPricedQuote,
        true,
        quoteId,
        false,
        updatingCablingOrigin
      )
    ),
  clearCablingSuccessState: () => dispatch(setCablingSuccessStateAction(false)),
});

const mapStateToProps = (state: IAppState) => ({
  successUpdating: state.quoteBuilder.pricing.pricingProgress.updatingSuccess,
  errorUpdating: state.quoteBuilder.pricing.pricingProgress.error,
  loadingUpdate: state.quoteBuilder.pricing.pricingProgress.loadingUpdate,
  quoteId: state.quoteBuilder.currentQuoteId,
  cablingUpdateSuccess: state.quoteBuilder.pricing.pricingProgress.cablingSuccess,
  quoteMeta: state.quoteBuilder.quoteEndpointMeta,
  cablingTypeSelection: selectCablingTypeSelection(state),
  selectedPrice: state.quoteBuilder.pricing.selectedPrice,
  quoteState: state.quoteBuilder.state,
  bearer: state.quoteBuilder.quote.bearer,
  isCablingChoiceMandatory: selectIsCablingChoiceMandatory(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(styledCabling);
