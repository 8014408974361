import React from 'react';
import { AlertWithAction } from 'shared/components/molecules/SecondaryCircuits/AlertWithAction';

const LOAD_PRICES_TEXT = `Click 'Load Prices' to check availability and pricing.`;

export const ShowLoadPrices = ({ isReadOnly = false, onClick }: { isReadOnly?: boolean; onClick?: () => void }) => {
  return (
    <AlertWithAction
      labelText={LOAD_PRICES_TEXT}
      buttonText="Load Prices"
      id="load-prices"
      onClick={onClick}
      isReadOnly={isReadOnly}
    />
  );
};
