import React, { FunctionComponent } from 'react';

import { INNIRecord, NNIDataCentreFromBE, NNISelectionType } from '../types';
import Dropdown, { IDropdownOption } from '../../../shared/components/molecules/Dropdown';
import { dataCentreReference, sortDataCentresByMaxBandwidth } from '../utils';
import { getNNILabel } from '../../../shared/utils/addresses/helperFunctions';
import { ResourceTypes } from 'Request';

const maxBandwidthFilter = (item: NNIDataCentreFromBE) => parseInt(item.attributes.max_bandwidth, 10) >= 1000;

export interface NNIOption extends IDropdownOption {
  type: NNISelectionType;
  postcode: string;
  nniReference: string;
  dataCentreReference: string;
}

interface INNISelectProps {
  className?: string;
  label?: string;
  nniExistingAgreements: INNIRecord[];
  nniCapableDataCentres: NNIDataCentreFromBE[];
  selectedId?: string;
  selectedType?: NNISelectionType;
  onChange(selection: NNIOption): void;
  isReadOnly?: boolean;
}

const NNISelect: FunctionComponent<React.PropsWithChildren<INNISelectProps>> = ({
  className,
  label = 'Select your NNI',
  nniExistingAgreements,
  nniCapableDataCentres,
  onChange,
  selectedId,
  selectedType,
  isReadOnly = false,
}) => {
  const groupedOptions = [
    {
      label: 'Choose an existing NNI',
      options: nniExistingAgreements
        .map(
          (nni): NNIOption => ({
            label: getNNILabel(nni),
            postcode: nni.attributes.pop_postcode,
            value: nni.id,
            nniReference: nni.attributes.reference,
            dataCentreReference: nni.attributes.pop_name,
            type: ResourceTypes.nni,
          })
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      label: 'Select a data centre for a new NNI',
      options: sortDataCentresByMaxBandwidth(nniCapableDataCentres)
        .filter(maxBandwidthFilter)
        .map(
          (dc): NNIOption => ({
            label: dataCentreReference(dc),
            postcode: dc.attributes.postcode,
            nniReference: '',
            dataCentreReference: dataCentreReference(dc),
            value: dc.id,
            type: ResourceTypes.data_centre,
          })
        ),
    },
  ];

  let defaultValue;
  const match = selectedId
    ? groupedOptions[selectedType === 'nni' ? 0 : 1].options.find((nni) => nni.value === selectedId)
    : false;

  if (match) {
    defaultValue = {
      label: match.label,
      value: match.value,
    };
  }

  return (
    <div className={className} data-testid="nni_dropdown">
      <h4>{label}</h4>
      <Dropdown
        isDisabled={isReadOnly}
        defaultValue={defaultValue}
        placeholder="Search for NNI and data centre"
        onChange={onChange}
        options={groupedOptions}
        className="dropdown"
      />
    </div>
  );
};

export default NNISelect;
