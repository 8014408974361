import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ProductType } from 'Quotes/types/productTypes';
import { selectSelectedPriceAnnualCost } from 'Quotes/selectors';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { Charges } from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/TillReceipt';
import { TCVMarginAndValuePanel } from './TCVMarginAndValuePanel';
import { CostsInternalAll } from './CostsInternalAll';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { DIAConfig } from 'Quotes/QuoteBuilder/components/Price/components/TillReceipt/Totals';
import { DualCircuitRouterConfigurations } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';

const MultiCircuitPanel = styled.div`
  background: ${(props) => props.theme.colours.secondaryC5};
  border-radius: 10px;
  padding: 15px;
`;

type Props = {
  selectedPrice: IPriceData;
  secondarySelectedPrice?: IPriceData;
  charges: Charges;
  secondaryCharges?: Charges;
  className?: string;
  isFTTXQuote: boolean;
  productType: ProductType | null | undefined;
  selectedPriceAnnualCost: ReturnType<typeof selectSelectedPriceAnnualCost>;
  secondarySelectedPriceAnnualCost?: ReturnType<typeof selectSelectedPriceAnnualCost>;
  diaConfig: DIAConfig;
  quote: IQuote;
};

export interface IMDIACosts {
  engineerInstallation: number;
  rackMountKit: number;
  router: number;
}

// These values are hardcoded due to the BE setting these values
// https://github.com/SSEDigitalFoundation/sse-api/pull/2156
export const MDIA_ENGINEER_COST = 386.58;
export const MDIA_RACK_MOUNT_KIT_COST = 82.0;
export const MDIA_ENGINEER_CHARGE = 480.0;
export const MDIA_RACK_MOUNT_KIT_CHARGE = 102.5;

function calculateMDIACosts(
  diaConfig: DIAConfig,
  selectedPrice: IPriceData,
  secondarySelectedPrice: IPriceData | undefined,
  quote: IQuote
) {
  const options = quote.location.aEnd.secondRouterOptions;
  const engineerInstallationRequired = options?.engineerInstallationRequired;
  const rackMountKitRequired = options?.rackMountKitRequired;

  const primaryMDIACosts: IMDIACosts | undefined = diaConfig.isManagedDIA
    ? {
        engineerInstallation: selectedPrice.mdia_engineer_cost ?? 0,
        router: selectedPrice.mdia_router_cost ?? selectedPrice.mdia_install_cost ?? 0,
        rackMountKit: selectedPrice.mdia_rack_mount_kit_cost ?? 0,
      }
    : undefined;
  const secondaryMDIACosts: IMDIACosts | undefined =
    secondarySelectedPrice &&
    diaConfig.isManagedDIA &&
    diaConfig.routerChoice !== DualCircuitRouterConfigurations.SINGLE
      ? {
          engineerInstallation: engineerInstallationRequired ? MDIA_ENGINEER_COST : 0,
          // The secondary router cost is the same as the primary router cost
          router: selectedPrice.mdia_router_cost ?? selectedPrice.mdia_install_cost ?? 0,
          rackMountKit: rackMountKitRequired ? MDIA_RACK_MOUNT_KIT_COST : 0,
        }
      : undefined;
  return { primaryMDIACosts, secondaryMDIACosts };
}

export const TillReceiptInternal: FunctionComponent<React.PropsWithChildren<Props>> = ({
  selectedPrice,
  secondarySelectedPrice,
  charges,
  secondaryCharges,
  className,
  selectedPriceAnnualCost,
  secondarySelectedPriceAnnualCost,
  isFTTXQuote,
  productType,
  diaConfig,
  quote,
}) => {
  const { primaryMDIACosts, secondaryMDIACosts } = calculateMDIACosts(
    diaConfig,
    selectedPrice,
    secondarySelectedPrice,
    quote
  );

  const singleRouterChoice = diaConfig?.routerChoice === DualCircuitRouterConfigurations.SINGLE;
  const primaryMdiaAnnualServiceCost = selectedPrice.mdia_annual_cost ?? 0;
  const secondaryMdiaAnnualServiceCost = !singleRouterChoice ? selectedPrice.mdia_annual_cost ?? 0 : 0;

  return (
    <div className={`${className} border-bottom mb-4 pb-4`}>
      <TCVMarginAndValuePanel charges={charges} secondaryCharges={secondaryCharges} />
      <h4 className="annual-suppliers-prompt mb-3">Costs (Neos Networks)</h4>
      {secondarySelectedPrice &&
      secondarySelectedPriceAnnualCost &&
      featureFlag.isEnabled(Feature.SecondaryCircuits) ? (
        <>
          <MultiCircuitPanel>
            <CostsInternalAll
              selectedPrice={selectedPrice}
              charges={charges}
              isFTTXQuote={isFTTXQuote}
              productType={productType}
              selectedPriceAnnualCost={selectedPriceAnnualCost}
              isManagedDIA={diaConfig.isManagedDIA}
              mdiaServiceCost={primaryMdiaAnnualServiceCost}
              mdiaCosts={primaryMDIACosts}
              label="Primary"
            />
          </MultiCircuitPanel>

          <br />
          <MultiCircuitPanel>
            <CostsInternalAll
              selectedPrice={secondarySelectedPrice}
              charges={secondaryCharges}
              isFTTXQuote={isFTTXQuote}
              productType={productType}
              selectedPriceAnnualCost={secondarySelectedPriceAnnualCost}
              isManagedDIA={diaConfig.isManagedDIA}
              mdiaServiceCost={secondaryMdiaAnnualServiceCost}
              mdiaCosts={secondaryMDIACosts}
              label="Secondary"
            />
          </MultiCircuitPanel>
        </>
      ) : (
        <CostsInternalAll
          selectedPrice={selectedPrice}
          charges={charges}
          isFTTXQuote={isFTTXQuote}
          productType={productType}
          selectedPriceAnnualCost={selectedPriceAnnualCost}
          isManagedDIA={diaConfig.isManagedDIA}
          mdiaCosts={primaryMDIACosts}
          mdiaServiceCost={primaryMdiaAnnualServiceCost}
        />
      )}
    </div>
  );
};

export default styled(TillReceiptInternal)`
  .tcv-title {
    color: ${(props) => props.theme.colours.grey70};
  }

  .tcv-figure {
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    color: ${(props) => props.theme.colours.primaryA1};
  }

  .currency {
    letter-spacing: 0.05rem;
  }
`;
