import React, { FC, FunctionComponent, useEffect, useState } from 'react';
import { ConnectorType, ISecondarySiteConfig, ISiteConfig, MediaType, PowerType } from 'Order/types/location';
import RadioButton, { RadioButtonDisabledOverride } from 'shared/components/molecules/RadioButton';
import TextInput from 'shared/components/atoms/TextInput';
import FormHeader from '../FormHeader';
import ConnectorTypeOptions from './ConnectorTypeOptions';
import MediaTypeOptions, { useMediaTypes } from './MediaTypeOptions';
import { CablingType, IPriceData, IQuote } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import VLANConfig from '../VLANConfiguration';
import { getDIAOrderOptions } from './DIAOrderOptions';
import { Feature } from 'FeatureFlags/types';
import { StackedOptions } from '../utils/StackedOptions';
import Column from 'shared/components/atoms/Column';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import Dropdown from 'shared/components/molecules/Dropdown';
import { Supplier } from 'Quotes/types/supplier';

const title = `Provide specific location details. 
Please note, we are unable to accept demarcation details for Floor, Room and Rack ID as 'N/A' or 'TBC'.
Please ensure these details are completed before submitting your order, to avoid rejection.`;

interface ISiteConfigProps {
  quote: IQuote;
  identifierPrefix: string;
  isFTTXQuote: boolean;
  isSupplierNNAT: boolean;
  siteConfig: ISiteConfig | ISecondarySiteConfig;
  endPort: BearerType;
  selectedPrice: IPriceData;
  updateSiteConfig(updated: ISiteConfig | ISecondarySiteConfig): void;
}

const MediaTypeInfoMessage: FC<{
  identifierPrefix: string;
  endPort: BearerType;
  isSupplierNNAT: boolean;
  siteConfig: ISiteConfig;
}> = ({ identifierPrefix, endPort, isSupplierNNAT, siteConfig }) => {
  const { showLR, showLX, showSR, showSX } = useMediaTypes(identifierPrefix, endPort, isSupplierNNAT, siteConfig, undefined);
  const displayMessage = (showLR || showLX) && (showSR || showSX);

  if (!displayMessage) return null;
  return (
    <p style={{ fontStyle: 'italic' }}>
      If ordering to an Equinix Data Centre, effective 31st March 2024, Equinix will only supply Single Mode Fibre cross
      connects. Please ensure you select the Single Mode option to avoid delays with placing your order.
    </p>
  );
};

type DropdownOption = { label: string; value: string; };

const FloorOptions: Array<DropdownOption> = [
  { label: 'Select an option', value: '', },
  { label: '001 - 1st Floor', value: '001 - 1st Floor', },
  { label: '002 - 2nd Floor', value: '002 - 2nd Floor', },
  { label: '003 - 3rd Floor', value: '003 - 3rd Floor', },
  { label: '004 - 4th Floor', value: '004 - 4th Floor', },
  { label: '005 - 5th Floor', value: '005 - 5th Floor', },
  { label: '006 - 6th Floor', value: '006 - 6th Floor', },
  { label: '007 - 7th Floor', value: '007 - 7th Floor', },
  { label: '008 - 8th Floor', value: '008 - 8th Floor', },
  { label: '009 - 9th Floor', value: '009 - 9th Floor', },
  { label: '010 - 10th Floor', value: '010 - 10th Floor', },
  { label: '011 - 11th Floor', value: '011 - 11th Floor', },
  { label: '012 - 12th Floor', value: '012 - 12th Floor', },
  { label: '013 - 13th Floor', value: '013 - 13th Floor', },
  { label: '014 - 14th Floor', value: '014 - 14th Floor', },
  { label: 'BAS - Basement', value: 'BAS - Basement', },
  { label: 'GND - Ground Floor', value: 'GND - Ground Floor', },
  { label: 'LGF - Lower Ground Floor', value: 'LGF - Lower Ground Floor', },
  { label: 'MEZ - Mezzanine', value: 'MEZ - Mezzanine', },
  { label: 'RFL - Roof floor', value: 'RFL - Roof floor', },
  { label: 'Top - Top floor', value: 'Top - Top floor', },
  { label: 'Others', value: 'Others', },
];

const RoomOptions: Array<DropdownOption> = [
  { label: 'Select an option', value: '', },
  { label: 'ADMN - Admin Room', value: 'ADMN - Admin Room', },
  { label: 'CNTL - Control Room', value: 'CNTL - Control Room', },
  { label: 'COMP - Computer Room', value: 'COMP - Computer Room', },
  { label: 'DATA - Data Room', value: 'DATA - Data Room', },
  { label: 'ELEC - Electric Room', value: 'ELEC - Electric Room', },
  { label: 'EQP - Equipment Room', value: 'EQP - Equipment Room', },
  { label: 'FRME - Frame Room', value: 'FRME - Frame Room', },
  { label: 'ICT - ICT Room', value: 'ICT - ICT Room', },
  { label: 'INTK - Intake Room', value: 'INTK - Intake Room', },
  { label: 'IT - IT Room', value: 'IT - IT Room', },
  { label: 'MNGR - Manager Room', value: 'MNGR - Manager Room', },
  { label: 'OFF - Office', value: 'OFF - Office', },
  { label: 'PABX - PABX Room', value: 'PABX - PABX Room', },
  { label: 'PBX - PBX Room', value: 'PBX - PBX Room', },
  { label: 'PLNT - Plant Room', value: 'PLNT - Plant Room', },
  { label: 'STOR - Store / Storage Room', value: 'STOR - Store / Storage Room', },
  { label: 'SVR - Server Room', value: 'SVR - Server Room', },
  { label: 'SWTC - Switch Room', value: 'SWTC - Switch Room', },
  { label: 'Others', value: 'Others', },
];

const SiteConfigInformation: FunctionComponent<React.PropsWithChildren<ISiteConfigProps>> = ({
  quote,
  identifierPrefix,
  isFTTXQuote,
  isSupplierNNAT,
  updateSiteConfig,
  siteConfig,
  endPort,
  selectedPrice,
}) => {
  const onChange = (field: keyof ISiteConfig, value: any): void => {
    const payload: ISiteConfig = {
      ...siteConfig,
      [field]: value,
    };

    if (field === 'mediaType' && value === MediaType.TX && siteConfig.connectorType !== ConnectorType.RJ45) {
      payload.connectorType = ConnectorType.RJ45;
    } else if (field === 'mediaType' && value !== MediaType.TX && siteConfig.connectorType === ConnectorType.RJ45) {
      payload.connectorType = ConnectorType.LC;
    }

    if (field === 'connectorType' && value === ConnectorType.RJ45) {
      payload.mediaType = MediaType.TX;
    } else if (field === 'connectorType' && value !== ConnectorType.RJ45 && siteConfig.connectorType === ConnectorType.RJ45) {
      payload.mediaType = null;
    }

    updateSiteConfig(payload);
  };

  useEffect(() => {
    if (isFTTXQuote && !siteConfig.autoNegotiation) {
      onChange('autoNegotiation', true);
    } else if (siteConfig.autoNegotiation === undefined) {
      onChange('autoNegotiation', endPort !== BearerType.LARGE);
    }
  }, []);

  const isManagedDIA = quote.location.aEnd.is_managed_dia;

  let forceAutoNegotiationYes: boolean;
  let forceAutoNegotiationNo: boolean;
  let removeAutoNegotiationSection = false;
  let allowVLAN: boolean;
  let allowPowerSupply = true;

  const isVLANAllowed = quote.productType !== ProductType.P2P;

  let opticalCabling = "";
  if (quote.productType === ProductType.OpticalP2P) {
    if ((identifierPrefix === "aEndLocation" ? selectedPrice.a_end_cabling_type : selectedPrice.b_end_cabling_type) === CablingType.Fibre) {
      opticalCabling = "yes";
    } else {
      opticalCabling = "no";
    }
  }

  const isOpenreach = (identifierPrefix === "aEndLocation" ? selectedPrice.a_end_access_type : selectedPrice.b_end_access_type) === Supplier.OPENREACH;
  // if (siteConfig.floor && !FloorOptions.find(o => o.value === siteConfig.floor)) {
  //   FloorOptions.push({ label: siteConfig.floor, value: siteConfig.floor });
  // }
  // if (siteConfig.room && !RoomOptions.find(o => o.value === siteConfig.room)) {
  //   RoomOptions.push({ label: siteConfig.room, value: siteConfig.room });
  // }

  // MDIA 1.2 logic
  if (featureFlag.isEnabled(Feature.newMDIAUI) && quote.productType === ProductType.DIA) {
    const {
      autoNegotiation: { isYesMandatory, isNoMandatory, removeSection },
      vlanTagging: { isAvailable },
    } = getDIAOrderOptions(isManagedDIA, endPort, isSupplierNNAT);

    forceAutoNegotiationYes = isYesMandatory;
    forceAutoNegotiationNo = isNoMandatory;
    removeAutoNegotiationSection = removeSection;
    allowVLAN = isAvailable && isVLANAllowed;
  }
  // classic logic
  else {
    forceAutoNegotiationNo = endPort === BearerType.LARGE;
    forceAutoNegotiationYes = isFTTXQuote || isManagedDIA;
    allowVLAN = isVLANAllowed;

    if (quote.productType === ProductType.OpticalP2P) {
      removeAutoNegotiationSection = true;
      allowVLAN = false;
      allowPowerSupply = false;
    }
  }

  const [showFloorTextInput, setShowFloorTextInput] = useState(false);
  const [customFloor, setCustomFloor] = useState(siteConfig.floor);
  const handleFloorDropdownChange = (selection: DropdownOption) => {
    if (selection && selection.value === 'Others') {
      setShowFloorTextInput(true);
      setCustomFloor('');
    } else {
      setShowFloorTextInput(false);
      onChange('floor', selection ? selection.value : '');
    }
  };

  const handleFloorTextInputChange = (e: any) => {
    const value = e.target.value;
    setCustomFloor(value);
    onChange('floor', value);
  };

  useEffect(() => {
    // Check if siteConfig.floor matches any option in FloorOptions
    const isCustomFloor = !FloorOptions.some(option => option.value === siteConfig.floor);
    setShowFloorTextInput(isCustomFloor);
    setCustomFloor(siteConfig.floor);
  }, [siteConfig.floor]);

  const [showRoomTextInput, setShowRoomTextInput] = useState(false);
  const [customRoom, setCustomRoom] = useState(siteConfig.room ?? "");
  const handleRoomDropdownChange = (selection: DropdownOption) => {
    if (selection && selection.value === 'Others') {
      setShowRoomTextInput(true);
      setCustomRoom('');
    } else {
      setShowRoomTextInput(false);
      onChange('room', selection ? selection.value : '');
    }
  };

  const handleRoomTextInputChange = (e: any) => {
    const value = e.target.value;
    setCustomRoom(value);
    onChange('room', value);
  };

  useEffect(() => {
    // Check if siteConfig.room matches any option in RoomOptions
    const isCustomRoom = !RoomOptions.some(option => option.value === (siteConfig.room ?? ""));
    setShowRoomTextInput(isCustomRoom);
    setCustomRoom(siteConfig.room ?? "");
  }, [siteConfig.room]);


  return (
    <>
      <FormHeader title={title} />

      <div className="row">
        <Column defaultWidth={6}>
          {isOpenreach &&
            <>
              <label className="small mb-1">Floor *</label>
              <Dropdown
                className="coords-dropdown"
                onChange={handleFloorDropdownChange}
                options={FloorOptions}
                //allowCreateNewOption={false}
                // onCreateOption={(newValue: any) => {
                //     //FloorOptions.push({ label: newValue, value: newValue });
                //     onChange('floor', newValue);
                //   }
                // }
                defaultValue={FloorOptions.find(o => o.value === siteConfig.floor) || FloorOptions.find(o => o.value === "Others")}
                isDisabled={opticalCabling === "no"}
              />
            </>
          }
          {(!isOpenreach || showFloorTextInput) &&
            <TextInput
              inputIdentifierPrefix={identifierPrefix}
              fieldName="_floor"
              maxLength={50}
              onChange={handleFloorTextInputChange}
              labelText={isOpenreach ? "" : "Floor *"}
              value={customFloor}
              readOnly={opticalCabling === "no"}
              placeholder={isOpenreach ? "Please specify the Floor name" : ""}
            />
          }
        </Column>

        <Column defaultWidth={6}>
          <TextInput
            inputIdentifierPrefix={identifierPrefix}
            fieldName="_suite"
            onChange={(e) => onChange('suite', e.target.value)}
            maxLength={50}
            labelText="Suite"
            value={siteConfig.suite}
            optional={true}
            readOnly={opticalCabling === "no"}
          />
        </Column>
      </div>

      <div className="row mb-2">
        <Column defaultWidth={6}>
          {isOpenreach &&
            <>
              <label className="small mb-1">Room *</label>
              <Dropdown
                className="coords-dropdown"
                onChange={handleRoomDropdownChange}
                options={RoomOptions}
                //allowCreateNewOption={false}
                // onCreateOption={(newValue: any) => {
                //     //RoomOptions.push({ label: newValue, value: newValue });
                //     onChange('room', newValue);
                //   }
                // }
                defaultValue={RoomOptions.find(o => o.value === (siteConfig.room ?? "")) || RoomOptions.find(o => o.value === "Others")}
                isDisabled={opticalCabling === "no"}
              />
            </>
          }
          {(!isOpenreach || showRoomTextInput) &&
            <TextInput
              inputIdentifierPrefix={identifierPrefix}
              fieldName="_room"
              maxLength={50}
              onChange={handleRoomTextInputChange}
              labelText={isOpenreach ? "" : "Room *"}
              value={customRoom}
              readOnly={opticalCabling === "no"}
              placeholder={isOpenreach ? "Please specify the Room name" : ""}
            />
          }
        </Column>

        <Column defaultWidth={6}>
          <TextInput
            inputIdentifierPrefix={identifierPrefix}
            fieldName="_rackId"
            onChange={(e) => onChange('rackId', e.target.value)}
            labelText="Rack ID *"
            maxLength={50}
            value={siteConfig.rackId || ''}
            readOnly={opticalCabling === "no"}
          />
        </Column>
      </div>

      {opticalCabling === "yes" &&
        <div className="row mb-2">
          <Column defaultWidth={12}>
            Cabling has been requested, Neos Networks will provide the rack information where the customer is providing the cross connect.
          </Column>
        </div>}

      {!isFTTXQuote && (
        <>
          <div className="row mb-2">
            <Column defaultWidth={6}>
              <FormHeader title="Connector type *" />

              <ConnectorTypeOptions
                identifierPrefix={identifierPrefix}
                siteConfig={siteConfig}
                onChange={onChange}
                endPort={endPort}
                isSupplierNNAT={isSupplierNNAT}
                accessType={identifierPrefix === 'aEndLocation' ? selectedPrice.a_end_access_type : selectedPrice.b_end_access_type}
              />
            </Column>

            <Column defaultWidth={6}>
              <FormHeader title={quote.productType === ProductType.OpticalP2P ? 'Interface type *' : 'Media type *'} />

              <MediaTypeOptions
                identifierPrefix={identifierPrefix}
                endPort={endPort}
                siteConfig={siteConfig}
                onChange={onChange}
                isSupplierNNAT={isSupplierNNAT}
                accessType={identifierPrefix === 'aEndLocation' ? selectedPrice.a_end_access_type : selectedPrice.b_end_access_type}
              />
            </Column>
          </div>
          <div className="row mb-2">
            {allowPowerSupply && (<Column defaultWidth={6}>
              <FormHeader title="Power supply? *" />

              <StackedOptions>
                <RadioButtonDisabledOverride>
                  <RadioButton
                    id={`${identifierPrefix}_powerType_AC`}
                    description="AC"
                    onClick={() => onChange('powerType', PowerType.AC)}
                    selected={siteConfig.powerType === PowerType.AC}
                    disabled={isManagedDIA}
                  />
                </RadioButtonDisabledOverride>

                {!isManagedDIA && !isSupplierNNAT && (
                  <RadioButton
                    id={`${identifierPrefix}_powerType_DC`}
                    description="DC"
                    onClick={() => onChange('powerType', PowerType.DC)}
                    selected={siteConfig.powerType === PowerType.DC}
                  />
                )}
              </StackedOptions>
            </Column>)}
            <Column defaultWidth={6}>
              <MediaTypeInfoMessage
                siteConfig={siteConfig}
                endPort={endPort}
                isSupplierNNAT={isSupplierNNAT}
                identifierPrefix={identifierPrefix}
              />
            </Column>
          </div>
        </>
      )}

      {(!isSupplierNNAT || quote.productType === ProductType.DIA) && (
        <>
          {!removeAutoNegotiationSection && (
            <div className="row mb-2">
              <Column defaultWidth={6}>
                <FormHeader title="Auto Negotiation *" />

                <div className="row">
                  {forceAutoNegotiationNo ? (
                    <Column defaultWidth={12}>
                      <RadioButton id={`${identifierPrefix}_autoNegotiation_no`} description="No" selected={true} />
                    </Column>
                  ) : forceAutoNegotiationYes ? (
                    <Column defaultWidth={12}>
                      <RadioButtonDisabledOverride>
                        <RadioButton
                          id={`${identifierPrefix}_autoNegotiation_yes`}
                          description="Yes"
                          selected={true}
                          disabled={quote.productType !== ProductType.DIA && isManagedDIA}
                        />
                      </RadioButtonDisabledOverride>
                    </Column>
                  ) : (
                    <>
                      <Column defaultWidth={6}>
                        <RadioButton
                          id={`${identifierPrefix}_autoNegotiation_yes`}
                          description="Yes"
                          onClick={() => onChange('autoNegotiation', true)}
                          selected={siteConfig.autoNegotiation}
                        />
                      </Column>
                      <Column defaultWidth={6}>
                        <RadioButton
                          id={`${identifierPrefix}_autoNegotiation_no`}
                          description="No"
                          onClick={() => onChange('autoNegotiation', false)}
                          selected={!siteConfig.autoNegotiation}
                        />
                      </Column>
                    </>
                  )}
                </div>
              </Column>
            </div>
          )}
        </>
      )}

      {allowVLAN && (
        <div className="row mb-2">
          <Column defaultWidth={12}>
            <VLANConfig
              identifierPrefix={identifierPrefix}
              siteConfig={siteConfig}
              updateSiteConfig={updateSiteConfig}
              popId={selectedPrice.a_end_p_o_p_id}
              defaultNNIRequestContactEmail={quote.createdBy}
            />
          </Column>
        </div>
      )}
    </>
  );
};

export default SiteConfigInformation;
