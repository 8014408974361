import React, { FC, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import headers from './headers';
import MultiSelectTable, { ISingleFilter, RowCell } from '../../../../shared/components/organisms/MultiSelectTable';
import styled from 'styled-components';
import { isCheckboxCell } from 'shared/utils/tableHelper';
import { bulkQuoteById } from 'Quotes/Routes';
import { selectBulkQuotesTableFilters, selectBulkQuotesTablePageSize } from 'Tables/selectors';
import TableNames from '../../../../Tables/types/tableNames';
import { IFilterPair } from 'shared/types/filterPairType';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { UpdateHeaderCellWidths } from 'shared/utils/updateHeaderCellWidth';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface IBulkQuotesTable {
  className?: string;
  paging: IServerSidePaging;
}

const BulkQuotesTable: FunctionComponent<React.PropsWithChildren<IBulkQuotesTable>> = (props) => {
  const navigate = useNavigate();
  return <BulkQuotesTableInternal {...props} navigate={navigate} />;
};
export const BulkQuotesTableInternal: FC<React.PropsWithChildren<IBulkQuotesTable & Navigation>> = ({
  className,
  paging,
  navigate,
}) => {
  const defaultPageSize = useSelector(selectBulkQuotesTablePageSize);

  const rowClickHandler = (cell: RowCell) => {
    if (!isCheckboxCell(cell)) {
      const bulkQuoteId = cell.row.original.id;
      navigate(bulkQuoteById(bulkQuoteId));
    }
  };

  const initialFilters: ISingleFilter[] = [];

  const filterPairs: IFilterPair<'bulkQuotesTable'>[] = [
    { tableHeaderId: 'id', storeField: 'quoteId' },
    { tableHeaderId: 'created_at', storeField: 'updatedAt' },
    { tableHeaderId: 'quoted_by', storeField: 'quotedBy' },
    { tableHeaderId: 'reference', storeField: 'reference' },
  ];

  const bulkQuotesTableFilters = useSelector(selectBulkQuotesTableFilters);

  filterPairs.forEach((pair) => {
    const storeValue = bulkQuotesTableFilters[pair.storeField];
    if (storeValue) {
      initialFilters.push({ id: pair.tableHeaderId, value: storeValue });
    }
  });

  return (
    <div className={className}>
      <MultiSelectTable
        headers={UpdateHeaderCellWidths(headers, TableNames.bulkQuotesTable)}
        paging={paging}
        isLoading={paging.isLoading}
        rows={paging.results}
        rowClickHandler={rowClickHandler}
        initialFilters={initialFilters}
        className="bulk-quotes-table"
        defaultSort={{
          id: 'created_at',
          desc: true,
        }}
        defaultPageSize={defaultPageSize}
        storeTableName={TableNames.bulkQuotesTable}
      />
    </div>
  );
};

const styledBulkQuotesTable = styled(BulkQuotesTable)`
  .table-wrapper {
    overflow-x: auto;
  }
`;

export default styledBulkQuotesTable;
