import React, { FC, ReactNode } from 'react';
import Column from 'shared/components/atoms/Column';
import styled from 'styled-components';
import { ProceedWithSecondaryCircuitToggle } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';

interface ICollapsableCardProps {
  includeSecondaryCircuit: boolean;
  isReadOnly: boolean;
  setInclude: (include: boolean) => void;
  children?: ReactNode;
  showToggle: boolean;
}

const Container = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  border: solid 0.5px ${(props) => props.theme.colours.grey10};
  .header {
    padding: 1em 1em 0.5em 1em;
  }
  .content {
    padding: 0em 1em 0.5em 1em;
  }

  hr {
    margin-top: 0.5em;
  }
`;

function Header(props: {
  showToggle: boolean;
  includeSecondaryCircuit: boolean;
  readOnly: boolean;
  include: (include: boolean) => void;
}) {
  return (
    <>
      <Column defaultWidth={8}>
        <span style={{ display: 'flex' }}>
          <h3 data-testid="diversity-header">Diversity &amp; Resiliency</h3>
          <span style={{ flexGrow: 1 }} />
        </span>
      </Column>
      <Column defaultWidth={4} className={'text-right pr-2'}>
        {props.showToggle && (
          <ProceedWithSecondaryCircuitToggle
            includeSecondaryCircuit={props.includeSecondaryCircuit}
            isReadOnly={props.readOnly}
            setInclude={props.include}
          />
        )}
      </Column>
    </>
  );
}

function Content(props: { children: React.ReactNode | (React.ReactNode & undefined) | undefined }) {
  return (
    <div className="row no-gutters mb-2 content">
      <Column defaultWidth={12}>{props.children}</Column>
    </div>
  );
}

export const DiverseCollapsableCard: FC<React.PropsWithChildren<ICollapsableCardProps>> = ({
  children,
  includeSecondaryCircuit,
  isReadOnly,
  setInclude,
  showToggle,
}) => {
  return (
    <Container>
      <div data-testid="diverse-collapsable-card">
        <div className="row no-gutters header">
          {children && (
            <Header
              showToggle={showToggle}
              includeSecondaryCircuit={includeSecondaryCircuit}
              readOnly={isReadOnly}
              include={setInclude}
            />
          )}
        </div>
        {includeSecondaryCircuit && <Content>{children}</Content>}
      </div>
    </Container>
  );
};
