import produce from 'immer';
import { AuthActions, AuthActionTypes } from './types/actions';

export interface IAuthState {
  showLogOutPrompt: boolean;
  logOutUser: boolean;
}

export const initialState: IAuthState = {
  showLogOutPrompt: false,
  logOutUser: false,
};

const reducer = produce((draft: IAuthState, action: AuthActions): IAuthState | void => {
  switch (action.type) {
    case AuthActionTypes.SET_SHOW_LOG_OUT_PROMPT: {
      draft.showLogOutPrompt = action.payload.showLogOutPrompt;
      break;
    }

    case AuthActionTypes.SET_LOG_OUT_USER: {
      draft.logOutUser = action.payload.logOutUser;
      break;
    }

    default:
      return draft;
  }
}, initialState);

export default reducer;
