import { call, put } from 'redux-saga/effects';
import { comparePrices, convertPriceDataFromQuotePrice } from 'Quotes/QuoteBuilder/utils/price';
import { replaceQuoteState, setPricedQuoteData, setSelectedPrice, setShortBulkQuoteId } from 'Quotes/actions';
import getQuote from '../Quotes/crud/getQuote';
import { filterIncludedBy } from 'Request/utils/filterIncludedBy';
import IQuoteRecord, { IQuotePricedOrOrdered, isBulkQuoteInclude } from '../Quotes/types/quoteRecord';
import { setSelectedCompany } from 'User/actions';
import { PopResource } from 'Location/NNI/types';

export const mapPrimaryPricesFromPayload = (prices: IQuotePricedOrOrdered[], secondaryPoPs?: PopResource[]) => {
  const primaryPrices = prices.filter((price) => !price.attributes.is_secondary_circuit_price);
  const secondaryPrices = prices.filter((price) => !!price.attributes.is_secondary_circuit_price);
  return primaryPrices
    .map((prices) => convertPriceDataFromQuotePrice(prices, secondaryPoPs, secondaryPrices))
    .sort(comparePrices);
};

export const mapSecondaryPricesFromPayload = (prices: IQuotePricedOrOrdered[], secondaryPoPs?: PopResource[]) => {
  return prices
    .filter((price) => !!price.attributes.is_secondary_circuit_price)
    .map((prices) => convertPriceDataFromQuotePrice(prices, secondaryPoPs))
    .sort(comparePrices);
};

export const findShortBulkIdInIncluded = ({ included }: IQuoteRecord) => {
  const bulkQuote = (included || []).filter(isBulkQuoteInclude);

  if (bulkQuote.length > 0 && isBulkQuoteInclude(bulkQuote[0])) {
    return bulkQuote[0].attributes.short_id;
  }

  return undefined;
};

export function* updatePriceData(
  quotePrices: IQuotePricedOrOrdered[] = [],
  preferredPriceId?: string,
  amortised?: boolean,
  secondaryPoPs?: PopResource[]
): any {
  const prices = mapPrimaryPricesFromPayload(quotePrices, secondaryPoPs);
  yield put(setPricedQuoteData(prices));
  yield put(setSelectedPrice(preferredPriceId, amortised));
}

export function* getQuoteWithPriceData(quoteId: string, selectedPriceId?: string): any {
  const quoteRecord = yield call(getQuote, quoteId, {
    prices: true,
    onatAddresses: true,
    pafAddresses: true,
    messages: true,
    secondaryPops: true,
  });

  const customerId = quoteRecord.data.attributes.customer_id;
  if (customerId) {
    yield put(setSelectedCompany(customerId));
  }

  yield put(replaceQuoteState(quoteRecord));

  const quotePrices = filterIncludedBy(quoteRecord.included as IQuotePricedOrOrdered[], 'price');
  const secondaryPoPs = filterIncludedBy(quoteRecord.included as PopResource[], 'pop');

  if (Array.isArray(quotePrices) && quotePrices.length > 0) {
    yield updatePriceData(
      quotePrices,
      selectedPriceId ?? quoteRecord.data.attributes.selected_price?.id,
      quoteRecord.data.attributes.selected_price?.amortised,
      secondaryPoPs
    );
  }
}

export function* getQuoteWithPriceDataOrderDataAndBulkQuoteData(quoteId: string, selectedPriceId?: string): any {
  const quoteRecord = yield call(getQuote, quoteId, {
    prices: true,
    orders: true,
    bulkQuote: true,
    nni: true,
    shadowNni: true,
    nniPop: true,
    shadowNniPop: true,
    aEndSupplierNNI: true,
    bEndSupplierNNI: true,
    onatAddresses: true,
    pafAddresses: true,
    messages: true,
    secondaryPops: true,
  });

  const customerId = quoteRecord.data.attributes.customer_id;
  if (customerId) {
    yield put(setSelectedCompany(customerId));
  }

  yield put(replaceQuoteState(quoteRecord));

  const shortBulkId = findShortBulkIdInIncluded(quoteRecord);

  if (quoteRecord.data.attributes.is_bulk && !!shortBulkId) {
    yield put(setShortBulkQuoteId(shortBulkId));
  }

  const quotePrices = filterIncludedBy<IQuotePricedOrOrdered>(quoteRecord.included, 'price');
  const secondaryPoPs = filterIncludedBy<PopResource>(quoteRecord.included, 'pop');

  if (quotePrices.length > 0) {
    yield updatePriceData(
      quotePrices,
      selectedPriceId ?? quoteRecord.data.attributes.selected_price?.id,
      quoteRecord.data.attributes.selected_price?.amortised,
      secondaryPoPs
    );
  }
}
