import { isFTTXConnection, OverallConnectionType } from '../../../../Quotes/types/connectionType';
import Column from '../../../../shared/components/atoms/Column';
import React from 'react';
import Cost from './Cost';

export function AEndFttxCosts(props: {
  connection: OverallConnectionType;
  aEndGeaCablelinkInstallCost: number | null;
  aEndGeaCablelinkAnnualCost: number | null;
}) {
  return (
    <>
      {isFTTXConnection(props.connection) && (
        <div className="row no-gutters mt-1">
          <Column defaultWidth={2} offsetDefaultWidth={2}>
            <span>GEA cablelink</span>
          </Column>
          <Cost label="Install" cost={props.aEndGeaCablelinkInstallCost ?? 0} className="a-end-gea-cablelink-install" />
          <Cost label="Annual" cost={props.aEndGeaCablelinkAnnualCost ?? 0} className="a-end-gea-cablelink-annual" />
        </div>
      )}
    </>
  );
}
