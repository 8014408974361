import React from 'react';
import { connect } from 'react-redux';

import { Role } from 'User/types/role';
import { anyRolesMatch } from 'User/utils/roles';
import { IAppState } from 'reducers';
import { forbidden } from './routes';
import { Navigate } from 'react-router-dom';

export const AuthoriseForRolesUnconnected = ({
  roles,
  children,
  userRoles,
}: {
  roles: Role[];
  children?: React.ReactNode;
  userRoles: Role[];
}): React.JSX.Element => {
  if (anyRolesMatch(roles, userRoles)) {
    return <>{children}</>;
  }
  return <Navigate to={forbidden} />;
};

const mapStateToProps = ({
  user: {
    userData: { roles },
  },
}: IAppState) => ({ userRoles: roles });

export default connect(mapStateToProps)(AuthoriseForRolesUnconnected);
