import { Container, Text } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import React from 'react';

export const AlertWithAction = ({
  isReadOnly = false,
  onClick,
  labelText,
  buttonText,
  id,
}: {
  isReadOnly?: boolean;
  onClick?: () => void;
  labelText: string;
  buttonText: string;
  id: string;
}) => {
  return (
    <Container>
      <Text data-testid={`${id}-info`}>{labelText}</Text>
      <Button
        data-testid={`${id}-button`}
        disabled={isReadOnly || !onClick}
        onClick={onClick}
        mainStyle={ButtonMainStyle.Secondary}
        subStyle="outline"
      >
        {buttonText}
      </Button>
    </Container>
  );
};
