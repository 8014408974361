import { ProductType } from '../types/productTypes';

export const productTypeHasFTTX = (productType: ProductType) => {
  switch (productType) {
    case ProductType.P2P:
    case ProductType.P2NNI:
    case ProductType.DIA:
      return true;
    default:
      return false;
  }
};
