import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ProductType } from 'Quotes/types/productTypes';
import PresentForRoles from 'Auth/PresentForRoles';
import TillReceiptInternal from '../TillReceiptInternal/TillReceiptInternal';
import { IPriceData, ISecondaryPrice } from 'Quotes/types/store';
import { userPermissions } from 'shared/utils/permissions';
import { selectQuote, selectSelectedPriceAnnualCost } from 'Quotes/selectors';
import {
  getChargesFromSecondarySelectedPrice,
  getChargesFromSelectedPrice,
} from 'Quotes/QuoteBuilder/utils/costsUtilities';
import Totals, { DIAConfig } from './Totals';
import {
  getSecondarySelectedPriceAnnualCost,
  getSelectedSecondaryPriceFromPrice,
  useSecondaryCircuits,
} from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { useSelector } from 'react-redux';

export interface Charges {
  amortisedPrice: number;
  annualPrice: number;
  marginAmount: number;
  marginPercentage: number;
  totalContractValue: number;
  mdiaCharges?: number;
}

export interface ITillReceipt {
  className?: string;
  selectedPrice: IPriceData;
  includeFTTPAggregationCharge: boolean;
  productType: ProductType | null | undefined;
  isFTTXQuote: boolean;
  showShadowVLANPrice: boolean;
  selectedPriceAnnualCost: ReturnType<typeof selectSelectedPriceAnnualCost>;
  diaConfig: DIAConfig;
}
const TillReceipt: FunctionComponent<ITillReceipt> = ({
  className,
  selectedPrice,
  includeFTTPAggregationCharge,
  productType,
  isFTTXQuote,
  showShadowVLANPrice,
  selectedPriceAnnualCost,
  diaConfig,
}) => {
  const quote = useSelector(selectQuote);

  const getSecondaryPriceData = (
    enabled: boolean,
    selectedPrice: IPriceData,
    includeFTTPAggregationCharge: boolean
  ): {
    secondarySelectedPriceAnnualCost: { A: number; B: number };
    secondaryCharges: Charges | undefined;
    secondarySelectedPrice: ISecondaryPrice | undefined;
  } => {
    const secondarySelectedPrice = enabled ? getSelectedSecondaryPriceFromPrice(selectedPrice) : undefined;
    const secondarySelectedPriceAnnualCost = getSecondarySelectedPriceAnnualCost(secondarySelectedPrice);
    const secondaryCharges = secondarySelectedPrice
      ? getChargesFromSecondarySelectedPrice(secondarySelectedPrice, selectedPrice, quote, includeFTTPAggregationCharge)
      : undefined;
    return { secondarySelectedPrice, secondarySelectedPriceAnnualCost, secondaryCharges };
  };

  const charges = getChargesFromSelectedPrice(selectedPrice, includeFTTPAggregationCharge);

  const secondaryCircuits = useSecondaryCircuits(selectedPrice);
  const { secondarySelectedPrice, secondarySelectedPriceAnnualCost, secondaryCharges } = getSecondaryPriceData(
    secondaryCircuits.enabled,
    selectedPrice,
    includeFTTPAggregationCharge
  );
  const combinedMarginPercentage = secondaryCharges
    ? (charges.marginPercentage + secondaryCharges.marginPercentage) / 2
    : charges.marginPercentage;

  return (
    <div className={className}>
      <PresentForRoles roles={userPermissions.costs}>
        <TillReceiptInternal
          selectedPrice={selectedPrice}
          secondarySelectedPrice={secondarySelectedPrice}
          charges={charges}
          secondaryCharges={secondaryCharges}
          isFTTXQuote={isFTTXQuote}
          selectedPriceAnnualCost={selectedPriceAnnualCost}
          secondarySelectedPriceAnnualCost={secondarySelectedPriceAnnualCost}
          productType={productType}
          diaConfig={diaConfig}
          quote={quote}
        />
      </PresentForRoles>

      <Totals
        charges={charges}
        includeFTTPAggregationCharge={includeFTTPAggregationCharge}
        productType={productType}
        secondaryCharges={secondaryCharges}
        secondarySelectedPrice={secondarySelectedPrice}
        selectedPrice={selectedPrice}
        showShadowVLANPrice={showShadowVLANPrice}
        diaConfig={diaConfig}
        quote={quote}
      />

      <PresentForRoles roles={userPermissions.costs}>
        {combinedMarginPercentage < 12 && (
          <div className="text-danger" style={{ marginTop: '10px', fontWeight: 'bold' }}>
            Your margin is currently less than 12% and will require approval.
            <br />
            Once completed, we&#39;ll contact you to confirm the order is ready to go.
          </div>
        )}
      </PresentForRoles>
    </div>
  );
};

const styledTillReceipt = styled(TillReceipt)`
  padding-bottom: 2em;

  .annual-suppliers-prompt {
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
    color: ${(props) => props.theme.colours.secondary};
  }

  .annual-suppliers-prompt,
  .segment_label {
    width: 100%;
    line-height: 26px;
  }

  .price-summary__segment-wrapper {
    display: flex;
  }

  .total {
    color: ${(props) => props.theme.colours.secondary};
  }

  .currency {
    letter-spacing: 0.05rem;
  }
`;

export default styledTillReceipt;
