import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import { Point } from './components/Point';
import Column from 'shared/components/atoms/Column';
import { Tab, TabList, TabPanel, Tabs } from 'shared/components/molecules/Tabs';
import { OnChange, Source } from 'Quotes/types/availabilityCheck';
import {
  availabilityCheckTabChange,
  getExistingQuote,
  resetQuoteStateAction,
  setIPOption as setIPOptionAction,
  triggerAvailabilityCheck,
} from 'Quotes/actions';
import { Bearer } from 'Quotes/QuoteBuilderByLocation/components/Bearer/Bearer';
import { Bandwidth } from 'Quotes/QuoteBuilderByLocation/components/Bandwidth/Bandwidth';
import { FTTX } from './components/FTTX';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import {
  selectAvailabilityCheck,
  selectIsQuoteUpdating,
  selectPricingProgress,
  selectProductType,
  selectQuote,
  selectQuoteCreating,
  selectQuoteRetrieving,
} from 'Quotes/selectors';
import styled from 'styled-components';
import Alert from 'shared/components/atoms/Alert';
import { DIAToggle } from './components/DIAToggle';
import NNI from 'Location/NNI/NNI';
import CloudConnect from 'Location/CloudConnect';
import ContractTermLength from '../QuoteBuilder/components/Configure/ContractTermLength';
import DIAConfig from '../QuoteBuilder/components/Configure/DIAConfig';
import { IPType } from '../QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { GetPrice } from './components/GetPrice';
import { ProductType } from '../types/productTypes';
import Diversity from '../QuoteBuilder/components/Configure/Diversity';
import { useParams } from 'react-router-dom';
import { IPAFAddressSite } from 'shared/types/postcodeResults';
import { requiresAsyncPrices } from 'Quotes/QuoteBuilder/utils/requiresAsyncPrices';
import { QuoteAsyncPricingAlert } from 'Quotes/shared/components/QuotePricingComplexAlert/QuoteAsyncPricingAlert';
import { useNNAT } from 'Quotes/QuoteBuilderByLocation/useNNAT';
import { NNATWarning } from 'Quotes/shared/components/NNAT/NNATAlerts';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { isNNATEnabledFor } from 'FeatureFlags/nnatUtils';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
`;

type UrlParams = {
  quoteId?: string;
};

const setSelectedIndexFromSource = (source: Source) => {
  switch (source) {
    case 'point_right':
      return 0;
    case 'nni':
      return 1;
    case 'cloud':
      return 2;
    default:
      return 0;
  }
};

const setSelectedSourceFromIndex = (index: number): Source => {
  switch (index) {
    case 0:
      return 'point_right';
    case 1:
      return 'nni';
    case 2:
      return 'cloud';
    default:
      return 'point_right';
  }
};

interface QuoteBuilderByLocationProps {
  cloneExistingQuote?: boolean;
}

const useCloneExistingQuote = (cloneExistingQuote: boolean) => {
  if (!cloneExistingQuote) {
    return { isCloningQuote: false };
  }

  const dispatch = useDispatch();
  const quoteRetrieving = useSelector(selectQuoteRetrieving);
  const params = useParams<UrlParams>();

  useEffect(() => {
    if (params?.quoteId) {
      dispatch(getExistingQuote(params.quoteId));
    }
  }, [dispatch, params.quoteId]);

  return {
    isCloningQuote: true,
    isLoading: quoteRetrieving.inProgress,
  };
};

export const QuoteBuilderByLocation = ({ cloneExistingQuote = false }: QuoteBuilderByLocationProps) => {
  const {
    loading: availabilityCheckLoading,
    failed: availabilityCheckFailed,
    sources,
    active: activeSources,
    showConfig,
  } = useSelector(selectAvailabilityCheck);
  const quoteCreating = useSelector(selectQuoteCreating);
  const pricingProgress = useSelector(selectPricingProgress);
  const quoteUpdating = useSelector(selectIsQuoteUpdating);
  const productType = useSelector(selectProductType);

  const cloning = useCloneExistingQuote(cloneExistingQuote);

  const dispatch = useDispatch();

  useEffect(() => {
    // Reset global state when QuoteBuilder is unmounted
    return () => {
      dispatch(resetQuoteStateAction());
    };
  }, [dispatch]);

  const quote = useSelector(selectQuote);
  const onChange: OnChange = (changes, source) => {
    dispatch(triggerAvailabilityCheck(changes, source));
    // Reset IP Options when DIA checkbox is unchecked
    if (!changes.is_dia && !cloning.isCloningQuote) {
      if (featureFlag.isEnabled(Feature.newMDIAUI)) {
        dispatch(setIPOptionAction(IPType.SUBNET_31));
      } else {
        dispatch(setIPOptionAction(IPType.WAN));
      }
    }
  };
  const [pointLeftSelectedPAFAddress, setPointLeftSelectedPAFAddress] = useState<IPAFAddressSite | null>(null);

  const [pointRightSelectedPAFAddress, setPointRightSelectedPAFAddress] = useState<IPAFAddressSite | null>(null);

  const { displayNNATWarningBox, setLeftAvailable, setRightAvailable } = useNNAT(
    pointLeftSelectedPAFAddress,
    pointRightSelectedPAFAddress,
    activeSources,
    sources
  );

  if (cloning.isCloningQuote && (!productType || cloning.isLoading)) {
    return (
      <HeaderWithChildrenWrapper header="New quote">
        <Spinner text="Retrieving quote..." size="medium" />
      </HeaderWithChildrenWrapper>
    );
  }

  return (
    <HeaderWithChildrenWrapper header="New quote">
      <h3 className="h5 mb-0">Select your location(s)</h3>
      <div className="row">
        <Column lgWidth={6} offsetLgWidth={6}>
          <DIAToggle onChange={onChange} />
        </Column>
      </div>
      <div className="row">
        <Column lgWidth={6}>
          <Tabs selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected" forceRenderTabPanel={true}>
            <TabList>
              <Tab>Point</Tab>
            </TabList>
            <TabPanel>
              <Point
                id="point_left"
                onChange={onChange}
                updateNNATSupported={setLeftAvailable}
                initialiseFromData={cloning.isCloningQuote}
                onSelectedAddressPAFChanged={(address) => {
                  setPointLeftSelectedPAFAddress(address);
                }}
                referencePAFAddress={pointRightSelectedPAFAddress}
              />
            </TabPanel>
          </Tabs>
        </Column>

        <Column lgWidth={6} classNames={['position-relative']}>
          <Tabs
            selectedTabClassName="is-selected"
            selectedTabPanelClassName="is-selected"
            forceRenderTabPanel={true}
            onSelect={(index) => {
              const newSource = setSelectedSourceFromIndex(index);
              dispatch(availabilityCheckTabChange(newSource));
              dispatch(
                triggerAvailabilityCheck(
                  {
                    ...sources[newSource],
                  },
                  newSource
                )
              );
            }}
            selectedIndex={setSelectedIndexFromSource(activeSources[1])}
          >
            <TabList>
              <Tab>Point</Tab>
              <Tab data-testid="nni_right_tab">NNI</Tab>
              <Tab>Cloud</Tab>
            </TabList>
            <TabPanel>
              <Point
                id="point_right"
                onChange={onChange}
                updateNNATSupported={setRightAvailable}
                initialiseFromData={cloning.isCloningQuote && productType === ProductType.P2P}
                onSelectedAddressPAFChanged={(address) => {
                  setPointRightSelectedPAFAddress(address);
                }}
                referencePAFAddress={pointLeftSelectedPAFAddress}
              />
            </TabPanel>
            <TabPanel>
              <NNI
                onTriggerAvailabilityCheck={onChange}
                isCloningQuote={cloning.isCloningQuote && productType === ProductType.P2NNI}
              />
            </TabPanel>
            <TabPanel>
              <CloudConnect
                renderCloudLocation={false}
                onAvailabilityCheckChange={onChange}
                initialisedFromData={cloning.isCloningQuote && productType === ProductType.P2CCT}
              />
            </TabPanel>
          </Tabs>

          {sources.point_left.is_dia && <Overlay className="is_dia_overlay" />}
        </Column>
      </div>
      <div className="row">
        <Column defaultWidth={12}>{isNNATEnabledFor(productType) && displayNNATWarningBox && <NNATWarning />}</Column>
      </div>
      <hr />

      {showConfig && (
        <div className="position-relative">
          {availabilityCheckFailed && (
            <Alert>
              <p className="mb-0">The availability check was unsuccessful. Please try again.</p>
            </Alert>
          )}
          <h3 className="h4 pb-3">Configuration</h3>
          <h4 className="h5 pb-3">Ethernet fibre / Optical wavelengths</h4>
          <Bearer />
          <Bandwidth />
          <hr />
          <h4 className="h5 pb-3">Ethernet over FTTC/P</h4>
          <FTTX />

          {productType === ProductType.P2CCT && (
            <>
              <hr />
              <Diversity />
            </>
          )}

          <ContractTermLength />
          {sources.point_left.is_dia && (
            <>
              <hr />
              <DIAConfig />
            </>
          )}

          {requiresAsyncPrices(quote) && <QuoteAsyncPricingAlert />}

          <GetPrice />

          {availabilityCheckLoading && (
            <Overlay>
              <Spinner text="Availability check in progress..." size="medium" />
            </Overlay>
          )}

          {(quoteCreating.inProgress || pricingProgress.fetchingPrice || quoteUpdating) && (
            <Overlay>
              <Spinner
                text={`${quoteCreating.inProgress ? 'Creating' : 'Pricing'} quote in progress...`}
                size="medium"
              />
            </Overlay>
          )}
        </div>
      )}
    </HeaderWithChildrenWrapper>
  );
};
