import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, DispatchProp } from 'react-redux';

import { IAppState } from 'reducers';
import UserStatus from './UserStatus';
import ViewPersonalInformation from '../../../../User/components/ViewPersonalInformation';
import SectionHeader from '../../../../shared/components/atoms/SectionHeader';
import { CompanyData, ICurrentlyViewedUserAttributes } from 'User/types/user';
import { resetUpdateUserRequestState } from 'Admin/actions';
import CompaniesAndRoles from 'Admin/shared/components/CompaniesAndRoles/CompaniesAndRoles';
import { isSSEUserFromBackendRoles } from 'Admin/utils/companyRolesRows';
import { userList } from 'Admin/routes';
import { useNavigate } from 'react-router-dom';

interface IReadOnlyUserDetails {
  userData: ICurrentlyViewedUserAttributes;
  className?: string;
  companyData: CompanyData;
  resetUpdateUser: () => void;
}

export const ReadOnlyUserDetails: FunctionComponent<React.PropsWithChildren<IReadOnlyUserDetails>> = ({
  className,
  companyData,
  userData,
  resetUpdateUser,
}) => {
  const navigate = useNavigate();
  const [currentCompanyId] = useState<string | null>(companyData.selectedCompanyId);

  useEffect(() => {
    if (currentCompanyId !== companyData.selectedCompanyId) {
      navigate(userList);
    }

    resetUpdateUser();
  }, [resetUpdateUser, currentCompanyId, companyData, navigate]);

  return (
    <div className={`${className} personalDetails__wrapper`}>
      <SectionHeader text="Profile" />
      <ViewPersonalInformation userData={userData} />
      <UserStatus userData={userData} />
      <CompaniesAndRoles
        companyData={companyData}
        initialData={userData.roles}
        isAdmin={true}
        isSSEUser={isSSEUserFromBackendRoles(userData.roles)}
        readonly={true}
      />
    </div>
  );
};

const StyledReadOnlyUserDetails = styled(ReadOnlyUserDetails)`
  .column {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
`;

const mapStateToProps = (state: IAppState) => ({
  companyData: state.user.companyData,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  resetUpdateUser: () => dispatch(resetUpdateUserRequestState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledReadOnlyUserDetails);
