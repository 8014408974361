import { setFilteredPricedData, toggleProductSubTypeFilter } from 'Quotes/actions';
import { selectPricing } from 'Quotes/selectors';
import { ProductSubType } from 'Quotes/types/store';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'shared/components/atoms/Checkbox';
import Column from 'shared/components/atoms/Column';
import { useOutsideClick } from 'shared/utils/customHooks';
import styled from 'styled-components';
import FilterButton from '../shared/FilterButton';

const Menu = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  background: white;
  box-shadow: ${(props) => props.theme.colours.grey20} 0px 0px 8px 0px;
  border-radius: 4px;
  padding: 0.5em;
  position: absolute;
  width: 100%;

  li {
    color: #737373;
    border-top: 1px solid ${(props) => props.theme.colours.grey20};
    padding-top: 0.45em;

    .checkbox {
      cursor: pointer;
    }
  }
`;

interface Props {
  className?: string;
}

const UnstyledProductSubTypeFilter: FunctionComponent<React.PropsWithChildren<Props>> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const pricing = useSelector(selectPricing);
  const dispatch = useDispatch();

  useOutsideClick(ref, () => setIsOpen(false));

  const onChange = useCallback((subType: ProductSubType) => {
    dispatch(toggleProductSubTypeFilter(subType));
    dispatch(setFilteredPricedData());
  }, []);

  return (
    <div className={`${className} position-relative`} ref={ref}>
      <FilterButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        Connection type
      </FilterButton>

      {isOpen && (
        <Menu>
          <span>Connection type</span>
          <ul className="list-unstyled pt-3 mb-0">
            <li className="row no-gutters">
              <Column lgWidth={7}>Ethernet</Column>
              <Column lgWidth={5}>
                <Checkbox
                  inputId="connection-type-checkbox__ethernet"
                  onChange={() => {
                    onChange('ethernet');
                  }}
                  value={pricing.subProductTypeFilterList.includes('ethernet')}
                />
              </Column>
            </li>
            <li className="row no-gutters">
              <Column lgWidth={7}>Optical</Column>
              <Column lgWidth={5}>
                <Checkbox
                  inputId="connection-type-checkbox__optical"
                  onChange={() => {
                    onChange('optical');
                  }}
                  value={pricing.subProductTypeFilterList.includes('optical')}
                />
              </Column>
            </li>
          </ul>
        </Menu>
      )}
    </div>
  );
};

export const ProductSubTypeFilter = styled(UnstyledProductSubTypeFilter)`
  z-index: 1;
`;
