import { doRequest, Methods } from '../../../Request/index';
import { IQuotePricedOrOrdered } from '../../types/quoteRecord';

const priceQuote: (quoteId: string) => Promise<IQuotePricedOrOrdered[]> = (quoteId: string) => {
  return doRequest({
    method: Methods.POST,
    path: `/quotes/${quoteId}/prices`,
  });
};

export default priceQuote;
