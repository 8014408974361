import { buildHeaders, getPostcodesFromPriceData, tableNamesByProductType } from './utilities';
import { presentSupplierNameForPriceTile } from 'Quotes/utils/supplierHelpers';
import styled from 'styled-components';
import { isPriceNNAT } from 'Quotes/utils/isPriceNNAT';
import React from 'react';
import { capitalize } from 'lodash';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import { formatBandwidth } from 'shared/utils/connectionCapacity';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { ProductType } from 'Quotes/types/productTypes';
import { CommonDataRow, DataRowP2NNI } from './types';
import { getNNIOrDataCentreLabel } from 'Quotes/QuoteBuilder/utils/getSelectedNNIReference';
import { monthsToYears } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import { NNATTooltip } from 'Quotes/shared/components/NNAT/NNATAlerts';

const LocationText = styled.span`
  display: inline-flex;
  column-gap: 1rem;
  font-family: 'bariol_regular';
  font-weight: normal;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.2rem;
`;

const Price = styled.strong`
  color: ${(props) => props.theme.colours.primaryA1};
`;

export const getHeadersByProductType = (productType: ProductType, isQuoteMultiTerm: boolean) => {
  return buildHeaders(
    [
      supplierHeader,
      bandwidthHeader,

      // NNI specific fields
      {
        ...NNIHeader,
        displayCondition: [ProductType.P2NNI, ProductType.NNI2CCT].includes(productType),
      },
      {
        ...shadowNNIHeader,
        displayCondition: productType === ProductType.P2NNI,
      },

      // Cloud connect specific fields
      {
        ...providerHeader,
        displayCondition: [ProductType.NNI2CCT, ProductType.P2CCT].includes(productType),
      },

      // Optical P2P specific fields
      {
        ...dataCenterHeader,
        displayCondition: productType === ProductType.OpticalP2P,
      },

      { ...contractTermHeader, displayCondition: isQuoteMultiTerm },
      connectionTypeHeader,
      priceHeader,
    ],
    { tableName: tableNamesByProductType[productType] }
  );
};

const bandwidthHeader = {
  Header: 'Bandwidth',
  accessor: (row: CommonDataRow) => {
    if (row.bandwidth?.includes('/')) {
      return Number(row.bandwidth.replaceAll('/', ''));
    }
    return Number(row.bandwidth);
  },
  Cell: ({ row: { original } }: any) => formatBandwidth(original.bandwidth),
  id: 'bandwidth',
  width: 60,
};

const contractTermHeader = {
  Header: 'Contract term',
  accessor: (row: any) => monthsToYears(row.termLengthInMonths),
  id: 'contractTerm',
  width: 75,
};

const supplierHeader = {
  Header: (context: any) => {
    const { aEndPostcode, bEndPostcode } = getPostcodesFromPriceData(context.data);

    return (
      <>
        <strong>Supplier </strong>
        <LocationText>
          {aEndPostcode && <span>{aEndPostcode} (A)</span>}
          {bEndPostcode && <span>{bEndPostcode} (B)</span>}
        </LocationText>
      </>
    );
  },
  accessor: 'aEndSupplier',
  id: 'provider',
  width: 150,
  Cell: ({ row: { original } }: any) => (
    <Container>
      {original.aEndSupplier && <span>{presentSupplierNameForPriceTile(original.aEndSupplier || '')} (A)</span>}
      {original.bEndSupplier && <span>{presentSupplierNameForPriceTile(original.bEndSupplier || '')} (B)</span>}
    </Container>
  ),
};

const connectionTypeHeader = {
  Header: 'Connection type',
  accessor: 'product_sub_type',
  id: 'connection_type',
  width: 150,
  Cell: ({ row: { original } }: any) => {
    const isNNAT = isPriceNNAT(original.aEndSupplier, original.bEndSupplier);

    return (
      <Container>
        {isNNAT && (
          <CenteredContent>
            Neos Networks Access Tail
            <NNATTooltip />
          </CenteredContent>
        )}
        <CenteredContent>
          {capitalize(original.product_sub_type)}

          {((original.aEndPostcode && original.aEndAccessMethod) ||
            (original.bEndPostcode && original.bEndAccessMethod)) && (
            <Tooltip text="access methods">
              <strong>Access method</strong>
              {original.aEndPostcode && original.aEndAccessMethod && (
                <>
                  <br />
                  A-end ({original.aEndPostcode}): {original.aEndAccessMethod}
                </>
              )}
              {original.bEndPostcode && original.bEndAccessMethod && (
                <>
                  <br />
                  B-end ({original.bEndPostcode}): {original.bEndAccessMethod}
                </>
              )}
            </Tooltip>
          )}
        </CenteredContent>
      </Container>
    );
  },
};

const PriceOnApplicationCell = styled.p`
  font-size: 1.2em;
`;

const priceHeader = {
  Header: 'Price',
  accessor: (row: CommonDataRow) => {
    if (row.isPoA) {
      return <PriceOnApplicationCell>Price on application</PriceOnApplicationCell>;
    }
    return row.amortisedAnnualPrice ? currencyFormatter.format(row.amortisedAnnualPrice) : '';
  },
  id: 'price_and_cost',
  Cell: ({ value }: any) => {
    return <Price>{value}</Price>;
  },
};

const NNIHeader = {
  Header: 'NNI',
  accessor: (row: DataRowP2NNI) => {
    return row.nniLabel || `${getNNIOrDataCentreLabel(row.nniPopName, row.nniReference)} ${row.nniLocation}`;
  },
  id: 'nni',
};

const shadowNNIHeader = {
  Header: 'Shadow NNI',
  accessor: (row: DataRowP2NNI) => {
    return row.nniShadowReference || 'N/A';
  },
  id: 'shadow_nni',
};

const providerHeader = {
  Header: 'Provider',
  accessor: 'provider',
  id: 'cct-provider',
  width: 30,
};

const dataCenterHeader = {
  Header: 'Data centre',
  accessor: 'a_end_data_centre_reference',
  id: 'a_end_data_centre_reference',
  Cell: ({ row: { original } }: any) => {
    return (
      <Container>
        {original.a_end_data_centre_reference && <span>{original.a_end_data_centre_reference} (A)</span>}
        {original.b_end_data_centre_reference && <span>{original.b_end_data_centre_reference} (B)</span>}
      </Container>
    );
  },
};
