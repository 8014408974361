import { PromiseBasedFileReader } from 'Quotes/utils/PromiseBasedFileReader';

async function encodeImageAsBase64(image: File | null) {
  let base64Image = null;

  if (image) {
    const fileReader = new PromiseBasedFileReader();
    const fileContents = await fileReader.readAsBinaryString(image);
    base64Image = btoa(fileContents);
  }

  return base64Image;
}

export interface UpdatePromotionRequest {
  image: any;
  valid_until?: string | null;
  user_id?: string;
  created_at?: string;
  valid_from?: string | null;
  banner_url?: string;
  body?: string;
  title?: string;
  banner_hidden?: boolean;
}

export interface UpdatePromotionBody {
  data: {
    attributes: UpdatePromotionRequest;
    type: string;
  };
}

export const toUpdatePromoBody: (
  data: Partial<UpdatePromotionRequest>,
  image: File | null
) => Promise<UpdatePromotionBody> = async (data: Partial<UpdatePromotionRequest>, image: File | null) => {
  const base64Image = await encodeImageAsBase64(image);
  return {
    data: {
      attributes: {
        ...data,
        image: base64Image,
      },
      type: 'promotion',
    },
  };
};
