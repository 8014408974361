export enum AppActionTypes {
  MAINTENANCE_MODE_CHECK = 'MAINTENANCE_MODE_CHECK',
}

type MaintenanceModeCheck = () => {
  type: AppActionTypes.MAINTENANCE_MODE_CHECK;
};

export const maintenanceModeCheck: MaintenanceModeCheck = () => ({
  type: AppActionTypes.MAINTENANCE_MODE_CHECK,
});
