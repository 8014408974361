import React from 'react';
import { selectSelectedCompanyId } from 'User/selectors';
import styled from 'styled-components';
import { useOrderTemplates } from 'Order/crud/orderTemplatesApi';
import { useSelector } from 'react-redux';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import { sortTemplatesPutDefaultFirst } from 'Admin/preferences/OrderTemplates/utils/sortTemplatesPutDefaultFirst';
import { OrderTemplateCard } from 'Admin/preferences/OrderTemplates/OrderTemplateCard';
import { Loading } from 'shared/components/molecules/Loading/Loading';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { AdminTool } from '../../../utils/AdminTool';
import { useModal } from '../../../shared/hooks/useModal';
import Modal from '../../../shared/components/molecules/Modal';

interface OrderTemplatesProps {
  /* Customer ID and company ID are the same thing */
  customerIdSelector?: typeof selectSelectedCompanyId;
  useOrderTemplatesHook?: typeof useOrderTemplates;
}

function DeleteAllOrderTemplates(props: { onClick: () => void }) {
  const { isOpen, openModal, closeModal } = useModal();
  return (
    <div className="row no-gutters button-wrapper">
      <div className="col-1 offset-10">
        <Button mainStyle={ButtonMainStyle.DangerRectangular} onClick={() => openModal()}>
          Delete All
        </Button>
      </div>
      {isOpen && (
        <Modal
          title={'Delete All Order Templates'}
          onClose={() => closeModal()}
          onConfirm={() => {
            props.onClick();
            closeModal();
          }}
        >
          Are you sure you want to delete all order templates? This action cannot be undone.
        </Modal>
      )}
    </div>
  );
}

export function OrderTemplates({
  customerIdSelector = selectSelectedCompanyId,
  useOrderTemplatesHook = useOrderTemplates,
}: OrderTemplatesProps) {
  const customerId = useSelector(customerIdSelector);
  const orderTemplates = useOrderTemplatesHook(customerId);
  if (orderTemplates.isLoading) return <SpinnerWithText text="Retrieving order templates" />;

  if (!orderTemplates.hasOrderTemplates) return <Alert alertType={Alerts.INFO}>No order templates available</Alert>;

  const sortedTemplates = orderTemplates.orderTemplates
    ? sortTemplatesPutDefaultFirst([...orderTemplates.orderTemplates])
    : [];

  return (
    <div>
      <AdminTool>
        <DeleteAllOrderTemplates onClick={() => orderTemplates.deleteAll()} />
      </AdminTool>
      <Container data-testid="all-order-template-cards">
        <Loading isLoading={orderTemplates.isLoading}>
          {sortedTemplates.map((orderTemplate) => (
            <OrderTemplateCard key={orderTemplate.id} customerId={customerId} orderTemplate={orderTemplate} />
          ))}
        </Loading>
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-top: 2rem;
`;
