import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'shared/components/atoms/Tooltip';

interface Props {
  className?: string;
  title: string;
  tooltip?: string;
}

const FormHeader: FunctionComponent<React.PropsWithChildren<Props>> = ({ className, title, tooltip }) => {
  return (
    <p className={className}>
      {title}
      {tooltip && <Tooltip text={tooltip} />}
    </p>
  );
};

const StyledFormHeader = styled(FormHeader)`
  font-family: ${(props) => props.theme.typography.fontFamilyBold};
  margin-bottom: 0.5rem;
  margin-top: 1rem;

  > span {
    margin-left: 0.2em;
  }
`;
export default StyledFormHeader;
