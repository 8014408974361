import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const LocationChangeScrollTopInternal: FC<React.PropsWithChildren<{ pathname: string }>> = ({ pathname }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const LocationChangeScrollTop: FC<React.PropsWithChildren<unknown>> = () => {
  const { pathname } = useLocation();

  return <LocationChangeScrollTopInternal pathname={pathname} />;
};
