import React, { ReactNode } from 'react';
import { formatDateTime } from 'shared/utils/dateHelper';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import { RowCell } from 'shared/components/organisms/MultiSelectTable/index';

export function renderCell(row: any, onClick: (cell: RowCell) => void) {
  return (cell: RowCell & { column: { customRenderer?: () => ReactNode } }) => {
    if (cell.column.id === 'provider' && row.original.supportsSecondaryCircuits) {
      return supportsRO2Provider(cell, onClick);
    }
    if (hasCustomRenderer(cell)) {
      return customRenderedCell(cell, onClick);
    }
    if (isTimestampCell(cell)) {
      return dateCell(cell, onClick);
    } else if (cell.column.id === 'annual_price') {
      return formattedPriceCell(cell, onClick);
    } else if (cell.column.id === 'price_and_cost' && !!row.original.tooltip) {
      return tooltipCell(cell);
    }
    return defaultCell(cell, onClick);
  };
}

function hasCustomRenderer(cell: RowCell & { column: { customRenderer?: () => ReactNode } }) {
  return !!cell.column.customRenderer;
}
function customRenderedCell(cell: RowCell, onClick: (cell: RowCell) => void) {
  return (
    <td {...cell.getCellProps()} onClick={() => onClick(cell)}>
      {cell.render('customRenderer')}
    </td>
  );
}

function dateCell(cell: RowCell, onClick: (cell: RowCell) => void): JSX.Element {
  const formattedCell = cell.value ? formatDateTime(cell.value) : '';
  return (
    <td {...cell.getCellProps()} onClick={() => onClick(cell)}>
      {formattedCell}
    </td>
  );
}

const formatPriceWithPoASupport = (value: number | string) => {
  if (typeof value === 'string') return value;
  return currencyFormatter.format(value);
};

function formattedPriceCell(cell: RowCell, onClick: (cell: RowCell) => void): JSX.Element {
  return (
    <td {...cell.getCellProps()} onClick={() => onClick(cell)} className="text-right currency">
      {cell.value && formatPriceWithPoASupport(cell.value)}
    </td>
  );
}

function defaultCell(cell: RowCell, onClick: (cell: RowCell) => void): JSX.Element {
  return (
    <td {...cell.getCellProps()} onClick={() => onClick(cell)}>
      {cell.render('Cell')}
    </td>
  );
}

function supportsRO2Provider(cell: RowCell, onClick: (cell: RowCell) => void): JSX.Element {
  return (
    <td {...cell.getCellProps()} onClick={() => onClick(cell)}>
      {cell.render('Cell')}
      <div
        style={{
          background: '#b0e0db',
          border: '1px solid #3ab1a5',
          borderRadius: '5px',
          marginLeft: '20px',
          padding: '10px',
        }}
      >
        + Diverse Options
      </div>
    </td>
  );
}
function tooltipCell(cell: RowCell): JSX.Element {
  return (
    <td {...cell.getCellProps()}>
      {cell.render('Cell')}{' '}
      {cell.row.original.tooltip && (
        <span className="ml-1">
          <Tooltip text={cell.row.original.tooltip} multiline />
        </span>
      )}
    </td>
  );
}

const TIMESTAMP_CELLS = ['created_at', 'updated_at', 'placed_at', 'modified', 'last_logged_in'];
const isTimestampCell = (cell: RowCell): boolean => {
  return TIMESTAMP_CELLS.includes(cell.column.id);
};
