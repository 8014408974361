import React, { FC } from 'react';
import Icon from 'shared/components/atoms/Icon';
import styled from 'styled-components';
import cx from 'classnames';
import { useCopyToClipboard } from 'shared/hooks/useCopyToClipboard';

type Props = {
  colour?: string;
  className?: string;
  label?: string;
  value: string;
};

export const CopyToClipboardBadge: FC<React.PropsWithChildren<Props>> = ({ className, label, value }) => {
  const { copy, isRecentlyCopied } = useCopyToClipboard();

  return (
    <div
      className={cx('copy-to-clipboard-badge', className, {
        copied: isRecentlyCopied,
      })}
    >
      <div>Copied!</div>
      <button type="button" onClick={() => copy(value)}>
        <div className="mr-1">
          <Icon name="file_copy" size="small" />
        </div>
        <div>
          {label && `${label} - `}
          {value}
        </div>
      </button>
    </div>
  );
};

export default styled(CopyToClipboardBadge)`
  display: inline-block;
  color: ${(props) => props.colour || props.theme.colours.secondaryB1};
  cursor: grab;
  position: relative;
  margin-bottom: 20px;

  > [type='button'] {
    display: flex;
    cursor: grab;
    outline: none;
    padding: 0.25em 1.25em;
    padding-right: 1em;
    background: white;
    position: relative;
    border-radius: 5px;
    border: 0.1em solid ${(props) => props.colour || props.theme.colours.secondaryB1};
  }

  > div {
    content: 'Copied!';
    position: absolute;
    display: block;
    width: 60px;
    height: 17px;
    bottom: 0;
    left: 50%;
    margin-left: -30px;
    background: ${(props) => props.theme.colours.primaryB1};
    color: white;
    text-align: center;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    font-size: 11px;
    padding-top: 1px;
    transition: bottom ease-in-out 150ms;
    z-index: 0;
  }

  &.copied > div {
    bottom: -17px;
  }
`;
