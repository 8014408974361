import Dropdown from 'shared/components/molecules/Dropdown';
import React from 'react';
import { Arrow, ExchangeOptions } from 'shared/components/molecules/SecondaryCircuits/SecondaryExchanges';

export function DIAExchanges(props: {
  exchangeOptions: ExchangeOptions[];
  isDropdownDisabled: boolean;
  selectedExchange: (it: ExchangeOptions) => boolean;
  onChange: (option: ExchangeOptions) => void;
}) {
  return (
    <>
      <div data-testid="SecondaryCircuits-secondary-exchange-a">
        <h4>Secondary Exchange</h4>
        <Dropdown
          options={props.exchangeOptions}
          isDisabled={props.isDropdownDisabled}
          defaultValue={props.exchangeOptions[0]}
          value={props.exchangeOptions.find(props.selectedExchange)}
          onChange={props.onChange}
          isClearable={false}
        />
      </div>
      <Arrow />
      <div data-testid="SecondaryCircuits-secondary-point">
        <h4>Internet Access Point</h4>
      </div>
    </>
  );
}
