import React from 'react';
import { totalGEACablelinkCostAnnual } from 'Quotes/QuoteBuilder/utils/costsUtilities';
import { ProductType } from 'Quotes/types/productTypes';
import { selectSelectedPriceAnnualCost } from 'Quotes/selectors';
import { IAEndLocation, IPriceData } from 'Quotes/types/store';
import { Charges } from '../TillReceipt/TillReceipt';
import { CostsInternalInstall } from './CostsInternalInstall';
import { CostsInternalAnnual } from './CostsInternalAnnual';
import { IMDIACosts } from 'Quotes/QuoteBuilder/components/Price/components/TillReceiptInternal/TillReceiptInternal';

interface CostsInternalAllProps {
  selectedPrice: IPriceData;
  charges?: Charges;
  secondaryCharges?: Charges;
  className?: string;
  isFTTXQuote: boolean;
  productType: ProductType | null | undefined;
  selectedPriceAnnualCost: ReturnType<typeof selectSelectedPriceAnnualCost>;
  isManagedDIA: IAEndLocation['is_managed_dia'];
  label?: string;
  mdiaCosts?: IMDIACosts;
  mdiaServiceCost: number;
}

export const CostsInternalAll: React.FC<CostsInternalAllProps> = ({
  selectedPrice,
  isFTTXQuote,
  selectedPriceAnnualCost,
  productType,
  isManagedDIA,
  label,
  mdiaCosts,
  mdiaServiceCost,
}) => {
  const portsCostAnnual = selectedPrice.port_costs_annual ?? 0;
  const bandwidthCostAnnual = selectedPrice.bandwidth_cost?.annual ?? 0;
  const geaCableLinkAnnual = totalGEACablelinkCostAnnual(selectedPrice);

  return (
    <>
      <div className="annual-suppliers-prompt mb-2">{label}</div>
      <div className="annual-suppliers-prompt mb-2">Install Costs</div>
      <CostsInternalInstall
        selectedPrice={selectedPrice}
        isFTTXQuote={isFTTXQuote}
        productType={productType}
        isManagedDIA={isManagedDIA}
        mdiaCosts={mdiaCosts}
      />

      <div className="annual-suppliers-prompt mt-2 mb-2">Annual Costs</div>
      <CostsInternalAnnual
        selectedPriceAnnualCost={selectedPriceAnnualCost}
        selectedPrice={selectedPrice}
        isFTTXQuote={isFTTXQuote}
        portsCostAnnual={portsCostAnnual}
        bandwidthCostAnnual={bandwidthCostAnnual}
        productType={productType}
        geaCableLinkAnnual={geaCableLinkAnnual}
        isManagedDIA={isManagedDIA}
        mdiaServiceCost={mdiaServiceCost}
      />
    </>
  );
};
