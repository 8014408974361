type Include = {
  attributes: any;
  id: string;
  type?: unknown;
};

type FilterIncludedBy = {
  <T extends Include>(included: T[] | undefined, type: string): T[];
};

export const filterIncludedBy: FilterIncludedBy = (included, type) => {
  return (Array.isArray(included) ? included : []).filter((item) => {
    return item.type === type;
  });
};
