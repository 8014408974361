import { Journey } from 'Quotes/types/actions';
import React, { FunctionComponent } from 'react';
import { OverallConnectionType } from 'Quotes/types/connectionType';

interface IAvailabilityMessage extends IYesAvailabilityMessage {
  requiresAvailabilityCheck: boolean;
}

interface IYesAvailabilityMessage extends IBaseAvailabilityMessage {
  availability: OverallConnectionType | undefined;
  journey: Journey;
}

interface IBaseAvailabilityMessage {
  error: boolean;
  isLoading: boolean;
  className?: string;
}

export const ETHERNET_MESSAGE_BY_LOCATION =
  'If you have entered or chosen an ALK, and not just a postcode, a check for EoFTTC and EoFTTP will be made. However Ethernet Fibre services are available at your chosen location.';
export const ETHERNET_MESSAGE_BY_PRODUCT_TYPE =
  'A check for EoFTTC and EoFTTP was run, but no availability was found. However Ethernet Fibre services are available at your chosen location.';
export const FTTP_MESSAGE = 'EoFTTP and Ethernet Fibre are available at this location';
export const FTTC_MESSAGE = 'EoFTTC and Ethernet Fibre are available at this location';
export const CHECK_NOT_REQUIRED_MESSAGE =
  'A check for EoFTTC and EoFTTP requires a full address, not just a postcode. However Ethernet Fibre services are available at your chosen location.';

export const AvailabilityMessage: FunctionComponent<React.PropsWithChildren<IAvailabilityMessage>> = ({
  availability,
  isLoading,
  error,
  requiresAvailabilityCheck,
  className,
  journey,
}) => {
  if (isLoading || error) {
    return null;
  }

  if (!requiresAvailabilityCheck) {
    return <NoAvailabilityMessage error={error} isLoading={isLoading} className={className} />;
  } else {
    return (
      <AvailabilityResultMessage
        className={className}
        availability={availability}
        journey={journey}
        error={error}
        isLoading={isLoading}
      />
    );
  }
};
export const AvailabilityResultMessage: FunctionComponent<React.PropsWithChildren<IYesAvailabilityMessage>> = ({
  availability,
  isLoading,
  error,
  className,
  journey,
}) => {
  if (isLoading || error) {
    return null;
  }

  let message;

  if (availability === 'EoFTTP') {
    message = FTTP_MESSAGE;
  } else if (availability === 'EoFTTC') {
    message = FTTC_MESSAGE;
  } else if (journey === 'by_location') {
    message = ETHERNET_MESSAGE_BY_LOCATION;
  } else if (journey === 'by_product_type') {
    message = ETHERNET_MESSAGE_BY_PRODUCT_TYPE;
  }

  return <Message className={className}>{message}</Message>;
};

export const NoAvailabilityMessage: FunctionComponent<React.PropsWithChildren<IBaseAvailabilityMessage>> = ({
  isLoading,
  error,
  className,
}) => {
  if (isLoading || error) {
    return null;
  }

  return <Message className={className}>{CHECK_NOT_REQUIRED_MESSAGE}</Message>;
};

function Message(props: { className: string | undefined; children: string | undefined }) {
  return <div className={`${props.className} availability-message text-muted mt-1`}>{props.children}</div>;
}

export default AvailabilityMessage;
