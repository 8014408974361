import React, { FunctionComponent, useState } from 'react';
import { ISiteConfig } from 'Order/types/location';
import Checkbox from 'shared/components/atoms/Checkbox';
import TextInput from 'shared/components/atoms/TextInput';
import { VLAN_ID_INTERNALLY_ASSIGNED } from 'Order/types/orderRecord';
import { VLAN_REGEX_STRING } from 'Order/OrderBuilder/shared/utils/vlanRegex';
import { isValidVLANIdField } from 'Order/OrderBuilder/shared/utils/validation';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { AlreadyOrderedNNIChoice } from '../shared/AlreadyOrderedNNIChoice';
import { NewNNIRequestForm } from '../shared/NewNNIRequestForm';
import { ShadowNNIInputFields } from '../shared/ShadowNNIInputFields';
import { findNewNNIRequestByPopId, handleNNIAlreadyOrderedToggle, onNewNNIRequestChange } from '../utils/nniRequest';
import { objectIsEmpty } from 'shared/utils/objectHelper';
import FormHeader from '../FormHeader';
import Column from 'shared/components/atoms/Column';

interface ISiteConfigProps {
  identifierPrefix: string;
  siteConfig: ISiteConfig;
  reference: string;
  required?: boolean;
  showLabel?: boolean;
  isNewNNI?: boolean;
  defaultNNIRequestContactEmail?: string;
  popId?: string;
  customerName?: string | null;
  dataCentre?: string | null;
  updateSiteConfig(updated: ISiteConfig): void;
}

const ShadowVLANConfiguration: FunctionComponent<React.PropsWithChildren<ISiteConfigProps>> = ({
  identifierPrefix,
  updateSiteConfig,
  reference,
  required,
  showLabel,
  siteConfig,
  isNewNNI,
  defaultNNIRequestContactEmail,
  popId,
  customerName,
  dataCentre,
}) => {
  // If a record already has 'Neos to assign VLAN Id' set, display it until they uncheck the box and hit save.
  // This successfully removes the ability to set it to true for new orders, but maintains functionality on existing orders
  const [showNeosToAssignVLANCheckbox] = useState(siteConfig.shadowVLANId === VLAN_ID_INTERNALLY_ASSIGNED);
  const onChange = (field: keyof ISiteConfig, value: any): void => {
    updateSiteConfig({
      ...siteConfig,
      [field]: value,
    });
  };

  const showVLAN = siteConfig.shadowVLANTagging || required;
  const newNNIRequest = findNewNNIRequestByPopId(popId, siteConfig.new_nni_requests);

  return (
    <>
      <div className="row mt-2">
        {!!reference && (
          <div className="mb-1">
            <h4>{reference}</h4>
          </div>
        )}
        {showLabel && <FormHeader title="Shadow VLAN Reference" />}

        {!required && (
          <Checkbox
            inputId={`${identifierPrefix}_shadowVLANTagging`}
            label="Shadow VLAN tagging required?"
            onChange={(val) => onChange('shadowVLANTagging', val)}
            value={required || siteConfig.shadowVLANTagging}
          />
        )}
        {showVLAN && (
          <Column defaultWidth={required ? 12 : 6} className={required ? 'pl-0' : ''}>
            <TextInput
              readOnly={siteConfig.shadowVLANId === VLAN_ID_INTERNALLY_ASSIGNED}
              inputIdentifierPrefix={identifierPrefix}
              pattern={VLAN_REGEX_STRING}
              fieldName="_shadowVLANId"
              onChange={(e) => onChange('shadowVLANId', e.target.value)}
              labelText="Shadow VLAN ID *"
              customError={isValidVLANIdField.shadowErrorMessage}
              maxLength={4}
              value={siteConfig.shadowVLANId === VLAN_ID_INTERNALLY_ASSIGNED ? '' : siteConfig.shadowVLANId || ''}
              helperText="Please ensure you enter the correct VLAN ID, failure to do so will result in delay to your order."
            />
            {showNeosToAssignVLANCheckbox && (
              <Checkbox
                inputId={`${identifierPrefix}_shadowVLANSSE`}
                label="Neos Networks to assign Shadow VLAN"
                onChange={(val) => {
                  onChange('shadowVLANId', val ? VLAN_ID_INTERNALLY_ASSIGNED : '');
                }}
                value={siteConfig.shadowVLANId === VLAN_ID_INTERNALLY_ASSIGNED}
              />
            )}
          </Column>
        )}
        {isNewNNI &&
          popId &&
          (featureFlag.isEnabled(Feature.newNNIRequests) ? (
            <AlreadyOrderedNNIChoice
              initialSelectionYes={objectIsEmpty(newNNIRequest)}
              onChange={(val) =>
                handleNNIAlreadyOrderedToggle(val, popId, siteConfig, updateSiteConfig, defaultNNIRequestContactEmail)
              }
              renderIfYes={
                <ShadowNNIInputFields identifierPrefix={identifierPrefix} siteConfig={siteConfig} onChange={onChange} />
              }
              renderIfNo={
                <NewNNIRequestForm
                  identifierPrefix={`${identifierPrefix}`}
                  onChange={(field, value) => onNewNNIRequestChange(field, value, popId, siteConfig, updateSiteConfig)}
                  newNNIRequest={newNNIRequest}
                  customerName={customerName}
                  dataCentre={dataCentre}
                />
              }
            />
          ) : (
            <ShadowNNIInputFields identifierPrefix={identifierPrefix} siteConfig={siteConfig} onChange={onChange} />
          ))}
      </div>
    </>
  );
};

export default ShadowVLANConfiguration;