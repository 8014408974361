import { Charges } from '../components/Price/components/TillReceipt/TillReceipt';
import { roundToDecimal } from 'shared/utils/roundToDecimal';
import { IPriceData, IQuote } from 'Quotes/types/store';
import {
  MDIA_ENGINEER_CHARGE,
  MDIA_ENGINEER_COST,
  MDIA_RACK_MOUNT_KIT_CHARGE,
  MDIA_RACK_MOUNT_KIT_COST,
} from 'Quotes/QuoteBuilder/components/Price/components/TillReceiptInternal/TillReceiptInternal';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';

interface CostUtilitiesSelectPriceProps {
  a_end_gea_cablelink_annual_cost?: number | null;
  a_end_gea_cablelink_install_cost?: number | null;
  b_end_gea_cablelink_annual_cost?: number | null;
  b_end_gea_cablelink_install_cost?: number | null;
  margin_amount?: number | null;
  margin_percentage_with_fttp_aggregation?: number | null;
  margin_percentage?: number | null;
  net_amortised_annual_price_with_fttp_aggregation?: number | null;
  net_amortised_annual_price?: number | null;
  net_annual_price_with_fttp_aggregation?: number | null;
  net_annual_price?: number | null;
  total_contract_value_with_fttp_aggregation?: number | null;
  total_contract_value?: number | null;
}

export const totalGEACablelinkCostAnnual = (selectedPrice: CostUtilitiesSelectPriceProps) => {
  return (selectedPrice.a_end_gea_cablelink_annual_cost ?? 0) + (selectedPrice.b_end_gea_cablelink_annual_cost ?? 0);
};

export const totalGEACablelinkCostInstall = (selectedPrice: CostUtilitiesSelectPriceProps) => {
  return (selectedPrice.a_end_gea_cablelink_install_cost ?? 0) + (selectedPrice.b_end_gea_cablelink_install_cost ?? 0);
};

export const roundTo2DecimalPlaces = (marginPercentage: number) => roundToDecimal(marginPercentage * 100, 2);
const toNum = (num: number | null | undefined) => num ?? 0;

const getPreCalculatedPricesFromIPChoice = (secondarySelectedPrice: IPriceData, quote: IQuote) => {
  const secondaryIPChoice = quote.location.aEnd.secondIPChoice;
  if (!secondaryIPChoice) return undefined;
  const subnetPrice = secondarySelectedPrice.subnetPrices?.pre_calculated?.ip_count?.[secondaryIPChoice];
  return {
    grossMarginAmount: subnetPrice?.gross_margin_amount,
    marginPercentage: subnetPrice?.margin_percentage,
    marginPercentageWithFttpAggregation: subnetPrice?.margin_percentage_with_fttp_aggregation,
    netAmortisedAnnualPrice: subnetPrice?.net_amortised_annual_price,
    netAnnualPrice: subnetPrice?.net_annual_price,
    totalContractValue: subnetPrice?.total_contract_value,
    totalContractValueWithFttpAggregation: subnetPrice?.total_contract_value_with_fttp_aggregation,
  };
};

export const getChargesFromSecondarySelectedPrice = (
  secondarySelectedPrice: IPriceData,
  selectedPrice: IPriceData,
  quote: IQuote,
  includeFTTPAggregationCharge: boolean
): Charges => {
  const secondaryIPChoice = quote.location.aEnd.secondIPChoice;
  const options = quote.location.aEnd.secondRouterOptions;
  const mdiaEngineerCharge = options?.engineerInstallationRequired ? MDIA_ENGINEER_CHARGE : 0;
  const mdiaRackMountKitCharge = options?.rackMountKitRequired ? MDIA_RACK_MOUNT_KIT_CHARGE : 0;
  const mdiaExtraCharges = mdiaEngineerCharge + mdiaRackMountKitCharge;
  const mdiaEngineerCost = options?.engineerInstallationRequired ? MDIA_ENGINEER_COST : 0;
  const mdiaRackMountKitCost = options?.rackMountKitRequired ? MDIA_RACK_MOUNT_KIT_COST : 0;
  const mdiaExtraCosts = mdiaEngineerCost + mdiaRackMountKitCost;

  const isAmortised = selectedPrice.amortised ?? true;

  const netAnnualPriceKey = includeFTTPAggregationCharge
    ? 'net_annual_price_with_fttp_aggregation'
    : 'net_annual_price';
  const netAmortisedAnnualPriceKey = includeFTTPAggregationCharge
    ? 'net_amortised_annual_price_with_fttp_aggregation'
    : 'net_amortised_annual_price';
  const marginPercentageKey = includeFTTPAggregationCharge
    ? 'margin_percentage_with_fttp_aggregation'
    : 'margin_percentage';

  const SupportedSecondaryIPChoiceValues = [
    IPType.SUBNET_29,
    IPType.SUBNET_28,
    IPType.SUBNET_27,
    IPType.SUBNET_26,
    IPType.STATIC_8,
    IPType.STATIC_16,
  ];

  const ipChoiceNetAnnualPrice = getPreCalculatedPricesFromIPChoice(secondarySelectedPrice, quote)?.netAnnualPrice;
  const ipChoiceNetAmortisedAnnualPrice = getPreCalculatedPricesFromIPChoice(secondarySelectedPrice, quote)
    ?.netAmortisedAnnualPrice;

  function getNetAnnualPrice() {
    const netAnnualPrice = toNum(secondarySelectedPrice[netAnnualPriceKey]);

    if (secondaryIPChoice === undefined || !SupportedSecondaryIPChoiceValues.includes(secondaryIPChoice)) {
      return netAnnualPrice;
    } else {
      return toNum(ipChoiceNetAnnualPrice);
    }
  }

  function getAmortisedNetAnnualPrice() {
    const netAmorisedAnnualPrice = toNum(secondarySelectedPrice[netAmortisedAnnualPriceKey]);
    if (secondaryIPChoice === undefined || !SupportedSecondaryIPChoiceValues.includes(secondaryIPChoice)) {
      return netAmorisedAnnualPrice;
    } else {
      return toNum(ipChoiceNetAmortisedAnnualPrice);
    }
  }

  const netAnnualPrice = getNetAnnualPrice();
  const netAmortisedAnnualPrice = getAmortisedNetAnnualPrice();
  const setupPrice = toNum(secondarySelectedPrice.setup_price);
  const installDiscount = toNum(secondarySelectedPrice.install_discount);
  const annualDiscount = toNum(secondarySelectedPrice.annual_discount);
  const contractTerm = Number(secondarySelectedPrice.term_length_in_months) / 12;
  const amortisedMdiaCharges = mdiaExtraCharges / contractTerm;
  const totalContractValue =
    netAnnualPrice * contractTerm - annualDiscount + (setupPrice - installDiscount) + mdiaExtraCharges;

  // supplier costs
  const supplierAnnualCost = secondarySelectedPrice.supplier_annual_cost ?? 0;
  const supplierSetupCost = secondarySelectedPrice.supplier_setup_cost ?? 0;

  // annual costs
  const portACostAnnual = toNum(secondarySelectedPrice.port_a_cost?.annual);
  const portBCostAnnual = toNum(secondarySelectedPrice.port_b_cost?.annual);
  const bandwidthCostAnnual = toNum(selectedPrice.bandwidth_cost?.annual);
  const mdiaAnnualCost = toNum(Number(selectedPrice.mdia_annual_cost));
  const totalAnnualCost = supplierAnnualCost + portACostAnnual + portBCostAnnual + bandwidthCostAnnual + mdiaAnnualCost;

  // install costs
  const portACostInstall = toNum(secondarySelectedPrice.port_a_cost?.install);
  const portBCostInstall = toNum(secondarySelectedPrice.port_b_cost?.install);
  const bandwidthCostInstall = toNum(selectedPrice.bandwidth_cost?.install);
  const mdiaInstallCost = toNum(Number(selectedPrice.mdia_router_cost)) + mdiaExtraCosts; // mdia router, mdia engineer, mdia rack mount kit
  const totalInstallCost =
    supplierSetupCost + portACostInstall + portBCostInstall + bandwidthCostInstall + mdiaInstallCost;

  const totalCost = totalAnnualCost * contractTerm + totalInstallCost;
  const marginAmount = totalContractValue - totalCost;

  return {
    annualPrice: netAnnualPrice,
    amortisedPrice: netAmortisedAnnualPrice + (isAmortised ? amortisedMdiaCharges : 0),
    marginPercentage: roundTo2DecimalPlaces(secondarySelectedPrice[marginPercentageKey] ?? 0),
    totalContractValue: totalContractValue,
    marginAmount: marginAmount,
  };
};

export const getChargesFromSelectedPrice = (
  selectedPrice: IPriceData,
  includeFTTPAggregationCharge: boolean
): Charges => {
  if (includeFTTPAggregationCharge) {
    return {
      annualPrice: selectedPrice.net_annual_price_with_fttp_aggregation ?? 0,
      amortisedPrice: selectedPrice.net_amortised_annual_price_with_fttp_aggregation ?? 0,
      marginPercentage: roundTo2DecimalPlaces(selectedPrice.margin_percentage_with_fttp_aggregation ?? 0),
      totalContractValue: selectedPrice.total_contract_value_with_fttp_aggregation ?? 0,
      marginAmount: selectedPrice.margin_amount ?? 0,
    };
  } else {
    return {
      annualPrice: selectedPrice.net_annual_price ?? 0,
      marginPercentage: roundTo2DecimalPlaces(selectedPrice.margin_percentage ?? 0),
      totalContractValue: selectedPrice.total_contract_value ?? 0,
      amortisedPrice: selectedPrice.net_amortised_annual_price ?? 0,
      marginAmount: selectedPrice.margin_amount ?? 0,
    };
  }
};

interface SelectedPriceProps {
  fttp_aggregation_charge?: number | null;
  annual_ip_charge?: number | null;
  mdia_annual_price?: number | null;
  mdia_engineer_price?: number | null;
  mdia_install_price?: number | null;
  mdia_rack_mount_kit_price?: number | null;
  mdia_router_price?: number | null;
  annual_discount?: number | null;
}

export interface AnnualExtras {
  fttpAggregationCharge: number;
  ipCost: number;
  mdiaAnnualPrice: number;
  annualDiscount: number;
}

const monthlyToAnnual = (monthlyCost: number) => monthlyCost * 12;

const ipChargeFactory: Record<IPType, number | undefined> = {
  // cost per month * 12 for annual IPChoice costs
  [IPType.SUBNET_31]: 0,
  [IPType.SUBNET_30]: 0,
  [IPType.SUBNET_29]: monthlyToAnnual(8),
  [IPType.SUBNET_28]: monthlyToAnnual(24),
  [IPType.SUBNET_27]: monthlyToAnnual(48),
  [IPType.SUBNET_26]: monthlyToAnnual(96),
  [IPType.STATIC_16]: monthlyToAnnual(24),
  [IPType.STATIC_8]: monthlyToAnnual(8),
  [IPType.STATIC_4]: 0,
  [IPType.WAN]: 0,
};

export const getPrimaryIPCharge = (quote: IQuote) => {
  const primaryIPChoice = quote.location.aEnd.ip.selectedId;
  if (!primaryIPChoice) return 0;
  return toNum(ipChargeFactory[primaryIPChoice]);
};
export const getSecondaryIPCharge = (quote: IQuote) => {
  const secondaryIPChoice = quote.location.aEnd.secondIPChoice;
  if (!secondaryIPChoice) return 0;
  return toNum(ipChargeFactory[secondaryIPChoice]);
};

export const calculatePrimaryAnnualExtraTotals = (selectedPrice: SelectedPriceProps): AnnualExtras => {
  const keyValues = (key: keyof SelectedPriceProps) => selectedPrice[key] ?? 0;

  return {
    fttpAggregationCharge: keyValues('fttp_aggregation_charge'),
    ipCost: keyValues('annual_ip_charge'),
    mdiaAnnualPrice: keyValues('mdia_annual_price'),
    annualDiscount: keyValues('annual_discount'),
  };
};

export const calculateSecondaryAnnualExtraTotals = (
  secondarySelectedPrice: SelectedPriceProps,
  selectedPrice: SelectedPriceProps,
  quote: IQuote
): AnnualExtras => {
  const secondaryIPChoice = quote.location.aEnd.secondIPChoice;
  const primaryIPCharge = selectedPrice.annual_ip_charge;
  const keyValues = (key: keyof SelectedPriceProps) => secondarySelectedPrice?.[key] ?? 0;
  const fttpAggregationCharge = keyValues('fttp_aggregation_charge');
  const mdiaAnnualPrice = keyValues('mdia_annual_price');
  const annualDiscount = keyValues('annual_discount');

  const ipCost = secondaryIPChoice ? toNum(ipChargeFactory[secondaryIPChoice]) : toNum(primaryIPCharge);

  return {
    fttpAggregationCharge: fttpAggregationCharge,
    ipCost: ipCost,
    mdiaAnnualPrice: mdiaAnnualPrice,
    annualDiscount: annualDiscount,
  };
};

export const calculateCombinedAnnualExtraTotals = (
  selectedPrice: SelectedPriceProps,
  quote: IQuote,
  secondarySelectedPrice?: SelectedPriceProps
): AnnualExtras => {
  const secondaryIPChoice = quote.location.aEnd.secondIPChoice;
  const primaryIPCharge = selectedPrice.annual_ip_charge ?? 0;

  const secondaryIPCharge = secondaryIPChoice ? toNum(ipChargeFactory[secondaryIPChoice]) : toNum(primaryIPCharge);

  const sumValues = (key: keyof SelectedPriceProps) => (selectedPrice[key] ?? 0) + (secondarySelectedPrice?.[key] ?? 0);

  const fttpAggregationCharge = sumValues('fttp_aggregation_charge');
  const mdiaAnnualPrice = sumValues('mdia_annual_price');
  const annualDiscount = sumValues('annual_discount');
  const ipCost = primaryIPCharge + secondaryIPCharge;

  return {
    fttpAggregationCharge: fttpAggregationCharge,
    ipCost: ipCost,
    mdiaAnnualPrice: mdiaAnnualPrice,
    annualDiscount: annualDiscount,
  };
};
