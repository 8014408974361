import React, { FunctionComponent } from 'react';
import { components, OptionProps, MenuProps } from 'react-select';
import styled, { useTheme } from 'styled-components';

import Dropdown, { IDropdownOption } from '../../../../shared/components/molecules/Dropdown';
import Icon from '../../../../shared/components/atoms/Icon';
import SelectAllNoneOptions from './SelectAllNoneOptions';

export interface ICompanySelectorOption extends IDropdownOption {
  enabled: boolean;
}

interface ICompanySelectorProps {
  options: ICompanySelectorOption[];
  onToggle(toggledOption: ICompanySelectorOption, enable: boolean): void;
  onSelectAll(): void;
  onSelectNone(): void;
}

const StyledOption = styled.div`
  position: relative;
  cursor: pointer;

  .add {
    color: ${(props) => props.theme.colours.secondary};
  }

  i {
    position: absolute;
    right: 0.3em;
    top: 0.3em;
  }
`;

const CompanySelector: FunctionComponent<React.PropsWithChildren<ICompanySelectorProps>> = ({
  onToggle,
  options,
  onSelectAll,
  onSelectNone,
}) => {
  const theme = useTheme();

  const allSelected = !options.find(function (option) {
    return !option.enabled;
  });

  const toggleFunc = allSelected ? onSelectNone : onSelectAll;

  const Option = (props: OptionProps<ICompanySelectorOption>) => {
    const onClick = () => onToggle(props.data, !props.data.enabled);

    return (
      <StyledOption className={`company-selector--option-wrapper_${props.data.value}`} onClick={onClick}>
        {props.data.enabled ? <Icon className="remove text-muted" name="close" /> : <Icon className="add" name="add" />}
        <components.Option className={`company-selector--option_${props.data.value}`} {...props} />
      </StyledOption>
    );
  };

  const Menu = (props: MenuProps<IDropdownOption>) => {
    return (
      <components.Menu {...props}>
        <>
          <SelectAllNoneOptions title="Companies" onSelectToggle={toggleFunc} />
          {props.children}
        </>
      </components.Menu>
    );
  };

  return (
    <Dropdown
      className="company-selector"
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      customComponents={{ Menu, Option }}
      isClearable={false}
      options={options.map((company) => ({
        enabled: company.enabled,
        label: company.label,
        value: company.value,
      }))}
      placeholder="Add/Remove Companies"
      styles={{
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused ? theme.colours.primaryB5 : 'inherit',
          color: state.data.enabled ? theme.colours.primaryB1 : theme.colours.grey80,
          cursor: 'pointer',
        }),
      }}
    />
  );
};

export default CompanySelector;
