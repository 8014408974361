import { ProductType } from 'Quotes/types/productTypes';
import { productTypeHasFTTX } from 'Quotes/utils/productTypeHasFTTX';
import React, { FC } from 'react';
import styled from 'styled-components';

const Paragraph = styled.p`
  color: ${(props) => props.theme.colours.grey70};
`;

interface Props {
  productType: ProductType;
}

export const LocationAddressInfo: FC<React.PropsWithChildren<Props>> = ({ productType }) => {
  if (!productTypeHasFTTX(productType)) {
    return null;
  }

  return <Paragraph>An address or location is required for EoFTTP/C results.</Paragraph>;
};
