import React, { FunctionComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { IAppState } from 'reducers';
import {
  setAddressesFoundAEnd,
  setAddressNotListedAEnd,
  setDraftPostcodeAEndAction,
  setFullAddressAEndAction,
  setPostcodeAEndAction,
  setOpenreachAddress as setOpenreachAddressAction,
  setAddressRetrieval,
  switchLocationLookup as switchLocationLookupAction,
} from 'Quotes/actions';
import LocationByPostcode from 'Location/LocationByPostcode';
import { IPAFAddressSite, IOpenReachAddressSite } from 'shared/types/postcodeResults';
import { IOnNetSite } from 'shared/types/onNetSite';
import { AddressesFound, ILocation } from 'Quotes/types/store';
import { ProductType } from 'Quotes/types/productTypes';
import { LocationAddressInfo } from 'Location/LocationAddressInfo';
import { LookupIntentSwitcher } from 'Location/LookupIntentSwitcher';
import { IMeta, LocationLookup } from 'Quotes/types/quoteRecordAttributesBase';
import { LocationByALK } from 'Location/LocationByALK';
import { ISetOpenreachAddress } from 'Quotes/types/actions';

export interface IAEndProps {
  addressesFound: AddressesFound;
  addressNotListed: boolean;
  draftPostcode: string;
  postcode: string;
  fullAddress: IPAFAddressSite | IOnNetSite | null;
  referenceAddress: IPAFAddressSite | IOnNetSite | null;
  openreachAddress: IOpenReachAddressSite | null;
  productType?: ProductType | null;
  title?: string;
  quoteMeta: IMeta;
  setFullAddress(address: IPAFAddressSite | IOnNetSite, onNetType?: ILocation['onNetType']): void;
  setAddressesFound(addresses: AddressesFound): void;
  setAddressNotListed(notListed: boolean): void;
  setDraftPostcode(postcode: string): void;
  setPostcode(postcode: string): void;
  setOpenreachAddress(address: IOpenReachAddressSite): void;
  addressRetrieval(retrieve: boolean, onNetAndPaf: boolean): void;
  switchLocationLookup(lookupType: LocationLookup): void;
}

const AEnd: FunctionComponent<React.PropsWithChildren<IAEndProps>> = ({
  addressesFound,
  addressNotListed,
  addressRetrieval,
  draftPostcode,
  postcode,
  productType,
  fullAddress,
  referenceAddress,
  openreachAddress,
  quoteMeta,
  setFullAddress,
  setAddressesFound,
  setAddressNotListed,
  setDraftPostcode,
  setPostcode,
  setOpenreachAddress,
  switchLocationLookup,
  title,
}) => {
  return (
    <div className="pt-4">
      <h4>{title ? title : 'A-End Location'}</h4>
      <LocationAddressInfo productType={productType!} />
      <LookupIntentSwitcher activeLookupIntent={quoteMeta.a_end_location_lookup} onSwitch={switchLocationLookup} />
      {quoteMeta.a_end_location_lookup !== 'alk' && (
        <LocationByPostcode
          addressRetrieval={addressRetrieval}
          addressesFound={addressesFound}
          addressNotListed={addressNotListed}
          draftPostcode={draftPostcode}
          postcode={postcode}
          productType={productType!}
          selectedAddress={fullAddress}
          referenceAddress={referenceAddress}
          selectedOpenreachAddress={openreachAddress}
          onAddressesFound={setAddressesFound}
          onAddressNotListed={setAddressNotListed}
          onDraftPostcodeChange={setDraftPostcode}
          onPostcodeSubmit={setPostcode}
          onFullAddressSubmit={setFullAddress}
          onOpenreachAddressSubmit={setOpenreachAddress}
        />
      )}
      {quoteMeta.a_end_location_lookup === 'alk' && (
        <LocationByALK
          addressRetrieval={addressRetrieval}
          onALKAddressSubmit={setOpenreachAddress}
          onPostcodeSubmit={setPostcode}
          onDraftPostcodeSubmit={setDraftPostcode}
          selectedOpenreachAddress={openreachAddress}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  setAddressNotListed: (notListed: boolean) => dispatch(setAddressNotListedAEnd(notListed)),
  setAddressesFound: (addresses: AddressesFound) => dispatch(setAddressesFoundAEnd(addresses)),
  setDraftPostcode: (postcode: string) => dispatch(setDraftPostcodeAEndAction(postcode)),
  setFullAddress: (fullAddress: IPAFAddressSite | IOnNetSite, onNetSite?: ILocation['onNetType']) =>
    dispatch(setFullAddressAEndAction(fullAddress, onNetSite)),
  setPostcode: (postcode: string) => dispatch(setPostcodeAEndAction(postcode)),
  setOpenreachAddress: (address: ISetOpenreachAddress['payload']['address']) =>
    dispatch(setOpenreachAddressAction('A', address)),
  addressRetrieval: (retrieve: boolean, onNetAndPaf: boolean) =>
    dispatch(setAddressRetrieval(true, onNetAndPaf, retrieve)),
  switchLocationLookup: (lookupType: LocationLookup) => dispatch(switchLocationLookupAction('A', lookupType)),
});

const mapStateToProps = (state: IAppState) => ({
  addressNotListed: state.quoteBuilder.quoteEndpointMeta.a_end_address_not_listed || false,
  addressesFound: state.quoteBuilder.quote.location.aEnd.addressesFound,
  draftPostcode: state.quoteBuilder.quote.location.aEnd.draftPostcode,
  postcode: state.quoteBuilder.quote.location.aEnd.postcode,
  fullAddress: state.quoteBuilder.quote.location.aEnd.fullAddress,
  referenceAddress: state.quoteBuilder.quote.location.bEnd.fullAddress,
  openreachAddress: state.quoteBuilder.quote.location.aEnd.openreachAddress,
  productType: state.quoteBuilder.quote.productType,
  quoteMeta: state.quoteBuilder.quoteEndpointMeta,
});

export { AEnd };
export default connect(mapStateToProps, mapDispatchToProps)(AEnd);
