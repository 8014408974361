import React, { FunctionComponent } from 'react';
import RadioButton from 'shared/components/molecules/RadioButton';
import { setBandwidthAction, setQuoteConnectionType, toggleChosenBandwidth } from 'Quotes/actions';
import { FTTXConnectionType, fttxConnectionTypes } from 'Quotes/types/connectionType';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBandwidth,
  selectChosenBandwidths,
  selectConnectionType,
  selectFTTXCheckingAvailability,
  selectLocationsContainOnNetAddress,
  selectProductType,
  selectQuote,
} from 'Quotes/selectors';
import AvailabilityMessage from 'Quotes/QuoteBuilder/components/Configure/AvailabilityMessage';
import { productTypeHasFTTX } from 'Quotes/utils/productTypeHasFTTX';
import getFttxBandwidthTooltipText from 'Quotes/QuoteBuilder/components/Configure/Bandwidth/utils/getFttxBandwidthTooltipText';
import styled from 'styled-components';
import CheckboxButton from 'shared/components/molecules/CheckboxButton';
import { Option, OptionGroup } from 'shared/components/organisms/OptionGroup/OptionGroup';
import { canToggle } from 'Quotes/QuoteBuilder/utils/canToggle';
import { MAX_SELECTABLE_BANDWIDTHS } from 'Quotes/reducer';

const BandwidthWrapper = styled.div`
  > div {
    margin-bottom: 1.25em;
  }
`;

export const FTTX: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const connectionType = useSelector(selectConnectionType);
  const dispatch = useDispatch();
  const quote = useSelector(selectQuote);
  const checkingAvailability = useSelector(selectFTTXCheckingAvailability);
  const hasOnNetLocation = useSelector(selectLocationsContainOnNetAddress);
  const productType = useSelector(selectProductType);
  const selectedBandwidth = useSelector(selectBandwidth);
  const chosenBandwidths = useSelector(selectChosenBandwidths);

  const connectionTypeOptions: Option<FTTXConnectionType>[] = fttxConnectionTypes.map((item) => ({
    customId: `bearer--${item}`,
    label: item,
    value: item as FTTXConnectionType,
    tooltip:
      item !== quote.availability?.overall ? 'This option is unavailable based on your current selections' : undefined,
    disabled: item !== quote.availability?.overall,
  }));

  const toggleChosenBandwidthWithLimitation = (bandwidth: string) => {
    if (canToggle(bandwidth, chosenBandwidths, MAX_SELECTABLE_BANDWIDTHS)) {
      dispatch(toggleChosenBandwidth(bandwidth));
    }
  };

  return (
    <>
      <div className="pb-4">
        {productType && productTypeHasFTTX(productType) && !hasOnNetLocation && (
          <AvailabilityMessage
            requiresAvailabilityCheck={true}
            error={!!checkingAvailability.error}
            isLoading={checkingAvailability.inProgress}
            availability={quote.availability?.overall}
            journey="by_location"
          />
        )}
      </div>

      <div className="pb-4">
        <OptionGroup
          options={connectionTypeOptions}
          onClick={(value) => {
            dispatch(setQuoteConnectionType(value));
          }}
          value={connectionType}
        />
      </div>

      {(quote.availability?.bandwidths || []).length === 0 && (
        <div>
          <small className="text-muted d-inline-block pt-4">Bandwidths not yet available.</small>
        </div>
      )}

      <BandwidthWrapper>
        {quote.availability &&
          (quote.availability?.bandwidths || []).map((fttxBandwidth) => {
            if (quote.availability?.overall === 'EoFTTC') {
              return (
                <RadioButton
                  inline
                  size="large"
                  className="bandwidth--radioButton"
                  description={fttxBandwidth.display_name}
                  id={`bandwidth--${fttxBandwidth.value}`}
                  key={`bandwidth--${fttxBandwidth.value}`}
                  onClick={() => {
                    dispatch(setBandwidthAction(fttxBandwidth.value));
                  }}
                  status={selectedBandwidth === fttxBandwidth.value ? 'selected' : 'notSelected'}
                  tooltipText={
                    selectedBandwidth === fttxBandwidth.value && connectionType === 'Ethernet'
                      ? 'This option is unavailable based on your current selections'
                      : undefined
                  }
                  disabled={connectionType === 'Ethernet'}
                />
              );
            } else {
              return (
                <CheckboxButton
                  inline
                  size="large"
                  description={fttxBandwidth.display_name}
                  id={`bandwidth--${fttxBandwidth.value}`}
                  key={`bandwidth--${fttxBandwidth.value}`}
                  onClick={() => {
                    toggleChosenBandwidthWithLimitation(fttxBandwidth.value);
                  }}
                  status={chosenBandwidths.includes(fttxBandwidth.value) ? 'selected' : 'notSelected'}
                  disabled={connectionType === 'Ethernet'}
                  tooltipText={
                    chosenBandwidths.includes(fttxBandwidth.value)
                      ? getFttxBandwidthTooltipText(fttxBandwidth.value, quote.availability?.bandwidths)
                      : connectionType === 'Ethernet'
                      ? 'This option is unavailable based on your current selections'
                      : undefined
                  }
                />
              );
            }
          })}
      </BandwidthWrapper>
    </>
  );
};
