import { doRequest, Methods } from 'Request';
import IQuoteRequestBody, { IAEndDataCenterAttributes, IBEndDataCenterAttributes } from 'Quotes/types/quoteRequestBody';
import { AddressType, IAEndLocation, ILocation, IQuote, ProviderName, QuoteOrigin } from 'Quotes/types/store';
import { IMeta, IQuoteRecordAttributesBase } from 'Quotes/types/quoteRecordAttributesBase';
import { ProductType } from 'Quotes/types/productTypes';
import { CompanyData } from 'User/types/user';
import { IOnNetSite, isOnNetSite } from 'shared/types/onNetSite';
import { IOpenReachAddressSite, IPAFAddress, IPAFAddressSite } from 'shared/types/postcodeResults';
import { isFTTXCompatibleOpenreachLocation } from 'shared/utils/addresses/helperFunctions';
import { getUpdateValues } from 'Quotes/QuoteBuilder/components/Configure';
import { Journey } from 'Quotes/types/actions';
import { isFTTXConnection } from 'Quotes/types/connectionType';
import { isNNATEnabledFor } from 'FeatureFlags/nnatUtils';

const getDiversityByProviderName = (providerName: ProviderName): false | null => {
  if (providerName === ProviderName.AWS) {
    return false;
  } else {
    return null;
  }
};

const getAEndAttributes = (
  aEnd: IAEndLocation,
  clone: boolean,
  productType: ProductType | null | undefined,
  origin: QuoteOrigin
) => {
  let aEndAttributes: Partial<IQuoteRecordAttributesBase> = {};

  if (aEnd.fullAddress !== null) {
    if (aEnd.addressType === AddressType.PAF || aEnd.addressType === AddressType.OPENREACH) {
      const fullAddress = aEnd.fullAddress.attributes as IPAFAddress;

      aEndAttributes.a_end_udprn = fullAddress.udprn || '';
      aEndAttributes.a_end_paf_organisation_name = fullAddress.organisation_name || '';
      aEndAttributes.a_end_building_name = fullAddress.building_name || '';
      aEndAttributes.a_end_building_number = fullAddress.building_number || '';
      aEndAttributes.a_end_sub_building = fullAddress.sub_building || '';
      aEndAttributes.a_end_full_site_county = fullAddress.county || '';
      aEndAttributes.a_end_full_site_town = fullAddress.post_town || '';
      aEndAttributes.a_end_full_site_thoroughfare = fullAddress.thoroughfare || '';
    } else {
      const fullAddress = aEnd.fullAddress.attributes;

      aEndAttributes.a_end_building_name = fullAddress.address_1 || '';
      aEndAttributes.a_end_full_site_county = fullAddress.county || '';
      aEndAttributes.a_end_full_site_town = fullAddress.town || '';
      aEndAttributes.a_end_full_site_thoroughfare = fullAddress.address_2 || '';

      aEndAttributes.a_end_udprn = '';
      aEndAttributes.a_end_paf_organisation_name = '';
      aEndAttributes.a_end_building_number = '';
      aEndAttributes.a_end_sub_building = '';

      if (origin !== QuoteOrigin.BULK && !isFTTXCompatibleOpenreachLocation(aEnd.openreachAddress)) {
        aEndAttributes.a_end_openreach_address = null;
      }
    }

    if (productType === ProductType.DIA && aEnd.ip.selectedId) {
      aEndAttributes.ip_count = aEnd.ip.selectedId;
    }
  } else {
    aEndAttributes.a_end_building_name = '';
    aEndAttributes.a_end_full_site_county = '';
    aEndAttributes.a_end_full_site_town = '';
    aEndAttributes.a_end_full_site_thoroughfare = '';
    aEndAttributes.a_end_udprn = '';
    aEndAttributes.a_end_paf_organisation_name = '';
    aEndAttributes.a_end_building_number = '';
    aEndAttributes.a_end_sub_building = '';
  }

  if (productType === ProductType.P2NNI || productType === ProductType.NNI2CCT) {
    aEndAttributes = {
      ...aEndAttributes,
      ...getNNIAttributes(aEnd),
    };
  }

  if (productType === ProductType.P2CCT || productType === ProductType.NNI2CCT) {
    aEndAttributes.cloud_connect_option = {
      provider: aEnd.cloudConnect.name,
      diversified: clone ? aEnd.cloudConnect.diversified : getDiversityByProviderName(aEnd.cloudConnect.name),
    };
  }

  return aEndAttributes;
};

const getBEndAttributes = (bEnd: ILocation, origin: QuoteOrigin) => {
  const bEndAttributes: Partial<IQuoteRecordAttributesBase> = {};

  if (bEnd.fullAddress !== null) {
    if (bEnd.addressType === AddressType.PAF || bEnd.addressType === AddressType.OPENREACH) {
      const fullAddress = bEnd.fullAddress.attributes as IPAFAddress;

      bEndAttributes.b_end_udprn = fullAddress.udprn || '';
      bEndAttributes.b_end_paf_organisation_name = fullAddress.organisation_name || '';
      bEndAttributes.b_end_building_name = fullAddress.building_name || '';
      bEndAttributes.b_end_building_number = fullAddress.building_number || '';
      bEndAttributes.b_end_sub_building = fullAddress.sub_building || '';
      bEndAttributes.b_end_full_site_county = fullAddress.county || '';
      bEndAttributes.b_end_full_site_town = fullAddress.post_town || '';
      bEndAttributes.b_end_full_site_thoroughfare = fullAddress.thoroughfare || '';
    } else {
      const fullAddress = bEnd.fullAddress.attributes;

      bEndAttributes.b_end_building_name = fullAddress.address_1 || '';
      bEndAttributes.b_end_full_site_county = fullAddress.county || '';
      bEndAttributes.b_end_full_site_town = fullAddress.town || '';
      bEndAttributes.b_end_full_site_thoroughfare = fullAddress.address_2 || '';

      bEndAttributes.b_end_udprn = '';
      bEndAttributes.b_end_paf_organisation_name = '';
      bEndAttributes.b_end_building_number = '';
      bEndAttributes.b_end_sub_building = '';

      if (origin !== QuoteOrigin.BULK && !isFTTXCompatibleOpenreachLocation(bEnd.openreachAddress)) {
        bEndAttributes.b_end_openreach_address = null;
      }
    }
  } else {
    bEndAttributes.b_end_building_name = '';
    bEndAttributes.b_end_full_site_county = '';
    bEndAttributes.b_end_full_site_town = '';
    bEndAttributes.b_end_full_site_thoroughfare = '';
    bEndAttributes.b_end_udprn = '';
    bEndAttributes.b_end_paf_organisation_name = '';
    bEndAttributes.b_end_building_number = '';
    bEndAttributes.b_end_sub_building = '';
  }

  return bEndAttributes;
};

const getNNIAttributes = (aEnd: IAEndLocation): IQuoteRecordAttributesBase => {
  return {
    a_end_n_n_i_id: aEnd.nni.selectedId || '',
    a_end_data_centre_id: aEnd.nni.selectedDataCentreId || '',

    a_end_n_n_i_reference: aEnd.nni.reference || '',
    a_end_data_centre_reference: aEnd.nni.popName || '',

    a_end_n_n_i_shadow_vlan_id: aEnd.nni.shadowVLAN.selectedId || '',

    a_end_n_n_i_shadow_vlan_data_centre_id: aEnd.nni.shadowVLAN.selectedDataCentreId || '',

    shadow_vlan_pop_name: aEnd.nni.shadowVLAN.name || '',
    shadow_vlan_pop_address: aEnd.nni.shadowVLAN.location || '',
  };
};

const getAEndDataCenterAttributes = (aEnd: ILocation, clone: boolean, productType: ProductType | null | undefined) => {
  const aEndDataCenterAttributes: Partial<IAEndDataCenterAttributes> = {};

  if (aEnd.dataCentreReference) {
    aEndDataCenterAttributes.a_end_data_centre_reference = aEnd.dataCentreReference;
  }

  if (productType === ProductType.OpticalP2P && aEnd.optical.selectedId) {
    aEndDataCenterAttributes.a_end_data_centre_id = aEnd.optical.selectedId;
    aEndDataCenterAttributes.a_end_data_centre_name = aEnd.fullAddress?.attributes.name;
  } else if (aEnd.fullAddress !== null && isOnNetSite(aEnd.fullAddress)) {
    const fullAddress = aEnd.fullAddress.attributes;

    if (aEnd.onNetType === 'sse' || (clone && aEnd.fullAddress.attributes.pop_id)) {
      aEndDataCenterAttributes.a_end_data_centre_id = fullAddress.pop_id;
      aEndDataCenterAttributes.a_end_colt_building_id = null;
    } else if (aEnd.onNetType === 'colt' || (clone && aEnd.fullAddress.attributes.colt_building_id)) {
      aEndDataCenterAttributes.a_end_colt_building_id = fullAddress.colt_building_id;
      aEndDataCenterAttributes.a_end_data_centre_id = null;
    }
  } else {
    aEndDataCenterAttributes.a_end_colt_building_id = null;

    // `getNNIAttributes` already populates `a_end_data_centre_id` - don't override.
    if (productType !== ProductType.P2NNI && productType !== ProductType.NNI2CCT) {
      aEndDataCenterAttributes.a_end_data_centre_id = null;
    }
  }
  return aEndDataCenterAttributes;
};

const getBEndDataCenterAttributes = (bEnd: ILocation, clone: boolean, productType: ProductType | null | undefined) => {
  const bEndDataCenterAttributes: Partial<IBEndDataCenterAttributes> = {};

  if (bEnd.dataCentreReference) {
    bEndDataCenterAttributes.b_end_data_centre_reference = bEnd.dataCentreReference;
  }

  if (productType === ProductType.OpticalP2P && bEnd.optical.selectedId) {
    bEndDataCenterAttributes.b_end_data_centre_id = bEnd.optical.selectedId;
    bEndDataCenterAttributes.b_end_data_centre_name = bEnd.fullAddress?.attributes.name;
  } else if (bEnd.fullAddress !== null && isOnNetSite(bEnd.fullAddress)) {
    const fullAddress = bEnd.fullAddress.attributes;

    if (bEnd.onNetType === 'sse' || (clone && bEnd.fullAddress.attributes.pop_id)) {
      bEndDataCenterAttributes.b_end_data_centre_id = fullAddress.pop_id;
      bEndDataCenterAttributes.b_end_colt_building_id = null;
    } else if (bEnd.onNetType === 'colt' || (clone && bEnd.fullAddress.attributes.colt_building_id)) {
      bEndDataCenterAttributes.b_end_colt_building_id = fullAddress.colt_building_id;
      bEndDataCenterAttributes.b_end_data_centre_id = null;
    }
  } else {
    bEndDataCenterAttributes.b_end_colt_building_id = null;
    bEndDataCenterAttributes.b_end_data_centre_id = null;
  }
  return bEndDataCenterAttributes;
};

const getMeta = (quote: IQuote, meta: IMeta, clone: boolean) => {
  const metaFields: Partial<IMeta> = { ...meta };

  if (clone) {
    delete metaFields.cabling_type_selection;
  }

  const { aEnd, bEnd } = quote.location;
  metaFields.nni_label = meta.nni_label;

  if (aEnd.fullAddress !== null && aEnd.addressType === AddressType.ON_NET) {
    const fullAddress = aEnd.fullAddress.attributes;

    metaFields.a_end_on_net_location = fullAddress.location;
    metaFields.a_end_on_net_reference = fullAddress.reference;
    metaFields.a_end_on_net_name = fullAddress.name;
  } else {
    metaFields.a_end_on_net_location = null;
    metaFields.a_end_on_net_reference = null;
    metaFields.a_end_on_net_name = null;
  }

  if (bEnd.fullAddress !== null && bEnd.addressType === AddressType.ON_NET) {
    const fullAddress = bEnd.fullAddress.attributes;

    metaFields.b_end_on_net_location = fullAddress.location;
    metaFields.b_end_on_net_reference = fullAddress.reference;
    metaFields.b_end_on_net_name = fullAddress.name;
  } else {
    metaFields.b_end_on_net_location = null;
    metaFields.b_end_on_net_reference = null;
    metaFields.b_end_on_net_name = null;
  }

  return metaFields;
};

export const getSSEOnNetExclusion = (locationA: ILocation, locationB: ILocation, quote: IQuote, clone: boolean) => ({
  a_end_exclude_sse_onnet: clone ? quote.aEndExcludeSSEOnNet : locationA.onNetType === 'sse' ? false : true,
  b_end_exclude_sse_onnet: clone ? quote.bEndExcludeSSEOnNet : locationB.onNetType === 'sse' ? false : true,
});

const getOpenReachAddress = (openreachAddress: IOpenReachAddressSite | null) => {
  if (openreachAddress === null) {
    return null;
  } else {
    const { attributes } = openreachAddress;
    return {
      full_site_alk: attributes.alk,
      full_site_match_type: attributes.qualifier,
      full_site_building_name: attributes.building_name,
      full_site_building_number: attributes.building_number,
      full_site_sub_building: attributes.sub_building,
      full_site_street: attributes.street,
      full_site_town: attributes.post_town,
      full_site_county: attributes.county,
      full_site_district_code: attributes.css_district_code,
    };
  }
};

const isPAFAddress = (address: IPAFAddressSite | IOnNetSite | null): address is IPAFAddressSite => {
  const paf = address as IPAFAddressSite | null;
  return paf?.attributes.organisation_name !== undefined;
};

const getPafAddressId = (end: ILocation, productType: ProductType | undefined): string | undefined => {
  if (isNNATEnabledFor(productType) && isPAFAddress(end.fullAddress)) {
    return end.fullAddress?.id;
  } else {
    return undefined;
  }
};

export const getBaseAttributes = (
  quote: IQuote,
  meta: IMeta,
  companyData: CompanyData,
  clone = false,
  isInternalUser = false
): IQuoteRecordAttributesBase => {
  const { aEnd, bEnd } = quote.location;

  const data = {
    meta: getMeta(quote, meta, clone),
    a_end_paf_address_id: getPafAddressId(aEnd, quote.productType || undefined),
    b_end_paf_address_id: getPafAddressId(bEnd, quote.productType || undefined),
    a_end_postcode: aEnd.postcode.toUpperCase(),
    b_end_postcode: bEnd.postcode.toUpperCase(),
    a_end_address_type: aEnd.addressType,
    b_end_address_type: bEnd.addressType,
    a_end_openreach_address: getOpenReachAddress(quote.location.aEnd.openreachAddress),
    b_end_openreach_address: getOpenReachAddress(quote.location.bEnd.openreachAddress),
    a_end_port: quote.bearer,
    b_end_port: quote.bearer,
    customer_id: companyData.selectedCompanyId!,
    path_speed: quote.chosen_bandwidths[0] || quote.bandwidth, // BE expects path_speed to be set, even though we populate chosen_bandwidths instead.
    chosen_bandwidths: quote.chosen_bandwidths,
    chosen_term_lengths_in_months: quote.chosen_term_lengths_in_months,
    term_length_in_months: quote.contractTerm,
    ...getSSEOnNetExclusion(aEnd, bEnd, quote, clone),
    ...getAEndAttributes(aEnd, clone, quote.productType, quote.origin),
    ...getBEndAttributes(bEnd, quote.origin),
    product_type: quote.productType || undefined,
    ...getAEndDataCenterAttributes(aEnd, clone, quote.productType),
    ...getBEndDataCenterAttributes(bEnd, clone, quote.productType),
    is_internal: clone ? quote.is_internal : isInternalUser,
    bulk_request_id: quote.bulkQuoteId,
  };

  // set DIA fields only if the bearer is already set
  // to ensure that the user had already made a selection
  if (quote.productType === ProductType.DIA && (quote.bearer || isFTTXConnection(quote.connectionType))) {
    delete data.b_end_port;
    data.is_managed_dia = quote.location.aEnd.is_managed_dia || false;

    if (quote.location.aEnd.dia_ip_allocation && !data.is_managed_dia)
      data.dia_ip_allocation = quote.location.aEnd.dia_ip_allocation;

    data.is_rack_mount_required = quote.location.aEnd.is_rack_mount_required;
    data.is_engineer_installation_required = quote.location.aEnd.is_engineer_installation_required;
  }

  if (meta?.journey_origin === 'by_location' || clone) {
    data.a_end_access_method = quote.aEndAccessMethod || undefined;
    data.b_end_access_method = quote.bEndAccessMethod || undefined;
  }

  return data;
};

const createQuote = (
  quote: IQuote,
  meta: IMeta,
  companyData: CompanyData,
  clone = false,
  isInternalUser = false,
  journey = 'by_product_type' as Journey
) => {
  const attributes = getBaseAttributes(quote, meta, companyData, clone, isInternalUser);

  const body: IQuoteRequestBody = {
    data: {
      attributes: journey === 'by_location' ? getUpdateValues(quote, attributes) : attributes,
      type: 'quote',
    },
  };

  return doRequest({
    body,
    method: Methods.POST,
    path: '/quotes',
  });
};

export default createQuote;
