import { ApiRecord, MaybeString } from 'shared/types/ApiRecord';
import { IQuoteData, IQuoteListItem } from 'Quotes/types/quoteRecord';
import { NoteResponse } from './note';
import { AddressType } from 'Quotes/types/store';
import { IPricedQuote } from 'Quotes/types/pricedQuote';
import { ProductType } from 'Quotes/types/productTypes';
import { ConnectionType } from 'Quotes/types/connectionType';
import { APINewNNIRequest, AsbestosRegister, SiteType } from './location';
import { Qualifier } from 'shared/types/postcodeResults';
import { DownstreamResponse } from './downstreamResponse';

import { MessageResponseData } from 'shared/types/Messages';
import { CerillionOrderStageHistory } from './store';
import { APISecondarySiteConfig } from 'Order/crud/updateOrder';

export const type = 'order';

export enum OrderStatus {
  DRAFT = 'draft',
  READY = 'ready',
  IN_REVIEW = 'in_review',
  RETURNED = 'returned',
  CANCELLED = 'cancelled',
  PLACED = 'placed',
}

export enum CerillionStatus {
  FAILED = 'failed',
  PARTIAL = 'partial',
  SUCCESS = 'success',
}

/** @deprecated Use VLAN_ID_INTERNALLY_ASSIGNED instead. */
export const LEGACY_VLAN_ID_INTERNALLY_ASSIGNED = 'SSE_ASSIGNED';
export const VLAN_ID_INTERNALLY_ASSIGNED = 'NEOS_ASSIGNED';

export type StateHistory = Array<{
  state: OrderStatus;
  created_at: string;
  updated_by: string;
}>;

export enum BillingFrequency {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  QuarterlyArrears = 'quarterly-arrears',
}

export interface SecondaryIPInformation {
  ip_text: string;
  ip_contact_nic_ripe_handle: string;
  end: string;
}

export type IOrderDataAttributes = {
  accepted_at?: string;
  accepted_by?: string;
  updated_at: string;
  created_at: string;
  quote_id: string;
  quote_lq_id?: string | null;
  short_id: string;
  customer_name: MaybeString;
  /** @deprecated */
  contact_name?: string;
  contact_first_name?: string;
  contact_surname?: string;
  contact_telephone?: string;
  contact_email?: string;
  /** @deprecated */
  service_ops_name?: string;
  service_ops_first_name?: string;
  service_ops_surname?: string;
  service_ops_telephone?: string;
  service_ops_email?: string;
  /** @deprecated */
  order_delivery_name?: string;
  order_delivery_first_name?: string;
  order_delivery_surname?: string;
  order_delivery_telephone?: string;
  order_delivery_email?: string;
  /** @deprecated */
  billing_contact_name?: string;
  billing_contact_first_name?: string;
  billing_contact_surname?: string;
  billing_contact_telephone: string | undefined;
  billing_contact_email: string | undefined;
  billing_contact_address: string | undefined;
  billing_frequency?: BillingFrequency;
  /** @deprecated */
  ip_contact_name?: string;
  ip_contact_organisation?: string;
  ip_contact_first_name?: string;
  ip_contact_surname?: string;
  ip_contact_address?: string;
  ip_contact_email?: string;
  ip_contact_nic_ripe_handle?: string;
  ip_contact_telephone?: string;
  ip_text?: string;
  secondary_circuits_ip_information?: SecondaryIPInformation[] | undefined;
  customer_reference: string | undefined;
  placed_at?: string | null;
  submitted_for_review_at?: string | null;
  a_end_company_name?: string | null;
  b_end_company_name?: string | null;
  a_end_full_site_address?: string | null;
  b_end_full_site_address?: string | null;
  a_end_full_site_address_type?: AddressType | null;
  b_end_full_site_address_type?: AddressType | null;
  a_end_full_site_alk: string | null;
  a_end_full_site_match_type?: Qualifier | null;
  a_end_full_site_building_name: MaybeString;
  a_end_full_site_building_number: MaybeString;
  a_end_full_site_sub_building: MaybeString;
  a_end_full_site_street: MaybeString;
  a_end_full_site_town: MaybeString;
  a_end_full_site_county: MaybeString;
  a_end_full_site_postal_code: string;
  a_end_n_n_i_reference?: string | null;
  a_end_site_notes?: string;
  /** @deprecated */
  a_end_site_contact_name?: string;
  a_end_site_contact_first_name?: string;
  a_end_site_contact_surname?: string;
  a_end_site_contact_telephone?: string;
  a_end_site_contact_email?: string;
  a_end_site_type: SiteType | null;
  a_end_access_required_prior_notice?: string | null;
  a_end_any_hazard?: string | null;
  a_end_hazard_description?: string | null;
  a_end_asbestos_register?: AsbestosRegister | null;
  a_end_more_than_one_tenant?: string | null;
  a_end_auto_negotiation?: MaybeString;
  a_end_duplexity?: string | null;
  a_end_vlan_tagging?: string | null;
  a_end_vlan_id?: string | null;
  a_end_floor?: MaybeString;
  a_end_room?: MaybeString;
  a_end_suite?: MaybeString;
  a_end_rack?: MaybeString;
  a_end_media_type?: MaybeString;
  a_end_connector_type?: MaybeString;
  a_end_power?: MaybeString;
  a_end_latitude?: string | null;
  a_end_longitude?: string | null;
  a_end_easting?: string | null;
  a_end_northing?: string | null;
  b_end_full_site_alk: string | null;
  b_end_full_site_match_type?: Qualifier | null;
  b_end_full_site_building_name: MaybeString;
  b_end_full_site_building_number: MaybeString;
  b_end_full_site_sub_building: MaybeString;
  b_end_full_site_street: MaybeString;
  b_end_full_site_town: MaybeString;
  b_end_full_site_county: MaybeString;
  b_end_full_site_postal_code: string;
  b_end_access_required_prior_notice?: string | null;
  b_end_any_hazard?: string | null;
  b_end_hazard_description?: string | null;
  b_end_asbestos_register?: AsbestosRegister | null;
  b_end_more_than_one_tenant?: string | null;
  b_end_site_notes?: string;
  /** @deprecated */
  b_end_site_contact_name?: string;
  b_end_site_contact_first_name?: string;
  b_end_site_contact_surname?: string;
  b_end_site_contact_telephone?: string;
  b_end_site_contact_email?: string;
  b_end_site_type: SiteType | null;
  b_end_auto_negotiation?: MaybeString;
  b_end_duplexity?: string | null;
  b_end_vlan_tagging?: string | null;
  b_end_vlan_id?: string | null;
  b_end_floor?: MaybeString;
  b_end_room?: MaybeString;
  b_end_suite?: MaybeString;
  b_end_rack?: MaybeString;
  b_end_media_type?: MaybeString;
  b_end_connector_type?: MaybeString;
  b_end_power?: MaybeString;
  b_end_latitude?: string | null;
  b_end_longitude?: string | null;
  b_end_easting?: string | null;
  b_end_northing?: string | null;
  placed_by?: string | null;
  submitted_for_review_by?: string | null;
  updated_by?: string | null;
  shadow_vlan?: string | null;
  shadow_vlan_n_n_i?: string;
  a_end_access_method?: ConnectionType | null;
  b_end_access_method?: ConnectionType | null;
  a_end_gea_cablelink_id: string | null;
  b_end_gea_cablelink_id: string | null;
  meta?: {
    a_end_PAF_address_not_listed: boolean;
    b_end_PAF_address_not_listed: boolean;
    a_end_full_address_not_listed: boolean;
    b_end_full_address_not_listed: boolean;
    a_end_land_owner_permission_required?: boolean | null;
    b_end_land_owner_permission_required?: boolean | null;
    a_end_on_net_location?: string | null;
    b_end_on_net_location?: string | null;
    a_end_on_net_reference?: string | null;
    b_end_on_net_reference?: string | null;
    a_end_on_net_name?: string | null;
    b_end_on_net_name?: string | null;
    a_end_PAF_full_site_udprn?: string | null;
    a_end_PAF_organisation_name?: string | null;
    a_end_PAF_building_name?: string | null;
    a_end_PAF_building_number?: string | null;
    a_end_PAF_county?: string | null;
    a_end_PAF_post_town?: string | null;
    a_end_PAF_postcode?: string | null;
    a_end_PAF_thoroughfare?: string | null;
    a_end_PAF_sub_building?: string | null;
    b_end_PAF_full_site_udprn?: string | null;
    b_end_PAF_organisation_name?: string | null;
    b_end_PAF_building_name?: string | null;
    b_end_PAF_building_number?: string | null;
    b_end_PAF_county?: string | null;
    b_end_PAF_post_town?: string | null;
    b_end_PAF_postcode?: string | null;
    b_end_PAF_thoroughfare?: string | null;
    b_end_PAF_sub_building?: string | null;
  };
  cloud_connect_configuration: {
    keys: {
      primary: string;
      secondary: string;
    };
    microsoft_peering: string;
    private_peering: string;
  };
  notes?: NoteResponse[];
  product_type: ProductType | null;
  state: OrderStatus;
  state_history: StateHistory;
  nni_job_reference: null | string;
  nni_idn_number: null | string;
  shadow_nni_job_reference: null | string;
  shadow_nni_idn_number: null | string;
  wan_ip_in_addition: boolean | null;
  cerillion_basket_id: string | null;
  cerillion_status: CerillionStatus | null;
  cerillion_stage?: CerillionStage | null;
  cerillion_stage_history?: CerillionOrderStageHistory | null;
  cerillion_order_status_message?: string | null;
  new_nni_requests?: APINewNNIRequest[];
  secondary_circuits_site_configs?: APISecondarySiteConfig[];
};

export enum CerillionStage {
  PENDING = 'pending',
  PLACED = 'placed',
  ACCEPTANCE = 'acceptance',
  INITIAL_PLANNING = 'initial_planning',
  COMMITTING_DELIVERY_DATE = 'committing_delivery_date',
  PHYSICAL_WORKS = 'physical_works',
  CONFIRMING_HANDOVER_DATE = 'confirming_handover_date',
}

type OrderListAdditionalData = {
  selected_price: IPricedQuote | null;
};

export interface IOrdersResponseData {
  id: string;
  attributes: IOrderDataAttributes & OrderListAdditionalData;
  relationships?: {
    quotes?: {
      data: {
        id: string;
      };
    };
  };
}

export type IOrderListItem = IOrderDataAttributes &
  OrderListAdditionalData & {
    id: string;
    quote?: IQuoteListItem;
  };

type IOrderRecord = ApiRecord & {
  data: {
    attributes: IOrderDataAttributes;
    relationships?: Record<string, Record<string, any>>;
  };
  included?: DownstreamResponse[] | MessageResponseData[];
};

export type IOrdersResponse = {
  data: IOrdersResponseData[];
  included: IQuoteData[];
};

export default IOrderRecord;
