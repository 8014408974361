import React, { FunctionComponent, useEffect } from 'react';
import SpinnerWithText from '../../shared/components/molecules/SpinnerWithText';
import Alert from '../../shared/components/atoms/Alert';
import { IAppState } from '../../reducers';
import { connect, DispatchProp } from 'react-redux';
import { getAvailableAccessMethods as getAvailableAccessMethodsAction } from '../actions';

export const ERROR_MESSAGE = 'Error in fetching availability';

interface IAvailabilityCheck {
  isLoading: boolean;
  error: boolean;
}

interface IEnhancedAvailabilityCheck extends IAvailabilityCheck {
  getAvailableAccessMethods: () => void;
}

export const AvailabilityCheck: FunctionComponent<React.PropsWithChildren<IAvailabilityCheck>> = ({
  isLoading,
  error,
}) => {
  return (
    <div className="availability-check mt-3">
      {isLoading && (
        <SpinnerWithText inline size="large" text="Checking available access methods..." id="availability-loading" />
      )}
      {error && <Alert>{ERROR_MESSAGE}</Alert>}
    </div>
  );
};

export const EnhancedAvailabilityCheck: FunctionComponent<React.PropsWithChildren<IEnhancedAvailabilityCheck>> = ({
  isLoading,
  error,
  getAvailableAccessMethods,
}) => {
  useEffect(() => {
    getAvailableAccessMethods();
  }, [getAvailableAccessMethods]);
  return <AvailabilityCheck error={error} isLoading={isLoading} />;
};

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  getAvailableAccessMethods: () => dispatch(getAvailableAccessMethodsAction()),
});

const mapStateToProps = (state: IAppState) => ({
  error: !!state.quoteBuilder.checkingAvailability.error,
  isLoading: state.quoteBuilder.checkingAvailability.inProgress,
});

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedAvailabilityCheck);
