import { INewNNIRequest, ISiteConfig } from '../../../types/location';
import { IPriceData, IQuote } from '../../../../Quotes/types/store';
import { NNILocation } from './NNILocation';
import { ShadowNNILocation } from './ShadowNNILocation';
import { BEndLocation } from './BEndLocation';
import React from 'react';

export function OrderLocation(props: {
  nniLabel: string;
  nniRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  siteConfig: ISiteConfig;
  quote: IQuote;
  selectedPrice: IPriceData;
  shadowRef: string;
  shadowNNIRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  sseUser: boolean;
  pointAddress: any;
  circuit?: string;
}) {
  return (
    <>
      <div className="row no-gutters mt-3">
        <NNILocation
          nniLabel={props.nniLabel}
          nniRequest={props.nniRequest}
          siteConfig={props.siteConfig}
          quote={props.quote}
          selectedPrice={props.selectedPrice}
          circuit={props.circuit}
        />
      </div>
      {props.shadowRef && (
        <ShadowNNILocation
          shadowRef={props.shadowRef}
          nniRequest={props.shadowNNIRequest}
          siteConfig={props.siteConfig}
        />
      )}
      <div className="row no-gutters mt-3">
        <BEndLocation
          selectedPrice={props.selectedPrice}
          sseUser={props.sseUser}
          quote={props.quote}
          pointAddress={props.pointAddress}
        />
      </div>
    </>
  );
}
