import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import getUsers from '../crud/getUsers';
import { IUserBackendProfile } from 'User/types/backendUser';
import { selectSelectedCompanyId } from 'User/selectors';
import Alert from 'shared/components/atoms/Alert';
import EmptyUsersList from './components/EmptyUsersList';
import UsersTable from './components/UsersTable';
import SpinnerWithText from 'shared/components/molecules/SpinnerWithText';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import LinkButton from 'shared/components/atoms/LinkButton';
import Column from 'shared/components/atoms/Column';
import { ButtonMainStyle } from 'shared/components/atoms/Button';

enum FetchUsersStatus {
  BEFORE_FETCHING = 'BEFORE_FETCHING',
  FETCHING_COMPLETE = 'FETCHING_COMPLETE',
}

export const ERROR_MESSAGE = 'Error in retrieving users';

const UsersList: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  useTrackPage(pages.users);
  const [users, setUsers] = useState<IUserBackendProfile[]>([]);
  const [fetchError, setFetchError] = useState('');
  const [fetchUsersStatus, setFetchUsersStatus] = useState<FetchUsersStatus>(FetchUsersStatus.BEFORE_FETCHING);
  const selectedCompanyId = useSelector(selectSelectedCompanyId);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUsers(selectedCompanyId);
        setUsers(response.data);
      } catch (error) {
        setFetchError(ERROR_MESSAGE);
      } finally {
        setFetchUsersStatus(FetchUsersStatus.FETCHING_COMPLETE);
      }
    })();
  }, [selectedCompanyId]);

  const getUsersContent = () => {
    if (users.length > 0) {
      return <UsersTable users={users} />;
    } else if (fetchError !== '') {
      return <Alert>{fetchError}</Alert>;
    } else {
      return <EmptyUsersList />;
    }
  };

  return (
    <HeaderWithChildrenWrapper header="User management" compact={true}>
      <div className="row no-gutters mb-3">
        <Column classNames={['text-right']}>
          <LinkButton
            buttonText="Add person"
            pathname="/admin/adduser"
            id="add-user"
            className="addPerson-btn"
            mainStyle={ButtonMainStyle.PrimaryRectangular}
          />
        </Column>
      </div>
      {fetchUsersStatus === FetchUsersStatus.BEFORE_FETCHING ? (
        <SpinnerWithText text="Retrieving users" size="large" />
      ) : (
        getUsersContent()
      )}
    </HeaderWithChildrenWrapper>
  );
};

export default UsersList;
