import { INewNNIRequest, ISiteConfig } from '../../../types/location';
import { IPriceData, IQuote } from '../../../../Quotes/types/store';
import Column from '../../../../shared/components/atoms/Column';
import { PoP } from '../PoP';
import React from 'react';
import { NewNNIJobReference } from './index';

export function NNILocation(props: {
  nniLabel: string;
  nniRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  siteConfig: ISiteConfig;
  quote: IQuote;
  selectedPrice: IPriceData;
  circuit?: string;
}) {
  return (
    <>
      <Column defaultWidth={2}>
        <span className="sub-heading">{props.circuit} Location(s)</span>
      </Column>
      <Column defaultWidth={2}>
        <span className="font-weight-bold">NNI (Neos Networks on-net)</span>
      </Column>
      <Column defaultWidth={8}>
        <div className="nni-label" data-testid="order-summary-nni-address">
          {props.nniLabel}
        </div>
        <NewNNIJobReference nniRequest={props.nniRequest} jobReference={props.siteConfig.nni_job_reference} />
        <PoP
          end="A"
          port={props.quote.aEndPort}
          pop={props.selectedPrice.a_end_p_o_p}
          postcode={props.selectedPrice.a_end_p_o_p_postcode}
          exchangeType={props.selectedPrice.a_end_exchange_type}
          showExchange={true}
        />
      </Column>
    </>
  );
}
