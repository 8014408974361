import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  li {
    word-break: inherit;
  }
`;

export const AddressAndContactInfo: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container>
      <p>
        You need to ensure that you select the most specific address/location. Or if entering a manual address make it
        as detailed as possible otherwise this could delay your order. In addition, if you are entering the address
        manually, it would be helpful if you could add site coordinates.
      </p>
    </Container>
  );
};

export const SiteConfigInfo: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container>
      <h4>Provide specific location details?</h4>
      <p>
        This information should be as specific as possible, otherwise this could delay your order while Neos Networks
        validate your requirements.
      </p>

      <h4>Connector type</h4>
      <p>For 100Mbit/s and 1Gbit/s ethernet ports:</p>
      <ul>
        <li>RJ45 - the standard electrical connector for Ethernet cables</li>
      </ul>
      <p>For 1Gbit/s and 10Gbit/s optical ports:</p>
      <ul>
        <li>LC = fibre optic connector type (small form factor fibre).</li>
        <li>SC = fibre optic connector type (large form factor fibre).</li>
      </ul>

      <h4>Media/Interface type</h4>
      <p>For 100Mbit/s and 1Gbit/s ethernet ports:</p>
      <ul>
        <li>TX - Twisted-Pair Transceiver</li>
      </ul>
      <p>For 1Gbit/s optical ports:</p>
      <ul>
        <li>LX - Long wavelength Single Mode Fibre (SMF)</li>
        <li>SX - Short wavelength Multi Mode Fibre (MMF)</li>
      </ul>
      <p>For 10Gbit/s optical ports:</p>
      <ul>
        <li>LR -Long Range Single Mode Fibre (SMF)</li>
        <li>SR- Short Range Multi Mode Fibre (MMF)</li>
      </ul>

      <h4>Power supply?</h4>
      <ul>
        <li>AC power is typically distributed at the local mains voltage of 120&nbsp;V, 208&nbsp;V, or 230&nbsp;V.</li>
        <li>DC power is typically distributed at the telecommunications standard voltage of 48&nbsp;V.</li>
      </ul>

      <h4>Auto Negotiation</h4>
      <p>
        Auto Negotiation is a signalling mechanism and procedure used by Ethernet by which two connected devices choose
        common transmission parameters, such as speed, duplex mode, and flow control.
      </p>

      <h4>VLAN tagging (Ethernet Point 2NNI only)</h4>
      <ul>
        <li>A-end: configured as VLAN Based as standard. NNI VLAN reference is mandatory.</li>
        <li>
          B-end: configured as Port Based as standard. Select VLAN tagging at B-end if VLAN Based configuration is
          required at <span className="text-nowrap">B-end</span> in addition to the
          <span className="text-nowrap"> A-end</span> NNI VLAN Ref.
        </li>
      </ul>
    </Container>
  );
};
