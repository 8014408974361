import React, { FunctionComponent, ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import Column from '../../atoms/Column';
import cx from 'classnames';
import EditButton from '../EditButton';
import { IQuotesState } from 'Quotes/types/store';
import { monthsToYears } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import {
  fttxBandwidthForDisplay,
  fttxMixedConnectionTypesBreakdown,
  isConnectionTypeMixed,
} from 'shared/utils/connectionCapacity';
import { isFTTXQuote } from 'Quotes/utils/isFTTXQuote';
import AddressTypeBadge, { AddressType } from 'shared/components/atoms/AddressTypeBadge';
import { Qualifier } from 'shared/types/postcodeResults';

import CopyToClipboardBadge from 'shared/components/molecules/CopyToClipboardBadge';
import PresentForRoles from 'Auth/PresentForRoles';
import { userPermissions } from 'shared/utils/permissions';
import { isFTTXConnection } from 'Quotes/types/connectionType';

export interface ISummaryRow {
  topLine?: string | null;
  bottomLine: ReactNode;
  alk?: string | null;
  qualifier?: Qualifier | null;
  districtCode?: string | null;
}

interface IQuoteAccordionHeaderProps {
  changeDisabled?: boolean;
  className?: string;
  hasChangeButton?: boolean;
  index: number;
  isActive?: boolean;
  isComplete?: boolean;
  title: string;
  summaries?: ISummaryRow[] | null;
  quote?: IQuotesState['quote'];
  onChangeButtonClicked?(): void;
  showContent(index: number): void;
}

const QuoteAccordionHeader: FunctionComponent<React.PropsWithChildren<IQuoteAccordionHeaderProps>> = ({
  changeDisabled = false,
  className,
  hasChangeButton = true,
  index,
  onChangeButtonClicked,
  showContent,
  isActive,
  isComplete,
  title,
  summaries,
  quote,
}) => {
  const theme = useTheme();

  const onChangeClick = () =>
    typeof onChangeButtonClicked === 'function' ? onChangeButtonClicked() : showContent(index);

  let summariesRows;
  let bandwidth;
  let mixedBandwidthBreakdown;

  if (quote && isFTTXConnection(quote.connectionType)) {
    bandwidth = fttxBandwidthForDisplay({
      aEndBandwidth: quote.aEndBandwidth,
      bEndBandwidth: quote.bEndBandwidth,
      connectionType: quote.connectionType,
      chosenBandwidths: quote.chosen_bandwidths,
    });

    if (
      isConnectionTypeMixed({
        aEndAccessMethod: quote.aEndAccessMethod,
        bEndAccessMethod: quote.bEndAccessMethod,
        connectionType: quote.connectionType,
      })
    ) {
      mixedBandwidthBreakdown = fttxMixedConnectionTypesBreakdown({
        aEndBandwidth: quote.aEndBandwidth,
        bEndBandwidth: quote.bEndBandwidth,
        aEndAccessMethod: quote.aEndAccessMethod,
        bEndAccessMethod: quote.bEndAccessMethod,
      });
    }
  }

  if (summaries) {
    summariesRows = summaries.map((summary: ISummaryRow, i: number) => (
      <div className="summary_summaryRow" key={i}>
        <div className="summary_summaryTopLine">{summary.topLine}</div>
        {summary.bottomLine && (
          <>
            <div>
              <div className="summary_summaryBottomLine">{summary.bottomLine}</div>
            </div>
            {(summary.alk || summary.districtCode) && (
              <div className="pt-2">
                {summary.alk && (
                  <AddressTypeBadge
                    addressType={AddressType.OPENREACH}
                    alk={summary.alk}
                    qualifier={summary.qualifier}
                  />
                )}
                {summary.districtCode && (
                  <PresentForRoles roles={userPermissions.viewDistrictCode}>
                    <span className={cx({ 'pl-3': summary.alk })}>
                      <CopyToClipboardBadge
                        colour={theme.colours.primaryB1}
                        label="District code"
                        value={summary.districtCode}
                      />
                    </span>
                  </PresentForRoles>
                )}
              </div>
            )}
          </>
        )}
      </div>
    ));
  }

  return (
    <div>
      <div className={cx(className, { active: isActive, complete: isComplete })}>
        <div className="accordion-container">
          <div className="title row no-gutters">
            <Column lgWidth={2}>
              <h3 className="h5">{title}</h3>
            </Column>

            {quote && isFTTXQuote(quote) ? (
              <Column lgWidth={9}>
                <div className="row no-gutters fttx-summary-wrapper pb-2">
                  <Column defaultWidth={2}>Term</Column>
                  <Column defaultWidth={3}>Access type</Column>
                  <Column defaultWidth={6}>Bandwidth</Column>
                </div>
                <div className="row no-gutters fttx-summary-wrapper fttx-summary-content">
                  <Column defaultWidth={2}>
                    {
                      // TODO: support multiple terms
                      monthsToYears(quote.contractTerm)
                    }
                  </Column>
                  <Column defaultWidth={3}>{quote.connectionType}</Column>
                  <Column defaultWidth={6}>
                    {bandwidth}
                    <br />
                    {mixedBandwidthBreakdown}
                  </Column>
                </div>
              </Column>
            ) : (
              <Column lgWidth={9}>
                <div className="summary_wrapper">{summariesRows}</div>
              </Column>
            )}

            <Column lgWidth={1} classNames={['changeButton_wrapper', 'text-right']}>
              {hasChangeButton && !isActive && !changeDisabled && <EditButton onClick={onChangeClick} />}
            </Column>
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledQuoteAccordionHeader = styled(QuoteAccordionHeader)`
  padding-top: 1rem;

  .accordion-container {
    margin-bottom: 1rem;
  }

  .title h3 {
    color: ${(props) => props.theme.colours.primaryA1};
  }

  button[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .summary_wrapper {
    margin: 0 1rem;
    position: static;
    font-family: ${(props) => props.theme.typography.fontFamilyBold};
  }

  .fttx-summary-wrapper {
    margin: 0 1rem;
  }

  .fttx-summary-content {
    color: ${(props) => props.theme.colours.secondary};
  }

  .summary_summaryRow {
    color: ${(props) => props.theme.colours.secondary};
    border-bottom: 1px solid ${(props) => props.theme.colours.grey10};
    width: 100%;
    padding-bottom: 0.5em;
  }

  .summary_summaryRow:last-child {
    border-bottom: none;
    padding-top: 0.5em;
    padding-bottom: 0;
  }

  .summary_summaryTopLine {
    line-height: 32px;
    text-align: left;
    width: 100%;
    color: ${(props) => props.theme.colours.grey90};
  }

  .summary_summaryBottomLine {
    color: ${(props) => props.theme.colours.grey90};
  }

  &.active {
    border-bottom-color: transparent;
  }

  .fttx-summary-content {
    color: ${(props) => props.theme.colours.grey90};
  }
`;

export default StyledQuoteAccordionHeader;
