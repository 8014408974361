import { doRequest, Methods } from '../../Request';

const getWhoAmI = () => {
  const result = doRequest({
    method: Methods.GET,
    path: '/users/whoami',
  });
  return result;
};

export default getWhoAmI;
