import React from 'react';
import { useModal } from 'shared/hooks/useModal';
import { noop } from 'lodash';
import { ClearDefaultButton } from 'Admin/preferences/OrderTemplates/OrderTemplateCard';
import Icon from 'shared/components/atoms/Icon';
import { ClearDefaultOrderTemplateModal } from 'Order/OrderBuilder/OrderTemplate/ClearDefaultOrderTemplateModal';

interface IClearDefaultOrderTemplateButtonProps {
  customerId: string;
  onCompletedMutation?: () => void;
}

export const ClearDefaultOrderTemplateButton = ({
  customerId,
  onCompletedMutation = noop,
}: IClearDefaultOrderTemplateButtonProps) => {
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      {isOpen && (
        <ClearDefaultOrderTemplateModal onClose={closeModal} customerId={customerId} onSuccess={onCompletedMutation} />
      )}

      <ClearDefaultButton onClick={openModal}>
        <Icon name="bookmark_remove" size="large" />
        <strong>Clear default</strong>
      </ClearDefaultButton>
    </>
  );
};
