import { ProductType } from '../../Quotes/types/productTypes';
import { PointType } from '../types/pointType';

interface IEndTypes {
  aEnd: PointType;
  bEnd: PointType;
}

export const getEndTypesForProduct = (productType: ProductType | null): IEndTypes => {
  switch (productType) {
    case ProductType.DIA:
      return {
        bEnd: PointType.DIA,
        aEnd: PointType.POINT,
      };
    case ProductType.NNI2CCT:
      return {
        aEnd: PointType.NNI,
        bEnd: PointType.CCT,
      };
    case ProductType.P2NNI:
      return {
        aEnd: PointType.NNI,
        bEnd: PointType.POINT,
      };
    case ProductType.P2CCT:
      return {
        aEnd: PointType.POINT,
        bEnd: PointType.CCT,
      };
    case ProductType.OpticalP2P:
      return {
        aEnd: PointType.OPTICAL,
        bEnd: PointType.OPTICAL,
      };
    default:
      return {
        aEnd: PointType.POINT,
        bEnd: PointType.POINT,
      };
  }
};
