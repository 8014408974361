import { Source } from 'Quotes/types/availabilityCheck';
import React, { useState } from 'react';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import Alert from 'shared/components/atoms/Alert';

export const PostcodeInput = ({
  initialText = '',
  onSubmit,
  id,
  isLoading,
  alkError,
  postcodeError,
}: {
  id: Source;
  initialText?: string;
  isLoading: boolean;
  onSubmit: (value: string) => void;
  alkError: any;
  postcodeError: any;
}) => {
  const [text, setText] = useState(initialText);

  const doSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    onSubmit(text.trim());
  };
  return (
    <form onSubmit={doSubmit}>
      <label className="d-block mb-2 text-muted" htmlFor={`${id}_lookup`}>
        You can enter a postcode, ALK, latitude/longitude or Eastings/Northings
      </label>
      <input
        disabled={isLoading}
        onChange={(e) => setText(e.target.value)}
        type="text"
        value={text}
        className="point-input"
        id={`${id}_lookup`}
      />
      <Button
        type="submit"
        disabled={isLoading}
        loading={isLoading}
        className="find-btn"
        mainStyle={ButtonMainStyle.SecondaryRectangular}
      >
        Find
      </Button>

      {alkError && (
        <Alert>
          <p className="mb-0">
            {Array.isArray(alkError?.response?.data?.errors) &&
            typeof alkError.response.data.errors[0]?.title === 'string'
              ? alkError.response.data.errors[0].title
              : alkError?.message || 'Unknown error occurred'}
          </p>
        </Alert>
      )}

      {postcodeError && (
        <Alert>
          <p className="mb-0">{postcodeError}</p>
        </Alert>
      )}
    </form>
  );
};
