import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { IAppState } from 'reducers';
import Alert from '../../shared/components/atoms/Alert';
import Spinner from '../../shared/components/molecules/SpinnerWithText';
import { usePreviousState } from 'shared/utils/customHooks';
import { getExistingQuote } from '../actions';
import QuoteBuilder from '../QuoteBuilder';
import { ErrorResponse, StatusCode } from 'Request';
import { forbidden } from 'Auth/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface IQuoteByIdProps {
  error?: boolean;
  errorResponse?: ErrorResponse | null;
  pending?: boolean;
  getQuote(quoteId: string): void;
}

type Params = {
  quoteId?: string;
};

export const QuoteById: FunctionComponent<React.PropsWithChildren<IQuoteByIdProps>> = (props) => {
  const navigate = useNavigate();
  return <QuoteByIdInternal {...props} navigate={navigate} />;
};

export const QuoteByIdInternal: FunctionComponent<React.PropsWithChildren<IQuoteByIdProps & Navigation>> = ({
  error,
  errorResponse,
  getQuote,
  pending,
  navigate,
}) => {
  const { quoteId } = useParams<Params>();
  const forbiddenResponse = errorResponse?.data?.errors?.some((err) => err.status === StatusCode.Forbidden);

  useEffect(() => {
    if (quoteId) {
      getQuote(quoteId);
    }
  }, [getQuote, quoteId]);

  useEffect(() => {
    if (!pending && forbiddenResponse) {
      return navigate(forbidden);
    }
  }, [forbiddenResponse, pending, navigate]);

  const prevPending = usePreviousState(pending);

  if (!pending && prevPending && !error) {
    return <QuoteBuilder />;
  }

  return (
    <>
      {pending && <Spinner text="Retrieving quote" size="large" />}

      {!pending && error && !forbiddenResponse && (
        <Alert>
          Error retrieving quote. Please try again later. If the problem persists, please contact your Account Manager.
        </Alert>
      )}
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  error: state.quoteBuilder.retrieving.error,
  errorResponse: state.quoteBuilder.retrieving.errorResponse,
  pending: state.quoteBuilder.retrieving.inProgress,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  getQuote: (quoteId: string) => dispatch(getExistingQuote(quoteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuoteById);
