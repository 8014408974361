import React from 'react';
import { currencyFormatter } from 'shared/utils/currencyFormatter';
import PriceSummarySegment from './PriceSummarySegment';
import MDIAPrices from './MDIAPrices';
import { IQuote } from 'Quotes/types/store';
import {
  MDIA_ENGINEER_CHARGE,
  MDIA_RACK_MOUNT_KIT_CHARGE,
} from 'Quotes/QuoteBuilder/components/Price/components/TillReceiptInternal/TillReceiptInternal';

interface UpfrontSelectedPriceProps {
  amortised?: boolean | null;
  install_discount: number | null;
  mdia_engineer_price: number | null;
  mdia_install_price: number | null;
  mdia_rack_mount_kit_price: number | null;
  mdia_router_price: number | null;
  net_amortised_install_price: number | null;
  net_install_price: number | null;
  shadow_vlan_price: number | null;
}
interface Prices {
  install_discount: number | null;
  mdia_engineer_price: number | null;
  mdia_install_price: number | null;
  mdia_rack_mount_kit_price: number | null;
  mdia_router_price: number | null;
  net_amortised_install_price: number | null;
  net_install_price: number | null;
  shadow_vlan_price: number | null;
}
interface UpfrontCostsProps {
  selectedPrice: UpfrontSelectedPriceProps;
  secondarySelectedPrice?: UpfrontSelectedPriceProps;
  showShadowVLANPrice: boolean;
  calculateUpFront: (priceAmortised: boolean, netAmortisedInstallPrice: number, netInstallPrice: number) => number;
  priceAmortised: boolean;
  mdiaPrices: IMDIAPrices | undefined;
  quote: IQuote;
  label: string;
}

export interface IMDIAPrices {
  engineerInstallation: number;
  rackMountKit: number;
  router: number;
}

const UpfrontCosts: React.FC<UpfrontCostsProps> = ({
  selectedPrice,
  secondarySelectedPrice,
  showShadowVLANPrice,
  priceAmortised,
  mdiaPrices,
  quote,
  label,
}) => {
  const options = quote.location.aEnd.secondRouterOptions;

  const sumValues = (key: keyof Prices) => (selectedPrice[key] ?? 0) + (secondarySelectedPrice?.[key] ?? 0);

  const totalNetAmortisedInstallPrice = sumValues('net_amortised_install_price');
  const totalShadowVlanPrice = sumValues('shadow_vlan_price');
  const totalNetInstallPrice = sumValues('net_install_price');
  const totalInstallDiscount = sumValues('install_discount');

  const upfrontCharge = priceAmortised ? totalNetAmortisedInstallPrice : totalNetInstallPrice;

  const engineerInstallationRequired = options?.engineerInstallationRequired;
  const rackMountKitRequired = options?.rackMountKitRequired;
  const mdiaEngineerCharge = engineerInstallationRequired ? MDIA_ENGINEER_CHARGE : 0;
  const mdiaRackMountKitCharge = rackMountKitRequired ? MDIA_RACK_MOUNT_KIT_CHARGE : 0;
  const mdiaExtras = mdiaEngineerCharge + mdiaRackMountKitCharge;

  const totalCharge = upfrontCharge + (mdiaPrices ? mdiaExtras : 0);

  return (
    <>
      <div className="price-summary__segment-wrapper">
        <div className="segment_label">Up-front</div>
        <div>
          <b className="total currency" data-testid="quote-page-upfront-charge">
            {label === 'Primary' && currencyFormatter.format(upfrontCharge)}
            {label === 'Secondary' &&
              currencyFormatter.format(!priceAmortised ? upfrontCharge + mdiaExtras : upfrontCharge)}
            {label === 'Total' && currencyFormatter.format(!priceAmortised ? totalCharge : upfrontCharge)}
          </b>
        </div>
      </div>
      {showShadowVLANPrice && (
        <PriceSummarySegment label="Includes one-off charge for Shadow VLAN of" value={totalShadowVlanPrice} />
      )}

      {!priceAmortised && !!mdiaPrices && (
        <MDIAPrices
          isManagedDIA={true}
          mdiaRouterPrice={mdiaPrices.router}
          mdiaEngineerPrice={mdiaPrices.engineerInstallation}
          mdiaRackMountKitPrice={mdiaPrices.rackMountKit}
          engineerInstallationRequired={mdiaPrices.engineerInstallation > 0}
        />
      )}

      {totalInstallDiscount > 0 && (
        <PriceSummarySegment label="Includes install discount of" value={totalInstallDiscount} />
      )}
    </>
  );
};

export default UpfrontCosts;
