import { copyFromOrderTemplate, setSelectedOrderTemplate } from 'Order/actions';
import { mapOrderToOrderTemplateState } from 'Order/crud/orderTemplateMapper';
import { initialState } from 'Order/reducer';
import { IOrderTemplate } from 'Order/types/orderTemplate';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Dropdown, { IDropdownOption } from 'shared/components/molecules/Dropdown';
import { findSingleOrArray } from 'shared/types/JSONApiResponse';
import styled from 'styled-components';
import Modal from 'shared/components/molecules/Modal';
import { useModal } from 'shared/hooks/useModal';
import { ActionMeta } from 'react-select';
import { useSearchParams } from 'react-router-dom';
import { afterCreationQueryParam } from 'Order/routes';
import { useOrderTemplates } from 'Order/crud/orderTemplatesApi';

const Container = styled.div`
  min-width: 20rem;
`;

export const CONFIRM_FILL_MODAL_TITLE = 'Auto-fill form confirmation';
export const CONFIRM_CLEAR_MODAL_WARNING =
  'Are you sure you want to clear the form of all values from the previously selected order template?';

export const CONFIRM_FILL_MODAL_WARNING =
  'Are you sure you want to auto-fill the form with values from the selected order template? This will replace all currently filled values with ones from the selected order template.';

export interface IOrderTemplateDropdownProps {
  customerId: string;
  selectedOrderTemplateId?: string;
  useOrderTemplatesHook?: typeof useOrderTemplates;
  copyFromOrderTemplateAction?: typeof copyFromOrderTemplate;
  onChange?: (selection: IDropdownOption | undefined) => void;
  autoFillFromDefaultTemplate?: boolean;
  setSelectedOrderTemplateAction?: typeof setSelectedOrderTemplate;
}

export const ConfirmFillFromOrderTemplate = ({
  isCleared,
  onClose,
  onConfirm,
}: {
  onConfirm: () => void;
  onClose: () => void;
  isCleared: boolean;
}) => {
  const confirmFillModalBodyValues = isCleared ? CONFIRM_CLEAR_MODAL_WARNING : CONFIRM_FILL_MODAL_WARNING;
  return (
    <div data-testid="confirm-fill-modal">
      <Modal title={CONFIRM_FILL_MODAL_TITLE} onConfirm={onConfirm} onClose={onClose}>
        <p data-testid="confirm_order_template_modal_body">{confirmFillModalBodyValues}</p>
      </Modal>
    </div>
  );
};

const defaultFirst = (a: IDropdownOption, b: IDropdownOption) =>
  a.label.localeCompare(b.label, undefined, { numeric: true });

export const OrderTemplateDropdown: FC<IOrderTemplateDropdownProps> = ({
  customerId,
  selectedOrderTemplateId,
  useOrderTemplatesHook = useOrderTemplates,
  copyFromOrderTemplateAction = copyFromOrderTemplate,
  onChange,
  autoFillFromDefaultTemplate = false,
  setSelectedOrderTemplateAction = setSelectedOrderTemplate,
}) => {
  const orderTemplates = useOrderTemplatesHook(customerId);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const orderTemplateModal = useModal(false);
  const [selectedTemplate, setSelectedTemplate] = useState<IDropdownOption | undefined>();
  const [isCleared, setIsCleared] = useState(false);

  const getDropdownOptions = useCallback(() => {
    return orderTemplates.orderTemplates
      ? orderTemplates.orderTemplates.map(mapOrderTemplateToDropdownOption).sort(defaultFirst)
      : [];
  }, [orderTemplates.orderTemplates]);

  const value = useMemo(() => {
    return getDropdownOptions().find((option) => option.value === selectedOrderTemplateId);
  }, [getDropdownOptions, selectedOrderTemplateId]);

  const handleSelection = useCallback(
    (selection: IDropdownOption | undefined, wasCleared = false) => {
      const copyOrderTemplate = (orderTemplate: IOrderTemplate | undefined) => {
        if (!orderTemplate) return;

        dispatch(copyFromOrderTemplateAction(orderTemplate.attributes.templateBody));

        toast.success('Order template copied successfully');
      };

      const reset = () => {
        const state = mapOrderToOrderTemplateState(initialState.order);
        dispatch(copyFromOrderTemplateAction(state));

        toast.info('Form reset to default');
      };

      if (!orderTemplates.orderTemplates) return;

      if (onChange) onChange(selection);
      const selectedOrderTemplate = findSingleOrArray(
        orderTemplates.orderTemplates,
        (item) => item.id === selection?.value
      );
      dispatch(setSelectedOrderTemplateAction(selectedOrderTemplate));

      if (!selection) reset();
      else copyOrderTemplate(findSingleOrArray(orderTemplates.orderTemplates, (item) => item.id === selection.value));

      setIsCleared(wasCleared);
    },
    [orderTemplates.orderTemplates, onChange, dispatch, copyFromOrderTemplateAction, setSelectedOrderTemplateAction]
  );

  useEffect(() => {
    if (
      autoFillFromDefaultTemplate &&
      !orderTemplates.isLoading &&
      orderTemplates.orderTemplates &&
      !selectedOrderTemplateId
    ) {
      const defaultTemplate = orderTemplates.orderTemplates.find((item) => item.attributes.isDefault);
      if (!defaultTemplate) return;

      const dropdownOption = mapOrderTemplateToDropdownOption(defaultTemplate);
      handleSelection(dropdownOption);
      if (searchParams.has(afterCreationQueryParam)) {
        setSearchParams((prevSearchParams) => {
          const updatedSearchParams = new URLSearchParams(prevSearchParams);
          updatedSearchParams.delete(afterCreationQueryParam);
          return updatedSearchParams;
        });
      }
    }
  }, [
    autoFillFromDefaultTemplate,
    orderTemplates.orderTemplates,
    selectedOrderTemplateId,
    handleSelection,
    searchParams,
    setSearchParams,
    orderTemplates.isLoading,
  ]);

  const handleDropdownChange = (selection: IDropdownOption | null, actionMeta: ActionMeta<IDropdownOption>) => {
    const wasCleared = actionMeta.action === 'clear';
    openConfirmationModal(selection || undefined, wasCleared);
  };

  const openConfirmationModal = (selection?: IDropdownOption, wasCleared = false) => {
    setSelectedTemplate(selection);
    setIsCleared(wasCleared);
    orderTemplateModal.openModal();
  };

  return (
    <Container data-selectedtemplateid={`${selectedTemplate ? selectedTemplate.value : ''}`}>
      <label htmlFor="">Select an order template:</label>
      <Dropdown
        options={getDropdownOptions()}
        isLoading={orderTemplates.isLoading}
        onChange={handleDropdownChange}
        classNamePrefix="order-template"
        value={value}
      />
      {orderTemplateModal.isOpen && (
        <ConfirmFillFromOrderTemplate
          onConfirm={() => {
            handleSelection(selectedTemplate, isCleared);
            orderTemplateModal.closeModal();
          }}
          onClose={() => {
            orderTemplateModal.closeModal();
          }}
          isCleared={isCleared}
        />
      )}
    </Container>
  );
};

const mapOrderTemplateToDropdownOption = (orderTemplate: IOrderTemplate): IDropdownOption => ({
  label: `${orderTemplate.attributes.title}${orderTemplate.attributes.isDefault ? ' (default)' : ''}`,
  value: orderTemplate.id,
});
