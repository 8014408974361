import { Exchange, ExchangeRequestStatus, INNI, SecondaryCircuits } from 'Quotes/types/store';
import { NNIOption } from 'Location/NNI/NNISelect';
import { INNIRecord, NNIDataCentreFromBE } from 'Location/NNI/types';
import { P2NNICircuitData } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import { useSelector } from 'react-redux';
import { selectNNILabel, selectQuote } from 'Quotes/selectors';
import { selectSelectedCompanyId } from 'User/selectors';
import { useCustomerNNIsWithMinimumBearerAndBandwidth } from 'Quotes/CustomerNNIApi';
import {
  getExchangeStatus,
  HandleSelectedCircuit,
  hasBEnd,
  useExchanges,
} from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';

export interface NNIData {
  primaryNNILabel: string;
  selected: INNI | undefined;
  selectNNI: (option: NNIOption) => void;
  existingNNIAgreements: INNIRecord[];
  nniCapableDataCentres: NNIDataCentreFromBE[];
  error: boolean;
  loading: boolean;
  findNNI: (id: string) => INNI | undefined;
}

export function useP2NNICircuitData(
  secondaryCircuits: SecondaryCircuits | undefined,
  handleSelectedCircuit: HandleSelectedCircuit,
  status: ExchangeRequestStatus
): P2NNICircuitData {
  const primaryNNILabel = useSelector(selectNNILabel);
  const quote = useSelector(selectQuote);

  const selectedCompanyId = useSelector(selectSelectedCompanyId);
  const customerNNIData = useCustomerNNIsWithMinimumBearerAndBandwidth(
    selectedCompanyId,
    quote.bearer,
    quote.chosen_bandwidths
  );

  const findNNI = (id: string) =>
    convertNNIRecordToNNI(customerNNIData.nniRecords.find((it) => it.id === id)) ??
    convertDataCentreToNNI(customerNNIData.dataCentres.find((it) => it.id === id));

  const selectedCircuit = secondaryCircuits?.circuits.find((it) => it?.id === secondaryCircuits?.selectedCircuitId);
  const selectedNNIId = selectedCircuit?.nniId;
  const handleSelectedNNI = (nni: INNI | undefined) => {
    return handleSelectedCircuit(getCircuitId(nni, bExchanges.selected));
  };

  const nniData: NNIData = {
    primaryNNILabel: primaryNNILabel,
    selected:
      convertNNIRecordToNNI(customerNNIData.nniRecords.find((it) => it.id === selectedNNIId)) ??
      convertDataCentreToNNI(customerNNIData.dataCentres.find((it) => it.id === selectedNNIId)),
    existingNNIAgreements: customerNNIData.nniRecords,
    nniCapableDataCentres: customerNNIData.dataCentres,
    loading: customerNNIData.isLoading,
    error: customerNNIData.error,
    findNNI: findNNI,
    selectNNI: async (nniOption: NNIOption) => {
      const newlySelectedNNI = convertNNIOptionToNNI(nniOption);
      await handleSelectedNNI(newlySelectedNNI);
    },
  };
  const bExchanges = useExchanges(
    secondaryCircuits?.bEndExchanges,
    secondaryCircuits?.selectedBEndId,
    (exchange: Exchange) => handleSelectedCircuit(getCircuitId(nniData.selected, exchange))
  );

  return {
    aEnd: nniData,
    bEnd: bExchanges,
    isLoading: customerNNIData.isLoading,
    getSelectedCircuitId: () => getCircuitId(nniData.selected, bExchanges.selected),
    getCircuitId: getCircuitId,
    status: () => getExchangeStatus(status, bExchanges.exchanges),
    canLoadPrices: () => !!nniData.selected && !!bExchanges.selectedExchangeId,
    processors: [hasBEnd],
    type: 'P2NNI',
  };
}

function getCircuitId(aEnd: INNI | undefined, bEnd: Exchange | undefined) {
  if (!aEnd || !bEnd) return undefined;
  if (aEnd?.selectedId) return `nni-${aEnd.selectedId}_datacentre-${bEnd.popId}`;
  else if (aEnd?.selectedDataCentreId) return `datacentre-${aEnd.selectedDataCentreId}_datacentre-${bEnd.popId}`;
  else {
    return undefined;
  }
}

export const convertNNIRecordToNNI = (nniRecord: INNIRecord | undefined): INNI | undefined => {
  if (!nniRecord) return undefined;
  return {
    capacity: { bandwidth: null, ports: [] },
    shadowVLAN: { enabled: false },
    selectedId: nniRecord.id,
    selectedDataCentreId: nniRecord.attributes.pop_id,
    reference: nniRecord.attributes.reference,
    popName: nniRecord.attributes.pop_name,
  };
};
export const convertDataCentreToNNI = (dataCentre: NNIDataCentreFromBE | undefined): INNI | undefined => {
  if (!dataCentre) return undefined;
  return {
    capacity: { bandwidth: null, ports: [] },
    shadowVLAN: { enabled: false },
    selectedId: '',
    selectedDataCentreId: dataCentre.id,
    reference: dataCentre.attributes.reference,
    popName: dataCentre.attributes.name,
  };
};
export const convertNNIOptionToNNI = (nniOption: NNIOption | null): INNI | undefined => {
  if (!nniOption) return undefined;
  return {
    capacity: { bandwidth: null, ports: [] },
    shadowVLAN: { enabled: false },
    selectedId: nniOption.type === 'nni' ? nniOption.value : '',
    selectedDataCentreId: nniOption.type === 'nni' ? '' : nniOption.value,
    reference: nniOption.nniReference,
    popName: nniOption.type === 'nni' ? nniOption.dataCentreReference : '',
  };
};

export const isNNI = (aEnd: INNI | Exchange | undefined): aEnd is INNI => {
  if (!aEnd) return false;
  return 'selectedDataCentreId' in aEnd;
};
