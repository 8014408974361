import { call, debounce, put, select } from 'redux-saga/effects';
import { replaceQuoteState, setOpticalDataCentreList, setShortBulkQuoteId } from 'Quotes/actions';
import getQuote from '../Quotes/crud/getQuote';
import IQuoteRecord, { IQuotePricedOrOrdered } from '../Quotes/types/quoteRecord';
import {
  createNoteError,
  createNoteSuccess,
  createOrderEndedAction,
  createOrderErrorAction,
  createOrderStartedAction,
  getOrderQuoteAndPriceErrorAction,
  getOrderQuoteAndPriceStartedAction,
  getOrderQuoteAndPriceSucceededAction,
  replaceOrderAEndLocationAction,
  replaceOrderBEndLocationAction,
  replaceOrderBillingAndContactAction,
  replaceOrderNotes,
  replaceOrderStateAction,
  replaceOrderStateHistory,
  replaceOrderStatus,
  setOrderIdAction,
  submitOrderError,
  submitOrderStarted,
  submitOrderSuccess,
  updateOrderEndedAction,
  updateOrderErrorAction,
  updateOrderStartedAction,
} from './actions';
import getOrder from './crud/getOrder';
import crudUpdateOrder from './crud/updateOrder';
import submitOrderRequest from './crud/submitOrder';
import createNote from './crud/createNote';
import {
  ICreateNote,
  ICreateOrder,
  IGetOrderQuoteAndPrice,
  ISubmitOrder,
  IUpdateOrder,
  OrderActionTypes,
} from './types/actions';
import IOrderRecord from './types/orderRecord';
import { findShortBulkIdInIncluded, updatePriceData } from 'shared/sagas';
import { filterIncludedBy } from 'Request/utils/filterIncludedBy';
import { setSelectedCompany } from 'User/actions';
import { selectProductType } from './selectors';
import { ProductType } from 'Quotes/types/productTypes';
import { loadDataCentres } from 'Location/OpticalDataCentres/hooks';
import { PopResource } from 'Location/NNI/types';
import createOrder from 'Order/crud/createOrder';

export function* createNewOrder({
  payload: { quoteId, quote, meta, lqId, customerName, selectedPrice },
}: ICreateOrder): any {
  yield put(createOrderStartedAction());
  try {
    const newOrder: IOrderRecord = yield call(createOrder, quoteId, quote, customerName, selectedPrice, meta, lqId);

    yield put(setOrderIdAction(newOrder.data.id));
  } catch (error) {
    yield put(createOrderErrorAction());
  } finally {
    yield put(createOrderEndedAction());
  }
}

export function* updateOrder({ payload: { orderId, data } }: IUpdateOrder): any {
  yield put(updateOrderStartedAction());

  try {
    const productType: ReturnType<typeof selectProductType> = yield select(selectProductType);
    yield call(crudUpdateOrder, orderId, data, productType!);
    const order = yield call(getOrder, orderId);

    if (data.locationA) {
      yield put(replaceOrderAEndLocationAction(order));
    }
    if (data.locationB) {
      yield put(replaceOrderBEndLocationAction(order));
    }
    if (data.billingContact) {
      yield put(replaceOrderBillingAndContactAction(order));
    }

    yield put(replaceOrderStatus(order.data.attributes.state));
    yield put(replaceOrderStateHistory(order.data.attributes.state_history));
  } catch (error) {
    yield put(updateOrderErrorAction());
  } finally {
    yield put(updateOrderEndedAction());
  }
}

const validQuotePrices = ({ included }: IQuoteRecord) =>
  included && Array.isArray(included) && included.length > 0 && included.some((incl) => incl.type === 'price');

export function* retrieveOrderQuoteBulkQuoteAndPrice({ payload: { orderId } }: IGetOrderQuoteAndPrice): any {
  let order: IOrderRecord;

  yield put(getOrderQuoteAndPriceStartedAction());

  try {
    order = yield call(getOrder, orderId);
  } catch (error: any) {
    let errorMessage;

    if (
      Array.isArray(error?.response?.data?.errors) &&
      typeof error?.response?.data?.errors[0]?.title === 'string' &&
      error?.response?.data?.errors[0]?.title.includes('deleted')
    ) {
      errorMessage = error?.response?.data?.errors[0]?.title;
    }

    yield put(getOrderQuoteAndPriceErrorAction(errorMessage));
    return;
  }

  try {
    const quoteRecord: IQuoteRecord = yield call(getQuote, order.data.attributes.quote_id, {
      prices: true,
      bulkQuote: true,
      aEndSupplierNNI: true,
      bEndSupplierNNI: true,
      onatAddresses: true,
      pafAddresses: true,
      messages: true,
      secondaryPops: true,
    });

    const customerId = quoteRecord.data.attributes.customer_id;
    if (customerId) {
      yield put(setSelectedCompany(customerId));
    }

    // Load optical data centre list for rendering later when we only have an ID,
    // So we can pull the name of the data centre from this list
    if (quoteRecord.data.attributes.product_type === ProductType.OpticalP2P) {
      const { data: opticalDataCentreList } = yield call(loadDataCentres);
      yield put(setOpticalDataCentreList(opticalDataCentreList));
    }

    yield put(replaceOrderStateAction(order, quoteRecord));

    yield put(replaceQuoteState(quoteRecord));

    if (validQuotePrices(quoteRecord)) {
      const quotePrices = filterIncludedBy(quoteRecord.included as IQuotePricedOrOrdered[], 'price');
      const secondaryPoPs = filterIncludedBy<PopResource>(quoteRecord.included as PopResource[], 'pop');

      yield* updatePriceData(
        quotePrices,
        quoteRecord.data.attributes.selected_price?.id,
        quoteRecord.data.attributes.selected_price?.amortised,
        secondaryPoPs
      );

      const shortBulkId = findShortBulkIdInIncluded(quoteRecord);

      if (quoteRecord.data.attributes.is_bulk && !!shortBulkId) {
        yield put(setShortBulkQuoteId(shortBulkId));
      }
      yield put(getOrderQuoteAndPriceSucceededAction());
    } else {
      yield put(getOrderQuoteAndPriceErrorAction());
    }
  } catch (error) {
    yield put(getOrderQuoteAndPriceErrorAction());
  }
}

export function* submitOrder({ payload: { orderId, submitType } }: ISubmitOrder): any {
  yield put(submitOrderStarted());

  try {
    yield call(submitOrderRequest, submitType, orderId);
    yield put(submitOrderSuccess());
  } catch (e) {
    yield put(submitOrderError());
  }
}

export function* postNote({ payload: { orderId, quoteId, message } }: ICreateNote): any {
  try {
    const order = yield call(createNote, quoteId, orderId, message);
    yield put(replaceOrderNotes(order));
    yield put(createNoteSuccess());
  } catch (e) {
    yield put(createNoteError());
  }
}

export default function* rootSaga() {
  yield debounce(300, OrderActionTypes.CREATE_ORDER, createNewOrder);
  yield debounce(300, OrderActionTypes.UPDATE_ORDER, updateOrder);
  yield debounce(300, OrderActionTypes.CREATE_NOTE, postNote);
  yield debounce(300, OrderActionTypes.GET_ORDER_QUOTE_AND_PRICE, retrieveOrderQuoteBulkQuoteAndPrice);
  yield debounce(300, OrderActionTypes.SUBMIT_ORDER, submitOrder);
}
