import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { isCheckboxCell } from 'shared/utils/tableHelper';
import MultiSelectTable, { ISingleFilter, RowCell } from '../../../../shared/components/organisms/MultiSelectTable';
import headers from './headers';
import { IServerSidePaging } from 'shared/types/ServerSidePaging';
import { selectOrdersTableFilters, selectOrdersTablePageSize } from 'Tables/selectors';
import TableNames from '../../../../Tables/types/tableNames';
import { IFilterPair } from 'shared/types/filterPairType';
import UpdateHeaderCellWidths from 'shared/utils/updateHeaderCellWidth';
import { useNavigate } from 'react-router-dom';
import { Navigation } from 'shared/RouterComponents';

interface IOrdersTable {
  className?: string;
  paging: IServerSidePaging;
}

const OrdersTable: FunctionComponent<React.PropsWithChildren<IOrdersTable>> = (props) => {
  const navigate = useNavigate();
  return <OrdersTableInternal {...props} navigate={navigate} />;
};

export const OrdersTableInternal: FunctionComponent<React.PropsWithChildren<IOrdersTable & Navigation>> = ({
  paging,
  navigate,
}) => {
  const defaultPageSize = useSelector(selectOrdersTablePageSize);

  const rowClickHandler = (cell: RowCell) => {
    if (!isCheckboxCell(cell)) {
      const orderId = cell.row.original.id;
      navigate(`/orders/${orderId}`);
    }
  };

  const initialFilters: ISingleFilter[] = [];

  const filterPairs: IFilterPair<'ordersTable'>[] = [
    { tableHeaderId: 'id', storeField: 'orderId' },
    { tableHeaderId: 'quote_id', storeField: 'quoteId' },
    { tableHeaderId: 'customer_name', storeField: 'customer' },
    { tableHeaderId: 'product_type', storeField: 'product' },
    { tableHeaderId: 'access_method', storeField: 'accessType' },
    { tableHeaderId: 'locations', storeField: 'location' },
    { tableHeaderId: 'access_type', storeField: 'accessProvider' },
    { tableHeaderId: 'updated_at', storeField: 'updatedAt' },
    { tableHeaderId: 'updated_by', storeField: 'updatedBy' },
    { tableHeaderId: 'order_state', storeField: 'status' },
  ];

  const ordersTableFilters = useSelector(selectOrdersTableFilters);

  filterPairs.forEach((pair) => {
    const storeValue = ordersTableFilters[pair.storeField];
    if (storeValue) {
      initialFilters.push({ id: pair.tableHeaderId, value: storeValue });
    }
  });

  return (
    <MultiSelectTable
      headers={UpdateHeaderCellWidths(headers, TableNames.ordersTable)}
      paging={paging}
      isLoading={paging.isLoading}
      rows={paging.results}
      rowClickHandler={rowClickHandler}
      initialFilters={initialFilters}
      className="orders-table"
      defaultSort={{
        id: 'updated_at',
        desc: true,
      }}
      defaultPageSize={defaultPageSize}
      storeTableName={TableNames.ordersTable}
    />
  );
};

export default OrdersTable;
