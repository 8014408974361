import { baseApi } from 'apiSlice';
import {
  APIOrderTemplate,
  IOrderTemplate,
  mapOrderTemplateFromAPI,
  mapOrderTemplateToAPI,
  SetDefaultBody,
} from 'Order/types/orderTemplate';
import { asArray, JSONAPIResponse } from 'shared/types/JSONApiResponse';
import { Methods, ResourceTypes } from 'Request';
import { mapOrderTemplateJSONAPIResponse, mapOrderToOrderTemplateState, Payload } from 'Order/crud/orderTemplateMapper';
import { validateOrderTemplateName } from 'Order/crud/orderTemplatesValidator';

const orderTemplatesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrderTemplates: build.query<IOrderTemplate[], string>({
      query: (customerId) => `/customers/${customerId}/order-templates`,
      providesTags: ['OrderTemplates'],
      transformResponse: (response: JSONAPIResponse<APIOrderTemplate[]>): IOrderTemplate[] =>
        asArray(mapOrderTemplateJSONAPIResponse(response).data),
    }),
    createOrderTemplate: build.mutation<IOrderTemplate, Payload>({
      invalidatesTags: ['OrderTemplates'],
      query: (payload) => {
        return {
          url: `/customers/${payload.customerId}/order-templates`,
          method: Methods.POST,
          body: {
            data: {
              type: ResourceTypes.order_template,
              attributes: {
                title: payload.name,
                original_order_id: payload.orderId,
                template_body: mapOrderToOrderTemplateState(payload.order),
                is_default: payload.isDefault ?? false,
              },
            },
          },
        };
      },
      transformResponse: (response: JSONAPIResponse<APIOrderTemplate>): IOrderTemplate =>
        mapOrderTemplateFromAPI(response.data),
    }),
    updateOrderTemplate: build.mutation<IOrderTemplate, APIOrderTemplate | SetDefaultBody>({
      invalidatesTags: ['OrderTemplates'],
      query: (orderTemplate) => {
        return {
          url: `/order-templates/${orderTemplate.id}`,
          method: Methods.PATCH,
          body: {
            data: orderTemplate,
          },
        };
      },
      transformResponse: (response: JSONAPIResponse<APIOrderTemplate>): IOrderTemplate =>
        mapOrderTemplateFromAPI(response.data),
    }),
    deleteOrderTemplate: build.mutation<void, string>({
      invalidatesTags: ['OrderTemplates'],
      query: (orderTemplateId) => {
        return {
          url: `/order-templates/${orderTemplateId}`,
          method: Methods.DELETE,
        };
      },
    }),
    unsetDefault: build.mutation<void, string>({
      invalidatesTags: ['OrderTemplates'],
      query: (customerId) => {
        return {
          url: `/customers/${customerId}/order-templates/unset-default`,
          method: Methods.POST,
        };
      },
    }),
  }),
});

const {
  useGetOrderTemplatesQuery,
  useCreateOrderTemplateMutation,
  useUpdateOrderTemplateMutation,
  useDeleteOrderTemplateMutation,
  useUnsetDefaultMutation,
} = orderTemplatesApi;

export interface UseOrderTemplates {
  isLoading: boolean;
  orderTemplates: IOrderTemplate[] | undefined;
  hasOrderTemplates: boolean;
  createOrderTemplate: (orderTemplate: Payload) => Promise<IOrderTemplate>;
  updateOrderTemplate: (orderTemplate: IOrderTemplate) => Promise<IOrderTemplate>;
  deleteOrderTemplate: (orderTemplateId: string) => Promise<void>;
  setAsDefault: (orderTemplateId: string) => Promise<IOrderTemplate>;
  unsetDefault: () => Promise<void>;
  isValidName: (name: string) => boolean;
  reload: () => void;
  deleteAll: () => void;
}

export const useOrderTemplates = (customerId: string): UseOrderTemplates => {
  const orderTemplates = useGetOrderTemplatesQuery(customerId);
  const [createOrderTemplate, create] = useCreateOrderTemplateMutation();
  const [updateOrderTemplate, update] = useUpdateOrderTemplateMutation();
  const [deleteOrderTemplate, deleteState] = useDeleteOrderTemplateMutation();
  const [unsetDefault, unset] = useUnsetDefaultMutation();

  return {
    isLoading:
      orderTemplates.isFetching ||
      orderTemplates.isLoading ||
      update.isLoading ||
      create.isLoading ||
      deleteState.isLoading ||
      unset.isLoading,
    orderTemplates: orderTemplates.currentData,
    isValidName: (name) =>
      validateOrderTemplateName(name, orderTemplates.currentData?.map((it) => it.attributes.title) ?? []),
    createOrderTemplate: (args) => createOrderTemplate(args).unwrap(),
    updateOrderTemplate: (args) => updateOrderTemplate(mapOrderTemplateToAPI(args)).unwrap(),
    deleteOrderTemplate: (orderTemplateId: string) => deleteOrderTemplate(orderTemplateId).unwrap(),
    unsetDefault: () => unsetDefault(customerId).unwrap(),
    setAsDefault: (orderTemplateId: string) =>
      updateOrderTemplate({
        id: orderTemplateId,
        type: ResourceTypes.order_template,
        attributes: { is_default: true },
      }).unwrap(),
    hasOrderTemplates: !!orderTemplates.currentData && orderTemplates.currentData.length > 0,
    reload: orderTemplates.refetch,
    deleteAll: () => {
      orderTemplates.currentData?.map((it) => it.id).forEach((id) => deleteOrderTemplate(id));
    },
  };
};
