import getAmortisedValueForContractTerm from 'Quotes/utils/getAmortisedValueForContractTerm';
import {
  ContractTerm,
  monthsToYears,
} from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';
import { IPriceData, IQuotesState } from 'Quotes/types/store';
import { cloneDeep } from 'lodash';
import { ProductType } from 'Quotes/types/productTypes';
import {
  IDummyPrice,
  isDummyPrice,
  isRealPrice,
} from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';

export const getPreferredPrice = (
  preferredPriceId: string,
  prices: IPriceData[],
  missingPrices: IDummyPrice[] | undefined
): IPriceData | IDummyPrice | undefined => {
  const preferredPrice = prices.find((price: IPriceData) => price.id === preferredPriceId);
  if (preferredPrice) return preferredPrice;

  const preferredMissingPrice = missingPrices?.find((price: IDummyPrice) => price.id === preferredPriceId);
  if (preferredMissingPrice) return preferredMissingPrice;

  return prices[0];
};

export const setRealPrice = (draft: IQuotesState, selectedPrice: IPriceData, shouldAmortise: boolean) => {
  draft.pricing.selectedDummyPrice = undefined;
  draft.pricing.selectedPrice = {
    ...cloneDeep<IPriceData>(selectedPrice),
    amortised: shouldAmortise,
  };

  if (selectedPrice.product_sub_type === 'optical' && draft.quote.productType === ProductType.P2P) {
    draft.quote.productType = ProductType.OpticalP2P;
  } else if (selectedPrice.product_sub_type === 'ethernet' && draft.quote.productType === ProductType.OpticalP2P) {
    draft.quote.productType = ProductType.P2P;
  }

  draft.quote.isPoa = selectedPrice.is_poa || false;

  return;
};

export const setSecondaryCircuitsEnabled = (draft: IQuotesState, priceId: string, enabled: boolean) => {
  if (draft.pricing.selectedPrice.id === priceId && draft.pricing.selectedPrice.secondary_circuits) {
    draft.pricing.selectedPrice.secondary_circuits.enabled = enabled;
  }
  const basePrice = draft.pricing.allPrices.find((it) => it.id === priceId);
  if (basePrice && basePrice.secondary_circuits) {
    basePrice.secondary_circuits.enabled = enabled;
  }
};

export const setSecondaryCircuits = (
  draft: IQuotesState,
  priceId: string,
  secondaryCircuits: IPriceData['secondary_circuits']
) => {
  if (draft.pricing.selectedPrice.id === priceId) draft.pricing.selectedPrice.secondary_circuits = secondaryCircuits;
  const basePrice = draft.pricing.allPrices.find((it) => it.id === priceId);
  if (basePrice) basePrice.secondary_circuits = secondaryCircuits;
};

export const addPricesToSelectedCircuit = (
  draft: IQuotesState,
  primaryPriceId: string,
  circuitId: string,
  prices: IPriceData[]
) => {
  const primaryPrice = draft.pricing.allPrices.find((it) => it.id === primaryPriceId);
  const circuitOnSelectedPrice = draft.pricing.selectedPrice.secondary_circuits?.circuits.find(
    (it) => it.id === circuitId
  );
  const circuit = primaryPrice?.secondary_circuits?.circuits.find((circuit) => circuit.id === circuitId);
  if (!circuit) return;
  circuit.prices = prices;

  if (!circuitOnSelectedPrice) return;
  circuitOnSelectedPrice.prices = prices;
};

export const setSelectedPriceOnCircuit = (
  draft: IQuotesState,
  primaryPriceId: string,
  circuitId: string,
  selectedPriceId: string
) => {
  const primaryPrice = draft.pricing.allPrices.find((it) => it.id === primaryPriceId);
  const circuitOnSelectedPrice = draft.pricing.selectedPrice.secondary_circuits?.circuits.find(
    (it) => it.id === circuitId
  );
  const circuit = primaryPrice?.secondary_circuits?.circuits.find((it) => it.id === circuitId);
  if (!circuit) return;
  circuit.selectedPriceId = selectedPriceId;

  if (!circuitOnSelectedPrice) return;
  circuitOnSelectedPrice.selectedPriceId = selectedPriceId;
};

export const setDummyPrice = (draft: IQuotesState, selectedPrice: IDummyPrice) => {
  draft.pricing.selectedDummyPrice = cloneDeep<IDummyPrice>(selectedPrice);
  if (draft.quote.productType === ProductType.P2P) {
    draft.quote.productType = ProductType.OpticalP2P;
  }
  draft.quote.isPoa = true;
};

export const getAmortise = (amortised: boolean | undefined, term: ContractTerm | undefined) =>
  amortised === undefined ? getAmortisedValueForContractTerm(term ? monthsToYears(term) : undefined) : amortised;

const setSelectedPrice = (
  draft: IQuotesState,
  preferredPriceId: string,
  amortised: boolean | undefined,
  prices: IPriceData[],
  missingPrices: IDummyPrice[] | undefined
) => {
  const selectedPrice = getPreferredPrice(preferredPriceId, prices, missingPrices);
  if (selectedPrice === undefined) return;

  if (isRealPrice(selectedPrice)) {
    const shouldAmortise = getAmortise(amortised, draft.quote.contractTerm || undefined);
    setRealPrice(draft, selectedPrice, shouldAmortise);
  } else if (isDummyPrice(selectedPrice)) {
    setDummyPrice(draft, selectedPrice);
  }
};

export default setSelectedPrice;
