import React from 'react';

import Configure from '.';

import { IAccordionSectionRenderProps } from '../../types';

const renderConfigure = ({ activeIndex, index, showContent }: IAccordionSectionRenderProps) => (
  <Configure
    accordion={{
      activeIndex,
      index,
      showContent,
    }}
  />
);

export default renderConfigure;
