import cx from 'classnames';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Button, { IButtonProps, ButtonMainStyle } from '../../atoms/Button';
import Icon from '../../atoms/Icon';

interface IButtonWithIconProps extends IButtonProps {
  iconName: string;
  iconPlacement?: 'right' | 'left';
}

const ButtonWithIcon: FunctionComponent<React.PropsWithChildren<IButtonWithIconProps>> = ({
  children,
  id,
  loading,
  type = 'button',
  mainStyle = ButtonMainStyle.Primary,
  subStyle,
  onClick,
  className,
  iconName,
  iconPlacement = 'right',
  disabled = false,
}) => {
  return (
    <Button
      className={cx(className, iconPlacement)}
      mainStyle={mainStyle}
      subStyle={subStyle}
      type={type}
      onClick={onClick}
      disabled={disabled}
      id={id}
      loading={loading}
    >
      <span style={{ order: iconPlacement === 'left' ? 1 : -1 }}>{children}</span>
      <Icon name={iconName} className="icon" size="large" />
    </Button>
  );
};

const StyledButtonWithIcon = styled(ButtonWithIcon)`
  display: inline-flex;
  column-gap: 8px;
  align-items: center;
`;

export default StyledButtonWithIcon;
