import React, { useEffect, useState } from 'react';
import { SecondaryEngineerInstallationRequired } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/EngineerInstallation';
import { SecondaryRackMountKit } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/RackMount';
import { IQuote } from 'Quotes/types/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearRouterChoice,
  cloneQuote as cloneQuoteAction,
  setRouterChoice,
  setSecondaryCircuitOptions,
} from 'Quotes/actions';
import { DualCircuitRouterConfigurations } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import styled from 'styled-components';
import { Option, OptionGroup } from 'shared/components/organisms/OptionGroup/OptionGroup';
import {
  getIPTypeBySubnetNumber,
  getLabelBySubnetNumber,
  getMinIPAddress,
  getSubnetNumberByIPType,
} from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import Modal from 'shared/components/molecules/Modal';
import { getPrimaryIPLabel } from 'shared/components/molecules/SecondaryCircuits/SecondaryIPAllocation';
import { Title } from 'shared/components/molecules/SecondaryCircuits/DIAConfig';
import { BooleanOptionGroup } from 'shared/components/organisms/OptionGroup/utils/BooleanOptionGroup';
import Alert, { Alerts } from 'shared/components/atoms/Alert';
import { bearerForDisplay } from 'shared/utils/connectionCapacity';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import { IAppState } from 'reducers';

const OptionsContainer = styled.div`
  margin-top: 1rem;
`;

export const mdiaRouterOptions: Option<DualCircuitRouterConfigurations>[] = [
  { label: 'Active/Active', value: DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_ACTIVE },
  { label: 'Active/Standby', value: DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_STANDBY },
  { label: 'Independent', value: DualCircuitRouterConfigurations.DUAL_INDEPENDENT },
];

const getDualRouterLabel = (routerChoice: DualCircuitRouterConfigurations) => {
  const option = mdiaRouterOptions.find((option) => option.value === routerChoice);
  return option ? option.label : undefined;
};

interface MDIARouterProps {
  quote: IQuote;
  isReadOnly: boolean;
  setEnabled: (enabled: boolean) => void;
}

function DualSingleSelector(props: { onClick: (newValue: boolean) => void; readOnly: boolean }) {
  return (
    <>
      <Title>Second Router</Title>
      <p className="text-muted mb-4">
        Coming Soon! Single router option. Contact your account manager for further details.
      </p>
      <BooleanOptionGroup
        onClick={props.onClick}
        value={true}
        customIdBase="is_second_router_required"
        readOnly={props.readOnly}
        disableRight={true}
        leftLabel={'Dual Routers'}
        rightLabel={'Single Router'}
      />
    </>
  );
}

function DualRouterConfig(props: {
  onClick: (newValue: DualCircuitRouterConfigurations) => void;
  quote: IQuote;
  readOnly: boolean;
}) {
  return (
    <>
      <p className="mb-2">Dual Router Config</p>
      <p className="text-muted mb-4">
        <strong>Dual failover</strong>: active/active or active/standby. <strong>Independent</strong>: two active and
        independent routers.
      </p>
      <OptionGroup
        options={mdiaRouterOptions}
        onClick={props.onClick}
        value={props.quote.location.aEnd.routerChoice}
        readOnly={props.readOnly}
      />
    </>
  );
}

export function MDIARouters({ quote, isReadOnly }: MDIARouterProps) {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const setEngineer = (engineer: boolean) =>
    dispatch(setSecondaryCircuitOptions({ engineerInstallationRequired: engineer }));
  const setRackMount = (rackMountKit: boolean) =>
    dispatch(setSecondaryCircuitOptions({ rackMountKitRequired: rackMountKit }));

  useEffect(() => {
    setEngineer(
      quote.location.aEnd.secondRouterOptions?.engineerInstallationRequired ??
        quote.location.aEnd.is_engineer_installation_required ??
        false
    );
    setRackMount(
      quote.location.aEnd.secondRouterOptions?.rackMountKitRequired ??
        quote.location.aEnd.is_rack_mount_required ??
        false
    );
  }, [dispatch]);

  const selectedPrimaryIP = quote.location.aEnd.ip.selectedId;
  if (!selectedPrimaryIP) {
    return null;
  }
  const bearer = quote.bearer;
  const { routerChoice } = quote.location.aEnd;
  const selectedPrimaryIPValue = getSubnetNumberByIPType(selectedPrimaryIP);
  const minimumPrimaryIPForMDIA = getMinIPAddress(bearer, routerChoice);
  const isPrimaryIPChangeRequired = minimumPrimaryIPForMDIA ? selectedPrimaryIPValue! > minimumPrimaryIPForMDIA : false;

  useEffect(() => {
    if (isPrimaryIPChangeRequired) {
      setShowModal(true);
    }
  }, [isPrimaryIPChangeRequired]);

  const handleCloseModal = () => {
    setShowModal(false);
    // setEnabled(false); // turn off the toggle when modal is closed
    dispatch(clearRouterChoice(undefined));
  };

  const handleConfirmModal = () => {
    const newQuote = JSON.parse(JSON.stringify(quote));
    newQuote.location.aEnd.ip.selectedId = getIPTypeBySubnetNumber(minimumPrimaryIPForMDIA!);
    dispatch(cloneQuoteAction(newQuote));
  };

  const primaryIPLabel = getPrimaryIPLabel(quote);
  const modalTitle = `${primaryIPLabel} change required`;

  function SecondRouter() {
    return (
      <div data-testid="mdia-secondary-router-section">
        <br />
        <DualSingleSelector
          onClick={(newValue) => {
            dispatch(
              setRouterChoice(
                newValue
                  ? DualCircuitRouterConfigurations.DUAL_FAILOVER_ACTIVE_ACTIVE
                  : DualCircuitRouterConfigurations.SINGLE
              )
            );
            if (!newValue) {
              setEngineer(false);
              setRackMount(false);
            }
          }}
          readOnly={isReadOnly}
        />
        <br />
        <DualRouterConfig
          onClick={(newValue) => {
            dispatch(setRouterChoice(newValue));
          }}
          quote={quote}
          readOnly={isReadOnly}
        />
      </div>
    );
  }

  const clonePending = useSelector((state: IAppState) => state.quoteBuilder.cloning.inProgress);
  const cloneError = useSelector((state: IAppState) => state.quoteBuilder.cloning.error);

  return (
    <>
      {showModal && (
        <Modal
          title={modalTitle}
          onClose={handleCloseModal}
          onConfirm={handleConfirmModal}
          closeBtnLabel="Return"
          confirmBtnLabel="New Quote"
        >
          <Alert alertType={Alerts.WARNING}>
            The selected <strong>Primary IP Addresses</strong> configuration <strong>{primaryIPLabel}</strong> is
            insufficient to support a diverse option.
          </Alert>
          <Alert alertType={Alerts.SUCCESS}>
            For a <strong>{bearerForDisplay(bearer)} Bearer</strong> &{' '}
            <strong>{getDualRouterLabel(routerChoice!)}</strong> dual router configuration, a minimum of{' '}
            <strong>{getLabelBySubnetNumber(minimumPrimaryIPForMDIA!)}</strong> is required.
          </Alert>
          <p>
            Do you wish to generate a new quote with a different <strong>Primary IP Addresses</strong> configuration or
            return to the current quote?
          </p>
          {clonePending && <Spinner className="saving" text="Creating new quote" size="large" />}
          {cloneError && (
            <Alert alertType={Alerts.DANGER}>
              Error creating new quote. Please try again later. If the problem persists, please contact your Account
              Manager.
            </Alert>
          )}
        </Modal>
      )}
      <div data-testid="mdia-secondary-router-section">
        <SecondRouter />
        {routerChoice && (
          <OptionsContainer>
            <SecondaryEngineerInstallationRequired
              required={quote.location.aEnd.secondRouterOptions?.engineerInstallationRequired ?? false}
              setRequired={setEngineer}
              isReadOnly={isReadOnly}
            />
            <SecondaryRackMountKit
              rackMountKit={quote.location.aEnd.secondRouterOptions?.rackMountKitRequired ?? false}
              setRackMountKit={setRackMount}
              isReadOnly={isReadOnly}
            />
          </OptionsContainer>
        )}
      </div>
    </>
  );
}
