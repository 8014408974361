import React from 'react';
import { Tooltip } from 'shared/components/atoms/Tooltip';

export const QuoteIsPOATooltip = () => (
  <Tooltip multiline text="Price on application">
    <h6>Price on application</h6>
    When you proceed with this quote we will run some additional checks, and your account manager will contact you with
    a price shortly after.
  </Tooltip>
);
