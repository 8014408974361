import React from 'react';
import { currencyFormatter } from 'shared/utils/currencyFormatter';

interface CostDisplayProps {
  label: string;
  cost: number;
}

const CostDisplay: React.FC<CostDisplayProps> = ({ label, cost }) => (
  <div className="price-summary__segment-wrapper">
    <div className="segment_label">{label}</div>
    <b className="currency">{currencyFormatter.format(cost)}</b>
  </div>
);

export default CostDisplay;
