import { BooleanOptionGroup } from 'shared/components/organisms/OptionGroup/utils/BooleanOptionGroup';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'reducers';
import { setIsEngineerInstallationRequired as setIsEngineerInstallationRequiredAction } from 'Quotes/actions';

function Primary(props: { onClick: (value: boolean) => void; value: boolean }) {
  return (
    <>
      <div data-testid="mdia-engineer-section">
        <p className="mt-4 mb-2">Do you require an engineer to install your MDIA router?</p>
        <p className="text-muted mb-4">
          Costs will be shown in the next step. The engineer is for the installation of the MDIA router only.
        </p>
        <BooleanOptionGroup
          onClick={props.onClick}
          value={props.value}
          customIdBase="is_engineer_installation_required"
        />
      </div>
    </>
  );
}

function Secondary(props: { onClick: (value: boolean) => void; value: boolean; isReadOnly?: boolean }) {
  return (
    <>
      <div data-testid="mdia-engineer-section">
        <br />
        <p className="text-muted mb-4">Do you require an engineer to install your second MDIA router?</p>
        <BooleanOptionGroup
          onClick={props.onClick}
          value={props.value}
          customIdBase="is_engineer_installation_required"
          readOnly={props.isReadOnly}
        />
      </div>
    </>
  );
}

const EngineerInstallationRequiredInternal = ({
  isEngineerInstallationRequired,
  setIsEngineerInstallationRequired,
  isMultiRouterChoice,
  isReadOnly,
}: {
  setIsEngineerInstallationRequired: (value: boolean) => void;
  isEngineerInstallationRequired: boolean;
  isMultiRouterChoice?: boolean;
  isReadOnly?: boolean;
}) => {
  return isMultiRouterChoice ? (
    <Secondary
      onClick={setIsEngineerInstallationRequired}
      value={isEngineerInstallationRequired}
      isReadOnly={isReadOnly}
    />
  ) : (
    <Primary onClick={setIsEngineerInstallationRequired} value={isEngineerInstallationRequired} />
  );
};

export const EngineerInstallationRequired = ({ isMultiRouterChoice }: { isMultiRouterChoice?: boolean }) => {
  const isEngineerInstallationRequired = useSelector(
    (state: IAppState) => state.quoteBuilder.quote.location.aEnd.is_engineer_installation_required
  );
  const dispatch = useDispatch();
  const setIsEngineerInstallationRequired = (installationRequired: boolean) =>
    dispatch(setIsEngineerInstallationRequiredAction(installationRequired));

  return (
    <EngineerInstallationRequiredInternal
      isEngineerInstallationRequired={isEngineerInstallationRequired}
      setIsEngineerInstallationRequired={setIsEngineerInstallationRequired}
      isMultiRouterChoice={isMultiRouterChoice}
    />
  );
};

export const SecondaryEngineerInstallationRequired = ({
  required,
  setRequired,
  isReadOnly,
}: {
  required: boolean;
  setRequired: (value: boolean) => void;
  isReadOnly?: boolean;
}) => {
  const isEngineerInstallationRequired = useSelector(
    (state: IAppState) => state.quoteBuilder.quote.location.aEnd.is_engineer_installation_required
  );
  const options = useSelector((state: IAppState) => state.quoteBuilder.quote.location.aEnd.secondRouterOptions);

  useEffect(() => {
    setRequired(options?.engineerInstallationRequired ?? isEngineerInstallationRequired);
  }, [isEngineerInstallationRequired, options?.engineerInstallationRequired]);

  return (
    <EngineerInstallationRequiredInternal
      isEngineerInstallationRequired={required}
      setIsEngineerInstallationRequired={setRequired}
      isMultiRouterChoice={true}
      isReadOnly={isReadOnly}
    />
  );
};
