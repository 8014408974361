import { INewNNIRequest, ISiteConfig } from 'Order/types/location';
import { IPriceData, IQuote, ISecondaryPrice } from 'Quotes/types/store';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { ProductType } from 'Quotes/types/productTypes';
import { SingleNNILocation } from 'Order/OrderBuilder/QuoteSummary/P2NNI/SingleNNILocation';
import React from 'react';
import styled from 'styled-components';

export const MultiCircuitPanel = styled.div`
  background: ${(props) => props.theme.colours.secondaryE2};
  border-radius: 10px;
  padding: 5px 15px 20px;
  margin-top: 15px;
`;
export function MultiNNILocations(props: {
  nniLabel: string;
  secondaryNNILabel: string;
  primaryNNIRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  secondaryNNIRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  siteConfig: ISiteConfig;
  secondarySiteConfig: ISiteConfig;
  quote: IQuote;
  selectedPrice: IPriceData;
  shadowRef: string;
  shadowNNIRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  sseUser: boolean;
  pointAddress: any;
  bandwidth: string;
  bearer: BearerType | undefined;
  secondaryBandwidth: string;
  secondaryBearer: BearerType | undefined;
  productType: ProductType;
  secondarySelectedPrice: ISecondaryPrice;
}) {
  return (
    <>
      <MultiCircuitPanel>
        <SingleNNILocation
          circuit={'Primary'}
          nniLabel={props.nniLabel}
          nniRequest={props.primaryNNIRequest}
          siteConfig={props.siteConfig}
          quote={props.quote}
          selectedPrice={props.selectedPrice}
          shadowRef={props.shadowRef}
          shadowNNIRequest={props.shadowNNIRequest}
          sseUser={props.sseUser}
          pointAddress={props.pointAddress}
          bandwidth={props.bandwidth}
          bearer={props.bearer}
          productType={props.productType}
        />
      </MultiCircuitPanel>
      <MultiCircuitPanel>
        <SingleNNILocation
          circuit={'Secondary'}
          nniLabel={props.secondaryNNILabel}
          nniRequest={props.secondaryNNIRequest}
          siteConfig={props.secondarySiteConfig}
          quote={props.quote}
          selectedPrice={props.secondarySelectedPrice}
          shadowRef={props.shadowRef}
          shadowNNIRequest={props.shadowNNIRequest}
          sseUser={props.sseUser}
          pointAddress={props.pointAddress}
          bandwidth={props.secondaryBandwidth}
          bearer={props.secondaryBearer}
          productType={props.productType}
        />
      </MultiCircuitPanel>
    </>
  );
}
