import { IAppState } from 'reducers';
import { QuoteStatus } from 'Quotes/types/quoteRecordAttributesBase';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { ProductType } from 'Quotes/types/productTypes';
import { selectAllPricedBandwidths, selectIsCablingChoiceMandatory, selectSelectedPrice } from 'Quotes/selectors';
import { isFttpAggregationPossible } from 'Quotes/utils/isFTTXQuote';
import { IDummyPrice } from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import { Supplier } from 'Quotes/types/supplier';
import { createSelector } from '@reduxjs/toolkit';
import { IPriceData, IQuote, IQuotesState } from 'Quotes/types/store';
import { ContractTerm } from 'Quotes/QuoteBuilder/components/Configure/ContractTermLength/contractTermLengths';

export const selectShouldEnableProceedToOrder = (state: IAppState) => {
  const {
    quote,
    state: quoteState,
    pricing: { selectedPrice },
  } = state.quoteBuilder;

  if (missingMandatoryCabling(state)) return false;

  // disabled because of progress states
  if (isInProgress(state)) return false;

  // Return true if quoteState is LAPSED, ORDERED, or quote is internal
  // - these always apply
  if (quoteState === QuoteStatus.LAPSED || quoteState === QuoteStatus.ORDERED || quote.is_internal) {
    return false;
  }

  const needsFTTPAggregationValue =
    !quote.isPoa &&
    featureFlag.isEnabled(Feature.fttpAggregation) &&
    (quote.fttpAggregation === undefined || quote.fttpAggregation === null);

  if (isFttpAggregationPossible(quote, selectedPrice) && needsFTTPAggregationValue) {
    return false;
  }

  if (selectedPrice.is_price_orderable === false){
    return false;
  }

  if (selectedPrice.is_orderable === true) {
    return true;
  }

  // Optical P2P specific logic
  if (quote.productType === ProductType.OpticalP2P) {
    return featureFlag.isEnabled(Feature.opticalP2PCerillion) && quote.sent_to_cerillion_at === null;
  }

  return true;
};

export const shouldDisplayProceedToOrderButton = (state: IAppState) => {
  // cf: proceed to data capture above; I'm not sure if we have a state for this button
  // other than proceed to order / proceed to data capture - should we have one state here?
  // @todo
  const { quote, state: quoteState } = state.quoteBuilder;
  const selectedPrice = selectSelectedPrice(state);

  // If the quote has already been ordered, it can't be ordered again
  // Lapsed quotes can't be ordered
  if (isQuoteFinished(quoteState)) return false;

  // Optical P2P is always orderable if feature enabled
  if (featureFlag.isEnabled(Feature.opticalP2PCerillion) && quote.productType === ProductType.OpticalP2P) {
    return true;
  }

  return !quote.isPoa && selectedPrice.is_orderable !== false;
};

function isQuoteFinished(quoteState: QuoteStatus | undefined) {
  return quoteState !== undefined && [QuoteStatus.ORDERED, QuoteStatus.LAPSED].includes(quoteState);
}

const missingMandatoryCabling = (state: IAppState) => {
  const cabling_type_selection = state.quoteBuilder.quoteEndpointMeta.cabling_type_selection || [];
  const selectedPrice = state.quoteBuilder.pricing.selectedPrice;
  const isCablingChoiceMandatory = selectIsCablingChoiceMandatory(state);
  if (isCablingChoiceMandatory.A || isCablingChoiceMandatory.B) {
    const existing = cabling_type_selection.find((item) => item.priceId === selectedPrice.id);

    if (!existing) return true;

    if (isCablingChoiceMandatory.A && !existing.a_end_cabling_type_selected) {
      return true;
    }

    if (isCablingChoiceMandatory.B && !existing.b_end_cabling_type_selected) {
      return true;
    }
  }
  return false;
};
function isInProgress(state: IAppState) {
  const pricingProgress = state.quoteBuilder.pricing.pricingProgress;
  const savingONATAddress = state.quoteBuilder.savingONATAddress;
  return (
    pricingProgress.fetchingPrice ||
    pricingProgress.error ||
    pricingProgress.loadingUpdate ||
    state.orderBuilder.creating.inProgress ||
    !!savingONATAddress.inProgress
  );
}

function includesChosenTerm(pricedBandwidths: TermAndBandwidth[], chosenTerm: TermAndBandwidth) {
  for (const pricedTerm of pricedBandwidths) {
    if (chosenTerm.term === pricedTerm.term && chosenTerm.bandwidth === pricedTerm.bandwidth) return true;
  }
  return false;
}

export const filterUnpricedBandwidths = (
  pricedBandwidths: TermAndBandwidth[],
  chosenBandwidths: TermAndBandwidth[]
) => {
  const missingTerms: TermAndBandwidth[] = [];
  for (const chosenTerm of chosenBandwidths) {
    if (!includesChosenTerm(pricedBandwidths, chosenTerm)) {
      missingTerms.push(chosenTerm);
    }
  }
  return missingTerms;
};

export type TermAndBandwidth = { bandwidth: string; term: ContractTerm };

const findUnpricedBandwidths = (state: IQuotesState): TermAndBandwidth[] => {
  if (
    !featureFlag.isEnabled(Feature.Optical100G400G) ||
    !featureFlag.getAttribute<boolean>(Feature.Optical100G400G, 'dummyPricesEnabled')
  )
    return [];

  const chosenBandwidths: string[] = state.quote.chosen_bandwidths;
  const chosenTerms: ContractTerm[] = state.quote.chosen_term_lengths_in_months;
  const allPossibleTerms: TermAndBandwidth[] = chosenBandwidths.flatMap((bandwidth) =>
    chosenTerms.flatMap((term): TermAndBandwidth => ({ bandwidth: bandwidth, term: term }))
  );
  const pricedTerms: TermAndBandwidth[] = selectAllPricedBandwidths(state);
  return filterUnpricedBandwidths(pricedTerms, allPossibleTerms);
};

export const createMissingPrices = (quote: IQuote, unpricedBandwidths: TermAndBandwidth[]): IDummyPrice[] => {
  return unpricedBandwidths.map(
    ({ bandwidth, term }): IDummyPrice => {
      return {
        id: `${bandwidth}-${term}`,
        type: 'dummy',
        term_length_in_months: term,
        bandwidth: bandwidth,
        aEndSupplier: Supplier.NONE,
        bEndSupplier: Supplier.NONE,
        aEndPostcode: quote.location.aEnd.postcode,
        bEndPostcode: quote.location.bEnd.postcode,
        aEndAccessMethod: 'Optical',
        bEndAccessMethod: 'Optical',
        aEndDataCentreReference: quote.location.aEnd.dataCentreReference,
        bEndDataCentreReference: quote.location.bEnd.dataCentreReference,
        aEndDataCentreName: quote.location.aEnd.fullAddress?.attributes.name,
        bEndDataCentreName: quote.location.bEnd.fullAddress?.attributes.name,
      };
    }
  );
};

export const generateMissingPrices = createSelector(
  (state: IAppState) => state.quoteBuilder,
  (state: IQuotesState): IDummyPrice[] => {
    const unpricedBandwidths = findUnpricedBandwidths(state);
    if (unpricedBandwidths.length === 0) return [];
    return createMissingPrices(state.quote, unpricedBandwidths);
  }
);

export const selectPriceAmortised = createSelector(
  (state: IAppState) => state.quoteBuilder.pricing.selectedPrice,
  (selectedPrice: IPriceData) => selectedPrice.amortised
);
