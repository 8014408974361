import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import LinkButton from '../../../shared/components/atoms/LinkButton';
import { ButtonMainStyle } from '../../../shared/components/atoms/Button';
import { newQuote } from '../../Routes';
import PresentForRoles from '../../../Auth/PresentForRoles';
import { userPermissions } from '../../../shared/utils/permissions';

interface IEmptyQuotesList {
  className?: string;
}

const EmptyQuotesList: FunctionComponent<React.PropsWithChildren<IEmptyQuotesList>> = ({ className }) => (
  <div className={className}>
    <p>It looks like you don&apos;t have any quotes yet.</p>
    <PresentForRoles roles={userPermissions.createEditQuote}>
      <p>
        <LinkButton
          buttonText="Start a quote"
          pathname={newQuote}
          id="start-a-quote-inline-link"
          mainStyle={ButtonMainStyle.Link}
        />
        &nbsp;to see how much you could save with Neos Networks.
      </p>
    </PresentForRoles>
  </div>
);

const StyledEmptyQuotesList = styled(EmptyQuotesList)<IEmptyQuotesList>`
  #start-a-quote-inline-link {
    color: ${(props) => props.theme.colours.primaryC1};
  }
`;

export default StyledEmptyQuotesList;
