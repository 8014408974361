import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import StyledBrowseInput from '../../../../../shared/components/atoms/BrowseInput';

interface IUploadFileView {
  className?: string;
  onUploadFile: (files: FileList) => void;
  showWrongFormatMessage: boolean;
}

const UploadFileView: FunctionComponent<React.PropsWithChildren<IUploadFileView>> = ({
  className,
  onUploadFile,
  showWrongFormatMessage,
}) => {
  return (
    <div className={`${className} uploadFileView__wrapper pb-4`}>
      <h4>Upload your file.</h4>
      {showWrongFormatMessage && (
        <p className="wrongFormat">{"*Oops, it looks like you're using the wrong file type!"}</p>
      )}
      <p>
        {'Make sure that your quotes are uploaded in the correct .CSV format – '}
        <a href="/documents/bulk_quote_upload_template.csv" download>
          download template
        </a>
        {'.'}
      </p>
      <StyledBrowseInput onUploadFile={onUploadFile} inputText="Upload file" />
    </div>
  );
};

const StyledUploadFileView = styled(UploadFileView)<IUploadFileView>`
  .wrongFormat {
    color: ${(props) => props.theme.colours.primaryC1};
  }
`;

export default StyledUploadFileView;
