import styled from 'styled-components';

export const StackedOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  > * {
    grid-column: span 1;
  }
`;
