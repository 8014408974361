import React, { FC } from 'react';
import { OptionGroup, Option } from '../OptionGroup';

interface IBooleanOptionGroupProps {
  customClassname?: string;
  onClick: (value: boolean) => void;
  value: boolean;
  customIdBase: string;
  readOnly?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  disableRight?: boolean;
  disableLeft?: boolean;
}
export const BooleanOptionGroup: FC<IBooleanOptionGroupProps> = ({
  customClassname,
  customIdBase,
  onClick,
  value,
  readOnly = false,
  disableRight = false,
  disableLeft = false,
  leftLabel = 'Yes',
  rightLabel = 'No',
}) => {
  const options: Option<boolean>[] = [
    {
      customId: `${customIdBase}_yes`,
      label: leftLabel,
      value: true,
      disabled: readOnly || disableLeft,
    },
    {
      customId: `${customIdBase}_no`,
      label: rightLabel,
      value: false,
      disabled: readOnly || disableRight,
    },
  ];

  const handleClick = (value: unknown) => {
    if (typeof value === 'boolean' && !readOnly) {
      onClick(value);
    }
  };

  return (
    <OptionGroup
      options={options}
      onClick={handleClick}
      value={value}
      customClassName={customClassname}
      readOnly={readOnly}
    />
  );
};
