import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import MultiSelectTable, { IMultiSelectTable, RowCell } from 'shared/components/organisms/MultiSelectTable';
import { getHeadersByProductType } from './headers';
import { ProductType } from 'Quotes/types/productTypes';

interface List {
  className?: string;
  data: IMultiSelectTable['rows'];
  productType: ProductType;
  onRowClick(cell: RowCell): void;
}

const defaultPageSize = 250;

const List: FunctionComponent<React.PropsWithChildren<List>> = ({ className, data, productType, onRowClick }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return null;
  }

  const props: IMultiSelectTable = {
    className,
    defaultPageSize,
    rows: data,
    rowClickHandler: onRowClick,
    headers: [],
  };

  const isQuoteMultiTerm = data.some((row) => row.termLengthInMonths);
  props.headers = getHeadersByProductType(productType, isQuoteMultiTerm);

  if (props.headers.length === 0) {
    return null;
  }

  return <MultiSelectTable {...props} />;
};

const StyledList = styled(List)`
  .price_list_row_selected {
    border: 3px solid ${(props) => props.theme.colours.primaryB1};
  }
  &.prices-list td {
    height: 100px;
    // TODO: find a cleaner way to override the props propagated by react-table getCellProps()
    display: flex !important;
    align-items: center;
  }
`;

export { StyledList as List };
