import styled from 'styled-components';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import React, { useState } from 'react';
import RolesInfoPane from 'Admin/shared/components/RolesInfoPane';
import { customerRoleValues, sseRoleValues } from 'User/utils/roles';

const RolesInfoWrapper = styled.div`
  position: relative;
`;
const RoleInfoButton = styled(Button)`
  color: ${(props) => props.theme.colours.primaryB1};
  position: absolute;
  top: -60px;
  right: 0;
`;
export const RolesInfo = ({ sseUser }: { sseUser: boolean }) => {
  const [showRolesInfoPane, setShowRolesInfoPane] = useState<boolean>(false);
  return (
    <RolesInfoWrapper>
      <RoleInfoButton onClick={() => setShowRolesInfoPane(true)} mainStyle={ButtonMainStyle.Link}>
        Role information
      </RoleInfoButton>

      {showRolesInfoPane && (
        <RolesInfoPane
          roles={sseUser ? sseRoleValues() : customerRoleValues()}
          onClose={() => setShowRolesInfoPane(false)}
        />
      )}
    </RolesInfoWrapper>
  );
};
