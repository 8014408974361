import { IAppState } from '../reducers';

export const selectSingleQuotesTableFilters = (state: IAppState) => state.tables.singleQuotesTable.filters;

export const selectBulkQuotesTableFilters = (state: IAppState) => state.tables.bulkQuotesTable.filters;

export const selectOrdersTableFilters = (state: IAppState) => state.tables.ordersTable.filters;

export const selectUsersTableFilters = (state: IAppState) => state.tables.usersTable.filters;

export const selectOrdersReportTableFilters = (state: IAppState) => state.tables.ordersReportTable.filters;

export const selectUsersTablePageSize = (state: IAppState) => state.tables.usersTable.pageSize;

export const selectSingleQuotesTablePageSize = (state: IAppState) => state.tables.singleQuotesTable.pageSize;

export const selectBulkQuotesTablePageSize = (state: IAppState) => state.tables.bulkQuotesTable.pageSize;

export const selectOrdersTablePageSize = (state: IAppState) => state.tables.ordersTable.pageSize;

export const selectOrdersReportTablePageSize = (state: IAppState) => state.tables.ordersReportTable.pageSize;

export const selectHistoricalAndAPITablePageSize = (state: IAppState) => state.tables.historicalAndAPITable.pageSize;
