import React, { FunctionComponent } from 'react';
import { Supplier } from 'Quotes/types/supplier';
import styled from 'styled-components';
import { IPriceData, IQuote } from 'Quotes/types/store';
import { PriceCard } from '../PriceCard';
import cx from 'classnames';
import {
  AccessMethodComponent,
  BandwidthLinkComponent,
  CostComponent,
  DataCentreComponent,
  SupplierImageComponent,
  SupplierNameComponent,
} from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider/OpticalP2P/Components';

const identifierPrefix = 'OpticalP2P';

interface IOpticalP2PProps {
  aEndPrice: number | null;
  aEndSupplier: Supplier;
  aEndPostcode: string;
  aEndAccessMethod: IQuote['aEndAccessMethod'];
  bEndAccessMethod: IQuote['bEndAccessMethod'];
  a_end_data_centre_reference?: string | null;
  a_end_data_centre_name?: string | null;
  amortisedAnnualPrice: number | null;
  bEndPostcode: string;
  bEndPrice: number | null;
  bEndSupplier: Supplier;
  b_end_data_centre_reference?: string | null;
  b_end_data_centre_name?: string | null;
  className?: string;
  onClick?: () => void;
  priceId: string | null;
  selected?: boolean;
  readonly: boolean;
  product_sub_type: IPriceData['product_sub_type'];
  bandwidth?: string;
  term_length_in_months?: IPriceData['term_length_in_months'];
  isPoA?: boolean;
}

export const OpticalP2P: FunctionComponent<React.PropsWithChildren<IOpticalP2PProps>> = ({
  selected = false,
  onClick,
  amortisedAnnualPrice,
  a_end_data_centre_reference,
  b_end_data_centre_reference,
  a_end_data_centre_name,
  b_end_data_centre_name,
  aEndSupplier,
  aEndPostcode,
  bEndPostcode,
  bEndSupplier,
  aEndPrice,
  bEndPrice,
  priceId,
  className,
  readonly,
  product_sub_type,
  bandwidth,
  aEndAccessMethod,
  bEndAccessMethod,
  term_length_in_months,
  isPoA = false,
}) => {
  return (
    <PriceCard
      identifierPrefix={identifierPrefix}
      priceId={priceId!}
      readonly={readonly}
      selected={selected}
      onClick={onClick}
      bEndPostcode={bEndPostcode}
      amortisedAnnualPrice={amortisedAnnualPrice}
      product_sub_type={product_sub_type}
      term_length_in_months={term_length_in_months}
      bandwidth={bandwidth}
      isPoA={isPoA}
    >
      <ul className={cx(className, 'list-unstyled')}>
        <SupplierImageComponent supplier={aEndSupplier} />
        <DataCentreComponent
          dataCentreName={a_end_data_centre_name}
          dataCentreReference={a_end_data_centre_reference}
          fallbackPostcode={aEndPostcode}
          data-testId="a-end-location"
        />
        <SupplierNameComponent supplier={aEndSupplier} data-testId="a-end-supplier" />
        <AccessMethodComponent accessMethod={aEndAccessMethod} data-testId="a-end-access-method" />
        <CostComponent poA={isPoA} price={aEndPrice} data-testId="a-end-price" />
        <BandwidthLinkComponent />
        <SupplierImageComponent supplier={bEndSupplier} />
        <DataCentreComponent
          dataCentreName={b_end_data_centre_name}
          dataCentreReference={b_end_data_centre_reference}
          fallbackPostcode={bEndPostcode}
          data-testId="b-end-location"
        />
        <SupplierNameComponent supplier={bEndSupplier} data-testId="b-end-supplier" />
        <AccessMethodComponent accessMethod={bEndAccessMethod} data-testId="b-end-access-method" />
        <CostComponent poA={isPoA} price={bEndPrice} data-testId="b-end-price" />
      </ul>
    </PriceCard>
  );
};

export const StyledOpticalP2P = styled(OpticalP2P)`
  .dc-ref {
    color: ${(props) => props.theme.colours.grey70};
    display: block;
    font-size: 0.75em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
