export const base = '/quotes';

export const newQuote = `${base}/new`;
export const newQuoteByLocation = `${base}/new/location`;
export const bulkQuotes = `${base}/bulks`;
export const multiQuotes = `${base}/multi`;
export const apiQuotes = `${base}/api`;
export const quoteById = (id: string, panelToOpen?: number | null) =>
  `${base}/${id}${panelToOpen ? `?initial-panel=${panelToOpen}` : ''}`;
export const quoteByIdByLocation = (id: string) => {
  return `${base}/by_location/${id}`;
};
export const apiQuoteById = (id: string) => `${base}/api/${id}`;
export const quoteList = `${base}`;
export const bulkQuoteById = (id: string) => `${base}/bulks/${id}`;
export const cerillionQuoteById = (id: string) => `${base}/cerillion/${id}`;
export const multiQuoteById = (id: string) => `${multiQuotes}/${id}`;

export const relativeBase = '';

export const relativeNewQuote = `${relativeBase}/new`;
export const relativeNewQuoteByLocation = `${relativeBase}/new/location`;
export const relativeBulkQuotes = `${relativeBase}/bulks`;
export const relativeMultiQuotes = `${relativeBase}/multi`;
export const relativeApiQuotes = `${relativeBase}/api`;
export const relativeCerillionQuotes = `${relativeBase}/cerillion`;
export const relativeQuoteList = `${relativeBase}`;
