export interface IPromoOffersRecord {
  data: IPromoOffer[];
}

export interface IPromoOffer {
  id: string;
  type: 'promotion';
  attributes: PromoOfferFields;
}

export interface PromoOfferFields {
  banner: {
    display: boolean;
    url?: string;
  };
  body: string;
  createdAt: string;
  title: string;
  userId: string;
  validFrom: string | null;
  validUntil: string | null;
}

export interface APIPromoOffersRecord {
  data: APIPromoOffer[];
}

export interface APIUpdatePromoOffersResponse {
  data: APIPromoOffer;
}

export interface APIPromoOffer {
  id: string;
  type: 'promotion';
  attributes: APIPromoOfferFields;
}

export interface APIPromoOfferFields {
  banner_url: string;
  banner_hidden: boolean;
  body: string;
  created_at: string;
  title: string;
  user_id: string;
  valid_from: string | null;
  valid_until: string | null;
}

export const toPromoRecord = (promoOffers: APIPromoOffersRecord): IPromoOffer[] => promoOffers.data.map(toPromoOffer);

export const toPromoOffer = (apiOffer: APIPromoOffer): IPromoOffer => {
  return {
    id: apiOffer.id,
    type: apiOffer.type,
    attributes: mapAPIOfferAttributes(apiOffer.attributes),
  };
};

const mapAPIOfferAttributes = (attributes: APIPromoOfferFields): PromoOfferFields => {
  return {
    banner: {
      display: !attributes.banner_hidden,
      url: attributes.banner_url,
    },
    body: attributes.body,
    createdAt: attributes.created_at,
    title: attributes.title,
    userId: attributes.user_id,
    validFrom: attributes.valid_from,
    validUntil: attributes.valid_until,
  };
};
