import React, { FunctionComponent, useRef } from 'react';
import styled from 'styled-components';
import { Supplier, SupplierEndType } from 'Quotes/types/supplier';
import StyledSupplierSelectionCheckboxes from '../SupplierSelectionCheckboxes';
import Column from 'shared/components/atoms/Column';
import { presentSupplierName } from 'Quotes/utils/supplierHelpers';
import { IAppState } from 'reducers';
import { connect, DispatchProp } from 'react-redux';
import { setSupplierEndFilter, setFilteredPricedData } from 'Quotes/actions';
import { IPriceData } from 'Quotes/types/store';
import { useOutsideClick } from 'shared/utils/customHooks';
import { ProductType } from 'Quotes/types/productTypes';
import { isNeosNetworksSupplier, supplierListIncludesNeosNeworksSupplier } from 'Quotes/utils/supplierHelpers';
import FilterButton from '../shared/FilterButton';

interface ISupplierSelectionDropdownProps {
  className?: string;
  productType: ProductType;
  suppliers: Supplier[];
  filteredData: {
    aEndFilterList: Supplier[];
    bEndFilterList: Supplier[];
  };
  allPrices: IPriceData[];
  setFilter(supplier: Supplier, endType: SupplierEndType): void;
  setFilteredPrices(): void;
}

export const SupplierSelectionDropdown: FunctionComponent<React.PropsWithChildren<ISupplierSelectionDropdownProps>> = (
  props: ISupplierSelectionDropdownProps
) => {
  const { className, productType, suppliers, filteredData, allPrices, setFilter, setFilteredPrices } = props;

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const checkEndValue = (supplier: Supplier, endtype: SupplierEndType) => {
    if (endtype === SupplierEndType.AEnd) {
      if (isNeosNetworksSupplier(supplier)) {
        return supplierListIncludesNeosNeworksSupplier(filteredData.aEndFilterList);
      }
      return filteredData.aEndFilterList.includes(supplier);
    } else if (endtype === SupplierEndType.BEnd) {
      if (isNeosNetworksSupplier(supplier)) {
        return supplierListIncludesNeosNeworksSupplier(filteredData.bEndFilterList);
      }
      return filteredData.bEndFilterList.includes(supplier);
    }
  };

  const disableEndValue = (supplier: Supplier, endtype: SupplierEndType) => {
    if (endtype === SupplierEndType.AEnd) {
      if (isNeosNetworksSupplier(supplier)) {
        return !supplierListIncludesNeosNeworksSupplier(
          allPrices.map((price) => price.a_end_access_type) as Supplier[]
        );
      }
      return !allPrices.map((price) => price.a_end_access_type).includes(supplier);
    } else if (endtype === SupplierEndType.BEnd) {
      if (isNeosNetworksSupplier(supplier)) {
        return !supplierListIncludesNeosNeworksSupplier(
          allPrices.map((price) => price.b_end_access_type) as Supplier[]
        );
      }
      return !allPrices.map((price) => price.b_end_access_type).includes(supplier);
    }
  };

  const onEndChange = (supplier: Supplier, endType: SupplierEndType) => {
    setFilter(supplier, endType);
    setFilteredPrices();
  };

  if (productType !== ProductType.NNI2CCT) {
    return (
      <div className={`${className} supplier-selection-dropdown`} tabIndex={0} ref={dropdownRef}>
        <FilterButton
          onClick={toggleDropdown}
          className="supplier-selection-dropdown__dropdown"
          data-testid="supplier-selection-dropdown__dropdown"
          isOpen={isOpen}
        >
          <span className="supplier-selection-dropdown__name">Suppliers</span>
        </FilterButton>

        {isOpen && (
          <div className="supplier-selection-dropdown__options">
            <div className="supplier-selection-dropdown__options-labels">
              <Column defaultWidth={5} classNames={['supplier-selection-dropdown__options-labels__provider']}>
                Provider
              </Column>
              <Column defaultWidth={1} />
              {productType === ProductType.P2P && (
                <>
                  <Column defaultWidth={3} classNames={['supplier-selection-dropdown__options-labels__aEnd']}>
                    A-End
                  </Column>
                  <Column defaultWidth={3} classNames={['supplier-selection-dropdown__options-labels__bEnd']}>
                    B-End
                  </Column>
                </>
              )}
              {productType === ProductType.P2CCT && (
                <>
                  <Column defaultWidth={3} classNames={['supplier-selection-dropdown__options-labels__aEnd']}>
                    A-End
                  </Column>
                </>
              )}
            </div>
            {suppliers.map((supplier) => {
              return (
                <StyledSupplierSelectionCheckboxes
                  key={supplier}
                  productType={productType}
                  label={presentSupplierName(supplier)}
                  aEndOnChange={() => onEndChange(supplier, SupplierEndType.AEnd)}
                  bEndOnChange={() => onEndChange(supplier, SupplierEndType.BEnd)}
                  aEndValue={checkEndValue(supplier, SupplierEndType.AEnd)}
                  bEndValue={checkEndValue(supplier, SupplierEndType.BEnd)}
                  aEndDisabled={disableEndValue(supplier, SupplierEndType.AEnd)}
                  bEndDisabled={disableEndValue(supplier, SupplierEndType.BEnd)}
                />
              );
            })}
            <p className="dropdown-note">
              Results do not include suppliers you have opted not to see. Edit your preferences to make changes.
            </p>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export const StyledSupplierSelectionDropdown = styled(SupplierSelectionDropdown)`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  z-index: 1;

  :focus {
    outline: none;
    outline-offset: none;
  }

  .supplier-selection-dropdown__control {
    border: 0;
    color: ${(props) => props.theme.colours.primaryB1};
    margin-left: 0.5em;
    margin-top: 0.16em;

    &:hover {
      border: 0;
    }
  }

  .supplier-selection-dropdown__options {
    min-width: 240px;
    max-width: 300px;

    background: white;
    box-shadow: ${(props) => props.theme.colours.grey20} 0px 0px 8px 0px;
    border-radius: 4px;
    color: #737373;
    padding: 1em 0.25em;
    position: absolute;
    width: 100%;
  }

  .supplier-selection-dropdown__options-labels {
    display: flex;
    font-size: 0.8em;
  }

  .supplier-selection-dropdown__options-labels__aEnd,
  .supplier-selection-dropdown__options-labels__bEnd {
    padding-left: 0;
  }

  .dropdown-note {
    margin: 1em 0 0 10px;
    font-size: 0.8em;
    font-weight: bold;
  }
`;

const mapStateToProps = (state: IAppState) => ({
  filteredData: {
    aEndFilterList: state.quoteBuilder.pricing.aEndFilterList,
    bEndFilterList: state.quoteBuilder.pricing.bEndFilterList,
  },
  allPrices: state.quoteBuilder.pricing.allPrices,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  setFilter: (supplier: Supplier, endType: SupplierEndType) => dispatch(setSupplierEndFilter(supplier, endType)),
  setFilteredPrices: () => dispatch(setFilteredPricedData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledSupplierSelectionDropdown);
