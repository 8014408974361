import { IPriceData } from 'Quotes/types/store';
import { Slider } from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider';
import PriceTile from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/PriceTile';
import React from 'react';
import {
  IDummyPrice,
  isDummyPrice,
} from 'Quotes/QuoteBuilder/components/Price/components/SupplierSelection/Prices/DummyPrice';
import DummyPriceTile from 'Quotes/QuoteBuilder/components/Price/components/SupplierView/Slider/DummyPriceTile';
import { useSecondaryCircuits } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';

function SecondaryCircuitAwareTile({
  onClick,
  onPriceItemClick,
  priceData,
  readOnly,
  selectedPriceId,
  Tile,
}: {
  priceData: IPriceData;
  onClick: () => void;
  selectedPriceId: string;
  onPriceItemClick: (priceId: string) => void;
  readOnly: boolean;
  Tile: typeof PriceTile;
}) {
  const secondaryCircuits = useSecondaryCircuits(priceData);
  const supportsSecondaryCircuits = secondaryCircuits.isSupported();
  return (
    <Tile
      priceData={priceData}
      onClick={onClick}
      priceSelected={selectedPriceId ? selectedPriceId === priceData.id : false}
      onPriceItemClick={onPriceItemClick}
      readOnly={readOnly}
      supportsSecondaryCircuits={supportsSecondaryCircuits}
    />
  );
}

export function PricesSlider({
  onPriceItemClick,
  prices,
  readonly,
  resetPriceError,
  selectedPriceId,
  slidesToShow,
  Tile = PriceTile,
}: {
  prices: (IPriceData | IDummyPrice)[];
  selectedPriceId: string;
  readonly: boolean;
  slidesToShow: number;
  resetPriceError: () => void;
  onPriceItemClick: (priceId: string) => void;
  Tile?: typeof PriceTile;
}) {
  return (
    <Slider
      prices={prices}
      selectedPriceId={selectedPriceId}
      readonly={readonly}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToShow}
    >
      {prices.map((priceData) => {
        if (isDummyPrice(priceData)) {
          return (
            <DummyPriceTile
              key={priceData.id}
              price={priceData}
              onClick={resetPriceError}
              priceSelected={selectedPriceId ? selectedPriceId === priceData.id : false}
              onPriceItemClick={onPriceItemClick}
              readOnly={readonly}
            />
          );
        } else {
          return (
            <SecondaryCircuitAwareTile
              Tile={Tile}
              key={priceData.id}
              priceData={priceData}
              onClick={resetPriceError}
              selectedPriceId={selectedPriceId}
              onPriceItemClick={onPriceItemClick}
              readOnly={readonly}
            />
          );
        }
      })}
    </Slider>
  );
}
