import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import ReactQuill from 'react-quill';

interface IHtmlEditorProps {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

export const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'video'],
    ['clean'],
  ],
};

export const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'link', 'video'];

const UnstyledHtmlEditor: FC<React.PropsWithChildren<IHtmlEditorProps>> = ({ className, value, onChange }) => {
  const theme = useTheme();

  return (
    <ReactQuill
      className={className}
      theme={theme.htmlEditorTheme}
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
    />
  );
};
export const HtmlEditor = styled(UnstyledHtmlEditor)`
  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 1px solid #4f4f4f;
  }
`;
