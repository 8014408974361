import { isFTTXConnection } from 'Quotes/types/connectionType';
import { IPriceData, IQuote, QuoteOrigin } from 'Quotes/types/store';

export const isFttpAggregationPossible = (quote: IQuote, selectedPrice: IPriceData) => {
  return (
    !isFTTXConnection(quote.connectionType) &&
    !isAPIQuote(quote.origin) &&
    selectedPrice.fttp_aggregation_charge !== null
  );
};

export const isFTTXQuote = (quote: IQuote) => {
  return isFTTXConnection(quote.connectionType);
};

const isAPIQuote = (origin: QuoteOrigin) => origin === QuoteOrigin.API;
