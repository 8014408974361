import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import { CheckboxButtonIcon } from '../CheckboxButtonIcon';

interface ICheckboxButton {
  description: string;
  className?: string;
  id?: string;
  key?: string;
  inline?: boolean;
  size?: 'small' | 'medium' | 'large' | 'fill';
  selected?: boolean;
  status?: 'notSelected' | 'selected';
  disabled?: boolean;
  onClick?(event: any): void;
  tooltipText?: string | undefined;
}

const CheckboxButton: FunctionComponent<React.PropsWithChildren<ICheckboxButton>> = ({
  onClick,
  description,
  className,
  id,
  inline,
  size = 'fill',
  selected,
  status,
  disabled,
  tooltipText,
}) => {
  return (
    <div
      className={cx(className, status, size, {
        inline,
        selected: !status && selected,
        notSelected: !status && !selected,
        disabled,
      })}
      id={`wrapper-${id}`}
      onClick={(e) => {
        e.preventDefault();
        if (!disabled && !!onClick) {
          onClick(e);
        }
      }}
    >
      <div className="checkboxButton__inner">
        <div className="checkboxButton__content">
          <label>
            <input type="checkbox" className="radioButton__input" id={id} disabled={disabled} />
            <div className="checkboxButton__icon-and-description">
              <CheckboxButtonIcon checked={status === 'selected' || selected} disabled={disabled} />
              <p className="checkboxButton__description font-weight-bold">
                {description}
                {!!tooltipText && <Tooltip text={tooltipText} />}
              </p>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export const CheckboxButtonDisabledOverride = styled.div`
  .disabled.selected {
    cursor: not-allowed;

    img {
      opacity: 0.5;
    }

    p,
    i {
      color: ${(props) => props.theme.colours.grey30};
    }
  }
`;

const StyledCheckboxButton = styled(CheckboxButton)`
  background-color: white;
  color: ${(props) => props.theme.colours.grey70};
  line-height: initial;
  user-select: none;
  border-radius: 4px;
  height: 52px;
  vertical-align: middle;
  width:100%;
  display: block;

  &.inline {
    display: inline-block;
  }
  
  &.small {
    width: 130px;
    font-size: 100%;
  }
  
  &.medium {
    width: 210px;
  }

  &.large {
    width: 250px;
  }

  &.fill {
    width: 100%;
  }  
  
  &.disabled {  
    img {
      opacity: 0.5;
    }
    
    p {
      color: ${(props) => props.theme.colours.grey30};
    }

    i {
      color: ${(props) => props.theme.colours.primaryB1};
    }

    &.selected {
      img {
        opacity: 1;
      }
    
      p, i {
        color: ${(props) => props.theme.colours.grey70};
      }
    }
  }
  
  :not(:last-child) {
    margin-right: 1.25em;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled:hover {
    cursor: not-allowed;
  }

  [type='checkbox'] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  button:focus {
    outline: 0;
  }

  .checkboxButton__content {
    display: table;
    height: 100%;
    label {
      margin-bottom: 0;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .checkboxButton__content {
    padding-left: 15px;
  }

  .checkboxButton__content label:hover {
    cursor: pointer;
  }

  &.disabled .checkboxButton__content label:hover {
    cursor: not-allowed;
  }
  
  .checkboxButton__inner {
    height:100%;
    width:100%;
    border 1.5px solid transparent;
  }

  &.notSelected {
    box-shadow: 0 2px 4px 0 rgba(0, 70, 135, 0.1);
    border: solid 0.5px ${(props) => props.theme.colours.secondaryC5};
  }

  &.selected {
    color: ${(props) => props.theme.colours.primaryB1};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 0.5px ${(props) => props.theme.colours.primaryB1};
    .checkboxButton__inner {
      border 1.5px solid ${(props) => props.theme.colours.primaryB1};
    }
  }

  .checkboxButton__icon-and-description {
    display: flex;

    .checkboxButton__description {
      margin-left: 0.4em;
      margin-top: auto;
      margin-bottom: auto;
      text-align: left;
      display: flex;
      align-items: center;

      > span {
        margin-left: 0.3em;
      }
    }
  }
  
  &.big {
    .checkboxButton__description {
      padding-right: 50px;
    }
  }
  
  .icon {
    width: 29px;
    height: 29px;
  }

  .checkboxButton__icon-and-description:hover {
    cursor: pointer;
  }

  &.disabled .checkboxButton__icon-and-description:hover {
    cursor: not-allowed;
  }
`;

export default StyledCheckboxButton;
