import { AddressType, IAEndLocation, ILocation, INNI, ProviderName } from '../../types/store';
import { ProductType } from '../../types/productTypes';
import { IMeta } from '../../types/quoteRecordAttributesBase';

interface IQuoteDetails {
  aEndLocation: IAEndLocation;
  bEndLocation: ILocation;
  productType: ProductType | null | undefined;
  meta: IMeta;
}

interface ISavedQuoteDetails extends IQuoteDetails {
  currentQuoteId: string | undefined;
}

export const isConfigureDisabled = ({
  aEndLocation,
  bEndLocation,
  currentQuoteId,
  productType,
  meta,
}: ISavedQuoteDetails): boolean => {
  switch (productType) {
    case ProductType.P2P:
      return (
        !aEndLocation.postcode ||
        !bEndLocation.postcode ||
        !currentQuoteId ||
        !onNetPostcodeValidation(aEndLocation, meta.a_end_address_not_listed) ||
        !onNetPostcodeValidation(bEndLocation, meta.b_end_address_not_listed)
      );

    case ProductType.P2NNI:
      return (
        !bEndLocation.postcode ||
        !isValidNNISelection(aEndLocation.nni) ||
        !currentQuoteId ||
        !onNetPostcodeValidation(bEndLocation, meta.b_end_address_not_listed)
      );

    case ProductType.DIA:
      return (
        !aEndLocation.postcode ||
        !currentQuoteId ||
        !onNetPostcodeValidation(aEndLocation, meta.a_end_address_not_listed)
      );

    case ProductType.P2CCT:
      return (
        !aEndLocation.postcode ||
        !currentQuoteId ||
        !onNetPostcodeValidation(aEndLocation, meta.a_end_address_not_listed) ||
        aEndLocation.cloudConnect.name === ProviderName.NOT_DEFINED
      );

    case ProductType.NNI2CCT:
      return (
        !isValidNNISelection(aEndLocation.nni) ||
        !currentQuoteId ||
        aEndLocation.cloudConnect.name === ProviderName.NOT_DEFINED
      );

    default:
      return true;
  }
};

export const isLocationDetailCompleted = ({
  aEndLocation,
  bEndLocation,
  currentQuoteId,
  productType,
  meta,
}: ISavedQuoteDetails): boolean => {
  switch (productType) {
    case ProductType.P2P:
      return (
        !!aEndLocation.postcode &&
        !!bEndLocation.postcode &&
        !!currentQuoteId &&
        onNetPostcodeValidation(aEndLocation, meta.a_end_address_not_listed) &&
        onNetPostcodeValidation(bEndLocation, meta.b_end_address_not_listed)
      );

    case ProductType.P2NNI:
      return (
        !!bEndLocation.postcode &&
        isValidNNISelection(aEndLocation.nni) &&
        !!currentQuoteId &&
        onNetPostcodeValidation(bEndLocation, meta.b_end_address_not_listed)
      );

    case ProductType.DIA:
      return (
        !!aEndLocation.postcode &&
        !!currentQuoteId &&
        onNetPostcodeValidation(aEndLocation, meta.a_end_address_not_listed)
      );

    case ProductType.OpticalP2P:
      return (
        !!aEndLocation.optical.selectedId &&
        !!currentQuoteId &&
        (!!bEndLocation.optical.selectedId ||
          (!!bEndLocation.postcode &&
            postcodeNotChanged(bEndLocation.postcode, bEndLocation.draftPostcode) &&
            onNetPostcodeValidation(bEndLocation, meta.b_end_address_not_listed)))
      );

    case ProductType.P2CCT:
      return (
        !!aEndLocation.postcode &&
        !!currentQuoteId &&
        onNetPostcodeValidation(aEndLocation, meta.a_end_address_not_listed) &&
        aEndLocation.cloudConnect.name !== ProviderName.NOT_DEFINED
      );

    case ProductType.NNI2CCT:
      return (
        isValidNNISelection(aEndLocation.nni) &&
        !!currentQuoteId &&
        aEndLocation.cloudConnect.name !== ProviderName.NOT_DEFINED
      );

    default:
      return false;
  }
};

export const onNetPostcodeValidation = (location: ILocation, notListed: boolean | undefined): boolean => {
  if (location.addressType === AddressType.ON_NET) {
    if (location.fullAddress !== null || notListed === true) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const isValidNNISelection = (nni: INNI): boolean => {
  if (!nni.selectedId && !nni.selectedDataCentreId) {
    return false;
  }

  if (nni.shadowVLAN.enabled && !nni.shadowVLAN.selectedId && !nni.shadowVLAN.selectedDataCentreId) {
    return false;
  }

  return true;
};

const isLocationCompleted = (location: ILocation, addressNotListed: boolean | undefined) =>
  !!location.postcode &&
  postcodeNotChanged(location.postcode, location.draftPostcode) &&
  onNetPostcodeValidation(location, addressNotListed);

export const isUnsavedLocationDetailCompleted = ({
  aEndLocation,
  bEndLocation,
  productType,
  meta,
}: IQuoteDetails): boolean => {
  switch (productType) {
    case ProductType.P2P:
      return (
        isLocationCompleted(aEndLocation, meta.a_end_address_not_listed) &&
        isLocationCompleted(bEndLocation, meta.b_end_address_not_listed)
      );

    case ProductType.P2NNI:
      return isValidNNISelection(aEndLocation.nni) && isLocationCompleted(bEndLocation, meta.b_end_address_not_listed);

    case ProductType.DIA:
      return isLocationCompleted(aEndLocation, meta.a_end_address_not_listed);

    case ProductType.OpticalP2P:
      return (
        !!aEndLocation.optical.selectedId &&
        (!!bEndLocation.optical.selectedId ||
          (!!bEndLocation.addressType && onNetPostcodeValidation(bEndLocation, meta.b_end_address_not_listed)))
      );

    case ProductType.P2CCT:
      return (
        isLocationCompleted(aEndLocation, meta.a_end_address_not_listed) &&
        aEndLocation.cloudConnect.name !== ProviderName.NOT_DEFINED
      );

    case ProductType.NNI2CCT:
      return isValidNNISelection(aEndLocation.nni) && aEndLocation.cloudConnect.name !== ProviderName.NOT_DEFINED;

    default:
      return false;
  }
};

export const postcodeNotChanged = (postcode: string, draftPostcode: string): boolean =>
  postcode.trim().replace(/\s/g, '') === draftPostcode.trim().replace(/\s/g, '');
