import { doRequest, Methods } from 'Request';

const deleteOrder = (orderId: string) => {
  return doRequest({
    method: Methods.POST,
    path: `/orders/${orderId}/archive`,
  });
};

export default deleteOrder;
