import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import Icon from '../../../../../shared/components/atoms/Icon';
import Button, { ButtonMainStyle } from '../../../../../shared/components/atoms/Button';
import { IBulkQuoteUploadState, UploadState } from '../../../../types/store';
import Spinner from '../../../../../shared/components/atoms/Spinner';

interface ISelectedFileView {
  className?: string;
  fileName: string;
  bulkQuoteUploadState: IBulkQuoteUploadState;
  onDelete: () => void;
  onSubmitBulkQuote: () => void;
}

const STATUS_MESSAGE_IDENTIFIER = 'bulkQuoteSubmitStatus';

function statusMessage(uploadState: IBulkQuoteUploadState) {
  if (uploadState === UploadState.EMPTY) {
    return null;
  }

  if (uploadState === UploadState.IN_PROGRESS) {
    return (
      <div className={STATUS_MESSAGE_IDENTIFIER}>
        <Spinner />
        <div className="text-center mt-1">Processing your quotes</div>
      </div>
    );
  }

  if (uploadState === UploadState.SUCCESSFUL) {
    return <p className={`${STATUS_MESSAGE_IDENTIFIER} my-2`}>Your bulk quote was uploaded</p>;
  }

  if (uploadState[0] === UploadState.ERRORED) {
    const errors = uploadState[1];
    return (
      <div className={STATUS_MESSAGE_IDENTIFIER}>
        <p className="my-2" key="message">
          Something went wrong getting your quotes
        </p>
        {errors.length > 0 && (
          <p className="my-2 text-warning" key="error-count">
            {errors.length}x errors
          </p>
        )}
        {errors.map((error, index) => (
          <p className="my-2" key={index}>
            {`${error.source.row}: ${error.detail}`}
            {error.source.parameter && `(${error.source.parameter})`}
          </p>
        ))}
      </div>
    );
  }
}

const SelectedFileView: FunctionComponent<React.PropsWithChildren<ISelectedFileView>> = ({
  className,
  fileName,
  bulkQuoteUploadState,
  onDelete,
  onSubmitBulkQuote,
}) => {
  const getQuotesButton = (
    <Button mainStyle={ButtonMainStyle.Primary} onClick={onSubmitBulkQuote} className="getQuotes-btn">
      Get Quotes
    </Button>
  );

  const deleteFileButton = (
    <Button mainStyle={ButtonMainStyle.Link} onClick={onDelete} className="deleteUploadedFile-btn">
      Delete
    </Button>
  );

  const notGettingQuotes = bulkQuoteUploadState === UploadState.EMPTY;
  const uploadError = bulkQuoteUploadState[0] === UploadState.ERRORED;

  return (
    <div className={`${className} selectFileView__wrapper`}>
      <div className="fileName__wrapper">
        <Icon
          className={`${uploadError ? 'text-warning' : ''}`}
          name={uploadError ? 'error_outline' : 'check_circle_outline'}
        />
        <p className={cx('uploadedFileName', { 'text-warning': uploadError })}>{fileName}</p>
        {deleteFileButton}
      </div>
      {statusMessage(bulkQuoteUploadState)}
      {notGettingQuotes && getQuotesButton}
    </div>
  );
};

const StyledSelectedFileView = styled(SelectedFileView)<ISelectedFileView>`
  .fileName__wrapper {
    display: flex;
    font-size: 1.2em;
    color: ${(props) => props.theme.colours.primaryB1};
    overflow: auto;
  }

  .deleteUploadedFile-btn {
    color: ${(props) => props.theme.colours.grey60};
    margin-left: 1em;
    margin-right: 1em;
  }

  .uploadedFileName {
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 70%;
  }

  .getQuotes-btn {
    margin-top: 2em;
    border-radius: 0.4em;
  }

  i {
    margin-right: 0.5em;
  }

  p {
    margin-bottom: 0;
  }
`;

export default StyledSelectedFileView;
