import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Column from 'shared/components/atoms/Column';
import CardRow from 'shared/components/molecules/CardRow';
import ExistingContactOptions, {
  ContactIdentifierType,
} from 'Order/OrderBuilder/shared/components/ExistingContactOptions';
import TextInput from 'shared/components/atoms/TextInput';
import TextAreaInput from 'shared/components/atoms/TextAreaInput';
import { updateIPContact, updateIPText, updateSecondaryIPDetails, updateWanIpInAddition } from 'Order/actions';
import { IPType } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/ip';
import { IPContact } from 'Order/types/formFields';
import { MAX_LENGTH_LONG_INPUT } from 'Order/OrderBuilder/shared/utils/validation';
import { isStaticIPWanIPCompatible, wanIpAdditionQuestion } from 'Quotes/QuoteBuilder/utils/ip';
import { Tooltip } from 'shared/components/atoms/Tooltip';
import RadioButton from 'shared/components/molecules/RadioButton';
import { ProductType } from 'Quotes/types/productTypes';
import { selectAEnd, selectProductType } from 'Quotes/selectors';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { selectOrder } from 'Order/selectors';
import {
  IUpdateIPContact,
  IUpdateIPText,
  IUpdateSecondaryIPDetails,
  IUpdateWanIpInAddition,
} from 'Order/types/actions';
import { IPAddressAllocation } from 'Quotes/QuoteBuilder/components/Configure/DIAConfig/types/diaIPAllocation';
import { IOrder } from 'Order/types/store';
import { useSecondaryCircuitsWithSelectedPrice } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';

const inputIdentifierPrefix = 'ipContact_';
const toolTipText = `If you require a WAN IP (/31 subnet) interface in addition to the public
addresses selected, please select this option when completing the order form. Mandatory
justification is required by RIPE for orders of 16 static IP addresses.`;

interface Props {
  className?: string;
}

const ONLY_STATIC_16 = [IPType.STATIC_16];
const MDIA_RANGE = [IPType.STATIC_16, IPType.SUBNET_26, IPType.SUBNET_27, IPType.SUBNET_28];

export const isIPAddressJustificationRequiredById = (selectedId: IPType | null | undefined) => {
  if (!selectedId) return false;
  const ipRange = featureFlag.isEnabled(Feature.newMDIAUI) ? MDIA_RANGE : ONLY_STATIC_16;
  return ipRange.includes(selectedId);
};

function WANIpAddition(props: {
  selectedId: IPType | null | undefined;
  productType: ProductType | null | undefined;
  managedDia: boolean;
  diaIpAllocation: IPAddressAllocation | null;
  onClick: (value: boolean) => IUpdateWanIpInAddition;
  order: IOrder;
}) {
  return (
    <>
      {props.selectedId && isStaticIPWanIPCompatible(props.selectedId) && props.productType !== ProductType.DIA && (
        <div className="row no-gutters pt-3">
          <Column defaultWidth={6} offsetDefaultWidth={6}>
            <p>
              {wanIpAdditionQuestion(props.selectedId, props.managedDia, props.diaIpAllocation)}
              <Tooltip text={toolTipText} />
            </p>

            <div className="row no-gutters">
              <Column defaultWidth={6} classNames={['pr-2']}>
                <RadioButton
                  id="wan_ip_in_addition_yes"
                  description="Yes"
                  onClick={() => props.onClick(true)}
                  selected={!!props.order.wan_ip_in_addition}
                />
              </Column>
              <Column defaultWidth={6} classNames={['pl-2']}>
                <RadioButton
                  id="wan_ip_in_addition_no"
                  description="No"
                  onClick={() => props.onClick(false)}
                  selected={!props.order.wan_ip_in_addition}
                />
              </Column>
            </div>
          </Column>
        </div>
      )}
    </>
  );
}

function RIPEJustificationText() {
  return <>RIPE require justification for orders containing 16 or more static IP addresses. (/28 or larger)</>;
}

function SingleCircuitIPAddressDeployment(props: {
  className: string | undefined;
  contact: IPContact;
  order: IOrder;
  onContactInputChange: (field: keyof IPContact, value: string) => IUpdateIPContact;
  ipText: string;
  onIpTextChange: (text: string) => IUpdateIPText;
  ipAddressJustificationRequired: boolean;
  selectedId: IPType | null | undefined;
  productType: ProductType | null | undefined;
  managedDia: boolean;
  diaIpAllocation: IPAddressAllocation | null;
  onWanChange: (wanIpInAddition: boolean) => IUpdateWanIpInAddition;
}) {
  return (
    <CardRow className={props.className} title="IP address deployment">
      <div className="row no-gutters">
        <Column defaultWidth={4} offsetDefaultWidth={1}>
          This person will be used as a contact by Neos Networks or RIPE in relation to this Deployment of IP addresses.
          <ExistingContactOptions
            contact={props.contact}
            contactIdentifier={ContactIdentifierType.ipContact}
            order={props.order}
          />
        </Column>
        <Column defaultWidth={6} offsetDefaultWidth={1}>
          <FirstNameInput onChange={props.onContactInputChange} ipContact={props.contact} />
          <SurnameInput onChange={props.onContactInputChange} ipContact={props.contact} />
          <TelephoneInput onChange={props.onContactInputChange} ipContact={props.contact} />
          <OrganisationInput onChange={props.onContactInputChange} ipContact={props.contact} />
          <EmailInput ipContact={props.contact} onChange={props.onContactInputChange} />
          <AddressInput onChange={props.onContactInputChange} ipContact={props.contact} />
          <RIPEHandle
            onChange={(value) => props.onContactInputChange('nic_ripe_handle', value)}
            ripeHandle={props.contact.nic_ripe_handle}
          />
        </Column>
      </div>
      <div className="row no-gutters">
        <Column defaultWidth={4} offsetDefaultWidth={1}>
          <RIPEJustificationText />
        </Column>
        <Column defaultWidth={6} offsetDefaultWidth={1}>
          <IPTextInput
            ipText={props.ipText}
            onChange={props.onIpTextChange}
            ipAddressJustificationRequired={props.ipAddressJustificationRequired}
          />
        </Column>
      </div>

      <WANIpAddition
        selectedId={props.selectedId}
        productType={props.productType}
        managedDia={props.managedDia}
        diaIpAllocation={props.diaIpAllocation}
        onClick={props.onWanChange}
        order={props.order}
      />
    </CardRow>
  );
}

function DualCircuitIPAddressDeployment(props: {
  className: string | undefined;
  contact: IPContact;
  order: IOrder;
  onContactInputChange: (field: keyof IPContact, value: string) => IUpdateIPContact;
  ipText: string;
  onIpTextChange: (text: string) => IUpdateIPText;
  ipAddressJustificationRequired: boolean;
  selectedId: IPType | null | undefined;
  productType: ProductType | null | undefined;
  managedDia: boolean;
  diaIpAllocation: IPAddressAllocation | null;
  onWanChange: (wanIpInAddition: boolean) => IUpdateWanIpInAddition;
  onSecondaryIPChange: (ipText: string, nicRipeHandle: string) => IUpdateSecondaryIPDetails;
}) {
  const secondaryIpConfig = props.order.secondaryIPDetails ?? { ipText: '', nicRIPEHandle: '' };
  return (
    <>
      <CardRow className={props.className} title="IP address deployment">
        <div className="row no-gutters">
          <Column defaultWidth={4} offsetDefaultWidth={1}>
            This person will be used as a contact by Neos Networks or RIPE in relation to this Deployment of IP
            addresses.
            <ExistingContactOptions
              contact={props.contact}
              contactIdentifier={ContactIdentifierType.ipContact}
              order={props.order}
            />
          </Column>
          <Column defaultWidth={6} offsetDefaultWidth={1}>
            <FirstNameInput onChange={props.onContactInputChange} ipContact={props.contact} />
            <SurnameInput onChange={props.onContactInputChange} ipContact={props.contact} />
            <TelephoneInput onChange={props.onContactInputChange} ipContact={props.contact} />
            <OrganisationInput onChange={props.onContactInputChange} ipContact={props.contact} />
            <EmailInput ipContact={props.contact} onChange={props.onContactInputChange} />
            <AddressInput onChange={props.onContactInputChange} ipContact={props.contact} />
          </Column>
        </div>
      </CardRow>
      <CardRow title={''} subtitle="Primary Circuit">
        <div className="row no-gutters">
          <Column defaultWidth={4} offsetDefaultWidth={1}></Column>
          <Column defaultWidth={6} offsetDefaultWidth={1}>
            <RIPEHandle
              onChange={(value) => props.onContactInputChange('nic_ripe_handle', value)}
              ripeHandle={props.contact.nic_ripe_handle}
            />
          </Column>
        </div>
        <div className="row no-gutters">
          <Column defaultWidth={4} offsetDefaultWidth={1}>
            <RIPEJustificationText />
          </Column>
          <Column defaultWidth={6} offsetDefaultWidth={1}>
            <IPTextInput
              ipText={props.ipText}
              onChange={props.onIpTextChange}
              ipAddressJustificationRequired={props.ipAddressJustificationRequired}
            />
          </Column>
        </div>
      </CardRow>
      <CardRow title={''} subtitle="Secondary Circuit">
        <div className="row no-gutters">
          <Column defaultWidth={4} offsetDefaultWidth={1}></Column>
          <Column defaultWidth={6} offsetDefaultWidth={1}>
            <RIPEHandle
              onChange={(value) => props.onSecondaryIPChange(secondaryIpConfig.ipText, value)}
              ripeHandle={secondaryIpConfig.nicRIPEHandle}
            />
          </Column>
        </div>
        <div className="row no-gutters">
          <Column defaultWidth={4} offsetDefaultWidth={1}>
            <RIPEJustificationText />
          </Column>
          <Column defaultWidth={6} offsetDefaultWidth={1}>
            <IPTextInput
              ipText={secondaryIpConfig.ipText}
              onChange={(value) => props.onSecondaryIPChange(value, secondaryIpConfig.nicRIPEHandle)}
              ipAddressJustificationRequired={props.ipAddressJustificationRequired}
            />
          </Column>
        </div>

        <WANIpAddition
          selectedId={props.selectedId}
          productType={props.productType}
          managedDia={props.managedDia}
          diaIpAllocation={props.diaIpAllocation}
          onClick={props.onWanChange}
          order={props.order}
        />
      </CardRow>
    </>
  );
}

export const Edit: FunctionComponent<React.PropsWithChildren<Props>> = ({ className }) => {
  const order = useSelector(selectOrder);
  const { ipContact, ipText } = order;
  const {
    ip: { selectedId },
    dia_ip_allocation,
    is_managed_dia,
  } = useSelector(selectAEnd);
  const dispatch = useDispatch();
  const productType = useSelector(selectProductType);

  const secondaryCircuits = useSecondaryCircuitsWithSelectedPrice();
  const hasSecondaryCircuit = secondaryCircuits.enabled && !!secondaryCircuits.selectedCircuit;

  const onChangeWithUpdate = (field: keyof IPContact, value: string) =>
    dispatch(updateIPContact({ ...ipContact, [field]: value }));

  const isIPAddressJustificationRequired = isIPAddressJustificationRequiredById(selectedId);

  return hasSecondaryCircuit ? (
    <DualCircuitIPAddressDeployment
      className={className}
      contact={ipContact}
      order={order}
      onContactInputChange={onChangeWithUpdate}
      ipText={ipText}
      onIpTextChange={(text) => dispatch(updateIPText(text))}
      ipAddressJustificationRequired={isIPAddressJustificationRequired}
      selectedId={selectedId}
      productType={productType}
      managedDia={is_managed_dia}
      diaIpAllocation={dia_ip_allocation}
      onWanChange={updateWanIpInAddition}
      onSecondaryIPChange={(ipText, nicRipeHandle) => dispatch(updateSecondaryIPDetails(ipText, nicRipeHandle))}
    />
  ) : (
    <SingleCircuitIPAddressDeployment
      className={className}
      contact={ipContact}
      order={order}
      onContactInputChange={onChangeWithUpdate}
      ipText={ipText}
      onIpTextChange={(text) => dispatch(updateIPText(text))}
      ipAddressJustificationRequired={isIPAddressJustificationRequired}
      selectedId={selectedId}
      productType={productType}
      managedDia={is_managed_dia}
      diaIpAllocation={dia_ip_allocation}
      onWanChange={updateWanIpInAddition}
    />
  );
};

export default styled(Edit)`
  margin-top: 1.5em;
`;

const FirstNameInput = (props: {
  onChange: (field: keyof IPContact, value: string) => IUpdateIPContact;
  ipContact: IPContact;
}) => (
  <TextInput
    inputIdentifierPrefix={inputIdentifierPrefix}
    fieldName="firstName"
    onChange={(e) => props.onChange('firstName', e.target.value)}
    labelText="First name *"
    maxLength={50}
    value={props.ipContact.firstName}
  />
);

function SurnameInput(props: {
  onChange: (field: keyof IPContact, value: string) => IUpdateIPContact;
  ipContact: IPContact;
}) {
  return (
    <TextInput
      inputIdentifierPrefix={inputIdentifierPrefix}
      fieldName="surname"
      onChange={(e) => props.onChange('surname', e.target.value)}
      labelText="Surname *"
      maxLength={50}
      value={props.ipContact.surname}
    />
  );
}

function TelephoneInput(props: {
  onChange: (field: keyof IPContact, value: string) => IUpdateIPContact;
  ipContact: IPContact;
}) {
  return (
    <TextInput
      inputIdentifierPrefix={inputIdentifierPrefix}
      fieldName="telephone"
      onChange={(e) => props.onChange('telephone', e.target.value)}
      labelText="Mobile or landline phone number *"
      maxLength={50}
      value={props.ipContact.telephone}
    />
  );
}

function OrganisationInput(props: {
  onChange: (field: keyof IPContact, value: string) => IUpdateIPContact;
  ipContact: IPContact;
}) {
  return (
    <TextInput
      inputIdentifierPrefix={inputIdentifierPrefix}
      fieldName="organisation"
      onChange={(e) => props.onChange('organisation', e.target.value)}
      labelText="Organisation *"
      maxLength={70}
      value={props.ipContact.organisation}
    />
  );
}

function EmailInput(props: {
  ipContact: IPContact;
  onChange: (field: keyof IPContact, value: string) => IUpdateIPContact;
}) {
  return (
    <TextInput
      fieldName="email"
      inputIdentifierPrefix={inputIdentifierPrefix}
      labelText="Email address *"
      maxLength={70}
      value={props.ipContact.email}
      onChange={(e) => props.onChange('email', e.target.value)}
    />
  );
}

function AddressInput(props: {
  onChange: (field: keyof IPContact, value: string) => IUpdateIPContact;
  ipContact: IPContact;
}) {
  return (
    <TextAreaInput
      inputIdentifierPrefix={inputIdentifierPrefix}
      fieldName="address"
      onChange={(e) => props.onChange('address', e.target.value)}
      labelText="Street address including postcode *"
      maxLength={MAX_LENGTH_LONG_INPUT}
      value={props.ipContact.address}
    />
  );
}

function RIPEHandle(props: {
  onChange: (value: string) => IUpdateIPContact | IUpdateSecondaryIPDetails;
  ripeHandle: IPContact['nic_ripe_handle'];
}) {
  return (
    <TextInput
      inputIdentifierPrefix={inputIdentifierPrefix}
      fieldName="nic_ripe_handle"
      onChange={(e) => props.onChange(e.target.value)}
      labelText="NIC RIPE Handle"
      maxLength={70}
      value={props.ripeHandle}
      optional={true}
    />
  );
}

function IPTextInput(props: {
  onChange: (value: string) => IUpdateIPText | IUpdateSecondaryIPDetails;
  ipAddressJustificationRequired: boolean;
  ipText: string;
}) {
  return (
    <>
      <TextAreaInput
        inputIdentifierPrefix={inputIdentifierPrefix}
        fieldName="ip_text"
        onChange={(e) => props.onChange(e.target.value)}
        labelText={`IP address justification ${props.ipAddressJustificationRequired ? '*' : ''}`}
        value={props.ipText || ''}
        maxLength={MAX_LENGTH_LONG_INPUT}
        optional={!props.ipAddressJustificationRequired}
      />
      <div className="float-right text-muted mt-1">
        {props.ipText.length} / {MAX_LENGTH_LONG_INPUT}
      </div>
    </>
  );
}
