import { useTheme } from 'styled-components';
import React from 'react';

interface IRadioButtonIcon {
  checked?: boolean;
  disabled?: boolean;
}

export const CheckboxButtonIcon = ({ checked, disabled }: IRadioButtonIcon) => {
  const theme = useTheme();

  if (checked)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="30" height="30" fill="#FFF" stroke={theme.colours.primaryB1} />
          <rect x="5" y="5" width="20" height="20" fill={theme.colours.primaryB1} />
        </g>
      </svg>
    );
  else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <rect
          x="0"
          y="0"
          width="30"
          height="30"
          fill="#FFF"
          fillRule="evenodd"
          stroke={disabled ? theme.colours.grey30 : '#767676'}
        />
      </svg>
    );
  }
};
