import Dropdown from 'shared/components/molecules/Dropdown';
import React from 'react';
import { Arrow, ExchangeOptions } from 'shared/components/molecules/SecondaryCircuits/SecondaryExchanges';

export function SecondaryDualExchanges(props: {
  aEndExchangeOptions: ExchangeOptions[];
  disabled: boolean;
  predicateAEnd: (it: any) => boolean;
  onChangeAEnd: (option: any) => void;
  bEndExchangeOptions: ExchangeOptions[];
  predicateBEnd: (it: any) => boolean;
  onChangeBEnd: (option: any) => void;
}) {
  return (
    <>
      <div data-testid="SecondaryCircuits-secondary-exchange">
        <h4>Select Secondary Exchange A</h4>
        <Dropdown
          options={props.aEndExchangeOptions}
          isDisabled={props.disabled}
          defaultValue={props.aEndExchangeOptions[0]}
          value={props.aEndExchangeOptions.find(props.predicateAEnd)}
          onChange={props.onChangeAEnd}
          isClearable={false}
        />
      </div>
      <Arrow />
      <div data-testid="SecondaryCircuits-secondary-exchange">
        <h4>Select Secondary Exchange B</h4>
        <Dropdown
          options={props.bEndExchangeOptions}
          isDisabled={props.disabled}
          defaultValue={props.bEndExchangeOptions[0]}
          value={props.bEndExchangeOptions.find(props.predicateBEnd)}
          onChange={props.onChangeBEnd}
          isClearable={false}
        />
      </div>
    </>
  );
}
