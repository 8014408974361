import { baseApi } from 'apiSlice';
import { INNIRecord, NNIDataCentreFromBE } from 'Location/NNI/types';
import { asArray, JSONAPIResponse } from 'shared/types/JSONApiResponse';
import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { useMemo } from 'react';
import { doesBearerSupportSpeed, getPortSpeedFromPort } from 'shared/utils/connectionCapacity';

const nniAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomerNNIs: build.query<INNIRecord[], string>({
      query: (customerId) => `/customers/${customerId}/nnis`,
      providesTags: ['NNIRecords'],
      transformResponse: (response: JSONAPIResponse<INNIRecord[]>): INNIRecord[] => asArray(response.data),
    }),
    getDataCentres: build.query<NNIDataCentreFromBE[], void>({
      query: () => `/nni-data-centres/`,
      providesTags: ['DataCentres'],
      transformResponse: (response: JSONAPIResponse<NNIDataCentreFromBE[]>): NNIDataCentreFromBE[] =>
        asArray(response.data),
    }),
  }),
});

const { useGetCustomerNNIsQuery, useGetDataCentresQuery } = nniAPI;

interface UseCustomerNNIs {
  isLoading: boolean;
  error: boolean;
  nniRecords: INNIRecord[];
  dataCentres: NNIDataCentreFromBE[];
}

type Filter<T> = (item: T | undefined) => boolean;

const applyFilters = <T>(results: T[], filters: Filter<T>[]): T[] =>
  filters.reduce((filteredResults, filter) => filteredResults.filter(filter), results);

export const useCustomerNNIs = (
  customerId: string,
  filters: { nni?: Filter<INNIRecord>[]; dataCentre?: Filter<NNIDataCentreFromBE>[] } = {}
): UseCustomerNNIs => {
  const getDataCentres = useGetDataCentresQuery();
  const getCustomerNNIs = useGetCustomerNNIsQuery(customerId);

  const nniRecords = useMemo(() => getCustomerNNIs.currentData ?? [], [getCustomerNNIs.currentData]);
  const dataCentres = useMemo(() => getDataCentres.currentData ?? [], [getDataCentres.currentData]);
  const filteredNNIs = useMemo(() => applyFilters(nniRecords, filters.nni ?? []), [filters.nni, nniRecords]);
  const filteredDataCentres = useMemo(() => applyFilters(dataCentres, filters.dataCentre ?? []), [
    dataCentres,
    filters.dataCentre,
  ]);

  return {
    isLoading:
      getCustomerNNIs.isLoading || getCustomerNNIs.isFetching || getDataCentres.isLoading || getDataCentres.isFetching,
    error: !!getCustomerNNIs.error || !!getDataCentres.error,
    nniRecords: filteredNNIs,
    dataCentres: filteredDataCentres,
  };
};

export const useCustomerNNIsWithMinimumBearerAndBandwidth = (
  customerId: string,
  bearer: BearerType | string | undefined,
  bandwidths: string[]
) => {
  const nniBearerFilter: Filter<INNIRecord> = useMemo(
    () => (nni) => doesBearerSupportSpeed(getPortSpeedFromPort(bearer))(nni?.attributes.port_type),
    [bearer]
  );
  const dataCentreBearerFilter: Filter<NNIDataCentreFromBE> = useMemo(
    () => (dataCentre) => {
      const isSpeedSupported = doesBearerSupportSpeed(getPortSpeedFromPort(bearer));
      return !!dataCentre?.attributes.ports.find((port) => isSpeedSupported(port));
    },
    [bearer]
  );

  const nniBandwidthFilter: Filter<INNIRecord> = useMemo(() => {
    const minBandwidth = bandwidths
      .filter((it) => !!it)
      .map((it) => parseInt(it, 10))
      .reduce((minimum, value) => (minimum > value ? value : minimum), Infinity);

    return (nni) =>
      nni?.attributes.pop_max_bandwidth ? parseInt(nni?.attributes.pop_max_bandwidth, 10) >= minBandwidth : false;
  }, [bandwidths]);

  const dataCentreBandwidthFilter: Filter<NNIDataCentreFromBE> = useMemo(() => {
    const minBandwidth = bandwidths
      .filter((it) => !!it)
      .map((it) => parseInt(it, 10))
      .reduce((minimum, value) => (minimum > value ? value : minimum), Infinity);

    return (dataCentre) =>
      dataCentre?.attributes.max_bandwidth ? parseInt(dataCentre?.attributes.max_bandwidth, 10) >= minBandwidth : false;
  }, [bandwidths]);

  return useCustomerNNIs(customerId, {
    nni: [nniBearerFilter, nniBandwidthFilter],
    dataCentre: [dataCentreBearerFilter, dataCentreBandwidthFilter],
  });
};
