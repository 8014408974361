import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Column from '../../atoms/Column';
import Button, { ButtonMainStyle } from '../../atoms/Button';
import TextArea from '../../atoms/TextArea';
import Alert from '../../atoms/Alert';
import Icon from '../../atoms/Icon';
import { base as faqBase } from 'FAQ/routes';

type Props = {
  className?: string;
  composeMode?: boolean;
  error?: boolean;
  loading?: boolean;
  numberOfNotes: number;
  onClose(): void;
  onSubmit(note: string): void;
  title: string;
  showFAQIntro?: boolean;
};

const Notes: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  composeMode = true,
  className,
  error = false,
  loading = false,
  numberOfNotes,
  onClose,
  onSubmit,
  title,
  showFAQIntro = false,
}) => {
  const [draftNote, setDraftNote] = useState('');
  const [showError, setShowError] = useState(false);
  const notesBottomAnchor = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (notesBottomAnchor && notesBottomAnchor.current) {
      notesBottomAnchor.current.scrollIntoView(false);
    }

    return function cleanup() {
      setDraftNote('');
    };
  }, [numberOfNotes]);

  useEffect(() => {
    setShowError(error);
  }, [error]);

  return (
    <div className={`${className} d-flex`}>
      <div className="d-flex pane-wrapper">
        <div className="d-flex pane-wrapper__header">
          <Column defaultWidth={9}>
            <span className="pane-wrapper__title">{title}</span>
          </Column>
          <Column defaultWidth={3} classNames={['text-right']}>
            <Button disabled={loading} className="close-btn" onClick={onClose} mainStyle={ButtonMainStyle.Link}>
              Close
            </Button>
          </Column>
        </div>
        {showFAQIntro && (
          <div className="d-flex pane-wrapper__intro">
            <p className="mb-0">
              Answers to common queries can be found in our{' '}
              <Link to={faqBase} target="_blank">
                Help and FAQ
              </Link>{' '}
              section. If you still have questions, send us a message or speak to your Account Manager.
            </p>
          </div>
        )}

        <div className="pane-wrapper__notes">
          {children}
          {numberOfNotes === 0 && <small className="no-notes-found">No notes found.</small>}
          <div className="notes-bottom-anchor" ref={notesBottomAnchor} />
        </div>

        <div className="d-flex pane-wrapper__footer">
          {composeMode ? (
            <form className="row no-gutters">
              <Column defaultWidth={12}>
                <TextArea
                  className={cx({ 'has-content': draftNote.length > 0 }, 'pb-0')}
                  disabled={loading}
                  value={draftNote}
                  placeholder="Write your notes in here."
                  onChange={(text) => setDraftNote(text)}
                />
              </Column>
              <Column defaultWidth={12} classNames={['text-right', 'pr-3']}>
                <Button
                  disabled={draftNote.length === 0 || loading}
                  onClick={() => onSubmit(draftNote)}
                  mainStyle={ButtonMainStyle.PrimaryRectangular}
                >
                  {loading ? 'Saving...' : 'Post Note'}
                </Button>
              </Column>
            </form>
          ) : (
            <div className="pt-3 pb-2 pl-5">
              <small className="compose-mode-disabled">New notes are disabled.</small>
            </div>
          )}
          {showError && (
            <Alert data-testid="note-error">
              Error whilst saving your note. Your changes may not have been saved. Please try again later. If the
              problem persists, please contact your Account Manager.
              <button className="error-close-btn" onClick={() => setShowError(false)}>
                <Icon name="close" />
              </button>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

const StyledNotes = styled(Notes)`
  background: white;
  border: solid 0.5px ${(props) => props.theme.colours.grey10};
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
  width: 475px;
  z-index: 1;

  .pane-wrapper {
    box-sizing: border-box;
    flex-flow: column;
    max-height: 100%;
    width: 100%;
  }

  .pane-wrapper__header {
    padding: 0.8em 0;
    border-bottom: solid 0.5px ${(props) => props.theme.colours.grey10};
  }

  .pane-wrapper__intro {
    padding: 0.8em 1.2em;
    background: ${(props) => props.theme.colours.secondaryC5};
    font-size: 1rem;
    border-bottom: solid 0.5px ${(props) => props.theme.colours.grey10};
  }

  .pane-wrapper__title {
    font-size: ${(props) => props.theme.typography.fontSizeH4};
  }

  .close-btn[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .pane-wrapper__notes {
    flex: 1;
    font-size: 0.95em;
    margin-bottom: 0;
    overflow-y: auto;

    .no-notes-found {
      font-style: italic;
      font-size: 90%;
      padding: 1em 0 0 1em;
      display: block;
    }
  }

  .pane-wrapper__footer {
    padding: 0.3em 0 1em 0;
    border-top: solid 0.5px ${(props) => props.theme.colours.grey10};
    position: relative;

    form {
      width: 100%;
    }

    textarea {
      height: 5em;
      resize: none;
    }

    .has-content textarea {
      border: 1px solid ${(props) => props.theme.colours.grey10};
    }

    .compose-mode-disabled {
      font-style: italic;
      font-size: 90%;
    }

    .alert {
      margin: 0 1em;
      position: absolute;
      top: -90px;

      .error-close-btn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`;

export { StyledNotes as Notes };
