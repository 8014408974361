import React, { FunctionComponent } from 'react';

import Edit from './Edit';
import Summary from './Summary';

interface Props {
  canEdit: boolean;
}

export const IPContact: FunctionComponent<React.PropsWithChildren<Props>> = ({ canEdit }) =>
  canEdit ? <Edit /> : <Summary />;
