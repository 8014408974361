import React, { FunctionComponent, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IAppState } from '../../reducers';
import { IRetrieving } from '../../shared/types/retrieving';
import Alert from '../../shared/components/atoms/Alert';
import Spinner from '../../shared/components/molecules/SpinnerWithText';
import { getAPIQuote } from '../actions';
import QuoteBuilder from '../QuoteBuilder';

interface Props {
  retrievingState: IRetrieving;
  getQuote(quoteId: string): void;
}

type Params = {
  quoteId?: string;
};

export const APIQuoteById: FunctionComponent<React.PropsWithChildren<Props>> = ({ retrievingState, getQuote }) => {
  const { quoteId } = useParams<Params>();

  useEffect(() => {
    if (quoteId) {
      getQuote(quoteId);
    }
  }, [getQuote, quoteId]);

  if (!retrievingState.error && !retrievingState.inProgress) {
    return <QuoteBuilder />;
  }

  return (
    <>
      {retrievingState.inProgress && <Spinner text="Retrieving API Quote" />}

      {retrievingState.error && (
        <Alert>
          Error retrieving quote. Please try again later. If the problem persists, please contact your Account Manager.
        </Alert>
      )}
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  retrievingState: state.quoteBuilder.retrieveAPIQuoteState,
});

const mapDispatchToProps = (dispatch: DispatchProp['dispatch']) => ({
  getQuote: (quoteId: string) => dispatch(getAPIQuote(quoteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(APIQuoteById);
