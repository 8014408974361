import { ResourceTypes } from 'Request';

export interface IPostcodeSiteBase {
  building_name?: string;
  building_number?: string;
  county?: string;
  post_town?: string;
  postcode?: string;
  thoroughfare?: string;
  sub_building?: string;
  [propName: string]: string | Qualifier | undefined | null;
}

export enum Qualifier {
  GOLD = 'Gold',
  SILVER = 'Silver',
  BRONZE = 'Bronze',
}

export interface IOpenReachAddress extends IPostcodeSiteBase {
  alk: string;
  css_district_code: string;
  street?: string;
  qualifier?: Qualifier | null;
}

export interface IPAFAddress extends IPostcodeSiteBase {
  udprn: string;
  uprn?: string;
  organisation_name?: string;
  latitude?: string;
  longitude?: string;
}

export interface IPostcodeSite {
  attributes: IOpenReachAddress | IPAFAddress;
}

export interface IPAFAddressSiteResponse {
  data: IPAFAddressSite[] | [];
  meta: {
    postcode: string;
    has_nnat_addresses: boolean;
  };
}

export interface IPAFAddressSite {
  id?: string;
  attributes: IPAFAddress;
  hasChanged? : boolean;
}

export interface IPAFAddressSiteQuoteIncludesResponse {
  id: string;
  type: ResourceTypes.paf_address;
  attributes: IPAFAddress;
}

export interface IOpenReachAddressSite {
  attributes: IOpenReachAddress;
  type: 'talktalk_addresses';
  id: string;
}

export interface ALKLocation {
  attributes: IOpenReachAddress;
  type: 'alk';
}

export interface ALKLocationResponse {
  data: ALKLocation;
}

export interface IPAFResponse {
  data: IPostcodeSite[] | [];
}

export const isPAFSite = (object: any): object is IPAFAddressSite => {
  try {
    return 'attributes' in object && 'udprn' in object.attributes;
  } catch {
    return false;
  }
};
