import React, { useEffect, useState } from 'react';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import cx from 'classnames';
import { IUserRoleFromBackend } from 'User/types/role';
import Column from 'shared/components/atoms/Column';
import { BackToUserManagement } from 'Admin/AddUser/BackToUserManagementButton';
import { UserType } from 'Admin/AddUser/index';

const SwitchUserTypeButtons = ({
  isLoading,
  setActiveView,
}: {
  isLoading: boolean;
  setActiveView: (view: UserType) => void;
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    setActiveView(activeTabIndex === 1 ? 'Neos User' : 'Customer');
  }, [activeTabIndex, setActiveView]);

  return (
    <div className="tab-buttons">
      <Button
        className={cx({ active: activeTabIndex === 0 })}
        disabled={isLoading}
        onClick={() => setActiveTabIndex(0)}
        mainStyle={ButtonMainStyle.LinkSecondary}
      >
        Customer
      </Button>
      <Button
        className={cx({ active: activeTabIndex === 1 })}
        disabled={isLoading}
        onClick={() => setActiveTabIndex(1)}
        mainStyle={ButtonMainStyle.LinkSecondary}
      >
        Neos Networks team
      </Button>
    </div>
  );
};
export const NavigationButtons = ({
  activeView,
  allRoles,
  loading,
  sseUser,
}: {
  sseUser: boolean;
  loading: boolean;
  activeView: (value: UserType) => void;
  allRoles: IUserRoleFromBackend[];
}) => (
  <div className="row">
    <Column defaultWidth={8}>
      {sseUser && <SwitchUserTypeButtons isLoading={loading} setActiveView={activeView} />}
    </Column>
    <Column defaultWidth={4} className="text-right">
      <BackToUserManagement allRoles={allRoles} isLoading={loading} />
    </Column>
  </div>
);
