import { BearerType } from 'Quotes/QuoteBuilder/components/Configure/Bearer/BearerType';
import { MediaType } from '../../../types/location';
import { ProductType } from 'Quotes/types/productTypes';

const mediaTypeForDisplay = (mediaType: MediaType | null, productType?: string | null, endPort?: BearerType | null) => {
  if (!mediaType) {
    return '';
  }

  if (mediaType === MediaType.LR) {

    if(productType === ProductType.OpticalP2P && endPort === BearerType.LARGE)
      return '10G-LR';
    else if(productType === ProductType.OpticalP2P && endPort === BearerType.LARGER)
      return '100G-LR';
    else if(productType === ProductType.OpticalP2P && endPort === BearerType.LARGEST)
      return '400G-LR';
    else
      return 'Single Mode (LR)';
  }

  if (mediaType === MediaType.SR) {
    return 'Multi Mode (SR)';
  }

  return mediaType.replace(/(.*)(\([A-Z]{2}\))/, '$1 $2');
};

export { mediaTypeForDisplay };
