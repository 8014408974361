export enum Feature {
  cerillionQuote = 'cerillionQuote',
  fttpAggregation = 'fttpAggregation',
  mdiaPriceBreakdown = 'mdiaPriceBreakdown',
  messaging = 'messaging',
  multiQuoteCopySection = 'multiQuoteCopySection',
  multiQuoteToOrder = 'multiQuoteToOrder',
  multiTermQuote = 'multiTermQuote',
  newMDIAUI = 'newMDIAUI',
  newNNIRequests = 'newNNIRequests',
  nnat = 'nnat',
  nnatOrderable = 'nnatOrderable',
  nrtRedirecting = 'nrtRedirecting',
  Optical100G400G = 'Optical100G400G',
  Optical100G400GInProductJourney = 'Optical100G400GInProductJourney',
  opticalP2PCerillion = 'opticalP2PCerillion',
  orderCerillionDeliveryInfo = 'orderCerillionDeliveryInfo',
  orderDownload = 'orderDownload',
  orderIncompleteAlert = 'orderIncompleteAlert',
  orderStatusStepperUI = 'orderStatusStepperUI',
  orderTemplates = 'orderTemplates',
  quoteCerillionStageUI = 'quoteCerillionStageUI',
  requestErrorToasts = 'requestErrorToasts',
  SecondaryCircuits = 'SecondaryCircuits',
  sampleFeature = 'sampleFeature',
}

export enum FeatureFlagEnvironment {
  dev = 'dev',
  integration = 'integration',
  testing = 'testing',
  staging = 'staging',
  preproduction = 'preproduction',
  production = 'production',
}

interface FeatureFlag {
  description: string;
  environments: {
    [key in FeatureFlagEnvironment]: {
      enabled: boolean;
      attributes?: {
        [key: string]: any;
      };
    };
  };
}

export default FeatureFlag;
