import React, { FunctionComponent } from 'react';
import { SplashLayout } from './SplashLayout';
import { pages, useTrackPage } from '../shared/utils/trackPage';
import Column from 'shared/components/atoms/Column';
import styled, { useTheme } from 'styled-components';

type MaintenanceMode = FunctionComponent<
  React.PropsWithChildren<{
    className?: string;
  }>
>;

export const MaintenanceMode: MaintenanceMode = ({ className }) => {
  useTrackPage(pages.maintenance);
  const theme = useTheme();

  return (
    <SplashLayout>
      <div className={`${className} row`}>
        <Column defaultWidth={6} xlWidth={6} classNames={['ml-auto']}>
          <div className="wrapper ml-auto">
            <h1>Come back soon</h1>
            <p>
              {theme.content.companyName} are doing some routine maintenance for{' '}
              {theme.content.productName.toUpperCase()}. We will be back online soon. Thanks for your patience.
            </p>
            <p>Contact your account manager if you have an urgent enquiry.</p>
          </div>
        </Column>
      </div>
    </SplashLayout>
  );
};

export default styled(MaintenanceMode)`
  padding-top: 15vh;

  .wrapper {
    background-color: white;
    border-radius: 6px;
    padding: 1.5em 2em;
    max-width: 500px;
  }

  h1 {
    color: ${(props) => props.theme.colours.primaryA1};
  }
`;
