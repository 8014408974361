import React, { FunctionComponent } from 'react';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import { Container } from '../Container';
import { isUploadErrorWithRows, useDownloadCSV, useUploadCSV } from '../../hooks';
import Spinner from 'shared/components/molecules/SpinnerWithText';
import Alert from 'shared/components/atoms/Alert';
import BrowseInput from 'shared/components/atoms/BrowseInput';
import Icon from 'shared/components/atoms/Icon';
import { UploadErrors } from '../UploadErrors';

export const PricingTab: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { downloading, error: downloadError, getData } = useDownloadCSV(
    '/pricing/supplier-prices/export',
    'FTTX_Pricing_Table.csv'
  );
  const { success, error: uploadError, errors: uploadErrors, uploading, uploadData } = useUploadCSV(
    '/pricing/supplier-prices'
  );
  const isLoading = downloading || uploading;
  const isError = downloadError || uploadError;

  return (
    <Container>
      <h2 className="h3">Upload new FTTX pricing data</h2>

      <ol>
        <li>
          <Button mainStyle={ButtonMainStyle.Link} onClick={getData} disabled={isLoading}>
            Download the FTTX spreadsheet
          </Button>{' '}
          for pricing edits.
        </li>
        <li>Make edits in the required format, then upload.</li>
      </ol>

      <div>
        <BrowseInput disabled={isLoading} inputText="Upload data" onUploadFile={uploadData} />
      </div>

      {success && (
        <p className="success">
          <Icon name="check" />
          <strong>Pricing table successfully updated.</strong>
        </p>
      )}

      {(isLoading || isError) && <hr />}

      {downloading && <Spinner text="Downloading spreadsheet..." />}
      {uploading && <Spinner text="Uploading spreadsheet..." />}

      {downloadError && (
        <Alert>
          Error downloading pricing data. Please try again later. If the problem persists, please contact your Account
          Manager.
        </Alert>
      )}
      {uploadError && (
        <Alert>
          Error uploading pricing data. Please try again later. If the problem persists, please contact your Account
          Manager.
          {isUploadErrorWithRows(uploadErrors) && uploadErrors.length > 0 && <UploadErrors errors={uploadErrors} />}
        </Alert>
      )}
    </Container>
  );
};
