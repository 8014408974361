import { ProductType } from '../../../../Quotes/types/productTypes';
import { isFTTXConnection, OverallConnectionType } from '../../../../Quotes/types/connectionType';
import Column from '../../../../shared/components/atoms/Column';
import React from 'react';
import Cost from './Cost';
import { IPriceData } from '../../../../Quotes/types/store';
export function FttxConnection(props: {
  connection: OverallConnectionType;
  productType: ProductType;
  selectedPrice: IPriceData;
}) {
  const bEndGeaCablelinkInstallCost = props.selectedPrice.b_end_gea_cablelink_install_cost ?? 0;
  const bEndGeaCablelinkAnnualCost = props.selectedPrice.b_end_gea_cablelink_annual_cost ?? 0;
  return (
    <>
      {isFTTXConnection(props.connection) && props.productType !== ProductType.DIA && (
        <div className="row no-gutters mt-1">
          <Column defaultWidth={2} offsetDefaultWidth={2}>
            <span>GEA cablelink</span>
          </Column>
          <Cost label="Install" cost={bEndGeaCablelinkInstallCost} className="b-end-gea-cablelink-install" />
          <Cost label="Annual" cost={bEndGeaCablelinkAnnualCost} className="b-end-gea-cablelink-annual" />
        </div>
      )}
    </>
  );
}
