import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';

interface IImageButton {
  description: string;
  className?: string;
  id?: string;
  key?: string;
  selected?: boolean;
  disabled?: boolean;
  src: string;
  onClick(event: any): void;
}

const ImageButton: FunctionComponent<React.PropsWithChildren<IImageButton>> = ({
  onClick,
  description,
  className,
  id,
  selected,
  src,
  disabled,
}) => {
  return (
    <div
      className={cx(className, 'image-button', {
        selected,
        notSelected: !selected,
        disabled,
      })}
      id={`wrapper-${id}`}
      onClick={(e) => {
        e.preventDefault();
        if (!disabled) {
          onClick(e);
        }
      }}
    >
      <div className="inner">
        <img alt={description} src={src} />
      </div>
    </div>
  );
};

const StyledImageButton = styled(ImageButton)`
  background-color: white;
  user-select: none;
  box-shadow: 0 2px 4px 0 rgba(0, 70, 135, 0.1);
  border: solid 0.5px ${(props) => props.theme.colours.grey10};
  background-color: #ffffff;
  border-radius: 3px;
  width: 100px;
  height: 80px;
  cursor: pointer;

  .inner {
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 2px solid transparent;
    padding: 10px;
  }

  &.disabled {
    opacity: 0.5;
    &.selected {
      opacity: 1;
    }
  }

  :not(:last-child) {
    margin-right: 1.25em;
  }

  img {
    width: 100%;
  }

  &.notSelected {
  }

  &.selected .inner {
    border-color: ${(props) => props.theme.colours.primaryB1};
  }
`;

export default StyledImageButton;
